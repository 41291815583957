import { useEffect, useState } from "react";

import ProductService from "../Services/ProductService";
import _ from "lodash";

function useNearbyPartnerPurchase(selectedBrands) {
  const [loading, setLoading] = useState("not_started");
  const [products, setProducts] = useState(null);
  const [brands, setBrands] = useState(null);
  const [error, setError] = useState(null);
  const district = localStorage.getItem("district");
  useEffect(() => {
    if (localStorage.getItem("district")) {
      const { axiosRequest, abortController } =
        ProductService.getNearbyPartnerPurchase(selectedBrands);
      setLoading("started");
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.productGists?.length > 0) {
            let productsFromResponse =
              response?.data?.responseData?.productGists;
            productsFromResponse = _.filter(productsFromResponse, (product) => {
              return (
                product.brandName !== "Unassigned Brand" &&
                ProductService.checkIfProductEnabled(product)
              );
            });
            console.log(`in nearby purchase request useEffect`);
            setProducts(productsFromResponse);
          } else {
            console.log(`no response received in responseData`);
            setProducts(null);
          }

          if (
            selectedBrands?.length === 0 &&
            response?.data?.responseData?.brands?.length > 0
          ) {
            let brandsFromResponse = response?.data?.responseData?.brands;
            brandsFromResponse = _.filter(brandsFromResponse, (brand) => {
              return brand.brandName !== "Unassigned Brand";
            });
            setBrands(brandsFromResponse);
          } else {
            console.log(`no brands received in responseData`);
          }
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting products`);
            if (error.data) {
              setError(error.data);
              console.log(error.data);
            } else {
              setError(error);
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    }
  }, [district, selectedBrands]);

  return { products, brands, loading, error };
}

export default useNearbyPartnerPurchase;
