import config from "../config";
import HTTPService from "./HTTPService";

export default class CatalogService {
  static getCropList(source) {
    const url = `${config.backendUrl}catalogservice/v2/crop/`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      Source: source,
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    });
  }
}
