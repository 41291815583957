import AnalyticsService from "./AnalyticsService";
import CommonConstantValues from "./CommonConstantValues";

export default class AndroidService {
  /*global Android*/

  static VOICE_SEARCH_COMPATIBLE_ANDROID_VERSION = 15;
  static CART_HIDDEN_ANDROID_VERSION = 16;
  static PULL_TO_REFRESH_ANDROID_VERSION = 16;
  static SEND_EVENT_COMPATIBLE_ANDROID_VERSION = 18;
  static SCANNER_COMPATIBLE_VERSION = 22;

  static checkIfAndroid() {
    try {
      if (Android) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  static getAndroidVersion() {
    let appVersionName = null;
    let appVersionCode = null;
    try {
      if (Android) {
        try {
          appVersionName = Android.getAppVersion();
          appVersionCode = Android.getAppVersionCode();
          return { appVersionName, appVersionCode };
        } catch (error) {
          return null;
        }
      }
    } catch (error) {
      return null;
    }
  }

  static setFileNameInApp(name) {
    try {
      if (Android) {
        return Android.setFileName(name);
      }
    } catch (e) {}
  }

  static downloadImage(fileName, url) {
    try {
      if (Android) {
        return Android.downloadImage(fileName, url);
      }
    } catch (e) {}
  }

  static shareOnWhatsApp(content, imageUrl) {
    try {
      if (Android) {
        Android.shareOnWhatsApp(content, imageUrl);
      }
    } catch (e) {}
  }

  static startSpeechToText(locale, callbackUrl, voiceSearchPrompt) {
    try {
      if (Android && AndroidService.getAndroidVersion) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (
          appVersionCode &&
          parseInt(appVersionCode) >=
            this.VOICE_SEARCH_COMPATIBLE_ANDROID_VERSION
        ) {
          Android.startSpeechToText(locale, callbackUrl, voiceSearchPrompt);
        } else {
        }
      }
    } catch (e) {}
  }

  static setCartInfo(numberOfProducts, cartUrl) {
    try {
      if (Android && this.getAndroidVersion()) {
        let { appVersionCode } = this.getAndroidVersion();
        if (
          appVersionCode &&
          parseInt(appVersionCode) >= this.CART_HIDDEN_ANDROID_VERSION
        ) {
          Android.setCartInfo(numberOfProducts, cartUrl);
        } else {
        }
      }
    } catch (e) {}
  }

  static showToolbarIcons = () => {
    try {
      if (Android) {
        Android.showToolbarIcons();
      }
    } catch (e) {}
  };

  static showBlockingProgress = () => {
    try {
      if (Android) {
        Android.showBlockingProgress();
      }
    } catch (error) {}
  };

  static goBack = () => {
    try {
      if (Android) {
        Android.goBack();
      }
    } catch (error) {}
  };

  static hideBlockingProgress = () => {
    try {
      if (Android) {
        Android.hideBlockingProgress();
      }
    } catch (error) {}
  };

  static hideToolbarIcons = () => {
    try {
      if (Android) {
        Android.hideToolbarIcons();
      }
    } catch (e) {}
  };

  static setPullToRefresh() {
    try {
      if (Android && AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (
          appVersionCode &&
          parseInt(appVersionCode) >= this.PULL_TO_REFRESH_ANDROID_VERSION
        ) {
          Android.setPullToRefresh();
        } else {
          console.log(
            `Set pull to refresh is not available on app version lower than ${this.PULL_TO_REFRESH_ANDROID_VERSION}`
          );
        }
      }
    } catch (e) {}
  }

  static unsetPullToRefresh() {
    try {
      if (Android && AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (
          appVersionCode &&
          parseInt(appVersionCode) >= this.PULL_TO_REFRESH_ANDROID_VERSION
        ) {
          Android.unsetPullToRefresh();
        } else {
          console.log(
            `Unset pull to refresh is not available on app version lower than ${this.PULL_TO_REFRESH_ANDROID_VERSION}`
          );
        }
      }
    } catch (e) {}
  }

  static captureImageAndLocation(
    callbackUrl,
    captureLocationFlag,
    signedUrlForUpload,
    storeImageS3Url,
    shouldCompress,
    targetImageWidth,
    targetImageHeight,
    targetImageQuality
  ) {
    try {
      if (Android && AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (
          appVersionCode &&
          parseInt(appVersionCode) >=
            CommonConstantValues.STORE_IMAGE_UPLOAD_ANDROID_VERSION
        ) {
          if (
            parseInt(appVersionCode) >=
            CommonConstantValues.IMAGE_COMPRESSION_ENABLED_ANDROID_VERSION
          ) {
            Android.captureImageAndLocation(
              callbackUrl,
              captureLocationFlag,
              signedUrlForUpload,
              storeImageS3Url,
              shouldCompress,
              targetImageWidth,
              targetImageHeight,
              targetImageQuality
            );
          } else {
            Android.captureImageAndLocation(
              callbackUrl,
              captureLocationFlag,
              signedUrlForUpload,
              storeImageS3Url
            );
          }
        } else {
          console.log(
            `Upload store image functionality is available on app version higher than ${CommonConstantValues.STORE_IMAGE_UPLOAD_ANDROID_VERSION}`
          );
        }
      }
    } catch (error) {
      console.log(`Can't load url with requested parameter.`, error);
    }
  }

  static pushEvent(eventName, eventProperties) {
    try {
      if (Android && AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (
          appVersionCode &&
          parseInt(appVersionCode) >= this.SEND_EVENT_COMPATIBLE_ANDROID_VERSION
        ) {
          eventProperties =
            AnalyticsService.populateWithDefaultEventProperties(
              eventProperties
            );
          Android.sendEvent(eventName, JSON.stringify(eventProperties));
        } else {
          AnalyticsService.pushEvent(eventName, eventProperties);
        }
      } else {
        AnalyticsService.pushEvent(eventName, eventProperties);
      }
    } catch (e) {}
  }

  static openScannerCameraActivity(urlToLoad, fallbackURL) {
    try {
      if (Android) {
        Android.openScannerCameraActivity(
          urlToLoad,
          fallbackURL,
          "Hold the camera still for 15 seconds & align with the barcode."
        );
      }
    } catch (e) {
      console.error("Android not found.");
    }
  }

  static startAnalysing() {
    try {
      if (Android) {
        Android.startAnalysing();
      }
    } catch (e) {
      console.error("Android not found.");
    }
  }

  static proceedToNextPage(nextPageURL) {
    try {
      if (Android) {
        Android.proceedToNextPage(nextPageURL);
      }
    } catch (e) {
      console.error(e + " Android not found.");
    }
  }

  static setInstructions(instructionText) {
    try {
      if (Android) {
        Android.setInstructions(instructionText);
      }
    } catch (e) {
      console.error("Android not found.");
    }
  }

  static handleNotificationRedirectionWithPermission() {
    try {
      if (Android) {
        Android.handleNotificationRedirectionWithPermission();
      }
    } catch (e) {}
  }

  static openWebViewActivity(url, title) {
    try {
      if (Android) {
        Android.openWebViewActivity(url, title);
      }
    } catch (e) {
      console.error("Android not found.");
    }
  }

  static askCameraPermission() {
    try {
      if (Android) {
        Android.askCameraPermission();
      }
    } catch (e) {
      console.error("Android not found.");
    }
  }
}
