import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";

import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import ProductsList from "../Product/ProductsList";
import ProductService from "../../Services/ProductService";
import CategoryService from "../../Services/CategoryService";
import BrandService from "../../Services/BrandService";
import Search from "../Dashboard/Search";
import StateUtils from "../../Utils/StateUtils";
import useQuery from "../../Hooks/useQuery";

const useStyles = makeStyles(() => ({
  mainContent: {
    backgroundColor: "#F3F3F3",
    marginBottom: "92px",
  },
}));

function Brand() {
  const classes = useStyles();
  const { brandName, categoryName, className } = useParams();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const [stateCode, setStateCode] = useState("MH");
  const query = useQuery();
  const showVariantsFromQueryParam = query.get("variants");

  useEffect(() => {
    setLoading("started");
    let currentStateCode = StateUtils.getStateCode();
    setStateCode(currentStateCode);
    if (categoryName && brandName) {
      let categoryId = null;

      const { axiosRequest, abortController } =
        CategoryService.getAllCategories();
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            let categoriesFromResponse = _.find(
              response?.data?.responseData,
              (categoryObject) =>
                categoryObject.type === "SHOP_BY_CATEGORY" &&
                categoryObject?.data?.categories
            )?.data?.categories;
            if (categoriesFromResponse) {
              let currentCategory = CategoryService.getCategoryByName(
                categoriesFromResponse,
                categoryName
              );
              if (currentCategory && currentCategory.id) {
                categoryId = currentCategory.id;
              }
              const { axiosRequest } = CategoryService.getCategoryDetails(
                categoryName,
                500
              );
              axiosRequest
                .then((categoryResponse) => {
                  if (categoryResponse?.data?.responseData) {
                    let responseData = categoryResponse.data.responseData;
                    if (responseData?.brands?.items?.length > 0) {
                      let brandsFromResponse = responseData.brands.items;

                      if (brandsFromResponse && brandsFromResponse.length > 0) {
                        let brandId = null;
                        let currentBrand = _.filter(
                          brandsFromResponse,
                          (brand) => {
                            return brand.brandName === brandName;
                          }
                        )?.[0];

                        if (currentBrand && currentBrand.brandId) {
                          brandId = currentBrand.brandId;
                          const { axiosRequest } =
                            ProductService.getProductsByCategoryAndBrandId(
                              categoryId,
                              brandId
                            );
                          axiosRequest
                            .then((productsResponse) => {
                              if (
                                productsResponse?.data?.responseData
                                  ?.productGists?.length > 0
                              ) {
                                setProducts(
                                  productsResponse.data.responseData
                                    .productGists
                                );
                              } else {
                                console.log(`no products found`);
                              }

                              setLoading("done");
                            })
                            .catch((error) => {
                              console.log(
                                `error fetching products for category & brand`
                              );
                              setLoading("done");
                            });
                        } else {
                          console.log(`invalid brand name or ID`);
                          setLoading("done");
                        }
                      } else {
                        console.log(`no brands found`);
                        setLoading("done");
                      }
                    } else {
                      console.log(`no brands found`);
                      setLoading("done");
                    }
                  } else {
                    console.log(
                      `no response received in responseData for categoryDetails`
                    );
                    setLoading("done");
                  }
                })
                .catch((error) => {
                  console.log(`error getting categories`);
                  if (error.data) {
                    console.log(error.data);
                  } else {
                    console.log(error);
                  }
                  setLoading("done");
                });
            }
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting categories`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
          }
        });
      return () => abortController.abort();
    } else if (brandName && className) {
      const { axiosRequest, abortController } = BrandService.getAllBrands();
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.brands?.length > 0) {
            let brandsFromResponse = response.data.responseData.brands;
            if (brandsFromResponse && brandsFromResponse.length > 0) {
              let brandId = null;
              let currentBrand = _.filter(brandsFromResponse, (brand) => {
                return brand.brandName === brandName;
              })?.[0];
              console.log({ currentBrand });
              if (currentBrand && currentBrand.brandId) {
                brandId = currentBrand.brandId;
                let productClass = _.toUpper(className)?.split("CLASS-")?.[1];
                let showVariantsFlag = false;
                if (showVariantsFromQueryParam == "true") {
                  showVariantsFlag = true;
                }
                const { axiosRequest } =
                  ProductService.getProductsByBrandIdAndClass(
                    brandId,
                    productClass,
                    showVariantsFlag
                  );
                axiosRequest
                  .then((productsResponse) => {
                    if (
                      productsResponse?.data?.responseData?.productGists
                        ?.length > 0
                    ) {
                      setProducts(
                        productsResponse.data.responseData.productGists
                      );
                    } else {
                      console.log(`no products found`);
                    }
                    setLoading("done");
                  })
                  .catch((error) => {
                    console.log(`error fetching products for brand for class`);
                  });
              } else {
                console.log(`invalid brand name or ID`);
                setLoading("done");
              }
            } else {
              console.log(`no brands found`);
              setLoading("done");
            }
          } else {
            console.log(`no brands found`);
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting brands`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    } else if (brandName) {
      const { axiosRequest, abortController } = BrandService.getAllBrands();
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.brands?.length > 0) {
            let brandsFromResponse = response.data.responseData.brands;
            if (brandsFromResponse && brandsFromResponse.length > 0) {
              let brandId = null;
              let currentBrand = _.filter(brandsFromResponse, (brand) => {
                return brand.brandName === brandName;
              })?.[0];
              console.log({ currentBrand });
              if (currentBrand && currentBrand.brandId) {
                brandId = currentBrand.brandId;
                const { axiosRequest } =
                  ProductService.getProductsByBrandId(brandId);
                axiosRequest
                  .then((productsResponse) => {
                    if (
                      productsResponse?.data?.responseData?.productGists
                        ?.length > 0
                    ) {
                      setProducts(
                        productsResponse.data.responseData.productGists
                      );
                    } else {
                      console.log(`no products found`);
                    }
                    setLoading("done");
                  })
                  .catch((error) => {
                    console.log(`error fetching products for category & brand`);
                  });
              } else {
                console.log(`invalid brand name or ID`);
                setLoading("done");
              }
            } else {
              console.log(`no brands found`);
              setLoading("done");
            }
          } else {
            console.log(`no brands found`);
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting brands`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    } else {
      console.log(`no category or brand name found`);
      setLoading("done");
    }
  }, [categoryName, brandName]);

  let title = brandName;

  if (categoryName && categoryName !== "") {
    title = title + ` in ${_.upperFirst(categoryName)}`;
  }

  return (
    <div>
      <ErrorBoundary message={``}>
        <TopBar
          title={title}
          backNavigation={true}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <div className={classes.mainContent}>
        <ErrorBoundary message={``}>
          <Search page={"Brand Page"} />
        </ErrorBoundary>
        <ErrorBoundary message={``}>
          <ProductsList
            loading={loading}
            products={products}
            page={`${title}'s Brand Page`}
            stateCode={stateCode}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default Brand;
