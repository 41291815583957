import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowForwardTwoTone } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import AmountUtils from "../../Utils/AmountUtils";
import CartCheckoutService from "../../Services/CartCheckoutService";
import _ from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";
import PaymentMethod from "./PaymentMethod";
import SentryService from "../../Services/SentryService";

const useStyles = makeStyles((theme) => ({
  number: {
    position: "absolute",
    right: 0,
    transition: "transform 0.3s ease-out",
    transform: "translateX(0%)",
  },
  numberExit: {
    position: "absolute",
    right: 0,
    transition: "transform 0.3s ease-out",
    transform: "translateX(100%)",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 100,
    background: "#FFFFFF",
    paddingTop: "16px",
  },
  paperProps: {
    borderRadius: "24px 24px 0px 0px",
    overflowY: "hidden",
  },
  loadingContainer: {
    background: "#F3F3F3",
    height: "fit-content",
    paddingBottom: "60px",
  },
  radioGroup: {
    padding: "0px 12px",
  },
  proceedButton: {
    fontWeight: 700,
    padding: "8px 16px",
    borderRadius: "0px",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  proceedText: {
    display: "flex",
    gap: "4px",
    fontSize: "16px",
    alignItems: "center",
    flex: "none",
  },
  totalPayableAmount: {
    fontSize: "10px",
    lineHeight: "10px",
    textTransform: "none",
  },
}));

const PaymentModal = ({
  open,
  cart,
  otherCart,
  onClose,
  onSubmit,
  currentTab,
  isPaymentOverThreshold,
  onError,
  usedRealCash,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(null);
  const isCreditBag = currentTab === "credit";
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState(
    isCreditBag && isPaymentOverThreshold ? "CREDIT" : "CASH"
  );
  const [reviewCart, setReviewCart] = useState(null);

  let applicableCoupons = cart.applicableCoupons;
  if (otherCart?.appliedCoupon) {
    applicableCoupons = _.filter(applicableCoupons, (coupon) => {
      if (
        coupon.offerId === otherCart?.appliedCoupon &&
        coupon.maxNoOfUsages === 1
      ) {
        return false;
      }
      return true;
    });
  }

  useEffect(() => {
    setCurrentlySelectedOption(
      isCreditBag && isPaymentOverThreshold ? "CREDIT" : "CASH"
    );
  }, [isPaymentOverThreshold, isCreditBag]);

  useEffect(() => {
    if (open) {
      setLoading("started");
      const { axiosRequest, abortController } = CartCheckoutService.reviewCart(
        { ...cart, usedRealCash },
        otherCart
      );
      axiosRequest
        .then((reviewCart) => {
          setReviewCart(reviewCart);
          let paymentOptions = [];
          let payableAmount = [];
          paymentOptions.push("online");
          payableAmount.push(`${cart?.billDetails?.saathiAdvancePayAmount}`);
          if (isPaymentOverThreshold) {
            paymentOptions = [];
            payableAmount = [];
          }
          if (isCreditBag) {
            paymentOptions.push("credit");
            payableAmount.push(`${cart?.billDetails?.saathiCreditAmount}`);
          }

          AnalyticsService.pushEvent("Drawer Viewed", {
            drawerType: "payment",
            paymentOptions: paymentOptions.join(","),
            payableAmount: payableAmount.join(","),
            cartType: currentTab,
            numberOfCoupons: applicableCoupons?.length || 0,
            couponIds:
              _.map(applicableCoupons || [], (coupon) => coupon?.offerId).join(
                ","
              ) || "",
            cartId: cart?.cartId,
          });
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log({ error });
            onError(`ERROR_FAILED_TO_GET_REVIEW_CART`);
            const reason = SentryService.captureCustomErrorMessage(
              "ERROR_FAILED_TO_GET_REVIEW_CART",
              error
            );
            AnalyticsService.pushEvent("Review Cart Call Failed", {
              reason: reason,
            });
            console.log(`failure in getting review cart`);
            setLoading("done");
          }
        });
      return () => abortController.abort();
    }
  }, [open]);

  const handleOnClose = () => {
    AnalyticsService.pushEvent("Cancel Icon Clicked", {
      drawerType: "payment",
      cartType: currentTab,
    });
    onClose();
  };

  return (
    <SwipeableDrawer
      anchor={`bottom`}
      open={open}
      PaperProps={{
        className: classes.paperProps,
      }}
      onClose={handleOnClose}
      onOpen={() => {}}
      disableDiscovery={true}
      disableSwipeToOpen={true}
    >
      <Box
        sx={{ height: "fit-content", overflow: "scroll" }}
        role="presentation"
      >
        <Box className={classes.stickyHeader}>
          <Grid
            container
            alignItems={`center`}
            justifyContent={`space-between`}
            sx={{ padding: "0px 20px 16px 20px" }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              {`Payment Method`}
            </Typography>
            <CloseIcon
              onClick={handleOnClose}
              sx={{
                color: "#999999",
                fontSize: "28px",
                cursor: "pointer",
              }}
            />
          </Grid>
          <Divider />
        </Box>

        <Box className={classes.loadingContainer}>
          {loading === "started" && (
            <>
              <Grid
                container
                direction={`row`}
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{ padding: "16px" }}
              >
                <Grid item xs={12}>
                  <Skeleton variant={`rectangular`} height={90} />
                </Grid>
                {currentTab === "credit" && (
                  <Grid item xs={12}>
                    <Skeleton variant={`rectangular`} height={90} />
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {loading === "done" && (
            <>
              <FormControl fullWidth className={classes.radioGroup}>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={currentlySelectedOption}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    value={"CASH"}
                    label={""}
                    control={
                      <PaymentMethod
                        url="/icons/orderPayNow.svg"
                        text="Pay Online"
                        amount={cart?.billDetails?.saathiAdvancePayAmount}
                        value="CASH"
                        isChecked={currentlySelectedOption === "CASH"}
                        handleOnSelected={() => {
                          AnalyticsService.pushEvent("Payment Option Clicked", {
                            paymentOption: "online",
                            payableAmount:
                              cart?.billDetails?.saathiAdvancePayAmount,
                            cartType: currentTab,
                            drawerType: "payment",
                            numberOfCoupons: applicableCoupons?.length || 0,
                            couponIds:
                              _.map(
                                applicableCoupons || [],
                                (coupon) => coupon?.offerId
                              ).join(",") || "",
                          });
                          setCurrentlySelectedOption("CASH");
                        }}
                        subTextPrefix={"Great ! You get"}
                        subText={
                          !_.isNil(
                            reviewCart?.totalCashDiscountForAdvancePayment
                          ) &&
                          reviewCart?.totalCashDiscountForAdvancePayment >= 0 &&
                          cart?.cashDiscountBenefit?.amount > 0 &&
                          ` ₹${AmountUtils.format(
                            cart?.cashDiscountBenefit?.amount
                          )} upfront Cash Discount on online payments`
                        }
                        errorText={`Online payment is not available for amount over 5 lakh`}
                        isDisabled={isPaymentOverThreshold}
                      />
                    }
                    sx={{
                      margin: "0px",
                      justifyContent: "space-between",
                      textTransform: "uppercase",
                      color: "#4F4F4F",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px",
                        fontWeight: 700,
                        paddingLeft: "8px",
                      },
                    }}
                  />
                  {currentTab === "credit" && (
                    <FormControlLabel
                      labelPlacement="start"
                      value={"CREDIT"}
                      label={""}
                      control={
                        <PaymentMethod
                          url="/icons/aslogo.svg"
                          text="Use Credit"
                          amount={cart?.billDetails?.saathiCreditAmount}
                          value="CREDIT"
                          isChecked={currentlySelectedOption === "CREDIT"}
                          handleOnSelected={() => {
                            AnalyticsService.pushEvent(
                              "Payment option clicked",
                              {
                                paymentOption: "credit",
                                payableAmount:
                                  cart?.billDetails?.saathiCreditAmount,
                                cartType: currentTab,
                                drawerType: "payment",
                                numberOfCoupons: applicableCoupons?.length || 0,
                                couponIds:
                                  _.map(
                                    applicableCoupons || [],
                                    (coupon) => coupon?.offerId
                                  ).join(",") || "",
                              }
                            );

                            setCurrentlySelectedOption("CREDIT");
                          }}
                          subTextPrefix={"You will get"}
                          subText={` CD & CN benefit after you clear your outstanding`}
                          errorText={`Order amount is more than credit limit`}
                        />
                      }
                      sx={{
                        margin: "0px",
                        justifyContent: "space-between",
                        textTransform: "uppercase",
                        color: "#4F4F4F",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "12px",
                          fontWeight: 700,
                          paddingLeft: "8px",
                        },
                      }}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </>
          )}
        </Box>

        <Box
          sx={{
            position: "sticky",
            bottom: "0",
            width: "100%",
            zIndex: 100,
            background: "#FFFFFF",
          }}
        >
          <Grid
            container
            spacing={0}
            alignItems={`center`}
            justifyContent={`center`}
            textAlign={`right`}
          >
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size={"medium"}
              disabled={loading !== "done"}
              onClick={() => {
                const eventData = {
                  paymentOption:
                    currentlySelectedOption === "CASH" ? "online" : "credit",
                  payableAmount:
                    currentlySelectedOption === "CASH"
                      ? cart?.billDetails?.saathiAdvancePayAmount
                      : cart?.billDetails?.saathiCreditAmount,
                  cartType: currentTab,
                  drawerType: "payment",
                };
                onSubmit(reviewCart, currentlySelectedOption, eventData);
              }}
              className={classes.proceedButton}
            >
              <span className={classes.proceedText}>
                <span>Proceed</span>
                <ArrowForwardTwoTone />
              </span>
              <span className={classes.totalPayableAmount}>
                {loading === "started" && (
                  <Skeleton variant={`rectangular`} height={18} width={100} />
                )}
                {loading === "started" && (
                  <Skeleton variant={`rectangular`} height={10} width={125} />
                )}
                {loading === "done" && (
                  <span
                    style={{
                      fontSize: "18px",
                      lineHeight: "18px",
                      overflow: "hidden",
                      position: "relative",
                      display: "inline-block",
                      height: "18px",
                      width: "100%",
                    }}
                  >
                    <span
                      className={
                        currentlySelectedOption === "CASH"
                          ? classes.number
                          : classes.numberExit
                      }
                      key={cart?.billDetails?.saathiAdvancePayAmount}
                    >
                      ₹
                      {AmountUtils.format(
                        cart?.billDetails?.saathiAdvancePayAmount
                      )}
                    </span>
                    <span
                      className={
                        currentlySelectedOption === "CREDIT"
                          ? classes.number
                          : classes.numberExit
                      }
                      key={cart?.billDetails?.saathiCreditAmount}
                    >
                      ₹
                      {AmountUtils.format(
                        cart?.billDetails?.saathiCreditAmount
                      )}
                    </span>
                  </span>
                )}
                {loading === "done" && (
                  <span className={classes.totalPayableAmount}>
                    Total Payable Amount
                  </span>
                )}
              </span>
            </Button>
          </Grid>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default PaymentModal;
