import React from "react";
import * as Sentry from "@sentry/react";

export default function ErrorBoundary(props) {
  return (
    <Sentry.ErrorBoundary fallback={<></>}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
