import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import AnalyticsService from "../../Services/AnalyticsService";
import BannerService from "../../Services/BannerService";
import _ from "lodash";
import Banner from "./Banner";
import BlockUi from "react-block-ui";
import EmptyError from "../EmptyError/EmptyError";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    marginBottom: "100px",
  },
  textAlign: {
    marginLeft: "10px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

const Offers = () => {
  const classes = useStyles();
  const [globalBanners, setGlobalBanners] = useState([]);
  const [loadingBanners, setLoadingBanners] = useState("not_started");
  let bannerArray = [];
  useEffect(() => {
    setLoadingBanners("started");
    AnalyticsService.pushEvent(`Offers Page Viewed`);
    const { axiosRequest, abortController } = BannerService.getBannersData(
      `OFFERS`,
      JSON.parse(localStorage.getItem("profileAddress"))?.district
    );
    axiosRequest
      .then((response) => {
        response?.data?.responseData?.scrollableBanners.map(
          (bannerData, index) => {
            let bannerObj = getScrollableBanners(bannerData);
            bannerArray.push(bannerObj);
          }
        );
        let staticBannerList = [];
        response?.data?.responseData?.banners.map((value, index) => {
          if (value?.media?.[0]?.type === "IMAGE") {
            let banner = value?.media[0];
            banner.bannerCode = value?.code;
            if (value?.data[0]) {
              banner.data = value?.data[0];
            }

            banner.description = value?.description;
            banner.priority = value?.priority;
            banner.bannerType = value?.type?.toUpperCase();
            banner.name = value?.name;
            staticBannerList.push(banner);
          }
        });
        let globalBannerList = [...bannerArray, ...staticBannerList];
        globalBannerList = globalBannerList.sort(
          (a, b) => parseInt(a.priority) - parseInt(b.priority)
        );
        setGlobalBanners(globalBannerList);
        setLoadingBanners("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting banners`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoadingBanners("done");
        }
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    OffersSection();
  }, [globalBanners]);

  const OffersSection = () => {
    return (
      <BlockUi
        tag="div"
        blocking={loadingBanners === "started"}
        message={`Loading...`}
        style={{ minHeight: "35em" }}
        keepInView
      >
        {loadingBanners === "done" && globalBanners.length < 1 && (
          <div>
            <EmptyError image="EmptyPage.svg" text="Sorry, No offers found" />
          </div>
        )}
        {globalBanners?.length > 0 && (
          <Banner
            globalBanners={globalBanners}
            loadingBanners={loadingBanners}
            page={`catalog`}
            blur={PartnerService.applySmudgedBannerFilter()}
          />
        )}
      </BlockUi>
    );
  };

  const getScrollableBanners = (bannerData) => {
    let scrollableBannerMedia = bannerData?.media;
    let scrollableBannerData = null;
    if (bannerData?.data) {
      scrollableBannerData = bannerData?.data;
      _.forEach(scrollableBannerMedia, (media, index) => {
        if (scrollableBannerData[index]) {
          media.data = scrollableBannerData[index];
        }
      });
    }
    scrollableBannerMedia = _.filter(scrollableBannerMedia, (media) => {
      return media.type === `IMAGE`;
    });
    scrollableBannerMedia["description"] = bannerData?.description;
    scrollableBannerMedia["bannerType"] = bannerData?.type?.toUpperCase();
    scrollableBannerMedia["priority"] = bannerData?.priority;
    scrollableBannerMedia["name"] = bannerData?.name;
    return scrollableBannerMedia;
  };

  return (
    <div className={classes.root}>
      <ErrorBoundary message={``}>
        <OffersSection />
      </ErrorBoundary>
    </div>
  );
};
export default Offers;
