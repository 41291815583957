import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";

import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import BrandsList from "../Brand/BrandsList";
import CategoryService from "../../Services/CategoryService";
import ProductsList from "../Product/ProductsList";
import EmptyError from "../EmptyError/EmptyError";
import Search from "../Dashboard/Search";
import AnalyticsService from "../../Services/AnalyticsService";
import StateUtils from "../../Utils/StateUtils";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: "#F3F3F3",
    minHeight: theme.spacing(20),
  },
}));

function Category() {
  const classes = useStyles();
  const { categoryName } = useParams();

  const [brands, setBrands] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const [stateCode, setStateCode] = useState("MH");

  useEffect(() => {
    AnalyticsService.pushEvent(`Category Page Viewed`);
  }, []);

  useEffect(() => {
    setLoading("started");
    let currentStateCode = StateUtils.getStateCode();
    setStateCode(currentStateCode);
    const { axiosRequest, abortController } =
      CategoryService.getCategoryDetails(categoryName, 500);
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          let responseData = response.data.responseData;
          if (responseData?.brands?.items?.length > 0) {
            let brandsFromResponse = responseData.brands.items;
            setBrands(brandsFromResponse);
            setLoading("done");
          } else {
            console.log(`no brands found`);
            setLoading("done");
          }
          if (responseData?.products?.items?.length > 0) {
            let productsFromResponse = responseData.products.items;
            setProducts(productsFromResponse);
            setLoading("done");
          }
        } else {
          console.log(`no response received in responseData`);
          setLoading("done");
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting brands`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, [categoryName]);

  return (
    <div>
      <ErrorBoundary message={``}>
        <TopBar
          title={_.upperFirst(categoryName)}
          backNavigation={true}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <div>
        <BlockUi
          tag="div"
          blocking={loading === "started" && loading !== "done"}
          message={`Loading...`}
          keepInView
        >
          <div className={classes.mainContent}>
            {loading === "done" && !brands && !products ? (
              <EmptyError
                image="EmptyPage.svg"
                text="Sorry! no products here"
              />
            ) : (
              <>
                <ErrorBoundary message={``}>
                  <Search page={"Category Page"} />
                </ErrorBoundary>

                {/* {stateCode !== "BH" && (
                  <ErrorBoundary message={``}>
                    <div style={{ marginTop: "0px", padding: "15px" }}>
                      <BrandsList
                        loading={loading}
                        brands={brands}
                        numberOfBrandsToShow={9}
                        categoryName={categoryName}
                        page={`${categoryName}'s Category Page`}
                      />
                    </div>
                  </ErrorBoundary>
                )} */}

                <ErrorBoundary message={``}>
                  <ProductsList
                    loading={loading}
                    products={products}
                    categoryName={categoryName}
                    page={`${categoryName} Category Page`}
                    stateCode={stateCode}
                  />
                </ErrorBoundary>
              </>
            )}
          </div>
        </BlockUi>
      </div>
    </div>
  );
}

export default Category;
