import React from "react";

import { makeStyles } from "@mui/styles";
import { Card, Typography } from "@mui/material";

import { iptPartnerStatus } from "./InterPartyTransfersPage";

const useStyles = makeStyles({
  iptApprovalStatus: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    borderRadius: "5px",
    padding: "5px 7px",
    textTransform: "capitalize",
  },
  headingStyles: { fontWeight: 700, marginTop: "16px", lineHeight: 1 },
  partnerCard: {
    marginTop: "8px",
    borderRadius: "10px",
    padding: "12px 16px",
    fontSize: "14px",
    borderTop: "1px solid #eee",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  partnerAddress: {
    display: "flex",
    alignItems: "flex-start",
    gap: "5px",
    marginTop: "5px",
  },
  locationIconImage: { position: "relative", top: 2, opacity: 0.7 },
});

export default function IPTDetailPagePartnerCard({
  heading,
  partner,
  showPartnerApprovalStatus,
}) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="subtitle1" className={classes.headingStyles}>
        {heading}
      </Typography>
      <Card className={classes.partnerCard}>
        <Typography variant="subtitle2" fontWeight={700}>
          {partner?.name}
          {showPartnerApprovalStatus &&
            iptPartnerStatus[partner?.iptApprovalStatus]}
        </Typography>
        <Typography className={classes.partnerAddress}>
          <img
            src="/icons/location.svg"
            alt="location"
            width="12px"
            className={classes.locationIconImage}
          />
          <span
            style={{ fontSize: 12, color: "#666", textTransform: "capitalize" }}
          >
            {partner?.address}
          </span>
        </Typography>
      </Card>
    </>
  );
}
