import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import LeadService from "../../Services/LeadService";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  leadName: {
    fontWeight: "700",
    fontSize: "14px",
    color: "white",
  },
  leadInformation: {
    fontWeight: "400",
    fontSize: "12px",
    color: "white",
  },
  cardContainer: {
    paddingTop: "5px",
    paddingBottom: "6px !important",
  },
  name: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#333333",
  },
  title: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#333333",
  },
  productCardWrapperLink: {
    textDecoration: `none`,
    color: "rgba(0, 0, 0, 0.87)",
  },
  productCardWrapper: {
    wordBreak: "break-word",
    borderRadius: "7px",
    border: "1px solid #EEEEEE",
    textAlign: "center",
  },
  productCardContent: {
    padding: theme.spacing(1),
    paddingBottom: "10px !important", // theme.spacing(1),
  },
  productImageContainer: {
    marginTop: theme.spacing(0.5),
    width: "100%",
  },
  productImage: {
    height: "100%",
    maxHeight: "150px",
    width: "95%",
    maxWidth: "100px",
  },
  productNameWrapper: {
    margin: theme.spacing(0, 0, 1.5, 0),
  },
  productName: {
    maxHeight: "3.9em",
    lineHeight: "1.3em",
    letterSpacing: "0px",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3 /* number of lines to show */,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
    fontSize: "14px",
  },
  secondaryButton: {
    background: "#00733E",
    borderRadius: "10px",
    width: "49%",
    boxShadow: "none",
    height: "40px",
    border: "0px",
    marginLeft: "4px",
  },
  primaryButton: {
    borderRadius: "10px",
    width: "49%",
    boxShadow: "none",
    height: "40px",
    border: "0px",
  },
  rejectedMedia: {
    background: "#F5E9E9",
    height: "256px",
    justifyContent: "center",
    display: "flex",
    marginTop: "-20px",
  },
  rejectedleadsText: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#9D2123",
  },
  disabledButton: {
    opacity: "0.5",
    color: "white !important",
    backgroundColor: "#00733E !important",
  },
  paper: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    minHeight: "0px",
  },
}));

function LeadsReject(props) {
  const classes = useStyles();
  const [remark, setRemark] = useState(null);
  const [state, setState] = useState({ bottom: false });

  useEffect(() => {
    const leadsRejectViewedData = {
      page: `/nearby-farmers`,
      screen: "Leads Reject Screen",
      leadId: props?.leadInformation?.leadId,
      farmerId: props?.leadInformation?.farmerId,
    };
    AnalyticsService.pushEvent(
      `Leads Reject Screen Viewed`,
      leadsRejectViewedData
    );
    setState({ ...state, [props.anchor]: true });
  }, [props.state]);

  const handleSetRemark = (remark) => {
    setRemark(remark);
  };

  const handleBottomSheetSwipe = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleRejectStatus = () => {
    let data = {
      leadStatus: "rejected",
      remarks: remark,
    };

    const leadsReasonForRejectData = {
      page: `/nearby-farmers`,
      remarks: remark,
      leadStatus: "rejected",
      leadId: props?.leadInformation?.leadInformation?.leadId,
      farmerId: props?.leadInformation?.leadInformation?.farmerId,
    };
    AnalyticsService.pushEvent(
      `Leads Rejection Reason Applied`,
      leadsReasonForRejectData
    );
    props.handleLeadsBottomSheetClose();
    const { axiosRequest } = LeadService.setLeadStatus(
      props?.leadInformation?.leadInformation?.leadId,
      props?.leadInformation?.leadInformation?.farmerId,
      data
    );
    axiosRequest
      .then((response) => {
        props.handleLeadsBottomSheetClose();
      })
      .catch(() => {});
  };

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={state["bottom"]}
      onClose={handleBottomSheetSwipe("bottom", false)}
      onOpen={handleBottomSheetSwipe("bottom", true)}
      classes={{ paper: classes.paper }}
      disableSwipeToOpen={true}
    >
      <div>
        <Box
          sx={{
            width:
              props.anchor === "top" || props.anchor === "bottom"
                ? "auto"
                : 250,
          }}
          role="presentation"
        >
          <Box
            sx={{
              padding: "15px",
              paddingTop: "5px",
              paddingBottom: "0px",
              marginTop: "10px",
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    height: "6px",
                    width: "43px",
                    background: "#DDDDDD",
                    borderRadius: "19%",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Box>
          <div className={classes.rejectedMedia}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid>
                <img
                  src="/icons/sadSmilie.svg"
                  style={{ alignSelf: "center", marginLeft: "3vw" }}
                />
                <Typography className={classes.rejectedleadsText}>
                  Ohhhhhhh!!!
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Box
            sx={{
              padding: "15px",
              paddingTop: "32px",
              paddingBottom: "26px",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.name}>
                  Reason For Reject
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              paddingRight: "10px",
              paddingLeft: "30px",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                justifyContent: "center",
                display: "flex",
                height: "45px",
                alignItems: "center",
                paddingRight: "10px",
                border: "1px solid #ccc",
                borderRadius: "7px",
              }}
              onClick={() => handleSetRemark("distance")}
            >
              <Grid
                item
                xs={10}
                style={{ alignSelf: "center", paddingTop: "0px" }}
              >
                <Typography className={classes.title}>
                  Farmer lives far
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ alignSelf: "center", paddingTop: "0px" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <img
                    src={
                      remark === "distance"
                        ? "/icons/enabledCheck.svg"
                        : "/icons/disabledCheck.svg"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{
              paddingRight: "10px",
              paddingLeft: "30px",
              paddingTop: "30px",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                justifyContent: "center",
                display: "flex",
                height: "45px",
                alignItems: "center",
                paddingRight: "10px",
                border: "1px solid #ccc",
                borderRadius: "7px",
              }}
              onClick={() => handleSetRemark("noinventory")}
            >
              <Grid
                item
                xs={10}
                style={{ alignSelf: "center", paddingTop: "0px" }}
              >
                <Typography className={classes.title}>
                  Product not in stock
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ alignSelf: "center", paddingTop: "0px" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <img
                    src={
                      remark === "noinventory"
                        ? "/icons/enabledCheck.svg"
                        : "/icons/disabledCheck.svg"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              paddingRight: "10px",
              paddingLeft: "30px",
              paddingTop: "30px",
              paddingBottom: "100px",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                justifyContent: "center",
                display: "flex",
                height: "45px",
                alignItems: "center",
                paddingRight: "10px",
                border: "1px solid #ccc",
                borderRadius: "7px",
              }}
              onClick={() => handleSetRemark("notconnected")}
            >
              <Grid
                item
                xs={10}
                style={{ alignSelf: "center", paddingTop: "0px" }}
              >
                <Typography className={classes.title}>
                  Call not connected
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ alignSelf: "center", paddingTop: "0px" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <img
                    src={
                      remark === "notconnected"
                        ? "/icons/enabledCheck.svg"
                        : "/icons/disabledCheck.svg"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Button
              variant="contained"
              color={"secondary"}
              fullWidth
              disabled={remark ? false : true}
              style={{ height: "50px", borderRadius: "0px" }}
              classes={{ disabled: classes.disabledButton }}
              onClick={() => handleRejectStatus()}
            >
              Done
            </Button>
          </Box>
        </Box>
      </div>
    </SwipeableDrawer>
  );
}

export default LeadsReject;
