import Dialog from "@mui/material/Dialog";
import { Box, Button, DialogContent, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AnalyticsService from "../../Services/AnalyticsService";

function CatalogItemUnpublishedDialog(props) {
  useEffect(() => {
    if (props.open) {
      AnalyticsService.pushEvent(
        "Store Front Catalog Item Unpublished Dialog Viewed"
      );
    }
  }, [props.open]);
  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
        } else {
          props.onClose();
        }
      }}
      fullWidth={true}
      PaperProps={{
        style: { borderRadius: "32px" },
      }}
    >
      <DialogContent>
        <Grid container alignItems={`center`} justifyContent={`center`}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center", minHeight: "67px" }}>
              <img src={`/icons/starWarning.svg`} alt={`star`} />
            </Box>

            <Typography
              variant={`h2`}
              sx={{
                textAlign: "center",
                color: "#9D2123",
                marginTop: "10px",
              }}
              fontSize={18}
              fontWeight={700}
            >
              Hidden
            </Typography>

            <Typography
              variant={`body2`}
              sx={{
                textAlign: "center",
                marginTop: "10px",
                lineHeight: "18px",
              }}
              fontSize={12}
              fontWeight={400}
            >
              You have successfully hidden this product from your AgroStar
              Digital Dukaan.
            </Typography>

            <Box sx={{ textAlign: "center", marginTop: "16px" }}>
              <Button
                variant={`outlined`}
                size={"large"}
                color={"secondary"}
                sx={{
                  borderRadius: "16px",
                  background: "rgba(255, 255, 255, 1)",
                  color: "rgba(0, 115, 62, 1)",
                }}
                onClick={() => {
                  props.handleClickDone();
                }}
              >
                Done
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CatalogItemUnpublishedDialog;
