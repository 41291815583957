import config from "../config";
import HTTPService from "./HTTPService";
import _ from "lodash";
import SelfOnboardingDistricts from "../Components/OnboardPartner/SelfOnboardingDistricts";

const useMocks = false;
export default class DistrictsService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getDistrictsAutoComplete(input, state) {
    if (useMocks === false) {
      const url = `${config.backendUrl}crmservice/v1/autocomplete/?term=${input}&type=district&state=${state}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: {} });
      });
    }
  }

  static filterDistrictsByPrefix = (prefix, districts) => {
    return _.filter(districts, (district) =>
      _.startsWith(_.toLower(district), _.toLower(prefix))
    );
  };

  static getHardcodedDistrictsAutoComplete(input, state) {
    if (useMocks === false) {
      return new Promise((resolve) => {
        resolve({
          data: {
            responseData: this.filterDistrictsByPrefix(
              input,
              state && state !== ""
                ? SelfOnboardingDistricts?.[state]
                : SelfOnboardingDistricts["All States"]
            ),
          },
        });
      });
    } else {
      return new Promise((resolve) => {
        resolve({ data: {} });
      });
    }
  }
}
