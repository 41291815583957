import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AmountUtils from "../../Utils/AmountUtils";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    background: "white",
    width: "100%",
    height: "auto",
    display: "flex",
    marginBottom: "10px",
  },
  productImage: {
    height: theme.spacing(8.5),
    width: theme.spacing(6),
    margin: "10px 16px ",
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    height: "auto",
  },
  productName: {
    margin: "10px 0px",
    fontSize: "14px",
    color: "#333333",
    width: "100%",
  },
  actionButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "auto",
    fontSize: "12px",
    fontWeight: "700",
    color: "#00733E",
    display: "flex",
    margin: "10px",
  },
  price: {
    color: "#9D2123",
    fontWeight: "700",
    fontSize: "18px",
    margin: "0px 10px",
  },
  actionButtonAndPriceContainer: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    marginLeft: "auto",
    alignItems: "end",
    flexBasis: "50%",
  },
  orderCount: {
    fontSize: "14px",
  },
}));

export default function ProductWithOrderCount({ product }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const onProductClicked = () => {
    const productClicked = {
      page: "Business Insights page",
      skuCode: product.skuCode,
      productName: product.productName,
    };
    AnalyticsService.pushEvent(`Product Clicked`, productClicked);
    navigate(`/products/${product.skuCode}?from=${window.location.pathname}`);
  };

  return (
    <Box className={classes.productContainer} onClick={onProductClicked}>
      <img
        className={classes.productImage}
        src={product.productImage}
        alt={product.productName}
      />
      <Box className={classes.productInfoContainer}>
        <Typography className={classes.productName}>
          {product.productName}
        </Typography>
        <Typography className={classes.orderCount}>
          {product.orderCount} Orders
        </Typography>
      </Box>
      <Box className={classes.actionButtonAndPriceContainer}>
        <Typography className={classes.actionButton}>
          Buy Now{" "}
          <ArrowForward
            sx={{ width: "16px", height: "16px", marginLeft: "4px" }}
          />
        </Typography>
        <Typography className={classes.price}>
          ₹{AmountUtils.format(product.totalAmount)}
        </Typography>
      </Box>
    </Box>
  );
}
