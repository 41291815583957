import config from "../config";
import BannersMock from "./mocks/BannersMock";
import HTTPService from "./HTTPService";
import BrandService from "./BrandService";

const useMocks = false;
export default class BannerService {
  static getHeaders() {
    return {
      source: localStorage.getItem(`source`),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getBannersData(section, district) {
    if (useMocks === false) {
      let url = `${config.backendUrl}contentservice/v1/banners?language=en&section=${section}&scrollable=true&static=true`;
      if (district) {
        url = url + `&district=${district}`;
      }
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: BannersMock });
      });
    }
  }

  static addProductHref(bannerData) {
    return new Promise((resolve) =>
      resolve(
        `/products/${bannerData?.data?.sku}?from=${window.location.pathname}`
      )
    );
  }
  static addProductListHref(bannerData) {
    return new Promise((resolve) =>
      resolve(`/products-offer/${bannerData?.data?.skus?.join(",")}`)
    );
  }
  static addBrandHref(bannerData) {
    return new Promise((resolve, reject) =>
      BrandService.getAllBrands().then((response) => {
        if (response?.data?.responseData?.brands) {
          const currentBrand = response.data.responseData.brands.find(
            (brand) => brand.brandId === bannerData?.data?.brandId
          );
          resolve(`/brands/${currentBrand.brandName}`);
        } else {
          resolve("/brands");
        }
      })
    );
  }
  static addWebPageHref(bannerData) {
    return new Promise((resolve) => resolve(bannerData?.data?.url));
  }
  static getBannerHrefLink = {
    PRODUCT_DETAILS: this.addProductHref,
    BRAND_DETAILS: this.addBrandHref,
    PRODUCT_LIST: this.addProductListHref,
    INTERNAL_WEB_PAGE: this.addWebPageHref,
    EXTERNAL_WEB_PAGE: this.addWebPageHref,

    NO_CLICK: null,
    NO_DATA: null,
  };
}
