import axios from "axios";
import SentryService from "./SentryService";
export default class HTTPService {
  static makeXHRRequest = (
    url,
    method,
    data,
    headers,
    params,
    responseType
  ) => {
    if (!headers) {
      headers = {};
    }

    const { sentryTraceHeader, sentryBaggageHeader } =
      SentryService.getSentryHeaders();
    headers.baggage = sentryBaggageHeader;
    headers["X-Request-Id"] = sentryTraceHeader;

    const abortController = new AbortController();
    const axiosRequest = axios.request({
      method: method,
      url: url,
      data: data,
      headers: headers,
      params: params,
      responseType: responseType,
      signal: abortController.signal,
    });

    return { axiosRequest, abortController };
  };
}
