import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  ratingWrapper: { color: "white" },
  ratingNumberHigh: {
    backgroundColor: "green",
    height: theme.spacing(2),
    color: "white",
    padding: theme.spacing(0.4),
    borderRadius: theme.spacing(0.5),
  },
  ratingNumberMid: {
    backgroundColor: "orange",
    height: theme.spacing(2),
    color: "white",
    padding: theme.spacing(0.4),
    borderRadius: theme.spacing(0.5),
  },
  ratingNumberLow: {
    backgroundColor: "red",
    height: theme.spacing(2),
    color: "white",
    padding: theme.spacing(0.4),
    borderRadius: theme.spacing(0.5),
  },
}));

function StarRatingLabel(props) {
  const classes = useStyles();
  const getRatingClass = (rating) => {
    let ratingNum = Number(rating);

    if (ratingNum >= 4.0) {
      return classes.ratingNumberHigh;
    } else if (ratingNum < 4.0 && ratingNum > 2.0) {
      return classes.ratingNumberMid;
    } else {
      return classes.ratingNumberLow;
    }
  };
  return (
    <>
      {props && props.rating && props.rating !== 0 ? (
        <Grid
          container
          direction={`row`}
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.ratingWrapper}
        >
          <Grid item xs={12}>
            <Typography
              variant={`caption`}
              className={getRatingClass(props.rating)}
            >
              {props.rating} &#9733;
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ minHeight: "25px" }}></Box>
      )}
    </>
  );
}

export default StarRatingLabel;
