import React, { useEffect, useState } from "react";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";
import { Container, Grid, Skeleton } from "@mui/material";

import SectionTitle from "../Common/SectionTitle";
import ProductCard from "./ProductCard";
import ProductService from "../../Services/ProductService";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    marginTop: theme.spacing(1),
  },
  loaderContent: {},
  mainContent: {
    backgroundColor: "#F3F3F3",
  },
  loaderWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
  viewAllBrandsButtonWrapperLink: {
    textDecoration: "none",
  },
}));

function AlternateProducts(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState("not_started");
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading("started");

    const { axiosRequest, abortController } =
      ProductService.getAlternateProducts(props.skuCode);
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.productGists?.length > 0) {
          let productsFromResponse = response.data.responseData.productGists;
          productsFromResponse = _.filter(productsFromResponse, (product) => {
            return (
              product.brandName !== "Unassigned Brand" &&
              ProductService.checkIfProductEnabled(product)
            );
          });
          setProducts(productsFromResponse);
        } else {
          setProducts([]);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setProducts([]);
          setLoading("done");
        }
      });

    return () => abortController.abort();
  }, [props.skuCode]);

  return (
    <div>
      {loading === "done" &&
        (!error || !error?.data) &&
        products &&
        products.length > 0 && (
          <Container maxWidth={`xl`}>
            <SectionTitle title={`Similar Products with High Margin`} />
            <div className={classes.mainWrapper}>
              <Grid
                className={classes.mainContent}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                wrap={"nowrap"}
                spacing={1}
              >
                {products.map((product, index) => {
                  return (
                    <Grid item xs={6} key={index}>
                      <ProductCard
                        product={product}
                        key={product.skuCode}
                        index={index}
                        section={"Alternative Products"}
                        stateCode={props.stateCode}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Container>
        )}

      {loading === "started" && (
        <>
          <Skeleton
            variant={`text`}
            width={300}
            style={{ marginLeft: "16px", marginTop: "8px" }}
          />
          <div className={classes.loaderWrapper}>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.loaderContent}
              wrap={"nowrap"}
              spacing={2}
            >
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default AlternateProducts;
