import config from "../config";
import ReferralMock from "./mocks/ReferralMock";
import Languages from "./LanguageCode";
import HTTPService from "./HTTPService";
const useMocks = false;
export default class ReferralService {
  static referrers = ["None", "Sales Officer" /*"Other Retailer", "Farmer"*/];

  static ReferralStatus = {
    "REGISTRATION DONE": "Registration Done",
    "DEPOSIT PAID": "Deposit Paid",
    "FIRST PURCHASE DONE": "First Purchase Done",
    "REWARD UNLOCKED": "Reward Unlocked",
  };

  static WhatsappReferralImageLink =
    "https://static.agrostar.in/saathi/saathi-referral/saathi-referral-banner-";

  static ReferralBannerImageLink =
    "https://static.agrostar.in/saathi/saathi-referral/how-referral-works-";

  static getWhatsappReferralImage() {
    const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
      ? Languages[localStorage.getItem("state")?.toUpperCase()]
      : "en";

    return `${this.WhatsappReferralImageLink}${languageCode}.jpg`;
  }

  static getReferralHeroImage() {
    const source = localStorage.getItem("source");
    const suffix = source?.slice(3)?.toLowerCase() || "en";

    if (suffix === "en") {
      return "https://static.agrostar.in/saathi/saathi-referral/how-referral-works-en.jpg";
    }

    return `${this.ReferralBannerImageLink}${suffix}.jpg`;
  }

  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getReferralCodeByFarmerId() {
    const url = `${
      config.backendUrl
    }referralservice/referralcode/?farmerId=${localStorage.getItem(
      "farmerId"
    )}`;
    if (useMocks === false) {
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ReferralMock });
      });
    }
  }

  static getSuccessfulReferralsByFarmerId() {
    const url = `${
      config.backendUrl
    }referralservice/referraltransactiondetails/?farmerID=${localStorage.getItem(
      "farmerId"
    )}`;
    if (useMocks === false) {
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ReferralMock });
      });
    }
  }
}
