import _ from "lodash";

import States from "./States";

export default class StateService {
  static getStateByName(stateName) {
    return _.filter(States, (state) => {
      return state.stateName.toLowerCase() === stateName.toLowerCase();
    })?.[0];
  }

  static getUserStateCode() {
    let stateName = localStorage.getItem("stateName");
    if (this.getStateByName(stateName)) {
      return this.getStateByName(stateName).stateCode;
    }
  }
}
