import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { Button, Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import SectionTitle from "../Common/SectionTitle";
import ViewAllButton from "../Common/ViewAllButton";
import EmptyError from "../EmptyError/EmptyError";
import BrandsLoader from "./BrandsLoader";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#D8D8D8",
    border: "1px solid #FFFFFF",
    boxShadow: "0px 10px 10px #E0E0E0",
    borderRadius: "20px",
    paddingTop: "10px",
    paddingBottom: "15px",
  },
  brandsListWrapper: {
    marginTop: theme.spacing(0),
  },
  brandButton: {
    height: theme.spacing(10),
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1.25),
    border: "none",
    "&:focus": {
      backgroundColor: `#FFFFFF`,
    },
    "&:hover": {
      backgroundColor: `#FFFFFF`,
    },
  },
  brandImage: {
    maxHeight: theme.spacing(9),
    maxWidth: theme.spacing(10),
  },
  viewAllBrandsButtonWrapperLink: {
    textDecoration: "none",
  },
}));

function BrandsList(props) {
  const classes = useStyles();

  const handleClickBrand = (brandName, index) => {
    const brandClickedEventData = {
      page: props.page,
      brandName: brandName || "",
      position: index + 1 || "",
    };

    AnalyticsService.pushEvent(`Brand Clicked`, brandClickedEventData);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth={`xl`}>
        <SectionTitle title={`Shop by Brands`} />
        {props.brands && props.brands.length > 0 ? (
          <>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              className={classes.brandsListWrapper}
            >
              {_.map(
                props.brands.slice(
                  0,
                  props.numberOfBrandsToShow
                    ? props.numberOfBrandsToShow
                    : props.brands.length
                ),
                (brand, index) => {
                  return (
                    <Grid item xs={4} key={index}>
                      <Link
                        to={
                          props.categoryName
                            ? `/categories/${props.categoryName}/brands/${brand.brandName}`
                            : `/brands/${brand.brandName}`
                        }
                        onClick={() => handleClickBrand(brand.brandName, index)}
                      >
                        <Button
                          variant={"outlined"}
                          fullWidth
                          className={classes.brandButton}
                        >
                          <img
                            src={
                              brand.brandImage
                                ? brand.brandImage
                                : `/icons/dummy.jpeg`
                            }
                            alt={brand.brandName}
                            className={classes.brandImage}
                          />
                        </Button>
                      </Link>
                    </Grid>
                  );
                }
              )}
            </Grid>
            {props.brands &&
              props.brands.length > props.numberOfBrandsToShow && (
                <Link
                  to={
                    props.categoryName
                      ? `/categories/${props.categoryName}/brands`
                      : `/brands`
                  }
                  className={classes.viewAllBrandsButtonWrapperLink}
                >
                  <ViewAllButton section={"Brand List"} />
                </Link>
              )}
          </>
        ) : (
          props.loading === "done" && (
            <EmptyError image="EmptyPage.svg" text="Sorry! No brands here" />
          )
        )}
        {props.loading === "started" && <BrandsLoader />}
      </Container>
    </div>
  );
}

export default BrandsList;
