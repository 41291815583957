import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductService from "../../Services/ProductService";

const CurrentProgressDescription = (props) => {
  let navigate = useNavigate();
  const differenceToNextSlabTargetAdjustedToMoq = props?.deltaPromptQuantity;

  const handleClickSchemeNudge = () => {
    if (props?.nudgeClickable) {
      AnalyticsService.pushEvent(`Scheme Nudge Clicked`, {
        page: props?.page,
        schemeName: props?.turnoverScheme?.name,
        schemeId: props?.schemeId,
        schemeAchievement:
          props?.turnoverScheme?.netValue >= 0
            ? props?.slabConstraintUnit === `₹`
              ? `${props.slabConstraintUnit} ${new Intl.NumberFormat(
                  "en-IN"
                ).format(props?.turnoverScheme?.netValue)}`
              : `${props?.turnoverScheme?.netValue} ${props?.slabConstraintUnit}`
            : ``,
      });
      if (props?.shopNowUrl) {
        navigate(props?.shopNowUrl);
      }
    }
  };

  const generateCurrentProgressDescription = () => {
    return (
      <>
        {props?.slabConstraintUnit === `₹` ? (
          <Typography
            color={`secondary`}
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              textAlign: "center",
              background: "rgba(248,209,73,0.3)",
              padding: "3px 8px",
              borderRadius: "10px",
              letterSpacing: "0.01px",
            }}
          >
            {props?.isFeaturedSchemes ? (
              <b>
                {`Shop for ${props?.slabConstraintUnit}${
                  differenceToNextSlabTargetAdjustedToMoq >= 10000000
                    ? `${ProductService.roundFloatsToNdecimalPlaces(
                        differenceToNextSlabTargetAdjustedToMoq / 10000000,
                        2
                      )} Cr`
                    : differenceToNextSlabTargetAdjustedToMoq >= 100000
                      ? `${ProductService.roundFloatsToNdecimalPlaces(
                          differenceToNextSlabTargetAdjustedToMoq / 100000,
                          2
                        )} lacs`
                      : `${new Intl.NumberFormat("en-IN").format(
                          Math.round(differenceToNextSlabTargetAdjustedToMoq)
                        )}`
                } ${props?.turnoverScheme?.netValue > 0 ? `more` : ``}`}
              </b>
            ) : (
              <b>
                {`Shop for ${
                  differenceToNextSlabTargetAdjustedToMoq >= 10000000
                    ? `${ProductService.roundFloatsToNdecimalPlaces(
                        differenceToNextSlabTargetAdjustedToMoq / 10000000,
                        2
                      )} Cr`
                    : differenceToNextSlabTargetAdjustedToMoq >= 100000
                      ? `${ProductService.roundFloatsToNdecimalPlaces(
                          differenceToNextSlabTargetAdjustedToMoq / 100000,
                          2
                        )} lacs`
                      : `${new Intl.NumberFormat("en-IN").format(
                          Math.round(differenceToNextSlabTargetAdjustedToMoq)
                        )}`
                } Qty ${props?.turnoverScheme?.netValue > 0 ? `more` : ``}`}
              </b>
            )}
            {` and get`}{" "}
            {
              <b>
                {props.generateEntitlementDescription(
                  props?.nextTargetEntitlement
                )}
              </b>
            }
          </Typography>
        ) : (
          <Typography
            color={`secondary`}
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              textAlign: "center",
              background: "rgba(248,209,73,0.3)",
              padding: "3px 8px",
              borderRadius: "10px",
              letterSpacing: "0.01px",
            }}
          >
            {
              <b>
                {`Shop for ${Math.round(
                  differenceToNextSlabTargetAdjustedToMoq
                )} ${
                  props?.isFeaturedSchemes ? props?.slabConstraintUnit : `Qty`
                } ${props?.turnoverScheme?.netValue > 0 ? `more` : ``}`}
              </b>
            }
            {` and get`}{" "}
            {
              <b>
                {props.generateEntitlementDescription(
                  props?.nextTargetEntitlement
                )}
              </b>
            }
          </Typography>
        )}
      </>
    );
  };

  const generateCurrentProgressReverseDescription = () => {
    return (
      <>
        {props?.slabConstraintUnit === `₹` ? (
          <Typography
            color={`secondary`}
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              textAlign: "center",
              background: "rgba(248,209,73,0.3)",
              padding: "3px 8px",
              borderRadius: "10px",
              letterSpacing: "0.01px",
            }}
          >
            {`Unlock `}
            {props.generateEntitlementDescription(props?.nextTargetEntitlement)}
            {` by reaching your goal, `}
            {props?.isFeaturedSchemes ? (
              <b>
                {`Shop for ${props?.slabConstraintUnit}${new Intl.NumberFormat(
                  "en-IN"
                ).format(
                  Math.round(differenceToNextSlabTargetAdjustedToMoq)
                )} ${props?.turnoverScheme?.netValue > 0 ? `more` : ``}`}
              </b>
            ) : (
              <b>
                {`Shop for ${new Intl.NumberFormat("en-IN").format(
                  Math.round(differenceToNextSlabTargetAdjustedToMoq)
                )} Qty ${props?.turnoverScheme?.netValue > 0 ? `more` : ``}`}
              </b>
            )}
          </Typography>
        ) : (
          <Typography
            color={`secondary`}
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              textAlign: "center",
              background: "rgba(248,209,73,0.3)",
              padding: "3px 8px",
              borderRadius: "10px",
              letterSpacing: "0.01px",
            }}
          >
            {`Unlock `}
            {props.generateEntitlementDescription(props?.nextTargetEntitlement)}
            {` by reaching your goal, `}
            {
              <b>
                {`Shop for ${Math.round(
                  differenceToNextSlabTargetAdjustedToMoq
                )} ${
                  props?.isFeaturedSchemes ? props?.slabConstraintUnit : `Qty`
                } ${props?.turnoverScheme?.netValue > 0 ? `more` : ``}`}
              </b>
            }
          </Typography>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        padding: "0px 8px",
        position: `${props?.reverseDescriptionFormat ? `relative` : ``}`,
        cursor: `${props?.nudgeClickable ? `pointer` : ``}`,
      }}
      onClick={handleClickSchemeNudge}
    >
      {props?.turnoverScheme?.netValue >= props?.finalSlabTarget ? (
        <Typography
          color={`secondary`}
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16px",
            textAlign: "center",
            background: "rgba(248,209,73,0.3)",
            padding: "3px 8px",
            borderRadius: "10px",
          }}
        >
          {`Congratulations !! You won ${props?.generateEntitlementDescription(
            props?.lastSlabAchieved?.entitlements?.entitlementList
          )}`}
        </Typography>
      ) : (
        <>
          {props?.turnoverScheme?.schemeStatus === "completed" &&
            (props?.lastSlabAchieved ? (
              <Typography
                color={`secondary`}
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  textAlign: "center",
                  background: "rgba(248,209,73,0.3)",
                  padding: "3px 8px",
                  borderRadius: "10px",
                }}
              >
                {`Congratulations !! You won ${props?.generateEntitlementDescription(
                  props?.lastSlabAchieved?.entitlements?.entitlementList
                )}`}
              </Typography>
            ) : (
              <Typography sx={{ height: "22px" }}>&nbsp;</Typography>
            ))}

          {(props?.turnoverScheme?.schemeStatus === "active" ||
            !props?.turnoverScheme?.schemeStatus) &&
            (props?.reverseDescriptionFormat
              ? generateCurrentProgressReverseDescription()
              : generateCurrentProgressDescription())}
        </>
      )}
    </Box>
  );
};

export default CurrentProgressDescription;
