import { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { WhatsappShareButton } from "react-share";
import AndroidService from "../../Services/AndroidService";
import useMessages from "../../Hooks/useMessages";
import AnalyticsService from "../../Services/AnalyticsService";

const StoryShareButton = ({ story, setStoryPaused }) => {
  const { getMessage } = useMessages();
  const [device, setDevice] = useState("web");
  const whatsappMessage =
    getMessage("story.share") +
    (story.type === "video" ? "\r\n" + story.url : "");
  const image = story.type === "image" ? story.url : null;

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (appVersionCode && parseInt(appVersionCode) > 6) {
          setDevice("android");
        } else {
          setDevice("web");
        }
      } else {
        setDevice("web");
      }
    }
  }, []);

  const handleAndroidJSBridgeWhatsappShare = () => {
    // setStoryPaused(true);
    try {
      /*global Android*/
      if (Android) {
        Android.shareOnWhatsApp(whatsappMessage, image);
      }
    } catch (e) {
      console.log(e);
    }
    handlePromoteOnWhatsappClickEvent();
  };

  const handlePromoteOnWhatsappClickEvent = () => {
    // setStoryPaused(true);
    const staticBannerClickedEventData = {
      section: story.section,
      name: story.name,
      imageUrl: story.imageUrl,
      position: story.position,
      subPosition: story.subPosition,
    };
    AnalyticsService.pushEvent(
      `Share Button Clicked`,
      staticBannerClickedEventData
    );
  };

  if ((story.type === "image" && device === `web`) || story.type === "quiz") {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          bottom: 15,
          right: 7,
          visibility: "hidden",
        }}
      >
        {story.type === "video" ? (
          <WhatsappShareButton
            url={`.`}
            title={whatsappMessage}
            onClick={handlePromoteOnWhatsappClickEvent}
          >
            <img
              alt="Share"
              src="/icons/videoShareIcon.svg"
              style={{ height: "55px" }}
            />
          </WhatsappShareButton>
        ) : (
          <IconButton
            variant="contained"
            color="secondary"
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
            }}
            onClick={handleAndroidJSBridgeWhatsappShare}
          >
            <img
              alt="Share"
              src="/icons/videoShareIcon.svg"
              style={{ height: "55px" }}
            />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default StoryShareButton;
