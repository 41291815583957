import React, { useState, useEffect } from "react";
import BlockUi from "react-block-ui";
import { useNavigate } from "react-router-dom";
import "react-block-ui/style.css";
import _ from "lodash";

import {
  getTime,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  format,
} from "date-fns";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { green } from "@mui/material/colors";
import {
  FormControlLabel,
  Checkbox,
  Container,
  Card,
  InputLabel,
  NativeSelect,
  Button,
  Grid,
  FormControl,
  Typography,
  ButtonBase,
  MenuItem,
  Select,
  Chip,
  Stack,
  Box,
} from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";
import AndroidService from "../../Services/AndroidService";
import HisaabService from "../../Services/HisaabService";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";
import TransactionDetailsCards from "./TransactionDetailsCard";
import DropdownSelector from "../Common/DropdownSelector";
import ChipSelector from "../Common/ChipSelector";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 0),
    marginBottom: "95px",
  },
  debit: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(0.5, 0),
    background: "rgba(157, 33, 35, 0.2)",
    color: "#9D2123",
  },
  credit: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(0.5, 0),
    background: "rgba(0, 115, 62, 0.2)",
    color: "#00733E",
  },
  details: {
    margin: theme.spacing(1.5, 0, 1, 0),
    padding: theme.spacing(1),
    borderTop: "1px solid #eeeeee",
  },
  checkboxLabel: {
    background: "white",
    padding: "2px",
    borderRadius: theme.spacing(0.6),
    margin: theme.spacing(0.5),
  },
  blockUI: {
    height: "90vh",
  },
  gridContainer: {
    padding: "0px !important",
    marginBottom: theme.spacing(1),
  },
  gridFilterItem: {
    margin: theme.spacing(0, 0.5, 0, -1),
  },
  filterCard: {
    paddingLeft: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    height: "30px",
    marginTop: "3px",
    paddingRight: "2px",
    width: "50%",
    marginLeft: "5px",
  },
  pdfButton: {
    marginTop: "5px",
    marginBottom: "4px",
    textTransform: "capitalize",
  },
}));

const GreenCheckbox = withStyles({
  root: {
    margin: "0px",
    padding: "0px",
    color: "#999999",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Transactions() {
  const classes = useStyles();
  let navigate = useNavigate();

  const [transactionDetails, setTransactionDetails] = useState({});
  const [filteredList, setFilteredList] = useState({});
  const [isCreditSelected, setIsCreditSelected] = useState(true);
  const [isDebitSelected, setIsDebitSelected] = useState(true);
  const [selectedAllValue, setSelectedAllValue] = useState("allTime");
  const [selectedReason, setSelectedReason] = useState("type");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [loading, setLoading] = useState("not_started");
  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);
  const [reasonList, setReasonList] = useState([]);
  const [expandReasonSelector, setExpandedReasonSelector] = useState(false);
  const [hisaabStatementFromDate, setHisaabPdfFromDate] = useState();
  const [hisaabStatementToDate, setHisaabPdfToDate] = useState();

  const timeDurationFilterList = [
    {
      label: "All Time",
      value: "allTime",
    },
    {
      label: "This Month",
      value: "thisMonth",
    },
    {
      label: "Last Month",
      value: "lastMonth",
    },
    {
      label: "Last 3 Months",
      value: "lastThreeMonths",
    },
    {
      label: "Last 12 Months",
      value: "lastYear",
    },
    {
      label: "This Year",
      value: "thisYear",
    },
  ];

  useEffect(() => {
    const hissabTabEventData = {
      body: {
        page: "transaction",
      },
      name: "Transaction Tab Viewed",
    };

    AnalyticsService.pushEvent(
      hissabTabEventData.name,
      hissabTabEventData.body
    );
  }, []);

  useEffect(() => {
    if (!userId || !localStorage.getItem("userId")) {
      setLoading("started");
      const { axiosRequest, abortController } =
        PartnerService.getPartnerDetailedData(localStorage.getItem(`farmerId`));
      axiosRequest
        .then((response) => {
          localStorage.setItem("userId", response?.data?.responseData?.userId);
          setUserId(response?.data?.responseData?.userId);
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoading("done");
            console.log(`error getting farmer details`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
          }
        });
      return () => abortController.abort();
    }
  }, [userId]);

  useEffect(() => {
    setLoading("started");
    const reason = selectedReason === "type" ? "" : selectedReason;

    const { axiosRequest, abortController } =
      HisaabService.getWallettransactions(userId, fromDate, toDate, reason);
    axiosRequest
      .then((response) => {
        setTransactionDetails(response?.data?.responseData);
        setFilteredList(response?.data?.responseData);

        // TODO : Later move this to backend
        let updatedReasonList = [{ label: "Type", value: "type" }];
        _.forEach(response?.data?.responseData?.reasons, (reasonName) => {
          if (reasonName === "Initial") {
            updatedReasonList.push({
              label: "Credit Limit",
              value: "Initial",
            });
          } else if (reasonName === "Real Cash") {
            updatedReasonList.push({ label: "Advance", value: "Real Cash" });
          } else if (reasonName === "Manual") {
            updatedReasonList.push({ label: "Others", value: "Manual" });
          } else {
            updatedReasonList.push({ label: reasonName, value: reasonName });
          }
        });
        setReasonList(updatedReasonList);
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.log(`error getting transaction details`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
        }
      });
    return () => abortController.abort();
  }, [fromDate, toDate, userId, selectedReason]);

  useEffect(() => {
    if (
      transactionDetails &&
      transactionDetails?.transactions &&
      transactionDetails?.transactions?.length
    ) {
      setLoading("started");
      const list = transactionDetails?.transactions.filter((item) => {
        if (!isCreditSelected && isDebitSelected) {
          return item.transactionType === "debit";
        } else if (isCreditSelected && !isDebitSelected) {
          return item.transactionType === "credit";
        } else if (isCreditSelected && isDebitSelected) {
          return (
            item.transactionType === "credit" ||
            item.transactionType === "debit"
          );
        } else {
          return true;
        }
      });

      const details = {
        userId: transactionDetails?.userId,
        transactions: list,
        totalTransactions: list.length,
      };
      setFilteredList(details);
      setLoading("done");
    }
  }, [isCreditSelected, isDebitSelected, transactionDetails]);

  useEffect(() => {
    if (
      selectedAllValue !== "allTime" &&
      fromDate &&
      toDate &&
      filteredList?.transactions?.length > 0
    ) {
      setHisaabPdfFromDate(fromDate);
      setHisaabPdfToDate(toDate);
    }

    if (
      selectedAllValue === "allTime" &&
      !fromDate &&
      !toDate &&
      filteredList?.transactions?.length > 0
    ) {
      setHisaabPdfFromDate(
        filteredList?.transactions[filteredList.transactions.length - 1]
          ?.transactionTimestamp
      );
      setHisaabPdfToDate(getTime(new Date()));
    }
  }, [selectedAllValue, fromDate, toDate, filteredList]);

  const handleChangeTimeDurationFilter = (event) => {
    if (event.target.value) {
      AnalyticsService.pushEvent(`Filter Applied`, {
        page: "Transactions",
        status: event.target.value ? "Active" : "Inactive",
        filterName: "date",
        filterApplied: event.target.value ? "Applied" : "Removed",
        filterOption: event.target.value,
      });
    }

    setSelectedAllValue(event.target.value);
    if (event.target.value === "allTime") {
      setFromDate("");
      setToDate("");
    }

    if (event.target.value === "thisMonth") {
      const fromDate = getTime(startOfMonth(new Date()));
      const toDate = getTime(new Date());

      setFromDate(fromDate);
      setToDate(toDate);
    }
    if (event.target.value === "lastMonth") {
      const fromDate = getTime(startOfMonth(subMonths(new Date(), 1)));
      const toDate = getTime(endOfMonth(subMonths(new Date(), 1)));

      setFromDate(fromDate);
      setToDate(toDate);
    }
    if (event.target.value === "lastThreeMonths") {
      const fromDate = getTime(startOfMonth(subMonths(new Date(), 3)));
      const toDate = getTime(endOfMonth(subMonths(new Date(), 1)));

      setFromDate(fromDate);
      setToDate(toDate);
    }
    if (event.target.value === "thisYear") {
      const fromDate = getTime(startOfYear(new Date()));
      const toDate = getTime(new Date());

      setFromDate(fromDate);
      setToDate(toDate);
    }
    if (event.target.value === "lastYear") {
      const fromDate = getTime(startOfMonth(subMonths(new Date(), 12)));
      const toDate = getTime(endOfMonth(subMonths(new Date(), 1)));

      setFromDate(fromDate);
      setToDate(toDate);
    }
  };

  const handleClickPDF = () => {
    AnalyticsService.pushEvent(`Transactions PDF Clicked`, {
      startDate: hisaabStatementFromDate,
      endDate: hisaabStatementToDate,
    });
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (appVersionCode && parseInt(appVersionCode) < 5) {
          setOpenAppUpdateDialog(true);
        } else {
          navigate({
            pathname: `/hisaab/transactions/download`,
            search: `?startDate=${format(
              hisaabStatementFromDate,
              "dd-MMM-yyyy"
            )}&endDate=${format(hisaabStatementToDate, "dd-MMM-yyyy")}`,
          });
        }
      } else {
        setOpenAppUpdateDialog(true);
      }
    } else {
      navigate({
        pathname: `/hisaab/transactions/download`,
        search: `?startDate=${format(
          hisaabStatementFromDate,
          "dd-MMM-yyyy"
        )}&endDate=${format(hisaabStatementToDate, "dd-MMM-yyyy")}`,
      });
    }
  };

  const handleChangeReason = (event) => {
    setSelectedReason(event.target.value);
    if (event.target.value) {
      AnalyticsService.pushEvent(`Filter Applied`, {
        page: "Transactions",
        status: event.target.value ? "Active" : "Inactive",
        filterName: "type",
        filterApplied: event.target.value ? "Applied" : "Removed",
        filterOption: event.target.value,
      });
    }
  };

  const handleClearFilters = () => {
    AnalyticsService.pushEvent(`Clear All Filters Clicked`, {
      page: "Transactions",
    });

    setSelectedAllValue("allTime");
    setSelectedReason("type");
    setIsCreditSelected(false);
    setIsDebitSelected(false);
    setFromDate("");
    setToDate("");
  };

  const handleChangeCreditSelection = () => {
    let updatedStatus = !isCreditSelected;
    setIsCreditSelected((isCreditSelected) => !isCreditSelected);

    AnalyticsService.pushEvent(`Filter Clicked`, {
      page: "Transactions",
      status: updatedStatus ? "Active" : "Inactive",
      filterName: "credit",
      filterApplied: updatedStatus ? "Applied" : "Removed",
    });
  };

  const handleChangeDebitSelection = () => {
    let updatedStatus = !isDebitSelected;
    setIsDebitSelected((isDebitSelected) => !isDebitSelected);

    AnalyticsService.pushEvent(`Filter Clicked`, {
      page: "Transactions",
      status: updatedStatus ? "Active" : "Inactive",
      filterName: "debit",
      filterApplied: updatedStatus ? "Applied" : "Removed",
    });
  };

  return (
    <Grid>
      <Container maxWidth={`xl`} className={classes.container}>
        <BlockUi
          tag="div"
          blocking={loading === "started" && loading !== "done"}
          message={`Loading...`}
          keepInView
          renderChildren={false}
          className={classes.blockUI}
        >
          <div>
            <AppVersionUnsupported
              open={openAppUpdateDialog}
              handleClose={() => {
                setOpenAppUpdateDialog(false);
              }}
            />
            <ErrorBoundary message={""}>
              <Grid
                justifyContent={"flex-start"}
                alignItems={"center"}
                flex
                className={classes.gridContainer}
              >
                <Box
                  sx={{
                    width: "100%",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    height: "50px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <ChipSelector
                    isFilterSelected={isCreditSelected}
                    handleChangeSelectedValue={handleChangeCreditSelection}
                    keyName={"Credited"}
                  />

                  <ChipSelector
                    isFilterSelected={isDebitSelected}
                    handleChangeSelectedValue={handleChangeDebitSelection}
                    keyName={"Debited"}
                  />

                  <DropdownSelector
                    selectedAllFilterValue={selectedAllValue}
                    handleChangeSelectedValue={handleChangeTimeDurationFilter}
                    menuList={timeDurationFilterList}
                    inputLabelText={"All Time"}
                  />

                  <DropdownSelector
                    selectedAllFilterValue={selectedReason}
                    handleChangeSelectedValue={handleChangeReason}
                    menuList={reasonList}
                    inputLabelText={"Type"}
                  />
                </Box>
                <Grid
                  container
                  direction={"row"}
                  sx={{ textAlign: "center", marginTop: "-10px" }}
                >
                  <Grid item xs={7}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.pdfButton}
                      onClick={() => handleClickPDF()}
                    >
                      Download Statement
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#9D2123",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                      onClick={handleClearFilters}
                    >
                      {" "}
                      Clear All Filters
                    </Typography>{" "}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {selectedAllValue !== "allTime" && fromDate && toDate && (
                    <Typography
                      variant={`body2`}
                      style={{ textAlign: "center" }}
                    >
                      From {format(fromDate, "dd/MM/yyyy")} - To{" "}
                      {format(toDate, "dd/MM/yyyy")}
                    </Typography>
                  )}
                  {selectedAllValue === "allTime" &&
                    !fromDate &&
                    !toDate &&
                    filteredList?.transactions?.length > 0 && (
                      <Typography variant={`body2`}>
                        From{" "}
                        {format(
                          filteredList?.transactions[
                            filteredList.transactions.length - 1
                          ]?.transactionTimestamp,
                          "dd/MM/yyyy"
                        )}{" "}
                        - To {format(new Date(), "dd/MM/yyyy")}
                      </Typography>
                    )}
                </Grid>
              </Grid>
            </ErrorBoundary>

            <ErrorBoundary>
              <TransactionDetailsCards transactionList={filteredList} />
            </ErrorBoundary>
          </div>
        </BlockUi>
      </Container>
    </Grid>
  );
}

export default Transactions;
