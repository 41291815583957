const OKR_EXECUTIVES = [
  { key: "salesManager", value: "Sales Manager", type: "call" },
  { key: "territoryManager", value: "Territory Manager", type: "call" },
  {
    key: "centralSalesTeam",
    value: "Relationship Manager",
    type: "call",
  },
  { key: "clusterManager", value: "Cluster Manager", type: "request_call" },
  { key: "stateHead", value: "State Head", type: "request_call" },
  {
    key: "relationshipManager",
    value: "Saathi Service Manager",
    type: "request_call",
  },
];
export default OKR_EXECUTIVES;
