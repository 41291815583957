import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import OrderService from "../../Services/OrderService";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import EastIcon from "@mui/icons-material/East";

export default function OrderCancelReasonSelect({
  open,
  setOpen,
  selectedCancelReason,
  setSelectedCancelReason,
  handleCancelOrder,
  loadingCancelReason,
  setLoadingCancelReason,
}) {
  const [cancellationReasons, setCancellationReasons] = useState(null);
  const [errorCancellationReasons, setErrorCancellationReasons] =
    useState(false);

  useEffect(() => {
    if (open) {
      AnalyticsService.pushEvent("Dialog Shown", {
        type: "Cancellation Reason",
      });

      setLoadingCancelReason("started");
      const { axiosRequest, abortController } =
        OrderService.getOrderCancellationReasons();
      axiosRequest
        .then((response) => {
          setLoadingCancelReason("done");
          if (response?.data?.responseData) {
            setCancellationReasons(response?.data?.responseData);
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoadingCancelReason("done");
            setErrorCancellationReasons(true);
            console.error(`Error Getting Cancellation Reasons: `, error);
          }
        });
      return () => abortController.abort();
    }
  }, [open]);

  return (
    <SwipeableDrawer
      anchor={`bottom`}
      open={open}
      onClose={() => {
        setSelectedCancelReason(null);
        setOpen(false);
      }}
      onOpen={() => {}}
      PaperProps={{
        style: {
          borderRadius: "24px 24px 0px 0px",
          overflowY: "hidden",
        },
      }}
      disableDiscovery={true}
      disableSwipeToOpen={true}
    >
      <Box
        sx={{
          position: "sticky",
          top: "0",
          zIndex: 100,
          background: "#FFFFFF",
          paddingTop: "16px",
        }}
      >
        <Grid
          container
          alignItems={`center`}
          justifyContent={`space-between`}
          sx={{ padding: "0px 20px 16px 20px" }}
        >
          <Typography
            sx={{
              color: "#333333",
              fontSize: "18px",
              fontWeight: "700",
            }}
          >
            {`Cancellation Reason`}
          </Typography>
          <CloseIcon
            onClick={() => {
              setSelectedCancelReason(null);
              setOpen(false);
            }}
            sx={{ color: "#999999", fontSize: "28px", cursor: "pointer" }}
          />
        </Grid>
        <Divider />
      </Box>

      <BlockUi
        tag="div"
        blocking={loadingCancelReason === "started"}
        message={`Please wait`}
        keepInView
      >
        <Box
          sx={{
            background: "#F3F3F3",
            paddingBottom: "80px",
            height: "70vh",
            overflowY: "scroll",
          }}
        >
          {loadingCancelReason === "done" && cancellationReasons?.length > 0 ? (
            <FormControl fullWidth sx={{ padding: "0px 12px" }}>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
              >
                {_.map(cancellationReasons, (cancelReason, index) => (
                  <FormControlLabel
                    labelPlacement="start"
                    value={cancelReason?.reason}
                    control={<Radio />}
                    label={cancelReason?.reason}
                    key={index}
                    onChange={(event) => {
                      AnalyticsService.pushEvent("Button Clicked", {
                        type: "Cancellation Reason",
                        label: event?.target?.value,
                      });
                      setSelectedCancelReason(cancelReason);
                    }}
                    sx={{
                      margin: "0px",
                      justifyContent: "space-between",
                      color: "#4F4F4F",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px",
                        fontWeight: 700,
                        paddingLeft: "8px",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : loadingCancelReason === "done" && errorCancellationReasons ? (
            <Typography
              color={"primary"}
              sx={{ padding: 2, paddingTop: 8, textAlign: "center" }}
            >
              Something went wrong. Please try again.
            </Typography>
          ) : (
            <></>
          )}
        </Box>

        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{
            borderRadius: 0,
            padding: "14px 22px",
            position: "fixed",
            bottom: 0,
            maxWidth: "600px",
            background: `${selectedCancelReason ? "#9D2123" : "#8C8C8C"}`,
            "&:hover": {
              backgroundColor: `${selectedCancelReason ? "#9D2123" : "#8C8C8C"}`,
            },
          }}
          endIcon={<EastIcon />}
          onClick={() => {
            AnalyticsService.pushEvent("Button Clicked", {
              type: "Cancel Order with Reason",
              reason: selectedCancelReason,
            });
            handleCancelOrder();
          }}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
            Cancel Order
          </Typography>
        </Button>
      </BlockUi>
    </SwipeableDrawer>
  );
}
