import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";

import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";

import ErrorBoundary from "../Common/ErrorBoundary";
import OfferEntitlements from "../Common/OfferEntitlements";
import AnalyticsService from "../../Services/AnalyticsService";
import AmountUtils from "../../Utils/AmountUtils";
import CreditPeriod from "../Product/CreditPeriod";
import AddRemoveBagItem from "./AddRemoveBagItem";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  priceSection: {
    margin: theme.spacing(1, 0, 1.5, -0.5),
  },
  price: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    float: "right",
    paddingRight: theme.spacing(2),
  },
  productCard: {
    border: "none",
    marginTop: theme.spacing(1.25),
  },
  productCardContent: {
    padding: theme.spacing(2, 1, 0.5, 1),
  },
  productCardContentError: {
    padding: theme.spacing(2, 1, 0.5, 1),
    backgroundColor: "#EADEDE",
  },
  productImage: {
    height: "100%",
    width: "80px",
  },
  removeIconWrapper: {
    color: "#999999",
    padding: theme.spacing(0),
    height: theme.spacing(3),
  },
  productName: {
    lineHeight: `1.3`,
  },

  pricePerQuantity: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#666666",
    textAlign: "left",
    width: "100%",
  },
  productMrp: {
    color: "#666666",
    textDecoration: "line-through",
    marginRight: theme.spacing(0.2),
  },
  productSellingPrice: {
    color: "#00733E",
  },
  productCreditPeriod: {
    fontSize: "12px",
    lineHeight: "10px",
    color: "#666666",
  },
  productOfferSection: {
    height: "14px",
  },
  productOfferSectionError: {
    height: "14px",
    backgroundColor: "#EADEDE",
  },
  fieldsetStyle: {
    borderTop: "0.5px dashed #9D2123",
    borderRight: "none",
    borderBottom: "none",
    borderLeft: "none",
    color: "#9D2123",
    backgroundColor: "#F2F8F5",
  },
  fieldsetStyleError: {
    borderTop: "0.5px dashed #9D2123",
    borderRight: "none",
    borderBottom: "none",
    borderLeft: "none",
    color: "#9D2123",
    backgroundColor: "#DED9D7",
  },
  offers: {
    backgroundColor: "#F2F8F5",
  },
  offersError: {
    backgroundColor: "#DED9D7",
  },
  offerName: {
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    padding: "14px 16px 8px 16px",
    borderBottom: "1px solid #0000001A",
  },
  offersIcon: {
    marginRight: theme.spacing(1),
  },
  bestPriceText: {
    color: "#00733E",
    fontSize: "14px",
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0, 2),
  },
  OfferAvailable: {
    color: "#666666",
    lineHeight: "2.5",
    padding: theme.spacing(0, 2),
  },
  entitlement: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 2),
  },
  errorContainer: {
    backgroundColor: "#ebd2d3",
    padding: "4px 8px",
    border: "none",
    fontWeight: 400,
    color: "black",
    fontSize: "14px",
    borderRadius: 0,
  },
}));

function BagItems(props) {
  const classes = useStyles();

  const handleProductClick = (product, index, event) => {
    const productClickedEventData = {
      page: "Cart Page",
      position: index + 1,
      listPrice: product?.listPrice || 0,
      sellingPrice: product?.sellingPrice || 0,
      isOfferAvailable:
        product?.offers && Object.entries(product?.offers).length > 0
          ? true
          : false,
      productName: product?.productName || "",
      skuCode: product?.skuCode || "",
    };

    AnalyticsService.pushEvent(`Cart Product Clicked`, productClickedEventData);
  };
  const isFieldAgent = localStorage.getItem("role") === "fieldAgent";

  return (
    <div>
      <ErrorBoundary message={``}>
        <>
          {_.map(props.cartProducts, (product, index) => {
            const productRestrictions =
              product.saathiRestrictions &&
              product.saathiRestrictions.filter(
                (restriction) => restriction.length
              );
            return (
              <Card
                className={classes.productCard}
                variant={"outlined"}
                key={product.skuCode}
              >
                <div className={classes.productCardContent}>
                  <Grid
                    container
                    direction={`row`}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    spacing={0}
                  >
                    <Grid
                      item
                      xs={3}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Link
                        to={{
                          pathname: `/products/${product.skuCode}`,
                          state: { from: window.location.pathname },
                        }}
                        onClick={handleProductClick.bind(this, product, index)}
                      >
                        <img
                          src={product.imageUrl}
                          className={classes.productImage}
                          alt={product.productName}
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid
                        container
                        direction={`column`}
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}
                        spacing={0}
                      >
                        <Grid
                          container
                          direction={`row`}
                          alignItems={"flex-start"}
                          justifyContent={"flex-start"}
                          spacing={0}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant={"subtitle1"}
                              className={classes.productName}
                            >
                              {product.productName}
                            </Typography>

                            <IconButton
                              className={classes.removeIconWrapper}
                              onClick={() => {
                                props.handleRemove(product);
                              }}
                              size="large"
                            >
                              <Close className={classes.removeIcon} />
                            </IconButton>
                          </Box>
                          <br />
                          <span className={classes.pricePerQuantity}>
                            Billing Price Per Quantity:{" "}
                            {product.mrp > 0 && (
                              <span
                                className={classes.productMrp}
                                style={{
                                  filter:
                                    PartnerService.applySmudgedPriceFilter(
                                      product
                                    ),
                                }}
                              >
                                ₹{AmountUtils.format(product.mrp)}
                              </span>
                            )}
                            {product.sellingPrice > 0 && (
                              <span
                                className={classes.productSellingPrice}
                                style={{
                                  filter:
                                    PartnerService.applySmudgedPriceFilter(
                                      product
                                    ),
                                }}
                              >
                                ₹{AmountUtils.format(product.sellingPrice)}
                              </span>
                            )}
                          </span>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <img
                              src="/icons/creditPeriod.svg"
                              alt="credit period icon"
                              height="12px"
                            />
                            <span className={classes.productCreditPeriod}>
                              {product?.creditPeriodDate &&
                              product?.creditPeriodDate !== ""
                                ? "Due Date: "
                                : "Credit Period: "}
                              <b>
                                {" "}
                                <CreditPeriod textOnly product={product} />{" "}
                              </b>
                            </span>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        spacing={0}
                      >
                        <Grid item xs={12} sx={{ width: "100%" }}>
                          <AddRemoveBagItem
                            product={product}
                            minimumOrderQuantity={product.minimumOrderQuantity}
                            setShowQuantityChangeToast={
                              props.setShowQuantityChangeToast
                            }
                            handleRemove={props.handleRemove}
                            handleUpdate={props.handleUpdate}
                            isOfferApplied={
                              !product.offerRemoved &&
                              product.offer &&
                              Object.entries(product.offer).length > 0
                                ? true
                                : false
                            }
                          />
                          {product?.minimumOrderQuantity > 1 && (
                            <Typography
                              variant={`body2`}
                              sx={{
                                marginTop: "-10px",
                                color: "#00733E",
                                fontSize: "12px",
                              }}
                            >
                              Available in pack of{" "}
                              {product.minimumOrderQuantity}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                {(product.maxOrderAmount ||
                  (product.hasError &&
                    (isFieldAgent ||
                      (productRestrictions?.length != null &&
                        productRestrictions?.length != 0)))) && (
                  <>
                    {!_.isNil(product.maxPlacementAmount) &&
                      product.totalPrice >
                        product.maxPlacementAmount -
                          (product.pendingAmount || 0) && (
                        <Card
                          variant="outlined"
                          className={classes.errorContainer}
                        >
                          <Typography
                            variant={`body2`}
                            sx={{ textAlign: "center" }}
                          >
                            <span
                              key={`cart-saathi-sku`}
                              style={{ padding: "0px 8px" }}
                            >
                              The product has reached its maximum billing limit{" "}
                              {PartnerService.checkIfFieldAgent() && (
                                <>
                                  of{" "}
                                  <strong>
                                    {AmountUtils.formatCurrency(
                                      product.maxPlacementAmount -
                                        (product.pendingAmount || 0)
                                    )}{" "}
                                  </strong>
                                </>
                              )}
                              and can only be purchased with cash
                            </span>
                          </Typography>
                        </Card>
                      )}
                    {!_.isNil(product.maxPlacementAmount) &&
                      product.maxPlacementAmount <
                        (product.pendingAmount || 0) && (
                        <Card
                          variant="outlined"
                          className={classes.errorContainer}
                        >
                          <Typography
                            variant={`body2`}
                            sx={{ textAlign: "center" }}
                          >
                            <span
                              key={`cart-saathi-sku`}
                              style={{ padding: "0px 8px" }}
                            >
                              Clear outstanding to place order on credit.
                            </span>
                          </Typography>
                        </Card>
                      )}
                  </>
                )}
                {!product.offerRemoved &&
                  product.offer &&
                  Object.entries(product.offer).length > 0 && (
                    <>
                      <div
                        className={
                          product.hasError && isFieldAgent
                            ? classes.productOfferSectionError
                            : classes.productOfferSection
                        }
                      >
                        <fieldset
                          className={
                            product.hasError && isFieldAgent
                              ? classes.fieldsetStyleError
                              : classes.fieldsetStyle
                          }
                        >
                          <legend>Applied offer</legend>
                        </fieldset>
                      </div>
                      <Grid
                        container
                        spacing={0}
                        direction={`row`}
                        alignItems="baseline"
                        justifyContent="space-between"
                        className={
                          product.hasError && isFieldAgent
                            ? classes.offersError
                            : classes.offers
                        }
                      >
                        <Grid item xs={12}>
                          <Typography className={classes.offerName}>
                            <img
                              src={`/icons/offersGreenIcon.svg`}
                              alt=""
                              className={classes.offersIcon}
                            />
                            {product.offer?.offerName}{" "}
                          </Typography>
                          <Typography
                            className={classes.bestPriceText}
                            style={{
                              filter:
                                PartnerService.applySmudgedPriceFilter(product),
                            }}
                          >
                            {product.offer?.netEffectivePriceApplicable ===
                            "false" ? (
                              <>{product.offer?.bestPriceText}</>
                            ) : (
                              <>
                                Offer Price ₹
                                {AmountUtils.format(
                                  product.offer?.netEffectivePrice
                                )}{" "}
                                per Quantity
                              </>
                            )}
                          </Typography>
                          <Typography
                            variant={"caption"}
                            className={classes.OfferAvailable}
                          >
                            Offer available on: {product.offer?.quantity}{" "}
                            quantity
                          </Typography>
                          <div className={classes.entitlement}>
                            {product?.offer?.entitlements?.map((e, index) => {
                              return (
                                <OfferEntitlements
                                  key={index}
                                  entitlement={e}
                                  product={product}
                                />
                              );
                            })}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
              </Card>
            );
          })}
        </>
      </ErrorBoundary>
    </div>
  );
}

export default BagItems;
