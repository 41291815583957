import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Container } from "@mui/material";
import { ArrowForwardTwoTone } from "@mui/icons-material";

import EmptyError from "../EmptyError/EmptyError";
import ErrorBoundary from "../Common/ErrorBoundary";
import ViewAllButton from "../Common/ViewAllButton";
import OrderService from "../../Services/OrderService";
import OrderDetailCard from "../Common/OrderDetailCard";
import CommonConstantValues from "../../Services/CommonConstantValues";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: "82px",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffff",
    cursor: "pointer",
    borderTop: "1px solid #eeeeee",
    borderLeft: "none",
    borderBottom: "none",
    borderRight: "none",
    borderBottomRightRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
    marginTop: theme.spacing(-1.5),
    padding: theme.spacing(1.5),
    "&:focus": {
      backgroundColor: `#FFFFFF`,
    },
    "&:hover": {
      backgroundColor: `#FFFFFF`,
    },
  },
  viewAllButtonWrapper: {
    textDecoration: "none",
  },
  loading: { textAlign: "center", fontSize: "16px" },
}));

const Orders = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [ordersFetchedCount, setOrdersFetchedCount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(1000);
  const [loading, setLoading] = useState("not_started");

  const handleClickSeeOrderDetails = (order) => {
    navigate({
      pathname: `/orders/${order.orderId}&wmsOrderId=${order.wmsOrderId}`,
      state: { wmsOrderId: order.wmsOrderId },
    });
  };

  useEffect(() => {
    setLoading("started");
    const allOrdersCB = getAllOrders();
    return () => allOrdersCB();
  }, []);

  const getAllOrders = () => {
    const { axiosRequest, abortController } = OrderService.getAllOrders(
      true,
      ordersFetchedCount,
      10,
      false
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.orders) {
          let ordersFromResponse = response.data.responseData.orders;
          ordersFromResponse = OrderService.filterOrders(ordersFromResponse);

          if (props.numberOfOrdersToShow && props.numberOfOrdersToShow > 0) {
            if (ordersFromResponse.length > props.numberOfOrdersToShow) {
              setViewAll(true);
            }
            ordersFromResponse = ordersFromResponse.splice(
              0,
              props.numberOfOrdersToShow
            );
          }

          const newFetchedCount = ordersFromResponse?.length;
          setOrdersFetchedCount((prevCount) => prevCount + newFetchedCount);
          setOrders((prevOrders) => [...prevOrders, ...ordersFromResponse]);
          if (!response?.data?.responseData?.orders?.length) {
            setTotalOrdersCount(ordersFetchedCount);
          }

          // // TODO: To consume totalOrderCount from Backend
          // if (response.data.responseData?.totalOrderCount) {
          //   setTotalOrdersCount(response.data.responseData?.totalOrderCount);
          // }
        } else {
          console.log(`no orders found`);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
        }
      });
    return () => abortController.abort();
  };

  return (
    <div className={classes.root}>
      <ErrorBoundary message={``}>
        <Container maxWidth={"xl"}>
          {loading === "done" && orders && orders?.length > 0 && (
            <>
              <InfiniteScroll
                dataLength={ordersFetchedCount}
                next={getAllOrders}
                hasMore={ordersFetchedCount < totalOrdersCount}
                loader={<h4 className={classes.loading}>Loading...</h4>}
              >
                {orders.map((order, index) => {
                  return (
                    <div key={index}>
                      {order?.products && order.products.length > 0 && (
                        <>
                          <OrderDetailCard
                            order={order}
                            showCancelOrder={false}
                            showPlacedBy={false}
                          />
                          <Button
                            fullWidth={true}
                            className={classes.footer}
                            onClick={() => handleClickSeeOrderDetails(order)}
                            sx={{
                              color:
                                order?.status ===
                                CommonConstantValues.DELIVERED_AT_GODOWN
                                  ? "#9D2123"
                                  : order?.statusMessage?.toLowerCase() ===
                                      "approval pending"
                                    ? "#9D2123"
                                    : "#00733E",
                            }}
                          >
                            {order?.status ===
                            CommonConstantValues.DELIVERED_AT_GODOWN
                              ? "GET FREIGHT CREDIT NOTE"
                              : order?.statusMessage?.toLowerCase() ===
                                  "approval pending"
                                ? "Approve Order"
                                : "Order Details And Invoice"}
                            <ArrowForwardTwoTone />
                          </Button>
                        </>
                      )}
                    </div>
                  );
                })}
              </InfiniteScroll>

              {viewAll && (
                <Link to={`/orders`} className={classes.viewAllButtonWrapper}>
                  <ViewAllButton section={"Orders"} />
                </Link>
              )}
            </>
          )}
          {loading === "done" && !orders?.length && orders && (
            <>
              <EmptyError
                image={`Empty Cart.svg`}
                text={`No orders found!`}
                subText={`Place an order and visit here to track its status`}
              />
            </>
          )}
          {loading === "started" && (
            <>
              <Skeleton
                variant="rectangular"
                height={175}
                sx={{ marginTop: "16px", borderRadius: "16px" }}
              />
              <Skeleton
                variant="rectangular"
                height={175}
                sx={{ marginTop: "16px", borderRadius: "16px" }}
              />
              <Skeleton
                variant="rectangular"
                height={175}
                sx={{ marginTop: "16px", borderRadius: "16px" }}
              />
              <Skeleton
                variant="rectangular"
                height={175}
                sx={{ marginTop: "16px", borderRadius: "16px" }}
              />
            </>
          )}
        </Container>
      </ErrorBoundary>
    </div>
  );
};

export default Orders;
