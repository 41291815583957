import { Box, Typography } from "@mui/material";

const TodProgressBarLegend = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        p: "0px 8px 6px 8px",
      }}
    >
      <Box sx={{ width: "25px", height: "9px", background: "#FFDC42" }} />
      <Typography sx={{ fontSize: "10px", color: "#4F4F4F", pl: 0.5 }}>
        Purchased
      </Typography>
      <Box
        sx={{ width: "25px", height: "9px", background: "#00733E", ml: 2 }}
      />
      <Typography sx={{ fontSize: "10px", color: "#4F4F4F", pl: 0.5 }}>
        Settled
      </Typography>
    </Box>
  );
};

export default TodProgressBarLegend;
