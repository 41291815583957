import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import Carousel from "react-material-ui-carousel";
import AnalyticsService from "../../Services/AnalyticsService";
import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import { Box } from "@mui/material";

const useStyles = makeStyles(() => ({
  // img: {
  //   background: "#ffffff",
  //   overflow: "hidden",
  //   display: "block",
  //   // width: "360px",
  // },
}));

const USPBanners = () => {
  const classes = useStyles();

  return (
    <Box sx={{ background: "#ffffff", minHeight: "200px" }}>
      {/* <ReactVideoPlayer
        height={"56.25vw"}
        video={{ src: "https://www.youtube.com/watch?v=lomx9z0DbGU" }}
        autoplay={false}
        playing={false}
        muted={false}
        handlePlaybackStart={() => {
          AnalyticsService.pushEvent(`Sign In Video Playback Started`, {
            page: "onboard",
            videoUrl: "https://www.youtube.com/watch?v=lomx9z0DbGU",
          });
        }}
        handlePlaybackEnd={() => {
          AnalyticsService.pushEvent(`Sign In Video Playback Ended`, {
            page: "onboard",
            videoUrl: "https://www.youtube.com/watch?v=lomx9z0DbGU",
          });
        }}
      /> */}
      <img
        src="/icons/loginSaathiBanner.jpeg"
        style={{
          maxWidth: 440,
          width: "100%",
          display: "block",
          textAlign: "center",
          align: "center",
          margin: "auto",
        }}
      />
    </Box>
  );
};

export default USPBanners;
