import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  Radio,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { ArrowRightAlt, Close } from "@mui/icons-material";
import useFocus from "../../Hooks/useFocus";
import PaymentService from "../../Services/PaymentService";
import useRazorpay from "react-razorpay";
import _, { parseInt } from "lodash";
import ProceedPaymentFailureDialog from "./ProceedPaymentFailureDialog";
import AnalyticsService from "../../Services/AnalyticsService";
import config from "../../config";
import AndroidService from "../../Services/AndroidService";

const PayAdvanceDialog = (props) => {
  const Razorpay = useRazorpay();
  useEffect(() => {
    if (props.open && props.paymentAmount === 0) {
      setPaymentAmountType("otherAmount");
    }
  }, [props.paymentAmount, props.open]);
  const [inputRef, setInputFocus] = useFocus();

  const amountSlabs = [
    { label: "₹50,000", value: 50000 },
    { label: "₹1,00,000", value: 100000 },
    { label: "₹2,50,000", value: 250000 },
    { label: "₹5,00,000", value: 500000 },
  ];

  const [paymentAmountType, setPaymentAmountType] = React.useState(
    props.amountTypeFromParams && props.amountTypeFromParams !== ""
      ? props.amountTypeFromParams === "other"
        ? "otherAmount"
        : "defaultAmount"
      : "defaultAmount"
  );
  const [openDebug, setOpenDebug] = useState(false);
  const [debugMessage, setDebugMessage] = useState("");
  const [defaultPaymentAmountValue, setDefaultPaymentAmountValue] = useState(
    props.paymentAmount
  );
  const [otherPaymentAmountValue, setOtherPaymentAmountValue] = React.useState(
    props.amountTypeFromParams &&
      props.amountTypeFromParams === "other" &&
      props.amountFromParams &&
      props.amountFromParams > 0
      ? props.amountFromParams
      : 0
  );

  const [showProceedPaymentFailureDialog, setShowProceedPaymentFailureDialog] =
    useState(false);
  const [proceedPaymentErrorCode, setProceedPaymentErrorCode] = useState(null);
  const [paymentReason, setPaymentReason] = useState(
    props?.reasonFromParams === "seed-investment"
      ? "Payment received for seed investment through Pay Advance"
      : props?.reasonFromParams === "others"
        ? "Payment received through Pay Advance"
        : null
  );
  const [paymentReasonError, setPaymentReasonError] = useState(null);

  const handleChange = (valueType, value) => {
    if (valueType === "otherAmount") {
      setInputFocus();
      if (otherPaymentAmountValue === 0) {
        setOtherPaymentAmountValue("");
      }
    } else {
      AnalyticsService.pushEvent("Pay Advance Default Amount Selected", {
        type: "advance",
        amount: value,
      });
    }
    setPaymentAmountType(valueType);
    setDefaultPaymentAmountValue(value);
  };

  const handleChangePaymentReason = (value) => {
    setPaymentReason(value);
    setPaymentReasonError(null);
    AnalyticsService.pushEvent("Pay Advance Reason Selected", {
      reason: value,
    });
  };

  useEffect(() => {
    if (paymentAmountType === "otherAmount") {
      AnalyticsService.pushEvent("Pay Advance Other Amount Selected", {
        type: "advance",
        amount: otherPaymentAmountValue,
      });
    }
  }, [paymentAmountType]);
  const handleChangeOtherPaymentAmount = (event) => {
    setOtherPaymentAmountValue(event.target.value);
  };

  function handlePaymentViaWebSDK(razorpayConfig) {
    props.setPaymentLoading("done");

    let razorpayInstance = new Razorpay(razorpayConfig);
    razorpayInstance.open();
    razorpayInstance.on("payment.failed", function (response) {
      AnalyticsService.pushEvent("Payment Initiation Failure", {
        code: response?.error?.code,
        description: response?.error?.description,
        source: response?.error?.source,
        step: response?.error?.step,
        reason: response?.error?.reason,
        orderId: response?.error?.metadata?.order_id,
        paymentId: response?.error?.metadata?.payment_id,
        type: "advance",
        paymentAmountType: paymentAmountType,
        amount: razorpayConfig?.amount,
        sdk: "web",
      });
      props.handleRazorpayFailure("RAZORPAY_FAILURE", response?.error?.code);
    });

    AnalyticsService.pushEvent("Payment Initiation Successful", {
      type: "advance",
      paymentAmountType: paymentAmountType,
      amount: razorpayConfig?.amount,
      sdk: "web",
    });
  }

  const handleProceedPaymentPayAdvance = (event) => {
    event.preventDefault();
    if (paymentReason === null || paymentReason === "") {
      setPaymentReasonError("Please select a reason for payment");
    } else {
      setPaymentReasonError(null);
      props.setPaymentLoading("started");
      let amountToPay = null;
      if (paymentAmountType === "defaultAmount") {
        amountToPay = parseInt(defaultPaymentAmountValue);
      } else if (paymentAmountType === "otherAmount") {
        amountToPay = parseInt(otherPaymentAmountValue);
      }

      AnalyticsService.pushEvent("Proceed Payment Clicked", {
        type: "advance",
        paymentAmountType: paymentAmountType,
        amount: amountToPay,
      });

      let paymentErrorCode = null;
      let initiationErrorCode = null;
      const { axiosRequest } = PaymentService.initiatePayment({
        paymentType: "advance",
        amount: amountToPay,
        reason: paymentReason,
      });
      axiosRequest
        .then((response) => {
          if (
            response?.data?.responseData &&
            !_.isEmpty(response?.data?.responseData)
          ) {
            let responseData = response.data.responseData;
            console.log({ responseData });
            let razorPayConfig = {
              key: responseData.apiKey,
              amount: amountToPay,
              currency: "INR",
              name: "AgroStar",
              description: "Payment Amount",
              order_id: responseData.razorpayOrderId,
              callback_url: `${config.paymentRedirectionUrl}paymentapi/v1/paymentredirect`,
              redirect: true,
              remember_customer: false,
              hidden: {
                contact: true,
                email: true,
              },
              modal: {
                confirm_close: true,
                ondismiss: function (response) {
                  console.log("in close");
                  console.log(response);
                  props.setPaymentLoading("done");
                  AnalyticsService.pushEvent("Razorpay Payment SDK Closed");
                },
              },
              prefill: {
                // name: localStorage.getItem("partnerName"),
                email: "rzpcustomer.payment@agrostar.in",
                contact: localStorage.getItem("mobile"),
                // method: "upi",
              },
              theme: {
                color: "#9D2123",
              },
              // notes: {
              //   address: "Razorpay Corporate Office",
              // },
              razorpaySuccessCallbackUrl: `${config.appUrl}/hisaab/payment-status/`,
              razorpayFailureCallbackUrl: `${config.appUrl}/hisaab/razorpay-failure/RAZORPAY_FAILURE`,
            };
            console.log({ razorPayConfig });
            if (responseData.apiKey && responseData.razorpayOrderId) {
              if (AndroidService.checkIfAndroid()) {
                if (AndroidService.getAndroidVersion()) {
                  // setOpenDebug(true);

                  let { appVersionCode } = AndroidService.getAndroidVersion();
                  // setOpenDebug(true);
                  // setDebugMessage(
                  //   `in get version; android version ${appVersionCode}`
                  // );
                  if (appVersionCode && parseInt(appVersionCode) >= 8) {
                    handlePaymentViaAndroidNativeSDK(razorPayConfig);
                  } else {
                    // setOpenDebug(true);
                    // setDebugMessage(`No appVersionCode;`);
                    handlePaymentViaWebSDK(razorPayConfig);
                  }
                } else {
                  // setOpenDebug(true);
                  // setDebugMessage(`No getAppVersion();`);
                  handlePaymentViaWebSDK(razorPayConfig);
                }
              } else {
                // setOpenDebug(true);
                // setDebugMessage(`No Android;`);
                handlePaymentViaWebSDK(razorPayConfig);
              }
            } else {
              props.setPaymentLoading("done");

              if (!responseData.razorpayOrderId) {
                initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_ORDER_ID";
                setProceedPaymentErrorCode(initiationErrorCode);
                setShowProceedPaymentFailureDialog(true);
              }
              if (!responseData.apiKey) {
                initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_API_KEY";
                setProceedPaymentErrorCode(initiationErrorCode);
                setShowProceedPaymentFailureDialog(true);
              }
            }
          } else {
            initiationErrorCode = "PAYMENT_INIT_NO_RESPONSE_DATA";
            setProceedPaymentErrorCode(initiationErrorCode);
            setShowProceedPaymentFailureDialog(true);
            props.setPaymentLoading("done");
          }
          if (initiationErrorCode) {
            AnalyticsService.pushEvent("Payment Initiation Failed", {
              type: "advance",
              paymentAmountType: paymentAmountType,
              amount: amountToPay,
              errorCode: initiationErrorCode,
            });
          }
        })
        .catch(() => {
          initiationErrorCode = "PAYMENT_INIT_FAILED";
          setProceedPaymentErrorCode(initiationErrorCode);
          AnalyticsService.pushEvent("Payment Initiation Failed", {
            type: "advance",
            paymentAmountType: paymentAmountType,
            amount: amountToPay,
            errorCode: initiationErrorCode,
          });
          setShowProceedPaymentFailureDialog(true);
          props.setPaymentLoading("done");
        })
        .finally(() => {});
    }
  };

  const handlePaymentViaAndroidNativeSDK = (razorPayConfig) => {
    props.setPaymentLoading("done");
    delete razorPayConfig.modal.ondismiss;
    razorPayConfig.redirect = false;
    delete razorPayConfig.callback_url;

    let razorPayMetadataStringified = JSON.stringify(razorPayConfig);

    try {
      /*global Android*/
      if (Android) {
        Android.startRazorpayPaymentFlow(razorPayMetadataStringified);
        AnalyticsService.pushEvent("Payment Initiation Successful", {
          type: "advance",
          paymentAmountType: paymentAmountType,
          amount: razorPayConfig?.amount,
          sdk: "android",
        });
      } else {
        // setOpenDebug(true);
        // setDebugMessage(`No Android;in native handler`);
        handlePaymentViaWebSDK(razorPayConfig);
      }
    } catch (e) {
      // setOpenDebug(true);
      // setDebugMessage(`in try native catch; error- ${JSON.stringify(e)}`);
      handlePaymentViaWebSDK(razorPayConfig);
    }
  };
  return (
    <>
      <SwipeableDrawer
        anchor={`bottom`}
        onOpen={() => {}}
        open={props.open}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        onClose={() => {
          AnalyticsService.pushEvent("Pay Advance Dialog Closed", {
            amount: props.paymentAmount,
            type: "advance",
          });
          setOtherPaymentAmountValue(0);
          props.handleClose();
        }}
        // swipeAreaWidth={drawerBleeding}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          },
        }}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent={`flex-start`}
          sx={{ marginTop: "20px" }}
        >
          <Grid item xs={5} />
          <Grid item xs={1}>
            <Box
              sx={{
                border: "3px solid #DDDDDD",
                width: "43px",
                height: "0px",
              }}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={1}>
            <IconButton
              sx={{ marginTop: "-16px" }}
              onClick={() => {
                AnalyticsService.pushEvent("Pay Advance Dialog Closed", {
                  amount: props.paymentAmount,
                  type: "advance",
                });
                props.handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <form onSubmit={handleProceedPaymentPayAdvance}>
          <Box sx={{ marginBottom: "10px" }}>
            {/* <FormGroup>
              <Container>
                <Typography
                  variant={`body1`}
                  sx={{ fontSize: "16px", marginLeft: "16px" }}
                >
                  Select Advance Payment Reason <small>(Required)</small>
                </Typography>
              </Container>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={1} />
                <Grid item xs={10}>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    onClick={() => {
                      handleChangePaymentReason(
                        "Payment received for seed investment through Pay Advance"
                      );
                    }}
                    value={
                      "Payment received for seed investment through Pay Advance"
                    }
                  >
                    <FormControlLabel
                      sx={{ marginLeft: "1px" }}
                      label={`Seed Investment`}
                      control={
                        <>
                          <Radio
                            checked={
                              paymentReason ===
                              "Payment received for seed investment through Pay Advance"
                            }
                            // onChange={handleChange}
                            // value={"defaultAmount"}
                          />
                        </>
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={1} />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={1} />
                <Grid item xs={10}>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    onClick={() => {
                      handleChangePaymentReason(
                        "Payment received through Pay Advance"
                      );
                    }}
                    value={"Payment received through Pay Advance"}
                  >
                    <FormControlLabel
                      sx={{ marginLeft: "1px" }}
                      label={`Others`}
                      control={
                        <>
                          <Radio
                            checked={
                              paymentReason ===
                              "Payment received through Pay Advance"
                            }
                            // onChange={handleChange}
                            // value={"defaultAmount"}
                          />
                        </>
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={1} />
              </Grid>
              <Box sx={{ height: "20px" }}>
                <Grid container justifyContent={`center`}>
                  <Grid item xs={10}>
                    <Typography
                      variant={`body2`}
                      color={"primary"}
                      align={`left`}
                      sx={{
                        fontWeight: "400",
                        marginTop: "4px",
                        fontSize: "14px",
                        // marginTop: "-20px",
                        // marginBottom: "10px",
                        marginLeft: "16px",
                      }}
                    >
                      {paymentReasonError && (
                        <>
                          <Warning
                            sx={{
                              display: "inline-flex",
                              verticalAlign: "middle",
                              marginBottom: "5px",
                              fontSize: "16px",
                            }}
                            fontSize={`small`}
                          />{" "}
                          {paymentReasonError}
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </FormGroup> */}
          </Box>
          <Box>
            <FormGroup>
              <Container>
                <Typography
                  variant={`body1`}
                  sx={{ fontSize: "16px", marginLeft: "16px" }}
                >
                  Enter Advance Amount
                </Typography>
              </Container>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    onClick={() => {
                      handleChange("defaultAmount", amountSlabs[0].value);
                    }}
                    value={amountSlabs[0].value}
                  >
                    <FormControlLabel
                      sx={{ marginLeft: "1px" }}
                      label={amountSlabs[0].label}
                      control={
                        <>
                          <Radio
                            checked={
                              paymentAmountType === "defaultAmount" &&
                              defaultPaymentAmountValue === amountSlabs[0].value
                            }
                            // onChange={handleChange}
                            // value={"defaultAmount"}
                          />
                        </>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    onClick={() => {
                      handleChange("defaultAmount", amountSlabs[1].value);
                    }}
                    value={amountSlabs[1].value}
                  >
                    <FormControlLabel
                      sx={{ marginLeft: "1px" }}
                      label={amountSlabs[1].label}
                      control={
                        <>
                          <Radio
                            checked={
                              paymentAmountType === "defaultAmount" &&
                              defaultPaymentAmountValue === amountSlabs[1].value
                            }
                            // onChange={handleChange}
                            // value={"defaultAmount"}
                          />
                        </>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{ marginTop: "-10px" }}
                spacing={2}
              >
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    onClick={() => {
                      handleChange("defaultAmount", amountSlabs[2].value);
                    }}
                    value={amountSlabs[2].value}
                  >
                    <FormControlLabel
                      sx={{ marginLeft: "1px" }}
                      label={amountSlabs[2].label}
                      control={
                        <>
                          <Radio
                            checked={
                              paymentAmountType === "defaultAmount" &&
                              defaultPaymentAmountValue === amountSlabs[2].value
                            }
                            // onChange={handleChange}
                            // value={"defaultAmount"}
                          />
                        </>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    onClick={() => {
                      handleChange("defaultAmount", amountSlabs[3].value);
                    }}
                    value={amountSlabs[3].value}
                  >
                    <FormControlLabel
                      sx={{ marginLeft: "1px" }}
                      label={amountSlabs[3].label}
                      control={
                        <>
                          <Radio
                            checked={
                              paymentAmountType === "defaultAmount" &&
                              defaultPaymentAmountValue === amountSlabs[3].value
                            }
                            // onChange={handleChange}
                            // value={"defaultAmount"}
                          />
                        </>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{ marginTop: "-10px" }}
              >
                <Grid item xs={1} />
                <Grid item xs={10}>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    value={"otherAmount"}
                    onClick={() => {
                      handleChange("otherAmount", props.paymentAmount);
                    }}
                  >
                    <FormControl variant="outlined" sx={{ marginLeft: "10px" }}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={paymentAmountType === "otherAmount"}
                          />
                        }
                        label={
                          <Input
                            autoFocus={paymentAmountType === "otherAmount"}
                            required={paymentAmountType === "otherAmount"}
                            onChange={handleChangeOtherPaymentAmount}
                            inputRef={inputRef}
                            value={otherPaymentAmountValue}
                            onFocus={() => {
                              setPaymentAmountType("otherAmount");
                            }}
                            inputProps={{
                              type: "number",
                              min: paymentAmountType === "otherAmount" ? 1 : 0,
                              max: 500000,
                            }}
                            startAdornment={<Typography>₹ </Typography>}
                            style={{ width: 230 }}
                            label={`Enter Your Amount`}
                            placeholder={` Enter Your Amount`}
                          />
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </FormGroup>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ marginTop: "16px" }}
            >
              <Grid item xs={12}>
                <Button
                  fullWidth={true}
                  size={"large"}
                  variant={`contained`}
                  sx={{ borderRadius: "0px", fontSize: "14px" }}
                  type={"submit"}
                  disabled={
                    localStorage.getItem("role") === "fieldAgent" ||
                    (props.paymentAmount === 0 &&
                      paymentAmountType === "defaultAmount")
                  }
                  endIcon={<ArrowRightAlt />}
                >
                  Proceed to Pay
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </SwipeableDrawer>

      <ProceedPaymentFailureDialog
        open={showProceedPaymentFailureDialog}
        retryPayment={handleProceedPaymentPayAdvance}
        errorCode={proceedPaymentErrorCode}
        handleClose={() => {
          setShowProceedPaymentFailureDialog(false);
        }}
      />
    </>
  );
};

export default PayAdvanceDialog;
