import config from "../config";
import { partner, partnerRegistered } from "./mocks/PartnerMock";
import FinBoxDetailsMock from "./mocks/FinBoxDetailsMock";
import PartnersListMock from "./mocks/PartnersListMock";
import moment from "moment";
import PartnersTerritoriesListMock from "./mocks/PartnersTerritoriesMock";
import IETFLanguages from "../Services/IETFLanguageCode";
import { anonymousAuthTokenMock, verifyOtpMock } from "./mocks/LoginMocks";
import CommonConstantValues from "./CommonConstantValues";
import OKRMapping from "./mocks/OKRMappingMock";
import { parseInt } from "lodash";
import Stats from "./mocks/PartnerLevelStatsMock";
import HTTPService from "./HTTPService";

const useMocks = false;

export const PARTNERS_TO_SHOW_IN_A_SINGLE_SCROLL = 20;
export default class PartnerService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getPartnerBasicData = (mobileNumber) => {
    const url = `${config.backendUrl}crmservice/v1/farmerdetails/basic/?phoneNumber=${mobileNumber}`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      ...this.getHeaders(),
      source: "CSR",
    });
  };

  static getInstitutionalDetailsByPhoneNumber(phoneNumber) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/institutionbyphonenumber/?phoneNumber=${phoneNumber}`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        source: "B2B",
        "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
      });
    } else {
      return new Promise((resolve) => {
        resolve({ data: partner });
      });
    }
  }

  static getPartnerClubHistory() {
    const url = `${
      config.backendUrl
    }institutionalsalesservice/clubhistory/?id=${localStorage.getItem(
      `farmerId`
    )}`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      ...this.getHeaders(),
      source: "B2B",
    });
  }

  static getPartnerDetailedData(farmerId) {
    const url = `${config.backendUrl}crmservice/v1/farmerdetails/?farmerId=${farmerId}`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      ...this.getHeaders(),
      source: "CSR",
    });
  }

  static setWhatsappOptIn(farmerId, mobileNumber, status) {
    const data = {
      farmerId,
      phoneNumbers: [mobileNumber],
      source: "CRM",
      status,
    };
    const url = `${config.backendUrl}crmservice/v2/whatsapp/farmerconsent/`;
    return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
  }

  static sendOtp = (mobile) => {
    const url = `${config.backendUrl}userservice/loginotp/?phoneNumber=${mobile}&userType=saathi`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      "X-Authorization-Token": localStorage.getItem(`anonymousAuthToken`),
    });
  };

  static getStoreNameForStoreFrontUrl = () => {
    let storeName = "store";
    if (localStorage.getItem("partnerName")) {
      if (localStorage.getItem("partnerName")?.split(" ")?.length > 1) {
        storeName = localStorage
          .getItem("partnerName")
          ?.split(" ")
          ?.filter((word) => word !== "")
          ?.join("-");
      } else {
        storeName = localStorage.getItem("partnerName");
      }
    }
    return storeName;
  };
  static verifyOtp = (otp, otpToken, phoneNumber) => {
    if (useMocks === false) {
      const url = `${config.backendUrl}userservice/loginotp/`;
      const data = {
        otp: otp,
        otpToken: otpToken,
        phoneNumber,
        userType: "saathi",
      };
      return HTTPService.makeXHRRequest(url, "POST", data, null);
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: verifyOtpMock });
        }, 1000);
      });
    }
  };

  static setPartnerAppInstalled(data) {
    const url = `${config.backendUrl}crmservice/v1/farmerdetails/`;
    return HTTPService.makeXHRRequest(url, "PATCH", data, {
      ...this.getHeaders(),
      source: "CSR",
    });
  }

  static registerPartnerFinbox(
    partnerMobile,
    shopName,
    partnerState,
    agentMobile,
    gstNumber
  ) {
    if (useMocks === false) {
      let data = {
        phoneNumber: partnerMobile,
        storeName: shopName,
        userState: partnerState,
        agentNumber: agentMobile,
      };
      if (gstNumber) {
        data.customerGSTNumber = gstNumber;
      }
      const url = `${config.backendUrl}institutionalsalesservice/createpartnerlead/`;
      return HTTPService.makeXHRRequest(url, "POST", data, {
        ...this.getHeaders(),
        source: "B2B",
      });
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: partner });
        }, 500);
      });
    }
  }

  static registerPartner(data) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/selfonboarding/?pageNumber=1`;
      return HTTPService.makeXHRRequest(url, "POST", data, {
        ...this.getHeaders(),
        source: "B2B",
      });
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ status: 200, data: partnerRegistered });
          // reject({ status: 400, data: partnerRegistrationValidatioFailed });
        }, 500);
      });
    }
  }

  static checkPartnerData(farmerId) {
    const url = `${config.backendUrl}crmservice/v1/farmerdetails/?farmerId=${farmerId}`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      ...this.getHeaders(),
      source: "CSR",
    });
  }

  static getPartnerFinBoxInitializationDetails() {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/${localStorage.getItem(
        `farmerId`
      )}/finboxtoken/`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        ...this.getHeaders(),
        source: "B2B",
      });
    } else {
      return new Promise((resolve) => {
        resolve({ data: FinBoxDetailsMock });
      });
    }
  }

  static verifyFieldAgent = (fieldAgentMobileNumber) => {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/agents/saathis/?agentPhoneNumber=${fieldAgentMobileNumber}&offset=${0}&limit=1`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        source: "B2B",
        "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
      });
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: PartnersListMock });
        }, 500);
      });
    }
  };

  static getPartnersListForFieldAgent = (
    fieldAgentMobileNumber,
    page,
    territory,
    searchKeyword,
    openForSale,
    salesBlocked,
    churned
  ) => {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }institutionalsalesservice/agents/saathis/?agentPhoneNumber=${fieldAgentMobileNumber}&offset=${
        page * PARTNERS_TO_SHOW_IN_A_SINGLE_SCROLL
      }&limit=${PARTNERS_TO_SHOW_IN_A_SINGLE_SCROLL}${
        searchKeyword ? `&searchTerm=${searchKeyword}` : ""
      }${
        territory && territory !== "All" ? `&territory=${territory}` : ""
      }&inactivatedSinceDays=${
        CommonConstantValues.ACCESS_PERIOD_IN_DAYS_FOR_INACTIVE_PARTNERS
      }${openForSale ? `&openForSale=${openForSale}` : ""}${
        salesBlocked ? `&salesBlocked=${salesBlocked}` : ""
      }${churned ? `&churned=${churned}` : ""}`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        source: "B2B",
        "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
      });
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: PartnersListMock });
        }, 500);
      });
    }
  };

  static getOnboardingLeads = (
    fieldAgentMobileNumber,
    page,
    searchKeyword,
    selectedStatus,
    filterKey
  ) => {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }institutionalsalesservice/agents/zoho/saathis/?agentPhoneNumber=${fieldAgentMobileNumber}&offset=${
        page * PARTNERS_TO_SHOW_IN_A_SINGLE_SCROLL
      }&limit=${PARTNERS_TO_SHOW_IN_A_SINGLE_SCROLL}${
        searchKeyword ? `&searchTerm=${searchKeyword}` : ""
      }${filterKey ? `&${filterKey}=${selectedStatus}` : ""}`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        ...this.getHeaders(),
        source: "B2B",
      });
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: PartnersListMock });
        }, 500);
      });
    }
  };

  static getLeadsDropDown = ({ district, territory, zohostatus }) => {
    const agentMobileNumber = localStorage.getItem("fieldAgentMobileNumber");
    let url = `${config.backendUrl}institutionalsalesservice/agents/onboarding/leads-dropdown/?agentPhoneNumber=${agentMobileNumber}`;
    if (district) url += `&district=true`;
    if (territory) url += `&territory=true`;
    if (zohostatus) url += `&zohostatus=true`;

    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  };

  static getTerritoriesForFieldAgent = (fieldAgentMobileNumber) => {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/agents/territories/?agentPhoneNumber=${fieldAgentMobileNumber}`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        ...this.getHeaders(),
        source: "B2B",
      });
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ data: PartnersTerritoriesListMock });
        }, 500);
      });
    }
  };

  static setLoggedInTimeStamp = () => {
    let currentTimeStamp = moment().unix();
    if (
      !localStorage.getItem(`loggedInAt`) &&
      localStorage.getItem("role") === "fieldAgent" &&
      localStorage.getItem("otpVerified") === "true"
    ) {
      localStorage.setItem(`loggedInAt`, currentTimeStamp.toString());
    }
  };

  static calculateIfAutoLogout = () => {
    let autoLogoutFlag = false;
    if (
      localStorage.getItem("role") === "fieldAgent" &&
      localStorage.getItem(`loggedInAt`) &&
      localStorage.getItem(`loggedInAt`) !== undefined
    ) {
      let loggedInAt = parseInt(localStorage.getItem(`loggedInAt`));
      let currentTimeStamp = moment().unix();
      let durationInHours = moment
        .duration(moment.unix(currentTimeStamp).diff(moment.unix(loggedInAt)))
        .asHours();

      //uncomment this for testing
      // let durationInHours = moment
      //   .duration(
      //     moment().add(2.001, "hours").diff(moment.unix(currentTimeStamp))
      //   )
      //   .asHours();

      if (
        parseFloat(durationInHours).toFixed(3) >=
        CommonConstantValues.FIELD_AGENT_AUTO_LOGOUT_DURATION_HOURS
      ) {
        autoLogoutFlag = true;
      }
    }
    return autoLogoutFlag;
  };
  static logoutFieldAgent = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("otpVerified");
    localStorage.removeItem("fieldAgentUserId");
    localStorage.removeItem("fieldAgentState");
    localStorage.removeItem("fieldAgentSource");
    localStorage.removeItem("fieldAgentMobileNumber");
  };
  static logout = () => {
    localStorage.clear();
  };

  static logoutFieldAgentAsPartner = () => {
    localStorage.removeItem("cartv2");
    localStorage.removeItem("order");
    localStorage.removeItem("cart");
    localStorage.removeItem("creditPeriod");
    localStorage.removeItem("creditLine");
    localStorage.removeItem("lendingProvider");
    localStorage.removeItem("createReturnInitiated");
    localStorage.removeItem("clubSegment");
    localStorage.removeItem("district");
    localStorage.removeItem("InstitutionDetails");
    localStorage.removeItem("farmerId");
    localStorage.removeItem("freeLeadAttemptsMade");
    localStorage.removeItem("mobile");
    localStorage.removeItem("partnerName");
    localStorage.removeItem("pinCode");
    localStorage.removeItem("state");
    localStorage.removeItem("source");
    localStorage.removeItem("partnerStatus");
    localStorage.removeItem("userId");
    localStorage.removeItem("visitedReturnOrderDetailsPage");
    localStorage.removeItem("viewedStories");
    localStorage.removeItem("warehousesAssigned");
    localStorage.removeItem("institutionId");
    localStorage.removeItem("agroExFranchiseId");
    localStorage.removeItem("partnerPricingEnabled");
    localStorage.removeItem("partnerSubStatus");
    localStorage.removeItem("onboardingReferrerSalesOfficerEmailID");
    localStorage.removeItem("documentSubmissionStatus");
    localStorage.removeItem("areDocumentsVerified");
  };

  static isPartnerInactiveSinceMoreThanNdays(inactivationDate) {
    var currentDate = moment(Date.now());
    var partnerInactivationDate = moment(inactivationDate);
    if (
      currentDate.diff(partnerInactivationDate, "days") >=
      CommonConstantValues.ACCESS_PERIOD_IN_DAYS_FOR_INACTIVE_PARTNERS
    ) {
      return true;
    }
    return false;
  }

  static startsWithAnyOf(str, prefixes) {
    return prefixes?.some((prefix) => str?.startsWith(prefix));
  }

  static isPartnerOnboardingInProgress() {
    const institutionDetails = localStorage.getItem("InstitutionDetails");
    let parsedInstitutionDetails = null;
    if (institutionDetails && institutionDetails !== "") {
      parsedInstitutionDetails = JSON.parse(institutionDetails);
    }
    if (!parsedInstitutionDetails) {
      return false;
    }

    const partnerName = parsedInstitutionDetails?.partnerName;
    const partnerNameTransformed = partnerName?.toLowerCase()?.trim();
    const onboardingPrefixStrings = [
      "on-boarding in progress",
      "on boarding in progress",
      "onboarding in progress",
    ];

    const isOnboardingInProgress = this.startsWithAnyOf(
      partnerNameTransformed,
      onboardingPrefixStrings
    );
    return isOnboardingInProgress;
  }

  static getStoreFrontUrl = () => {
    let storeFrontUrl = `${
      config.farmerWebAppBaseUrl
    }/saathi-store/${encodeURIComponent(
      this.getStoreNameForStoreFrontUrl()
    )}/${localStorage.getItem(`farmerId`)}?showHeader=false`;

    if (
      localStorage.getItem("state") &&
      IETFLanguages[localStorage.getItem("state")?.toUpperCase()]
    ) {
      storeFrontUrl += `&language=${
        IETFLanguages[localStorage.getItem("state")?.toUpperCase()]?.split(
          "-"
        )?.[0]
      }&state=${localStorage.getItem("state")?.toLowerCase()}`;
    }
    return storeFrontUrl;
  };

  static getStoreDetails = (retailerId) => {
    const url = `${config.backendUrl}institutionalsalesservice/getnearbystore/?advancedSearch=true&retailerId=${retailerId}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  };

  static getProductsInStore = (retailerId, offset = 0) => {
    const url = `${config.backendUrl}shopaggregator/saathi/productsinstore/?saathi_id=${retailerId}&limit=10&offset=${offset}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  };
  static getOKRMapping = () => {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }institutionalsalesservice/v2/okrmapping/?farmerId=${localStorage.getItem(
        `farmerId`
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        ...this.getHeaders(),
        source: "B2B",
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ data: OKRMapping });
      });
    }
  };

  static savePartnerStatuses = (partnerStatusesResponse) => {
    if (partnerStatusesResponse?.status) {
      localStorage.setItem("partnerStatus", partnerStatusesResponse?.status);
      if (partnerStatusesResponse?.subStatus) {
        localStorage.setItem(
          "partnerSubStatus",
          partnerStatusesResponse?.subStatus
        );
      }
      // if (partnerStatusesResponse?.showPrices) {
      localStorage.setItem(
        "partnerPricingEnabled",
        partnerStatusesResponse?.showPrices
      );
      // }
    }
  };

  static isProductPriceBlocked = (product) => {
    if (product?.sellingPrice === 4) {
      return true;
    } else {
      return false;
    }
  };

  static smudgePriceDueToOnboardingInProgress = () => {
    let pricingEnabled = localStorage.getItem("partnerPricingEnabled");
    let partnerStatus = localStorage.getItem("partnerStatus");
    let partnerSubStatus = localStorage.getItem("partnerSubStatus");
    if (
      partnerStatus === "UNVERIFIED" &&
      (partnerSubStatus === "DOCUMENTS_UPLOAD_PENDING" ||
        partnerSubStatus === "DOCUMENTS_SUBMITTED") &&
      (pricingEnabled === "false" || pricingEnabled === null)
    ) {
      return true;
    } else {
      return false;
    }
  };

  static checkIfPriceToBeSmudged = (product) => {
    if (
      this.smudgePriceDueToOnboardingInProgress() ||
      this.isProductPriceBlocked(product)
    ) {
      return true;
    } else {
      return false;
    }
  };

  static getSmudgePriceStyle = () => {
    return "blur(4.5px)";
  };
  static applySmudgedPriceFilter = (product) => {
    if (this.checkIfPriceToBeSmudged(product)) {
      return this.getSmudgePriceStyle();
    } else {
      return "none";
    }
  };

  static getSmudgeBannerStyle = () => {
    return "blur(9px)";
  };
  static applySmudgedBannerFilter = () => {
    if (this.checkIfPriceToBeSmudged()) {
      return this.getSmudgeBannerStyle();
    } else {
      return "none";
    }
  };

  static getAnonymousAuthToken = () => {
    if (useMocks === false) {
      const url = `${config.backendUrl}userservice/anonauthtoken/`;
      return HTTPService.makeXHRRequest(url, "POST", {}, null);
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: anonymousAuthTokenMock });
        }, 1000);
      });
    }
  };

  static isOtpVerified = () => {
    return (
      localStorage.getItem(`onboardingPartnerMobileNumber`) !== "" &&
      localStorage.getItem(`onboardingPartnerMobileNumber`) !== undefined &&
      localStorage.getItem(`onboardingPartnerMobileNumber`) !== null &&
      localStorage.getItem("otpVerified") === "true"
    );
  };

  static hasFilledPartnerDetails = () => {
    return (
      this.isOtpVerified() &&
      localStorage.getItem(`partnerStatus`) === "UNVERIFIED" &&
      localStorage.getItem(`partnerSubStatus`) === "DOCUMENTS_UPLOAD_PENDING"
    );
  };

  static fieldTeamHasFilledPartnerDetails = () => {
    return (
      localStorage.getItem(`role`) === "fieldAgent" &&
      this.hasFilledPartnerDetails()
    );
  };

  static areMandatoryDocumentsSubmitted = () => {
    const documentSubmissionStatus = localStorage.getItem(
      "documentSubmissionStatus"
    );
    return (
      this.isOtpVerified() &&
      (documentSubmissionStatus === "PARTIAL" ||
        documentSubmissionStatus === "COMPLETED")
    );
  };

  static hasUploadedDocuments = () => {
    return (
      this.isOtpVerified() &&
      localStorage.getItem(`partnerStatus`) === "UNVERIFIED" &&
      localStorage.getItem(`partnerSubStatus`) === "DOCUMENTS_SUBMITTED"
    );
  };

  static isPartnerStatusUnverified = () => {
    return (
      this.isOtpVerified() &&
      localStorage.getItem(`partnerStatus`) === "UNVERIFIED"
    );
  };

  static areDocumentsVerified = () => {
    // checks if currently the user is in the documents verified state
    return (
      this.isOtpVerified() &&
      localStorage.getItem(`partnerStatus`) === "UNVERIFIED" &&
      localStorage.getItem(`partnerSubStatus`) === "DOCUMENTS_VERIFIED"
    );
  };

  static haveDocumentsBeenVerified = () => {
    // check if the user's onboarding documents have been verified once
    return (
      this.isOtpVerified() &&
      localStorage.getItem(`areDocumentsVerified`) === "true"
    );
  };

  static hasPaidSecurityDeposit = () => {
    return (
      this.isOtpVerified() &&
      localStorage.getItem(`partnerStatus`) === "UNVERIFIED" &&
      localStorage.getItem(`partnerSubStatus`) === "SECURITY_DEPOSIT_PAID"
    );
  };

  static showBottomBarForChurnSubStatus = () => {
    return (
      this.isOtpVerified() &&
      localStorage.getItem(`partnerStatus`) === "UNVERIFIED" &&
      (localStorage.getItem(`partnerSubStatus`) === "PROVISIONAL_CHURN" ||
        localStorage.getItem(`partnerSubStatus`) === "FINALLY_CHURNED")
    );
  };

  static hasOnboarded = () => {
    return (
      localStorage.getItem(`farmerId`) &&
      localStorage.getItem(`farmerId`) !== undefined &&
      localStorage.getItem(`otpVerified`) &&
      localStorage.getItem(`otpVerified`) === `true` &&
      localStorage.getItem(`X-Authorization-Token`) &&
      localStorage.getItem(`X-Authorization-Token`) !== undefined &&
      localStorage.getItem(`X-Authorization-Token`) !== "undefined" &&
      localStorage.getItem("partnerStatus") === "ACTIVE"
    );
  };

  static hasOnboardedAsFieldAgent = () => {
    return (
      localStorage.getItem(`fieldAgentMobileNumber`) &&
      localStorage.getItem(`fieldAgentMobileNumber`) !== undefined &&
      localStorage.getItem(`role`) &&
      localStorage.getItem(`role`) === "fieldAgent" &&
      localStorage.getItem(`otpVerified`) &&
      localStorage.getItem(`otpVerified`) === `true`
    );
  };

  static isInActiveGracePeriod = () => {
    return (
      localStorage.getItem("partnerStatus") === "INACTIVE" &&
      parseInt(localStorage.getItem("inactivatedOnDate")) > 0 &&
      !PartnerService.isPartnerInactiveSinceMoreThanNdays(
        parseInt(localStorage.getItem("inactivatedOnDate"))
      )
    );
  };

  static getPartnerPurchaseHistory = (
    fromDate,
    toDate,
    page,
    size,
    productType,
    getCategoryWiseSummary,
    getClassWiseSummary
  ) => {
    if (useMocks === false) {
      const url = `${config.backendUrl}onlineecommerceservice/${localStorage.getItem(
        "farmerId"
      )}/purchase-history/?page=${page}&since=${fromDate}&size=${size}&till=${toDate}&product_types=${productType}&get_categorywise_summary=${getCategoryWiseSummary}&get_classwise_summary=${getClassWiseSummary}
    `;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: Stats });
        }, 2000);
      });
    }
  };

  static checkIfFieldAgent = () => {
    return localStorage.getItem(`role`) === "fieldAgent";
  };

  static isFieldAssistedOnboarding = () => {
    const onboardingReferrerSalesOfficerEmailID = localStorage.getItem(
      "onboardingReferrerSalesOfficerEmailID"
    );
    if (
      onboardingReferrerSalesOfficerEmailID &&
      onboardingReferrerSalesOfficerEmailID !== ""
    ) {
      return true;
    }
    return false;
  };

  static isPartnerExternalCreditType = () => {
    const lendingProvider = localStorage
      .getItem("lendingProvider")
      ?.toLowerCase();

    if (lendingProvider === "rupifi") {
      return true;
    }
    return false;
  };

  static getLendingPartner = () => {
    const lendingProvider = localStorage
      .getItem("lendingProvider")
      ?.toUpperCase();

    if (lendingProvider && lendingProvider !== "") {
      if (lendingProvider === "AGROSTAR CREDIT") {
        return "AGROSTAR";
      }
      return lendingProvider;
    }
    return "AGROSTAR";
  };

  static selfOnboardingStates = [
    "Maharashtra",
    "Uttar Pradesh",
    "Rajasthan",
    "Gujarat",
    "Madhya Pradesh",
    "Bihar",
    "Haryana",
    "Karnataka",
    "Andhra Pradesh",
    "Chhattisgarh",
    "Telangana",
  ];

  static businessTypes = ["Proprietorship", "Private Limited", "Partnership"];

  static businessCategoriesList = [
    "Crop Nutrition",
    "Crop Protection",
    "Seeds",
    "Fertilizer",
    "Field Crop",
    "Hardware",
    "Animal Husbandry",
  ];
}
