import { Box, Tab, Tabs } from "@mui/material";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import AnalyticsService from "../../Services/AnalyticsService";
import { useState } from "react";
import Offers from "./Offers";
import Schemes from "./Schemes";
import OnboardingStatusNudge from "../OnboardPartner/OnboardingStatusNudge";
import Vyapaar from "./Vyapaar";
import useQuery from "../../Hooks/useQuery";
import BackDropLoader from "../Common/BackDropLoader";

const VyapaarPage = () => {
  const query = useQuery();
  const tabFromDeeplink = query.get("tab");
  const [currentTab, setCurrentTab] = useState(
    tabFromDeeplink && tabFromDeeplink !== "" ? tabFromDeeplink : `vyapaar`
  );
  const [loadingAgreementSigningWindow, setLoadingAgreementSigningWindow] =
    useState("not-started");

  const handleTabClickedEvent = (tabName, pageName) => {
    AnalyticsService.pushEvent(`Tab Clicked`, {
      tabName: tabName,
      page: pageName,
    });
    setCurrentTab(tabName);
  };

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Vyapaar`}
          backNavigation={true}
          backLink={"/dashboard"}
          showCart={true}
          showHisaab={true}
          toolTip={``}
        />
      </ErrorBoundary>

      <ErrorBoundary message={``}>
        <OnboardingStatusNudge
          setLoadingAgreementSigningWindow={setLoadingAgreementSigningWindow}
        />
      </ErrorBoundary>

      <ErrorBoundary message={``}>
        <Tabs
          value={currentTab}
          centered
          sx={{
            background: "#ffffff",
            margin: "16px",
            marginBottom: "16px",
            padding: "0px 24px",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
            borderRadius: "20px",
          }}
        >
          <Tab
            label="Vyapaar"
            value={"vyapaar"}
            sx={{
              width: "33.3%",
              borderBottom: `${
                currentTab === `vyapaar` ? `1px solid #9D2123` : `none`
              }`,
              color: `${currentTab === `vyapaar` ? `#9D2123` : `#333333`}`,
              opacity: `${currentTab === `vyapaar` ? `1` : `0.6`}`,
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "20px 16px",
            }}
            onClick={() => {
              handleTabClickedEvent("vyapaar", "vyapaar");
            }}
          />
          <Tab
            label="Schemes"
            value={"schemes"}
            sx={{
              width: "33.3%",
              borderBottom: `${
                currentTab === `schemes` ? `1px solid #9D2123` : `none`
              }`,
              color: `${currentTab === `schemes` ? `#9D2123` : `#333333`}`,
              opacity: `${currentTab === `schemes` ? `1` : `0.6`}`,
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "20px 16px",
            }}
            onClick={() => {
              handleTabClickedEvent("schemes", "vyapaar");
            }}
          />
          <Tab
            label="Offers"
            value={"offers"}
            sx={{
              width: "33.3%",
              borderBottom: `${
                currentTab === `offers` ? `1px solid #9D2123` : `none`
              }`,
              color: `${currentTab === `offers` ? `#9D2123` : `#333333`}`,
              opacity: `${currentTab === `offers` ? `1` : `0.6`}`,
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "20px 16px",
            }}
            onClick={() => {
              handleTabClickedEvent("offers", "vyapaar");
            }}
          />
        </Tabs>
      </ErrorBoundary>

      {currentTab === "schemes" && <Schemes />}
      {currentTab === "vyapaar" && <Vyapaar />}
      {currentTab === "offers" && (
        <Box sx={{ marginTop: "16px" }}>
          <Offers />
        </Box>
      )}
      <BackDropLoader open={loadingAgreementSigningWindow === "started"} />
    </>
  );
};

export default VyapaarPage;
