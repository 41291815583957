import SectionTitle from "../Common/SectionTitle";
import ErrorBoundary from "../Common/ErrorBoundary";
import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import AnalyticsService from "../../Services/AnalyticsService";
import PurchaseDetailsTable from "./PurchaseDetailsTable";
import AmountUtils from "../../Utils/AmountUtils";

const PurchaseDetails = (props) => {
  const handleTabClickedEvent = (tabName, pageName) => {
    AnalyticsService.pushEvent(`Tab Clicked`, {
      tabName: tabName,
      page: pageName,
    });
    props?.setCurrentTableTab(tabName);
  };

  const boxHeading = {
    fontSize: "10px",
    fontWeight: "800",
    color: "#1C1C1C90",
    textTransform: "uppercase",
  };
  const amountStyling = {
    fontSize: "12px",
    fontWeight: "700",
  };
  const gridItemStyling = {
    borderRadius: "16px",
    padding: "10px 4px",
  };

  return (
    <div>
      <SectionTitle title={"Purchase Details"} />
      <ErrorBoundary>
        <Box
          sx={{
            background: "#ffffff",
            borderTopLeftRadius: "20px !important",
            borderTopRightRadius: "20px",
            marginTop: "12px",
            padding: "1px",
          }}
        >
          <Tabs
            value={props?.currentTableTab}
            centered
            TabIndicatorProps={{
              sx: {
                height: "1px",
              },
            }}
            sx={{
              background: "#FFFFFF",
              margin: "10px",
              boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
              borderRadius: "16px",
            }}
          >
            <Tab
              label="Category Wise"
              value={"categoryWiseSale"}
              sx={{
                width: "50%",
                padding: "0px !important",

                borderBottom: `${
                  props?.currentTableTab === `categoryWiseSale`
                    ? `1px solid #9D2123`
                    : `none`
                }`,
                color: `${
                  props?.currentTableTab === `categoryWiseSale`
                    ? `#9D2123`
                    : `#333333`
                }`,
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: `${
                  props?.currentTableTab === `categoryWiseSale` ? `700` : `400`
                }`,
              }}
              onClick={() =>
                handleTabClickedEvent("categoryWiseSale", "vyapaar")
              }
            />
            <Tab
              label="Class Wise"
              value={"classWiseSale"}
              sx={{
                padding: "0px !important",
                width: "50%",
                borderBottom: `${
                  props?.currentTableTab === `classWiseSale`
                    ? `1px solid #9D2123`
                    : `none`
                }`,
                color: `${
                  props?.currentTableTab === `classWiseSale`
                    ? `#9D2123`
                    : `#4F4F4F`
                }`,
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: `${
                  props?.currentTableTab === `classWiseSale` ? `700` : `400`
                }`,
              }}
              onClick={() => handleTabClickedEvent("classWiseSale", "vyapaar")}
            />
          </Tabs>
        </Box>
        <PurchaseDetailsTable
          categoryWisePurchaseDetails={props?.categoryWisePurchaseDetails}
          classWisePurchaseDetails={props?.classWisePurchaseDetails}
          currentTableTab={props?.currentTableTab}
        />
        <Box
          sx={{
            backgroundColor: "#ffffff",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            padding: "10px",
          }}
        >
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              xs={3.8}
              sx={{
                ...gridItemStyling,
                border: "1px solid #00733E20",
                background: "#00733E10",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  ...boxHeading,
                }}
              >
                Total Purchase
              </Typography>
              <Typography
                sx={{
                  ...amountStyling,
                  color: "#00733E",
                }}
              >
                ₹{AmountUtils.format(props?.totalPurchase)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3.8}
              sx={{
                ...gridItemStyling,
                border: "1px solid #9D212320",
                background: "#9D212310",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  ...boxHeading,
                }}
              >
                Total Return
              </Typography>
              <Typography
                sx={{
                  ...amountStyling,
                  color: "#9D2123",
                }}
              >
                ₹{AmountUtils.format(props?.totalReturn)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3.8}
              sx={{
                ...gridItemStyling,
                border: "1px solid #F8D14940",
                background: "#f8d14930",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  ...boxHeading,
                }}
              >
                Net Purchase
              </Typography>
              <Typography
                sx={{
                  ...amountStyling,
                  color: "#A06600",
                }}
              >
                ₹{AmountUtils.format(props?.netPurchase)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ErrorBoundary>
    </div>
  );
};

export default PurchaseDetails;
