import React, { useEffect, useRef, useState } from "react";

import ErrorBoundary from "../Common/ErrorBoundary";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowRight,
  ArrowRightAlt,
  CheckCircle,
  CheckCircleOutline,
  Close,
  CurrencyRupee,
  CurrencyRupeeOutlined,
  Error,
  HourglassBottom,
  Sync,
  SyncRounded,
} from "@mui/icons-material";
import useFocus from "../../Hooks/useFocus";
import PaymentService from "../../Services/PaymentService";
import _, { parseInt } from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";
import { useNavigate, useParams } from "react-router-dom";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import AndroidService from "../../Services/AndroidService";

const PaymentStatusDialog = (props) => {
  const { paymentId } = useParams();
  const navigate = useNavigate();
  const [paymentErrorCode, setPaymentErrorCode] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [loading, setLoading] = useState("not-started");
  const [amount, setAmount] = useState(null);
  const [type, setType] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [checkoutOrderType, setCheckoutOrderType] = useState(null);

  const handleClosePaymentStatus = () => {
    navigate("/hisaab");
  };

  useEffect(() => {
    let errorCode = null;
    setLoading("started");
    const { axiosRequest, abortController } =
      PaymentService.getPaymentStatus(paymentId);
    axiosRequest
      .then((responsePaymentStatus) => {
        if (responsePaymentStatus?.data?.responseData) {
          let paymentStatusData = responsePaymentStatus.data.responseData;
          if (paymentStatusData?.status) {
            if (paymentStatusData?.status === "paid") {
              setPaymentStatus("success");
              if (
                paymentStatusData?.meta?.paymentType === "order" ||
                paymentStatusData?.meta?.paymentType === "product"
              ) {
                var orderPaymentSuccessDetailsFromRazorpay = [];
                if (
                  localStorage.getItem(
                    "orderPaymentSuccessDetailsFromRazorpay"
                  ) &&
                  localStorage.getItem(
                    "orderPaymentSuccessDetailsFromRazorpay"
                  ) !== ""
                ) {
                  orderPaymentSuccessDetailsFromRazorpay = JSON.parse(
                    localStorage.getItem(
                      "orderPaymentSuccessDetailsFromRazorpay"
                    )
                  );
                }

                var lastPaymentDetails = _.find(
                  orderPaymentSuccessDetailsFromRazorpay,
                  (paymentDetails) => {
                    return (
                      paymentDetails.orderId ===
                      paymentStatusData?.meta?.orderId
                    );
                  }
                );

                if (lastPaymentDetails) {
                  // if successful payment for this orderId was already done before (partial settlement),
                  // update its "paymentSuccessCaptureEpoch" in localStorage
                  orderPaymentSuccessDetailsFromRazorpay = _.filter(
                    orderPaymentSuccessDetailsFromRazorpay,
                    (paymentDetails) => {
                      return (
                        paymentDetails.orderId !==
                        paymentStatusData?.meta?.orderId
                      );
                    }
                  );
                }

                orderPaymentSuccessDetailsFromRazorpay?.push({
                  paymentStatus: "success",
                  paymentSuccessCaptureEpoch: Date.now(),
                  orderId: paymentStatusData?.meta?.orderId,
                  productSalesGroup: paymentStatusData?.meta?.productSalesGroup,
                });
                localStorage.setItem(
                  "orderPaymentSuccessDetailsFromRazorpay",
                  JSON.stringify(orderPaymentSuccessDetailsFromRazorpay)
                );
              }
              setPaymentType(paymentStatusData?.meta?.paymentType);

              AndroidService.pushEvent("Payment Status Check Successful", {
                amount: parseInt(paymentStatusData.paidAmount) / 100,
                type: paymentStatusData?.meta?.paymentType,
                transactionId: paymentStatusData?.transactionId,
              });
              AnalyticsService.pushEvent("Payment Status Dialog Shown", {
                status: paymentStatusData?.status,
                amount: parseInt(paymentStatusData.paidAmount) / 100,
                type: paymentStatusData?.meta?.paymentType,
                transactionId: paymentStatusData?.transactionId,
              });
            } else if (paymentStatusData?.status === "attempted") {
              setPaymentStatus("failure");
              errorCode = "PAYMENT_API_STATUS_ATTEMPTED";
            } else {
              setPaymentStatus("waiting");
              errorCode = "PAYMENT_API_STATUS_NOT_PAID";
            }
          } else {
            setPaymentStatus("waiting");
            errorCode = "PAYMENT_API_STATUS_NOT_PAID";
          }

          if (paymentStatusData?.paidAmount) {
            setAmount(parseInt(paymentStatusData.paidAmount) / 100);
          }
          if (paymentStatusData?.transactionId) {
            setTransactionId(paymentStatusData.transactionId);
          }
          if (paymentStatusData?.meta?.paymentType) {
            setType(paymentStatusData?.meta?.paymentType);
          }
          if (paymentStatusData?.meta?.checkoutOrderType) {
            setCheckoutOrderType(paymentStatusData?.meta?.checkoutOrderType);
          }
        } else {
          setPaymentStatus("failure");
          errorCode = "PAYMENT_API_NO_RESPONSE_DATA";
        }
        if (errorCode) {
          setPaymentErrorCode(errorCode);
          AnalyticsService.pushEvent("Payment Status Check Failed", {
            errorCode: errorCode,
          });
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setPaymentStatus("failure");
          setLoading("done");
          errorCode = "PAYMENT_API_FAILED";
          setPaymentErrorCode(errorCode);
          AnalyticsService.pushEvent("Payment Status Check Failed", {
            errorCode: errorCode,
          });
          AnalyticsService.pushEvent("Payment Status Dialog Shown", {
            status: paymentStatus,
            errorCode: errorCode,
          });
        }
      })
      .finally(() => {});
    return () => abortController.abort();
  }, [paymentId]);

  return (
    <Dialog fullWidth={true} fullScreen={true} open={true}>
      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "95vh" }}
      >
        <Box
          sx={{
            background:
              paymentStatus === "success"
                ? "#E6F2EC"
                : paymentStatus === "waiting"
                  ? "#FAEFDE"
                  : "#FAEEEE",
            height: "92vh",
          }}
        >
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <IconButton
                href={
                  paymentType === "due"
                    ? `/hisaab`
                    : paymentType === "order"
                      ? checkoutOrderType === "FIELD-APP-SELF-CHECKOUT"
                        ? `/`
                        : `/hisaab/transactions`
                      : paymentType === "securitydeposit"
                        ? `/`
                        : paymentType === "product"
                          ? `/hisaab/transactions`
                          : `/hisaab`
                }
                size={"large"}
                sx={{ color: "#757575", marginLeft: "6px" }}
                onClick={() => {
                  AnalyticsService.pushEvent("Payment Status Dialog Closed", {
                    amount: amount,
                    type: type,
                    transactionId: transactionId,
                    errorCode: paymentErrorCode,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>

          {paymentStatus === "success" && (
            <Box>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "15vh" }}
              >
                <Grid item>
                  <img src={`/icons/success.svg`} alt="" />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "3vh" }}
              >
                <Grid item>
                  <Typography
                    variant={"h6"}
                    align={"center"}
                    color={"secondary"}
                    sx={{ fontWeight: "700" }}
                  >
                    Payment Successful
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "1vh" }}
              >
                <Grid item>
                  <Typography variant={"body2"} align={"center"}>
                    Paid Amount : <b>₹ {amount}</b>
                  </Typography>
                  <Typography variant={"body2"} align={"center"}>
                    Payment Type : <b> {_.capitalize(type)}</b>
                  </Typography>
                  <Typography variant={"body2"} align={"center"}>
                    Your Transaction ID : <b>{transactionId}</b>
                  </Typography>
                  {/* {paymentType === "securitydeposit" && (
                    <Typography
                      variant={"body2"}
                      align={"center"}
                      sx={{ padding: "16px" }}
                    >
                      You have unlocked a credit limit of ₹
                      <b>{new Intl.NumberFormat("en-IN").format(amount)}</b>.
                      You will be able to use this limit soon.
                    </Typography>
                  )} */}
                </Grid>
              </Grid>
              <Button
                href={
                  paymentType === "due"
                    ? `/hisaab`
                    : paymentType === "order"
                      ? checkoutOrderType === "FIELD-APP-SELF-CHECKOUT"
                        ? `/orders`
                        : `/hisaab/transactions`
                      : paymentType === "securitydeposit"
                        ? `/dashboard`
                        : paymentType === "product"
                          ? `/hisaab/transactions`
                          : `/wallet/real-cash`
                }
                variant={"contained"}
                fullWidth={true}
                color={"secondary"}
                endIcon={<ArrowRightAlt />}
                onClick={() => {
                  AnalyticsService.pushEvent(
                    `Payment Success Dialog Go To ${
                      paymentType === "due"
                        ? `Hisaab`
                        : paymentType === "order"
                          ? checkoutOrderType === "FIELD-APP-SELF-CHECKOUT"
                            ? `Order History`
                            : `Hisaab Transactions`
                          : paymentType === "securitydeposit"
                            ? `Home`
                            : paymentType === "product"
                              ? `Transactions`
                              : `Wallet`
                    } Clicked`,
                    {
                      amount: parseInt(props.paymentData?.paidAmount) / 100,
                      mode: props.paymentData?.meta?.paymentType,
                      transactionId: props.paymentData?.transactionId,
                    }
                  );
                }}
                sx={{
                  marginTop: "8px",
                  position: "fixed",
                  bottom: 0,
                  borderRadius: "0",
                  zIndex: 0,
                  height: "8vh",
                }}
              >
                Go to&nbsp;
                {paymentType === "due"
                  ? `Hisaab`
                  : paymentType === "order"
                    ? checkoutOrderType === "FIELD-APP-SELF-CHECKOUT"
                      ? `Order History`
                      : `Transactions`
                    : paymentType === "securitydeposit"
                      ? `Home`
                      : paymentType === "product"
                        ? `Transactions`
                        : `Wallet`}
              </Button>
            </Box>
          )}

          {paymentStatus === "waiting" && (
            <Box>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "20vh" }}
              >
                <Grid item>
                  <Sync color={"warning"} sx={{ fontSize: "60px" }} />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "3vh" }}
              >
                <Grid item>
                  <Typography
                    variant={"h6"}
                    align={"center"}
                    sx={{ color: "#ed6c02", fontWeight: "700" }}
                  >
                    Payment is Processing
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "1vh" }}
              >
                <Grid item xs={9}>
                  <Typography
                    variant={"body1"}
                    align={"center"}
                    sx={{ color: "#757575" }}
                  >
                    Please wait for some time &<br /> click to check again.
                  </Typography>
                </Grid>
              </Grid>

              <Button
                variant={"contained"}
                fullWidth={true}
                color={"secondary"}
                onClick={() => {
                  AnalyticsService.pushEvent(
                    "Payment Status Dialog Refresh Status Clicked",
                    {
                      errorCode: paymentErrorCode,
                      amount: amount,
                      type: type,
                      transactionId: transactionId,
                    }
                  );
                }}
                href={`/hisaab/payment-status/${paymentId}`}
                sx={{
                  marginTop: "8px",
                  position: "fixed",
                  bottom: 0,
                  borderRadius: "0",
                  zIndex: 0,
                  height: "8vh",
                }}
              >
                Check Again
              </Button>
            </Box>
          )}

          {paymentStatus === "failure" && (
            <Box>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "20vh" }}
              >
                <Grid item>
                  <Error color={"error"} sx={{ fontSize: "60px" }} />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "3vh" }}
              >
                <Grid item>
                  <Typography
                    variant={"h6"}
                    align={"center"}
                    sx={{ fontWeight: "700" }}
                    color={"error"}
                  >
                    Sorry, Payment Failed.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "1vh" }}
              >
                <Grid item xs={9}>
                  <Typography
                    variant={"body1"}
                    align={"center"}
                    sx={{ color: "#757575" }}
                  >
                    Your transaction has failed, if you have been charged we
                    will contact you. Please click to retry.
                  </Typography>
                </Grid>
              </Grid>
              {paymentErrorCode && (
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ marginTop: "3vh" }}
                >
                  <Grid item>
                    <Typography
                      variant={"caption"}
                      align={"center"}
                      color={"error"}
                      sx={{ color: "#222222" }}
                    >
                      {paymentErrorCode}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Button
                variant={"contained"}
                fullWidth={true}
                color={"secondary"}
                onClick={() => {
                  AnalyticsService.pushEvent(
                    "Payment Status Dialog Retry Payment Clicked",
                    {
                      errorCode: paymentErrorCode,
                    }
                  );
                }}
                href={`/hisaab?payment=true&type=${
                  paymentType === "due" ? `due` : `advance`
                }`}
                sx={{
                  marginTop: "8px",
                  position: "fixed",
                  bottom: 0,
                  borderRadius: "0",
                  zIndex: 0,
                  height: "8vh",
                }}
              >
                Retry Payment
              </Button>
            </Box>
          )}
        </Box>
      </BlockUi>
    </Dialog>
  );
};

export default PaymentStatusDialog;
