import React from "react";
import ReactPlayer from "react-player";
import AnalyticsService from "../../Services/AnalyticsService";

const ReactVideoPlayer = (props) => {
  const [playingMediaId, setPlayingMediaId] = React.useState(null);

  React.useEffect(() => {
    window.addEventListener("storage", function () {
      setPlayingMediaId(localStorage.getItem("playingMediaId"));
    });

    return () =>
      window.addEventListener("storage", function () {
        setPlayingMediaId(localStorage.getItem("playingMediaId"));
      });
  }, []);

  return (
    <ReactPlayer
      style={props.style}
      url={props.video.src}
      ref={props.ref}
      playing={
        props?.playing
          ? props?.playing
          : props.video.src?.split("?v=")?.[1] === playingMediaId
      }
      muted={!props.muted || props.muted === false ? false : true}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={false}
      title="Embedded youtube"
      playsinline={true}
      width={"100%"}
      className={props.section ? "reactPlayer" : ""}
      height={props.height || "186px"}
      controls={true}
      volume={props.volume}
      light={props.light}
      config={{
        youtube: {
          playerVars: {
            enablejsapi: 1,
            autoplay: props.autoplay ? 1 : 0,
            rel: 0,
            modestbranding: 1,
            playsinline: 1,
            fs: props.fullscreen ? 1 : 0,
            controls: props.controls,
          },
          embedOptions: {},
        },
      }}
      onClickPreview={() => {
        let videoId = props?.src?.split("?v=")?.[1];
        localStorage.setItem("playingMediaId", videoId);
        window.setTimeout(() => {
          setPlayingMediaId(videoId);
        }, 300);
      }}
      onEnded={() => {
        AnalyticsService.pushEvent(
          props?.playbackEndedEventName || "Video Playback Ended",
          props?.eventProperties || {}
        );
      }}
      onPlay={() => {
        localStorage.setItem(
          "playingMediaId",
          props.video.src?.split("?v=")?.[1]
        );
        window.dispatchEvent(new Event("storage"));
        AnalyticsService.pushEvent(
          props?.playbackStartedEventName || "Video Playback Started",
          props?.eventProperties || {}
        );
      }}
      onPause={() => {
        AnalyticsService.pushEvent(
          props?.playbackPausedEventName || "Video Playback Paused",
          props?.eventProperties || {}
        );
      }}
      onReady={() => {
        if (props.handlePlaybackReady) {
          props.handlePlaybackReady();
        }
      }}
      onError={() => {
        if (props.handlePlaybackError) {
          props.handlePlaybackError();
        }
        AnalyticsService.pushEvent(
          props?.playbackErrorEventName || "Video Playback Error",
          props?.eventProperties || {}
        );
      }}
    />
  );
};

export default ReactVideoPlayer;
