import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ArrowForwardTwoTone } from "@mui/icons-material";
import AmountUtils from "../../Utils/AmountUtils";

const useStyles = makeStyles((theme) => ({
  placeRequestButton: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "600px",
    },
    marginTop: theme.spacing(1),
    position: "fixed",
    bottom: 0,
    borderRadius: "0",
    zIndex: 1,
    "&:disabled": {
      backgroundColor: `#CACACA`,
    },
  },
}));

function PlaceRequest(props) {
  const classes = useStyles();
  const disablePlaceRequest =
    (props?.canPlaceOrder === false && props?.isFieldAgent) ||
    !props.cartProducts ||
    props.cartProducts.length === 0 ||
    props.hasMinimumOrderValueRestriction ||
    props.isLoading;
  const shouldShowPlaceRequest =
    !props.enabledSelfCheckout ||
    (props.enabledSelfCheckout && props.canPlaceOrder === false) ||
    (props.currentTab === "cash" && props.isPaymentOverThreshold);
  return (
    <div>
      <Button
        size={`large`}
        variant={"contained"}
        color={shouldShowPlaceRequest ? "secondary" : "primary"}
        fullWidth
        type={`submit`}
        disabled={disablePlaceRequest}
        className={classes.placeRequestButton}
        endIcon={<ArrowForwardTwoTone />}
      >
        {shouldShowPlaceRequest ? "Place Request" : "Place Order"}
        {props.hasMinimumOrderValueRestriction && (
          <div
            style={{
              position: "absolute",
              top: "-20px",
              left: "0",
              width: "100%",
              background: "#ebd2d3",
              color: "#9D2123",
            }}
          >
            <Typography
              variant={`body2`}
              textAlign={`center`}
              sx={{ fontSize: "14px" }}
            >
              <b>
                Minimum order value should be ₹
                {AmountUtils.format(props.minimumOrderValue)}
              </b>
            </Typography>
          </div>
        )}
      </Button>
    </div>
  );
}

export default PlaceRequest;
