import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";
import { Close, Error } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import makeStyles from "@mui/styles/makeStyles";
import SignAgreementButton from "../Dashboard/SignAgreementButton";
import HisaabService from "../../Services/HisaabService";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    borderRadius: "0",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "0.56px",
    textTransform: "uppercase",
  },
}));

const AgreementSigningFailure = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const query = useQuery();
  const documentId = query.get("documentId");
  const [loadingAgreementSigningWindow, setLoadingAgreementSigningWindow] =
    useState("not-started");
  const [agreementSigningStatus, setAgreementSigningStatus] = useState("");

  useEffect(() => {
    const farmerId = localStorage.getItem("farmerId");
    const { axiosRequest, abortController } =
      HisaabService.getInstitutionDetails(farmerId);
    axiosRequest
      .then((institutionalDetailsResponse) => {
        const institutionalDetails =
          institutionalDetailsResponse?.data?.responseData;
        if (institutionalDetails?.agreementSigningStatus === "SUCCESS") {
          setAgreementSigningStatus("SUCCESS");
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
        }
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    AnalyticsService.pushEvent("Dialog Shown", {
      type: "Agreement Signing Failure",
      documentId: documentId,
    });
  }, []);

  return (
    <Dialog fullWidth={true} fullScreen={true} open={true}>
      <BlockUi
        tag="div"
        blocking={loadingAgreementSigningWindow === "started"}
        message="Please wait..."
        keepInView={true}
      >
        <Box sx={{ background: "#FAEEEE", height: "92vh" }}>
          <Grid
            container
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Grid item xs={10} />
            <Grid item xs={2}>
              <IconButton
                size={"large"}
                href={"/dashboard"}
                sx={{ color: "#757575" }}
                onClick={() => {
                  AnalyticsService.pushEvent("Button Clicked", {
                    type: "Agreement Signing Failure Close Icon",
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginTop: "17vh" }}
          >
            <Grid item>
              <img
                src="/icons/payment-status-failed.svg"
                height="150px"
                alt=""
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginTop: "1vh" }}
          >
            <Grid item>
              <Typography
                variant={"h6"}
                align={"center"}
                sx={{ fontWeight: "700" }}
                color={"primary"}
              >
                Failed to sign agreement
              </Typography>
              <Typography variant={"body2"} align={"center"}>
                Document ID - <b>{documentId}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginTop: "1vh" }}
          >
            <Grid item xs={9}>
              <Typography
                variant={"body1"}
                align={"center"}
                sx={{ color: "#757575" }}
              >
                Agreement signing has failed.
                <br />
                {agreementSigningStatus !== "SUCCESS"
                  ? "Please retry again."
                  : "You have already signed the agreement."}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            height: "8vh",
            display: "flex",
          }}
        >
          <Button
            variant={"outlined"}
            fullWidth={true}
            color={"secondary"}
            className={classes.buttonStyle}
            sx={{
              background: "#FFF",
              "&:focus": {
                backgroundColor: `#F5F5F5`,
              },
              "&:hover": {
                backgroundColor: `#F5F5F5`,
              },
            }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Go Back
          </Button>
          {agreementSigningStatus !== "SUCCESS" && (
            <SignAgreementButton
              page={"agreementSigningFailure"}
              setLoadingAgreementSigningWindow={
                setLoadingAgreementSigningWindow
              }
            />
          )}
        </Box>
      </BlockUi>
    </Dialog>
  );
};

export default AgreementSigningFailure;
