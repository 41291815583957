import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Grid, Typography } from "@mui/material";

import ErrorBoundary from "./ErrorBoundary";
import AmountUtils from "../../Utils/AmountUtils";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  productIcon: {
    height: 40,
  },
  freeProductIcon: {
    height: 35,
  },
  iconWrapper: {
    width: "45px",
    height: "45px",
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001A",
    borderRadius: "5px",
    marginRight: "8px",
    marginBottom: "2px",
    flexGrow: 0,
    flexShrink: 0,
  },
  freeIcon: {
    position: "relative",
    height: 16,
    top: "3px",
    right: "-4px",
  },
  entitlementProductQuantity: {
    color: "#666666",
    fontSize: "12px",
    position: "relative",
  },
  entitlementName: {
    fontWeight: 400,
    fontSize: "14px",
    // lineHeight: "1.25",
    display: "flex",
    alignItems: "center",
  },
  productName: {
    fontSize: "14px",
    lineHeight: "1.25",
  },
}));

const OfferEntitlements = ({ entitlement, product }) => {
  const classes = useStyles();
  return (
    <ErrorBoundary message={``}>
      <Box>
        {entitlement.type === "freeProducts" && (
          <Grid container flexWrap="nowrap">
            <Box className={classes.iconWrapper}>
              <img
                src={entitlement.product?.imageUrl || "/icons/free-gift.svg"}
                alt=""
                className={
                  entitlement.product?.imageUrl
                    ? classes.productIcon
                    : classes.freeProductIcon
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant={"body2"} className={classes.productName}>
                {entitlement.product.productName}
              </Typography>
              <Typography className={classes.entitlementProductQuantity}>
                Quantity:<b>{entitlement.product.quantity}</b>
                <img
                  src={`/icons/free.svg`}
                  alt="free"
                  className={classes.freeIcon}
                />
              </Typography>
            </Box>
          </Grid>
        )}

        {entitlement.type === "cashDiscount" && (
          <Grid container flexWrap="nowrap">
            <Box className={classes.iconWrapper}>
              <img src={`/icons/directDiscount.svg`} alt="" />
            </Box>
            <Typography
              className={classes.entitlementName}
              sx={{ filter: PartnerService.applySmudgedPriceFilter(product) }}
            >
              <b>₹{AmountUtils.format(entitlement.value)}</b>&nbsp;Direct
              discount
            </Typography>
          </Grid>
        )}

        {["directCashback", "cashBack"].includes(entitlement.type) && (
          <Grid container flexWrap="nowrap">
            <Box className={classes.iconWrapper}>
              <img src={`/icons/cashback.svg`} alt="" />
            </Box>
            <Typography
              className={classes.entitlementName}
              sx={{ filter: PartnerService.applySmudgedPriceFilter(product) }}
            >
              <b>₹{AmountUtils.format(entitlement.value)}</b>&nbsp;Direct
              Cashback
            </Typography>
          </Grid>
        )}

        {entitlement.type === "percentDiscount" && (
          <Grid container flexWrap="nowrap">
            <Box className={classes.iconWrapper}>
              <img src={`/icons/percentDiscount.svg`} alt="" />
            </Box>
            <Typography className={classes.entitlementName}>
              <b>{entitlement.value}%</b>&nbsp;Discount
            </Typography>
          </Grid>
        )}

        {entitlement.type === "percentCashback" && (
          <Grid container flexWrap="nowrap">
            <Box className={classes.iconWrapper}>
              <img src={`/icons/percentCashback.svg`} alt="" />
            </Box>
            <Typography
              className={classes.entitlementName}
              sx={{ filter: PartnerService.applySmudgedPriceFilter(product) }}
            >
              <b>{entitlement.value}%</b>&nbsp;Cashback
            </Typography>
          </Grid>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default OfferEntitlements;
