import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import moment from "moment";

const useStyles = makeStyles(() => ({
  weatherWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    background: "#000",
    zIndex: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  weatherContent: {
    background: "#F2F2F2",
    width: "inherit",
    height: "inherit",
    padding: "0 20px",
    paddingBottom: "10px",
    paddingTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  weatherIconBottom: {
    display: "flex",
    justifyContent: "center",
    flex: "none",
    marginTop: "10px",
  },
  forecastWrapper: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flex: "1",
    flexFlow: "column wrap",
  },
  currentWeatherWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "30px",
    margin: "10px 0",
    background: "white",
    borderRadius: "20px",
    padding: "10px 20px",
  },
  weatherForecastCard: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
    background: "white",
    borderRadius: "20px",
    padding: "10px 20px",
    width: "100%",
  },
  forecastSidebar: {
    display: "flex",
    minWidth: "120px",
    gap: "10px",
    alignItems: "center",
  },
}));

const WeatherForecastCard = ({ weather }) => {
  const classes = useStyles();
  return (
    <Box className={classes.weatherForecastCard}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="body2"
          style={{ fontSize: "14px", fontWeight: 700 }}
        >
          {formatDate(new Date(weather.epoch))}
        </Typography>
        <Typography variant="body2" style={{ fontSize: "14px" }}>
          {weather?.text || ""}
        </Typography>
        {weather?.alertIcon && weather?.alertText && (
          <span style={{ display: "flex" }}>
            <img src={weather.alertIcon} height="15px" alt=""></img>
            <Typography
              variant="body2"
              style={{
                paddingLeft: "5px",
                fontSize: "12px",
                color: "#999",
              }}
            >
              {weather.alertText || ""}
            </Typography>
          </span>
        )}
      </Box>
      <Box className={classes.forecastSidebar}>
        <img src={weather.imageUrl} height="60" alt=""></img>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2">
            {weather.temperature.max}º {weather.temperature.unit}
          </Typography>
          <Typography variant="body2" sx={{ color: "#999" }}>
            {weather.temperature.min}º {weather.temperature.unit}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const CurrentWeatherCard = ({ weather }) => {
  const classes = useStyles();
  return (
    <Box className={classes.currentWeatherWrapper}>
      <img src={weather.weather.imageUrl} height="100" alt="current_weather" />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="body2"
          style={{ fontSize: "24px", fontWeight: 700 }}
        >
          {weather?.weather?.text || ""}
        </Typography>
        <Typography variant="body2" style={{ fontSize: "14px" }}>
          {formatDate(new Date(weather.weather.epoch))}
        </Typography>
        <Typography variant="body2" style={{ fontSize: "18px" }}>
          {weather.weather.temperature.max}º {weather.weather.temperature.unit}
          {` / `}
          <span style={{ color: "#999" }}>
            {weather.weather.temperature.min}º{" "}
            {weather.weather.temperature.unit}
          </span>
        </Typography>
        {weather?.location && (
          <Typography
            variant="body2"
            style={{ fontSize: "14px", fontWeight: 700 }}
          >
            {weather.location.name?.localised
              ? `${weather.location.name.localised}, `
              : ", "}
            {weather.location.area?.localised || ""}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

function formatDate(date) {
  return moment(date).format("dddd, D MMMM");
}

const Renderer = ({ story, config }) => {
  const { width, height } = config;
  const weather = story.originalContent;
  const classes = useStyles();

  return (
    <Box
      className={classes.weatherWrapper}
      sx={{ width: width, height: height }}
    >
      <Box className={classes.weatherContent}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "none",
            height: "fit-content",
          }}
        >
          <Typography
            variant="body1"
            style={{ fontSize: "18px", fontWeight: 700 }}
          >
            Today
          </Typography>
          <CurrentWeatherCard weather={weather.current_weather} />
          <Typography
            variant="body1"
            style={{ fontSize: "18px", fontWeight: 700 }}
          >
            Forecast
          </Typography>
        </Box>

        <Box className={classes.forecastWrapper}>
          {_.map(weather.upcoming_weather.n_days_forecast, (weather, index) => (
            <WeatherForecastCard weather={weather} key={index} />
          ))}
        </Box>

        <Box className={classes.weatherIconBottom}>
          <img
            src={weather.current_weather.brought_to_by.icon}
            height="30"
            alt="brought_by"
          />
        </Box>
      </Box>
    </Box>
  );
};

export const Tester = (story) => {
  return {
    condition: story.type === "weather",
    priority: 2,
  };
};

const VideoRenderer = {
  renderer: Renderer,
  tester: Tester,
};
export default VideoRenderer;
