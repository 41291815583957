import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useEffect, useRef, useState } from "react";
import "../Hisaab/CustomDateRangeStyles.css";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

const VyapaarDateRange = (props) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: new Date(""),
      key: "selection",
    },
  ]);
  const [open, setOpen] = useState(true);
  const inputRef = useRef(null);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    document.addEventListener("click", handleHideOnClickOutside, true);
  }, []);

  const handleHideOnClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      props?.setSelectedDropdownFilterValue(props?.previousDropdownFilterValue);
      setOpen(false);
    }
  };

  const handleDateRangeChange = (dateRangeObject) => {
    setDateRange([dateRangeObject.selection]);

    if (clickCount === 0) {
      // First click (date range start selected)
      setClickCount(1);
    } else {
      // Second click (date range end selected)
      setClickCount(0);
      setOpen(false);
      props?.setSelectedDateRangeFilter([
        dateRangeObject?.selection?.startDate,
        dateRangeObject?.selection?.endDate,
      ]);
    }
  };

  return (
    <>
      <Box ref={inputRef} className="calendarWrapper">
        {open && (
          <DateRange
            onChange={(dateRangeObject) =>
              handleDateRangeChange(dateRangeObject)
            }
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            months={1}
            direction="horizontal"
            className="calendarElement"
            rangeColors={["#9D2123"]}
            maxDate={props?.maxDate}
            minDate={props?.minDate}
          />
        )}
      </Box>
    </>
  );
};

export default VyapaarDateRange;
