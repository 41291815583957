import React from "react";
import { Link } from "react-router-dom";

import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";

import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  imgStatic: {
    width: "100%",
    overflow: "hidden",
    display: "block",
    borderRadius: "10px !important",
  },
}));

function StaticBanner(props) {
  const classes = useStyles();

  const handleClickStaticBanners = () => {
    const staticBannerClickedEventData = {
      bannerType: props?.banner?.data?.type || "",
      skuList:
        props?.banner?.data?.data?.sku?.toString() ||
        props?.banner?.data?.data?.skus?.join(",") ||
        "",
      bannerCode: props?.banner?.bannerCode || "",
      imageUrl: props?.banner?.url || "",
      page: props?.page,
    };

    AnalyticsService.pushEvent(`Banner Clicked`, staticBannerClickedEventData);
  };

  return (
    <>
      <Grid
        container
        direction={`row`}
        justifyContent={`center`}
        spacing={0}
        className={classes.root}
        style={{
          marginTop: "-3px",
          paddingTop: "0px",
        }}
      >
        <Grid item xs={12}>
          {props.loadingBanners === true && (
            <Skeleton variant="rectangular" height={290} />
          )}

          {props.banner && props.banner.url && (
            <div onClick={() => handleClickStaticBanners()}>
              {props.banner?.data?.type === `PRODUCT_DETAILS` && (
                <>
                  {props.banner?.data?.data?.sku ? (
                    <Link
                      to={{
                        pathname: `/products/${props.banner.data.data.sku}`,
                        state: { from: window.location.pathname },
                      }}
                    >
                      <img
                        className={classes.imgStatic}
                        src={props.banner.url}
                        alt={`banner`}
                        style={props?.blur ? { filter: props.blur } : {}}
                      />
                    </Link>
                  ) : (
                    <img
                      className={classes.imgStatic}
                      src={props.banner.url}
                      alt={`banner`}
                      style={props?.blur ? { filter: props.blur } : {}}
                    />
                  )}
                </>
              )}

              {props.banner?.data?.type === `PRODUCT_LIST` && (
                <>
                  {props.banner?.data?.data?.skus ? (
                    <Link to={`/products-offer/${props.banner.data.data.skus}`}>
                      <img
                        className={classes.imgStatic}
                        src={props.banner.url}
                        alt={`banner`}
                        style={props?.blur ? { filter: props.blur } : {}}
                      />
                    </Link>
                  ) : (
                    <img
                      className={classes.imgStatic}
                      src={props.banner.url}
                      alt={`banner`}
                      style={props?.blur ? { filter: props.blur } : {}}
                    />
                  )}
                </>
              )}

              {props.banner?.data?.type === `EXTERNAL_WEB_PAGE` && (
                <a href={props.banner?.data?.data?.url}>
                  <img
                    className={classes.imgStatic}
                    src={props.banner.url}
                    alt={`banner`}
                    style={props?.blur ? { filter: props.blur } : {}}
                  />
                </a>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default StaticBanner;
