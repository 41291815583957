import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Navigation, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Card, CardActions, CardMedia } from "@mui/material";

import TopBar from "../Common/TopBar";
import ThemeButton from "./ThemeButton";
import EmptyError from "../EmptyError/EmptyError";
import ErrorBoundary from "../Common/ErrorBoundary";
import BannerService from "../../Services/BannerService";
import AnalyticsService from "../../Services/AnalyticsService";
import DocumentService from "../../Services/DocumentService";
import { PromotionService } from "../../Services/PromotionService";
import OfferShareAndDownload from "./OfferShareAndDownload";

const useStyles = makeStyles(() => ({
  pageBackground: {
    minHeight: "calc(100vh - 56px)",
    backgroundImage: "url(/greetingPageBackground.svg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  cardImage: {
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: "450px",
    zIndex: 1,
    borderRadius: "10px",
  },
  cardActions: {
    position: "relative",
    top: "-50px",
    padding: "24px",
    paddingTop: "70px",
    background: "rgb(0,0,0,0.4)",
    borderRadius: "30px",
  },
}));

export default function FestiveGreetings() {
  const classes = useStyles();
  const [banners, setBanners] = useState([]);
  const [activeTheme, setActiveTheme] = useState(null);
  const [shareText, setShareText] = useState("");
  const [activeThemeUrl, setActiveThemeUrl] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const source = localStorage.getItem("source");

  useEffect(() => {
    AnalyticsService.pushEvent(`Festive Greetings Page Viewed`, {
      page: "/festive-greetings",
    });
    const informationCB = setStoreAndGreetingInformation();
    return () => informationCB();
  }, []);

  function setStoreAndGreetingInformation() {
    setLoading("started");

    let storeName = "",
      storeAddress = "",
      storeMobileNumber = "",
      isLocalDetailAvailable = false,
      shareTextSource = "ENGLISH";
    const { axiosRequest, abortController } =
      DocumentService.getInstitutionId();
    axiosRequest
      .then((storeResponse) => {
        if (storeResponse?.data?.responseData) {
          const storeData = storeResponse?.data?.responseData;
          if (
            storeData?.nameInRegionalLanguage &&
            storeData?.addressInRegionalLanguage?.pincode
          ) {
            shareTextSource = source;
            isLocalDetailAvailable = true;
            storeName = storeData?.nameInRegionalLanguage;
            storeMobileNumber = storeData?.contacts?.mobileNumber;

            if (storeData?.addressInRegionalLanguage) {
              const address = storeData?.addressInRegionalLanguage;
              storeAddress = `${address?.landMark}, ${address?.village}, ${address?.taluka}, ${address?.district}`;
            }
          } else {
            storeName = storeData?.name;
            storeMobileNumber = storeData?.contacts?.mobileNumber;

            if (storeData?.address) {
              const address = storeData?.address;
              storeAddress = `${address?.landMark}, ${address?.village}, ${address?.taluka}, ${address?.district}`;
            }
          }

          const text = PromotionService.getFestiveShareText(
            shareTextSource,
            storeName,
            storeMobileNumber
          );
          setShareText(text);
        }
        const district = localStorage.getItem("district");
        const { axiosRequest } = BannerService.getBannersData(
          `GREETINGS_PAGE`,
          district
        );
        axiosRequest
          .then((bannerResponse) => {
            if (bannerResponse?.data?.responseData) {
              const banners = bannerResponse?.data?.responseData?.banners;
              if (banners?.length) {
                const newBannerData = banners?.map((banner) => ({
                  type: "IMAGE",
                  originalUrl: banner?.media[0]?.url,
                  shareText: banner?.templateTexts?.[0]
                    ?.replaceAll("`", "")
                    ?.replaceAll("{{comma}}", ",")
                    ?.replaceAll("{{storeName}}", storeName)
                    ?.replaceAll("{{storeAddress}}", storeAddress)
                    ?.replaceAll("{{storeMobileNumber}}", storeMobileNumber),
                  url: PromotionService.getOverlayUrlForFestiveGreeting(
                    banner?.media[0]?.url,
                    storeName,
                    storeAddress,
                    `${
                      isLocalDetailAvailable
                        ? PromotionService.getPhoneString(source)
                        : "Phone No:"
                    } ${storeMobileNumber}`
                  ),
                }));
                setActiveTheme(1);
                setActiveThemeUrl(newBannerData[0]?.url);
                setBanners(newBannerData);
              }
            }
            setLoading("done");
          })
          .catch((e) => {
            setLoading("done");
            console.log("Greeting API Failure: ", e?.message);
          });
      })
      .catch((e) => {
        if (e?.message !== "canceled") {
          setLoading("done");
          console.log("Institution API Failure: ", e?.message);
        }
      });
    return () => abortController.abort();
  }

  const handleThemeChange = (index) => {
    if (activeTheme === index + 1) return null;

    AnalyticsService.pushEvent(`Theme Change Button Clicked`, {
      page: "/festive-greetings",
      themeName: `Theme ${index + 1}`,
      themeUrl: banners[index]?.originalUrl,
    });
    setActiveThemeUrl(banners[index]?.url);
    setActiveTheme(index + 1);
  };

  return (
    <>
      <ErrorBoundary>
        <TopBar
          title={`Festive Greetings`}
          backNavigation={true}
          showCart={false}
          showHisaab={false}
        />
      </ErrorBoundary>
      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "90vh" }}
      >
        <div className={classes.pageBackground}>
          {activeTheme !== null ? (
            <>
              <ThemeButton
                themes={banners}
                activeTheme={activeTheme}
                handleThemeChange={handleThemeChange}
              />

              <Swiper
                modules={[Navigation, Virtual]}
                onSlideChange={(e) => {
                  handleThemeChange(e.activeIndex);
                }}
              >
                {banners?.map((banner, index) => (
                  <SwiperSlide key={index}>
                    <Card
                      sx={{
                        p: 3,
                        pt: 2,
                        background: "none",
                        boxShadow: "none",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={activeThemeUrl}
                        alt="greeting"
                        className={classes.cardImage}
                      />

                      <CardActions
                        disableSpacing
                        className={classes.cardActions}
                      >
                        <OfferShareAndDownload
                          index={activeTheme}
                          shareText={
                            banner?.shareText ? banner?.shareText : shareText
                          }
                          imageUrl={activeThemeUrl}
                          page="/festive-greetings"
                          banners={banners}
                        />
                      </CardActions>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : loading === "done" ? (
            <Box sx={{ p: "60px 30px 0" }}>
              <EmptyError
                image="EmptyPage.svg"
                text="Ooooops!"
                subText="Looks like there are no templates available at this moment.
                Please come back later."
                extraStyle={{
                  minHeight: "500px",
                  p: "0 20px",
                  borderRadius: "20px",
                  background: "#fff",
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </div>
      </BlockUi>
    </>
  );
}
