import React from "react";

import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  themeWrapper: {
    display: "flex",
    padding: "20px 24px 10px",
    overflowX: "scroll",
  },
  themeButton: {
    minWidth: "75px",
    height: "24px",
    padding: "4px 8px",
    marginRight: "5px",
    fontSize: "12px",
    fontWeight: "600",
    textTransform: "none",
    borderRadius: "10px",
  },
}));

export default function ThemeButton({
  themes,
  activeTheme,
  handleThemeChange,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.themeWrapper}>
      {themes?.map((theme, index) => (
        <Button
          key={index}
          variant="contained"
          color="primary"
          className={classes.themeButton}
          sx={{
            color: activeTheme === index + 1 ? "" : "rgb(0,0,0,0.6)",
            background: activeTheme === index + 1 ? "" : "#fff",
            "&:hover": {
              color: activeTheme === index + 1 ? "" : "rgb(0,0,0,0.6)",
              background: activeTheme === index + 1 ? "" : "#fff",
            },
          }}
          onClick={() => handleThemeChange(index)}
        >
          Theme {index + 1}
        </Button>
      ))}
    </Box>
  );
}
