import React from "react";

import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  productsLoaderWrapper: {
    marginTop: theme.spacing(0),
  },
}));

function ProductsLoader() {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={`xl`}>
        <Grid
          container
          direction={`row`}
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.productsLoaderWrapper}
          spacing={3}
        >
          <Grid item xs={6}>
            <Skeleton variant={`rectangular`} height={264} width={152} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant={`rectangular`} height={264} width={152} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ProductsLoader;
