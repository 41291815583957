import React from "react";
import _ from "lodash";
import {
  InputLabel,
  Button,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import ChangeQuantity from "../Product/ChangeQuantity";
import CommonConstantValues from "../../Services/CommonConstantValues";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PartnerService from "../../Services/PartnerService";
import AmountUtils from "../../Utils/AmountUtils";

function ReviewProductCart({
  editedProductsCards,
  hasClickedEdit,
  activeSkuCode,
  handleClickedOnEdit,
  handleRemoveProductFromCart,
  quantity,
  handleAdd,
  handleRemove,
  handleChange,
  setQuantity,
  minimumOrderQuantity,
  selectedReason,
  handleChangeReturnReason,
  returnReasons,
  updateCart,
}) {
  return (
    <div>
      {_.map(editedProductsCards, (productDetail) => {
        return (
          <>
            <Grid
              container
              sx={{
                margin: "10px 0px",
                display: "flex",
                padding: "8px",
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                border: "1px solid #EEEEEE",
                background:
                  hasClickedEdit && activeSkuCode === productDetail?.skuCode
                    ? "#FFFFFF"
                    : "#CACACA",
              }}
            >
              <Grid
                item
                xs={3}
                sx={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "5px",
                  background: "#fff",
                  textAlign: "center",
                  height: "85px",
                }}
              >
                <img
                  src={productDetail?.productImage}
                  alt=""
                  width={"auto"}
                  height="75px"
                />
              </Grid>

              <Grid
                item
                xs={9}
                container
                sx={{ height: "100%", paddingLeft: "10px" }}
              >
                <Grid item xs={9}>
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {productDetail.productName}
                  </Typography>
                </Grid>

                <Grid item xs={3} sx={{ textAlign: "right" }}>
                  {!(
                    hasClickedEdit && activeSkuCode === productDetail?.skuCode
                  ) && (
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#9D2123",
                      }}
                      onClick={() => handleClickedOnEdit(productDetail)}
                    >
                      Edit
                    </Typography>
                  )}
                </Grid>

                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#4F4F4F",
                    fontWeight: "700",
                  }}
                >
                  Return Quantity - {productDetail?.quantity}
                </Typography>

                {PartnerService.checkIfFieldAgent() && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#4F4F4F",
                      fontWeight: "700",
                    }}
                  >
                    Approx Return Amount -{" "}
                    {AmountUtils.formatCurrency(
                      productDetail?.returnableAmount || 0
                    )}
                  </Typography>
                )}

                <Grid item xs={9}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#4F4F4F",
                      fontWeight: "700",
                    }}
                  >
                    Reason -{" "}
                    {productDetail?.remarks || "No Remarks are present"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ position: "relative" }}>
                  {!(
                    hasClickedEdit && activeSkuCode === productDetail?.skuCode
                  ) && (
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#9D2123",
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                      }}
                      onClick={(event) =>
                        handleRemoveProductFromCart(
                          event,
                          productDetail?.skuCode
                        )
                      }
                    >
                      Remove
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {hasClickedEdit && activeSkuCode === productDetail?.skuCode && (
              <Grid
                sx={{
                  marginTop: "-17px",
                  padding: "5px 17px",
                  background:
                    hasClickedEdit && activeSkuCode === productDetail?.skuCode
                      ? "#FFFFFF"
                      : "#CACACA",
                }}
              >
                <Grid item xs={5}>
                  <Grid
                    sx={{
                      display: "flex",
                      padding: "7px",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                      Return Quantity
                    </Typography>

                    <Grid
                      sx={{
                        width: "150px",
                        padding: "3px",
                      }}
                    >
                      <ChangeQuantity
                        product={productDetail}
                        quantity={quantity}
                        handleAdd={() =>
                          handleAdd(productDetail?.minimumOrderQuantity)
                        }
                        handleRemove={() =>
                          handleRemove(productDetail?.minimumOrderQuantity)
                        }
                        handleChange={(event) =>
                          handleChange(
                            event,
                            productDetail?.minimumOrderQuantity
                          )
                        }
                        setQuantity={setQuantity}
                        minimumOrderQuantity={minimumOrderQuantity}
                      />
                      {quantity > CommonConstantValues?.MAX_RETURN_QUANTITY ? (
                        <Typography
                          sx={{
                            color: "#9D2123",
                            display: "flex",
                            fontSize: "12px",
                            marginBottom: "14px",
                            justifyContent: "center",
                          }}
                        >
                          <ErrorOutlineIcon fontSize="small" />
                          Quantity should be less than{" "}
                          {CommonConstantValues?.MAX_RETURN_QUANTITY}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "#9D2123",
                            display: "flex",
                            fontSize: "12px",
                            marginBottom: "14px",
                            justifyContent: "center",
                          }}
                        >
                          Return allowed in pack of{" "}
                          {productDetail?.minimumOrderQuantity === 0
                            ? 1
                            : productDetail?.minimumOrderQuantity}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl
                      sx={{
                        width: "100%",
                        height: "35px",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      }}
                    >
                      {!selectedReason && (
                        <InputLabel sx={{ marginTop: "-9px" }}>
                          Select a Reason
                        </InputLabel>
                      )}
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={selectedReason || productDetail?.remarks}
                        onChange={handleChangeReturnReason}
                        sx={{
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {_.map(returnReasons, ({ reasonName }) => {
                          return (
                            <MenuItem
                              key={reasonName}
                              value={reasonName}
                              sx={{ whiteSpace: "normal" }}
                            >
                              {reasonName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Button
                    fullWidth
                    variant={"contained"}
                    sx={{
                      marginTop: "18px",
                      borderRadius: "10px",
                      height: "35px",
                      background: "#9D2123",
                      color: "#FFFFFF",
                      letterSpacing: "0.04em",
                      textTransform: "uppercase",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                    disabled={
                      quantity < 1 ||
                      quantity > CommonConstantValues?.MAX_RETURN_QUANTITY ||
                      quantity.toString().includes(".")
                    }
                    onClick={() => updateCart(productDetail)}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        );
      })}
    </div>
  );
}

export default ReviewProductCart;
