import Clamp from "react-multiline-clamp";
import AmountUtils from "../../Utils/AmountUtils";
import { Grid, Typography } from "@mui/material";
import AnalyticsService from "../../Services/AnalyticsService";

const TopProductCard = (props) => {
  let productDetails = props?.product;
  const handleProductCardClickedEvent = () => {
    AnalyticsService.pushEvent(`Card Clicked`, {
      page: "vyapaar",
      type: "Product",
      title: productDetails?.productName || "",
      skuCode: productDetails?.itemSKU,
    });
  };
  return (
    <div>
      <Grid
        container
        sx={{
          borderRadius: "16px",
          marginTop: "12px",
        }}
      >
        <Grid
          item
          xs={2.5}
          sx={{
            backgroundColor: "white",
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {productDetails?.itemSKU ? (
            <a
              href={`/products/${productDetails.itemSKU}`}
              onClick={() => handleProductCardClickedEvent()}
            >
              <img
                src={
                  productDetails?.productImage &&
                  productDetails?.productImage !== ""
                    ? productDetails?.productImage
                    : `/icons/dummy.jpeg`
                }
                alt=""
                height="48px"
                min-height="48px"
              />
            </a>
          ) : (
            <img
              src={
                productDetails?.productImage &&
                productDetails?.productImage !== ""
                  ? productDetails?.productImage
                  : `/icons/dummy.jpeg`
              }
              alt=""
              height="48px"
              min-height="48px"
            />
          )}
        </Grid>
        <Grid
          item
          xs={9.5}
          sx={{
            border: "1px solid #ffffff",
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
          }}
        >
          <Grid
            container
            direction={"row"}
            sx={{
              padding: "6px 6px 4px 10px",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                minHeight: "36px",
                marginBottom: "6px",
              }}
            >
              <Clamp lines={2} maxLines={2}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  {productDetails?.productName &&
                  productDetails?.productName !== ""
                    ? productDetails.productName
                    : productDetails?.itemSKU
                      ? productDetails?.itemSKU
                      : ""}
                </Typography>
              </Clamp>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={3.6}
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #00733E20",
                  background: "#00733E10",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "800",
                    color: "#1C1C1C90",
                    textTransform: "uppercase",
                  }}
                >
                  Purchase
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    color: "#00733E",
                  }}
                >
                  ₹
                  {AmountUtils.formatNumberWithSuffix(
                    productDetails.grossRevenue
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.6}
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #9D212320",
                  background: "#9D212310",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "800",
                    color: "#1C1C1C90",
                    textTransform: "uppercase",
                  }}
                >
                  Return
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    color: "#9D2123",
                  }}
                >
                  ₹
                  {AmountUtils.formatNumberWithSuffix(
                    productDetails.returnRevenue
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.6}
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #F8D14940",
                  background: "#F8D14930",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "800",
                    color: "#1C1C1C90",
                    textTransform: "uppercase",
                  }}
                >
                  Net
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    color: "#A06600",
                  }}
                >
                  ₹
                  {AmountUtils.formatNumberWithSuffix(
                    productDetails.netRevenue
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TopProductCard;
