import { Button, Grid, Skeleton, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import PaymentService from "../../Services/PaymentService";
import ProceedPaymentFailureDialog from "../Hisaab/ProceedPaymentFailureDialog";
import HisaabService from "../../Services/HisaabService";
import OnboardingProcessCTA from "./OnboardingProcessCTA";
import PartnerService from "../../Services/PartnerService";

const OnboardingStatusNudge = (props) => {
  const Razorpay = useRazorpay();
  const paymentType = `securitydeposit`;
  const [partnerStatus, setPartnerStatus] = useState("");
  const [partnerSubStatus, setPartnerSubStatus] = useState("");
  const securityDepositAmount = Number(
    localStorage.getItem(`securityDepositAmount`)
  );
  const [showProceedPaymentFailureDialog, setShowProceedPaymentFailureDialog] =
    useState(false);
  const [proceedPaymentErrorCode, setProceedPaymentErrorCode] = useState(null);
  const [agreementSigningStatus, setAgreementSigningStatus] = useState("");
  const [openUploadDrawer, setOpenUploadDrawer] = useState(false);
  const [loading, setLoading] = useState("not_started");

  const handlePayNowClick = () => {
    AnalyticsService.pushEvent("Pay Now Clicked", {
      dueAmount: securityDepositAmount,
      page: window?.location?.pathname || "",
      type: paymentType || "",
    });

    let initiationErrorCode = null;
    const { axiosRequest } = PaymentService.initiatePayment({
      paymentType,
      amount: securityDepositAmount,
    });
    axiosRequest
      .then((response) => {
        if (
          response?.data?.responseData &&
          !_.isEmpty(response?.data?.responseData)
        ) {
          let responseData = response.data.responseData;
          console.log({ responseData });
          let razorPayConfig = {
            key: responseData.apiKey,
            amount: securityDepositAmount,
            currency: "INR",
            name: "AgroStar",
            description: "Payment Amount",
            order_id: responseData.razorpayOrderId,
            callback_url: `${config.paymentRedirectionUrl}paymentapi/v1/paymentredirect`,
            redirect: true,
            remember_customer: false,
            hidden: {
              contact: true,
              email: true,
            },
            modal: {
              confirm_close: true,
              ondismiss: function (response) {
                console.log("in close");
                console.log(response);
                // props.setPaymentLoading("done");
                AnalyticsService.pushEvent("Razorpay Payment SDK Closed");
              },
            },
            prefill: {
              // name: localStorage.getItem("partnerName"),
              email: "rzpcustomer.payment@agrostar.in",
              contact: localStorage.getItem("mobile"),
              // method: "upi",
            },

            theme: {
              color: "#9D2123",
            },
            // notes: {
            //   address: "Razorpay Corporate Office",
            // },
            razorpaySuccessCallbackUrl: `${config.appUrl}/hisaab/payment-status/`,
            razorpayFailureCallbackUrl: `${config.appUrl}/hisaab/razorpay-failure/RAZORPAY_FAILURE`,
          };

          console.log({ razorPayConfig });
          if (responseData.apiKey && responseData.razorpayOrderId) {
            if (AndroidService.checkIfAndroid()) {
              if (AndroidService.getAndroidVersion()) {
                // setOpenDebug(true);

                let { appVersionCode } = AndroidService.getAndroidVersion();
                // setOpenDebug(true);
                // setDebugMessage(
                //   `in get version; android version ${appVersionCode}`
                // );
                if (appVersionCode && parseInt(appVersionCode) >= 8) {
                  handlePaymentViaAndroidNativeSDK(razorPayConfig);
                } else {
                  // setOpenDebug(true);
                  // setDebugMessage(`No appVersionCode;`);
                  handlePaymentViaWebSDK(razorPayConfig);
                }
              } else {
                // setOpenDebug(true);
                // setDebugMessage(`No getAppVersion();`);
                handlePaymentViaWebSDK(razorPayConfig);
              }
            } else {
              // setOpenDebug(true);
              // setDebugMessage(`No Android;`);
              handlePaymentViaWebSDK(razorPayConfig);
            }
          } else {
            // props.setPaymentLoading("done");

            if (!responseData.razorpayOrderId) {
              initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_ORDER_ID";
              setProceedPaymentErrorCode(initiationErrorCode);
              setShowProceedPaymentFailureDialog(true);
            }
            if (!responseData.apiKey) {
              initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_API_KEY";
              setProceedPaymentErrorCode(initiationErrorCode);
              setShowProceedPaymentFailureDialog(true);
            }
          }
        } else {
          initiationErrorCode = "PAYMENT_INIT_NO_RESPONSE_DATA";
          setProceedPaymentErrorCode(initiationErrorCode);
          setShowProceedPaymentFailureDialog(true);
          // props.setPaymentLoading("done");
        }
        if (initiationErrorCode) {
          AnalyticsService.pushEvent("Payment Initiation Failed", {
            type: paymentType,
            paymentAmountType: "",
            amount: securityDepositAmount,
            errorCode: initiationErrorCode,
          });
        }
      })
      .catch(() => {
        initiationErrorCode = "PAYMENT_INIT_FAILED";
        setProceedPaymentErrorCode(initiationErrorCode);
        AnalyticsService.pushEvent("Payment Initiation Failed", {
          type: paymentType,
          paymentAmountType: "",
          amount: securityDepositAmount,
          errorCode: initiationErrorCode,
        });
        setShowProceedPaymentFailureDialog(true);
        // props.setPaymentLoading("done");
      })
      .finally(() => {});
  };

  function handlePaymentViaWebSDK(razorpayConfig) {
    // props.setPaymentLoading("done");

    let razorpayInstance = new Razorpay(razorpayConfig);
    razorpayInstance.open();
    razorpayInstance.on("payment.failed", function (response) {
      // props.handleRazorpayFailure("RAZORPAY_FAILURE");
    });

    AnalyticsService.pushEvent("Payment Initiation Successful", {
      type: paymentType,
      paymentAmountType: "",
      amount: securityDepositAmount,
      sdk: "web",
    });
  }

  const handlePaymentViaAndroidNativeSDK = (razorPayConfig) => {
    // props.setPaymentLoading("done");
    delete razorPayConfig.modal.ondismiss;
    razorPayConfig.redirect = false;
    delete razorPayConfig.callback_url;

    let razorPayMetadataStringified = JSON.stringify(razorPayConfig);

    try {
      /*global Android*/
      if (Android) {
        Android.startRazorpayPaymentFlow(razorPayMetadataStringified);
        AnalyticsService.pushEvent("Payment Initiation Successful", {
          type: paymentType,
          paymentAmountType: "",
          amount: securityDepositAmount,
          sdk: "android",
        });
      } else {
        // setOpenDebug(true);
        // setDebugMessage(`No Android;in native handler`);
        handlePaymentViaWebSDK(razorPayConfig);
      }
    } catch (e) {
      // setOpenDebug(true);
      // setDebugMessage(`in try native catch; error- ${JSON.stringify(e)}`);
      handlePaymentViaWebSDK(razorPayConfig);
    }
  };

  useEffect(() => {
    setPartnerStatus(localStorage.getItem("partnerStatus"));
    setPartnerSubStatus(localStorage.getItem("partnerSubStatus"));
  }, []);

  useEffect(() => {
    setLoading("started");
    const farmerId = localStorage.getItem("farmerId");
    const { axiosRequest, abortController } =
      HisaabService.getInstitutionDetails(farmerId);
    axiosRequest
      .then((institutionalDetailsResponse) => {
        const institutionalDetails =
          institutionalDetailsResponse?.data?.responseData;
        if (institutionalDetails?.agreementSigningStatus === "SUCCESS") {
          setAgreementSigningStatus("SUCCESS");
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, []);

  const showSignAgreementNudge = () => {
    let showNudgeFlag = false;
    if (loading === "done") {
      if (
        localStorage.getItem("onboardingReferrerSalesOfficerEmailID") &&
        agreementSigningStatus !== "SUCCESS"
      ) {
        if (PartnerService.checkIfFieldAgent()) {
          showNudgeFlag = true;
        }
        showNudgeFlag = true;
      }
    }
    return showNudgeFlag;
  };

  return (
    <>
      {loading !== "done" ? (
        <Skeleton variant={`rectangular`} height={52} />
      ) : partnerStatus === "UNVERIFIED" && loading === "done" ? (
        partnerSubStatus === "DOCUMENTS_UPLOAD_PENDING" ? (
          <>
            <Grid
              container
              alignItems={"center"}
              bgcolor={"#F8D149"}
              sx={{ padding: "8px 16px" }}
            >
              <Grid item xs={8}>
                <Typography
                  color={"secondary"}
                  fontWeight={700}
                  fontSize={"12px"}
                  paddingRight={"2px"}
                >
                  {showSignAgreementNudge()
                    ? "Upload documents and sign agreement to unlock pricing"
                    : "Upload your documents to unlock pricing"}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign={"center"}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    borderRadius: "16px",
                    textTransform: "capitalize",
                    lineHeight: "14px",
                    padding: "8px 10px",
                  }}
                  onClick={() => {
                    AnalyticsService.pushEvent("Button Clicked", {
                      type: "Unlock Prices",
                      page: window.location.pathname,
                    });
                    setOpenUploadDrawer(true);
                  }}
                >
                  Unlock Price
                </Button>
              </Grid>
            </Grid>
          </>
        ) : partnerSubStatus === "DOCUMENTS_SUBMITTED" ? (
          <>
            <Grid
              container
              alignItems={"center"}
              bgcolor={"#F8D149"}
              sx={{ padding: "8px 16px" }}
            >
              <Grid item xs={8.5}>
                <Typography
                  color={"secondary"}
                  fontWeight={700}
                  fontSize={"12px"}
                  paddingRight={"2px"}
                >
                  {showSignAgreementNudge()
                    ? "Documents are being verified. Please sign agreement to unlock pricing"
                    : "Your documents are being verified"}
                </Typography>
              </Grid>
              <Grid item xs={3.5} textAlign={"center"}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    borderRadius: "16px",
                    textTransform: "capitalize",
                    lineHeight: "14px",
                    padding: "8px 10px",
                  }}
                  onClick={() => {
                    AnalyticsService.pushEvent("Button Clicked", {
                      type: "Unlock Prices",
                      page: window.location.pathname,
                    });
                    setOpenUploadDrawer(true);
                  }}
                >
                  Unlock Price
                </Button>
              </Grid>
            </Grid>
          </>
        ) : partnerSubStatus === "DOCUMENTS_VERIFIED" &&
          securityDepositAmount ? (
          <>
            <Grid
              container
              alignItems={"center"}
              bgcolor={"#F8D149"}
              sx={{ padding: "8px 16px" }}
            >
              <Grid item xs={8}>
                <Typography
                  color={"secondary"}
                  fontWeight={400}
                  fontSize={"12px"}
                  paddingRight={"2px"}
                >
                  <>
                    Please pay Security Deposit of{" "}
                    <b>
                      ₹
                      {new Intl.NumberFormat("en-IN").format(
                        securityDepositAmount
                      )}
                    </b>{" "}
                    to unlock limit
                  </>
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign={"center"}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    borderRadius: "16px",
                    textTransform: "capitalize",
                    lineHeight: "14px",
                    padding: "8px 10px",
                  }}
                  onClick={() => {
                    AnalyticsService.pushEvent("Button Clicked", {
                      type: "Unlock CTA",
                      page: window.location.pathname,
                    });
                    setOpenUploadDrawer(true);
                  }}
                >
                  Unlock Limit
                </Button>
              </Grid>
            </Grid>
          </>
        ) : partnerSubStatus === "SECURITY_DEPOSIT_PAID" ? (
          <>
            <Grid
              container
              alignItems={"center"}
              bgcolor={"#F8D149"}
              sx={{ padding: "8px 16px" }}
            >
              <Grid
                item
                xs={
                  agreementSigningStatus !== "SUCCESS" ||
                  PartnerService.isPartnerStatusUnverified()
                    ? 8
                    : 12
                }
              >
                <Typography
                  color={"secondary"}
                  fontWeight={700}
                  fontSize={"12px"}
                  paddingRight={"2px"}
                >
                  {agreementSigningStatus !== "SUCCESS"
                    ? "Sign Agreement to unlock limit"
                    : "Deposit received. We're awaiting legal verification. Our team will contact you shortly."}
                </Typography>
              </Grid>
              {(agreementSigningStatus !== "SUCCESS" ||
                PartnerService.isPartnerStatusUnverified()) && (
                <Grid item xs={4} textAlign={"center"}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                      borderRadius: "16px",
                      textTransform: "capitalize",
                      lineHeight: "14px",
                      padding: "8px 10px",
                    }}
                    onClick={() => {
                      AnalyticsService.pushEvent("Button Clicked", {
                        type: "Unlock Prices",
                        page: window.location.pathname,
                      });
                      setOpenUploadDrawer(true);
                    }}
                  >
                    Unlock Limit
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      <ProceedPaymentFailureDialog
        open={showProceedPaymentFailureDialog}
        retryPayment={handlePayNowClick}
        errorCode={proceedPaymentErrorCode}
        handleClose={() => {
          setShowProceedPaymentFailureDialog(false);
        }}
        paymentType={paymentType}
        orderId={null}
        initiationFailureRetryRedirectionLink="/"
      />
      <OnboardingProcessCTA
        open={openUploadDrawer}
        setOpenUploadDrawer={setOpenUploadDrawer}
        onClose={() => {
          setOpenUploadDrawer(false);
        }}
        setLoadingAgreementSigningWindow={
          props?.setLoadingAgreementSigningWindow
        }
        partnerSubStatus={partnerSubStatus}
        partnerStatus={partnerStatus}
        handlePayNowClick={handlePayNowClick}
        agreementSigningStatus={agreementSigningStatus}
      />
    </>
  );
};

export default OnboardingStatusNudge;
