import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import Clamp from "react-multiline-clamp";
import StarRatingLabel from "./StarRatingLabel";
import AnalyticsService from "../../Services/AnalyticsService";
import makeStyles from "@mui/styles/makeStyles";
import ProductListPrice from "../Dashboard/ProductListPrice";
import { ArrowForward } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#333333",
  },
  imageWrapper: {
    background: "#FFFFFF",
    border: "1px solid #F3F3F3",
    boxShadow: "inset 0px 0px 11px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px",
    padding: "5px 10px",
    marginRight: "10px",
    marginLeft: "10px",
  },
  ratingLabelWrapper: {
    width: "50px",
    justifyContent: "flex-start",
    display: "flex",
    marginTop: "4px",
  },
  productContainer: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "3px",
  },
  imageContainer: {
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productCardWrapper: {
    borderRadius: "16px",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
  },
  buyNowText: {
    color: "#9D2123",
    fontSize: "12px",
    fontWeight: "700",
    textTransform: "capitalize",
    "& .MuiButton-endIcon": {
      marginLeft: "2px",
      marginTop: "2px",
    },
    lineHeight: "14px",
    marginTop: "16px",
  },
  productCardWrapperLink: {
    textDecoration: `none`,
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

const ProductVariantCard = (props) => {
  const classes = useStyles();
  const variant = props?.variant;

  const handleAlternateProductCardClick = () => {
    AnalyticsService.pushEvent(`Card Clicked`, {
      type: "Product Variant",
      title: variant?.skuName,
      skuCode: variant?.sku_code,
    });
  };
  return (
    <Box sx={{ padding: "2px", marginTop: "4px" }}>
      <Card className={classes.productCardWrapper} variant={"elevation"}>
        <CardContent sx={{ padding: "0px !important" }}>
          <a
            href={`/products/${variant?.sku_code}`}
            className={classes.productCardWrapperLink}
            onClick={() => handleAlternateProductCardClick()}
          >
            <Grid container className={classes.productContainer}>
              <Grid item xs={3} className={classes.imageContainer}>
                <Box className={classes.imageWrapper}>
                  <img
                    src={
                      variant?.thumbImage && variant?.thumbImage !== ""
                        ? variant?.thumbImage
                        : `/icons/dummy.jpeg`
                    }
                    alt=""
                    width="40px"
                    height="50px"
                    min-height="48px"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={9}
                sx={{
                  border: "1px solid #ffffff",
                  borderTopRightRadius: "16px",
                  borderBottomRightRadius: "16px",
                  lineHeight: "14px",
                }}
              >
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={7}
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography className={classes.title}>
                        <Clamp withTooltip lines={2}>
                          {variant?.skuName}
                        </Clamp>
                      </Typography>
                      <Box sx={{ marginTop: "4px" }}>
                        <ProductListPrice product={variant} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Box className={classes.ratingLabelWrapper}>
                        {variant?.rating && variant?.rating !== 0 ? (
                          <StarRatingLabel rating={variant?.rating?.average} />
                        ) : (
                          <Box sx={{ minHeight: "25px" }}></Box>
                        )}
                      </Box>
                      <Button
                        className={classes.buyNowText}
                        variant={`text`}
                        endIcon={<ArrowForward />}
                      >
                        Buy
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </a>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProductVariantCard;
