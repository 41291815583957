import config from "../config";
import AddressService from "./AddressService";
import PurchaseRequestMock from "./mocks/PurchaseRequestMock";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class PurchaseRequestService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static createPurchaseRequest = (cartFromLocal) => {
    const { shippingAddressId, billingAddressId } =
      AddressService.getShippingAndBillingAddress();
    let data = {
      shippingAddressId: shippingAddressId,
      shipAddrSameAsBillAddr: true,
      billingAddressId: billingAddressId,
      advanceOrderDate: "",
      pseudoBalanceUsed: false,
      realBalanceUsed: false,
      cartId: cartFromLocal.cartId,
      farmerId: parseInt(localStorage.getItem(`farmerId`)),
    };
    if (useMocks === false) {
      const url = `${config.backendUrl}ordermanagementservice/v3/orderapp/`;
      return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: PurchaseRequestMock });
      });
    }
  };
}
