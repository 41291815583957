import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";
import {
  CheckCircle,
  CheckCircleOutlined,
  Circle,
  CircleRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Radio,
  Button,
  Grid,
  Typography,
  Alert,
  Snackbar,
  FormControlLabel,
  RadioGroup,
  Box,
  Card,
  CardContent,
  Divider,
} from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import OfferEntitlements from "../Common/OfferEntitlements";
import AnalyticsService from "../../Services/AnalyticsService";
import SectionTitle from "../Common/SectionTitle";
import app from "../../App";
import Clamp from "react-multiline-clamp";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  offerRadioButton: {
    margin: theme.spacing(-1, 0, 0, 0),
  },
  offer: {
    width: "290px",
    maxWidth: "290px",
    // marginLeft: "4vw",
    height: "195px",
    maxHeight: "195px",
    // marginTop: theme.spacing(3),
    padding: theme.spacing(1),
    border: "1px dashed #CCCCCC",
    boxSizing: "border-box",
    borderRadius: "10px",
    boxShadow: "0px 4px 15px 0px #0000000D",
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
  },
  selectedOffer: {
    width: "290px",
    maxWidth: "290px",
    // marginLeft: "4vw",
    height: "195px",
    maxHeight: "195px",
    // marginTop: theme.spacing(-1),
    padding: theme.spacing(1),
    border: "1.2px dashed #9D2123",
    boxSizing: "border-box",
    borderRadius: "10px",
    boxShadow: "0px 4px 15px 0px #0000000D",
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
  },
  offerName: {
    fontWeight: 700,
    display: "flex",
    fontSize: "14px",
    color: "#00733E",
    alignItems: "center",
    lineHeight: "19px",
  },
  offerIcon: {
    marginRight: theme.spacing(1),
  },
  bestPriceText: {
    color: "#00733E",
    fontSize: "14px",
    marginTop: theme.spacing(1),
  },
  successToastie: {
    background: "#666666",
    color: "#ffffff",
    borderRadius: "6px",
    textAlign: "center",
  },
  offerAvailable: {
    color: "#666666",
    lineHeight: "2.5",
  },
}));

const OffersContainer = ({
  product,
  appliedOffer,
  handleOfferChange,
  initiallyLoaded,
}) => {
  const classes = useStyles();

  const horizontalScrollableDivRef = useRef(null);
  const scrollToOffset = (scrollOffset) => {
    horizontalScrollableDivRef.current.scrollLeft = scrollOffset;
  };

  // const handleOfferClick = () => {};

  useEffect(() => {
    if (appliedOffer !== null) {
      // scrollToOffset(300);
      let appliedOfferIndex = product?.offersDetail?.productOffers?.findIndex(
        (offer) => {
          return offer?.id === appliedOffer?.id;
        }
      );
      scrollToOffset(appliedOfferIndex * 300);
    }
  }, [appliedOffer, initiallyLoaded]);

  const [showMoreOffers, setShowMoreOffers] = useState(true);
  const [showSuccessToastie, setShowSuccessToastie] = useState(true);

  const handleViewMoreButtonClick = () => {
    if (showMoreOffers) {
      const hideOffersClickedData = {
        skuCode: product?.skuCode || "",
      };

      AnalyticsService.pushEvent(`Hide Offers Clicked`, hideOffersClickedData);
    } else {
      const viewMoreOffersClickedData = {
        skuCode: product?.skuCode || "",
      };

      AnalyticsService.pushEvent(
        `View More Offers Clicked`,
        viewMoreOffersClickedData
      );
    }

    setShowMoreOffers(!showMoreOffers);
  };

  return (
    <ErrorBoundary message={``}>
      <Snackbar
        open={showSuccessToastie}
        autoHideDuration={1500}
        onClose={() => setShowSuccessToastie(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ marginBottom: "46px" }}
      >
        <Alert
          icon={false}
          className={classes.successToastie}
          onClose={() => setShowSuccessToastie(false)}
        >
          Congratulations! <b>{appliedOffer?.offerName}</b> Offer has been
          applied with quantity {appliedOffer?.quantity}.
        </Alert>
      </Snackbar>

      <Grid
        ref={horizontalScrollableDivRef}
        flexWrap={"wrap"}
        container
        direction={"row"}
        sx={{
          overflowX: "auto",
          marginTop: "20px",
          height: "200px",
          maxHeight: "200px",
          filter: PartnerService.applySmudgedPriceFilter(product),
        }}
      >
        <RadioGroup sx={{ height: "200px", maxHeight: "200px" }}>
          {product?.offersDetail?.productOffers.map((offer, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Card
                  className={
                    appliedOffer?.id === offer.id
                      ? classes.selectedOffer
                      : classes.offer
                  }
                  onClick={() => {
                    AnalyticsService.pushEvent("Offer Clicked", {
                      offerId: offer?.offerId,
                      offerName: offer?.offerName,
                      offerType: offer?.offerType?.value,
                      skuCode: product?.skuCode,
                    });
                  }}
                >
                  <FormControlLabel
                    control={
                      <CardContent sx={{ marginTop: "-10px" }}>
                        <Grid container>
                          <Grid
                            container
                            spacing={0}
                            direction={`row`}
                            alignItems="flex-start"
                            justifyContent="space-between"
                          >
                            <Grid item xs={9}>
                              <Typography className={classes.offerName}>
                                <img
                                  src={`/icons/offersGreenIcon.svg`}
                                  alt=""
                                  className={classes.offerIcon}
                                />
                                <Clamp withTooltip lines={1}>
                                  {offer?.offerName}
                                </Clamp>
                              </Typography>

                              <Typography
                                variant={"caption"}
                                className={classes.offerAvailable}
                              >
                                Offer available on: {offer?.quantity} Quantity
                              </Typography>
                              <Box>
                                {offer?.entitlements.map((e, index) => {
                                  return (
                                    <OfferEntitlements
                                      key={index}
                                      entitlement={e}
                                      product={product}
                                    />
                                  );
                                })}
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Radio
                                icon={<CheckCircle color={"disabled"} />}
                                checkedIcon={<CheckCircle />}
                                color={"primary"}
                                className={classes.offerRadioButton}
                                checked={appliedOffer?.id === offer?.id}
                                onChange={handleOfferChange}
                                value={offer?.id}
                              />
                            </Grid>
                          </Grid>

                          <Box
                            sx={{
                              height: "0px",
                              opacity: "0.3",
                              border: "1px dashed #000000",
                              width: "290px",
                              marginTop: "8px",
                              // marginBottom: "10px",
                              marginLeft: "-7px",
                            }}
                          />
                          <Grid
                            container
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              marginTop: "8px",
                              // marginBottom: "-8px",
                            }}
                          >
                            {offer.netEffectivePriceApplicable === "false" ? (
                              <Grid item xs={12}>
                                <Typography
                                  variant={`body2`}
                                  sx={{
                                    color: "#00733E",
                                  }}
                                >
                                  {offer.bestPriceText}
                                </Typography>
                              </Grid>
                            ) : (
                              <>
                                <Grid item xs={6}>
                                  <Typography
                                    variant={"body2"}
                                    sx={{
                                      filter:
                                        PartnerService.applySmudgedPriceFilter(
                                          product
                                        ),
                                    }}
                                  >
                                    <b>
                                      ₹
                                      {new Intl.NumberFormat("en-IN").format(
                                        product.sellingPrice
                                      )}
                                    </b>
                                    <br />
                                    <>Price Per Quantity</>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{
                                      color: "#00733E",
                                      filter:
                                        PartnerService.applySmudgedPriceFilter(
                                          product
                                        ),
                                    }}
                                    variant={"body2"}
                                  >
                                    <b>
                                      ₹
                                      {new Intl.NumberFormat("en-IN").format(
                                        offer.offerBenefit
                                      )}
                                    </b>
                                    <br />
                                    <>Offer Benefit</>
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    }
                    label={``}
                  />
                </Card>
              </Grid>
            );
          })}
        </RadioGroup>
      </Grid>
    </ErrorBoundary>
  );
};

export default OffersContainer;
