import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BlockUi from "react-block-ui";

import { makeStyles } from "@mui/styles";
import { Box, Button, Container, Modal, Typography } from "@mui/material";

import config from "../../config";
import TopBar from "../Common/TopBar";
import PartnersSearch from "../Partners/PartnersSearch";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import InitiateTransferPartnerCard from "./InitiateTransferPartnerCard";
import InterPartyTransfersService from "../../Services/InterPartyTransfersService";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";

const useStyles = makeStyles({
  headingStyles: { fontWeight: 700, lineHeight: 1 },
  searchAgain: {
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(0,0,0,0.6)",
  },
  partnerCard: {
    position: "relative",
    padding: "12px 16px",
    marginTop: "8px",
    fontSize: "14px",
    borderTop: "1px solid #eee",
    borderRadius: "16px",
    background: "#fff",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  partnerAddress: {
    display: "flex",
    alignItems: "flex-start",
    gap: "5px",
    img: { position: "relative", top: 2, opacity: 0.7 },
  },
  callButton: {
    position: "absolute",
    right: "16px",
    bottom: "12px",
    width: "70px",
    height: "30px",
    fontSize: "12px",
    borderRadius: "10px",
  },
  proceedButton: {
    position: "fixed",
    bottom: 0,
    height: "50px",
    borderRadius: "0",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "90%",
    padding: "24px",
    border: "none",
    textAlign: "center",
    background: "#fff",
    borderRadius: "32px",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  modalButton: {
    width: "100px",
    height: "45px",
    fontWeight: 700,
    marginTop: "12px",
    textTransform: "none",
    borderRadius: "16px",
  },
});

export default function InitiateTransferPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const selectedPartnersRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [searchInfo, setSearchInfo] = useState("");
  const [loading, setLoading] = useState("done");
  const [partnersList, setPartnersList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [selectedPartners, setSelectedPartners] = useState(null);
  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);
  const [transferCreationFailedReason, setTransferCreationFailedReason] =
    useState(null);
  selectedPartnersRef.current = selectedPartners;

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, { page: "Select Store" });
  }, []);

  const handleSearch = (keyword) => {
    if (
      selectedPartnersRef.current?.transferFromPartner &&
      selectedPartnersRef.current?.transferToPartner
    ) {
      return setSearchKeyword("");
    }

    if (keyword) {
      setSearchKeyword(keyword);
      if (keyword?.length < 5) {
        return setSearchInfo("Type minimum 5 characters!");
      }
      setSearchInfo("");
      setLoading("started");
      const { axiosRequest } =
        InterPartyTransfersService.partnerSearchByMobileOrName(keyword);
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            const activePartnersList = response?.data?.responseData?.filter(
              (partner) => partner?.status === "ACTIVE"
            );
            setPartnersList(activePartnersList);
          }
          setLoading("done");
        })
        .catch((error) => {
          setLoading("done");
          setPartnersList([]);
          console.error("Failed to Get Partner List: ", error);
        });
    } else {
      clearSearch();
    }
  };

  const clearSearch = () => {
    setPartnersList([]);
    setSearchKeyword("");
  };

  const handleSelectPartner = (partner) => {
    // TODO: to enable the selectable logic in next iteration
    // if (!partner?.selectable) {
    //   setTransferCreationFailedReason(partner?.reason || "");
    //   return setOpenModal(true);
    // }

    if (!selectedPartners?.transferFromPartner) {
      AnalyticsService.pushEvent(`Partner Selected`, {
        page: "Select Store",
        type: "Transfer From",
      });
      setSelectedPartners({ transferFromPartner: partner });
    } else {
      AnalyticsService.pushEvent(`Partner Selected`, {
        page: "Select Store",
        type: "Transfer To",
      });
      setSelectedPartners((prevSelectedPartners) => ({
        ...prevSelectedPartners,
        transferToPartner: partner,
      }));
    }
    setSearchKeyword("");
    setPartnersList([]);
  };

  const handleRemovePartner = (partner) => {
    AnalyticsService.pushEvent(`Remove Button Clicked`, {
      page: "Select Store",
      farmer_id: partner?.farmerId,
    });
    if (selectedPartners?.transferFromPartner?.mobile === partner?.mobile) {
      setSelectedPartners(null);
    } else {
      setSelectedPartners({
        transferFromPartner: selectedPartners?.transferFromPartner,
      });
    }
  };

  const handleProceed = () => {
    AnalyticsService.pushEvent(`Proceed Button Clicked`, {
      page: "Select Store",
    });

    setLoading("started");
    const { axiosRequest } = InterPartyTransfersService.createIPTRequest(
      selectedPartners?.transferFromPartner?.farmerId,
      selectedPartners?.transferToPartner?.farmerId
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          const iptRequestId = response?.data?.responseData?.id;
          const cartId = response?.data?.responseData?.cartID;

          if (AndroidService.checkIfAndroid()) {
            if (
              parseInt(AndroidService.getAndroidVersion()?.appVersionCode) >=
              AndroidService.SCANNER_COMPATIBLE_VERSION
            ) {
              AndroidService.openScannerCameraActivity(
                `${config.appUrl}/inter-party-transfers/barcode-scan-details?iptRequestId=${iptRequestId}&cartId=${cartId}&scanCode=`,
                `${config.appUrl}/inter-party-transfers/scanned-products?iptRequestId=${iptRequestId}&cartId=${cartId}`
              );
            } else {
              setOpenAppUpdateDialog(true);
              AnalyticsService.pushEvent("App Update Required Dialog Shown", {
                page: "Select Store",
              });
            }
          } else {
            navigate(
              `/inter-party-transfers/barcode-scan-details?iptRequestId=${iptRequestId}&cartId=${cartId}&scanCode=`,
              { replace: true }
            );
          }
        }
        setLoading("done");
      })
      .catch((error) => {
        setLoading("done");
        console.error("Error Creating IPT Request", error);

        if (error?.response?.data?.message) {
          setTransferCreationFailedReason(error?.response?.data?.message);
        } else {
          setTransferCreationFailedReason("Transfer creation failed.");
        }
        setOpenModal(true);
      });
  };

  return (
    <>
      <TopBar title={`Select Store`} backNavigation={true} />

      <Container maxWidth={`xl`} sx={{ background: "#fff", pt: 1 }}>
        <PartnersSearch
          errorMessage={searchInfo}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          searchKeyword={searchKeyword}
        />
      </Container>

      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Loading...`}
        style={{ minHeight: "85vh" }}
        keepInView
      >
        {selectedPartners?.transferFromPartner && (
          <Container
            maxWidth={`xl`}
            sx={{
              paddingTop: 2,
              paddingBottom: selectedPartners?.transferFromPartner ? 2 : 0,
              background: selectedPartners?.transferFromPartner
                ? "#CACACA"
                : "",
            }}
          >
            <Typography variant="subtitle1" className={classes.headingStyles}>
              Transfer From
            </Typography>

            <InitiateTransferPartnerCard
              isSelected={true}
              handleRemovePartner={handleRemovePartner}
              partner={selectedPartners?.transferFromPartner}
            />
          </Container>
        )}

        <Container maxWidth={`xl`} sx={{ pt: 2, pb: 8 }}>
          {selectedPartners?.transferFromPartner && (
            <Typography variant="subtitle1" className={classes.headingStyles}>
              Transfer To
            </Typography>
          )}
          {selectedPartners?.transferToPartner && (
            <InitiateTransferPartnerCard
              isSelected={true}
              handleRemovePartner={handleRemovePartner}
              partner={selectedPartners?.transferToPartner}
            />
          )}

          {partnersList?.length ? (
            <>
              {!selectedPartners?.transferFromPartner && (
                <Typography
                  variant="subtitle1"
                  className={classes.headingStyles}
                >
                  Transfer From
                </Typography>
              )}

              {partnersList?.map((partner) =>
                selectedPartners?.transferFromPartner?.farmerId !==
                  partner?.farmerId &&
                selectedPartners?.transferToPartner?.farmerId !==
                  partner?.farmerId ? (
                  <InitiateTransferPartnerCard
                    partner={partner}
                    key={partner?.farmerId}
                    handleSelectPartner={handleSelectPartner}
                  />
                ) : null
              )}
            </>
          ) : (
            loading === "done" && (
              <>
                {!selectedPartners?.transferFromPartner && (
                  <Typography
                    variant="subtitle1"
                    className={classes.headingStyles}
                  >
                    Transfer From
                  </Typography>
                )}
                {!selectedPartners?.transferFromPartner ||
                !selectedPartners?.transferToPartner ? (
                  <Typography
                    variant="subtitle1"
                    className={classes.searchAgain}
                  >
                    {searchKeyword ? "No Partner Found." : "Search partner !"}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            )
          )}
        </Container>

        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box className={classes.modalBox}>
            <img src="/icons/starBlack.svg" alt="star" />
            <Typography variant="h6" sx={{ color: "#9D2123" }}>
              Oops!!
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {transferCreationFailedReason}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.modalButton}
              onClick={() => {
                setOpenModal(false);
                setTransferCreationFailedReason(null);
              }}
            >
              Okay
            </Button>
          </Box>
        </Modal>

        <Button
          fullWidth
          variant="contained"
          className={classes.proceedButton}
          disabled={
            !selectedPartners?.transferFromPartner ||
            !selectedPartners?.transferToPartner
          }
          onClick={handleProceed}
        >
          Proceed
        </Button>
      </BlockUi>

      <AppVersionUnsupported
        open={openAppUpdateDialog}
        handleClose={() => {
          AnalyticsService.pushEvent("App Update Required Dialog Closed", {
            page: "Select Store",
          });
          setOpenAppUpdateDialog(false);
        }}
        page={window.location.pathname}
      />
    </>
  );
}
