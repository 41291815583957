import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import CallIcon from "@mui/icons-material/Call";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import ErrorBoundary from "../Common/ErrorBoundary";

import PartnerService from "../../Services/PartnerService";
import StateService from "../../Services/StateService";
import USPBanners from "./USPBanners";
import AnalyticsService from "../../Services/AnalyticsService";
import {
  handleMobileNumberEnteredEvent,
  handleOtpEnteredEvent,
  handleResendOtpEvent,
  handleEventChangeMobileNumber,
  handleOnBoardingSuccessfulEvent,
  handleRegisterNowClickEvent,
} from "./OnboardingEventHandlers";
import HisaabService from "../../Services/HisaabService";
import * as Sentry from "@sentry/browser";
import { handleMobileScreenViewedEvent } from "./OnboardingEventHandlers";
import useReferrerParams from "../../Hooks/useReferrerParams";
import DocumentService from "../../Services/DocumentService";

const useStyles = makeStyles((theme) => ({
  main: {
    fontSize: "16px",
    background: theme.palette.background.main,
  },
  body: {
    background: theme.palette.background.default,
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    padding: theme.spacing(2, 2, 2, 2),
  },
  inputField: {
    margin: theme.spacing(1, 0),
    marginBottom: theme.spacing(-0.5),
    padding: theme.spacing(0.5),
  },
  continueButton: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.2),
  },
  registerNowButton: {
    margin: theme.spacing(-1, "auto", 2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.2),
    textDecoration: "none",
    color: "#ffffff",
  },
  wrongNo: {
    color: "#9D2123",
    display: "flex",
    margin: theme.spacing(1, 0),
    fontSize: "14px",
    marginBottom: "14px",
  },
  registerWithUs: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    background: "#E5F1EC",
    color: "#00733E",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  saathiForm: {
    marginBottom: "32px",
    padding: theme.spacing(1),
    background: "#DBE6E1",
    borderRadius: "10px",
    height: "40px",
    fontSize: "14px",
    color: "#00733E",
    display: "flex",
    justifyContent: "center",
  },
  formText: {
    fontSize: "14px",
    color: "#00733E",
    alignSelf: "center",
  },
  callIcon: {
    padding: theme.spacing(0.5),
    background: "#00733E",
    color: "#ffffff",
    borderRadius: theme.spacing(1),
    height: "30px",
    width: "30px",
    cursor: "pointer",
  },
  editIcon: {
    margin: theme.spacing(0, 0, -0.75, 1),
    padding: theme.spacing(0.5),
    background: "#00733E",
    color: "#ffffff",
    borderRadius: theme.spacing(2),
  },
  editText: {
    textDecoration: "underline",
    color: "#00733E",
    cursor: "pointer",
  },
  titleText: {
    fontSize: theme.spacing(3),
    lineHeight: "1.3",
    fontWeight: 500,
  },
  footer: {
    paddingTop: theme.spacing(1.5),
    background: theme.palette.background.main,
  },
  subtitleWrapper: {
    color: "#666666",
    lineHeight: "1.3",
    fontWeight: 400,
  },
  tAndc: {
    textAlign: "center",
    fontSize: "0.75rem",
    marginBottom: theme.spacing(-1),
  },
  titleWrapper: {
    fontWeight: 700,
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  getMessage: {
    color: "#333333",
    fontSize: "0.8rem",
    lineHeight: "1.3",
    marginLeft: theme.spacing(1),
  },
  tncHighlight: {
    color: "#9D2123",
    fontWeight: "700",
  },
}));

const SignIn = (mode) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [noAuthTokenError, setNoAuthTokenError] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [partnerNotRegistered, setPartnerNotRegistered] = useState(false);
  const [partnerInactive, setPartnerInactive] = useState(false);
  const [error, setError] = useState("");
  const [isOtpAsked, setIsOtpAsked] = useState(false);
  const [otpVerified, setOtpVerified] = useState("not_verified");
  const [isContinue, setIsContinue] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [loading, setLoading] = useState("not-started");

  const handleChangeMobileNumber = (event) => {
    setMobileNumber(event.target.value);
    setPartnerNotRegistered(false);
    setPartnerInactive(false);
    setError("");
  };

  const handleChangeOtp = (event) => {
    setOtp(event.target.value);
    setOtpVerified("entered");
  };

  const handleEditMobileClick = () => {
    setIsOtpAsked(false);
    setOtpVerified("not_verified");
    setOtp("");
    setIsContinue(false);
    handleEventChangeMobileNumber();
  };

  const sendOtp = (type) => {
    if (type === "resend") {
      handleResendOtpEvent();
    }
    setLoading("started");
    const { axiosRequest } = PartnerService.sendOtp(mobileNumber);
    axiosRequest
      .then((response) => {
        setIsOtpAsked(true);
        setLoading("done");
        if (response?.data?.responseData?.otpToken) {
          setOtpToken(response.data.responseData.otpToken);
        } else {
          setOtpToken("");
        }
      })
      .catch((error) => {
        setLoading("done");
        setIsOtpAsked(false);
        if (error?.response?.data?.message === "Invalid phone number") {
          setPartnerNotRegistered(true);
        } else {
          setError("Something went wrong, Please try again later.");
        }
        if (error.data) {
          console.log(error.data);
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    const { axiosRequest, abortController } =
      PartnerService.getAnonymousAuthToken();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.authToken) {
          localStorage.setItem(
            "anonymousAuthToken",
            response?.data?.responseData?.authToken
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    handleMobileScreenViewedEvent();
    return () => abortController.abort();
  }, []);

  function checkSelfOnboarding() {
    console.log({ mobileNumber });
    setLoading("started");
    const { axiosRequest } =
      PartnerService.getInstitutionalDetailsByPhoneNumber(mobileNumber);
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.institutionId) {
          let responseData = response?.data?.responseData;
          PartnerService.savePartnerStatuses(responseData);
          localStorage.setItem("institutionId", responseData?.institutionId);
          localStorage.setItem("onboardingPartnerMobileNumber", mobileNumber);
          if (responseData?.status === "ACTIVE") {
            fetchAndSaveProfile();
          } else {
            if (responseData?.status === "INACTIVE") {
              fetchAndSaveProfile();
            }
            if (responseData?.status === "UNVERIFIED") {
              if (responseData?.subStatus === "DOCUMENTS_UPLOAD_PENDING") {
                fetchAndSaveProfile();
              }
              if (responseData?.subStatus === "BASIC_DETAILS_PENDING") {
                navigate(`/register/partner-details`, { replace: true });
              }
              if (responseData?.subStatus === "DOCUMENTS_SUBMITTED") {
                fetchAndSaveProfile();
              }
              if (responseData?.subStatus === "DOCUMENTS_VERIFIED") {
                fetchAndSaveProfile();
              }
              if (responseData?.subStatus === "PROVISIONAL_CHURN") {
                fetchAndSaveProfile();
              }
              if (responseData?.subStatus === "FINALLY_CHURNED") {
                fetchAndSaveProfile();
              }
              if (responseData?.showPrices) {
                fetchAndSaveProfile();
              }
            }
          }
        } else {
          localStorage.setItem("onboardingPartnerMobileNumber", mobileNumber);
          //if no institution ID it is the case for registration
          navigate(`/register/partner-details`, { replace: true });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading("done");
        if (error?.response?.status === 400) {
          localStorage.setItem("onboardingPartnerMobileNumber", mobileNumber);
          //if no institution ID it is the case for registration
          navigate(`/register/partner-details`, { replace: true });
        }
      });
  }

  const fetchAndSaveProfile = () => {
    const farmerId = JSON.parse(localStorage.getItem(`farmerId`));
    const { axiosRequest } = PartnerService.getPartnerDetailedData(farmerId);
    axiosRequest
      .then((partnerDataResponse) => {
        const partnerData = partnerDataResponse?.data?.responseData;
        const { farmerType, userId, address } = partnerData;
        const profileAddress = address?.profileAddress;
        handleMobileNumberEnteredEvent(farmerType, mobileNumber);

        if (
          (farmerType === "B2B Partner" || farmerType === "B2B Onboarding") &&
          profileAddress?.state
        ) {
          let source = ``;
          let partnerStatus = ``;
          let stateName = profileAddress.state;
          if (stateName) {
            let stateFromName = StateService.getStateByName(stateName);
            let stateCode = ``;
            if (
              stateFromName &&
              stateFromName.stateCode &&
              stateFromName.stateCode !== ""
            ) {
              const { axiosRequest } =
                HisaabService.getInstitutionDetails(farmerId);
              axiosRequest
                .then((institutionalDetailsResponse) => {
                  const institutionalDetails =
                    institutionalDetailsResponse?.data?.responseData;
                  if (
                    institutionalDetails?.status === "ACTIVE" ||
                    (institutionalDetails?.status === "UNVERIFIED" &&
                      institutionalDetails?.subStatus !==
                        "BASIC_DETAILS_PENDING") ||
                    (institutionalDetails?.status === "INACTIVE" &&
                      institutionalDetails?.inactivatedOnDate > 0 &&
                      !PartnerService.isPartnerInactiveSinceMoreThanNdays(
                        institutionalDetails?.inactivatedOnDate
                      ))
                  ) {
                    setIsOtpAsked(true);
                    stateCode = stateFromName.stateCode;
                    source = `B2B${stateCode}`;
                    partnerStatus = institutionalDetails?.status;
                    localStorage.setItem("source", source);
                    localStorage.setItem("userId", userId);
                    localStorage.setItem(`state`, stateName);
                    localStorage.setItem("farmerId", farmerId);
                    localStorage.setItem("mobile", partnerData.mobile1);
                    localStorage.setItem("pinCode", profileAddress.pinCode);
                    localStorage.setItem(
                      `creditPeriod`,
                      institutionalDetails?.creditPeriod
                    );
                    localStorage.setItem("partnerStatus", partnerStatus);
                    if (
                      institutionalDetails?.referrerDetails?.referrerEmailId
                    ) {
                      localStorage.setItem(
                        "onboardingReferrerSalesOfficerEmailID",
                        institutionalDetails?.referrerDetails?.referrerEmailId
                      );
                    }
                    if (institutionalDetails?.documentSubmissionStatus) {
                      localStorage.setItem(
                        "documentSubmissionStatus",
                        institutionalDetails?.documentSubmissionStatus
                      );
                    }
                    if (institutionalDetails?.areDocumentsVerified) {
                      localStorage.setItem(
                        "areDocumentsVerified",
                        institutionalDetails?.areDocumentsVerified
                      );
                    }
                    AnalyticsService.pushProfile(
                      farmerId,
                      partnerData.mobile1,
                      source
                    );
                    PartnerService.setWhatsappOptIn(
                      farmerId,
                      mobileNumber,
                      "OPT_IN"
                    );
                    setLoading("done");
                    navigate("/dashboard", { replace: true });
                  } else {
                    setLoading("done");

                    setPartnerInactive(true);
                    localStorage.removeItem("otpVerified");
                    localStorage.removeItem("X-Authorization-Token");
                    console.log("Partner is not active");
                  }
                })
                .catch((error) => {
                  console.log(`error getting institution details`);
                  if (error.data) {
                    console.log(error.data);
                  } else {
                    console.log(error);
                  }
                  setLoading("done");
                });
            } else {
              console.log(`invalid state name`);
            }
          }
        } else {
          if (mobileNumber) {
            setPartnerNotRegistered(true);
          } else {
            setPartnerNotRegistered(false);
          }
          setLoading("done");
        }
      })
      .catch((error) => {
        if (error.data) {
          console.log(error.data);
        } else {
          console.log(error);
        }
        setLoading("done");
        handleMobileNumberEnteredEvent(false, mobileNumber);
        setPartnerNotRegistered(true);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (event.target.elements.continue !== undefined) {
      setIsContinue(true);
      setIsComplete(false);

      if (mobileNumber && mobileNumber.length === 10) {
        sendOtp("");
      }
    }
    if (event.target.elements.complete !== undefined) {
      if (otp && otp.length === 4) {
        setLoading("started");

        handleOtpEnteredEvent(mobileNumber);

        const { axiosRequest } = PartnerService.verifyOtp(
          otp,
          otpToken,
          mobileNumber
        );
        axiosRequest
          .then((response) => {
            if (
              response?.data?.status &&
              response?.data?.message &&
              response.data.status === true &&
              response.data.message === "OTP verified for user" &&
              response?.data?.responseData?.authToken &&
              response?.data?.responseData?.authToken !== "" &&
              response?.data?.responseData?.authToken !== null &&
              response?.data?.responseData?.authToken !== undefined &&
              response?.data?.responseData?.authToken !== "null" &&
              response?.data?.responseData?.authToken !== "undefined"
            ) {
              const userId = response?.data?.responseData?.userId;
              const farmerId = response?.data?.responseData?.farmerId;
              const authToken = response?.data?.responseData?.authToken;
              const fieldAgentSource = response?.data?.responseData?.source;
              const fieldAgentState = response?.data?.responseData?.state;

              localStorage.setItem(`X-Authorization-Token`, authToken);
              setOtpVerified("verified");
              localStorage.setItem(`otpVerified`, true);

              if (response?.data?.responseData?.userType === "field_agent") {
                localStorage.setItem("role", "fieldAgent");
                localStorage.setItem("fieldAgentUserId", userId);
                localStorage.setItem("fieldAgentState", fieldAgentState);
                localStorage.setItem("fieldAgentSource", fieldAgentSource);
                localStorage.setItem("fieldAgentMobileNumber", mobileNumber);
                PartnerService.setLoggedInTimeStamp();
                setLoading("done");
                navigate("/partners");
              } else {
                console.log("self onboarding check");
                if (
                  farmerId &&
                  farmerId !== "null" &&
                  farmerId !== "undefined"
                ) {
                  localStorage.setItem(
                    "onboardingPartnerMobileNumber",
                    mobileNumber
                  );
                  localStorage.setItem("farmerId", JSON.stringify(farmerId));
                  fetchAndSaveProfile();
                } else {
                  checkSelfOnboarding();
                }
              }

              setIsComplete(true);
              setIsContinue(false);
              handleOnBoardingSuccessfulEvent();
            } else {
              Sentry.captureMessage(
                `No Auth Token in response for Validated OTP, phoneNumber = ${mobileNumber}`
              );
              setNoAuthTokenError(true);
              setLoading("done");
            }
          })
          .catch((error) => {
            setLoading("done");
            setOtpVerified("verification_failed");
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
          });
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ErrorBoundary message={``}>
          <USPBanners />
        </ErrorBoundary>
        <div className={classes.body}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/*{!isOtpAsked && (*/}
            {/*  <Grid item xs={12}>*/}
            {/*    <a*/}
            {/*      style={{ textDecoration: "none" }}*/}
            {/*      onClick={() =>*/}
            {/*        window.open(*/}
            {/*          "https://docs.google.com/forms/d/e/1FAIpQLSfkIsaydxWw5g5_HvHAV2r0jTlrl82Z7r4WvXJImbZZ6dBhkA/viewform",*/}
            {/*          "_blank",*/}
            {/*        )*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <Box className={classes.saathiForm}>*/}
            {/*        <Typography className={classes.formText}>*/}
            {/*          To become a Saathi Partner fill this form{" "}*/}
            {/*        </Typography>*/}

            {/*        <ArrowForwardTwoToneIcon*/}
            {/*          style={{ height: "18px", marginTop: "2px" }}*/}
            {/*        />*/}
            {/*      </Box>*/}
            {/*    </a>*/}
            {/*  </Grid>*/}
            {/*)}*/}

            <Grid item xs={12} className={classes.titleWrapper}>
              <Typography variant={"h6"}>Enter Your</Typography>
              <Typography className={classes.titleText}>
                {isOtpAsked ? "OTP" : "Mobile Number"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isOtpAsked ? (
                <Typography
                  variant={"subtitle2"}
                  className={classes.subtitleWrapper}
                >
                  We have sent the OTP to your mobile number -{" "}
                  <b
                    className={classes.editText}
                    onClick={() => handleEditMobileClick()}
                  >
                    {mobileNumber} <EditIcon className={classes.editIcon} />
                  </b>
                </Typography>
              ) : (
                <Typography
                  variant={"subtitle2"}
                  className={classes.subtitleWrapper}
                >
                  {mode === "login"
                    ? "Enter your Saathi registered mobile number"
                    : ""}

                  {mode === "signup" ? "Enter your mobile number" : ""}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={classes.inputField}>
              {isOtpAsked ? (
                <TextField
                  id="otp"
                  variant="outlined"
                  fullWidth
                  required={true}
                  onChange={handleChangeOtp}
                  name="otp"
                  value={otp}
                  inputProps={{
                    type: "tel",
                    autoFocus: true,
                    minLength: "4",
                    maxLength: "4",
                    pattern: "[0-9]+",
                    title: "e.g. 1010",
                  }}
                  style={{ background: "#F3F3F3", border: "0px !important" }}
                />
              ) : (
                <TextField
                  id="mobileNumber"
                  variant="outlined"
                  fullWidth
                  required={true}
                  onChange={handleChangeMobileNumber}
                  name="mobileNumber"
                  value={mobileNumber}
                  inputProps={{
                    type: "tel",
                    autoFocus: true,
                    minLength: "10",
                    maxLength: "10",
                    pattern: "[0-9]+",
                    title: "e.g. 9999999999",
                    autoComplete: "tel",
                  }}
                  style={{ background: "#F3F3F3", border: "0px !important" }}
                  // color="F3F3F3"
                />
              )}
              {partnerNotRegistered && (
                <Typography className={classes.wrongNo}>
                  <ErrorOutlineIcon
                    fontSize="medium"
                    className={classes.infoIcon}
                  />
                  This number is not registered with AgroStar.
                </Typography>
              )}
              {partnerInactive && (
                <Typography className={classes.wrongNo}>
                  <ErrorOutlineIcon
                    fontSize="medium"
                    className={classes.infoIcon}
                  />
                  Your account with AgroStar has been deactivated. Please give
                  us a call at 9960107107 for more details.
                </Typography>
              )}
            </Grid>
            {!partnerNotRegistered && (
              <Grid item xs={12}>
                {isOtpAsked ? (
                  <Button
                    size="large"
                    variant="contained"
                    color={`primary`}
                    type={"submit"}
                    name={"complete"}
                    fullWidth
                    value={"complete"}
                    className={classes.continueButton}
                    endIcon={
                      loading === "started" ? (
                        <CircularProgress size={26} sx={{ color: "#fff" }} />
                      ) : (
                        <ArrowForwardTwoToneIcon />
                      )
                    }
                    disabled={
                      !otp ||
                      (otp && otp.length !== 4) ||
                      ((otpVerified === "not_verified" ||
                        otpVerified === "verification_failed") &&
                        otpVerified !== "entered")
                    }
                  >
                    {loading === "started" ? "" : "COMPLETE"}
                  </Button>
                ) : (
                  <Button
                    size="large"
                    variant="contained"
                    color={`primary`}
                    type={"submit"}
                    fullWidth
                    value={"continue"}
                    name={"continue"}
                    className={classes.continueButton}
                    endIcon={
                      loading === "started" ? (
                        <CircularProgress size={26} sx={{ color: "#fff" }} />
                      ) : (
                        <ArrowForwardTwoToneIcon />
                      )
                    }
                    disabled={
                      !(mobileNumber.length === 10) ||
                      (mobileNumber &&
                        mobileNumber.length === 10 &&
                        partnerNotRegistered)
                    }
                  >
                    {loading === "started" ? "" : "CONTINUE"}
                  </Button>
                )}
              </Grid>
            )}
            {partnerNotRegistered && (
              <>
                <Grid item xs={12}>
                  <Button
                    size="large"
                    variant="contained"
                    color={`primary`}
                    type={"button"}
                    fullWidth
                    className={classes.registerNowButton}
                    endIcon={<ArrowForwardTwoToneIcon />}
                    onClick={() => handleRegisterNowClickEvent(mobileNumber)}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfkIsaydxWw5g5_HvHAV2r0jTlrl82Z7r4WvXJImbZZ6dBhkA/viewform"
                  >
                    Partner With Us
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.registerWithUs}>
                  <Typography>
                    Give a missed call to us on <b>02068110610</b> to know more
                    details
                  </Typography>
                  <IconButton href={`tel:02068110610`} size="large">
                    <CallIcon className={classes.callIcon} />
                  </IconButton>
                </Grid>
              </>
            )}

            {error && (
              <Typography className={classes.wrongNo}>
                <ErrorOutlineIcon
                  fontSize="medium"
                  className={classes.infoIcon}
                />
                {error}
              </Typography>
            )}

            {isOtpAsked &&
              otp &&
              otp.length === 4 &&
              otpVerified === "verification_failed" && (
                <Grid item xs={12}>
                  <Typography className={classes.wrongNo}>
                    <ErrorOutlineIcon
                      fontSize="medium"
                      className={classes.infoIcon}
                    />
                    OTP expired or Invalid OTP entered. Please enter again.
                  </Typography>
                  <Typography
                    className={classes.editText}
                    onClick={() => {
                      setOtp("");
                      sendOtp("resend");
                    }}
                  >
                    <b>Resend OTP</b>
                  </Typography>
                </Grid>
              )}
            {noAuthTokenError && (
              <Grid item xs={12}>
                <Typography className={classes.wrongNo}>
                  <ErrorOutlineIcon
                    fontSize="medium"
                    className={classes.infoIcon}
                  />
                  Something went wrong. Please try again.
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classes.footer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/*<AgentLoginButton />*/}
            {!partnerNotRegistered && (
              <Grid item xs={12} className={classes.tAndc}>
                By clicking on ‘continue’ you agree with the
                <br />
                <a
                  href="https://www.corporate.agrostar.in/saathitnc-en"
                  className={classes.tncHighlight}
                >
                  terms & conditions and the privacy policy
                </a>
              </Grid>
            )}
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
