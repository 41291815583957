import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { WhatsappShareButton } from "react-share";
import config from "../../config";
import useMessages from "../../Hooks/useMessages";
import AnalyticsService from "../../Services/AnalyticsService";
import Languages from "../../Services/LanguageCode";

const useStyles = makeStyles((theme) => ({
  shareProfile: {
    textTransform: "capitalize",
    fontWeight: "700",
  },
  profileShareButton: {
    width: "100%",
  },
}));

const ShareProfile = (props) => {
  const classes = useStyles();
  const { getMessage } = useMessages();
  const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "en";
  let shopData = props.profileData;
  let whatsappMessage =
    getMessage(`profile.header`) +
    "\r\n\r\n" +
    getMessage(`profile.storeName`) +
    shopData?.nameInRegionalLanguage +
    "\r\n" +
    getMessage(`profile.storeAddress`) +
    shopData?.addressInRegionalLanguage?.village +
    ", " +
    shopData?.addressInRegionalLanguage?.taluka +
    ", " +
    shopData?.addressInRegionalLanguage?.district +
    ", " +
    shopData?.addressInRegionalLanguage?.state +
    ", " +
    shopData?.addressInRegionalLanguage?.country +
    ", " +
    shopData?.addressInRegionalLanguage?.pincode +
    ".\r\n" +
    getMessage(`profile.storePhoneNumber`) +
    shopData?.contacts?.mobileNumber +
    "\r\n\r\n";

  const handleProfileShareEvent = () => {
    console.log("Event Clicked");
    const profileSharedData = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(`Profile Shared`, profileSharedData);
  };
  return (
    <>
      <WhatsappShareButton
        url={`.`}
        title={whatsappMessage}
        className={classes.profileShareButton}
      >
        <Button
          size={`large`}
          variant={"outlined"}
          color={"primary"}
          fullWidth={true}
          type={`submit`}
          sx={{ borderRadius: "10px" }}
          className={classes.shareProfile}
          onClick={handleProfileShareEvent}
          component={`a`}
        >
          Share Profile
        </Button>
      </WhatsappShareButton>
    </>
  );
};
export default ShareProfile;
