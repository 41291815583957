import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlockUi from "react-block-ui";
import { getTime, startOfDay, endOfDay, format } from "date-fns";

import {
  Button,
  Grid,
  Typography,
  TextField,
  Container,
  Alert,
  Snackbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import ErrorBoundary from "../Common/ErrorBoundary";
import AndroidService from "../../Services/AndroidService";
import HisaabService from "../../Services/HisaabService";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    minHeight: window.innerHeight,
  },
  blockUI: {
    height: "90vh",
  },
  closeButton: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  iconWrapper: {
    marginTop: theme.spacing(4),
  },
  icon: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  downloadMessage: {
    margin: theme.spacing(1, 0, 0.5),
    fontWeight: "bold",
    lineHeight: `1.1`,
    color: "#000000",
  },
  info: {
    lineHeight: `1.1`,
    color: "#000000",
  },
  downloadButtonWrapper: {
    marginTop: theme.spacing(15),
  },
  downloadButton: {
    borderRadius: theme.spacing(1.25),
  },
  formGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0, 0),
  },
  dateField: {
    width: "75%",
    marginLeft: theme.spacing(1),
  },
  alertMessage: {
    background: "#666666",
    color: "#ffffff",
    textAlign: "center",
    lineHeight: `1.1`,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

function GenerateStatement() {
  const classes = useStyles();
  let navigate = useNavigate();

  const InstitutionDetails = localStorage.getItem("InstitutionDetails")
    ? JSON.parse(localStorage.getItem("InstitutionDetails"))
    : null;

  const dates = window?.location?.search.split("&");

  const HisaabStatementFromDate = new Date(dates[0].split("?startDate=")[1]);
  const HisaabStatementToDate = new Date(dates[1].split("endDate=")[1]);

  const FROM_DATE = HisaabStatementFromDate
    ? format(HisaabStatementFromDate, "yyyy-MM-dd")
    : format(new Date(), "yyyy-MM-dd");

  const TO_DATE = HisaabStatementToDate
    ? format(HisaabStatementToDate, "yyyy-MM-dd")
    : format(new Date(), "yyyy-MM-dd");

  const [startDate, setStartDate] = useState(FROM_DATE);
  const [endDate, setEndDate] = useState(TO_DATE);
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [loading, setLoading] = useState("not_started");
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [showStorageAlertMessage, setShowStorageAlertMessage] = useState(false);
  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);

  useEffect(() => {
    AnalyticsService.pushEvent("Transactions PDF Download Page Viewed");
  }, []);

  useEffect(() => {
    if (!userId || !localStorage.getItem("userId")) {
      setLoading("started");
      const { axiosRequest, abortController } =
        PartnerService.getPartnerDetailedData(localStorage.getItem(`farmerId`));
      axiosRequest
        .then((response) => {
          localStorage.setItem("userId", response.data.responseData.userId);
          setUserId(response?.data?.responseData?.userId);
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting farmer details`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    }
  }, [userId]);

  const downloadFile = (
    fileData,
    fromDate,
    toDate,
    fileType,
    fileContentType
  ) => {
    const newBlob = new Blob([fileData], {
      type: fileContentType,
    });

    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = `AgroStar-Saathi-Transactions-from-${format(
      fromDate,
      "dd-MM-yyyy"
    )}-to-${format(toDate, "dd-MM-yyyy")}.${fileType}`;
    link.click();
    setLoading("done");
  };

  const handleDownloadNowClick = (fileType) => {
    setLoading("started");
    AnalyticsService.pushEvent(`Hisaab Download Now Clicked`, {
      startDate,
      endDate,
      fileType,
    });

    const fromDate = getTime(startOfDay(new Date(startDate)));
    const toDate = getTime(endOfDay(new Date(endDate)));

    const { axiosRequest } = HisaabService.downloadTransactions(
      fromDate,
      toDate,
      userId,
      InstitutionDetails?.name ? InstitutionDetails?.name : "",
      InstitutionDetails?.contacts?.mobileNumber
        ? InstitutionDetails?.contacts?.mobileNumber
        : "",
      InstitutionDetails?.bankAndGST?.virtualAccountNumber
        ? InstitutionDetails?.bankAndGST?.virtualAccountNumber
        : "",
      InstitutionDetails?.bankAndGST?.gstNumber
        ? InstitutionDetails?.bankAndGST?.gstNumber
        : "",
      InstitutionDetails?.bankAndGST?.panNumber
        ? InstitutionDetails?.bankAndGST?.panNumber
        : "",
      fileType
    );
    axiosRequest
      .then((response) => {
        try {
          var fileContentType = response.headers.get("Content-Type");
          if (AndroidService.checkIfAndroid()) {
            /*global Android*/
            Android.setFileName(
              `Agrostar-Saathi-Transactions-from-${format(
                fromDate,
                "dd-MM-yyyy"
              )}-to-${format(toDate, "dd-MM-yyyy")}`
            );
            downloadFile(
              response.data,
              fromDate,
              toDate,
              fileType,
              fileContentType
            );
          } else {
            console.log("No android");
            downloadFile(
              response.data,
              fromDate,
              toDate,
              fileType,
              fileContentType
            );
          }
        } catch (error) {
          console.log("error in setting file name in android", error);
          setLoading("done");
        }
      })
      .catch((error) => {
        console.log(`error getting transaction details`);
        if (error.data) {
          console.log(error.data);
        } else {
          console.log(error);
        }
        setLoading("done");
      });
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    AnalyticsService.pushEvent("Hisaab PDF Start Date Changed", {
      previousDate: startDate,
      selectedDate: event.target.value,
    });
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    AnalyticsService.pushEvent("Hisaab PDF End Date Changed", {
      previousDate: endDate,
      selectedDate: event.target.value,
    });
  };

  return (
    <div className={classes.root}>
      <ErrorBoundary message={``}>
        <Container maxWidth={`xl`}>
          <BlockUi
            tag="div"
            blocking={loading === "started" && loading !== "done"}
            message={`Loading...`}
            keepInView
            renderChildren={false}
            className={classes.blockUI}
          >
            <AppVersionUnsupported
              open={openAppUpdateDialog}
              handleClose={() => {
                setOpenAppUpdateDialog(false);
              }}
            />
            <Snackbar
              open={showStorageAlertMessage}
              autoHideDuration={5000}
              onClose={() => setShowStorageAlertMessage(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                icon={false}
                className={classes.alertMessage}
                onClose={() => setShowStorageAlertMessage(false)}
              >
                Provide Storage Permission{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              open={showAlertMessage}
              autoHideDuration={5000}
              onClose={() => setShowAlertMessage(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                icon={false}
                className={classes.alertMessage}
                onClose={() => setShowAlertMessage(false)}
              >
                No Transactions Found{" "}
              </Alert>
            </Snackbar>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={1}
                className={classes.closeButton}
                onClick={() => {
                  navigate(`/hisaab/transactions`);
                }}
              >
                <CloseOutlinedIcon />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4} className={classes.iconWrapper}>
                <img
                  src={`/icons/downloadHisaabPdf.svg`}
                  className={classes.icon}
                  alt=""
                />
              </Grid>

              <Grid item xs={10}>
                <Typography
                  align={"center"}
                  variant={`body1`}
                  color={`secondary`}
                  className={classes.downloadMessage}
                >
                  Download Your Statement
                </Typography>
                <Typography
                  align={"center"}
                  variant={`body1`}
                  color={`secondary`}
                  className={classes.info}
                >
                  {
                    "PDF will have all credit & debit entries within the selected date range"
                  }
                </Typography>
              </Grid>

              <Grid item xs={10} className={classes.formGroup}>
                <Typography>From</Typography>
                <TextField
                  id="startDate"
                  type="date"
                  required
                  defaultValue={startDate}
                  onChange={(event) => handleStartDateChange(event)}
                  className={classes.dateField}
                />
              </Grid>

              <Grid item xs={10} className={classes.formGroup}>
                <Typography>To</Typography>
                <TextField
                  id="endDate"
                  type="date"
                  required
                  defaultValue={endDate}
                  onChange={(event) => handleEndDateChange(event)}
                  inputProps={{ min: startDate }}
                  className={classes.dateField}
                />
              </Grid>

              <Grid item xs={12} className={classes.downloadButtonWrapper}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <Button
                      size={"medium"}
                      variant={"contained"}
                      color={"primary"}
                      fullWidth={true}
                      className={classes.downloadButton}
                      disabled={!startDate || !endDate}
                      onClick={() => handleDownloadNowClick("pdf")}
                    >
                      Download Pdf
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size={"medium"}
                      variant={"contained"}
                      color={"primary"}
                      fullWidth={true}
                      className={classes.downloadButton}
                      disabled={!startDate || !endDate}
                      onClick={() => {
                        if (AndroidService.checkIfAndroid()) {
                          if (AndroidService.getAndroidVersion()) {
                            let { appVersionCode } =
                              AndroidService.getAndroidVersion();
                            if (
                              appVersionCode &&
                              parseInt(appVersionCode) < 21
                            ) {
                              setOpenAppUpdateDialog(true);
                            } else {
                              handleDownloadNowClick("xlsx");
                            }
                          } else {
                            setOpenAppUpdateDialog(true);
                          }
                        } else {
                          handleDownloadNowClick("xlsx");
                        }
                      }}
                    >
                      Download Excel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BlockUi>
        </Container>
      </ErrorBoundary>
    </div>
  );
}

export default GenerateStatement;
