import { useEffect, useState } from "react";
import VideoService from "../Services/VideoService";
import { isToday } from "date-fns";

function useFeaturedVideos() {
  const [loading, setLoading] = useState("not_started");
  const [featuredVideos, setFeaturedVideos] = useState(null);
  const [error, setError] = useState(null);
  const [liveSession, setLiveSession] = useState(null);

  const currentTimestamp = Math.floor(new Date() / 1000);

  useEffect(() => {
    setLoading("started");

    const { axiosRequest, abortController } = VideoService.getFeaturedVideos();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.videos?.length > 0) {
          let videosFromResponse = response?.data?.responseData?.videos;
          videosFromResponse = videosFromResponse?.sort(
            (a, b) => parseInt(a.ranking) - parseInt(b.ranking)
          );

          videosFromResponse = videosFromResponse.filter((video) => {
            const startDateTimestamp = Math.floor(
              new Date(video.startDate).getTime() / 1000
            );
            const endDateTimestamp = Math.floor(
              new Date(video.endDate).getTime() / 1000
            );
            return (
              currentTimestamp >= startDateTimestamp &&
              currentTimestamp <= endDateTimestamp
            );
          });

          const sessionList = videosFromResponse.filter((video) => {
            if (
              isToday(new Date()) &&
              isToday(new Date(video.startDate)) &&
              isToday(new Date(video.endDate))
            ) {
              if (video?.redirectionLink.includes("meet.google.com")) {
                return video;
              }
            }
          });
          if (sessionList?.length > 0) {
            // Below filter added to get latest live video with minimum(higher) ranking
            let minRankingValue = Infinity;
            let minRankingObject = null;
            sessionList.forEach((video) => {
              const rankingValue = parseInt(video.ranking);
              if (rankingValue <= minRankingValue) {
                minRankingValue = rankingValue;
                minRankingObject = video;
              }
            });
            setLiveSession(minRankingObject);

            // Below filter added to exclude live video from list of featured videos
            const featuredVideosList = sessionList.map(
              (item) => item["videoCode"]
            );
            videosFromResponse = videosFromResponse.filter(
              (item2) => !featuredVideosList.includes(item2["videoCode"])
            );
          }
          setFeaturedVideos(videosFromResponse);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, []);

  return { featuredVideos, loading, error, liveSession };
}

export default useFeaturedVideos;
