import {
  Avatar,
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import TopBar from "../Common/TopBar";
import { useEffect, useState } from "react";
import PartnerService from "../../Services/PartnerService";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import makeStyles from "@mui/styles/makeStyles";
import CallSentDialog from "./CallSentDialog";
import OKR_EXECUTIVES from "./OKRExecutives";
import { isSameDay } from "date-fns";
import EmptyError from "../EmptyError/EmptyError";
import BlockUi from "react-block-ui";
import { useLocation } from "react-router-dom";
import { deepOrange } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  detailContainerStyle: {
    backgroundColor: "#FFF",
    borderRadius: "12px",
    boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
    margin: "8px 16px 8px 16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageStyle: {
    width: "48px",
    height: "48px",
    aspectRatio: "1:1",
    borderRadius: "100px",
    border: "1px solid #CACACA",
    margin: "6px 8px 6px 8px",
  },
  callButtonStyle: {
    align: "end",
    fontSize: "12px",
    fontWeight: "700",
    color: "#9D2123",
    borderRadius: "16px",
    border: "1px solid #9D2123",
    padding: "2px 8px",
    margin: "8px",
    textTransform: "none",
  },
  requestedCallButtonStyle: {
    align: "end",
    fontSize: "12px",
    fontWeight: "700",
    color: "#00733E",
    borderRadius: "16px",
    border: "1px solid #00733E",
    padding: "2px 8px",
    margin: "8px",
    textTransform: "none",
  },
  nameTextStyle: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#333",
  },
  roleTextStyle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#333",
  },
  nameRoleContainerStyle: {
    margin: "6px 0px",
    flex: "3",
  },
  loaderStyle: {
    height: 100,
    margin: "8px 16px 8px 16px",
    borderRadius: "16px",
  },
}));

function BizConnect() {
  const classes = useStyles();
  let location = useLocation();
  const [loading, setLoading] = useState("not-started");
  const [okrMapping, setOKRMapping] = useState([]);
  const [shouldShowCallSentDialog, setShouldShowCallSentDialog] =
    useState(false);
  const [requestedCalls, setRequestedCalls] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [errorCode, setErrorCode] = useState(null);
  const OKR_MAPPING_NOT_FOUND = 404;

  useEffect(() => {
    setLoading("started");
    const pageViewedData = {
      page: location.pathname,
    };
    AnalyticsService.pushEvent(`BizConnect Page Viewed`, pageViewedData);
    setRequestedCalls(getTodayRequestedCalls());
    const { axiosRequest, abortController } = PartnerService.getOKRMapping();
    axiosRequest
      .then((response) => {
        setLoading("done");
        if (response.data.responseData) {
          setOKRMapping(transformData(response.data.responseData));
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(error);
          setLoading("done");
          setErrorCode(error?.response?.status);
        }
      });
    return () => abortController.abort();
  }, []);

  const getTodayRequestedCalls = () => {
    var profiles =
      JSON.parse(localStorage.getItem("callRequestedProfiles")) || [];
    if (profiles) {
      var filteredProfiles = profiles.filter((profile) =>
        isRequestedOnSameDay(profile.requestedTimeStamp)
      );
      filteredProfiles.length == 0
        ? localStorage.removeItem("callRequestedProfiles")
        : localStorage.setItem(
            "callRequestedProfiles",
            JSON.stringify(filteredProfiles)
          );
    }
    return profiles;
  };

  const isRequestedOnSameDay = (timeStamp) => {
    return isSameDay(Date.now(), new Date(timeStamp));
  };

  const transformData = (data) => {
    var allMappingsFromResponse = {
      ...data.HqMapping.currentHq,
      ...data.TerritoryMapping.currentTerritory,
      ...data.TerritoryMapping.currentCluster,
      ...data.TerritoryMapping.currentBuUnit,
    };
    var transformedResponse = [];
    var keys = Object.keys(allMappingsFromResponse);
    OKR_EXECUTIVES.forEach((element) => {
      if (isRoleExist(keys, element.key)) {
        transformedResponse = [
          ...transformedResponse,
          {
            name: getFieldValueFromMapping(
              "Name",
              element.key,
              allMappingsFromResponse
            ),
            number: getFieldValueFromMapping(
              "Number",
              element.key,
              allMappingsFromResponse
            ),
            role: element.value,
            type: element.type,
          },
        ];
      }
    });
    return transformedResponse;
  };
  const isRoleExist = (responseKeys, role) => {
    return responseKeys?.filter((key) => key?.startsWith(role))?.length > 0;
  };
  const getFieldValueFromMapping = (key, role, mapping) => {
    if (role == "centralSalesTeam" && key == "Number") {
      key = "ContactNumber";
    }
    return mapping[`${role}${key}`] ? mapping[`${role}${key}`] : "";
  };

  const handleCallButtonClick = (entry) => {
    if (entry.type == "call") {
      var requestedNumber =
        entry.role == "Relationship Manager" ? "9960107107" : entry.number;
      makeCall(requestedNumber, entry.role);
    } else if (
      entry.type == "request_call" &&
      !checkIfAlreadyRequested(entry)
    ) {
      sendCallRequestOnCT(entry);
      markProfileCallRequested(entry);
      toggleCallSentAcknowledgement(true, entry.name);
    }
  };

  const toggleCallSentAcknowledgement = (shouldShow, name = "") => {
    setProfileName(name);
    setShouldShowCallSentDialog(shouldShow);
  };

  const markProfileCallRequested = (entry) => {
    entry.requestedTimeStamp = new Date().getTime();
    var profiles = [...requestedCalls, entry];
    setRequestedCalls((requestedCalls) => [...requestedCalls, entry]);
    localStorage.setItem("callRequestedProfiles", JSON.stringify(profiles));
  };

  const sendCallRequestOnCT = (entry) => {
    const callRequestedData = {
      page: location.pathname,
      name: entry.name,
      number: entry.number,
      role: entry.role,
    };
    AnalyticsService.pushEvent(`Call Requested`, callRequestedData);
  };

  const getCallStatus = (element) => {
    if (element.type == "call") return "Call";
    if (element.type == "request_call") {
      if (checkIfAlreadyRequested(element)) {
        return "Requested";
      } else {
        return "Request Call";
      }
    }
  };

  const checkIfAlreadyRequested = (element) => {
    if (requestedCalls && requestedCalls.length == 0) return false;
    var filteredProfile = requestedCalls.filter(
      (profile) =>
        element.name === profile.name && element.role === profile.role
    );
    return filteredProfile !== null && filteredProfile.length > 0;
  };

  const makeCall = (number, role) => {
    const callData = {
      page: location.pathname,
      number: number,
      role: role,
    };
    AnalyticsService.pushEvent(`Call Button Clicked`, callData);
    window.location.href = `tel:${number}`;
  };

  return (
    <>
      <TopBar title={`BizConnect`} backNavigation={true} toolTip={``} />
      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        className={classes.blockUI}
        style={{ minHeight: "100vh" }}
      >
        <ErrorBoundary message={``}>
          {okrMapping &&
            okrMapping?.length > 0 &&
            okrMapping.map((entry, index) => {
              return (
                <ErrorBoundary message={""} key={index}>
                  <Stack
                    direction={"row"}
                    className={classes.detailContainerStyle}
                  >
                    <Avatar
                      alt={
                        entry.name != " " && entry.name != ""
                          ? entry.name?.trim()
                          : entry.role?.trim()
                      }
                      className={classes.imageStyle}
                      src={`https://static.agrostar.in/saathi/executives/tr:w-150,h-150,fo-face/${entry.number}.jpg`}
                    ></Avatar>
                    <Stack
                      direction="column"
                      className={classes.nameRoleContainerStyle}
                    >
                      <Typography className={classes.nameTextStyle}>
                        {entry.name ? entry.name : ""}
                      </Typography>
                      <Typography className={classes.roleTextStyle}>
                        {entry.role}
                      </Typography>
                    </Stack>
                    <Box flex="2.1" textAlign="end">
                      <Button
                        className={
                          checkIfAlreadyRequested(entry)
                            ? classes.requestedCallButtonStyle
                            : classes.callButtonStyle
                        }
                        onClick={() => handleCallButtonClick(entry)}
                      >
                        {getCallStatus(entry)}
                      </Button>
                    </Box>
                  </Stack>
                </ErrorBoundary>
              );
            })}
          {(errorCode == OKR_MAPPING_NOT_FOUND ||
            okrMapping == null ||
            okrMapping.length == 0) && (
            <>
              <div>
                <EmptyError
                  image="EmptyPage.svg"
                  text="BizConnect details will be available soon"
                  extraStyle={{
                    padding: "24px",
                    minHeight: "calc(100vh - 4rem)",
                  }}
                />
              </div>
            </>
          )}
        </ErrorBoundary>
      </BlockUi>
      <CallSentDialog
        open={shouldShowCallSentDialog}
        name={profileName}
        handleClose={() => {
          toggleCallSentAcknowledgement(false);
        }}
      />
    </>
  );
}

export default BizConnect;
