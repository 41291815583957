const Leads = {
  message: "OK",
  responseData: {
    leads: [
      {
        leadId: "b738ff45-382d-4416-ac5d-6c57a15b32df",
        farmerId: 1920884,
        leadStatus: "active",
        leadRemark: "",
        leadCreationDate: 1663665285000,
        farmerAddress: {
          city: "Pune",
          farmerName: "rohitPunawale arun dharrao",
          country: "IN",
          farmerPincode: "411033",
          farmerPhone: "7020776806",
          state: "Maharashtra",
          line2: "Punawale | Punawale B.O | Mulshi",
          line1: "mamledar chowk",
        },
        products: [
          {
            productSkuCode: "AGS-HW-1217",
            productName: "Spraywell 2 stroke Petrol Power Sprayer Luxury",
            productImage: "https://static.agrostar.in/static/AGS-HW-1217_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1224",
            productName: "RSR Power Sprayer 4 Stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1224_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1229",
            productName: "Sunlord Power Sprayer 2 stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1229_l.jpg",
          },
        ],
      },
      {
        leadId: "c14a0a60-6cdd-4ce2-acce-691d51bd88c1",
        farmerId: 1920884,
        leadStatus: "active",
        leadRemark: "",
        leadCreationDate: 1663666353000,
        farmerAddress: {
          city: "Pune",
          farmerName: "rohitPunawale arun dharrao",
          country: "IN",
          farmerPincode: "411033",
          farmerPhone: "7020776806",
          state: "Maharashtra",
          line2: "Punawale | Punawale B.O | Mulshi",
          line1: "mamledar chowk",
        },
        products: [
          {
            productSkuCode: "AGS-HW-774",
            productName: "Gladiator Battery Pump GL1012 (12*12)",
            productImage: "https://static.agrostar.in/static/AGS-HW-774_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1217",
            productName: "Spraywell 2 stroke Petrol Power Sprayer Luxury",
            productImage: "https://static.agrostar.in/static/AGS-HW-1217_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1224",
            productName: "RSR Power Sprayer 4 Stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1224_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1229",
            productName: "Sunlord Power Sprayer 2 stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1229_l.jpg",
          },
        ],
      },
      {
        leadId: "f1e2ef27-2406-4cdc-9436-b4a0f0ebfb3a",
        farmerId: 1920884,
        leadStatus: "attempted",
        leadRemark: "",
        leadCreationDate: 1663666965000,
        farmerAddress: {
          city: "Pune",
          farmerName: "rohitPunawale arun dharrao",
          country: "IN",
          farmerPincode: "411033",
          farmerPhone: "7020776806",
          state: "Maharashtra",
          line2: "Punawale | Punawale B.O | Mulshi",
          line1: "mamledar chowk",
        },
        products: [
          {
            productSkuCode: "AGS-HW-774",
            productName: "Gladiator Battery Pump GL1012 (12*12)",
            productImage: "https://static.agrostar.in/static/AGS-HW-774_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1217",
            productName: "Spraywell 2 stroke Petrol Power Sprayer Luxury",
            productImage: "https://static.agrostar.in/static/AGS-HW-1217_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1224",
            productName: "RSR Power Sprayer 4 Stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1224_l.jpg",
          },
        ],
      },
      {
        leadId: "4f0db22f-9396-4fda-bf61-f5411407b6d6",
        farmerId: 1920884,
        leadStatus: "active",
        leadRemark: "",
        leadCreationDate: 1663742518000,
        farmerAddress: {
          city: "Pune",
          farmerName: "rohitPunawale arun dharrao",
          country: "IN",
          farmerPincode: "411033",
          farmerPhone: "7020776806",
          state: "Maharashtra",
          line2: "Punawale | Punawale B.O | Mulshi",
          line1: "mamledar chowk",
        },
        products: [
          {
            productSkuCode: "AGS-HW-774",
            productName: "Gladiator Battery Pump GL1012 (12*12)",
            productImage: "https://static.agrostar.in/static/AGS-HW-774_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1217",
            productName: "Spraywell 2 stroke Petrol Power Sprayer Luxury",
            productImage: "https://static.agrostar.in/static/AGS-HW-1217_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1224",
            productName: "RSR Power Sprayer 4 Stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1224_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1229",
            productName: "Sunlord Power Sprayer 2 stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1229_l.jpg",
          },
        ],
      },
      {
        leadId: "1cfef1ea-c317-4fec-937b-eb10c67d422b",
        farmerId: 1920884,
        leadStatus: "active",
        leadRemark: "",
        leadCreationDate: 1663754814000,
        farmerAddress: {
          city: "Pune",
          farmerName: "rohitPunawale arun dharrao",
          country: "IN",
          farmerPincode: "411033",
          farmerPhone: "7020776806",
          state: "Maharashtra",
          line2: "Punawale | Punawale B.O | Mulshi",
          line1: "mamledar chowk",
        },
        products: [
          {
            productSkuCode: "AGS-HW-774",
            productName: "Gladiator Battery Pump GL1012 (12*12)",
            productImage: "https://static.agrostar.in/static/AGS-HW-774_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1217",
            productName: "Spraywell 2 stroke Petrol Power Sprayer Luxury",
            productImage: "https://static.agrostar.in/static/AGS-HW-1217_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1224",
            productName: "RSR Power Sprayer 4 Stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1224_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1229",
            productName: "Sunlord Power Sprayer 2 stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1229_l.jpg",
          },
        ],
      },
      {
        leadId: "38611fa1-e35c-46d2-ba97-db368d7a2018",
        farmerId: 1920884,
        leadStatus: "rejected",
        leadRemark: "distance",
        leadCreationDate: 1663754966000,
        farmerAddress: {
          city: "Pune",
          farmerName: "rohitPunawale arun dharrao",
          country: "IN",
          farmerPincode: "411033",
          farmerPhone: "7020776806",
          state: "Maharashtra",
          line2: "Punawale | Punawale B.O | Mulshi",
          line1: "mamledar chowk",
        },
        products: [
          {
            productSkuCode: "AGS-HW-774",
            productName: "Gladiator Battery Pump GL1012 (12*12)",
            productImage: "https://static.agrostar.in/static/AGS-HW-774_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1217",
            productName: "Spraywell 2 stroke Petrol Power Sprayer Luxury",
            productImage: "https://static.agrostar.in/static/AGS-HW-1217_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1224",
            productName: "RSR Power Sprayer 4 Stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1224_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1229",
            productName: "Sunlord Power Sprayer 2 stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1229_l.jpg",
          },
        ],
      },
      {
        leadId: "cf2da77f-24bb-44cd-9b06-5e1b8c15c4c4",
        farmerId: 1920884,
        leadStatus: "active",
        leadRemark: "",
        leadCreationDate: 1663830694000,
        farmerAddress: {
          city: "Pune",
          farmerName: "rohitPunawale arun dharrao",
          country: "IN",
          farmerPincode: "411033",
          farmerPhone: "7020776806",
          state: "Maharashtra",
          line2: "Punawale | Punawale B.O | Mulshi",
          line1: "mamledar chowk",
        },
        products: [
          {
            productSkuCode: "AGS-HW-774",
            productName: "Gladiator Battery Pump GL1012 (12*12)",
            productImage: "https://static.agrostar.in/static/AGS-HW-774_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1217",
            productName: "Spraywell 2 stroke Petrol Power Sprayer Luxury",
            productImage: "https://static.agrostar.in/static/AGS-HW-1217_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1224",
            productName: "RSR Power Sprayer 4 Stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1224_l.jpg",
          },
          {
            productSkuCode: "AGS-HW-1229",
            productName: "Sunlord Power Sprayer 2 stroke Petrol (25 Ltr)",
            productImage: "https://static.agrostar.in/static/AGS-HW-1229_l.jpg",
          },
        ],
      },
    ],
  },
  status: true,
};

export default Leads;
