import React, { useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import useStories from "../../Hooks/useStories";
import AndroidService from "../../Services/AndroidService";
import StoryEmptyScreen from "./StoryEmptyScreen";
import Stories from "./Stories";
import CommonConstantValues from "../../Services/CommonConstantValues";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    overflowX: "scroll",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    scrollSnapType: "x mandatory",
    maxHeight: "1200px",
    background: "white",
    overflowY: "hidden",
    overscrollBehavior: "none",
  },
  mahaulStoryContainer: {
    zIndex: 0,
    scrollSnapStop: "always",
    scrollSnapAlign: "start",
    width: "100%",
    height: "100%",
    position: "relative",
  },
}));

const StoriesContainer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { stories, loading, error, storiesDurations, markStoryViewed } =
    useStories();

  useEffect(() => {
    AndroidService.unsetPullToRefresh();
    return () => {
      AndroidService.setPullToRefresh();
    };
  }, []);

  useEffect(() => {
    if (loading === CommonConstantValues.DONE) {
      const sections = document.querySelectorAll(
        "." + classes.mahaulStoryContainer
      );
      let storyIndex = -1;
      const storyId = params.storyId;
      if (storyId && stories?.length) {
        storyIndex = _.findIndex(
          stories,
          (story) => story[0].storyParentCode === storyId
        );
      }

      const observer = new IntersectionObserver(
        function (entries) {
          _.forEach(entries, (entry, _) => {
            if (entry?.isIntersecting) {
              const newIndex = entry.target.id.replace("mahaul", "");
              const oldIndex = window.location.pathname.split("/").pop();
              const oldStorySubPosition =
                new URLSearchParams(window.location.search).get("storyId") ||
                "0";
              handleStoriesScrolled(oldIndex, newIndex, oldStorySubPosition);
              navigate(
                {
                  pathname: `/stories/${newIndex}`,
                  search: `storyId=0`,
                },
                { replace: true }
              );
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }
      );
      if (storyIndex >= 0) {
        observer.disconnect();
        document
          .getElementById("mahaul" + stories[storyIndex][0].storyParentCode)
          .scrollIntoView();
      }
      _.forEach(sections, (node) => observer.observe(node));

      return () => {
        observer.disconnect();
      };
    }
  }, [loading]);

  const redirectToDashboard = () => {
    navigate({ pathname: "/dashboard" });
  };

  const handleCloseButtonPressed = (story) => {
    const staticBannerClickedEventData = {
      section: story.section,
      name: story.name,
      imageUrl: story.imageUrl,
      position: story.position,
      subPosition: story.subPosition,
      storyType: story.type,
    };
    AnalyticsService.pushEvent(
      `Cancel Icon Clicked`,
      staticBannerClickedEventData
    );
    redirectToDashboard();
  };

  const handleStoryStarted = (story) => {
    navigate(
      {
        search: `storyId=${story.subPosition}`,
      },
      { replace: true }
    );

    const staticBannerClickedEventData = {
      section: story.section,
      name: story.name,
      imageUrl: story.imageUrl,
      position: story.position,
      subPosition: story.subPosition,
      storyType: story.type,
    };
    AnalyticsService.pushEvent(`Page Viewed`, staticBannerClickedEventData);
  };

  const handleStoryEnded = (story, childIndex) => {
    const staticBannerClickedEventData = {
      section: story.section,
      name: story.name,
      imageUrl: story.imageUrl,
      position: story.position,
      subPosition: story.subPosition,
      storyType: story.type,
    };
    AnalyticsService.pushEvent(
      `Stories Auto Advance Event`,
      staticBannerClickedEventData
    );
    handleNextStoryPageViewedEvent(story, childIndex);
  };

  const handleNextStoryPageViewedEvent = (story, childIndex) => {
    let parentIndex = _.findIndex(
      stories,
      (childStory) => childStory[0].storyParentCode === story.storyParentCode
    );
    let nextStory = stories[parentIndex][childIndex + 1];
    if (nextStory !== undefined && nextStory.type !== "video") {
      navigate(
        {
          search: `storyId=${childIndex + 1}`,
        },
        { replace: true }
      );
      const staticBannerClickedEventData = {
        section: nextStory.section,
        name: nextStory.name,
        imageUrl: nextStory.imageUrl,
        position: nextStory.position,
        subPosition: nextStory.subPosition,
        storyType: nextStory.type,
      };
      AnalyticsService.pushEvent(`Page Viewed`, staticBannerClickedEventData);
    }
  };

  const handleOnAllStoriesEnd = (index) => {
    markStoryViewed(index);
  };

  const handleStoriesNavigated = (story, isNavigatedForward) => {
    const staticBannerClickedEventData = {
      section: story.section,
      name: story.name,
      imageUrl: story.imageUrl,
      position: story.position,
      subPosition: story.subPosition,
      clickType: isNavigatedForward ? "Next" : "Previous",
      storyType: story.type,
    };
    AnalyticsService.pushEvent(`Stories Clicked`, staticBannerClickedEventData);
  };

  const handleStoriesScrolled = (
    oldStoryId,
    newStoryId,
    oldStorySubPosition
  ) => {
    const oldIndex = _.findIndex(
      stories,
      (story) => story[0].storyParentCode === oldStoryId
    );
    const newIndex = _.findIndex(
      stories,
      (story) => story[0].storyParentCode === newStoryId
    );
    if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
      const isNavigatedForward = newIndex > oldIndex;
      let subPosition = 0;
      if (
        oldStorySubPosition &&
        parseInt(oldStorySubPosition) >= 0 &&
        parseInt(oldStorySubPosition) < stories[oldIndex].length
      ) {
        subPosition = parseInt(oldStorySubPosition);
      }
      const staticBannerClickedEventData = {
        section: stories[oldIndex][subPosition].section,
        name: stories[oldIndex][subPosition].name,
        imageUrl: stories[oldIndex][subPosition].imageUrl,
        position: stories[oldIndex][subPosition].position,
        subPosition: stories[oldIndex][subPosition].subPosition,
        scrollType: isNavigatedForward ? "Next" : "Previous",
        storyType: stories[oldIndex][subPosition].type,
      };
      AnalyticsService.pushEvent(
        `Stories Scrolled`,
        staticBannerClickedEventData
      );
    }
  };

  return (
    <Box id="container" className={classes.container}>
      {loading === CommonConstantValues.DONE &&
        (!error || !error?.data) &&
        stories?.length > 0 &&
        _.map(stories, (story, index) => {
          return (
            <Box
              className={classes.mahaulStoryContainer}
              id={"mahaul" + story[0].storyParentCode}
              key={"mahaul" + story[0].storyParentCode}
            >
              <Box
                sx={{
                  width: "100vw",
                  height: "100%",
                  background: "black",
                }}
              ></Box>
              <Stories
                classes={classes}
                stories={story}
                currentIndex={0}
                nextStory={
                  index + 1 === stories.length ? null : stories[index + 1]
                }
                onCloseClick={handleCloseButtonPressed}
                onStoriesStart={handleStoryStarted}
                onStoriesEnd={handleStoryEnded}
                onAllStoriesEnd={() => handleOnAllStoriesEnd(index)}
                onFinish={redirectToDashboard}
                onStoryNavigated={handleStoriesNavigated}
                storiesDurations={storiesDurations}
              />
            </Box>
          );
        })}

      {loading === CommonConstantValues.DONE &&
        (!error || !error?.data) &&
        stories.length === 0 && (
          <StoryEmptyScreen
            classes={classes}
            text="No content available for now"
            handleCloseButtonPressed={redirectToDashboard}
          />
        )}
      {loading === CommonConstantValues.DONE && error && error.data && (
        <StoryEmptyScreen
          classes={classes}
          text="Something went wrong, try again later"
          handleCloseButtonPressed={redirectToDashboard}
        />
      )}
      {loading === CommonConstantValues.STARTED && (
        <StoryEmptyScreen
          classes={classes}
          handleCloseButtonPressed={redirectToDashboard}
          isLoading
        />
      )}
    </Box>
  );
};

export default StoriesContainer;
