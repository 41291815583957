import React from "react";
import "react-block-ui/style.css";

import { Alert, Snackbar, Typography } from "@mui/material";

function QuantityChangeToast(props) {
  return (
    <Snackbar
      // style={{ top: "70vh" }}
      open={props.show}
      autoHideDuration={3000}
      onClose={() => props.setShow(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ marginBottom: "46px" }}
    >
      <Alert
        sx={{
          background: "#666666",
          color: "#ffffff",
          borderRadius: "6px",
          textAlign: "center",
        }}
        icon={false}
        onClose={() => props.setShow(false)}
      >
        Sorry, this product can only be purchased in pack of&nbsp;
        <b>{props.minimumOrderQuantity}</b>
        {props.showForcedQuantityToast && (
          <>
            ,
            <br />
            Quantity should not exceed 10,000
          </>
        )}
      </Alert>
    </Snackbar>
  );
}

export default QuantityChangeToast;
