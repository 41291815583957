import { Skeleton, Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import HisaabService from "../../Services/HisaabService";
import OrderService from "../../Services/OrderService";
import PartnerService from "../../Services/PartnerService";
import AnniversaryPopUp from "./AnniversaryPopUp";
import UpcomingPaymentsReminder from "./UpcomingPaymentsReminder";

function Bulletins(props) {
  const [showAnniverssaryMessage, setShowAnniverssaryMessage] = useState(false);
  const [saathiPartnerName, setSaathiPartnerName] = useState("");
  const [loading, setLoading] = useState("not-started");
  const [totalOCPAmount, setTotalOCPAmount] = useState(0);
  const [overdueDays, setOverdueDays] = useState(null);
  const [upcomingWCPTransactionData, setUpcomingWCPTransactionData] =
    useState(null);

  function checkIfAnniversaryMessageShouldBeShown(firstOrderDate) {
    const date = moment(firstOrderDate, "YYYY-MM-DD");
    const years = Math.floor(
      moment(new Date()).diff(
        moment(firstOrderDate, "YYYY-MM-DD"),
        "years",
        true
      )
    );
    const days = moment().diff(date.add(years, "years"), "days", false);

    if (years > 0 && days <= 7) {
      setShowAnniverssaryMessage(true);
      AnalyticsService.pushEvent("Anniversary Message Viewed");
    } else {
      setShowAnniverssaryMessage(false);
    }
  }

  function getDueInDays(dueDate) {
    const currentDate = Date.now();
    const diffInMs = dueDate - currentDate;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  }

  useEffect(() => {
    setLoading("started");
    const { axiosRequest, abortController } = HisaabService.getWalletBreakup();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          if (response?.data?.responseData?.creditDetails?.totalOCP) {
            setTotalOCPAmount(
              response.data.responseData.creditDetails.totalOCP
            );
            if (
              response.data.responseData.creditDetails.outSideCrditPeriod
                .length > 0
            ) {
              setOverdueDays(
                response.data.responseData.creditDetails.outSideCrditPeriod[0]
                  .pastDues
              );
            }
          } else if (
            response?.data?.responseData?.creditDetails?.withinCreditPeriod
              .length > 0 &&
            getDueInDays(
              response.data.responseData.creditDetails.withinCreditPeriod[0]
                .date
            ) <= 10
          ) {
            setUpcomingWCPTransactionData(
              response.data.responseData.creditDetails.withinCreditPeriod[0]
            );
          }
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting wallet breakup data`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoading("done");
        }
      });

    const {
      axiosRequest: axiosRequestOrders,
      abortController: abortControllerOrders,
    } = OrderService.getAllOrders(true, 0, 1, false);
    axiosRequestOrders
      .then((allOrdersResponse) => {
        const firstOrderCreationTimestamp =
          allOrdersResponse?.data?.responseData?.orders?.[0]?.orderCreationDate;
        const firstOrderCreationDate = new Date(firstOrderCreationTimestamp);
        const date = firstOrderCreationDate.getDate();
        const month = firstOrderCreationDate.getMonth();
        const year = firstOrderCreationDate.getFullYear();
        const firstOrderDateString = `${year}-${month + 1}-${date}`;
        checkIfAnniversaryMessageShouldBeShown(firstOrderDateString);
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting first order data`);
          if (error?.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => {
      abortController.abort();
      abortControllerOrders.abort();
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("partnerName")) {
      setSaathiPartnerName(localStorage.getItem("partnerName"));
    } else {
      const { axiosRequest, abortController } =
        PartnerService.getPartnerDetailedData(localStorage.getItem("farmerId"));
      axiosRequest
        .then((res) => {
          setSaathiPartnerName(res?.data?.responseData?.createdBy);
          localStorage.setItem(
            "partnerName",
            res?.data?.responseData?.createdBy
          );
        })
        .catch(() => {});
      return () => abortController.abort();
    }
  }, []);

  return (
    <Stack
      direction={"row"}
      spacing={2}
      sx={{
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {loading === "started" && (
        <Skeleton
          variant="rectangular"
          height={80}
          width={"100%"}
          sx={{
            margin: "16px !important",
          }}
        />
      )}
      {/* {loading === "done" && showAnniverssaryMessage && (
        <AnniversaryPopUp name={saathiPartnerName} />
      )} */}
      {loading === "done" &&
        (totalOCPAmount > 0 || upcomingWCPTransactionData) && (
          <UpcomingPaymentsReminder
            totalOCPAmount={totalOCPAmount}
            overdueDays={overdueDays}
            upcomingWCPTransactionData={upcomingWCPTransactionData}
            handleClickPayNow={props?.handleClickPayNow}
          />
        )}
    </Stack>
  );
}

export default Bulletins;
