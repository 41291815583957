import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";

import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";

import AnalyticsService from "../../Services/AnalyticsService";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  img: {
    // height: "270px !important",
    maxWidth: 440,
    width: "100%",
    overflow: "hidden",
    display: "block",
    textAlign: "center",
    align: "center",
    margin: "auto",
  },
}));

function ScrollableBanners(props) {
  const classes = useStyles();

  const handleClickScrollableBanners = (index) => {
    MiscUtils.saveScrollYPosition();
    const scrollableBannerClickedEventData = {
      bannerType: props?.banners[index]?.data?.type || "",
      position: index + 1 || "",
      skuList:
        props?.banners[index]?.data?.data?.sku?.toString() ||
        props?.banners[index]?.data?.data?.skus?.join(",") ||
        "",
      imageUrl: props?.banners[index]?.url || "",
    };

    AnalyticsService.pushEvent(
      `Banner Clicked`,
      scrollableBannerClickedEventData
    );
  };

  return (
    <div>
      <Grid container direction={`row`} justifyContent={`center`} spacing={0}>
        <Grid item xs={12}>
          {props.banners && props.banners.length > 0 && (
            <>
              <Carousel
                swipe={true}
                navButtonsAlwaysVisible={false}
                animation={`slide`}
                fullHeightHover={true}
                interval={9000}
                duration={600}
                activeIndicatorIconButtonProps={{
                  style: {
                    // background: "#9D2123",
                    color: "#9D2123",
                  },
                }}
                indicatorIconButtonProps={{
                  style: {
                    // color: "#999999",
                    background: "#F3F3F3",
                  },
                }}
              >
                {props.banners.map((scrollableBanner, index) => (
                  <div
                    key={index}
                    onClick={() => handleClickScrollableBanners(index)}
                  >
                    {scrollableBanner?.data?.type === `PRODUCT_DETAILS` && (
                      <>
                        {scrollableBanner?.data?.data?.sku ? (
                          <Link
                            to={{
                              pathname: `/products/${scrollableBanner.data.data.sku}`,
                              state: { from: window.location.pathname },
                            }}
                          >
                            <img
                              className={classes.img}
                              src={scrollableBanner.url}
                              alt={`banner`}
                              style={{ filter: props?.blur }}
                            />
                          </Link>
                        ) : (
                          <img
                            className={classes.img}
                            src={scrollableBanner.url}
                            alt={`banner`}
                            style={{ filter: props?.blur }}
                          />
                        )}
                      </>
                    )}

                    {scrollableBanner?.data?.type === `PRODUCT_LIST` && (
                      <>
                        {scrollableBanner?.data?.data?.skus ? (
                          <Link
                            to={`/products-offer/${scrollableBanner.data.data.skus}`}
                          >
                            <img
                              className={classes.img}
                              src={scrollableBanner.url}
                              alt={`banner`}
                              style={{ filter: props?.blur }}
                            />
                          </Link>
                        ) : (
                          <img
                            className={classes.img}
                            src={scrollableBanner.url}
                            alt={`banner`}
                            style={{ filter: props?.blur }}
                          />
                        )}
                      </>
                    )}

                    {scrollableBanner?.data?.type === "EXTERNAL_WEB_PAGE" && (
                      <>
                        {scrollableBanner?.data?.data?.url && (
                          <a href={scrollableBanner.data.data.url}>
                            <img
                              className={classes.img}
                              src={scrollableBanner.url}
                              alt={`banner`}
                              style={{ filter: props?.blur }}
                            />
                          </a>
                        )}
                      </>
                    )}

                    {scrollableBanner?.data?.type === `NO_CLICK` && (
                      <>
                        <img
                          className={classes.img}
                          src={scrollableBanner.url}
                          alt={`banner`}
                          style={{ filter: props?.blur }}
                        />
                      </>
                    )}
                  </div>
                ))}
              </Carousel>
            </>
          )}

          {props.loadingBanners === true && (
            <Skeleton variant="rectangular" height={290} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ScrollableBanners;
