import React, { useEffect, useState, useCallback } from "react";
import BlockUi from "react-block-ui";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Alert, Box, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

import TopBar from "../Common/TopBar";
import ThemeButton from "./ThemeButton";
import useQuery from "../../Hooks/useQuery";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductService from "../../Services/ProductService";
import DocumentService from "../../Services/DocumentService";
import BannerService from "../../Services/BannerService";
import { PromotionService } from "../../Services/PromotionService";
import OfferShareAndDownload from "./OfferShareAndDownload";
import EmptyError from "../EmptyError/EmptyError";
import { Star } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  pageBackground: {
    minHeight: "100vh",
    backgroundImage: "url(/greetingPageBackground.svg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    position: "relative",
    padding: "16px 24px 24px",
    background: "none",
    boxShadow: "none",
  },
  cardImage: {
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: "450px",
    zIndex: 1,
    borderRadius: "10px",
  },
  cardContent: {
    width: "100%",
    padding: "0",
    position: "absolute",
    top: 16,
    left: 0,
    zIndex: 2,
    textAlign: "center",
  },
  changeBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    fontSize: "14px",
    fontWeight: "400",
    padding: 0,
    color: "#9D2123",
    textTransform: "none",
  },
  addProductBox: {
    position: "relative",
    width: "55%",
    maxWidth: "145px",
    padding: "18px 10px 8px",
    margin: "14% auto 0",
    font: "600 14px sans-serif",
    border: "none",
    borderRadius: "15px",
    background: "#fff",
  },
  imageWrapper: {
    padding: " 10px 0",
    marginBottom: "8px",
    boxShadow: "inset 0px 0px 10px rgb(0 0 0 / 5%)",
    borderRadius: "20px",
  },
  productName: {
    maxHeight: "32px",
    margin: "5px 0",
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "15px",
    textAlign: "left",
    letterSpacing: 0,
    wordWrap: "break-word",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  productRating: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "10px",
    fontWeight: "500",
    padding: "5px 5px 5px 10px",
    color: "#fff",
    background: "#00733E",
    borderRadius: "3px",
    borderTopLeftRadius: "15px",
  },
  offerInputElementStyle: {
    width: "74%",
    minHeight: "20px",
    padding: "10px 15px",
    margin: "5% auto 0",
    border: "none",
    borderRadius: "10px",
    background: "#f3f3f3",
    fontFamily: "inherit",
  },
  cardActions: {
    position: "relative",
    top: "-50px",
    padding: "24px",
    paddingTop: "70px",
    background: "rgb(0,0,0,0.4)",
    borderRadius: "30px",
  },
  saveOffer: {
    height: "45px",
    fontSize: "14px",
    fontWeight: "700",
    textTransform: "none",
    borderRadius: "10px",
  },
  successToastie: {
    background: "#666666",
    color: "#ffffff",
    borderRadius: "6px",
    textAlign: "center",
  },
}));

export default function CreateOffer() {
  const classes = useStyles();
  const query = useQuery();
  const navigate = useNavigate();

  const titleFromQuery = query.get("title");
  const themeFromQuery = query.get("theme");
  const skuCodeFromQuery = query.get("skuCode");
  const keywordCodeFromQuery = query.get("keyword");
  const descriptionFromQuery = query.get("description");
  const [skuCode, setSkuCode] = useState(skuCodeFromQuery || "");
  const [keyword, setKeyword] = useState(keywordCodeFromQuery || "");

  const source = localStorage.getItem("source");
  const regex =
    source === "B2BGJ"
      ? /[^a-zA-Z0-9\u0A80-\u0AFF\u000A !@%&_|-]/gi
      : /[^a-zA-Z0-9\u0900-\u097F\u000A !@%&_|-]/gi;
  const specialCharacterRegex =
    /[^a-zA-Z0-9\u0900-\u097F\u0A80-\u0AFF\u000A !@%&_|-]/gi;
  const [phoneString, setPhoneString] = useState("Phone No:");
  const [shareText, setShareText] = useState("");
  const [loading, setLoading] = useState("not_started");
  const [activeTheme, setActiveTheme] = useState(null);
  const [activeThemeUrl, setActiveThemeUrl] = useState("");
  const [banners, setBanners] = useState([]);
  const [offerStatus, setOfferStatus] = useState("not_savable");
  const [toastMessage, setToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [offerDetails, setOfferDetails] = useState({
    title: titleFromQuery || "",
    description: descriptionFromQuery || "",
  });

  useEffect(() => {
    AnalyticsService.pushEvent(`Create Offer Page Viewed`, {
      page: "/create-offer",
    });

    setLoading("started");
    const source = localStorage.getItem("source");
    if (skuCode) {
      const { axiosRequest, abortController } =
        ProductService.getProductDetailsBySku(skuCode, source);
      axiosRequest
        .then((productResponse) => {
          const product = productResponse?.data?.responseData;
          setOfferDetails((offerDetails) => ({
            ...offerDetails,
            image: product?.productImages[0]?.name,
            productName: product?.productName,
            rating: product?.rating,
          }));

          if (offerDetails?.title !== "") {
            setOfferStatus("savable");
          }
        })
        .catch((e) => {
          if (e?.message !== "canceled") {
            setStoreAndGreetingInformation();
            console.log("Product API Failure: ", e?.message);
          }
        });
      return () => abortController.abort();
    }
    const informationCB = setStoreAndGreetingInformation();
    return () => informationCB();
  }, []);

  function setStoreAndGreetingInformation() {
    let storeName = "",
      storeAddress = "",
      storeMobileNumber = "",
      shareTextSource = "ENGLISH";
    const source = localStorage.getItem("source");
    const district = localStorage.getItem("district");
    let isLocalDetailAvailable = false;

    const { axiosRequest, abortController } =
      DocumentService.getInstitutionId();
    axiosRequest
      .then((storeResponse) => {
        if (storeResponse?.data?.responseData) {
          const storeData = storeResponse?.data?.responseData;
          if (
            storeData?.nameInRegionalLanguage &&
            storeData?.addressInRegionalLanguage?.pincode
          ) {
            shareTextSource = source;
            isLocalDetailAvailable = true;
            setPhoneString(PromotionService.getPhoneString(source));
            storeName = storeData?.nameInRegionalLanguage;
            storeMobileNumber = storeData?.contacts?.mobileNumber;

            if (storeData?.addressInRegionalLanguage) {
              const address = storeData?.addressInRegionalLanguage;
              storeAddress = `${address?.landMark}, ${address?.village}, ${address?.taluka}, ${address?.district}`;
            }
          } else {
            storeName = storeData?.name;
            storeMobileNumber = storeData?.contacts?.mobileNumber;

            if (storeData?.address) {
              const address = storeData?.address;
              storeAddress = `${address?.landMark}, ${address?.village}, ${address?.taluka}, ${address?.district}`;
            }
          }
          const text = PromotionService.getOfferShareText(
            shareTextSource,
            storeName,
            storeMobileNumber
          );
          setShareText(text);
        }

        const { axiosRequest } = BannerService.getBannersData(
          `PROMOTIONS`,
          district
        );
        axiosRequest
          .then((bannerResponse) => {
            if (bannerResponse?.data?.responseData) {
              const { banners } = bannerResponse?.data?.responseData;
              if (banners?.length) {
                const newBannerData = banners.map((banner) => ({
                  type: "IMAGE",
                  originalUrl: banner?.media[0]?.url,
                  shareText: banner?.templateTexts?.[0]
                    ?.replaceAll("`", "")
                    ?.replaceAll("{{comma}}", ",")
                    ?.replaceAll("{{storeName}}", storeName)
                    ?.replaceAll("{{storeAddress}}", storeAddress)
                    ?.replaceAll("{{storeMobileNumber}}", storeMobileNumber),
                  url: PromotionService.getOverlayUrlForCreateOffer(
                    banner?.media[0]?.url,
                    storeName,
                    storeAddress,
                    `${
                      isLocalDetailAvailable
                        ? PromotionService.getPhoneString(source)
                        : "Phone No:"
                    } ${storeMobileNumber}`
                  ),
                  textOverlayFontColour: banner?.textOverlayFontColour,
                }));

                if (themeFromQuery) {
                  const themeIndex = Number(themeFromQuery);
                  setActiveTheme(themeIndex);
                  setActiveThemeUrl(newBannerData[themeIndex - 1]?.url);
                } else {
                  setActiveTheme(1);
                  setActiveThemeUrl(newBannerData[0]?.url);
                }
                setBanners(newBannerData);
              }

              const { title, description } =
                PromotionService.getOfferPlaceholders(
                  source,
                  storeName,
                  storeMobileNumber
                );
              setOfferDetails((offerDetails) => ({
                ...offerDetails,
                storeName,
                storeAddress,
                storeMobileNumber,
                titlePlaceholder: title,
                descriptionPlaceholder: description,
              }));
            }
            setLoading("done");
          })
          .catch((e) => {
            setLoading("done");
            console.log("Promotion Banner API Failure: ", e?.message);
          });
      })
      .catch((e) => {
        if (e?.message !== "canceled") {
          setLoading("done");
          console.log("Institution API Failure: ", e?.message);
        }
      });
    return () => abortController.abort();
  }

  const handleThemeChange = (index) => {
    if (activeTheme === index + 1) return null;

    AnalyticsService.pushEvent(`Theme Change Button Clicked`, {
      page: "/create-offer",
      themeName: `Theme ${index + 1}`,
      themeUrl: banners[index]?.originalUrl,
    });
    navigate(
      `/create-offer?keyword=${keyword}&skuCode=${skuCode}&title=${
        offerDetails?.title
      }&description=${offerDetails?.description}&theme=${index + 1}`,
      { replace: true }
    );
    setActiveTheme(index + 1);
    setActiveThemeUrl(banners[index]?.url);
  };

  const handleAddProduct = () => {
    AnalyticsService.pushEvent(`Add Product Clicked`, {
      page: "/create-offer",
    });
    navigate(
      `/search?title=${offerDetails?.title}&description=${offerDetails?.description}&theme=${activeTheme}&type=create-offer`
    );
  };

  const handleChangeProduct = () => {
    AnalyticsService.pushEvent(`Change Product Clicked`, {
      page: "/create-offer",
    });
    navigate(
      `/search?keyword=${keyword}&skuCode=${skuCode}&title=${offerDetails?.title}&description=${offerDetails?.description}&theme=${activeTheme}&type=create-offer`
    );
  };

  const handleSaveOfferClickEvent = () => {
    if (offerStatus !== "savable") return null;

    setLoading("started");
    AnalyticsService.pushEvent(`Save Offer Button Clicked`, {
      page: "/create-offer",
      themeName: `Theme ${activeTheme + 1}`,
      imageUrl: activeThemeUrl,
      themeUrl: banners[activeTheme - 1]?.originalUrl,
      offerTitle: offerDetails?.title,
      offerDescription: offerDetails?.description,
      skuCode,
    });

    const savedOfferUrl = PromotionService.getOverlayUrlForCreateOffer(
      banners[activeTheme - 1]?.originalUrl,
      offerDetails?.storeName,
      offerDetails?.storeAddress,
      `${phoneString} ${offerDetails?.storeMobileNumber}`,
      offerDetails?.image,
      offerDetails?.productName,
      offerDetails?.title,
      offerDetails?.description,
      offerDetails?.rating?.average,
      banners[activeTheme - 1]?.textOverlayFontColour
    );
    const { axiosRequest } = PromotionService.createSaathiOffer({
      templateUrl: banners[activeTheme - 1]?.originalUrl,
      productDetails: [{ skuCode, productName: offerDetails?.productName }],
      title: offerDetails?.title,
      descriptions: [offerDetails?.description],
      textOverlayFontColour: banners[activeTheme - 1]?.textOverlayFontColour,
      exportedImageUrl: savedOfferUrl,
      messageTemplateText: banners[activeTheme - 1]?.shareText,
    });
    axiosRequest
      .then(() => {
        const image = new Image();
        image.onload = () => {
          setActiveThemeUrl(savedOfferUrl);
          setOfferStatus("saved");
          setLoading("done");
        };
        image.onerror = () => {
          console.log("Offer Image Loading Failed");
          setLoading("done");
        };
        image.src = savedOfferUrl;
      })
      .catch((e) => {
        setLoading("done");
        console.log("Create Offer API Failure: ", e.message);
      });
  };

  const handleMyOffersClickEvent = () => {
    AnalyticsService.pushEvent(`My Offers Clicked`, {
      page: "/promote-offer",
    });
    navigate("/promotion");
    navigate(`/my-offers`);
  };

  const handleEvent = (event) => {
    let { name, value } = event.target;
    value = value.replace(regex, "");

    if (name === "title") {
      AnalyticsService.pushEvent(`Offer Title Sent`, {
        offerTitle: value || "",
        offerDescription: offerDetails?.description,
        page: "/create-offer",
        skuCode,
      });
    } else {
      AnalyticsService.pushEvent(`Offer Description Sent`, {
        offerDescription: value || "",
        page: "/create-offer",
      });
    }
  };

  const handleChangeDebounced = useCallback(debounce(handleEvent, 1000), []);

  const handleChangeInput = (event) => {
    const { name, value } = event.target;

    const invalidSpecialCharacter = value.replace(specialCharacterRegex, "");
    if (value !== invalidSpecialCharacter) {
      setToastMessage(
        "Certain special characters are not allowed. Valid characters include  (!, @, %, &, _, |, -)"
      );
      setShowErrorToast(true);
    }

    const languageCharacter = invalidSpecialCharacter.replace(regex, "");
    if (invalidSpecialCharacter !== languageCharacter) {
      const source = localStorage.getItem("source");
      const language = source === "B2BGJ" ? "Gujarati" : "Devanagri";
      setToastMessage(
        `Only ${language} and English languages are supported for the title and description.`
      );
      setShowErrorToast(true);
    }

    setOfferDetails((offerDetails) => ({
      ...offerDetails,
      [name]: languageCharacter,
    }));

    let titleString = offerDetails?.title;
    if (name === "title") {
      titleString = languageCharacter;
      navigate(
        `/create-offer?keyword=${keyword}&skuCode=${skuCode}&title=${titleString}&description=${offerDetails?.description}&theme=${activeTheme}`,
        { replace: true }
      );
    } else {
      const descriptionString = languageCharacter;
      navigate(
        `/create-offer?keyword=${keyword}&skuCode=${skuCode}&title=${offerDetails?.title}&description=${descriptionString}&theme=${activeTheme}`,
        { replace: true }
      );
    }

    if (titleString === "" || skuCode === "") {
      setOfferStatus("not_savable");
    } else {
      setOfferStatus("savable");
    }

    handleChangeDebounced(event);
  };

  return (
    <>
      <ErrorBoundary>
        <TopBar
          title={offerStatus !== "saved" ? `Create Offer` : `Promote Offer`}
          backNavigation={true}
          backLink="/promotion"
          showCart={false}
          showHisaab={false}
          otherTextOrIconToShow={{
            text: "My Offers",
            function: handleMyOffersClickEvent,
          }}
        />
      </ErrorBoundary>

      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "90vh" }}
      >
        <Snackbar
          open={showErrorToast}
          autoHideDuration={2000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            icon={false}
            className={classes.successToastie}
            onClose={() => setShowErrorToast(false)}
          >
            {toastMessage}
          </Alert>
        </Snackbar>
        <div className={classes.pageBackground}>
          {banners?.length ? (
            <>
              {offerStatus !== "saved" ? (
                <>
                  <ThemeButton
                    page="/create-offer"
                    themes={banners}
                    activeTheme={activeTheme}
                    handleThemeChange={handleThemeChange}
                  />

                  <Swiper
                    modules={[Navigation, Virtual]}
                    onSlideChange={(e) => {
                      handleThemeChange(e.activeIndex);
                    }}
                    addSlidesAfter={2}
                    addSlidesBefore={2}
                    virtual
                  >
                    {banners?.map((banner, index) => (
                      <SwiperSlide key={index}>
                        <Card className={classes.card}>
                          <CardMedia
                            component="img"
                            image={activeThemeUrl}
                            alt="greeting"
                            className={classes.cardImage}
                          />
                          <CardContent className={classes.cardContent}>
                            <Box className={classes.addProductBox}>
                              {offerDetails?.image ? (
                                <>
                                  <Button
                                    variant="standard"
                                    className={classes.changeBtn}
                                    onClick={handleChangeProduct}
                                  >
                                    Change
                                  </Button>
                                  <img
                                    src={offerDetails?.image}
                                    alt=""
                                    height="80px"
                                  />
                                  <Typography className={classes.productName}>
                                    {offerDetails?.productName.length > 40
                                      ? offerDetails?.productName
                                          .slice(0, 40)
                                          .concat(`...`)
                                      : offerDetails?.productName}
                                  </Typography>
                                  {offerDetails?.rating?.average > 0 && (
                                    <div className={classes.productRating}>
                                      {offerDetails?.rating?.average}
                                      <Star
                                        sx={{
                                          width: "10px",
                                          height: "10px",
                                          ml: 0.1,
                                        }}
                                      />
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: 50,
                                      fontWeight: 100,
                                      pt: 3,
                                    }}
                                    onClick={handleAddProduct}
                                  >
                                    +
                                  </Typography>
                                  <Typography variant="body2" sx={{ pb: 3 }}>
                                    Add Product
                                  </Typography>
                                </>
                              )}
                            </Box>

                            <input
                              type="text"
                              name="title"
                              placeholder={offerDetails?.titlePlaceholder}
                              maxLength="48"
                              onChange={handleChangeInput}
                              onClick={() =>
                                AnalyticsService.pushEvent(
                                  `Offer Title Clicked`,
                                  {
                                    skuCode: skuCode,
                                    offerTitle: offerDetails?.title,
                                    offerDescription: offerDetails?.description,
                                  }
                                )
                              }
                              value={offerDetails?.title}
                              className={classes.offerInputElementStyle}
                            />
                            <textarea
                              rows="4"
                              name="description"
                              placeholder={offerDetails?.descriptionPlaceholder}
                              maxLength="300"
                              onChange={handleChangeInput}
                              onClick={() =>
                                AnalyticsService.pushEvent(
                                  `Offer Description Clicked`,
                                  {
                                    skuCode: skuCode,
                                    offerTitle: offerDetails?.title,
                                    offerDescription: offerDetails?.description,
                                  }
                                )
                              }
                              value={offerDetails?.description}
                              className={classes.offerInputElementStyle}
                            />
                          </CardContent>

                          <CardActions
                            disableSpacing
                            className={classes.cardActions}
                          >
                            <Button
                              variant="contained"
                              fullWidth
                              color="secondary"
                              onClick={handleSaveOfferClickEvent}
                              className={classes.saveOffer}
                              sx={{
                                opacity: offerStatus === "savable" ? 1 : 0.5,
                                background:
                                  offerStatus === "savable" ? "" : "#666",
                                "&:hover": {
                                  background:
                                    offerStatus === "savable" ? "" : "#666",
                                },
                              }}
                            >
                              Save Offer
                            </Button>
                          </CardActions>
                        </Card>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              ) : (
                <Card className={classes.card}>
                  <CardMedia
                    component="img"
                    image={activeThemeUrl}
                    alt="greeting"
                    className={classes.cardImage}
                  />

                  <CardActions disableSpacing className={classes.cardActions}>
                    <OfferShareAndDownload
                      index={activeTheme}
                      imageUrl={activeThemeUrl}
                      page="/create-offer"
                      banners={banners}
                      shareText={
                        banners[activeTheme - 1]?.shareText
                          ? banners[activeTheme - 1]?.shareText
                          : shareText
                      }
                      skuCode={skuCode}
                      offerTitle={offerDetails?.title}
                      offerDescription={offerDetails?.description}
                    />
                  </CardActions>
                </Card>
              )}
            </>
          ) : loading === "done" ? (
            <Box sx={{ p: "60px 30px 0" }}>
              <EmptyError
                image="EmptyPage.svg"
                text="Ooooops!"
                subText="Looks like there are no templates available at this moment.
                Please come back later."
                extraStyle={{
                  minHeight: "500px",
                  p: "0 20px",
                  borderRadius: "20px",
                  background: "#fff",
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </div>
      </BlockUi>
    </>
  );
}
