import { Navigate } from "react-router-dom";

import PartnerService from "./Services/PartnerService";

import Brand from "./Components/Brand/Brand";
import BrandsPage from "./Components/Brand/BrandsPage";
import Cart from "./Components/Cart/Cart";
import Category from "./Components/Category/Category";
import Dashboard from "./Components/Dashboard/Dashboard";
import SearchResults from "./Components/Dashboard/SearchResults";
import HisaabDashboard from "./Components/Hisaab/HisaabDashboard";
import GenerateStatement from "./Components/Hisaab/GenerateStatement";
import OrderDetails from "./Components/OrderDetails/OrderDetails";
import OrdersList from "./Components/Orders/OrdersList";
import FeaturedProductsPage from "./Components/Product/FeaturedProductsPage";
import PopularProductsPage from "./Components/Product/PopularProductsPage";
import Product from "./Components/Product/Product";
import ProductsOffer from "./Components/Product/ProductsOffer";
import SuccessfulRequest from "./Components/Request/SuccessfulRequest";
import PromoteWhatsapp from "./Components/Promote/Promote";
import Referral from "./Components/Referral/Referral";
import Profile from "./Components/Profile/Profile";
import ReferralForm from "./Components/Referral/ReferralForm";
import TermsAndCondition from "./Components/Profile/TermsAndCondition";
import Wallet from "./Components/Wallet/Wallet";
import RecentlyBoughtProductsPage from "./Components/Product/RecentlyBoughtProductsPage";
import PaymentStatusDialog from "./Components/Hisaab/PaymentStatusDialog";
import RazorPayFailureDialog from "./Components/Hisaab/RazorPayFailureDialog";
import NearbyPartnerPurchasePage from "./Components/Product/NearbyPartnerPurchasePage";
import Leads from "./Components/Leads/Leads";
import DiwaliGreeting from "./Components/Dashboard/DiwaliGreeting";
import BuyItAgainPage from "./Components/Product/BuyItAgainPage";
import RecentlyViewedProductsPage from "./Components/Product/RecentlyViewedProductsPage";
import GrahakProductsRecommendationPage from "./Components/Product/GrahakProductsRecommendationPage";
import Registration from "./Components/OnboardPartner/Registration";
import Promotion from "./Components/Promotion/Promotion";
import FestiveGreetings from "./Components/Promotion/FestiveGreetings";
import Safal from "./Components/Safal/Safal";
import ViewAllVideos from "./Components/VideoDetails/ViewAllVideos";
import VideoCard from "./Components/VideoDetails/VideoCard";
import CreateOffer from "./Components/Promotion/CreateOffer";
import MyOffers from "./Components/Promotion/MyOffers";
import MyFarmersPage from "./Components/MyFarmers/MyFarmersPage";
import PointsRedemptionCampaign from "./Components/PointsRedemptionCampaign/PointsRedemptionCampaign";
import SafalMeetPage from "./Components/Safal/SafalMeetPage";
import Partners from "./Components/Partners/Partners";
import VyapaarPage from "./Components/Offers/VyapaarPage";
import SchemeProductsPage from "./Components/Offers/SchemeProductsPage";
import SchemeDetailsPage from "./Components/Offers/SchemeDetailsPage";
import SelectReturnProducts from "./Components/ReturnOrders/SelectReturnProducts";
import ReviewReturnProducts from "./Components/ReturnOrders/ReviewReturnProducts";
import SuccessReturn from "./Components/ReturnOrders/SuccessReturn";
import ReturnOrderDetails from "./Components/ReturnOrders/ReturnOrderDetails";
import ReturnOrders from "./Components/ReturnOrders/ReturnOrders";
import StoreFront from "./Components/Profile/StoreFront";
import ComplaintHistory from "./Components/Complaints/ComplaintHistory";
import CreateComplaint from "./Components/Complaints/CreateComplaint";
import ComplaintSuccessfulDialog from "./Components/Complaints/ComplaintSuccessfulDialog";
import StoriesContainer from "./Components/Mahaul/StoriesContainer";
import InterPartyTransfersPage from "./Components/InterPartyTransfer/InterPartyTransfersPage";
import InterPartyTransfersDetailsPage from "./Components/InterPartyTransfer/InterPartyTransfersDetailsPage";
import InitiateTransferPage from "./Components/InterPartyTransfer/InitiateTransferPage";
import BarcodeScanDetailsPage from "./Components/InterPartyTransfer/BarcodeScanDetailsPage";
import InterPartyTransferRequestsPage from "./Components/InterPartyTransfer/InterPartyTransferRequestsPage";
import ScannedProductsPage from "./Components/InterPartyTransfer/ScannedProductsPage";
import IPTOrderSuccessPage from "./Components/InterPartyTransfer/IPTOrderSuccessPage";
import OrderPaymentRequest from "./Components/Orders/OrderPaymentRequest";
import PaymentStatus from "./Components/Orders/PaymentStatus";
import OrderSuccessful from "./Components/Orders/OrderSuccessful";
import StoreManagerPage from "./Components/StoreManager/StoreManagerPage";
import StoreInventoryPage from "./Components/StoreManager/StoreInventoryPage";
import BizConnect from "./Components/Profile/BizConnect";
import TurnoverSchemeProductsRecommendationPage from "./Components/Product/TurnoverSchemeProductsRecommendationPage";
import AddProductsToCatalogPage from "./Components/StoreManager/AddProductsToCatalogPage";
import PartnerDetails from "./Components/OnboardPartner/PartnerDetails";
import DocumentsUploadPage from "./Components/OnboardPartner/DocumentsUploadPage";
import LogInOrSignUp from "./Components/OnboardPartner/LogInOrSignUp";
import SignIn from "./Components/OnboardPartner/SignIn";
import FieldAgentCommercePage from "./Components/FieldAgent/FieldAgentCommercePage";
import SelectComplaintProducts from "./Components/Complaints/SelectComplaintProducts";
import ManuallyAddRemoveProductsPage from "./Components/InterPartyTransfer/ManuallyAddRemoveProductsPage";
import KrishiGyanPage from "./Components/KrishiGyan/KrishiGyanPage";
import FieldStar from "./Components/FieldStar/FieldStar";
import CropListPage from "./Components/KrishiGyan/CropListPage";
import PayByProductGroupDetailsPage from "./Components/ProductGroup/PayByProductGroupDetailsPage";
import FreightChargeSuccessPage from "./Components/OrderDetails/FreightChargeSuccessPage";
import SuggestionSearchResults from "./Components/Dashboard/SuggestionSearchResults";
import AgreementSigningSuccess from "./Components/AgreementSigning/AgreementSigningSuccess";
import AgreementSigningFailure from "./Components/AgreementSigning/AgreementSigningFailure";
import Recovery from "./Components/Reports/Recovery";
import AgreementDetails from "./Components/OnboardPartner/AgreementDetails";
import PaymentSettledSuccessPage from "./Components/Hisaab/PaymentSettledSuccessPage";
import ScanQR from "./Components/StoreManager/ScanQR";

const routes = [
  {
    path: `/`,
    component: PartnerService.hasOnboarded() ? (
      <Dashboard />
    ) : PartnerService.hasOnboardedAsFieldAgent() ? (
      <Partners />
    ) : PartnerService.hasFilledPartnerDetails() ? (
      PartnerService.hasUploadedDocuments() ? (
        <Dashboard />
      ) : (
        <Dashboard />
      )
    ) : PartnerService.areDocumentsVerified() ? (
      <Dashboard />
    ) : PartnerService.hasPaidSecurityDeposit() ? (
      <Dashboard />
    ) : PartnerService.isOtpVerified() ? (
      <PartnerDetails />
    ) : (
      <LogInOrSignUp />
    ),
    private: false,
  },
  {
    path: `/login`,
    component: PartnerService.hasOnboarded() ? <Dashboard /> : <SignIn />,
    private: false,
  },
  {
    path: `/sign-in`,
    component: PartnerService.hasOnboarded() ? <Dashboard /> : <SignIn />,
    private: false,
  },
  {
    path: `/register/partner-details`,
    component: PartnerService.hasOnboarded() ? (
      <Dashboard />
    ) : PartnerService.hasFilledPartnerDetails() ? (
      <DocumentsUploadPage />
    ) : PartnerService.isOtpVerified() ? (
      <PartnerDetails />
    ) : (
      <PartnerDetails /> //some issue with state managment after otp verification,
      // hence keeping this. handled redirection to / in the useEffect of PartnerDetails
    ),
    private: false,
  },
  {
    path: `/register/documents-upload`,
    component: PartnerService.hasOnboarded() ? (
      <Dashboard />
    ) : PartnerService.hasFilledPartnerDetails() ? (
      <DocumentsUploadPage />
    ) : (
      <DocumentsUploadPage /> //redirection in the useEffect of DocumentsUploadPage
    ),
    private: false,
  },
  { path: `/partners`, component: <Partners />, private: false },
  { path: `/dashboard`, component: <Dashboard />, private: true },
  { path: `/categories/:categoryName`, component: <Category />, private: true },
  {
    path: `/categories/:categoryName/brands`,
    component: <BrandsPage />,
    private: true,
  },
  {
    path: `/categories/:categoryName/brands/:brandName`,
    component: <Brand />,
    private: true,
  },
  { path: `/brands/`, component: <BrandsPage />, private: true },
  { path: `/brands/:brandName`, component: <Brand />, private: true },
  {
    path: `/brands/:brandName/:className`,
    component: <Brand />,
    private: true,
  },
  { path: `/products/`, component: <Dashboard />, private: true },
  { path: `/products/:sku?from=:from`, component: <Product />, private: true },
  { path: `/products/:sku`, component: <Product />, private: true },
  {
    path: `/products/:sku/promote`,
    component: <PromoteWhatsapp />,
    private: true,
  },
  {
    path: `/products-offer/:skus`,
    component: <ProductsOffer />,
    private: true,
  },
  {
    path: `/featured-products/`,
    component: <FeaturedProductsPage />,
    private: true,
  },
  {
    path: `/nearby-partner-purchase-products/`,
    component: <NearbyPartnerPurchasePage />,
    private: true,
  },
  {
    path: `/popular-products/`,
    component: <PopularProductsPage />,
    private: true,
  },
  {
    path: `/market-your-products/`,
    component: <RecentlyBoughtProductsPage />,
    private: true,
  },
  { path: `/cart`, component: <Cart />, private: true },
  { path: `/request/success`, component: <SuccessfulRequest />, private: true },
  { path: `/orders`, component: <OrdersList />, private: true },
  { path: "/orders/return", component: <ReturnOrders />, private: true },
  {
    path: "/complaints/history",
    component: <ComplaintHistory />,
    private: true,
  },
  {
    path: "/order/payment-request/",
    component: <OrderPaymentRequest />,
    private: true,
  },
  {
    path: `/order/payment-status/:paymentId`,
    component: <PaymentStatus />,
    private: true,
  },
  {
    path: `/order/razorpay-failure/:error`,
    component: <OrderPaymentRequest hasError={true} />,
    private: true,
  },
  { path: `/order/success`, component: <OrderSuccessful />, private: true },
  {
    path: `/order/freight-charge-reimburse-success`,
    component: <FreightChargeSuccessPage />,
    private: true,
  },
  {
    path: "/orders/return/select-product",
    component: <SelectReturnProducts />,
    private: true,
  },
  {
    path: "/orders/return/review-product",
    component: <ReviewReturnProducts />,
    private: true,
  },
  {
    path: `/orders/return/:returnOrderID/order-detail`,
    component: <ReturnOrderDetails />,
    private: true,
  },
  {
    path: `/orders/return/:returnOrderId/success-return`,
    component: <SuccessReturn />,
    private: true,
  },
  { path: `/orders/:orderId`, component: <OrderDetails />, private: false },
  { path: `/search/`, component: <SuggestionSearchResults />, private: true },
  { path: `/hisaab`, component: <HisaabDashboard />, private: true },
  {
    path: `/hisaab/pay/`,
    component: <HisaabDashboard pay={true} />,
    private: true,
  },
  {
    path: `/hisaab/payment-status/:paymentId`,
    component: <PaymentStatusDialog />,
    private: true,
  },
  {
    path: `/hisaab/razorpay-failure/:error`,
    component: <RazorPayFailureDialog origin="hisaab" />,
    private: true,
  },
  { path: `/hisaab/orders`, component: <HisaabDashboard />, private: true },
  {
    path: `/hisaab/transactions`,
    component: <HisaabDashboard />,
    private: true,
  },
  {
    path: `hisaab/pay-by-product-group`,
    component: <HisaabDashboard />,
    private: true,
  },
  {
    path: `/hisaab/pay-by-product-group/:groupName`,
    component: <PayByProductGroupDetailsPage />,
    private: true,
  },
  {
    path: `/hisaab/transactions/download`,
    component: <GenerateStatement />,
    private: true,
  },
  { path: `/referral`, component: <Referral />, private: true },
  { path: `/referral/form`, component: <ReferralForm />, private: true },
  { path: `/diwali-greeting`, component: <DiwaliGreeting />, private: true },
  { path: `/profile`, component: <Profile />, private: true },
  {
    path: `/terms-and-conditions`,
    component: <TermsAndCondition />,
    private: true,
  },
  { path: `/vyapaar`, component: <VyapaarPage />, private: true },
  {
    path: `/schemes`,
    component: (
      <Navigate to={{ pathname: "/vyapaar", search: "?tab=schemes" }} />
    ),
    private: true,
  },
  {
    path: `/vyapaar/:schemeId`,
    component: <SchemeDetailsPage />,
    private: true,
  },
  {
    path: `/schemes/:schemeId`,
    component: <SchemeDetailsPage />,
    private: true,
  },
  {
    path: `/scheme-products/:skus`,
    component: <SchemeProductsPage />,
    private: true,
  },
  { path: `/learning-videos`, component: <ViewAllVideos />, private: true },
  { path: `/featured-videos`, component: <VideoCard />, private: true },
  { path: `/stories/:storyId`, component: <StoriesContainer />, private: true },
  { path: `/stories/`, component: <StoriesContainer />, private: true },
  { path: `/nearby-farmers`, component: <Leads />, private: true },
  { path: `/buy-it-again`, component: <BuyItAgainPage />, private: true },
  { path: `/wallet`, component: <Wallet />, private: true },
  { path: `/promotion`, component: <Promotion />, private: true },
  { path: `/create-offer`, component: <CreateOffer />, private: true },
  { path: `/my-offers`, component: <MyOffers />, private: true },
  {
    path: `/festive-greetings`,
    component: <FestiveGreetings />,
    private: true,
  },
  { path: `/wallet/real-cash`, component: <Wallet />, private: true },
  { path: `/registration`, component: <Registration />, private: false },
  {
    path: `/recently-viewed-products`,
    component: <RecentlyViewedProductsPage />,
    private: true,
  },
  {
    path: `/grahak-products-recommendation`,
    component: <GrahakProductsRecommendationPage />,
    private: true,
  },
  {
    path: `/turnover-scheme-products-recommendation`,
    component: <TurnoverSchemeProductsRecommendationPage />,
    rivate: true,
  },
  { path: `/safal-meet/:meetId`, component: <Safal />, private: true },
  { path: `/my-farmers`, component: <MyFarmersPage />, private: true },
  {
    path: `/agrostar-points-transfer-to-saathi`,
    component: <PointsRedemptionCampaign />,
    private: true,
  },
  {
    path: `/offline-saathi-safal-meeting`,
    component: <SafalMeetPage />,
    private: true,
  },
  { path: `/store-front`, component: <StoreFront />, private: true },
  {
    path: `/store-front-catalog`,
    component: <StoreInventoryPage />,
    private: true,
  },
  {
    path: `/add-products-to-catalog`,
    component: <AddProductsToCatalogPage />,
    private: true,
  },
  { path: `/store-manager`, component: <StoreManagerPage />, private: true },
  {
    path: `/store-manager/store-inventory`,
    component: <StoreManagerPage />,
    private: true,
  },
  {
    path: `/store-manager/shipped-orders`,
    component: <StoreManagerPage />,
    private: true,
  },
  {
    path: `/store-manager/store-orders`,
    component: <StoreManagerPage />,
    private: true,
  },
  {
    path: `/store-manager/business-insights`,
    component: <StoreManagerPage />,
    private: true,
  },
  { path: "/complaints/create", component: <CreateComplaint />, private: true },
  {
    path: "/complaints/select-products",
    component: <SelectComplaintProducts />,
    private: true,
  },
  {
    path: `/complaints/successful/:ticketId`,
    component: <ComplaintSuccessfulDialog />,
    private: "true",
  },
  {
    path: `/inter-party-transfers`,
    component: <InterPartyTransfersPage />,
    private: false,
  },
  {
    path: `/inter-party-transfers/initiate`,
    component: <InitiateTransferPage />,
    private: false,
  },
  {
    path: `/inter-party-transfers/requests`,
    component: <InterPartyTransferRequestsPage />,
    private: true,
  },
  {
    path: `/inter-party-transfers/barcode-scan-details`,
    component: <BarcodeScanDetailsPage />,
    private: false,
  },
  {
    path: `/inter-party-transfers/manually-add-products`,
    component: <ManuallyAddRemoveProductsPage />,
    private: false,
  },
  {
    path: `/inter-party-transfers/scanned-products`,
    component: <ScannedProductsPage />,
    private: false,
  },
  {
    path: `/inter-party-transfers/success`,
    component: <IPTOrderSuccessPage />,
    private: false,
  },
  {
    path: `/inter-party-transfers/:orderId`,
    component: <InterPartyTransfersDetailsPage />,
    private: false,
  },
  { path: `/bizconnect`, component: <BizConnect />, private: true },
  {
    path: `/field-agent-commerce`,
    component: <FieldAgentCommercePage />,
    private: false,
  },
  { path: `/crop-selection`, component: <CropListPage />, private: false },
  { path: `/krishi-gyan`, component: <KrishiGyanPage />, private: false },
  { path: `/field-star`, component: <FieldStar />, private: false },
  {
    path: `/agreement-signing/processed`,
    component: <AgreementSigningSuccess />,
    private: true,
  },
  {
    path: `/agreement-signing/failure`,
    component: <AgreementSigningFailure />,
    private: true,
  },
  { path: `/reports/recovery`, component: <Recovery />, private: false },
  {
    path: `/agreement-details`,
    component: <AgreementDetails />,
    private: true,
  },
  {
    path: `/hisaab/payment-settled-success`,
    component: <PaymentSettledSuccessPage />,
    private: false,
  },
  {
    path: `/scan-qr`,
    component: <ScanQR />,
    private: true,
  },
];

export default routes;
