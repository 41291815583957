import { useCallback, useState } from "react";

import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add, Remove } from "@mui/icons-material";

import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";

import { debounce } from "lodash";
import ProductService from "../../Services/ProductService";
import AmountUtils from "../../Utils/AmountUtils";
import CommonConstantValues from "../../Services/CommonConstantValues";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  input: { fontSize: "10px" },
  addIconButton: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    border: "1px solid #999999",
  },
  addIcon: { color: "#333333" },
  removeIconButton: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    border: "1px solid #999999",
  },
  removeIcon: {
    color: "#333333",
  },
  productMrp: {
    color: "#666666",
    textDecoration: "line-through",
    marginRight: theme.spacing(0.2),
    lineHeight: "19.07px",
    fontSize: "14px",
    display: "block",
  },
  priceSection: {
    margin: theme.spacing(1, 0, 1.5, 0),
  },
  price: {
    float: "right",
    margin: theme.spacing(0, 0.5),
  },
  offerQuantity: {
    marginRight: theme.spacing(2),
  },
  removeIconButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  addIconButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const AddRemoveBagItem = ({
  product,
  isOfferApplied,
  minimumOrderQuantity,
  setShowQuantityChangeToast,
  ...props
}) => {
  const classes = useStyles();

  let initialQuantity = product?.quantity ? product.quantity : 1;
  const [quantity, setQuantity] = useState(Number(initialQuantity));

  const handleAdd = () => {
    let updatedQuantity = quantity + 1;
    let [newQuantity, forcedQuantityFlag] =
      ProductService.calculateQuantityOnMinimumOrderQuantity(
        updatedQuantity,
        minimumOrderQuantity,
        "addition"
      );
    if (forcedQuantityFlag) {
      setShowQuantityChangeToast(
        true,
        minimumOrderQuantity,
        forcedQuantityFlag
      );
    }
    setQuantity(newQuantity);
    let productToAdd = {
      skuCode: product.skuCode,
      quantity: newQuantity,
      image: product?.productImages?.[0]?.name,
      productName: product?.productName,
      inventory: product?.inventory,
    };
    props.handleUpdate(productToAdd);
    let productQuantityIncreasedEventData = {
      skuCode: product?.skuCode || "",
      page: "Cart Page",
    };

    AnalyticsService.pushEvent(
      `Product Quantity Increased`,
      productQuantityIncreasedEventData
    );
  };

  const handleRemove = () => {
    if (quantity > 1) {
      let updatedQuantity = quantity - 1;
      let [newQuantity, forcedQuantityFlag] =
        ProductService.calculateQuantityOnMinimumOrderQuantity(
          updatedQuantity,
          minimumOrderQuantity,
          "removal"
        );
      if (
        updatedQuantity !== newQuantity &&
        updatedQuantity < minimumOrderQuantity
      ) {
        setShowQuantityChangeToast(
          true,
          minimumOrderQuantity,
          forcedQuantityFlag
        );
      }
      setQuantity(newQuantity);
      if (newQuantity > 0) {
        let productToAdd = {
          skuCode: product.skuCode,
          quantity: newQuantity,
          image: product?.productImages?.[0]?.name,
          productName: product?.productName,
          inventory: product?.inventory,
        };
        props.handleUpdate(productToAdd);
      } else {
        props.handleRemove(product.skuCode);
      }
      let productQuantityDecreasedEventData = {
        skuCode: product?.skuCode || "",
        page: "Cart Page",
      };

      AnalyticsService.pushEvent(
        `Product Quantity Decreased`,
        productQuantityDecreasedEventData
      );
    }
  };

  const handleChange = (userInputQuantity) => {
    let quantityToSet = 1;

    if (userInputQuantity) {
      userInputQuantity = parseInt(userInputQuantity);
      if (userInputQuantity === 0) {
        setShowQuantityChangeToast(true, minimumOrderQuantity, false);
        setQuantity(1);
        quantityToSet = 1;
      } else {
        if (!userInputQuantity || userInputQuantity === "") {
          setShowQuantityChangeToast(true, minimumOrderQuantity, false);
          setQuantity(quantityToSet);
        } else {
          let [newQuantity, forcedQuantityFlag] =
            ProductService.calculateQuantityOnMinimumOrderQuantity(
              userInputQuantity,
              minimumOrderQuantity,
              "addition"
            );
          if (newQuantity !== userInputQuantity) {
            setShowQuantityChangeToast(
              true,
              minimumOrderQuantity,
              forcedQuantityFlag
            );
          }
          setQuantity(newQuantity);
          quantityToSet = newQuantity;
        }
      }

      console.log({ quantityToSet });
      let productToAdd = {
        skuCode: product.skuCode,
        quantity: quantityToSet,
        image: product?.productImages?.[0]?.name,
        productName: product?.productName,
        inventory: product?.inventory,
      };
      props.handleUpdate(productToAdd);
    } else {
      props.handleRemove(product.skuCode);
    }
  };

  const handleChangeDebounced = useCallback(debounce(handleChange, 800), [
    props.handleUpdate,
    props.handleRemove,
  ]);
  const handleChangeInput = (event) => {
    let userInputQuantity = event.target.value;
    userInputQuantity = parseInt(userInputQuantity);

    if (quantity === userInputQuantity) {
    } else {
      setQuantity(userInputQuantity);
      handleChangeDebounced(userInputQuantity);
    }
    let productQuantityUpdatedEventData = {
      skuCode: product?.skuCode || "",
      initialQuantity: quantity || 0,
      quantityChangedTo: userInputQuantity,
      page: "Cart Page",
    };

    AnalyticsService.pushEvent(
      `Product Quantity Updated Via Input`,
      productQuantityUpdatedEventData
    );
  };
  return (
    <div>
      <ErrorBoundary message={``}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          className={classes.priceSection}
        >
          <Grid item xs={7}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
              style={{ margin: 0 }}
            >
              {isOfferApplied ? (
                <>
                  <Grid item xs={12} className={classes.offerQuantity}>
                    <Typography variant={`body2`}>
                      Qty: <b>{product.quantity}</b>
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} className={classes.inputWrapper}>
                    <Box className={classes.removeIconButtonWrapper}>
                      <IconButton
                        size={"small"}
                        className={classes.removeIconButton}
                        onClick={handleRemove}
                      >
                        <Remove className={classes.removeIcon} />
                      </IconButton>
                    </Box>
                    <TextField
                      name={`quantity`}
                      required={true}
                      type={`number`}
                      size={`small`}
                      variant={"outlined"}
                      value={quantity}
                      onChange={handleChangeInput}
                      fullWidth={true}
                      inputProps={{
                        min: { minimumOrderQuantity },
                        max: `${CommonConstantValues.MAX_PURCHASE_QUANTITY}`,
                        step: { minimumOrderQuantity },
                        style: {
                          height: 15,
                          fontSize: 16,
                          textAlign: "center",
                          zIndex: 0,
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #999999",
                          borderRadius: "4px",
                        },
                      }}
                      min={1}
                    />
                    <Box className={classes.addIconButtonWrapper}>
                      <IconButton
                        size={"small"}
                        onClick={handleAdd}
                        className={classes.addIconButton}
                      >
                        <Add className={classes.addIcon} />
                      </IconButton>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography
              variant={"subtitle1"}
              className={classes.price}
              style={{
                filter: PartnerService.applySmudgedPriceFilter(product),
              }}
            >
              <b>
                ₹
                {AmountUtils.format(
                  product.sellingPrice && quantity
                    ? quantity * product.sellingPrice
                    : product.sellingPrice
                )}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </ErrorBoundary>
    </div>
  );
};

export default AddRemoveBagItem;
