import React from "react";
import AmountUtils from "../../Utils/AmountUtils";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const PurchaseDetailsTable = (props) => {
  const tablePurchaseDetails =
    props?.currentTableTab === "categoryWiseSale"
      ? props?.categoryWisePurchaseDetails
        ? props?.categoryWisePurchaseDetails
        : []
      : props?.classWisePurchaseDetails
        ? props?.classWisePurchaseDetails
        : [];
  const PRODUCT_CATEGORIES = [
    "Seeds",
    "Hardware",
    "Crop Protection",
    "Crop Nutrition",
    "Others",
  ];
  const displayNameMappings = {
    "Crop Nutrition": "CN",
    "Crop Protection": "CP",
  };
  const PRODUCT_CLASSES = ["A", "B", "C", "D", "No Class"];
  const displayDataFromResponseObject = tablePurchaseDetails.map((item) => ({
    category: item.productCategory || null,
    class: item.productClass || null,
    grossRevenue: item.grossRevenue || 0,
    returnRevenue: item.returnRevenue || 0,
    netRevenue: item.netRevenue || 0,
  }));
  const rowNames =
    props?.currentTableTab === "categoryWiseSale"
      ? PRODUCT_CATEGORIES
      : PRODUCT_CLASSES;

  const tableCellHeading = {
    fontSize: "11px",
    fontWeight: "700",
    padding: "4px",
    color: "#4F4F4F",
  };
  const tableCell = {
    borderRight: "dashed",
    borderColor: "#00000010 !important",
    borderBottom: "none",
    padding: "4px",
    color: "#4F4F4F",
  };
  return (
    <TableContainer
      component={Paper}
      sx={{ wordBreak: "break-word", borderRadius: "0px" }}
    >
      <Table
        sx={{
          backgroundColor: "#4F4F4F10",
          tableLayout: "fixed",
        }}
        aria-label="simple table"
        size={"small"}
      >
        <TableHead sx={{ border: "none !important" }}>
          <TableRow sx={{ borderBottom: "dashed", borderColor: "#00000010" }}>
            <TableCell
              sx={{
                ...tableCellHeading,
                borderRight: "dashed",
                borderColor: "#00000010 !important",
              }}
              align={"left"}
            >
              {props?.currentTableTab === "categoryWiseSale"
                ? "Category"
                : "Class"}
            </TableCell>
            <TableCell
              sx={{
                ...tableCellHeading,
                borderRight: "dashed",
                borderColor: "#00000010 !important",
                lineHeight: "normal",
              }}
              align="left"
            >
              Gross Purchase
            </TableCell>
            <TableCell
              sx={{
                ...tableCellHeading,
                borderRight: "dashed",
                borderColor: "#00000010 !important",
              }}
              align="left"
            >
              Returns
            </TableCell>
            <TableCell
              sx={{
                ...tableCellHeading,
                borderRight: "1px solid #ffffff !important",
                lineHeight: "normal",
              }}
              align="left"
            >
              Net Purchase
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowNames.map((rowName) => {
            const categorydisplayName = displayNameMappings[rowName] || rowName;
            const rowData = displayDataFromResponseObject.find((item) =>
              props?.currentTableTab === "categoryWiseSale"
                ? item.category === rowName
                : item.class === rowName
            ) || {
              grossRevenue: 0,
              returnRevenue: 0,
              netRevenue: 0,
            };
            return (
              <TableRow key={rowName}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    ...tableCell,
                    fontSize: "11px",
                    fontWeight: "700",
                    lineHeight: "26px",
                    paddingLeft: "6px",
                  }}
                  align={"left"}
                >
                  {categorydisplayName}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCell,
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  ₹{AmountUtils.format(rowData.grossRevenue)}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCell,
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  ₹{AmountUtils.format(rowData.returnRevenue)}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #ffffff",
                    fontSize: "12px",
                    padding: "4px",
                    borderBottom: "none",
                    color: "#4F4F4F",
                  }}
                  align="right"
                >
                  ₹{AmountUtils.format(rowData.netRevenue)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchaseDetailsTable;
