import { Box, Grid, Typography } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import AnalyticsService from "../../Services/AnalyticsService";
import AmountUtils from "../../Utils/AmountUtils";

const CurrentSchemeAchievements = (props) => {
  return (
    <>
      {props?.showTitle && (
        <Typography
          sx={{
            color: "#000000",
            fontSize: "16px",
            fontWeight: 700,
            margin: "20px 0px",
          }}
        >
          Achievements
        </Typography>
      )}

      <Box>
        <Grid
          container
          textAlign={`center`}
          sx={{
            background: "#FFFFFF",
            borderRadius: "10px",
          }}
        >
          <Grid item xs={4}></Grid>
          <Grid
            item
            xs={8}
            textAlign={`center`}
            sx={{
              background: "rgba(248, 209, 73, 0.1)",
              paddingBottom: "14px",
              paddingTop: "17px",
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#333333",
                  }}
                >
                  {`Sale`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#333333",
                    borderRadius: "0px 10px 0px 0px",
                  }}
                >
                  {`Amount`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} textAlign={`left`}>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#333333",
                paddingLeft: "25px",
                paddingBottom: "22px",
              }}
            >
              {`Gross Sale`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              background: "rgba(248, 209, 73, 0.1)",
              paddingBottom: "22px",
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333333",
                  }}
                >
                  {props?.turnoverScheme?.grossValue >= 0
                    ? props?.slabConstraintUnit === `₹`
                      ? `${props?.slabConstraintUnit} ${new Intl.NumberFormat(
                          "en-IN"
                        ).format(props.turnoverScheme.grossValue)}`
                      : `${props.turnoverScheme.grossValue} ${props?.slabConstraintUnit}`
                    : ``}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333333",
                  }}
                >
                  {props?.turnoverScheme?.grossAmount >= 0
                    ? `₹ ` +
                      new Intl.NumberFormat("en-IN").format(
                        props.turnoverScheme.grossAmount
                      )
                    : ``}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} textAlign={`left`}>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#333333",
                paddingLeft: "25px",
                paddingBottom: "16px",
              }}
            >
              {`Returns`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              background: "rgba(248, 209, 73, 0.1)",
              paddingBottom: "16px",
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333333",
                  }}
                >
                  {props?.turnoverScheme?.returnedValue >= 0
                    ? props?.slabConstraintUnit === `₹`
                      ? `${props.slabConstraintUnit} ${new Intl.NumberFormat(
                          "en-IN"
                        ).format(props.turnoverScheme.returnedValue)}`
                      : `${props.turnoverScheme.returnedValue} ${props.slabConstraintUnit}`
                    : ``}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333333",
                  }}
                >
                  {props?.turnoverScheme?.returnedAmount >= 0
                    ? `₹ ` +
                      new Intl.NumberFormat("en-IN").format(
                        props.turnoverScheme.returnedAmount
                      )
                    : ``}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {props?.showNetSchemeCN && (
            <>
              <Grid item xs={4} textAlign={`left`}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333333",
                    paddingLeft: "25px",
                    paddingBottom: "16px",
                  }}
                >
                  {`Net Scheme CN`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  background: "rgba(248, 209, 73, 0.1)",
                  paddingBottom: "16px",
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#333333",
                      }}
                    >
                      {`-`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#333333",
                      }}
                    >
                      {props?.turnoverScheme?.totalCNReceived >= 0
                        ? `₹ ` +
                          new Intl.NumberFormat("en-IN").format(
                            props.turnoverScheme.totalCNReceived
                          )
                        : `-`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {props?.showNetLandingCN && (
            <>
              <Grid item xs={4} textAlign={`left`}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333333",
                    paddingLeft: "25px",
                    paddingBottom: "16px",
                  }}
                >
                  {`Net Landing CN`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  background: "rgba(248, 209, 73, 0.1)",
                  paddingBottom: "16px",
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#333333",
                      }}
                    >
                      {`-`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#333333",
                      }}
                    >
                      {props?.turnoverScheme?.currentNLDAmount >= 0
                        ? `₹ ` +
                          new Intl.NumberFormat("en-IN").format(
                            props.turnoverScheme.currentNLDAmount
                          )
                        : `-`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {props?.isFeaturedSchemes && (
            <>
              <Grid item xs={4} textAlign={`left`}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333333",
                    paddingLeft: "25px",
                    paddingBottom: "16px",
                  }}
                >
                  Other CN
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  background: "rgba(248, 209, 73, 0.1)",
                  paddingBottom: "16px",
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#333333",
                      }}
                    >
                      {`-`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#333333",
                      }}
                    >
                      {AmountUtils.formatCurrency(
                        props.turnoverScheme.manualCN || 0
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} textAlign={`left`}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333333",
                    paddingLeft: "25px",
                    paddingBottom: "16px",
                  }}
                >
                  Cash Discount
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  background: "rgba(248, 209, 73, 0.1)",
                  paddingBottom: "16px",
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#333333",
                      }}
                    >
                      {`-`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#333333",
                      }}
                    >
                      {AmountUtils.formatCurrency(
                        props.turnoverScheme.cashDiscount || 0
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <Grid
            item
            xs={4}
            textAlign={`left`}
            sx={{ background: "rgba(0, 115, 62, 0.1)" }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                color: "#333333",
                paddingLeft: "25px",
                paddingBottom: "22px",
                paddingTop: "14px",
              }}
            >
              {`Eligible Net Sale`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              background: "rgb(229, 236, 220)",
              paddingBottom: "22px",
              paddingTop: "14px",
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#333333",
                  }}
                >
                  {props?.turnoverScheme?.netValue >= 0
                    ? props?.slabConstraintUnit === `₹`
                      ? `${props.slabConstraintUnit} ${new Intl.NumberFormat(
                          "en-IN"
                        ).format(props.turnoverScheme.netValue)}`
                      : `${props.turnoverScheme.netValue} ${props.slabConstraintUnit}`
                    : ``}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#333333",
                  }}
                >
                  {props?.turnoverScheme?.netAmount >= 0
                    ? `₹ ` +
                      new Intl.NumberFormat("en-IN").format(
                        props.turnoverScheme.netAmount
                      )
                    : ``}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            textAlign={`left`}
            sx={{
              background: "rgba(0, 115, 62, 0.1)",
              borderRadius: "0px 0px 0px 10px",
              display: "flex",
            }}
            onClick={() => {
              if (props?.showSettledSaleBreakup) {
                props?.setOpenSettledSaleBreakup(true);
                AnalyticsService.pushEvent("Button Clicked", {
                  type: "Settled Sale Breakup",
                });
                AnalyticsService.pushEvent("Drawer Opened", {
                  type: "Settled Sale Breakup",
                });
              }
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                color: "#333333",
                paddingLeft: "25px",
                paddingBottom: "22px",
                borderRadius: "0px 0px 0px 10px",
                textDecoration: props?.showSettledSaleBreakup
                  ? "underline"
                  : "",
              }}
            >
              {`Settled Sale`}
            </Typography>
            {props?.showSettledSaleBreakup && (
              <ChevronRightRoundedIcon sx={{ mr: 1 }} />
            )}
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              background: "rgb(229, 236, 220)",
              paddingBottom: "22px",
              borderRadius: "0px 0px 10px 0px",
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#333333",
                  }}
                >
                  {props?.turnoverScheme?.SettledValue >= 0
                    ? props?.slabConstraintUnit === `₹`
                      ? `${props.slabConstraintUnit} ${new Intl.NumberFormat(
                          "en-IN"
                        ).format(props.turnoverScheme.SettledValue)}`
                      : `${props.turnoverScheme.SettledValue} ${props.slabConstraintUnit}`
                    : ``}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#333333",
                    borderRadius: "0px 0px 10px 0px",
                  }}
                >
                  {props?.turnoverScheme?.settledAmount >= 0
                    ? `₹ ` +
                      new Intl.NumberFormat("en-IN").format(
                        props.turnoverScheme.settledAmount
                      )
                    : ``}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CurrentSchemeAchievements;
