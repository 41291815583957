import React from "react";

import {
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles({
  iconButton: {
    height: "24px",
    width: "24px",
    margin: "5px",
    border: "1px solid #9D2123",
  },
  addIcon: {
    height: "20px",
    color: "#9D2123",
  },
  removeIcon: {
    height: "20px",
    color: "#9D2123",
  },
  priceInput: {
    width: "100px",
    margin: "0 5px",
    "& fieldset": { border: "1px solid #CACACA" },
  },
});

function CatalogProductCard(props) {
  const classes = useStyles();

  const handleCatalogChange = (property, value) => {
    const newCatalog = props?.catalog?.map((product) => {
      if (product?.skuCode === props?.product?.skuCode) {
        const inventoryPayload = _.uniqBy(
          [{ ...product, [property]: value }, ...props?.inventoryChangePayload],
          "skuCode"
        );
        props?.setInventoryChangePayload(inventoryPayload);

        return { ...product, [property]: value };
      }
      return product;
    });
    props?.setCatalog(newCatalog);
    props?.setIsInventoryChanged(true);

    if (property === "quantity") {
      AnalyticsService.pushEvent("Quantity Changed", {
        skuCode: props?.product?.skuCode,
        quantity: value,
        isEnabled: props?.product?.isEnabled,
        sellingPrice: props?.product?.sellingPrice,
      });
    }
  };

  const handleToggleChange = () => {
    handleCatalogChange("isEnabled", !props?.product?.isEnabled);

    const eventName = !props?.product?.isEnabled
      ? "Store Front Catalog Item Publish Button Clicked"
      : "Store Front Catalog Item Unpublish Button Clicked";
    AnalyticsService.pushEvent(eventName, {
      skuCode: props?.product?.skuCode,
      quantity: props?.product?.quantity,
      isEnabled: !props?.product?.isEnabled,
      sellingPrice: props?.product?.sellingPrice,
    });
  };

  const handleAdd = () => {
    handleCatalogChange("quantity", Number(props?.product?.quantity) + 1);
  };

  const handleRemove = () => {
    if (Number(props?.product?.quantity) > 0) {
      handleCatalogChange("quantity", Number(props?.product?.quantity) - 1);
    }
  };

  const handleQuantityChange = (event) => {
    handleCatalogChange("quantity", event.target.value);
  };

  const handlePriceChange = (event) => {
    AnalyticsService.pushEvent("Price Changed", {
      skuCode: props?.product?.skuCode,
      quantity: props?.product?.quantity,
      isEnabled: props?.product?.isEnabled,
      sellingPrice: event.target.value,
    });
    handleCatalogChange("sellingPrice", event.target.value);
  };

  return (
    <Card
      sx={{
        marginTop: "8px",
        padding: "8px",
        borderRadius: "16px",
        border: "1px solid rgba(202, 202, 202, 1)",
        boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
      }}
    >
      <Grid container alignItems="flex-start">
        <Grid item xs={2}>
          <img
            src={
              props.product?.productImages?.[0]?.name
                ? props.product.productImages?.[0]?.name
                : `/icons/dummy.jpeg`
            }
            style={{ borderRadius: "16px" }}
            alt={props.product?.productName}
            height={50}
          />
        </Grid>
        <Grid
          item
          xs={10}
          container
          rowGap="10px"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={9}
            sx={{ lineHeight: "0.1", span: { lineHeight: "1.5" } }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "#1C1C1C",
                fontWeight: 700,
                wordBreak: "break-all",
              }}
            >
              {props.product?.productName}
            </Typography>
          </Grid>

          <Grid item xs={3} sx={{ textAlign: "right" }}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={
                <Switch
                  color={"secondary"}
                  size={"small"}
                  checked={props?.product?.isEnabled}
                  onChange={() =>
                    props.isDeliveryViaStoreEnabled
                      ? handleToggleChange()
                      : props?.handleToggleCatalogItem(props.product)
                  }
                />
              }
            />
          </Grid>

          {props.isDeliveryViaStoreEnabled && (
            <>
              <Grid item xs={4}>
                <TextField
                  name={`price`}
                  type={`number`}
                  size={`small`}
                  disabled={props?.product?.isPLProduct}
                  value={props?.product?.sellingPrice}
                  className={classes.priceInput}
                  onChange={handlePriceChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span style={{ fontWeight: 700, color: "#00733E" }}>
                          ₹
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0,
                    max: 1000000,
                    type: "number",
                    minLength: "1",
                    maxLength: "4",
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    style: {
                      height: 17,
                      fontSize: 15,
                      fontWeight: "700",
                      textAlign: "center",
                      borderRadius: "4px",
                      color: "#00733E",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  onClick={handleRemove}
                  className={classes.iconButton}
                >
                  <Remove className={classes.removeIcon} />
                </IconButton>
                <TextField
                  name={`quantity`}
                  type={`number`}
                  size={`small`}
                  value={props?.product?.quantity}
                  onChange={handleQuantityChange}
                  sx={{ height: 25 }}
                  inputProps={{
                    min: 0,
                    max: 1000000,
                    type: "number",
                    minLength: "1",
                    maxLength: "4",
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    style: {
                      width: 40,
                      height: 17,
                      fontSize: 15,
                      padding: "8px",
                      fontWeight: "700",
                      textAlign: "center",
                      borderRadius: "4px",
                      color: "#9D2123",
                      border: "1px solid #9D2123",
                    },
                  }}
                />
                <IconButton onClick={handleAdd} className={classes.iconButton}>
                  <Add className={classes.addIcon} />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default CatalogProductCard;
