import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import _ from "lodash";

const OnboardingProgressStepper = ({ steps, currentlyActiveStep }) => {
  return (
    <>
      <Stepper activeStep={currentlyActiveStep} alternativeLabel>
        {_.map(steps, (step, index) => (
          <Step
            key={index}
            sx={{
              paddingLeft: "2px",
              paddingRight: "2px",
              ".MuiStepConnector-line": {
                borderColor: "#9D2123",
                borderTopWidth: "5px",
              },
              ".MuiStepConnector-root": {
                left: "calc(-50% + 15px)",
                right: "calc(50% + 15px)",
              },
              ".MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                marginTop: "8px",
              },
            }}
          >
            <StepLabel
              sx={{
                ".MuiStepLabel-label": {
                  color: "#4F4F4F !important",
                  fontSize: "11px",
                  fontWeight: "700 !important",
                },
              }}
            >
              {step?.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ display: "flex" }}>
        {_.map(steps, (step, index) => (
          <Box
            key={index}
            sx={{
              width: `${100 / steps?.length}%`,
              display: "inline-block",
              textAlign: "center",
              padding: "0px 2px",
            }}
          >
            <Typography color={"secondary"} fontSize={"11px"}>
              {step?.subtitle}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default OnboardingProgressStepper;
