import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import format from "date-fns/format";
import InfiniteScroll from "react-infinite-scroll-component";

import makeStyles from "@mui/styles/makeStyles";
import { Skeleton } from "@mui/material";
import { Card, Container, Button, Grid, Typography, Box } from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import HisaabService from "../../Services/HisaabService";
import AmountUtils from "../../Utils/AmountUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
    background: "#ffffff",
  },
  blockUI: {
    height: "90vh",
  },
  gridContainer: {
    borderTop: "2px solid #9D2123",
    padding: "8px !important",
  },
  filterButton: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(2),
    width: "65px",
    height: "25px",
    fontSize: "12px",
  },
  cards: {
    padding: theme.spacing(2, 0.5),
    borderRadius: theme.spacing(0),
    borderBottom: "1px solid #eaeaea",
  },
  debitAmount: {
    color: "#9D2123",
    fontSize: "16px",
  },
  creditAmount: {
    color: "#00733E",
    fontSize: "16px",
  },
  creditIcon: {
    margin: theme.spacing(0.5, 0, 0, 0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(4),
    background: "rgba(0, 115, 62, 0.2)",
    width: "51px",
    height: "51px",
  },
  debitIcon: {
    margin: theme.spacing(0.5, 0, 0, 0.5),
    padding: theme.spacing(1.4),
    borderRadius: theme.spacing(4),
    background: "rgba(157, 33, 35, 0.2)",
    width: "51px",
    height: "51px",
  },
  schemeCardsWrapper: {
    display: "flex",
    gap: "10px",
    overflowX: "scroll",
    margin: "8px 0 20px",
  },
  schemeCard: {
    minWidth: "120px",
    maxWidth: "120px",
    padding: "5px 8px",
    borderRadius: "8px",
    wordBreak: "break-all",
    border: "1px solid #CACACA",
  },
}));

const TransactionDetails = ({ isLoading, currentTab, currentPage }) => {
  const classes = useStyles();
  const endpoint =
    currentTab === "/wallet"
      ? "cashbackwalletbalance"
      : currentTab === "/wallet/real-cash"
        ? "transaction"
        : null;
  const [loading, setLoading] = useState(isLoading ? isLoading : "not_started");
  const [isCreditSelected, setIsCreditSelected] = useState(false);
  const [isDebitSelected, setIsDebitSelected] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [absDetails, setABSDetails] = useState({});
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [totalTransactionCount, setTotalTransactionCount] = useState(0);
  const [currentTransactionCount, setCurrentTransactionCount] = useState(0);
  const [isTransactionTypeChanged, setIsTransactionTypeChanged] =
    useState(false);

  useEffect(() => {
    setLoading("started");
    setABSDetails({});
    setWalletTransactions([]);
    setTotalTransactionCount(0);
    setCurrentTransactionCount(0);

    if (isCreditSelected && !isDebitSelected) {
      setTransactionType("credit");
      setIsTransactionTypeChanged(!isTransactionTypeChanged);
    } else if (!isCreditSelected && isDebitSelected) {
      setTransactionType("debit");
      setIsTransactionTypeChanged(!isTransactionTypeChanged);
    } else {
      setTransactionType("");
      setIsTransactionTypeChanged(!isTransactionTypeChanged);
    }
  }, [currentPage, isCreditSelected, isDebitSelected]);

  useEffect(() => {
    const walletCB = fetchWalletTransaction(0);
    return () => walletCB();
  }, [isTransactionTypeChanged]);

  const fetchWalletTransaction = (offset = currentTransactionCount) => {
    const { axiosRequest, abortController } =
      HisaabService.getWalletTransaction(endpoint, transactionType, offset);
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          const list =
            response.data.responseData?.transactions?.map((item) => ({
              ...item,
              transactionType: item?.transactionType
                ?.toLowerCase()
                ?.includes("credit")
                ? "CREDIT"
                : "DEBIT",
            })) || [];
          const transactionCount =
            response.data.responseData?.totalTransactions ||
            response.data.responseData?.totalTransactionCount;

          setCurrentTransactionCount(
            (previousCount) => previousCount + list.length
          );
          setTotalTransactionCount(transactionCount);
          setWalletTransactions((previousTransactions) =>
            previousTransactions ? [...previousTransactions, ...list] : list
          );
          setABSDetails(response.data.responseData);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.log("error", error);
        }
      });
    return () => abortController.abort();
  };

  const handleCreditButtonClick = () => {
    setIsCreditSelected(!isCreditSelected);
    AnalyticsService.pushEvent(`Credit Button Clicked`, { page: currentPage });
  };

  const handleDebitButtonClick = () => {
    setIsDebitSelected(!isDebitSelected);
    AnalyticsService.pushEvent(`Debit Button Clicked`, { page: currentPage });
  };

  return (
    <ErrorBoundary>
      <Container maxWidth={`xl`} className={classes.container}>
        <BlockUi
          tag="div"
          blocking={loading === "started" && loading !== "done"}
          message={`Loading...`}
          keepInView
          renderChildren={false}
          className={classes.blockUI}
        >
          <ErrorBoundary message={""}>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              className={classes.gridContainer}
            >
              {currentTab === "/wallet/real-cash" && (
                <>
                  <Grid item xs={12} sx={{ fontWeight: "500" }}>
                    Amount Breakup
                  </Grid>
                  <Grid item xs={12} className={classes.schemeCardsWrapper}>
                    {absDetails?.absSchemeBalances?.absSchemeBalanceBreakup?.map(
                      (scheme, index) => (
                        <Grid item key={index} className={classes.schemeCard}>
                          <Typography fontSize={12}>
                            {scheme.schemeName}
                          </Typography>
                          <Typography
                            fontSize={12}
                            fontWeight={700}
                            color="secondary"
                          >
                            {AmountUtils.formatCurrency(scheme.realUsable || 0)}
                          </Typography>
                        </Grid>
                      )
                    )}
                    <Grid item className={classes.schemeCard}>
                      <Typography fontSize={12}>Normal Real Cash</Typography>
                      <Typography
                        fontSize={12}
                        fontWeight={700}
                        color="secondary"
                      >
                        {AmountUtils.formatCurrency(
                          absDetails?.realCashBreakup?.realAvailable || 0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item sx={{ fontWeight: "500" }}>
                Transactions
              </Grid>
              <Grid item>
                <Button
                  className={classes.filterButton}
                  variant={isCreditSelected ? "contained" : "outlined"}
                  color={"secondary"}
                  onClick={handleCreditButtonClick}
                >
                  Credit
                </Button>
                <Button
                  className={classes.filterButton}
                  variant={isDebitSelected ? "contained" : "outlined"}
                  color={"secondary"}
                  onClick={handleDebitButtonClick}
                >
                  Debit
                </Button>
              </Grid>
            </Grid>
          </ErrorBoundary>

          {walletTransactions && walletTransactions.length > 0 ? (
            <InfiniteScroll
              dataLength={currentTransactionCount}
              next={fetchWalletTransaction}
              hasMore={currentTransactionCount < totalTransactionCount}
              loader={<p style={{ textAlign: "center" }}>Loading...</p>}
            >
              {walletTransactions.map((entry, index) => (
                <ErrorBoundary message={""} key={index}>
                  <Card className={classes.cards}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={2}>
                        {entry.transactionType === "CREDIT" ? (
                          <img
                            src={`/icons/creditIcon.svg`}
                            alt=""
                            className={classes.creditIcon}
                          />
                        ) : (
                          <img
                            src={`/icons/debitIcon.svg`}
                            alt=""
                            className={classes.debitIcon}
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            color: "#666666",
                            fontSize: "12px",
                            lineHeight: "15.4px",
                          }}
                        >
                          <b sx={{ fontSize: "14px" }}>{entry.remarks || ""}</b>
                          <br />
                          <span>
                            Reason:{" "}
                            {entry.reason === "SCAN_AND_EARN"
                              ? "STORE_PURCHASE_REWARD"
                              : entry.reason || ""}
                            <br />
                            {entry.transactionType === "CREDIT"
                              ? "Earned on"
                              : "Used on"}
                            :{" "}
                            {entry?.transactionTimestamp || entry?.created_on
                              ? format(
                                  entry.transactionTimestamp ||
                                    entry.created_on,
                                  "dd MMM yyyy"
                                )
                              : "-"}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          <b
                            className={
                              entry.transactionType === "CREDIT"
                                ? classes.creditAmount
                                : classes.debitAmount
                            }
                          >
                            {entry.transactionType === "CREDIT" ? "+" : "-"}{" "}
                            {AmountUtils.formatCurrency(entry?.amount || 0)}
                            /-
                          </b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </ErrorBoundary>
              ))}
            </InfiniteScroll>
          ) : (
            loading === "done" && (
              <Typography
                variant={"body1"}
                textAlign={"center"}
                sx={{ margin: (theme) => theme.spacing(5, 1, 1, 1) }}
              >
                No Transactions Found.
              </Typography>
            )
          )}

          {loading === "started" && (
            <Skeleton variant="rectangular" height={600} />
          )}
        </BlockUi>
      </Container>
    </ErrorBoundary>
  );
};

export default TransactionDetails;
