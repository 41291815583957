import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CloseIcon from "@mui/icons-material/Close";
import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";

const SingleSelectFilter = ({
  page,
  selectedFilter,
  setSelectedFilter,
  clearAllFilters,
  setCurrentPage,
  selectedFilterCategory,
  setSelectedFilterCategory,
  filter: { filterTitle, filterOptions, filterCategory },
}) => {
  const [open, setOpen] = useState(false);
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState("All");
  const [previousSelectedOption, setPreviousSelectedOption] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);

  const handleClickOpenFilter = () => {
    AnalyticsService.pushEvent(`Filters Button Clicked`, {
      filterCategory: filterCategory,
      page: page,
    });
    setOpen(true);
    AndroidService.unsetPullToRefresh();
  };

  const handleClickCloseFilter = () => {
    setOpen(false);
    AndroidService.setPullToRefresh();
    if (clearFilter) {
      setCurrentlySelectedOption(previousSelectedOption);
      setPreviousSelectedOption("All");
      setClearFilter(false);
    }
  };

  const handleClickApplyFilter = () => {
    AnalyticsService.pushEvent(`Apply Filter Clicked`, {
      filterCategory: filterCategory,
      selectedFilter: currentlySelectedOption,
      page: page,
    });
    setOpen(false);
    AndroidService.setPullToRefresh();
    if (currentlySelectedOption === "All") {
      setSelectedFilterCategory(null);
    } else {
      setSelectedFilterCategory(filterCategory);
    }
    setCurrentPage(0);
    setSelectedFilter(currentlySelectedOption);
  };

  const handleClickClearFilter = () => {
    AnalyticsService.pushEvent(`Clear All Filters Clicked`, {
      filterCategory: filterCategory,
      page: page,
    });
    setPreviousSelectedOption(currentlySelectedOption);
    setCurrentlySelectedOption("All");
    setClearFilter(true);
    setCurrentPage(0);
    setSelectedFilterCategory(null);
  };

  useEffect(() => {
    AndroidService.setPullToRefresh();
    if (clearAllFilters) {
      setPreviousSelectedOption("All");
      setCurrentlySelectedOption("All");
      setClearFilter(true);
    }
  }, [clearAllFilters]);

  return filterOptions?.length > 0 ? (
    <>
      <Chip
        clickable={true}
        sx={{
          boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1)",
          backgroundColor:
            selectedFilterCategory === filterCategory
              ? `#9D2123 !important`
              : `#FFFFFF !important`,
          color:
            selectedFilterCategory === filterCategory ? `#FFFFFF` : `#4F4F4F`,
          fontWeight: 700,
          maxWidth: "60vw",
        }}
        deleteIcon={
          <ExpandMoreRoundedIcon
            sx={{
              marginLeft: "-16px !important",
              fontSize: "32px !important",
              color:
                selectedFilterCategory === filterCategory
                  ? `#FFFFFF !important`
                  : `#4F4F4F !important`,
            }}
          />
        }
        label={
          selectedFilterCategory === filterCategory
            ? selectedFilter
            : filterTitle
        }
        onClick={handleClickOpenFilter}
        onDelete={handleClickOpenFilter}
      />

      <SwipeableDrawer
        anchor={`bottom`}
        open={open}
        onClose={handleClickCloseFilter}
        onOpen={handleClickOpenFilter}
        PaperProps={{
          style: {
            borderRadius: "24px 24px 0px 0px",
            overflowY: "hidden",
          },
        }}
        disableDiscovery={true}
        disableSwipeToOpen={true}
      >
        <Box
          sx={{ height: "80vh" }}
          role="presentation"
          onKeyDown={handleClickCloseFilter}
        >
          <Box
            sx={{
              position: "sticky",
              top: "0",
              zIndex: 100,
              background: "#FFFFFF",
              paddingTop: "16px",
            }}
          >
            <Grid
              container
              alignItems={`center`}
              justifyContent={`space-between`}
              sx={{ padding: "0px 20px 16px 20px" }}
            >
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {filterTitle}
              </Typography>
              <CloseIcon
                onClick={() => {
                  handleClickCloseFilter();
                  AnalyticsService.pushEvent(`Close Filters Button Clicked`, {
                    filterCategory: filterCategory,
                    page: page,
                  });
                }}
                sx={{
                  color: "#999999",
                  fontSize: "28px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Divider />
          </Box>

          <Box
            sx={{
              background: "#F3F3F3",
              paddingBottom: "50px",
              height: "70vh",
              overflowY: "scroll",
            }}
          >
            <FormControl fullWidth sx={{ padding: "0px 12px" }}>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => {
                  setPreviousSelectedOption(currentlySelectedOption);
                  setCurrentlySelectedOption(event?.target?.value);
                }}
                value={currentlySelectedOption}
              >
                {_.map(filterOptions, (filterOption, index) => (
                  <FormControlLabel
                    labelPlacement="start"
                    value={filterOption}
                    control={<Radio />}
                    label={filterOption}
                    key={index}
                    sx={{
                      margin: "0px",
                      justifyContent: "space-between",
                      textTransform: "capitalize",
                      color: "#4F4F4F",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px",
                        fontWeight: 700,
                        paddingLeft: "8px",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              position: "sticky",
              bottom: "0",
              width: "100%",
              zIndex: 100,
              background: "#FFFFFF",
            }}
          >
            <Grid
              container
              spacing={0}
              alignItems={`center`}
              justifyContent={`center`}
              textAlign={`right`}
            >
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  disableRipple
                  disableTouchRipple
                  fullWidth="true"
                  size={"medium"}
                  onClick={() => {
                    handleClickClearFilter();
                  }}
                  sx={{
                    fontWeight: 700,
                    padding: "10px 15px",
                    borderRadius: "0px",
                    boxShadow: "none",
                    backgroundColor: "#FFFFFF",
                    color: "#333333",
                    border: "none !important",
                    "&:hover": {
                      border: "none !important",
                    },
                  }}
                >
                  Clear All
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth="true"
                  color="primary"
                  size={"medium"}
                  onClick={() => {
                    handleClickApplyFilter();
                  }}
                  sx={{
                    fontWeight: 700,
                    padding: "10px 15px",
                    borderRadius: "0px",
                    boxShadow: "none",
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  ) : null;
};

export default SingleSelectFilter;
