import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { Box, Grid, Skeleton } from "@mui/material";

import Search from "./Search";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import DebugDialog from "../Common/DebugDialog";
import ProductsList from "../Product/ProductsList";
import ProductService from "../../Services/ProductService";
import AnalyticsService from "../../Services/AnalyticsService";
import StateUtils from "../../Utils/StateUtils";
import useQuery from "../../Hooks/useQuery";
import useRecentlyBoughtProducts from "../../Hooks/useRecentlyBoughtProducts";
import RecentSearches from "./RecentSearches";
import EmptyError from "../EmptyError/EmptyError";
import PopularSearchProducts from "./PopularSearchProducts";
import SuggestionResults from "./SuggestionResults";

function SuggestionSearchResults(props) {
  const query = useQuery();
  const typeFromQuery = query.get("type");
  const titleFromQuery = query.get("title");
  const themeFromQuery = query.get("theme");
  const skuCodeFromQuery = query.get("skuCode");
  const descriptionFromQuery = query.get("description");
  const keywordFromQuery = query.get("keyword");
  const searchType = query.get("searchType");
  const productSearchImageUrl = query.get("imageUrl");
  const [type, setType] = useState(typeFromQuery);
  const [keyword, setKeyword] = useState(keywordFromQuery || "");
  const [selectedSkuCode, setSelectedSkuCode] = useState(skuCodeFromQuery);
  const [keywordDebounced, setKeywordDebounced] = useState(
    keywordFromQuery || ""
  );
  const [offerDetail, setOfferDetail] = useState({
    title: titleFromQuery,
    theme: themeFromQuery,
    description: descriptionFromQuery,
  });

  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const [loadingImageSearchResults, setLoadingImageSearchResults] =
    useState("not_started");
  const [openDebug, setOpenDebug] = useState(false);
  const [debugMessage, setDebugMessage] = useState("");
  const [stateCode, setStateCode] = useState("MH");
  const recentBought = useRecentlyBoughtProducts();
  const [refresh, setRefresh] = useState(0);
  const [suggestionResult, setSuggestionResult] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const navigate = useNavigate();

  const handleClickSearchTerm = (searchTerm) => {
    setKeyword(searchTerm);
    setKeywordDebounced(searchTerm);
    setRefresh((previousValue) => previousValue + 1);
    const querySearchType = "Recent Search";
    getSearchResultsBySuggestion(searchTerm, querySearchType);
  };

  const getSearchResultsBySuggestion = (
    suggestionKeyword,
    querySearchType,
    shouldNavigate = true
  ) => {
    if (_.trim(suggestionKeyword)?.length === 0) {
      return;
    }

    setLoading("started");
    //setKeywordDebounced(suggestionKeyword);
    setKeyword(suggestionKeyword);
    setShowSuggestions(false);

    if (shouldNavigate) {
      navigate(`/search?keyword=${suggestionKeyword}`, { replace: true });
    }

    const searchClickRequestEventData = {
      searchQuery: suggestionKeyword || "",
      page: props?.page || "",
      searchType: searchType || "textSearch",
      imageUrl: searchType === "imageSearch" ? productSearchImageUrl : "",
      querySearchType: querySearchType || "",
    };

    AnalyticsService.pushEvent(
      `Search Query Sent`,
      searchClickRequestEventData
    );
    const { axiosRequest } =
      ProductService.searchProductsByKeyword(suggestionKeyword);
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          let responseData = response.data.responseData;
          console.log("responseData", responseData);

          if (responseData?.productGists?.length > 0) {
            let productsFromResponse = responseData.productGists;
            setProducts(productsFromResponse);
            setLoading("done");
          } else {
            setProducts(null);
            setLoading(`done`);
          }
          const searchClickResponseEventData = {
            searchQuery: keyword || "",
            numberOfProducts:
              response?.data?.responseData?.productGists?.length || 0,
            skuCode:
              response?.data?.responseData?.productGists
                ?.slice(0, 15)
                ?.map((p) => p.skuCode)
                .toString() || "",
            searchType: searchType || "textSearch",
          };

          AnalyticsService.pushEvent(
            `Search Result`,
            searchClickResponseEventData
          );
        } else {
          console.log(`no response received in responseData`);
          setLoading("done");
        }

        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting search results`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoading("done");
        }
      });

    const searchResultsPageViewedEventData = {
      searchType: searchType || "textSearch",
      imageUrl: searchType === "imageSearch" ? productSearchImageUrl : "",
    };

    AnalyticsService.pushEvent(
      `Search Results Page Viewed`,
      searchResultsPageViewedEventData
    );
  };

  const getSuggestionsByKeyword = (searchKeyword) => {
    AnalyticsService.pushEvent(`Suggestion Query Sent`, {
      queryText: searchKeyword,
      page: props?.page || "",
      searchType: searchType || "textSearch",
    });
    setShowSuggestions(true);
    const { axiosRequest } = ProductService.getSuggestionsByKeyword(
      searchKeyword,
      false
    );
    axiosRequest
      .then((suggestionResponse) => {
        const suggestionResults =
          suggestionResponse?.data?.responseData?.suggestions;
        const isSearchKeywordPresentInResult =
          _.findIndex(
            suggestionResults,
            (result) => result === searchKeyword
          ) !== -1;

        setSuggestionResult({
          suggestions: suggestionResults,
          isSearchKeywordPresentInResult: isSearchKeywordPresentInResult,
        });
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(error);
        } else {
          AnalyticsService.pushEvent("Suggestion Query Cancelled", {
            queryText: searchKeyword || "",
            page: props?.page || "",
            searchType: searchType || "textSearch",
          });
        }
        setSuggestionResult(null);
      });
  };

  useEffect(() => {
    if (productSearchImageUrl && productSearchImageUrl !== "") {
      setLoadingImageSearchResults("started");
      const searchClickRequestEventData = {
        searchQuery: keywordDebounced || "",
        page: props?.page || "",
        searchType: "imageSearch",
        imageUrl: productSearchImageUrl,
      };

      AnalyticsService.pushEvent(
        `Search Query Sent`,
        searchClickRequestEventData
      );
      const { axiosRequest, abortController } =
        ProductService.getProductByImageSearch(productSearchImageUrl);
      axiosRequest
        .then((response) => {
          var skuString = "";
          var products = response?.data?.responseData?.products;
          skuString = _.map(products, (product) => {
            return product.skuCode;
          })?.join(",");

          const { axiosRequest } =
            ProductService.getProductsListBySKUs(skuString);
          axiosRequest
            .then((response) => {
              if (response?.data?.responseData?.productGists?.length > 0) {
                let productsFromResponse =
                  response.data.responseData.productGists;
                productsFromResponse = _.filter(
                  productsFromResponse,
                  (product) => {
                    return (
                      product.brandName !== "Unassigned Brand" &&
                      ProductService.checkIfProductEnabled(product)
                    );
                  }
                );

                setProducts(productsFromResponse);
              } else {
                console.log(
                  `no response received in search products by image Recommendation`
                );
                setProducts(null);
              }
              const searchClickResponseEventData = {
                numberOfProducts:
                  response?.data?.responseData?.productGists?.length || 0,
                skuCode:
                  response?.data?.responseData?.productGists
                    ?.slice(0, 15)
                    ?.map((p) => p.skuCode)
                    .toString() || "",
                searchType: "imageSearch",
                imageUrl: productSearchImageUrl,
              };

              AnalyticsService.pushEvent(
                `Search Result`,
                searchClickResponseEventData
              );
              setLoadingImageSearchResults("done");
            })
            .catch((error) => {
              console.log(
                `error getting search products by image recommendation`
              );
              if (error?.data) {
                console.log(error.data);
              } else {
                console.log(error);
              }
              setLoadingImageSearchResults("done");
            });
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingImageSearchResults("done");
        });
      return () => abortController.abort();
    } else {
      setProducts(null);
      // setLoadingImageSearchResults("not_started");
    }
  }, [productSearchImageUrl]);

  useEffect(() => {
    setDebugMessage("");
    let currentStateCode = StateUtils.getStateCode();
    setStateCode(currentStateCode);
    const searchKeyword = _.trim(keywordDebounced);
    if (
      (searchKeyword && searchKeyword?.length > 1) ||
      searchType === "imageSearch"
    ) {
      const isVoiceSearch = searchType === "voiceSearch";
      if (isVoiceSearch) {
        getSuggestionsByKeyword(searchKeyword);
      } else {
        if (showSuggestions) {
          getSuggestionsByKeyword(searchKeyword);
        }
      }
    } else {
      //setShowSuggestions(false);
      setSuggestionResult(null);
      setProducts(null);
      setLoading("not_started");
    }
  }, [keywordDebounced, searchType]);

  useEffect(() => {
    if (keywordFromQuery?.length > 0 && searchType !== "voiceSearch") {
      getSearchResultsBySuggestion(keywordFromQuery, "", false);
    }
  }, [keywordFromQuery]);

  useEffect(() => {
    if (
      type === `create-offer` &&
      (keywordDebounced === null || keywordDebounced === "")
    ) {
      setLoading(recentBought?.loading);
      setProducts(recentBought?.products);
    }
  }, [recentBought.products, recentBought?.loading]);

  return (
    <Box
      sx={{
        overflow: showSuggestions ? "hidden" : "visible",
        height: showSuggestions ? "100vh" : "100%",
        paddingBottom: "16px",
      }}
    >
      <ErrorBoundary message={``}>
        <TopBar
          title={_.upperFirst(
            productSearchImageUrl ? `Image Search` : `Search`
          )}
          backNavigation={true}
          backLink={type === "undefined" ? `/dashboard` : false}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <ErrorBoundary message={``}>
        <Search
          setKeyword={setKeyword}
          keywordDebounced={keywordDebounced}
          setKeywordDebounced={setKeywordDebounced}
          keyword={keyword}
          page={`Search Results`}
          type={type}
          offerDetail={offerDetail}
          selectedSkuCode={selectedSkuCode}
          refresh={refresh}
          setShowSuggestions={setShowSuggestions}
          debounceValue={300}
          replaceCurrentUrl={false}
          navigateToSearchRoute={false}
          getSearchResultsBySuggestion={getSearchResultsBySuggestion}
        />
      </ErrorBoundary>
      {showSuggestions && (
        <ErrorBoundary message={``}>
          <Box sx={{ position: "relative" }}>
            <SuggestionResults
              suggestionResult={suggestionResult}
              keyword={_.trim(keywordDebounced)}
              handleSuggestionClick={getSearchResultsBySuggestion}
              setShowSuggestions={setShowSuggestions}
              page={props?.page}
              searchType={searchType}
            />
          </Box>
        </ErrorBoundary>
      )}

      {localStorage.getItem(`recentSearches`) && (
        <RecentSearches
          handleClickSearchTerm={handleClickSearchTerm}
          keyword={keyword}
          keywordDebounced={keywordDebounced}
          products={products}
        />
      )}

      {(productSearchImageUrl
        ? loadingImageSearchResults === "not_started"
        : loading === "not_started") && (
        <ErrorBoundary message={``}>
          <PopularSearchProducts
            numberOfProductsToShow={10}
            stateCode={stateCode}
          />
        </ErrorBoundary>
      )}

      {(productSearchImageUrl
        ? loadingImageSearchResults === "done" && !products
        : loading === "done" && !products && keywordDebounced) && (
        <EmptyError image="EmptyPage.svg" text="Sorry! No Results Found" />
      )}

      {(
        productSearchImageUrl
          ? loadingImageSearchResults === "done" && products?.length > 0
          : loading === "done" && products?.length > 0
      ) ? (
        <>
          <Box sx={{ backgroundColor: "#F3F3F3" }}>
            <>
              <ErrorBoundary message={``}>
                <ProductsList
                  loading={loading}
                  products={products}
                  stateCode={stateCode}
                  title={
                    keyword && keyword.length > 0
                      ? products && products.length > 0
                        ? products.length > 1
                          ? `Showing ${products.length} results for "${keyword}"`
                          : `Showing 1 result for "${keyword}"`
                        : `No matches found for "${keyword}"`
                      : ` `
                  }
                  hideEmptyError={true}
                  type={type}
                  keyword={keyword}
                  offerDetail={offerDetail}
                  selectedSkuCode={selectedSkuCode}
                  setSelectedSkuCode={setSelectedSkuCode}
                />
              </ErrorBoundary>
            </>
          </Box>
        </>
      ) : (
        <></>
      )}

      {(loading === "started" || loadingImageSearchResults === "started") && (
        <>
          <Box padding={2}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <Skeleton variant={`text`} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  variant="rectangular"
                  height={260}
                  sx={{ borderRadius: "10px" }}
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  variant="rectangular"
                  height={260}
                  sx={{ borderRadius: "10px" }}
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  variant="rectangular"
                  height={260}
                  sx={{ borderRadius: "10px" }}
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  variant="rectangular"
                  height={260}
                  sx={{ borderRadius: "10px" }}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <DebugDialog
        open={openDebug}
        debugMessage={debugMessage}
        handleCloseDebug={() => setOpenDebug(false)}
      />
    </Box>
  );
}

export default SuggestionSearchResults;
