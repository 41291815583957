import React from "react";
import useGrahakProductsRecommendation from "../../Hooks/useGrahakProductsRecommendation";
import HorizontalStandardProductCardContainer from "../Common/HorizontalStandardProductCardContainer";

function GrahakProductsRecommendation(props) {
  const { products, loading, error } = useGrahakProductsRecommendation();

  return (
    <>
      <HorizontalStandardProductCardContainer
        products={products}
        loading={loading}
        error={error}
        numberOfProductsToShow={props?.numberOfProductsToShow}
        title={""}
        viewAllUrl={"/grahak-products-recommendation"}
        stateCode={props?.stateCode}
        backgroundColor={"#F8D149"}
        section={"Grahak Products Recommendation"}
        saveScrollYPosition={true}
      />
    </>
  );
}

export default GrahakProductsRecommendation;
