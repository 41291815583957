import React from "react";
import format from "date-fns/format";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Card, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import ErrorBoundary from "./ErrorBoundary";
import CommonConstantValues from "../../Services/CommonConstantValues";
import { agentLeadStatus } from "../FieldAgent/FieldAgentLeadCard";
import AnalyticsService from "../../Services/AnalyticsService";
import AmountUtils from "../../Utils/AmountUtils";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(2),
    border: "none",
  },
  cardHeader: {
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    borderTop: "1px solid #eeeeee",
  },
  iptTag: {
    fontSize: "14px",
    borderRadius: "5px",
    background: "#dcdcdc",
    padding: "5px 4px",
    marginRight: "8px",
  },
  orderStatus: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    textTransform: "capitalize",
  },
  orderStatusIcon: {
    marginRight: theme.spacing(1),
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  eddLabel: {
    fontSize: "14px",
  },
  eddValue: {
    fontSize: "16px",
    fontWeight: 700,
  },
  totalAmountLabel: {
    fontSize: "14px",
  },
  totalAmountValue: {
    float: "right",
    fontSize: "16px",
    fontWeight: 700,
  },
  infoIcon: {
    margin: theme.spacing(0, -0.2, -0.4, 0),
    height: theme.spacing(2),
  },
  orderMessage: {
    fontSize: "14px",
    color: "#999999",
  },
  leadStatus: {
    borderRadius: "5px",
    padding: "5px 7px 5px 5px",
    fontSize: "12px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
  },
}));

const OrderDetailCard = ({
  order,
  orderType,
  showCancelOrder,
  showPlacedBy,
  setOpenSelectCancelReason,
}) => {
  const classes = useStyles();

  const handleClickCancelOrder = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Cancel Order",
    });
    setOpenSelectCancelReason(true);
  };

  const isOrderCancellableByFieldAgent = () => {
    if (
      localStorage.getItem("role") === "fieldAgent" &&
      order?.cancellable &&
      orderType !== "fieldAgentLead"
    ) {
      return true;
    }
    return false;
  };

  return (
    <ErrorBoundary message={""}>
      <Card className={classes.card} variant="outlined">
        <div className={classes.cardHeader}>
          <div style={{ minWidth: "165px" }}>
            {order?.orderType === CommonConstantValues.IPT_FORWARD_ORDER && (
              <span className={classes.iptTag}>IPT</span>
            )}
            <span style={{ fontSize: "14px" }}>
              {orderType === "fieldAgentLead" ? "Lead ID: " : "Order ID: "}
              {order?.orderId ? order.orderId : ""}
            </span>
          </div>
          {orderType == "fieldAgentLead" ? (
            <Typography
              className={classes.leadStatus}
              sx={{
                color:
                  agentLeadStatus?.[order?.crmStatus]?.[order?.InternalNote]
                    ?.color,
                background:
                  agentLeadStatus?.[order?.crmStatus]?.[order?.InternalNote]
                    ?.background,
              }}
            >
              {agentLeadStatus?.[order?.crmStatus]?.[order?.InternalNote]?.icon}
              {agentLeadStatus?.[order?.crmStatus]?.[order?.InternalNote]?.text}
            </Typography>
          ) : (
            <span
              className={classes.orderStatus}
              style={{
                color: order?.statusColor ? order.statusColor : "",
                background: order?.statusContainerColor
                  ? order.statusContainerColor
                  : "",
              }}
            >
              <img
                src={order?.statusIcon ? order.statusIcon : ""}
                alt="order status"
                className={classes.orderStatusIcon}
              />
              {order?.statusMessage ? order.statusMessage : ""}
            </span>
          )}
        </div>
        {orderType !== "fieldAgentLead" &&
        order.status.toLowerCase() === "processing" &&
        order.crmStatus.toLowerCase() === "mob_app_unverified" ? (
          <div className={classes.cardFooter}>
            <Typography className={classes.orderMessage}>
              <ErrorOutlineIcon
                fontSize="medium"
                className={classes.infoIcon}
              />
              Our team will call you to confirm the order
            </Typography>
          </div>
        ) : order.status.toLowerCase() === "returned" ||
          order.status.toLowerCase() === "returnconfirmed" ||
          order.status.toLowerCase() === "returnexpected" ||
          order.status.toLowerCase() === "returnacknowledged" ||
          order.status.toLowerCase() === "cancelled" ? (
          ""
        ) : (
          <>
            <div className={classes.cardFooter}>
              {order?.estimatedDeliveryDate ? (
                <>
                  <Typography className={classes.eddLabel}>
                    Estimated Delivery
                    <br />
                    <span className={classes.eddValue}>
                      {format(order.estimatedDeliveryDate, "dd MMM yyyy")}
                    </span>
                  </Typography>
                </>
              ) : (
                <Typography>
                  Estimated Delivery
                  <br />
                  <b>Not Applicable</b>
                </Typography>
              )}
              <Typography className={classes.totalAmountLabel}>
                Total Order Amount
                <br />
                <span className={classes.totalAmountValue}>
                  {AmountUtils.formatCurrency(order?.totalOrderAmount)}
                </span>
              </Typography>
            </div>

            {order?.enteredBy && showPlacedBy && (
              <Box sx={{ padding: 1 }}>
                <Typography sx={{ fontSize: "14px" }}>
                  Order Placed By
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                  {order?.enteredBy}
                </Typography>
              </Box>
            )}

            {isOrderCancellableByFieldAgent() && showCancelOrder ? (
              <Button
                fullWidth={true}
                onClick={handleClickCancelOrder}
                sx={{
                  cursor: "pointer",
                  borderTop: "1px solid #EEEEEE",
                  padding: 1,
                  paddingBottom: 0,
                }}
              >
                Cancel Order
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </Card>
    </ErrorBoundary>
  );
};

export default OrderDetailCard;
