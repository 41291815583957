import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import AddressCard from "./AddressCard";
import AddressService from "../../Services/AddressService";
import { ArrowForwardTwoTone } from "@mui/icons-material";
import AnalyticsService from "../../Services/AnalyticsService";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    position: "sticky",
    top: "0",
    zIndex: 100,
    background: "#FFFFFF",
    paddingTop: "16px",
  },
  closeButton: {
    color: "#999999",
    fontSize: "28px",
    cursor: "pointer",
  },
  content: {
    background: "#F3F3F3",
    paddingBottom: "60px",
    height: "70vh",
    overflowY: "scroll",
  },
  radioFormControl: {
    padding: "0px 12px",
  },
  radioLabel: {
    margin: "0px",
    justifyContent: "space-between",
    textTransform: "uppercase",
    color: "#4F4F4F",
    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      fontWeight: 700,
      paddingLeft: "8px",
    },
  },
  stickyFooter: {
    position: "sticky",
    bottom: "0",
    width: "100%",
    zIndex: 100,
    background: "#FFFFFF",
  },
  continueButton: {
    fontWeight: 700,
    padding: "10px 15px",
    borderRadius: "0px",
    boxShadow: "none",
  },
}));

const AddressSelect = ({ open, cart, currentTab, onClose, onSubmit }) => {
  const classes = useStyles(); // Apply the makeStyles hook

  const addressCurrent = AddressService.getAddressForCart();
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState(
    addressCurrent.id
  );

  const handleOnClose = () => {
    AnalyticsService.pushEvent("Cancel Icon Clicked", {
      drawerType: "address",
      cartId: cart.cartId,
      cartType: currentTab,
    });
    onClose();
  };

  return (
    <SwipeableDrawer
      anchor={`bottom`}
      open={open}
      PaperProps={{
        style: {
          borderRadius: "24px 24px 0px 0px",
          overflowY: "hidden",
        },
      }}
      onClose={handleOnClose}
      onOpen={() => {}}
      disableDiscovery={true}
      disableSwipeToOpen={true}
    >
      <Box sx={{ height: "80vh" }} role="presentation">
        <Box className={classes.stickyHeader}>
          <Grid
            container
            alignItems={`center`}
            justifyContent={`space-between`}
            sx={{ padding: "0px 20px 16px 20px" }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              {`Select Address`}
            </Typography>
            <CloseIcon
              onClick={handleOnClose}
              className={classes.closeButton}
            />
          </Grid>
          <Divider />
        </Box>

        <Box className={classes.content}>
          <FormControl fullWidth className={classes.radioFormControl}>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              name="radio-buttons-group"
              value={currentlySelectedOption}
            >
              {_.map(
                JSON.parse(AddressService.AddressMap),
                (address, index) => (
                  <FormControlLabel
                    key={`${currentTab}-${index}-address`}
                    labelPlacement="start"
                    value={address.id}
                    label={""}
                    control={
                      <>
                        <AddressCard
                          data={address}
                          isChecked={currentlySelectedOption === address.id}
                          handleOnSelected={() => {
                            AnalyticsService.pushEvent(
                              "Select Address Clicked",
                              {
                                drawerType: "address",
                                cartId: cart.cartId,
                                cartType: currentTab,
                                addressId: address.id,
                              }
                            );
                            setCurrentlySelectedOption(address.id);
                          }}
                          showRadio
                          hideChangeAddress
                        />
                      </>
                    }
                    className={classes.radioLabel}
                  />
                )
              )}
            </RadioGroup>
          </FormControl>
        </Box>

        <Box className={classes.stickyFooter}>
          <Grid
            container
            spacing={0}
            alignItems={`center`}
            justifyContent={`center`}
            textAlign={`right`}
          >
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size={"medium"}
              endIcon={<ArrowForwardTwoTone />}
              onClick={() => {
                AnalyticsService.pushEvent("Proceed Button Clicked", {
                  drawerType: "address",
                  cartId: cart.cartId,
                  cartType: currentTab,
                  addressId: currentlySelectedOption,
                });
                AddressService.setAddressForCart(currentlySelectedOption);
                onSubmit(currentlySelectedOption);
              }}
              className={classes.continueButton}
            >
              Continue
            </Button>
          </Grid>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default AddressSelect;
