import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import format from "date-fns/format";
import AmountUtils from "../../Utils/AmountUtils";

const useStyles = makeStyles({
  title: {
    color: "black",
    fontSize: "14px",
    fontWeight: "700",
    margin: "10px 16px",
  },
  dataContainerParent: {
    borderRadius: "16px",
    margin: "10px 14px 20px",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "4px 4px 16px #1C1C1C1A",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "10px 12px",
    alignSelf: "start",
  },

  duration: {
    color: "#4F4F4F",
    fontSize: "12px",
    fontWeight: "700",
  },
  price: {
    fontSize: "18px",
    fontWeight: "700",
  },
  order: {
    color: "#4F4F4F",
    fontSize: "12px",
    weight: "400",
  },
});

export default function SalesAndOrderCard({ data, containerType }) {
  const classes = useStyles();
  const weekData = data.weekData;
  const monthData = data.monthData;

  return (
    <Box>
      <Box className={classes.dataContainerParent}>
        <Box className={classes.dataContainer}>
          <Typography className={classes.duration}>
            Week -{" "}
            {`${format(weekData.fromDuration, "dd MMM")} to ${format(
              weekData.toDuration,
              "dd MMM"
            )}`}
          </Typography>
          <Typography
            sx={{
              color: containerType === "delivered" ? "#00733E" : "#9D2123",
            }}
            className={classes.price}
          >
            ₹{AmountUtils.format(weekData.amount)}
          </Typography>
          <Typography className={classes.duration}>
            {weekData.totalOrders} Orders
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box className={classes.dataContainer}>
          <Typography className={classes.duration}>
            Month -{" "}
            {`${format(monthData.fromDuration, "dd MMM")} to ${format(
              monthData.toDuration,
              "dd MMM"
            )}`}
          </Typography>
          <Typography
            sx={{
              color: containerType === "delivered" ? "#00733E" : "#9D2123",
            }}
            className={classes.price}
          >
            ₹{AmountUtils.format(monthData.amount)}
          </Typography>
          <Typography className={classes.duration}>
            {monthData.totalOrders} Orders
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
