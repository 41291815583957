import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import _ from "lodash";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import CartService from "../../Services/CartService";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  CardContent,
  Alert,
  Snackbar,
  Box,
  Skeleton,
  ButtonBase,
} from "@mui/material";
import TicketService from "../../Services/TicketService";
import OrderService from "../../Services/OrderService";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import AnalyticsService from "../../Services/AnalyticsService";
import ConfirmationModal from "../Common/ConfirmationModal";
import ReviewProductCart from "./ReviewProductCart";
import AndroidService from "../../Services/AndroidService";
import CommonConstantValues from "../../Services/CommonConstantValues";
import saathiReturnsConstValues from "../../Services/SaathiReturnsConst";
import QuantityChangeToast from "../Product/QuantityChangeToast";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "./style.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

function ReviewReturnProducts() {
  let navigate = useNavigate();
  const [editedProductsCards, setEditedProductsCards] = useState([]);
  const [hasClickedEdit, setHasClickedEdit] = useState(false);
  const [activeSkuCode, setActiveSkuCode] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedReason, setSelectedReason] = useState("");
  const [returnReasons, setReturnReasons] = useState([]);
  const [files, setFiles] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState("");
  const [loading, setLoading] = useState(CommonConstantValues.NOT_STARTED);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [returnIssueId, setReturnIssueId] = useState("");
  const [showAddAttachmentButton, setShowAddAttachmentButton] = useState(true);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [toBeRemovedSkuCode, setToBeRemovedSkuCode] = useState("");
  const [showStorageAlertMessage, setShowStorageAlertMessage] = useState(false);
  const [creatingReturnOrder, setCreatingReturnOrder] = useState(
    CommonConstantValues.NOT_STARTED
  );
  const [fileSizeErrorMessage, setFileSizeErrorMessage] = useState("");
  const [showQuantityChangeToast, setShowQuantityChangeToast] = useState(false);
  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("markAsDoneButtonClicked")) {
      navigate("/orders/return", { replace: true });
    }
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (appVersionCode && parseInt(appVersionCode) < 20) {
          setShowAddAttachmentButton(false);
        }
      }
    }
    setLoading(CommonConstantValues.STARTED);
    const { axiosRequest, abortController } =
      CartService.getReturnProductsCart();
    axiosRequest
      .then(({ data }) => {
        let products = data.responseData.products;

        let productsArray = [];
        _.forEach(products, (item) => {
          productsArray.push({
            productName: item?.productName,
            skuCode: item?.skuCode,
            quantity: item?.quantity,
            remarks: item?.remarks || "",
            productImage: item?.productImage,
            minimumOrderQuantity: item?.minimumOrderQuantity,
            returnableAmount: item?.returnableAmount,
          });
        });
        let totalQuantityCount = 0;
        _.forEach(productsArray, (items) => {
          totalQuantityCount += Number(items?.quantity);
        });
        setTotalQuantity(totalQuantityCount);
        setEditedProductsCards(productsArray);

        const { axiosRequest } = TicketService.getAllReturnReasons();
        axiosRequest
          .then(({ data }) => {
            setLoading(CommonConstantValues.DONE);
            setReturnIssueId(
              data?.responseData?.returnOrderReasons[0]?.issueId || ""
            );
            setReturnReasons(
              data?.responseData?.returnOrderReasons[0]?.reasons || []
            );
          })
          .catch(() => {
            setLoading(CommonConstantValues.DONE);
          });
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading(CommonConstantValues.DONE);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleRemoveProductFromCart = (event, skuCode) => {
    setShowModal(true);
    setToBeRemovedSkuCode(skuCode);
  };

  const handleClickedOnEdit = (productDetail) => {
    const editCartEventData = {
      page: `/orders/return/review-product`,
      skuCode: productDetail?.skuCode,
      reason: productDetail?.remarks,
      quantity: productDetail?.quantity,
    };
    AnalyticsService.pushEvent(
      `Return Edit Product Button Clicked`,
      editCartEventData
    );

    setSelectedReason("");
    setQuantity(productDetail?.minimumOrderQuantity);
    if (activeSkuCode === productDetail?.skuCode) {
      setHasClickedEdit(!hasClickedEdit);
    } else {
      setHasClickedEdit(true);
    }
    const updatedMinimumOrderQuantityValue =
      productDetail?.minimumOrderQuantity === 0
        ? 1
        : productDetail?.minimumOrderQuantity;
    setMinimumOrderQuantity(updatedMinimumOrderQuantityValue);
    setQuantity(productDetail?.minimumOrderQuantity);
    setActiveSkuCode(productDetail?.skuCode);
  };

  const handleAdd = (minimumOrderQuantity) => {
    const updatedMinimumOrderQuantityValue =
      minimumOrderQuantity === 0 ? 1 : minimumOrderQuantity;

    const updatedQuantity = Number(quantity) + updatedMinimumOrderQuantityValue;
    const remainder = updatedQuantity % updatedMinimumOrderQuantityValue;

    if (
      Number(quantity) < saathiReturnsConstValues?.maxReturnQuantity &&
      Number(quantity) >= updatedMinimumOrderQuantityValue &&
      Number(quantity) % updatedMinimumOrderQuantityValue === 0
    ) {
      setQuantity(updatedQuantity);
    } else if (Number(quantity) < updatedMinimumOrderQuantityValue) {
      setQuantity(updatedMinimumOrderQuantityValue);
    } else {
      const adjustedQuantity =
        updatedQuantity + (updatedMinimumOrderQuantityValue - remainder);
      if (adjustedQuantity <= saathiReturnsConstValues?.maxReturnQuantity) {
        setQuantity(adjustedQuantity);
      }
    }
  };

  const handleChange = (event, minimumOrderQuantity) => {
    const updatedMinimumOrderQuantityValue =
      minimumOrderQuantity === 0 ? 1 : minimumOrderQuantity;

    const inputQuantity = Number(event?.target?.value);
    const remainder = inputQuantity % updatedMinimumOrderQuantityValue;
    let updatedQuantity;

    if (remainder === 0) {
      updatedQuantity = inputQuantity;
    } else {
      updatedQuantity =
        inputQuantity + (updatedMinimumOrderQuantityValue - remainder);
    }
    if (updatedQuantity !== inputQuantity) {
      setShowQuantityChangeToast(true);
    }
    setQuantity(updatedQuantity);
  };

  const handleRemove = (minimumOrderQuantity) => {
    const updatedMinimumOrderQuantityValue =
      minimumOrderQuantity === 0 ? 1 : minimumOrderQuantity;

    const updatedQuantity = Number(quantity) - updatedMinimumOrderQuantityValue;
    const remainder = updatedQuantity % updatedMinimumOrderQuantityValue;

    if (
      Number(quantity) > updatedMinimumOrderQuantityValue &&
      Number(quantity) >= updatedMinimumOrderQuantityValue &&
      Number(quantity) % updatedMinimumOrderQuantityValue === 0
    ) {
      setQuantity(updatedQuantity);
    } else if (Number(quantity) <= updatedMinimumOrderQuantityValue) {
      setQuantity(updatedMinimumOrderQuantityValue);
    } else {
      const adjustedQuantity = updatedQuantity - remainder;
      if (adjustedQuantity >= updatedMinimumOrderQuantityValue) {
        setQuantity(adjustedQuantity);
      }
    }
  };

  const handleChangeReturnReason = (event) => {
    setSelectedReason(event?.target?.value);
  };

  const updateCart = (productDetails) => {
    const updateButtonClickEvent = {
      page: `/orders/return/review-product`,
      skuCode: productDetails?.skuCode,
      reason: productDetails?.remarks,
      quantity: productDetails?.quantity,
    };
    AnalyticsService.pushEvent(
      `Return Edit Product Update Button Clicked`,
      updateButtonClickEvent
    );

    _.forEach(editedProductsCards, (item) => {
      if (item.skuCode === productDetails?.skuCode) {
        item.quantity = quantity;
        if (selectedReason) item.remarks = selectedReason;
      }
    });
    setCreatingReturnOrder(CommonConstantValues.STARTED);

    const { axiosRequest } =
      CartService.updateReturnProductCart(editedProductsCards);
    axiosRequest
      .then((response) => {
        const products = response?.data?.responseData?.products;
        let totalCount = 0;
        const productsObj = {};
        _.forEach(products, (item) => (productsObj[item?.skuCode] = item));
        const newEditedProductsCards = _.map(editedProductsCards, (item) => {
          totalCount += item?.quantity;
          return {
            ...item,
            returnableAmount: productsObj[item?.skuCode]?.returnableAmount,
          };
        });
        setTotalQuantity(totalCount);
        setEditedProductsCards(newEditedProductsCards);
        setHasClickedEdit(!hasClickedEdit);
        setActiveSkuCode("");
        setSelectedReason("");
        setQuantity(1);
        setCreatingReturnOrder(CommonConstantValues.DONE);
      })
      .catch(() => {
        setCreatingReturnOrder(CommonConstantValues.DONE);
        setShowStorageAlertMessage(true);
      });
  };

  const onclickSubmit = () => {
    let productNamesList = "";
    let skuCodeList = "";

    _.map(editedProductsCards, (items) => {
      productNamesList += `${items?.productName}, `;
      skuCodeList += `${items?.skuCode}, `;
    });

    const handleSubmitEvent = {
      page: `/orders/return/review-product`,
      productName: productNamesList,
      products: skuCodeList,
    };

    AnalyticsService.pushEvent(
      `Return Submit Button Clicked`,
      handleSubmitEvent
    );
    setCreatingReturnOrder(CommonConstantValues.STARTED);
    setLoading(CommonConstantValues.STARTED);
    const { axiosRequest } =
      CartService.updateReturnProductCart(editedProductsCards);
    axiosRequest
      .then(({ data }) => {
        const formData = new FormData();
        const fieldAgentPhoneNumber = localStorage.getItem(
          "fieldAgentMobileNumber"
        );
        formData.append("farmerId", Number(localStorage.getItem(`farmerId`)));
        formData.append("cartId", data?.responseData?.cartId);
        _.forEach(files, (fileItem) => {
          formData.append("file", fileItem.file, fileItem.file.name);
        });

        const skuTableData = _.map(
          editedProductsCards,
          ({ skuCode, productName, quantity, remarks }) => [
            skuCode,
            productName,
            quantity,
            remarks,
          ]
        );
        skuTableData.unshift(["SKU", "Product", "Qty", "Reason"]);
        const remarksTable =
          (descriptionValue || "") + makeTableHTML(skuTableData);

        formData.append("ticketRemarks", remarksTable);
        formData.append("ticketIssueId", returnIssueId);
        if (fieldAgentPhoneNumber) {
          formData.append("agentPhoneNo", fieldAgentPhoneNumber);
        }

        const { axiosRequest } = OrderService.createReturnOrder({
          payload: formData,
        });
        axiosRequest
          .then((response) => {
            localStorage.setItem("createdReturnOrder", true);
            setLoading(CommonConstantValues.DONE);
            setCreatingReturnOrder(CommonConstantValues.DONE);
            const returnOrderId = response?.data?.responseData?.orderId;
            navigate(`/orders/return/${returnOrderId}/success-return`, {
              replace: true,
            });
          })
          .catch((error) => {
            if (error?.response?.status == 413) {
              setFileSizeErrorMessage("File size is too large");
            }
            setShowStorageAlertMessage(true);
            setLoading(CommonConstantValues.DONE);
            setCreatingReturnOrder(CommonConstantValues.DONE);
          });
      })
      .catch(() => {
        setShowStorageAlertMessage(true);
        setLoading(CommonConstantValues.DONE);
        setCreatingReturnOrder(CommonConstantValues.DONE);
      });
  };

  const makeTableHTML = (tableDataArray) => {
    let remarksTable = "<br><br><table border=1>";
    for (let i = 0; i < tableDataArray.length; i++) {
      remarksTable += "<tr>";
      for (var j = 0; j < tableDataArray[i].length; j++) {
        remarksTable += "<td>&nbsp;" + tableDataArray[i][j] + "&nbsp;</td>";
      }
      remarksTable += "</tr>";
    }
    remarksTable += "</table><br>";
    return remarksTable;
  };

  const addMoreProducts = () => {
    const clickedAddMoreProductEventData = {
      page: `/orders/return/review-product`,
    };
    AnalyticsService.pushEvent(
      `Return Add More Product Clicked`,
      clickedAddMoreProductEventData
    );

    navigate("/orders/return/select-product", { replace: true });
  };

  const handleFilePondUpdate = (fileItems) => {
    const isSubmitDisabled = _.some(
      fileItems,
      (item) =>
        item.fileSize > CommonConstantValues.MAX_FILE_SIZE_FOR_RETURN_ATTACHMENT
    );

    if (isSubmitDisabled) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }

    setFiles(fileItems);
  };

  const handleRemoveFile = () => {
    const removedAttachmentEventData = {
      page: `/orders/return/review-product`,
    };
    AnalyticsService.pushEvent(
      `Remove Attachment Clicked`,
      removedAttachmentEventData
    );
  };

  const onConfirmRemoveProductFromCart = () => {
    let updatedCard = _.filter(
      editedProductsCards,
      (items) => items.skuCode !== toBeRemovedSkuCode
    );

    let totalQuantityCount = 0;
    _.forEach(updatedCard, (items) => {
      totalQuantityCount += Number(items?.quantity);
    });

    const { axiosRequest } = CartService.updateReturnProductCart(updatedCard);
    axiosRequest
      .then(() => {
        setTotalQuantity(totalQuantityCount);

        setEditedProductsCards(updatedCard);
        setShowModal(false);
      })
      .catch(() => {
        setShowStorageAlertMessage(true);
      });
  };

  const onCancelRemoveProductFromCart = () => {
    setShowModal(false);
  };

  const handleClickAddAttachmentButton = () => {
    const clickedAddMoreProductEventData = {
      page: `/orders/return/review-product`,
    };
    AnalyticsService.pushEvent(
      `Add Attachment Clicked`,
      clickedAddMoreProductEventData
    );
  };

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Review Products`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
        />
      </ErrorBoundary>

      <QuantityChangeToast
        show={showQuantityChangeToast}
        minimumOrderQuantity={minimumOrderQuantity}
        setShow={setShowQuantityChangeToast}
      />
      <ErrorBoundary>
        {loading === CommonConstantValues.STARTED &&
        editedProductsCards?.length == 0 ? (
          <CardContent>
            <Skeleton variant="rectangular" height={120} />
          </CardContent>
        ) : (
          <></>
        )}
        <BlockUi
          tag="div"
          blocking={creatingReturnOrder === CommonConstantValues.STARTED}
          message={`Please wait`}
          keepInView
        >
          <Snackbar
            open={showStorageAlertMessage}
            autoHideDuration={5000}
            onClose={() => setShowStorageAlertMessage(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            sx={{
              marginBottom: "55px",
            }}
          >
            <Alert
              icon={false}
              onClose={() => setShowStorageAlertMessage(false)}
              sx={{
                background: "#f26b6b",
                color: "#ffffff",
                width: "328px",
              }}
            >
              {fileSizeErrorMessage
                ? fileSizeErrorMessage
                : " Something went wrong!"}
            </Alert>
          </Snackbar>

          <CardContent sx={{ color: "#333333" }}>
            {loading === CommonConstantValues.DONE &&
            editedProductsCards?.length ? (
              <ReviewProductCart
                editedProductsCards={editedProductsCards}
                hasClickedEdit={hasClickedEdit}
                activeSkuCode={activeSkuCode}
                handleClickedOnEdit={handleClickedOnEdit}
                handleRemoveProductFromCart={handleRemoveProductFromCart}
                quantity={quantity}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                handleChange={handleChange}
                setQuantity={setQuantity}
                minimumOrderQuantity={
                  saathiReturnsConstValues?.minimumOrderQuantity
                }
                selectedReason={selectedReason}
                handleChangeReturnReason={handleChangeReturnReason}
                returnReasons={returnReasons}
                updateCart={updateCart}
              />
            ) : (
              <></>
            )}

            <Button
              variant={"outlined"}
              sx={{
                background: "#FFFFFF",
                width: "100%",
                borderRadius: "10px",
                marginTop: "25px",
              }}
              onClick={addMoreProducts}
            >
              Add More Products <ArrowForwardTwoToneIcon />
            </Button>

            <Box
              sx={{
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  margin: "6px 9px",
                }}
              >
                Description
              </Typography>
              <textarea
                rows="10"
                name="description"
                maxLength="300"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  border: "1px solid #EEEEEE",
                  fontFamily: "Roboto",
                }}
                onChange={(event) => setDescriptionValue(event.target.value)}
                value={descriptionValue}
              />

              {showAddAttachmentButton ? (
                <>
                  <Typography
                    sx={{
                      marginLeft: "100px",
                      marginTop: "21px",
                      fontSize: "12px",
                      marginBottom: "-13px",
                    }}
                  >
                    Upto 10MB file size supported
                  </Typography>
                  {files.length >=
                  CommonConstantValues.RETURNS_ATTACHMENTS_MAX_FILES_LIMIT ? (
                    <Button
                      fullWidth
                      variant={"outlined"}
                      disabled={true}
                      sx={{
                        background: "white",
                        marginTop: "30px",
                        borderRadius: "10px",
                        height: "40px",
                        textTransform: "none",
                      }}
                    >
                      Only{" "}
                      {CommonConstantValues.RETURNS_ATTACHMENTS_MAX_FILES_LIMIT}{" "}
                      attachments are supported
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Box
                    sx={{
                      background: "#FFFFFF",
                      color: "#9D2123",
                      borderRadius: "10px",
                      marginTop: "17px",
                    }}
                  >
                    <FilePond
                      files={files}
                      allowFileSizeValidation={true}
                      maxFileSize={
                        CommonConstantValues.MAX_FILE_SIZE_FOR_RETURN_ATTACHMENT
                      } // 10MB
                      labelMaxFileSizeExceeded={"File is too large"}
                      allowReorder={false}
                      allowMultiple={true}
                      onupdatefiles={handleFilePondUpdate}
                      onremovefile={handleRemoveFile}
                      onaddfile={handleClickAddAttachmentButton}
                      labelMaxFileSize="Max file size is 10 MB"
                      labelIdle={`<b>Add Attachment</b>`}
                      maxFiles={
                        CommonConstantValues.RETURNS_ATTACHMENTS_MAX_FILES_LIMIT
                      }
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Box>

            <ConfirmationModal
              isOpen={showModal}
              handleClose={() => setShowModal(false)}
              modalText="Are you sure you want to remove this product from cart ?"
              confirmButtonText="Yes"
              cancelButtonText="No"
              handleClickConfirmButton={onConfirmRemoveProductFromCart}
              handleClickCancelButton={onCancelRemoveProductFromCart}
            />
          </CardContent>

          <Box sx={{ height: "40px" }}></Box>
          <ButtonBase
            sx={{ width: "100%" }}
            disabled={disableSubmitButton || !editedProductsCards.length}
            onClick={() => onclickSubmit()}
          >
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                position: "fixed",
                bottom: "0",
                width: "100%",
                height: "50px",
                background:
                  disableSubmitButton || !editedProductsCards.length
                    ? "#CACACA"
                    : "#9D2123",
                zIndex: "1",
              }}
            >
              <Grid
                item
                xs={2.3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFFFFF",
                  flexDirection: "column",
                  paddingLeft: "10px",
                  marginTop: "-5px",
                  textAlign: "center",
                }}
                variant={"contained"}
                color={"primary"}
              >
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19.07px",
                    fontWeight: "bold",
                  }}
                >
                  {editedProductsCards.length}{" "}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontSize: "12px", lineHeight: "19.07px" }}
                >
                  Product
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFFFFF",
                  flexDirection: "column",
                  marginTop: "-5px",
                  textAlign: "center",
                }}
                variant={"contained"}
                color={"primary"}
              >
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19.07px",
                    fontWeight: "bold",
                  }}
                >
                  {totalQuantity}{" "}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontSize: "12px", lineHeight: "19.07px" }}
                >
                  Quantity
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  padding: "10px",
                }}
              >
                <Button
                  style={{
                    height: "100%",
                    background:
                      disableSubmitButton || !editedProductsCards.length
                        ? "#CACACA"
                        : "#9D2123",
                    color: "#FFFFFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  disabled={disableSubmitButton || !editedProductsCards.length}
                >
                  <Typography
                    sx={{
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    SUBMIT
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </ButtonBase>
        </BlockUi>
      </ErrorBoundary>
    </>
  );
}

export default ReviewReturnProducts;
