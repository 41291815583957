import _ from "lodash";
import { Box, Typography } from "@mui/material";
import AnalyticsService from "../../Services/AnalyticsService";

export default function SuggestionResults({
  suggestionResult,
  keyword,
  handleSuggestionClick,
  setShowSuggestions,
  page,
  searchType,
}) {
  if (keyword?.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: "rgba(79, 79, 79, 0.7)",
        border: "0px solid red",
        width: "100%",
        height: "800px",
        position: "absolute",
        zIndex: 20,
      }}
      onClick={() => setShowSuggestions(false)}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          boxShadow: "0px 12px 16px 0px rgba(28, 28, 28, 0.20)",
          padding: "8px 16px 16px 16px",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
      >
        {_.map(suggestionResult?.suggestions, (suggestion, index) => {
          return (
            <Box
              key={index}
              sx={{
                padding: "8px 6px",
                display: "flex",
                gap: "8px",
                cursor: "pointer",
                position: "relative",
                zIndex: 30,
              }}
              onClick={() => {
                AnalyticsService.pushEvent("Suggestion Result Clicked", {
                  queryText: keyword,
                  suggestionText: suggestion,
                  position: index + 1,
                  resultHasQueryText:
                    suggestionResult?.isSearchKeywordPresentInResult,
                  page: page || "",
                  searchType: searchType || "textSearch",
                });
                const querySearchType = "Suggestion Search";
                handleSuggestionClick(suggestion, querySearchType);
              }}
            >
              <img height={20} width={20} src={"/icons/search.svg"} alt={``} />
              <Typography
                variant="subtitle2"
                component="p"
                sx={{ color: "#333" }}
              >
                {suggestion}
              </Typography>
            </Box>
          );
        })}
        {!suggestionResult?.isSearchKeywordPresentInResult && (
          <Box
            sx={{
              padding: "8px 6px",
              display: "flex",
              gap: "8px",
            }}
            onClick={() => {
              AnalyticsService.pushEvent("Suggestion Result Clicked", {
                queryText: keyword,
                suggestionText: keyword,
                position: suggestionResult?.suggestions?.length + 1,
                resultHasQueryText:
                  suggestionResult?.isSearchKeywordPresentInResult,
                page: page || "",
                searchType: searchType || "textSearch",
              });
              const querySearchType = "Default Suggestion Search";
              handleSuggestionClick(keyword, querySearchType);
            }}
          >
            <img height={20} width={20} src={"/icons/search.svg"} alt={``} />
            <Typography
              variant="subtitle2"
              component="p"
              sx={{ color: "#333" }}
            >
              {`Search for: ${keyword}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
