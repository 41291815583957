import React from "react";
import IframeContent from "../Common/IframeContent";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  containerIframe: {
    position: "relative",
    overflow: "scroll",
    width: "100%",
    height: "100vh !important",
    paddingTop: "56.25%" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
    // -webkit-overflow-scrolling: -webkit-overflow-scrolling: touch!important;!important;
  },
  /* Then style the iframe to fit in the container div with full height and width */
  responsiveIframe: {
    // position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100vh",
    border: "0px",
    overflow: "auto",
  },
}));

export default function Registration() {
  const classes = useStyles();
  const iframeData = {
    src: "https://docs.google.com/forms/d/e/1FAIpQLSfkIsaydxWw5g5_HvHAV2r0jTlrl82Z7r4WvXJImbZZ6dBhkA/viewform",
    iframeClass: classes.responsiveIframe,
  };
  return (
    // <div className={classes.containerIframe}>
    <IframeContent iframeData={iframeData} />
    // </div>
  );
}
