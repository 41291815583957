import ScrollableBanners from "../Common/ScrollableBanner";
import StaticBanner from "../Common/StaticBanner";
import HorizontalScrollableBanner from "../Common/HorizontalScrollableBanners";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EmptyError from "../EmptyError/EmptyError";

const useStyles = makeStyles((theme) => ({
  textAlign: {
    marginLeft: "20px",
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

const Banner = (props) => {
  const classes = useStyles();

  return (
    <div>
      {props.globalBanners?.length > 0 ? (
        <>
          {props.globalBanners?.map((banner, index) => {
            return (
              <div key={index}>
                <div>
                  {banner?.name ? (
                    <Typography className={classes.textAlign}>
                      {banner?.name}
                    </Typography>
                  ) : (
                    <div></div>
                  )}
                  {banner?.description === "CAROUSEL" &&
                    banner?.bannerType === "SCROLLABLE" && (
                      <ScrollableBanners
                        banners={banner}
                        loadingBanners={props.loadingBanners}
                        page={props?.page}
                        blur={props?.blur}
                      />
                    )}
                  {banner?.description === "HORIZONTAL" &&
                    banner?.bannerType === "SCROLLABLE" && (
                      <HorizontalScrollableBanner
                        banners={banner}
                        loadingBanners={props.loadingBanners}
                        page={props?.page}
                        blur={props?.blur}
                      />
                    )}
                  {banner?.description === "" &&
                    banner?.bannerType === "BANNER" && (
                      <div>
                        <StaticBanner
                          banner={banner}
                          loadingBanners={props.loadingBanners}
                          page={props?.page}
                          blur={props?.blur}
                        />
                      </div>
                    )}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div>
          <EmptyError image="EmptyPage.svg" text="Sorry, No offers found" />
        </div>
      )}
    </div>
  );
};

export default Banner;
