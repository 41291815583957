import { useEffect, useState } from "react";
import OrderService from "../Services/OrderService";
import ProductService from "../Services/ProductService";
import _ from "lodash";

function useRecentlyBoughtProducts(selectedBrands) {
  const [loading, setLoading] = useState("not_started");
  const [products, setProducts] = useState(null);
  const [brands, setBrands] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading("started");

    const { axiosRequest, abortController } = OrderService.getAllOrders();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.orders?.length > 0) {
          let productsFromResponse = response.data.responseData.orders;
          let productData = [];
          let currentDate = new Date();
          const secondDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          );
          productsFromResponse = _.filter(productsFromResponse, (product) => {
            return (
              product.brandName !== "Unassigned Brand" &&
              ProductService.checkIfProductEnabled(product)
            );
          });
          productsFromResponse.map((orderValue, orderIndex) => {
            let orderedDate = new Date(orderValue?.orderCreationDate);

            const oneDay = 24 * 60 * 60 * 1000;
            const firstDate = new Date(
              orderedDate.getFullYear(),
              orderedDate.getMonth(),
              orderedDate.getDate()
            );

            const numberOfDays = Math.round(
              Math.abs((firstDate - secondDate) / oneDay)
            );
            if (
              orderValue?.status !== "PROCESSING" &&
              orderValue?.status !== "CREATED" &&
              orderValue?.status !== "PICKING" &&
              orderValue?.status !== "PICKED" &&
              orderValue?.status !== "PACKED" &&
              orderValue?.status !== "CANCELLED" &&
              numberOfDays < 30
            ) {
              orderValue.products.map((productValue, productIndex) => {
                productData.push(productValue?.productSKU);
              });
            }
          });
          productData = _.uniq(productData);
          if (productData?.length > 1) {
            const { axiosRequest } = ProductService.getProductsListBySKUs(
              productData,
              selectedBrands
            );
            axiosRequest
              .then((response) => {
                if (response?.data?.responseData?.productGists?.length > 0) {
                  let productsFromResponse =
                    response.data.responseData.productGists;
                  if (productsFromResponse?.length > 0) {
                    setProducts(productsFromResponse);
                  } else {
                    setProducts([]);
                  }
                } else {
                  console.log(`no response received in responseData`);
                  setProducts(null);
                }

                if (
                  selectedBrands?.length === 0 &&
                  response?.data?.responseData?.brands?.length > 0
                ) {
                  let brandsFromResponse = response?.data?.responseData?.brands;
                  brandsFromResponse = _.filter(brandsFromResponse, (brand) => {
                    return brand.brandName !== "Unassigned Brand";
                  });
                  setBrands(brandsFromResponse);
                } else {
                  console.log(`no brands received in responseData`);
                }

                setLoading("done");
              })
              .catch((error) => {
                console.log(`error getting products`);
                if (error.data) {
                  setError(error.data);
                  console.log(error.data);
                } else {
                  setError(error);
                  console.log(error);
                }
                setLoading("done");
              });
          } else {
            productData = [];
            setLoading("done");
          }
        } else {
          console.log(`no response received in responseData`);
          setLoading("done");
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting products`);
          if (error.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, [selectedBrands]);

  return { products, brands, loading, error };
}

export default useRecentlyBoughtProducts;
