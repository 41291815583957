import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  ButtonBase,
  Box,
  Chip,
  Skeleton,
} from "@mui/material";

import useFeaturedVideos from "../../Hooks/useFeaturedVideos";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import ChipSelector from "../Common/ChipSelector";
import ErrorBoundary from "../Common/ErrorBoundary";
import LiveSessionContainer from "../Common/LiveSessionContainer";
import TopBar from "../Common/TopBar";
import EmptyError from "../EmptyError/EmptyError";
import { WhatsappShareButton } from "react-share";
import useMessages from "../../Hooks/useMessages";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    width: "100%",
    padding: theme.spacing(1),
  },
  gridContainer: {
    margin: theme.spacing(1.5, 1),
  },
  gridItem: {
    paddingTop: " 0px !important",
    margin: "4px 0px -8px 0px",
  },
  card: {
    borderRadius: theme.spacing(2),
    boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.1)",
    margin: theme.spacing(1, 0.5),
  },
  cardContentContainer: {
    margin: theme.spacing(-1, -1, -1.3, -1),
    paddingBottom: "0px !important",
  },
  contentVideoThumbnailContainer: {
    // height: "155px",
    borderRadius: theme.spacing(2),
  },
  contentVideoThumbnail: {
    objectFit: "cover",
  },
  playButtonBase: {
    top: theme.spacing(-12),
    left: "39%",
    marginBottom: theme.spacing(-4),
  },
  playButton: {
    width: "30px",
    height: "30px",
    opacity: "0.9",
    alignSelf: "center",
  },
  contentVideoTitle: {
    fontWeight: 600,
    fontSize: "14px",
    wordBreak: "break-word",
    marginTop: theme.spacing(0.5),
  },
  tag: {
    backgroundColor: "lightyellow !important",
    color: "#666666",
    fontSize: "11px",
    height: "18px",
    fontWeight: "400",
    boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.10)",
  },
}));

export default function VideoCard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { getMessage } = useMessages();

  const { featuredVideos, liveSession, loading, error } = useFeaturedVideos();

  const [appliedFeaturedVideos, setAppliedFeaturedVideos] = useState(
    featuredVideos?.length ? [...featuredVideos] : []
  );
  const [selectedFilter, setSelectedFilter] = useState("All");

  const isVideoALiveSession =
    featuredVideos?.length > 0 &&
    liveSession?.link &&
    liveSession?.redirectionLink;

  let typesOfVideos =
    featuredVideos && featuredVideos.length > 0
      ? [...new Set(featuredVideos.map((item) => item.typeOfVid))]
      : [];
  typesOfVideos = ["All", ...typesOfVideos];

  useEffect(() => {
    AndroidService.unsetPullToRefresh();
  }, []);

  useEffect(() => {
    setAppliedFeaturedVideos(
      featuredVideos && featuredVideos.length ? [...featuredVideos] : []
    );
    setSelectedFilter("ALL");

    let featuredVideoPageViewedData = {
      farmerId: localStorage.getItem("farmerId"),
      page: "/learning-videos",
      source: localStorage.getItem("source"),
    };

    AnalyticsService.pushEvent(
      `Learning Videos Page Viewed`,
      featuredVideoPageViewedData
    );
  }, []);

  useEffect(() => {
    if (featuredVideos && featuredVideos?.length > 0) {
      setAppliedFeaturedVideos(
        featuredVideos && featuredVideos.length ? [...featuredVideos] : []
      );
    }
    setSelectedFilter("All");
  }, [featuredVideos]);

  const handleChangeFilter = (value) => {
    let list =
      value !== "All"
        ? featuredVideos.filter((video) => video.typeOfVid === value)
        : [...featuredVideos];

    setSelectedFilter(value);

    setAppliedFeaturedVideos(list);

    AnalyticsService.pushEvent(`Learning Videos Filter Clicked`, {
      page: "/learning-videos",
      filterName: `${value}`,
      filterApplied: `${value}`,
    });
  };

  const handleVideoClick = (video) => {
    let videoID = video?.link
      .split("/")
      [video?.link.split("/").length - 1].replace("watch?v=", "");

    const featuredVideoClickedData = {
      farmerId: localStorage.getItem("farmerId"),
      page: "/learning-videos",
      source: localStorage.getItem("source"),
      videoUrl: video?.link,
    };

    AnalyticsService.pushEvent(
      `Feature Video Clicked`,
      featuredVideoClickedData
    );

    navigate({
      pathname: `/featured-videos`,
      videos: featuredVideos,
      search: `youtubeId=${videoID}`,
    });
  };

  const handleWhatsAppShareClickEvent = (event, videoUrl) => {
    event.stopPropagation();
    AnalyticsService.pushEvent("Button Clicked", {
      type: "WhatsApp Share",
      videoUrl: videoUrl,
    });
  };

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar title={"Videos"} backNavigation={true} />
      </ErrorBoundary>

      <ErrorBoundary message={""}>
        {featuredVideos && featuredVideos.length > 0 && (
          <Grid
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            className={classes.gridContainer}
          >
            <Box
              sx={{
                width: "100%",
                overflowX: "auto",
                whiteSpace: "nowrap",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {typesOfVideos &&
                typesOfVideos.length > 0 &&
                _.map(typesOfVideos, (video, index) => {
                  return (
                    <ChipSelector
                      style={{ textTransform: "capitalize" }}
                      key={index}
                      isFilterSelected={selectedFilter === video}
                      handleChangeSelectedValue={() =>
                        handleChangeFilter(video)
                      }
                      keyName={video}
                    />
                  );
                })}
            </Box>
          </Grid>
        )}
      </ErrorBoundary>

      <ErrorBoundary message={``}>
        {loading === "done" &&
          (!error || !error?.data) &&
          isVideoALiveSession !== null && (
            <LiveSessionContainer
              liveSession={liveSession}
              page={"learning-videos"}
            />
          )}
      </ErrorBoundary>

      <ErrorBoundary message={``}>
        <Box
          className={classes.container}
          sx={{
            height: isVideoALiveSession ? "60vh" : "",
          }}
        >
          {loading === "started" && !error && (
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12}>
                <Skeleton variant={`rectangular`} height={148} width={"100%"} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant={`rectangular`} height={146} width={"100%"} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant={`rectangular`} height={146} width={"100%"} />
              </Grid>
            </Grid>
          )}

          {loading === "done" &&
            (!error || !error?.data) &&
            appliedFeaturedVideos &&
            appliedFeaturedVideos.length > 0 && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                {_.map(appliedFeaturedVideos, (video, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={video.videoCode}
                      className={classes.gridItem}
                    >
                      {video && video.link && (
                        <Card
                          className={classes.card}
                          variant={"outlined"}
                          onClick={() => handleVideoClick(video)}
                        >
                          <CardContent className={classes.cardContentContainer}>
                            <Grid
                              container
                              direction="row"
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              spacing={1}
                            >
                              <Grid item xs={6}>
                                <div
                                  className={
                                    classes.contentVideoThumbnailContainer
                                  }
                                >
                                  <video
                                    autoPlay="autoplay"
                                    loop={true}
                                    muted
                                    defaultmuted="true"
                                    playsInline={true}
                                    poster={`https://i.ytimg.com/vi/${video?.link
                                      ?.split("/")
                                      [
                                        video?.link?.split("/").length - 1
                                      ].replace("watch?v=", "")}/mqdefault.jpg`}
                                    style={{
                                      width: "100%",
                                      height: "115px",
                                    }}
                                  >
                                    <source
                                      src={`https://static.agrostar.in/videos/previews/${video?.link
                                        ?.split("/")
                                        [
                                          video?.link?.split("/").length - 1
                                        ].replace(
                                          "watch?v=",
                                          ""
                                        )}.MP4.5s.mp4?tr=so-5,du-5`}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                                {video?.link ? (
                                  <ButtonBase
                                    centerRipple={true}
                                    focusRipple={true}
                                    aria-label="play"
                                    className={classes.playButtonBase}
                                    size="medium"
                                  >
                                    <img
                                      src="/icons/playThumbnail.svg"
                                      alt="play"
                                      className={classes.playButton}
                                    />
                                  </ButtonBase>
                                ) : (
                                  ""
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                <Grid
                                  container
                                  direction="column"
                                  justifyContent={"space-between"}
                                  alignItems={"normal"}
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent={"space-between"}
                                      alignItems={"flex-start"}
                                    >
                                      <Grid item>
                                        <Chip
                                          label={video?.typeOfVid}
                                          className={classes.tag}
                                          clickable={false}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <WhatsappShareButton
                                          url={`.`}
                                          title={`${getMessage(
                                            "video.title"
                                          )}${video?.videoTitle}\r\n${video?.link}`}
                                          onClick={(event) =>
                                            handleWhatsAppShareClickEvent(
                                              event,
                                              video?.link
                                            )
                                          }
                                          style={{ marginTop: "3px" }}
                                        >
                                          <img
                                            src={`/icons/whatsapp.svg`}
                                            alt=""
                                            height={"18px"}
                                            width={"18px"}
                                          />
                                        </WhatsappShareButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.contentVideoTitle}
                                    >
                                      {video?.videoTitle}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            )}

          {loading === "done" && (error || error?.data) && (
            <EmptyError image="EmptyPage.svg" text="Sorry! No Videos Found" />
          )}
        </Box>
      </ErrorBoundary>
    </>
  );
}
