import { useEffect, useState } from "react";
import { Link, useLocation, useNavigationType } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import makeStyles from "@mui/styles/makeStyles";
import {
  bottomTabs,
  bottomTabsBlurredExceptHisaab,
} from "../../Services/bottomTabs";
import AnalyticsService from "../../Services/AnalyticsService";
import "../../App.css";
import LeadService from "../../Services/LeadService";
import { Alert, Snackbar, Typography } from "@mui/material";
import PartnerService from "../../Services/PartnerService";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import ExpandMoreDrawer from "./ExpandMoreDrawer";

const useStyles = makeStyles((theme) => ({
  alignActiveBottomTab: {
    marginBottom: "-10px",
    marginTop: "-22px",
  },
  alignInActiveBottomTab: {
    marginBottom: "-1px",
    marginTop: "-13px",
  },
  bottomBarWithoutShadow: {
    position: "fixed",
    overflow: "hidden",
    bottom: "0",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "600px",
    },
    zIndex: "2",
  },
  bottomBarWithShadow: {
    position: "fixed",
    overflow: "hidden",
    bottom: "0",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "600px",
    },
    zIndex: "2",
    boxShadow: "0px -4px 10px rgb(129 129 129 / 30%)",
  },
  bottomActiveClass: {
    display: "block !important",
  },
  bottomInActiveClass: {
    display: "none !important",
  },
  alignTabs: {
    height: "57px",
    minWidth: "0px !important",
  },
  alignTabBottom: {
    minWidth: "0px !important",
    bottom: "13px",
    paddingLeft: "10px",
    background: "transparent",
    backgroundImage: "url(/icons/bottomBarCenterTabCurve.svg)",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderTop: "0px !important",
  },
  alignTab: {
    height: "57px",
    minWidth: "0px !important",
    padding: "9px 12px",
  },
}));

const BottomBar = () => {
  const appClasses = useStyles();
  const [locationKeys, setLocationKeys] = useState([]);
  const location = useLocation();
  const navigationType = useNavigationType();
  const [open, setOpen] = useState(false);

  let tabElementContent = true;

  const [bottomNavShow, setBottomNavShow] = useState(tabElementContent);
  const [tabValue, setTabValue] = useState("/");
  const [leadsCount, setLeadsCount] = useState(0);
  const [bottomTabData, setBottomTabData] = useState(null);
  const [shadow, setShadow] = useState(false);
  const [partnerStatus, setPartnerStatus] = useState(null);
  const [partnerSubStatus, setPartnerSubStatus] = useState(null);
  const [openPartnerBlockedDialog, setOpenPartnerBlockedDialog] =
    useState(false);

  useEffect(() => {
    if (
      (window.location.pathname === "/nearby-farmers" ||
        window.location.pathname === "/" ||
        window.location.pathname === "/dashboard") &&
      bottomNavShow &&
      localStorage.getItem("otpVerified") &&
      localStorage.getItem("farmerId")
    ) {
      const { axiosRequest, abortController } = LeadService.getLeadCount();
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            const leadsData = response?.data?.responseData;
            setLeadsCount(
              leadsData
                ? leadsData?.activeLead +
                    leadsData?.attemptedLead?.attemptedLead +
                    leadsData?.attemptedLead?.notConnectedLead
                : 0
            );
          } else {
            console.log("No Data Found");
          }
        })
        .catch((error) => {
          console.error("Failed to Get Leads Count: ", error);
        });
      return () => abortController.abort();
    }
  }, [bottomNavShow]);

  const setBottomTab = () => {
    const partnerStatus = localStorage.getItem("partnerStatus") || null;
    if (partnerStatus === "INACTIVE") {
      setBottomTabData(bottomTabsBlurredExceptHisaab);
      setShadow(false);
    }
    if (partnerStatus === "ACTIVE" || partnerStatus === "UNVERIFIED") {
      setBottomTabData(bottomTabs);
      setShadow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("storage", storageEventHandler, false);
  }, []);

  const storageEventHandler = () => {
    setPartnerStatus(localStorage.getItem("partnerStatus") || null);
    setPartnerSubStatus(localStorage.getItem("partnerSubStatus") || null);
  };

  useEffect(() => {
    tabElementContent = bottomTabs.some(
      (tabElement) =>
        tabElement.navigationLink === window.location.pathname ||
        window.location.pathname === "/hisaab/pay-by-product-group" ||
        window.location.pathname === "/hisaab/transactions" ||
        window.location.pathname === "/hisaab/orders" ||
        window.location.pathname === "/"
    );
    setBottomNavShow(tabElementContent);

    if (tabElementContent) {
      setTabValue(window.location.pathname);
    }
    setBottomTab();
    setHisaabTab();
    if (!window.history.visitedLength) {
      window.history.visitedLength = window.history.length;
      window.history.actualLength = window.history.length;
    }
  }, [window.location.pathname, partnerStatus, partnerSubStatus]);

  const handleTabClickEvent = (tabName, navigationLink) => {
    if (partnerStatus !== "INACTIVE") {
      let bottomTabName = tabName.toLowerCase();
      let bottomTabEventsData = {
        name: "Bottom Tab Clicked",
        body: {
          tabName: bottomTabName,
          page: window.location.pathname,
        },
      };

      AnalyticsService.pushEvent(
        bottomTabEventsData.name,
        bottomTabEventsData.body
      );
      setTabValue(navigationLink);
    } else if (tabName !== "Hisaab") {
      setOpenPartnerBlockedDialog(true);
    }
  };

  useEffect(() => {
    if (navigationType === "PUSH") {
      setLocationKeys([location.key]);
      window.history.visitedLength = window.history.visitedLength + 1;
      setTabValue(window.location.pathname);
      setHisaabTab();
    }

    if (navigationType === "POP") {
      window.history.visitedLength = window.history.visitedLength - 1;
      if (locationKeys[1] === location.key) {
        setLocationKeys(([_, ...keys]) => keys);
        setTabValue(window.location.pathname);
        setHisaabTab();
      } else {
        setLocationKeys((keys) => [location.key, ...keys]);
        setTabValue(window.location.pathname);
        setHisaabTab();
      }
    }
  }, [location]);

  let setHisaabTab = () => {
    if (
      window.location.pathname === "/hisaab/pay-by-product-group" ||
      window.location.pathname === "/hisaab/transactions" ||
      window.location.pathname === "/hisaab/orders"
    ) {
      setTabValue("/hisaab");
    }
    if (
      window.location.pathname === "/" &&
      localStorage.getItem("otpVerified")
    ) {
      setTabValue("/dashboard");
    } else {
      if (
        window.location.pathname !== "/hisaab/pay-by-product-group" &&
        window.location.pathname !== "/hisaab/transactions" &&
        window.location.pathname !== "/hisaab/orders"
      ) {
        setTabValue(window.location.pathname);
      }
    }
  };

  return (
    <>
      <ExpandMoreDrawer open={open} setOpen={setOpen} leadsCount={leadsCount} />

      <div
        style={
          bottomNavShow &&
          (PartnerService.hasOnboarded() ||
            PartnerService.hasOnboardedAsFieldAgent() ||
            PartnerService.hasFilledPartnerDetails() ||
            PartnerService.hasUploadedDocuments() ||
            PartnerService.areDocumentsVerified() ||
            PartnerService.hasPaidSecurityDeposit() ||
            PartnerService.isInActiveGracePeriod() ||
            PartnerService.showBottomBarForChurnSubStatus())
            ? {
                display: "block",
                position: "relative",
                width: "inherit",
                zIndex: 1060,
              }
            : {
                display: "none",
                position: "relative",
                width: "inherit",
                zIndex: 1060,
              }
        }
      >
        <div
          className={
            shadow
              ? appClasses.bottomBarWithShadow
              : appClasses.bottomBarWithoutShadow
          }
        >
          <Tabs
            value={tabValue}
            aria-label="icon label tabs example"
            className={appClasses.alignTabs}
            centered
            variant={`fullWidth`}
          >
            {bottomTabData?.length > 0 &&
              bottomTabData.map((bottomTabValue, bottomTabIndex) =>
                bottomTabValue.name === "More" ? (
                  <Tab
                    label={
                      <Typography
                        variant={"body2"}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "15px",
                          filter: `blur(${
                            partnerStatus === "INACTIVE" &&
                            bottomTabValue.name !== "Hisaab"
                              ? `2.5px`
                              : `0px`
                          })`,
                        }}
                      >
                        {bottomTabValue.name}
                      </Typography>
                    }
                    className="bottom-tab"
                    icon={
                      <>
                        <div
                          style={{
                            height: "67px",
                            width: "67px",
                            position: "fixed",
                            marginTop: "-7px",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: "50%",
                            zIndex: "3",
                          }}
                          className="leadsBottom"
                        >
                          <div
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                              border: "1px solid transparent",
                              alignSelf: "center",
                              boxShadow:
                                "inset 0px 0px 12px rgba(0, 0, 0, 0.1)",
                              background:
                                "linear-gradient(16.55deg, #853F4F 0%, #E54B25 122.59%)",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "25px",
                              fontWeight: "700",
                              color: "white",
                              zIndex: "5",
                              filter: `blur(${
                                partnerStatus === "INACTIVE" ? `2.5px` : `0px`
                              })`,
                            }}
                          >
                            <div
                              style={{
                                alignSelf: "center",
                                position: "absolute",
                              }}
                            >
                              {open ? (
                                <ExpandMoreRounded
                                  sx={{
                                    mt: "5px",
                                    width: "30px",
                                    height: "34px",
                                  }}
                                />
                              ) : leadsCount ? (
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {leadsCount}
                                </Typography>
                              ) : (
                                <ExpandLessRounded
                                  sx={{
                                    mt: "5px",
                                    width: "30px",
                                    height: "34px",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    classes={{ root: appClasses.alignTabBottom }}
                    onClick={() => {
                      handleTabClickEvent(
                        `${bottomTabValue.name}`,
                        `${bottomTabValue.navigationLink}`
                      );
                      if (partnerStatus !== "INACTIVE") {
                        setOpen(!open);
                      }
                    }}
                    key={bottomTabIndex}
                  />
                ) : (
                  <Tab
                    label={
                      <Typography
                        variant={"body2"}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "15px",
                          filter: `blur(${
                            partnerStatus === "INACTIVE" &&
                            bottomTabValue.name !== "Hisaab"
                              ? `2.5px`
                              : `0px`
                          })`,
                        }}
                      >
                        {bottomTabValue.name}
                      </Typography>
                    }
                    className="bottom-tab"
                    icon={
                      <div>
                        <img
                          style={bottomTabValue.activeClass}
                          src={bottomTabValue.activeImageLink}
                          alt=""
                          className={
                            tabValue === bottomTabValue.navigationLink
                              ? appClasses.bottomActiveClass
                              : appClasses.bottomInActiveClass
                          }
                        />
                        <img
                          style={bottomTabValue.inActiveClass}
                          src={bottomTabValue.inActiveImageLink}
                          alt=""
                          className={
                            tabValue !== bottomTabValue.navigationLink
                              ? appClasses.bottomActiveClass
                              : appClasses.bottomInActiveClass
                          }
                        />
                      </div>
                    }
                    classes={{ root: appClasses.alignTab }}
                    to={
                      partnerStatus === "INACTIVE"
                        ? "/hisaab"
                        : bottomTabValue.navigationLink
                    }
                    component={Link}
                    value={bottomTabValue.navigationLink}
                    onClick={() => {
                      setOpen(false);
                      handleTabClickEvent(
                        `${bottomTabValue.name}`,
                        `${bottomTabValue.navigationLink}`
                      );
                    }}
                    key={bottomTabIndex}
                  />
                )
              )}
          </Tabs>
        </div>
      </div>

      <Snackbar
        open={openPartnerBlockedDialog}
        autoHideDuration={3000}
        onClose={() => setOpenPartnerBlockedDialog(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ marginBottom: "44px" }}
      >
        <Alert
          sx={{
            background: "#666666",
            color: "#FFFFFF",
            borderRadius: "6px",
            textAlign: "center",
          }}
          icon={false}
          onClose={() => setOpenPartnerBlockedDialog(false)}
        >
          {`Sorry, You cannot access this page due to account deactivation`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BottomBar;
