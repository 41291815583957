// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "Andhra Pradesh": [
    "Annamayya",
    "Chittoor",
    "SPSR Nellore",
    "Tirupati",
    "Y.S.R.",
    "East Godavari",
    "Eluru",
    "Kakinada",
    "Konaseema",
    "Krishna",
    "NTR",
    "Palnadu",
    "West Godavari",
    "Ananthapuramu",
    "Bapatla",
    "Guntur",
    "Kurnool",
    "Nandyal",
    "Prakasam",
    "Sri Sathya Sai",
    "Alluri Sitharama Raju",
    "Anakapalli",
    "Parvathipuram Manyam",
    "Srikakulam",
    "Visakhapatanam",
    "Vizianagaram",
  ],
  Bihar: [
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Khagaria",
    "Munger",
    "Saharsa",
    "Arwal",
    "BH-Aurangabad",
    "Bhojpur",
    "Buxar",
    "Jehanabad",
    "Kaimur",
    "Patna",
    "Rohtas",
    "Gaya",
    "Jamui",
    "Lakhisarai",
    "Nalanda",
    "Nawada",
    "Sheikhpura",
    "East Champaran",
    "Gopalganj",
    "Saran",
    "Sheohar",
    "Siwan",
    "West Champaran",
    "Darbhanga",
    "Madhubani",
    "Muzaffarpur",
    "Samastipur",
    "Sitamarhi",
    "Vaishali",
    "Araria",
    "Katihar",
    "Madhepura",
    "Purnia",
    "Supaul",
  ],
  Chhattisgarh: [
    "Bastar",
    "Bijapur",
    "Dantewada",
    "Narayanpur",
    "Sukma",
    "Bilaspur",
    "Gaurela Pendra Marwahi",
    "Janjgir-Champa",
    "Kabeerdham",
    "Korba",
    "Mungeli",
    "Balod",
    "Dhamtari",
    "Gariyaband",
    "Kanker",
    "Kondagaon",
    "Rajnandgaon",
    "Jashpur",
    "Mahasamund",
    "Raigarh",
    "Sakti",
    "Sarangarh Bilaigarh",
    "Balodabazar",
    "Bemetara",
    "Durg",
    "Khairagarh Chhuikhadan Gandai",
    "Mohla Manpur Ambagarh Chouki",
    "Raipur",
    "Balrampur-CG",
    "Korea",
    "Manendragarh",
    "Surajpur",
    "Surguja",
  ],
  Gujarat: [
    "Banaskantha",
    "Mehsana",
    "Patan",
    "Aravalli",
    "Gandhinagar",
    "Sabarkantha",
    "Dang",
    "Navsari",
    "Valsad",
    "Surat",
    "Tapi",
    "Ahmedabad",
    "Anand",
    "Kheda",
    "Dahod",
    "Mahisagar",
    "Panchmahals",
    "Bharuch",
    "Chhota Udepur",
    "Narmada",
    "Vadodara",
    "Amreli",
    "Bhavnagar",
    "Junagadh",
    "Kutch",
    "Morbi",
    "Dwarka",
    "Jamnagar",
    "Porbandar",
    "Rajkot",
    "Botad",
    "Surendranagar",
  ],
  Haryana: [
    "Fatehabad",
    "Hisar",
    "Sirsa",
    "Ambala",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Panchkula *",
    "Panipat",
    "Yamunanagar",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Gurgaon",
    "Jhajjar",
    "Mahendragarh",
    "Rewari",
    "Rohtak",
    "Sonipat",
  ],
  Karnataka: [
    "Belagavi",
    "Dharwad",
    "Gadag",
    "Haveri",
    "Uttara Kannada",
    "Chikkamagaluru",
    "Chitradurga",
    "Davanagere",
    "Shivamogga",
    "Udupi",
    "Yadgiri",
    "Bangalore Rural",
    "Bengaluru Urban",
    "Chamarajanagar",
    "Chikkaballapura",
    "Dakshina Kannada",
    "Hassan",
    "Kodagu",
    "Kolar",
    "Mandya",
    "Mysore",
    "Ramanagara",
    "Tumkur",
    "Bagalkote",
    "Ballari",
    "Bidar",
    "Kalaburagi",
    "Koppal",
    "Raichur",
    "Vijayapura",
  ],
  Maharashtra: [
    "Akola",
    "Amravati",
    "Buldhana",
    "Bhandara",
    "Gondia",
    "Nagpur",
    "Latur",
    "Nanded",
    "Hingoli",
    "Parbhani",
    "Chandrapur",
    "Gadchiroli",
    "Wardha",
    "Washim",
    "Yavatmal",
    "Ahmednagar",
    "Aurangabad",
    "Beed",
    "Jalna",
    "Dhule",
    "Nandurbar",
    "Jalgaon",
    "Malegaon",
    "Nashik",
    "Palghar",
    "Thane",
    "Osmanabad",
    "Pune",
    "Raigarh",
    "Kolhapur",
    "Ratnagiri",
    "Sangli",
    "Sindhudurg",
    "Satara",
    "Solapur",
  ],
  "Madhya Pradesh": [
    "Bhopal",
    "Sehore",
    "Vidisha",
    "Dewas",
    "Indore",
    "Harda",
    "Narmadapuram",
    "Raisen",
    "Rajgarh",
    "Shajapur",
    "Bhind",
    "Datia",
    "Gwalior",
    "Morena",
    "Ashok Nagar",
    "Guna",
    "Sheopur",
    "Shivpuri",
    "Burhanpur",
    "Khandwa",
    "Khargone",
    "Alirajpur",
    "Barwani",
    "Dhar",
    "Mandsaur",
    "Neemuch",
    "Jhabua",
    "Ratlam",
    "Agarmalwa",
    "Ujjain",
    "Betul",
    "Chhindwara",
    "Balaghat",
    "Mandla",
    "Seoni",
    "Chhatarpur",
    "Niwari",
    "Tikamgarh",
    "Jabalpur",
    "Narsinghpur",
    "Damoh",
    "Katni",
    "Sagar",
    "Panna",
    "Rewa",
    "Satna",
    "Sidhi",
    "Singrauli",
    "Anuppur",
    "Dindori",
    "Shahdol",
    "Umaria",
  ],
  Rajasthan: [
    "Bhilwara",
    "Chittorgarh",
    "Baran",
    "Jhalawar",
    "Banswara",
    "Dungarpur",
    "Pratapgarh",
    "Rajsamand",
    "Udaipur",
    "Bikaner",
    "Churu",
    "Hanumangarh",
    "Sri Ganganagar",
    "Alwar",
    "Bharatpur",
    "Ajmer",
    "Jaipur",
    "Jhunjhunu",
    "Sikar",
    "Barmer",
    "Jalore",
    "Sirohi",
    "Jaisalmer",
    "Jodhpur",
    "Nagaur",
    "Pali",
    "Dausa",
    "Dholpur",
    "Karauli",
    "Bundi",
    "Kota",
    "Sawai Madhopur",
    "Tonk",
  ],
  Telangana: [
    "Adilabad",
    "Hyderabad",
    "Kamareddy",
    "Kumuram Bheem Asifabad",
    "Mancherial",
    "Medak",
    "Medchal Malkajgiri",
    "Nirmal",
    "Nizamabad",
    "Ranga Reddy",
    "Sangareddy",
    "Siddipet",
    "Vikarabad",
    "Yadadri Bhuvanagiri",
    "Jogulamba Gadwal",
    "Khammam",
    "Mahabubnagar",
    "Nagarkurnool",
    "Nalgonda",
    "Narayanpet",
    "Suryapet",
    "Wanaparthy",
    "Bhadradri Kothagudem",
    "Hanumakonda",
    "Jagitial",
    "Jangoan",
    "Jayashankar Bhupalapally",
    "Karimnagar",
    "Mahabubabad",
    "Mulugu",
    "Peddapalli",
    "Rajanna Sircilla",
    "Warangal",
  ],
  "Uttar Pradesh": [
    "Ambedkarnagar",
    "Faizabad",
    "Sultanpur",
    "Bahraich",
    "Gonda",
    "Shrawasti",
    "Sitapur",
    "Azamgarh",
    "Ballia",
    "Mau",
    "Deoria",
    "Gorakhpur",
    "Kushinagar",
    "Sant Kabir Nagar",
    "Balrampur",
    "Basti",
    "Maharajganj",
    "Siddharthnagar",
    "Farrukhabad",
    "Hardoi",
    "Kannauj",
    "Hamirpur",
    "Jhansi",
    "Lalitpur",
    "Mahoba",
    "Banda",
    "Chitrakut",
    "Fatehpur",
    "Kanpur",
    "Kanpur Dehat",
    "Barabanki",
    "Lucknow",
    "Raebareli",
    "Unnao",
    "Lakhimpur Kheri",
    "Shahjahanpur",
    "Allahabad",
    "Kaushambi",
    "Pratapgarh",
    "Chandauli",
    "Mirzapur",
    "Sonbhadra",
    "Bhadohi",
    "Ghazipur",
    "Jaunpur",
    "Varanasi",
    "Agra",
    "Hathras",
    "Mathura",
    "Etah",
    "Kasganj",
    "Auraiya",
    "Etawah",
    "Jalaun",
    "Firozabad",
    "Mainpuri",
    "Badaun",
    "Bareilly",
    "Pilibhit",
    "Sambhal",
    "Aligarh",
    "Bulandshahr",
    "Gautam Buddha Nagar",
    "Hapur",
    "Amroha",
    "Ghaziabad",
    "Jyotiba Phule Nagar",
    "Meerut",
    "Moradabad",
    "Rampur",
    "Bagpat",
    "Bijnor",
    "Muzaffarnagar",
    "Saharanpur",
    "Shamali",
  ],
  "All States": [
    "Annamayya",
    "Chittoor",
    "SPSR Nellore",
    "Tirupati",
    "Y.S.R.",
    "East Godavari",
    "Eluru",
    "Kakinada",
    "Konaseema",
    "Krishna",
    "NTR",
    "Palnadu",
    "West Godavari",
    "Ananthapuramu",
    "Bapatla",
    "Guntur",
    "Kurnool",
    "Nandyal",
    "Prakasam",
    "Sri Sathya Sai",
    "Alluri Sitharama Raju",
    "Anakapalli",
    "Parvathipuram Manyam",
    "Srikakulam",
    "Visakhapatanam",
    "Vizianagaram",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Khagaria",
    "Munger",
    "Saharsa",
    "Arwal",
    "BH-Aurangabad",
    "Bhojpur",
    "Buxar",
    "Jehanabad",
    "Kaimur",
    "Patna",
    "Rohtas",
    "Gaya",
    "Jamui",
    "Lakhisarai",
    "Nalanda",
    "Nawada",
    "Sheikhpura",
    "East Champaran",
    "Gopalganj",
    "Saran",
    "Sheohar",
    "Siwan",
    "West Champaran",
    "Darbhanga",
    "Madhubani",
    "Muzaffarpur",
    "Samastipur",
    "Sitamarhi",
    "Vaishali",
    "Araria",
    "Katihar",
    "Madhepura",
    "Purnia",
    "Supaul",
    "Bastar",
    "Bijapur",
    "Dantewada",
    "Narayanpur",
    "Sukma",
    "Bilaspur",
    "Gaurela Pendra Marwahi",
    "Janjgir-Champa",
    "Kabeerdham",
    "Korba",
    "Mungeli",
    "Balod",
    "Dhamtari",
    "Gariyaband",
    "Kanker",
    "Kondagaon",
    "Rajnandgaon",
    "Jashpur",
    "Mahasamund",
    "Raigarh",
    "Sakti",
    "Sarangarh Bilaigarh",
    "Balodabazar",
    "Bemetara",
    "Durg",
    "Khairagarh Chhuikhadan Gandai",
    "Mohla Manpur Ambagarh Chouki",
    "Raipur",
    "Balrampur-CG",
    "Korea",
    "Manendragarh",
    "Surajpur",
    "Surguja",
    "Banaskantha",
    "Mehsana",
    "Patan",
    "Aravalli",
    "Gandhinagar",
    "Sabarkantha",
    "Dang",
    "Navsari",
    "Valsad",
    "Surat",
    "Tapi",
    "Ahmedabad",
    "Anand",
    "Kheda",
    "Dahod",
    "Mahisagar",
    "Panchmahals",
    "Bharuch",
    "Chhota Udepur",
    "Narmada",
    "Vadodara",
    "Amreli",
    "Bhavnagar",
    "Junagadh",
    "Kutch",
    "Morbi",
    "Dwarka",
    "Jamnagar",
    "Porbandar",
    "Rajkot",
    "Botad",
    "Surendranagar",
    "Fatehabad",
    "Hisar",
    "Sirsa",
    "Ambala",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Panchkula *",
    "Panipat",
    "Yamunanagar",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Gurgaon",
    "Jhajjar",
    "Mahendragarh",
    "Rewari",
    "Rohtak",
    "Sonipat",
    "Belagavi",
    "Dharwad",
    "Gadag",
    "Haveri",
    "Uttara Kannada",
    "Chikkamagaluru",
    "Chitradurga",
    "Davanagere",
    "Shivamogga",
    "Udupi",
    "Yadgiri",
    "Bangalore Rural",
    "Bengaluru Urban",
    "Chamarajanagar",
    "Chikkaballapura",
    "Dakshina Kannada",
    "Hassan",
    "Kodagu",
    "Kolar",
    "Mandya",
    "Mysore",
    "Ramanagara",
    "Tumkur",
    "Bagalkote",
    "Ballari",
    "Bidar",
    "Kalaburagi",
    "Koppal",
    "Raichur",
    "Vijayapura",
    "Akola",
    "Amravati",
    "Buldhana",
    "Bhandara",
    "Gondia",
    "Nagpur",
    "Latur",
    "Nanded",
    "Hingoli",
    "Parbhani",
    "Chandrapur",
    "Gadchiroli",
    "Wardha",
    "Washim",
    "Yavatmal",
    "Ahmednagar",
    "Aurangabad",
    "Beed",
    "Jalna",
    "Dhule",
    "Nandurbar",
    "Jalgaon",
    "Malegaon",
    "Nashik",
    "Palghar",
    "Thane",
    "Osmanabad",
    "Pune",
    "Raigarh",
    "Kolhapur",
    "Ratnagiri",
    "Sangli",
    "Sindhudurg",
    "Satara",
    "Solapur",
    "Bhopal",
    "Sehore",
    "Vidisha",
    "Dewas",
    "Indore",
    "Harda",
    "Narmadapuram",
    "Raisen",
    "Rajgarh",
    "Shajapur",
    "Bhind",
    "Datia",
    "Gwalior",
    "Morena",
    "Ashok Nagar",
    "Guna",
    "Sheopur",
    "Shivpuri",
    "Burhanpur",
    "Khandwa",
    "Khargone",
    "Alirajpur",
    "Barwani",
    "Dhar",
    "Mandsaur",
    "Neemuch",
    "Jhabua",
    "Ratlam",
    "Agarmalwa",
    "Ujjain",
    "Betul",
    "Chhindwara",
    "Balaghat",
    "Mandla",
    "Seoni",
    "Chhatarpur",
    "Niwari",
    "Tikamgarh",
    "Jabalpur",
    "Narsinghpur",
    "Damoh",
    "Katni",
    "Sagar",
    "Panna",
    "Rewa",
    "Satna",
    "Sidhi",
    "Singrauli",
    "Anuppur",
    "Dindori",
    "Shahdol",
    "Umaria",
    "Bhilwara",
    "Chittorgarh",
    "Baran",
    "Jhalawar",
    "Banswara",
    "Dungarpur",
    "Pratapgarh",
    "Rajsamand",
    "Udaipur",
    "Bikaner",
    "Churu",
    "Hanumangarh",
    "Sri Ganganagar",
    "Alwar",
    "Bharatpur",
    "Ajmer",
    "Jaipur",
    "Jhunjhunu",
    "Sikar",
    "Barmer",
    "Jalore",
    "Sirohi",
    "Jaisalmer",
    "Jodhpur",
    "Nagaur",
    "Pali",
    "Dausa",
    "Dholpur",
    "Karauli",
    "Bundi",
    "Kota",
    "Sawai Madhopur",
    "Tonk",
    "Adilabad",
    "Hyderabad",
    "Kamareddy",
    "Kumuram Bheem Asifabad",
    "Mancherial",
    "Medak",
    "Medchal Malkajgiri",
    "Nirmal",
    "Nizamabad",
    "Ranga Reddy",
    "Sangareddy",
    "Siddipet",
    "Vikarabad",
    "Yadadri Bhuvanagiri",
    "Jogulamba Gadwal",
    "Khammam",
    "Mahabubnagar",
    "Nagarkurnool",
    "Nalgonda",
    "Narayanpet",
    "Suryapet",
    "Wanaparthy",
    "Bhadradri Kothagudem",
    "Hanumakonda",
    "Jagitial",
    "Jangoan",
    "Jayashankar Bhupalapally",
    "Karimnagar",
    "Mahabubabad",
    "Mulugu",
    "Peddapalli",
    "Rajanna Sircilla",
    "Warangal",
    "Ambedkarnagar",
    "Faizabad",
    "Sultanpur",
    "Bahraich",
    "Gonda",
    "Shrawasti",
    "Sitapur",
    "Azamgarh",
    "Ballia",
    "Mau",
    "Deoria",
    "Gorakhpur",
    "Kushinagar",
    "Sant Kabir Nagar",
    "Balrampur",
    "Basti",
    "Maharajganj",
    "Siddharthnagar",
    "Farrukhabad",
    "Hardoi",
    "Kannauj",
    "Hamirpur",
    "Jhansi",
    "Lalitpur",
    "Mahoba",
    "Banda",
    "Chitrakut",
    "Fatehpur",
    "Kanpur",
    "Kanpur Dehat",
    "Barabanki",
    "Lucknow",
    "Raebareli",
    "Unnao",
    "Lakhimpur Kheri",
    "Shahjahanpur",
    "Allahabad",
    "Kaushambi",
    "Pratapgarh",
    "Chandauli",
    "Mirzapur",
    "Sonbhadra",
    "Bhadohi",
    "Ghazipur",
    "Jaunpur",
    "Varanasi",
    "Agra",
    "Hathras",
    "Mathura",
    "Etah",
    "Kasganj",
    "Auraiya",
    "Etawah",
    "Jalaun",
    "Firozabad",
    "Mainpuri",
    "Badaun",
    "Bareilly",
    "Pilibhit",
    "Sambhal",
    "Aligarh",
    "Bulandshahr",
    "Gautam Buddha Nagar",
    "Hapur",
    "Amroha",
    "Ghaziabad",
    "Jyotiba Phule Nagar",
    "Meerut",
    "Moradabad",
    "Rampur",
    "Bagpat",
    "Bijnor",
    "Muzaffarnagar",
    "Saharanpur",
    "Shamali",
  ],
};
