import React, { useEffect, useState } from "react";

import { getTime, startOfDay, endOfDay, format } from "date-fns";
import { Card, Typography, Grid, IconButton, Box, Button } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  FileDownloadOutlined,
} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import ErrorBoundary from "../Common/ErrorBoundary";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonConstantValues from "../../Services/CommonConstantValues";
import PaymentService from "../../Services/PaymentService";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1.5, 0.5),
    paddingBottom: "90px",
  },
  cards: {
    marginTop: theme.spacing(1),
    borderTopLeftRadius: "16px !important",
    borderTopRightRadius: "16px !important",
    // padding: theme.spacing(0.5, 0, 0.5, 0),
    // borderRadius: theme.spacing(1.2),
    border: "none",
    fontSize: "12px",
  },
  debit: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    margin: theme.spacing(0.5, 1, 0.5, 0),
    background: "rgba(157, 33, 35, 0.2)",
    color: "#9D2123",
    // lineHeight: "16px",
  },
  credit: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    margin: theme.spacing(0.5, 1, 0.5, 0),
    background: "rgba(0, 115, 62, 0.2)",
    color: "#00733E",
  },

  amount: {
    // margin: theme.spacing(0.5, 1),
  },
  debitAmount: {
    color: "#9D2123",
  },
  creditAmount: {
    color: "#00733E",
  },
  details: {
    borderTop: "1px solid #eeeeee",
  },
  noData: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
}));

const TransactionDetailsCards = ({ transactionList }) => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState([]);

  useEffect(() => {
    let list = [];
    for (let i = 0; i < transactionList?.totalTransactions; i++) {
      list.push(false);
    }
    setShowDetails([...list]);
  }, [transactionList?.totalTransactions]);

  const Details = (type, index) => {
    let list = [];
    list = [...showDetails];
    list[index] = type === "show" ? true : false;
    setShowDetails([...list]);
  };

  const downloadFile = (fileData, transactionId) => {
    const newBlob = new Blob([fileData], {
      type: "application/pdf",
    });

    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = `AgroStar-Saathi-Credit-Transactions-${transactionId}.pdf`;
    link.click();
  };

  const handleDownloadCreditInvoice = (transactionId, transactionDetails) => {
    AnalyticsService.pushEvent(`Download Button Clicked`, {
      page: "Transactions",
      transactionId: transactionId,
      imageUrl: transactionDetails?.creditNoteUrl,
      buttonName: "Credit Note",
    });

    const { axiosRequest } =
      PaymentService.downloadCreditNoteInvoice(transactionId);
    axiosRequest.then(({ data }) => {
      if (AndroidService.checkIfAndroid()) {
        /*global Android*/
        Android.setFileName(`Agrostar-Saathi-Credit-Transactions`);
        downloadFile(data, transactionId);
      } else {
        downloadFile(data, transactionId);
      }
    });
  };
  return (
    <div className={classes.container}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        sx={{ paddingLeft: 2, paddingRight: 2 }}
      >
        <Grid item xs={2}>
          <Typography fontSize={12} variant={`body2`}>
            Date
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={12} variant={`body2`}>
            Reason
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            align={"right"}
            // sx={{ marginLeft: "6px" }}
            fontSize={12}
            variant={`body2`}
          >
            Debit
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            align={"right"}
            // sx={{ marginLeft: "6px" }}
            fontSize={12}
            variant={`body2`}
          >
            Credit
          </Typography>
        </Grid>
      </Grid>
      {transactionList &&
      transactionList.transactions &&
      transactionList.transactions.length > 0 ? (
        transactionList.transactions.map((entry, index) => {
          return (
            <ErrorBoundary message={""} key={index}>
              <>
                <Accordion
                  className={classes.cards}
                  variant="outlined"
                  TransitionProps={{ unmountOnExit: true }}
                  disableGutters={true}
                  elevation={0}
                  sx={{
                    "&.MuiAccordion-root:before": {
                      display: "none",
                    },
                    borderBottom:
                      entry?.creditNoteUrl && "1px solid #EEEEEE !important",
                    borderBottomRightRadius:
                      !entry?.creditNoteUrl &&
                      entry.reason !== "Return" &&
                      entry.reason !== "Credit Note" &&
                      "16px",
                    borderBottomLeftRadius:
                      !entry?.creditNoteUrl &&
                      entry.reason !== "Return" &&
                      entry.reason !== "Credit Note" &&
                      "16px",
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0}
                    >
                      <Grid item xs={2}>
                        <Typography variant={`body2`} fontSize={12}>
                          {format(entry.transactionTimestamp, "dd MMM yyyy")}
                        </Typography>
                      </Grid>
                      {/*<Grid item xs={3}>*/}
                      {/*  <Typography*/}
                      {/*    className={*/}
                      {/*      entry.transactionType === "debit"*/}
                      {/*        ? classes.debit*/}
                      {/*        : classes.credit*/}
                      {/*    }*/}
                      {/*    variant={`body2`}*/}
                      {/*  >*/}
                      {/*    {entry.transactionType === "debit" ? "Dr" : "Cr"}*/}
                      {/*  </Typography>*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={4}>*/}
                      {/*  <Typography variant={`body2`} fontSize={12}>*/}
                      {/*    Reason - <b>{entry.reason}</b>*/}
                      {/*    <br />*/}
                      {/*    {format(entry.transactionTimestamp, "dd MMM yyyy")}*/}
                      {/*  </Typography>*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={2}>*/}
                      {/*  <Typography variant={`body2`}fontSize={12}>*/}
                      {/*    <b*/}
                      {/*      className={*/}
                      {/*        entry.transactionType === "debit"*/}
                      {/*          ? classes.debitAmount*/}
                      {/*          : classes.creditAmount*/}
                      {/*      }*/}
                      {/*    >*/}
                      {/*      ₹*/}
                      {/*      {entry.amount*/}
                      {/*        ? new Intl.NumberFormat("en-IN").format(*/}
                      {/*            entry.amount*/}
                      {/*          )*/}
                      {/*        : 0}*/}
                      {/*    </b>*/}
                      {/*  </Typography>*/}
                      {/*</Grid>*/}
                      <Grid item xs={4}>
                        <Typography variant={`body2`} fontSize={12}>
                          <>{entry.reason}</>
                        </Typography>
                      </Grid>
                      <Grid item xs={3} fontSize={12}>
                        {entry.transactionType === "debit" && (
                          <Typography
                            className={classes.amount}
                            align={"right"}
                            variant={`body2`}
                            fontSize={12}
                          >
                            <span className={classes.debitAmount}>
                              ₹
                              {entry.amount
                                ? new Intl.NumberFormat("en-IN").format(
                                    entry.amount
                                  )
                                : 0}
                            </span>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={3} fontSize={12}>
                        {entry.transactionType === "credit" && (
                          <Typography
                            className={classes.amount}
                            align={"right"}
                            variant={`body2`}
                            fontSize={12}
                          >
                            <span className={classes.creditAmount}>
                              ₹
                              {entry.amount
                                ? new Intl.NumberFormat("en-IN").format(
                                    entry.amount
                                  )
                                : 0}
                            </span>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <div>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        style={{ marginTop: "1px" }}
                      >
                        <Grid item xs={12}>
                          <Typography variant={"body2"} fontSize={12}>
                            Remark: <b>{entry.remarks}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                      {entry.transactionType === "debit" && entry.dueDate && (
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={1}
                          style={{ marginTop: "1px" }}
                        >
                          <Grid item xs={12}>
                            <Typography fontSize={12} variant={"body2"}>
                              Due Date:{" "}
                              <b>{format(entry.dueDate, "dd MMM yyyy")}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
                {entry?.creditNoteUrl &&
                CommonConstantValues.CREDIT_NOTE_RESTRICTED_TYPES.includes(
                  entry.reason
                ) ? (
                  <Box
                    sx={{
                      background: "white",
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginTop: "-5px",
                      borderBottomLeftRadius: "16px",
                      borderBottomRightRadius: "16px",
                      padding: "13px 10px 9px 10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        borderRadius: "16px",
                        fontWeight: "700",
                        fontSize: "14px",
                        textTransform: "none",
                        height: "25px",
                      }}
                      onClick={() =>
                        handleDownloadCreditInvoice(entry?.transactionId, entry)
                      }
                    >
                      <FileDownloadOutlined />
                      Credit Note
                    </Button>
                  </Box>
                ) : (
                  CommonConstantValues.CREDIT_NOTE_RESTRICTED_TYPES.includes(
                    entry.reason
                  ) && (
                    <Box
                      sx={{
                        background: "#E4F1EB",
                        display: "flex",
                        marginTop: "-5px",
                        borderBottomLeftRadius: "16px",
                        borderBottomRightRadius: "16px",
                        padding: "6px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography color="secondary" sx={{ fontSize: "12px" }}>
                        Credit Note will be available soon
                      </Typography>
                    </Box>
                  )
                )}
              </>
            </ErrorBoundary>
          );
        })
      ) : (
        <Typography
          variant={"body1"}
          className={classes.noData}
          textAlign={"center"}
        >
          No Transactions Found.
        </Typography>
      )}
    </div>
  );
};

export default TransactionDetailsCards;
