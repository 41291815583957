import { Box, Chip, Container, Skeleton, Stack } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import useQuery from "../../Hooks/useQuery";
import AnalyticsService from "../../Services/AnalyticsService";
import SchemesService from "../../Services/SchemesService";
import ErrorBoundary from "../Common/ErrorBoundary";
import EmptyError from "../EmptyError/EmptyError";
import SchemeCard from "./SchemeCard";
import PartnerService from "../../Services/PartnerService";

const SCHEMES_CONTAINER_HORIZONTAL_PADDING = 16;
const isFeaturedSchemes = true;

const Schemes = () => {
  const [turnoverSchemes, setTurnoverSchemes] = useState([]);
  const [loading, setLoading] = useState("not_started");
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState(`active`);
  const query = useQuery();

  useEffect(() => {
    setLoading("started");
    const { axiosRequest, abortController } =
      SchemesService.getTurnoverBasedSchemes(isFeaturedSchemes, currentTab);
    axiosRequest
      .then((response) => {
        let turnoverSchemesFromResponse =
          response?.data?.responseData?.turnoverData?.turnoverOfferStatus;
        setTurnoverSchemes(turnoverSchemesFromResponse);
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting turnover based offers`);
          if (error.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, [currentTab]);

  useEffect(() => {
    AnalyticsService.pushEvent(`Schemes Page Viewed`, {
      page: "offers-and-schemes",
    });
  }, []);

  const handleTabClickedEvent = (tabName, pageName, sectionName) => {
    AnalyticsService.pushEvent(`Tab Clicked`, {
      tabName: tabName,
      page: pageName,
      section: sectionName,
    });
    setCurrentTab(tabName);
  };

  return (
    <Container
      maxWidth={`xl`}
      sx={{
        paddingLeft: `${SCHEMES_CONTAINER_HORIZONTAL_PADDING}px`,
        paddingRight: `${SCHEMES_CONTAINER_HORIZONTAL_PADDING}px`,
        paddingBottom: "84px",
      }}
    >
      <ErrorBoundary message={``}>
        <img
          src={`https://static.agrostar.in/agro-elearning-firebase/v0/b/agro-elearning/o/saathi%2Fbanners%2Fmy-schemes-banner-${localStorage
            .getItem("source")
            ?.toLowerCase()}.png?alt=media&timeStamp=${Date.now()
            .toString()
            .slice(0, -5)}`}
          width={"100%"}
          alt={``}
          style={{
            borderRadius: "16px",
            filter: PartnerService.applySmudgedBannerFilter(),
          }}
        />
      </ErrorBoundary>

      {!error && (
        <ErrorBoundary message={``}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={`right`}
            sx={{ margin: "3px 0px 16px 0px" }}
          >
            <Chip
              label="Active"
              color={`primary`}
              variant="outlined"
              clickable={false}
              sx={{
                padding: "3px 0px",
                cursor: "pointer",
                border: "1px solid #9D2123",
                color: `${currentTab === "active" ? "#FFFFFF" : "#9D2123"}`,
                backgroundColor: `${
                  currentTab === "active" ? "#9D2123" : "transparent"
                }`,
              }}
              onClick={() => {
                handleTabClickedEvent(
                  "active",
                  "offers-and-schemes",
                  "schemes"
                );
              }}
            />
            <Chip
              color={`primary`}
              label="Completed"
              variant="outlined"
              clickable={false}
              sx={{
                padding: "3px 0px",
                cursor: "pointer",
                border: "1px solid #9D2123",
                color: `${currentTab === "completed" ? "#FFFFFF" : "#9D2123"}`,
                backgroundColor: `${
                  currentTab === "completed" ? "#9D2123" : "transparent"
                }`,
              }}
              onClick={() => {
                handleTabClickedEvent(
                  "completed",
                  "offers-and-schemes",
                  "schemes"
                );
              }}
            />
          </Stack>
        </ErrorBoundary>
      )}

      {loading === "started" && (
        <>
          <Skeleton
            variant="rounded"
            height={220}
            sx={{ borderRadius: "16px", marginBottom: "12px" }}
          />
          <Skeleton
            variant="rounded"
            height={220}
            sx={{ borderRadius: "16px", marginBottom: "12px" }}
          />
          <Skeleton
            variant="rounded"
            height={220}
            sx={{ borderRadius: "16px", marginBottom: "12px" }}
          />
          <Skeleton
            variant="rounded"
            height={220}
            sx={{ borderRadius: "16px", marginBottom: "12px" }}
          />
        </>
      )}

      {loading === "done" && error && (
        <EmptyError
          image="EmptyPage.svg"
          text=""
          subText="Something went wrong. Please try again later."
          hideBack={true}
          extraStyle={{
            minHeight: `${window.innerHeight - 400}px`,
            background: "#FFFFFF",
          }}
        />
      )}

      {loading === "done" && !error && (
        <>
          {turnoverSchemes?.length > 0 ? (
            <>
              {_.map(turnoverSchemes, (turnoverScheme, index) => (
                <Box sx={{ filter: PartnerService.applySmudgedPriceFilter() }}>
                  <SchemeCard
                    key={index}
                    turnoverScheme={turnoverScheme}
                    SCHEMES_CONTAINER_HORIZONTAL_PADDING={
                      SCHEMES_CONTAINER_HORIZONTAL_PADDING
                    }
                    cardClickable={true}
                    clickRedirectTo={"SCHEME_DETAILS_PAGE"}
                    nudgeClickable={true}
                    showProjectedProgress={true}
                    page={"offers-and-schemes"}
                    isFeaturedSchemes={isFeaturedSchemes}
                  />
                </Box>
              ))}
            </>
          ) : (
            <EmptyError
              image="EmptyPage.svg"
              text=""
              subText={`You don't have any ${currentTab} schemes`}
              hideBack={true}
              extraStyle={{
                minHeight: `${window.innerHeight - 400}px`,
                background: "#FFFFFF",
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Schemes;
