import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import AnalyticsService from "../../Services/AnalyticsService";
import Languages from "../../Services/LanguageCode";

const PointsRedemptionCampaign = () => {
  const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "en";

  useEffect(() => {
    AnalyticsService.pushEvent(`AgroStar Points Transfer Page Viewed`, {
      page: `/agrostar-points-transfer-to-saathi`,
    });
  }, []);

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`AgroStar Points Transfer`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
          toolTip={`AgroStar Points Transfer`}
        />
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "18px", padding: "15px", fontWeight: "bold" }}
        >
          How It Works
        </Typography>
        <Box sx={{ textAlign: "center", marginBottom: "64px" }}>
          <img
            src={`/icons/pointsRedemption_${languageCode}.png`}
            style={{ width: "92%" }}
            alt={`How it works`}
          />
        </Box>
      </ErrorBoundary>
    </>
  );
};

export default PointsRedemptionCampaign;
