import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import LeadService, {
  GRAHAK_CHAT_SOURCES_ENABLED,
} from "../../Services/LeadService";
import AnalyticsService from "../../Services/AnalyticsService";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import EmptyError from "../EmptyError/EmptyError";
import ReactWhatsapp from "react-whatsapp";
import _ from "lodash";
import useMessages from "../../Hooks/useMessages";

const useStyles = makeStyles((theme) => ({
  leadName: {
    fontWeight: "700",
    fontSize: "14px",
    color: "white",
  },
  leadInformation: {
    fontWeight: "400",
    fontSize: "12px",
    color: "white",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardContainer: {
    paddingTop: "5px",
    paddingBottom: "6px !important",
  },
  name: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#333333",
  },
  title: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#333333",
  },
  productCardWrapperLink: {
    textDecoration: `none`,
    color: "rgba(0, 0, 0, 0.87)",
  },
  productCardWrapper: {
    wordBreak: "break-word",
    border: "1px solid #EEEEEE",
    textAlign: "center",
    // height: "80px",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
  },
  productCardContent: {
    padding: theme.spacing(1),
    paddingBottom: "6px !important", // theme.spacing(1),
  },
  productImageContainer: {
    // marginTop: theme.spacing(0.5),
    width: "94%",
  },
  productImage: {
    height: "100%",
    maxHeight: "150px",
    width: "50%",
    maxWidth: "100px",
  },
  productNameWrapper: {
    margin: theme.spacing(0, 0, 1.5, 0),
  },
  productName: {
    maxHeight: "3.9em",
    lineHeight: "1.3em",
    letterSpacing: "0px",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3 /* number of lines to show */,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
    fontSize: "14px",
  },
  paper: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    height: "700px",
  },
}));

function LeadsDetail(props) {
  const classes = useStyles();
  const { getMessage } = useMessages();

  const [leadsCropData, setLeadsCropData] = useState(null);
  const [state, setState] = useState({ bottom: false });
  const [productNames, setProductNames] = useState("");

  const handleWhatsappClickChat = (event) => {
    props.handleLeadsWhatsappMessageOpen(event);
    // props.handleLeadsCallAttemptViewScreen(event);
  };

  useEffect(() => {
    const leadsDetailViewedData = {
      page: `/nearby-farmers`,
      screen: "Leads Detail",
      leadId: props?.leadInformation?.leadId,
      farmerId: props?.leadInformation?.farmerId,
    };
    AnalyticsService.pushEvent(
      `Leads Detail Screen Viewed`,
      leadsDetailViewedData
    );

    const { axiosRequest, abortController } = LeadService.getLeadDetails(
      props.leadInformation.leadId,
      props.leadInformation.farmerId
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.crops?.length) {
          setLeadsCropData(response?.data?.responseData?.crops);
          let productNamesDetail = "";
          response?.data?.responseData?.products?.map((product, ind) => {
            let productData =
              ind === response?.data?.responseData?.products?.length - 1
                ? `${(productNamesDetail =
                    productNamesDetail + product?.productName)}`
                : `${(productNamesDetail =
                    productNamesDetail + product?.productName)}, `;
          });
          setProductNames(productNamesDetail);
        } else {
          setLeadsCropData([]);
        }
        console.log(response);
      })
      .catch(() => {});
    setState({ ...state, [props.anchor]: true });
    return () => abortController.abort();
  }, [props.state]);

  const handleBottomSheetSwipe = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={state["bottom"]}
      onClose={handleBottomSheetSwipe("bottom", false)}
      onOpen={handleBottomSheetSwipe("bottom", true)}
      classes={{ paper: classes.paper }}
      disableSwipeToOpen={true}
    >
      <div>
        <Box
          sx={{
            width:
              props.anchor === "top" || props.anchor === "bottom"
                ? "auto"
                : 250,
          }}
          role="presentation"
        >
          <Box
            sx={{
              padding: "15px",
              paddingTop: "5px",
              paddingBottom: "0px",
              marginTop: "10px",
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    height: "6px",
                    width: "43px",
                    background: "#DDDDDD",
                    borderRadius: "19%",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{
              padding: "15px",
              paddingTop: "0px",
              paddingBottom: "12px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={(event) => props.handleLeadsBottomSheetClose(event)}
                >
                  <img
                    src="/icons/cross.svg"
                    style={{ height: "18px", position: "absolute" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <img
              src={"/icons/pattern.png"}
              style={{ width: "96%", height: "62px" }}
            />
          </Box>
          <Box
            sx={{
              padding: "15px",
              paddingTop: "0px",
              paddingBottom: "5px",
              marginTop: "-67px",
            }}
          >
            <Card
              style={{
                boxShadow: "none",
                borderRadius: "7px",
                marginTop: "10px",
                background:
                  "linear-gradient(12.06deg, #B84307 0%, #F6A313 130.92%)",
              }}
            >
              <CardContent className={classes.cardContainer}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={
                      _.includes(
                        GRAHAK_CHAT_SOURCES_ENABLED,
                        localStorage.getItem("source")
                      )
                        ? 8
                        : 12
                    }
                  >
                    <Typography className={classes.leadName}>
                      {props.leadInformation.farmerAddress.farmerName}
                    </Typography>
                    <div>
                      <Typography className={classes.leadInformation}>
                        <img
                          src="./icons/whiteLocation.svg"
                          style={{ height: "14px" }}
                        />
                        &nbsp;{" "}
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            marginTop: "-20px",
                            marginLeft: "20px",
                          }}
                        >
                          {props.leadInformation?.farmerAddress?.line2}
                        </div>
                      </Typography>
                    </div>
                  </Grid>
                  {_.includes(
                    GRAHAK_CHAT_SOURCES_ENABLED,
                    localStorage.getItem("source")
                  ) && (
                    <Grid item xs={4} sx={{ alignSelf: "center" }}>
                      {props?.blockLeadsInteraction() ? (
                        <IconButton
                          style={{
                            height: "29px",
                            width: "100%",
                            borderRadius: "60px",
                            color: "white",
                            background: "#9D2123",
                            display: "flex",
                            textDecoration: "none",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "700",
                          }}
                          onClick={(event) => {
                            props?.handleLeadsAction(event, false, "", "leads");
                            props?.setIsLeadsBlocked(true);
                            event.stopPropagation();
                          }}
                        >
                          <img
                            src="/icons/crossWhite.svg"
                            style={{ alignSelf: "center" }}
                          />
                          &nbsp;
                          <span>Decline</span>
                        </IconButton>
                      ) : (
                        <IconButton
                          style={{
                            height: "29px",
                            width: "100%",
                            borderRadius: "60px",
                            color: "white",
                            background: "#9D2123",
                            display: "flex",
                            textDecoration: "none",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "700",
                          }}
                          onClick={(event) => {
                            if (
                              props?.totalOCP >
                                props?.ocpRestrictionData
                                  ?.outstanding_ocp_amount_allowed &&
                              localStorage.getItem(`creditLine`) !== "FINBOX"
                            ) {
                              props?.handleMadeFreeLeadAttempt();
                            }
                            props.handleLeadsRejectScreenView(event);
                          }}
                        >
                          <img
                            src="/icons/crossWhite.svg"
                            style={{ alignSelf: "center" }}
                          />
                          &nbsp;
                          <span>Decline</span>
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>

          <Box
            sx={{ padding: "15px", paddingTop: "5px", paddingBottom: "5px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.name}>
                  Product Required by Farmer
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {leadsCropData?.length > 0 &&
            leadsCropData.map((cropValue, cropIndex) => {
              return (
                <>
                  <Box
                    sx={{
                      paddingTop: "10px",
                      paddingBottom: "15px",
                      marginLeft: "15px",
                      marginRight: "15px",
                    }}
                    key={cropIndex}
                  >
                    <Box
                      sx={{
                        background: "#F4F4F4",
                        padding: "18px",
                        borderRadius: "20px",
                      }}
                    >
                      <Grid container spacing={2} sx={{ ml: 0.5, mt: 0.3 }}>
                        <Grid container row justifyContent="center">
                          <Grid item xs={12} alignSelf="center">
                            <Typography className={classes.name}>
                              {cropValue.cropName}
                            </Typography>
                            {cropValue?.cropSowingDate ? (
                              <Typography className={classes.title}>
                                Crop Age:{" "}
                                {cropValue?.cropAge === 1
                                  ? `${cropValue?.cropAge} Day`
                                  : cropValue?.cropAge > 0
                                    ? `${cropValue?.cropAge} Days`
                                    : cropValue?.cropAge === 0
                                      ? `Crop will be sowed today`
                                      : cropValue?.cropAge === -1
                                        ? `Crop will be sowed in ${cropValue?.cropAge
                                            ?.toString()
                                            ?.replace("-", "")} day`
                                        : `Crop will be sowed in ${cropValue?.cropAge
                                            ?.toString()
                                            ?.replace("-", "")} days`}
                              </Typography>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Typography className={classes.name} sx={{ ml: 0.5 }}>
                          Products
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        sx={{ flexGrow: 1 }}
                      >
                        {cropValue.products.map(
                          (productValue, productIndex) => {
                            return (
                              <Grid item xs={12} key={productIndex}>
                                <Card
                                  className={classes.productCardWrapper}
                                  variant={"outlined"}
                                >
                                  <CardContent
                                    className={classes.productCardContent}
                                  >
                                    <Link
                                      to={{
                                        pathname: `/products/${productValue?.productSkuCode}`,
                                        state: {
                                          from: window.location.pathname,
                                        },
                                      }}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <Grid
                                        container
                                        direction={`row`}
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        className={
                                          classes.productImageContainer
                                        }
                                        spacing={0}
                                      >
                                        <Grid item xs={4}>
                                          <img
                                            className={classes.productImage}
                                            src={
                                              productValue.productImage
                                                ? productValue.productImage
                                                : "/icons/dummy.jpeg"
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={8}
                                          sx={{
                                            textAlign: "left",
                                            alignSelf: "center",
                                          }}
                                        >
                                          <Typography className={classes.title}>
                                            {productValue?.productName}
                                          </Typography>
                                          {/* {productValue.productName} */}
                                        </Grid>
                                      </Grid>
                                    </Link>
                                  </CardContent>
                                </Card>
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "1px",
                      width: "100%",
                      background: "#000000",
                      opacity: "0.1",
                    }}
                  ></Box>
                </>
              );
            })}
          {leadsCropData?.length < 1 && (
            <>
              <Box sx={{ marginTop: "-74px" }}>
                <EmptyError
                  image="EmptyPage.svg"
                  text="Sorry, No Products found"
                />
              </Box>
            </>
          )}
        </Box>
      </div>
      <Box
        sx={{
          height: "50px",
          position: "fixed",
          bottom: "0px",
          zIndex: "4000",
          width: "100%",
        }}
      >
        {(props?.leadsTabValue === "active" ||
          props?.leadsTabValue === "sale") &&
          _.includes(
            GRAHAK_CHAT_SOURCES_ENABLED,
            localStorage.getItem("source")
          ) && (
            <>
              {props?.blockLeadsInteraction() ? (
                <IconButton
                  sx={{
                    height: "50px",
                    width: "50%",
                    textAlign: "center",
                    background: "white",
                    color: "#00733E",
                    fontWeight: "700",
                    fontSize: "14px",
                    borderRadius: "0px",
                  }}
                  variant="contained"
                  onClick={(event) => {
                    props?.handleLeadsAction(
                      event,
                      false,
                      "",
                      props?.leadInformation
                    );
                    props?.setIsLeadsBlocked(true);
                    event.stopPropagation();
                  }}
                >
                  <img
                    src="/icons/whatsappLeadsChat.svg"
                    style={{ height: "30px" }}
                  />{" "}
                  &nbsp; <span style={{ alignSelf: "center" }}>Message</span>
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    height: "50px",
                    width: "50%",
                    textAlign: "center",
                    background: "white",
                    color: "#00733E",
                    fontWeight: "700",
                    fontSize: "14px",
                    borderRadius: "0px",
                  }}
                  variant="contained"
                  onClick={(event) => {
                    if (
                      props?.totalOCP >
                        props?.ocpRestrictionData
                          ?.outstanding_ocp_amount_allowed &&
                      localStorage.getItem(`creditLine`) !== "FINBOX"
                    ) {
                      props?.handleMadeFreeLeadAttempt();
                    }
                    handleWhatsappClickChat(event);
                  }}
                >
                  <ReactWhatsapp
                    style={{
                      background: "transparent",
                      border: "0px",
                      color: "#00733E",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                    number={`+91${props?.leadInformation?.farmerAddress?.farmerPhone}`}
                    message={`${getMessage(`grahak.whatsappMessage1`)} *${
                      props?.leadInformation?.farmerAddress?.farmerName
                    }* ${getMessage(
                      `grahak.whatsappMessage2`
                    )} ${productNames} ${getMessage(
                      `grahak.whatsappMessage3`
                    )}*${localStorage.getItem("partnerName")}*${getMessage(
                      `grahak.whatsappMessage4`
                    )}${localStorage.getItem(
                      "district"
                    )}, ${localStorage.getItem(
                      "state"
                    )}, ${localStorage.getItem("pinCode")} ${getMessage(
                      `grahak.whatsappMessage5`
                    )}${localStorage.getItem("mobile")}`}
                  >
                    <img
                      src="/icons/whatsappLeadsChat.svg"
                      style={{ height: "30px" }}
                    />{" "}
                    &nbsp; <span style={{ alignSelf: "center" }}>Message</span>
                  </ReactWhatsapp>
                </IconButton>
              )}
            </>
          )}

        {props?.leadsTabValue === "active" &&
          !_.includes(
            GRAHAK_CHAT_SOURCES_ENABLED,
            localStorage.getItem("source")
          ) && (
            <>
              {props?.blockLeadsInteraction() ? (
                <IconButton
                  sx={{
                    height: "50px",
                    width: "50%",
                    textAlign: "center",
                    background: "#9D2123",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "14px",
                    borderRadius: "0px",
                  }}
                  onClick={(event) => {
                    props?.handleLeadsAction(event, false, "", "leads");
                    props?.setIsLeadsBlocked(true);
                    event.stopPropagation();
                  }}
                  variant="contained"
                >
                  <img src="/icons/callCancell.svg" /> &nbsp; Decline
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    height: "50px",
                    width: "50%",
                    textAlign: "center",
                    background: "#9D2123",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "14px",
                    borderRadius: "0px",
                  }}
                  onClick={(event) => {
                    if (
                      props?.totalOCP >
                        props?.ocpRestrictionData
                          ?.outstanding_ocp_amount_allowed &&
                      localStorage.getItem(`creditLine`) !== "FINBOX"
                    ) {
                      props?.handleMadeFreeLeadAttempt();
                    }
                    props.handleLeadsRejectScreenView(event);
                  }}
                  variant="contained"
                >
                  <img src="/icons/callCancell.svg" /> &nbsp; Decline
                </IconButton>
              )}
            </>
          )}

        {(props?.leadsTabValue === "active" ||
          props?.leadsTabValue === "sale") && (
          <>
            {props?.blockLeadsInteraction() ? (
              <a
                style={{ textDecoration: "none" }}
                onClick={(event) => {
                  props?.handleLeadsAction(
                    event,
                    false,
                    "",
                    props?.leadInformation
                  );
                  props?.setIsLeadsBlocked(true);
                  event.stopPropagation();
                }}
              >
                <IconButton
                  sx={{
                    height: "50px",
                    width:
                      props?.leadsTabValue === "active" ||
                      _.includes(
                        GRAHAK_CHAT_SOURCES_ENABLED,
                        localStorage.getItem("source")
                      )
                        ? "50%"
                        : "100%",
                    textAlign: "center",
                    background: "#00733E",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "14px",
                    borderRadius: "0px",
                  }}
                  variant="contained"
                  // onClick={(event) => props.handleLeadsCallAttemptViewScreen(event)}
                >
                  <img src="/icons/callNow.svg" /> &nbsp; Call Now
                </IconButton>
              </a>
            ) : (
              <a
                href={`tel:${props?.leadInformation?.farmerAddress?.farmerPhone}`}
                style={{ textDecoration: "none" }}
                onClick={(event) => {
                  if (
                    props?.totalOCP >
                      props?.ocpRestrictionData
                        ?.outstanding_ocp_amount_allowed &&
                    localStorage.getItem(`creditLine`) !== "FINBOX"
                  ) {
                    props?.handleMadeFreeLeadAttempt();
                  }
                  props.handleLeadsCallAttemptViewScreen(event);
                }}
              >
                <IconButton
                  sx={{
                    height: "50px",
                    width:
                      props?.leadsTabValue === "active" ||
                      _.includes(
                        GRAHAK_CHAT_SOURCES_ENABLED,
                        localStorage.getItem("source")
                      )
                        ? "50%"
                        : "100%",
                    textAlign: "center",
                    background: "#00733E",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "14px",
                    borderRadius: "0px",
                  }}
                  variant="contained"
                  // onClick={(event) => props.handleLeadsCallAttemptViewScreen(event)}
                >
                  <img src="/icons/callNow.svg" /> &nbsp; Call Now
                </IconButton>
              </a>
            )}
          </>
        )}
      </Box>
    </SwipeableDrawer>
  );
}

export default LeadsDetail;
