import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Container, Grid, Skeleton } from "@mui/material";

import SectionTitle from "../Common/SectionTitle";
import ViewAllButton from "../Common/ViewAllButton";
import HorizontalProductCard from "../Product/HorizontalProductCard";
import ProductService from "../../Services/ProductService";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    marginTop: theme.spacing(2),
  },
  loaderContent: {},
  mainContent: {
    // backgroundColor: "#F3F3F3",
    width: "602px",
  },
  loaderWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
  viewAllBrandsButtonWrapperLink: {
    textDecoration: "none",
  },
  sectionTitle: {
    marginTop: "20px",
  },
}));

function HorizontalProductCardContainer({
  products,
  loading,
  error,
  title,
  viewAllUrl,
  numberOfProductsToShow,
  stateCode,
  backgroundColor,
  saveScrollYPosition,
}) {
  const classes = useStyles();

  let containerHeight = "350px";
  if (products?.length === 1) {
    containerHeight = "120px";
  } else if (products?.length === 2) {
    containerHeight = "240px";
  } else {
  }

  let containerWidth = "602px";
  if (products?.length < 4) {
    containerWidth = "100%";
  }

  return (
    <div style={{ background: backgroundColor, paddingBottom: "10px" }}>
      {loading === "done" &&
        (!error || !error?.data) &&
        products &&
        products.length > 0 && (
          <Container maxWidth={`xl`} className={classes.sectionTitle}>
            <Box sx={{ marginTop: "20px", paddingTop: "10px" }}>
              <SectionTitle title={title} />
              <div
                style={{ marginTop: "10px", overflow: "auto", width: "104%" }}
              >
                <Grid
                  container
                  direction="column"
                  sx={{
                    flexWrap: "wrap",
                    width: containerWidth,
                    height: containerHeight,
                  }}
                  spacing={0}
                >
                  {_.map(
                    products.slice(
                      0,
                      numberOfProductsToShow
                        ? numberOfProductsToShow
                        : products.length
                    ),
                    (product, index) => {
                      return (
                        ProductService.checkIfProductEnabled(product) && (
                          <Grid
                            item
                            xs={6}
                            sx={{ flex: "1 1", width: "288px" }}
                            key={index}
                          >
                            <HorizontalProductCard
                              product={product}
                              key={product.skuCode}
                              index={index}
                              section={title}
                              stateCode={stateCode}
                              saveScrollYPosition={saveScrollYPosition}
                            />
                          </Grid>
                        )
                      );
                    }
                  )}
                  {products?.length === 5 && (
                    <Grid
                      item
                      xs={6}
                      sx={{ flex: "1 1", width: "288px" }}
                    ></Grid>
                  )}
                </Grid>
              </div>
            </Box>
            {(!error || !error?.data) &&
              products &&
              products.length > numberOfProductsToShow && (
                <Box sx={{ marginBottom: "10px", marginTop: "-10px" }}>
                  <Link
                    to={viewAllUrl}
                    className={classes.viewAllBrandsButtonWrapperLink}
                  >
                    <ViewAllButton
                      section={title}
                      saveScrollYPosition={saveScrollYPosition}
                    />
                  </Link>
                </Box>
              )}
          </Container>
        )}

      {loading === "started" && !error && (
        <>
          <Skeleton
            variant={`text`}
            width={300}
            style={{ marginLeft: "16px", marginTop: "8px" }}
          />
          <div className={classes.loaderWrapper}>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.loaderContent}
              wrap={"nowrap"}
              spacing={2}
            >
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default HorizontalProductCardContainer;
