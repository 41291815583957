import { Box, Container, Typography } from "@mui/material";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import InfiniteScroll from "react-infinite-scroll-component";
import FieldAgentOrderCard from "./FieldAgentOrderCard";
import EmptyError from "../EmptyError/EmptyError";
import CustomDateRange from "../Hisaab/CustomDateRange";
import { useEffect } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import PartnersSearch from "../Partners/PartnersSearch";

const FieldAgentOrders = ({
  loading,
  ordersList,
  currentCount,
  totalCount,
  getAgentOrders,
  orderDateRangeFilter,
  setOrderDateRangeFilter,
  handleClearOrderDateRangeFilter,
  handleSearch,
  clearSearch,
  searchKeyword,
}) => {
  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, { page: "Agent Orders" });
  }, []);

  return (
    <BlockUi
      tag="div"
      blocking={loading === "started"}
      message={`Loading...`}
      style={{ minHeight: "85vh" }}
      keepInView
    >
      <Container maxWidth={`xl`} sx={{ pb: 1 }}>
        <PartnersSearch
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          searchKeyword={searchKeyword}
          page={"Agent Orders"}
        />
      </Container>
      <Container maxWidth={`xl`} sx={{ padding: 0 }}>
        <Box
          sx={{
            padding: "0px 16px",
            marginBottom: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomDateRange
            filterLabel="Filter By Date"
            maxDate={new Date()}
            selectedDateRangeFilter={orderDateRangeFilter}
            setSelectedDateRangeFilter={setOrderDateRangeFilter}
            page={"My Orders"}
          />
          <Typography
            sx={{
              color: "#33333380",
              fontWeight: "700",
              fontSize: "12px",
            }}
            onClick={handleClearOrderDateRangeFilter}
          >
            Clear All
          </Typography>
        </Box>
        {ordersList?.length ? (
          <InfiniteScroll
            dataLength={currentCount}
            next={getAgentOrders}
            hasMore={currentCount < totalCount}
            loader={<p style={{ textAlign: "center" }}>Loading...</p>}
            style={{ padding: "10px 16px 125px 16px" }}
          >
            {ordersList?.map((order) => (
              <FieldAgentOrderCard key={order?.orderId} order={order} />
            ))}
          </InfiniteScroll>
        ) : (
          loading === "done" && (
            <EmptyError text="No Orders Found" hideBack={true} />
          )
        )}
      </Container>
    </BlockUi>
  );
};

export default FieldAgentOrders;
