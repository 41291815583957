import config from "../config";
import OrdersMock from "./mocks/OrdersMock";
import _ from "lodash";
import OrdersHistoryBySettlementMock from "./mocks/OrdersHistoryBySettlementMock";
import InvoiceAvailabilityMock from "./mocks/InvoiceAvailabilityMock";
import format from "date-fns/format";
import CommonConstantValues from "./CommonConstantValues";
import moment from "moment/moment";
import PartnerService from "./PartnerService";
import RecentOrderDetailsMock from "./mocks/RecentOrderDetailsMock";
import HTTPService from "./HTTPService";

const useMocks = false;
const filterByPaymentAmount = true;

export default class OrderService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getAllOrders(
    isLazyLoading,
    offset = 0,
    limit = 10,
    getFieldWaitingForApprovalOrderOnly = false
  ) {
    if (useMocks === false) {
      let url = `${
        config.backendUrl
      }onlineecommerceservice/v2/getorders/?language=en&farmerId=${localStorage.getItem(
        `farmerId`
      )}&excludeOrderTypes=RETURN_ORDER`;

      if (isLazyLoading) {
        url += `&limit=${limit}&offset=${offset}`;
      }

      // if false, ALL orders are fetched (including "waiting for approval by partner" orders)
      if (getFieldWaitingForApprovalOrderOnly) {
        url += `&getFieldWaitingForApprovalOrderOnly=true`;
      }

      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static getOrder(orderId, walletUserId, orderType) {
    let getCancellableByFieldAgentFlag = undefined;
    if (localStorage.getItem("role") === "fieldAgent") {
      if (orderType !== "fieldAgentLead") {
        getCancellableByFieldAgentFlag = true;
      }
    }

    const url = `${
      config.backendUrl
    }onlineecommerceservice/v2/getorderdetail/?orderId=${orderId}${
      walletUserId ? `&walletUserId=${walletUserId}` : ``
    }${getCancellableByFieldAgentFlag ? `&getCancellable=true` : ``}`;

    const headers = this.getHeaders();
    if (localStorage.getItem("role") === "fieldAgent") {
      headers["source"] = localStorage.getItem("fieldAgentSource");
    }

    return HTTPService.makeXHRRequest(url, "GET", null, headers);
  }

  static filterOrders(orders) {
    return _.filter(orders, (order) => {
      if (
        order?.status.toLowerCase() === `cancelled` &&
        order.crmStatus &&
        _.includes(order.crmStatus.toLowerCase(), `edited`)
      ) {
        return false;
      } else {
        if (filterByPaymentAmount === true) {
          if (
            order.status.toLowerCase() === `cancelled` &&
            order.crmStatus.toLowerCase() === `cancelled` &&
            order.paymentDetails?.paymentAmount > 0
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    });
  }

  static getOrderInvoice(wmsOrderId) {
    if (useMocks === false) {
      const url = `${config.backendUrl}invoiceprintservice/get/invoice?orderId=${wmsOrderId}`;
      return HTTPService.makeXHRRequest(
        url,
        "GET",
        null,
        { ...this.getHeaders(), "Content-type": "application/json" },
        "",
        "blob"
      );
    }
  }

  static checkIfInvoiceIsPresent(wmsOrderId) {
    if (useMocks === false) {
      const url = `${config.backendUrl}invoiceprintservice/check/invoice?orderId=${wmsOrderId}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: InvoiceAvailabilityMock });
      });
    }
  }

  static getAllReturnableOrders(returnableTillDays) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }onlineecommerceservice/recentorderstatistics/?farmerId=${localStorage.getItem(
        `farmerId`
      )}&daysFilter=${returnableTillDays}&returnOrder=true&language=en`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static getReturnOrderDetails(returnOrderId) {
    if (useMocks === false) {
      const url = `${config.backendUrl}onlineecommerceservice/ordersummary/?isReturnOrder=true&orderId=${returnOrderId}&language=en`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static createReturnOrder({ payload }) {
    const userRole =
      localStorage.getItem(`role`) === "fieldAgent" ? "FIELD_AGENT" : "SAATHI";

    if (useMocks === false) {
      const url = `${config.backendUrl}ordermanagementservice/returnorder/`;
      return HTTPService.makeXHRRequest(url, "POST", payload, {
        ...this.getHeaders(),
        client: userRole,
      });
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static getAllReturnOrdersList() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }/ordermanagementservice/v1/farmerorderdetails/?farmerId=${localStorage.getItem(
        `farmerId`
      )}&isReturnOrder=true`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static getMinimumOrderValue() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }/ordermanagementservice/v4/minimumordervalue/?farmerId=${localStorage.getItem(
        `farmerId`
      )}&source=${localStorage.getItem(
        `source`
      )}&state=${localStorage.getItem(`state`)}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({
          data: { responseData: { minimumOrderValue: 5000.0 } },
        });
      });
    }
  }

  static getOrderHistoryBySettlement(
    offset,
    limit,
    searchTerm,
    selectedFilter,
    orderCreationDateRangeFilter
  ) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }onlineecommerceservice/v3/orders/?walletUserId=${localStorage.getItem(
        "userId"
      )}&language=en&offset=${offset}&limit=${limit}${
        searchTerm && searchTerm !== "" ? `&searchTerm=${searchTerm}` : ``
      }${
        selectedFilter && !searchTerm ? `&paymentstatus=${selectedFilter}` : ``
      }${
        orderCreationDateRangeFilter && !searchTerm
          ? `&filterType=createdOn&fromDate=${
              orderCreationDateRangeFilter?.[0]
                ? format(orderCreationDateRangeFilter?.[0], "t") * 1000
                : ``
            }&toDate=${
              orderCreationDateRangeFilter?.[1]
                ? format(
                    orderCreationDateRangeFilter?.[1]?.setHours(
                      23,
                      59,
                      59,
                      999
                    ),
                    "t"
                  ) * 1000
                : ``
            }`
          : ``
      }`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: OrdersHistoryBySettlementMock });
        }, 2000);
      });
    }
  }

  static disablePayNowForOrder(order) {
    if (
      this.isLastSuccessfulPaymentInProgressForOrder(order) ||
      !order?.isPayNowEnabled ||
      config.disablePayNow ||
      (localStorage.getItem("role") !== "fieldAgent" &&
        PartnerService.isPartnerOnboardingInProgress())
    ) {
      return true;
    }
    return false;
  }

  static isLastSuccessfulPaymentInProgressForOrder(order) {
    var orderPaymentSuccessDetailsFromRazorpay = [];
    if (
      localStorage.getItem("orderPaymentSuccessDetailsFromRazorpay") &&
      localStorage.getItem("orderPaymentSuccessDetailsFromRazorpay") !== ""
    ) {
      orderPaymentSuccessDetailsFromRazorpay = JSON.parse(
        localStorage.getItem("orderPaymentSuccessDetailsFromRazorpay")
      );
    }

    var lastPaymentDetails = _.find(
      orderPaymentSuccessDetailsFromRazorpay,
      (paymentDetails) => {
        if (order?.productSalesGroup) {
          return paymentDetails.productSalesGroup === order?.productSalesGroup;
        }
        return paymentDetails.orderId == order?.orderId;
      }
    );

    if (lastPaymentDetails) {
      var lastSuccessfulPaymentEpochForOrder =
        lastPaymentDetails?.paymentSuccessCaptureEpoch;
      var currentEpoch = Date.now();
      const timeInMinutesSinceLastSuccessfulPaymentForOrder = moment
        .duration(currentEpoch - lastSuccessfulPaymentEpochForOrder)
        .as("minutes");

      if (
        timeInMinutesSinceLastSuccessfulPaymentForOrder <
        CommonConstantValues.PAY_NOW_BLOCK_DURATION_AFTER_ORDER_PAYMENT_SUCCESS_MINUTES
      ) {
        return true;
      }
    }
    return false;
  }

  static getRecentOrders(days, type) {
    if (useMocks === false) {
      const farmerId = localStorage.getItem(`farmerId`);
      const url = `${config.backendUrl}onlineecommerceservice/recentorderdetails/?daysFilter=${days}&farmerId=${farmerId}&type=${type}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: RecentOrderDetailsMock });
        }, 2000);
      });
    }
  }

  static cancelOrder(orderId, cancellationReason) {
    const isCancellationByAgent = localStorage.getItem("role") === "fieldAgent";
    const payload = {
      cancellationReason: cancellationReason ? [cancellationReason] : [],
    };

    if (useMocks === false) {
      const url = `${config.backendUrl}onlineecommerceservice/v1/saathiorder/orders/${orderId}/cancel/`;
      return HTTPService.makeXHRRequest(url, "PUT", payload, {
        ...this.getHeaders(),
        source: isCancellationByAgent
          ? localStorage.getItem(`fieldAgentSource`)
          : localStorage.getItem(`source`),
      });
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({
            data: {
              responseData: {
                message: "Cancelled",
              },
            },
          });
        }, 2000);
      });
    }
  }

  static approveOrder({ orderId, redirectUrl }) {
    if (useMocks === false) {
      const payload = {};
      if (redirectUrl) {
        payload["redirectUrl"] = redirectUrl;
      }
      const url = `${config.backendUrl}onlineecommerceservice/v1/saathiorder/orders/${orderId}/approve/`;
      return HTTPService.makeXHRRequest(url, "PUT", payload, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({
            data: {
              responseData: {
                deliveryData: {
                  deliveryDate: 1709317800000,
                  facilityCode: "",
                  hasError: false,
                  shippingProvider: "Saathiself",
                  tatObjectId: "ff2e8788-8964-4e59-bcce-0d09d2afe486",
                  orderId: 1728085,
                },
              },
            },
          });
        }, 2000);
      });
    }
  }

  static getOrderCancellationReasons() {
    if (useMocks === false) {
      const url = `${config.backendUrl}ordermanagementservice/v1/cancellationreasons/?type=cancel
      `;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static getProductGroups({ productSalesGroup, lendingPartner }) {
    const userId = localStorage.getItem(`userId`);
    let url = `${config.backendUrl}onlineecommerceservice/v3/paybyproductsalesgroup/?userId=${userId}`;
    if (productSalesGroup)
      url += `&productSalesGroup=${encodeURI(productSalesGroup)}`;
    if (lendingPartner && PartnerService.isPartnerExternalCreditType()) {
      url += `&lendingPartner=${encodeURI(lendingPartner)}`;
    }
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static getProductGroupDetails({ productSalesGroup, lendingPartner }) {
    const userId = localStorage.getItem(`userId`);
    let url = `${config.backendUrl}onlineecommerceservice/v3/paybyproductsalesgroupdetail/?userId=${userId}&productSalesGroup=${encodeURI(productSalesGroup)}`;
    if (lendingPartner && PartnerService.isPartnerExternalCreditType()) {
      url += `&lendingPartner=${encodeURI(lendingPartner)}`;
    }
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static markOrderDelivered(orderId) {
    const url = `${config.backendUrl}onlineecommerceservice/v1/saathipackageupdate/`;
    const deliveryTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const partnerName = localStorage.getItem("InstitutionDetails")
      ? JSON.parse(localStorage.getItem("InstitutionDetails"))?.partnerName
      : "";
    const fieldAgentUserId = localStorage.getItem("fieldAgentUserId");
    const updatedBy = PartnerService.checkIfFieldAgent()
      ? `Field Agent (${fieldAgentUserId})`
      : `Partner (${partnerName})`;
    const data = {
      orderId,
      deliveryTime,
      packageStatus: "delivered",
      updatedBy,
    };
    return HTTPService.makeXHRRequest(url, "PUT", data, this.getHeaders());
  }
}
