import config from "../config";
import HTTPService from "./HTTPService";
const useMocks = false;

export default class LeegalityService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static createAgreementSignRequest(farmerId) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/institution/${farmerId ? farmerId : localStorage.getItem("farmerId")}/v2/agreement/`;
      return HTTPService.makeXHRRequest(url, "POST", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: {} });
      });
    }
  }

  static editEmailForAgreementSignRequest(institutionId, email, mobileNumber) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/institution/`;
      const data = {
        updateType: "Email",
        institutionId: institutionId,
        contacts: {
          email: email.trim(),
          mobileNumber: mobileNumber,
        },
      };
      const headers = {
        source: "B2B",
        "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
      };
      return HTTPService.makeXHRRequest(url, "PATCH", data, headers);
    } else {
      return new Promise((resolve) => {
        resolve({ data: {} });
      });
    }
  }

  static getInstitutionMetaInfo(farmerId) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/institution/${farmerId ? farmerId : localStorage.getItem("farmerId")}/v2/agreement/meta/`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({
          data: {
            message: "OK",
            responseData: {
              storeOwnerName: "",
              storeOwnerAddress: "",
              storeName: "",
              emailId: "",
              businessType: "",
              businessTypeMetaInformation: [
                {
                  name: "",
                  address: "",
                },
              ],
              securityDepositAmount: "",
            },
            status: true,
          },
        });
      });
    }
  }

  static editDetailsForAgreementSignRequest(farmerId, payload) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/institution/${farmerId ? farmerId : localStorage.getItem("farmerId")}/v2/agreement/meta/`;
      const headers = {
        source: "B2B",
        "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
      };
      return HTTPService.makeXHRRequest(url, "POST", payload, headers);
    } else {
      return new Promise((resolve) => {
        resolve({ data: {} });
      });
    }
  }
}
