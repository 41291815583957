import { Grid } from "@mui/material";
import _ from "lodash";

const BrandsFilterList = (props) => {
  return (
    <Grid container justifyContent={`center`} alignItems={`center`}>
      {_.map(props.brands, (brand, index) => {
        return (
          <Grid
            item
            key={index}
            xs={5}
            sx={{
              background: "#FFFFFF",
              borderRadius: "10px",
              margin: "5px",
              padding: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `${
                _.find(props?.brandsSelected, brand)
                  ? `1px solid #9D2123`
                  : `1px solid #FFFFFF`
              }`,
            }}
            onClick={() => {
              props?.handleClickBrand(brand);
            }}
          >
            <img
              src={brand.brandImage}
              alt={brand.brandName}
              style={{ height: "46px", borderRadius: "10px" }}
            />
          </Grid>
        );
      })}
      {props?.brands?.length % 2 !== 0 && (
        <Grid item xs={5} sx={{ margin: "5px", padding: "4px" }} />
      )}
    </Grid>
  );
};

export default BrandsFilterList;
