import React, { useEffect, useState } from "react";
import { useNavigate, matchPath, useLocation } from "react-router-dom";
import BlockUi from "react-block-ui";

import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Tabs,
  Tab,
  Skeleton,
  CardMedia,
  CardContent,
  Card,
  Typography,
} from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import NeedHelp from "../Common/NeedHelp";
import TopBar from "../Common/TopBar";
import TransactionDetails from "./TransactionsDetails";
import HisaabService from "../../Services/HisaabService";
import AnalyticsService from "../../Services/AnalyticsService";
import AmountUtils from "../../Utils/AmountUtils";

const tabs = [
  {
    name: "AgroStar Points",
    expiryText: "Has Expiry Date",
    image: "/icons/agrostarPoints.svg",
    path: "/wallet",
    border: "1.5px solid #9D2123",
  },
  {
    name: "AgroStar Real Cash",
    expiryText: "No Expiry",
    image: "/icons/agrostarRealCash.svg",
    path: "/wallet/real-cash",
    border: "1.5px solid #FFFFFF",
  },
];

const useStyles = makeStyles((theme) => ({
  blockUI: {
    height: "135px",
  },
  tab: {
    width: "50%",
    height: "115px",
    textTransform: "capitalize",
    margin: theme.spacing(0.5, 0.5, 2, 0.5),
    padding: theme.spacing(1.5, 0.5),
  },
  cards: {
    width: "95%",
    height: "100%",
    color: "#ffffff",
    textAlign: "left",
    borderRadius: theme.spacing(1.3),
    boxShadow: "0px 8px 4px 0px #e5e5e5",
  },
  cardContent: {
    padding: theme.spacing(0.5, 0, 0, 1),
    lineHeight: 0,
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <i className="arrow"></i>,
    }}
  ></Tabs>
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .arrow": {
    margin: "-8px",
    border: "24px solid #9D2123",
    borderWidth: "0 48px 48px 0",
    transform: "rotate(45deg)",
    " -webkit-transform": "rotate(45deg)",
  },
});

const Wallet = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  let navigate = useNavigate();

  const [loading, setLoading] = useState("not_started");
  const [walletDetails, setWalletDetails] = useState({});

  const useRouteMatch = (patterns) => {
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  };

  const RouteMatch = useRouteMatch(["/wallet", "/wallet/real-cash"]);
  const CurrentTab = RouteMatch?.pathname;
  const CurrentPage =
    CurrentTab === "/wallet"
      ? "AGROSTAR POINTS"
      : CurrentTab === "/wallet/real-cash"
        ? "REAL CASH"
        : "";

  useEffect(() => {
    setLoading("started");
    const { axiosRequest, abortController } = HisaabService.getWalletBreakup();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setWalletDetails(response.data.responseData || {});
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoading("done");
        }
      });

    const walletPageViewedData = {
      page: CurrentPage,
    };
    AnalyticsService.pushEvent(`Wallet Page Viewed`, walletPageViewedData);
    return () => abortController.abort();
  }, [CurrentPage]);

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`AgroStar Wallet`}
          backNavigation={true}
          showCart={false}
          showHisaab={false}
        />
      </ErrorBoundary>

      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        className={classes.blockUI}
      >
        <ErrorBoundary message={``}>
          <StyledTabs
            centered
            variant="fullWidth"
            value={CurrentTab}
            onChange={() =>
              navigate(
                CurrentTab === "/wallet" ? "/wallet/real-cash" : "/wallet",
                { replace: true }
              )
            }
          >
            {tabs?.map((tab, index) => (
              <Tab
                key={index}
                value={tab.path}
                className={classes.tab}
                label={
                  <Card className={classes.cards} sx={{ border: tab.border }}>
                    <CardMedia
                      image={tab.image}
                      alt={tab.name}
                      sx={{
                        height: "156px",
                        width: "100%",
                        objectFit: "cover",
                        "@media screen and (max-width: 600px)": {
                          height: "116px",
                        },
                      }}
                    >
                      <CardContent className={classes.cardContent}>
                        <Typography sx={{ fontSize: "14px" }}>
                          <b>{tab.name}</b>
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                          {tab.expiryText}
                        </Typography>
                        <Typography sx={{ fontSize: "18px", marginTop: 2 }}>
                          {tab.name === tabs[0].name && (
                            <b>
                              {AmountUtils.formatCurrency(
                                walletDetails?.totalUsableCashbackPoints || 0
                              )}
                            </b>
                          )}
                          {tab.name === tabs[1].name && (
                            <b>
                              {AmountUtils.formatCurrency(
                                walletDetails?.totalRealUsableAmount || 0
                              )}
                            </b>
                          )}
                        </Typography>
                      </CardContent>
                    </CardMedia>
                  </Card>
                }
              />
            ))}
          </StyledTabs>

          {loading === "started" && (
            <Skeleton variant="rectangular" height={1000} />
          )}
        </ErrorBoundary>
      </BlockUi>

      <TransactionDetails
        isLoading={loading}
        currentTab={CurrentTab}
        currentPage={CurrentPage}
      />

      <ErrorBoundary>
        <NeedHelp title={`Need Help`} />
      </ErrorBoundary>
    </>
  );
};

export default Wallet;
