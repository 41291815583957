import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Global } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Card, Grid, SwipeableDrawer, Typography } from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";

const tabs = [
  {
    path: "/krishi-gyan",
    name: "Krishi Gyan",
    iconPath: "/icons/krishiGyan.svg",
  },
  {
    path: "/nearby-farmers",
    name: "Grahak",
    iconPath: "/icons/farmerTurban.svg",
  },
  {
    path: "/promotion",
    name: "Prachaar",
    iconPath: "/icons/prachaar.svg",
  },
];

const useStyles = makeStyles({
  wrapper: {
    margin: "20px 0 60px",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  puller: {
    width: 50,
    height: 6,
    position: "absolute",
    top: 10,
    left: "calc(50% - 25px)",
    borderRadius: 3,
    backgroundColor: "#ddd",
  },
  tabName: {
    display: "block",
    marginTop: 0.5,
    fontWeight: 500,
    color: "#0009",
  },
  leadsCount: {
    width: "40px",
    height: "40px",
    margin: "auto",
    fontSize: "18px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    background: "linear-gradient(16.55deg, #853F4F 0%, #E54B25 122.59%)",
    borderRadius: "50%",
    border: "0.5px solid white",
  },
});

export default function ExpandMoreDrawer({ open, setOpen, leadsCount }) {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    AndroidService.setPullToRefresh();
  }, []);

  return (
    <>
      {open && (
        <Global styles={{ ".MuiDrawer-root": { zIndex: "1055 !important" } }} />
      )}

      <SwipeableDrawer
        anchor={`bottom`}
        open={open}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        onOpen={() => AndroidService.unsetPullToRefresh()}
        onClose={() => {
          setOpen(false);
          AndroidService.setPullToRefresh();
        }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          },
        }}
      >
        <div className={classes.puller}></div>
        <Grid container className={classes.wrapper}>
          {tabs?.map((tab, index) => (
            <Grid item xs={3} key={index}>
              <Card
                elevation={0}
                sx={{ position: "relative", padding: 1, textAlign: "center" }}
                onClick={() => {
                  setOpen(false);
                  AnalyticsService.pushEvent(`Tab Clicked`, {
                    tabName: tab.name,
                  });
                  tab.path && navigate(tab.path);
                }}
              >
                {tab.name === "Grahak" && leadsCount ? (
                  <div className={classes.leadsCount}>{leadsCount}</div>
                ) : (
                  <img
                    src={tab.iconPath}
                    alt={tab.name}
                    style={{ height: "25px", marginTop: "12px" }}
                  />
                )}
                <Typography variant={`caption`} className={classes.tabName}>
                  {tab.name}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </SwipeableDrawer>
    </>
  );
}
