import Fab from "@mui/material/Fab";
import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AnalyticsService from "../../Services/AnalyticsService";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  createReturnButton: {
    background: "rgba(157, 33, 35, 0.7)",
    position: "fixed",
    right: 16,
    [theme.breakpoints.up("sm")]: {
      right: "30vw",
    },
    color: "white",
    fontWeight: "700",
    fontSize: "15px",
    textTransform: "capitalize",
    borderRadius: "47.8px",
    height: "40px",
    boxShadow: "0px, 8.39px rgba(157, 33, 35, 0.2)",
  },
  buttonImage: {
    height: "22px",
  },
}));

const CreateReturnDialog = (props) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const handleCreateReturn = () => {
    const createReturnEventData = {
      page: `/orders/return`,
    };
    localStorage.setItem("createReturnInitiated", true);
    AnalyticsService.pushEvent(`Create Return Clicked`, createReturnEventData);
    navigate("/orders/return/select-product");
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Fab
          onClick={handleCreateReturn}
          variant="extended"
          className={classes.createReturnButton}
          color="primary"
          style={{ bottom: 30, fontSize: "12px" }}
        >
          <img src={`/icons/returnFab.svg`} className={classes.buttonImage} />
          {!scrollTop ? <>&nbsp;&nbsp;{props.title}</> : ""}
        </Fab>
      </Box>
    </>
  );
};

export default CreateReturnDialog;
