// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "AGS-VIR-017": {
    bookingPricePerUnit: 100,
    startTime: "2024-08-01 11:00 AM",
    endTime: "2024-09-30 11:00 AM",
    productCardImage: {},
    schemeDetails: [
      {
        title: "Mustard Advance Booking Scheme - Rabi 2024",
        type: "text",
        description: ["12th August 2024 to 27th August 2024"],
      },
      {
        title: "Base Benefit:",
        type: "image",
        description: [
          "https://static.agrostar.in/saathi/advance-booking-scheme/mustardabs_base_benefit_Aug_2024.jpg",
        ],
      },
      {
        title: "Investment Slab Benefit:",
        type: "image",
        description: [
          "https://static.agrostar.in/saathi/advance-booking-scheme/mustardabs_inv_benefit_Aug_2024.jpg",
        ],
      },
      {
        title: "Key Features:",
        type: "text",
        description: [
          "The benefits will be purely calculated on the basis of net booked and sold revenue/ quantity/ volume and order payment/settlement within the specified Credit period for all the purchases made under the aforementioned scheme.",
          "Example: If the partners book 2500 kgs in early bird and",
          "Case 1: if the partner sells 2500 kgs then he will get the benefit of 25+30= 55 per kg on 2500 kgs only.",
          "Case 2: if the partner sells 3000 kgs, he will get a benefit of 25+30 = 55 per kg on 2500 kgs only.",
          "Case 3: if the partner sells 2100 kgs, he will get a benefit of 25+30= 55 per packet on 2100 kgs only.",
          "b. Flat Top Up Advance Collection Scheme benefit for the above mentioned period table.",
        ],
      },
    ],
    schemeUnit: "Kg",
    schemeName: "Mustard Rabi 2024",
    schemeUnitMultiple: "Kg(s)",
  },
};
