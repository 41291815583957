import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";

import AnalyticsService from "../../Services/AnalyticsService";
import StarRatingLabel from "./StarRatingLabel";
import ProductService from "../../Services/ProductService";
import ProductListPrice from "../Dashboard/ProductListPrice";
import _ from "lodash";
import Clamp from "react-multiline-clamp";
import CommonConstantValues from "../../Services/CommonConstantValues";
import PayAdvanceSchemeProducts from "../../Services/PayAdvanceSchemeProducts";
import TurnoverSchemeProgressBar from "./TurnoverSchemeProgressBar";
import SchemesService from "../../Services/SchemesService";
import PartnerService from "../../Services/PartnerService";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  productCardWrapperLink: {
    textDecoration: `none`,
    color: "rgba(0, 0, 0, 0.87)",
  },
  productCardWrapper: {
    // width: theme.spacing(19),
    height: "260px", //theme.spacing(38.75),
    wordBreak: "break-word",
    border: "none",
    borderRadius: "10px",
    position: "relative",
    boxShadow: "0px 10px 10px #E0E0E0",
  },
  productCardWrapperTodRecommendation: {
    height: "260px", //theme.spacing(38.75),
    wordBreak: "break-word",
    border: "none",
    borderRadius: "10px",
    position: "relative",
  },
  productCardWrapperGrahakRecommendation: {
    // width: theme.spacing(19),
    height: "260px", //theme.spacing(38.75),
    wordBreak: "break-word",
    border: "none",
    borderRadius: "10px",
    position: "relative",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
  },
  productCardWrapperVideos: {
    height: "210px", //theme.spacing(38.75),
    wordBreak: "break-word",
    border: "none",
    borderRadius: "10px",
    position: "relative",
    // boxShadow: "0px 10px 10px #E0E0E0",
  },
  productCardWrapperWithoutWhatsappPromote: {
    // width: theme.spacing(19),
    height: "290px", //theme.spacing(38.75),
    wordBreak: "break-word",
    border: "none",
    borderRadius: theme.spacing(1),
    position: "relative",
    boxShadow: "0px 10px 10px #E0E0E0",
  },
  productCardContent: {
    padding: theme.spacing(1),
    paddingBottom: "0px !important", // theme.spacing(1),
  },
  ratingLabelWrapper: {
    position: "absolute",
    minHeight: theme.spacing(1),
    width: "50%",
    justifyContent: "flex-start",
    display: "flex",
    zIndex: 9,
  },
  productImageContainer: {
    textAlign: "center",
    marginTop: theme.spacing(0.5),
    width: "100%",
    // height: "150px",
  },
  productImage: {
    // height: "100%",
    // maxHeight: "120px",
    display: "inline-block",
    // width: "90%",
    maxWidth: "100px",
  },
  productNameWrapper: {
    margin: theme.spacing(-2.3, 0, 1.5, 0),
  },
  productName: {
    // maxHeight: "3.9em",
    // lineHeight: "1.3em",
    // letterSpacing: "0px",
    // wordWrap: "break-all",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // display: "-webkit-box",
    // "-webkit-line-clamp": 3 /* number of lines to show */,
    // "line-clamp": 3,
    // "-webkit-box-orient": "vertical",
    fontSize: "14px",
  },
  productNameOffer: {
    // maxHeight: "2.6em",
    // lineHeight: "1.3em",
    // letterSpacing: "0px",
    // wordWrap: "break-all",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // display: "-webkit-box",
    // "-webkit-line-clamp": 2 /* number of lines to show */,
    // "line-clamp": 2,
    // "-webkit-box-orient": "vertical",
    fontSize: "14px",
  },
  productPrice: {
    // maxHeight: "3.9em",
    // lineHeight: "1.3em",
    // letterSpacing: "0px",
    // wordWrap: "break-word",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // display: "-webkit-box",
    // "-webkit-line-clamp": 3 /* number of lines to show */,
    // "line-clamp": 3,
    // "-webkit-box-orient": "vertical",
    fontSize: "12px",
    marginTop: "4px",
  },
  productMrp: {
    color: "#666666",
    textDecoration: "line-through",
    marginLeft: theme.spacing(0.5),
  },

  cdBenefitIcon: {
    height: "14px",
    marginRight: theme.spacing(0.3),
    verticalAlign: "middle",
    marginTop: theme.spacing(-0.5),
  },
  offersIcon: {
    height: "14px",
    marginRight: theme.spacing(0.3),
    verticalAlign: "middle",
    marginTop: theme.spacing(-0.5),
  },
  marginSection: {
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    fontSize: "10px",
    color: "#00733E",
    background: "#E5F1EC",
  },
  topSection: {
    display: "flex",
    width: "100%",
    marginTop: "-5px",
  },
  whatsappShare: {
    justifyContent: "flex-end",
    width: "50%",
    display: "flex",
    flexDirection: "reverse",
    position: "relative",
    zIndex: 1,
  },
  whatsappShareEmptyDiv: {
    width: "50%",
    display: "flex",
  },

  promoteButton: {
    borderRadius: "7px",
    color: "white",
    border: "1px solid #00733E",
    height: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    position: "absolute",
    bottom: "8px",
    width: "93%",
    // background: "#00733E",
  },
}));

function ProductCard(props) {
  const classes = useStyles();
  let navigate = useNavigate();

  const isPayAdvanceSchemeActive = ProductService.isPayAdvanceSchemeActive(
    props.product?.skuCode
  );

  const showTodProgressBar = props?.product?.turnoverData?.turnoverDetails?.[0]
    ?.constraintsStatus?.[0]?.slabConstraintsStatus?.[0]
    ?.showTODProgressBarOnPLP
    ? true
    : false;

  const {
    lastAchievedSlabDetails,
    currentTargetSlabDetails,
    qualifiedSlabRelativePercentageAchieved,
  } = SchemesService.getDataForTurnoverSchemeProgressBar(
    showTodProgressBar,
    props?.product
  );

  const handleClickProductClick = () => {
    if (props?.saveScrollYPosition) {
      MiscUtils.saveScrollYPosition();
    }
    const productClickedEventData = {
      page: props?.page ? props.page : "",
      section: props?.section || "",
      position: props.index + 1 || "",
      mrp: props?.product?.mrp || 0,
      sellingPrice: props?.product?.sellingPrice || 0,
      isOfferAvailable:
        props?.product?.offers &&
        Object.entries(props?.product?.offers).length > 0
          ? true
          : false,
      productName: props?.product?.productName || "",
      skuCode: props?.product?.skuCode || "",
      todProgressBar: showTodProgressBar ? "Yes" : "No",
      todProgressLowerSlab: SchemesService.generateEntitlementDescription(
        lastAchievedSlabDetails?.entitlements?.entitlementList
      ),
      todProgressUpperSlab: SchemesService.generateEntitlementDescription(
        currentTargetSlabDetails?.entitlements?.entitlementList
      ),
      todPercentageCompletion: qualifiedSlabRelativePercentageAchieved
        ? qualifiedSlabRelativePercentageAchieved
        : ``,
    };

    AnalyticsService.pushEvent(`Product Card Clicked`, productClickedEventData);
    if (props.keyword) {
      let recentSearches = JSON.parse(localStorage.getItem(`recentSearches`));

      if (recentSearches) {
        if (
          recentSearches?.length < CommonConstantValues.RECENT_SEARCH_MAX_LIMIT
        ) {
          if (recentSearches.includes(props.keyword.trim())) {
            _.pull(recentSearches, props.keyword.trim());
          }
        } else {
          if (recentSearches.includes(props.keyword.trim())) {
            _.pull(recentSearches, props.keyword.trim());
          } else {
            recentSearches.pop();
          }
        }

        recentSearches.unshift(props.keyword.trim());
        localStorage.setItem(`recentSearches`, JSON.stringify(recentSearches));
      } else {
        localStorage.setItem(
          `recentSearches`,
          JSON.stringify([props.keyword.trim()])
        );
      }
    }
  };

  const handleWhatsappShareClick = (skuCode) => {
    const whatsappPromoteClickedEventData = {
      page: props?.page ? props.page : "",
      skuCode: skuCode,
    };
    AnalyticsService.pushEvent(
      `Whatsapp Promote Clicked From Product List Card`,
      whatsappPromoteClickedEventData
    );
    navigate({
      pathname: `/products/${skuCode}/promote`,
    });
  };

  const handlePromoteRecentlyBoughtProduct = (skuCode) => {
    const whatsappPromoteClickedEventData = {
      page: props?.page ? props.page : "",
      skuCode: skuCode,
    };
    AnalyticsService.pushEvent(
      `Whatsapp Promote Clicked From Recently Bought Product List Card`,
      whatsappPromoteClickedEventData
    );
    navigate({
      pathname: `/products/${skuCode}/promote`,
    });
  };

  return (
    <a
      href={`/products/${props.product.skuCode}?from=${window.location.pathname}`}
      className={classes.productCardWrapperLink}
      onClick={() => handleClickProductClick()}
    >
      <Grid item xs={12}>
        {props.product && props.product.skuCode && (
          <>
            <Card
              className={
                props.section === "Recently Bought Products"
                  ? classes.productCardWrapperWithoutWhatsappPromote
                  : props.section === "Grahak Products Recommendation"
                    ? classes.productCardWrapperGrahakRecommendation
                    : props.path === "/featured-videos"
                      ? classes.productCardWrapperVideos
                      : props?.section ===
                          "Turnover Scheme Products Recommendation"
                        ? classes.productCardWrapperTodRecommendation
                        : classes.productCardWrapper
              }
              style={{
                position: "relative",
                width: `${
                  props?.section === "Alternative Products" ? "156px" : ""
                }`,
              }}
              variant={"outlined"}
            >
              <CardContent className={classes.productCardContent}>
                <div className={classes.topSection}>
                  <div className={classes.whatsappShareEmptyDiv}></div>
                  <div className={classes.whatsappShare}>
                    {ProductService.getClassImageUrl(
                      props.product?.productClass
                    ) && localStorage.getItem("role") === "fieldAgent" ? (
                      <>
                        <img
                          style={{
                            height: "30px",
                            marginTop: "3px",
                            borderRadius: "5px",
                            position: "absolute",
                          }}
                          src={ProductService.getClassImageUrl(
                            props.product?.productClass
                          )}
                          alt=""
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <Box>
                  <div className={classes.ratingLabelWrapper}>
                    {props?.product?.rating ? (
                      <StarRatingLabel rating={props.product.rating.average} />
                    ) : (
                      <></>
                    )}
                  </div>

                  <Grid
                    container
                    direction={`row`}
                    justifyContent="center"
                    alignItems="flex-start"
                    className={classes.productImageContainer}
                    style={{
                      height:
                        props.path === "/featured-videos" ? "110px" : "150px",
                      position: "relative",
                    }}
                    spacing={0}
                  >
                    <Grid item xs={12}>
                      <img
                        className={classes.productImage}
                        alt={props.product.productName}
                        style={{
                          zIndex: 1,
                          width:
                            props.path === "/featured-videos" ? "58%" : "90%",
                        }}
                        src={ProductService.getProductCardImageUrl(
                          props.product,
                          isPayAdvanceSchemeActive
                        )}
                      />
                      {ProductService.getEventBasedSaleIconUrl(
                        props.product?.skuCode
                      ) && (
                        <img
                          src={ProductService.getEventBasedSaleIconUrl(
                            props.product?.skuCode
                          )}
                          alt=""
                          style={{
                            zIndex: 1,
                            width: "30px",
                            height: "30px",
                            position: "absolute",
                            top: "88px",
                            right: "0px",
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {props.newLaunch && (
                    <img
                      src={`/icons/newLaunch.svg`}
                      // height={`25px`}
                      alt={`New Launch`}
                      style={{
                        zIndex: 9,
                        position: "absolute",
                        top: "110px",
                        left: 0,
                      }}
                    />
                  )}
                  {props.product?.productName && (
                    <Grid
                      container
                      direction={`row`}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className={classes.productNameWrapper}
                      style={{ position: "relative" }}
                    >
                      <Grid item xs={12}>
                        <>
                          {props.product.offers !== null &&
                          (Object.entries(props.product.offers).length !== 0 ||
                            Object.entries(props.product.offersDetail)
                              .length !== 0) ? (
                            <Typography className={classes.productNameOffer}>
                              <Clamp withTooltip lines={3}>
                                {props.product.productName}
                              </Clamp>
                            </Typography>
                          ) : (
                            <Box className={classes.productName}>
                              <Typography className={classes.productNameOffer}>
                                <Clamp withTooltip lines={3}>
                                  {props.product.productName}
                                </Clamp>
                              </Typography>
                            </Box>
                          )}
                        </>
                      </Grid>

                      {isPayAdvanceSchemeActive ? (
                        <Grid item xs={12}>
                          <Typography
                            variant={"body2"}
                            color={"secondary"}
                            fontSize={12}
                            sx={{
                              filter: PartnerService.applySmudgedPriceFilter(
                                props?.product
                              ),
                              textTransform: "capitalize",
                            }}
                          >
                            Book @{" "}
                            <b>
                              ₹
                              {
                                PayAdvanceSchemeProducts?.[
                                  props?.product?.skuCode
                                ]?.bookingPricePerUnit
                              }
                              {PayAdvanceSchemeProducts?.[
                                props?.product?.skuCode
                              ]?.schemeUnit
                                ? `/- Per ${
                                    PayAdvanceSchemeProducts?.[
                                      props?.product?.skuCode
                                    ]?.schemeUnit
                                  }`
                                : ""}
                            </b>
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <ProductListPrice product={props?.product} />
                        </Grid>
                      )}

                      {/* <Grid item xs={12}>
                        <Typography className={classes.marginSection}>
                          Farmer Price <b>₹{appResponseData?.sellingPrice}</b>
                          <br />
                          Your Margin{" "}
                          <b>
                            ₹
                            {appResponseData?.sellingPrice -
                              props.product?.sellingPrice}
                          </b>
                        </Typography>
                      </Grid> */}
                    </Grid>
                  )}
                </Box>
              </CardContent>

              {PartnerService.isProductPriceBlocked(props?.product) &&
              !PartnerService.smudgePriceDueToOnboardingInProgress() ? (
                <>
                  {" "}
                  <Grid
                    container
                    alignItems={`center`}
                    sx={{
                      position: "absolute",
                      bottom:
                        props.section === "Recently Bought Products"
                          ? "35px"
                          : 0,
                    }}
                    justifyContent={`center`}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        background: "rgba(224, 224, 224, 1)",
                        marginTop: "-12px",
                        paddingBottom: "3px",
                        paddingTop: "4px",
                      }}
                    >
                      <Typography
                        variant={`body1`}
                        sx={{
                          color: "#333333",
                          fontSize: "12px",
                          marginLeft: "8px",
                        }}
                      >
                        Price will be available soon
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : isPayAdvanceSchemeActive ? (
                <>
                  <Grid
                    container
                    alignItems={`center`}
                    sx={{
                      position: "absolute",
                      bottom:
                        props.section === "Recently Bought Products"
                          ? "35px"
                          : 0,
                    }}
                    justifyContent={`center`}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        background: "#F8D149",
                        marginTop: "-12px",
                        paddingBottom: "3px",
                        paddingTop: "4px",
                      }}
                    >
                      <Typography
                        variant={`body1`}
                        sx={{
                          color: "#4F4F4F",
                          fontSize: "12px",
                          marginLeft: "4px",
                        }}
                      >
                        <img
                          src={`/icons/offersGreenIcon.svg`}
                          alt=""
                          className={classes.cdBenefitIcon}
                        />
                        Advance Booking Open
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : showTodProgressBar ? (
                <TurnoverSchemeProgressBar
                  section={props?.section}
                  product={props?.product}
                  showTodProgressBar={showTodProgressBar}
                  lastAchievedSlabDetails={lastAchievedSlabDetails}
                  currentTargetSlabDetails={currentTargetSlabDetails}
                  qualifiedSlabRelativePercentageAchieved={
                    qualifiedSlabRelativePercentageAchieved
                  }
                />
              ) : (
                <>
                  {props.product.offers !== null &&
                  (Object.entries(props.product.offers).length !== 0 ||
                    Object.entries(props.product.offersDetail).length !== 0) ? (
                    <>
                      {" "}
                      <Grid
                        container
                        alignItems={`center`}
                        justifyContent={`center`}
                        sx={{
                          position: "absolute",
                          bottom:
                            props.section === "Recently Bought Products"
                              ? "35px"
                              : 0,
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            marginTop: "-12px",
                            paddingBottom: "3px",
                            paddingTop: "4px",
                            marginLeft: "4px",
                          }}
                        >
                          {props.product.offers !== null &&
                            (Object.entries(props.product.offers).length !==
                              0 ||
                              Object.entries(props.product.offersDetail)
                                .length !== 0) && (
                              <Typography
                                variant={`body1`}
                                sx={{
                                  color: "#9D2123",
                                  fontSize: "12px",
                                  marginLeft: "4px",
                                }}
                              >
                                <img
                                  src={`/icons/offersRedIcon.svg`}
                                  alt=""
                                  className={classes.offersIcon}
                                />
                                Offers Applied
                              </Typography>
                            )}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    props?.product?.isCashDiscountApplicable && (
                      <>
                        {" "}
                        <Grid
                          container
                          alignItems={`center`}
                          sx={{
                            position: "absolute",
                            bottom:
                              props.section === "Recently Bought Products"
                                ? "35px"
                                : 0,
                          }}
                          justifyContent={`center`}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              background: "rgba(0, 115, 62, 0.1)",
                              marginTop: "-12px",
                              paddingBottom: "3px",
                              paddingTop: "4px",
                            }}
                          >
                            <Typography
                              variant={`body1`}
                              sx={{
                                color: "#00733E",
                                fontSize: "12px",
                                marginLeft: "4px",
                              }}
                            >
                              <img
                                src={`/icons/offersGreenIcon.svg`}
                                alt=""
                                className={classes.cdBenefitIcon}
                              />
                              Check CD Benefit
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )
                  )}
                </>
              )}

              {props.section === "Recently Bought Products" &&
                localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
                  // {
                  <div
                    style={{
                      padding: "5px",
                      marginTop: `${
                        props.product.offers !== null ? "-5px" : ""
                      }`,
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth={true}
                      className={classes.promoteButton}
                      color={"secondary"}
                      onClick={() =>
                        handlePromoteRecentlyBoughtProduct(
                          props.product.skuCode
                        )
                      }
                    >
                      <img
                        style={{ height: "16px" }}
                        src={`/icons/whatsappWhite.svg`}
                        alt=""
                      />
                      &nbsp;Promote
                    </Button>
                  </div>
                )}
            </Card>
          </>
        )}
      </Grid>
    </a>
  );
}

export default ProductCard;
