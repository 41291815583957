import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TopBar from "../Common/TopBar";
import ErrorBoundary from "../Common/ErrorBoundary";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";

import LeadsDetail from "./LeadsDetail";
import LeadsCallAttempt from "./LeadsCallAttempt";
import LeadsReject from "./LeadsReject";
import LeadService, {
  GRAHAK_CHAT_SOURCES_ENABLED,
} from "../../Services/LeadService";
import AnalyticsService from "../../Services/AnalyticsService";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { useNavigate } from "react-router-dom";
import HisaabService from "../../Services/HisaabService";
import ReactWhatsapp from "react-whatsapp";
import _ from "lodash";
import useMessages from "../../Hooks/useMessages";
import OnboardingStatusNudge from "../OnboardPartner/OnboardingStatusNudge";
import BackDropLoader from "../Common/BackDropLoader";

momentDurationFormatSetup(moment);
const useStyles = makeStyles((theme) => ({
  root: {
    height: "0px",
  },
  summaryContainer: {
    display: "flex",
    padding: "22px",
    background: "linear-gradient(16.55deg, #853F4F 0%, #E54B25 122.59%)",
    // marginTop: "-243px",
    paddingLeft: "0px",
    // paddingTop: "10px",
    // position: "absolute",
    position: "relative",
    zIndex: "-1",
    // top: "50px",
    width: "100%",
    "@media screen and (min-width: 600px)": {
      width: "600px",
    },
  },
  leadStatusType: {
    alignSelf: "center",
    color: "white",
  },
  leadsNumber: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "27px",
    textTransform: "capitalize",
    textAlign: "center",
    color: "white",
  },
  leadsText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "center",
    color: "white",
  },
  tabStyle: {
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "16px",
    color: "#333333",
    marginTop: "5px",
    // padding: "12px 9px",
  },
  MuiOutlinedInputNotchedOutline: {
    border: "1px solid rgba(157, 33, 35, 0.5) !important",
    "::placeholder": {
      color: "#333",
      opacity: "1",
    },
  },
  leadName: {
    fontWeight: "700",
    fontSize: "14px",
  },
  leadInformation: {
    fontWeight: "400",
    fontSize: "12px",
    color: "#666666",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "4px",
  },
  leadInterest: {
    background: "#9D2123",
    opacity: "0.1",
    borderRadius: "10px",
    color: "#9D2123",
    position: "relative",
    zIndex: 1,
    display: "flex",
    height: "20px",
    width: "97px",
  },
  time: {
    fontWeight: "700",
    fontSize: "12px",
    color: "#333333",
    opacity: "0.5",
  },
  expire: {
    fontWeight: "400",
    fontSize: "12px",
    color: "#9D2123",
  },
  cardContainer: {
    paddingTop: "5px",
    paddingBottom: "6px !important",
  },
  paper: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  interested: {
    width: "72px",
    background: "#00733E",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  interestedColor: {
    color: "#00733E",
    position: "absolute",
    zIndex: 2,
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "7px",
  },
  attempted: {
    width: "100px",
    background: "#00733E",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  attemptedColor: {
    color: "#00733E",
    position: "absolute",
    zIndex: 2,
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "7px",
  },
  connected: {
    width: "72px",
    background: "#00733E",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  connectedColor: {
    color: "#00733E",
    position: "absolute",
    zIndex: 2,
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "7px",
  },
  notconnected: {
    width: "187px",
    background: "rgb(157, 33, 35)",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  notinterested: {
    width: "58px",
    background: "rgb(157, 33, 35)",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  notinterestedColor: {
    color: "rgb(157, 33, 35)",
    position: "absolute",
    zIndex: 2,
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "7px",
  },
  notconnectedColor: {
    color: "rgb(157, 33, 35)",
    position: "absolute",
    zIndex: 2,
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "7px",
  },
  distance: {
    width: "166px",
    background: "rgb(157, 33, 35)",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  distanceColor: {
    color: "rgb(157, 33, 35)",
    position: "absolute",
    zIndex: 2,
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "7px",
  },
  noinventory: {
    width: "194px",
    background: "rgb(157, 33, 35)",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  noinventoryColor: {
    color: "rgb(157, 33, 35)",
    position: "absolute",
    zIndex: 2,
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "7px",
  },
  expired: {
    width: "56px",
    background: "rgb(157, 33, 35)",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  expiredColor: {
    color: "rgb(157, 33, 35)",
    position: "absolute",
    zIndex: 2,
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "7px",
  },
  notRejected: {
    width: "102px",
    background: "rgb(157, 33, 35)",
    opacity: "0.1",
    zIndex: 1,
    display: "flex",
    height: "20px",
    borderRadius: "10px",
  },
  limitScroll: { height: "90vh", overflow: "hidden" },
  ocpAmount: { fontWeight: "700", color: "#FFCC33" },
}));

function Leads() {
  const formatTime = (time, format) => {
    return moment.duration(time).format(format);
  };
  const classes = useStyles();
  let navigate = useNavigate();
  const [loading, setLoading] = useState("not_started");
  const [bottomSheetViewScreen, setBottomSheetViewScreen] = useState(null);
  const [state, setState] = useState(0);
  const [leadsTabValue, setLeadsTabValue] = useState("active");
  const [leadsData, setLeadsData] = useState(null);
  const [leadsDataCopy, setLeadsDataCopy] = useState([]);
  const [leadInformation, setLeadInformation] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [anchorValue, setAnchorValue] = useState("bottom");
  const [totalOCP, setTotalOCP] = useState(null);
  const [isLeadsBlocked, setIsLeadsBlocked] = useState(false);
  const [leadsSummary, setLeadsSummary] = useState({
    activeLead: 0,
    expiredLead: 0,
    rejectedLead: 0,
    totalLead: 0,
    connectedLead: 0,
  });
  const [ocpRestrictionData, setOcpRestrictionData] = useState({});

  const [loadingAgreementSigningWindow, setLoadingAgreementSigningWindow] =
    useState("not-started");

  const { getMessage } = useMessages();

  // let anchorValue = "bottom";
  const getLeads = (leadAttribute) => {
    setLoading("started");
    const { axiosRequest, abortController } = LeadService.getLeads(
      localStorage.getItem("userId"),
      leadAttribute
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.leads?.length) {
          if (leadAttribute === "sale" || leadAttribute === "nosale") {
            let leadsData = response?.data?.responseData?.leads?.reverse();
            setLeadsDataCopy(leadsData);
            setLeadsData(leadsData);
          } else {
            setLeadsDataCopy(response?.data?.responseData?.leads);
            setLeadsData(response?.data?.responseData?.leads);
          }
        } else {
          setLeadsDataCopy([]);
          setLeadsData([]);
          console.log("data not found");
        }

        const { axiosRequest } = LeadService.getLeadCount();
        axiosRequest
          .then((response) => {
            if (response?.data?.responseData) {
              setLeadsSummary(response.data.responseData);

              if (!localStorage.getItem(`freeLeadAttemptsMade`)) {
                localStorage.setItem(`freeLeadAttemptsMade`, 0);
              }
            } else {
              console.log("No Data Found");
            }
            getBreakup(response.data.responseData);
          })
          .catch((error) => {
            console.log(error);
            setLoading("done");
          });
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(error);
          setLoading("done");
        }
      });
    return () => abortController.abort();
  };

  const handleAttemptedLeads = (lead) => {
    let data = {
      leadStatus: "attempted",
      remarks: "",
    };
    const { axiosRequest } = LeadService.setLeadStatus(
      lead?.leadId,
      lead?.farmerId,
      data
    );
    axiosRequest
      .then((response) => {
        console.log("lead attempted");
        getLeads(leadsTabValue);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const leadsCB = getLeads(leadsTabValue);
    return () => leadsCB();
  }, []);

  useEffect(() => {
    setOcpRestrictionData({
      max_free_lead_attempts: 5,
      outstanding_ocp_amount_allowed: 20000,
    });
  }, []);

  // const handleWhatsappClickChat = (farmerId, leadId) => {
  //   const chatButtonData = {
  //     page: `/nearby-farmers`,
  //     screen: "Leads List",
  //     leadId: leadId,
  //     farmerId: farmerId,
  //     source: localStorage.getItem("source"),
  //     button: "Chat button on leads page",
  //   };
  //   AnalyticsService.pushEvent(`Chat button on leads page`, chatButtonData);
  // };

  const getProducts = (products) => {
    let productNames = "";
    products?.map((product, ind) => {
      let productData =
        ind === products?.length - 1
          ? `${(productNames = productNames + product?.productName)}`
          : `${(productNames = productNames + product?.productName)}, `;
    });
    return productNames;
  };

  const getBreakup = (leadCountData) => {
    const { axiosRequest } = HisaabService.getWalletBreakup();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.creditDetails) {
          const creditDetails = response?.data?.responseData?.creditDetails;
          const totalOCPAmount = Math.round(creditDetails.totalOCP);
          setTotalOCP(totalOCPAmount);

          const creditLine = localStorage.getItem("creditLine");
          let eventLogged = false;
          if (totalOCPAmount > 0 && creditLine !== "FINBOX") {
            // setIsLeadsBlocked(true);
            AnalyticsService.pushEvent(`Leads Page Viewed`, {
              isBlocked: "Yes",
              leadCount:
                leadCountData?.activeLead +
                +leadCountData?.attemptedLead?.attemptedLead +
                +leadCountData?.attemptedLead?.notConnectedLead,
            });
            eventLogged = true;
          }
          if (!eventLogged) {
            AnalyticsService.pushEvent(`Leads Page Viewed`, {
              isBlocked: "No",
              leadCount:
                leadCountData?.activeLead +
                +leadCountData?.attemptedLead?.attemptedLead +
                +leadCountData?.attemptedLead?.notConnectedLead,
            });
          }
        }
        setLoading("done");
      })
      .catch((e) => {
        console.log(e);
        setLoading("done");
      });
  };

  const handleBottomSheetSwipe = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleChange = (event, newValue) => {
    setLeadsTabValue(newValue);
  };

  const handleLeadTabNavigation = (leadType, leadCount) => {
    setSearchValue("");

    const leadTabClickedData = {
      page: `/nearby-farmers`,
      tab: leadType,
      leadCount: leadCount,
    };
    AnalyticsService.pushEvent(`Lead Tab Clicked`, leadTabClickedData);
    setAnchorValue("NA");
    getLeads(leadType);
  };

  const handleLeadsAction = (event, open, view, lead, closed) => {
    let isWhatsapp = false;
    if (view === "whatsappchat") {
      // handleWhatsappClickChat(lead?.farmerId, lead?.leadId);
      view = "lastCallView";
      isWhatsapp = true;
    }

    if (event.currentTarget != event.target) {
      event.stopPropagation();
    }

    setAnchorValue("bottom");
    let screen =
      view === "lastCallView"
        ? "Leads Call Attempt Button"
        : view === "leadsRejectedView"
          ? "Leads Reject Button"
          : closed
            ? "Leads Drawer Close Button"
            : "Leads Detail Button";

    // screen = isWhatsapp ? "Chat button on leads page" : screen;

    const leadsScreenData = {
      page: `/nearby-farmers`,
      button: isWhatsapp ? "Chat button on leads page" : screen,
      leadId: lead?.leadId,
      farmerId: lead?.farmerId,
    };
    AnalyticsService.pushEvent(`${screen} Clicked`, leadsScreenData);

    if (view === "lastCallView") {
      handleAttemptedLeads(lead);
    }

    setBottomSheetViewScreen(view);
    setLeadInformation(lead);
    setState(state + 1);
    if (!open && !view && !lead) {
      getLeads(leadsTabValue);
    }
  };

  const handleGrahakImprovement = () => {
    AnalyticsService.pushEvent(`Grahak Banner Clicked`, {
      page: "/nearby-farmers",
      source: localStorage.getItem("source"),
      farmerId: localStorage.getItem("farmerId"),
      youtubeId: "0NDnDhRiCiE",
    });
    navigate("/featured-videos?youtubeId=0NDnDhRiCiE");
  };

  const handleLeadSearch = (value) => {
    // setSearchValue(value);
    let searchedValue = leadsDataCopy.filter((lead) => {
      return lead?.farmerAddress?.farmerName
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    console.log(searchedValue);
    setLeadsData(searchedValue);
  };

  const handleMadeFreeLeadAttempt = () => {
    if (localStorage.getItem(`freeLeadAttemptsMade`)) {
      localStorage.setItem(
        `freeLeadAttemptsMade`,
        parseInt(localStorage.getItem(`freeLeadAttemptsMade`)) + 1
      );
    }
  };

  const blockLeadsInteraction = () => {
    if (
      parseInt(localStorage.getItem(`freeLeadAttemptsMade`)) >=
        ocpRestrictionData?.max_free_lead_attempts &&
      totalOCP > ocpRestrictionData?.outstanding_ocp_amount_allowed &&
      localStorage.getItem(`creditLine`) !== "FINBOX"
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Grahak`}
          style={{ background: "transparent", height: "200px" }}
          backNavigation={true}
        />
      </ErrorBoundary>
      <div className={isLeadsBlocked ? classes.limitScroll : ""}>
        <ErrorBoundary message={``}>
          <OnboardingStatusNudge
            setLoadingAgreementSigningWindow={setLoadingAgreementSigningWindow}
          />
        </ErrorBoundary>

        <div className={classes.summaryContainer}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: "url('/icons/pattern.png')",
              backgroundSize: "cover",
            }}
          ></div>
          {loading === "started" && (
            <>
              <Box
                sx={{
                  height: "65px",
                  width: "100%",
                  color: "white",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Box sx={{ alignSelf: "center" }}>Loading...</Box>
              </Box>
            </>
          )}
          {loading === "done" && (
            <Grid container direction="row">
              <Grid item xs={3}>
                <div className={classes.leadsNumber}>
                  {(leadsSummary && leadsSummary?.totalLead) || 0}
                </div>
                <div className={classes.leadsText}>Total Leads</div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.leadsNumber}>
                  {(leadsSummary &&
                    leadsSummary?.activeLead +
                      leadsSummary?.attemptedLead?.attemptedLead +
                      leadsSummary?.attemptedLead?.notConnectedLead) ||
                    0}
                </div>
                <div className={classes.leadsText}>Total Active</div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.leadsNumber}>
                  {(leadsSummary &&
                    leadsSummary?.attemptedLead?.interestedLead) ||
                    0}
                </div>
                <div className={classes.leadsText}>Sale Made</div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.leadsNumber}>
                  {(leadsSummary &&
                    Math.round(
                      (leadsSummary?.attemptedLead?.interestedLead /
                        leadsSummary?.totalLead) *
                        100
                    )) ||
                    0}
                  %
                </div>
                <div className={classes.leadsText}>Leads Converted</div>
              </Grid>
            </Grid>
          )}
        </div>

        <Box
          sx={{
            paddingTop: "24px",
            paddingLeft: "15px",
            paddingRight: "15px",
            marginBottom: "-15px",
          }}
        >
          <img
            src="/icons/grahakBanner.svg"
            onClick={() => handleGrahakImprovement()}
            style={{ width: "100%" }}
            alt=""
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            p: 2,
          }}
        >
          <Tabs
            value={leadsTabValue}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            centered
            style={{
              background: "white",
              height: "60px",
              borderRadius: "20px",
              justifyContent: "center",
              marginTop: "12px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05) !important",
            }}
          >
            <Tab
              value="active"
              onClick={() =>
                handleLeadTabNavigation(
                  "active",
                  leadsSummary?.activeLead +
                    leadsSummary?.attemptedLead?.attemptedLead +
                    leadsSummary?.attemptedLead?.notConnectedLead
                )
              }
              className={classes.tabStyle}
              label={
                leadsTabValue == "active"
                  ? `Leads (${
                      leadsSummary?.activeLead +
                        leadsSummary?.attemptedLead?.attemptedLead +
                        leadsSummary?.attemptedLead?.notConnectedLead || 0
                    })`
                  : "Leads"
              }
            />
            <Tab
              value="sale"
              className={classes.tabStyle}
              label={
                leadsTabValue == "sale"
                  ? `Sale Made (${
                      leadsSummary?.attemptedLead?.interestedLead || 0
                    })`
                  : "Sale Made"
              }
              onClick={() =>
                handleLeadTabNavigation(
                  "sale",
                  leadsSummary?.attemptedLead?.interestedLead
                )
              }
            />
            <Tab
              value="nosale"
              onClick={() =>
                handleLeadTabNavigation(
                  "nosale",
                  leadsSummary?.expiredLead +
                    leadsSummary?.rejectedLead +
                    leadsSummary?.attemptedLead?.notInterestedLead
                )
              }
              className={classes.tabStyle}
              label={
                leadsTabValue == "nosale"
                  ? `No Sale (${
                      leadsSummary?.expiredLead +
                        leadsSummary?.rejectedLead +
                        leadsSummary?.attemptedLead?.notInterestedLead || 0
                    })`
                  : "No Sale"
              }
            />
          </Tabs>
        </Box>
        <Box sx={{ pl: 2, pr: 2 }}>
          <FormControl style={{ width: "100%" }}>
            <OutlinedInput
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
              }}
              autoFocus={false}
              classes={{
                notchedOutline: classes.MuiOutlinedInputNotchedOutline,
              }}
              placeholder="Search farmer name"
              style={{
                height: "40px",
                background: "#F5E9E9",
                border: "1px solid rgba(157, 33, 35, 0.5) !important",
                borderRadius: "20px",
              }}
              onKeyUp={(event) => handleLeadSearch(event?.target?.value)}
              endAdornment={<img src={`icons/searchLeads.svg`} />}
            />
          </FormControl>
        </Box>
        {leadsData?.length > 0 && loading === "done" && (
          <>
            <div style={{ padding: "15px", paddingBottom: "80px" }}>
              {leadsData.map((lead, index) => {
                return (
                  <div key={index}>
                    <Card
                      style={{
                        boxShadow: "none",
                        borderRadius: "7px",
                        marginBottom: "15px",
                        position: "relative",
                      }}
                      onClick={(event) =>
                        handleLeadsAction(event, true, "leadsDetailsView", lead)
                      }
                    >
                      <CardContent className={classes.cardContainer}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={
                              _.includes(
                                GRAHAK_CHAT_SOURCES_ENABLED,
                                localStorage.getItem("source")
                              )
                                ? 9
                                : 8
                            }
                          >
                            <Typography className={classes.leadName}>
                              {lead?.farmerAddress?.farmerName}
                            </Typography>
                            <Typography className={classes.leadInformation}>
                              {lead?.products.map((product, ind) => {
                                return (
                                  <>
                                    {product?.productName}{" "}
                                    {ind === lead?.products?.length - 1
                                      ? ""
                                      : ","}{" "}
                                  </>
                                );
                              })}
                            </Typography>
                            <div>
                              <Typography
                                className={classes.leadInformation}
                                style={{ display: "flex" }}
                              >
                                <img
                                  src="/icons/location.svg"
                                  style={{ height: "14px" }}
                                />
                                &nbsp;{" "}
                                <div
                                  style={{
                                    marginTop: "-2px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {lead?.farmerAddress?.line2}
                                </div>
                              </Typography>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={
                              _.includes(
                                GRAHAK_CHAT_SOURCES_ENABLED,
                                localStorage.getItem("source")
                              )
                                ? 3
                                : 4
                            }
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              style={{ marginTop: "10px" }}
                            >
                              &nbsp;
                              {!_.includes(
                                GRAHAK_CHAT_SOURCES_ENABLED,
                                localStorage.getItem("source")
                              ) && (
                                <>
                                  {blockLeadsInteraction() ? (
                                    <a
                                      style={{ textDecoration: "none" }}
                                      onClick={(event) => {
                                        setIsLeadsBlocked(true);
                                        event.stopPropagation();
                                      }}
                                    >
                                      <IconButton
                                        style={{
                                          height: "29px",
                                          width: "100%",
                                          borderRadius: "60px",
                                          color: "white",
                                          background: "#00733E",
                                          display: "flex",
                                          textDecoration: "none",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: "700",
                                        }}
                                      >
                                        <img
                                          src="/icons/callWhite.svg"
                                          style={{ alignSelf: "center" }}
                                        />
                                        &nbsp;
                                        <span>Call</span>
                                      </IconButton>
                                    </a>
                                  ) : (
                                    <a
                                      href={`tel:${lead?.farmerAddress?.farmerPhone}`}
                                      style={{ textDecoration: "none" }}
                                      onClick={(event) => {
                                        if (
                                          totalOCP >
                                            ocpRestrictionData?.outstanding_ocp_amount_allowed &&
                                          localStorage.getItem(`creditLine`) !==
                                            "FINBOX"
                                        ) {
                                          handleMadeFreeLeadAttempt();
                                        }
                                        handleLeadsAction(
                                          event,
                                          true,
                                          "lastCallView",
                                          lead
                                        );
                                      }}
                                    >
                                      <IconButton
                                        style={{
                                          height: "29px",
                                          width: "100%",
                                          borderRadius: "60px",
                                          color: "white",
                                          background: "#00733E",
                                          display: "flex",
                                          textDecoration: "none",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: "700",
                                        }}
                                      >
                                        <img
                                          src="/icons/callWhite.svg"
                                          style={{ alignSelf: "center" }}
                                        />
                                        &nbsp;
                                        <span>Call</span>
                                      </IconButton>
                                    </a>
                                  )}
                                </>
                              )}
                              &nbsp;
                              <IconButton
                                onClick={(event) =>
                                  handleLeadsAction(
                                    event,
                                    true,
                                    "leadsDetailsView",
                                    lead
                                  )
                                }
                              >
                                <img src="/icons/openLead.svg" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={leadsTabValue === "nosale" ? 7 : 5}>
                            {(leadsTabValue === "active" ||
                              leadsTabValue === "attempted" ||
                              leadsTabValue === "nosale") && (
                              <div
                                style={{ display: "flex" }}
                                // style={
                                //   lead?.leadStatus === "rejected"
                                //     ? { display: "flex", position: "relative" }
                                //     : { display: "flex" }
                                // }
                              >
                                <div
                                  style={
                                    lead?.leadStatus === "rejected"
                                      ? { position: "absolute" }
                                      : {}
                                  }
                                  className={
                                    classes[
                                      lead?.leadRemark
                                        ? lead?.leadRemark
                                        : lead?.leadStatus === "active"
                                          ? ""
                                          : lead?.leadStatus ===
                                                "notconnected" &&
                                              lead?.leadRemark === ""
                                            ? "notRejected"
                                            : lead?.leadStatus
                                    ]
                                  }
                                ></div>
                                <div
                                  className={
                                    classes[
                                      `${
                                        lead?.leadRemark
                                          ? lead?.leadRemark
                                          : lead?.leadStatus === "active"
                                            ? ""
                                            : lead?.leadStatus
                                      }Color`
                                    ]
                                  }
                                >
                                  {" "}
                                  {lead?.leadStatus === "rejected"
                                    ? `Rejected - ${
                                        lead?.leadRemark === "distance"
                                          ? "Farmer lives far"
                                          : lead?.leadRemark === "noinventory"
                                            ? "Product not in stock"
                                            : "Call not connected"
                                      }`
                                    : lead?.leadStatus === "active"
                                      ? ""
                                      : lead?.leadRemark === "notinterested"
                                        ? "No Sale"
                                        : lead?.leadStatus === "attempted"
                                          ? "Call Attempted"
                                          : lead?.leadRemark === "interested"
                                            ? "Sale Made"
                                            : lead?.leadStatus ===
                                                "notconnected"
                                              ? "Not Connected"
                                              : lead?.leadStatus}
                                </div>
                                &nbsp;
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={leadsTabValue === "nosale" ? 5 : 7}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              {leadsTabValue === "active" && (
                                <>
                                  <div style={{ display: "flex" }}>
                                    <img
                                      src="/icons/expire.gif"
                                      style={{
                                        height: "28px",
                                        marginTop: "-4.5px",
                                        marginRight: "-3px",
                                      }}
                                    />

                                    <div className={classes.expire}>
                                      Expires in{" "}
                                      <span style={{ fontWeight: "700" }}>
                                        {formatTime(lead?.expiresIn, "HH:mm")
                                          ?.length > 2
                                          ? formatTime(
                                              lead?.expiresIn,
                                              "HH:mm"
                                            ).split(":")[0] >= 24
                                            ? formatTime(lead?.expiresIn, "DD")
                                            : formatTime(
                                                lead?.expiresIn,
                                                "HH:mm"
                                              )
                                          : `00:${formatTime(
                                              lead?.expiresIn,
                                              "HH:mm"
                                            )}`}
                                      </span>{" "}
                                      {formatTime(
                                        lead?.expiresIn,
                                        "HH:mm"
                                      ).split(":")[0] >= 24
                                        ? formatTime(lead?.expiresIn, "DD") ===
                                          "01"
                                          ? "day"
                                          : "days"
                                        : "hrs"}
                                    </div>
                                  </div>
                                </>
                              )}
                              {(leadsTabValue === "sale" ||
                                leadsTabValue === "nosale") && (
                                <>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      className={classes.expire}
                                      style={{ color: "#666666" }}
                                    >
                                      Date:{" "}
                                      <span style={{ fontWeight: "700" }}>
                                        {moment(lead?.leadUpdationDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {(leadsTabValue === "active" ||
                          leadsTabValue === "attempted" ||
                          leadsTabValue === "sale") &&
                          _.includes(
                            GRAHAK_CHAT_SOURCES_ENABLED,
                            localStorage.getItem("source")
                          ) && (
                            <>
                              <Grid
                                container
                                spacing={1}
                                sx={{ marginTop: "0px", marginBottom: "5px" }}
                              >
                                <Grid item xs={6}>
                                  {blockLeadsInteraction() ? (
                                    <IconButton
                                      style={{
                                        height: "35px",
                                        width: "100%",
                                        borderRadius: "60px",
                                        color: "white",
                                        background: "#00733E",
                                        display: "flex",
                                        textDecoration: "none",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                      }}
                                      onClick={(event) => {
                                        setIsLeadsBlocked(true);
                                        event.stopPropagation();
                                      }}
                                    >
                                      {/* <WhatsappShareButton
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        url={`.`}
                                        title={}
                                      > */}
                                      <img
                                        src="/icons/whatsappLeadsChat.svg"
                                        style={{ alignSelf: "center" }}
                                      />
                                      &nbsp;
                                      <span style={{ alignSelf: "center" }}>
                                        Message
                                      </span>
                                      {/* </WhatsappShareButton> */}
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      style={{
                                        height: "35px",
                                        width: "100%",
                                        borderRadius: "60px",
                                        color: "white",
                                        background: "#00733E",
                                        display: "flex",
                                        textDecoration: "none",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                      }}
                                      onClick={(event) => {
                                        if (
                                          totalOCP >
                                            ocpRestrictionData?.outstanding_ocp_amount_allowed &&
                                          localStorage.getItem(`creditLine`) !==
                                            "FINBOX"
                                        ) {
                                          handleMadeFreeLeadAttempt();
                                        }
                                        handleLeadsAction(
                                          event,
                                          true,
                                          "whatsappchat",
                                          lead
                                        );
                                      }}
                                    >
                                      <ReactWhatsapp
                                        number={`+91${lead?.farmerAddress?.farmerPhone}`}
                                        message={`${getMessage(
                                          `grahak.whatsappMessage1`
                                        )} *${
                                          lead?.farmerAddress?.farmerName
                                        }* ${getMessage(
                                          `grahak.whatsappMessage2`
                                        )} ${getProducts(
                                          lead?.products
                                        )} ${getMessage(
                                          `grahak.whatsappMessage3`
                                        )}*${localStorage.getItem(
                                          "partnerName"
                                        )}*${getMessage(
                                          `grahak.whatsappMessage4`
                                        )}${localStorage.getItem(
                                          "district"
                                        )}, ${localStorage.getItem(
                                          "state"
                                        )}, ${localStorage.getItem(
                                          "pinCode"
                                        )} ${getMessage(
                                          `grahak.whatsappMessage5`
                                        )}${localStorage.getItem("mobile")}`}
                                        style={{
                                          background: "transparent",
                                          border: "0px",
                                          color: "white",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {/* <WhatsappShareButton
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      url={`.`}
                                      title={}
                                    > */}
                                        <img
                                          src="/icons/whatsappLeadsChat.svg"
                                          style={{ alignSelf: "center" }}
                                        />
                                        &nbsp;
                                        <span style={{ alignSelf: "center" }}>
                                          Message
                                        </span>
                                        {/* </WhatsappShareButton> */}
                                      </ReactWhatsapp>
                                    </IconButton>
                                  )}
                                </Grid>
                                <Grid item xs={6}>
                                  {blockLeadsInteraction() ? (
                                    <a
                                      style={{ textDecoration: "none" }}
                                      onClick={(event) => {
                                        setIsLeadsBlocked(true);
                                        event.stopPropagation();
                                      }}
                                    >
                                      <IconButton
                                        style={{
                                          height: "35px",
                                          width: "100%",
                                          borderRadius: "60px",
                                          color: "white",
                                          background: "#00733E",
                                          display: "flex",
                                          textDecoration: "none",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: "700",
                                        }}
                                      >
                                        <img
                                          src="/icons/callWhite.svg"
                                          style={{ alignSelf: "center" }}
                                        />
                                        &nbsp;
                                        <span>Call</span>
                                      </IconButton>
                                    </a>
                                  ) : (
                                    <a
                                      href={`tel:${lead?.farmerAddress?.farmerPhone}`}
                                      style={{ textDecoration: "none" }}
                                      onClick={(event) => {
                                        if (
                                          totalOCP >
                                            ocpRestrictionData?.outstanding_ocp_amount_allowed &&
                                          localStorage.getItem(`creditLine`) !==
                                            "FINBOX"
                                        ) {
                                          handleMadeFreeLeadAttempt();
                                        }
                                        handleLeadsAction(
                                          event,
                                          true,
                                          "lastCallView",
                                          lead
                                        );
                                      }}
                                    >
                                      <IconButton
                                        style={{
                                          height: "35px",
                                          width: "100%",
                                          borderRadius: "60px",
                                          color: "white",
                                          background: "#00733E",
                                          display: "flex",
                                          textDecoration: "none",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: "700",
                                        }}
                                      >
                                        <img
                                          src="/icons/callWhite.svg"
                                          style={{ alignSelf: "center" }}
                                        />
                                        &nbsp;
                                        <span>Call</span>
                                      </IconButton>
                                    </a>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          )}
                      </CardContent>
                      {leadsTabValue === "active" && (
                        <div
                          style={{
                            width: `${
                              0.8 * +formatTime(lead?.expiresIn, "HH")
                            }%`,
                            height: "2px",
                            background: "#9D2123",
                          }}
                        ></div>
                      )}
                    </Card>
                  </div>
                );
              })}
            </div>

            <div>
              {bottomSheetViewScreen === "leadsDetailsView" && (
                <LeadsDetail
                  anchor={anchorValue}
                  leadInformation={leadInformation}
                  handleLeadsBottomSheetClose={(event) =>
                    handleLeadsAction(event, false, "", "leads", true)
                  }
                  handleLeadsRejectScreenView={(event) => {
                    handleLeadsAction(event, false, "", "leads");
                    handleLeadsAction(event, true, "leadsRejectedView", {
                      leadInformation,
                    });
                  }}
                  handleLeadsWhatsappMessageOpen={(event) => {
                    handleLeadsAction(event, false, "", leadInformation);
                    handleLeadsAction(
                      event,
                      true,
                      "whatsappchat",
                      leadInformation
                    );
                  }}
                  handleLeadsCallAttemptViewScreen={(event) => {
                    handleLeadsAction(event, false, "", leadInformation);
                    handleLeadsAction(
                      event,
                      true,
                      "lastCallView",
                      leadInformation
                    );
                  }}
                  leadsTabValue={leadsTabValue}
                  state={state}
                  totalOCP={totalOCP}
                  setIsLeadsBlocked={setIsLeadsBlocked}
                  handleMadeFreeLeadAttempt={handleMadeFreeLeadAttempt}
                  handleLeadsAction={handleLeadsAction}
                  blockLeadsInteraction={blockLeadsInteraction}
                  ocpRestrictionData={ocpRestrictionData}
                />
              )}
              {bottomSheetViewScreen === "leadsRejectedView" && (
                <LeadsReject
                  anchor={anchorValue}
                  leadInformation={leadInformation}
                  handleLeadsBottomSheetClose={() => navigate(0)}
                  state={state}
                />
              )}
              {bottomSheetViewScreen === "lastCallView" && (
                <ErrorBoundary>
                  <LeadsCallAttempt
                    anchor="bottom"
                    leadInformation={leadInformation}
                    handleLeadsBottomSheetClose={() => navigate(0)}
                    handleLeadsRejectScreenView={(event) => {
                      handleLeadsAction(event, false, "", "leads");
                      handleLeadsAction(event, true, "leadsRejectedView", {
                        leadInformation,
                      });
                    }}
                    state={state}
                  />
                </ErrorBoundary>
              )}
            </div>
          </>
        )}
        <div>
          {loading === "started" && (
            <>
              <Box sx={{ margin: "16px" }}>
                <Grid
                  container
                  direction={`column`}
                  justifyContent="flex-start"
                  alignItems="flex-center"
                  wrap={"nowrap"}
                  spacing={2}
                >
                  <Grid item>
                    <Skeleton
                      variant={`rectangular`}
                      height={148}
                      sx={{ borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant={`rectangular`}
                      height={148}
                      sx={{ borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant={`rectangular`}
                      height={148}
                      sx={{ borderRadius: "7px" }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </div>
        <div
          style={{
            marginTop: "110px",
            textAlign: "center",
            color: "#333",
            opacity: "0.7",
          }}
        >
          {loading === "done" && !leadsData?.length && (
            <>
              {leadsTabValue !== "active" && (
                <>
                  <p
                    style={{
                      marginTop: "-20px",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Sorry, No Leads Found!!
                  </p>
                </>
              )}
              {leadsTabValue === "active" && (
                <>
                  <Typography
                    sx={{
                      marginTop: "-20px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      width: "100%",
                    }}
                  >
                    Coming soon, Grahak purchase leads.
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "-20px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      mt: 0.6,
                    }}
                  >
                    Stay tuned!!
                  </Typography>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {isLeadsBlocked && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            left: 0,
            bottom: "57px",
            width: "100%",
            height: "100%",
            textAlign: "center",
            color: "#fff",
            background: "rgb(255,255,255,0.1)",
            backdropFilter: "blur(2.5px)",
            zIndex: 2,
          }}
        >
          <Grid item xs={8}>
            <Box
              sx={{
                position: "absolute",
                top: "30%",
                left: "calc(50% - 300px/2)",
                width: "300px",
                height: "300px",
                background: "#614040",
                filter: "blur(70px)",
              }}
            ></Box>
            <Box
              fontSize={18}
              fontWeight={700}
              sx={{
                mb: 2,
                color: "#fff",
                filter: "blur(.1px)",
              }}
            >
              <Typography variant="h6">Your Payment is Overdue</Typography>
              <Typography variant="subtitle1" sx={{ lineHeight: 1.1 }}>
                Please pay your overdue amount of{" "}
                <span className={classes.ocpAmount}>Rs. {totalOCP}/-</span> now
                and get access to farmer leads.
              </Typography>
            </Box>

            <Button
              variant="contained"
              fullWidth="true"
              type="payment"
              onClick={() => {
                AnalyticsService.pushEvent(
                  `Make Payment Clicked On Leads Page`,
                  { page: "/nearby-farmers" }
                );
                navigate(
                  `/hisaab?payment=true&type=due&amount-type=other&amount=${totalOCP}`
                );
              }}
            >
              Make Payment
            </Button>
          </Grid>
        </Grid>
      )}
      <BackDropLoader open={loadingAgreementSigningWindow === "started"} />
    </div>
  );
}

export default Leads;
