import { Box } from "@mui/material";
import _ from "lodash";
import ErrorBoundary from "../Common/ErrorBoundary";
import ComplaintProductCard from "./ComplaintProductCard";

function ComplaintProductList({
  products,
  handleSelectProduct,
  handleRemoveProduct,
  selectedProducts,
  searchValue,
  issueReasons,
}) {
  return (
    <Box
      sx={{
        paddingBottom: "80px",
      }}
    >
      {_.map(products, (product) => {
        if (
          !product?.productName
            ?.toLowerCase()
            ?.includes(searchValue.toLowerCase())
        ) {
          return null;
        }
        return (
          <ErrorBoundary message={``} key={product.sku_code}>
            <ComplaintProductCard
              product={product}
              handleSelectProduct={handleSelectProduct}
              handleRemoveProduct={handleRemoveProduct}
              selectedProducts={selectedProducts}
              issueReasons={issueReasons}
            />
          </ErrorBoundary>
        );
      })}
    </Box>
  );
}

export default ComplaintProductList;
