import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SalesAndOrderCard from "./SalesAndOrderCard";
import BlockUi from "react-block-ui";
import BusinessInsightsService from "../../Services/BusinessInsightsService";
import _ from "lodash";
import MissedSalesWithDate from "./MissedSalesWithDate";

const useStyles = makeStyles({
  headerText: {
    color: "black",
    fontSize: "16px",
    fontWeight: "700",
    margin: "10px 14px",
  },
  noMissedSalesContainer: {
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "20vh",
    width: "100%",
  },
  noMissedSalesText: {
    textAlign: "center",
    margin: "20px",
  },
});

export default function BusinessInsightsPage() {
  const [businessDetailLoading, setBusinessDetailLoading] = useState("");
  const [partnerMissedSalesLoading, setPartnerMissedSalesLoading] =
    useState("");
  const classes = useStyles();
  const [missedSalesWithDate, setMissedSalesWithDate] = useState([]);
  const [deliveredData, setDeliveredData] = useState(null);
  const [missedData, setMissedData] = useState(null);

  useEffect(() => {
    setBusinessDetailLoading("loading");
    const { axiosRequest, abortController } =
      BusinessInsightsService.getPartnerBusinessDetails();
    axiosRequest
      .then((response) => {
        setBusinessDetailLoading("done");
        setDeliveredData(response?.data?.responseData?.assignedData);
        setMissedData(response?.data?.responseData?.missedData);
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setBusinessDetailLoading("done");
        }
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    setPartnerMissedSalesLoading("loading");
    const { axiosRequest, abortController } =
      BusinessInsightsService.getPartnerMissedSalesData();
    axiosRequest
      .then((response) => {
        setPartnerMissedSalesLoading("done");
        setMissedSalesWithDate(response?.data?.responseData);
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setPartnerMissedSalesLoading("done");
        }
      });
    return () => abortController.abort();
  }, []);

  return (
    <Box sx={{ background: "#F3F3F3" }}>
      <BlockUi
        tag="div"
        blocking={businessDetailLoading === "loading"}
        message="Please wait..."
        keepInView={true}
      >
        <Box
          sx={{ background: "white", paddingBottom: "20px", minHeight: "20vh" }}
        >
          {deliveredData != null && (
            <>
              <Typography className={classes.headerText}>
                Delivered Order And Sales
              </Typography>
              <SalesAndOrderCard
                data={deliveredData}
                containerType="delivered"
              />
            </>
          )}
          {missedData != null && (
            <>
              <Typography className={classes.headerText}>
                Missed Order And Sales
              </Typography>
              <SalesAndOrderCard data={missedData} containerType="missed" />
            </>
          )}
        </Box>
      </BlockUi>
      <BlockUi
        tag="div"
        blocking={partnerMissedSalesLoading === "loading"}
        message="Please wait..."
        keepInView={true}
      >
        <Typography className={classes.headerText}>Missed Orders</Typography>
        {missedSalesWithDate?.length > 0 ? (
          _.map(missedSalesWithDate, (missedSale, index) => {
            return (
              <MissedSalesWithDate
                missedSalesWithDate={missedSale}
                key={index}
              />
            );
          })
        ) : (
          <Box className={classes.noMissedSalesContainer}>
            <Typography className={classes.noMissedSalesText}>
              No missed sales. This is great. Keep updating inventory!
            </Typography>
          </Box>
        )}
      </BlockUi>
    </Box>
  );
}
