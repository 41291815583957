import { Chip, Container, Grid, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";

function RecentSearches(props) {
  const [recentSearches, setRecentSearches] = useState([]);

  let matchingSearchTerms = _.filter(recentSearches, (searchTerm) => {
    return searchTerm.includes(props?.keyword);
  });

  useEffect(() => {
    if (localStorage.getItem(`recentSearches`)) {
      setRecentSearches(JSON.parse(localStorage.getItem(`recentSearches`)));
    }
  }, []);

  const handleClearRecentSearches = () => {
    localStorage.removeItem(`recentSearches`);
    setRecentSearches([]);
    AnalyticsService.pushEvent("Recent Search Clear All Button Clicked", {
      page: "/search",
    });
  };

  const handleRemoveSearchTerm = (keyword) => {
    let updatedRecentSearches = _.filter(
      recentSearches,
      (searchTerm) => searchTerm !== keyword
    );

    setRecentSearches(updatedRecentSearches);
    localStorage.setItem(
      `recentSearches`,
      JSON.stringify(updatedRecentSearches)
    );
    AnalyticsService.pushEvent("Recent Search Remove Button Clicked", {
      page: "/search",
      searchTerm: keyword,
      searchTermPosition:
        matchingSearchTerms?.length > 0
          ? matchingSearchTerms.indexOf(keyword) + 1
          : ``,
    });
  };

  const handleClickSearchTerm = (searchTerm) => {
    props.handleClickSearchTerm(searchTerm);
    if (recentSearches.includes(searchTerm)) {
      _.pull(recentSearches, searchTerm);
      recentSearches.unshift(searchTerm);
      localStorage.setItem(`recentSearches`, JSON.stringify(recentSearches));
    }
    AnalyticsService.pushEvent("Recent Search Term Clicked", {
      page: "/search",
      searchTerm: searchTerm,
      searchTermPosition:
        matchingSearchTerms?.length > 0
          ? matchingSearchTerms.indexOf(searchTerm) + 1
          : ``,
    });
  };

  return (
    <>
      {props?.keywordDebounced?.trim() === "" && !props?.products ? (
        <>
          {recentSearches &&
            recentSearches.length > 0 &&
            matchingSearchTerms?.length > 0 && (
              <Container maxWidth={`xl`}>
                <Grid
                  container
                  alignItems={`center`}
                  justifyContent={`space-between`}
                  sx={{ margin: "20px 0px 7px 0px" }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Recent Searches
                  </Typography>
                  <Typography
                    onClick={handleClearRecentSearches}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#4F4F4F",
                      opacity: "0.5",
                    }}
                  >
                    Clear All
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    maxHeight: "120px",
                    display: "block",
                    overflow: "hidden",
                  }}
                >
                  {_.map(recentSearches, (recentSearch, index) => {
                    return (
                      <Chip
                        key={index}
                        clickable={false}
                        label={recentSearch}
                        onClick={() => {
                          handleClickSearchTerm(recentSearch);
                        }}
                        sx={{
                          margin: 0.5,
                          backgroundColor: "#FFFFFF",
                          color: "#9D2123",
                          fontWeight: 700,
                        }}
                      />
                    );
                  })}
                </Box>
              </Container>
            )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default RecentSearches;
