import config from "../config";
import BusinessDetailsMock from "./mocks/BusinessDetailsMock";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class BusinessInsightsService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getPartnerBusinessDetails() {
    if (useMocks === false) {
      let url = `${
        config.backendUrl
      }ordermanagementservice/v1/saathibusinessdetails?farmerId=${localStorage.getItem(
        "farmerId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: BusinessDetailsMock.businessDetails });
        }, 2000);
      });
    }
  }

  static getPartnerMissedSalesData() {
    if (useMocks === false) {
      let url = `${
        config.backendUrl
      }ordermanagementservice/v1/saathimissedsales?farmerId=${localStorage.getItem(
        "farmerId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: BusinessDetailsMock.missedSales });
        }, 2000);
      });
    }
  }
}
