import { Box, Button } from "@mui/material";
import CartService from "../../Services/CartService";
import AnalyticsService from "../../Services/AnalyticsService";
import EastIcon from "@mui/icons-material/East";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  cartButton: {
    borderRadius: "0px",
  },
  promote: {
    background: "white",
    color: "#9D2123",
    "&:hover": {
      background: "#F5F5F5",
    },
    borderRadius: "0px",
  },
  iconSize: {
    height: "22px",
    marginTop: "-3px",
  },
}));

function ViewOrUpdateCartButton(props) {
  const classes = useStyles();
  let navigate = useNavigate();

  const viewCart = () => {
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      props?.setShowUnauthorizedError(true);
      props?.setUnauthorizedMessage(
        "You will get access to this feature once you become our Saathi partner"
      );
    } else if (PartnerService.isProductPriceBlocked(props?.product)) {
      props?.setShowUnauthorizedError(true);
      props?.setUnauthorizedMessage(
        "Ordering will be enabled once pricing is available"
      );
    } else {
      navigate(`/cart`);
      AnalyticsService.pushEvent(`View Cart Clicked`, {
        section: props?.section || "",
      });
    }
  };

  const getItemCount = () => {
    const products = CartService.getCart();
    return products?.length === 1
      ? `0${products?.length} Item`
      : `${
          products?.length < 10 ? "0" + products?.length : products?.length
        } Items`;
  };

  return (
    <>
      {CartService.getProductFromCart(props.skuCode)?.quantity ===
      props.quantity ? (
        <Button
          size={"large"}
          variant={"contained"}
          color={"primary"}
          fullWidth={true}
          onClick={viewCart}
          className={classes.cartButton}
          disabled={props.quantity < 1}
          type={"submit"}
          sx={{ justifyContent: "space-around" }}
        >
          <Box sx={{ textTransform: "uppercase" }}>{getItemCount()}</Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={`/icons/bag.svg`}
              alt=""
              className={classes.iconSize}
              style={{ marginRight: "8px" }}
            />
            Go to Cart
            <EastIcon fontSize="medium" sx={{ marginLeft: "8px" }} />
          </Box>
        </Button>
      ) : (
        <>
          {!props?.hideWhatsAppPromote && (
            <Button
              size={"large"}
              variant={"contained"}
              color={"primary"}
              fullWidth={true}
              className={classes.promote}
              disabled={props.quantity < 1}
              onClick={props.handlePromoteWhatsappNavigation}
              startIcon={
                <img
                  src={`/icons/whatsapp.svg`}
                  alt={`promote`}
                  className={classes.iconSize}
                />
              }
            >
              Promote
            </Button>
          )}
          <Button
            size={"large"}
            variant={"contained"}
            color={"primary"}
            fullWidth={true}
            onClick={() => {
              props.addToCart(true);
            }}
            className={classes.cartButton}
            disabled={props.quantity < 1}
            type={"submit"}
            startIcon={
              <img src={`/icons/bag.svg`} alt="" className={classes.iconSize} />
            }
          >
            Update Cart
          </Button>
        </>
      )}
    </>
  );
}

export default ViewOrUpdateCartButton;
