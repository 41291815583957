import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Clamp from "react-multiline-clamp";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductService from "../../Services/ProductService";
import ProductListPrice from "../Dashboard/ProductListPrice";
import StarRatingLabel from "./StarRatingLabel";
import TurnoverSchemeProgressBar from "./TurnoverSchemeProgressBar";
import SchemesService from "../../Services/SchemesService";
import PartnerService from "../../Services/PartnerService";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingTop: "5px",
    paddingBottom: "6px !important",
  },
  name: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#333333",
  },
  title: {
    // fontWeight: "600",
    fontSize: "14px",
    color: "#333333",
    height: "28px",
  },
  productCardWrapper: {
    wordBreak: "break-word",
    borderRadius: "15px",
    // border: "1px solid #EEEEEE",
    textAlign: "center",
    height: "107px",
    // background: "#FFFFFF",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
  },
  productCardContent: {
    padding: theme.spacing(1),
    paddingBottom: "6px !important", // theme.spacing(1),
  },
  productImageContainer: {
    // marginTop: theme.spacing(0.5),
    // width: "94%",
    // position: "relative",
  },
  productImage: {
    // height: "60px",
    // maxHeight: "150px",
    width: "80%",
    // maxWidth: "100px",
  },
  productNameWrapper: {
    margin: theme.spacing(0, 0, 1.5, 0),
  },
  productName: {
    // maxHeight: "3.9em",
    // lineHeight: "1.3em",
    // letterSpacing: "0px",
    // wordWrap: "break-word",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // display: "-webkit-box",
    // "-webkit-line-clamp": 3 /* number of lines to show */,
    // "line-clamp": 3,
    // "-webkit-box-orient": "vertical",
    fontSize: "12px",
  },

  productPrice: {
    marginTop: theme.spacing(2),
  },
  paper: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    height: "700px",
  },
  offersIcon: {
    // height: "14px",
    display: "inline-flex",
    verticalAlign: "middle",
    marginRight: theme.spacing(0.3),
    marginTop: theme.spacing(-0.5),
  },
  cdBenefitIcon: {
    // height: "14px",
    display: "inline-flex",
    verticalAlign: "middle",
    marginRight: theme.spacing(0.3),
    // marginTop: theme.spacing(-0.4),
  },
  imageWrapper: {
    background: "#FFFFFF",
    border: "1px solid #F3F3F3",
    boxShadow: "inset 0px 0px 11px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px",
    padding: "5px",
    marginRight: "10px",
  },
  productMrp: {
    color: "#666666",
    marginLeft: "4px",
    textDecoration: "line-through",
  },
  ratingLabelWrapper: {
    minHeight: theme.spacing(1),
    // width: "35px",
    justifyContent: "flex-start",
    display: "flex",
  },
  offersAvailableText: {
    display: "flex",
    alignItems: "center",
    color: "#9D2123",
    // marginTop: theme.spacing(2),
    fontSize: "12px",
  },
  buy: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "11px",
    letterSpacing: "-0.005em",
    color: "#9D2123",
  },
  productCardWrapperLink: {
    textDecoration: `none`,
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export default function HorizontalProductCard(props) {
  const classes = useStyles();
  const handleClickProductClick = () => {
    if (props?.saveScrollYPosition) {
      MiscUtils.saveScrollYPosition();
    }

    const productClickedEventData = {
      page: props?.page ? props.page : "",
      section: props?.section || "",
      position: props.index + 1 || "",
      mrp: props?.product?.mrp || 0,
      sellingPrice: props?.product?.sellingPrice || 0,
      isOfferAvailable:
        props?.product?.offers &&
        Object.entries(props?.product?.offers).length > 0
          ? true
          : false,
      productName: props?.product?.productName || "",
      skuCode: props?.product?.skuCode || "",
      path: props?.path,
      todProgressBar: showTodProgressBar ? "Yes" : "No",
      todProgressLowerSlab: SchemesService.generateEntitlementDescription(
        lastAchievedSlabDetails?.entitlements?.entitlementList
      ),
      todProgressUpperSlab: SchemesService.generateEntitlementDescription(
        currentTargetSlabDetails?.entitlements?.entitlementList
      ),
      todPercentageCompletion: qualifiedSlabRelativePercentageAchieved
        ? qualifiedSlabRelativePercentageAchieved
        : ``,
    };

    AnalyticsService.pushEvent(`Product Card Clicked`, productClickedEventData);
  };

  const isPayAdvanceSchemeActive = ProductService.isPayAdvanceSchemeActive(
    props.product?.skuCode
  );

  const showTodProgressBar = props?.product?.turnoverData?.turnoverDetails?.[0]
    ?.constraintsStatus?.[0]?.slabConstraintsStatus?.[0]
    ?.showTODProgressBarOnPLP
    ? true
    : false;

  const {
    lastAchievedSlabDetails,
    currentTargetSlabDetails,
    qualifiedSlabRelativePercentageAchieved,
  } = SchemesService.getDataForTurnoverSchemeProgressBar(
    showTodProgressBar,
    props?.product
  );

  return (
    <Grid container spacing={0} direction="row" sx={{ flexGrow: 1 }}>
      <Grid item xs={12}>
        <Card className={classes.productCardWrapper} variant={"elevation"}>
          <CardContent className={classes.productCardContent}>
            <a
              href={`/products/${props.product.skuCode}?from=${window.location.pathname}`}
              className={classes.productCardWrapperLink}
              onClick={() => handleClickProductClick()}
            >
              <Grid
                container
                direction={`row`}
                justifyContent="center"
                alignItems="flex-start"
                className={classes.productImageContainer}
                spacing={0}
              >
                <Grid item xs={4}>
                  <div className={classes.imageWrapper}>
                    <img
                      className={classes.productImage}
                      src={ProductService.getProductCardImageUrl(
                        props.product,
                        isPayAdvanceSchemeActive
                      )}
                      alt={""}
                    />
                  </div>
                </Grid>

                <Grid item xs={8}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item xs={9} sx={{ textAlign: "left" }}>
                      <Typography className={classes.title}>
                        <Clamp withTooltip lines={2}>
                          {props?.product?.productName}
                        </Clamp>
                      </Typography>
                      <Box sx={{ marginTop: "16px" }}>
                        <ProductListPrice product={props?.product} />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={classes.ratingLabelWrapper}>
                        {props?.product?.rating ? (
                          <StarRatingLabel
                            rating={props.product.rating.average}
                          />
                        ) : (
                          <Box sx={{ minHeight: "25px" }}></Box>
                        )}
                      </div>
                      {ProductService.getClassImageUrl(
                        props.product?.productClass
                      ) && localStorage.getItem("role") === "fieldAgent" ? (
                        <img
                          style={{
                            height: "30px",
                            marginTop: "8px",
                            borderRadius: "5px",
                          }}
                          src={ProductService.getClassImageUrl(
                            props.product?.productClass
                          )}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {PartnerService.isProductPriceBlocked(props?.product) &&
                    !PartnerService.smudgePriceDueToOnboardingInProgress() ? (
                      <>
                        <Grid
                          container
                          alignItems={`center`}
                          justifyContent={`center`}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              background: "rgba(224, 224, 224, 1)",
                              paddingBottom: "5.5px",
                              paddingTop: "5.5px",
                              borderRadius: "10px 10px 0px 0px",
                            }}
                          >
                            <Typography
                              variant={`body1`}
                              sx={{
                                color: "#333333",
                                fontSize: "12px",
                                marginLeft: "4px",
                              }}
                            >
                              Price will be available soon
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : isPayAdvanceSchemeActive ? (
                      <>
                        <Grid
                          container
                          alignItems={`center`}
                          justifyContent={`center`}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              background: "#F8D149",
                              paddingBottom: "5.5px",
                              paddingTop: "5.5px",
                              borderRadius: "10px 10px 0px 0px",
                            }}
                          >
                            <Typography
                              variant={`body1`}
                              sx={{
                                color: "#4F4F4F",
                                fontSize: "12px",
                                marginLeft: "4px",
                              }}
                            >
                              <img
                                src={`/icons/offersGreenIcon.svg`}
                                alt=""
                                className={classes.cdBenefitIcon}
                              />
                              Advanced Booking Open
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : showTodProgressBar ? (
                      <TurnoverSchemeProgressBar
                        section={props?.section}
                        productCardType={"horizontal"}
                        product={props?.product}
                        showTodProgressBar={showTodProgressBar}
                        lastAchievedSlabDetails={lastAchievedSlabDetails}
                        currentTargetSlabDetails={currentTargetSlabDetails}
                        qualifiedSlabRelativePercentageAchieved={
                          qualifiedSlabRelativePercentageAchieved
                        }
                      />
                    ) : props.product.offers !== null &&
                      (Object.entries(props.product.offers).length !== 0 ||
                        Object.entries(props.product.offersDetail).length !==
                          0) ? (
                      <Grid
                        container
                        alignItems={`center`}
                        justifyContent={`flex-start`}
                      >
                        <Grid
                          item
                          xs={9}
                          sx={{
                            paddingBottom: "3px",
                            paddingTop: "4px",
                            marginLeft: "4px",
                            textAlign: "left",
                          }}
                        >
                          <Typography
                            variant={`body1`}
                            sx={{
                              color: "#9D2123",
                              fontSize: "12px",
                              marginLeft: "4px",
                            }}
                          >
                            <img
                              src={`/icons/offersRedIcon.svg`}
                              alt=""
                              className={classes.offersIcon}
                            />
                            Offers Applied
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      props?.product?.isCashDiscountApplicable && (
                        <Grid
                          container
                          alignItems={`center`}
                          justifyContent={`flex-start`}
                        >
                          <Grid
                            item
                            xs={9}
                            sx={{
                              background: "rgba(0, 115, 62, 0.1)",
                              // marginTop: "-12px",
                              paddingBottom: "5.5px",
                              paddingTop: "5.5px",
                              borderRadius: "10px 10px 0px 0px",
                              textAlign: "left",
                            }}
                          >
                            <Typography
                              variant={`body1`}
                              sx={{
                                color: "#00733E",
                                fontSize: "12px",
                                marginLeft: "4px",
                              }}
                            >
                              <img
                                src={`/icons/offersGreenIcon.svg`}
                                alt=""
                                className={classes.cdBenefitIcon}
                              />
                              Check CD Benefit
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Grid item xs={4}>
                    {props.path === "/featured-videos" && (
                      <Typography
                        className={classes.buy}
                        sx={{
                          marginTop: "-15px",
                        }}
                      >
                        Buy Now{" "}
                        <img
                          style={{ marginBottom: "-2px" }}
                          src="/icons/buyArrow.svg"
                          alt={"Buy Now"}
                        />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </a>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
