import config from "../config";
import {
  MyFarmersMockPage1,
  MyFarmersMockPage2,
  MyFarmersMockPage3,
  MyFarmersSummaryMock,
  ScanRewardRedeemHistoryMock,
} from "./mocks/FarmersMock";
import Languages from "./LanguageCode";
import HTTPService from "./HTTPService";

const useMocks = false;

export default class FarmersService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getMyFarmersSummary(duration) {
    let url = `${
      config.backendUrl
    }onlineecommerceservice/v2/${localStorage.getItem(
      `farmerId`
    )}/myfarmers/summary/`;

    if (duration) {
      url = url + `?duration=${duration}`;
    }

    if (useMocks === false) {
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: MyFarmersSummaryMock });
        }, 300);
      });
    }
  }

  static getMyFarmers(page) {
    let url = `${
      config.backendUrl
    }onlineecommerceservice/v2/${localStorage.getItem(
      `farmerId`
    )}/myfarmers/?page=${page}`;

    if (useMocks === false) {
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (page === 1) {
            resolve({ data: MyFarmersMockPage1 });
          }
          if (page === 2) {
            resolve({ data: MyFarmersMockPage2 });
          }
          if (page === 3) {
            resolve({ data: MyFarmersMockPage3 });
          }
        }, 1500);
      });
    }
  }

  static getScanRewardRedeemHistory(farmerId) {
    const language = Languages[localStorage.getItem("state")?.toUpperCase()]
      ? Languages[localStorage.getItem("state")?.toUpperCase()]
      : "en";

    if (useMocks === false) {
      const url = `${config.backendUrl}onlineecommerceservice/v2/barcodescanredeemed/?farmerId=${farmerId}&getProductDetails=${true}&language=${language}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: ScanRewardRedeemHistoryMock });
        }, 300);
      });
    }
  }
}
