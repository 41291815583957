import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab } from "@mui/material";
import TopBar from "../Common/TopBar";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../Common/ErrorBoundary";
import MyPartners from "./MyPartners";
import OnboardingLeads from "./OnboardingLeads";
import useQuery from "../../Hooks/useQuery";
import useProfile from "../../Hooks/useProfile";
import PartnerLoginInProgressDialog from "./PartnerLoginInProgressDialog";

const Partners = (props) => {
  let navigate = useNavigate();
  const query = useQuery();

  const [authorized, setAuthorized] = useState(
    localStorage.getItem(`fieldAgentMobileNumber`) &&
      localStorage.getItem(`fieldAgentMobileNumber`) !== undefined &&
      localStorage.getItem("role") === "fieldAgent" &&
      localStorage.getItem("otpVerified") === "true"
  );
  const TAB_ONE = "myPartners";
  const TAB_TWO = "onboardingLeads";
  const [currentTab, setCurrentTab] = useState(TAB_ONE);
  const { saveProfile, loading, errorCode, errorMessage } = useProfile();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (loading === "started" || errorCode !== null) {
      setOpenDialog(true);
    }
  }, [loading, errorCode]);

  useEffect(() => {
    if (authorized) {
      const partnerId = query.get("partnerId");
      const isFieldAgent = localStorage.getItem("role") === "fieldAgent";
      if (
        localStorage.getItem("farmerId") &&
        localStorage.getItem("farmerId") !== undefined
      ) {
        if (isFieldAgent && partnerId) {
          PartnerService.logoutFieldAgentAsPartner();
          window.location.reload();
        } else {
          navigate("/dashboard");
        }
      } else {
        if (isFieldAgent && partnerId) {
          saveProfile(
            partnerId,
            "/hisaab/pay-by-product-group",
            "Login As Field Agent Successful",
            "Login As Field Agent Failed"
          );
        } else {
          navigate("/partners");
        }
      }
    } else {
      navigate("/");
    }
  }, [authorized]);

  const handleLogout = () => {
    PartnerService.logoutFieldAgent();
    AnalyticsService.pushEvent("Logout As Field Agent Clicked");
    setAuthorized(false);
    navigate("/");
    window.location.reload();
  };

  const handleTabClickedEvent = (tabName, pageName) => {
    AnalyticsService.pushEvent(`Tab Clicked`, {
      tabName: tabName,
      page: pageName,
    });
    setCurrentTab(tabName);
  };

  return (
    <>
      <TopBar
        title={`SAATHI`}
        showCart={false}
        showHisaab={false}
        showCallAgriDoctor={false}
        showFieldAgentLogout={true}
        handleLogout={handleLogout}
      />
      <Container maxWidth={`xl`} sx={{ paddingTop: 2 }}>
        <ErrorBoundary message={``}>
          <Tabs
            value={currentTab}
            centered
            sx={{
              background: "#ffffff",
              marginBottom: "16px",
              padding: "0px 16px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
              borderRadius: "20px",
            }}
          >
            <Tab
              label="My Partners"
              value={TAB_ONE}
              sx={{
                width: "50%",
                borderBottom: `${
                  currentTab === TAB_ONE ? `1px solid #9D2123` : `none`
                }`,
                color: `${currentTab === TAB_ONE ? `#9D2123` : `#333333`}`,
                opacity: `${currentTab === TAB_ONE ? `1` : `0.6`}`,
                textTransform: "capitalize",
                fontSize: "14px",
                padding: "20px 8px",
              }}
              onClick={() => {
                handleTabClickedEvent(TAB_ONE, "Partners");
              }}
            />
            <Tab
              label="Onboarding Leads"
              value={TAB_TWO}
              sx={{
                width: "50%",
                borderBottom: `${
                  currentTab === TAB_TWO ? `1px solid #9D2123` : `none`
                }`,
                color: `${currentTab === TAB_TWO ? `#9D2123` : `#333333`}`,
                opacity: `${currentTab === TAB_TWO ? `1` : `0.6`}`,
                textTransform: "capitalize",
                fontSize: "14px",
                padding: "20px 8px",
              }}
              onClick={() => {
                handleTabClickedEvent(TAB_TWO, "Partners");
              }}
            />
          </Tabs>
        </ErrorBoundary>

        {currentTab === TAB_ONE && <MyPartners authorized={authorized} />}

        {currentTab === TAB_TWO && <OnboardingLeads authorized={authorized} />}
      </Container>

      <PartnerLoginInProgressDialog
        loading={loading}
        open={openDialog}
        errorCode={errorCode}
        partner={{ partnerId: query.get("partnerId") }}
        errorMessage={errorMessage}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
};

export default Partners;
