import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import useQuery from "../../Hooks/useQuery";
import _ from "lodash";
import AmountUtils from "../../Utils/AmountUtils";
import OrderPlacedDetailCard from "../Orders/OrderPlacedDetailCard";
import AndroidService from "../../Services/AndroidService";
import { WhatsappShareButton } from "react-share";
import ProductService from "../../Services/ProductService";

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: "#ffffff", minHeight: window.innerHeight },
  successIconWrapper: {
    position: "relative",
    marginTop: theme.spacing(8),
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  successIcon: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    position: "absolute",
    top: 0,
    left: 0,
  },
  successIconCongrats: {
    animation: "$hideAfterSingleRun 1.83s forwards",
    width: theme.spacing(16),
    height: theme.spacing(16),
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    transform: "scale(2.5)",
  },
  "@keyframes hideAfterSingleRun": {
    "0%": {
      opacity: 0,
    },
    "1%": {
      opacity: 1,
    },
    "99%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  successMessage: {
    fontWeight: "bold",
    lineHeight: `1.3`,
    fontSize: "18px",
    color: "#000000",
    textAlign: "left",
    "&>span": {
      fontSize: "14px",
      fontWeight: "normal",
    },
  },
  successMessageWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  buttonStyle: {
    borderRadius: "0",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "0.56px",
    textTransform: "uppercase",
  },
  billAmount: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    margin: "0px 8px",
    background: "#777777",
    opacity: "70%",
    borderRadius: "9px",
    fontSize: "14px",
    fontWeight: 400,
  },
  fixedShareIcon: {
    width: "75px",
    height: "26px",
    position: "fixed",
    top: "20px",
    right: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    borderRadius: "16px",
    background: "#9D2123 !important",
    boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
    padding: "5px 10px 5px 14px",
    color: "white !important",
    fontWeight: "700 !important",
    fontSize: "12px !important",
    textTransform: "none",
    gap: "4px",
  },
}));

function SuccessfulRequest() {
  const classes = useStyles();
  let navigate = useNavigate();
  const query = useQuery();
  const redirect = query.get("redirect");

  useEffect(() => {
    AnalyticsService.pushEvent(`Success Page Viewed`, {
      productsName: order?.productName,
      appliedOffersName: order?.appliedOffersName,
      appliedOffersID: order?.appliedOffersId,
      quantities: order?.quantities,
      skuCode: order?.skuCode,
      productPrice: order?.sellingPrices,
      totalBillAmount: order?.totalOrderValue,
      couponName: order?.couponName,
      requestType: "Purchase Request",
      orderIDs: `${order?.orderIds}`,
      cartId: order?.cartId,
    });
  }, []);

  const handleOkayClick = () => {
    AnalyticsService.pushEvent(`Go to cart button clicked`, {
      productsName: order?.productName,
      appliedOffersName: order?.appliedOffersName,
      appliedOffersID: order?.appliedOffersId,
      quantities: order?.quantities,
      skuCode: order?.skuCode,
      productPrice: order?.sellingPrices,
      totalBillAmount: order?.totalOrderValue,
      couponName: order?.couponName,
      requestType: "Purchase Request",
      orderIDs: `${order?.orderIds}`,
      cartId: order?.cartId,
    });
    localStorage.removeItem("order");
    if (redirect === "cart") {
      navigate(`/cart`, { replace: true });
    } else {
      sessionStorage.removeItem("scrollYPosition");
      navigate(`/dashboard`);
    }
  };

  const handleHomepageClick = () => {
    AnalyticsService.pushEvent(`Go to dashboard button clicked`, {
      productsName: order?.productName,
      appliedOffersName: order?.appliedOffersName,
      appliedOffersID: order?.appliedOffersId,
      quantities: order?.quantities,
      skuCode: order?.skuCode,
      productPrice: order?.sellingPrices,
      totalBillAmount: order?.totalOrderValue,
      couponName: order?.couponName,
      requestType: "Purchase Request",
      orderIDs: `${order?.orderIds}`,
      cartId: order?.cartId,
    });
    localStorage.removeItem("order");
    sessionStorage.removeItem("scrollYPosition");
    navigate(`/dashboard`);
  };

  const order = localStorage.getItem("order")
    ? JSON.parse(localStorage.getItem("order"))
    : null;

  if (order === null) {
    sessionStorage.removeItem("scrollYPosition");
    navigate({
      pathname: "/dashboard",
    });
  }

  const partnerName = localStorage.getItem("partnerName");
  const partnerId = localStorage.getItem("farmerId");

  const orderDetails = `Order ${order?.orderIds} - ${partnerName} (${partnerId})`;

  const totalOrderValue = AmountUtils.format(order?.totalOrderValue);
  const orderId = order?.orderIds;
  const maxProductsToShowOnImageOverlay = 15;
  const maxCharactersInALine = 38;
  const productsOrdered = order?.productName
    .split(",")
    .slice(0, maxProductsToShowOnImageOverlay);

  var yOffset = 390;
  const overlayProductFontSize = 15;
  const xOffset = 42;
  const yOffsetBetweenTwoProducts = 20;

  let productsOverlayUrl = ProductService.getProductsOverlayUrl(
    xOffset,
    yOffset,
    yOffsetBetweenTwoProducts,
    productsOrdered,
    maxCharactersInALine,
    maxProductsToShowOnImageOverlay,
    overlayProductFontSize
  );

  const shareUrl = `https://static.agrostar.in/saathi-order-share/tr:w-400,f-jpg,l-text,i-${orderId},fs-16,lx-113,ly-375,ff-fonts@@NotoSans-Bold.ttf,l-end:l-text,i-Rs.${totalOrderValue},fs-16,lx-280,ly-375,ff-fonts@@NotoSans-Bold.ttf,l-end:l-text,i-Rs.${totalOrderValue},fs-17,lx-280,ly-812,co-white,ff-fonts@@NotoSans-Bold.ttf,l-end:${productsOverlayUrl}/lead-share.jpg`;

  const handleAndroidShareClickEvent = () => {
    handleWhatsAppShareClickEvent();
    AndroidService.shareOnWhatsApp(`${orderDetails}`, shareUrl);
  };
  const handleWhatsAppShareClickEvent = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "WhatsApp Share",
      imageUrl: shareUrl,
    });
  };
  return (
    <div className={classes.root}>
      {AndroidService.checkIfAndroid() ? (
        <Button
          variant="contained"
          className={classes.fixedShareIcon}
          startIcon={<img src={`/icons/share.svg`} alt="" />}
          onClick={handleAndroidShareClickEvent}
          sx={{
            gap: "0px !important",
            "& .MuiButton-startIcon": {
              marginRight: "4px",
            },
          }}
        >
          Share
        </Button>
      ) : (
        <WhatsappShareButton
          className={classes.fixedShareIcon}
          url={shareUrl}
          title={orderDetails}
          onClick={handleWhatsAppShareClickEvent}
        >
          <img src={`/icons/share.svg`} alt="" />
          Share
        </WhatsappShareButton>
      )}

      <ErrorBoundary message={``}>
        <Box
          sx={{
            height: "92vh",
            maxHeight: "92vh",
            overflow: "scroll",
            paddingBottom: "40px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div className={classes.successIconWrapper}>
              <img
                src={`/icons/congratulations.gif`}
                className={classes.successIconCongrats}
                alt=""
              />
              <img
                src={`/icons/success.svg`}
                className={classes.successIcon}
                alt=""
              />
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.successMessageWrapper}
          >
            <Grid item xs={11}>
              <Typography
                align={"center"}
                variant={`body1`}
                color={`secondary`}
                className={classes.successMessage}
              >
                We've received your purchase request.
                <br />
                <span>
                  Our team will reach out shortly to confirm your order.{" "}
                </span>
                <br />
                <br />
                Why it's a purchase request?
                <br />
                <span>
                  Some items in your order need a quick review to ensure best
                  possible service
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Container maxWidth={`xl`} sx={{ margin: "16px 0" }}>
              {order?.productName && (
                <OrderPlacedDetailCard
                  order={{
                    ...order,
                    orderId: order.orderIds,
                    products: order.productName.split(","),
                  }}
                  onOrderClicked={() => {
                    const orderDetailsClickedEventData = {
                      orderState: "",
                      estimatedDeliveryDate: "",
                      productNames: order.productName,
                      orderId: order.orderIds || "",
                      numberOfProducts: order.numberOfProducts || 0,
                      totalOrderValue: order.totalOrderValue || 0,
                      requestType: "Place Request",
                    };

                    AnalyticsService.pushEvent(
                      `Order Details Clicked`,
                      orderDetailsClickedEventData
                    );

                    navigate({
                      pathname: `/orders`,
                    });
                  }}
                />
              )}
            </Container>
          </Grid>
        </Box>
        <Box
          sx={{
            height: "8vh",
            display: "flex",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
        >
          {query.get("redirect") === "cart" && (
            <Button
              variant={"outlined"}
              fullWidth={true}
              className={classes.buttonStyle}
              sx={{
                background: "#FFF",
                "&:focus": {
                  backgroundColor: `#F5F5F5`,
                },
                "&:hover": {
                  backgroundColor: `#F5F5F5`,
                },
              }}
              onClick={handleOkayClick}
            >
              Go To Cart
            </Button>
          )}
          <Button
            variant={"contained"}
            fullWidth={true}
            className={classes.buttonStyle}
            onClick={handleHomepageClick}
          >
            Go To Homepage
          </Button>
          <Box
            sx={{
              height: "20px",
              position: "absolute",
              top: "-50px",
              left: 0,
              color: "white",
              width: "100%",
            }}
          >
            <Box className={classes.billAmount}>
              <span>Total Bill Amount: </span>
              <span>
                <b>₹ {AmountUtils.format(order?.totalOrderValue)}</b>
              </span>
            </Box>
          </Box>
        </Box>
      </ErrorBoundary>
    </div>
  );
}

export default SuccessfulRequest;
