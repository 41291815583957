import React, { useEffect, useState } from "react";
import { Skeleton, CardContent, Snackbar, Alert } from "@mui/material";
import _ from "lodash";
import EmptyError from "../EmptyError/EmptyError";
import AnalyticsService from "../../Services/AnalyticsService";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import TicketService from "../../Services/TicketService";
import FloatingActionButton from "../Common/FloatingActionButton";
import CommonConstantValues from "../../Services/CommonConstantValues";
import ComplaintHistoryCards from "./ComplaintHistoryCards";
import Languages from "../../Services/LanguageCode";
import useMessages from "../../Hooks/useMessages";

function ComplaintHistory() {
  const { getMessage } = useMessages();
  const [complaintsList, setComplaintsList] = useState([]);
  const [loading, setLoading] = useState(CommonConstantValues.NOT_STARTED);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "en";

  useEffect(() => {
    localStorage.removeItem("createComplaintFromProfilePage");
    setLoading(CommonConstantValues.STARTED);
    const { axiosRequest, abortController } =
      TicketService.getComplaintHistory(languageCode);
    axiosRequest
      .then(({ data }) => {
        const pageViewedEventData = {
          page: "Complaints History",
        };
        AnalyticsService.pushEvent(`Page Viewed`, pageViewedEventData);

        setLoading(CommonConstantValues.DONE);
        setComplaintsList(data?.responseData?.ticketHistory || []);
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading(CommonConstantValues.DONE);
          setShowErrorMessage(true);
        }
      });
    return () => abortController.abort();
  }, []);

  return (
    <>
      <ErrorBoundary>
        <TopBar
          title={getMessage("complaintHistory.header")}
          backNavigation={true}
          showHisaab={true}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <CardContent>
          {loading === CommonConstantValues.STARTED && (
            <>
              <Skeleton
                variant="rectangular"
                height={125}
                sx={{ marginTop: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                height={125}
                sx={{ marginTop: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                height={125}
                sx={{ marginTop: "10px" }}
              />
            </>
          )}

          {loading === CommonConstantValues.DONE && complaintsList.length ? (
            _.map(complaintsList, (complaintDetails) => {
              return (
                <ComplaintHistoryCards complaintDetails={complaintDetails} />
              );
            })
          ) : (
            <></>
          )}
          {loading === CommonConstantValues.DONE &&
          complaintsList.length === 0 ? (
            <EmptyError
              image="noComplaints.svg"
              text={getMessage(`complaintHistory.noComplaintsMessage`)}
            />
          ) : (
            <></>
          )}
        </CardContent>

        {localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
          <FloatingActionButton
            title={getMessage(`complaintHistory.fabHeader`)}
            fabIconPath={"/icons/complaintFABIcon.svg"}
            currentPagePath={"Complaints History"}
            clickedEventTitle={`Fab Clicked`}
            nextPagePath={`/complaints/create`}
            isFromProfilePage={false}
          />
        )}
      </ErrorBoundary>
      <Snackbar
        open={showErrorMessage}
        autoHideDuration={5000}
        onClose={() => setShowErrorMessage(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          marginBottom: "55px",
        }}
      >
        <Alert
          icon={false}
          sx={{
            background: "#f26b6b",
            color: "#ffffff",
            width: "328px",
          }}
          onClose={() => setShowErrorMessage(false)}
        >
          Apologies, it seems that an unexpected error occurred. Please come
          back later!
        </Alert>
      </Snackbar>
    </>
  );
}

export default ComplaintHistory;
