import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

import { Close } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";

import useFocus from "../../Hooks/useFocus";
import AnalyticsService from "../../Services/AnalyticsService";

const PartnersSearch = (props) => {
  const [inputRef, setInputFocus] = useFocus();
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    setKeyword(props?.searchKeyword || "");
  }, [props?.searchKeyword]);

  const handleChange = (event) => {
    props.handleSearch(event?.target?.value?.trim());
  };

  const handleChangeDebounced = useCallback(debounce(handleChange, 1000), []);

  const handleChangeInput = (event) => {
    handleChangeDebounced(event);
    setKeyword(event?.target?.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.setAttribute("readonly", true); // Force keyboard to hide on input field.

      setTimeout(function () {
        event.target.blur(); //actually close the keyboard
        event.target.removeAttribute("readonly"); // Remove readonly attribute after keyboard is hidden.
      }, 100);
    }
  };

  return (
    <Box>
      <TextField
        inputRef={inputRef}
        onClick={() =>
          AnalyticsService.pushEvent(`Partners Search Clicked`, {
            page: props?.page ? props?.page : "",
            tab: props?.tab ? props?.tab : "",
          })
        }
        onKeyDown={handleKeyDown}
        autoFocus={
          window.location.pathname && window.location.pathname === `/partners`
        }
        sx={{
          marginBottom: 1,
          background: "#FFFFFF",

          borderRadius: 2,
          [`& fieldset`]: {
            border: "none",
            borderRadius: 2,
          },
          boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1)",
        }}
        fullWidth={true}
        placeholder={
          props?.placeholderText ? props?.placeholderText : "Search Partners"
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" onClick={(event) => {}}>
              <img src={`/icons/search.svg`} alt="Search" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ cursor: "default", borderRadius: "10px" }}
              onClick={() => {
                props.clearSearch();
                setKeyword(``);
              }}
            >
              {keyword && keyword.length > 0 && <Close color="primary" />}
            </InputAdornment>
          ),
          type: "text",
        }}
        variant="outlined"
        size={`small`}
        value={keyword}
        onChange={handleChangeInput}
      />

      {props?.errorMessage && (
        <span style={{ fontSize: "12px", color: "red", padding: "0 5px" }}>
          {props?.errorMessage}
        </span>
      )}
    </Box>
  );
};

export default PartnersSearch;
