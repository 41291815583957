import makeStyles from "@mui/styles/makeStyles";

import IframeContent from "../Common/IframeContent";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import { useEffect, useState } from "react";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  containerIframe: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "90vh !important",
    paddingTop: "56.25%" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
  },
  /* Then style the iframe to fit in the container div with full height and width */
  responsiveIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "90vh",
    border: "0px",
  },
}));

const DiwaliGreeting = () => {
  const classes = useStyles();
  const [iframeData, setIframeData] = useState(null);
  useEffect(() => {
    setIframeData({
      src: `https://${
        config.farmerAppBaseUrl
      }/diwali-greeting/greetings/?source=${localStorage.getItem(
        `source`
      )}&farmerId=${localStorage.getItem(`farmerId`)}`,
      iframeClass: classes.responsiveIframe,
    });
  }, []);

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Diwali Greeting`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
          toolTip={`Diwali Greeting`}
          position={"sticky"}
        />
        <div className={classes.containerIframe}>
          {iframeData && <IframeContent iframeData={iframeData} />}
        </div>
      </ErrorBoundary>
    </>
  );
};

export default DiwaliGreeting;
