import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import CouponCard from "./CouponCard";

const useStyles = makeStyles((theme) => ({
  couponCardWrapper: {
    margin: theme.spacing(2),
    marginBottom: 0,
    marginTop: theme.spacing(1),
    border: "1px solid #9D2123",
    borderStyle: "dashed",
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "0",
    cursor: "pointer",
    background: "#FFFFFF",
  },
  couponCard: {
    padding: theme.spacing(0, 2),
    margin: 0,
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  couponCardWrapperApplied: {
    margin: theme.spacing(2),
    marginBottom: 0,
    marginTop: theme.spacing(1),
    border: "1px solid #9D2123",
    borderStyle: "dashed",
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    background: "none",
  },
  applyText: {
    margin: 0,
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  appliedOfferWrapper: {
    margin: "8px 16px 0px",
  },
  viewAllCoupon: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #9D2123",
    margin: "10px 16px 0px",
    padding: "4px 8px",
    textTransform: "none",
    width: "calc(100% - 32px)",
  },
}));

function Coupon({
  onApplySelected,
  cart,
  handleApplyOnCouponClicked,
  onRemove,
}) {
  const classes = useStyles();
  const offerId = cart.appliedCoupon;
  const appliedOffer = _.find(
    cart.applicableCoupons,
    (applicableCoupon) => applicableCoupon.offerId === offerId
  );
  return (
    <>
      {appliedOffer ? (
        <Box className={classes.appliedOfferWrapper}>
          <CouponCard
            coupon={appliedOffer}
            isChecked={true}
            showRadio={false}
            appliedOffer={appliedOffer}
            onRemove={onRemove}
          />
        </Box>
      ) : cart?.applicableCoupons?.length ? (
        <Box>
          <Box className={classes.appliedOfferWrapper}>
            <CouponCard
              coupon={cart.applicableCoupons[0]}
              isChecked={false}
              showRadio={false}
              showApply={true}
              handleApplyOnCouponClicked={handleApplyOnCouponClicked}
            />
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Button
        className={classes.viewAllCoupon}
        fullWidth
        size={"large"}
        variant={`outlined`}
        onClick={() => onApplySelected("View All")}
        startIcon={
          !cart?.applicableCoupons?.length && (
            <img src="../icons/applyCoupon.svg" alt="icon" />
          )
        }
      >
        {cart?.applicableCoupons?.length ? "View All Coupons" : "Apply Coupons"}
      </Button>
    </>
  );
}

export default Coupon;
