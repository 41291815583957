import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CloseIcon from "@mui/icons-material/Close";
import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";

const TerritorySelect = (props) => {
  const [open, setOpen] = useState(false);
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState("All");
  const [previousSelectedOption, setPreviousSelectedOption] = useState(null);
  const [clearTerritoryFilters, setClearTerritoryFilters] = useState(false);

  const handleClickOpenTerritoryFilter = () => {
    AnalyticsService.pushEvent(`Filters Button Clicked`, {
      filterCategory: `Territory`,
    });
    setOpen(true);
    AndroidService.unsetPullToRefresh();
  };

  const handleClickCloseTerritoryFilter = () => {
    setOpen(false);
    AndroidService.setPullToRefresh();
    if (clearTerritoryFilters) {
      setCurrentlySelectedOption(previousSelectedOption);
      setPreviousSelectedOption("All");
      setClearTerritoryFilters(false);
    }
  };

  const handleClickApplyTerritoryFilter = () => {
    AnalyticsService.pushEvent(`Apply Filter Clicked`, {
      filterCategory: `Territory`,
      selectedTerritory: currentlySelectedOption,
    });
    setOpen(false);
    AndroidService.setPullToRefresh();
    props?.setSelectedTerritory(currentlySelectedOption);
  };

  const handleClickClearTerritoryFilter = () => {
    AnalyticsService.pushEvent(`Clear All Filters Clicked`, {
      filterCategory: `Territory`,
    });

    setPreviousSelectedOption(currentlySelectedOption);
    setCurrentlySelectedOption("All");
    setClearTerritoryFilters(true);
  };

  useEffect(() => {
    if (props?.clearAllFilters) {
      setPreviousSelectedOption("All");
      setCurrentlySelectedOption("All");
      setClearTerritoryFilters(true);
    }
  }, [props?.clearAllFilters]);

  return (
    <>
      <Chip
        clickable={true}
        sx={{
          boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1)",
          backgroundColor: `${
            props?.selectedTerritory && props?.selectedTerritory !== `All`
              ? `#9D2123 !important`
              : `#FFFFFF !important`
          }`,
          color: `${
            props?.selectedTerritory && props?.selectedTerritory !== `All`
              ? `#FFFFFF`
              : `#4F4F4F`
          }`,
          fontWeight: 700,
        }}
        deleteIcon={
          <ExpandMoreRoundedIcon
            sx={{
              marginLeft: "-16px !important",
              fontSize: "32px !important",
              color: `${
                props?.selectedTerritory && props?.selectedTerritory !== `All`
                  ? `#FFFFFF !important`
                  : `#4F4F4F !important`
              }`,
            }}
          />
        }
        label={
          props?.selectedTerritory === `All`
            ? `Select Territory`
            : props?.selectedTerritory
        }
        onClick={handleClickOpenTerritoryFilter}
        onDelete={handleClickOpenTerritoryFilter}
      />

      <SwipeableDrawer
        anchor={`bottom`}
        open={open}
        onClose={handleClickCloseTerritoryFilter}
        onOpen={handleClickOpenTerritoryFilter}
        PaperProps={{
          style: {
            borderRadius: "24px 24px 0px 0px",
            overflowY: "hidden",
          },
        }}
        disableDiscovery={true}
        disableSwipeToOpen={true}
      >
        <Box
          sx={{ height: "80vh" }}
          role="presentation"
          onKeyDown={handleClickCloseTerritoryFilter}
        >
          <Box
            sx={{
              position: "sticky",
              top: "0",
              zIndex: 100,
              background: "#FFFFFF",
              paddingTop: "16px",
            }}
          >
            <Grid
              container
              alignItems={`center`}
              justifyContent={`space-between`}
              sx={{ padding: "0px 20px 16px 20px" }}
            >
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {`Select Territory`}
              </Typography>
              <CloseIcon
                onClick={() => {
                  handleClickCloseTerritoryFilter();
                  AnalyticsService.pushEvent(`Close Filters Button Clicked`, {
                    filterCategory: `Territory`,
                  });
                }}
                sx={{
                  color: "#999999",
                  fontSize: "28px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Divider />
          </Box>

          <Box
            sx={{
              background: "#F3F3F3",
              paddingBottom: "60px",
              height: "70vh",
              overflowY: "scroll",
            }}
          >
            <FormControl fullWidth sx={{ padding: "0px 12px" }}>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => {
                  setPreviousSelectedOption(currentlySelectedOption);
                  setCurrentlySelectedOption(event?.target?.value);
                }}
                value={currentlySelectedOption}
              >
                {_.map(props?.territories, (territory, index) => (
                  <FormControlLabel
                    labelPlacement="start"
                    value={territory}
                    control={<Radio />}
                    label={territory}
                    key={index}
                    sx={{
                      margin: "0px",
                      justifyContent: "space-between",
                      textTransform: "uppercase",
                      color: "#4F4F4F",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px",
                        fontWeight: 700,
                        paddingLeft: "8px",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              position: "sticky",
              bottom: "0",
              width: "100%",
              zIndex: 100,
              background: "#FFFFFF",
            }}
          >
            <Grid
              container
              spacing={0}
              alignItems={`center`}
              justifyContent={`center`}
              textAlign={`right`}
            >
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  disableRipple
                  disableTouchRipple
                  fullWidth="true"
                  size={"medium"}
                  onClick={() => {
                    handleClickClearTerritoryFilter();
                  }}
                  sx={{
                    fontWeight: 700,
                    padding: "10px 15px",
                    borderRadius: "0px",
                    boxShadow: "none",
                    backgroundColor: "#FFFFFF",
                    color: "#333333",
                    border: "none !important",
                    "&:hover": {
                      border: "none !important",
                    },
                  }}
                >
                  Clear All
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth="true"
                  color="primary"
                  size={"medium"}
                  onClick={() => {
                    handleClickApplyTerritoryFilter();
                  }}
                  sx={{
                    fontWeight: 700,
                    padding: "10px 15px",
                    borderRadius: "0px",
                    boxShadow: "none",
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default TerritorySelect;
