import config from "../config";
import {
  NewOrdersMock,
  StoreCatalogNPLProducts,
  StoreFrontCatalogMock,
} from "./mocks/StoreFrontCatalogMock";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class StoreFrontService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getStoreFrontCatalog() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }catalogservice/v1/saathicataloginventoryproducts?district=${localStorage.getItem(
        `district`
      )}&partnerId=${localStorage.getItem(`farmerId`)}&language=en`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ data: StoreFrontCatalogMock });
        }, 1000);
      });
    }
  }

  static getCatalogNPLProducts() {
    if (useMocks === false) {
      const partnerId = parseInt(localStorage.getItem(`farmerId`));
      const url = `${config.backendUrl}catalogservice/v1/saathicatalognplproducts?partnerId=${partnerId}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ data: StoreCatalogNPLProducts });
        }, 1000);
      });
    }
  }

  static getCatalogProductRecommendations() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }catalogservice/v1/saathicatalogdistrictproducts?district=${localStorage.getItem(
        `district`
      )}&partnerId=${localStorage.getItem(
        `farmerId`
      )}&language=en&offset=0&limit=100`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ data: StoreFrontCatalogMock });
        }, 1000);
      });
    }
  }

  static toggleStoreFrontCatalogItem(
    skuCode,
    isEnabled,
    sellingPrice,
    quantity
  ) {
    const payload = {
      partnerId: parseInt(localStorage.getItem(`farmerId`)),
      skuCode: skuCode,
      isEnabled: isEnabled,
      sellingPrice,
      quantity,
    };

    if (useMocks === false) {
      const url = `${config.backendUrl}catalogservice/v1/saathicatalogskus`;
      return HTTPService.makeXHRRequest(url, "PUT", payload, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ data: StoreFrontCatalogMock });
        }, 1000);
      });
    }
  }

  static toggleStoreFrontCatalogItems(products) {
    const payload = {
      partnerId: parseInt(localStorage.getItem(`farmerId`)),
      products,
    };

    if (useMocks === false) {
      const url = `${config.backendUrl}catalogservice/v1/saathi-catalog-skus-bulk`;
      return HTTPService.makeXHRRequest(
        url,
        "PATCH",
        payload,
        this.getHeaders()
      );
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ data: StoreFrontCatalogMock });
        }, 1000);
      });
    }
  }

  static getFarmerOrders(type, offset = 0) {
    if (useMocks === false) {
      const partnerId = localStorage.getItem(`farmerId`);
      const url = `${config.backendUrl}ordermanagementservice/v4/storeorders/?type=${type}&partnerId=${partnerId}&limit=10&offset=${offset}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ data: NewOrdersMock });
        }, 1000);
      });
    }
  }

  static getStoreOrders(type, offset = 0) {
    if (useMocks === false) {
      const partnerId = localStorage.getItem(`farmerId`);
      //TODO: make changes here
      const url = `${config.backendUrl}ordermanagementservice/v4/offlineorders/?type=${type}&partnerId=${partnerId}&limit=10&offset=${offset}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          resolve({ data: NewOrdersMock });
        }, 1000);
      });
    }
  }

  static getStoreOrderDetails(cartId) {
    const url = `${config.backendUrl}ordermanagementservice/v4/offlineorders/?cartId=${cartId}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }
}
