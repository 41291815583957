export const bottomTabs = [
  {
    name: "Home",
    activeImageLink: "/icons/activeHomeTab.svg",
    inActiveImageLink: "/icons/inActiveHomeTab.svg",
    navigationLink: "/dashboard",
    activeClass: {
      marginBottom: "-10px",
      marginTop: "-22px",
      height: "40px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "22px",
      display: "none",
    },
  },
  {
    name: "Vyapaar",
    activeImageLink: "/icons/activeOfferTab.svg",
    inActiveImageLink: "/icons/inActiveOfferTab.svg",
    navigationLink: "/vyapaar",
    activeClass: {
      marginBottom: "-10.1px",
      marginTop: "-21px",
      height: "40px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "23px",
      marginLeft: "0px",
      paddingTop: "1px",
      display: "none",
    },
  },
  {
    name: "More",
    // navigationLink: "/nearby-farmers",
    activeClass: {
      marginBottom: "-10px",
      marginTop: "-22px",
      height: "40px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "0px",
      marginTop: "-12px",
      height: "20px",
      display: "none",
    },
  },
  {
    name: "Hisaab",
    activeImageLink: "/icons/activePaymentTab.svg",
    inActiveImageLink: "/icons/inActivePaymentTab.svg",
    navigationLink: "/hisaab",
    activeClass: {
      marginBottom: "-10px",
      marginTop: "-22px",
      height: "40px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "0px",
      marginTop: "-12px",
      height: "20px",
      display: "none",
    },
  },
  {
    name: "Profile",
    activeImageLink: "/icons/activeUser.svg",
    inActiveImageLink: "/icons/inActiveUser.svg",
    navigationLink: "/profile",
    activeClass: {
      marginBottom: "-8.5px",
      marginTop: "-20px",
      height: "36px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "-8.5px",
      marginTop: "-20px",
      height: "36px",
      display: "none",
    },
  },
];

export const bottomTabsBlurredExceptHisaab = [
  {
    name: "Home",
    activeImageLink: "/icons/activeHomeTab.svg",
    inActiveImageLink: "/icons/inActiveHomeTab.svg",
    navigationLink: "/dashboard",
    activeClass: {
      marginBottom: "-10px",
      marginTop: "-22px",
      height: "40px",
      display: "none",
      filter: "blur(2.5px)",
    },
    inActiveClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "22px",
      display: "none",
      filter: "blur(2.5px)",
    },
  },
  {
    name: "Vyapaar",
    activeImageLink: "/icons/activeOfferTab.svg",
    inActiveImageLink: "/icons/inActiveOfferTab.svg",
    navigationLink: "/vyapaar",
    activeClass: {
      marginBottom: "-10.1px",
      marginTop: "-21px",
      height: "40px",
      display: "none",
      filter: "blur(2.5px)",
    },
    inActiveClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "23px",
      marginLeft: "0px",
      paddingTop: "1px",
      display: "none",
      filter: "blur(2.5px)",
    },
  },
  {
    name: "More",
    // navigationLink: "/nearby-farmers",
    activeClass: {
      marginBottom: "-10px",
      marginTop: "-22px",
      height: "40px",
      display: "none",
      filter: "blur(2.5px)",
    },
    inActiveClass: {
      marginBottom: "0px",
      marginTop: "-12px",
      height: "20px",
      display: "none",
      filter: "blur(2.5px)",
    },
  },
  {
    name: "Hisaab",
    activeImageLink: "/icons/activePaymentTab.svg",
    inActiveImageLink: "/icons/inActivePaymentTab.svg",
    navigationLink: "/hisaab",
    activeClass: {
      marginBottom: "-10px",
      marginTop: "-22px",
      height: "40px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "0px",
      marginTop: "-12px",
      height: "20px",
      display: "none",
    },
  },
  {
    name: "Profile",
    activeImageLink: "/icons/activeUser.svg",
    inActiveImageLink: "/icons/inActiveUser.svg",
    navigationLink: "/profile",
    activeClass: {
      marginBottom: "-8.5px",
      marginTop: "-20px",
      height: "36px",
      display: "none",
      filter: "blur(2.5px)",
    },
    inActiveClass: {
      marginBottom: "-8.5px",
      marginTop: "-20px",
      height: "36px",
      display: "none",
      filter: "blur(2.5px)",
    },
  },
];
