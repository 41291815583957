import React, { useState } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Modal,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import WrongOptionIcon from "@mui/icons-material/CancelOutlined";
import CorrectOptionIcon from "@mui/icons-material/CheckCircleOutlined";
import { useEffect } from "react";
import StoryService from "../../Services/StoryService";
import AnalyticsService from "../../Services/AnalyticsService";
import { useParams } from "react-router-dom";
import { WithHeader, WithSeeMore } from "react-insta-stories";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(/icons/quizBackgroundImage.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  quizContainer: {
    zIndex: "1000",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "50px",
  },
  quizComponentContainer: {
    borderRadius: "16px",
    background: "#FFFFFF",
  },
  questionContainer: {
    borderRadius: "16px 16px 0px 0px",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    color: "#FFFFFF",
    padding: "6px",
    background: "#9D2123",
  },
  optionCardContainer: {
    display: "flex",
    border: "1px solid #CACACA",
    borderRadius: "16px",
    flexDirection: "row",
    padding: "10px",
    margin: "12px 14px",
  },
  optionPointerStyle: {
    width: "24px",
    height: "24px",
    border: "1px solid",
    borderRadius: "100%",
    padding: "5px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  optionTextStyle: {
    fontSize: "14px",
    fontStyle: "normal",
    margin: "0px 8px",
    flex: "1",
  },
  loaderContainer: {
    position: "absolute",
    zIndex: "1000",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: "#00000090",
  },
  loaderText: {
    color: "white",
    fontSize: "20px",
    marginTop: "20px",
    textAlign: "center",
  },
  modalBox: {
    position: "absolute",
    zIndex: "1000",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: "#00000090",
  },
  errorDialogContainer: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    padding: "20px",
    borderRadius: "4px",
    width: "70%",
  },
  errorDialogMessage: {
    marginBottom: "12px",
    color: "black",
    textAlign: "center",
  },
  errorDialogButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  successIconCongrats: {
    animation: "$hideAfterSingleRun 5s forwards",
    width: theme.spacing(16),
    height: theme.spacing(16),
    position: "absolute",
    zIndex: "1000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transform: "scale(2.5)",
  },
  "@keyframes hideAfterSingleRun": {
    "0%": {
      opacity: 0,
    },
    "1%": {
      opacity: 1,
    },
    "99%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
}));

const Renderer = ({ story, action, config }) => {
  const [pauseStory, shouldPauseStory] = useState(false);
  const [showLoader, shouldShowLoader] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showErrorDialog, shouldShowErrorDialog] = useState(false);
  const [showCelebrations, shouldShowCelebrations] = useState(false);
  const quiz = story;
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    if (params.storyId === story.storyParentCode && story.type === "quiz") {
      //This
      action(pauseStory ? "pause" : "play");
    }
  }, [action, pauseStory]);

  const updateQuizSelection = (selectedIndex) => {
    shouldShowLoader(true);
    setSelectedIndex(selectedIndex);
    const { axiosRequest } = StoryService.updateQuizSelection(
      selectedIndex,
      quiz
    );
    axiosRequest
      .then((response) => {
        shouldShowLoader(false);
        quiz.updateStoryWhenQuizIsAnswered(quiz, response.data.responseData);
        shouldPauseStory(false);
        sendQuizOptionChosenEventToAnalytics(response.data.responseData);
        setSelectedIndex(-1);
        shouldShowCelebrations(response.data.responseData.isAnsweredCorrect);
      })
      .catch((error) => {
        shouldShowLoader(false);
        shouldShowErrorDialog(true);
        setSelectedIndex(-1);
      });
  };

  const sendQuizOptionChosenEventToAnalytics = (quizResponse) => {
    const quizOptionChosen = {
      quizId: quiz.quizId,
      userSelectedIndex: quizResponse.userSelectedIndex,
      isAnsweredCorrect: quizResponse.isAnsweredCorrect,
      correctAnswerIndex: quizResponse.correctAnswerIndex,
    };
    AnalyticsService.pushEvent("Quiz Option Chosen", quizOptionChosen);
  };

  const onQuizOptionSelected = (index) => {
    if (quiz.isAnswered) return;
    shouldPauseStory(true);
    updateQuizSelection(index);
  };

  const getOptionBackgroundColor = (index) => {
    if (!quiz.isAnswered) {
      return index === selectedIndex ? "#00733e4d" : "white";
    }
    if (quiz.isAnsweredCorrect) {
      if (index === quiz.correctAnswerIndex) {
        return "#00733E";
      }
      return "white";
    } else {
      if (index === quiz.userSelectedIndex) {
        return "#9D2123";
      }
      if (index === quiz.correctAnswerIndex) {
        return "#00733e4d";
      }
      return "white";
    }
  };

  const getPointerBorderColor = (index) => {
    if (!quiz.isAnswered) return "#CACACA";
    if (
      quiz.isAnswered &&
      (index === quiz.correctAnswerIndex || index === quiz.userSelectedIndex)
    )
      return "white";
  };

  const getTextColor = (index) => {
    if (
      quiz.isAnswered &&
      (index === quiz.correctAnswerIndex || index === quiz.userSelectedIndex)
    )
      return "white";
    return "#4F4F4F";
  };

  const getOptionIcon = (index) => {
    if (!quiz.isAnswered) return "";
    if (index === quiz.correctAnswerIndex) {
      return <CorrectOptionIcon sx={{ fill: "white" }} />;
    }
    if (!quiz.isAnsweredCorrect && index === quiz.userSelectedIndex) {
      return <WrongOptionIcon sx={{ fill: "white" }} />;
    }
    return <WrongOptionIcon sx={{ fill: "#CACACA" }} />;
  };

  const okayClickedOnErrorDialog = () => {
    shouldShowErrorDialog(false);
    shouldPauseStory(false);
  };

  return (
    <WithHeader {...{ story, globalHeader: config.header }}>
      <WithSeeMore {...{ story, action }}>
        <Box className={classes.container}>
          <Box className={classes.quizContainer}>
            <Box className={classes.quizComponentContainer}>
              <Typography className={classes.questionContainer}>
                {quiz.question}
              </Typography>
              {_.map(quiz.options, (option, index) => {
                return (
                  <Box
                    className={classes.optionCardContainer}
                    sx={{
                      background: getOptionBackgroundColor(index),
                    }}
                    onClick={() => {
                      onQuizOptionSelected(index, quiz.correctAnswer);
                    }}
                    key={index}
                  >
                    <Typography
                      className={classes.optionPointerStyle}
                      sx={{
                        borderColor: getPointerBorderColor(index),
                        color: getTextColor(index),
                      }}
                    >
                      {option.id}
                    </Typography>
                    <Typography
                      className={classes.optionTextStyle}
                      sx={{
                        color: getTextColor(index),
                      }}
                    >
                      {option.value}
                    </Typography>
                    {getOptionIcon(index)}
                  </Box>
                );
              })}
            </Box>
            {/* <StoryLikeButton story={story} shouldPauseStory={shouldPauseStory} /> */}
          </Box>
          {showLoader && (
            <Box className={classes.loaderContainer}>
              <CircularProgress />
              <Typography className={classes.loaderText}>
                Please wait...We are validating your answer
              </Typography>
            </Box>
          )}
          {showCelebrations && (
            <img
              src={`/icons/congratulations.gif`}
              className={classes.successIconCongrats}
              alt=""
            />
          )}
          <Modal className={classes.modalBox} open={showErrorDialog}>
            <Box className={classes.errorDialogContainer}>
              <Typography
                variant="subtitle1"
                className={classes.errorDialogMessage}
              >
                Sorry,something went wrong. Please try again in sometime.
              </Typography>
              <Box className={classes.errorDialogButtonContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={okayClickedOnErrorDialog}
                >
                  Okay
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </WithSeeMore>
    </WithHeader>
  );
};

export const Tester = (story) => {
  return {
    condition: story.type === "quiz",
    priority: 2,
  };
};

const QuizRenderer = {
  renderer: Renderer,
  tester: Tester,
};

export default QuizRenderer;
