import { Divider, Grid, Typography } from "@mui/material";
import _ from "lodash";
import CircleIcon from "@mui/icons-material/Circle";

const SchemeBenefits = (props) => {
  return (
    <Grid container sx={{ background: "#FFFFFF", borderRadius: "10px" }}>
      <Grid item xs={6}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            padding: "21px 0px 10px 0px",
            textAlign: "center",
          }}
        >
          {`Slabs`}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ background: "rgba(248, 209, 73, 0.1)" }}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            padding: "21px 0px 10px 0px",
            textAlign: "center",
          }}
        >
          {`Benefits`}
        </Typography>
      </Grid>

      {_.map(props?.schemeBenefits, (schemeBenefit, index) => {
        return (
          <>
            <Grid item xs={6} sx={{ padding: "6px 14px" }}>
              <Typography sx={{ fontSize: "12px", color: "#333333" }}>
                {schemeBenefit.slabs}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ background: "rgba(248, 209, 73, 0.1)", padding: "6px 0px" }}
            >
              {_.map(schemeBenefit.benefits, (benefit, index) => {
                return (
                  <>
                    {benefit?.toUpperCase()?.includes("VOUCHER") ? (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#333333",
                          padding: "0px 14px",
                          wordBreak: "break-all",
                        }}
                      >
                        <CircleIcon
                          sx={{ fontSize: "4px", margin: "0px 4px 1px 0px" }}
                        />
                        {benefit}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#333333",
                          padding: "0px 14px",
                        }}
                      >
                        <CircleIcon
                          sx={{ fontSize: "4px", margin: "0px 4px 1px 0px" }}
                        />
                        {benefit}
                      </Typography>
                    )}
                  </>
                );
              })}
            </Grid>

            {index === props?.schemeBenefits?.length - 1 ? (
              <></>
            ) : (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </>
        );
      })}
    </Grid>
  );
};

export default SchemeBenefits;
