import React from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Container, Grid, Typography } from "@mui/material";

import SectionTitle from "../Common/SectionTitle";
import ViewAllButton from "../Common/ViewAllButton";
import AnalyticsService from "../../Services/AnalyticsService";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
  },
  mainWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    marginTop: theme.spacing(2),
  },
  loaderContent: {},
  mainContent: {
    backgroundColor: "#F3F3F3",
  },
  loaderWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
  viewAllBrandsButtonWrapperLink: {
    textDecoration: "none",
    width: "100%",
    padding: "0px",
    marginBottom: "20px",
    marginTop: "0px",
  },
  videoCard: {
    width: "47vw",
    "@media screen and (min-width: 600px)": {
      width: "23vw",
    },
    borderRadius: "10px",
  },
  videoSection: {
    background: "#D8D8D8",
  },
  VideoContainer: {
    width: "99%",
    overflowX: "scroll",
    overflowY: "hidden",
    paddingLeft: "7px",
    paddingRight: "30px",
    scrollbarColor: "rgb(216, 216, 216)",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "rgb(216, 216, 216)",
    },
  },
  videoTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    color: "#333333",
    marginTop: "2px",
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  newTag: {
    position: "absolute",
    right: "2px",
    top: "2px",
  },
}));

export default function FeaturedVideos({
  numberOfVideosToShow,
  featuredVideos,
  loading,
  error,
  saveScrollYPosition,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleViewAllClick = () => {
    const featuredVideoClickedData = {
      farmerId: localStorage.getItem("farmerId"),
      page: "/",
      source: localStorage.getItem("source"),
    };

    AnalyticsService.pushEvent(
      `Feature Video View All Clicked`,
      featuredVideoClickedData
    );
    navigate({
      pathname: `/learning-videos`,
    });
  };

  const handleFeaturedVideoClicked = (videoUrl, videoTitle, ranking) => {
    if (saveScrollYPosition) {
      MiscUtils.saveScrollYPosition();
    }
    const featuredVideoClickedData = {
      farmerId: localStorage.getItem(""),
      page: "/",
      videoUrl: videoUrl,
      videoTitle: videoTitle,
      ranking: ranking,
      source: localStorage.getItem("source"),
    };

    AnalyticsService.pushEvent(
      `Featured Video Clicked`,
      featuredVideoClickedData
    );

    let v = videoUrl
      .split("/")
      [videoUrl.split("/").length - 1].replace("watch?v=", "");

    navigate({
      pathname: `/featured-videos`,
      videos: featuredVideos,
      search: `youtubeId=${v}`,
    });
  };

  return (
    <div
      style={{
        overflowY: "hidden",
        marginTop: "20px",
        background: "#D8D8D8",
        overflowX: "hidden",
      }}
    >
      {loading === "done" &&
        (!error || !error?.data) &&
        featuredVideos &&
        featuredVideos.length > 0 && (
          <>
            <Container
              maxWidth={`xl`}
              style={{ marginBottom: "10px" }}
              className={classes.videoSection}
            >
              <SectionTitle title={`Featured Videos`} />
            </Container>
            <div
              style={{ marginTop: "10px" }}
              className={classes.VideoContainer}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                wrap={"nowrap"}
                spacing={2}
                flex
                style={{
                  width: `${featuredVideos.length <= 2 ? `115%` : `143%`}`,
                }}
              >
                {_.map(featuredVideos.slice(0, 8), (video, index) => {
                  return (
                    <Grid item xs={6} key={video.videoCode}>
                      <Box
                        className={classes.videoCard}
                        onClick={() => {
                          handleFeaturedVideoClicked(
                            video?.link,
                            video?.videoTitle,
                            video?.ranking
                          );
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            height: "115px",
                          }}
                        >
                          <video
                            autoPlay="autoplay"
                            loop={true}
                            muted
                            defaultmuted="true"
                            playsInline={true}
                            poster={`https://i.ytimg.com/vi/${video?.link
                              ?.split("/")
                              [
                                video?.link?.split("/").length - 1
                              ].replace("watch?v=", "")}/mqdefault.jpg`}
                            style={{
                              borderRadius: "10px",
                              width: "100%",
                              height: "115px",
                            }}
                          >
                            <source
                              src={`https://static.agrostar.in/videos/previews/${video?.link
                                ?.split("/")
                                [video?.link?.split("/").length - 1].replace(
                                  "watch?v=",
                                  ""
                                )}.MP4.5s.mp4?tr=h-120,so-5,du-5`}
                              type="video/mp4"
                            />
                          </video>
                          <Box
                            sx={{
                              position: "absolute",
                              height: "100%",
                              display: "flex",
                            }}
                          >
                            <img
                              src="/icons/play.svg"
                              style={{ height: "30px", alignSelf: "center" }}
                            />
                          </Box>
                        </Box>

                        <Typography className={classes.videoTitle}>
                          {video?.videoTitle}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </div>

            <Container maxWidth={`xl`} className={classes.videoSection}>
              {featuredVideos &&
                featuredVideos.length > numberOfVideosToShow && (
                  <Box>
                    <Box
                      onClick={() => handleViewAllClick()}
                      className={classes.viewAllBrandsButtonWrapperLink}
                    >
                      <ViewAllButton
                        section={"Featured Videos"}
                        saveScrollYPosition={saveScrollYPosition}
                      />
                    </Box>
                  </Box>
                )}
            </Container>
          </>
        )}
    </div>
  );
}
