import React, { useState, useEffect } from "react";
import BlockUi from "react-block-ui";
import { useNavigate } from "react-router-dom";

import { East } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

import TopBar from "../Common/TopBar";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import BannerService from "../../Services/BannerService";
import { makeStyles } from "@mui/styles";
import EmptyError from "../EmptyError/EmptyError";
import { PromotionService } from "../../Services/PromotionService";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    marginTop: theme.spacing(2),
    minHeight: "275px",
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: "20px",
  },
  saathiPromotingImage: {
    position: "absolute",
    width: "180px",
    top: "42px",
    right: "25px",
    objectFit: "contain",
  },
  farmerDrummingImage: {
    position: "absolute",
    width: "165px",
    top: "42px",
    right: 0,
  },
}));

export default function Promotion() {
  const navigate = useNavigate();
  const district = localStorage.getItem("district");
  const [loading, setLoading] = useState("not_started");
  const [showFestiveTab, setShowFestiveTab] = useState(false);
  const [showOfferCreationTab, setShowOfferCreationTab] = useState(false);

  useEffect(() => {
    AnalyticsService.pushEvent(`Promotion Page Viewed`, { page: "/promotion" });

    setLoading("started");
    const { axiosRequest, abortController } = BannerService.getBannersData(
      `GREETINGS_PAGE`,
      district
    );
    axiosRequest
      .then((greetingResponse) => {
        if (greetingResponse?.data?.responseData) {
          const { banners } = greetingResponse?.data?.responseData;
          setShowFestiveTab(banners?.length ? true : false);
        }

        const { axiosRequest } = BannerService.getBannersData(
          `PROMOTIONS`,
          district
        );
        axiosRequest
          .then((offerCreationResponse) => {
            if (offerCreationResponse?.data?.responseData) {
              const { banners } = offerCreationResponse?.data?.responseData;
              setShowOfferCreationTab(banners?.length ? true : false);
            }

            setLoading("done");
          })
          .catch((e) => {
            setLoading("done");
            console.log("Promotion Banner API Failure: ", e?.message);
          });
      })
      .catch((e) => {
        if (e?.message !== "canceled") {
          setLoading("done");
          console.log("Greetings API Failure: ", e?.message);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleCreateOfferCardClick = () => {
    AnalyticsService.pushEvent(`Create Offer Card Clicked`, {
      page: "/promotion",
    });
    navigate("/create-offer");
  };

  const handleFestiveGreetingCardClick = () => {
    AnalyticsService.pushEvent(`Festive Greetings Card Clicked`, {
      page: "/promotion",
    });
    navigate("/festive-greetings");
  };

  const handleMyOffersClickEvent = () => {
    AnalyticsService.pushEvent(`My Offers Clicked`, {
      page: "/promotion",
    });
    navigate(`/my-offers`);
  };

  return (
    <>
      <ErrorBoundary>
        <TopBar
          title={`Prachaar`}
          backNavigation={true}
          backLink="/dashboard"
          showCart={false}
          showHisaab={false}
          otherTextOrIconToShow={{
            text: "My Offers",
            function: handleMyOffersClickEvent,
          }}
        />
      </ErrorBoundary>

      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "90vh" }}
      >
        {showFestiveTab || showOfferCreationTab ? (
          <div style={{ padding: "16px" }}>
            {showOfferCreationTab && (
              <PromotionCardItem
                text="Create And Promote Offers"
                imagePath="saathiPromoting"
                imageStyle="saathiPromotingImage"
                otherCardStyles={{ background: "#00733E" }}
                handleClick={handleCreateOfferCardClick}
              />
            )}
            {showFestiveTab && (
              <PromotionCardItem
                text="Share Greetings With Farmers"
                imagePath="farmerDrumming"
                imageStyle="farmerDrummingImage"
                otherCardStyles={{ background: "#9D2123" }}
                handleClick={handleFestiveGreetingCardClick}
              />
            )}
          </div>
        ) : loading === "done" ? (
          <EmptyError
            image="EmptyPage.svg"
            text="Ooooops!"
            subText="Looks like there are no templates available at this moment.
                Please come back later."
          />
        ) : (
          ""
        )}
      </BlockUi>
    </>
  );
}

function PromotionCardItem({
  text,
  imagePath,
  imageStyle,
  otherCardStyles,
  handleClick,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={handleClick} sx={otherCardStyles}>
      <CardContent sx={{ p: 3 }}>
        <Typography variant="h6">{text}</Typography>
      </CardContent>
      <CardMedia
        component="img"
        height="225"
        image={`/icons/${imagePath}.svg`}
        alt={imagePath}
        className={classes[imageStyle]}
      />
      <CardActions sx={{ position: "absolute", bottom: 0, p: 3 }}>
        <East />
      </CardActions>
    </Card>
  );
}
