class BusinessDetailsMock {
  static missedSales = {
    status: true,
    message: "",
    responseData: [
      {
        date: 1708497565000,
        saleAmount: 2500,
        products: [
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
        ],
      },
      {
        date: 1708497565000,
        saleAmount: 2500,
        products: [
          {
            skuCode: "AGS-CN-005",
            productImage: "https://static.agrostar.in/static/AGS-CN-005_nl.jpg",
            productName: "Humic Power",
            price: 500,
            orderCount: 6,
          },
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
        ],
      },
      {
        date: 1708497565000,
        saleAmount: 2500,
        products: [
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
        ],
      },
      {
        date: 1708497565000,
        saleAmount: 2500,
        products: [
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
        ],
      },
      {
        date: 1708497565000,
        saleAmount: 2500,
        products: [
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
        ],
      },
      {
        date: 1708497565000,
        saleAmount: 2500,
        products: [
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
        ],
      },
      {
        date: 1708497565000,
        saleAmount: 2500,
        products: [
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
        ],
      },
      {
        date: 1708497565000,
        saleAmount: 2500,
        products: [
          {
            skuCode: "AGS-CN-824",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-824_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
          {
            skuCode: "AGS-CN-831",
            productImage:
              "https://static.agrostar.in/static/AGS-CN-831_N1_l.jpg",
            productName: "अॅग्रोस्टार 0-52-34 (1 किग्रॅ)",
            price: 500,
            orderCount: 3,
          },
        ],
      },
    ],
  };

  static businessDetails = {
    status: true,
    message: "",
    responseData: {
      deliveredData: {
        weekData: {
          fromDuration: 1708281000000,
          toDuration: 1708885800000,
          amount: 500,
          totalOrders: 36,
        },
        monthData: {
          fromDuration: 1706293800000,
          toDuration: 1708885800000,
          amount: 500,
          totalOrders: 130,
        },
      },
      missedData: {
        weekData: {
          fromDuration: 1708281000000,
          toDuration: 1708885800000,
          amount: 400,
          totalOrders: 16,
        },
        monthData: {
          fromDuration: 1706293800000,
          toDuration: 1708885800000,
          amount: 2500,
          totalOrders: 80,
        },
      },
    },
  };
}

export default BusinessDetailsMock;
