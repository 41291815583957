import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { Tab, Tabs, Typography } from "@mui/material";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";

import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";
import FieldAgentExpandMoreDrawer from "./FieldAgentExpandMoreDrawer";

const bottomTabs = [
  {
    name: "Partners",
    activeImageLink: "/icons/activeHomeTab.svg",
    inActiveImageLink: "/icons/inActiveHomeTab.svg",
    navigationLink: "/partners",
    activeClass: {
      marginBottom: "-10.1px",
      marginTop: "-21px",
      height: "40px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "23px",
      marginLeft: "0px",
      paddingTop: "1px",
      display: "none",
    },
  },
  {
    name: "Transfers",
    activeImageLink: "/icons/activeTransferTab.svg",
    inActiveImageLink: "/icons/inActiveTransferTab.svg",
    navigationLink: "/inter-party-transfers",
    activeClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "22px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "22px",
      display: "none",
    },
  },
  {
    name: "More",
    activeClass: {
      marginBottom: "-10px",
      marginTop: "-22px",
      height: "40px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "0px",
      marginTop: "-12px",
      height: "20px",
      display: "none",
    },
  },
  {
    name: "Krishi Gyan",
    activeImageLink: "/icons/krishiGyan.svg",
    inActiveImageLink: "/icons/krishiGyan.svg",
    navigationLink: "/crop-selection",
    activeClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "22px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "22px",
      display: "none",
    },
  },
  {
    name: "FieldStar",
    activeImageLink: "/icons/activeFieldStarTab.svg",
    inActiveImageLink: "/icons/inActiveFieldStarTab.svg",
    navigationLink: "/field-star",
    activeClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "22px",
      display: "none",
    },
    inActiveClass: {
      marginBottom: "-1px",
      marginTop: "-13px",
      height: "22px",
      display: "none",
    },
  },
];

const useStyles = makeStyles((theme) => ({
  alignTabs: {
    height: "57px",
    minWidth: "0px !important",
  },
  alignTab: {
    height: "57px",
  },
  bottomBar: {
    position: "fixed",
    overflow: "hidden",
    bottom: "0",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "600px",
    },
    zIndex: 1060,
  },
  bottomBarWithShadow: {
    position: "fixed",
    overflow: "hidden",
    bottom: "0",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "600px",
    },
    zIndex: 1060,
    boxShadow: "0px -4px 10px rgb(129 129 129 / 30%)",
  },
  bottomActiveClass: {
    display: "block !important",
  },
  bottomInActiveClass: {
    display: "none !important",
  },
  alignTabBottom: {
    minWidth: "0px !important",
    bottom: "13px",
    paddingLeft: "10px",
    background: "transparent",
    backgroundImage: "url(/icons/bottomBarCenterTabCurve.svg)",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderTop: "0px !important",
  },
}));

const FieldAgentBottomBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [tabValue, setTabValue] = useState("/");
  const [bottomNavShow, setBottomNavShow] = useState(true);
  const [bottomTabData, setBottomTabData] = useState(null);

  useEffect(() => {
    handleBottomNav();
  }, [location]);

  const handleBottomNav = () => {
    const tabElementContent = bottomTabs.some(
      (tabElement) =>
        tabElement.navigationLink !== "/crop-selection" &&
        tabElement.navigationLink === window.location.pathname
    );
    setBottomNavShow(tabElementContent);
    setBottomTabData(bottomTabs);
    setShadow(true);

    if (tabElementContent) {
      setTabValue(window.location.pathname);
    }
  };

  return bottomNavShow && localStorage.getItem("otpVerified") ? (
    <div className={shadow ? classes.bottomBarWithShadow : classes.bottomBar}>
      <FieldAgentExpandMoreDrawer open={open} setOpen={setOpen} />

      <Tabs
        value={tabValue}
        className={classes.alignTabs}
        aria-label="bottom tabs"
        variant={`fullWidth`}
        centered
      >
        {bottomTabData?.map((bottomTabValue, bottomTabIndex) =>
          bottomTabValue.name === "More" ? (
            <Tab
              label={
                <Typography
                  variant={"body2"}
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "15px",
                  }}
                >
                  {bottomTabValue.name}
                </Typography>
              }
              className="bottom-tab"
              icon={
                <>
                  <div
                    style={{
                      height: "67px",
                      width: "67px",
                      position: "fixed",
                      marginTop: "-7px",
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "50%",
                      zIndex: "3",
                    }}
                  >
                    <div
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        border: "1px solid transparent",
                        alignSelf: "center",
                        boxShadow: "inset 0px 0px 12px rgba(0, 0, 0, 0.1)",
                        background:
                          "linear-gradient(16.55deg, #853F4F 0%, #E54B25 122.59%)",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "25px",
                        fontWeight: "700",
                        color: "white",
                        zIndex: "5",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                        }}
                      >
                        {open ? (
                          <ExpandMoreRounded
                            sx={{
                              mt: "5px",
                              width: "30px",
                              height: "34px",
                            }}
                          />
                        ) : (
                          <ExpandLessRounded
                            sx={{
                              mt: "5px",
                              width: "30px",
                              height: "34px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              }
              classes={{ root: classes.alignTabBottom }}
              onClick={() => {
                AnalyticsService.pushEvent("Bottom Tab Clicked", {
                  tabName: bottomTabValue.name,
                  page: window.location.pathname,
                  isMoreOpened: !open,
                });
                setOpen(!open);
              }}
              key={bottomTabIndex}
            />
          ) : (
            <Tab
              key={bottomTabIndex}
              label={
                <Typography variant={"string"} fontWeight={500}>
                  {bottomTabValue.name}
                </Typography>
              }
              icon={
                <div>
                  <img
                    style={bottomTabValue.activeClass}
                    src={bottomTabValue.activeImageLink}
                    alt="activeIcon"
                    className={
                      tabValue === bottomTabValue.navigationLink
                        ? classes.bottomActiveClass
                        : classes.bottomInActiveClass
                    }
                  />
                  <img
                    style={bottomTabValue.inActiveClass}
                    src={bottomTabValue.inActiveImageLink}
                    alt="inActiveIcon"
                    className={
                      tabValue !== bottomTabValue.navigationLink
                        ? classes.bottomActiveClass
                        : classes.bottomInActiveClass
                    }
                  />
                </div>
              }
              className="bottom-tab"
              sx={{
                padding: "12px 9px",
                minWidth:
                  bottomTabValue.name === "Krishi Gyan"
                    ? "84px !important"
                    : "auto",
              }}
              classes={{ root: classes.alignTab }}
              value={bottomTabValue.navigationLink}
              onClick={() => {
                AnalyticsService.pushEvent("Bottom Tab Clicked", {
                  tabName: bottomTabValue.name,
                  page: window.location.pathname,
                });

                if (bottomTabValue.name === "FieldStar") {
                  if (AndroidService.checkIfAndroid()) {
                    AndroidService.openWebViewActivity(
                      "https://www.appsheet.com/start/abe9817d-2f93-448f-96a0-cbe3ab693ac2#view=Weekly%20visit%20plan",
                      "FieldStar"
                    );
                  } else {
                    window.open(
                      "https://www.appsheet.com/start/abe9817d-2f93-448f-96a0-cbe3ab693ac2#view=Weekly%20visit%20plan",
                      "_self"
                    );
                  }
                } else {
                  navigate(bottomTabValue.navigationLink);
                  setTabValue(bottomTabValue.navigationLink);
                }
                setOpen(false);
              }}
            />
          )
        )}
      </Tabs>
    </div>
  ) : (
    ""
  );
};

export default FieldAgentBottomBar;
