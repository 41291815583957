import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SectionTitle from "../Common/SectionTitle";
import AmountUtils from "../../Utils/AmountUtils";
import { Info } from "@mui/icons-material";
import CommonConstantValues from "../../Services/CommonConstantValues";

const useStyles = makeStyles((theme) => ({
  temp: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "2.5%",
      width: "94%",
      background:
        "radial-gradient(ellipse at center, #F3F3F3 0%, #F3F3F3 40%, transparent 8%)",
      backgroundSize: "calc(94% / 12) 100%",
      backgroundRepeat: "repeat-x",
      transform: "translate(0, -50%)",
      aspectRatio: 12,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      left: "2.5%",
      width: "94%",
      background:
        "radial-gradient(ellipse at center, #F3F3F3 0%, #F3F3F3 40%, transparent 8%)",
      backgroundSize: "calc(94% / 12) 100%",
      backgroundRepeat: "repeat-x",
      transform: "translate(0, 50%)",
      aspectRatio: 12,
    },
  },
  billSection: {
    marginTop: theme.spacing(3),
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "none",
    fontWeight: 400,
  },
  totalValueText: {
    padding: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
  },
  totalValue: {
    fontSize: "18px",
    fontWeight: 700,
  },
  billSectionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    padding: theme.spacing(0.5),
  },
  discountSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    padding: theme.spacing(0.5),
    color: "#00733E",
  },
}));

export function OrderBillDetailPriceItem({ title, subtitle, value }) {
  return (
    <>
      <Grid
        container
        alignItems={`center`}
        sx={{ margin: "0px 16px", width: "auto" }}
      >
        <Grid item xs={8}>
          <Typography
            variant={`body1`}
            sx={{ fontSize: "16px" }}
            textAlign={`start`}
          >
            {title}
          </Typography>

          {subtitle && (
            <Typography
              variant={`body2`}
              sx={{
                marginTop: "-4px",
                fontSize: "12px",
              }}
              textAlign={`start`}
            >
              {subtitle}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant={`body1`}
            sx={{ fontSize: "16px" }}
            textAlign={`end`}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          borderTop: "1px dashed #00000080",
          margin: "8px 16px",
        }}
      />
    </>
  );
}

export function OrderBillDetailFinalPriceItem({ title, subtitle, value }) {
  return (
    <Grid
      container
      alignItems={`center`}
      sx={{
        backgroundColor: "#DED7BD",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px",
        marginTop: -1,
      }}
    >
      <Grid item xs={8}>
        <Typography
          variant={`body1`}
          sx={{ fontSize: "16px", fontWeight: 700 }}
          textAlign={`start`}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant={`body1`}
          sx={{ fontSize: "16px", fontWeight: 700 }}
          textAlign={`end`}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

function OrderBillDetails({ cart, order }) {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={"xl"} className={classes.billSection}>
        <SectionTitle title={`Bill Details`} />
      </Container>

      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#EAE2C7",
          padding: "8px",
          border: "none",
          fontWeight: 400,
          marginBottom: "16px",
          marginTop: "12px",
          borderRadius: "0px",
        }}
        className={classes.temp}
      >
        <CardContent sx={{ color: "#333333", padding: 0, marginTop: "16px" }}>
          <OrderBillDetailPriceItem
            title={`Sub Total`}
            value={`${AmountUtils.formatCurrency(order?.subTotal)}`}
          />
          {order?.usedPseudoCash > 0 && (
            <OrderBillDetailPriceItem
              title={`AgroStar Points`}
              value={`- ${AmountUtils.formatCurrency(order?.usedPseudoCash)}`}
            />
          )}
          {order?.totalDiscount > 0 && (
            <OrderBillDetailPriceItem
              title={`Discount Amount`}
              value={`- ${AmountUtils.formatCurrency(order?.totalDiscount)}`}
            />
          )}
          <OrderBillDetailFinalPriceItem
            title={`Total Order Amount`}
            value={`${AmountUtils.formatCurrency(order?.totalOrderAmount)}`}
          />
          <Box
            sx={{
              borderTop: "1px dashed #00000080",
              marginBottom: "8px",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          />
          {order?.b2bTcsAmount > 0 && (
            <OrderBillDetailPriceItem
              title={`TCS`}
              value={`${AmountUtils.formatCurrency(order?.b2bTcsAmount)}`}
            />
          )}
          {order?.realCashTransferedToB2BCredit > 0 && (
            <OrderBillDetailPriceItem
              title={`Real Cash Applied`}
              value={`- ${AmountUtils.formatCurrency(
                order?.realCashTransferedToB2BCredit
              )}`}
            />
          )}
          <OrderBillDetailPriceItem
            title={`Total Payable Amount`}
            value={`${AmountUtils.formatCurrency(order?.payableAmount)}`}
          />

          {(order?.totalSavings || order?.cdBenefitAmount) > 0 && (
            <Box
              sx={{
                marginTop: 2.5,
                paddingTop: 1,
                paddingBottom: "8px",
                paddingLeft: "12px",
                paddingRight: "12px",
                backgroundColor: "rgba(0, 115, 62, 0.2)",
                borderRadius: "10px",
                color: "#00733E",
              }}
            >
              {order?.totalSavings > 0 && (
                <Grid container sx={{ marginTop: 0.5, marginBottom: 0.5 }}>
                  <Grid item xs={9}>
                    <Typography
                      variant={`h5`}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        display: "inline-block",
                      }}
                      textAlign={`start`}
                    >
                      Total Saving
                    </Typography>
                    <Typography
                      variant={`caption`}
                      sx={{
                        display: "inline-block",
                      }}
                      textAlign={`start`}
                    >
                      &nbsp;(Discount)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant={`h5`}
                      sx={{ fontSize: "16px", fontWeight: 700 }}
                      textAlign={`end`}
                    >
                      {AmountUtils.formatCurrency(order?.totalSavings)}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {order?.cdBenefitAmount > 0 && (
                <Grid container sx={{ marginTop: 1, marginBottom: 0.5 }}>
                  <Grid item xs={9}>
                    <Typography
                      variant={`h5`}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                      textAlign={`start`}
                    >
                      Total CD Benefit
                    </Typography>
                    <Typography
                      variant={`subtitle2`}
                      textAlign={`start`}
                      sx={{
                        verticalAlign: "start",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      &nbsp;(On Advanced Pay)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant={`h5`}
                      sx={{ fontSize: "16px", fontWeight: 700 }}
                      textAlign={`end`}
                    >
                      {AmountUtils.formatCurrency(order?.cdBenefitAmount)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </CardContent>
      </Card>

      {(order?.freightCharges > 0 && order?.status === CommonConstantValues.DELIVERED)&& (
        <Container sx={{ display: "flex" }}>
          <Info sx={{ width: "30px", height: "30px", opacity: 0.3 }} />
          <Typography
            sx={{
              fontSize: "14px",
              marginLeft: "4px",
              color: "#4F4F4F",
              opacity: 0.8,
            }}
          >
            Freight charges have been reimbursed for this order
          </Typography>
        </Container>
      )}
    </>
  );
}

export default OrderBillDetails;
