import React, { useEffect, useState } from "react";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import _ from "lodash";

import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";

import "./App.css";
import routes from "./routes";
import HisaabService from "./Services/HisaabService";
import BottomBar from "./Components/Common/BottomBar";
import PartnerService from "./Services/PartnerService";
import AndroidService from "./Services/AndroidService";
import ScrollToTop from "./Components/Common/ScrollToTop";
import AnalyticsService from "./Services/AnalyticsService";
import FieldAgentBottomBar from "./Components/Partners/FieldAgentBottomBar";
import firebase from "./firebase";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const theme = createTheme({
  palette: {
    primary: {
      main: "#9D2123",
    },
    secondary: {
      main: "#00733E",
    },
    error: {
      main: "#FF0000",
    },
    background: {
      default: "#fff",
      main: "#F3F3F3",
    },
  },
  typography: {
    fontFamily: [
      "Noto Sans",
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  let location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("farmerId")) {
      if (
        !localStorage.getItem("X-Authorization-Token") ||
        localStorage.getItem("X-Authorization-Token") === "" ||
        localStorage.getItem("X-Authorization-Token") === null ||
        localStorage.getItem("X-Authorization-Token") === undefined ||
        localStorage.getItem("X-Authorization-Token") === "null" ||
        localStorage.getItem("X-Authorization-Token") === "undefined"
      ) {
        console.log("No localstorage auth");
        // localStorage.setItem(`X-Authorization-Token`, config.xAuthToken);
        Sentry.captureMessage(
          `No LocalStorage Auth Token for Logged In User, farmerId: ${localStorage.getItem(
            `farmerId`
          )}`
        );
        PartnerService.logout();
      }
    }
    // localStorage.setItem(`X-Authorization-Token`, config.xAuthToken);
    AndroidService.setPullToRefresh();
  }, []);

  useEffect(() => {
    if (!userId || !localStorage.getItem("userId")) {
      if (localStorage.getItem("farmerId")) {
        const { axiosRequest, abortController } =
          PartnerService.getPartnerDetailedData(
            localStorage.getItem(`farmerId`)
          );
        axiosRequest
          .then((response) => {
            if (response?.data?.responseData?.userId) {
              localStorage.setItem("userId", response.data.responseData.userId);
              setUserId(response?.data?.responseData?.userId);
              localStorage.setItem(
                "mobile",
                response.data.responseData.mobile1
              );
            } else {
              console.log(`no userid found`);
            }
          })
          .catch((error) => {
            if (error?.message !== "canceled") {
              console.log(`error getting farmer details`);
              if (error.data) {
                console.log(error.data);
              } else {
                console.log(error);
              }
            }
          });
        return () => abortController.abort();
      }
    }
  }, [userId]);

  useEffect(() => {
    if (localStorage.getItem(`farmerId`)) {
      const { axiosRequest, abortController } =
        HisaabService.getInstitutionDetails(localStorage.getItem(`farmerId`));
      axiosRequest
        .then((institutionalDetailsResponse) => {
          let institutionalDetailsResponseData =
            institutionalDetailsResponse?.data?.responseData;
          if (institutionalDetailsResponseData?.status) {
            localStorage.setItem(
              "partnerStatus",
              institutionalDetailsResponseData?.status
            );
            window.dispatchEvent(new Event("storage"));

            if (institutionalDetailsResponseData?.status === "INACTIVE") {
              // if an inactive partner tries to access another tab (except hisaab)
              // directly on desktop via URL, redirect him to Hisaab page
              if (
                location.pathname !== "/hisaab" &&
                location.pathname !== "/hisaab/transactions"
              ) {
                window.location.replace("/hisaab");
              }
            }
          }
        })
        .catch(() => {});
      return () => abortController.abort();
    }
  }, []);

  function HandlePrivateRoute() {
    useEffect(() => {
      if (!localStorage.getItem(`loggedInAt`)) {
        PartnerService.setLoggedInTimeStamp();
      } else {
        let autoLogoutFlag = PartnerService.calculateIfAutoLogout();
        if (autoLogoutFlag) {
          AnalyticsService.pushEvent("Field Agent Auto Logged Out");
          PartnerService.logout();
          window.location.reload();
        }
      }
    }, [location]);

    return null;
  }

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <GlobalStyles
            styles={{
              body: {
                backgroundColor: theme.palette.background.main,
              },
            }}
          />
          <ScrollToTop />
          <SentryRoutes>
            {_.map(routes, (route, index) => {
              if (route.private === true) {
                HandlePrivateRoute();
                return (
                  <Route
                    exact
                    key={index}
                    path={route.path}
                    element={
                      localStorage.getItem(`farmerId`) &&
                      localStorage.getItem(`farmerId`) !== undefined &&
                      localStorage.getItem(`otpVerified`) &&
                      localStorage.getItem(`otpVerified`) === "true" &&
                      localStorage.getItem(`X-Authorization-Token`) &&
                      localStorage.getItem(`X-Authorization-Token`) !==
                        undefined &&
                      localStorage.getItem(`X-Authorization-Token`) !==
                        "undefined" ? (
                        route.component
                      ) : (
                        <Navigate to="/" state={{ from: location }} />
                      )
                    }
                  />
                );
              }

              return (
                <Route
                  exact
                  key={index}
                  path={route.path}
                  element={route.component}
                />
              );
            })}
          </SentryRoutes>
          <BottomBar />
          {/* Field Agent Bottom Tabs */}
          <FieldAgentBottomBar />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default Sentry.withProfiler(App);
