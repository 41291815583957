import { Button, Grid, Typography } from "@mui/material";

function UpcomingPaymentsReminder(props) {
  function getDueInDays(dueDate) {
    const currentDate = Date.now();
    const diffInMs = Math.abs(dueDate - currentDate);
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  }

  function getDueMessage() {
    if (props?.totalOCPAmount) {
      let message = " is overdue";
      if (props?.overdueDays !== null) {
        message = message + " since " + props.overdueDays;
        if (props.overdueDays === 1) {
          message = message + " day";
        } else {
          message = message + " days";
        }
      }
      return message;
    }
    const dueDateEpoch = props?.upcomingWCPTransactionData?.date;
    const dueDays = getDueInDays(dueDateEpoch);
    if (dueDays === 0) {
      return " is due today";
    } else {
      let message = " is due in " + dueDays + " ";
      if (dueDays === 1) message = message + " day";
      else message = message + " days";
      return message;
    }
  }

  function getBackgroundColor() {
    if (
      props?.totalOCPAmount > 0 ||
      getDueInDays(props?.upcomingWCPTransactionData?.date) < 4
    ) {
      return "#F5E9E9";
    } else {
      return "white";
    }
  }

  function getAmountToPay() {
    if (props?.totalOCPAmount) {
      return props?.totalOCPAmount;
    } else {
      return props?.upcomingWCPTransactionData?.amount;
    }
  }

  return (
    <Grid
      container
      alignItems={"center"}
      sx={{
        minWidth: "300px",
        backgroundColor: `${getBackgroundColor()}`,
        padding: "12px 10px",
        borderRadius: "16px",
        margin: "16px 16px 0px 16px !important",
        border: "1px solid white",
        boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
      }}
      columns={16}
    >
      <Grid item xs={2}>
        <img src="/icons/rupee.svg" alt="rupee" />
      </Grid>
      <Grid item xs={8}>
        <Typography
          sx={{
            color: "#9D2123",
            fontWeight: "700",
            fontSize: "12px",
            fontStyle: "normal",
          }}
        >
          {"Payment of ₹" +
            new Intl.NumberFormat("en-IN").format(getAmountToPay())}
        </Typography>
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "12px",
            fontStyle: "normal",
          }}
        >
          {getDueMessage()}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{ borderRadius: "16px", textTransform: "capitalize" }}
          onClick={() => {
            props?.handleClickPayNow(getAmountToPay());
          }}
        >
          Pay Now
        </Button>
      </Grid>
    </Grid>
  );
}

export default UpcomingPaymentsReminder;
