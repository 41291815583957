import messages from "../content/Messages";
import Languages from "../Services/LanguageCode";

export default function useMessages() {
  const language = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "en";
  const getMessage = (key) => {
    if (key) {
      if (messages[key][language]) {
        return messages[key][language];
      }
    }
  };
  return { getMessage };
}
