import { Box, Card, Grid, Slider, Tooltip, Typography } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AnalyticsService from "../../Services/AnalyticsService";
import { useEffect, useState } from "react";
import _ from "lodash";
import format from "date-fns/format";
import CurrentProgressDescription from "./CurrentProgressDescription";
import { useNavigate } from "react-router-dom";
import SchemesService from "../../Services/SchemesService";
import ProductService from "../../Services/ProductService";
import NonFeaturedSchemesPrompt from "./NonFeaturedSchemesPrompt";
import TodProgressBarLegend from "./TodProgressBarLegend";

const SCHEME_CARD_HORIZONTAL_PADDING = 24;

const SchemeCard = (props) => {
  const [sliderMarks, setSliderMarks] = useState(null);
  const [overlaySliderMarks, setOverlaySliderMarks] = useState(null);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [overlayProgressBarValue, setOverlayProgressBarValue] = useState(0);
  const [nextSlabDetails, setNextSlabDetails] = useState(null);
  const [nextTargetEntitlement, setNextTargetEntitlement] = useState(null);
  const [openedTooltipIndex, setOpenedTooltipIndex] = useState(null);
  const [schemeConstraintType, setSchemeConstraintType] = useState(null);
  const [shopNowUrl, setShopNowUrl] = useState(null);
  const [lastSlabAchieved, setLastSlabAchieved] = useState(null);
  const [deltaPromptQuantity, setDeltaPromptQuantity] = useState(null);
  const [deltaPromptQuantityForFinalSlab, setDeltaPromptQuantityForFinalSlab] =
    useState(null);

  let schemeId =
    props?.turnoverScheme?.name +
    "-" +
    props?.turnoverScheme?.promotionUUID?.slice(0, 3);
  schemeId = _.replace(schemeId, "%", "");
  schemeId = _.replace(schemeId, ".", "");
  schemeId = _.trim(schemeId);

  let navigate = useNavigate();

  const handleTooltipClick = (index) => {
    AnalyticsService.pushEvent(`Scheme Milestone Clicked`, {
      page: props?.page,
      schemeName: props?.turnoverScheme?.name,
      schemeId: schemeId,
      schemeAchievement:
        props?.turnoverScheme?.netValue >= 0
          ? slabConstraintUnit === `₹`
            ? `${slabConstraintUnit} ${new Intl.NumberFormat("en-IN").format(
                props?.turnoverScheme?.netValue
              )}`
            : `${props?.turnoverScheme?.netValue} ${slabConstraintUnit}`
          : ``,
      milestoneName: generateEntitlementDescription(
        props?.turnoverScheme?.turnoverOfferRule?.[index - 1]?.entitlements
          ?.entitlementList
      ),
    });
    if (index !== 0) {
      setOpenedTooltipIndex(index);
    }
    if (props?.turnoverScheme?.deltaPrompt && props?.showProjectedProgress) {
      let qtyViaMilestone = _.find(props?.turnoverScheme?.deltaPrompt, {
        slabIndex: index,
      });
      if (props?.setQuantityViaMilestoneClick) {
        props?.setQuantityViaMilestoneClick(true);
      }
      if (props?.handleChange) {
        props?.handleChange(
          null,
          "milestoneClick",
          qtyViaMilestone?.deltaQuantity
        );
      }
      if (props?.setMilestoneClickIndex) {
        props?.setMilestoneClickIndex(index);
      }
    }
  };

  useEffect(() => {
    let nextSlabIndex = null;
    for (let i = 0; i < sliderMarks?.length; i++) {
      if (props?.turnoverScheme?.SettledValue < sliderMarks?.[i]?.slabTarget) {
        nextSlabIndex = i;
        break;
      }
    }
    if (!nextSlabIndex) {
      nextSlabIndex = sliderMarks?.length - 1;
    }

    if (props?.quantityViaMilestoneClick) {
      setOpenedTooltipIndex(props?.milestoneClickIndex);
    } else {
      setOpenedTooltipIndex(nextSlabIndex);
    }
  }, [sliderMarks]);

  const slabConstraintUnit =
    SchemesService.SLAB_CONSTRAINT_UNIT_MAP[
      props?.turnoverScheme?.constraintsStatus?.[0]?.slabConstraintsStatus?.[0]
        ?.constraintKey
    ];

  const finalSlabDetails = _.last(props?.turnoverScheme?.turnoverOfferRule);
  const finalSlabTarget =
    finalSlabDetails?.constraints?.constraintList?.[0]?.params?.targetValue;

  const generateEntitlementDescription = (entitlements) => {
    let entitlementsList = [];
    let entitlementsString = ``;
    _.forEach(entitlements, (entitlement) => {
      if (entitlement?.type === `FREE_VOUCHER`) {
        _.forEach(entitlement?.params?.freeVouchers, (voucherDetails) => {
          entitlementsList.push(
            `${voucherDetails?.voucherCode} ${
              SchemesService.ENTITLEMENT_MAP[entitlement?.type]
            }`
          );
        });
      } else {
        if (
          entitlement?.type === `CREDIT_MEMO` ||
          entitlement?.type === `CREDIT_MEMO_PER_QUANTITY`
        ) {
          entitlementsList.push(
            `${
              entitlement?.params?.value >= 10000000
                ? `₹${ProductService.roundFloatsToNdecimalPlaces(
                    entitlement?.params?.value / 10000000,
                    2
                  )} Cr`
                : entitlement?.params?.value >= 100000
                  ? `₹${ProductService.roundFloatsToNdecimalPlaces(
                      entitlement?.params?.value / 100000,
                      2
                    )} lacs`
                  : `₹${new Intl.NumberFormat("en-IN").format(
                      entitlement?.params?.value
                    )}`
            }  ${SchemesService.ENTITLEMENT_MAP[entitlement?.type]}`
          );
        } else {
          entitlementsList.push(
            `${entitlement?.params?.value} ${
              SchemesService.ENTITLEMENT_MAP[entitlement?.type]
            }`
          );
        }
      }
    });
    entitlementsString = _.join(entitlementsList, ", ");
    return entitlementsString;
  };

  const generateSliderMarks = (
    slabsData,
    valueToPlotOnProgressBar,
    isOverlayProgressBar
  ) => {
    let generatedSliderMarks = [
      {
        label: isOverlayProgressBar ? (
          ``
        ) : slabConstraintUnit === `₹` ? (
          `${slabConstraintUnit} 0`
        ) : (
          <span>
            0 {slabsData?.length > 5 ? <br /> : ""}
            {slabConstraintUnit}
          </span>
        ),
        value: 0,
        slabIndex: 0,
        slabTarget: 0,
        nextSlabTarget:
          slabsData?.[0]?.constraints?.constraintList?.[0]?.params?.targetValue,
      },
    ];

    _.forEach(slabsData, (slab, index) => {
      let sliderMarkItem = {};
      const targetValue =
        slab?.constraints?.constraintList?.[0]?.params?.targetValue;
      sliderMarkItem.label = isOverlayProgressBar ? (
        ``
      ) : slabConstraintUnit === `₹` ? (
        <span>
          {slabConstraintUnit}{" "}
          {targetValue >= 10000000 ? (
            <>
              {ProductService.roundFloatsToNdecimalPlaces(
                targetValue / 10000000,
                2
              )}
              {slabsData?.length > 5 ? <br /> : " "}
              Cr
            </>
          ) : targetValue >= 100000 ? (
            <>
              {ProductService.roundFloatsToNdecimalPlaces(
                targetValue / 100000,
                2
              )}
              {slabsData?.length > 5 ? <br /> : " "}
              lacs
            </>
          ) : (
            new Intl.NumberFormat("en-IN").format(targetValue)
          )}
        </span>
      ) : props?.turnoverScheme?.turnoverOfferRule?.length > 5 ? (
        <span>
          {targetValue}
          <br />
          {slabConstraintUnit}
        </span>
      ) : (
        `${targetValue} ${slabConstraintUnit}`
      );
      sliderMarkItem.value = (index + 1) * (100 / slabsData?.length);
      sliderMarkItem.slabIndex = index + 1;
      sliderMarkItem.slabTarget = targetValue;
      sliderMarkItem.nextSlabTarget = slabsData?.[index + 1]
        ? slabsData?.[index + 1]?.constraints?.constraintList?.[0]?.params
            ?.targetValue
        : finalSlabTarget;
      generatedSliderMarks.push(sliderMarkItem);
    });

    var valueToPlotSlabIndex = -1;
    _.forEach(generatedSliderMarks, (sliderMark, index) => {
      if (
        sliderMark.slabTarget > valueToPlotOnProgressBar &&
        valueToPlotSlabIndex === -1
      ) {
        valueToPlotSlabIndex = sliderMark.slabIndex - 1;
      }
    });

    if (valueToPlotSlabIndex === -1) {
      valueToPlotSlabIndex = generatedSliderMarks?.length - 1;
    }

    let lastSlabValueOnProgressBar =
      generatedSliderMarks[valueToPlotSlabIndex].value;

    let netValueExceedingLastSlabTarget =
      valueToPlotOnProgressBar -
      generatedSliderMarks[valueToPlotSlabIndex].slabTarget;

    let slabWindowSizeActual =
      generatedSliderMarks[valueToPlotSlabIndex].nextSlabTarget -
      generatedSliderMarks[valueToPlotSlabIndex].slabTarget;

    let slabWindowSizeOnProgressBar = 100 / slabsData?.length;

    let slabWindowSizeRatio =
      slabWindowSizeActual / slabWindowSizeOnProgressBar;

    let progBarValue =
      lastSlabValueOnProgressBar +
      netValueExceedingLastSlabTarget / slabWindowSizeRatio;

    if (slabWindowSizeRatio === 0) {
      progBarValue = 100;
    }

    if (isOverlayProgressBar) {
      setOverlayProgressBarValue(progBarValue);
    } else {
      setProgressBarValue(progBarValue);
    }
    return generatedSliderMarks;
  };

  useEffect(() => {
    let deltaPromptQuantityFromResponse = props?.isFeaturedSchemes
      ? props?.turnoverScheme?.deltaPrompt?.[0]?.deltaMatrixValue
      : props?.turnoverScheme?.deltaPrompt?.[0]?.deltaQuantity;
    setDeltaPromptQuantity(deltaPromptQuantityFromResponse);
    setDeltaPromptQuantityForFinalSlab(
      props?.turnoverScheme?.deltaPrompt?.slice(-1)?.[0]?.deltaQuantity
    );

    let nextSlabData = _.find(
      props.turnoverScheme.turnoverOfferRule,
      (slabDetails) => {
        return (
          slabDetails.constraints?.constraintList?.[0]?.params?.targetValue >
          props.turnoverScheme.netValue
        );
      }
    );

    if (!nextSlabData) {
      // if current slab is the last slab, treat the current slab itself as next slab
      nextSlabData = props?.turnoverScheme?.turnoverOfferRule?.slice(-1)?.[0];
    }

    setNextSlabDetails(nextSlabData);
    setNextTargetEntitlement(nextSlabData?.entitlements?.entitlementList);

    let lastSlabAchievedIndex = _.findIndex(
      props.turnoverScheme.turnoverOfferRule,
      (slabDetails) => {
        return (
          props.turnoverScheme.netValue <
          slabDetails.constraints?.constraintList?.[0]?.params?.targetValue
        );
      }
    );
    let lastSlabAchievedData = null;
    if (lastSlabAchievedIndex === -1) {
      lastSlabAchievedData =
        props?.turnoverScheme?.turnoverOfferRule?.slice(-1)?.[0];
    } else if (lastSlabAchievedIndex > 0) {
      lastSlabAchievedData =
        props?.turnoverScheme?.turnoverOfferRule?.[lastSlabAchievedIndex - 1];
    }
    setLastSlabAchieved(lastSlabAchievedData);
    let sliderMarksFromSlabsData = generateSliderMarks(
      props?.turnoverScheme?.turnoverOfferRule,
      props.turnoverScheme.SettledValue,
      false
    );
    setSliderMarks(sliderMarksFromSlabsData);

    let overlaySliderMarksFromSlabsData = generateSliderMarks(
      props?.turnoverScheme?.turnoverOfferRule,
      props?.turnoverScheme?.netValue,
      true
    );
    setOverlaySliderMarks(overlaySliderMarksFromSlabsData);

    let schemeConstraintTypeDerived =
      props?.turnoverScheme?.turnoverOfferRule?.[0]?.constraints
        ?.constraintList?.[0]?.type;
    setSchemeConstraintType(schemeConstraintTypeDerived);
  }, []);

  useEffect(() => {
    if (
      props?.turnoverScheme?.promotionUUID &&
      schemeConstraintType === `PRODUCT_COHORT`
    ) {
      const { axiosRequest, abortController } =
        SchemesService.getProductCohorts(props?.turnoverScheme?.promotionUUID);
      axiosRequest
        .then((response) => {
          let productCohortsFromResponse =
            response?.data?.responseData?.productCohorts?.productDetails;
          let applicableSkus = [];

          _.forEach(productCohortsFromResponse, (productCohort, index) => {
            applicableSkus.push(productCohort?.skuCode);
          });

          if (applicableSkus?.length > 0) {
            setShopNowUrl(`/scheme-products/${applicableSkus?.join(",")}`);
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting product cohorts`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
          }
        });
      return () => abortController.abort();
    } else if (schemeConstraintType === `PRODUCT`) {
      let applicableSkus = [];
      _.forEach(
        props?.turnoverScheme?.turnoverOfferRule?.[0]?.constraints
          ?.constraintList?.[0]?.params?.itemQuanList,
        (itemQuan) => {
          applicableSkus.push(itemQuan?.skuCode);
        }
      );
      // if only 1 sku, redirect user to PDP. If multiple skus, redirect to PLP
      if (applicableSkus?.length === 1) {
        setShopNowUrl(`/products/${applicableSkus?.[0]}`);
      } else if (applicableSkus?.length > 1) {
        setShopNowUrl(`/scheme-products/${applicableSkus?.join(",")}`);
      }
    } else if (schemeConstraintType === `CATEGORY`) {
      let categoryName =
        props?.turnoverScheme?.turnoverOfferRule?.[0]?.constraints
          ?.constraintList?.[0]?.params?.itemQuanList?.[0]?.categoryName;
      let allowedCategories = [
        "Seeds",
        "Crop Protection",
        "Crop Nutrition",
        "Hardware",
      ];
      if (allowedCategories.includes(categoryName)) {
        setShopNowUrl(`/categories/${categoryName}`);
      }
    } else if (schemeConstraintType === `ORDER_VALUE`) {
      setShopNowUrl(`/nearby-partner-purchase-products`);
    }
  }, [props?.turnoverScheme, schemeConstraintType]);

  // const nextEntitlementTooltipPosition =
  //   ((window.innerWidth -
  //     (2 * props?.SCHEMES_CONTAINER_HORIZONTAL_PADDING +
  //       2 * SCHEME_CARD_HORIZONTAL_PADDING)) /
  //     100) *
  //   overlayProgressBarValue;

  // const nextEntitlementTooltipText = generateEntitlementDescription(
  //   nextTargetEntitlement
  // );

  const handleClickOpenScheme = () => {
    if (props?.clickRedirectTo === "SCHEME_DETAILS_PAGE") {
      navigate(`/vyapaar/${schemeId}`);
    } else if (props?.clickRedirectTo === "SCHEME_DETAILS_DRAWER") {
      props?.setOpenSchemeDetailsDrawer(true);
      AnalyticsService.pushEvent(`Drawer Opened`, {
        type: "Scheme Details",
      });
    }
  };

  const sliderTooltipValue = (sliderProgressType) => {
    const sliderValue =
      sliderProgressType === "NET_VALUE"
        ? props?.turnoverScheme?.netValue
        : props?.turnoverScheme?.SettledValue;

    if (slabConstraintUnit === `₹`) {
      return `${slabConstraintUnit} ${
        sliderValue >= 10000000
          ? `${ProductService.roundFloatsToNdecimalPlaces(
              sliderValue / 10000000,
              2
            )} Cr`
          : sliderValue >= 100000
            ? `${ProductService.roundFloatsToNdecimalPlaces(
                sliderValue / 100000,
                2
              )} lacs`
            : `${new Intl.NumberFormat("en-IN").format(sliderValue)}`
      } `;
    } else {
      return `${sliderValue} ${slabConstraintUnit}`;
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          marginBottom: "12px",
          borderRadius: "16px",
          border: "none",
          boxShadow: "0px 4px 10px rgba(28, 28, 28, 0.1)",
        }}
      >
        <Box
          sx={{ padding: "16px 16px 8px 16px" }}
          onClick={() => {
            if (props?.cardClickable) {
              AnalyticsService.pushEvent(`Scheme Card Clicked`, {
                schemeName: props?.turnoverScheme?.name,
                schemeId: schemeId,
                page: props?.page,
                schemeAchievement:
                  props?.turnoverScheme?.netValue >= 0
                    ? slabConstraintUnit === `₹`
                      ? `${slabConstraintUnit} ${new Intl.NumberFormat(
                          "en-IN"
                        ).format(props?.turnoverScheme?.netValue)}`
                      : `${props?.turnoverScheme?.netValue} ${slabConstraintUnit}`
                    : ``,
              });
              handleClickOpenScheme();
            }
          }}
        >
          <Grid container alignItems={`center`}>
            <Grid item xs={props?.cardClickable ? 11 : 12}>
              <Typography
                variant={`body2`}
                sx={{
                  color: "#4F4F4F",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "16px",
                }}
              >
                {props?.turnoverScheme?.name ? props.turnoverScheme.name : ``}
              </Typography>
              <Typography
                color={`secondary`}
                sx={{
                  marginTop: "5px",
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                {`Highest Benefit - `}
                <b>{`${generateEntitlementDescription(
                  finalSlabDetails?.entitlements?.entitlementList
                )}`}</b>
              </Typography>
            </Grid>

            {props?.cardClickable && (
              <Grid item xs={1} sx={{ textAlign: "right" }}>
                <ArrowForwardIosRoundedIcon
                  sx={{ color: "#CACACA", fontSize: "22px", marginTop: "4px" }}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "16px", padding: `8px 0px` }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2px",
              padding: `0px ${SCHEME_CARD_HORIZONTAL_PADDING - 6}px`,
            }}
            onClick={() => {
              if (props?.cardClickable) {
                handleClickOpenScheme();
              }
            }}
          >
            <Typography
              sx={{ fontSize: "10px", fontWeight: 400 }}
            >{`Starts on ${format(
              props?.turnoverScheme?.validFrom,
              "do MMM"
            )}`}</Typography>
            <Typography
              sx={{ fontSize: "10px", fontWeight: 400 }}
            >{`Ends on ${format(
              props?.turnoverScheme?.validTo,
              "do MMM"
            )}`}</Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              padding: `4px ${SCHEME_CARD_HORIZONTAL_PADDING}px`,
              paddingBottom:
                props.turnoverScheme.turnoverOfferRule?.length > 5
                  ? "42px"
                  : "28px",
            }}
          >
            <Slider
              disabled={true} // disabled slider so that slider thumb is not draggable
              aria-label="Custom marks"
              value={progressBarValue}
              valueLabelDisplay="on"
              valueLabelFormat={() => sliderTooltipValue("SETTLED_VALUE")}
              marks={sliderMarks}
              sx={{
                gridArea: "1/1",
                height: "15px",
                borderRadius: "15px",
                marginBottom: "5px",
                "& .MuiSlider-markLabel": {
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "#4F4F4F",
                  wordSpacing: "-1px",
                },
                "& .MuiSlider-rail": { background: "#E5E5E5", opacity: 1 },
                "& .MuiSlider-track": {
                  background: "#00733E",
                  border: "1px solid #FFFFFF",
                  zIndex: 1,
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: "-6px",
                    right: 0,
                    bottom: 0,
                    width: "102%",
                    background: "#00733E",
                    border: "inherit",
                    zIndex: -1,
                    borderRadius: "16px",
                  },
                },
                "& .MuiSlider-valueLabel": {
                  backgroundColor: "#00733E",
                  borderRadius: "6px",
                  padding: "1px 5px",
                  fontSize: "10px",
                  fontWeight: 400,
                  lineHeight: "14px",
                  wordSpacing: "-1px",
                  marginTop: "10px",
                },
                "& .MuiSlider-valueLabel:before": {
                  height: "5px",
                  width: "5px",
                },
                "& .MuiSlider-thumb": {
                  height: `25px`,
                  width: `25px`,
                  backgroundImage: `url("/icons/sliderTip.svg")`,
                  backgroundColor: "transparent",
                  "&::before": {
                    boxShadow: "none",
                  },
                  zIndex: 2,
                },
                "& .MuiSlider-mark": {
                  height: "14px",
                  width: "14px",
                  backgroundColor: "#B8BABB",
                  borderRadius: "50%",
                  display: "inline-block",
                  border: "1px solid #FFFFFF",
                  marginLeft: "-7px",
                  zIndex: 1,
                },
                "& .MuiSlider-markActive": {
                  background:
                    "linear-gradient(270deg, #00733E 0%, #FFDC42 100%)",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  zIndex: 1,
                },
              }}
            />

            {props?.showProjectedProgress && (
              <Slider
                disabled={true}
                aria-label="Custom marks"
                value={overlayProgressBarValue}
                // valueLabelDisplay="off"
                valueLabelDisplay="on"
                valueLabelFormat={() => sliderTooltipValue("NET_VALUE")}
                marks={overlaySliderMarks}
                sx={{
                  gridArea: "1/1",
                  height: "15px",
                  borderRadius: "15px",
                  marginBottom: "5px",
                  "& .MuiSlider-rail": { background: "#E5E5E5", opacity: 1 },
                  "& .MuiSlider-track": {
                    // background: `linear-gradient(270deg, #00733E 0%, #FFDC42 100%)`,
                    background: "#FFDC42",
                    border: "1px solid #FFFFFF",
                    // opacity: "0.2",
                  },
                  "& .MuiSlider-valueLabel": {
                    backgroundColor: "#FFDC42",
                    borderRadius: "6px",
                    padding: "1px 5px",
                    fontSize: "10px",
                    fontWeight: 400,
                    lineHeight: "14px",
                    wordSpacing: "-1px",
                    color: "black",
                    marginTop: "-3px",
                  },
                  "& .MuiSlider-valueLabel:before": {
                    height: "5px",
                    width: "5px",
                  },
                  "& .MuiSlider-thumb": {
                    height: `0px`,
                    width: `0px`,
                    // backgroundImage: `url("/icons/locationBlack.svg")`,
                    // backgroundColor: "transparent",
                    // "&::before": {
                    //   boxShadow: "none",
                    // },
                    // zIndex: 3,
                    // marginLeft: "0px",
                  },
                  "& .MuiSlider-mark": { height: "0px" },
                }}
              />
            )}

            <Slider
              disabled={true}
              aria-label="Custom marks"
              value={100}
              valueLabelDisplay="off"
              sx={{
                gridArea: "1/1",
                height: "15px",
                borderRadius: "15px",
                marginBottom: "5px",
                "& .MuiSlider-rail": { background: "transparent" },
                "& .MuiSlider-track": {
                  background: `transparent`,
                  border: "none",
                  zIndex: -1,
                },
                "& .MuiSlider-valueLabel": {
                  backgroundColor: "#00733E",
                  borderRadius: "6px",
                  padding: "1px 5px",
                  fontSize: "10px",
                  fontWeight: 400,
                  lineHeight: "14px",
                  wordSpacing: "-1px",
                },
                "& .MuiSlider-valueLabel:before": {
                  height: "5px",
                  width: "5px",
                },
                "& .MuiSlider-thumb": {
                  height: `25px`,
                  width: `25px`,
                  backgroundImage: `url("/icons/${
                    props?.turnoverScheme?.SettledValue < finalSlabTarget
                      ? `sliderEndEmpty.svg`
                      : `sliderEndFilled.svg`
                  }")`,
                  backgroundColor: "transparent",
                  zIndex: 2,
                  "&::before": {
                    boxShadow: "none",
                  },
                },
                "& .MuiSlider-mark": { height: "0px" },
              }}
            />

            <Box
              sx={{
                gridArea: "1/1",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "18px",
                position: "relative",
                zIndex: 1,
              }}
            >
              {_.map(sliderMarks, (sliderMark, index) => (
                <Tooltip
                  key={index}
                  arrow
                  placement="bottom"
                  PopperProps={{
                    disablePortal: true,
                    style: {
                      zIndex: 0,
                    },
                  }}
                  open={index === openedTooltipIndex ? true : false}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={generateEntitlementDescription(
                    props?.turnoverScheme?.turnoverOfferRule?.[index - 1]
                      ?.entitlements?.entitlementList
                  )}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        display: `${
                          index === openedTooltipIndex ? `` : `none`
                        }`,
                        "& .MuiTooltip-arrow": {},
                        marginTop:
                          props.turnoverScheme.turnoverOfferRule?.length > 5
                            ? null
                            : "0px !important",
                        borderRadius: "10px",
                        fontSize: "10px",
                        fontWeight: "400",
                        // lineHeight: "14px",
                        padding: "4px 6px",
                        wordBreak: "break-all",
                      },
                    },
                  }}
                >
                  <Box
                    onClick={() => {
                      handleTooltipClick(index);
                    }}
                    sx={{
                      color: "transparent",
                      fontSize: "18px",
                      lineHeight: "18px",
                    }}
                  >
                    O
                  </Box>
                </Tooltip>
              ))}
            </Box>
          </Box>

          <TodProgressBarLegend />

          {SchemesService.getHardcodedSchemeMessage(
            props.turnoverScheme.promotionUUID
          ) && (
            <Box
              sx={{
                fontSize: "11px",
                margin: "4px 8px",
                padding: "3px 5px",
                background: "#f7d86c",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              {SchemesService.getHardcodedSchemeMessage(
                props.turnoverScheme.promotionUUID
              )}
            </Box>
          )}

          {/* Temporarily commented out as prompt message requires backend change to consider settlement logic (instead of purchase logic) */}
          {/* {props?.isFeaturedSchemes ? (
            <CurrentProgressDescription
              reverseDescriptionFormat={false}
              schemeId={schemeId}
              turnoverScheme={props?.turnoverScheme}
              finalSlabTarget={finalSlabTarget}
              slabConstraintUnit={slabConstraintUnit}
              nextTargetEntitlement={nextTargetEntitlement}
              nextSlabDetails={nextSlabDetails}
              generateEntitlementDescription={generateEntitlementDescription}
              shopNowUrl={shopNowUrl}
              schemeConstraintType={schemeConstraintType}
              page={props?.page}
              nudgeClickable={props?.nudgeClickable}
              lastSlabAchieved={lastSlabAchieved}
              deltaPromptQuantity={deltaPromptQuantity}
              isFeaturedSchemes={props?.isFeaturedSchemes}
            />
          ) : (
            <NonFeaturedSchemesPrompt
              reverseDescriptionFormat={false}
              schemeId={schemeId}
              turnoverScheme={props?.turnoverScheme}
              finalSlabTarget={finalSlabTarget}
              slabConstraintUnit={slabConstraintUnit}
              generateEntitlementDescription={generateEntitlementDescription}
              shopNowUrl={shopNowUrl}
              page={props?.page}
              nudgeClickable={props?.nudgeClickable}
              lastSlabAchieved={lastSlabAchieved}
              deltaPromptQuantityForFinalSlab={deltaPromptQuantityForFinalSlab}
              isFeaturedSchemes={props?.isFeaturedSchemes}
              finalSlabDetails={finalSlabDetails}
            />
          )} */}
        </Box>
      </Card>
    </>
  );
};

export default SchemeCard;
