import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";

import { Skeleton } from "@mui/material";

import OrderService from "../../Services/OrderService";
import PaymentService from "../../Services/PaymentService";
import SettlePaymentNudge from "../Hisaab/SettlePaymentNudge";
import OrderConfirmationNudge from "../Cart/OrderConfirmationNudge";
import OnboardingStatusNudge from "../OnboardPartner/OnboardingStatusNudge";
import AnalyticsService from "../../Services/AnalyticsService";

export default function NudgeCarousel({
  institutionalDetails,
  setLoadingAgreementSigningWindow,
}) {
  const [nudges, setNudges] = useState([]);
  const [loading, setLoading] = useState("not-started");
  const isFieldAgent = localStorage.getItem("role") === "fieldAgent";

  useEffect(() => {
    if (
      institutionalDetails?.status === "UNVERIFIED" &&
      institutionalDetails?.subStatus
    ) {
      setNudges([
        {
          data: null,
          priority: 0,
          name: "Onboarding Status Nudge",
          Component: OnboardingStatusNudge,
        },
      ]);
    }
  }, [institutionalDetails]);

  useEffect(() => {
    setLoading("started");

    const promises = [];
    const { axiosRequest, abortController } =
      PaymentService.getVANPaymentDetails();
    promises.push(axiosRequest);

    let ordersRequest, ordersAbortController;
    if (!isFieldAgent) {
      const getAllOrders = OrderService.getAllOrders(true, 0, 20, true);
      ordersRequest = getAllOrders.axiosRequest;
      ordersAbortController = getAllOrders.abortController;
      promises.push(ordersRequest);
    }

    Promise.allSettled(promises)
      .then(([vanPayment, orders]) => {
        if (
          vanPayment?.status === "fulfilled" &&
          vanPayment?.value?.data?.responseData
        ) {
          vanPayment = vanPayment?.value?.data?.responseData;
          if (vanPayment?.amount > 0) {
            AnalyticsService.pushEvent("Nudge Viewed", {
              type: "Settle VAN Payment",
              amount: vanPayment?.amount,
              enableCustomSettlement: vanPayment?.enableCustomSettlement,
            });
            setNudges((prevNudges) => [
              ...prevNudges,
              {
                priority: 2,
                data: vanPayment,
                name: "Settle Payment Nudge",
                Component: SettlePaymentNudge,
              },
            ]);
          }
        }

        if (
          !isFieldAgent &&
          orders?.status === "fulfilled" &&
          orders?.value?.data?.responseData
        ) {
          orders = orders?.value?.data?.responseData?.orders;
          if (orders) {
            setNudges((prevNudges) => [
              ...prevNudges,
              {
                priority: 1,
                data: orders,
                name: "Order Confirmation Nudge",
                Component: OrderConfirmationNudge,
              },
            ]);
          }
        }

        if (
          vanPayment?.status !== "rejected" ||
          orders?.status !== "rejected"
        ) {
          setLoading("done");
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
        }
      });

    return () => {
      abortController.abort();
      ordersAbortController && ordersAbortController.abort();
    };
  }, [isFieldAgent]);

  return loading === "started" ? (
    <Skeleton variant={`rectangular`} height={50} />
  ) : (
    loading === "done" && nudges.length > 0 && (
      <>
        {nudges.length === 1 ? (
          nudges?.map(({ Component, data }, index) => (
            <Component
              key={index}
              data={data}
              setLoadingAgreementSigningWindow={
                setLoadingAgreementSigningWindow
              }
            />
          ))
        ) : (
          <Carousel
            animation={`fade`}
            autoPlay={false}
            swipe={true}
            fullHeightHover={true}
            navButtonsAlwaysInvisible={true}
            height={50}
            onChange={(currentIndex, previousIndex) => {
              AnalyticsService.pushEvent("Carousel Swiped", {
                currentIndex,
                previousIndex,
                page: "Dashboard",
                type: nudges?.[currentIndex]?.name,
              });
            }}
          >
            {nudges
              ?.sort((a, b) => a.priority - b.priority)
              ?.map(({ Component, data }, index) => (
                <Component
                  key={index}
                  data={data}
                  setLoadingAgreementSigningWindow={
                    setLoadingAgreementSigningWindow
                  }
                />
              ))}
          </Carousel>
        )}
      </>
    )
  );
}
