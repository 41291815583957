import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { Typography, CircularProgress, Box } from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import ProductService from "../../Services/ProductService";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import AnalyticsService from "../../Services/AnalyticsService";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({}));

const ProductPrice = ({
  product,
  appliedCashDiscountBenefit,
  appliedOffer,
  offersLoading,
  turnOverSchemeBenefits,
  cashDiscountBenefitContainerRef,
  offersContainerRef,
  turnoverScheme,
  turnoverSchemeContainerRef,
  productDetailsStickyAtTop,
  setOpenSchemeDetailsDrawer,
  section,
}) => {
  const classes = useStyles();

  const handleClickNetPriceBreakup = () => {
    if (section === "Scheme Details Drawer") {
      setOpenSchemeDetailsDrawer(false);
    }
    if (cashDiscountBenefitContainerRef?.current?.hasChildNodes()) {
      // if CD Benefit Container is present, scroll that into view
      cashDiscountBenefitContainerRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    } else if (offersContainerRef?.current?.hasChildNodes()) {
      // else, if Offers container is present, scroll that into view
      offersContainerRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    } else if (turnoverScheme) {
      // else, scroll the TOD section into view
      turnoverSchemeContainerRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
    AnalyticsService.pushEvent(`Net Price Breakup Clicked`, {
      section: section || "",
    });
  };

  return (
    <ErrorBoundary message={``}>
      {offersLoading !== "started" ? (
        <>
          <Typography textAlign={`right`}>
            <Typography
              variant={`body1`}
              sx={{
                color: "#999999",
                textDecorationLine: "line-through",
                display: "inline-flex",
                lineHeight: "19px",
                fontWeight: "400",
                fontSize: `${productDetailsStickyAtTop ? `12px` : `14px`}`,
                transition: ProductService.productDetailsStickyTransition,
                filter: PartnerService.applySmudgedPriceFilter(product),
              }}
            >
              ₹
              {product?.sellingPrice
                ? new Intl.NumberFormat("en-IN").format(product?.sellingPrice)
                : 0}
            </Typography>

            <Typography
              variant={`body1`}
              color={`secondary`}
              sx={{
                display: "inline-flex",
                lineHeight: "19px",
                fontWeight: "700",
                marginLeft: "4px",
                fontSize: `${productDetailsStickyAtTop ? `16px` : `18px`}`,
                transition: ProductService.productDetailsStickyTransition,
                filter: PartnerService.applySmudgedPriceFilter(product),
              }}
            >
              ₹
              {new Intl.NumberFormat("en-IN").format(
                ProductService.getApplicablePrice(
                  product,
                  appliedOffer,
                  appliedCashDiscountBenefit,
                  turnOverSchemeBenefits
                )
              )}
            </Typography>
          </Typography>

          <Box textAlign={"right"} alignItems={"center"}>
            <Typography
              variant={`body2`}
              sx={{
                display: "inline-flex",
                color: "#212121",
                fontSize: `${productDetailsStickyAtTop ? `10px` : `12px`}`,
                transition: ProductService.productDetailsStickyTransition,
                fontWeight: "400",
                paddingRight: "3px",
              }}
            >
              Net Price
            </Typography>
            <Typography
              variant={`span`}
              color={`secondary`}
              sx={{
                display: "inline-flex",
                fontSize: `${productDetailsStickyAtTop ? `10px` : `12px`}`,
                transition: ProductService.productDetailsStickyTransition,
                fontWeight: "700",
              }}
              onClick={handleClickNetPriceBreakup}
            >
              Breakup
            </Typography>
            <ArrowForwardSharpIcon
              onClick={handleClickNetPriceBreakup}
              sx={{
                borderRadius: "50%",
                border: "1px solid #00733E",
                fontSize: `${productDetailsStickyAtTop ? `14px` : `16px`}`,
                transition: ProductService.productDetailsStickyTransition,
                background: "#00733E",
                color: "#FFFFFF",
                marginLeft: "4px",
                marginBottom: "-4px",
              }}
            />
          </Box>
        </>
      ) : (
        <CircularProgress
          sx={{ marginLeft: "40px", align: "right" }}
          color={"primary"}
          size={20}
        />
      )}
    </ErrorBoundary>
  );
};

export default ProductPrice;
