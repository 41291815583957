import { CheckCircle } from "@mui/icons-material";
import { Box, Card, Grid, Typography } from "@mui/material";
import _ from "lodash";

function SelectedComplaintProductCard({ product, handleRemoveProduct }) {
  return (
    <Card
      elevation={0}
      sx={{
        background: "#CACACA66",
        margin: "5px 0px",
        borderRadius: "8px",
        stroke: "#EEE",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={2}
          sx={{
            paddingLeft: "14px",
            paddingTop: "10px",
          }}
        >
          <img
            src={product?.productImage}
            alt=""
            width={"32px"}
            height="100%"
            style={{ borderRadius: "4px" }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            paddingTop: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "18px",
              color: "#212121",
            }}
          >
            {product.productName}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#4f4f4f",
            }}
          >
            Selected Quantity - {product.quantity}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            paddingTop: "10px",
            display: "flex",
            justifyContent: "right",
            paddingRight: "14px",
          }}
        >
          <CheckCircle color="secondary" />
        </Grid>
      </Grid>
      <hr
        style={{
          border: "1px solid #CACACAE5",
          marginTop: "8px",
        }}
      />
      {_.map(product.reasons, (reason) => (
        <Card
          width="100%"
          key={reason.reasonId}
          sx={{
            margin: "4px 16px",
            padding: "5px 10px",
            boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
            borderRadius: "16px",
            background: "#EEE",
          }}
          elevation={0}
        >
          <Typography
            justifyContent={`center`}
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#212121",
            }}
          >
            {reason.reasonName}
          </Typography>
        </Card>
      ))}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          margin: "16px 16px 10px 0px",
        }}
      >
        <Typography
          sx={{
            color: "#9D2123",
            fontSize: "12px",
            fontWeight: "400",
          }}
          onClick={() => handleRemoveProduct(product)}
        >
          Remove
        </Typography>
      </Box>
    </Card>
  );
}

export default SelectedComplaintProductCard;
