import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import BagItems from "./BagItems";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import SectionTitle from "../Common/SectionTitle";
import EmptyError from "../EmptyError/EmptyError";
import CartService from "../../Services/CartService";
import AddressService from "../../Services/AddressService";
import PurchaseRequestService from "../../Services/PurchaseRequestService";
import PlaceRequest from "./PlaceRequest";
import ModalComp from "../Common/ModalComponent";
import AnalyticsService from "../../Services/AnalyticsService";
import DebugDialog from "../Common/DebugDialog";
import QuantityChangeToast from "../Product/QuantityChangeToast";
import { ArrowForward, Close } from "@mui/icons-material";
import BillDetails from "./BillDetails";
import MultiCartTabs from "./MultiCartTabs";
import CartCheckoutService from "../../Services/CartCheckoutService";
import AddressCard from "./AddressCard";
import AddressSelect from "./AddressSelect";
import PaymentModal from "./PaymentModal";
import Coupon from "./Coupon";
import CouponSelect from "./CouponSelect";
import CouponToast from "./CouponToast";
import OrderService from "../../Services/OrderService";
import BlockedSelfCheckoutProducts from "../../content/BlockedSelfCheckoutProducts";
import StateUtils from "../../Utils/StateUtils";
import SentryService from "../../Services/SentryService";
import UnverifiedPartnersDialog from "../Common/UnverifiedPartnersDialog";
import AmountUtils from "../../Utils/AmountUtils";
import RealCashBifurcationDialog from "./RealCashBifurcationDialog";
import Promo from "./Promo";
import BagFreeItems from "./BagFreeItems";
import PromoToast from "./PromoToast";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: theme.palette.background.main,
    paddingTop: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "none",
    fontWeight: 400,
  },

  addressCard: {
    margin: theme.spacing(1.5, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 2),
    border: "1px solid white",
    fontSize: "12px",
    lineHeight: "16px",
  },

  errorContainer: {
    backgroundColor: "#ebd2d3",
    padding: "8px",
    border: "none",
    fontWeight: 400,
    color: "black",
    fontSize: "14px",
  },

  topWarningMessage: {
    backgroundColor: "#F8D148",
    color: "black",
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1),
    margin: "16px",
  },
}));

function Cart() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [checkoutCarts, setCheckoutCarts] = useState({});
  const [carts, setCarts] = useState({});
  const [addressSelectorOpen, setAddressSelectorOpen] = useState(false);
  const [couponSelectorOpen, setCouponSelectorOpen] = useState(false);
  const [promosData, setPromosData] = useState([]);
  const [promoSelectorOpen, setPromoSelectorOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openDebug, setOpenDebug] = useState(false);
  const [debugMessage, setDebugMessage] = useState("");
  const [errorCode, setErrorCode] = useState(``);
  const [loading, setLoading] = useState("not_started");
  const [currentTab, setCurrentTab] = useState("credit");
  const [enabledSelfCheckout, setEnabledSelfCheckout] = useState(true);
  const [blockedProducts, setBlockedProducts] = useState(false);
  const [minOrderValue, setMinimumOrderValue] = useState(null);
  const [showQuantityChangeToast, setShowQuantityChangeToast] = useState(false);
  const [showForcedQuantityToast, setShowForcedQuantityToast] = useState(false);
  const [showCouponToast, setShowCouponToast] = useState(false);
  const [showPromoToast, setShowPromoToast] = useState(false);
  const [couponRemoved, setCouponRemoved] = useState(false);
  const [promoRemoved, setPromoRemoved] = useState(false);
  const [realCashInput, setRealCashInput] = useState(null);
  const [realCashError, setRealCashError] = useState(null);
  const [couponApplyLoader, setCouponApplyLoader] = useState("not_started");
  const [promoApplyLoader, setPromoApplyLoader] = useState("not_started");
  const [showRealCashBifurcation, setShowRealCashBifurcation] = useState(false);

  const setShowQuantityChangeToastMessage = (
    flag,
    minimumQuantity,
    forcedFlag
  ) => {
    setMinimumOrderQuantityInToast(minimumQuantity);
    setShowForcedQuantityToast(forcedFlag);
    setShowQuantityChangeToast(flag);
  };
  const [minimumOrderQuantityInToast, setMinimumOrderQuantityInToast] =
    useState(1);
  const handleCloseDebug = () => {
    setOpenDebug(false);
  };
  const [showUnauthorizedError, setShowUnauthorizedError] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      setShowUnauthorizedError(true);
    }
  }, []);

  useEffect(() => {
    setDebugMessage("");
    const cart = checkoutCarts[currentTab];
    if (!cart) return;
    const blockedProductSkus =
      BlockedSelfCheckoutProducts[StateUtils.getStateCode()] || [];
    if (
      _.filter(
        cart.products || [],
        ({ skuCode }) => blockedProductSkus.indexOf(skuCode) >= 0
      ).length
    ) {
      setBlockedProducts(true);
    } else {
      setBlockedProducts(false);
    }
    const shouldPlaceRequest =
      !(enabledSelfCheckout && !blockedProductSkus) ||
      (enabledSelfCheckout && !blockedProductSkus && !cart.canPlaceOrder) ||
      (currentTab === "cash" && isPaymentOverThreshold);
    const { shippingAddressId } = AddressService.getShippingAndBillingAddress();
    let purchaseRequestEventData = {
      page: "Cart Page",
      totalDiscount: cart?.totalDiscount || 0,
      numberOfProducts: cart.products ? cart.products.length : 0,
      productName: cart.products
        ? _.map(cart.products, (product) => {
            return product.productName;
          })?.join(",")
        : "",
      skuCode: cart.products
        ? _.map(cart.products, (product) => {
            return product.skuCode;
          })?.join(",")
        : "",
      cartId: cart?.cartId || "",
      farmerId: cart?.farmerId || "",
      shippingAddressId: shippingAddressId || "",
      quantities: cart.products
        ? _.map(cart.products, (product) => {
            return product.quantity;
          })?.join(",")
        : "",
      sellingPrices: cart.products
        ? _.map(cart.products, (product) => {
            return product.sellingPrice;
          })?.join(",")
        : "",
      totalOrderValue: cart?.billDetails?.totalBillAmount || 0,
      appliedOffersName: cart.products
        ? _.map(cart.products, (product) => {
            return product?.offer?.offerName;
          })?.toString()
        : "",
      appliedOffersId: cart.products
        ? _.map(cart.products, (product) => {
            return product?.offer?.offerId;
          })?.toString()
        : "",
      couponName: cart.appliedCoupon
        ? _.filter(cart.applicableCoupons, (coupon) => {
            return coupon.offerId === cart.appliedCoupon;
          })?.[0]?.offerName
        : "N/A",
      cartType: currentTab,
      requestType: shouldPlaceRequest ? "Purchase Request" : "Order",
      cartError: null,
      appliedRealCash: cart?.agrostarWallet?.realCash?.amountUsable || 0,
    };
    AnalyticsService.pushEvent(`Cart Page Viewed`, purchaseRequestEventData);
  }, [checkoutCarts, currentTab]);

  const showCouponRemoved = (isRemoved) => {
    setCouponRemoved(isRemoved);
    setShowCouponToast(true);
  };
  const showPromoRemoved = (isRemoved) => {
    setPromoRemoved(isRemoved);
    setShowPromoToast(true);
  };

  useEffect(() => {
    setLoading("started");
    const { axiosRequest, abortController } = AddressService.setAddresses();
    axiosRequest
      .then((addressCount) => {
        if (addressCount === 0) {
          setShowError(true);
          setErrorCode(`ERROR_NO_ADDRESS_FOUND`);
          setLoading("done");
          console.log(`no address found`);
        } else {
          const { axiosRequest } = OrderService.getMinimumOrderValue();
          axiosRequest
            .then(({ data }) => {
              setMinimumOrderValue(data?.responseData?.minimumOrderValue);
              const { axiosRequest } = CartService.getMultiCartLive();
              axiosRequest
                .then((carts) => {
                  setCarts(carts);
                  let currentCartAssigned = null;
                  let otherCartAssigned = null;
                  let currentTabAssigned = "credit";
                  if (carts.CREDIT_ORDER) {
                    currentCartAssigned = carts.CREDIT_ORDER;
                    otherCartAssigned = carts.CASH_ONLY_ORDER;
                    currentTabAssigned = "credit";
                  } else if (carts.CASH_ONLY_ORDER) {
                    currentCartAssigned = carts.CASH_ONLY_ORDER;
                    otherCartAssigned = carts.CREDIT_ORDER;
                    currentTabAssigned = "cash";
                  }
                  if (currentTab !== currentTabAssigned)
                    setCurrentTab(currentTabAssigned);
                  setRealCashError(null);
                  if (currentCartAssigned) {
                    const { axiosRequest } = CartCheckoutService.getCart(
                      currentCartAssigned,
                      otherCartAssigned
                    );
                    axiosRequest
                      .then((cart) => {
                        setCheckoutCarts({ ...cart });
                        setLoading("done");
                      })
                      .catch((error) => {
                        console.log({ error });
                        setShowError(true);
                        setErrorCode(`ERROR_FAILED_TO_GET_CHECKOUT_CART`);
                        const reason = SentryService.captureCustomErrorMessage(
                          "ERROR_FAILED_TO_GET_CHECKOUT_CART",
                          error
                        );
                        AnalyticsService.pushEvent(
                          "Checkout Cart Call Failed",
                          {
                            reason: reason,
                          }
                        );
                        console.log(`failure in getting checkout cart`);
                        setLoading("done");
                      });
                  } else {
                    setLoading("done");
                  }
                })
                .catch((error) => {
                  if (error?.message !== "canceled") {
                    if (error.data) {
                      console.log(error.data);
                    } else {
                      console.log(error);
                    }
                  }
                });
            })
            .catch(() => {
              setShowError(true);
              setErrorCode(`ERROR_MIN_ORDER_VAL`);
              setLoading("done");
              console.log(`minimum order value failed`);
            });
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setShowError(true);
          setErrorCode(`ERROR_NO_ADDRESS_FOUND`);
          setLoading("done");
          console.log(`no address found`);
        }
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (
      ((currentTab === "cash" && carts.CASH_ONLY_ORDER) ||
        (currentTab === "credit" && carts.CREDIT_ORDER)) &&
      !checkoutCarts[currentTab]
    ) {
      let currentCartAssigned = null;
      let otherCartAssigned = null;
      if (currentTab === "cash") {
        currentCartAssigned = carts.CASH_ONLY_ORDER;
        otherCartAssigned = carts.CREDIT_ORDER;
      } else if (currentTab === "credit") {
        currentCartAssigned = carts.CREDIT_ORDER;
        otherCartAssigned = carts.CASH_ONLY_ORDER;
      }
      if (currentCartAssigned) {
        setLoading("started");
        const { axiosRequest, abortController } = CartCheckoutService.getCart(
          currentCartAssigned,
          otherCartAssigned
        );
        axiosRequest
          .then((cart) => {
            setCheckoutCarts({ ...checkoutCarts, ...cart });
            setLoading("done");
          })
          .catch((error) => {
            if (error?.message !== "canceled") {
              console.log({ error });
              setShowError(true);
              setErrorCode(`ERROR_FAILED_TO_GET_CHECKOUT_CART`);
              const reason = SentryService.captureCustomErrorMessage(
                "ERROR_FAILED_TO_GET_CHECKOUT_CART",
                error
              );
              AnalyticsService.pushEvent("Checkout Cart Call Failed", {
                reason: reason,
              });
              console.log(`failure in getting checkout cart`);
              setLoading("done");
            }
          });
        return () => abortController.abort();
      }
    }
  }, [currentTab]);

  useEffect(() => {
    const { axiosRequest, abortController } = CartCheckoutService.getPromos();
    axiosRequest
      .then((response) => {
        let responseData = response?.data?.responseData;
        setPromosData(responseData);
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
        }
      });
    return () => abortController.abort();
  }, []);

  const refreshCurrentCart = () => {
    let currentCartAssigned = null;
    let otherCartAssigned = null;
    if (currentTab === "cash") {
      currentCartAssigned = carts.CASH_ONLY_ORDER;
      otherCartAssigned = carts.CREDIT_ORDER;
    } else if (currentTab === "credit") {
      currentCartAssigned = carts.CREDIT_ORDER;
      otherCartAssigned = carts.CASH_ONLY_ORDER;
    }
    if (currentCartAssigned) {
      setLoading("started");
      const { axiosRequest } = CartCheckoutService.getCart(
        currentCartAssigned,
        otherCartAssigned
      );
      axiosRequest
        .then((cart) => {
          setCheckoutCarts({ ...checkoutCarts, ...cart });
          setLoading("done");
        })
        .catch((error) => {
          console.log({ error });
          setShowError(true);
          setErrorCode(`ERROR_FAILED_TO_GET_CHECKOUT_CART`);
          const reason = SentryService.captureCustomErrorMessage(
            "ERROR_FAILED_TO_GET_CHECKOUT_CART",
            error
          );
          AnalyticsService.pushEvent("Checkout Cart Call Failed", {
            reason: reason,
          });
          console.log(`failure in getting checkout cart`);
          setLoading("done");
        });
    }
  };

  const handleRemove = (product) => {
    setLoading("started");
    let currentCartAssigned = null;
    let otherCartAssigned = null;
    if (currentTab === "cash") {
      currentCartAssigned = carts.CASH_ONLY_ORDER;
      otherCartAssigned = carts.CREDIT_ORDER;
    } else if (currentTab === "credit") {
      currentCartAssigned = carts.CREDIT_ORDER;
      otherCartAssigned = carts.CASH_ONLY_ORDER;
    }
    const products = currentCartAssigned.products.filter(
      ({ skuCode }) => skuCode !== product.skuCode
    );
    const newCarts = { ...carts };
    currentCartAssigned.products = products;
    if (products?.length === 0) {
      if (currentTab === "cash") {
        newCarts.CASH_ONLY_ORDER = null;
      } else if (currentTab === "credit") {
        newCarts.CREDIT_ORDER = null;
      }
    } else {
      if (currentTab === "cash") {
        newCarts.CASH_ONLY_ORDER = currentCartAssigned;
      } else if (currentTab === "credit") {
        newCarts.CREDIT_ORDER = currentCartAssigned;
      }
    }
    CartCheckoutService.putCart(currentCartAssigned, otherCartAssigned)
      .then((cart) => {
        setCarts(() => {
          const result = { ...newCarts };
          if (cart?.cash) {
            result.CASH_ONLY_ORDER = {
              ...result.CASH_ONLY_ORDER,
              appliedCoupon: cart?.cash?.appliedCoupon,
              appliedPromo: cart?.cash?.appliedPromo,
              products: cart?.cash?.products || [],
              freeProducts: cart?.cash?.freeProducts || [],
              cartType: "CASH_ONLY_ORDER",
            };
          } else if (result.CASH_ONLY_ORDER) {
            delete result.CASH_ONLY_ORDER;
          }
          if (cart?.credit) {
            result.CREDIT_ORDER = {
              ...result.CREDIT_ORDER,
              appliedCoupon: cart?.credit?.appliedCoupon,
              appliedPromo: cart?.credit?.appliedPromo,
              products: cart?.credit?.products || [],
              freeProducts: cart?.credit?.freeProducts || [],
              cartType: "CREDIT_ORDER",
            };
          } else if (result.CREDIT_ORDER) {
            delete result.CREDIT_ORDER;
          }
          return result;
        });
        if (
          checkoutCarts?.[currentTab]?.appliedCoupon !==
            cart?.[currentTab]?.appliedCoupon &&
          cart?.[currentTab]?.products?.length
        ) {
          showCouponRemoved(true);
        }
        setCheckoutCarts(() => {
          const result = { ...checkoutCarts };
          if (cart?.cash) {
            result.cash = {
              ...cart.cash,
            };
          } else if (result.cash) {
            delete result.cash;
          }
          if (cart?.credit) {
            result.credit = {
              ...cart.credit,
            };
          } else if (result.credit) {
            delete result.credit;
          }
          return result;
        });
        setLoading("done");
        let productRemovedEventData = {
          skuCode: product?.skuCode || "",
          page: `Cart`,
        };
        AnalyticsService.pushEvent(`Product Removed`, productRemovedEventData);
      })
      .catch((error) => {
        console.log({ error });
        setShowError(true);
        setErrorCode(`ERROR_FAILED_TO_REMOVE_PRODUCT`);
        const reason = SentryService.captureCustomErrorMessage(
          "ERROR_FAILED_TO_REMOVE_PRODUCT",
          error
        );
        AnalyticsService.pushEvent("Product Removal Failed", {
          reason: reason,
        });
        console.log(`failure in removing product`);
        setLoading("done");
      });
  };

  const handleUpdate = (product) => {
    setLoading("started");
    let currentCartAssigned = null;
    let otherCartAssigned = null;
    if (currentTab === "cash") {
      currentCartAssigned = carts.CASH_ONLY_ORDER;
      otherCartAssigned = carts.CREDIT_ORDER;
    } else if (currentTab === "credit") {
      currentCartAssigned = carts.CREDIT_ORDER;
      otherCartAssigned = carts.CASH_ONLY_ORDER;
    }
    const products = currentCartAssigned.products.map((p) =>
      p.skuCode === product.skuCode ? { ...p, quantity: product.quantity } : p
    );
    const newCarts = { ...carts };
    currentCartAssigned.products = products;
    if (products.length === 0) {
      if (currentTab === "cash") {
        newCarts.CASH_ONLY_ORDER = null;
      } else if (currentTab === "credit") {
        newCarts.CREDIT_ORDER = null;
      }
    } else {
      if (currentTab === "cash") {
        newCarts.CASH_ONLY_ORDER = currentCartAssigned;
      } else if (currentTab === "credit") {
        newCarts.CREDIT_ORDER = currentCartAssigned;
      }
    }
    CartCheckoutService.putCart(currentCartAssigned, otherCartAssigned)
      .then((cart) => {
        setCarts(() => {
          const result = { ...newCarts };
          if (cart?.cash) {
            result.CASH_ONLY_ORDER = {
              ...result.CASH_ONLY_ORDER,
              appliedCoupon: cart?.cash?.appliedCoupon,
              appliedPromo: cart?.cash?.appliedPromo,
              products: cart?.cash?.products || [],
              cartType: "CASH_ONLY_ORDER",
            };
          } else if (result.CASH_ONLY_ORDER) {
            delete result.CASH_ONLY_ORDER;
          }
          if (cart?.credit) {
            result.CREDIT_ORDER = {
              ...result.CREDIT_ORDER,
              appliedCoupon: cart?.credit?.appliedCoupon,
              appliedPromo: cart?.credit?.appliedPromo,
              products: cart?.credit?.products || [],
              cartType: "CREDIT_ORDER",
            };
          } else if (result.CREDIT_ORDER) {
            delete result.CREDIT_ORDER;
          }
          return result;
        });
        if (
          checkoutCarts?.[currentTab]?.appliedCoupon !==
            cart?.[currentTab]?.appliedCoupon &&
          cart?.[currentTab]?.products?.length
        ) {
          showCouponRemoved(true);
        }
        setCheckoutCarts(() => {
          const result = { ...checkoutCarts };
          if (cart?.cash) {
            result.cash = {
              ...cart.cash,
            };
          } else if (result.cash) {
            delete result.cash;
          }
          if (cart?.credit) {
            result.credit = {
              ...cart.credit,
            };
          } else if (result.credit) {
            delete result.credit;
          }
          return result;
        });
        setLoading("done");
        let productUpdatedEventData = {
          skuCode: product?.skuCode || "",
          page: `Cart`,
        };
        AnalyticsService.pushEvent(`Product Updated`, productUpdatedEventData);
      })
      .catch((error) => {
        console.log({ error });
        setShowError(true);
        setErrorCode(`ERROR_FAILED_TO_UPDATE_PRODUCT`);
        const reason = SentryService.captureCustomErrorMessage(
          "ERROR_FAILED_TO_UPDATE_PRODUCT",
          error
        );
        AnalyticsService.pushEvent("Product update Failed", {
          reason: reason,
        });
        console.log(`failure in updating product`);
        setLoading("done");
      });
  };

  const placeRequest = (checkoutCart) => {
    let currentCartAssigned = null;
    if (currentTab === "cash") {
      currentCartAssigned = carts.CASH_ONLY_ORDER;
    } else if (currentTab === "credit") {
      currentCartAssigned = carts.CREDIT_ORDER;
    }
    const { shippingAddressId } = AddressService.getShippingAndBillingAddress();
    const { axiosRequest } =
      PurchaseRequestService.createPurchaseRequest(currentCartAssigned);
    axiosRequest
      .then((purchaseResponse) => {
        if (purchaseResponse?.data?.responseData) {
          let purchaseRequestEventData = {
            page: "Cart Page",
            totalDiscount: checkoutCart?.totalDiscount || 0,
            numberOfProducts: checkoutCart.products
              ? checkoutCart.products.length
              : 0,
            productName: checkoutCart.products
              ? _.map(checkoutCart.products, (product) => {
                  return product.productName;
                })?.join(",")
              : "",
            skuCode: checkoutCart.products
              ? _.map(checkoutCart.products, (product) => {
                  return product.skuCode;
                })?.join(",")
              : "",
            cartId: checkoutCart?.cartId || "",
            farmerId: checkoutCart?.farmerId || "",
            shippingAddressId: shippingAddressId || "",
            quantities: checkoutCart.products
              ? _.map(checkoutCart.products, (product) => {
                  return product.quantity;
                })?.join(",")
              : "",
            sellingPrices: checkoutCart.products
              ? _.map(checkoutCart.products, (product) => {
                  return product.sellingPrice;
                })?.join(",")
              : "",
            totalOrderValue: checkoutCart?.billDetails?.totalBillAmount || 0,
            orderIds: purchaseResponse?.data?.responseData || "",
            appliedOffersName: checkoutCart.products
              ? _.map(checkoutCart.products, (product) => {
                  return product?.offer?.offerName;
                })?.toString()
              : "",
            appliedOffersId: checkoutCart.products
              ? _.map(checkoutCart.products, (product) => {
                  return product?.offer?.offerId;
                })?.toString()
              : "",
            couponName: checkoutCart.appliedCoupon
              ? _.filter(checkoutCart.applicableCoupons, (coupon) => {
                  return coupon.offerId === checkoutCart.appliedCoupon;
                })?.[0]?.offerName
              : "N/A",
            promos: checkoutCart.appliedPromo,
            cartType: currentTab,
            requestType: "Purchase Request",
            cartError: null,
            appliedRealCash:
              checkoutCart?.agrostarWallet?.realCash?.amountUsable || 0,
          };

          AnalyticsService.pushEvent(
            `Purchase Request Created`,
            purchaseRequestEventData
          );
          console.log(`purchase request created successfully`);
          const newCarts = { ...carts };
          let redirect = "";
          if (currentTab === "cash" && newCarts.CREDIT_ORDER) {
            redirect = "?redirect=cart";
          } else if (currentTab === "credit" && newCarts.CASH_ONLY_ORDER) {
            redirect = "?redirect=cart";
          }
          AddressService.setOldAddressForCart();
          localStorage.setItem(
            "order",
            JSON.stringify(purchaseRequestEventData)
          );
          navigate(`/request/success${redirect}`, { replace: true });
          setLoading("done");
        } else {
          setShowError(true);
          setErrorCode(`ERROR_FAILED_TO_CREATE_PURCHASE_REQUEST`);
          AnalyticsService.pushEvent("Purchase Request Creation Failed", {
            reason: "No Data in Purchase Request ResponseData",
          });
          console.log(`could not create purchase request`);
          setLoading("done");
        }
      })
      .catch((error) => {
        console.log({ error });
        setShowError(true);
        setErrorCode(`ERROR_FAILED_TO_CREATE_PURCHASE_REQUEST`);
        const reason = SentryService.captureCustomErrorMessage(
          "ERROR_FAILED_TO_CREATE_PURCHASE_REQUEST",
          error
        );
        AnalyticsService.pushEvent("Purchase Request Creation Failed", {
          reason: reason,
        });
        console.log(`failure in creating purchase request`);
        setLoading("done");
      });
  };

  const placeOrder = (
    reviewCart,
    checkoutCart,
    paymentType,
    otherCart,
    eventData
  ) => {
    setLoading("started");
    const { shippingAddressId } = AddressService.getShippingAndBillingAddress();
    let placeOrderEventData = {
      page: "Cart Page",
      totalDiscount: checkoutCart?.totalDiscount || 0,
      numberOfProducts: checkoutCart.products
        ? checkoutCart.products.length
        : 0,
      productName: checkoutCart.products
        ? _.map(checkoutCart.products, (product) => {
            return product.productName;
          })?.join(",")
        : "",
      skuCode: checkoutCart.products
        ? _.map(checkoutCart.products, (product) => {
            return product.skuCode;
          })?.join(",")
        : "",
      cartId: checkoutCart?.cartId || "",
      farmerId: checkoutCart?.farmerId || "",
      shippingAddressId: shippingAddressId || "",
      quantities: checkoutCart.products
        ? _.map(checkoutCart.products, (product) => {
            return product.quantity;
          })?.join(",")
        : "",
      sellingPrices: checkoutCart.products
        ? _.map(checkoutCart.products, (product) => {
            return product.sellingPrice;
          })?.join(",")
        : "",
      totalOrderValue: checkoutCart?.billDetails?.totalBillAmount || 0,
      appliedOffersName: checkoutCart.products
        ? _.map(checkoutCart.products, (product) => {
            return product?.offer?.offerName;
          })?.toString()
        : "",
      appliedOffersId: checkoutCart.products
        ? _.map(checkoutCart.products, (product) => {
            return product?.offer?.offerId;
          })?.toString()
        : "",
      couponName: checkoutCart.appliedCoupon
        ? _.filter(checkoutCart.applicableCoupons, (coupon) => {
            return coupon.offerId === checkoutCart.appliedCoupon;
          })?.[0]?.offerName
        : "N/A",
      promos: checkoutCart?.appliedPromo,
      cartType: currentTab,
      requestType: "Order",
      cartError: null,
      appliedRealCash:
        checkoutCart?.agrostarWallet?.realCash?.amountUsable || 0,
      ...eventData,
    };
    AnalyticsService.pushEvent(`Proceed Button Clicked`, placeOrderEventData);
    CartCheckoutService.placeOrder(
      reviewCart,
      checkoutCart,
      paymentType,
      otherCart
    )
      .then((order) => {
        const orderEventData = {
          ...placeOrderEventData,
          orderIds: _.reduce(
            order.placedOrders,
            (acc, order) => {
              return acc ? `${acc},${order?.orderId}` : `${order?.orderId}`;
            },
            ""
          ),
          paymentAmount:
            order?.paymentAmountDetails?.actualPaymentToBeCollected || 0,
        };
        if (orderEventData.drawerType) {
          delete orderEventData.drawerType;
        }
        AnalyticsService.pushEvent(`Order Created`, orderEventData);
        console.log(`order request created successfully`);
        const newCarts = { ...carts };
        let redirect = "";
        if (currentTab === "cash" && newCarts.CREDIT_ORDER) {
          redirect = "?redirect=cart";
        } else if (currentTab === "credit" && newCarts.CASH_ONLY_ORDER) {
          redirect = "?redirect=cart";
        }
        order.eventData = orderEventData;
        localStorage.setItem("order", JSON.stringify(order));
        AddressService.setOldAddressForCart();
        if (paymentType === "CASH") {
          if (isFieldAgent) {
            // if payment mode is online & it's a field order, directly place the order without Razorpay flow
            navigate(`/order/success${redirect}`, { replace: true });
          } else {
            navigate(`/order/payment-request/${redirect}`, { replace: true });
          }
        } else {
          navigate(`/order/success${redirect}`, { replace: true });
        }
        setLoading("done");
      })
      .catch((error) => {
        console.log({ error });
        setShowError(true);
        setErrorCode(`ERROR_FAILED_TO_CREATE_ORDER`);
        const reason = SentryService.captureCustomErrorMessage(
          "ERROR_FAILED_TO_CREATE_ORDER",
          error
        );
        AnalyticsService.pushEvent("Order Creation Failed", {
          reason: reason,
        });
        console.log(`failure in creating order`);
        setLoading("done");
      });
  };

  const handlePlaceRequest = (event) => {
    event.preventDefault();
    const shouldPlaceRequest =
      !(enabledSelfCheckout && !blockedProducts) ||
      (enabledSelfCheckout && !blockedProducts && !cart.canPlaceOrder) ||
      (currentTab === "cash" && isPaymentOverThreshold);
    if (shouldPlaceRequest) {
      setLoading("started");
      placeRequest(cart);
    } else {
      const { shippingAddressId } =
        AddressService.getShippingAndBillingAddress();
      let placeOrderEventData = {
        page: "Cart Page",
        totalDiscount: cart?.totalDiscount || 0,
        numberOfProducts: cart.products ? cart.products.length : 0,
        productName: cart.products
          ? _.map(cart.products, (product) => {
              return product.productName;
            })?.join(",")
          : "",
        skuCode: cart.products
          ? _.map(cart.products, (product) => {
              return product.skuCode;
            })?.join(",")
          : "",
        cartId: cart?.cartId || "",
        farmerId: cart?.farmerId || "",
        shippingAddressId: shippingAddressId || "",
        quantities: cart.products
          ? _.map(cart.products, (product) => {
              return product.quantity;
            })?.join(",")
          : "",
        sellingPrices: cart.products
          ? _.map(cart.products, (product) => {
              return product.sellingPrice;
            })?.join(",")
          : "",
        totalOrderValue: cart?.billDetails?.totalBillAmount || 0,
        appliedOffersName: cart.products
          ? _.map(cart.products, (product) => {
              return product?.offer?.offerName;
            })?.toString()
          : "",
        appliedOffersId: cart.products
          ? _.map(cart.products, (product) => {
              return product?.offer?.offerId;
            })?.toString()
          : "",
        couponName: cart.appliedCoupon
          ? _.filter(cart.applicableCoupons, (coupon) => {
              return coupon.offerId === cart.appliedCoupon;
            })?.[0]?.offerName
          : "N/A",
        couponId: cart.appliedCoupon,
        promos: cart.appliedPromo,
        cartType: currentTab,
        requestType: "Order",
        cartError: null,
      };
      AnalyticsService.pushEvent("Place Order Clicked", placeOrderEventData);
      setPaymentModalOpen(true);
    }
  };

  const applyCoupon = (coupon, couponType) => {
    setLoading("started");
    setCouponApplyLoader("started");
    let currentCartAssigned = null;
    let otherCartAssigned = null;
    if (currentTab === "cash") {
      currentCartAssigned = carts.CASH_ONLY_ORDER;
      otherCartAssigned = carts.CREDIT_ORDER;
    } else if (currentTab === "credit") {
      currentCartAssigned = carts.CREDIT_ORDER;
      otherCartAssigned = carts.CASH_ONLY_ORDER;
    }
    CartCheckoutService.putCart(
      {
        ...currentCartAssigned,
        appliedCoupon: coupon,
      },
      otherCartAssigned
    )
      .then((cart) => {
        setCarts((prevCarts) => {
          const result = { ...prevCarts };
          if (cart?.cash) {
            result.CASH_ONLY_ORDER = {
              ...result.CASH_ONLY_ORDER,
              appliedCoupon: cart?.cash?.appliedCoupon,
              appliedPromo: cart?.cash?.appliedPromo,
              products: cart?.cash?.products || [],
              cartType: "CASH_ONLY_ORDER",
            };
          } else if (result.CASH_ONLY_ORDER) {
            delete result.CASH_ONLY_ORDER;
          }
          if (cart?.credit) {
            result.CREDIT_ORDER = {
              ...result.CREDIT_ORDER,
              appliedCoupon: cart?.credit?.appliedCoupon,
              appliedPromo: cart?.credit?.appliedPromo,
              products: cart?.credit?.products || [],
              cartType: "CREDIT_ORDER",
            };
          } else if (result.CREDIT_ORDER) {
            delete result.CREDIT_ORDER;
          }
          return result;
        });

        setCheckoutCarts(() => {
          const result = { ...checkoutCarts };
          if (cart?.cash) {
            result.cash = {
              ...cart.cash,
            };
          } else if (result.cash) {
            delete result.cash;
          }
          if (cart?.credit) {
            result.credit = {
              ...cart.credit,
            };
          } else if (result.credit) {
            delete result.credit;
          }
          return result;
        });

        if (cart?.[currentTab].couponRemoved) {
          showCouponRemoved(false);
        }
        if (!cart?.[currentTab].couponRemovedReason) {
          setCouponSelectorOpen(false);
          if (coupon !== null) {
            AnalyticsService.pushEvent("Coupon Applied", {
              coupon: coupon,
              couponType: couponType,
            });
          }
        }
        setLoading("done");
        setCouponApplyLoader("done");
      })
      .catch((error) => {
        console.log({ error });
        setShowError(true);
        setErrorCode(`ERROR_FAILED_TO_APPLY_COUPON`);
        const reason = SentryService.captureCustomErrorMessage(
          "ERROR_FAILED_TO_APPLY_COUPON",
          error
        );
        AnalyticsService.pushEvent("Coupon Application Failed", {
          reason: reason,
        });
        console.log(`failure in applying product`);
        setLoading("done");
        setCouponApplyLoader("done");
      });
  };

  const applyPromo = (promosToBeApplied) => {
    setLoading("started");
    setPromoApplyLoader("started");
    let currentCartAssigned = null;
    let otherCartAssigned = null;
    if (currentTab === "cash") {
      currentCartAssigned = carts.CASH_ONLY_ORDER;
      otherCartAssigned = carts.CREDIT_ORDER
        ? { ...carts.CREDIT_ORDER, appliedPromo: "" } // clear the promo in the other cart
        : carts.CREDIT_ORDER;
    } else if (currentTab === "credit") {
      currentCartAssigned = carts.CREDIT_ORDER;
      otherCartAssigned = carts.CASH_ONLY_ORDER
        ? { ...carts.CASH_ONLY_ORDER, appliedPromo: "" } // clear the promo in the other cart
        : carts.CASH_ONLY_ORDER;
    }
    CartCheckoutService.putCart(
      {
        ...currentCartAssigned,
        appliedPromo: promosToBeApplied,
      },
      otherCartAssigned
    )
      .then((cart) => {
        setCarts((prevCarts) => {
          const result = { ...prevCarts };
          if (cart?.cash) {
            result.CASH_ONLY_ORDER = {
              ...result.CASH_ONLY_ORDER,
              appliedPromo: cart?.cash?.appliedPromo,
              products: cart?.cash?.products || [],
              cartType: "CASH_ONLY_ORDER",
            };
          } else if (result.CASH_ONLY_ORDER) {
            delete result.CASH_ONLY_ORDER;
          }
          if (cart?.credit) {
            result.CREDIT_ORDER = {
              ...result.CREDIT_ORDER,
              appliedPromo: cart?.credit?.appliedPromo,
              products: cart?.credit?.products || [],
              cartType: "CREDIT_ORDER",
            };
          } else if (result.CREDIT_ORDER) {
            delete result.CREDIT_ORDER;
          }
          return result;
        });

        setCheckoutCarts(() => {
          const result = { ...checkoutCarts };
          if (cart?.cash) {
            result.cash = {
              ...cart.cash,
            };
          } else if (result.cash) {
            delete result.cash;
          }
          if (cart?.credit) {
            result.credit = {
              ...cart.credit,
            };
          } else if (result.credit) {
            delete result.credit;
          }
          return result;
        });

        if (cart?.[currentTab].promoRemovedReason) {
          showPromoRemoved(false);
        }
        if (!cart?.[currentTab].promoRemovedReason) {
          if (promosToBeApplied !== "") {
            AnalyticsService.pushEvent("Promo Applied", {
              promo: promosToBeApplied,
            });
          }
        }
        setLoading("done");
        setPromoApplyLoader("done");
      })
      .catch((error) => {
        console.log({ error });
        setShowError(true);
        setErrorCode(`ERROR_FAILED_TO_APPLY_PROMO`);
        const reason = SentryService.captureCustomErrorMessage(
          "ERROR_FAILED_TO_APPLY_PROMO",
          error
        );
        AnalyticsService.pushEvent("Promo Application Failed", {
          reason: reason,
        });
        console.log(`failure in applying promo`);
        setLoading("done");
        setPromoApplyLoader("done");
      });
  };

  const applyRealCash = (realCashToBeApplied) => {
    setRealCashError(null);
    setLoading("started");
    let currentCartAssigned = null;
    let otherCartAssigned = null;
    if (currentTab === "cash") {
      currentCartAssigned = carts.CASH_ONLY_ORDER;
      otherCartAssigned = carts.CREDIT_ORDER;
    } else if (currentTab === "credit") {
      currentCartAssigned = carts.CREDIT_ORDER;
      otherCartAssigned = carts.CASH_ONLY_ORDER;
    }
    CartCheckoutService.putCart(
      { ...currentCartAssigned, usedRealCash: realCashToBeApplied },
      otherCartAssigned
    )
      .then((cart) => {
        if (
          realCashToBeApplied &&
          cart?.[currentTab]?.agrostarWallet?.realCash?.amountUsable === 0
        ) {
          setRealCashError(
            "Real cash cannot be applied due to restrictions. Please check the cart items and Real Cash amount."
          );
        }

        setCarts((prevCarts) => {
          const result = { ...prevCarts };
          if (cart?.cash) {
            result.CASH_ONLY_ORDER = {
              ...result.CASH_ONLY_ORDER,
              appliedCoupon: cart?.cash?.appliedCoupon,
              appliedPromo: cart?.cash?.appliedPromo,
              products: cart?.cash?.products || [],
              cartType: "CASH_ONLY_ORDER",
              usedRealCash: cart?.cash?.agrostarWallet?.realCash?.amountUsable
                ? true
                : false,
            };
          } else if (result.CASH_ONLY_ORDER) {
            delete result.CASH_ONLY_ORDER;
          }
          if (cart?.credit) {
            result.CREDIT_ORDER = {
              ...result.CREDIT_ORDER,
              appliedCoupon: cart?.credit?.appliedCoupon,
              appliedPromo: cart?.credit?.appliedPromo,
              products: cart?.credit?.products || [],
              cartType: "CREDIT_ORDER",
              usedRealCash: cart?.credit?.agrostarWallet?.realCash?.amountUsable
                ? true
                : false,
            };
          } else if (result.CREDIT_ORDER) {
            delete result.CREDIT_ORDER;
          }
          return result;
        });
        setCheckoutCarts(() => {
          const result = { ...checkoutCarts };
          if (cart?.cash) {
            result.cash = {
              ...cart.cash,
            };
          } else if (result.cash) {
            delete result.cash;
          }
          if (cart?.credit) {
            result.credit = {
              ...cart.credit,
            };
          } else if (result.credit) {
            delete result.credit;
          }
          return result;
        });

        setLoading("done");
      })
      .catch((error) => {
        console.log({ error });
        setShowError(true);
        setErrorCode(`ERROR_FAILED_TO_APPLY_REAL_CASH`);
        SentryService.captureCustomErrorMessage(
          "ERROR_FAILED_TO_APPLY_REAL_CASH",
          error
        );
        setLoading("done");
      });
  };

  const removeRealCash = () => {
    setRealCashInput(`""`);
    AnalyticsService.pushEvent("Button Clicked", { type: "Remove Real Cash" });
    applyRealCash(false);
  };

  const cart = checkoutCarts[currentTab];
  const otherCart = checkoutCarts[currentTab === "cash" ? "credit" : "cash"];
  const isFieldAgent = localStorage.getItem("role") === "fieldAgent";
  const isPaymentOverThreshold =
    cart?.billDetails?.saathiAdvancePayAmount >= 500000;
  const hasMinimumOrderValueRestriction =
    minOrderValue && cart && cart.subTotal < minOrderValue;

  const displayMessage =
    checkoutCarts.cash &&
    checkoutCarts.cash.products &&
    checkoutCarts.cash.products
      .filter(
        (value) =>
          value.maxPlacementAmount !== null &&
          value.maxPlacementAmount <
            value.quantity * value.sellingPrice + (value.pendingAmount || 0)
      )
      .reduce((acc, current) => {
        if (acc.indexOf(current) == -1) {
          acc.push(current.productSalesGroup);
        }
        return acc;
      }, []);

  return (
    <div>
      <QuantityChangeToast
        show={showQuantityChangeToast}
        setShow={setShowQuantityChangeToast}
        showForcedQuantityToast={showForcedQuantityToast}
        minimumOrderQuantity={minimumOrderQuantityInToast}
      />
      {enabledSelfCheckout && (
        <CouponToast
          show={showCouponToast}
          setShow={setShowCouponToast}
          error={cart?.couponRemovedReason}
          showRemovedText={couponRemoved}
        />
      )}
      {enabledSelfCheckout && (
        <PromoToast
          show={showPromoToast}
          setShow={setShowPromoToast}
          error={cart?.promoRemovedReason}
          showRemovedText={promoRemoved}
        />
      )}
      <ErrorBoundary message={``}>
        <TopBar title={`My Cart`} backNavigation={true} />
      </ErrorBoundary>
      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Please wait`}
        keepInView
      >
        <div className={classes.mainContent}>
          <ModalComp
            isOpen={showError}
            handleClose={() => setShowError(false)}
            modalText="There seems to be an error. Please try again."
            modalBtnText="RETRY"
            modalImage="warning.svg"
            modelBtnClick={() => setShowError(false)}
            errorCode={errorCode}
          />
          {displayMessage && displayMessage.length > 0 && (
            <Typography
              variant={"subtitle1"}
              className={classes.topWarningMessage}
            >
              <span style={{ fontWeight: "bold", color: "#2C8140" }}>
                {_.uniq(displayMessage).join(", ")}
              </span>{" "}
              has been moved to Cash Bag as order value is greater than maximum
              limit for credit order.
            </Typography>
          )}
          <MultiCartTabs
            currentTab={currentTab}
            carts={carts}
            setCurrentTab={(tab) => {
              AnalyticsService.pushEvent("Cart Switch Button Clicked", {
                cartType: currentTab,
                newCartType: tab,
                cartId: cart?.cartId,
              });
              setCurrentTab(tab);
              setRealCashError(null);
            }}
            setRealCashError={setRealCashError}
          />
          <form
            onSubmit={handlePlaceRequest}
            style={{
              marginBottom:
                cart?.hasError &&
                (isFieldAgent
                  ? "130px"
                  : cart.saathiRestrictions &&
                      cart.saathiRestrictions.filter(
                        (restriction) => restriction.length
                      ).length
                    ? "60px"
                    : "40px"),
            }}
          >
            {cart && cart.products && cart.products.length > 0 ? (
              <>
                <Container maxWidth={"xl"}>
                  <SectionTitle title={`Select Address`} />
                </Container>
                <AddressCard
                  currentTab={currentTab}
                  onChangeAddress={() => {
                    const { shippingAddressId } =
                      AddressService.getShippingAndBillingAddress();
                    AnalyticsService.pushEvent("Address Change Clicked", {
                      cartId: cart?.cartId,
                      cartType: currentTab,
                      addressId: shippingAddressId,
                    });
                    setAddressSelectorOpen(true);
                  }}
                />
                <AddressSelect
                  open={addressSelectorOpen}
                  currentTab={currentTab}
                  cart={cart}
                  onClose={() => {
                    setAddressSelectorOpen(false);
                  }}
                  onSubmit={() => {
                    setAddressSelectorOpen(false);
                    refreshCurrentCart();
                  }}
                />

                <PaymentModal
                  open={paymentModalOpen}
                  cart={cart}
                  otherCart={otherCart}
                  currentTab={currentTab}
                  isPaymentOverThreshold={isPaymentOverThreshold}
                  onError={(errorCode) => {
                    setPaymentModalOpen(false);
                    setShowError(true);
                    setErrorCode(errorCode);
                  }}
                  onClose={() => {
                    setPaymentModalOpen(false);
                  }}
                  onSubmit={(reviewCart, paymentType, eventData) => {
                    setPaymentModalOpen(false);
                    placeOrder(
                      reviewCart,
                      cart,
                      paymentType,
                      otherCart,
                      eventData
                    );
                  }}
                  usedRealCash={
                    carts?.[
                      currentTab === "cash" ? "CASH_ONLY_ORDER" : "CREDIT_ORDER"
                    ]?.usedRealCash
                  }
                />

                <Container maxWidth={"xl"}>
                  <SectionTitle
                    title={`Items in Cart (${cart.products.length})`}
                  />
                </Container>

                <ErrorBoundary message={``}>
                  <BagItems
                    cartProducts={cart.products}
                    handleRemove={handleRemove}
                    handleUpdate={handleUpdate}
                    setShowQuantityChangeToast={
                      setShowQuantityChangeToastMessage
                    }
                  />
                </ErrorBoundary>

                {cart?.freeProducts?.length > 0 ? (
                  <>
                    <Container maxWidth={"xl"}>
                      <SectionTitle
                        title={`Free Gifts (${cart.freeProducts.length})`}
                      />
                    </Container>
                    <BagFreeItems cartProducts={cart?.freeProducts} />
                  </>
                ) : (
                  <></>
                )}

                {enabledSelfCheckout && (
                  <Container maxWidth={"xl"}>
                    <SectionTitle title={`Coupons`} />
                  </Container>
                )}
                {enabledSelfCheckout && (
                  <Coupon
                    cart={cart}
                    onApplySelected={(ctaType) => {
                      AnalyticsService.pushEvent("Apply Coupon Clicked", {
                        cartId: cart?.cartId,
                        cartType: currentTab,
                        viewType: ctaType,
                      });
                      setCouponSelectorOpen(true);
                    }}
                    handleApplyOnCouponClicked={(coupon) => {
                      AnalyticsService.pushEvent("Apply On Coupon Clicked", {
                        cartId: cart?.cartId,
                        cartType: currentTab,
                        couponId: coupon.offerId,
                      });
                      applyCoupon(coupon.offerId, "Non-floating");
                    }}
                    onRemove={() => {
                      applyCoupon(null, "");
                    }}
                  />
                )}
                {enabledSelfCheckout && (
                  <CouponSelect
                    open={couponSelectorOpen}
                    cart={cart}
                    otherCart={otherCart}
                    currentTab={currentTab}
                    onClose={() => {
                      setCouponSelectorOpen(false);
                    }}
                    onSubmit={(coupon) => {
                      setCouponSelectorOpen(false);
                      applyCoupon(coupon, "Non-floating");
                    }}
                    applyCoupon={applyCoupon}
                    couponApplyLoader={couponApplyLoader}
                  />
                )}
                {enabledSelfCheckout && promosData?.totalCount > 0 && (
                  <Container maxWidth={"xl"}>
                    <SectionTitle title={`Promo Code`} />
                    <Promo
                      promosData={promosData}
                      open={promoSelectorOpen}
                      cart={cart}
                      otherCart={otherCart}
                      currentTab={currentTab}
                      onClose={() => {
                        setPromoSelectorOpen(false);
                      }}
                      applyPromo={applyPromo}
                      promoApplyLoader={promoApplyLoader}
                      setPromoSelectorOpen={setPromoSelectorOpen}
                    />
                  </Container>
                )}

                {enabledSelfCheckout && isFieldAgent && (
                  <>
                    <Container maxWidth={"xl"}>
                      <SectionTitle title={`Use Real Cash`} />
                      {cart?.billDetails?.usableRealCashAmount > 0 ? (
                        <Card
                          variant="outlined"
                          sx={{
                            p: 2,
                            mt: 1,
                            borderRadius: "8px",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
                            background: "#9d21231a",
                          }}
                        >
                          <Grid container alignItems={"center"}>
                            <Grid item xs={9.5}>
                              <Typography>
                                Real Cash Applied:{" "}
                                <b>
                                  {AmountUtils.formatCurrency(
                                    cart?.billDetails?.usableRealCashAmount
                                  )}
                                </b>
                              </Typography>
                              <Typography
                                fontSize={12}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#4F4F4F",
                                }}
                                onClick={() => setShowRealCashBifurcation(true)}
                              >
                                Based on selected products
                                <ArrowForward
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    ml: "4px",
                                    color: "#666",
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={2.5} sx={{ textAlign: "end" }}>
                              <Close
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  removeRealCash();
                                }}
                                style={{ float: "right", color: "#9D2123" }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      ) : (
                        <Card
                          variant="outlined"
                          sx={{
                            p: 2,
                            mt: 1,
                            borderRadius: "8px",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <Grid container alignItems={"center"}>
                            <Grid item xs={9.5}>
                              <Typography>
                                Available Real Cash:{" "}
                                <span
                                  style={{
                                    color: "#00733E",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {checkoutCarts?.[currentTab]?.agrostarWallet
                                    ?.realCash?.amountRemaining >= 0
                                    ? AmountUtils.formatCurrency(
                                        checkoutCarts?.[currentTab]
                                          ?.agrostarWallet?.realCash
                                          ?.amountRemaining
                                      )
                                    : 0}
                                </span>
                              </Typography>
                              <Typography
                                fontSize={12}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#4F4F4F",
                                }}
                                onClick={() => setShowRealCashBifurcation(true)}
                              >
                                Based on selected products
                                <ArrowForward
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    ml: "4px",
                                    color: "#666",
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={2.5} sx={{ textAlign: "end" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  AnalyticsService.pushEvent("Button Clicked", {
                                    type: "Apply Real Cash",
                                    realCash: realCashInput,
                                  });
                                  applyRealCash(true);
                                }}
                                disabled={
                                  realCashError ||
                                  !cart?.agrostarWallet?.realCash
                                    ?.amountRemaining
                                }
                                sx={{
                                  textTransform: "capitalize",
                                  borderRadius: "16px",
                                  fontWeight: "bold",
                                  padding: "2px 16px",
                                }}
                              >
                                Apply
                              </Button>
                            </Grid>
                          </Grid>
                          {realCashError && (
                            <Typography
                              color={"primary"}
                              variant="subtitle"
                              sx={{ fontSize: "14px", mt: "5px" }}
                            >
                              {realCashError}
                            </Typography>
                          )}
                        </Card>
                      )}
                    </Container>
                  </>
                )}

                <ErrorBoundary message={""}>
                  <BillDetails
                    cart={cart}
                    isPurchaseRequest={
                      !(enabledSelfCheckout && !blockedProducts) ||
                      (enabledSelfCheckout &&
                        !blockedProducts &&
                        !cart.canPlaceOrder) ||
                      (currentTab === "cash" && isPaymentOverThreshold)
                    }
                  />
                </ErrorBoundary>

                {cart.hasError &&
                  (isFieldAgent ||
                    (cart.saathiRestrictions &&
                      cart.saathiRestrictions.filter(
                        (restriction) => restriction.length
                      ).length > 0)) && (
                    <Card
                      variant="outlined"
                      className={classes.errorContainer}
                      sx={{
                        position: "fixed",
                        bottom: hasMinimumOrderValueRestriction ? 62 : 42,
                        width: "100%",
                      }}
                    >
                      {isFieldAgent &&
                        cart.restrictions?.length != null &&
                        cart.restrictions?.length != 0 && (
                          <Typography variant={`body2`} textAlign={`center`}>
                            {_.map(
                              cart.restrictions.filter(
                                (restriction) => restriction.length
                              ),
                              (restriction, index) => {
                                return (
                                  <b key={`cart-restriction-${index}`}>
                                    {restriction}
                                  </b>
                                );
                              }
                            )}
                          </Typography>
                        )}
                      {!isFieldAgent &&
                        cart.saathiRestrictions?.length != null &&
                        cart.saathiRestrictions?.length != 0 && (
                          <Typography variant={`body2`} textAlign={`center`}>
                            {_.map(
                              cart.saathiRestrictions,
                              (restriction, index) => {
                                return (
                                  <b key={`cart-saathi-restriction-${index}`}>
                                    {restriction}
                                  </b>
                                );
                              }
                            )}
                          </Typography>
                        )}
                    </Card>
                  )}
                <Box
                  sx={{
                    height: hasMinimumOrderValueRestriction ? "40px" : "20px",
                  }}
                />

                <ErrorBoundary message={``}>
                  <PlaceRequest
                    handlePlaceRequest={handlePlaceRequest}
                    currentTab={currentTab}
                    cartProducts={cart.products}
                    canPlaceOrder={cart.canPlaceOrder}
                    isFieldAgent={isFieldAgent}
                    enabledSelfCheckout={
                      enabledSelfCheckout && !blockedProducts
                    }
                    hasMinimumOrderValueRestriction={
                      hasMinimumOrderValueRestriction
                    }
                    minimumOrderValue={minOrderValue}
                    isPaymentOverThreshold={isPaymentOverThreshold}
                    isLoading={loading === "started"}
                    cartHasError={cart?.hasError}
                  />
                </ErrorBoundary>
              </>
            ) : (
              loading === "done" &&
              (!cart || _.isEmpty(cart?.products)) && (
                <EmptyError
                  image="Empty Cart.svg"
                  text="Your Cart is empty!"
                  subText="Products added to cart will show up here"
                />
              )
            )}
            {loading === "started" && !cart && (
              <Skeleton variant="rectangular" height={600} />
            )}
          </form>
        </div>
      </BlockUi>
      <DebugDialog
        open={openDebug}
        debugMessage={debugMessage}
        handleCloseDebug={handleCloseDebug}
      />
      <UnverifiedPartnersDialog
        showError={showUnauthorizedError}
        setShowError={setShowUnauthorizedError}
        error={
          "You will get access to this feature once you become our Saathi partner"
        }
      />
      <RealCashBifurcationDialog
        open={showRealCashBifurcation}
        setOpen={setShowRealCashBifurcation}
        realCash={checkoutCarts[currentTab]?.agrostarWallet?.realCash}
      />
    </div>
  );
}

export default Cart;
