const CommonConstantValues = {
  NOT_STARTED: "not-started",
  STARTED: "started",
  DONE: "done",
  PAY_NOW_BLOCK_DURATION_AFTER_ORDER_PAYMENT_SUCCESS_MINUTES: 1,
  PAY_NOW_MAX_AMOUNT: 500000,
  RECENT_SEARCH_MAX_LIMIT: 20,
  MAX_FILE_SIZE_FOR_RETURN_ATTACHMENT: 10000000, //10 mb
  MAX_FILE_SIZE_FOR_COMPLAINT_ATTACHMENT: 10000000, //10 mb
  COMPLAINTS_ENABLED_STATES: "B2BMP",
  COMPLAINTS_ATTACHMENTS_MAX_FILES_LIMIT: 5,
  RETURNS_ATTACHMENTS_MAX_FILES_LIMIT: 5,
  ERROR_MESSAGE:
    "Apologies, it seems that an unexpected error occurred. Please try after some time.",
  ACCESS_PERIOD_IN_DAYS_FOR_INACTIVE_PARTNERS: 720,
  MAX_RETURN_QUANTITY: 2000,
  FIELD_AGENT_AUTO_LOGOUT_DURATION_HOURS: 168.0, // 7 days in hours
  MAX_PURCHASE_QUANTITY: 10000,
  CREDIT_NOTE_RESTRICTED_TYPES: ["Return", "Credit Note"],
  STORE_IMAGE_UPLOAD_ANDROID_VERSION: 21,
  STORE_IMAGE_APP_UPDATE_MESSAGE: "Please update the app to upload image",
  IPT_FORWARD_ORDER: "IPT_FORWARD_ORDER",
  IPT_RETURN_ORDER: "IPT_RETURN_ORDER",
  ANSWERED_QUIZ: "answered_quiz",
  LIKED_STORY: "liked_story",
  IMAGE_COMPRESSION_ENABLED_ANDROID_VERSION: 26,
  ALLOW_DOC_DOCX_DOWNLOAD_IN_WEBVIEW: 25,
  INVENTORY_SAME_AS_YESTERDAY_TIME: 1, // In days
  SIGN_AGREEMENT_ENABLED_ANDROID_VERSION: 29,
  DELIVERED: "DELIVERED",
  DELIVERED_AT_GODOWN: "DELIVERED_AT_GODOWN",
  OFFLINE_DISCOUNT_AT_STORE_ENABLED_SOURCES: [
    "B2BUP",
    "B2BCT",
    "B2BHR",
    "B2BBH",
  ],
};
export default CommonConstantValues;
