import TopBar from "../Common/TopBar";
import ErrorBoundary from "../Common/ErrorBoundary";
import { Typography, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SectionTitle from "../Common/SectionTitle";
import PromoteRequest from "./PromoteRequest";
import ProductDetails from "./ProductDetails";
import ShopDetails from "./ShopDetails";
import HisaabService from "../../Services/HisaabService";
import { useEffect, useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductService from "../../Services/ProductService";
import BlockUi from "react-block-ui";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  shopSection: {
    marginTop: theme.spacing(3),
  },
  promoteDescription: {
    color: "#333333",
    padding: "16px",
  },
}));
const PromoteWhatsapp = () => {
  const classes = useStyles();

  let [loading, setLoading] = useState("started");
  let [shopData, setShopData] = useState(null);
  let [productName, setProductName] = useState(null);
  let [productDetailObject, setProductDetailObject] = useState({});
  let skuCode = window.location.pathname.split("/")[2];
  useEffect(() => {
    const { axiosRequest, abortController } =
      ProductService.getTestimonialBySku(skuCode);
    axiosRequest
      .then((response) => {
        let testimonial = null;
        if (response?.data?.responseData?.additionalDetails) {
          let additionalDetails =
            response?.data?.responseData?.additionalDetails;
          _.forEach(additionalDetails, (detailItem) => {
            if (detailItem.value === "TESTIMONIALS") {
              if (detailItem.data?.media?.[0]?.url) {
                testimonial = detailItem.data?.media?.[0]?.url;
              }
            }
          });
        }

        setProductDetailObject({
          testimonial: testimonial,
          localName: response?.data?.responseData?.productName || "",
          brandName: response?.data?.responseData?.brand || "",
          localComposition:
            response?.data?.responseData?.details[0].attributes[0].value || "",
        });
        const { axiosRequest } = ProductService.getProductDetailsBySku(
          skuCode,
          localStorage.getItem("source")
        );
        axiosRequest
          .then((response) => {
            const promotePageViewedData = {
              page: `/products/${skuCode}/promote`,
              skuCode: skuCode,
              productName: response?.data?.responseData?.productName || "",
            };
            AnalyticsService.pushEvent(
              `Promote Page Viewed`,
              promotePageViewedData
            );
            setProductName(response?.data?.responseData?.productName);
            let variants = response?.data?.responseData?.variants;

            let differentVariant = "";
            if (variants.length > 0) {
              differentVariant = differentVariant + "Other Variants: ";
            }
            for (let i of variants) {
              differentVariant = differentVariant + i.label + ". ";
            }
            setProductDetailObject((previousState) => ({
              ...previousState,
              skuCode: response?.data?.responseData?.skuCode || "",
              image: response?.data?.responseData?.media[0]?.url || "",
              name: response?.data?.responseData?.productName || "",
              cartQuantity: response?.data?.responseData?.cartQuantity || "",
              composition:
                response?.data?.responseData?.details[0]?.attributes[0]
                  ?.value || "",
              differentVariant: differentVariant || "",
            }));

            const { axiosRequest } = HisaabService.getInstitutionDetails();
            axiosRequest
              .then((response) => {
                setShopData(response?.data?.responseData);
                console.log(response?.data?.responseData);
                setLoading("done");
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      })
      .catch((error) => {});
    return () => abortController.abort();
  }, []);

  return (
    <div>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Promote`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
          toolTip={``}
        />

        <BlockUi
          tag="div"
          blocking={loading === "started"}
          message={`Loading...`}
          style={{ minHeight: "80vh" }}
          keepInView
        >
          {loading === "done" ? (
            <div>
              <Typography
                variant="body2"
                className={classes.promoteDescription}
              >
                Promote this Product on WhatsApp with farmers
              </Typography>
              <Container maxWidth={"xl"}>
                <SectionTitle title={`Product Details`} />
              </Container>
              <ErrorBoundary message={``}>
                <ProductDetails productData={productDetailObject} />
              </ErrorBoundary>
              <ErrorBoundary message={""}>
                <Container maxWidth={"xl"} className={classes.shopSection}>
                  <SectionTitle title={`Shop Details`} />
                </Container>
                <ShopDetails shopData={shopData} />
              </ErrorBoundary>
              <ErrorBoundary>
                <PromoteRequest
                  details={productDetailObject}
                  shopData={shopData}
                  productName={productName}
                />
              </ErrorBoundary>
            </div>
          ) : (
            ""
          )}
        </BlockUi>
      </ErrorBoundary>
    </div>
  );
};

export default PromoteWhatsapp;
