import _ from "lodash";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Divider from "@mui/material/Divider";
import moment from "moment";
import { Skeleton } from "@mui/material";
import ReferralService from "../../Services/ReferralService";

const useStyles = makeStyles((theme) => ({
  head: {
    fontSize: "18px",
    padding: "15px",
    fontWeight: "bold",
  },
  name: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  details: {
    fontSize: "14px",
    padding: "15px",
    paddingTop: "0px",
    opacity: 0.5,
    marginTop: "-18px",
  },
  note: {
    fontStyle: "italic",
    fontSize: "11px",
    padding: "15px",
    paddingTop: "0px",
    opacity: 0.5,
  },
  cardContent: {
    paddingBottom: "0.625rem !important",
    paddingLeft: "0px",
    paddingTop: "0px",
  },
  card: {
    marginTop: "20px",
    marginBottom: "0px",
  },
  container: {
    marginBottom: "80px",
  },
  referralList: {
    marginLeft: "10px",
    marginTop: "-10px",
    marginBottom: "5px",
  },
}));

const SuccessfulReferral = ({ successfulReferredUsers, loading }) => {
  const referralDetailStyle = {
    "REGISTRATION DONE": {
      backgroundColor: "#ebd3d3",
      fontColor: "#9e2123",
      icon: "/icons/referralRegistrationDone.svg",
    },
    "DEPOSIT PAID": {
      backgroundColor: "#efe4cd",
      fontColor: "#a56e00",
      icon: "/icons/referralDepositPaid.svg",
    },
    "FIRST PURCHASE DONE": {
      backgroundColor: "#dbe9fe",
      fontColor: "#225bb1",
      icon: "/icons/referralFirstPurchaseDone.svg",
    },
    "REWARD UNLOCKED": {
      backgroundColor: "#cde2d8",
      fontColor: "#01733e",
      icon: "/icons/referralRewardUnlocked.svg",
    },
  };

  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      {loading === "started" ? (
        _.map([1, 2, 3], (element) => {
          return (
            <Box key={element} sx={{ marginBottom: "8px" }}>
              <Skeleton height={100} variant="rounded" />
            </Box>
          );
        })
      ) : (
        <>
          <Box sx={{ padding: "16px", marginBottom: "8px" }}>
            {successfulReferredUsers?.length > 0 ? (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Successful Referrals
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ color: "grey" }}
                >
                  Below is the list of your referred friends who
                  <b> Onboarded Successfully</b> as a Saathi Partner
                </Typography>
              </>
            ) : (
              <Typography
                variant="subtitle2"
                component="p"
                sx={{ fontSize: "16px", fontWeight: "bold" }}
              >
                No successful referrals yet.
              </Typography>
            )}
          </Box>
          <Divider />
          {_.map(successfulReferredUsers, (referredUser, index) => {
            return (
              <Box key={index}>
                <Box key={index} sx={{ padding: "12px 12px 0px 12px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      component="p"
                      sx={{ fontWeight: "bold" }}
                    >
                      {referredUser?.farmerName}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      sx={{ color: "grey" }}
                    >
                      {moment(referredUser?.referredOn).format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="p"
                      sx={{ color: "grey" }}
                    >
                      +91 {referredUser?.referredFarmerMobile}
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor:
                          referralDetailStyle[referredUser?.status]
                            ?.backgroundColor,
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        padding: "6px 6px",
                        borderRadius: "4px",
                      }}
                    >
                      <img
                        src={referralDetailStyle[referredUser?.status]?.icon}
                        alt=""
                        height={24}
                        width={24}
                      />
                      <Typography
                        variant="body2"
                        component="p"
                        sx={{
                          color:
                            referralDetailStyle[referredUser?.status]
                              ?.fontColor,
                          fontWeight: 500,
                        }}
                      >
                        {ReferralService.ReferralStatus[referredUser?.status]}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default SuccessfulReferral;
