import React, { useCallback, useEffect, useState } from "react";
import { Button, Box, Modal, Typography } from "@mui/material";
import UiSearch from "../Common/UiSearch";
import TopBar from "../Common/TopBar";
import _ from "lodash";
import ProductContainer from "./ProductContainer";
import BlockUi from "react-block-ui";
import { makeStyles } from "@mui/styles";
import InterPartyTransfersService from "../../Services/InterPartyTransfersService";
import useQuery from "../../Hooks/useQuery";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import Toast from "../Common/Toast";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  blockUiContainer: {
    minHeight: `40vh`,
    width: "100%",
    marginBottom: "40px",
  },
  doneButton: {
    position: "fixed",
    border: "0px",
    bottom: "0",
    borderRadius: "0",
  },
  noProductScannedMessage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
    color: "#828282",
    fontWeight: 400,
    padding: "5px 16px",
  },
  modalBox: {
    position: "absolute",
    left: "50%",
    bottom: "50%",
    width: "95%",
    padding: "16px 24px",
    border: "none",
    textAlign: "center",
    background: "#fff",
    borderRadius: "8px",
    transform: "translate(-50%, -10%)",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  modalText: {
    margin: "20px 0",
  },
});

export default function ManuallyAddRemoveProductsPage() {
  const classes = useStyles();
  const queryParams = useQuery();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");

  const cartId = queryParams.get("cartId");

  const fetchProductsBasedOnSearchValue = (queriedValue) => {
    setLoading("started");
    const { axiosRequest } =
      InterPartyTransfersService.fetchProductsFromSearchQuery(
        queriedValue,
        cartId
      );
    axiosRequest
      .then((response) => {
        setLoading("done");
        if (response?.data?.responseData) {
          setProducts(response.data.responseData.products);
          if (response.data.responseData.products.length === 0) {
            showToastAlert("No Products Found!");
          }
        }
      })
      .catch((error) => {
        setLoading("done");
        showToastAlert("Something went wrong");
      });
  };

  const setSearchValueDebounce = (queriedValue) => {
    setSearchValue(queriedValue);
    setSearchValueForQueryDebounce(queriedValue);
  };

  const setSearchValueForQueryDebounce = useCallback(
    _.debounce((queriedValue) => {
      if (queriedValue?.length >= 4) {
        fetchProductsBasedOnSearchValue(queriedValue);
      } else {
        setProducts([]);
      }
    }, 1000),
    []
  );

  const addOrRemoveButtonClickedOfProduct = (product) => {
    let eventName = product.isAddedToCart ? "Remove" : "Add";
    let action = product.isAddedToCart ? "REMOVE_BARCODE" : "ADD_BARCODE";
    AnalyticsService.pushEvent(`Button Clicked`, {
      type: `${eventName}`,
      page: `Manually Add Remove Products Page`,
    });
    setLoading("started");

    const { axiosRequest } = InterPartyTransfersService.updateIPTCart(
      cartId,
      action,
      product.code
    );
    axiosRequest
      .then((response) => {
        setLoading("done");
        if (response.data.responseData?.invalidBarcodes?.[0]?.reason) {
          showToastAlert(
            response.data.responseData?.invalidBarcodes?.[0]?.reason
          );
        } else {
          updateProductStatus(product);
          showToastAlert(
            `Product ${
              action === "REMOVE_BARCODE" ? "Removed" : "Added"
            } Successfully`
          );
        }
      })
      .catch((error) => {
        setLoading("done");
        showToastAlert("Something went wrong");
      });
  };

  const updateProductStatus = (productToBeUpdated) => {
    setProducts((prevState) => {
      return _.map(prevState, (product, index) => {
        return product.skuCode === productToBeUpdated.skuCode
          ? {
              ...product,
              isAddedToCart: !productToBeUpdated.isAddedToCart,
            }
          : product;
      });
    });
  };

  const onDoneButtonClicked = () => {
    AnalyticsService.pushEvent(`Button Clicked`, {
      type: "Done",
      page: "Manually Add Remove Products Page",
    });

    navigate(
      `/inter-party-transfers/scanned-products?iptRequestId=${queryParams.get(
        `iptRequestId`
      )}&cartId=${queryParams.get(`cartId`)}`,
      { replace: true }
    );
  };

  const showToastAlert = (alertText) => {
    setShowModal(true);
    setModalText(alertText);
  };

  const handleModalButtonClicked = () => {
    setShowModal(false);
    setModalText("");
  };

  return (
    <>
      <TopBar title={`Search Product`} backNavigation={true} />
      <BlockUi
        className={classes.blockUiContainer}
        tag="div"
        blocking={loading === "started"}
        message="Looking for products..."
      >
        <ErrorBoundary message={``}>
          <UiSearch
            setSearchValue={setSearchValueDebounce}
            searchValue={searchValue}
            shouldBlockSendSearchQuerySentEvent={true}
            shouldKeepDefaultBackgroundColor={true}
            placeholderText="Enter last 4 digits of barcode"
          />
        </ErrorBoundary>
        {products.length > 0 ? (
          <>
            {_.map(products, (product, index) => {
              return (
                <ProductContainer
                  key={product.skuCode}
                  product={product}
                  addOrRemoveButtonClicked={addOrRemoveButtonClickedOfProduct}
                />
              );
            })}
            <Button
              fullWidth
              variant="contained"
              className={classes.doneButton}
              onClick={() => {
                onDoneButtonClicked();
              }}
            >
              Done
            </Button>
          </>
        ) : (
          <Typography
            variant="subtitle1"
            className={classes.noProductScannedMessage}
          >
            Type above to search product!
          </Typography>
        )}
      </BlockUi>
      {showModal && modalText && (
        <Modal open={showModal} onClose={() => {}}>
          <Box className={classes.modalBox}>
            <Typography className={classes.modalText}>{modalText}</Typography>
            <Button
              variant={`contained`}
              onClick={() => {
                handleModalButtonClicked();
              }}
            >
              Okay
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
}
