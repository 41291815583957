import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import useQuery from "../../Hooks/useQuery";
import _ from "lodash";
import OrderPlacedDetailCard from "./OrderPlacedDetailCard";
import AmountUtils from "../../Utils/AmountUtils";
import { format } from "date-fns";
import AndroidService from "../../Services/AndroidService";
import { WhatsappShareButton } from "react-share";
import ProductService from "../../Services/ProductService";

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: "#ffffff", minHeight: window.innerHeight },
  successIconWrapper: {
    position: "relative",
    marginTop: theme.spacing(8),
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  successIcon: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    position: "absolute",
    top: 0,
    left: 0,
  },
  successIconCongrats: {
    animation: "$hideAfterSingleRun 1.83s forwards",
    width: theme.spacing(16),
    height: theme.spacing(16),
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    transform: "scale(2.5)",
  },
  "@keyframes hideAfterSingleRun": {
    "0%": {
      opacity: 0,
    },
    "1%": {
      opacity: 1,
    },
    "99%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  okayButtonWrapper: {
    marginTop: theme.spacing(20),
  },
  okayButton: {
    borderRadius: theme.spacing(1.25),
  },
  successMessage: {
    fontWeight: "700",
    lineHeight: `34px`,
    fontSize: "18px",
    color: "#000000",
  },
  successMessageSubText: {
    fontWeight: "400",
    lineHeight: `27px`,
    fontSize: "14px",
    color: "#000000",
  },
  successMessageWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  buttonStyle: {
    borderRadius: "0",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "0.56px",
    textTransform: "uppercase",
  },
  billAmount: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    margin: "0px 8px",
    background: "#777777",
    opacity: "70%",
    borderRadius: "9px",
    fontSize: "14px",
    fontWeight: 400,
  },
  fixedShareIcon: {
    width: "75px",
    height: "26px",
    position: "fixed",
    display: "flex",
    right: "15px",
    top: "20px",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    borderRadius: "16px",
    background: "#9D2123 !important",
    boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
    padding: "5px 10px 5px 14px",
    color: "white !important",
    fontWeight: "700 !important",
    fontSize: "12px !important",
    textTransform: "none",
    gap: "4px",
  },
}));

function OrderSuccessful() {
  const classes = useStyles();
  let navigate = useNavigate();
  const query = useQuery();
  const redirect = query.get("redirect");

  useEffect(() => {
    AnalyticsService.pushEvent(`Success Page Viewed`, {
      productsName: order?.eventData?.productName,
      appliedOffersName: order?.eventData?.appliedOffersName,
      appliedOffersID: order?.eventData?.appliedOffersId,
      quantities: order?.eventData?.quantities,
      skuCode: order?.eventData?.skuCode,
      productPrice: order?.eventData?.sellingPrices,
      totalBillAmount: order?.eventData?.totalOrderValue,
      couponName: order?.eventData?.couponName,
      requestType: "Order",
      orderIDs: `${order?.eventData?.orderIds}`,
      cartId: order?.eventData?.cartId,
    });
  }, []);

  const handleOkayClick = () => {
    AnalyticsService.pushEvent(`Go To Cart Button Clicked`, {
      productsName: order?.eventData?.productName,
      appliedOffersName: order?.eventData?.appliedOffersName,
      appliedOffersID: order?.eventData?.appliedOffersId,
      quantities: order?.eventData?.quantities,
      skuCode: order?.eventData?.skuCode,
      productPrice: order?.eventData?.sellingPrices,
      totalBillAmount: order?.eventData?.totalOrderValue,
      couponName: order?.eventData?.couponName,
      requestType: "Order",
      orderIDs: `${order?.eventData?.orderIds}`,
      cartId: order?.eventData?.cartId,
    });
    localStorage.removeItem("order");
    if (redirect === "cart") {
      navigate(`/cart`, { replace: true });
    } else {
      sessionStorage.removeItem("scrollYPosition");
      navigate({
        pathname: "/dashboard",
      });
    }
  };

  const handleHomepageClick = () => {
    AnalyticsService.pushEvent(`Go To Dashboard Button Clicked`, {
      productsName: order?.eventData?.productName,
      appliedOffersName: order?.eventData?.appliedOffersName,
      appliedOffersID: order?.eventData?.appliedOffersId,
      quantities: order?.eventData?.quantities,
      skuCode: order?.eventData?.skuCode,
      productPrice: order?.eventData?.sellingPrices,
      totalBillAmount: order?.eventData?.totalOrderValue,
      couponName: order?.eventData?.couponName,
      requestType: "Order",
      orderIDs: `${order?.eventData?.orderIds}`,
      cartId: order?.eventData?.cartId,
    });
    localStorage.removeItem("order");
    sessionStorage.removeItem("scrollYPosition");
    navigate(`/dashboard`);
  };

  const order = localStorage.getItem("order")
    ? JSON.parse(localStorage.getItem("order"))
    : null;

  if (order === null) {
    sessionStorage.removeItem("scrollYPosition");
    navigate({
      pathname: "/dashboard",
    });
  }

  if (order && !order.paid) {
    order.paid = true;
    localStorage.setItem("order", JSON.stringify(order));
  }
  const partnerName = localStorage.getItem("partnerName");
  const partnerId = localStorage.getItem("farmerId");
  const totalOrderValue = AmountUtils.format(order?.totalValue);
  const orderId = order?.placedOrders[0]?.orderId;
  const estimatedDeliveryDate = order?.placedOrders[0]?.deliveryData
    ?.deliveryDate
    ? format(
        new Date(order?.placedOrders[0]?.deliveryData?.deliveryDate),
        "dd-MMM-yyyy"
      )
    : ` `;
  const maxProductsToShowOnImageOverlay = 15;
  const maxCharactersInALine = 38;
  const productsOrdered = order?.placedOrders[0]?.products.slice(
    0,
    maxProductsToShowOnImageOverlay
  );
  var yOffset = 580;
  const overlayProductFontSize = 22;
  const xOffset = 62;
  const yOffsetBetweenTwoProducts = 30;

  let productsOverlayUrl = ProductService.getProductsOverlayUrl(
    xOffset,
    yOffset,
    yOffsetBetweenTwoProducts,
    productsOrdered,
    maxCharactersInALine,
    maxProductsToShowOnImageOverlay,
    overlayProductFontSize
  );

  const shareUrl = `https://static.agrostar.in/saathi-order-share/tr:f-jpg,l-text,i-${orderId},fs-24,lx-169,ly-506,ff-fonts@@NotoSans-Bold.ttf,l-end:l-text,i-Rs.${totalOrderValue},fs-24,lx-405,ly-506,ff-fonts@@NotoSans-Bold.ttf,l-end:l-text,i-${estimatedDeliveryDate},fs-24,lx-390,ly-546,ff-fonts@@NotoSans-Bold.ttf,l-end:l-text,i-Rs.${totalOrderValue},fs-24,tg-b,lx-400,ly-1221,co-white,ff-fonts@@NotoSans-Bold.ttf,l-end:${productsOverlayUrl}/order-share.jpg`;
  const orderDetails = `Order ${orderId} - ${partnerName} (${partnerId})`;
  const handleAndroidShareClickEvent = () => {
    handleWhatsAppShareClickEvent();
    AndroidService.shareOnWhatsApp(`${orderDetails}`, shareUrl);
  };

  const handleWhatsAppShareClickEvent = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "WhatsApp Share",
      imageUrl: shareUrl,
    });
  };

  return (
    <div className={classes.root}>
      {AndroidService.checkIfAndroid() ? (
        <Button
          variant="contained"
          className={classes.fixedShareIcon}
          startIcon={<img src={`/icons/share.svg`} alt="" />}
          onClick={handleAndroidShareClickEvent}
          sx={{
            gap: "0px !important",
            "& .MuiButton-startIcon": {
              marginRight: "4px",
            },
          }}
        >
          Share
        </Button>
      ) : (
        <WhatsappShareButton
          className={classes.fixedShareIcon}
          url={shareUrl}
          title={orderDetails}
          onClick={handleWhatsAppShareClickEvent}
        >
          <img src={`/icons/share.svg`} alt="" />
          Share
        </WhatsappShareButton>
      )}
      <ErrorBoundary message={``}>
        <Box
          sx={{
            height: "92vh",
            maxHeight: "92vh",
            overflow: "scroll",
            paddingBottom: "40px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div className={classes.successIconWrapper}>
              <img
                src={`/icons/congratulations.gif`}
                className={classes.successIconCongrats}
                alt=""
              />
              <img
                src={`/icons/success.svg`}
                className={classes.successIcon}
                alt=""
              />
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.successMessageWrapper}
          >
            <Grid item xs={12}>
              <Typography
                align={"center"}
                variant={`body1`}
                color={`secondary`}
                className={classes.successMessage}
              >
                Congratulations !! <br />
              </Typography>
              <Typography
                align={"center"}
                variant={`body1`}
                color={`secondary`}
                className={classes.successMessageSubText}
              >
                We have received your order successfully.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            {order &&
              _.map(order?.placedOrders || [], (order, index) => {
                return (
                  <Container
                    key={`order-details-${index}`}
                    maxWidth={`xl`}
                    sx={{ marginBottom: "16px" }}
                  >
                    <OrderPlacedDetailCard
                      order={order}
                      onOrderClicked={() => {
                        const orderDetailsClickedEventData = {
                          orderState: order?.status || "",
                          estimatedDeliveryDate:
                            format(
                              order?.deliveryData?.deliveryDate,
                              "dd MMM yyyy"
                            ) || "",
                          productNames:
                            order?.products
                              .map((p) => p.productName)
                              .toString() || "",
                          orderId: order?.orderId || "",
                          numberOfProducts: order?.products.length || 0,
                          totalOrderValue:
                            order?.paymentDetails?.orderValue || 0,
                          requestType: "Order",
                        };
                        AnalyticsService.pushEvent(
                          `Order Details Clicked`,
                          orderDetailsClickedEventData
                        );
                        navigate({
                          pathname: `/orders`,
                        });
                      }}
                    />
                  </Container>
                );
              })}
          </Grid>
        </Box>

        <Box
          sx={{
            height: "8vh",
            display: "flex",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
        >
          {query.get("redirect") === "cart" && (
            <Button
              variant={"outlined"}
              fullWidth={true}
              className={classes.buttonStyle}
              sx={{
                background: "#FFF",
                "&:focus": {
                  backgroundColor: `#F5F5F5`,
                },
                "&:hover": {
                  backgroundColor: `#F5F5F5`,
                },
              }}
              onClick={handleOkayClick}
            >
              Go To Cart
            </Button>
          )}
          <Button
            variant={"contained"}
            fullWidth={true}
            className={classes.buttonStyle}
            onClick={handleHomepageClick}
          >
            Go To Homepage
          </Button>
          <Box
            sx={{
              height: "20px",
              position: "absolute",
              top: "-50px",
              left: 0,
              color: "white",
              width: "100%",
            }}
          >
            <Box className={classes.billAmount}>
              <span>Total Bill Amount: </span>
              <span>
                <b>₹ {AmountUtils.format(order?.totalValue)}</b>
              </span>
            </Box>
          </Box>
        </Box>
      </ErrorBoundary>
    </div>
  );
}

export default OrderSuccessful;
