import { Box, Button } from "@mui/material";
import AnalyticsService from "../../Services/AnalyticsService";
import StoryShareButton from "./StoryShareButton";
import StoryLikeButton from "./StoryLikeButton";
import StoryShareButtonNew from "./StoryShareButtonNew";

const customSeeMore = {
  textAlign: "center",
  position: "relative",
  background: "transparent",
  margin: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const StoryFooterElement = (navigate, story, setIsPaused) => {
  const { ctaText, ctaLink, webLink } = story;
  return ({ toggleMore, action }) => (
    <Box style={customSeeMore}>
      {ctaText && (
        <Button
          variant="contained"
          color="secondary"
          size={"small"}
          sx={{ width: 145, height: 40, marginBottom: "25px" }}
          onClick={() => {
            // toggleMore(true);
            // setIsPaused(true);
            const staticBannerClickedEventData = {
              section: story.section,
              name: story.name,
              imageUrl: story.imageUrl,
              position: story.position,
              subPosition: story.subPosition,
            };
            AnalyticsService.pushEvent(
              `Redirection Link Clicked`,
              staticBannerClickedEventData
            );
            if (ctaLink) navigate(ctaLink);
            if (webLink) window.open(webLink, "_blank").focus();
          }}
        >
          {ctaText}
        </Button>
      )}
      <StoryLikeButton story={story} action={action} />
      <StoryShareButtonNew story={story} action={action} />
      <StoryShareButton story={story} setStoryPaused={setIsPaused} />
    </Box>
  );
};

export const StoryFooterComponentExpanded = () => {
  return ({ close }) => <Box onClick={close}></Box>;
};
