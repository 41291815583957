import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { Tab, Tabs } from "@mui/material";

import TopBar from "../Common/TopBar";
import NewOrders from "./NewOrders";
import StoreInventory from "./StoreInventory";
import AnalyticsService from "../../Services/AnalyticsService";
import BusinessInsightsPage from "../BusinessInsights/BusinessInsightsPage";
import StoreOrders from "./StoreOrders";
import CommonConstantValues from "../../Services/CommonConstantValues";

const useStyles = makeStyles({
  pageWrapper: { minHeight: "100vh", background: "#FFF" },
  tabs: {
    margin: "18px 2px 0",
    borderRadius: "16px",
    background: "#FFF",
    boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
  },
  tab: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#333333",
    padding: "12px 7px",
    textTransform: "capitalize",
  },
  active: { fontWeight: 700 },
});

export default function StoreManagerPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let storeManagerTabs;

  const institutionDetailsFromLocalStorage =
    localStorage.getItem(`InstitutionDetails`);
  const institutionDetails = JSON.parse(institutionDetailsFromLocalStorage);
  const shouldShowAllStoreManagerTabs =
    institutionDetails?.isDeliveryViaStoreEnabled ||
    !CommonConstantValues.OFFLINE_DISCOUNT_AT_STORE_ENABLED_SOURCES.includes(
      localStorage.getItem("source")
    );

  if (shouldShowAllStoreManagerTabs) {
    storeManagerTabs = [
      { name: "Inventory", path: "/store-manager/store-inventory" },
      { name: "Online Orders", path: "/store-manager" },
      { name: "Store Orders", path: "/store-manager/store-orders" },
      { name: "Business Insights", path: "/store-manager/business-insights" },
    ];
  } else {
    storeManagerTabs = [
      { name: "Inventory", path: "/store-manager/store-inventory" },
      { name: "Store Orders", path: "/store-manager/store-orders" },
    ];
  }

  const handleTabClick = (tab) => {
    AnalyticsService.pushEvent("My Catalog Tab Clicked", {
      tabName: tab.name,
    });
    navigate(tab.path, { replace: true });
  };

  return (
    <div className={classes.pageWrapper}>
      <TopBar title={`My Catalog`} backNavigation={true} />
      {shouldShowAllStoreManagerTabs ? (
        <>
          <Tabs value={pathname} className={classes.tabs}>
            {storeManagerTabs?.map((storeManagerTab, index) => (
              <Tab
                key={index}
                label={storeManagerTab.name}
                value={storeManagerTab.path}
                className={`${classes.tab} ${
                  pathname === storeManagerTab.path && classes.active
                }`}
                sx={{
                  width: "20%",
                }}
                onClick={() => handleTabClick(storeManagerTab)}
              />
            ))}
          </Tabs>
          {pathname === storeManagerTabs[0].path && <StoreInventory />}
          {pathname === storeManagerTabs[1].path && <NewOrders />}
          {pathname === storeManagerTabs[2].path && <StoreOrders />}
          {pathname === storeManagerTabs[3].path && <BusinessInsightsPage />}
          {pathname === "/store-manager/shipped-orders" && <NewOrders />}
        </>
      ) : (
        <>
          <Tabs value={pathname} className={classes.tabs}>
            {storeManagerTabs?.map((storeManagerTab, index) => (
              <Tab
                key={index}
                label={storeManagerTab.name}
                value={storeManagerTab.path}
                className={`${classes.tab} ${
                  pathname === storeManagerTab.path && classes.active
                }`}
                sx={{
                  width: "50%",
                }}
                onClick={() => handleTabClick(storeManagerTab)}
              />
            ))}
          </Tabs>
          {pathname === storeManagerTabs[0].path && <StoreInventory />}
          {pathname === storeManagerTabs[1].path && <StoreOrders />}
        </>
      )}
    </div>
  );
}
