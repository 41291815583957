import React from "react";
import useBuyItAgain from "../../Hooks/useBuyItAgain";
import HorizontalProductCardContainer from "../Common/HorizontalProductCardContainer";

function BuyItAgain(props) {
  const { products, loading, error } = useBuyItAgain();

  return (
    <HorizontalProductCardContainer
      products={products}
      loading={loading}
      error={error}
      title={`Buy it Again`}
      viewAllUrl={`/buy-it-again`}
      numberOfProductsToShow={props.numberOfProductsToShow}
      stateCode={props.stateCode}
      backgroundColor={"rgba(157, 33, 35, 0.1)"}
      saveScrollYPosition={true}
    />
  );
}

export default BuyItAgain;
