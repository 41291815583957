import { Container, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import useNearbyPartnerPurchase from "../../Hooks/useNearbyPartnerPurchase";
import usePopularSearchProducts from "../../Hooks/usePopularSearchProducts";
import SectionTitle from "../Common/SectionTitle";
import ProductCard from "../Product/ProductCard";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    marginTop: theme.spacing(2),
  },
  loaderContent: {},
  mainContent: {
    backgroundColor: "#F3F3F3",
  },
  loaderWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
}));

function PopularSearchProducts(props) {
  const { products, loading, error } = usePopularSearchProducts();
  return (
    <div>
      {loading === "done" && (!error || !error?.data) && products && (
        <Container maxWidth={`xl`}>
          <SectionTitle
            title={`Popular Searches in ${localStorage.getItem("district")}`}
          />
          <div style={{ marginTop: "10px" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              {_.map(
                products.slice(
                  0,
                  props.numberOfProductsToShow
                    ? props.numberOfProductsToShow
                    : products.length
                ),
                (product, index) => {
                  return (
                    <Grid item xs={6} key={index}>
                      <ProductCard
                        product={product}
                        key={product.skuCode}
                        index={index}
                        section={"Popular Search Products"}
                        stateCode={props.stateCodeÒ}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          </div>
        </Container>
      )}

      {loading === "started" && (
        <Container maxWidth={`xl`}>
          <Skeleton variant={`text`} style={{ margin: "8px 0px" }} />
          <Grid
            container
            direction={`row`}
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={6}>
              <Skeleton
                variant={`rectangular`}
                height={250}
                sx={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant={`rectangular`}
                height={250}
                sx={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant={`rectangular`}
                height={250}
                sx={{ borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant={`rectangular`}
                height={250}
                sx={{ borderRadius: "10px" }}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}

export default PopularSearchProducts;
