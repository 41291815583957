import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

import format from "date-fns/format";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import Divider from "@mui/material/Divider";
import { parseInt } from "lodash";
import { useNavigate } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import AnalyticsService from "../../Services/AnalyticsService";
import HisaabService from "../../Services/HisaabService";
import PartnerService from "../../Services/PartnerService";
import BackDropLoader from "../Common/BackDropLoader";
import ErrorBoundary from "../Common/ErrorBoundary";
import SectionTitle from "../Common/SectionTitle";
import PayAdvanceDialog from "./PayAdvanceDialog";
import PayNowDialog from "./PayNowDialog";
import TransactionDetailsCards from "./TransactionDetailsCard";
import config from "../../config";
import SentryService from "../../Services/SentryService";
import AmountUtils from "../../Utils/AmountUtils";
import CreditLimitDetails from "./CreditLimitDetails";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 2),
    marginBottom: "260px",
    paddingBottom: "160px",
  },
  card: {
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.spacing(1.2),
    border: "none",
    fontSize: "12px",
    lineHeight: "16px",
  },
  infoText: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    color: "#000000",
    opacity: "0.6",
  },
  info: {
    margin: theme.spacing(1.5, 0),
    display: "flex",
    alignItems: "center",
    color: "#00733E",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sectionName: {
    width: "70%",
  },
  sectionValue: {
    textAlign: "left",
    width: "100%",
  },
  balanceCards: {
    margin: theme.spacing(1.5, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 2),
    border: "1px solid white",
    fontSize: "12px",
    lineHeight: "16px",
  },
  creditSection: {
    padding: theme.spacing(0, 0, 1, 1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  creditLineTitle: {
    marginTop: "5px",
    marginBottom: "8px",
    fontWeight: "600",
    lineHeight: "1.3",
  },
  availableCredit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
    paddingTop: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1.2),
    borderBottomLeftRadius: theme.spacing(1.2),
  },
  sectionTitle: {
    padding: theme.spacing(1, 0, 0, 1),
  },
  ocpSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 1),
    borderBottom: "1px solid #f3f3f3",
    marginTop: theme.spacing(1),
  },
  wcpSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 1),
    // backgroundColor: "rgba(157, 33, 35, 0.1)",
    marginTop: "12px",
  },
  ocpValue: {
    // display: "flex",
    fontSize: "12px",
    color: "#9D2123",
  },
  wcpValue: {
    // display: "flex",
    fontSize: "12px",
    color: "#00733E",
  },
  ocpDetailsSection: {
    padding: theme.spacing(1.5, 2),
    background: "#F3F3F3",
  },
  wcpDetailsSection: {
    padding: theme.spacing(1.5, 0, 2.5, 0),
    background: "#F3F3F3",
    marginLeft: "-16px",
    marginRight: "-16px",
  },
  subSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.5, 0, 1, 0),
  },
  subText: {
    color: "gray",
    fontSize: "10px",
  },
  btn: {
    width: "min-content",
    fontSize: "smaller",
  },
  offersIcon: {
    display: "flex",
    paddingLeft: "2px",
  },
}));

const enablePayNowForFinBox = false;

const Hisaab = (props) => {
  const classes = useStyles();
  const query = useQuery();
  const directPayment = query.get("payment");
  let navigate = useNavigate();
  const paymentType = query.get("type");
  const amountTypeFromParams = query.get("amount-type");
  const amountFromParams = query.get("amount");
  const reasonFromParams = query.get("reason");

  const [payNowPaymentAmount, setPayNowPaymentAmount] = useState(0);
  const [lendingPartnerDueAmount, setLendingPartnerDueAmount] = useState(0);
  const [institutionDetails, setInstitutionDetails] = useState({});
  const [creditDetails, setCreditDetails] = useState({});
  const [creditLine, setCreditLine] = useState(null);
  const [showPayNowDialog, setShowPayNowDialog] = useState(false);
  const [paymentErrorCode, setPaymentErrorCode] = useState(null);

  const [showPayAdvanceDialog, setShowPayAdvanceDialog] = useState(false);

  const [showOutsideCreditPeriodDetails, setShowOutsideCreditPeriodDetails] =
    useState(false);
  const [showWithinCreditPeriodDetails, setShowWithinCreditPeriodDetails] =
    useState(false);
  const [loading, setLoading] = useState("not_started");
  const [paymentLoading, setPaymentLoading] = useState("not_started");
  const [openDialog, setOpenDialog] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [transactionList, setTransactionList] = useState({});
  const [paymentResponseData, setPaymentResponseData] = useState({});
  const AGROSTAR = "AGROSTAR";
  const RUPIFI = "RUPIFI";
  const [lendingProvider, setLendingProvider] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [loadingWalletBreakup, setLoadingWalletBreakup] =
    useState("not_started");

  const isPayNowEnabled = () => {
    let enabledFlag = true;
    if (localStorage.getItem("creditLine")) {
      if (
        localStorage.getItem(`creditLine`)?.toLowerCase() === "finbox" &&
        !enablePayNowForFinBox
      ) {
        enabledFlag = false;
      } else {
        enabledFlag = true;
      }
    } else {
      enabledFlag = true;
    }
    return enabledFlag;
  };
  const handleClosePayNow = () => {
    setShowPayNowDialog(false);
  };
  const handleClosePayAdvance = () => {
    setShowPayAdvanceDialog(false);
  };

  const handlePaymentStatus = (paymentId) => {
    navigate(`/hisaab/payment-status/${paymentId}`);
  };
  const handleRazorpayFailure = (error, errorCode = null) => {
    const urlParams = errorCode ? `?errorCode=${errorCode}` : "";
    navigate(`/hisaab/razorpay-failure/${error}${urlParams}`);
  };

  useEffect(() => {
    if (!userId || !localStorage.getItem("userId")) {
      setLoading("started");
      const { axiosRequest, abortController } = PartnerService.checkPartnerData(
        localStorage.getItem(`farmerId`)
      );
      axiosRequest
        .then((response) => {
          localStorage.setItem("userId", response.data.responseData.userId);
          setUserId(response?.data?.responseData?.userId);
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting farmer details`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    }
  }, [userId]);

  useEffect(() => {
    localStorage.setItem("InstitutionDetails", "");

    setLoading("started");
    setLoadingWalletBreakup("started");
    const hissabTabEventData = {
      body: {
        page: "hisaab",
      },
      name: "Hisaab Tab Viewed",
    };

    AnalyticsService.pushEvent(
      hissabTabEventData.name,
      hissabTabEventData.body
    );

    const { axiosRequest, abortController } =
      HisaabService.getInstitutionDetails();
    axiosRequest
      .then((response) => {
        let institutionalDetailsData = response?.data?.responseData;
        setInstitutionDetails(institutionalDetailsData);
        if (institutionalDetailsData.lendingProvider) {
          setLendingProvider(
            institutionalDetailsData.lendingProvider?.toUpperCase()
          );
        }
        localStorage.setItem(
          "InstitutionDetails",
          JSON.stringify(institutionalDetailsData)
        );

        localStorage.setItem(
          `creditPeriod`,
          institutionalDetailsData.creditPeriod
        );

        if (
          institutionalDetailsData?.inactivatedOnDate ||
          institutionalDetailsData?.inactivatedOnDate === 0
        ) {
          localStorage.setItem(
            "inactivatedOnDate",
            institutionalDetailsData?.inactivatedOnDate
          );
        }
        if (institutionalDetailsData?.lendingProvider) {
          localStorage.setItem(
            "lendingProvider",
            institutionalDetailsData?.lendingProvider
          );
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting institution details`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoading("done");
        }
      });

    const {
      axiosRequest: axiosRequestWallet,
      abortController: abortControllerWallet,
    } = HisaabService.getWalletBreakup();
    axiosRequestWallet
      .then((response) => {
        if (response?.data?.responseData) {
          let responseData = response.data.responseData;
          setCreditDetails(responseData);
          if (responseData.creditDetails) {
            let creditDetailsData = responseData.creditDetails;
            let amountOutsideCreditPeriod = creditDetailsData.totalOCP;
            let amountWithinCreditPerioud = creditDetailsData.totalWCP;
            let amountToPayNow =
              parseInt(amountOutsideCreditPeriod) +
              parseInt(amountWithinCreditPerioud);
            console.log({
              amountToPayNow,
            });
            setPayNowPaymentAmount(amountToPayNow);

            if (creditDetailsData?.lendingPartnerDueAmount > 0) {
              // Pay Now will only clear the lending partner due amount
              setLendingPartnerDueAmount(
                creditDetailsData?.lendingPartnerDueAmount
              );
            }

            console.log({ directPayment, paymentType });
            if (directPayment === "true" && paymentType !== "") {
              if (paymentType === "due") {
                if (localStorage.getItem("creditLine") !== "FINBOX") {
                  setShowPayNowDialog(true);
                }
              }
              if (
                paymentType === "advance" &&
                creditDetailsData?.totalOCP === 0
              ) {
                setShowPayAdvanceDialog(true);
              }
            }
          }
        }
        setLoadingWalletBreakup("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          SentryService.captureCustomErrorMessage(
            "GET_WALLET_BREAKUP_API_FAILED",
            error
          );
          if (error.response?.status === 401) {
            if (PartnerService.checkIfFieldAgent()) {
              PartnerService.logoutFieldAgentAsPartner();
              navigate("/partners");
              window.location.reload();
            } else {
              PartnerService.logout();
              window.location.reload();
            }
          } else {
            console.log(`error getting wallet details`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
          }
          setLoadingWalletBreakup("done");
        }
      });

    return () => {
      abortController.abort();
      abortControllerWallet.abort();
    };
  }, []);

  const payNowHandler = () => {
    if (!localStorage.getItem("role")) {
      if (localStorage.getItem("creditLine")?.toLowerCase() === "finbox") {
        if (enablePayNowForFinBox) {
          const { axiosRequest } =
            PartnerService.getPartnerFinBoxInitializationDetails();
          axiosRequest
            .then((response) => {
              let responseData = response?.data?.responseData;
              if (responseData) {
                let finBoxCustomerId = responseData?.finboxCustomerId;
                let userToken = responseData?.finboxToken;

                if (finBoxCustomerId && userToken) {
                  try {
                    /*global Android*/
                    if (Android) {
                      // Android.startFinBoxFlow(
                      //   finBoxCustomerId,
                      //   config.finBoxClientApiKey,
                      //   userToken,
                      //   `${config.appUrl}/hisaab`
                      // );
                    } else {
                      setErrorMessage(
                        "Please use the latest AgroStar Saathi App to continue."
                      );
                      setErrorCode("NO_ANDROID");
                      console.log("No android");
                    }
                  } catch (e) {
                    setErrorMessage(`Something went wrong. Please try again.`);
                    setErrorCode("FAILED_INITIATION");
                  }
                } else {
                  setErrorMessage(
                    `Could not get FinBox details. Please try again.`
                  );
                  setErrorCode("NO_USERID_OR_NO_F_TOKEN");
                }
              } else {
                setErrorMessage(
                  `Could not get FinBox details. Please try again.`
                );
                setErrorCode("FINBOX_FETCH_NO_RESPONSE_DATA");
              }
            })
            .catch((error) => {
              console.log(error);
              setErrorMessage(
                `Could not get FinBox details. Please try again.`
              );
              setErrorCode("FAILED_FINBOX_FETCH");
            });
        } else {
          setErrorMessage(
            `Pay Now is currently disabled for FinBox credit line users.`
          );
          setErrorCode("FINBOX_PAY_NOW_DISABLED");
          console.log("Pay now is disabled for FinBox credit line users.s");
        }
      } else {
        if (!config.disablePayNow) {
          setShowPayNowDialog(true);
        }
      }
    }
  };
  const handleClickPayNow = () => {
    payNowHandler();
    AnalyticsService.pushEvent("Pay Now Clicked", {
      dueAmount: payNowPaymentAmount,
      page: window?.location?.pathname || "",
      creditLine: localStorage.getItem("creditLine")?.toLowerCase(),
    });
  };
  const handleClickPayNowButtonOffer = (bannerName) => {
    payNowHandler();
    AnalyticsService.pushEvent("Pay Now Button Banner Clicked", {
      dueAmount: payNowPaymentAmount,
      bannerName: bannerName,
    });
  };
  const handleClickPayAdvance = () => {
    setShowPayAdvanceDialog(true);
    AnalyticsService.pushEvent("Pay Advance Clicked", {
      creditLine: localStorage.getItem("creditLine")?.toLowerCase(),
    });
  };

  const handleClickOCPCard = () => {
    setShowOutsideCreditPeriodDetails((previousValue) => {
      return !previousValue;
    });
    AnalyticsService.pushEvent("Hisaab Page Overdue Card Clicked", {
      ocpAmount: creditDetails?.creditDetails?.totalOCP,
      page: "hisaab",
    });
  };
  const handleClickWCPCard = () => {
    setShowWithinCreditPeriodDetails((previousValue) => {
      return !previousValue;
    });
    AnalyticsService.pushEvent("Hisaab Page Within Due Date Card Clicked", {
      wcpAmount: creditDetails?.creditDetails?.totalWCP,
      page: "hisaab",
    });
  };

  const handleLogout = () => {
    PartnerService.logoutFieldAgentAsPartner();
    navigate("/partners");
    AnalyticsService.pushEvent("Logout Clicked");
  };

  const checkIfVANAvailable = () => {
    if (lendingProvider) {
      if (lendingProvider === AGROSTAR) {
        return (
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" &&
          institutionDetails?.bankAndGST?.virtualAccountNumber
        );
      } else {
        return (
          institutionDetails.externalLanderDetails &&
          institutionDetails.externalLanderDetails.length > 0
        );
      }
    }

    if (institutionDetails?.bankAndGST?.virtualAccountNumber) {
      return true;
    }
  };

  return (
    <div className={classes.container}>
      <BlockUi
        tag="div"
        blocking={loading !== "done" || loadingWalletBreakup !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "95vh" }}
      >
        <div>
          <ErrorBoundary message={""}>
            <CreditLimitDetails
              classes={classes}
              creditDetails={creditDetails}
              loadingWalletBreakup={loadingWalletBreakup}
            />
          </ErrorBoundary>
          <ErrorBoundary message={""}>
            <Card className={classes.balanceCards} variant="outlined">
              <Grid
                container
                sx={{ marginTop: "10px" }}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Grid item xs={7} onClick={handleClickOCPCard}>
                  <Typography
                    variant={`h6`}
                    sx={{ fontSize: "14px", fontWeight: "700" }}
                  >
                    Payment Overdue
                  </Typography>
                  <Typography variant={`body2`} sx={{ fontSize: "12px" }}>
                    Outside Credit Period
                  </Typography>
                </Grid>
                <Grid item xs={4} onClick={handleClickOCPCard}>
                  <Typography
                    className={classes.ocpValue}
                    variant={"body2"}
                    textAlign={"right"}
                    sx={{ lineHeight: "22.4px" }}
                  >
                    <b>
                      {AmountUtils.formatCurrency(
                        creditDetails?.creditDetails?.totalOCP || 0
                      )}
                    </b>
                  </Typography>
                  {/* {PartnerService.isPartnerExternalCreditType() && (
                    <Typography
                      className={classes.ocpValue}
                      variant={"body2"}
                      textAlign={"right"}
                    >
                      Interest +{" "}
                      {AmountUtils.formatCurrency(
                        creditDetails?.creditDetails
                          ?.totalLendingPartnerInterest || 0
                      )}
                    </Typography>
                  )} */}
                </Grid>
                <Grid item xs={1}>
                  {creditDetails?.creditDetails?.totalOCP > 0 &&
                    !showOutsideCreditPeriodDetails && (
                      <IconButton
                        sx={{ marginTop: "-16px", marginLeft: "-8px" }}
                        onClick={() => {
                          setShowOutsideCreditPeriodDetails(true);
                          AnalyticsService.pushEvent(
                            "Hisaab Page Overdue Card Clicked",
                            {
                              ocpAmount: creditDetails?.creditDetails?.totalOCP,
                              page: "hisaab",
                            }
                          );
                        }}
                      >
                        <KeyboardArrowDown
                          sx={{
                            color: "#000000",
                            opacity: "0.3",
                          }}
                          fontSize={"large"}
                        />
                      </IconButton>
                    )}
                  {creditDetails?.creditDetails?.totalOCP > 0 &&
                    showOutsideCreditPeriodDetails && (
                      <IconButton
                        sx={{ marginTop: "-16px", marginLeft: "-8px" }}
                        onClick={() => {
                          setShowOutsideCreditPeriodDetails(false);
                          AnalyticsService.pushEvent(
                            "Hisaab Page Overdue Card Clicked",
                            {
                              ocpAmount: creditDetails?.creditDetails?.totalOCP,
                              page: "hisaab",
                            }
                          );
                        }}
                      >
                        <KeyboardArrowUp
                          sx={{
                            color: "#000000",
                            opacity: "0.3",
                          }}
                          fontSize={"large"}
                          onClick={() =>
                            setShowOutsideCreditPeriodDetails(false)
                          }
                        />
                      </IconButton>
                    )}
                </Grid>
              </Grid>

              {creditDetails?.creditDetails?.totalOCP > 0 &&
                showOutsideCreditPeriodDetails && (
                  <div className={classes.ocpDetailsSection}>
                    <div
                      className={classes.subSection}
                      style={{
                        width:
                          institutionDetails?.creditLine === "FINBOX"
                            ? "65%"
                            : "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant={`body2`}
                        sx={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Due Date
                      </Typography>
                      <Box>
                        <Typography
                          variant={`body2`}
                          sx={{ fontSize: "12px", fontWeight: "700" }}
                        >
                          Due Amount
                        </Typography>
                        {/* {PartnerService.isPartnerExternalCreditType() && (
                          <Typography
                            variant={`body2`}
                            sx={{
                              fontSize: "12px",
                              color: "#666666",
                              textAlign: "end",
                            }}
                          >
                            + Interest
                          </Typography>
                        )} */}
                      </Box>
                    </div>
                    {creditDetails?.creditDetails?.outSideCrditPeriod.map(
                      (ocp, index) => {
                        return (
                          <div
                            className={classes.subSection}
                            style={{ width: "100%" }}
                            key={index}
                          >
                            <Typography
                              variant={"body2"}
                              sx={{ fontSize: "12px", fontColor: "#000000" }}
                            >
                              {format(ocp.date, "dd MMM yyyy")}
                              <br />
                              <span className={classes.subText}>
                                {ocp.pastDues} Days Overdue
                              </span>
                            </Typography>
                            <Box>
                              <Typography
                                variant={"body2"}
                                fontSize={"12px"}
                                color={"primary"}
                              >
                                <b>
                                  {AmountUtils.formatCurrency(ocp?.amount || 0)}
                                </b>
                              </Typography>
                              {/* {PartnerService.isPartnerExternalCreditType() && (
                                <Typography
                                  variant={"body2"}
                                  fontSize={"12px"}
                                  color={"#666666"}
                                  textAlign={"end"}
                                >
                                  +{" "}
                                  {AmountUtils.formatCurrency(
                                    ocp?.lendingPartnerInterest || 0
                                  )}
                                </Typography>
                              )} */}
                            </Box>
                            {institutionDetails?.creditLine === "FINBOX" && (
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.btn}
                                onClick={() => {
                                  setTransactionList({
                                    transactions: ocp.transactions,
                                    totalTransactions: ocp.transactions.length,
                                  });
                                  setOpenDialog(true);
                                }}
                                sx={{
                                  textTransform: "capitalize",
                                  borderRadius: "10px",
                                  boxShadow: "none",
                                }}
                              >
                                View
                              </Button>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}

              <Divider
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "-16px",
                  marginRight: "-16px",
                }}
              />
              <Grid
                container
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Grid item xs={7} onClick={handleClickWCPCard}>
                  <Typography
                    variant={`h6`}
                    sx={{ fontSize: "14px", fontWeight: "700" }}
                  >
                    Upcoming Payment
                  </Typography>
                  <Typography variant={`body2`} sx={{ fontSize: "12px" }}>
                    Within Credit Period
                  </Typography>
                </Grid>
                <Grid item xs={4} onClick={handleClickWCPCard}>
                  <Typography className={classes.wcpValue} textAlign={"right"}>
                    <b>
                      +{" "}
                      {AmountUtils.formatCurrency(
                        creditDetails?.creditDetails?.totalWCP || 0
                      )}
                    </b>
                  </Typography>
                  {creditDetails?.creditDetails?.totalWithinCreditPeriodCD >
                    0 && (
                    <Typography
                      className={classes.wcpValue}
                      textAlign={"right"}
                    >
                      Total CD{" "}
                      {AmountUtils.formatCurrency(
                        creditDetails?.creditDetails
                          ?.totalWithinCreditPeriodCD || 0
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={1}>
                  {creditDetails?.creditDetails?.totalWCP > 0 &&
                    !showWithinCreditPeriodDetails && (
                      <IconButton
                        sx={{ marginTop: "-16px", marginLeft: "-8px" }}
                        onClick={() => {
                          setShowWithinCreditPeriodDetails(true);
                          AnalyticsService.pushEvent(
                            "Hisaab Page Within Due Date Card Clicked",
                            {
                              wcpAmount: creditDetails?.creditDetails?.totalWCP,
                              page: "hisaab",
                            }
                          );
                        }}
                      >
                        <KeyboardArrowDown
                          sx={{
                            // marginTop: "5px",
                            color: "#000000",
                            opacity: "0.3",
                          }}
                          fontSize={"large"}
                        />
                      </IconButton>
                    )}
                  {creditDetails?.creditDetails?.totalWCP > 0 &&
                    showWithinCreditPeriodDetails && (
                      <IconButton
                        sx={{ marginTop: "-16px", marginLeft: "-8px" }}
                        onClick={() => {
                          setShowWithinCreditPeriodDetails(false);
                          AnalyticsService.pushEvent(
                            "Hisaab Page Within Due Date Card Clicked",
                            {
                              wcpAmount: creditDetails?.creditDetails?.totalWCP,
                              page: "hisaab",
                            }
                          );
                        }}
                      >
                        <KeyboardArrowUp
                          sx={{
                            // marginTop: "-4px",
                            color: "#000000",
                            opacity: "0.3",
                          }}
                          fontSize={"large"}
                        />
                      </IconButton>
                    )}
                </Grid>
              </Grid>
              {creditDetails?.creditDetails?.totalWCP > 0 &&
                showWithinCreditPeriodDetails && (
                  <div className={classes.wcpDetailsSection}>
                    <Grid
                      container
                      direction={`row`}
                      alignItems={`flex-start`}
                      spacing={1.5}
                      sx={{ padding: "0px 16px 16px 16px" }}
                    >
                      <Grid
                        item
                        xs={
                          institutionDetails?.creditLine === "FINBOX" ? 3.8 : 4
                        }
                      >
                        <Typography
                          variant={`body2`}
                          sx={{ fontSize: "12px", fontWeight: "700" }}
                        >
                          Due Date
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={
                          institutionDetails?.creditLine === "FINBOX" ? 3.2 : 4
                        }
                      >
                        <Typography
                          variant={`body2`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "700",
                            marginLeft: "-1px",
                          }}
                          textAlign={"end"}
                        >
                          Due Amount
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={institutionDetails?.creditLine === "FINBOX" ? 4 : 4}
                        textAlign={"end"}
                      >
                        <Typography
                          variant={`body2`}
                          sx={{ fontSize: "12px", fontWeight: "700" }}
                        >
                          Cash Discount
                        </Typography>
                        <Typography
                          variant={`body2`}
                          sx={{ fontSize: "12px", color: "#666666" }}
                        >
                          Pay By
                        </Typography>
                      </Grid>
                    </Grid>

                    {creditDetails?.creditDetails?.withinCreditPeriod.map(
                      (wcp, index) => {
                        return (
                          <Grid
                            container
                            direction={`row`}
                            alignItems={`flex-start`}
                            style={{ padding: "4px 16px 8px 16px" }}
                            key={index}
                            spacing={1.5}
                          >
                            <Grid
                              item
                              xs={
                                institutionDetails?.creditLine === "FINBOX"
                                  ? 3.8
                                  : 4
                              }
                            >
                              <Typography variant={`body2`}>
                                {format(wcp.date, "dd MMM yyyy")}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={
                                institutionDetails?.creditLine === "FINBOX"
                                  ? 3.2
                                  : 4
                              }
                              textAlign={"end"}
                            >
                              <Typography
                                variant={"caption"}
                                color={"secondary"}
                              >
                                <b>
                                  {AmountUtils.formatCurrency(wcp?.amount || 0)}
                                </b>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={
                                institutionDetails?.creditLine === "FINBOX"
                                  ? 3.5
                                  : 4
                              }
                              textAlign={"end"}
                            >
                              <Typography
                                variant={"caption"}
                                sx={{
                                  display: "block",
                                  color: "#4F4F4F",
                                  lineHeight: "16px",
                                }}
                              >
                                <b>
                                  {AmountUtils.formatCurrency(
                                    wcp?.eligibleCD || 0
                                  )}
                                </b>
                              </Typography>
                              <Typography
                                variant={"caption"}
                                sx={{
                                  display: "block",
                                  color: "#4F4F4F",
                                  lineHeight: "16px",
                                  minHeight: "16px",
                                }}
                              >
                                {wcp?.cdValidTill && wcp?.eligibleCD !== 0
                                  ? format(wcp.cdValidTill, "dd MMM yyyy")
                                  : ``}
                              </Typography>
                            </Grid>
                            {institutionDetails?.creditLine === "FINBOX" && (
                              <Grid
                                item
                                xs={1.5}
                                sx={{ textAlign: "right", marginLeft: "-4px" }}
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className={classes.btn}
                                  onClick={() => {
                                    setTransactionList({
                                      transactions: wcp.transactions,
                                      totalTransactions:
                                        wcp.transactions.length,
                                    });
                                    setOpenDialog(true);
                                  }}
                                  sx={{
                                    textTransform: "capitalize",
                                    padding: "4px",
                                    minWidth: "35px",
                                    lineHeight: "16px",
                                    borderRadius: "10px",
                                    boxShadow: "none",
                                  }}
                                >
                                  View
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        );
                      }
                    )}
                  </div>
                )}
              <Box
                sx={{
                  backgroundColor: "rgba(157, 33, 35,0.1)",
                  marginLeft: "-16px",
                  marginRight: "-16px",
                  marginBottom: "-12px",
                  marginTop: "10px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                }}
              >
                {" "}
                <Grid container>
                  <Grid item xs={9}>
                    <Typography
                      variant={`h6`}
                      sx={{ fontSize: "14px", fontWeight: "700" }}
                    >
                      Total Outstanding Balance
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      color={`secondary`}
                      sx={{ fontSize: "16px", fontWeight: "700" }}
                    >
                      {AmountUtils.formatCurrency(payNowPaymentAmount || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {lendingPartnerDueAmount > 0 && (
                <Box
                  sx={{
                    margin: "10px -16px -12px -16px",
                    padding: "12px 16px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography
                        variant={`h6`}
                        sx={{ fontSize: "14px", fontWeight: "700" }}
                      >
                        Total Payable Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        color={`secondary`}
                        sx={{ fontSize: "16px", fontWeight: "700" }}
                      >
                        {AmountUtils.formatCurrency(
                          lendingPartnerDueAmount || 0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {isPayNowEnabled() === true && (
                <Box sx={{ marginLeft: "-6px", marginRight: "-6px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{ marginTop: "12px", marginBottom: "-8px" }}
                  >
                    <Grid item xs={12}>
                      <Button
                        size={"large"}
                        fullWidth
                        variant={`contained`}
                        sx={{
                          borderRadius: "10px",
                          fontSize: "14px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        onClick={handleClickPayNow}
                        disabled={
                          localStorage.getItem("role") === "fieldAgent" ||
                          config.disablePayNow ||
                          PartnerService.isPartnerOnboardingInProgress() ||
                          localStorage.getItem("partnerStatus") === "UNVERIFIED"
                        }
                      >
                        Pay Now
                      </Button>
                      {config.disablePayNow ? (
                        <Typography
                          textAlign={"center"}
                          sx={{ fontSize: "12px", marginTop: "-10px" }}
                        >
                          Pay Now is temporarily disabled for system
                          maintenance. It will be available soon.
                        </Typography>
                      ) : localStorage.getItem("role") === "fieldAgent" ? (
                        <Typography
                          textAlign={"center"}
                          sx={{ fontSize: "12px", marginTop: "-10px" }}
                        >
                          This feature is only for Partners
                        </Typography>
                      ) : PartnerService.isPartnerOnboardingInProgress() ||
                        localStorage.getItem("partnerStatus") ===
                          "UNVERIFIED" ? (
                        <Typography
                          textAlign={"center"}
                          sx={{ fontSize: "12px", marginTop: "-10px" }}
                        >
                          Pay Now will be available once you become our Saathi
                          Partner
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                  {errorMessage && (
                    <Grid
                      container
                      alignItems={`center`}
                      justifyContent={`center`}
                    >
                      <Grid item xs={10}>
                        <Typography
                          variant={`body2`}
                          align={`center`}
                          color={"error"}
                        >
                          {errorMessage}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {errorCode && (
                    <Grid
                      container
                      alignItems={`center`}
                      justifyContent={`center`}
                    >
                      <Grid item xs={10}>
                        <Typography
                          variant={`body2`}
                          color={"gray"}
                          align={`center`}
                        >
                          {errorCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {loadingWalletBreakup === "started" && (
                    <Box sx={{ margin: "5px 0px" }}>
                      <Skeleton variant="rectangular" height={38} />
                    </Box>
                  )}
                  {loadingWalletBreakup === "done" &&
                    creditDetails?.creditDetails?.totalWithinCreditPeriodCD >
                      0 && (
                      <Grid
                        container
                        alignItems={`center`}
                        sx={{
                          padding: "6px 12px",
                          margin: "15px 0px 5px 0px",
                          background: "#CCE3D8",
                          borderRadius: "10px",
                        }}
                        onClick={() =>
                          handleClickPayNowButtonOffer("cdBenefitBanner")
                        }
                      >
                        <Grid item xs={1}>
                          <img
                            src={`/icons/offersGreenIcon.svg`}
                            alt=""
                            className={classes.offersIcon}
                          />
                        </Grid>
                        <Grid item xs={11} sx={{ paddingLeft: "4px" }}>
                          <Typography
                            variant="span"
                            sx={{
                              color: "#333333",
                              fontSize: "12px",
                              letterSpacing: "0px",
                            }}
                          >
                            {`Pay Now and get `}
                          </Typography>
                          <Typography
                            variant="span"
                            color={`secondary`}
                            sx={{ fontSize: "12px", letterSpacing: "0px" }}
                          >
                            <b>{`upto ${AmountUtils.formatCurrency(
                              creditDetails?.creditDetails
                                ?.totalWithinCreditPeriodCD || 0
                            )} Cash Discount`}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                  <Grid
                    container
                    alignItems="center"
                    justifyContent={`center`}
                    sx={{ marginTop: "5px" }}
                    onClick={() =>
                      handleClickPayNowButtonOffer("apOfferBanner")
                    }
                  >
                    <Grid item xs={12}>
                      <img
                        src={`https://static.agrostar.in/agro-elearning-firebase/v0/b/agro-elearning/o/saathi%2Fbanners%2Fpay-now-button-offer.png?alt=media&timeStamp=${Date.now()
                          .toString()
                          .slice(0, -5)}`}
                        width={"100%"}
                        alt={`Pay Now Button Offer`}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Card>
          </ErrorBoundary>

          <ErrorBoundary message={""}>
            <Card className={classes.card} variant="outlined">
              <Typography variant={`body2`} className={classes.creditLineTitle}>
                VAN Account Details
              </Typography>
              {checkIfVANAvailable() ? (
                <>
                  <div className={classes.section}>
                    <Typography
                      className={classes.sectionName}
                      variant={"body2"}
                    >
                      Account Number:
                    </Typography>
                    <Typography className={classes.sectionValue}>
                      {!lendingProvider || lendingProvider === AGROSTAR
                        ? institutionDetails?.bankAndGST?.virtualAccountNumber
                        : institutionDetails.externalLanderDetails?.[0]
                            ?.externalVanDetails?.virtualAccountNumber}
                    </Typography>
                  </div>
                  <div className={classes.section}>
                    <Typography
                      className={classes.sectionName}
                      variant={"body2"}
                    >
                      Account Name:
                    </Typography>
                    <Typography
                      className={classes.sectionValue}
                      variant={"body2"}
                    >
                      {!lendingProvider || lendingProvider === AGROSTAR
                        ? institutionDetails?.bankAndGST?.accountHolderName ||
                          "Ulink Agritech Private Limited"
                        : institutionDetails.externalLanderDetails?.[0]
                            ?.externalVanDetails?.accountHolderName}
                    </Typography>
                  </div>
                  <div className={classes.section}>
                    <Typography
                      className={classes.sectionName}
                      variant={"body2"}
                    >
                      Bank Name:
                    </Typography>
                    <Typography
                      className={classes.sectionValue}
                      variant={"body2"}
                    >
                      {!lendingProvider || lendingProvider === AGROSTAR
                        ? institutionDetails?.bankAndGST?.bankName ||
                          "Kotak Mahindra Bank"
                        : institutionDetails.externalLanderDetails?.[0]
                            ?.externalVanDetails?.bankName || "N/A"}
                    </Typography>
                  </div>
                  <div className={classes.section}>
                    <Typography
                      className={classes.sectionName}
                      variant={"body2"}
                    >
                      Branch:
                    </Typography>
                    <Typography
                      className={classes.sectionValue}
                      variant={"body2"}
                    >
                      {!lendingProvider || lendingProvider === AGROSTAR
                        ? institutionDetails?.bankAndGST?.branch ||
                          "Kalyaninagar Pune"
                        : institutionDetails.externalLanderDetails?.[0]
                            ?.externalVanDetails?.branchName || "N/A"}
                    </Typography>
                  </div>
                  <div className={classes.section}>
                    <Typography
                      className={classes.sectionName}
                      variant={"body2"}
                    >
                      IFSC Code:
                    </Typography>
                    <Typography
                      className={classes.sectionValue}
                      variant={"body2"}
                    >
                      {!lendingProvider || lendingProvider === AGROSTAR
                        ? institutionDetails?.bankAndGST?.ifscCode ||
                          "KKBK0001758"
                        : institutionDetails.externalLanderDetails?.[0]
                            ?.externalVanDetails?.ifscCode}
                    </Typography>
                  </div>

                  <Typography className={classes.info} variant={"body2"}>
                    <InfoOutlinedIcon sx={{ marginRight: "8px" }} />
                    Payments made to VAN account will take up to 3 hours to
                    reflect.
                  </Typography>
                </>
              ) : (
                <Typography className={classes.infoText} variant={"body2"}>
                  Coming Soon...
                </Typography>
              )}
            </Card>
          </ErrorBoundary>

          <Card className={classes.balanceCards} variant="outlined">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              sx={{
                marginTop: "12px",
                marginBottom: "-8px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: "-10px",
                  color: "#9D2123",
                }}
              >
                <>
                  <PrivacyTipIcon
                    fontSize={"small"}
                    sx={{ marginBottom: "-10px" }}
                  />
                  <Typography
                    variant={`body2`}
                    color={"primary"}
                    sx={{
                      fontSize: "12px",
                      marginTop: "-20px",
                      marginBottom: "10px",
                      marginLeft: "30px",
                    }}
                  >
                    Advance payment amount will be added to the{" "}
                    <b>Real Cash Balance</b>.
                  </Typography>
                </>

                <Button
                  size={"large"}
                  fullWidth
                  variant={`outlined`}
                  sx={{
                    borderRadius: "10px",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                  disabled={
                    localStorage.getItem("partnerStatus") === "UNVERIFIED"
                  }
                  onClick={handleClickPayAdvance}
                >
                  Pay Advance
                </Button>
                {localStorage.getItem("partnerStatus") === "UNVERIFIED" && (
                  <Typography
                    textAlign={"center"}
                    sx={{
                      fontSize: "12px",
                      marginTop: "-8px",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    Pay Advance will be available once you become our Saathi
                    Partner
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>

          <ErrorBoundary message={""}>
            {localStorage.getItem("role") === "fieldAgent" &&
              localStorage.getItem("partnerStatus") === "INACTIVE" && (
                <Button
                  variant={`outlined`}
                  fullWidth
                  sx={{
                    background: "#FFFFFF",
                    marginBottom: "120px",
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              )}
          </ErrorBoundary>
        </div>
      </BlockUi>

      <Dialog fullScreen open={openDialog} onClose={() => setOpenDialog(false)}>
        <AppBar
          color={"transparent"}
          sx={{
            position: "relative",
            boxShadow: `none`,
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, fontSize: "18px" }}
              variant="h6"
              component="div"
            >
              Transaction Details
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpenDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <ErrorBoundary>
          <TransactionDetailsCards transactionList={transactionList} />
        </ErrorBoundary>
      </Dialog>
      <PayNowDialog
        open={showPayNowDialog}
        setOpen={setShowPayNowDialog}
        paymentAmount={
          lendingPartnerDueAmount > 0
            ? lendingPartnerDueAmount
            : payNowPaymentAmount
        }
        lendingPartnerDueAmount={lendingPartnerDueAmount}
        lendingPartner={
          lendingPartnerDueAmount > 0
            ? PartnerService.getLendingPartner()
            : "AGROSTAR"
        }
        handleClose={handleClosePayNow}
        handlePaymentStatus={handlePaymentStatus}
        handleRazorpayFailure={handleRazorpayFailure}
        paymentLoading={paymentLoading}
        setPaymentLoading={setPaymentLoading}
        amountTypeFromParams={amountTypeFromParams}
        amountFromParams={amountFromParams}
        paymentType={"due"}
        initiationFailureRetryRedirectionLink={"/hisaab?payment=true&type=due"}
      />
      <PayAdvanceDialog
        open={showPayAdvanceDialog}
        paymentAmount={50000}
        handleClose={handleClosePayAdvance}
        handlePaymentStatus={handlePaymentStatus}
        handleRazorpayFailure={handleRazorpayFailure}
        paymentLoading={paymentLoading}
        setPaymentLoading={setPaymentLoading}
        amountTypeFromParams={amountTypeFromParams}
        amountFromParams={amountFromParams}
        reasonFromParams={"others"}
      />

      <BackDropLoader open={paymentLoading === "started"} />
    </div>
  );
};

export default Hisaab;
