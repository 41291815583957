import React, { useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Grid, Box, Typography } from "@mui/material";
import Clamp from "react-multiline-clamp";
import { useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import makeStyles from "@mui/styles/makeStyles";
import CircleIcon from "@mui/icons-material/Circle";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1.5, 0.5),
  },
  cards: {
    marginTop: "5px",
    borderRadius: theme.spacing(1.2),
    border: "none",
    fontSize: "12px",
    boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
  },

  expandIcon: {
    fontSize: "35px",
    color: "#4F4F4F",
    marginTop: "2px",
    marginLeft: "12px",
    width: "100%",
    height: "100% ",
  },
  iconsWrapper: {
    textAlign: "center",
    height: "25px",
  },
}));

function ComplaintHistoryCards({ complaintDetails }) {
  const classes = useStyles();
  let location = useLocation();
  const [complaintDetailsCardExpanded, setComplaintDetailsCardExpanded] =
    useState(false);
  const isComplaintStatusResolved =
    complaintDetails?.status === "Closed" ||
    complaintDetails?.status === "Resolved";

  const handleAccordionExpand = () => {
    const cardClickedEvent = {
      page: location.pathname,
    };
    AnalyticsService.pushEvent(`Card Clicked`, cardClickedEvent);
    setComplaintDetailsCardExpanded(
      (complaintDetailsCardExpanded) => !complaintDetailsCardExpanded
    );
  };

  function formatDate(complaintCreatedDate) {
    if (complaintCreatedDate) {
      const parsedDate = moment(complaintCreatedDate);
      return parsedDate.format("D MMMM YYYY");
    }
  }

  const handleComplaintsCardClicked = (event) => {
    event.stopPropagation();
    handleAccordionExpand();
  };

  function removeHashTag(text) {
    if (text.includes("#")) {
      return text.replace(/#/g, "");
    } else {
      return text;
    }
  }

  return (
    <Box
      sx={{
        marginBottom: "15px",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          margin: "0px 10px",
          color: "#4F4F4F",
          fontWeight: "400",
        }}
      >
        {formatDate(complaintDetails?.createdOn)}
      </Typography>

      <Accordion
        className={classes.cards}
        expanded={complaintDetailsCardExpanded}
        onChange={handleAccordionExpand}
        variant="outlined"
        TransitionProps={{ unmountOnExit: true }}
        disableGutters={true}
        elevation={0}
        sx={{
          borderRadius: "16px !important",
          "&.MuiAccordion-root:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            marginTop: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid container>
              <Grid item xs={7}>
                <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                  {complaintDetails?.issueName || ""}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{ color: "#4F4F4F", fontSize: "12px" }}
                >
                  Id - {removeHashTag(complaintDetails?.id || "")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "30px",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "inline",
                    background: isComplaintStatusResolved
                      ? "#cce3d8"
                      : "#efe4cd",
                    color: isComplaintStatusResolved ? "#228557" : "#aa7810",
                    padding: "6px 11px",
                    borderRadius: "7px",
                    fontSize: "12px",
                  }}
                >
                  {isComplaintStatusResolved ? "Resolved" : "In-Progress"}
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.iconsWrapper}>
                {complaintDetailsCardExpanded ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </Grid>
            </Grid>
            {complaintDetails.orderItemNames && (
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#4F4F4F",
                  opacity: "70%",
                  fontWeight: "700",
                }}
              >
                {_.join(complaintDetails.orderItemNames, ", ")}
              </Typography>
            )}
            <Box>
              {!complaintDetailsCardExpanded ? (
                <Box sx={{ marginTop: "5px" }}>
                  {complaintDetails?.reasonsTranslations.length ? (
                    <>
                      <Box
                        component="span"
                        sx={{
                          display: "inline",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <CircleIcon
                          sx={{
                            fontSize: "10px",
                            margin: "5px 5px 0px 0px",
                            color: "#d2d2d2",
                            display:
                              !complaintDetails?.reasonsTranslations.length &&
                              "none",
                          }}
                        />
                        {complaintDetails?.reasonsTranslations[0] || ""}
                      </Box>

                      <Box
                        component={"span"}
                        sx={{
                          display: "inline",
                          color: "#9D2123",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginLeft: "5px",
                        }}
                      >
                        {complaintDetails?.reasonsTranslations?.length > 1
                          ? `(${
                              complaintDetails?.reasonsTranslations?.length - 1
                            } more)`
                          : ""}
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <Box sx={{ margin: "10px 0px" }}>
                  {_.map(
                    complaintDetails?.reasonsTranslations,
                    (reasonName) => {
                      return (
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <CircleIcon
                            sx={{
                              fontSize: "10px",
                              marginTop: "5px",
                              color: "#d2d2d2",
                            }}
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              marginBottom: "5px",
                              fontWeight: "400",
                              marginLeft: "5px",
                            }}
                          >
                            {reasonName}
                          </span>
                        </Box>
                      );
                    }
                  )}
                </Box>
              )}

              {!complaintDetailsCardExpanded ? (
                <Box sx={{ marginTop: "5px" }}>
                  <Clamp withTooltip lines={2}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#4F4F4F",
                        lineHeight: "15.65px",
                        opacity: "70%",
                      }}
                    >
                      {complaintDetails?.remarksText || ""}
                    </Typography>
                  </Clamp>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails onClick={handleComplaintsCardClicked}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#4F4F4F",
              opacity: "70%",
              marginTop: "-14px",
            }}
          >
            {complaintDetails?.remarksText || ""}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default ComplaintHistoryCards;
