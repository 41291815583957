export default function getHarcodedProductTestimonial(sku, source) {
  if (
    !!productTestimonials[sku] &&
    productTestimonials[sku].source.includes(source)
  ) {
    if (source === "B2BMH") {
      return productTestimonials[sku]?.youtubeURLB2BMH;
    }

    return productTestimonials[sku]?.youtubeURL;
  }

  return null;
}

const productTestimonials = {
  "AGS-CP-1540": {
    youtubeURL: ["https://youtu.be/RWIbJwz1xd8"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1636": {
    youtubeURL: ["https://youtu.be/RWIbJwz1xd8"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1537": {
    youtubeURL: ["https://youtu.be/RWIbJwz1xd8"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1493": {
    youtubeURL: ["https://youtu.be/3ELHI5_Z02w"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/6MzL2YkUWEg"],
  },
  "AGS-CP-1494": {
    youtubeURL: ["https://youtu.be/3ELHI5_Z02w"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/6MzL2YkUWEg"],
  },
  "AGS-CP-1495": {
    youtubeURL: ["https://youtu.be/3ELHI5_Z02w"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/6MzL2YkUWEg"],
  },
  "AGS-CP-1719": {
    youtubeURL: [
      "https://youtu.be/JlUGgteQky8",
      "https://youtu.be/dCHuBW4skUY",
    ],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/8hu7_4g-5fM"],
  },
  "AGS-CP-1722": {
    youtubeURL: [
      "https://youtu.be/JlUGgteQky8",
      "https://youtu.be/dCHuBW4skUY",
    ],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/8hu7_4g-5fM"],
  },
  "AGS-CP-1720": {
    youtubeURL: [
      "https://youtu.be/JlUGgteQky8",
      "https://youtu.be/dCHuBW4skUY",
    ],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/8hu7_4g-5fM"],
  },
  "AGS-CP-1721": {
    youtubeURL: [
      "https://youtu.be/JlUGgteQky8",
      "https://youtu.be/dCHuBW4skUY",
    ],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/8hu7_4g-5fM"],
  },
  "AGS-CP-1295": {
    youtubeURL: ["https://youtu.be/7e6KqqnOZks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-KIT-1176": {
    youtubeURL: ["https://youtu.be/7e6KqqnOZks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-KIT-1177": {
    youtubeURL: ["https://youtu.be/7e6KqqnOZks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CN-885": {
    youtubeURL: ["https://www.youtube.com/watch?v=HoQSHnuDTjQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CN-702": {
    youtubeURL: ["https://www.youtube.com/watch?v=HoQSHnuDTjQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CN-723": {
    youtubeURL: ["https://www.youtube.com/watch?v=HoQSHnuDTjQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CN-884": {
    youtubeURL: ["https://www.youtube.com/watch?v=HoQSHnuDTjQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CN-811": {
    youtubeURL: ["https://www.youtube.com/watch?v=HoQSHnuDTjQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1619": {
    youtubeURL: ["https://youtu.be/CnPSmjwD1Ks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1247": {
    youtubeURL: ["https://youtu.be/CnPSmjwD1Ks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1245": {
    youtubeURL: ["https://youtu.be/CnPSmjwD1Ks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1246": {
    youtubeURL: ["https://youtu.be/CnPSmjwD1Ks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1186": {
    youtubeURL: ["https://youtu.be/CnPSmjwD1Ks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1187": {
    youtubeURL: ["https://youtu.be/CnPSmjwD1Ks"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-956": {
    youtubeURL: ["https://youtu.be/NkNsNWcLNkg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/7jPdlnhACII"],
  },
  "AGS-CP-1503": {
    youtubeURL: ["https://youtu.be/NkNsNWcLNkg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/7jPdlnhACII"],
  },
  "AGS-CP-1504": {
    youtubeURL: ["https://youtu.be/NkNsNWcLNkg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/7jPdlnhACII"],
  },
  "AGS-CP-1614": {
    youtubeURL: ["https://youtu.be/NkNsNWcLNkg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/7jPdlnhACII"],
  },
  "AGS-CP-787": {
    youtubeURL: ["https://youtu.be/NkNsNWcLNkg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/7jPdlnhACII"],
  },
  "AGS-CP-1306": {
    youtubeURL: ["https://youtu.be/phuDe7XvYwQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1308": {
    youtubeURL: ["https://youtu.be/phuDe7XvYwQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1309": {
    youtubeURL: ["https://youtu.be/phuDe7XvYwQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1307": {
    youtubeURL: ["https://youtu.be/phuDe7XvYwQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-905": {
    youtubeURL: ["https://youtu.be/BKzIfhq_848"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1582": {
    youtubeURL: ["https://youtu.be/BKzIfhq_848"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1216": {
    youtubeURL: ["https://youtu.be/BKzIfhq_848"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1215": {
    youtubeURL: ["https://youtu.be/BKzIfhq_848"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1264": {
    youtubeURL: ["https://youtu.be/bkIoFLJYqDU"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-971": {
    youtubeURL: ["https://youtu.be/bkIoFLJYqDU"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1447": {
    youtubeURL: ["https://youtu.be/Xhz1PTPZvKs"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1448": {
    youtubeURL: ["https://youtu.be/Xhz1PTPZvKs"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1455": {
    youtubeURL: ["https://youtu.be/Xhz1PTPZvKs"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-HW-773": {
    youtubeURL: ["https://youtu.be/loh0IChvgEg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/5QAsnEtSfb0"],
  },
  "AGS-HW-774": {
    youtubeURL: ["https://youtu.be/loh0IChvgEg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/5QAsnEtSfb0"],
  },
  "AGS-HW-1591": {
    youtubeURL: ["https://youtu.be/loh0IChvgEg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/5QAsnEtSfb0"],
  },
  "AGS-S-3232": {
    youtubeURL: ["https://youtu.be/xADWtXGNK0U"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CN-612": {
    youtubeURL: ["https://youtu.be/KYRUhK4CUzY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/JEP9PJTbmi0"],
  },
  "AGS-CN-627": {
    youtubeURL: ["https://youtu.be/KYRUhK4CUzY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/JEP9PJTbmi0"],
  },
  "AGS-CN-766": {
    youtubeURL: ["https://youtu.be/KYRUhK4CUzY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/JEP9PJTbmi0"],
  },
  "AGS-HW-1244": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-HW-1273": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-HW-1286": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-HW-1311": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-HW-708": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-HW-710": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-HW-862": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-HW-863": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-HW-861": {
    youtubeURL: ["https://youtu.be/TR6-3JLPong"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/uKwBiiSzxjI"],
  },
  "AGS-CN-818": {
    youtubeURL: ["https://youtu.be/BLvq9r44se4"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/jOy1-O9TT5s"],
  },
  "AGS-CN-759": {
    youtubeURL: ["https://youtu.be/BLvq9r44se4"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/jOy1-O9TT5s"],
  },
  "AGS-CN-846": {
    youtubeURL: ["https://youtu.be/BLvq9r44se4"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/jOy1-O9TT5s"],
  },
  "AGS-S-2963": {
    youtubeURL: ["https://youtu.be/SfyKMdK2p6s"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1147": {
    youtubeURL: ["https://youtu.be/_BVC5nUj1vY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: [
      "https://youtu.be/VAlwo39BTK4",
      "https://youtu.be/u8RrAsizALM",
    ],
  },
  "AGS-CP-1217": {
    youtubeURL: ["https://youtu.be/_BVC5nUj1vY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: [
      "https://youtu.be/VAlwo39BTK4",
      "https://youtu.be/u8RrAsizALM",
    ],
  },
  "AGS-CP-1218": {
    youtubeURL: ["https://youtu.be/_BVC5nUj1vY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: [
      "https://youtu.be/VAlwo39BTK4",
      "https://youtu.be/u8RrAsizALM",
    ],
  },
  "AGS-CP-1209": {
    youtubeURL: ["https://youtu.be/cvLfA1RwAd0"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1211": {
    youtubeURL: ["https://youtu.be/cvLfA1RwAd0"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1210": {
    youtubeURL: ["https://youtu.be/cvLfA1RwAd0"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1434": {
    youtubeURL: ["https://youtu.be/dCHuBW4skUY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/8hu7_4g-5fM"],
  },
  "AGS-CP-1435": {
    youtubeURL: ["https://youtu.be/dCHuBW4skUY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/8hu7_4g-5fM"],
  },
  "AGS-CP-1436": {
    youtubeURL: ["https://youtu.be/dCHuBW4skUY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/8hu7_4g-5fM"],
  },
  "AGS-CP-1437": {
    youtubeURL: ["https://youtu.be/dCHuBW4skUY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/8hu7_4g-5fM"],
  },
  "AGS-CP-1294": {
    youtubeURL: ["https://youtu.be/BCE9l2wnegg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1488": {
    youtubeURL: ["https://youtu.be/BCE9l2wnegg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1675": {
    youtubeURL: ["https://youtu.be/BCE9l2wnegg"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1649": {
    youtubeURL: ["https://youtu.be/BQjpODVpKKM"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-602": {
    youtubeURL: ["https://youtu.be/BQjpODVpKKM"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/5lMyuYVzLpw"],
  },
  "AGS-CP-1612": {
    youtubeURL: ["https://youtu.be/BQjpODVpKKM"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/5lMyuYVzLpw"],
  },
  "AGS-CP-601": {
    youtubeURL: ["https://youtu.be/BQjpODVpKKM"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/5lMyuYVzLpw"],
  },
  "AGS-CP-947": {
    youtubeURL: ["https://youtu.be/BQjpODVpKKM"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/5lMyuYVzLpw"],
  },
  "AGS-CP-949": {
    youtubeURL: ["https://youtu.be/BQjpODVpKKM"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/5lMyuYVzLpw"],
  },
  "AGS-CP-1232": {
    youtubeURL: ["https://youtu.be/PY3WqdB6qgE"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/tFM0ZTbUzYE"],
  },
  "AGS-CP-938": {
    youtubeURL: ["https://youtu.be/PY3WqdB6qgE"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/tFM0ZTbUzYE"],
  },
  "AGS-CP-1234": {
    youtubeURL: ["https://youtu.be/PY3WqdB6qgE"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/tFM0ZTbUzYE"],
  },
  "AGS-CP-1102": {
    youtubeURL: ["https://youtu.be/PY3WqdB6qgE"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/tFM0ZTbUzYE"],
  },
  "AGS-CP-937": {
    youtubeURL: ["https://youtu.be/PY3WqdB6qgE"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/tFM0ZTbUzYE"],
  },
  "AGS-CN-713": {
    youtubeURL: ["https://youtu.be/7zuA_OISESo"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/9qQ3ZDgiDsI"],
  },
  "AGS-CN-864": {
    youtubeURL: ["https://youtu.be/7zuA_OISESo"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/9qQ3ZDgiDsI"],
  },
  "AGS-CN-941": {
    youtubeURL: ["https://youtu.be/7zuA_OISESo"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/9qQ3ZDgiDsI"],
  },
  "AGS-CP-1157": {
    youtubeURL: ["https://youtu.be/AemQ6WuUMNk"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/I1KKtSr5UMI"],
  },
  "AGS-CN-004": {
    youtubeURL: ["https://youtu.be/-mnF_78qKTQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/hShP2dd3lBc"],
  },
  "AGS-CN-801": {
    youtubeURL: ["https://youtu.be/-mnF_78qKTQ"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT", "B2BMH"],
    youtubeURLB2BMH: ["https://youtu.be/hShP2dd3lBc"],
  },
  "AGS-CN-777": {
    youtubeURL: ["https://youtu.be/yL52Za6EOyY"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1219": {
    youtubeURL: ["https://youtu.be/u8RrAsizALM"],
    source: ["B2BMH"],
  },
  "AGS-CP-1220": {
    youtubeURL: ["https://youtu.be/u8RrAsizALM"],
    source: ["B2BMH"],
  },
  "AGS-CP-1468": {
    youtubeURL: ["https://youtu.be/voVQvMPbRT8"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1467": {
    youtubeURL: ["https://youtu.be/voVQvMPbRT8"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1469": {
    youtubeURL: ["https://youtu.be/voVQvMPbRT8"],
    source: ["B2BRJ", "B2BMP", "B2BUP", "B2BGJ", "B2BHR", "B2BCT"],
  },
  "AGS-CP-1473,": {
    youtubeURLB2BMH: ["https://youtu.be/whKzVsXbvBs"],
    source: ["B2BMH"],
  },
  "AGS-CP-1474,": {
    youtubeURLB2BMH: ["https://youtu.be/whKzVsXbvBs"],
    source: ["B2BMH"],
  },
  "AGS-CP-1475,": {
    youtubeURLB2BMH: ["https://youtu.be/whKzVsXbvBs"],
    source: ["B2BMH"],
  },
  "AGS-CN-737,": {
    youtubeURLB2BMH: ["https://youtu.be/EeZZkPieL7Q"],
    source: ["B2BMH"],
  },
  "AGS-CN-738,": {
    youtubeURLB2BMH: ["https://youtu.be/EeZZkPieL7Q"],
    source: ["B2BMH"],
  },
  "AGS-CN-858,": {
    youtubeURLB2BMH: ["https://youtu.be/EeZZkPieL7Q"],
    source: ["B2BMH"],
  },
  "AGS-CN-880,": {
    youtubeURLB2BMH: ["https://youtu.be/EeZZkPieL7Q"],
    source: ["B2BMH"],
  },
  "AGS-CP-600,": {
    youtubeURLB2BMH: ["https://youtu.be/Y0RWmTDTrts"],
    source: ["B2BMH"],
  },
  "AGS-CP-910,": {
    youtubeURLB2BMH: ["https://youtu.be/Y0RWmTDTrts"],
    source: ["B2BMH"],
  },
  "AGS-CP-911,": {
    youtubeURLB2BMH: ["https://youtu.be/Y0RWmTDTrts"],
    source: ["B2BMH"],
  },
  "AGS-KIT-1076,": {
    youtubeURLB2BMH: ["https://youtu.be/Y0RWmTDTrts"],
    source: ["B2BMH"],
  },
  "AGS-S-4523,": {
    youtubeURLB2BMH: ["https://youtu.be/jVGsD3Bpt3k"],
    source: ["B2BMH"],
  },
  "AGS-S-3189,": {
    youtubeURLB2BMH: ["https://youtu.be/LqUHlyJKtCM"],
    source: ["B2BMH"],
  },
  "AGS-S-4550,": {
    youtubeURLB2BMH: ["https://youtu.be/Fy6B7fpwVco"],
    source: ["B2BMH"],
  },
  "AGS-CP-1263,": {
    youtubeURLB2BMH: ["https://youtu.be/u8RrAsizALM"],
    source: ["B2BMH"],
  },
  "AGS-CP-1615,": {
    youtubeURLB2BMH: ["https://youtu.be/u8RrAsizALM"],
    source: ["B2BMH"],
  },
};
