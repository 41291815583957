import { useState } from "react";

import PartnerService from "../Services/PartnerService";
import StateService from "../Services/StateService";
import HisaabService from "../Services/HisaabService";
import AnalyticsService from "../Services/AnalyticsService";
import { useNavigate } from "react-router-dom";

function useProfile() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState("not-started");
  const [errorCode, setErrorCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFailure = (errorCode, errorMessage, failureEvent) => {
    setErrorCode(errorCode);
    setErrorMessage(errorMessage);
    AnalyticsService.pushEvent(failureEvent, {
      errorCode: errorCode,
    });
    setLoading("done");
  };
  const saveProfile = (
    farmerId,
    successRedirectUrl,
    successEvent,
    failureEvent
  ) => {
    setLoading("started");
    const { axiosRequest } = PartnerService.getPartnerDetailedData(farmerId);
    axiosRequest
      .then((partnerDataResponse) => {
        if (
          partnerDataResponse?.data?.responseData?.farmerType &&
          (partnerDataResponse.data.responseData.farmerType === "B2B Partner" ||
            partnerDataResponse.data.responseData.farmerType ===
              "B2B Onboarding")
        ) {
          if (
            partnerDataResponse?.data?.responseData?.address?.profileAddress
              ?.state
          ) {
            let source = ``;
            let stateName =
              partnerDataResponse.data.responseData.address.profileAddress
                .state;

            let stateFromName = StateService.getStateByName(stateName);

            let stateCode = ``;
            if (
              stateFromName &&
              stateFromName.stateCode &&
              stateFromName.stateCode !== ""
            ) {
              const { axiosRequest } =
                HisaabService.getInstitutionDetails(farmerId);
              axiosRequest
                .then((institutionalDetailsResponse) => {
                  if (
                    institutionalDetailsResponse?.data?.responseData?.status ===
                      "ACTIVE" ||
                    (institutionalDetailsResponse?.data?.responseData
                      ?.status === "UNVERIFIED" &&
                      PartnerService.checkIfFieldAgent()) ||
                    (institutionalDetailsResponse?.data?.responseData
                      ?.status === "INACTIVE" &&
                      institutionalDetailsResponse?.data?.responseData
                        ?.inactivatedOnDate > 0 &&
                      !PartnerService.isPartnerInactiveSinceMoreThanNdays(
                        institutionalDetailsResponse?.data?.responseData
                          ?.inactivatedOnDate
                      ))
                  ) {
                    stateCode = stateFromName.stateCode;
                    source = `B2B${stateCode}`;
                    localStorage.setItem("source", source);
                    localStorage.setItem(`state`, stateName);

                    localStorage.setItem(
                      "farmerId",
                      partnerDataResponse.data.responseData.farmerId
                    );

                    localStorage.setItem(
                      "userId",
                      partnerDataResponse.data.responseData.userId
                    );

                    localStorage.setItem(
                      "mobile",
                      partnerDataResponse.data.responseData.mobile1
                    );
                    localStorage.setItem(
                      "onboardingPartnerMobileNumber",
                      partnerDataResponse.data.responseData.mobile1
                    );
                    localStorage.setItem(
                      "pinCode",
                      partnerDataResponse.data.responseData.address
                        .profileAddress.pinCode
                    );

                    localStorage.setItem(
                      `creditPeriod`,
                      institutionalDetailsResponse.creditPeriod
                    );

                    localStorage.setItem(
                      "partnerStatus",
                      institutionalDetailsResponse?.data?.responseData?.status
                    );

                    if (
                      institutionalDetailsResponse?.data?.responseData
                        ?.documentSubmissionStatus
                    ) {
                      localStorage.setItem(
                        "documentSubmissionStatus",
                        institutionalDetailsResponse?.data?.responseData
                          ?.documentSubmissionStatus
                      );
                    }

                    if (
                      institutionalDetailsResponse?.data?.responseData
                        ?.areDocumentsVerified
                    ) {
                      localStorage.setItem(
                        "areDocumentsVerified",
                        institutionalDetailsResponse?.data?.responseData
                          ?.areDocumentsVerified
                      );
                    }

                    if (partnerDataResponse?.data?.responseData?.segment) {
                      localStorage.setItem(
                        "clubSegment",
                        partnerDataResponse?.data?.responseData?.segment
                      );
                    }

                    AnalyticsService.pushProfile(
                      partnerDataResponse.data.responseData.farmerId,
                      partnerDataResponse.data.responseData.mobile1,
                      source
                    );
                    setLoading("done");

                    navigate(successRedirectUrl);
                    AnalyticsService.pushEvent(successEvent);
                  } else {
                    handleFailure(
                      "PARTNER_NOT_ACTIVE",
                      "Something went wrong",
                      failureEvent
                    );
                  }
                })
                .catch((error) => {
                  handleFailure(
                    "INSTITUTIONAL_DETAILS_FETCH_FAILED",
                    "Something went wrong",
                    failureEvent
                  );
                });
            } else {
              handleFailure(
                "PROFILE_INVALID_STATE_NAME_OR_CODE",
                "Something went wrong",
                failureEvent
              );
            }
          } else {
            handleFailure(
              "PROFILE_NO_STATE",
              "Something went wrong",
              failureEvent
            );
          }
        } else {
          handleFailure(
            "PROFILE_NON_B2B_PARTNER",
            "Something went wrong",
            failureEvent
          );
        }
      })
      .catch((error) => {
        handleFailure(
          "CRM_PROFILE_DETAILS_FETCH_FAILED",
          "Something went wrong",
          failureEvent
        );
      });
  };

  return { saveProfile, loading, errorCode, errorMessage };
}

export default useProfile;
