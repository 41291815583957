import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, Skeleton, CardContent, Grid } from "@mui/material";
import _ from "lodash";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import ReturnOrderDetailCard from "../Common/ReturnOrderDetailCard";
import OrderService from "../../Services/OrderService";
import ReturnOrderStatusIcons from "./ReturnOrderStatusIcons";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import EmptyError from "../EmptyError/EmptyError";
import CommonConstantValues from "../../Services/CommonConstantValues";
import AnalyticsService from "../../Services/AnalyticsService";
import CreateReturnDialog from "../Common/CreateReturnDialog";

function ReturnOrders() {
  let navigate = useNavigate();
  const [returnOrders, setReturnOrders] = useState([]);
  const [loading, setLoading] = useState(CommonConstantValues.NOT_STARTED);

  useEffect(() => {
    const returnsPageViewed = {
      page: `/orders/return`,
    };
    AnalyticsService.pushEvent(`Page Viewed`, returnsPageViewed);

    setLoading(CommonConstantValues.STARTED);
    const { axiosRequest, abortController } =
      OrderService.getAllReturnOrdersList();
    axiosRequest
      .then(({ data }) => {
        setLoading(CommonConstantValues.DONE);
        if (data?.responseData?.orders?.length) {
          _.forEach(data?.responseData?.orders, (items) => {
            items.statusDetails =
              ReturnOrderStatusIcons[items.status.toUpperCase()];
          });
          setReturnOrders(data?.responseData?.orders);
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading(CommonConstantValues.DONE);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleGoToOrderDetails = ({ orderId }) => {
    const orderDetailsClickedEvent = {
      page: `/orders/return`,
    };
    AnalyticsService.pushEvent(
      `Order Details Clicked`,
      orderDetailsClickedEvent
    );

    navigate(`/orders/return/${orderId}/order-detail`);
  };

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Return History`}
          backNavigation={true}
          showHisaab={true}
        />
      </ErrorBoundary>

      {loading === CommonConstantValues.STARTED &&
        returnOrders?.length == 0 && (
          <CardContent>
            <Grid item xs={4}>
              <Skeleton
                variant="rectangular"
                height={160}
                sx={{ marginTop: "10px" }}
              />
            </Grid>
            <Grid item xs={4}>
              <Skeleton
                variant="rectangular"
                height={160}
                sx={{ marginTop: "10px" }}
              />
            </Grid>
            <Grid item xs={4}>
              <Skeleton
                variant="rectangular"
                height={160}
                sx={{ marginTop: "10px" }}
              />
            </Grid>
          </CardContent>
        )}

      <ErrorBoundary>
        {loading === CommonConstantValues.DONE && returnOrders?.length === 0 ? (
          <EmptyError image={`Empty Cart.svg`} text={`No Returns found!`} />
        ) : (
          <Box>
            {returnOrders?.length ? (
              _.map(returnOrders, (orders) => {
                return (
                  <Container maxWidth={`xl`}>
                    <ReturnOrderDetailCard order={orders} />
                    <Box
                      sx={{
                        color: "#9D2123",
                        cursor: "pointer",
                        display: "flex",
                        padding: "12px",
                        background: "#ffffff",
                        borderTop: "1px solid #eeeeee",
                        marginTop: "-12px",
                        alignItems: "center",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "none",
                        justifyContent: "center",
                        borderBottomLeftRadius: "16px",
                        borderBottomRightRadius: "16px",
                        fontWeight: "700",
                        fontSize: "12px",
                      }}
                      onClick={() => handleGoToOrderDetails(orders)}
                    >
                      ORDER DETAILS
                      <ArrowForwardTwoToneIcon />
                    </Box>
                  </Container>
                );
              })
            ) : (
              <></>
            )}

            <Box
              style={{
                height: "120px",
              }}
            ></Box>
          </Box>
        )}

        <CreateReturnDialog title={`Create Return`} />
      </ErrorBoundary>
    </>
  );
}

export default ReturnOrders;
