import _ from "lodash";
import { Link } from "react-router-dom";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  productCard: {
    border: "none",
    marginTop: theme.spacing(1.25),
    background: "#E5F1EC",
  },
  productCardContent: {
    padding: theme.spacing(2, 1, 2, 1),
  },
  productImage: {
    height: "100%",
    width: "50px",
  },
  removeIconWrapper: {
    color: "#999999",
    padding: theme.spacing(0),
    height: theme.spacing(3),
  },
  productName: {
    lineHeight: `1.3`,
  },
  productQuantity: {
    lineHeight: `1.3`,
    marginTop: theme.spacing(0.5),
  },
}));

function BagFreeItems(props) {
  const classes = useStyles();

  const handleProductClick = (product, index, event) => {
    const productClickedEventData = {
      page: "Cart Page",
      position: index + 1,
      listPrice: product?.listPrice || 0,
      sellingPrice: product?.sellingPrice || 0,
      isOfferAvailable:
        product?.offers && Object.entries(product?.offers).length > 0
          ? true
          : false,
      productName: product?.productName || "",
      skuCode: product?.skuCode || "",
    };
    AnalyticsService.pushEvent(`Cart Product Clicked`, productClickedEventData);
  };

  return (
    <div>
      <ErrorBoundary message={``}>
        <>
          {_.map(props.cartProducts, (product, index) => {
            return (
              <Card
                className={classes.productCard}
                variant={"outlined"}
                key={product?.skuCode}
              >
                <div className={classes.productCardContent}>
                  <Grid
                    container
                    direction={`row`}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    spacing={0}
                  >
                    <Grid
                      item
                      xs={3}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Link
                        to={{
                          pathname: `/products/${product.skuCode}`,
                          state: { from: window.location.pathname },
                        }}
                        onClick={handleProductClick.bind(this, product, index)}
                      >
                        <img
                          src={"/icons/freeProduct.svg"}
                          className={classes.productImage}
                          alt=""
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid
                        container
                        direction={`column`}
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}
                        spacing={0}
                      >
                        <Grid
                          container
                          direction={`row`}
                          alignItems={"flex-start"}
                          justifyContent={"flex-start"}
                          spacing={0}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box>
                              <Typography
                                variant={"subtitle1"}
                                className={classes.productName}
                              >
                                {product.productName}
                              </Typography>
                              <Typography
                                variant={"subtitle1"}
                                className={classes.productQuantity}
                              >
                                Qty: {product?.quantity}
                              </Typography>
                            </Box>

                            {/* <IconButton
                              className={classes.removeIconWrapper}
                              onClick={() => {
                                props.handleRemove(product);
                              }}
                              size="large"
                            >
                              <Close className={classes.removeIcon} />
                            </IconButton> */}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            );
          })}
        </>
      </ErrorBoundary>
    </div>
  );
}

export default BagFreeItems;
