import React, { useCallback, useEffect, useState } from "react";
import BlockUi from "react-block-ui";

import { ArrowRightAlt, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  Radio,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import _, { debounce, parseInt } from "lodash";
import useRazorpay from "react-razorpay";
import config from "../../config";
import useFocus from "../../Hooks/useFocus";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import CommonConstantValues from "../../Services/CommonConstantValues";
import HisaabService from "../../Services/HisaabService";
import PaymentService from "../../Services/PaymentService";
import {
  BillDetailFinalPriceItem,
  BillDetailPriceItem,
} from "../Cart/BillDetails";
import ProceedPaymentFailureDialog from "./ProceedPaymentFailureDialog";

const PayNowDialog = (props) => {
  const Razorpay = useRazorpay();
  useEffect(() => {
    if (
      (props.open && props.paymentAmount === 0) ||
      props.paymentAmount > CommonConstantValues.PAY_NOW_MAX_AMOUNT
    ) {
      setPaymentAmountType("otherAmount");
    }
  }, [props.paymentAmount, props.open]);
  const [inputRef, setInputFocus] = useFocus();

  const [paymentAmountType, setPaymentAmountType] = React.useState(
    props.amountTypeFromParams && props.amountTypeFromParams !== ""
      ? props.amountTypeFromParams === "other"
        ? "otherAmount"
        : "defaultAmount"
      : "defaultAmount"
  );
  const [openDebug, setOpenDebug] = useState(false);
  const [debugMessage, setDebugMessage] = useState("");
  const defaultPaymentAmountValue = props.paymentAmount;
  const [otherPaymentAmountValue, setOtherPaymentAmountValue] = React.useState(
    props.amountTypeFromParams &&
      props.amountTypeFromParams === "other" &&
      props.amountFromParams &&
      props.amountFromParams > 0
      ? props.amountFromParams
      : 0
  );
  const [loadingCashDiscountDetails, setLoadingCashDiscountDetails] =
    useState("not-started");
  const [cashDiscountDetails, setCashDiscountDetails] = useState(null);
  const [showProceedPaymentFailureDialog, setShowProceedPaymentFailureDialog] =
    useState(false);
  const [proceedPaymentErrorCode, setProceedPaymentErrorCode] = useState(null);

  const handleChange = (valueType) => {
    if (valueType === "otherAmount") {
      setInputFocus();
      if (otherPaymentAmountValue === 0) {
        setOtherPaymentAmountValue("");
      }
    } else {
      AnalyticsService.pushEvent("Pay Now Default Amount Selected", {
        type: props?.paymentType,
        amount: props.paymentAmount,
      });
    }
    setPaymentAmountType(valueType);
  };

  useEffect(() => {
    props?.open && calculateCashDiscount();
    if (paymentAmountType === "otherAmount") {
      AnalyticsService.pushEvent("Pay Now Other Amount Selected", {
        type: props?.paymentType,
        amount: props.paymentAmount,
      });
    }
  }, [props?.open, paymentAmountType]);

  const calculateCashDiscount = (event) => {
    if (
      props?.paymentType === "order" ||
      props?.paymentType === "productsalesgroup"
    ) {
      setLoadingCashDiscountDetails("started");
      const amount =
        paymentAmountType === "defaultAmount"
          ? defaultPaymentAmountValue || 0
          : (event?.target?.value || event?.target?.value === ""
              ? event?.target?.value
              : otherPaymentAmountValue) || 0;

      if (
        amount === 0 &&
        paymentAmountType === "otherAmount" &&
        (otherPaymentAmountValue === 0 || otherPaymentAmountValue === "")
      ) {
        // if user has selected "Settle Other Amount" but otherPaymentAmountValue is 0 or empty
        // skip making api call to backend & set CD details as 0
        setLoadingCashDiscountDetails("done");
        setCashDiscountDetails({
          cashDiscount: 0,
          netPayable: 0,
          totalAmountToSettle: 0,
          transactionReferenceNumber: null,
        });
      } else {
        const { axiosRequest } = HisaabService.getCashDiscount({
          amount,
          orderId: props?.orderId,
          paymentType: props?.paymentType,
          productSalesGroup: props?.productSalesGroup,
          lendingPartner: props?.lendingPartner,
        });
        axiosRequest
          .then((response) => {
            setLoadingCashDiscountDetails("done");
            setCashDiscountDetails(response?.data?.responseData);
          })
          .catch((error) => {
            setLoadingCashDiscountDetails("done");
            console.error(`Error Getting Cash Discount: `, error);
          });
      }
    }
  };

  const handleCDDebounced = useCallback(debounce(calculateCashDiscount, 1000), [
    paymentAmountType,
  ]);

  // useEffect(() => {
  //   if (props?.paymentType === "order" && paymentAmountType === "otherAmount") {
  //     setOtherPaymentAmountValue(props?.amountFromParams);
  //   }
  // }, [paymentAmountType, props?.amountFromParams]);

  const handleChangeOtherPaymentAmount = (event) => {
    setOtherPaymentAmountValue(event.target.value);
    if (
      props?.paymentType === "order" ||
      props?.paymentType === "productsalesgroup"
    ) {
      setLoadingCashDiscountDetails("started");
    }
    handleCDDebounced(event);

    if (event?.target?.value > CommonConstantValues.PAY_NOW_MAX_AMOUNT) {
      event.target.setCustomValidity(
        `Value must be less than or equal to ${new Intl.NumberFormat(
          "en-IN"
        ).format(CommonConstantValues.PAY_NOW_MAX_AMOUNT)}`
      );
    } else if (
      (props?.paymentType === "order" ||
        props?.paymentType === "productsalesgroup" ||
        props?.lendingPartnerDueAmount > 0) &&
      paymentAmountType === "otherAmount" &&
      event?.target?.value > defaultPaymentAmountValue
    ) {
      // for invoice-level payments & lending partner loan repayment, other amount cannot be greater than unsettled amount
      event.target.setCustomValidity(
        "Amount cannot be greater than full amount"
      );
    } else {
      event.target.setCustomValidity("");
    }
  };

  function handlePaymentViaWebSDK(razorpayConfig) {
    props.setPaymentLoading("done");

    let razorpayInstance = new Razorpay(razorpayConfig);
    razorpayInstance.open();
    razorpayInstance.on("payment.failed", function (response) {
      AnalyticsService.pushEvent("Payment Initiation Failure", {
        code: response?.error?.code,
        description: response?.error?.description,
        source: response?.error?.source,
        step: response?.error?.step,
        reason: response?.error?.reason,
        orderId: response?.error?.metadata?.order_id,
        paymentId: response?.error?.metadata?.payment_id,
        type: props?.paymentType,
        paymentAmountType: paymentAmountType,
        amount: razorpayConfig?.amount,
        sdk: "web",
        checkoutOrderType: props?.checkoutOrderType || "",
      });
      props.handleRazorpayFailure("RAZORPAY_FAILURE", response?.error?.code);
    });

    AnalyticsService.pushEvent("Payment Initiation Successful", {
      type: props?.paymentType,
      paymentAmountType: paymentAmountType,
      amount: razorpayConfig?.amount,
      sdk: "web",
      checkoutOrderType: props?.checkoutOrderType || "",
    });
  }

  const handleProceedPaymentPayNow = (event) => {
    event.preventDefault();
    props.setPaymentLoading("started");
    let amountToPay = null;
    const transactionReferenceNumber =
      cashDiscountDetails?.transactionReferenceNumber;
    if (
      props?.paymentType === "order" ||
      props?.paymentType === "productsalesgroup"
    ) {
      amountToPay = cashDiscountDetails?.netPayable;
    } else {
      if (paymentAmountType === "defaultAmount") {
        amountToPay = parseInt(defaultPaymentAmountValue);
      } else if (paymentAmountType === "otherAmount") {
        amountToPay = parseInt(otherPaymentAmountValue);
      }
    }

    AnalyticsService.pushEvent("Proceed Payment Clicked", {
      type: props?.paymentType,
      paymentAmountType: paymentAmountType,
      amount:
        props?.paymentType === "order" ||
        props?.paymentType === "productsalesgroup"
          ? cashDiscountDetails?.totalAmountToSettle
          : amountToPay,
      transactionReferenceNumber: transactionReferenceNumber
        ? transactionReferenceNumber
        : "",
      cashDiscountAmount:
        props?.paymentType === "order" ||
        props?.paymentType === "productsalesgroup"
          ? cashDiscountDetails?.cashDiscount
          : "",
      netPayableAmount:
        props?.paymentType === "order" ||
        props?.paymentType === "productsalesgroup"
          ? cashDiscountDetails?.netPayable
          : "",
      checkoutOrderType: props?.checkoutOrderType || "",
    });

    let paymentErrorCode = null;
    let initiationErrorCode = null;
    let checkoutOrderType = props?.checkoutOrderType
      ? props?.checkoutOrderType
      : null;
    const { axiosRequest } = PaymentService.initiatePayment({
      paymentType: props?.paymentType,
      amount: amountToPay,
      orderId: props?.orderId,
      transactionReferenceNumber,
      checkoutOrderType,
      productSalesGroup: props?.productSalesGroup,
      redirectUrl: `${config.appUrl}/hisaab/transactions`,
      lendingPartnerDueAmount:
        props?.lendingPartner === "RUPIFI" ? amountToPay : 0,
      lendingPartner: props?.lendingPartner,
    });
    axiosRequest
      .then((response) => {
        if (
          response?.data?.responseData &&
          !_.isEmpty(response?.data?.responseData)
        ) {
          let responseData = response.data.responseData;
          if (
            responseData?.paymentGateway === "RUPIFI_PAYMENT" &&
            responseData?.paymentGatewayData?.repaymentUrl
          ) {
            window.open(
              responseData?.paymentGatewayData?.repaymentUrl,
              "_self"
            );
            props?.setOpen(false);
            props.setPaymentLoading("done");
          } else {
            let razorPayConfig = {
              key: responseData.apiKey,
              amount: amountToPay,
              currency: "INR",
              name: "AgroStar",
              description: "Payment Amount",
              order_id: responseData.razorpayOrderId,
              callback_url: `${config.paymentRedirectionUrl}paymentapi/v1/paymentredirect`,
              redirect: true,
              remember_customer: false,
              hidden: {
                contact: true,
                email: true,
              },
              modal: {
                confirm_close: true,
                ondismiss: function (response) {
                  props.setPaymentLoading("done");
                  AnalyticsService.pushEvent("Razorpay Payment SDK Closed");
                },
              },
              prefill: {
                // name: localStorage.getItem("partnerName"),
                email: "rzpcustomer.payment@agrostar.in",
                contact: localStorage.getItem("mobile"),
                // method: "upi",
              },

              theme: {
                color: "#9D2123",
              },
              // notes: {
              //   address: "Razorpay Corporate Office",
              // },
              razorpaySuccessCallbackUrl: `${config.appUrl}/hisaab/payment-status/`,
              razorpayFailureCallbackUrl: `${config.appUrl}/hisaab/razorpay-failure/RAZORPAY_FAILURE`,
            };

            if (
              props?.paymentType === "order" ||
              props?.paymentType === "productsalesgroup"
            ) {
              razorPayConfig["totalAmountToSettle"] =
                cashDiscountDetails?.totalAmountToSettle;
            }

            if (responseData.apiKey && responseData.razorpayOrderId) {
              if (AndroidService.checkIfAndroid()) {
                if (AndroidService.getAndroidVersion()) {
                  // setOpenDebug(true);

                  let { appVersionCode } = AndroidService.getAndroidVersion();
                  // setOpenDebug(true);
                  // setDebugMessage(
                  //   `in get version; android version ${appVersionCode}`
                  // );
                  if (appVersionCode && parseInt(appVersionCode) >= 8) {
                    handlePaymentViaAndroidNativeSDK(razorPayConfig);
                  } else {
                    // setOpenDebug(true);
                    // setDebugMessage(`No appVersionCode;`);
                    handlePaymentViaWebSDK(razorPayConfig);
                  }
                } else {
                  // setOpenDebug(true);
                  // setDebugMessage(`No getAppVersion();`);
                  handlePaymentViaWebSDK(razorPayConfig);
                }
              } else {
                // setOpenDebug(true);
                // setDebugMessage(`No Android;`);
                handlePaymentViaWebSDK(razorPayConfig);
              }
            } else {
              props.setPaymentLoading("done");

              if (!responseData.razorpayOrderId) {
                initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_ORDER_ID";
                setProceedPaymentErrorCode(initiationErrorCode);
                setShowProceedPaymentFailureDialog(true);
              }
              if (!responseData.apiKey) {
                initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_API_KEY";
                setProceedPaymentErrorCode(initiationErrorCode);
                setShowProceedPaymentFailureDialog(true);
              }
            }
          }
        } else {
          initiationErrorCode = "PAYMENT_INIT_NO_RESPONSE_DATA";
          setProceedPaymentErrorCode(initiationErrorCode);
          setShowProceedPaymentFailureDialog(true);
          props.setPaymentLoading("done");
        }
        if (initiationErrorCode) {
          AnalyticsService.pushEvent("Payment Initiation Failed", {
            type: props?.paymentType,
            paymentAmountType: paymentAmountType,
            amount: amountToPay,
            errorCode: initiationErrorCode,
            checkoutOrderType: props?.checkoutOrderType || "",
          });
        }
      })
      .catch(() => {
        initiationErrorCode = "PAYMENT_INIT_FAILED";
        setProceedPaymentErrorCode(initiationErrorCode);
        AnalyticsService.pushEvent("Payment Initiation Failed", {
          type: props?.paymentType,
          paymentAmountType: paymentAmountType,
          amount: amountToPay,
          errorCode: initiationErrorCode,
          checkoutOrderType: props?.checkoutOrderType || "",
        });
        setShowProceedPaymentFailureDialog(true);
        props.setPaymentLoading("done");
      })
      .finally(() => {});
  };

  const handlePaymentViaAndroidNativeSDK = (razorPayConfig) => {
    props.setPaymentLoading("done");
    delete razorPayConfig.modal.ondismiss;
    razorPayConfig.redirect = false;
    delete razorPayConfig.callback_url;

    let razorPayMetadataStringified = JSON.stringify(razorPayConfig);

    try {
      /*global Android*/
      if (Android) {
        Android.startRazorpayPaymentFlow(razorPayMetadataStringified);
        AnalyticsService.pushEvent("Payment Initiation Successful", {
          type: props?.paymentType,
          paymentAmountType: paymentAmountType,
          amount: razorPayConfig?.amount,
          sdk: "android",
          checkoutOrderType: props?.checkoutOrderType || "",
        });
      } else {
        // setOpenDebug(true);
        // setDebugMessage(`No Android;in native handler`);
        handlePaymentViaWebSDK(razorPayConfig);
      }
    } catch (e) {
      // setOpenDebug(true);
      // setDebugMessage(`in try native catch; error- ${JSON.stringify(e)}`);
      handlePaymentViaWebSDK(razorPayConfig);
    }
  };
  return (
    <>
      <SwipeableDrawer
        anchor={`bottom`}
        onOpen={() => {}}
        open={props.open}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        onClose={() => {
          AnalyticsService.pushEvent("Pay Now Dialog Closed", {
            amount: props.paymentAmount,
            type: props?.paymentType,
            checkoutOrderType: props?.checkoutOrderType || "",
          });
          setOtherPaymentAmountValue(0);
          setPaymentAmountType("defaultAmount");
          props.handleClose();
        }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          },
        }}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent={`flex-start`}
          sx={{ marginTop: "20px" }}
        >
          <Grid item xs={5} />
          <Grid item xs={1}>
            <Box
              sx={{
                border: "3px solid #DDDDDD",
                width: "43px",
                height: "0px",
                borderRadius: "5px",
              }}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={1}>
            <IconButton
              sx={{ marginTop: "-16px" }}
              onClick={() => {
                AnalyticsService.pushEvent("Pay Now Dialog Closed", {
                  amount: props.paymentAmount,
                  type: props?.paymentType,
                  checkoutOrderType: props?.checkoutOrderType || "",
                });
                setOtherPaymentAmountValue(0);
                setPaymentAmountType("defaultAmount");
                props.handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        {/*<Grid*/}
        {/*  container*/}
        {/*  alignItems="center"*/}
        {/*  justifyContent={`center`}*/}
        {/*  sx={{ marginTop: "8px", marginBottom:"20px" }}*/}
        {/*>*/}
        {/*  <Grid item xs={10}>*/}
        {/*    <img*/}
        {/*      src={`https://static.agrostar.in/agro-elearning-firebase/v0/b/agro-elearning/o/saathi%2Fbanners%2Fpay-now-offer.png?alt=media&timeStamp=${Date.now()*/}
        {/*        .toString()*/}
        {/*        .slice(0, -5)}`}*/}
        {/*      width={"100%"}*/}
        {/*      alt={"Pay Now Offer"}*/}
        {/*      onClick={() => {*/}
        {/*        AnalyticsService.pushEvent("Pay Now Banner Clicked");*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        <form onSubmit={handleProceedPaymentPayNow}>
          {props?.checkoutOrderType !== "FIELD-APP-SELF-CHECKOUT" ? (
            <FormGroup>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ padding: "0 20px" }}
              >
                <Grid item xs={5}>
                  <Typography variant={`body1`} sx={{ fontSize: "15px" }}>
                    Settle Full Amount
                  </Typography>

                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    onClick={() => {
                      if (
                        props.paymentAmount >
                        CommonConstantValues.PAY_NOW_MAX_AMOUNT
                      ) {
                        return null;
                      }
                      handleChange("defaultAmount");
                    }}
                    value={"defaultAmount"}
                  >
                    <FormControlLabel
                      sx={{ marginLeft: "1px" }}
                      label={`₹${new Intl.NumberFormat("en-IN").format(
                        defaultPaymentAmountValue
                      )}`}
                      control={
                        <>
                          <Radio
                            checked={paymentAmountType === "defaultAmount"}
                            // onChange={handleChange}
                            // value={"defaultAmount"}
                          />
                        </>
                      }
                      disabled={
                        props.paymentAmount >
                        CommonConstantValues.PAY_NOW_MAX_AMOUNT
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={`body1`} sx={{ fontSize: "15px" }}>
                    Settle Other Amount
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "8px",
                      marginTop: "6px",
                    }}
                    value={"otherAmount"}
                    onClick={() => {
                      handleChange("otherAmount");
                    }}
                  >
                    <FormControl variant="outlined" sx={{ marginLeft: "10px" }}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={paymentAmountType === "otherAmount"}
                          />
                        }
                        label={
                          <Input
                            autoFocus={paymentAmountType === "otherAmount"}
                            required={paymentAmountType === "otherAmount"}
                            onChange={handleChangeOtherPaymentAmount}
                            inputRef={inputRef}
                            id="input-with-icon-adornment"
                            value={otherPaymentAmountValue}
                            onFocus={() => {
                              setPaymentAmountType("otherAmount");
                            }}
                            inputProps={{
                              type: "number",
                              min: paymentAmountType === "otherAmount" ? 1 : 0,
                              max: CommonConstantValues.PAY_NOW_MAX_AMOUNT,
                            }}
                            sx={{
                              "& .MuiInputBase-input": {
                                minWidth: "70px !important",
                              },
                            }}
                            startAdornment={<Typography>₹ </Typography>}
                          />
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </FormGroup>
          ) : (
            <Typography sx={{ padding: "0px 16px", fontWeight: "bold" }}>
              {`Please make the payment to process the order`}
            </Typography>
          )}

          {(props?.paymentType === "order" ||
            props?.paymentType === "productsalesgroup") && (
            <BlockUi
              tag="div"
              keepInView
              message={`Calculating Cash Discount`}
              blocking={loadingCashDiscountDetails === "started"}
              style={{ minHeight: `185px` }}
            >
              <Grid
                container
                justifyContent="center"
                sx={{
                  marginTop: "20px",
                  padding: "30px 16px 40px",
                  background: "#EAE2C7",
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: "0",
                    left: "2.5%",
                    width: "94%",
                    background:
                      "radial-gradient(ellipse at center, #FFF 0%, #FFF 40%, transparent 8%)",
                    backgroundSize: "calc(94% / 12) 100%",
                    backgroundRepeat: "repeat-x",
                    transform: "translate(0, -50%)",
                    aspectRatio: 12,
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: "0",
                    left: "2.5%",
                    width: "94%",
                    background:
                      "radial-gradient(ellipse at center, #FFF 0%, #FFF 40%, transparent 8%)",
                    backgroundSize: "calc(94% / 12) 100%",
                    backgroundRepeat: "repeat-x",
                    transform: "translate(0, 50%)",
                    aspectRatio: 12,
                  },
                }}
              >
                <Grid item xs={12}>
                  <BillDetailPriceItem
                    title={
                      props?.checkoutOrderType === "FIELD-APP-SELF-CHECKOUT"
                        ? `Amount`
                        : `Settlement Amount`
                    }
                    value={`₹${new Intl.NumberFormat("en-IN").format(
                      cashDiscountDetails?.totalAmountToSettle || 0
                    )}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BillDetailPriceItem
                    title={`Cash Discount`}
                    value={
                      <span style={{ color: "#00733E" }}>
                        - ₹
                        {new Intl.NumberFormat("en-IN").format(
                          cashDiscountDetails?.cashDiscount || 0
                        )}
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <BillDetailFinalPriceItem
                    title={
                      props?.checkoutOrderType === "FIELD-APP-SELF-CHECKOUT"
                        ? `Net Payable Amount`
                        : `Payable Amount`
                    }
                    value={`₹${new Intl.NumberFormat("en-IN").format(
                      cashDiscountDetails?.netPayable || 0
                    )}`}
                  />
                  <Box
                    sx={{
                      margin: "0 16px",
                      borderTop: "1px dashed #00000080",
                    }}
                  />
                </Grid>
              </Grid>
            </BlockUi>
          )}

          <Button
            fullWidth
            size="large"
            type={"submit"}
            variant={`contained`}
            sx={{
              height: "45px",
              marginTop: "25px",
              borderRadius: "0",
              fontSize: "14px",
            }}
            disabled={
              localStorage.getItem("role") === "fieldAgent" ||
              (props.paymentAmount === 0 &&
                paymentAmountType === "defaultAmount") ||
              loadingCashDiscountDetails === "started"
            }
            endIcon={<ArrowRightAlt />}
          >
            Proceed to Pay
          </Button>
        </form>
      </SwipeableDrawer>
      {/*<DebugDialog*/}
      {/*  open={openDebug}*/}
      {/*  debugMessage={debugMessage}*/}
      {/*  handleCloseDebug={() => setOpenDebug(false)}*/}
      {/*/>*/}
      <ProceedPaymentFailureDialog
        open={showProceedPaymentFailureDialog}
        retryPayment={handleProceedPaymentPayNow}
        errorCode={proceedPaymentErrorCode}
        handleClose={() => {
          setShowProceedPaymentFailureDialog(false);
        }}
        paymentType={props?.paymentType}
        orderId={props?.orderId}
        initiationFailureRetryRedirectionLink={
          props?.initiationFailureRetryRedirectionLink
        }
      />
    </>
  );
};

export default PayNowDialog;
