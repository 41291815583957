import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import _ from "lodash";
import UploadDocument from "./UploadDocument";
import EastIcon from "@mui/icons-material/East";
import ErrorBoundary from "../Common/ErrorBoundary";
import OnboardingProgressStepper from "./OnboardingProgressStepper";
import { useEffect, useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import DocumentService from "../../Services/DocumentService";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";
import PartnerService from "../../Services/PartnerService";
import StateService from "../../Services/StateService";
import HisaabService from "../../Services/HisaabService";
import Backdrop from "@mui/material/Backdrop";
import NeedHelp from "../Common/NeedHelp";
import ArrowForwardTwoTone from "@mui/icons-material/ArrowForwardTwoTone";
import UploadDocumentGroup from "./UploadDocumentGroup";
import TopBar from "../Common/TopBar";

const onboardingSteps = [
  { title: "Basic Info", subtitle: "Unlock Products" },
  { title: "Upload Documents", subtitle: "Unlock Pricing" },
  { title: "Deposit Security", subtitle: "Unlock Credit Limit" },
];

const imageFileTypeExtensions = ["jpg", "jpeg", "png"];
const documentFileTypeExtensions = ["pdf", "xls", "xlsx", "doc", "docx"];

const DocumentsUploadPage = () => {
  let navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fetchingUploadedFiles, setFetchingUploadedFiles] =
    useState("not-started");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [bankStatementList, setBankStatementList] = useState([]);
  const [gstCertificateList, setGstCertificateList] = useState([]);
  const [cibilReportList, setCibilReportList] = useState([]);
  const [blankChequeList, setBlankChequeList] = useState([]);
  const [shopFrontPhotoList, setShopFrontPhotoList] = useState([]);
  const [shopCounterPhotoList, setShopCounterPhotoList] = useState([]);
  const [shopShelvesPhotoList, setShopShelvesPhotoList] = useState([]);
  const [aadharFrontPhotoList, setAadharFrontPhotoList] = useState([]);
  const [aadharBackPhotoList, setAadharBackPhotoList] = useState([]);
  const [panCardPhotoList, setPanCardPhotoList] = useState([]);
  const [fertiliserLicenseList, setFertiliserLicenseList] = useState([]);
  const [seedLicenseList, setSeedLicenseList] = useState([]);
  const [pesticideLicenseList, setPesticideLicenseList] = useState([]);
  const [otherDocumentsList, setOtherDocumentsList] = useState([]);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [loadingProceedOnboarding, setLoadingProceedOnboarding] =
    useState("not-started");

  // mimeType is not needed for group uploads, as we have 2 different CTAs for upload
  const documentsTemplatesArray = [
    {
      title: "Shop Front Photo",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "SHOP_FRONT",
      isMandatory: true,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: shopFrontPhotoList,
      stateSetter: setShopFrontPhotoList,
      groupTitle: "Upload Shop Front Photo",
      onlyNeededInFieldAssistedOnboarding: false,
    },
    {
      title: "Shop Counter Photo",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "SHOP_COUNTER",
      isMandatory: true,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: shopCounterPhotoList,
      stateSetter: setShopCounterPhotoList,
      groupTitle: "Upload Shop Counter Photo",
      onlyNeededInFieldAssistedOnboarding: false,
    },
    {
      title: "Shop Shelves Photo",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "SHOP_SHELVES",
      isMandatory: true,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: shopShelvesPhotoList,
      stateSetter: setShopShelvesPhotoList,
      groupTitle: "Upload Shop Shelves Photo",
      onlyNeededInFieldAssistedOnboarding: false,
    },
    {
      title: "Aadhar Card Front",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "AADHAR_FRONT",
      isMandatory: true,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: aadharFrontPhotoList,
      stateSetter: setAadharFrontPhotoList,
      groupTitle: "Upload Aadhar Card Front Photo",
      onlyNeededInFieldAssistedOnboarding: false,
    },
    {
      title: "Aadhar Card Back",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "AADHAR_BACK",
      isMandatory: true,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: aadharBackPhotoList,
      stateSetter: setAadharBackPhotoList,
      groupTitle: "Upload Aadhar Card Back Photo",
      onlyNeededInFieldAssistedOnboarding: false,
    },
    {
      title: "PAN Card",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "PAN_CARD",
      isMandatory: true,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: panCardPhotoList,
      stateSetter: setPanCardPhotoList,
      groupTitle: "Upload PAN Card Photo",
      onlyNeededInFieldAssistedOnboarding: false,
    },
    {
      title: "Bank Statement",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png", "pdf"],
      identifier: "BANK_STATEMENT",
      isMandatory: false,
      uploadType: ["Image", "PDF"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: bankStatementList,
      stateSetter: setBankStatementList,
      groupTitle: "Upload Bank Statement",
      onlyNeededInFieldAssistedOnboarding: false,
    },
    {
      title: "GST Certificate",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png", "pdf"],
      identifier: "GST_CERTIFICATE",
      isMandatory: false,
      uploadType: ["Image", "PDF"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: gstCertificateList,
      stateSetter: setGstCertificateList,
      groupTitle: "Upload GST Certificate",
      onlyNeededInFieldAssistedOnboarding: false,
    },
    {
      title: "CIBIL Report",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png", "pdf"],
      identifier: "CIBIL_REPORT",
      isMandatory: false,
      uploadType: ["Image", "PDF"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: cibilReportList,
      stateSetter: setCibilReportList,
      groupTitle: "Upload CIBIL Report",
      onlyNeededInFieldAssistedOnboarding: true,
    },
    {
      title: "Blank Cheque",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "BLANK_CHEQUE",
      isMandatory: false,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: blankChequeList,
      stateSetter: setBlankChequeList,
      groupTitle: "Upload Blank Cheque",
      onlyNeededInFieldAssistedOnboarding: true,
    },
  ];
  const licensesTemplatesArray = [
    {
      title: "Fertiliser License",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "FERTILISER_LICENSE",
      isMandatory: false,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: fertiliserLicenseList,
      stateSetter: setFertiliserLicenseList,
      groupTitle: "Upload Fertiliser License",
    },
    {
      title: "Seed License",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "SEED_LICENSE",
      isMandatory: false,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: seedLicenseList,
      stateSetter: setSeedLicenseList,
      groupTitle: "Upload Seed License",
    },
    {
      title: "Pesticide License",
      subtitle: "",
      allowedFileExtensions: ["jpg", "jpeg", "png"],
      identifier: "PESTICIDE_LICENSE",
      isMandatory: false,
      uploadType: ["Image"],
      allowEditable: PartnerService.haveDocumentsBeenVerified() ? false : true,
      state: pesticideLicenseList,
      stateSetter: setPesticideLicenseList,
      groupTitle: "Upload Pesticide License",
    },
  ];

  const otherDocumentsTemplate = {
    title: "Other Documents",
    subtitle: "",
    allowedFileExtensions: ["jpg", "jpeg", "png", "pdf"],
    identifier: "ADDITIONAL_DOCUMENT",
    isMandatory: false,
    uploadType: ["Image", "PDF"],
    allowEditable: true,
    state: otherDocumentsList,
    stateSetter: setOtherDocumentsList,
    groupTitle: "Upload Any Other Additional Documents",
    onlyNeededInFieldAssistedOnboarding: false,
  };

  let areAllMandatoryDocsUploaded = true;
  let areAllOptionalDocsUploaded = true;
  let isAnyLicenseUploaded = false;
  // license upload is optional for "hardware" business category
  let skipLicenseUploadCheck =
    _.includes(_.map(businessCategories, _.lowerCase), "hardware") &&
    businessCategories?.length === 1;

  const mandatoryDocumentsTemplates = _.filter(
    documentsTemplatesArray,
    (template) => template?.isMandatory
  );
  for (let i = 0; i < mandatoryDocumentsTemplates?.length; i++) {
    let matchedDocument = _.find(uploadedFiles, (uploadedFile) =>
      uploadedFile?.type?.startsWith(
        mandatoryDocumentsTemplates?.[i]?.identifier
      )
    );
    if (!matchedDocument?.isUploaded) {
      areAllMandatoryDocsUploaded = false;
      break;
    }
  }

  const optionalDocumentsTemplates = PartnerService.isFieldAssistedOnboarding()
    ? _.filter(documentsTemplatesArray, (template) => !template?.isMandatory)
    : _.filter(
        documentsTemplatesArray,
        (template) =>
          !template?.isMandatory &&
          !template?.onlyNeededInFieldAssistedOnboarding
      );
  for (let i = 0; i < optionalDocumentsTemplates?.length; i++) {
    let matchedDocument = _.find(uploadedFiles, (uploadedFile) =>
      uploadedFile?.type?.startsWith(
        optionalDocumentsTemplates?.[i]?.identifier
      )
    );
    if (!matchedDocument?.isUploaded) {
      areAllOptionalDocsUploaded = false;
      break;
    }
  }

  for (let i = 0; i < licensesTemplatesArray?.length; i++) {
    let matchedDocument = _.find(uploadedFiles, (uploadedFile) =>
      uploadedFile?.type?.startsWith(licensesTemplatesArray?.[i]?.identifier)
    );
    if (matchedDocument?.isUploaded) {
      isAnyLicenseUploaded = true;
      break;
    }
  }

  const isPartialUploadComplete = skipLicenseUploadCheck
    ? areAllMandatoryDocsUploaded
    : areAllMandatoryDocsUploaded && isAnyLicenseUploaded;

  const isFullUploadComplete = skipLicenseUploadCheck
    ? areAllMandatoryDocsUploaded && areAllOptionalDocsUploaded
    : areAllMandatoryDocsUploaded &&
      areAllOptionalDocsUploaded &&
      isAnyLicenseUploaded;

  function replicateTemplateObject(
    objectToReplicate,
    timesToReplicate,
    startsAfterNumber
  ) {
    const replicatedObjects = [];
    for (
      let i = startsAfterNumber;
      i < startsAfterNumber + timesToReplicate;
      i++
    ) {
      const replicatedObj = _.cloneDeep(objectToReplicate);
      replicatedObj.identifier = `${objectToReplicate?.identifier}_${i + 1}`;
      replicatedObj.title = `${objectToReplicate.title} ${i + 1}`;
      replicatedObjects.push(replicatedObj);
    }
    return replicatedObjects;
  }

  useEffect(() => {
    if (showError === true && errorMessage) {
      AnalyticsService.pushEvent("Dialog Shown", {
        type: showError ? "Failure" : "",
        message: errorMessage,
        page: "Upload Documents",
        errorCode: errorCode || "",
      });
    }
  }, [showError, errorMessage, errorCode]);

  useEffect(() => {
    AnalyticsService.pushEvent("Page Viewed", {
      page: "Upload Documents",
    });
    if (
      PartnerService.hasFilledPartnerDetails() ||
      PartnerService.fieldTeamHasFilledPartnerDetails() ||
      PartnerService.isPartnerStatusUnverified()
    ) {
      if (window.location.pathname !== "/register/documents-upload") {
        navigate("/register/documents-upload");
      }
    } else if (
      PartnerService.hasUploadedDocuments() &&
      !PartnerService.isPartnerStatusUnverified()
    ) {
      navigate("/dashboard", { replace: true });
    } else {
      navigate("/");
    }

    if (
      localStorage.getItem("institutionId") &&
      localStorage.getItem("institutionId") !== ""
    ) {
      setFetchingUploadedFiles("started");
      const { axiosRequest, abortController } =
        DocumentService.getUploadedDocumentsForUser();
      axiosRequest
        .then((response) => {
          const documentsFromResponse =
            response?.data?.responseData?.documentsData?.documents;
          let updatedDocumentsFromResponse = _.map(
            documentsFromResponse,
            (document) => {
              return {
                ...document,
                isUploaded: document?.fileLink ? true : false,
              };
            }
          );

          _.map(documentsTemplatesArray, (template) => {
            let uploadedDocument = _.filter(
              updatedDocumentsFromResponse,
              (updatedDocsFromResponse) =>
                _.startsWith(
                  updatedDocsFromResponse?.type,
                  template?.identifier
                )
            );
            let replicatedDocumentObjs = replicateTemplateObject(
              template,
              uploadedDocument?.length,
              0
            );
            template.stateSetter(replicatedDocumentObjs);
          });

          if (
            response?.data?.responseData?.userData?.businessCategory?.length > 0
          ) {
            setBusinessCategories(
              response?.data?.responseData?.userData?.businessCategory
            );
          }

          setUploadedFiles(updatedDocumentsFromResponse);
          setShowError(false);
          setErrorMessage(null);
          setFetchingUploadedFiles("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setShowError(true);
            setErrorMessage("Error getting previously uploaded files");
            setErrorCode("FETCH_UPLOADED_DOCS_API_FAILURE");
            setFetchingUploadedFiles("done");
          }
        });
      return () => abortController.abort();
    } else {
      setFetchingUploadedFiles("done");
    }
  }, []);

  const fetchAndSaveProfile = () => {
    const onboardingPartnerMobileNumber = localStorage?.getItem(
      "onboardingPartnerMobileNumber"
    );
    if (onboardingPartnerMobileNumber) {
      const farmerId = localStorage.getItem("farmerId");
      const { axiosRequest } = PartnerService.getPartnerDetailedData(farmerId);
      axiosRequest
        .then((partnerDataResponse) => {
          const partnerData = partnerDataResponse?.data?.responseData;
          const { farmerType, userId, address } = partnerData;
          const profileAddress = address?.profileAddress;

          if (farmerType === "B2B Partner" || farmerType === "B2B Onboarding") {
            let source = ``;
            let partnerStatus = ``;
            let stateName = profileAddress?.state;
            if (stateName) {
              let stateFromName = StateService.getStateByName(stateName);
              let stateCode = ``;
              if (
                stateFromName &&
                stateFromName.stateCode &&
                stateFromName.stateCode !== ""
              ) {
                const { axiosRequest } =
                  HisaabService.getInstitutionDetails(farmerId);
                axiosRequest
                  .then((institutionalDetailsResponse) => {
                    const institutionalDetails =
                      institutionalDetailsResponse?.data?.responseData;
                    if (
                      institutionalDetails?.status === "ACTIVE" ||
                      (institutionalDetails?.status === "UNVERIFIED" &&
                        institutionalDetails?.subStatus !==
                          "BASIC_DETAILS_PENDING") ||
                      (institutionalDetails?.status === "INACTIVE" &&
                        institutionalDetails?.inactivatedOnDate > 0 &&
                        !PartnerService.isPartnerInactiveSinceMoreThanNdays(
                          institutionalDetails?.inactivatedOnDate
                        ))
                    ) {
                      stateCode = stateFromName.stateCode;
                      source = `B2B${stateCode}`;
                      partnerStatus = institutionalDetails?.status;
                      localStorage.setItem("source", source);
                      localStorage.setItem("userId", userId);
                      localStorage.setItem(`state`, stateName);
                      localStorage.setItem("farmerId", farmerId);
                      localStorage.setItem("mobile", partnerData.mobile1);
                      localStorage.setItem("pinCode", profileAddress.pinCode);
                      localStorage.setItem(
                        `creditPeriod`,
                        institutionalDetails?.creditPeriod
                      );
                      localStorage.setItem("partnerStatus", partnerStatus);
                      if (
                        institutionalDetails?.referrerDetails?.referrerEmailId
                      ) {
                        localStorage.setItem(
                          "onboardingReferrerSalesOfficerEmailID",
                          institutionalDetails?.referrerDetails?.referrerEmailId
                        );
                      }
                      if (institutionalDetails?.documentSubmissionStatus) {
                        localStorage.setItem(
                          "documentSubmissionStatus",
                          institutionalDetails?.documentSubmissionStatus
                        );
                      }
                      if (institutionalDetails?.areDocumentsVerified) {
                        localStorage.setItem(
                          "areDocumentsVerified",
                          institutionalDetails?.areDocumentsVerified
                        );
                      }
                      AnalyticsService.pushProfile(
                        farmerId,
                        partnerData.mobile1,
                        source
                      );
                      PartnerService.setWhatsappOptIn(
                        farmerId,
                        onboardingPartnerMobileNumber,
                        "OPT_IN"
                      );
                      setLoadingProceedOnboarding("done");
                      navigate("/dashboard", { replace: true });
                    } else {
                      setLoadingProceedOnboarding("done");
                      setShowError(true);
                      setErrorMessage(
                        "Something went wrong. Please try again."
                      );
                      localStorage.removeItem("otpVerified");
                      localStorage.removeItem("X-Authorization-Token");
                      console.log("Partner is not active");
                      if (
                        institutionalDetails?.status === "UNVERIFIED" &&
                        institutionalDetails?.subStatus ===
                          "BASIC_DETAILS_PENDING"
                      ) {
                        setErrorCode(
                          "FETCH_INSTITUTION_PARTNER_BASIC_DETAILS_PENDING"
                        );
                      } else {
                        setErrorCode("FETCH_INSTITUTION_PARTNER_INACTIVE");
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(`error getting institution details`);
                    setShowError(true);
                    setErrorMessage("Something went wrong. Please try again.");
                    if (error.data) {
                      console.log(error.data);
                    } else {
                      console.log(error);
                    }
                    setErrorCode("FETCH_INSTITUTION_API_FAILURE");
                    setLoadingProceedOnboarding("done");
                  });
              } else {
                setShowError(true);
                setErrorMessage("Something went wrong. Please try again.");
                setErrorCode("FETCH_PROFILE_NO_VALID_STATE");
                setLoadingProceedOnboarding("done");
              }
            } else {
              setShowError(true);
              setErrorMessage("Something went wrong. Please try again.");
              if (profileAddress?.stateName) {
                setErrorCode("FETCH_PROFILE_NO_PROFILE_STATE");
              }
              setLoadingProceedOnboarding("done");
            }
          } else {
            setShowError(true);
            setErrorMessage("Something went wrong. Please try again.");
            setErrorCode("FETCH_PROFILE_NOT_B2B_PARTNER");
            setLoadingProceedOnboarding("done");
          }
        })
        .catch((error) => {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setShowError(true);
          setErrorMessage("Something went wrong. Please try again.");
          setErrorCode("FETCH_PARTNER_DETAILS_API_FAILURE");
          setLoadingProceedOnboarding("done");
        });
    } else {
      setShowError(true);
      setErrorMessage("Something went wrong. Please try again.");
      setErrorCode("REGISTER_NO_MOBILE");
      setLoadingProceedOnboarding("done");
    }
  };

  const handleProceedOnboarding = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Submit Uploaded Documents",
      page: "Upload Documents",
      isUploadComplete: (
        skipLicenseUploadCheck
          ? areAllMandatoryDocsUploaded
          : areAllMandatoryDocsUploaded && isAnyLicenseUploaded
      )
        ? true
        : false,
    });

    if (
      skipLicenseUploadCheck
        ? areAllMandatoryDocsUploaded
        : areAllMandatoryDocsUploaded && isAnyLicenseUploaded
    ) {
      setLoadingProceedOnboarding("started");
      // removing "isUploaded" & "filePresignedLink" from PUT request payload
      const requestPayload = uploadedFiles.map(
        ({ isUploaded, filePresignedLink, ...requiredFieldsForPayload }) =>
          requiredFieldsForPayload
      );

      const { axiosRequest } = DocumentService.uploadDocumentsForOnboarding({
        requestPayload: requestPayload,
        isPartialUploadComplete: isPartialUploadComplete,
        isCompleted: isFullUploadComplete,
      });
      axiosRequest
        .then((response) => {
          const responseData = response?.data?.responseData;
          if (responseData?.institutionId) {
            localStorage.setItem("institutionId", responseData?.institutionId);
          }
          PartnerService.savePartnerStatuses(responseData);
          localStorage.setItem("farmerId", responseData?.saathiId);
          fetchAndSaveProfile();
        })
        .catch((error) => {
          setShowError(true);
          setErrorMessage("Something went wrong. Please try again later.");
          let errorFeedbackMessage = "";
          if (
            error?.response?.data?.responseData?.validationFailureData
              ?.Documents
          ) {
            errorFeedbackMessage = _.join(
              error?.response?.data?.responseData?.validationFailureData
                ?.Documents,
              ", "
            );
          } else {
            errorFeedbackMessage = "UPLOAD_DOCS_COMPLETE_API_FAILURE";
          }
          setErrorCode(errorFeedbackMessage);
          setLoadingProceedOnboarding("done");
        });
    } else {
      setShowError(true);
      if (
        areAllMandatoryDocsUploaded &&
        !isAnyLicenseUploaded &&
        !skipLicenseUploadCheck
      ) {
        setErrorMessage(
          "Please upload at least one license to proceed onboarding"
        );
      } else if (
        !areAllMandatoryDocsUploaded &&
        (skipLicenseUploadCheck ? true : isAnyLicenseUploaded)
      ) {
        setErrorMessage(
          "Please upload all the mandatory documents (marked with *) to proceed onboarding"
        );
      } else if (
        !areAllMandatoryDocsUploaded &&
        !isAnyLicenseUploaded &&
        !skipLicenseUploadCheck
      ) {
        setErrorMessage(
          "Please upload all the mandatory documents (marked with *) & at least one license to proceed onboarding"
        );
      } else {
        setErrorMessage("Something went wrong. Please try again later.");
      }
    }
  };

  const handleLogout = () => {
    if (PartnerService.checkIfFieldAgent()) {
      PartnerService.logoutFieldAgentAsPartner();
      navigate("/partners");
      window.location.reload();
    } else {
      PartnerService.logout();
      window.location.reload();
    }
    AnalyticsService.pushEvent("Logout Clicked");
  };

  return (
    <>
      <ErrorBoundary>
        <TopBar
          title={`SAATHI`}
          showCart={false}
          showHisaab={false}
          showCallAgriDoctor={false}
          showFieldAgentLogout={true}
          handleLogout={handleLogout}
        />
        <Box paddingBottom={22}>
          <Box sx={{ background: "#F3F3F3", padding: 2 }}>
            <OnboardingProgressStepper
              steps={onboardingSteps}
              currentlyActiveStep={1}
            />
          </Box>

          <Box sx={{ background: "#FFF", borderRadius: "16px", padding: 2 }}>
            <Typography
              sx={{
                color: "#000",
                fontSize: "18px",
                fontWeight: 700,
                marginBottom: 1,
              }}
            >
              How To Upload Documents
            </Typography>
            <ReactVideoPlayer
              video={{ src: "https://www.youtube.com/watch?v=F9YCdQq2yvY" }}
              autoplay={true}
              style={{ overflow: "hidden", borderRadius: "16px" }}
              light={true}
              height={"200px"}
              eventProperties={{
                page: "Upload Documents",
                type: "How To Upload Documents",
                videoUrl: "https://www.youtube.com/watch?v=F9YCdQq2yvY",
              }}
            />
          </Box>

          <Grid
            container
            sx={{ padding: "16px 16px 0px 16px" }}
            alignItems={"center"}
          >
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
                Upload your documents to unlock pricing
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ paddingLeft: "8px" }}>
              <Button
                size={`small`}
                variant={"outlined"}
                color="primary"
                endIcon={<ArrowForwardTwoTone />}
                sx={{
                  textTransform: "capitalize",
                  fontSize: "11px",
                  fontWeight: 700,
                  padding: "4px 8px",
                  background: "#FFF",
                  borderRadius: "10px",
                  lineHeight: "14px",
                  "& .MuiButton-endIcon": {
                    marginLeft: "2px",
                  },
                  "&:hover": {
                    border: "none",
                  },
                }}
                onClick={() => {
                  navigate("/dashboard");
                  AnalyticsService.pushEvent("Button Clicked", {
                    type: "View Products",
                    page: "Upload Documents",
                  });
                }}
              >
                <img
                  src="/icons/products.svg"
                  alt=""
                  style={{ paddingRight: "4px" }}
                />
                View Products
              </Button>
            </Grid>
          </Grid>

          <Box padding={2} paddingBottom={0}>
            {/* {_.map(documentsRequired, (documentRequired, index) => (
              <UploadDocument
                key={index}
                documentTitle={documentRequired?.title}
                documentSubtitle={documentRequired?.subtitle}
                documentAllowedExtensions={
                  documentRequired?.allowedFileExtensions
                }
                documentMimeType={documentRequired?.mimeType}
                imageFileTypeExtensions={imageFileTypeExtensions}
                documentFileTypeExtensions={documentFileTypeExtensions}
                documentIdentifier={documentRequired?.identifier}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                isMandatory={documentRequired?.isMandatory}
              />
            ))} */}

            {_.map(
              PartnerService.isFieldAssistedOnboarding()
                ? documentsTemplatesArray
                : _.filter(documentsTemplatesArray, {
                    onlyNeededInFieldAssistedOnboarding: false,
                  }),
              (template, index) => (
                <UploadDocumentGroup
                  key={index}
                  documentTitle={template?.title}
                  documentSubtitle={template?.subtitle}
                  documentAllowedExtensions={template?.allowedFileExtensions}
                  imageFileTypeExtensions={imageFileTypeExtensions}
                  documentFileTypeExtensions={documentFileTypeExtensions}
                  documentIdentifier={template?.identifier}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  documentTemplate={template}
                  documentGroup={template?.state}
                  setDocumentGroup={template?.stateSetter}
                  groupTitle={template?.groupTitle}
                  uploadType={template?.uploadType}
                />
              )
            )}
          </Box>

          {!skipLicenseUploadCheck && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                color: "#4F4F4F",
                padding: "16px 16px 8px 16px",
              }}
            >
              {"Please upload at least one license *"}
            </Typography>
          )}
          <Box padding={2} paddingTop={0} paddingBottom={1}>
            {/* {_.map(licensesRequired, (licenseRequired, index) => (
              <UploadDocument
                key={index}
                documentTitle={licenseRequired?.title}
                documentSubtitle={licenseRequired?.subtitle}
                documentAllowedExtensions={
                  licenseRequired?.allowedFileExtensions
                }
                documentMimeType={licenseRequired?.mimeType}
                imageFileTypeExtensions={imageFileTypeExtensions}
                documentFileTypeExtensions={documentFileTypeExtensions}
                documentIdentifier={licenseRequired?.identifier}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
              />
            ))} */}
            {_.map(licensesTemplatesArray, (template, index) => (
              <UploadDocumentGroup
                key={index}
                documentTitle={template?.title}
                documentSubtitle={template?.subtitle}
                documentAllowedExtensions={template?.allowedFileExtensions}
                imageFileTypeExtensions={imageFileTypeExtensions}
                documentFileTypeExtensions={documentFileTypeExtensions}
                documentIdentifier={template?.identifier}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                documentTemplate={template}
                documentGroup={template?.state}
                setDocumentGroup={template?.stateSetter}
                groupTitle={template?.groupTitle}
                uploadType={template?.uploadType}
              />
            ))}
          </Box>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              color: "#4F4F4F",
              padding: "16px 16px 8px 16px",
            }}
          >
            {"Any other additional documents"}
          </Typography>
          <Box padding={2} paddingTop={0} paddingBottom={1}>
            <UploadDocumentGroup
              documentTitle={otherDocumentsTemplate?.title}
              documentSubtitle={otherDocumentsTemplate?.subtitle}
              documentAllowedExtensions={
                otherDocumentsTemplate?.allowedFileExtensions
              }
              imageFileTypeExtensions={imageFileTypeExtensions}
              documentFileTypeExtensions={documentFileTypeExtensions}
              documentIdentifier={otherDocumentsTemplate?.identifier}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              documentTemplate={otherDocumentsTemplate}
              documentGroup={otherDocumentsTemplate?.state}
              setDocumentGroup={otherDocumentsTemplate?.stateSetter}
              groupTitle={otherDocumentsTemplate?.groupTitle}
              uploadType={otherDocumentsTemplate?.uploadType}
            />
          </Box>

          <Typography sx={{ p: 2, pt: 0, color: "#666666", fontSize: "12px" }}>
            All fields marked with an asterisk (*) are required
          </Typography>

          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              borderRadius: 0,
              padding: "14px 22px",
              position: "fixed",
              bottom: 0,
              maxWidth: "600px",
              background: `${
                (
                  skipLicenseUploadCheck
                    ? areAllMandatoryDocsUploaded
                    : areAllMandatoryDocsUploaded && isAnyLicenseUploaded
                )
                  ? "#9D2123"
                  : "#8C8C8C"
              }`,
              "&:hover": {
                backgroundColor: `${
                  (
                    skipLicenseUploadCheck
                      ? areAllMandatoryDocsUploaded
                      : areAllMandatoryDocsUploaded && isAnyLicenseUploaded
                  )
                    ? "#9D2123"
                    : "#8C8C8C"
                }`,
              },
              zIndex: 2,
            }}
            endIcon={<EastIcon />}
            onClick={handleProceedOnboarding}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
              Continue
            </Typography>
          </Button>
        </Box>
        <Backdrop
          open={
            fetchingUploadedFiles !== "done" ||
            loadingProceedOnboarding === "started"
          }
        >
          <CircularProgress sx={{ color: "#FFFFFF" }} />
        </Backdrop>

        <Dialog
          open={showError}
          onClose={() => {
            setShowError(false);
            window.location.reload();
          }}
          PaperProps={{
            style: {
              borderRadius: "32px",
              padding: "24px",
              minWidth: "70vw",
            },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon fontSize={"large"} sx={{ color: "#9D2123" }} />
            <Typography
              variant={`body1`}
              sx={{ color: "#9D2123", marginTop: 1 }}
            >
              {errorMessage}
            </Typography>

            {errorCode && (
              <Typography
                variant={`caption`}
                sx={{ color: "#222222", opacity: 0.3, wordBreak: "break-all" }}
              >
                {errorCode}
              </Typography>
            )}
            <Box sx={{ marginTop: 2.5 }}>
              <Button
                variant={"outlined"}
                size={"large"}
                color={"secondary"}
                sx={{
                  borderRadius: "16px",
                  background: "rgba(255, 255, 255, 1)",
                  color: "rgba(0, 115, 62, 1)",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setShowError(false);
                  if (errorCode !== "FETCH_UPLOADED_DOCS_API_FAILURE") {
                    window.location.reload();
                  }
                }}
              >
                Okay
              </Button>
            </Box>
          </Box>
        </Dialog>

        <NeedHelp title={`Need Help`} />
      </ErrorBoundary>
    </>
  );
};

export default DocumentsUploadPage;
