import React, { useEffect, useState } from "react";

import makeStyles from "@mui/styles/makeStyles";

import ErrorBoundary from "../Common/ErrorBoundary";
import Orders from "./Orders";
import TopBar from "../Common/TopBar";
import AnalyticsService from "../../Services/AnalyticsService";
import NeedHelp from "../Common/NeedHelp";
import useQuery from "../../Hooks/useQuery";
import PendingApprovalOrdersNudge from "../Orders/PendingApprovalOrdersNudge";
import PartnerService from "../../Services/PartnerService";
import OrderService from "../../Services/OrderService";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    marginBottom: theme.spacing(5),
  },
}));

function OrdersList() {
  const classes = useStyles();
  const query = useQuery();
  // if merchantPaymentRefId exists, it means user is coming to OrdersList via Rupifi order approval OTP flow
  const merchantPaymentRefId = query.get("merchantPaymentRefId");
  const backLink =
    merchantPaymentRefId && merchantPaymentRefId !== "" ? "/dashboard" : null;
  const [approvalPendingOrdersCount, setApprovalPendingOrdersCount] =
    useState(0);
  const [loadingApprovalPendingOrders, setLoadingApprovalPendingOrders] =
    useState("not-started");

  useEffect(() => {
    AnalyticsService.pushEvent(`Orders Page Viewed`);

    if (!PartnerService.checkIfFieldAgent()) {
      setLoadingApprovalPendingOrders("started");
      const { axiosRequest, abortController } = OrderService.getAllOrders(
        true,
        0,
        20,
        true
      );
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.totalOrderCount) {
            let approvalPendingOrdersCount =
              response?.data?.responseData?.totalOrderCount;
            setApprovalPendingOrdersCount(approvalPendingOrdersCount);
          }
          setLoadingApprovalPendingOrders("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoadingApprovalPendingOrders("done");
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
          }
        });
      return () => abortController.abort();
    }
  }, []);

  return (
    <div className={classes.root}>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Your Ongoing Orders`}
          backNavigation={true}
          backLink={backLink}
          showCart={true}
          showHisaab={true}
          toolTip={``}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <PendingApprovalOrdersNudge
          ordersCount={approvalPendingOrdersCount}
          loading={loadingApprovalPendingOrders}
        />
      </ErrorBoundary>
      <div>
        <ErrorBoundary message={``}>
          <Orders />
          <NeedHelp page={`orders`} title={`Need Help`} />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default OrdersList;
