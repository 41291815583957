import React from "react";
import { useNavigate } from "react-router-dom";

import { East } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";

import OrderService from "../../Services/OrderService";
import AnalyticsService from "../../Services/AnalyticsService";
import AmountUtils from "../../Utils/AmountUtils";

export default function ConfirmDelivery({
  order,
  setLoading,
  handleConfirmDeliveryClicked,
}) {
  const navigate = useNavigate();

  // const handleConfirmDelivery = () => {
  //   AnalyticsService.pushEvent("Button Clicked", {
  //     type: "Confirm Delivery",
  //     orderId: order?.orderId,
  //   });
  //   setLoading("started");
  //   const { axiosRequest } = OrderService.markOrderDelivered(order?.wmsOrderId);
  //   axiosRequest
  //     .then((response) => {
  //       if (response?.data?.message === "Operation Successful") {
  //         navigate(
  //           `/order/freight-charge-reimburse-success?orderId=${
  //             order?.orderId
  //           }&freightCharges=${order?.freightCharges || 0}`
  //         );
  //       }
  //       setLoading("done");
  //     })
  //     .catch((error) => {
  //       console.log("Error Marking Order Delivered: ", error?.message);
  //       setLoading("done");
  //     });
  // };

  return (
    <Grid container sx={{ position: "fixed", bottom: 0, marginTop: 2 }}>
      <Grid item xs={12}>
        <Typography
          sx={{ fontSize: "14px", padding: "4px 16px", background: "#FFDC42" }}
        >
          Please confirm delivery to get{" "}
          <b>
            {AmountUtils.formatCurrency(order?.freightCharges || 0)} Freight CN
          </b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          sx={{ fontWeight: "bold", height: "40px", borderRadius: 0 }}
          endIcon={<East />}
          onClick={handleConfirmDeliveryClicked}
        >
          CONFIRM DELIVERY
        </Button>
      </Grid>
    </Grid>
  );
}
