import config from "../config";
import _ from "lodash";
import ProductsMock from "./mocks/ProductsMock";
import TurnoverProductsMock from "./mocks/TurnoverProductsMock";
import ProductsCountMock from "./mocks/ProductsCountMock";
import ProductDetailsMock from "./mocks/ProductDetailsMock";
import Languages from "./LanguageCode";
import PayAdvanceSchemeProducts from "./PayAdvanceSchemeProducts";
import moment from "moment/moment";
import EventBasedSale from "../content/EventBasedSale";
import StateUtils from "../Utils/StateUtils";
import EventBasedSaleTheme from "../Theme/EventBasedSaleTheme";
import CommonConstantValues from "./CommonConstantValues";
import ReturnPolicyMock from "./mocks/ReturnPolicyMock";
import SkuBenefitsMock from "./SkuBenefitsMock";
import HTTPService from "./HTTPService";

const useMocks = false;
const PRODUCT_COUNT = 30;
const AGROSTAR_BRAND_PRODUCT_COUNT = 500;
export default class ProductService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static checkIfProductEnabled(product) {
    const role = localStorage.getItem("role") || null;
    if (role === "fieldAgent") {
      return product?.enabledForFieldOfficer;
    }

    return product?.enabledForSaathi;
  }

  static getProductsByBrandId(brandId) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/products?brand=${brandId}&language=en&count=${AGROSTAR_BRAND_PRODUCT_COUNT}&farmerId=${localStorage.getItem(
        "farmerId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getProductsByBrandIdAndClass(brandId, productClass, showVariants) {
    if (useMocks === false) {
      let variantsQueryParam = ``;
      if (showVariants === true) {
        variantsQueryParam = `includeVariants=true`;
      }
      const url = `${
        config.backendUrl
      }shopaggregator/v1/products?brand=${brandId}&language=en&count=${AGROSTAR_BRAND_PRODUCT_COUNT}&productClass=${productClass}&farmerId=${localStorage.getItem(
        "farmerId"
      )}${variantsQueryParam}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getSKUsFromProducts(products) {
    let skus = [];
    _.forEach(products, (product) => {
      if (product.skuCode) {
        skus.push(product.skuCode);
      }
    });
    return skus;
  }

  static getProductsListBySKUs(skus, selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }

      const url = `${
        config.backendUrl
      }shopaggregator/v1/products?language=en&sku=${skus}${
        selectedBrands?.length > 0 ? `&brand=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getProductsListBySKUsCount(skus, selectedBrands) {
    if (useMocks === false) {
      if (selectedBrands?.length > 0) {
        let selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");

        const url = `${config.backendUrl}shopaggregator/v1/products/count?language=en&sku=${skus}&brand=${selectedBrandIds}`;
        return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
      } else {
        const url = `${config.backendUrl}shopaggregator/v1/products/count?language=en&sku=${skus}`;
        return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
      }
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsCountMock });
      });
    }
  }

  static getProductByImageSearch(productSearchImageUrl) {
    const url = `${config.backendUrl}productdetectionengine/productdetector/?productImageURL=${productSearchImageUrl}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static getProductsByCategoryAndBrandId(categoryId, brandId) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/products?brand=${brandId}&category=${categoryId}&language=en&count=${PRODUCT_COUNT}&farmerId=${localStorage.getItem(
        "farmerId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getProductDetailsBySku(sku, source) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/productdetail/${sku}?farmerId=${localStorage.getItem(
        `farmerId`
      )}&pincode=${localStorage.getItem(
        `pinCode`
      )}&limit=5&offerResponseKey=OFFERDETAILS&state=${localStorage.getItem(
        `state`
      )}&language=en&include=variantProducts`; //@TODO add state in localstorage and fetch from there
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductDetailsMock });
      });
    }
  }

  static getProductDetailsBySkuAndSetProductView(sku, source) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/productdetail/${sku}?farmerId=${localStorage.getItem(
        `farmerId`
      )}&pincode=${localStorage.getItem(
        `pinCode`
      )}&limit=5&offerResponseKey=OFFERDETAILS&state=${localStorage.getItem(
        `state`
      )}&language=en${
        localStorage.getItem("role") === "fieldAgent"
          ? ""
          : `&userId=${localStorage.getItem("userId")}`
      }&include=variantProducts`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        resolve({ data: ProductDetailsMock });
      });
    }
  }

  static getProductDetailsBySkuWithQuantity(sku, source, quantity) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/productdetail/${sku}?farmerId=${localStorage.getItem(
        `farmerId`
      )}&pincode=${localStorage.getItem(
        `pinCode`
      )}&limit=5&offerResponseKey=OFFERDETAILS&state=${localStorage.getItem(
        `state`
      )}&language=en${
        quantity ? `&quantity=${quantity}` : ``
      }&isFeatured=${false}&include=variantProducts`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: ProductDetailsMock });
        }, 3000);
      });
    }
  }

  static getTestimonialBySku(sku) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/productdetail/${sku}?farmerId=${localStorage.getItem(
        `farmerId`
      )}&pincode=${localStorage.getItem(
        `pinCode`
      )}&limit=5&state=${localStorage.getItem(`state`)}&language=${
        Languages[localStorage.getItem("state")?.toUpperCase()]
          ? Languages[localStorage.getItem("state")?.toUpperCase()]
          : "en"
      }&include=variantProducts`; //@TODO add state in localstorage and fetch from there
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        resolve({ data: ProductDetailsMock });
      });
    }
  }

  static searchProductsByKeyword(keyword) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v2/products?language=en&searchString=${encodeURIComponent(
        keyword
      )}&pageNo=1&pageSize=${PRODUCT_COUNT}&farmerId=${localStorage.getItem(
        "farmerId"
      )}excludePrefixSearch=true`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: ProductsMock });
        }, 10000);
      });
    }
  }

  static getFeaturedProducts(selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }

      const url = `${config.backendUrl}shopaggregator/v1/featuredproducts?language=en${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: ProductsMock });
        }, 3000);
      });
    }
  }

  static getFeaturedProductsCount(selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }
      const url = `${
        config.backendUrl
      }shopaggregator/v1/featuredproducts/count?language=en${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: ProductsCountMock });
        }, 3000);
      });
    }
  }

  static getPopularProducts(count, selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }
      const url = `${
        config.backendUrl
      }shopaggregator/v1/popularproducts?language=en&count=${count}${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getPopularProductsCount(count, selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }
      const url = `${
        config.backendUrl
      }shopaggregator/v1/popularproducts/count?language=en&count=${count}${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsCountMock });
      });
    }
  }

  static getRecentlyViewedProducts(count, selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }

      const url = `${
        config.backendUrl
      }shopaggregator/v1/recentlyviewedproducts/?language=en&count=${count}&userId=${localStorage.getItem(
        `userId`
      )}${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getRecentlyViewedProductsCount(count, selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }

      const url = `${
        config.backendUrl
      }shopaggregator/v1/recentlyviewedproducts/count?language=en&count=${count}&userId=${localStorage.getItem(
        `userId`
      )}${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsCountMock });
      });
    }
  }

  static getAlternateProducts(sku) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/alternateproducts/?skuCode=${sku}&farmerId=${localStorage.getItem(
        "farmerId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getNearbyPartnerPurchase(selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }
      const url = `${
        config.backendUrl
      }shopaggregator/v1/popularproducts?language=en&district=${localStorage.getItem(
        "district"
      )}&requester=SAATHI${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;

      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getNearbyPartnerPurchaseCount(selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }
      const url = `${
        config.backendUrl
      }shopaggregator/v1/popularproducts/count?language=en&district=${localStorage.getItem(
        "district"
      )}&requester=SAATHI${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsCountMock });
      });
    }
  }

  static getBuyItAgainProduct(selectedBrands) {
    if (useMocks === false) {
      if (selectedBrands?.length > 0) {
        let selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");

        const url = `${
          config.backendUrl
        }shopaggregator/v1/buyagain/?farmerId=${localStorage.getItem(
          "farmerId"
        )}&brandIds=${selectedBrandIds}`;

        return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
      } else {
        const url = `${
          config.backendUrl
        }shopaggregator/v1/buyagain/?farmerId=${localStorage.getItem(
          "farmerId"
        )}`;

        return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
      }
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getBuyItAgainProductCount(selectedBrands) {
    if (useMocks === false) {
      if (selectedBrands?.length > 0) {
        let selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");

        const url = `${
          config.backendUrl
        }shopaggregator/v1/buyagain/count?farmerId=${localStorage.getItem(
          "farmerId"
        )}&brandIds=${selectedBrandIds}`;
        return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
      } else {
        const url = `${
          config.backendUrl
        }shopaggregator/v1/buyagain/count?farmerId=${localStorage.getItem(
          "farmerId"
        )}`;
        return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
      }
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsCountMock });
      });
    }
  }

  static getGrahakProductsRecommendation(count, selectedBrands) {
    if (useMocks === false) {
      let selectedBrandIds = null;
      if (selectedBrands?.length > 0) {
        selectedBrandIds = selectedBrands
          .map((selectedBrand) => selectedBrand.brandId)
          .join(",");
      }
      const url = `${
        config.backendUrl
      }shopaggregator/v1/grahakrecommendation/?language=en&count=${count}&saathiId=${localStorage.getItem(
        `farmerId`
      )}${
        selectedBrands?.length > 0 ? `&brandIds=${selectedBrandIds}` : ``
      }&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static getTurnoverSchemeRecommendation() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/todrecommendations/?farmerId=${localStorage.getItem(
        "farmerId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: TurnoverProductsMock });
      });
    }
  }

  static calculateQuantityOnMinimumOrderQuantity = (
    quantity,
    minimumOrderQuantity,
    operation
  ) => {
    if (
      !quantity ||
      typeof quantity === "undefined" ||
      Number(quantity) === 0 ||
      quantity === ""
    ) {
      quantity = 1;
    }
    let newQuantity = 1;
    if (minimumOrderQuantity < 1) {
      minimumOrderQuantity = 1;
    }
    const forcedQuantity = Math.min(
      quantity,
      CommonConstantValues.MAX_PURCHASE_QUANTITY - minimumOrderQuantity + 1
    );
    const forcedQuantityFlag = forcedQuantity !== quantity;
    quantity = forcedQuantity;
    if (minimumOrderQuantity > 1) {
      if (quantity > 1) {
        if (quantity < minimumOrderQuantity) {
          newQuantity = minimumOrderQuantity;
        } else {
          if (quantity % minimumOrderQuantity === 0) {
            newQuantity = quantity;
          } else {
            if (operation === "removal") {
              newQuantity =
                Math.floor(quantity / minimumOrderQuantity) *
                minimumOrderQuantity;
            } else {
              newQuantity =
                Math.ceil(quantity / minimumOrderQuantity) *
                minimumOrderQuantity;
            }
          }
        }
      } else if (quantity === 1) {
        newQuantity = minimumOrderQuantity;
      } else {
      }
    } else {
      newQuantity = quantity;
    }

    return [newQuantity, forcedQuantityFlag];
  };

  static getApplicablePrice = (
    product,
    appliedOffer,
    appliedCashDiscountBenefit,
    turnOverSchemeBenefits
  ) => {
    let applicablePrice = 0;
    if (appliedCashDiscountBenefit?.effectiveOfferPrice) {
      applicablePrice = appliedCashDiscountBenefit.effectiveOfferPrice;
    } else if (turnOverSchemeBenefits) {
      applicablePrice = turnOverSchemeBenefits.perUnitNetEffectivePrice;
    } else if (appliedOffer) {
      if (appliedOffer.netEffectivePriceApplicable === "true") {
        applicablePrice = appliedOffer.netEffectivePrice;
      } else if (appliedOffer.offerPrice) {
        applicablePrice = appliedOffer.offerPrice;
      } else {
      }
    } else {
      if (product?.sellingPrice) {
        applicablePrice = product.sellingPrice;
      } else {
      }
    }
    return Math.round(applicablePrice);
  };

  static getVoiceSearchPromptText(source) {
    if (!source) return `Try saying the name of the product`;
    const message = {
      B2BMP: `उत्पाद का नाम बोलकर देखें`,
      B2BUP: `उत्पाद का नाम बोलकर देखें`,
      B2BRJ: `उत्पाद का नाम बोलकर देखें`,
      B2BMH: `उत्पादनाचे नाव बोलून पहा`,
      B2BGJ: `ઉત્પાદનનું નામ કહો`,
    };

    return message[source]
      ? message[source]
      : `Try saying the name of the product`;
  }

  static getClassImageUrl = (productClass) => {
    return productClass
      ? `https://static.agrostar.in/static/Class_${productClass}_tag.png`
      : null;
  };

  static getEventBasedSaleImageUrl = (skuCode) => {
    let currentStateCode = StateUtils.getStateCode();
    let saleImageUrl = null;
    if (EventBasedSale?.[currentStateCode]?.[skuCode]) {
      let percentageOfSale = EventBasedSale?.[currentStateCode]?.[skuCode];
      if (percentageOfSale) {
        saleImageUrl = `${EventBasedSaleTheme?.eventBannerImageUrl}${percentageOfSale}.svg`;
      }
    }
    return saleImageUrl;
  };

  static getEventBasedSaleIconUrl = (skuCode) => {
    let currentStateCode = StateUtils.getStateCode();
    let saleImageUrl = null;
    if (EventBasedSale?.[currentStateCode]?.[skuCode]) {
      let percentageOfSale = EventBasedSale?.[currentStateCode]?.[skuCode];
      if (percentageOfSale) {
        saleImageUrl = `${EventBasedSaleTheme?.eventBannerLogoUrl}${percentageOfSale}.svg`;
      }
    }
    return saleImageUrl;
  };

  static getPopularSearchProducts() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/popularsearchproducts?language=en&district=${localStorage.getItem(
        "district"
      )}&farmerId=${localStorage.getItem("farmerId")}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ProductsMock });
      });
    }
  }

  static roundFloatsToNdecimalPlaces = (number, fixedTo) => {
    fixedTo = fixedTo || 0;
    fixedTo = Math.pow(10, fixedTo);
    return Math.floor(number * fixedTo) / fixedTo;
  };

  // TODO: explore if there is a better way to define transitions at App/theme level
  static productDetailsStickyTransition = "all 0.2s ease-in-out";

  static isPayAdvanceSchemeActive = (skuCode) => {
    const payAdvanceScheme = PayAdvanceSchemeProducts?.[skuCode];
    if (
      payAdvanceScheme &&
      moment(payAdvanceScheme.startTime, "YYYY-MM-DD HH:mm A").unix() <=
        moment().unix() &&
      moment(payAdvanceScheme.endTime, "YYYY-MM-DD HH:mm A").unix() >=
        moment().unix()
    ) {
      return true;
    }
    return false;
  };

  static getReturnPolicy = (skus, epoch) => {
    if (useMocks === false) {
      const url = `${config.backendUrl}catalogservice/v4/skureturnpolicy?date=${epoch}&skuCodes=${skus}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: ReturnPolicyMock });
      });
    }
  };

  static getSourceStateCode = () => {
    let source = localStorage.getItem(`source`)?.split("B2B")?.[1];
    return source;
  };

  static getBenefitsForSkus = (skus) => {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }offerservice/v2/calculatebenefitsforplps/?skuCodes=${skus}&farmerId=${localStorage.getItem(
        "farmerId"
      )}`;

      return HTTPService.makeXHRRequest(url, "GET", null, {
        ...this.getHeaders(),
        source: StateUtils.getAppSource(),
      });
    } else {
      return new Promise((resolve) => {
        resolve({ data: SkuBenefitsMock });
      });
    }
  };

  static getAdvancedSchemeBenefitImageUrl = (sku) => {
    let source = ProductService.getSourceStateCode();
    let url = null;
    if (PayAdvanceSchemeProducts?.[sku]?.productCardImage?.[source]) {
      url = PayAdvanceSchemeProducts?.[sku]?.productCardImage?.[source];
    }
    return url;
  };
  static getProductCardImageUrl = (product, isPayAdvanceSchemeActive) => {
    let url = product?.productImages?.[0]?.name;
    if (isPayAdvanceSchemeActive) {
      if (
        ProductService.getSourceStateCode() &&
        PayAdvanceSchemeProducts?.[product?.skuCode]?.productCardImage?.[
          ProductService.getSourceStateCode()
        ]
      ) {
        url =
          PayAdvanceSchemeProducts?.[product?.skuCode]?.productCardImage?.[
            ProductService.getSourceStateCode()
          ];
      }
    } else {
    }
    if (!url) {
      url = `/icons/dummy.jpeg`;
    }
    return url;
  };

  static getProductsOverlayUrl = (
    xOffset,
    yOffset,
    yOffsetBetweenTwoProducts,
    productsOrdered,
    maxCharactersInALine,
    maxProductsToShowOnImageOverlay,
    overlayProductFontSize
  ) => {
    let productsOverlayUrl = "";
    productsOverlayUrl = _.map(_.uniq(productsOrdered), (product) => {
      product =
        product.length > maxCharactersInALine
          ? product.slice(0, maxCharactersInALine).concat("...")
          : product;
      let encodedProductName = encodeURIComponent(product)
        .replace(/[`*`]/g, "x")
        .replace(/\(/g, "%28")
        .replace(/\)/g, "%29");
      yOffset = yOffset + yOffsetBetweenTwoProducts;
      return `l-text,i-${encodedProductName},fs-${overlayProductFontSize},tg-b,lx-${xOffset},ly-${yOffset},ff-fonts@@NotoSans-Regular.ttf,l-end`;
    })?.join(":");
    productsOverlayUrl =
      productsOrdered?.length >= maxProductsToShowOnImageOverlay - 1
        ? productsOverlayUrl.concat(
            `:l-text,i-........,fs-${overlayProductFontSize},lx-${xOffset},ly-${
              yOffset + yOffsetBetweenTwoProducts + 10
            },ff-fonts@@NotoSans-Regular.ttf,l-end`
          )
        : productsOverlayUrl;
    return productsOverlayUrl;
  };

  static getProductsNotifyMe = (skuCode, warehousesAssigned) => {
    const url = `${config.backendUrl}shopaggregator/v1/productsnotifyme`;
    const data = {
      userId: parseInt(localStorage.getItem("userId")),
      phoneNumber: localStorage.getItem("mobile"),
      skuCode: skuCode,
      language: Languages[localStorage.getItem("state")?.toUpperCase()]
        ? Languages[localStorage.getItem("state")?.toUpperCase()]
        : "en",
      warehouses: warehousesAssigned,
    };
    return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
  };

  static getSuggestionsByKeyword = (keyword, useMock = false) => {
    if (useMock === false) {
      const url = `${config.backendUrl}catalogservice/v4/autocompletion?searchString=${keyword}`;
      const request = HTTPService.makeXHRRequest(
        url,
        "GET",
        null,
        this.getHeaders()
      );
      return request;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          const resultData = {
            data: {
              responseData: {
                suggestions: [
                  "Power Gel",
                  "Power",
                  "Humic",
                  "Fertilizer",
                  "Hardware",
                ],
              },
            },
          };
          resolve(resultData);
        }, 1500);
      });
    }
  };
}
