import React from "react";

import { Container, Grid, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  brandLoaderWrapper: {
    marginTop: theme.spacing(0),
  },
}));

function BrandsLoader() {
  const classes = useStyles();

  return (
    <Container maxWidth={`xl`}>
      <Grid
        container
        direction={`row`}
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        className={classes.brandLoaderWrapper}
      >
        <Grid item xs={4}>
          <Skeleton variant={`rectangular`} height={80} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant={`rectangular`} height={80} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant={`rectangular`} height={80} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant={`rectangular`} height={80} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant={`rectangular`} height={80} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant={`rectangular`} height={80} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default BrandsLoader;
