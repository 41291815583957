import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function withRouter(Component) {
  return (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    return <Component {...props} router={{ location, navigate, params }} />;
  };
}
