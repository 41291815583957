import { useState, useRef, useEffect } from "react";
import _ from "lodash";
import moment from "moment";

import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";

import { AVATAR_PALETTE } from "../../Utils/AvatarPalette";
import ReactAudioPlayer from "../Common/ReactAudioPlayer";
import AnalyticsService from "../../Services/AnalyticsService";
import FarmerExperiencePopup from "./FarmerExperiencePopup";

export default function FarmerExperienceCard({
  post,
  skuCode,
  postIndex,
  setAutoPlayCarousel,
}) {
  const [showViewMoreButton, setShowViewMoreButton] = useState(false);
  const [showTestimonialPopup, setShowTestimonialPopup] = useState(false);

  const viewMoreRef = useRef(null);

  const postDateTime = moment(post?.updated_client_epoch).format(
    "DD MMM YYYY, hh:MM A"
  );
  const postCity = post?.author?.location?.city
    ? `${post?.author?.location?.city}, `
    : "";
  const postState = post?.author?.location?.state || "";

  const cardMediaContent = _.find(
    post?.data,
    (element) => element?.type === "image"
  );
  const cardFooterContent =
    _.find(
      post?.data,
      (element) => element?.type === "text" || element?.type === "audio"
    ) || null;
  const isCardFooterContentTypeText = cardFooterContent?.type === "text";

  function handleAutoPlayCarousel(autoPlayStatus) {
    setAutoPlayCarousel(autoPlayStatus);
  }

  useEffect(() => {
    const element = viewMoreRef.current;

    if (element) {
      setShowViewMoreButton(element.scrollHeight > element.clientHeight);
    }
  }, []);
  return (
    <>
      <Card
        elevation={2}
        sx={{
          boxShadow: "0px 12px 24px 0px rgba(28,28,28,0.10)",
          borderRadius: "24px",
          paddingTop: "8px",
          paddingBottom: "0px",
          height: "440px",
        }}
      >
        <CardHeader
          sx={{ padding: "0px", paddingLeft: "8px" }}
          avatar={
            <Avatar
              sx={{
                width: 45,
                height: 45,
                bgcolor:
                  AVATAR_PALETTE[
                    post?.author?.name?.charCodeAt(0) % AVATAR_PALETTE.length
                  ],
              }}
              aria-label="Kisan"
            >
              {post?.author?.pic && post?.author?.pic.length > 0 ? (
                <img
                  height={45}
                  width={45}
                  src={post?.author?.pic}
                  alt={post?.author?.name}
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <Typography component="span">
                  {post?.author?.name.slice(0, 1).toUpperCase()}
                </Typography>
              )}
            </Avatar>
          }
          title={
            <Typography
              variant="subtitle1"
              component="h1"
              sx={{ fontSize: 12, fontWeight: 700 }}
            >
              {post?.author?.name}
            </Typography>
          }
          subheader={
            <>
              <Typography
                color={(theme) => theme.palette.text.primary}
                variant="subtitle2"
                component="p"
                sx={{ fontWeight: 400, fontSize: 12 }}
              >
                {`${postCity}${postState}`}
              </Typography>
              <Typography
                color={(theme) => theme.palette.grey[700]}
                variant="subtitle2"
                component="p"
                sx={{ fontWeight: 400, fontSize: 12 }}
              >
                {postDateTime}
              </Typography>
            </>
          }
        />
        <Box
          display="flex"
          gap="5px"
          overflow="scroll"
          padding="8px 8px 8px 8px"
        >
          {_.map(post?.tags, (tag, tagIndex) => {
            return (
              <Chip
                variant="outlined"
                key={tagIndex}
                label={tag?.label}
                size="medium"
                sx={{
                  fontSize: 12,
                  fontWeight: 700,
                  padding: "8px 4px 8px 4px",
                  color: "#00733E",
                  borderColor: "#00733E",
                }}
              />
            );
          })}
        </Box>
        <CardMedia
          component="img"
          image={cardMediaContent.value}
          height={255}
          alt=""
          sx={{
            textAlign: "center",
            overflow: "hidden",
            verticalAlign: "middle",
            objectFit: "contain",
          }}
        />
        {cardFooterContent && (
          <CardContent sx={{ padding: "8px 16px 0px 16px" }}>
            {isCardFooterContentTypeText ? (
              <>
                <Typography
                  ref={viewMoreRef}
                  variant="body2"
                  component="p"
                  sx={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                  }}
                >
                  {cardFooterContent?.value}
                </Typography>
                {showViewMoreButton && (
                  <Box sx={{ lineHeight: "18px" }}>
                    <Typography
                      variant="body2"
                      component="span"
                      color="primary"
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        lineHeight: "18px",
                        textDecoration: "underline",
                        textUnderlineOffset: "3px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        AnalyticsService.pushEvent("Button Clicked", {
                          type: "View More",
                          section: "Farmer's Experience",
                          page: "Product Details",
                          ElementId: post?._id,
                          pageId: skuCode,
                          position: postIndex + 1,
                        });
                        setAutoPlayCarousel(false);
                        setShowTestimonialPopup(true);
                      }}
                    >
                      View More
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <ReactAudioPlayer
                src={cardFooterContent.value}
                height={50}
                setAutoPlayParentCarousel={setAutoPlayCarousel}
                eventProperties={{
                  page: "Product Detail",
                  sku: skuCode,
                  section: "Farmer's Experience",
                }}
              />
            )}
          </CardContent>
        )}
      </Card>
      {cardFooterContent && (
        <FarmerExperiencePopup
          post={post}
          skuCode={skuCode}
          testimonialIndex={postIndex}
          showTestimonialPopup={showTestimonialPopup}
          setShowTestimonialPopup={setShowTestimonialPopup}
          setAutoPlayCarousel={setAutoPlayCarousel}
        />
      )}
    </>
  );
}
