import React from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { East } from "@mui/icons-material";
import { Box, Button, Card, Grid } from "@mui/material";

import { iptStatus } from "./InterPartyTransferRequestsPage";

const useStyles = makeStyles({
  iptCard: {
    marginTop: "10px",
    borderRadius: "10px",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  iptCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
  },
  iptCardBody: {
    padding: "10px 14px",
    fontSize: "14px",
    borderTop: "1px solid #eee",
  },
  iptTag: {
    fontSize: "14px",
    borderRadius: "5px",
    background: "#dcdcdc",
    padding: "5px 4px",
    marginRight: "8px",
  },
  iptApprovalStatus: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    borderRadius: "5px",
    padding: "5px 7px",
    textTransform: "capitalize",
  },
  orderDetailsButton: {
    height: "40px",
    fontWeight: 700,
    paddingRight: "20px",
    justifyContent: "right",
    borderTop: "1px solid #eee",
  },
});

export default function IPTRequestDetailsCard({
  ipt,
  partner,
  partnerTitle,
  partnerName,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleIPTRequestCardClick = (ipt) => {
    navigate(`/inter-party-transfers/${ipt.id}`);
  };

  return (
    <Card
      className={classes.iptCard}
      onClick={() => handleIPTRequestCardClick(ipt)}
    >
      <Box className={classes.iptCardHeader}>
        <div>
          <span className={classes.iptTag}>IPT</span>
          <span style={{ fontSize: "14px" }}>Request ID: {ipt.id}</span>
        </div>
        <span
          className={classes.iptApprovalStatus}
          style={{
            color: iptStatus[ipt?.status]?.color,
            background: iptStatus[ipt?.status]?.bgColor,
          }}
        >
          {iptStatus[ipt?.status]?.icon}
          {ipt?.status}
        </span>
      </Box>
      <Grid container className={classes.iptCardBody}>
        <Grid item xs={12}>
          {partnerTitle}
        </Grid>
        <Grid item xs={12} sx={{ fontWeight: 700 }}>
          {partnerName}
        </Grid>
      </Grid>
      <Button
        fullWidth
        endIcon={<East />}
        className={classes.orderDetailsButton}
      >
        ORDER DETAILS
      </Button>
    </Card>
  );
}
