import React, { useState, useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";

import {
  Typography,
  Box,
  Container,
  Stack,
  Skeleton,
  SvgIcon,
} from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import SectionTitle from "../Common/SectionTitle";
import { InfoRounded } from "@mui/icons-material";
import { addDays, format } from "date-fns";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  containerStack: {
    padding: "10px 0px 0px 0px",
  },
  returnablePolicyBox: {
    marginTop: "8px",
    borderRadius: "10px",
    border: "2px solid #ffffff",
    background: "rgba(248, 209, 73, 0.2)",
  },
  nonReturnablePolicyBox: {
    marginTop: "8px",
    borderRadius: "10px",
    border: "2px solid #ffffff",
    background: "rgba(157, 33, 35, .2)",
  },
  textStyle: {
    fontSize: "14px",
    color: "#4F4F4F",
    fontWeight: "400",
    margin: "10px 8px 10px 10px",
  },
  boldTextStyle: {
    fontSize: "14px",
    color: "#4F4F4F",
    fontWeight: "800",
    margin: "10px 8px 10px 10px",
  },
  infoIconRedBox: {
    backgroundColor: "rgba(157, 33, 35, 0.3)",
    padding: "6px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    display: "flex",
  },
  infoIconYellowBox: {
    backgroundColor: "rgba(248, 209, 73, 0.3)",
    padding: "6px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    display: "flex",
  },
}));

const ReturnPolicyContainer = (props) => {
  const classes = useStyles();
  const [isReturnable, setIsReturnable] = useState(false);
  const [returnableQuantity, setReturnableQuantity] = useState(0);

  useEffect(() => {
    setIsReturnable(isProductReturnable());
  }, [props?.returnPolicy, props?.creditPeriod, props?.creditPeriodDate]);

  const getCreditPeriod = () => {
    let creditPeriodToShow = 0;
    if (props?.creditPeriod) {
      if (props?.creditPeriod > 1) {
        creditPeriodToShow = props?.creditPeriod;
      }
    } else {
      let creditPeriodFromLocalStorage = localStorage.getItem(`creditPeriod`);
      if (creditPeriodFromLocalStorage > 1) {
        creditPeriodToShow = creditPeriodFromLocalStorage;
      }
    }
    return creditPeriodToShow;
  };

  useEffect(() => {
    setReturnableQuantity(
      calculateReturnableQuantity(
        props.quantity,
        props?.returnPolicy.percentageAcceptance,
        props?.minimumOrderQuantity
      )
    );
  }, [props.quantity]);

  const getReturnDate = () => {
    if (props?.returnPolicy?.returnAtDueDate) {
      if (props?.creditPeriodDate && props?.creditPeriodDate !== "") {
        return addDays(
          new Date(props?.creditPeriodDate),
          parseInt(props?.returnPolicy?.returnAtDueDate)
        );
      } else {
        let extendableDays =
          parseInt(getCreditPeriod(), 0) +
          parseInt(props?.returnPolicy?.returnAtDueDate);
        return moment().add(extendableDays, "days").toDate();
      }
    } else {
      return new Date(props?.returnPolicy?.returnDate);
    }
  };

  const isProductReturnable = () => {
    let isReturnable = true;
    if (props?.returnPolicy?.percentageAcceptance == 0) {
      isReturnable = false;
    } else if (props?.returnPolicy?.returnAtDueDate > 0) {
      if (getReturnDate() < Date.now()) {
        isReturnable = false;
      }
    } else if (props?.returnPolicy?.returnDate) {
      if (new Date(props?.returnPolicy?.returnDate).getTime() < Date.now()) {
        isReturnable = false;
      }
    }
    return isReturnable;
  };

  const calculateReturnableQuantity = (
    quantity,
    percentage,
    minimumOrderQuantity
  ) => {
    var returnableQuantity =
      Math.floor(
        Math.floor((quantity * percentage) / 100) / (minimumOrderQuantity || 1)
      ) * (minimumOrderQuantity || 1);
    return returnableQuantity;
  };

  const getReturnableQuantityText = () => {
    return returnableQuantity > 0
      ? getAllowedReturnableQuantityText()
      : getSelectedQuantityNotSatisfyThresholdText();
  };

  const getAllowedReturnableQuantityText = () => {
    return (
      <div>
        Returns are only allowed at the box level. You can return{" "}
        <b>{returnableQuantity} quantity</b>
      </div>
    );
  };

  const getSelectedQuantityNotSatisfyThresholdText = () => {
    return (
      <div>
        Returns are only allowed at the box level. Your selected quantity does
        not meet the return percentage criteria.
      </div>
    );
  };

  return (
    <ErrorBoundary message={``}>
      <Container sx={{ marginTop: "30px" }}>
        <SectionTitle title={`Return Policy`} />
        {props.offersLoading !== "started" ? (
          <Box
            className={
              isReturnable
                ? classes.returnablePolicyBox
                : classes.nonReturnablePolicyBox
            }
          >
            {isReturnable && (
              <Typography className={classes.textStyle}>
                Return allowed till:{" "}
                <b>{format(getReturnDate(), "dd MMM yyyy")}</b>
                <br /> Maximum allowed percentage:{" "}
                <b>{props?.returnPolicy?.percentageAcceptance}%</b>
              </Typography>
            )}
            {!isReturnable && (
              <Typography className={classes.boldTextStyle}>
                Non returnable
              </Typography>
            )}
            <Stack
              direction="row"
              alignItems={
                isReturnable && returnableQuantity == 0 ? "stretch" : "center"
              }
              display="flex"
              backgroundColor={
                isReturnable
                  ? "rgba(248, 209, 73, 0.2)"
                  : "rgba(157, 33, 35, 0.1)"
              }
              sx={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <Box
                className={
                  isReturnable
                    ? classes.infoIconYellowBox
                    : classes.infoIconRedBox
                }
                alignItems="center"
              >
                <img
                  src="/icons/infoIcon.svg"
                  style={{ height: "24px" }}
                  alt="i"
                />
              </Box>

              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#1C1C1C",
                  padding: "3px 10px",
                }}
                alignContent="center"
                display="flex"
              >
                {isReturnable
                  ? getReturnableQuantityText()
                  : "You can not return this product"}
              </Typography>
            </Stack>
          </Box>
        ) : (
          <Skeleton variant={`rectangular`} height={100}></Skeleton>
        )}
      </Container>
    </ErrorBoundary>
  );
};

export default ReturnPolicyContainer;
