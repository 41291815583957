import React from "react";
import _ from "lodash";

import { Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import SectionTitle from "../Common/SectionTitle";
import EmptyError from "../EmptyError/EmptyError";
import ProductCard from "./ProductCard";
import SelectProductCard from "../Promotion/SelectProductCard";
import ProductsLoader from "./ProductsLoader";
import ProductService from "../../Services/ProductService";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(11),
  },
  productsListWrapper: {
    marginTop: "0px",
  },
  submitBtn: {
    height: "50px",
    position: "fixed",
    left: 0,
    bottom: 0,
    borderRadius: 0,
  },
}));

function ProductsList(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth={`xl`}>
        {props?.type === `create-offer` ? (
          props?.keyword === null || props?.keyword === "" ? (
            <SectionTitle title="Recently Bought Products" />
          ) : (
            <>
              Showing {props?.products?.length} results of{" "}
              <b>{props?.keyword}</b>
            </>
          )
        ) : props?.title !== `search-products` ? (
          <SectionTitle title={`Products`} />
        ) : (
          <Typography sx={{ fontSize: "14px" }}>
            Showing {props?.products?.length}{" "}
            {props?.products?.length > 1 ? "results" : "result"} of{" "}
            <b>{props?.keyword}</b>
          </Typography>
        )}

        {props.products && props.products.length > 0 ? (
          <>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.productsListWrapper}
              spacing={2}
            >
              {_.map(props.products, (product, index) => {
                if (
                  product.brandName !== "Unassigned Brand" &&
                  ProductService.checkIfProductEnabled(product)
                ) {
                  return (
                    <Grid item xs={6} key={product.skuCode}>
                      {props?.type !== `create-offer` ? (
                        <ProductCard
                          product={product}
                          index={index}
                          section={
                            props.title === "Recently Bought Products"
                              ? "Recently Bought Products"
                              : "Products List"
                          }
                          page={props.page}
                          stateCode={props.stateCode}
                          keyword={props.keyword}
                          newLaunch={props.newLaunch}
                        />
                      ) : (
                        <SelectProductCard
                          product={product}
                          index={index}
                          keyword={props?.keyword}
                          offerDetail={props?.offerDetail}
                          stateCode={props.stateCode}
                          selectedSkuCode={props.selectedSkuCode}
                          setSelectedSkuCode={props.setSelectedSkuCode}
                        />
                      )}
                    </Grid>
                  );
                }
              })}
            </Grid>
          </>
        ) : (
          props.loading === "done" &&
          !props.hideEmptyError && (
            <EmptyError image="EmptyPage.svg" text="Sorry! no products here" />
          )
        )}
        {props.loading === "started" && <ProductsLoader />}
      </Container>
    </div>
  );
}

export default ProductsList;
