export default class StringUtils {
  static stringContainsDecimal(inputString) {
    const decimalRegex = /\d*\.\d+/;
    return decimalRegex.test(inputString);
  }

  // strings is an array
  static fillTemplate = (template, strings) => {
    let result = "";
    for (let i = 0; i < template.length; i++) {
      if (
        template[i] === "%" &&
        !isNaN(Number(template[i + 1])) &&
        template[i + 2] === "$" &&
        template[i + 3] === "s"
      ) {
        let index = parseInt(template[i + 1]);
        result += strings[index - 1] || template.substring(i, i + 4);
        i += 3; // Skip the placeholder characters
      } else {
        result += template[i];
      }
    }
    return result;
  };
}
