import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Clamp from "react-multiline-clamp";
import BlockUi from "react-block-ui";

import { makeStyles } from "@mui/styles";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";

import config from "../../config";
import useQuery from "../../Hooks/useQuery";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import InterPartyTransfersService from "../../Services/InterPartyTransfersService";

const useStyles = makeStyles({
  pageWrapper: {
    minHeight: "300px",
    maxHeight: "300px",
    paddingBottom: "60px",
    overflow: "scroll",
    color: "#fff",
    background: "#2F2F2F",
    "& .block-ui-message": { color: "#828282" },
    "& .block-ui-overlay": { background: "none" },
  },
  scanInfoWrapper: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    borderBottom: "1px solid #ffffff1a",
    padding: "5px 16px",
  },
  scanManuallyButtonWrapper: {
    borderRadius: "24px",
    fontSize: "12px",
  },
  productWrapper: {
    padding: "10px 16px",
    marginBottom: "4px",
    color: "#fff",
    background: "#404040",
  },
  noProductScannedMessage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
    color: "#828282",
    fontWeight: 400,
    padding: "5px 16px",
  },
  pageFixedBottom: {
    position: "fixed",
    bottom: 0,
    padding: "8px 16px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 700,
    background: "#2F2F2F",
    borderTop: "1px solid #ffffff1a",
  },
  modalBox: {
    position: "absolute",
    left: "50%",
    bottom: "0",
    width: "95%",
    padding: "16px 24px",
    border: "none",
    textAlign: "center",
    background: "#fff",
    borderRadius: "8px",
    transform: "translate(-50%, -10%)",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  buttonWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "10px",
  },
  modalButton: {
    height: "40px",
    fontWeight: 700,
    textTransform: "none",
    borderRadius: "16px",
  },
});

export default function BarcodeScanDetailsPage() {
  const classes = useStyles();
  const query = useQuery();
  const navigate = useNavigate();
  const [iptCart, setIPTCart] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState("not_started");
  const [barcodeError, setBarcodeError] = useState(null);

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, { page: "Scan Page" });

    if (query.get("cartId")) {
      setLoading("started");
      const { axiosRequest, abortController } =
        InterPartyTransfersService.getIPTCart(query.get("cartId"));
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            const cart = response?.data?.responseData;
            const scanCode = query
              .get("scanCode")
              ?.split("/")
              ?.slice(-1)?.[0]
              ?.split("?")?.[0];

            if (scanCode) {
              let isScanCodeInCart = false;
              const validBarcodes = cart?.validBarcodes;
              for (let x = 0; x < validBarcodes?.length; x++) {
                if (
                  validBarcodes[x]?.barcode === scanCode ||
                  validBarcodes[x]?.boxcode === scanCode ||
                  validBarcodes[x]?.lotNumber === scanCode
                ) {
                  isScanCodeInCart = true;
                  break;
                }
              }

              setIPTCart(cart);
              if (isScanCodeInCart) {
                setLoading("done");
                setOpenModal(true);
              } else {
                handleUpdateIPTCart(cart?.cartId, "ADD_BARCODE");
              }
            } else {
              setIPTCart(cart);
              setLoading("done");
            }
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoading("done");
            console.error("Error Getting Cart: ", error);
          }
        });
      return () => abortController.abort();
    }
  }, []);

  const handleUpdateIPTCart = (cartId, action) => {
    const scanCode = query
      .get("scanCode")
      ?.split("/")
      ?.slice(-1)?.[0]
      ?.split("?")?.[0];
    setLoading("started");
    const { axiosRequest } = InterPartyTransfersService.updateIPTCart(
      cartId,
      action,
      scanCode
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          const cart = response?.data?.responseData;

          let products = cart?.products || [];
          products = products.map((product) => {
            if (product?.skuCode === cart?.validBarcodes?.[0]?.sku_code) {
              return {
                ...product,
                recentlyScanned: true,
              };
            }

            return { ...product, recentlyScanned: false };
          });

          products.sort((a, b) => b.recentlyScanned - a.recentlyScanned);
          setIPTCart({ ...cart, products });

          const productScannedProperties = {
            page: "Scan Page",
            skucode: cart?.validBarcodes?.[0]?.sku_code || "",
            barcode: scanCode,
            status: "Scan Failed",
            action: action === "ADD_BARCODE" ? "Addition" : "Removal",
          };
          if (cart?.invalidBarcodes?.[0]?.reason) {
            setOpenModal(true);
            setBarcodeError(cart?.invalidBarcodes?.[0]?.reason);
          } else if (action === "ADD_BARCODE") {
            setBarcodeError("Successfully scanned.");
            setOpenModal(true);
            productScannedProperties["status"] = "Scan Success";
          } else if (action === "REMOVE_BARCODE") {
            AndroidService.setInstructions("Scanning Again!");
            AndroidService.startAnalysing();
          }

          AnalyticsService.pushEvent(
            `Product Scanned Status`,
            productScannedProperties
          );
        }

        setLoading("done");
      })
      .catch((error) => {
        setLoading("done");
        setOpenModal(true);
        setBarcodeError(
          error?.response?.data?.message ||
            "Something went wrong, Please try again later !"
        );
        AnalyticsService.pushEvent(`Product Scanned Status`, {
          page: "Scan Page",
          barcode: scanCode,
          status: "Scan Failed",
          action: action === "ADD_BARCODE" ? "Addition" : "Removal",
        });
        console.error("Error Getting Scanned Code Details: ", error);
      });
  };

  const handleProceed = () => {
    AnalyticsService.pushEvent(`Proceed Button Clicked`, {
      page: "Scan Page",
    });

    if (!iptCart?.products?.length) {
      setBarcodeError("Your cart is empty. Add products to proceed.");
      return setOpenModal(true);
    }

    AndroidService.checkIfAndroid()
      ? AndroidService.proceedToNextPage(
          `${
            config.appUrl
          }/inter-party-transfers/scanned-products?iptRequestId=${query.get(
            `iptRequestId`
          )}&cartId=${query.get(`cartId`)}`
        )
      : navigate(
          `/inter-party-transfers/scanned-products?iptRequestId=${query.get(
            `iptRequestId`
          )}&cartId=${query.get(`cartId`)}`,
          { replace: true }
        );
  };

  const openManuallyAddRemovePage = () => {
    AnalyticsService.pushEvent(`Button Clicked`, {
      type: "Add Manually",
      page: "Scan Page",
    });

    AndroidService.checkIfAndroid()
      ? AndroidService.proceedToNextPage(
          `${
            config.appUrl
          }/inter-party-transfers/manually-add-products?iptRequestId=${query.get(
            `iptRequestId`
          )}&cartId=${query.get(`cartId`)}`
        )
      : navigate(
          `/inter-party-transfers/manually-add-products?iptRequestId=${query.get(
            `iptRequestId`
          )}&cartId=${query.get(`cartId`)}`,
          { replace: true }
        );
  };

  return (
    <div style={{ minHeight: "100vh", background: "#2F2F2F" }}>
      <div className={classes.pageWrapper}>
        <div className={classes.scanInfoWrapper}>
          <Button
            className={classes.scanManuallyButtonWrapper}
            variant={`contained`}
            onClick={openManuallyAddRemovePage}
          >
            Add/Remove Manually
          </Button>
        </div>
        <BlockUi
          tag="div"
          blocking={loading === "started"}
          message={`Loading...`}
          style={{ minHeight: "85vh" }}
          keepInView
        >
          {iptCart?.products?.length
            ? iptCart?.products?.map((product, index) => (
                <Grid key={index} container className={classes.productWrapper}>
                  <Grid item xs={2}>
                    <img
                      src={product?.productImage}
                      alt=""
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{ paddingLeft: "10px" }}>
                    <Clamp lines={2} maxLines={2}>
                      <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        sx={{ wordBreak: "break-all" }}
                      >
                        {product?.productName}
                      </Typography>
                    </Clamp>

                    <Typography variant="subtitle2" fontWeight={400}>
                      Quantity - {product?.quantity}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            : loading === "done" && (
                <Typography
                  variant="subtitle1"
                  className={classes.noProductScannedMessage}
                >
                  No Products Scanned Yet!
                </Typography>
              )}
        </BlockUi>

        <Grid container alignItems="center" className={classes.pageFixedBottom}>
          <Grid item xs={8} container>
            <Grid item xs={6}>
              {iptCart?.totalProducts || 0}
            </Grid>
            <Grid item xs={6}>
              {iptCart?.totalQuantity || 0}
            </Grid>
            <Grid item xs={6}>
              Products
            </Grid>
            <Grid item xs={6}>
              Quantity
            </Grid>
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Button
              fullWidth
              variant="contained"
              sx={{ borderRadius: "10px", height: "40px" }}
              onClick={handleProceed}
            >
              Proceed
            </Button>
          </Grid>
        </Grid>

        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box className={classes.modalBox}>
            <Typography variant="subtitle1" sx={{ marginBottom: "12px" }}>
              {barcodeError
                ? barcodeError
                : "Are you sure, you want to remove the scanned product ?"}
            </Typography>
            {barcodeError ? (
              <Button
                variant="contained"
                color="secondary"
                className={classes.modalButton}
                onClick={() => {
                  setBarcodeError(null);
                  setOpenModal(false);
                  AndroidService.setInstructions("Scanning Again!");
                  AndroidService.startAnalysing();
                }}
              >
                Okay
              </Button>
            ) : (
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  className={classes.modalButton}
                  onClick={() => {
                    handleUpdateIPTCart(iptCart?.cartId, "REMOVE_BARCODE");
                    setOpenModal(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.modalButton}
                  onClick={() => {
                    setOpenModal(false);
                    AndroidService.setInstructions("Scanning Again!");
                    AndroidService.startAnalysing();
                  }}
                >
                  No
                </Button>
              </div>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
}
