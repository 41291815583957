import React from "react";

import PartnerCard from "./PartnerCard";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import _ from "lodash";
import EmptyError from "../EmptyError/EmptyError";
import { Box, Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { PARTNERS_TO_SHOW_IN_A_SINGLE_SCROLL } from "../../Services/PartnerService";

const PartnersList = (props) => {
  return (
    <>
      <BlockUi
        tag="div"
        blocking={props.loading === "started"}
        message={`Please wait`}
        keepInView={true}
        style={{ height: "85vh" }}
      >
        {props.partners?.length > 0 ? (
          <>
            <InfiniteScroll
              dataLength={props.partners?.length}
              next={props.fetchMorePartners}
              hasMore={
                Math.ceil(
                  props.partners?.length / PARTNERS_TO_SHOW_IN_A_SINGLE_SCROLL
                ) <
                Math.ceil(
                  props.totalPartners / PARTNERS_TO_SHOW_IN_A_SINGLE_SCROLL
                )
              }
              loader={
                <Typography
                  variant={`h4`}
                  sx={{ textAlign: "center", fontSize: "16px" }}
                >
                  Loading...
                </Typography>
              }
              style={{ paddingBottom: "80px" }}
            >
              {_.map(props.partners, (partner) => {
                return (
                  <PartnerCard
                    partner={partner}
                    key={partner?.referenceCustomerId}
                    showZohoStatus={props?.showZohoStatus}
                  />
                );
              })}
            </InfiniteScroll>
          </>
        ) : (
          <>
            {props.loading === "done" && (
              <Box sx={{ marginTop: "12px" }}>
                <EmptyError
                  text={
                    props?.noResultsText
                      ? props?.noResultsText
                      : "No Partners Found"
                  }
                  hideBack={true}
                />
              </Box>
            )}
          </>
        )}
      </BlockUi>
    </>
  );
};

export default PartnersList;
