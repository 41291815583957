import React, { useEffect, useRef, useState } from "react";
import ErrorBoundary from "../Common/ErrorBoundary";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import EastIcon from "@mui/icons-material/East";
import { debounce } from "@mui/material/utils";
import DistrictsService from "../../Services/DistrictsService";
import PartnerService from "../../Services/PartnerService";
import OnboardingProgressStepper from "./OnboardingProgressStepper";
import { useNavigate } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductsUnlockedDialog from "./ProductsUnlockedDialog";
import StateService from "../../Services/StateService";
import HisaabService from "../../Services/HisaabService";
import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import FooterImageLogo from "../Common/FooterImageLogo";
import Backdrop from "@mui/material/Backdrop";
import NeedHelp from "../Common/NeedHelp";
import useReferrerParams from "../../Hooks/useReferrerParams";
import ReferralService from "../../Services/ReferralService";
import TopBar from "../Common/TopBar";

const onboardingSteps = [
  { title: "Basic Info", subtitle: "Unlock Products" },
  { title: "Upload Documents", subtitle: "Unlock Pricing" },
  { title: "Deposit Security", subtitle: "Unlock Credit Limit" },
];

const referrers = ReferralService.referrers;

export default function PartnerDetails(props) {
  const navigate = useNavigate();

  const {
    storeReferralParams,
    referralCodeFromLocalStorage,
    referrerFromLocalStorage,
    referrerMobileNumberFromLocalStorage,
    referrerEmailIdFromLocalStorage,
  } = useReferrerParams();

  let validatedQueryParamReferralCode = "";
  if (referralCodeFromLocalStorage?.length === 7) {
    validatedQueryParamReferralCode = referralCodeFromLocalStorage;
  }

  let validatedQueryParamReferrer = "";
  if (referrers.some((referrer) => referrer === referrerFromLocalStorage)) {
    validatedQueryParamReferrer = referrerFromLocalStorage;
  }

  let validatedQueryParamReferrerMobileNumber = "";
  if (referrerMobileNumberFromLocalStorage?.length === 10) {
    validatedQueryParamReferrerMobileNumber =
      referrerMobileNumberFromLocalStorage;
  }
  let validatedQueryParamReferrerEmailId = "";
  if (
    referrerEmailIdFromLocalStorage?.length > 1 &&
    referrerEmailIdFromLocalStorage.indexOf("@") > 0
  ) {
    validatedQueryParamReferrerEmailId = referrerEmailIdFromLocalStorage;
  }
  const [partnerName, setPartnerName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [landMark, setLandMark] = useState("");
  const [userState, setUserState] = React.useState("");
  const [referralCode, setReferralCode] = React.useState(
    validatedQueryParamReferralCode || ""
  );
  const [referrer, setReferrer] = React.useState(
    validatedQueryParamReferrer || ""
  );
  const [referrerEmailId, setReferrerEmailId] = React.useState(
    validatedQueryParamReferrerEmailId || ""
  );
  const [referrerMobileNumber, setReferrerMobileNumber] = React.useState(
    validatedQueryParamReferrerMobileNumber || ""
  );
  const [businessType, setBusinessType] = React.useState("");
  const [businessCategories, setBusinessCategories] = useState([]);
  const [taluka, setTaluka] = useState("");
  const [village, setVillage] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [districtValue, setDistrictValue] = React.useState(null);
  const [gstNumber, setGstNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [loading, setLoading] = useState("not-started");
  const errorMessageRef = useRef();

  const handleChangePartnerName = (event) => {
    setPartnerName(event.target.value);
  };
  const handleChangeEmailId = (event) => {
    setEmailId(event.target.value);
  };
  const handleChangeShopName = (event) => {
    setShopName(event.target.value);
  };

  const handleChangeBusinessType = (event) => {
    setBusinessType(event.target.value);
  };
  const handleChangeBusinessCategories = (event) => {
    const {
      target: { value },
    } = event;
    setBusinessCategories(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeUserState = (event) => {
    setUserState(event.target.value);
  };

  const handleChangeReferralCode = (event) => {
    setReferralCode(event.target.value);
  };
  const handleChangeReferrer = (event) => {
    setReferrer(event.target.value);
  };

  const handleChangeReferrerEmailId = (event) => {
    setReferrerEmailId(event.target.value);
  };

  const handleChangeReferrerMobileNumber = (event) => {
    setReferrerMobileNumber(event.target.value);
  };

  const handleChangeLandmark = (event) => {
    setLandMark(event.target.value);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event.target.value);
  };
  const handleChangeVillage = (event) => {
    setVillage(event.target.value);
  };
  const handleChangePinCode = (event) => {
    setPinCode(event.target.value);
  };
  const handleChangeGstNumber = (event) => {
    setGstNumber(event.target.value);
  };

  const [districtOptions, setDistrictOptions] = React.useState([]);
  const [districtInput, setDistrictInput] = React.useState("");

  const [districtInputValue, setDistrictInputValue] = React.useState("");
  const [loadingDistricts, setLoadingDistricts] = React.useState(false);
  const districtAutoCompleteFetcher = React.useMemo(
    () =>
      debounce((request, callback) => {
        const input = request?.input;
        if (input) {
          DistrictsService.getHardcodedDistrictsAutoComplete(input, userState)
            .then((response) => {
              if (response?.data?.responseData) {
                callback(response?.data?.responseData);
              }
            })
            .catch((error) => {});
        } else {
          callback([]);
        }
      }, 500),
    [userState]
  );

  const [showProductsUnlockedDialog, setShowProductsUnlockedDialog] =
    useState(false);

  React.useEffect(() => {
    let active = true;

    if (districtInputValue === "") {
      setDistrictOptions(districtValue ? [districtValue] : []);
      return undefined;
    }

    setLoadingDistricts(true);
    districtAutoCompleteFetcher({ input: districtInputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (districtValue) {
          newOptions = [districtValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setLoadingDistricts(false);

        setDistrictOptions(newOptions);
      } else {
        setLoadingDistricts(false);
      }
    });

    return () => {
      active = false;
    };
  }, [districtValue, districtInputValue]);

  useEffect(() => {
    AnalyticsService.pushEvent("Page Viewed", {
      page: "Fill Partner Details",
    });

    if (PartnerService.isOtpVerified()) {
      if (PartnerService.hasFilledPartnerDetails()) {
        navigate("/register/documents-upload");
      } else if (PartnerService.hasUploadedDocuments()) {
        navigate("/dashboard", { replace: true });
      } else {
        navigate("/register/partner-details");
      }
    } else {
      navigate("/");
    }
  }, []);

  const handleSubmit = (event) => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Submit Partner Details",
      page: "Fill Partner Details",
      isComplete: true,
      referralCode: referralCode,
      name: shopName,
      partnerName,
      mobileNumber: localStorage.getItem("onboardingPartnerMobileNumber"),
      emailId: emailId,
      state: userState,
    });
    event.preventDefault();
    setErrorCode("");
    setErrorMessage("");

    if (!districtValue) {
      setErrorCode("INVALID_DISTRICT_NAME");
      setErrorMessage("Please select a valid district name & try again.");
      errorMessageRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return;
    }

    setLoading("started");
    const registrationData = {
      name: shopName,
      partnerName,
      businessType,
      businessCategories,
      contacts: {
        email: emailId,
        mobileNumber: localStorage.getItem("onboardingPartnerMobileNumber"),
      },
      address: {
        // address: shopAddress,
        village,
        taluka,
        landMark,
        district: districtValue
          ? _.toUpper(districtValue)
          : _.toUpper(districtInputValue),
        state: userState,
        pincode: pinCode,
        country: "India",
        postOffice: "N/A",
      },
      referrer: referrer,
      referrerEmailId: referrer === "Sales Officer" ? referrerEmailId : "",
      referrerMobileNumber:
        referrer === "Other Retailer" || referrer === "Farmer"
          ? referrerMobileNumber
          : "",
      referralCode: referralCode,
      bankAndGST: {
        gstNumber,
        // panNumber: "",
      },
      isComplete: true,
    };

    const { axiosRequest } = PartnerService.registerPartner(registrationData);
    axiosRequest
      .then((response) => {
        // setLoading("done");
        if (response?.status === 200) {
          let responseData = response?.data?.responseData;
          if (
            responseData?.message === "Successfully created Institution" &&
            responseData?.institutionId &&
            responseData?.xauth
          ) {
            localStorage.setItem("farmerId", responseData?.saathiId);
            localStorage.setItem("X-Authorization-Token", responseData?.xauth);
            localStorage.setItem("institutionId", responseData?.institutionId);
            PartnerService.savePartnerStatuses(responseData);
            fetchAndSaveProfile();

            setShowProductsUnlockedDialog(true);
          } else {
            setLoading("done");
            setErrorMessage("Something went wrong. Please try again.");
            errorMessageRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            if (!responseData?.message) {
              setErrorCode("REGISTER_NO_SUCCESS_MESSAGE");
            }
            if (!responseData?.institutionId) {
              setErrorCode("REGISTER_NO_INSTITUTION_ID");
            }
            if (!responseData?.xauth) {
              setErrorCode("REGISTER_NO_TOKEN");
            }
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          if (error?.response?.data) {
            const errorData = error?.response?.data?.responseData;
            if (errorData && !_.isEmpty(errorData)) {
              if (errorData?.validationFailureData) {
                let validationMessages = "";
                let validationMessagesArray = [];
                let errorMessage = "";
                _.each(
                  errorData.validationFailureData,
                  (validationErrorCodes, key) => {
                    _.forEach(validationErrorCodes, (errorCode) => {
                      if (errorCode === "EMPTY") {
                        errorMessage = `${errorMessage} ${_.startCase(
                          key
                        )} is required.`;
                      }
                      if (errorCode === "REQUIRED") {
                        errorMessage = `${errorMessage} ${_.startCase(
                          key
                        )} is required.`;
                      }
                      if (errorCode === "INVALID_FORMAT") {
                        errorMessage = `${errorMessage} ${_.startCase(
                          key
                        )} is invalid.`;
                      }
                    });

                    validationMessagesArray.push(errorMessage);
                  }
                );
                validationMessages = validationMessagesArray.join(", ");
                setErrorMessage(
                  validationMessages +
                    " Please check your inputs and try again."
                );
                setErrorCode("REGISTER_VALIDATION_FAILURE");
              } else {
                setErrorMessage("Something went wrong. Please try again.");
                setErrorCode("REGISTER_UNKNOWN_ERROR");
              }
              errorMessageRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            } else {
              if (error?.response?.data?.message) {
                let errorResponseMessage = error?.response?.data?.message;

                if (
                  errorResponseMessage?.indexOf(
                    "Could not create profile, a profile already exists"
                  ) > -1
                ) {
                  setErrorMessage("Something went wrong. Please try again.");
                  setErrorCode("REGISTER_EXISTING_PROFILE");
                } else {
                  setErrorMessage("Something went wrong. Please try again.");
                  setErrorCode("REGISTER_UNKNOWN_ERROR");
                }
              } else {
                setErrorMessage("Something went wrong. Please try again.");
                setErrorCode("REGISTER_UNKNOWN_ERROR");
              }
              errorMessageRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          } else {
            setErrorMessage("Something went wrong. Please try again.");
            setErrorCode("REGISTER_UNKNOWN_ERROR");
            errorMessageRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        } else if (error?.response?.status === 401) {
          setErrorMessage("Something went wrong. Please try again.");
          setErrorCode("AUTH_TOKEN_EXPIRED");
          errorMessageRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          handleLogout(true);
        } else {
          setErrorMessage("Something went wrong. Please try again.");
          setErrorCode("REGISTER_API_FAILURE");
          errorMessageRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        setLoading("done");
      });
  };

  const fetchAndSaveProfile = () => {
    setLoading("started");
    const onboardingPartnerMobileNumber = localStorage?.getItem(
      "onboardingPartnerMobileNumber"
    );
    if (onboardingPartnerMobileNumber) {
      const farmerId = localStorage.getItem("farmerId");
      const { axiosRequest } = PartnerService.getPartnerDetailedData(farmerId);
      axiosRequest
        .then((partnerDataResponse) => {
          const partnerData = partnerDataResponse?.data?.responseData;
          const { farmerType, userId, address } = partnerData;
          const profileAddress = address?.profileAddress;

          if (farmerType === "B2B Partner" || farmerType === "B2B Onboarding") {
            let source = ``;
            let partnerStatus = ``;
            let stateName = profileAddress?.state;
            if (stateName) {
              let stateFromName = StateService.getStateByName(stateName);
              let stateCode = ``;
              if (
                stateFromName &&
                stateFromName.stateCode &&
                stateFromName.stateCode !== ""
              ) {
                const { axiosRequest } =
                  HisaabService.getInstitutionDetails(farmerId);
                axiosRequest
                  .then((institutionalDetailsResponse) => {
                    const institutionalDetails =
                      institutionalDetailsResponse?.data?.responseData;
                    if (
                      institutionalDetails?.status === "ACTIVE" ||
                      (institutionalDetails?.status === "UNVERIFIED" &&
                        institutionalDetails?.subStatus !==
                          "BASIC_DETAILS_PENDING") ||
                      (institutionalDetails?.status === "INACTIVE" &&
                        institutionalDetails?.inactivatedOnDate > 0 &&
                        !PartnerService.isPartnerInactiveSinceMoreThanNdays(
                          institutionalDetails?.inactivatedOnDate
                        ))
                    ) {
                      stateCode = stateFromName.stateCode;
                      source = `B2B${stateCode}`;
                      partnerStatus = institutionalDetails?.status;
                      localStorage.setItem("source", source);
                      localStorage.setItem("userId", userId);
                      localStorage.setItem(`state`, stateName);
                      localStorage.setItem("farmerId", farmerId);
                      localStorage.setItem("mobile", partnerData.mobile1);
                      localStorage.setItem("pinCode", profileAddress.pinCode);
                      localStorage.setItem(
                        `creditPeriod`,
                        institutionalDetails?.creditPeriod
                      );
                      if (
                        institutionalDetails?.referrerDetails?.referrerEmailId
                      ) {
                        localStorage.setItem(
                          "onboardingReferrerSalesOfficerEmailID",
                          institutionalDetails?.referrerDetails?.referrerEmailId
                        );
                      }
                      localStorage.setItem("partnerStatus", partnerStatus);
                      AnalyticsService.pushProfile(
                        farmerId,
                        partnerData.mobile1,
                        source
                      );
                      PartnerService.setWhatsappOptIn(
                        farmerId,
                        onboardingPartnerMobileNumber,
                        "OPT_IN"
                      );
                      // setShowProductsUnlockedDialog(false);
                      setLoading("done");
                      // navigate("/dashboard");
                    } else {
                      setLoading("done");
                      // setPartnerInactive(true);
                      localStorage.removeItem("otpVerified");
                      localStorage.removeItem("X-Authorization-Token");

                      if (
                        institutionalDetails?.status === "UNVERIFIED" &&
                        institutionalDetails?.subStatus ===
                          "BASIC_DETAILS_PENDING"
                      ) {
                        setErrorCode(
                          "FETCH_INSTITUTION_PARTNER_BASIC_DETAILS_PENDING"
                        );
                      } else {
                        setErrorCode("FETCH_INSTITUTION_PARTNER_INACTIVE");
                      }
                    }
                  })
                  .catch((error) => {
                    setErrorMessage("Something went wrong. Please try again.");
                    if (error.data) {
                      console.log(error.data);
                    } else {
                      console.log(error);
                    }
                    setErrorCode("FETCH_INSTITUTION_API_FAILURE");
                    setLoading("done");
                    errorMessageRef?.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  });
              } else {
                setErrorMessage("Something went wrong. Please try again.");
                setErrorCode("FETCH_PROFILE_NO_VALID_STATE");
                setLoading("done");
                errorMessageRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            } else {
              setErrorMessage("Something went wrong. Please try again.");
              if (profileAddress?.stateName) {
                setErrorCode("FETCH_PROFILE_NO_PROFILE_STATE");
              }
              setLoading("done");
              errorMessageRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          } else {
            setErrorMessage("Something went wrong. Please try again.");
            setErrorCode("FETCH_PROFILE_NOT_B2B_PARTNER");
            setLoading("done");
            errorMessageRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        })
        .catch((error) => {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setErrorMessage("Something went wrong. Please try again.");
          setLoading("done");
          errorMessageRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        });
    } else {
      setErrorMessage("Something went wrong. Please try again.");
      setErrorCode("REGISTER_NO_MOBILE");
      setLoading("done");
      errorMessageRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleLogout = (isAutoLogout) => {
    PartnerService.logout();
    window.location.reload();
    if (isAutoLogout) {
      AnalyticsService.pushEvent("Partner Auto Logged Out");
    } else {
      AnalyticsService.pushEvent("Logout Clicked");
    }
  };

  return (
    <ErrorBoundary>
      <TopBar
        title={`SAATHI`}
        showCart={false}
        showHisaab={false}
        showCallAgriDoctor={false}
        showFieldAgentLogout={true}
        handleLogout={handleLogout}
      />
      <Box sx={{ backgroundColor: `background.main` }}>
        <Box sx={{ padding: 2 }}>
          <OnboardingProgressStepper
            steps={onboardingSteps}
            currentlyActiveStep={0}
          />
        </Box>

        <Box sx={{ background: "#FFF", borderRadius: "16px", padding: 2 }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "18px",
              fontWeight: 700,
              marginBottom: 1,
            }}
          >
            Why Partner with Us
          </Typography>
          <ReactVideoPlayer
            video={{ src: "https://www.youtube.com/watch?v=F9YCdQq2yvY" }}
            autoplay={true}
            style={{ overflow: "hidden", borderRadius: "16px" }}
            light={true}
            height={"200px"}
            eventProperties={{
              page: "Fill Partner Details",
              type: "Why Partner With Us",
              videoUrl: "https://www.youtube.com/watch?v=F9YCdQq2yvY",
            }}
          />
        </Box>

        <form onSubmit={handleSubmit}>
          <Container maxWidth={`xl`} sx={{ paddingTop: "16px" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
              Complete the form to unlock our range of products
            </Typography>
            <Box sx={{ marginTop: 2, marginBottom: 5 }}>
              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Your Name *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  placeholder="Enter Here"
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    pattern: `[A-Za-z0-9 ]{2,100}`,
                    title: `e.g. Prakash Patil`,

                    autoComplete: `name`,
                  }}
                  type={`text`}
                  value={partnerName}
                  onChange={handleChangePartnerName}
                  required={true}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Email ID *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    title: `e.g. prakash@gmail.com`,

                    autoComplete: `email`,
                  }}
                  type={`email`}
                  value={emailId}
                  onChange={handleChangeEmailId}
                  required={true}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Shop Name *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    pattern: `[A-Za-z0-9 ]{2,100}`,
                    title: `e.g. Mauli Krishi Kendra`,
                    autoComplete: `organization`,
                  }}
                  type={`text`}
                  value={shopName}
                  onChange={handleChangeShopName}
                  required={true}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Shop Address *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    title: `e.g. A-1, First Floor`,
                    autoComplete: `address`,
                  }}
                  type={`text`}
                  value={landMark}
                  onChange={handleChangeLandmark}
                  required={true}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Business Type *`}
                </Typography>
                <Select
                  variant={`outlined`}
                  size={`small`}
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    type: "select",
                    title: "e.g. Proprietorship",
                  }}
                  required={true}
                  value={businessType}
                  onChange={handleChangeBusinessType}
                  fullWidth
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <>Select Business Type</>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem disabled value="">
                    Select Business Type
                  </MenuItem>
                  {_.map(
                    PartnerService.businessTypes,
                    (businessType, index) => {
                      return (
                        <MenuItem value={businessType} key={index}>
                          {businessType}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Business Categories`}
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    variant={`outlined`}
                    size={`small`}
                    sx={{
                      marginTop: 1.25,
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                      },
                    }}
                    inputProps={{
                      sx: {
                        backgroundColor: "#ffffff",
                        borderRadius: 1.25,
                      },
                      type: "select",
                      title: "e.g. Crop Nutrition",
                    }}
                    value={businessCategories}
                    onChange={handleChangeBusinessCategories}
                    multiple
                    fullWidth
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <>Select Business Categories</>;
                      }
                      return selected.join(", ");
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {_.map(
                      PartnerService.businessCategoriesList,
                      (category, index) => (
                        <MenuItem key={index} value={category}>
                          <ListItemText primary={category} />
                          <Checkbox
                            checked={businessCategories.indexOf(category) > -1}
                          />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`State *`}
                </Typography>
                <Select
                  variant={`outlined`}
                  size={`small`}
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    type: "select",
                    title: "e.g. Maharashtra",

                    autoComplete: "address-level1",
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                  required={true}
                  value={userState}
                  onChange={handleChangeUserState}
                  fullWidth
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <>Select Your State</>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem disabled value="">
                    Select Your State
                  </MenuItem>
                  {_.map(
                    PartnerService.selfOnboardingStates,
                    (state, index) => {
                      return (
                        <MenuItem value={state} key={index}>
                          {state}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`District *`}
                </Typography>
                <Autocomplete
                  // freeSolo
                  disableClearable
                  // options={districts}
                  size={"small"}
                  filterOptions={(x) => x}
                  options={districtOptions}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={districtValue}
                  noOptionsText={
                    districtInputValue?.length > 0 &&
                    districtOptions?.length === 0 &&
                    !loadingDistricts
                      ? "No locations"
                      : ""
                  }
                  onChange={(event, newValue) => {
                    setDistrictOptions(
                      newValue
                        ? [newValue, ...districtOptions]
                        : districtOptions
                    );
                    setDistrictValue(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setDistrictInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label={props.title}
                      required
                      sx={{
                        marginTop: 1.25,
                        "& fieldset": {
                          borderColor: "#FFFFFF",
                        },
                      }}
                      InputProps={{
                        sx: {
                          backgroundColor: "#ffffff",
                          borderRadius: 1.25,
                        },
                        endAdornment: (
                          <>
                            {loadingDistricts ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        ...params.InputProps,
                      }}
                      inputProps={{
                        type: "search",
                        title: "e.g. Pune",
                        autoComplete: `address-level2`,
                        placeholder: "Start typing to search",
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Taluka *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    pattern: `[A-Za-z0-9 ]{2,100}`,
                    title: `e.g. Haveli`,
                    autoComplete: `address-level3`,
                  }}
                  type={`text`}
                  value={taluka}
                  onChange={handleChangeTaluka}
                  required={true}
                  fullWidth
                />
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Village *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    pattern: `[A-Za-z0-9 ]{2,100}`,
                    title: `e.g. Alandi`,
                    autoComplete: `address-level4`,
                  }}
                  type={`text`}
                  value={village}
                  onChange={handleChangeVillage}
                  required={true}
                  fullWidth
                />
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Pin Code *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    pattern: `[A-Za-z0-9 ]{2,100}`,
                    title: `e.g. 411001`,
                    autoComplete: `postal-code`,
                  }}
                  type={`text`}
                  value={pinCode}
                  onChange={handleChangePinCode}
                  required={true}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Sales Contact *`}
                </Typography>
                <Select
                  variant={`outlined`}
                  size={`small`}
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    type: "select",
                    title: "e.g. Sales Officer",
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                  required={true}
                  value={referrer}
                  onChange={handleChangeReferrer}
                  fullWidth
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <>Select Your Sales Contact</>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem disabled value="">
                    Select Your Sales Contact
                  </MenuItem>
                  {_.map(referrers, (referrer, index) => {
                    return (
                      <MenuItem value={referrer} key={index}>
                        {referrer}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              {referrer === "Sales Officer" ? (
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    variant={`body1`}
                    sx={{ fontWeight: "600", fontSize: 14 }}
                  >
                    {`Sales Officer Email ID *`}
                  </Typography>

                  <TextField
                    variant={`outlined`}
                    size={`small`}
                    placeholder="Enter Here"
                    sx={{
                      marginTop: 1.25,
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                      },
                    }}
                    inputProps={{
                      sx: {
                        backgroundColor: "#ffffff",
                        borderRadius: 1.25,
                      },
                      minLength: 2,
                      maxLength: 100,
                      title: `e.g. sales.officer@agrostar.in`,
                      autoComplete: `email`,
                    }}
                    type={`email`}
                    value={referrerEmailId}
                    onChange={handleChangeReferrerEmailId}
                    required={true}
                    fullWidth
                  />
                </Box>
              ) : (
                <></>
              )}

              {/* {referrer === "Other Retailer" ? (
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    variant={`body1`}
                    sx={{ fontWeight: "600", fontSize: 14 }}
                  >
                    {`Referrer Retailer Mobile Number *`}
                  </Typography>

                  <TextField
                    variant={`outlined`}
                    size={`small`}
                    placeholder="Enter Here"
                    sx={{
                      marginTop: 1.25,
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                      },
                    }}
                    inputProps={{
                      sx: {
                        backgroundColor: "#ffffff",
                        borderRadius: 1.25,
                      },
                      minLength: 10,
                      maxLength: 10,
                      title: `e.g. 9999999999`,
                      autoComplete: `tel`,
                      pattern: "[0-9]+",
                    }}
                    type={`tel`}
                    value={referrerMobileNumber}
                    onChange={handleChangeReferrerMobileNumber}
                    required={true}
                    fullWidth
                  />
                </Box>
              ) : (
                <></>
              )} */}

              {/* {referrer === "Farmer" ? (
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    variant={`body1`}
                    sx={{ fontWeight: "600", fontSize: 14 }}
                  >
                    {`Referrer Farmer Mobile Number *`}
                  </Typography>

                  <TextField
                    variant={`outlined`}
                    size={`small`}
                    placeholder="Enter Here"
                    sx={{
                      marginTop: 1.25,
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                      },
                    }}
                    inputProps={{
                      sx: {
                        backgroundColor: "#ffffff",
                        borderRadius: 1.25,
                      },
                      minLength: 10,
                      maxLength: 10,
                      title: `e.g. 9999999999`,
                      autoComplete: `tel`,
                      pattern: "[0-9]+",
                    }}
                    type={`tel`}
                    value={referrerMobileNumber}
                    onChange={handleChangeReferrerMobileNumber}
                    required={true}
                    fullWidth
                  />
                </Box>
              ) : (
                <></>
              )} */}

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Referral Code`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 7,
                    maxLength: 7,
                    pattern: `[A-Za-z0-9 ]{2,100}`,
                    title: `e.g AABBCC7`,
                  }}
                  type={`text`}
                  value={referralCode}
                  onChange={handleChangeReferralCode}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`GST Number`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 15,
                    maxLength: 15,
                    pattern: `\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}`,
                    title: `e.g. e.g. 22AAAAA0000A1Z5`,
                    autoComplete: ``,
                  }}
                  type={`text`}
                  value={gstNumber}
                  onChange={handleChangeGstNumber}
                  required={false}
                  fullWidth
                />
              </Box>
              <Typography sx={{ pt: 2, color: "#666666", fontSize: "12px" }}>
                All fields marked with an asterisk (*) are required
              </Typography>
            </Box>

            <Box ref={errorMessageRef}>
              {errorMessage && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    variant={`body2`}
                    fontWeight={600}
                    color={`primary`}
                  >
                    Error: {errorMessage}
                    <br />
                  </Typography>
                </Box>
              )}
            </Box>

            {errorCode && (
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  variant={`caption`}
                  sx={{ color: "#222222", opacity: 0.3 }}
                >
                  {errorCode}
                  <br />
                </Typography>
              </Box>
            )}
          </Container>

          <FooterImageLogo />

          <Box
            sx={(theme) => ({
              bottom: 0,
              position: "fixed",
              width: "100%",
              marginTop: 2,
              [theme.breakpoints.up("md")]: {
                maxWidth: "600px",
              },
            })}
          >
            <Button
              fullWidth={true}
              variant={`contained`}
              size={"large"}
              type={`submit`}
              sx={{ borderRadius: "0px" }}
              endIcon={<EastIcon fontSize="medium" />}
              onClick={() =>
                AnalyticsService.pushEvent("Button Clicked", {
                  type: "Submit Partner Details",
                  page: "Fill Partner Details",
                })
              }
            >
              Continue
            </Button>
          </Box>
        </form>
      </Box>

      <Backdrop sx={{}} open={loading === "started"} onClick={() => {}}>
        <CircularProgress sx={{ color: "#FFFFFF" }} />
      </Backdrop>

      <ProductsUnlockedDialog
        open={showProductsUnlockedDialog}
        handleClickViewProducts={() => {
          AnalyticsService.pushEvent("Button Clicked", {
            type: "View Products",
            page: "Fill Partner Details",
          });
          // setShowProductsUnlockedDialog(false);
          navigate("/dashboard", { replace: true });
        }}
        handleClickUnlockPrices={() => {
          AnalyticsService.pushEvent("Button Clicked", {
            type: "Unlock Prices",
            page: "Fill Partner Details",
          });
          setShowProductsUnlockedDialog(false);
          navigate("/register/documents-upload");
        }}
        onClose={() => {
          setShowProductsUnlockedDialog(false);
        }}
      />

      <NeedHelp title={`Need Help`} page={`Fill Partner Details`} />
    </ErrorBoundary>
  );
}
