import React from "react";

import { Typography } from "@mui/material";

const ErrorMessage = (props) => {
  return (
    <Typography variant={"body1"} sx={{ marginTop: "10px" }} color={`error`}>
      {props.message}
    </Typography>
  );
};

export default ErrorMessage;
