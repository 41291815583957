import React from "react";
import Clamp from "react-multiline-clamp";

import { VisibilityOutlined } from "@mui/icons-material";
import { Box, Button, Card, Grid, Typography } from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";

export default function CatalogRecommendedProductCard(props) {
  return (
    <>
      <Card
        key={props.product.skuCode}
        sx={{
          marginTop: "10px",
          padding: 1,
          borderRadius: "16px",
          border: "1px solid #CACACA",
          boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
          backgroundColor: "#CACACA",
        }}
        elevation={0}
      >
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={2}>
            <Box>
              {props.product.productImages?.[0]?.name ? (
                <img
                  src={props.product.productImages?.[0]?.name}
                  style={{ borderRadius: "16px" }}
                  height={50}
                  alt={``}
                />
              ) : (
                <img
                  src={`/icons/dummy.jpeg`}
                  style={{ borderRadius: "16px" }}
                  alt={``}
                  height={50}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant={`body1`}
                  fontSize={12}
                  fontWeight={700}
                  sx={{ minHeight: "35px" }}
                >
                  <Clamp withTooltip lines={2}>
                    {props.product?.productName}
                  </Clamp>
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container sx={{ marginTop: 1 }}>
              <Grid item xs={2}>
                <VisibilityOutlined
                  sx={{ color: "rgba(79, 79, 79, 1)" }}
                  fontSize={"small"}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant={`body1`}
                  fontSize={12}
                  sx={{
                    marginTop: "1px",
                    marginBottom: "-2px",
                  }}
                >
                  <b>{` ${props.product?.viewCount}`}</b> Farmer Views
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Button
              variant={`outlined`}
              size={"small"}
              color={"primary"}
              sx={{
                borderRadius: "16px",
                backgroundColor: "#ffffff",
                fontWeight: "700",
                textTransform: "none",
              }}
              href={`/products/${props.product.skuCode}?from=${window.location.pathname}`}
              onClick={() => {
                AnalyticsService.pushEvent(
                  "Store Front Catalog Recommended Product Clicked",
                  { skuCode: props?.product?.skuCode }
                );
              }}
            >
              Buy Now
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
