import React from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AnalyticsService from "../../Services/AnalyticsService";
import { CheckCircle, Star } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  productCardWrapper: {
    position: "relative",
    paddingTop: "20px",
    border: "none",
    borderRadius: "10px",
    boxShadow: "0px 10px 10px #E0E0E0",
  },
  checkCircle: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  productName: {
    minHeight: "55px",
    maxHeight: "55px",
    fontSize: "14px",
    lineHeight: "1.3em",
    letterSpacing: "0px",
    wordWrap: "break-word",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  productRating: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "10px",
    fontWeight: "500",
    padding: "5px 5px 5px 10px",
    color: "#fff",
    background: "#00733E",
    borderRadius: "3px",
    borderTopLeftRadius: "15px",
  },
}));

function SelectProductCard({
  index,
  product,
  keyword,
  offerDetail,
  selectedSkuCode,
  setSelectedSkuCode,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClickProductClick = () => {
    AnalyticsService.pushEvent(`Product Card For Create Offer Clicked`, {
      mrp: product?.mrp || 0,
      position: index + 1 || "",
      skuCode: product?.skuCode || "",
      productName: product?.productName || "",
      sellingPrice: product?.sellingPrice || 0,
    });
    setSelectedSkuCode(product?.skuCode);
    navigate(
      `/create-offer?keyword=${keyword || ""}&skuCode=${
        product?.skuCode
      }&title=${offerDetail?.title}&description=${
        offerDetail?.description
      }&theme=${offerDetail?.theme}`
    );
  };

  return (
    <>
      {product?.skuCode && (
        <Card
          className={classes.productCardWrapper}
          variant={"outlined"}
          onClick={handleClickProductClick}
        >
          <CheckCircle
            className={classes.checkCircle}
            sx={{
              color:
                selectedSkuCode === product?.skuCode ? "#9D2123" : "#D9D9D9",
            }}
          />
          {product?.rating?.average > 0 && (
            <div className={classes.productRating}>
              {product?.rating?.average}
              <Star sx={{ width: "10px", height: "10px", ml: 0.1 }} />
            </div>
          )}
          {product?.productName && (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <CardMedia
                  component="img"
                  image={
                    product?.productImages?.[0]?.name
                      ? product?.productImages?.[0]?.name
                      : `/icons/dummy.jpeg`
                  }
                  alt={product.productName}
                  sx={{
                    maxWidth: "100px",
                    minHeight: "125px",
                    maxHeight: "125px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ width: "100%", p: 1 }}>
                <Typography className={classes.productName}>
                  {product.productName.length > 60
                    ? product.productName.slice(0, 60).concat(`...`)
                    : product.productName}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Card>
      )}
    </>
  );
}

export default SelectProductCard;
