import {
  Box,
  Button,
  ButtonBase,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import useQuery from "../../Hooks/useQuery";
import AnalyticsService from "../../Services/AnalyticsService";
import OrderService from "../../Services/OrderService";
import BackDropLoader from "../Common/BackDropLoader";
import ErrorBoundary from "../Common/ErrorBoundary";
import EmptyError from "../EmptyError/EmptyError";
import OrderCard from "./OrderCard";
import OrderFilters from "./OrderFilters";
import PayNowDialog from "./PayNowDialog";
import SearchOrders from "./SearchOrders";
import AmountUtils from "../../Utils/AmountUtils";
import PaymentService from "../../Services/PaymentService";
import { East } from "@mui/icons-material";
import SettleNowDialog from "./SettleNowDialog";
import PartnerService from "../../Services/PartnerService";

let ORDER_FILTERS = [
  {
    label: "Unsettled Orders",
    identifier: "unsettled",
    selected: true,
  },
  {
    label: "All Orders",
    identifier: "",
    selected: false,
  },
];

const Orders = () => {
  let navigate = useNavigate();
  const [ordersHistory, setOrdersHistory] = useState(null);
  const [loading, setLoading] = useState("not-started");
  const [loadingVANDetails, setLoadingVANDetails] = useState("not-started");
  const [error, setError] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [keywordDebounced, setKeywordDebounced] = useState("");
  const [totalOrders, setTotalOrders] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const ORDERS_TO_SHOW_IN_A_SINGLE_SCROLL = 5;
  const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] = useState(
    _.find(ORDER_FILTERS, (filter) => {
      return filter?.selected;
    })
  );
  const [orderCreationDateRangeFilter, setOrderCreationDateRangeFilter] =
    useState(null);

  const query = useQuery();
  // const directPayment = query.get("payment");
  // const paymentType = query.get("type");
  // const amountTypeFromParams = query.get("amount-type");
  // const [amountFromParams, setAmountFromParams] = useState(query.get("amount"));

  const orderIdFromParams = query.get("orderId");
  const [loadedOrderFromDeeplink, setLoadedOrderFromDeeplink] = useState(false);

  const [showPayNowDialog, setShowPayNowDialog] = useState(false);
  const [payNowPaymentAmount, setPayNowPaymentAmount] = useState(0);
  const [paymentLoading, setPaymentLoading] = useState("not_started");
  const [payNowOrderId, setPayNowOrderId] = useState(null);
  const [orderListToSettle, setOrderListToSettle] = useState([]);
  const [vanPaymentDetails, setVanPaymentDetails] = useState(null);
  const [selectedAmountToSettle, setSelectedAmountToSettle] = useState(0);
  const [selectClicked, setSelectClicked] = useState(false);
  const [showSettleNowDialog, setShowSettleNowDialog] = useState(false);
  const [settlementDetails, setSettlementDetails] = useState(null);

  // const handleOpenDeepLink = (order) => {
  //   if (amountFromParams > order?.unsettledAmount) {
  //     setAmountFromParams(order?.unsettledAmount);
  //   }

  //   if (order?.unsettledAmount) {
  //     setPayNowPaymentAmount(order?.unsettledAmount);
  //   }

  //   if (directPayment === "true" && paymentType !== "") {
  //     if (orderIdFromParams === order?.orderId && order?.isPayNowEnabled) {
  //       if (localStorage.getItem("creditLine") !== "FINBOX") {
  //         setShowPayNowDialog(true);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    const hissabTabEventData = {
      body: {
        page: "orders",
      },
      name: "Orders Tab Viewed",
    };

    AnalyticsService.pushEvent(
      hissabTabEventData.name,
      hissabTabEventData.body
    );
  }, []);

  useEffect(() => {
    setLoading("started");
    setCurrentPage(0);
    const { axiosRequest, abortController } =
      OrderService.getOrderHistoryBySettlement(
        0,
        ORDERS_TO_SHOW_IN_A_SINGLE_SCROLL,
        orderIdFromParams && !loadedOrderFromDeeplink
          ? orderIdFromParams
          : keywordDebounced,
        selectedOrderStatusFilter?.identifier,
        orderCreationDateRangeFilter
      );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          let ordersDataFromResponse = response.data.responseData;
          setOrdersHistory(ordersDataFromResponse?.orderTransactionList);
          setTotalOrders(ordersDataFromResponse?.orderCount);
        } else {
          console.log(`no orders found`);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log("error getting orders history");
          if (error?.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setLoading("done");
        }
      });

    if (orderIdFromParams && !loadedOrderFromDeeplink) {
      setKeyword(orderIdFromParams);
      setKeywordDebounced(orderIdFromParams);
      setLoadedOrderFromDeeplink(true);
    }
    return () => abortController.abort();
  }, [
    keywordDebounced,
    selectedOrderStatusFilter,
    orderCreationDateRangeFilter,
  ]);

  useEffect(() => {
    setLoadingVANDetails("started");
    const { axiosRequest, abortController } =
      PaymentService.getVANPaymentDetails();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setVanPaymentDetails(response.data.responseData);
        }
        setLoadingVANDetails("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoadingVANDetails("done");
          console.error("Error getting VAN Payment Details: ", error?.message);
        }
      });
    return () => abortController.abort();
  }, []);

  const fetchMoreOrders = () => {
    const { axiosRequest } = OrderService.getOrderHistoryBySettlement(
      (currentPage + 1) * ORDERS_TO_SHOW_IN_A_SINGLE_SCROLL,
      ORDERS_TO_SHOW_IN_A_SINGLE_SCROLL,
      keywordDebounced,
      selectedOrderStatusFilter?.identifier,
      orderCreationDateRangeFilter
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.orderTransactionList) {
          let fetchedOrders = response.data.responseData.orderTransactionList;
          setOrdersHistory((previousOrders) => [
            ...previousOrders,
            ...fetchedOrders,
          ]);
        } else {
          console.log(`no orders found`);
        }
      })
      .catch((error) => {
        console.log(`error getting orders`);
        if (error.data) {
          console.log(error.data);
        } else {
          console.log(error);
        }
      });

    setCurrentPage((previousPageNumber) => {
      return previousPageNumber + 1;
    });
  };

  const handleClickClearAllFilters = () => {
    setSelectedOrderStatusFilter(null);
    setOrderCreationDateRangeFilter(null);
    AnalyticsService.pushEvent(`Clear All Filters Clicked`);
  };

  const payNowHandler = (order) => {
    if (order?.isPayNowEnabled) {
      if (!config.disablePayNow) {
        setShowPayNowDialog(true);
      }
    }
  };
  const handleClickPayNow = (order) => {
    if (order?.unsettledAmount) {
      setPayNowPaymentAmount(order?.unsettledAmount);
    }

    // if (query.get("amount") > order?.unsettledAmount) {
    //   setAmountFromParams(order?.unsettledAmount);
    // }

    payNowHandler(order);
    AnalyticsService.pushEvent("Pay Now Clicked", {
      page: window?.location?.pathname || "",
      dueAmount: order?.unsettledAmount,
      creditLine: localStorage.getItem("creditLine")?.toLowerCase(),
      orderId: order?.orderId || "",
      orderAmount: order?.orderAmount,
      cashDiscountAmount: order?.cdBenefitAmount,
      netPayableAmount: order?.netPayableAmount,
    });
  };
  const handleClosePayNow = () => {
    setShowPayNowDialog(false);
  };
  const handlePaymentStatus = (paymentId) => {
    navigate(`/hisaab/payment-status/${paymentId}`);
  };
  const handleRazorpayFailure = (error, errorCode = null) => {
    const urlParams = errorCode ? `?errorCode=${errorCode}` : "";
    navigate(`/hisaab/razorpay-failure/${error}${urlParams}`);
  };

  const handleSelectClick = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: !selectClicked ? "Select" : "Cancel",
      isSelected: !selectClicked,
    });
    setSelectClicked(!selectClicked);
    setOrderListToSettle([]);
  };

  const handleProceed = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Proceed",
      selectedAmountToSettle,
      isDisabled: selectedAmountToSettle === 0,
    });

    if (loading !== "started" && selectedAmountToSettle > 0) {
      setLoading("started");
      const listToSettle = orderListToSettle?.map((order) => order?.orderId);
      const { axiosRequest } = PaymentService.getCustomSettlementDetails({
        listToSettle,
        settlementType: "order",
        creditTransactionId: vanPaymentDetails?.transactionId,
      });
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            setSettlementDetails(response.data.responseData);
            setShowSettleNowDialog(true);
          }
          setLoading("done");
        })
        .catch((error) => {
          setLoading("done");
          console.error("Error Credit Custom Settlement: ", error?.message);
        });
    }
  };

  return (
    <>
      <Container maxWidth={`xl`}>
        <ErrorBoundary message={``}>
          <SearchOrders
            keyword={keyword}
            setKeyword={setKeyword}
            keywordDebounced={keywordDebounced}
            setKeywordDebounced={setKeywordDebounced}
            page={window?.location?.pathname}
          />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          {loadingVANDetails === "started" ? (
            <Skeleton variant={`rectangular`} height={52} />
          ) : loadingVANDetails === "done" && vanPaymentDetails?.amount > 0 ? (
            <Grid
              container
              alignItems="center"
              sx={{
                margin: "5px 0 8px",
                borderRadius: "10px",
                padding: "8px 16px",
                backgroundColor: "#F8D149",
              }}
            >
              <Grid item xs={8.5}>
                <Typography color={"secondary"} fontSize={"12px"}>
                  We have received{" "}
                  <b>
                    {AmountUtils.formatCurrency(vanPaymentDetails?.amount || 0)}
                  </b>{" "}
                  via VAN payment. Please select invoices to settle.
                </Typography>
              </Grid>
              <Grid item xs={3.5} sx={{ textAlign: "right" }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    borderRadius: "16px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleSelectClick}
                  disabled={!vanPaymentDetails?.enableCustomSettlement}
                >
                  {selectClicked ? "Cancel" : "Select"}
                </Button>
              </Grid>
              {!vanPaymentDetails?.enableCustomSettlement && (
                <Grid
                  item
                  xs={12}
                  textAlign="center"
                  sx={{
                    fontSize: "12px",
                    padding: "8px 0 0",
                    color: "#9D2123",
                  }}
                >
                  {vanPaymentDetails?.disabledMessage}
                </Grid>
              )}
            </Grid>
          ) : null}
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          {keywordDebounced && keywordDebounced?.length > 0 ? (
            <></>
          ) : (
            <OrderFilters
              orderFilters={ORDER_FILTERS}
              selectedOrderStatusFilter={selectedOrderStatusFilter}
              setSelectedOrderStatusFilter={setSelectedOrderStatusFilter}
              orderCreationDateRangeFilter={orderCreationDateRangeFilter}
              setOrderCreationDateRangeFilter={setOrderCreationDateRangeFilter}
              showDateFilter={true}
            />
          )}
        </ErrorBoundary>
      </Container>
      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "60vh", marginTop: "16px" }}
      >
        <Container maxWidth={`xl`}>
          <ErrorBoundary>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "16px 0px 10px 0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >{`Pay Order Dues`}</Typography>
              {keywordDebounced && keywordDebounced?.length > 0 ? (
                <></>
              ) : (
                <ButtonBase
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#9D2123",
                  }}
                  onClick={handleClickClearAllFilters}
                >{`Clear All Filters`}</ButtonBase>
              )}
            </Box>
          </ErrorBoundary>
        </Container>

        <ErrorBoundary message={``}>
          {ordersHistory?.length > 0 ? (
            <>
              <Container maxWidth={`xl`}>
                <ErrorBoundary message={``}>
                  <Box sx={{ paddingBottom: "114px" }}>
                    <InfiniteScroll
                      dataLength={ordersHistory?.length}
                      next={fetchMoreOrders}
                      hasMore={ordersHistory?.length < totalOrders}
                      loader={
                        <h4 style={{ textAlign: "center", fontSize: "16px" }}>
                          Loading...
                        </h4>
                      }
                    >
                      {_.map(ordersHistory, (order, index) => (
                        <OrderCard
                          order={order}
                          key={index}
                          handleClickPayNow={handleClickPayNow}
                          disablePayNowForOrder={
                            OrderService.disablePayNowForOrder
                          }
                          isLastSuccessfulPaymentInProgressForOrder={
                            OrderService.isLastSuccessfulPaymentInProgressForOrder
                          }
                          payNowOrderId={payNowOrderId}
                          setPayNowOrderId={setPayNowOrderId}
                          // handleOpenDeepLink={handleOpenDeepLink}
                          orderIdFromParams={orderIdFromParams}
                          vanPaymentDetails={vanPaymentDetails}
                          selectClicked={selectClicked}
                          orderListToSettle={orderListToSettle}
                          setOrderListToSettle={setOrderListToSettle}
                          selectedAmountToSettle={selectedAmountToSettle}
                          setSelectedAmountToSettle={setSelectedAmountToSettle}
                        />
                      ))}
                    </InfiniteScroll>
                  </Box>
                </ErrorBoundary>
              </Container>
            </>
          ) : error ? (
            <EmptyError
              image="EmptyPage.svg"
              text=""
              subText="Something went wrong. Please try again later."
              hideBack={true}
              extraStyle={{ minHeight: "50vh" }}
            />
          ) : (
            <>
              <EmptyError
                image={`Empty Cart.svg`}
                text={`No orders found!`}
                extraStyle={{ minHeight: "50vh" }}
                hideBack={true}
              />
            </>
          )}
        </ErrorBoundary>
      </BlockUi>
      {selectClicked && (
        <Grid
          container
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: 0,
            height: "60px",
            maxWidth: "600px",
            padding: "8px 16px",
            color: "#fff",
            fontSize: "14px",
            background:
              loading !== "started" && selectedAmountToSettle > 0
                ? "#9D2123"
                : "#999",
            borderTop: "1px solid #ffffff1a",
            zIndex: 1061,
          }}
          onClick={handleProceed}
        >
          <Grid item xs={8} container>
            <Grid item xs={12}>
              <b>{AmountUtils.formatCurrency(selectedAmountToSettle)}</b>
            </Grid>
            <Grid item xs={12}>
              Selected Amount
            </Grid>
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Button variant="text" sx={{ color: "#fff" }} endIcon={<East />}>
              Proceed
            </Button>
          </Grid>
        </Grid>
      )}
      <PayNowDialog
        open={showPayNowDialog}
        setOpen={setShowPayNowDialog}
        paymentAmount={payNowPaymentAmount}
        lendingPartner={PartnerService.getLendingPartner()}
        handleClose={handleClosePayNow}
        handlePaymentStatus={handlePaymentStatus}
        handleRazorpayFailure={handleRazorpayFailure}
        paymentLoading={paymentLoading}
        setPaymentLoading={setPaymentLoading}
        paymentType={"order"}
        orderId={payNowOrderId}
        initiationFailureRetryRedirectionLink={`/hisaab/orders?orderId=${payNowOrderId}`}
      />
      <BackDropLoader open={paymentLoading === "started"} />

      {showSettleNowDialog && (
        <SettleNowDialog
          open={showSettleNowDialog}
          paymentAmount={selectedAmountToSettle}
          handleClose={() => setShowSettleNowDialog(false)}
          setPaymentLoading={setPaymentLoading}
          settlementType={"order"}
          settlementDetails={settlementDetails}
        />
      )}
    </>
  );
};

export default Orders;
