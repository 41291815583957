import { Container } from "@mui/material";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import InfiniteScroll from "react-infinite-scroll-component";
import FieldAgentLeadCard from "./FieldAgentLeadCard";
import EmptyError from "../EmptyError/EmptyError";
import { useEffect } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import PartnersSearch from "../Partners/PartnersSearch";

const FieldAgentLeads = ({
  loading,
  leadsList,
  currentCount,
  totalCount,
  getAgentLeads,
  handleSearch,
  clearSearch,
  searchKeyword,
}) => {
  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, { page: "Agent Leads" });
  }, []);

  return (
    <BlockUi
      tag="div"
      blocking={loading === "started"}
      message={`Loading...`}
      style={{ minHeight: "85vh" }}
      keepInView
    >
      <Container maxWidth={`xl`} sx={{ pb: 1 }}>
        <PartnersSearch
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          searchKeyword={searchKeyword}
          page={"Agent Leads"}
        />
      </Container>
      <Container maxWidth={`xl`} sx={{ padding: 0 }}>
        {leadsList?.length ? (
          <InfiniteScroll
            dataLength={currentCount}
            next={getAgentLeads}
            hasMore={currentCount < totalCount}
            loader={<p style={{ textAlign: "center" }}>Loading...</p>}
            style={{ padding: "10px 16px 125px 16px" }}
          >
            {leadsList?.map((lead) => (
              <FieldAgentLeadCard
                key={lead.orderId}
                lead={lead}
              ></FieldAgentLeadCard>
            ))}
          </InfiniteScroll>
        ) : (
          loading === "done" && (
            <EmptyError text="No Leads Found" hideBack={true} />
          )
        )}
      </Container>
    </BlockUi>
  );
};

export default FieldAgentLeads;
