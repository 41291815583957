import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  SwipeableDrawer,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import CouponCard from "./CouponCard";
import AnalyticsService from "../../Services/AnalyticsService";
import { makeStyles } from "@mui/styles";
import EmptyError from "../EmptyError/EmptyError";
import BlockUi from "react-block-ui";

const useStyles = makeStyles((theme) => ({
  header: {
    position: "sticky",
    top: "0",
    zIndex: 100,
    background: "#FFFFFF",
    paddingTop: "16px",
  },
  closeButton: {
    color: "#999999",
    fontSize: "28px",
    cursor: "pointer",
  },
  content: {
    background: "#F3F3F3",
    paddingBottom: "60px",
    height: "70vh",
    overflowY: "scroll",
  },
  formControl: {
    padding: "0px 12px",
  },
  radioLabel: {
    margin: "0px",
    justifyContent: "space-between",
    color: "#4F4F4F",
    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      fontWeight: 700,
      paddingLeft: "8px",
    },
  },
  footer: {
    position: "sticky",
    bottom: "0",
    width: "100%",
    zIndex: 100,
    background: "#FFFFFF",
  },
  applyButton: {
    fontWeight: 700,
    padding: "10px 15px",
    borderRadius: "0px",
    boxShadow: "none",
  },
}));

const CouponSelect = ({
  open,
  cart,
  otherCart,
  onClose,
  onSubmit,
  currentTab,
  applyCoupon,
  couponApplyLoader,
}) => {
  const classes = useStyles();
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState(
    cart.appliedCoupon
  );
  const [couponCodeInput, setCouponCodeInput] = useState("");

  useEffect(() => {
    setCurrentlySelectedOption(cart.appliedCoupon);
    if (open) {
      AnalyticsService.pushEvent("Drawer Viewed", {
        drawerType: "coupon",
        numberOfCoupons: applicableCoupons?.length || 0,
        couponIds:
          _.map(applicableCoupons || [], (coupon) => coupon?.offerId).join(
            ","
          ) || "",
        cartId: cart.cartId,
        cartType: currentTab,
      });
    }
  }, [open]);

  let applicableCoupons = cart.applicableCoupons;
  if (otherCart?.appliedCoupon) {
    applicableCoupons = _.filter(applicableCoupons, (coupon) => {
      if (
        coupon.offerId === otherCart?.appliedCoupon &&
        coupon.maxNoOfUsages === 1
      ) {
        return false;
      }
      return true;
    });
  }

  const handleOnClose = () => {
    AnalyticsService.pushEvent("Cancel Icon Clicked", {
      drawerType: "coupon",
      numberOfCoupons: applicableCoupons?.length || 0,
      couponIds:
        _.map(applicableCoupons || [], (coupon) => coupon?.offerId).join(",") ||
        "",
      cartId: cart.cartId,
      cartType: currentTab,
    });
    onClose();
    setCouponCodeInput("");
  };

  const handleChangeCouponCode = (event) => {
    const couponCodeEntered = event?.target?.value;
    setCouponCodeInput(couponCodeEntered);
  };

  const applyCouponCode = (couponCodeToBeApplied) => {
    if (couponCodeToBeApplied !== "") {
      applyCoupon(couponCodeToBeApplied, "Floating");
      setCouponCodeInput("");
    }
  };

  return (
    <SwipeableDrawer
      anchor={`bottom`}
      open={open}
      PaperProps={{
        style: {
          borderRadius: "24px 24px 0px 0px",
          overflowY: "hidden",
        },
      }}
      onClose={handleOnClose}
      onOpen={() => {}}
      disableDiscovery={true}
      disableSwipeToOpen={true}
    >
      <Box sx={{ height: "85vh" }} role="presentation">
        <Box className={classes.header}>
          <Grid
            container
            alignItems={`center`}
            justifyContent={`space-between`}
            sx={{ padding: "0px 20px 10px" }}
          >
            <img src="/icons/coupon.svg" alt="" />
            <Typography
              sx={{
                color: "#333333",
                fontSize: "18px",
                fontWeight: "700",
                marginRight: "120px",
              }}
            >
              {`Apply Coupons`}
            </Typography>
            <CloseIcon
              onClick={handleOnClose}
              className={classes.closeButton}
            />
          </Grid>
          {!localStorage.getItem("role") === "fieldAgent" && <Divider />}
        </Box>

        {localStorage.getItem("role") === "fieldAgent" && (
          <Box sx={{ padding: "2px 15px 8px 15px" }}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Add Coupon Code"
              size={`small`}
              onChange={handleChangeCouponCode}
              value={couponCodeInput}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
                },
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        size={"small"}
                        color="primary"
                        onClick={() => {
                          applyCouponCode(couponCodeInput);
                        }}
                        disabled={
                          !couponCodeInput || couponCodeInput == `""`
                            ? true
                            : false
                        }
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: "16px",
                          boxShadow: "none",
                          "&:hover": {
                            boxShadow: "none",
                          },
                          fontWeight: "700",
                          fontSize: "14px",
                          padding: "2px 16px",
                        }}
                      >
                        Apply
                      </Button>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
        )}

        <BlockUi
          tag="div"
          blocking={couponApplyLoader === "started"}
          message={`Applying...`}
          keepInView
        >
          <Box className={classes.content}>
            <FormControl fullWidth className={classes.formControl}>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={currentlySelectedOption}
              >
                {_.map(applicableCoupons, (coupon, index) => (
                  <FormControlLabel
                    key={`${coupon.offerId}-${index}`}
                    labelPlacement="start"
                    value={coupon.offerId}
                    label={""}
                    control={
                      <CouponCard
                        coupon={coupon}
                        isChecked={currentlySelectedOption === coupon.offerId}
                        handleOnSelected={() => {
                          AnalyticsService.pushEvent("Coupon Clicked", {
                            drawerType: "coupon",
                            numberOfCoupons: applicableCoupons?.length || 0,
                            couponIds:
                              _.map(
                                applicableCoupons || [],
                                (coupon) => coupon?.offerId
                              ).join(",") || "",
                            couponId: coupon.offerId,
                            cartId: cart.cartId,
                            cartType: currentTab,
                          });
                          setCurrentlySelectedOption(coupon.offerId);
                        }}
                        showRadio
                        handleOnClose={handleOnClose}
                      />
                    }
                    className={classes.radioLabel}
                  />
                ))}
              </RadioGroup>
              {applicableCoupons.length === 0 && (
                <>
                  <EmptyError
                    image="coupon-entitlement.svg"
                    text="No Coupons Applicable At The Moment!"
                    subText="Coupons applicable to cart will show up here"
                    extraStyle={{
                      height: "min-content",
                      marginTop: "48px",
                      ">div>img": {
                        height: "100px",
                        opacity: "70%",
                      },
                    }}
                    hideBack
                  />
                </>
              )}
            </FormControl>
          </Box>
        </BlockUi>
        <Box className={classes.footer}>
          <Grid
            container
            spacing={0}
            alignItems={`center`}
            justifyContent={`center`}
            textAlign={`right`}
          >
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size={"medium"}
              onClick={() => {
                AnalyticsService.pushEvent("Apply Button Clicked", {
                  drawerType: "coupon",
                  numberOfCoupons: applicableCoupons?.length || 0,
                  couponIds:
                    _.map(
                      applicableCoupons || [],
                      (coupon) => coupon?.offerId
                    ).join(",") || "",
                  couponId: currentlySelectedOption,
                  cartId: cart.cartId,
                  cartType: currentTab,
                });
                if (currentlySelectedOption !== cart.appliedCoupon)
                  onSubmit(currentlySelectedOption);
                else onClose();
              }}
              disabled={!currentlySelectedOption}
              className={classes.applyButton}
            >
              Done
            </Button>
          </Grid>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default CouponSelect;
