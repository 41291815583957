import { useEffect, useRef, useState } from "react";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import DocumentService from "../../Services/DocumentService";
import _ from "lodash";
import ErrorBoundary from "../Common/ErrorBoundary";
import ErrorIcon from "@mui/icons-material/Error";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "./style.css";
import CommonConstantValues from "../../Services/CommonConstantValues";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const UploadDocument = ({
  documentTitle,
  documentSubtitle,
  documentAllowedExtensions,
  documentMimeType,
  imageFileTypeExtensions,
  documentFileTypeExtensions,
  documentIdentifier,
  uploadedFiles,
  setUploadedFiles,
  isMandatory,
  allowEditable,
}) => {
  var isFileUploadEnabled = true;
  if (AndroidService.checkIfAndroid()) {
    if (AndroidService.getAndroidVersion()) {
      let { appVersionCode } = AndroidService.getAndroidVersion();
      if (appVersionCode && parseInt(appVersionCode) < 20) {
        isFileUploadEnabled = false;
      }
    }
  }

  const attachedFile = _.find(uploadedFiles, { type: documentIdentifier });
  const [uploadingFile, setUploadingFile] = useState("not-started");
  const [downloadingFile, setDownloadingFile] = useState("not-started");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);
  const pond = useRef(null);

  useEffect(() => {
    if (showError === true && errorMessage) {
      AnalyticsService.pushEvent("Dialog Shown", {
        type: showError ? "Failure" : "",
        message: errorMessage,
        page: "Upload Documents",
        errorCode: errorCode || "",
      });
    }
  }, [showError, errorMessage, errorCode]);

  const openFilePondDialog = () => {
    if (pond.current) {
      pond.current.browse();
    }
  };

  const handleRemoveFile = () => {};

  const handleAddFile = (error, file) => {
    if (error) {
      setShowError(true);
      setErrorMessage(error?.sub);
      return;
    }

    if (!_.includes(documentAllowedExtensions, file?.fileExtension)) {
      setShowError(true);
      setErrorMessage(
        `${documentTitle} must be a file with ${
          documentAllowedExtensions?.length <= 1
            ? `extension`
            : `one of these extensions`
        }: ${_.join(documentAllowedExtensions, ", ")}`
      );
      return;
    }

    AnalyticsService.pushEvent(`Add Attachment Clicked`, {
      page: "Upload Documents",
    });

    setUploadingFile("started");
    const { axiosRequest } = DocumentService.getDocumentUploadUrls(
      file?.fileExtension,
      documentIdentifier
    );
    axiosRequest
      .then(({ data }) => {
        const putPresignedUrl = data?.responseData?.presignedPutUrl;
        const getPresignedUrl = data?.responseData?.presignedGetUrl;
        const objectUrl = data?.responseData?.objectUrl;
        const fileData = file?.file;

        const { axiosRequest } = DocumentService.uploadDocumentToS3(
          putPresignedUrl,
          fileData,
          file?.fileType
        );
        axiosRequest
          .then((response) => {
            const fileObject = {
              type: documentIdentifier,
              fileLink: objectUrl,
              filePresignedLink: getPresignedUrl,
              fileType: file?.fileExtension,
              isUploaded: true,
            };

            const putRequestPayload = { ...fileObject };
            delete putRequestPayload.isUploaded;
            delete putRequestPayload.filePresignedLink;

            const { axiosRequest } =
              DocumentService.uploadDocumentsForOnboarding({
                requestPayload: [putRequestPayload],
                isPartialUploadComplete: false,
                isCompleted: false,
              });
            axiosRequest
              .then((response) => {
                setUploadedFiles((prevFiles) => {
                  const previousIndexOfThisFileType = uploadedFiles.findIndex(
                    (element) => element.type === documentIdentifier
                  );
                  if (previousIndexOfThisFileType !== -1) {
                    // the document for this file type has already been attached in current session
                    const updatedFiles = [
                      ...prevFiles.slice(0, previousIndexOfThisFileType),
                      fileObject,
                      ...prevFiles.slice(previousIndexOfThisFileType + 1),
                    ];
                    return updatedFiles;
                  } else {
                    // the document for this file type is being attached for the 1st time in current session
                    const updatedFiles = [...prevFiles, fileObject];
                    return updatedFiles;
                  }
                });

                setUploadingFile("done");
              })
              .catch(() => {
                setShowError(true);
                setErrorMessage("Something went wrong. Please try again.");
                setErrorCode("UPLOAD_DOC_API_FAILURE");
                setUploadingFile("done");
              });
          })
          .catch(() => {
            setShowError(true);
            setErrorMessage("Something went wrong. Please try again.");
            setErrorCode("UPLOAD_DOC_S3_FAILURE");
            setUploadingFile("done");
          });
      })
      .catch(() => {
        setShowError(true);
        setErrorMessage("Something went wrong. Please try again.");
        setErrorCode("GENERATE_UPLOAD_URLS_FAILURE");
        setUploadingFile("done");
      });
  };

  const handleClickPreview = () => {
    if (attachedFile?.fileType === "doc" || attachedFile?.fileType === "docx") {
      if (AndroidService.checkIfAndroid()) {
        if (AndroidService.getAndroidVersion()) {
          let { appVersionCode } = AndroidService.getAndroidVersion();
          if (
            appVersionCode &&
            parseInt(appVersionCode) <
              CommonConstantValues.ALLOW_DOC_DOCX_DOWNLOAD_IN_WEBVIEW
          ) {
            setOpenAppUpdateDialog(true);
            return;
          }
        }
      }
    }

    setDownloadingFile("started");
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Download Document",
      page: "Upload Documents",
      documentName: documentIdentifier || "",
    });

    const { axiosRequest } = DocumentService.getDocumentUploadUrls(
      attachedFile?.fileType,
      attachedFile?.type
    );
    axiosRequest
      .then(({ data }) => {
        const getPresignedUrl = data?.responseData?.presignedGetUrl;
        fetch(getPresignedUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                `Failed to download file. Status: ${response.status}`
              );
            }
            return response.blob();
          })
          .then((blob) => {
            if (AndroidService.checkIfAndroid()) {
              /*global Android*/
              Android.setFileName(`${attachedFile?.type}`);
            }

            const newBlob = new Blob([blob], {
              type: blob.type,
            });
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement("a");
            link.href = data;
            link.download = `${attachedFile?.type}.${attachedFile?.fileType}`;
            document.body.appendChild(link);
            link.click();
            setDownloadingFile("done");
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
            setShowError(true);
            setErrorMessage(
              "Error while downloading preview. Please try again."
            );
            setDownloadingFile("done");
          });
        setDownloadingFile("done");
      })
      .catch(() => {
        setShowError(true);
        setErrorMessage("Something went wrong. Please try again.");
        setErrorCode("DOWNLOAD_PREVIEW_URL_GENERATION_FAILURE");
        setDownloadingFile("done");
      });
  };

  return (
    <>
      <ErrorBoundary>
        <Grid
          container
          alignItems={"center"}
          sx={{
            borderRadius: "12px",
            background: "#FFF",
            boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10);",
            padding: "5px",
            marginBottom: "4px",
          }}
        >
          <Grid item xs={2} sx={{ display: "flex" }}>
            {attachedFile?.isUploaded ? (
              <>
                {imageFileTypeExtensions?.includes(attachedFile?.fileType) ? (
                  <img
                    src={attachedFile?.filePresignedLink}
                    alt=""
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "12px",
                    }}
                  />
                ) : documentFileTypeExtensions?.includes(
                    attachedFile?.fileType
                  ) ? (
                  <Box
                    sx={{
                      height: "40px",
                      width: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {downloadingFile === "started" ? (
                      <CircularProgress color="primary" size={24} />
                    ) : (
                      <img
                        src="/icons/pdfIcon.svg"
                        alt=""
                        // onClick={handleClickPreview}
                        style={{
                          height: "40px",
                          width: "38px",
                        }}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      background: "#D9D9D9",
                      borderRadius: "12px",
                    }}
                  />
                )}
              </>
            ) : (
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  background: "#D9D9D9",
                  borderRadius: "12px",
                }}
              />
            )}
          </Grid>
          <Grid item xs={7}>
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
              {documentTitle + (isMandatory ? " *" : "")}
            </Typography>
            <Typography sx={{ fontSize: "14px", marginTop: "-2px" }}>
              {documentSubtitle}
            </Typography>
          </Grid>

          {isFileUploadEnabled && (
            <>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: `${
                    uploadingFile !== "started" && attachedFile?.isUploaded
                      ? "flex-end"
                      : uploadingFile === "started"
                        ? "center"
                        : "flex-end"
                  }`,
                }}
              >
                <Box
                  sx={{ display: "flex", gap: "8px" }}
                  className="onboardingDocumentsUpload"
                >
                  <FilePond
                    ref={pond}
                    allowFileSizeValidation={true}
                    maxFileSize={"25MB"}
                    acceptedFileTypes={documentMimeType}
                    labelMaxFileSizeExceeded={"File is too large"}
                    allowReorder={false}
                    allowMultiple={false}
                    onaddfile={handleAddFile}
                    onremovefile={handleRemoveFile}
                    labelMaxFileSize="Max file size allowed is 25 MB"
                    labelIdle={`<img src="/icons/upload.svg" />`}
                    maxFiles={1}
                  />
                  {uploadingFile !== "started" && attachedFile?.isUploaded ? (
                    <>
                      {allowEditable && (
                        <img
                          src="/icons/edit.svg"
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            openFilePondDialog();
                            AnalyticsService.pushEvent("Button Clicked", {
                              type: "Edit Document",
                              page: "Upload Documents",
                              documentName: documentIdentifier || "",
                            });
                          }}
                        />
                      )}
                      <img src="/icons/doneTick.svg" alt="" />
                    </>
                  ) : uploadingFile === "started" ? (
                    <img src="/icons/loading.svg" alt="" className="spin" />
                  ) : (
                    <img
                      src="/icons/upload.svg"
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // allow only one upload
                        if (!attachedFile?.isUploaded) {
                          openFilePondDialog();
                          AnalyticsService.pushEvent("Button Clicked", {
                            type: "Upload Document",
                            page: "Upload Documents",
                            documentName: documentIdentifier || "",
                          });
                        }
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <Dialog
          open={showError}
          onClose={() => {
            setShowError(false);
            window.location.reload();
          }}
          PaperProps={{
            style: {
              borderRadius: "32px",
              padding: "24px",
              minWidth: "70vw",
            },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon fontSize={"large"} sx={{ color: "#9D2123" }} />
            <Typography
              variant={`body1`}
              sx={{ color: "#9D2123", marginTop: 1 }}
            >
              {errorMessage}
            </Typography>
            {errorCode && (
              <Typography
                variant={`caption`}
                sx={{ color: "#222222", opacity: 0.3, wordBreak: "break-all" }}
              >
                {errorCode}
              </Typography>
            )}

            <Box sx={{ marginTop: 2.5 }}>
              <Button
                variant={"outlined"}
                size={"large"}
                color={"secondary"}
                sx={{
                  borderRadius: "16px",
                  background: "rgba(255, 255, 255, 1)",
                  color: "rgba(0, 115, 62, 1)",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setShowError(false);
                  window.location.reload();
                }}
              >
                Okay
              </Button>
            </Box>
          </Box>
        </Dialog>

        <AppVersionUnsupported
          open={openAppUpdateDialog}
          handleClose={() => {
            AnalyticsService.pushEvent("App Update Required Dialog Closed", {
              page: "Upload Documents",
            });
            setOpenAppUpdateDialog(false);
          }}
          page={"Upload Documents"}
        />
      </ErrorBoundary>
    </>
  );
};

export default UploadDocument;
