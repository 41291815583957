import React from "react";
import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";

function ProductsFilterLoader() {
  return (
    // <Box
    //   sx={{
    //     position: "fixed",
    //     bottom: "0",
    //     width: "100%",
    //     textAlign: "center",
    //     padding: "26px",
    //   }}
    // >
    //   <Skeleton
    //     variant={`rectangular`}
    //     height={45}
    //     width={112}
    //     sx={{ borderRadius: "57px", margin: "auto" }}
    //   />
    // </Box>
    <></>
  );
}

export default ProductsFilterLoader;
