import React from "react";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FBEBDF",
    margin: "16px !important",
    borderRadius: "12px",
    border: "1px solid white",
    display: "flex",
    textAlign: "left",
    position: "relative",
    paddingRight: "4px",
    boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
  },
}));

export default function AnniversaryPopUp({ name }) {
  const classes = useStyles();

  function onClickAnniversaryMessage() {
    AnalyticsService.pushEvent("Anniversary Message Clicked");
  }

  return (
    <Box
      sx={{ minWidth: "300px" }}
      onClick={onClickAnniversaryMessage}
      className={classes.root}
    >
      <Box
        sx={{
          position: "relative",
          ml: 0.7,
        }}
      >
        <img src="/icons/saathiStoreBackDrop.svg" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: "9px",
          top: "-3px",
        }}
      >
        <img src="/icons/saathiStore.svg" />
      </Box>
      <Box
        sx={{
          mt: 0.6,
          ml: 0.2,
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "14px",
            fontStyle: "normal",
          }}
        >
          <b style={{ color: "#9D2123" }}> Congratulations </b>{" "}
        </Typography>
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "12px",
            fontStyle: "normal",
          }}
        >
          <b> {name} </b> , on your anniversary with us! 🥳🎉{" "}
        </Typography>
      </Box>
    </Box>
  );
}
