import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Card, Typography } from "@mui/material";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import ErrorBoundary from "../Common/ErrorBoundary";
import AmountUtils from "../../Utils/AmountUtils";
import _ from "lodash";
import Clamp from "react-multiline-clamp";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
    border: "none",
    background: "#F4F4F4",
  },
  cardHeader: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
  },
  cardFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    borderTop: "1px solid #eeeeee",
  },
  orderAmount: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: 700,
    color: "#333",
    alignSelf: "flex-start",
  },
  headerText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const OrderPlacedDetailCard = ({ order, onOrderClicked }) => {
  const classes = useStyles();

  return (
    <ErrorBoundary message={""}>
      <Card className={classes.card} variant="outlined">
        <Box className={classes.cardHeader}>
          <Typography className={classes.headerText}>
            <span>
              Order ID: <b>{order?.orderId ? order.orderId : ""}</b>
            </span>
            <span className={classes.orderAmount}>
              ₹ {AmountUtils.format(order?.value || order?.totalOrderValue)}
            </span>
          </Typography>
          {order?.deliveryData?.deliveryDate && (
            <Typography className={classes.headerText}>
              <span>Estimated Delivery Date:</span>
              <span>
                <b>
                  {format(
                    new Date(order?.deliveryData?.deliveryDate),
                    "dd-MMM-yyyy"
                  )}
                </b>
              </span>
            </Typography>
          )}
        </Box>
        <Box className={classes.cardFooter}>
          {_.map(_.uniq(order?.products || []), (product, index) => {
            return (
              <Typography
                key={`product-${index}-${order?.orderId}`}
                className={classes.headerText}
              >
                <Clamp lines={1}>{product}</Clamp>
              </Typography>
            );
          })}
        </Box>

        <Box
          sx={{
            color: "#9D2123",
            cursor: "pointer",
            display: "flex",
            padding: "12px",
            paddingBottom: "4px",
            borderTop: "1px solid #eeeeee",
            alignItems: "center",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
            justifyContent: "center",
            borderBottomLeftRadius: "16px",
            borderBottomRightRadius: "16px",
            fontWeight: 700,
            fontSize: "12px",
            letterSpacing: "0.04em",
          }}
          onClick={onOrderClicked}
        >
          ORDER DETAILS
          <ArrowForwardTwoToneIcon fontSize="small" />
        </Box>
      </Card>
    </ErrorBoundary>
  );
};

export default OrderPlacedDetailCard;
