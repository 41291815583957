import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";

function CreditPeriod(props) {
  const checkCreditPeriodVisibility = () => {
    let showCreditPeriod = false;
    if (localStorage.getItem(`creditLine`)?.toLowerCase() !== "finbox") {
      if (getCreditPeriod() !== 0) {
        showCreditPeriod = true;
      }
    }
    return showCreditPeriod;
  };
  const getCreditPeriod = () => {
    let creditPeriodToShow = "Cash Only";
    if (_.isNil(props?.product?.creditPeriod)) {
      let creditPeriodFromLocalStorage = localStorage.getItem(`creditPeriod`);
      if (creditPeriodFromLocalStorage > 1) {
        creditPeriodToShow = creditPeriodFromLocalStorage;
      }
    } else {
      if (props?.product?.creditPeriod) {
        if (props?.product?.creditPeriod > 1) {
          creditPeriodToShow = props.product.creditPeriod;
        }
      }
    }
    return creditPeriodToShow;
  };

  const getCreditPeriodDate = () => {
    let creditPeriodDateToShow = null;
    if (
      props?.product?.creditPeriodDate &&
      props?.product?.creditPeriodDate !== ""
    ) {
      creditPeriodDateToShow = format(
        new Date(props?.product?.creditPeriodDate),
        "dd MMM yyyy"
      );
    }
    return creditPeriodDateToShow;
  };

  if (props.textOnly) {
    return checkCreditPeriodVisibility()
      ? getCreditPeriodDate()
        ? getCreditPeriodDate()
        : getCreditPeriod() !== "Cash Only"
          ? `${getCreditPeriod()} days`
          : `${getCreditPeriod()}`
      : "";
  }
  return checkCreditPeriodVisibility() ? (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justifyContent={"flex-start"}
    >
      <Grid item xs={2} sx={{ paddingLeft: "6px" }}>
        <img src="/icons/creditPeriod.svg" alt="credit period icon" />
      </Grid>
      <Grid item xs={10}>
        <Typography
          variant={`body2`}
          sx={{
            fontSize: "12px",
            color: "#000000",
            display: "flex-inline",
          }}
        >
          &nbsp;&nbsp;
          {getCreditPeriodDate() ? "Due Date : " : "Credit Period : "}
          <Typography fontWeight={"700"} variant={`caption`}>
            {getCreditPeriodDate()
              ? getCreditPeriodDate()
              : getCreditPeriod() !== "Cash Only"
                ? `${getCreditPeriod()} days`
                : `${getCreditPeriod()}`}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}

export default CreditPeriod;
