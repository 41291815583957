import * as React from "react";

import Dialog from "@mui/material/Dialog";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import ErrorMessage from "../OnboardPartner/ErrorMessage";
import AnalyticsService from "../../Services/AnalyticsService";
import { Close } from "@mui/icons-material";

export default function PartnerLoginInProgressDialog(props) {
  return (
    <>
      <Dialog
        open={props.open}
        // sx={{ minHeight: "40vh" }}
        onClose={props.handleClose}
      >
        <DialogContent>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item xs={10} />
            Logging in as{" "}
            {props.partner?.mobileNumber || props.partner?.partnerId}
            <Grid item xs={2}>
              <IconButton
                size={"large"}
                sx={{ marginLeft: "6px" }}
                onClick={() => {
                  props.handleClose();
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>

          {props.loading === "started" && <LinearProgress />}
          {props.errorCode && (
            <Typography
              variant={"caption"}
              align={"center"}
              color={"error"}
              sx={{ color: "#222222" }}
            >
              {props.errorCode}
            </Typography>
          )}
          {props.errorMessage && (
            <ErrorMessage message={props.errorMessage}></ErrorMessage>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
