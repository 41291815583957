import { useEffect, useState } from "react";

import ProductService from "../Services/ProductService";
import _ from "lodash";

function useTurnoverSchemeProductsRecommendation() {
  const [loading, setLoading] = useState("not_started");
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading("started");

    const { axiosRequest, abortController } =
      ProductService.getTurnoverSchemeRecommendation();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.productGists?.length > 0) {
          let productsFromResponse = response.data.responseData.productGists;
          productsFromResponse = _.filter(productsFromResponse, (product) => {
            return (
              product.brandName !== "Unassigned Brand" &&
              ProductService.checkIfProductEnabled(product)
            );
          });
          setProducts(productsFromResponse);
        } else {
          console.log(
            `no response received in responseData of Turnover Scheme Recommendation`
          );
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting Turnover Scheme products recommendation`);
          if (error?.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, []);

  return { products, loading, error };
}

export default useTurnoverSchemeProductsRecommendation;
