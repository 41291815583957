import { useNavigate } from "react-router-dom";
import { East, DoneAll, CheckCircle, Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import AmountUtils from "../../Utils/AmountUtils";
import { Box, Button, Card, Container, Typography } from "@mui/material";

const useStyles = makeStyles({
  agentLeadCard: {
    marginBottom: "10px",
    borderRadius: "10px",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  leadBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    borderBottom: "1px solid #eee",
  },
  boldSizeText: {
    fontSize: "14px",
    fontWeight: 700,
  },
  normalSizeText: {
    fontSize: "14px",
    fontWeight: 400,
  },
  alignElementVertically: {
    display: "flex",
    flexDirection: "column",
  },
  leadStatus: {
    borderRadius: "5px",
    padding: "5px 7px 5px 5px",
    fontSize: "12px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
  },
  leadDetailsButton: {
    padding: "8px",
    fontSize: "12px",
    fontWeight: 700,
  },
});

export const agentLeadStatus = {
  MOB_APP_UNVERIFIED: {
    PURCHASE_REQUEST_BY_FIELD_AGENT: {
      icon: <DoneAll sx={{ color: "#cc8800", height: "18px" }} />,
      text: "Created",
      color: "#cc8800",
      background: "#FDF4E2",
    },
    PURCHASE_REQUEST_ORDER_PLACED: {
      icon: <CheckCircle sx={{ color: "#00733e", height: "18px" }} />,
      text: "Order Placed",
      color: "#00733E",
      background: "rgba(0, 115, 62, 0.2)",
    },
  },
  CANCELLED: {
    PURCHASE_REQUEST_BY_FIELD_AGENT: {
      icon: <Cancel sx={{ color: "#9d2123", height: "18px" }} />,
      text: "Cancelled",
      color: "#9d2123",
      background: "rgba(157, 33, 35, 0.2)",
    },
    PURCHASE_REQUEST_ORDER_PLACED: {
      icon: <CheckCircle sx={{ color: "#00733e", height: "18px" }} />,
      text: "Order Placed",
      color: "#00733E",
      background: "rgba(0, 115, 62, 0.2)",
    },
  },
};

export default function FieldAgentLeadCard({ lead }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card
      className={classes.agentLeadCard}
      onClick={() =>
        navigate({
          pathname: `/orders/${lead.orderId}&orderType=fieldAgentLead`,
          state: {
            orderType: "fieldAgentLead",
          },
        })
      }
    >
      <Box className={classes.leadBox}>
        <Typography className={classes.boldSizeText}>
          {lead.saathiName ? lead.saathiName : ""}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
          }}
        >
          {lead.createdOn ? lead.createdOn : ""}
        </Typography>
      </Box>
      <Box className={classes.leadBox}>
        <Typography
          className={classes.normalSizeText}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Mob: {lead.saathiMobileNum ? lead.saathiMobileNum : ""}
        </Typography>
        <Typography
          className={classes.leadStatus}
          sx={{
            color: agentLeadStatus?.[lead?.status][lead?.internalNote]?.color,
            background:
              agentLeadStatus?.[lead?.status][lead?.internalNote]?.background,
          }}
        >
          {agentLeadStatus?.[lead?.status][lead?.internalNote]?.icon}
          {agentLeadStatus?.[lead?.status][lead?.internalNote]?.text}
        </Typography>
      </Box>
      <Box className={classes.leadBox}>
        <Box className={classes.alignElementVertically}>
          <Typography className={classes.normalSizeText} component="span">
            Lead ID
          </Typography>
          <Typography className={classes.boldSizeText} component="span">
            {lead.orderId ? lead.orderId : ""}
          </Typography>
        </Box>
        <Box className={classes.alignElementVertically}>
          <Typography className={classes.normalSizeText} component="span">
            Total Amount
          </Typography>
          <Typography
            className={classes.boldSizeText}
            sx={{ textAlign: "right" }}
            component="span"
          >
            {AmountUtils.formatCurrency(lead?.grandTotal)}
          </Typography>
        </Box>
      </Box>
      <Button
        fullWidth
        endIcon={<East />}
        className={classes.leadDetailsButton}
      >
        Lead Details
      </Button>
    </Card>
  );
}
