import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import useSelectedBrands from "../../Hooks/useSelectedBrands";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductService from "../../Services/ProductService";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import Search from "../Dashboard/Search";
import ProductsFilter from "../Product/ProductsFilter";
import ProductsFilterLoader from "../Product/ProductsFilterLoader";
import ProductsList from "../Product/ProductsList";
import ProductsLoader from "../Product/ProductsLoader";
import SelectedBrandFilters from "../Product/SelectedBrandFilters";
import SelectedBrandFiltersLoader from "../Product/SelectedBrandFiltersLoader";

const SchemeProductsPage = (props) => {
  const { skus } = useParams();
  const [applicableProductsCohort, setApplicableProductsCohort] = useState([]);
  const [loadingProductsCohort, setLoadingProductsCohort] =
    useState("not_started");

  const query = useQuery();
  let brandNamesFromQuery = query.get("brandNames")?.split(",");
  brandNamesFromQuery = _.map(brandNamesFromQuery, (brandNameFromQuery) =>
    brandNameFromQuery?.toLowerCase()
  );
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [brands, setBrands] = useState(null);

  const { loadingAllBrands } = useSelectedBrands(
    brandNamesFromQuery,
    brands,
    setSelectedBrands
  );

  useEffect(() => {
    AnalyticsService.pushEvent(`Product List Page Viewed`, {
      page: "Scheme Products",
    });
    if (skus) {
      setLoadingProductsCohort("started");
      const { axiosRequest, abortController } =
        ProductService.getProductsListBySKUs(skus, selectedBrands);
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.productGists?.length > 0) {
            let productsFromResponse = response.data.responseData.productGists;
            productsFromResponse = _.filter(productsFromResponse, (product) => {
              return (
                product.brandName !== "Unassigned Brand" &&
                ProductService.checkIfProductEnabled(product)
              );
            });
            setApplicableProductsCohort(productsFromResponse);
          } else {
            console.log(`no response received in responseData`);
            setApplicableProductsCohort(null);
          }

          if (
            selectedBrands?.length === 0 &&
            response?.data?.responseData?.brands?.length > 0
          ) {
            let brandsFromResponse = response?.data?.responseData?.brands;
            brandsFromResponse = _.filter(brandsFromResponse, (brand) => {
              return brand.brandName !== "Unassigned Brand";
            });
            setBrands(brandsFromResponse);
          } else {
            console.log(`no brands received in responseData`);
          }

          setLoadingProductsCohort("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting products`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoadingProductsCohort("done");
          }
        });
      return () => abortController.abort();
    } else {
      console.log(`no SKUs found in route`);
      setLoadingProductsCohort("done");
    }
  }, [skus, selectedBrands]);

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Scheme Products`}
          backNavigation={true}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <ErrorBoundary message={``}>
        <Search page={"Scheme Products Page"} shouldMakeItSticky={true} />
      </ErrorBoundary>

      {loadingAllBrands === "done" &&
      loadingProductsCohort !== "started" &&
      applicableProductsCohort &&
      applicableProductsCohort.length > 0 ? (
        <>
          <ErrorBoundary message={``}>
            <SelectedBrandFilters
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
            />
          </ErrorBoundary>
          <ErrorBoundary message={``}>
            <ProductsList
              products={applicableProductsCohort}
              loading={loadingProductsCohort}
              page={`Scheme Products Page`}
            />
          </ErrorBoundary>{" "}
          <ErrorBoundary message={``}>
            <ProductsFilter
              brands={brands}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
            />
          </ErrorBoundary>
        </>
      ) : (
        <>
          {(loadingProductsCohort === "started" ||
            loadingAllBrands === "started") && (
            <>
              {selectedBrands?.length > 0 && <SelectedBrandFiltersLoader />}
              <ProductsLoader />
              <ProductsFilterLoader />
            </>
          )}
        </>
      )}
    </>
  );
};

export default SchemeProductsPage;
