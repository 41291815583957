import * as React from "react";
import { useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AnalyticsService from "../../Services/AnalyticsService";

export default function AppVersionUnsupported(props) {
  useEffect(() => {
    if (props.open === true) {
      AnalyticsService.pushEvent("App Update Required Dialog Shown", {
        page: props?.page ? props?.page : "",
        section: props?.section ? props?.section : "",
      });
    }
  }, [props.open]);
  const handleClickDownload = () => {
    AnalyticsService.pushEvent("Download App Clicked", {
      page: props?.page ? props?.page : "",
      section: props?.section ? props?.section : "",
    });
  };
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>
          <Grid container alignItems={`center`} justifyContent={`center`}>
            <Grid item xs={11}>
              App Update Required
            </Grid>
            <Grid item xs={1}>
              {!props.disableClose && (
                <IconButton
                  color={`primary`}
                  edge={`end`}
                  style={{ marginBottom: "2px" }}
                  onClick={props.handleClose}
                >
                  <Close />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems={`center`} justifyContent={`center`}>
            <Typography variant={`body1`} align={`center`}>
              Please update your app to use this feature. Download from Play
              Store using the button below.
            </Typography>

            <IconButton
              style={{ marginTop: "-30px" }}
              onClick={handleClickDownload}
              href={`https://play.google.com/store/apps/details?id=in.ulink.agrostar.saathi`}
              align={`center`}
            >
              <img
                src={`/icons/google-play.svg`}
                alt={`google-play`}
                width={180}
              />
            </IconButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
