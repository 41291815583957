import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import _ from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";
import CustomDateRange from "../Hisaab/CustomDateRange";
import CustomDropdownSelector from "./CustomDropdownSelector";
import { set } from "date-fns";

const PartnerStatsFilter = (props) => {
  const handleFilterClick = (clickedFilter) => {
    AnalyticsService.pushEvent(`Button Clicked`, {
      page: "vyapaar",
      filterLabel: clickedFilter?.label || "",
      type: "Filter",
    });
    props?.setPartnerStatsProductCategoryFilter(clickedFilter);
    props?.setCurrentPage(1);
  };

  const getMinimumDateValue = () => {
    var dateBefore731Days = new Date();
    dateBefore731Days.setDate(dateBefore731Days.getDate() - 731);
    //required for validation as we do not have the entire data before the following date
    const minDateValue = set(new Date(), { year: 2023, month: 0, date: 1 });
    return dateBefore731Days < minDateValue ? minDateValue : dateBefore731Days;
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          margin: "4px 0px",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {_.map(props?.statsFilters, (filter, index) => {
          return (
            <ButtonBase
              key={index}
              sx={{
                boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1);",
                borderRadius: "16px",
                padding: "5px 10px",
                display: "flex",
                alignItems: "center",
                fontWeight: 700,
                color: `${
                  props?.partnerStatsProductCategoryFilter?.identifier ===
                  filter?.identifier
                    ? "#9D2123"
                    : "#4F4F4F"
                }`,
                border: `${
                  props?.partnerStatsProductCategoryFilter?.identifier ===
                  filter?.identifier
                    ? "1px solid #9D2123"
                    : ``
                }`,
                backgroundColor: "#FFFFFF !important",
              }}
              onClick={() => {
                handleFilterClick(filter);
              }}
            >
              <Typography
                noWrap
                sx={{
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                {filter.label}
              </Typography>
            </ButtonBase>
          );
        })}
        <Box>
          <CustomDropdownSelector
            selectedDropdownFilterValue={props?.partnerStatsDropdownFilter}
            setSelectedDropdownFilterValue={
              props?.setPartnerStatsDropdownFilter
            }
            handleChangeSelectedDropdownFilterValue={
              props?.handleChangePartnerStatsDropdownFilter
            }
            menuList={props?.menuList}
            selectedDateRangeFilter={props?.partnerStatsDateRangeFilter}
            setSelectedDateRangeFilter={props?.setPartnerStatsDateRangeFilter}
            minDate={getMinimumDateValue()}
            previousDropdownFilterValue={props?.previousDropdownFilterValue}
          />
        </Box>
        &nbsp;&nbsp;
      </Stack>
    </>
  );
};

export default PartnerStatsFilter;
