import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import clevertap from "clevertap-web-sdk";
import config from "./config";
import * as Sentry from "@sentry/react";

import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { extraErrorDataIntegration as ExtraErrorDataIntegration } from "@sentry/react";

const history = createBrowserHistory();
Sentry.init({
  dsn: "https://bb81d5dbfd5e43a89b78c9833d450698@o1305558.ingest.sentry.io/6294038",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.breadcrumbsIntegration({ console: false }),
    Sentry.replayIntegration(),
  ],
  environment: config.environment,
  release: config.reactAppVersion,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.02,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.0001,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.01,
  autoSessionTracking: true,
});
clevertap.init(config.clevertapAccountId);
clevertap.spa = true;

// axios.interceptors.response.use(
//   (response) => {
//     // Edit response config
//     return response;
//   },
//   (error) => {
//     Sentry.captureException(error);
//     return Promise.reject(error);
//   }
// );

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
