import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Carousel from "react-material-ui-carousel";

import _ from "lodash";

import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import EmptyError from "../EmptyError/EmptyError";
import ProductService from "../../Services/ProductService";
import CartService from "../../Services/CartService";
import StarRatingLabel from "./StarRatingLabel";
import ProductInfo from "./ProductInfo";
import OffersContainer from "./OffersContainer";
import AnalyticsService from "../../Services/AnalyticsService";
import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import NeedHelp from "../Common/NeedHelp";
import SectionTitle from "../Common/SectionTitle";
import AlternateProducts from "./AlternateProducts";
import StateUtils from "../../Utils/StateUtils";
import QuantityChangeToast from "./QuantityChangeToast";
import CashDiscountBenefitContainer from "./CashDiscountBenefitContainer";
import PricingDetailsContainer from "./PricingDetailsContainer";
import AddedToBagToast from "../Cart/AddedToBagToast";
import BagUpdatedToast from "../Cart/BagUpdatedToast";
import SchemeCard from "../Offers/SchemeCard";
import PayAdvanceSchemeProducts from "../../Services/PayAdvanceSchemeProducts";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import PayAdvancedSchemeDetails from "./PayAdvancedSchemeDetails";
import Testimonials from "./Testimonials";
import CouponToast from "../Cart/CouponToast";
import ReturnPolicyContainer from "./ReturnPolicyContainer";
import AmountUtils from "../../Utils/AmountUtils";
import PartnerService from "../../Services/PartnerService";
import UnverifiedPartnersDialog from "../Common/UnverifiedPartnersDialog";
import FarmerExperience from "./FarmerExperience";
import FarmerExperienceService from "../../Services/FarmerExperienceService";
import InventoryDetailsDialog from "./InventoryDetailsDialog";
import SchemeDetailsDrawer from "../Offers/SchemeDetailsDrawer";
import ProductDetailsPrimary from "./ProductDetailsPrimary";
import ProductCheckoutActions from "./ProductCheckoutActions";
import getHarcodedProductTestimonial from "../../Services/ProductTestimonials";

const useStyles = makeStyles((theme) => ({
  card: {
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    borderRadius: "0px",
  },
  brandImageWrapper: {
    marginTop: theme.spacing(-2),
    padding: theme.spacing(2, 2, 0, 2),
    position: "absolute",
    zIndex: 9,
  },
  brandImage: {
    height: theme.spacing(7.5),
    width: "auto",
    maxWidth: "100%",
  },
  productImage: {
    display: "block",
    margin: "auto",
    height: theme.spacing(30),
    // [theme.breakpoints.up("sm")]: {
    //   height: theme.spacing(30),
    //   width: "auto",
    // },
  },
  productImageCarousel: {
    display: "block",
    margin: "auto",
    height: theme.spacing(28),
    // [theme.breakpoints.up("sm")]: {
    //   height: theme.spacing(28),
    //   width: "auto",
    // },
    // "@media (min-width:600px)": {
    //   width: "100%",
    //   height: "auto",
    // },
  },
  productImageWrapper: {
    margin: theme.spacing(0.5),
    minHeight: theme.spacing(30),
  },
  productTitle: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    wordBreak: "break-word",
  },
  productTitleSizeReduced: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    transition: ProductService.productDetailsStickyTransition,
    wordBreak: "break-word",
  },
  productSubTitle: {
    fontWeight: "700",
    fontSize: "1.125rem",
    // lineHeight: "1.533rem",
    marginLeft: "8px",
    textAlign: "right",
  },
  offersLoading: {
    fontSize: "1rem",
    lineHeight: "1rem",
    marginLeft: "8px",
    textAlign: "right",
  },
  greenDot: {
    height: "0.625rem",
    width: "0.625rem",
    backgroundColor: "#00733E",
    borderRadius: "50%",
    display: "inline-block",
  },
  stocks: {
    display: "inline-block",
    fontWeight: "500",
    color: "#00733E",
    fontSize: "0.875rem",
    lineHeight: "1.188rem",
    marginLeft: "4px",
  },
  outOfStock: {
    color: "#999999",
    display: "inline-block",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.188rem",
    marginLeft: "4px",
  },
  productDetailsWrapper: {
    paddingTop: theme.spacing(1),
  },
  hrLine: {
    marginTop: "1.25rem",
    borderBottom: "1px solid #CCCCCC",
    display: "block",
    marginBottom: "0.625rem",
  },
  contactLine: {
    color: "#999999",
    fontSize: "12px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-1),
    textAlign: "center",
  },
  DeliveryLine: {
    color: "#000000",
    fontSize: "12px",
  },
  boldFonts: {
    fontWeight: "700",
  },
  cardContent: {
    paddingBottom: "0.625rem !important",
  },
  deliveryWrapper: {
    marginTop: "0.5rem",
  },
  pricePerQuantity: {
    color: "#333333",
    fontWeight: "400",
    fontSize: "10px",
    width: "max-content",
    marginTop: theme.spacing(-0.5),
  },
  productMrp: {
    color: "#666666",
    textDecoration: "line-through",
    marginRight: theme.spacing(0.5),
    lineHeight: "19.07px",
    fontSize: "14px",
    fontWeight: "400",
  },
  productContainer: {
    minHeight: "90vh",
    marginBottom: theme.spacing(10),
  },
  testimonialCard: {
    border: "0px",
    borderRadius: "0px",
  },
  productInformationCard: {
    border: "0px",
    padding: "15px",
    borderRadius: "0px",
  },
  keyPointsLabel: {
    fontWeight: "700",
    size: "14px",
    marginTop: "-3px",
    marginBottom: "10px",
    paddingLeft: "10px",
  },
  subHeader: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#000000",
    marginBottom: "10px",
    marginTop: "-5px",
  },

  videoHeader: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#000000",
    marginTop: "8px",
    marginBottom: "-5px",
  },
  testimonialContent: {
    padding: "0px",
    paddingBottom: "5px !important",
    // marginBottom: "0px",
  },
}));

function Product(props) {
  const classes = useStyles();
  const { sku } = useParams();
  const query = new URLSearchParams(window.location.search);
  const from = query.get("from");

  let navigate = useNavigate();
  const [showQuantityChangeToast, setShowQuantityChangeToast] = useState(false);
  const [showForcedQuantityToast, setShowForcedQuantityToast] = useState(false);
  const [loading, setLoading] = useState("not_started");
  const [cartLoading, setCartLoading] = useState("not_started");
  const [quantity, setQuantity] = useState(1);
  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState(1);
  const [increased, setIncreased] = useState(false);
  const [decreased, setDecreased] = useState(false);
  const [manuallyChanged, setManuallyChanged] = useState(false);
  const [appliedOffer, setAppliedOffer] = useState(null);
  const [offerFromCart, setOfferFromCart] = useState(null);
  const [hasOfferInCart, setHasOfferInCart] = useState(null);
  const [product, setProduct] = useState(null);
  const [localLangProduct, setLocalLangProduct] = useState(null);
  const [title, setTitle] = useState(null);
  const [titleToShow, setTitleToShow] = useState(null);
  const [testimonials, setTestimonials] = useState(null);
  const [appliedCashDiscountBenefit, setAppliedCashDiscountBenefit] =
    useState(null);
  const [productInformationVideo, setProductInformationVideo] = useState(null);
  const [productCautionVideo, setProductCautionVideo] = useState(null);
  const [initiallyLoaded, setInitiallyLoaded] = useState(true);
  const [showTestimonialVideo, setShowTestimonialVideo] = useState(false);
  const [stateCode, setStateCode] = useState("MH");
  const [skuCode, setSkuCode] = useState(null);

  const [showAddedToBagToast, setShowAddedToBagToast] = useState(false);
  const [showBagUpdatedToast, setShowBagUpdatedToast] = useState(false);
  const [showCouponToast, setShowCouponToast] = useState(false);
  const [couponRemovedReason, setCouponRemovedReason] = useState("");
  const [turnoverScheme, setTurnoverScheme] = useState(null);
  const [turnOverSchemeBenefits, setTurnOverSchemeBenefits] = useState(null);
  const [openSchemeDetailsDrawer, setOpenSchemeDetailsDrawer] = useState(false);

  const saathiSource = localStorage.getItem(`source`);
  const cashDiscountBenefitContainerRef = useRef();
  const offersContainerRef = useRef();
  const turnoverSchemeContainerRef = useRef();
  const productDetailsRef = useRef();
  const [productDetailsStickyAtTop, setProductDetailsStickyAtTop] =
    useState(false);
  const [quantityViaMilestoneClick, setQuantityViaMilestoneClick] =
    useState(false);
  const [milestoneClickIndex, setMilestoneClickIndex] = useState(null);
  const [returnPolicy, setReturnPolicy] = useState(null);
  const [farmerNetPrice, setFarmerNetPrice] = useState(null);
  const [showUnauthorizedError, setShowUnauthorizedError] = useState(false);
  const [unauthorizedMessage, setUnauthorizedMessage] = useState("");
  const [farmerExperiencePosts, setFarmerExperiencePosts] = useState([]);
  const [showInventoryDetails, setShowInventoryDetails] = useState(false);
  const [productKeyPoints, setProductKeyPoints] = useState(null);

  const payAdvanceSchemeDetailsRef = useRef();
  const getOfferById = (responseData, id) => {
    return _.filter(responseData?.offersDetail?.productOffers, (offer) => {
      return offer?.id === id?.toString();
    })?.[0];
  };
  const applyOffer = (offer) => {
    setAppliedOffer(offer);
  };

  const setShowQuantityChangeToastMessage = (flag, forcedFlag) => {
    setShowForcedQuantityToast(forcedFlag);
    setShowQuantityChangeToast(flag);
  };

  // const applyOfferFromCart = (offer) => {
  //   setAppliedOfferFromCart(offer);
  //   if (offer?.quantity) {
  //     setQuantity(offer?.quantity);
  //   }
  // };
  const getLargestOfferQuantity = () => {
    if (product?.offersDetail?.productOffers?.length > 0) {
      return _.orderBy(
        product.offersDetail.productOffers,
        ["quantity"],
        ["desc"]
      )?.[0].quantity;
    } else {
      return false;
    }
  };
  const getSmallestOfferQuantity = () => {
    if (product?.offersDetail?.productOffers?.length > 0) {
      return _.orderBy(
        product.offersDetail.productOffers,
        ["quantity"],
        ["asc"]
      )?.[0].quantity;
    } else {
      return false;
    }
  };

  const handleAdd = () => {
    setInitiallyLoaded(false);
    let largestOfferQuantity = getLargestOfferQuantity();
    if (
      largestOfferQuantity &&
      quantity >= largestOfferQuantity &&
      quantity % largestOfferQuantity === 0
    ) {
      let newQuantity = quantity + largestOfferQuantity;

      getProductDetailsBySkuWithQuantityAndOffer(appliedOffer, newQuantity);
      setQuantity(newQuantity);
    } else if (quantity < largestOfferQuantity) {
      let closestHigherOffer = product?.offersDetail?.productOffers
        ?.sort(
          (offer1, offer2) =>
            Math.abs(quantity - offer1.quantity) -
            Math.abs(quantity - offer2.quantity)
        )
        .filter((offer) => offer.quantity > quantity)?.[0];

      getProductDetailsBySkuWithQuantityAndOffer(
        closestHigherOffer,
        closestHigherOffer.quantity
      );
    } else {
      let updatedQuantity = null;
      if (!appliedOffer && turnoverScheme?.deltaPrompt) {
        // if offers are not applicable but TOD is applicable, change quantity as per TOD slabs
        let nextSlabDetails = _.find(
          turnoverScheme?.deltaPrompt,
          (slabDetails) => {
            return slabDetails?.deltaQuantity > quantity;
          }
        );
        let deltaQuantityForNextSlab = nextSlabDetails?.deltaQuantity;
        updatedQuantity = deltaQuantityForNextSlab
          ? deltaQuantityForNextSlab
          : quantity + 1;

        let nextMilestoneClickIndex = nextSlabDetails?.slabIndex
          ? nextSlabDetails?.slabIndex
          : turnoverScheme?.deltaPrompt?.slice(-1)?.[0]?.slabIndex;
        if (nextSlabDetails) {
          setQuantityViaMilestoneClick(true);
          setMilestoneClickIndex(nextMilestoneClickIndex);
        }
      } else {
        // if offers & TOD are both not applicable, increase quantity by 1
        updatedQuantity = quantity + 1;
      }

      let [newQuantity, forcedQuantityFlag] =
        ProductService.calculateQuantityOnMinimumOrderQuantity(
          updatedQuantity,
          minimumOrderQuantity,
          "addition"
        );
      if (forcedQuantityFlag) {
        setShowQuantityChangeToastMessage(true, forcedQuantityFlag);
      }

      getProductDetailsBySkuWithQuantityAndOffer(appliedOffer, newQuantity);

      setQuantity(newQuantity);
    }

    let productQuantityIncreasedEventData = {
      skuCode: product?.skuCode || "",
      page: "Product Page",
    };

    AnalyticsService.pushEvent(
      `Product Quantity Increased`,
      productQuantityIncreasedEventData
    );
  };

  const handleRemove = () => {
    setInitiallyLoaded(false);
    if (quantity > 1) {
      let largestOfferQuantity = getLargestOfferQuantity();
      if (
        largestOfferQuantity &&
        quantity > largestOfferQuantity &&
        quantity % largestOfferQuantity === 0
      ) {
        let highestOffer = _.orderBy(
          product.offersDetail?.productOffers,
          ["quantity"],
          ["desc"]
        )?.[0];

        getProductDetailsBySkuWithQuantityAndOffer(
          highestOffer,
          quantity - highestOffer.quantity
        );
      } else if (largestOfferQuantity && quantity === largestOfferQuantity) {
        let closestLowerOffer = null;

        if (quantity === minimumOrderQuantity) {
          closestLowerOffer = product?.offersDetail?.productOffers
            ?.sort(
              (offer1, offer2) =>
                Math.abs(quantity - offer1.quantity) -
                Math.abs(quantity - offer2.quantity)
            )
            .filter((offer) => offer.quantity <= quantity)?.[0];
        } else {
          closestLowerOffer = product?.offersDetail?.productOffers
            ?.sort(
              (offer1, offer2) =>
                Math.abs(quantity - offer1.quantity) -
                Math.abs(quantity - offer2.quantity)
            )
            .filter((offer) => offer.quantity < quantity)?.[0];
        }

        if (closestLowerOffer) {
          if (closestLowerOffer?.quantity === quantity) {
            if (Number(quantity) - 1 > 0) {
              let updatedQuantity = Number(quantity) - 1;
              let [newQuantity, forcedQuantityFlag] =
                ProductService.calculateQuantityOnMinimumOrderQuantity(
                  updatedQuantity,
                  minimumOrderQuantity,
                  "removal"
                );
              getProductDetailsBySkuWithQuantityAndOffer(
                closestLowerOffer,
                newQuantity
              );
              setQuantity(newQuantity);
            } else {
              setQuantity(minimumOrderQuantity);
            }
            applyOffer(null);
          } else {
            getProductDetailsBySkuWithQuantityAndOffer(
              closestLowerOffer,
              closestLowerOffer.quantity
            );
          }
        } else {
          let updatedQuantity = quantity - 1;

          let [newQuantity, forcedQuantityFlag] =
            ProductService.calculateQuantityOnMinimumOrderQuantity(
              updatedQuantity,
              minimumOrderQuantity,
              "removal"
            );

          if (
            updatedQuantity !== newQuantity &&
            updatedQuantity < minimumOrderQuantity
          ) {
            setShowQuantityChangeToastMessage(true, forcedQuantityFlag);
          }

          applyOffer(null);

          getProductDetailsBySkuWithQuantity(newQuantity, false);

          setQuantity(newQuantity);
        }
      } else if (quantity < largestOfferQuantity) {
        if (quantity <= getSmallestOfferQuantity()) {
          let updatedQuantity = Number(quantity) - 1;
          let [newQuantity, forcedQuantityFlag] =
            ProductService.calculateQuantityOnMinimumOrderQuantity(
              updatedQuantity,
              minimumOrderQuantity,
              "removal"
            );
          console.log("qty less than smallest");

          if (
            updatedQuantity !== newQuantity &&
            updatedQuantity < minimumOrderQuantity
          ) {
            setShowQuantityChangeToastMessage(true, forcedQuantityFlag);
          }
          getProductDetailsBySkuWithQuantity(newQuantity, false);
          setQuantity(newQuantity);
          applyOffer(null);
          setAppliedOffer(null);
        } else {
          let closestLowerOffer = product?.offersDetail?.productOffers
            ?.sort(
              (offer1, offer2) =>
                Math.abs(quantity - offer1.quantity) -
                Math.abs(quantity - offer2.quantity)
            )
            .filter((offer) => offer.quantity < quantity)?.[0];

          if (closestLowerOffer) {
            getProductDetailsBySkuWithQuantityAndOffer(
              closestLowerOffer,
              closestLowerOffer.quantity
            );
          }
        }
      } else {
        let updatedQuantity = null;
        if (!appliedOffer && turnoverScheme?.deltaPrompt) {
          // if offers are not applicable but TOD is applicable, change quantity as per TOD slabs
          let previousSlabDetails = _.findLast(
            turnoverScheme?.deltaPrompt,
            (slabDetails) => {
              return slabDetails?.deltaQuantity < quantity;
            }
          );
          let deltaQuantityForPreviousSlab = previousSlabDetails?.deltaQuantity;
          updatedQuantity = deltaQuantityForPreviousSlab
            ? deltaQuantityForPreviousSlab
            : quantity - 1;

          let previousMilestoneClickIndex = previousSlabDetails?.slabIndex
            ? previousSlabDetails?.slabIndex
            : 1;
          if (previousSlabDetails) {
            setQuantityViaMilestoneClick(true);
            setMilestoneClickIndex(previousMilestoneClickIndex);
          }
        } else {
          // if offers & TOD are both not applicable, decrease quantity by 1
          updatedQuantity = quantity - 1;
        }

        let [newQuantity, forcedQuantityFlag] =
          ProductService.calculateQuantityOnMinimumOrderQuantity(
            updatedQuantity,
            minimumOrderQuantity,
            "removal"
          );
        if (
          updatedQuantity !== newQuantity &&
          updatedQuantity < minimumOrderQuantity
        ) {
          setShowQuantityChangeToastMessage(true, forcedQuantityFlag);
        }
        getProductDetailsBySkuWithQuantityAndOffer(appliedOffer, newQuantity);
        setQuantity(newQuantity);
      }
    }
    let productQuantityDecreasedEventData = {
      skuCode: product?.skuCode || "",
      page: "Product Page",
    };

    AnalyticsService.pushEvent(
      `Product Quantity Decreased`,
      productQuantityDecreasedEventData
    );
  };

  const handleChange = (event, inputVia, quantity) => {
    setIncreased(false);
    setDecreased(false);
    setManuallyChanged(true);
    setInitiallyLoaded(false);
    setAppliedOffer(null);

    let updatedQuantity = null;
    if (inputVia === "inputBox") {
      updatedQuantity = Number(event?.target?.value) || event?.target?.value;
    } else if (inputVia === "milestoneClick") {
      updatedQuantity = quantity;
    }

    let [newQuantity, forcedQuantityFlag] =
      ProductService.calculateQuantityOnMinimumOrderQuantity(
        updatedQuantity,
        minimumOrderQuantity,
        "addition"
      );
    if (updatedQuantity !== newQuantity) {
      setShowQuantityChangeToastMessage(true, forcedQuantityFlag);
    }
    // getProductDetailsBySkuWithQuantityAndOffer(appliedOffer, newQuantity);
    setQuantity(newQuantity);
    if (getSmallestOfferQuantity() > newQuantity) {
      setAppliedOffer(null);
      getProductDetailsBySkuWithQuantity(newQuantity, false);
    } else {
      // if (newQuantity <= 1000) {
      getProductDetailsBySkuWithQuantity(newQuantity, false);
      // } else if (newQuantity > 1000) {
      //   newQuantity = 1000;
      //   getProductDetailsBySkuWithQuantity(newQuantity);
      //   setQuantity(newQuantity);
      // }
    }
    if (inputVia === "inputBox") {
      handleQuantityUpdated(event.target.value);
    } else if (inputVia === "milestoneClick") {
      handleQuantityUpdated(quantity);
    }
  };

  const getBestOffer = (productResponse, selectedQuantity) => {
    if (
      productResponse?.offersDetail?.productOffers &&
      productResponse?.offersDetail?.bestSelectID
    ) {
      return getOfferById(
        productResponse,
        productResponse?.offersDetail?.bestSelectID
      );
    } else if (
      productResponse?.offersDetail?.productOffers &&
      productResponse?.offersDetail?.autoSelectId
    ) {
      // if backend is not sending bestSelectID, it means the offer is not applicable under NEP
      // use autoSelectId in this case, but only if user's selected quantity greater than offer quantity
      let offer = getOfferById(
        productResponse,
        productResponse?.offersDetail?.autoSelectId
      );
      if (selectedQuantity >= offer?.quantity) {
        return offer;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const [offersLoading, setOffersLoading] = useState("not_started");
  const getProductDetailsBySkuWithQuantity = (quantity, isInitialPageLoad) => {
    setOffersLoading("started");

    const { axiosRequest } = ProductService.getProductDetailsBySkuWithQuantity(
      sku,
      saathiSource,
      !isInitialPageLoad ? quantity : null
    );
    axiosRequest
      .then((saathiResponse) => {
        if (saathiResponse?.data?.responseData) {
          let responseData = saathiResponse.data.responseData;
          if (responseData) {
            setProduct(responseData);
            let bestOffer = getBestOffer(responseData, quantity);
            applyOffer(bestOffer);
            if (isInitialPageLoad) {
              setQuantity(responseData.productQuantity || quantity);
            }
            if (
              responseData?.turnOverOffersDetails?.turnoverDetails?.length > 0
            ) {
              setTurnoverScheme(
                responseData?.turnOverOffersDetails?.turnoverDetails?.[0]
              );
            } else {
              setTurnoverScheme(null);
            }
            if (responseData?.turnOverOffersDetails?.turnOverSchemeBenefits) {
              if (
                responseData?.turnOverOffersDetails?.turnOverSchemeBenefits?.[
                  bestOffer?.offerId
                ]
              ) {
                setTurnOverSchemeBenefits(
                  responseData?.turnOverOffersDetails?.turnOverSchemeBenefits[
                    bestOffer?.offerId
                  ]
                );
              } else {
                setTurnOverSchemeBenefits(
                  responseData?.turnOverOffersDetails?.turnOverSchemeBenefits[
                    "no-offer"
                  ]
                );
              }
            } else {
              setTurnOverSchemeBenefits(null);
            }
          }
        } else {
          console.log(`no response received in responseData`);
        }
        setOffersLoading("done");
      })
      .catch(() => {
        setOffersLoading("done");
      });
  };
  const getProductDetailsBySkuWithQuantityAndOffer = (
    userSelectedOffer,
    quantity
  ) => {
    setOffersLoading("started");

    applyOffer(null);
    const { axiosRequest } = ProductService.getProductDetailsBySkuWithQuantity(
      sku,
      saathiSource,
      quantity
    );
    axiosRequest
      .then((saathiResponse) => {
        if (saathiResponse?.data?.responseData) {
          let responseData = saathiResponse?.data?.responseData;
          if (responseData) {
            setProduct(responseData);
            let userSelectedOfferUpdated = getOfferById(
              responseData,
              userSelectedOffer?.id
            );
            if (userSelectedOfferUpdated) {
              applyOffer(userSelectedOfferUpdated);
              setQuantity(quantity);
            }

            if (
              responseData?.turnOverOffersDetails?.turnoverDetails?.length > 0
            ) {
              setTurnoverScheme(
                responseData?.turnOverOffersDetails?.turnoverDetails?.[0]
              );
            } else {
              setTurnoverScheme(null);
            }
            if (responseData?.turnOverOffersDetails?.turnOverSchemeBenefits) {
              if (
                responseData?.turnOverOffersDetails?.turnOverSchemeBenefits?.[
                  userSelectedOfferUpdated?.offerId
                ]
              ) {
                setTurnOverSchemeBenefits(
                  responseData?.turnOverOffersDetails?.turnOverSchemeBenefits?.[
                    userSelectedOfferUpdated?.offerId
                  ]
                );
              } else {
                setTurnOverSchemeBenefits(
                  responseData?.turnOverOffersDetails?.turnOverSchemeBenefits[
                    "no-offer"
                  ]
                );
              }
            } else {
              setTurnOverSchemeBenefits(null);
            }
          }
        } else {
          console.log(`no response received in responseData`);
        }
        setOffersLoading("done");
      })
      .catch(() => {
        setOffersLoading("done");
      });
  };
  const getProductDetailsBySkuWithoutQuantity = (
    userSelectedOffer,
    quantity
  ) => {
    setOffersLoading("started");

    applyOffer(null);
    const { axiosRequest } = ProductService.getProductDetailsBySku(
      sku,
      saathiSource,
      quantity
    );
    axiosRequest
      .then((saathiResponse) => {
        if (saathiResponse?.data?.responseData) {
          let responseData = saathiResponse.data.responseData;
          if (responseData) {
            setProduct(responseData);

            let userSelectedOfferUpdated = getOfferById(
              responseData,
              userSelectedOffer.id
            );
            if (userSelectedOfferUpdated) {
              applyOffer(userSelectedOfferUpdated);
              setQuantity(quantity);
            }

            if (
              responseData?.turnOverOffersDetails?.turnoverDetails?.length > 0
            ) {
              setTurnoverScheme(
                responseData?.turnOverOffersDetails?.turnoverDetails?.[0]
              );
            } else {
              setTurnoverScheme(null);
            }
            if (responseData?.turnOverOffersDetails?.turnOverSchemeBenefits) {
              if (
                responseData?.turnOverOffersDetails?.turnOverSchemeBenefits?.[
                  userSelectedOfferUpdated?.offerId
                ]
              ) {
                setTurnOverSchemeBenefits(
                  responseData?.turnOverOffersDetails?.turnOverSchemeBenefits[
                    userSelectedOfferUpdated?.offerId
                  ]
                );
              } else {
                setTurnOverSchemeBenefits(
                  responseData?.turnOverOffersDetails?.turnOverSchemeBenefits[
                    "no-offer"
                  ]
                );
              }
            } else {
              setTurnOverSchemeBenefits(null);
            }
          }
        } else {
          console.log(`no response received in responseData`);
        }
        setOffersLoading("done");
      })
      .catch(() => {
        setOffersLoading("done");
      });
  };

  useEffect(() => {
    setLoading("started");

    setSkuCode(sku);
    setAppliedOffer(null);
    let currentStateCode = StateUtils.getStateCode();
    setStateCode(currentStateCode);

    const { axiosRequest, abortController } =
      ProductService.getProductDetailsBySkuAndSetProductView(sku, saathiSource);
    axiosRequest
      .then((saathiResponse) => {
        if (saathiResponse?.data?.responseData) {
          let responseData = saathiResponse.data.responseData;
          if (responseData) {
            let productFromCart = CartService.getProductFromCart(
              responseData.skuCode
            );

            if (
              responseData.minimumOrderQuantity &&
              responseData.minimumOrderQuantity > 0
            ) {
              setMinimumOrderQuantity(responseData.minimumOrderQuantity);
            }
            setInitiallyLoaded(true);
            let quantityToSet = 1;

            if (productFromCart?.quantity > 0) {
              quantityToSet =
                Number(productFromCart.quantity) || productFromCart.quantity;
            } else {
              // setQuantity(1);
            }
            if (productFromCart?.appliedOfferData?.id) {
              let offerFromCartLocal = getOfferById(
                responseData,
                productFromCart.appliedOfferData?.id
              );
              if (offerFromCartLocal) {
                getProductDetailsBySkuWithQuantityAndOffer(
                  offerFromCartLocal,
                  productFromCart.quantity
                );
              }
            } else {
              let bestOffer = getBestOffer(
                responseData,
                responseData?.productQuantity
              );
              if (bestOffer) {
                applyOffer(bestOffer);
                quantityToSet = bestOffer.quantity;
              }
              // setAppliedOffer(null);
            }
            if (
              responseData.minimumOrderQuantity > 0 &&
              quantityToSet === 1 &&
              quantityToSet !== responseData.minimumOrderQuantity
            ) {
              quantityToSet = responseData.minimumOrderQuantity;
            }
            setQuantity(quantityToSet);
            getProductDetailsBySkuWithQuantity(quantityToSet, true);
            setProduct(responseData);
            handleProductViewed(responseData);
            let titleToSet =
              responseData && responseData.brand && responseData.category
                ? `${responseData.brand} : ${responseData.category}`
                : ``;
            setTitle(titleToSet);
            let titleToShow = titleToSet;
            if (titleToSet.length > 25) {
              titleToShow = titleToShow.slice(0, 25);
              titleToShow = titleToShow.concat(` ...`);
            }
            setTitleToShow(titleToShow);
          }
        } else {
          console.log(`no response received in responseData`);
        }

        const { axiosRequest } = ProductService.getTestimonialBySku(sku);
        axiosRequest
          .then((testimonialResponse) => {
            if (testimonialResponse?.data?.responseData) {
              const vernacularResponseDataForMedia =
                testimonialResponse.data.responseData;
              setLocalLangProduct(vernacularResponseDataForMedia);
              setProductKeyPoints(
                testimonialResponse?.data?.responseData?.details
              );

              if (vernacularResponseDataForMedia?.additionalDetails) {
                let additionalDetails =
                  vernacularResponseDataForMedia?.additionalDetails;
                _.forEach(additionalDetails, (detailItem) => {
                  if (detailItem.value === "TESTIMONIALS") {
                    if (detailItem?.data?.media) {
                      let testimonials = [];

                      // [SAT-1202]: Harcoded Testimonial video in PDP
                      const harcodedTestimonials =
                        getHarcodedProductTestimonial(sku, saathiSource);

                      if (harcodedTestimonials?.length > 0) {
                        harcodedTestimonials.forEach((url) =>
                          testimonials.push({
                            src: url,
                            autoplay: false,
                            skuCode: sku,
                          })
                        );
                      }

                      _.forEach(detailItem?.data?.media, (mediaItem) =>
                        testimonials.push({
                          src: mediaItem?.url,
                          autoplay: false,
                          skuCode: vernacularResponseDataForMedia?.skuCode,
                        })
                      );
                      setTestimonials(testimonials);
                    }
                  } else if (detailItem.value === "HOW_TO_USE") {
                    if (detailItem.data?.media?.[0]?.url) {
                      setProductInformationVideo({
                        src: detailItem.data?.media?.[0]?.url,
                        skuCode: vernacularResponseDataForMedia?.skuCode,
                        section: detailItem.value,
                      });
                    }
                  } else if (detailItem.value === "BENEFITS") {
                    if (detailItem.data?.media?.[0]?.url) {
                      setProductCautionVideo({
                        src: detailItem.data?.media?.[0]?.url,
                        skuCode: vernacularResponseDataForMedia?.skuCode,
                        section: detailItem.value,
                      });
                    }
                  }
                });
              }
            }
          })
          .catch((error) => {
            console.log(`error getting farmer testimonial data`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }

            // [SAT-1202]: Harcoded Testimonial video in PDP
            let testimonials = [];
            const harcodedTestimonials = getHarcodedProductTestimonial(
              sku,
              saathiSource
            );

            if (harcodedTestimonials?.length > 0) {
              harcodedTestimonials.forEach((url) =>
                testimonials.push({
                  src: url,
                  autoplay: false,
                  skuCode: sku,
                })
              );
            }
            setTestimonials(testimonials || null);
            setProductInformationVideo(null);
            setProductCautionVideo(null);
          });

        const { axiosRequest: axiosRequestReturnPolicy } =
          ProductService.getReturnPolicy(sku, Date.now());
        axiosRequestReturnPolicy
          .then((returnPolicyResponse) => {
            if (
              returnPolicyResponse?.data?.responseData?.productReturnPolicy?.[
                sku
              ]
            ) {
              setReturnPolicy(
                returnPolicyResponse?.data?.responseData?.productReturnPolicy[
                  sku
                ]
              );
            } else {
              console.log(
                `no response received in responseData for return policy`
              );
            }
          })
          .catch((error) => {
            console.log(`error getting return policy data`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setReturnPolicy(null);
          });

        const { axiosRequest: axiosRequestBenefit } =
          ProductService.getBenefitsForSkus(sku);
        axiosRequestBenefit
          .then((benefitsForSkuResponse) => {
            if (benefitsForSkuResponse?.data?.responseData?.[sku]) {
              setFarmerNetPrice(
                benefitsForSkuResponse?.data?.responseData?.[sku]
                  .defaultOfferCalculation?.offerPrice
              );
            } else {
              console.log(
                `no response received in responseData for sku benefits`
              );
            }
          })
          .catch((error) => {
            console.log(`error getting sku benefits data`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setFarmerNetPrice(null);
          });

        const productGroup =
          saathiResponse?.data?.responseData?.productGroup || "";

        const { axiosRequest: axiosRequestPosts } =
          FarmerExperienceService.getFarmerExperiencePosts(productGroup, sku);
        axiosRequestPosts
          .then((farmerExperienceResponse) => {
            if (farmerExperienceResponse?.data?.responseData) {
              const fetchedFarmerExperiencePosts =
                farmerExperienceResponse?.data?.responseData?.posts;
              if (
                fetchedFarmerExperiencePosts &&
                fetchedFarmerExperiencePosts.length > 0
              ) {
                const filteredFarmerExperiencePosts = _.filter(
                  fetchedFarmerExperiencePosts,
                  (post) =>
                    _.findIndex(
                      post?.data,
                      (data) => data?.type === "image"
                    ) !== -1
                );

                setFarmerExperiencePosts(filteredFarmerExperiencePosts);
              }
            }
          })
          .catch((error) => {
            console.log(`error getting farmer experience posts`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setFarmerExperiencePosts([]);
          });

        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting product details`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setProduct(null);
          setLoading("done");
        }
      });

    return () => abortController.abort();
  }, [sku, saathiSource]);

  const handleOfferChange = (event) => {
    if (event?.target?.value) {
      let idUserSelectedOffer = event.target.value;
      let userSelectedOffer = getOfferById(product, idUserSelectedOffer);

      if (userSelectedOffer) {
        // getProductDetailsBySkuWithoutQuantity(
        //   userSelectedOffer,
        //   userSelectedOffer.quantity
        // );
        getProductDetailsBySkuWithQuantityAndOffer(
          userSelectedOffer,
          userSelectedOffer.quantity
        );
      }
    }
  };

  const generateOfferEntitlementsString = (offer) => {
    const offerEntitlementsArray = [];
    _.forEach(offer?.entitlements, (entitlement) => {
      if (entitlement?.type === "freeProducts") {
        offerEntitlementsArray.push(
          `Free Products: ${entitlement?.product?.productName} (Quantity - ${entitlement?.product?.quantity})`
        );
      } else if (entitlement?.type === "cashDiscount") {
        offerEntitlementsArray.push(
          `₹${AmountUtils.format(entitlement?.value)} Direct Discount`
        );
      } else if (
        entitlement?.type === "directCashback" ||
        entitlement?.type === "cashBack"
      ) {
        offerEntitlementsArray.push(
          `₹${AmountUtils.format(entitlement?.value)} Direct Cashback`
        );
      } else if (entitlement?.type === "percentDiscount") {
        offerEntitlementsArray.push(`${entitlement?.value}% Discount`);
      } else if (entitlement?.type === "percentCashback") {
        offerEntitlementsArray.push(`${entitlement?.value}% Cashback`);
      } else {
      }
    });

    return _.join(offerEntitlementsArray, ",");
  };

  const handleProductViewed = (responseData) => {
    const getCameVia = () => {
      let cameVia = "";
      if (from) {
        if (from === "/") {
          cameVia = "/dashboard";
        } else {
          cameVia = from;
        }
      } else {
      }
      return cameVia;
    };
    const topOffer = getOfferById(
      responseData,
      responseData?.offersDetail?.bestSelectID
    );

    const productViewedEventData = {
      cameVia: getCameVia(),
      estimatedDeliveryDays: responseData?.estimatedDeliveryDays || "",
      offerType:
        responseData?.offersDetail?.productOffers
          ?.map((offer) => offer.offerType.value)
          .toString() || "",
      numberOfOffers: responseData?.offersDetail?.productOffers?.length || 0,
      isProductInStock: responseData?.showNotifyMe ? "false" : "true",
      skuCode: responseData.skuCode || "",
      productName: responseData?.productName || "",
      numberOfVariants: responseData?.variants?.length || 0,
      variants:
        responseData?.variants?.map((variant) => variant.sku_code).join(",") ||
        "",
      imageUrl: responseData?.productImages?.[0]?.name || "",
      topOffer: generateOfferEntitlementsString(topOffer),
    };
    AnalyticsService.pushEvent(`Product Viewed`, productViewedEventData);
  };

  const handleQuantityUpdated = (newQuantity) => {
    let productQuantityUpdatedEventData = {
      skuCode: product?.skuCode || "",
      initialQuantity: Number(quantity) || 0,
      quantityChangedTo: Number(newQuantity) || 0,
      page: "Product Details Page",
    };

    AnalyticsService.pushEvent(
      `Product Quantity Updated Via Input`,
      productQuantityUpdatedEventData
    );
  };

  const handleClickBrandOnPDP = (brandName, brandId) => {
    const brandClickedEventData = {
      page: "PDP",
      brandName: brandName || "",
    };

    AnalyticsService.pushEvent(`Brand Clicked`, brandClickedEventData);
    navigate(`/brands/${brandName}`);
  };

  useEffect(() => {
    if (productInformationVideo) {
      // setShowTestimonialVideo(false);
      // setShowProductInformationVideo(true);
    } else {
      // setShowTestimonialVideo(true);
      // setShowProductInformationVideo(false);
    }
  }, [productInformationVideo]);

  const handleImageSwiped = (currentIndex, previousIndex, skuCode) => {
    AnalyticsService.pushEvent(`Product Image Swiped`, {
      currentIndex,
      previousIndex,
      skuCode,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const rect = productDetailsRef?.current?.getBoundingClientRect();
      if (rect?.top <= 80) setProductDetailsStickyAtTop(true);
      if (rect?.top > 100) setProductDetailsStickyAtTop(false);
    };
    window.addEventListener("scroll", handleScroll);
  }, []);

  const setShowCouponRemovedToast = (show, reason) => {
    setCouponRemovedReason(reason);
    setShowCouponToast(show);
  };

  const isPayAdvanceSchemeActive = useMemo(() => {
    return ProductService.isPayAdvanceSchemeActive(skuCode);
  }, [skuCode]);

  const handleShowInventoryDetails = () => {
    if (PartnerService.checkIfFieldAgent() && product?.inventory > 0) {
      setShowInventoryDetails(true);
    }
  };

  return (
    <Box sx={{ backgroundColor: "#F3F3F3" }}>
      <ErrorBoundary message={``}>
        <TopBar
          showSearchBar={true}
          backNavigation={true}
          currentPage={"product"}
          showCart={true}
          showHisaab={true}
          toolTip={title}
        />
      </ErrorBoundary>
      <BlockUi
        tag="div"
        blocking={cartLoading === "started" || loading === "started"}
        message={`Loading...`}
        keepInView
      >
        <div className={classes.productContainer}>
          <QuantityChangeToast
            show={showQuantityChangeToast}
            minimumOrderQuantity={minimumOrderQuantity}
            showForcedQuantityToast={showForcedQuantityToast}
            setShow={setShowQuantityChangeToast}
          />
          <CouponToast
            show={showCouponToast}
            doubleMargin={true}
            showRemovedText={true}
            setShow={setShowCouponToast}
            error={couponRemovedReason}
          />
          <AddedToBagToast
            show={showAddedToBagToast}
            setShow={setShowAddedToBagToast}
          />
          <BagUpdatedToast
            show={showBagUpdatedToast}
            setShow={setShowBagUpdatedToast}
          />
          {product && ProductService.checkIfProductEnabled(product) ? (
            <div>
              <ErrorBoundary message={``}>
                <Card className={classes.card} variant={"outlined"}>
                  <Grid
                    container
                    spacing={0}
                    alignItems={`center`}
                    justifyContent={"flex-start"}
                    className={classes.brandImageWrapper}
                  >
                    <Grid item xs={10}>
                      <Grid container alignItems={`center`}>
                        <Grid
                          item
                          className={classes.brandImage}
                          xs={4}
                          onClick={() => {
                            handleClickBrandOnPDP(product.brand);
                          }}
                        >
                          <img
                            src={product.brandImage}
                            className={classes.brandImage}
                            alt="Brand-logo"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={2}>
                      {product.rating && (
                        <div style={{ textAlign: "end" }}>
                          <StarRatingLabel rating={product.rating.average} />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <CardContent className={classes.cardContent}>
                    {/* product Image */}
                    <div className={classes.productImageWrapper}>
                      {isPayAdvanceSchemeActive &&
                      ProductService.getAdvancedSchemeBenefitImageUrl(
                        product?.skuCode
                      ) ? (
                        <>
                          <img
                            src={ProductService.getAdvancedSchemeBenefitImageUrl(
                              product?.skuCode
                            )}
                            alt="productImage"
                            className={classes.productImage}
                          />
                        </>
                      ) : (
                        <>
                          {localLangProduct?.productImages?.length > 0 &&
                            (localLangProduct?.productImages?.length === 1 ? (
                              <img
                                src={localLangProduct?.productImages[0]?.name}
                                alt="productImage"
                                className={classes.productImage}
                              />
                            ) : (
                              <Carousel
                                animation={`fade`}
                                autoPlay={false}
                                swipe={true}
                                navButtonsAlwaysVisible={true}
                                fullHeightHover={true}
                                cycleNavigation={
                                  localLangProduct.productImages.length !== 1
                                }
                                height={224}
                                onChange={(currentIndex, previousIndex) =>
                                  handleImageSwiped(
                                    currentIndex,
                                    previousIndex,
                                    localLangProduct.skuCode
                                  )
                                }
                                indicatorContainerProps={{
                                  style: { marginTop: "0px" },
                                }}
                              >
                                {localLangProduct.productImages.map(
                                  (media, index) => (
                                    <img
                                      src={media?.name}
                                      alt="productImage"
                                      key={index}
                                      className={classes.productImageCarousel}
                                    />
                                  )
                                )}
                              </Carousel>
                            ))}
                        </>
                      )}

                      {/* stock availability */}
                      <Grid
                        container
                        alignItems={`center`}
                        justifyContent={"space-between"}
                      >
                        <Grid item sx={{ display: "flex" }}>
                          {ProductService.getClassImageUrl(
                            product?.productClass
                          ) ? (
                            <>
                              <img
                                style={{
                                  height: "30px",
                                  borderRadius: "5px",
                                }}
                                src={ProductService.getClassImageUrl(
                                  product?.productClass
                                )}
                                alt=""
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item>
                          {!isPayAdvanceSchemeActive && (
                            <>
                              {product?.showNotifyMe ? (
                                <img src={`/icons/OutOfStock.svg`} alt="" />
                              ) : (
                                <div className={classes.greenDot} />
                              )}
                              <Typography
                                className={
                                  product?.showNotifyMe
                                    ? classes.outOfStock
                                    : classes.stocks
                                }
                                onClick={handleShowInventoryDetails}
                              >
                                {product?.showNotifyMe ? (
                                  `Out Of Stock`
                                ) : (
                                  <>
                                    <span>In Stock</span>
                                    {PartnerService.checkIfFieldAgent() && (
                                      <ArrowForwardSharpIcon
                                        sx={{
                                          position: "relative",
                                          bottom: "-7px",
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>

                {/* Primary Product Details: product name, net price, margin, variants, ETA, change quantity */}
                <ProductDetailsPrimary
                  product={product}
                  productDetailsRef={productDetailsRef}
                  productDetailsStickyAtTop={productDetailsStickyAtTop}
                  isPayAdvanceSchemeActive={isPayAdvanceSchemeActive}
                  appliedOffer={appliedOffer}
                  appliedCashDiscountBenefit={appliedCashDiscountBenefit}
                  offersLoading={offersLoading}
                  turnOverSchemeBenefits={turnOverSchemeBenefits}
                  cashDiscountBenefitContainerRef={
                    cashDiscountBenefitContainerRef
                  }
                  offersContainerRef={offersContainerRef}
                  turnoverScheme={turnoverScheme}
                  turnoverSchemeContainerRef={turnoverSchemeContainerRef}
                  farmerNetPrice={farmerNetPrice}
                  loading={loading}
                  payAdvanceSchemeDetailsRef={payAdvanceSchemeDetailsRef}
                  quantity={quantity}
                  handleAdd={handleAdd}
                  handleRemove={handleRemove}
                  handleChange={handleChange}
                  setQuantity={setQuantity}
                  minimumOrderQuantity={minimumOrderQuantity}
                />

                {/* Turnover Scheme section */}
                <Container
                  maxWidth={`xl`}
                  sx={{ marginTop: "16px" }}
                  ref={turnoverSchemeContainerRef}
                >
                  {offersLoading === "done" && !turnoverScheme ? (
                    <></>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: 700,
                        marginBottom: "8px",
                      }}
                    >
                      {`Scheme`}
                    </Typography>
                  )}
                  {offersLoading === "started" && (
                    <Skeleton
                      variant="rounded"
                      height={200}
                      sx={{ borderRadius: "16px" }}
                    />
                  )}
                  {offersLoading === "done" && turnoverScheme && (
                    <Box
                      sx={{
                        filter: PartnerService.applySmudgedPriceFilter(product),
                      }}
                    >
                      <SchemeCard
                        turnoverScheme={turnoverScheme}
                        turnOverSchemeBenefits={turnOverSchemeBenefits}
                        cardClickable={true}
                        clickRedirectTo={"SCHEME_DETAILS_DRAWER"}
                        nudgeClickable={true}
                        showProjectedProgress={true}
                        page={window?.location?.pathname?.slice(1)}
                        isFeaturedSchemes={false}
                        handleChange={handleChange}
                        quantityViaMilestoneClick={quantityViaMilestoneClick}
                        setQuantityViaMilestoneClick={
                          setQuantityViaMilestoneClick
                        }
                        milestoneClickIndex={milestoneClickIndex}
                        setMilestoneClickIndex={setMilestoneClickIndex}
                        setOpenSchemeDetailsDrawer={setOpenSchemeDetailsDrawer}
                      />
                    </Box>
                  )}
                </Container>

                {/* Available offers section */}
                {(product.offers !== null || product.offersDetail !== null) &&
                  (Object.entries(product.offers).length !== 0 ||
                    Object.entries(product.offersDetail).length !== 0) && (
                    <>
                      <Container
                        sx={{ marginTop: "20px", marginBottom: "-10px" }}
                        ref={offersContainerRef}
                      >
                        <SectionTitle title={`Offers`} />
                      </Container>

                      <BlockUi
                        tag="div"
                        blocking={offersLoading === "started"}
                        message={`Loading...`}
                        keepInView
                      >
                        <OffersContainer
                          product={product}
                          appliedOffer={appliedOffer}
                          handleOfferChange={handleOfferChange}
                          initiallyLoaded={initiallyLoaded}
                        />
                      </BlockUi>
                    </>
                  )}

                <Box ref={cashDiscountBenefitContainerRef}>
                  <CashDiscountBenefitContainer
                    appliedOffer={appliedOffer}
                    cashDiscountBenefits={product.cashDiscountBenefits}
                    setAppliedCashDiscountBenefit={
                      setAppliedCashDiscountBenefit
                    }
                    offersLoading={offersLoading}
                    appliedCashDiscountBenefit={appliedCashDiscountBenefit}
                    isPayAdvanceSchemeActive={isPayAdvanceSchemeActive}
                    product={product}
                  />
                </Box>

                <PricingDetailsContainer
                  product={product}
                  cashDiscountBenefits={product.cashDiscountBenefits}
                  appliedCashDiscountBenefit={appliedCashDiscountBenefit}
                  appliedOffer={appliedOffer}
                  quantity={quantity}
                  offersLoading={offersLoading}
                  turnoverScheme={turnoverScheme}
                  turnOverSchemeBenefits={turnOverSchemeBenefits}
                />

                {isPayAdvanceSchemeActive && (
                  <Box
                    ref={payAdvanceSchemeDetailsRef}
                    sx={{ scrollMargin: "200px" }}
                  >
                    <PayAdvancedSchemeDetails
                      scheme={PayAdvanceSchemeProducts?.[skuCode]}
                      skuCode={skuCode}
                    />
                  </Box>
                )}

                <ProductCheckoutActions
                  product={product}
                  quantity={quantity}
                  loading={loading}
                  isPayAdvanceSchemeActive={isPayAdvanceSchemeActive}
                  skuCode={skuCode}
                  setLoading={setLoading}
                  sku={sku}
                  appliedOffer={appliedOffer}
                  setCartLoading={setCartLoading}
                  setShowAddedToBagToast={setShowAddedToBagToast}
                  setShowBagUpdatedToast={setShowBagUpdatedToast}
                  setShowCouponRemovedToast={setShowCouponRemovedToast}
                  setShowUnauthorizedError={setShowUnauthorizedError}
                  setUnauthorizedMessage={setUnauthorizedMessage}
                />

                <ErrorBoundary message={``}>
                  <Box sx={{ mt: 2.5 }}>
                    <AlternateProducts stateCode={stateCode} skuCode={sku} />
                  </Box>
                </ErrorBoundary>
                {returnPolicy && product && (
                  <ReturnPolicyContainer
                    quantity={quantity}
                    returnPolicy={returnPolicy}
                    minimumOrderQuantity={minimumOrderQuantity}
                    creditPeriod={product.creditPeriod}
                    creditPeriodDate={product?.creditPeriodDate}
                    offersLoading={offersLoading}
                  />
                )}

                {productInformationVideo ? (
                  <ErrorBoundary message={``}>
                    <Card
                      variant={`outlined`}
                      className={classes.productInformationCard}
                      style={{ marginTop: "20px" }}
                    >
                      <Typography className={classes.subHeader}>
                        How to Use
                      </Typography>
                      <ReactVideoPlayer
                        video={productInformationVideo}
                        autoplay={false}
                        style={{ overflow: "hidden", borderRadius: "16px" }}
                        playbackStartedEventName="Product Video Playback Started"
                        playbackEndedEventName="Product Video Playback Ended"
                        eventProperties={{
                          page: "Product Page",
                          skuCode: productInformationVideo?.skuCode,
                          // videoTitle: productInformationVideo?.title || "",
                          videoUrl: productInformationVideo?.src || "",
                          section: productInformationVideo?.section || "",
                        }}
                      />
                      {/*<Typography className={classes.videoHeader}>*/}
                      {/*  {productInformationVideo?.title}*/}
                      {/*</Typography>*/}
                    </Card>
                  </ErrorBoundary>
                ) : (
                  ""
                )}

                {testimonials ? (
                  <ErrorBoundary message={``}>
                    <Card
                      variant={`outlined`}
                      className={classes.testimonialCard}
                      style={{ marginTop: "20px" }}
                    >
                      <CardContent className={classes.testimonialContent}>
                        <Typography
                          className={classes.subHeader}
                          style={{ marginTop: "10px", marginLeft: "15px" }}
                        >
                          Product Testimonial
                        </Typography>
                        <Testimonials
                          testimonials={testimonials}
                          section="Testimonials"
                          skuCode={sku}
                        />
                      </CardContent>
                    </Card>
                  </ErrorBoundary>
                ) : (
                  ""
                )}

                {farmerExperiencePosts.length > 0 && (
                  <FarmerExperience
                    farmerExperiencePosts={farmerExperiencePosts}
                    skuCode={sku}
                  />
                )}

                {productCautionVideo ? (
                  <ErrorBoundary message={``}>
                    <Card
                      variant={`outlined`}
                      className={classes.productInformationCard}
                      style={{ marginTop: "20px" }}
                    >
                      <Typography className={classes.subHeader}>
                        Caution on Usage
                      </Typography>
                      <ReactVideoPlayer
                        video={productCautionVideo}
                        autoplay={false}
                        style={{ overflow: "hidden", borderRadius: "16px" }}
                        playbackStartedEventName="Product Video Playback Started"
                        playbackEndedEventName="Product Video Playback Ended"
                        eventProperties={{
                          page: "Product Page",
                          skuCode: productCautionVideo?.skuCode,
                          // videoTitle: productCautionVideo?.title || "",
                          videoUrl: productCautionVideo?.src || "",
                          section: productCautionVideo?.section || "",
                        }}
                      />
                    </Card>
                  </ErrorBoundary>
                ) : (
                  ""
                )}

                <ErrorBoundary>
                  <NeedHelp
                    skuCode={sku}
                    title={
                      localStorage.getItem("partnerStatus") === "UNVERIFIED"
                        ? `Need Help`
                        : `Best Deal`
                    }
                  />
                </ErrorBoundary>
                {product.details && product.details.length > 0 && (
                  <ErrorBoundary message={``}>
                    <ProductInfo
                      productInfo={
                        productKeyPoints ? productKeyPoints : product?.details
                      }
                    />
                  </ErrorBoundary>
                )}
              </ErrorBoundary>
            </div>
          ) : (
            <></>
          )}
          {loading === "done" &&
          (!product || !ProductService.checkIfProductEnabled(product)) ? (
            <EmptyError
              image="EmptyPage.svg"
              text="Sorry, the product was not found"
            />
          ) : (
            <></>
          )}
        </div>
      </BlockUi>

      <UnverifiedPartnersDialog
        showError={showUnauthorizedError}
        setShowError={setShowUnauthorizedError}
        error={unauthorizedMessage}
      />

      <InventoryDetailsDialog
        product={product}
        open={showInventoryDetails}
        setOpen={setShowInventoryDetails}
      />

      <SchemeDetailsDrawer
        open={openSchemeDetailsDrawer}
        setOpen={setOpenSchemeDetailsDrawer}
        product={product}
        productDetailsRef={productDetailsRef}
        productDetailsStickyAtTop={productDetailsStickyAtTop}
        isPayAdvanceSchemeActive={isPayAdvanceSchemeActive}
        appliedOffer={appliedOffer}
        appliedCashDiscountBenefit={appliedCashDiscountBenefit}
        offersLoading={offersLoading}
        turnOverSchemeBenefits={turnOverSchemeBenefits}
        cashDiscountBenefitContainerRef={cashDiscountBenefitContainerRef}
        offersContainerRef={offersContainerRef}
        turnoverScheme={turnoverScheme}
        turnoverSchemeContainerRef={turnoverSchemeContainerRef}
        farmerNetPrice={farmerNetPrice}
        loading={loading}
        payAdvanceSchemeDetailsRef={payAdvanceSchemeDetailsRef}
        quantity={quantity}
        handleAdd={handleAdd}
        handleRemove={handleRemove}
        handleChange={handleChange}
        setQuantity={setQuantity}
        minimumOrderQuantity={minimumOrderQuantity}
        skuCode={skuCode}
        setLoading={setLoading}
        sku={sku}
        setCartLoading={setCartLoading}
        setShowAddedToBagToast={setShowAddedToBagToast}
        setShowBagUpdatedToast={setShowBagUpdatedToast}
        setShowCouponRemovedToast={setShowCouponRemovedToast}
        setShowUnauthorizedError={setShowUnauthorizedError}
        setUnauthorizedMessage={setUnauthorizedMessage}
      />
    </Box>
  );
}

export default Product;
