import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Button, DialogContent, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import AnalyticsService from "../../Services/AnalyticsService";

export default function ImageSearchInformationDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickConfirmButton = () => {
    AnalyticsService.pushEvent("Image Search Confirm Button Clicked");
    props.onConfirmTakeProductPhoto();
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.handleClose}
        PaperProps={{
          sx: {
            borderRadius: "32px",
            backgroundColor: "#FFF",
            boxShadow: "0px 12px 24px 0px #1c1c1c1a",
          },
        }}
      >
        <DialogContent
          sx={{
            backgroundSize: "100% auto",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: "24px",
          }}
        >
          <Typography sx={{ textAlign: "right" }}>
            <Close
              onClick={props.handleClose}
              sx={{ color: "#000", fontSize: "28px" }}
            />
          </Typography>

          <Typography
            sx={{ textAlign: "center", marginTop: "-24px", minHeight: "150px" }}
          >
            <img src={"/icons/mobilePhone.svg"} alt="" />
          </Typography>

          <Typography
            variant="h2"
            sx={{ textAlign: "center", fontSize: "18px", fontWeight: "700" }}
          >
            Take a photo to search products
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontSize: "14px", textAlign: "center", padding: "12px" }}
          >
            Please take a clear photo of one product only. Make sure the name or
            composition are easy to see.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleClickConfirmButton}
              variant="contained"
              sx={{
                borderRadius: "16px",
                color: "primary",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 32px",
              }}
            >
              Take Photo
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
