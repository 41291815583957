import { useEffect, useState } from "react";
import { useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Fab from "@mui/material/Fab";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AnalyticsService from "../../Services/AnalyticsService";
import { useNavigate, useLocation } from "react-router-dom";
import BrandsFilterList from "./BrandsFilterList";
import AndroidService from "../../Services/AndroidService";

const PRODUCT_FILTERS = [{ label: "Brand", identifier: "brand" }];

const useStyles = makeStyles((theme) => ({
  filterButton: {
    background: "rgba(157,33,35,0.8)",
    fontWeight: "700",
    fontSize: "14px",
    borderRadius: "57px",
    height: "45px",
    boxShadow: "0px 10px 10px rgba(157, 33, 35, 0.2)",
    backdropFilter: "blur(2px)",
    textTransform: "capitalize",
    padding: "0px 26px",
  },
  buttonImage: {
    height: "17px",
    marginRight: "9px",
  },
  scrollingFadedEffect: {
    background: "rgba(157,33,35,0.2)",
  },
}));

const ProductsFilter = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const filterButtonRef = useRef(null);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(
    PRODUCT_FILTERS[0]
  );
  const [brandsSelected, setBrandsSelected] = useState([
    ...props.selectedBrands,
  ]);
  let navigate = useNavigate();
  const { pathname } = useLocation();

  var timer = null;
  window.addEventListener(
    "scroll",
    function () {
      filterButtonRef?.current?.classList?.add(classes.scrollingFadedEffect);

      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        filterButtonRef?.current?.classList?.remove(
          classes.scrollingFadedEffect
        );
      }, 75);
    },
    false
  );

  useEffect(() => {
    AndroidService.setPullToRefresh();
  }, []);

  const handleClickOpenFilters = () => {
    AnalyticsService.pushEvent(`Filters Button Clicked`);
    setOpen(true);
    AndroidService.unsetPullToRefresh();
  };

  const handleClickCloseFilters = () => {
    // props.handleClickChooseBrands(props.selectedBrands);
    setBrandsSelected(props.selectedBrands);
    setOpen(false);
    AndroidService.setPullToRefresh();
  };

  const handleClickClearAllFilters = () => {
    AnalyticsService.pushEvent(`Clear All Filters Clicked`);
    // props.handleClickChooseBrands([]);
    setBrandsSelected([]);
  };

  const handleClickApplyFilter = () => {
    AnalyticsService.pushEvent(`Apply Filter Clicked`, {
      filterCategory: selectedFilterCategory.label || ``,
      selectedBrands:
        _.map(brandsSelected, (brand) => {
          return brand.brandName;
        }).join(",") || ``,
    });
    props.setSelectedBrands(brandsSelected);

    if (brandsSelected.length > 0) {
      navigate(
        `${pathname}?brandNames=${_.map(brandsSelected, (brandSelected) =>
          brandSelected?.brandName?.toLowerCase()
        ).join(",")}`
      );
    } else {
      navigate(pathname);
    }
  };

  const handleClickFilter = (selectedFilterCategory) => {
    setSelectedFilterCategory(selectedFilterCategory);
  };

  const handleClickBrand = (brand) => {
    AnalyticsService.pushEvent(`Brand Clicked`, {
      brandName: brand.brandName || ``,
      brandId: brand.brandId || ``,
    });
    if (!_.find(brandsSelected, brand)) {
      // props.handleClickChooseBrands([...brandsSelected, brand]);
      setBrandsSelected((currentlySelectedBrands) => [
        ...currentlySelectedBrands,
        brand,
      ]);
    } else {
      // props.handleClickChooseBrands(
      //   _.filter(brandsSelected, (selectedBrand) => {
      //     return selectedBrand.brandId !== brand.brandId;
      //   })
      // );

      setBrandsSelected((currentlySelectedBrands) => {
        return _.filter(currentlySelectedBrands, (selectedBrand) => {
          return selectedBrand.brandId !== brand.brandId;
        });
      });
    }
  };

  return (
    <>
      {props?.brands?.length > 0 && (
        <>
          {/* <Box
            sx={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              textAlign: "center",
              padding: "26px",
              zIndex: 99
            }}
          >
            <Fab
              onClick={handleClickOpenFilters}
              variant="extended"
              className={classes.filterButton}
              color="primary"
              ref={filterButtonRef}
            >
              <img src={`/icons/filter.svg`} className={classes.buttonImage} />
              {brandsSelected?.length > 0
                ? `Filter (${brandsSelected.length})`
                : `Filter`}
            </Fab>
          </Box> */}

          <div>
            <SwipeableDrawer
              anchor={`bottom`}
              open={open}
              onClose={handleClickCloseFilters}
              onOpen={handleClickOpenFilters}
              PaperProps={{
                style: {
                  borderRadius: "24px 24px 0px 0px",
                  overflowY: "hidden",
                },
              }}
            >
              <Box
                sx={{ height: "90vh" }}
                role="presentation"
                onKeyDown={handleClickCloseFilters}
              >
                <Box
                  sx={{
                    position: "sticky",
                    top: "0",
                    zIndex: 100,
                    background: "#FFFFFF",
                    paddingTop: "16px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        height: "6px",
                        width: "35px",
                        background: "rgba(0,0,0,0.1)",
                        borderRadius: "19%",
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems={`center`}
                    justifyContent={`space-between`}
                    sx={{ padding: "0px 20px 16px 20px" }}
                  >
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      Filter By
                    </Typography>
                    <CloseIcon
                      onClick={() => {
                        handleClickCloseFilters();
                        AnalyticsService.pushEvent(
                          `Close Filters Button Clicked`
                        );
                      }}
                      sx={{
                        color: "#999999",
                        fontSize: "28px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                  <Divider />
                </Box>

                <Grid container direction={`row`}>
                  <Grid item xs={3.4}>
                    <List sx={{ padding: 0 }}>
                      {PRODUCT_FILTERS.map((filter, index) => (
                        <ListItem
                          key={filter.identifier}
                          disablePadding
                          sx={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                          onClick={() => {
                            handleClickFilter(filter);
                          }}
                          style={{
                            color: `${
                              filter === selectedFilterCategory
                                ? `#9D2123`
                                : `#333333`
                            }`,
                          }}
                        >
                          <ListItemButton sx={{ padding: "0px 11px" }}>
                            <ListItemText
                              sx={{ margin: 0 }}
                              primary={
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    lineHeight: "40px",
                                  }}
                                >
                                  {filter.label}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid
                    item
                    xs={8.6}
                    sx={{
                      background: "#F3F3F3",
                      padding: "10px 0px 60px 0px",
                      height: "80vh",
                      overflowY: "scroll",
                    }}
                  >
                    {selectedFilterCategory?.identifier === "brand" && (
                      <BrandsFilterList
                        brands={props?.brands}
                        brandsSelected={brandsSelected}
                        handleClickBrand={handleClickBrand}
                      />
                    )}
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    position: "sticky",
                    bottom: "0",
                    width: "100%",
                    zIndex: 100,
                    background: "#FFFFFF",
                  }}
                >
                  <Divider />
                  <Grid
                    container
                    alignItems={`center`}
                    sx={{ padding: "8px 16px 8px 6px" }}
                  >
                    <Grid item xs={4} paddingLeft={`10px`}>
                      {/* {!props.errorProductsCount && props?.productsCount && (
                        <>
                          {props.loadingProductsCount === "done" ? (
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#333333",
                                fontSize: "14px",
                                lineHeight: "19px",
                                fontWeight: "700",
                                letterSpacing: "0.04em",
                              }}
                            >
                              {props?.productsCount ? props.productsCount : ``}
                            </Typography>
                          ) : (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress size={19} />
                            </Box>
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#333333",
                              fontSize: "12px",
                              lineHeight: "16px",
                              letterSpacing: "0.04em",
                            }}
                          >
                            Total Products
                          </Typography>
                        </>
                      )} */}
                    </Grid>

                    <Grid item xs={8}>
                      <Grid
                        container
                        spacing={1}
                        alignItems={`center`}
                        justifyContent={`center`}
                        textAlign={`right`}
                      >
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            fullWidth="true"
                            color="primary"
                            size={"medium"}
                            onClick={() => {
                              handleClickClearAllFilters();
                            }}
                            sx={{
                              fontWeight: 700,
                              letterSpacing: "0.04em",
                              padding: "4px 8px",
                              borderRadius: "7px",
                              boxShadow: "none",
                              border: "1px solid #9D2123",
                            }}
                          >
                            Clear All
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            fullWidth="true"
                            color="primary"
                            size={"medium"}
                            onClick={() => {
                              handleClickApplyFilter();
                            }}
                            sx={{
                              fontWeight: 700,
                              letterSpacing: "0.04em",
                              padding: "5px 16px",
                              borderRadius: "7px",
                              boxShadow: "none",
                            }}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </SwipeableDrawer>
          </div>
        </>
      )}
    </>
  );
};

export default ProductsFilter;
