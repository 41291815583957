import config from "../config";

import {
  InstituionMock,
  WalletBreakupMock,
  WalletTransactionsMock,
} from "./mocks/HisaabMock";
import HTTPService from "./HTTPService";

const useMocks = false;

export default class HisaabService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getInstitutionDetails(farmerId) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/institution/?farmerId=${
        farmerId ? farmerId : localStorage.getItem(`farmerId`)
      }`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        ...this.getHeaders(),
        source: "B2B",
      });
    } else {
      return new Promise((resolve) => {
        resolve({ data: InstituionMock });
      });
    }
  }

  static getWallettransactions(userId, fromDate, toDate, selectedReason) {
    let params = {
      userId,
    };

    if (selectedReason) {
      params.reason = selectedReason;
    }
    if (fromDate) {
      params.fromDate = fromDate;
    }
    if (toDate) {
      params.toDate = toDate;
    }

    if (useMocks === false) {
      const url = `${config.backendUrl}paymentapi/v1/creditwallettransaction/`;
      return HTTPService.makeXHRRequest(
        url,
        "GET",
        null,
        this.getHeaders(),
        params
      );
    } else {
      return new Promise((resolve) => {
        resolve({ data: WalletTransactionsMock });
      });
    }
  }

  static getWalletBreakup() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }crmservice/v1/walletbreakup/?cashDiscount=softupdate&farmerId=${localStorage.getItem(
        `farmerId`
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: WalletBreakupMock });
      });
    }
  }

  static downloadTransactions(
    fromDate,
    toDate,
    userId,
    partnerName,
    partnerMobileNum,
    partnerVanAccountNum,
    partnerGSTNum,
    partnerPanNumber,
    fileType
  ) {
    let sessionId = Math.floor(Math.random() * 1000);
    if (useMocks === false) {
      const url = `${config.backendUrl}paymentapi/v1/creditwallettransaction/download?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}&partnerName=${partnerName}&partnerMobileNum=${partnerMobileNum}&partnerVanAccountNum=${partnerVanAccountNum}&partnerGSTNum=${partnerGSTNum}&partnerPanNum=${partnerPanNumber}&sessionId=${sessionId}&fileType=${fileType}`;
      return HTTPService.makeXHRRequest(
        url,
        "GET",
        null,
        this.getHeaders(),
        "",
        "blob"
      );
    } else {
      return new Promise((resolve) => {
        resolve({ data: WalletTransactionsMock });
      });
    }
  }

  static getUserWalletDetails() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }crmservice/v1/farmerwallet/?farmerId=${localStorage.getItem(
        `farmerId`
      )}&history=true`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    }
  }

  static getCashDiscount({
    amount,
    orderId,
    paymentType,
    productSalesGroup,
    lendingPartner,
  }) {
    const userId = localStorage.getItem("userId");
    let url = `${config.backendUrl}onlineecommerceservice/v1/getcashdiscount/?userId=${userId}&amount=${amount}&paymentType=${paymentType}`;
    if (orderId) url += `&orderId=${orderId}`;
    if (productSalesGroup)
      url += `&productSalesGroup=${encodeURI(productSalesGroup)}`;
    url += `&lendingPartner=${encodeURI(lendingPartner ? lendingPartner : "AGROSTAR")}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static getWalletTransaction(endpoint, transactionType = "", offset = 0) {
    const userId = localStorage.getItem(`userId`);
    const url = `${config.backendUrl}paymentapi/v1/${endpoint}/?history=true&limit=10&offset=${offset}&userId=${userId}&transactionType=${transactionType}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }
}
