import { Alert, Snackbar } from "@mui/material";

function ProductRemovedToast(props) {
  return (
    <Snackbar
      open={props.show}
      autoHideDuration={3000}
      onClose={() => props.setShow(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ marginBottom: "46px" }}
    >
      <Alert
        sx={{
          background: "#666666",
          color: "#ffffff",
          borderRadius: "6px",
          textAlign: "center",
        }}
        icon={false}
        onClose={() => props.setShow(false)}
      >
        Product Removed
      </Alert>
    </Snackbar>
  );
}

export default ProductRemovedToast;
