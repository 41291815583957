import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";

import { Box, Card, Typography } from "@mui/material";
import AmountUtils from "../../Utils/AmountUtils";

const CREDIT_TYPE_TEXT = {
  AG_DEFAULT: "Default Limit",
  CREDIT_LINE_A_B: "Class A and B Limit",
  CREDIT_LINE_C_D: "Class C and D Limit",
  SAATHI_OVERALL_LIMIT: "Saathi Overall Limit",
};

export default function CreditLimitDetails({
  classes,
  creditDetails,
  loadingWalletBreakup,
}) {
  const [creditLines, setCreditLines] = useState(null);

  useEffect(() => {
    const creditLines = [];
    creditLines.push({
      name: CREDIT_TYPE_TEXT.SAATHI_OVERALL_LIMIT,
      available: creditDetails?.creditDetails?.totalCreditLimit,
      used: creditDetails?.creditDetails?.usedCreditLimit,
      usable: creditDetails?.creditDetails?.creditAvailable,
    });
    if (creditDetails?.creditDetails?.availableCreditLines?.length) {
      creditLines.push(...creditDetails?.creditDetails?.availableCreditLines);
    }
    setCreditLines(creditLines);
  }, [creditDetails]);

  return (
    <BlockUi
      tag="div"
      keepInView
      message={`Loading...`}
      renderChildren={false}
      style={{ minHeight: "130px" }}
      blocking={loadingWalletBreakup === "started"}
    >
      {creditLines?.length === 1 ||
      (creditLines?.length === 2 && creditLines[1]?.name === "AG_DEFAULT") ? (
        <CreditLine
          classes={classes}
          creditType={creditLines[0].name}
          totalCreditLimit={creditLines[0].available}
          usedCreditLimit={creditLines[0].used}
          creditAvailable={creditLines[0].usable}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {creditLines?.map((creditLine, index) => (
            <CreditLine
              classes={classes}
              key={index}
              creditType={creditLine?.name}
              totalCreditLimit={creditLine?.available}
              usedCreditLimit={creditLine?.used}
              creditAvailable={creditLine?.usable}
            />
          ))}
        </Box>
      )}
    </BlockUi>
  );
}

function CreditLine({
  classes,
  creditType,
  totalCreditLimit,
  usedCreditLimit,
  creditAvailable,
}) {
  return (
    <Card
      variant="outlined"
      sx={{ minWidth: "270px", margin: "12px 0 0 !important" }}
      className={classes.balanceCards}
    >
      <Typography
        variant={`body2`}
        className={classes.creditLineTitle}
        sx={{
          color:
            creditType === CREDIT_TYPE_TEXT.SAATHI_OVERALL_LIMIT
              ? "#00733E"
              : "",
          textTransform: "capitalize",
        }}
      >
        {CREDIT_TYPE_TEXT[creditType] || creditType?.toLowerCase()}
      </Typography>

      <div className={classes.creditSection}>
        <Typography variant={"subtitle"}>Total Credit Limit:</Typography>
        <Typography variant={"subtitle"}>
          {AmountUtils.formatCurrency(totalCreditLimit || 0)}
        </Typography>
      </div>
      <div className={classes.creditSection}>
        <Typography variant={`subtitle`}>Used Credit Limit:</Typography>
        <Typography variant={`subtitle`}>
          - {AmountUtils.formatCurrency(usedCreditLimit || 0)}
        </Typography>
      </div>

      <div
        className={classes.availableCredit}
        style={{
          background:
            creditType === CREDIT_TYPE_TEXT.SAATHI_OVERALL_LIMIT
              ? "#E5F1EC"
              : "#ebebeb",
        }}
      >
        <Typography variant={`subtitle`}>
          <b>Available Credit Limit:</b>
        </Typography>
        <Typography variant={`subtitle`}>
          <b>{AmountUtils.formatCurrency(creditAvailable || 0)}</b>
        </Typography>
      </div>
    </Card>
  );
}
