import React, { useEffect } from "react";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";

export default function FieldStar() {
  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, { page: "FieldStar" });
    AndroidService.unsetPullToRefresh();
    return () => {
      AndroidService.setPullToRefresh();
    };
  }, []);

  return <></>;
}
