import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { WhatsappShareButton } from "react-share";
import AnalyticsService from "../../Services/AnalyticsService";
import useMessages from "../../Hooks/useMessages";
import { useEffect, useState } from "react";
import AndroidService from "../../Services/AndroidService";

const useStyles = makeStyles((theme) => ({
  promoteButton: {
    marginTop: theme.spacing(1),
    position: "fixed",
    bottom: 0,
    borderRadius: "0",
    zIndex: 0,
    height: "48px",
  },
  iconSize: {
    height: "22px",
    marginTop: "-3px",
  },
}));

const PromoteRequest = (props) => {
  const classes = useStyles();
  const { getMessage } = useMessages();
  const [device, setDevice] = useState("web");
  let product = props.details;
  let shopData = props.shopData;
  let whatsappMessage =
    getMessage(`product.header`) +
    "\r\n\r\n*" +
    getMessage(`product.details`) +
    " :*" +
    (product?.brandName ? "\r\n" : "") +
    (product?.brandName ? "*" + product?.brandName + "*" : "") +
    (product?.localName ? "\r\n" : "") +
    (product?.localName ? "*" + product?.localName + "*" : "") +
    (product?.localComposition ? "\r\n" : "") +
    (product?.localComposition ? product?.localComposition : "") +
    (product?.testimonial
      ? "\r\n\r\n*" + getMessage(`product.testimonialVideo`) + " :*\r\n"
      : "") +
    (product?.testimonial ? "*" + product?.testimonial + "*" : "") +
    // (product.differentVariant ? ",\r\n" : "") +
    // product.differentVariant +
    "\r\n\r\n*" +
    getMessage(`product.address`) +
    "* :\r\n" +
    shopData?.name +
    "\r\n" +
    shopData?.contacts?.mobileNumber +
    "\r\n" +
    shopData?.address?.village +
    ", " +
    shopData?.address?.taluka +
    ", " +
    shopData?.address?.district +
    ", " +
    shopData?.address?.state +
    ", " +
    shopData?.address?.country +
    ", " +
    shopData?.address?.pincode;

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (appVersionCode && parseInt(appVersionCode) > 6) {
          setDevice("android");
        } else {
          setDevice("web");
        }
      } else {
        setDevice("web");
      }
    }
  }, []);

  const handleAndroidJSBridgeWhatsappShare = () => {
    try {
      /*global Android*/
      if (Android) {
        Android.shareOnWhatsApp(whatsappMessage, product?.image);
      }
    } catch (e) {
      console.log(e);
    }
    handlePromoteOnWhatsappClickEvent();
  };

  const handlePromoteOnWhatsappClickEvent = () => {
    const promoteOnWhatsAppClickEventData = {
      page: `/products/${product.skuCode}/promote`,
      skuCode: product.skuCode,
      productName: props.productName,
    };
    AnalyticsService.pushEvent(
      `Promote On Whatsapp Clicked`,
      promoteOnWhatsAppClickEventData
    );
  };

  return (
    <>
      {device === `web` ? (
        <WhatsappShareButton url={`.`} title={whatsappMessage}>
          <Button
            size={`large`}
            variant={"contained"}
            color={"primary"}
            fullWidth={true}
            type={`submit`}
            className={classes.promoteButton}
            onClick={handlePromoteOnWhatsappClickEvent}
            startIcon={
              <img
                src={`/icons/whatsapp.svg`}
                alt={`promote`}
                className={classes.iconSize}
              />
            }
          >
            Promote on Whatsapp
          </Button>
        </WhatsappShareButton>
      ) : (
        <div>
          <Button
            size={`large`}
            variant={"contained"}
            color={"primary"}
            fullWidth={true}
            type={`submit`}
            className={classes.promoteButton}
            onClick={handleAndroidJSBridgeWhatsappShare}
            startIcon={
              <img
                src={`/icons/whatsapp.svg`}
                alt={`promote`}
                className={classes.iconSize}
              />
            }
          >
            Promote on Whatsapp
          </Button>
        </div>
      )}
    </>
  );
};

export default PromoteRequest;
