import _ from "lodash";
import moment from "moment";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import { AVATAR_PALETTE } from "../../Utils/AvatarPalette";
import AnalyticsService from "../../Services/AnalyticsService";

export default function FarmerExperiencePopup({
  post,
  skuCode,
  testimonialIndex,
  showTestimonialPopup,
  setShowTestimonialPopup,
  setAutoPlayCarousel,
}) {
  const postDateTime = moment(post?.updated_client_epoch).format(
    "DD MMM YYYY, hh:MM A"
  );
  const postCity = post?.author?.location?.city
    ? `${post?.author?.location?.city}, `
    : "";
  const postState = post?.author?.location?.state || "";

  const cardMediaContent = _.find(
    post?.data,
    (element) => element?.type === "image"
  );
  const cardFooterContent =
    _.find(
      post?.data,
      (element) => element?.type === "text" || element?.type === "audio"
    ) || null;

  return (
    <Dialog
      open={showTestimonialPopup}
      fullWidth={true}
      maxWidth="xs"
      onClose={(e) => {
        if (e) {
          e?.stopPropagation();
        }
        AnalyticsService.pushEvent("Backdrop Clicked", {
          type: "Popup backdrop clicked",
          section: "Farmer's Experience",
          page: "Product Details",
          ElementId: post?._id,
          pageId: skuCode,
          position: testimonialIndex + 1,
        });
        setAutoPlayCarousel(true);
        setShowTestimonialPopup(false);
      }}
      sx={
        {
          //minHeight: "50vh",
          //minWidth: "75vw",
          //padding: "0px",
        }
      }
      PaperProps={{
        style: { borderRadius: "32px" },
      }}
    >
      <Card>
        <CardHeader
          sx={{ padding: "8px", paddingBottom: "0px" }}
          avatar={
            <Avatar
              sx={{
                width: 45,
                height: 45,
                bgcolor:
                  AVATAR_PALETTE[
                    post?.author?.name?.charCodeAt(0) % AVATAR_PALETTE.length
                  ],
              }}
              aria-label="Kisan"
            >
              {post?.author?.pic?.length > 0 ? (
                <img
                  height={45}
                  width={45}
                  src={post?.author?.pic}
                  alt={post?.author?.name}
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <Typography>
                  {post?.author?.name?.slice(0, 1).toUpperCase()}
                </Typography>
              )}
            </Avatar>
          }
          title={
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="column"
                sx={{ padding: "0px", paddingTop: "8px" }}
              >
                <Typography
                  variant="subtitle1"
                  component="h1"
                  sx={{ fontSize: 12, fontWeight: 700 }}
                >
                  {post?.author?.name}
                </Typography>
                <Typography
                  color="secondary.black"
                  variant="subtitle2"
                  component="p"
                  sx={{ fontWeight: 400, fontSize: 12 }}
                >
                  {`${postCity}${postState}`}
                </Typography>
                <Typography
                  color="secondary.grey"
                  variant="subtitle2"
                  component="p"
                  sx={{ fontWeight: 400, fontSize: 12 }}
                >
                  {postDateTime}
                </Typography>
              </Box>

              <IconButton
                aria-label="close"
                onClick={() => {
                  AnalyticsService.pushEvent("Button Clicked", {
                    type: "Popup close button clicked",
                    section: "Farmer's Experience",
                    page: "Product Details",
                    ElementId: post?._id,
                    pageId: skuCode,
                    position: testimonialIndex + 1,
                  });
                  setAutoPlayCarousel(true);
                  setShowTestimonialPopup(false);
                }}
                sx={{
                  //position: "absolute",
                  //right: "8px",
                  //top: "8px",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Cancel />
              </IconButton>
            </Box>
          }
        />

        <Box
          display="flex"
          gap="5px"
          overflow="scroll"
          padding="0px 8px 8px 0px"
          marginLeft="8px"
        >
          {_.map(post?.tags, (tag, tagIndex) => {
            return (
              <Chip
                variant="outlined"
                key={tagIndex}
                label={tag?.label}
                color="secondary"
                size="medium"
                sx={{
                  fontSize: 12,
                  fontWeight: 700,
                  padding: "8px 4px 6px 4px",
                }}
              />
            );
          })}
        </Box>
        <CardMedia
          component="img"
          image={cardMediaContent.value}
          height={255}
          alt=""
          sx={{ objectFit: "contain" }}
        />

        {cardFooterContent && (
          <CardContent
            sx={{
              padding: "16px",
              paddingTop: "8px",
            }}
          >
            <Typography
              variant="body2"
              component="p"
              sx={{
                fontSize: "12px",
                lineHeight: "18px",
              }}
            >
              {cardFooterContent?.value}
            </Typography>
          </CardContent>
        )}
      </Card>
    </Dialog>
  );
}
