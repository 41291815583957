import makeStyles from "@mui/styles/makeStyles";

import IframeContent from "../Common/IframeContent";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  containerIframe: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "110vh !important",
    paddingTop: "56.25%" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
  },
  /* Then style the iframe to fit in the container div with full height and width */
  responsiveIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "88vh",
    border: "0px",
  },
}));

const ReferralForm = () => {
  const classes = useStyles();
  const iframeData = {
    src: `https://docs.google.com/forms/d/e/1FAIpQLSfvIcIkq8aZlNlYw1PFm79bN8OCw0QnSbJUuD4ToobwmqK5Jg/viewform?embedded=true&entry.1824954328=${window?.location?.state?.mobile}&entry.491826009=${window?.location?.state?.partnerName}`,
    iframeClass: classes.responsiveIframe,
  };

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Refer & Earn`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
          toolTip={`Refer & Earn`}
        />
        <div className={classes.containerIframe}>
          <IframeContent iframeData={iframeData} />
        </div>
      </ErrorBoundary>
    </>
  );
};

export default ReferralForm;
