import React, { useState } from "react";
import moment from "moment";
import Clamp from "react-multiline-clamp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowForwardTwoTone, ExpandMore } from "@mui/icons-material";
import AmountUtils from "../../Utils/AmountUtils";
import AnalyticsService from "../../Services/AnalyticsService";
import { useNavigate } from "react-router-dom";
import StoreFrontService from "../../Services/StoreFrontService";

const useStyles = makeStyles({
  accordian: {
    margin: "0 0 8px !important",
    borderRadius: "10px !important",
    boxShadow: "0px 12px 24px rgba(28, 28, 28, 0.10)",
    "&::before": { display: "none" },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0 !important",
    },
  },
  accordianSummary: {
    padding: "12px 16px",
    alignItems: "flex-start",
    borderBottom: "1px solid #eee",
    "& .MuiAccordionSummary-content": {
      margin: 0,
      flexDirection: "column",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 !important",
    },
  },
  orderStatus: {
    position: "relative",
    fontSize: "12px",
    borderRadius: "5px",
    padding: "5px 7px",
    textTransform: "capitalize",
  },
  orderDetails: {
    paddingTop: "10px",
    "& .MuiTypography-subtitle2": { fontWeight: 400 },
    "& b": { fontSize: "16px", color: "#00733E" },
  },
  lineDivider: {
    position: "absolute",
    left: 0,
    bottom: "40px",
    borderTop: "1px solid #eee",
    width: "100%",
  },
  productWrapper: {
    padding: "8px 16px",
    marginBottom: "5px",
    fontSize: "12px",
    fontWeight: 400,
    background: "#cacaca1a",
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #CACACA",
  },
  productTotalAmount: {
    textAlign: "right",
    color: "#00733E",
    fontSize: "14px",
    fontWeight: 700,
  },
  lineDividerNew: {
    position: "absolute",
    left: 0,
    bottom: "9px",
    borderTop: "1px solid #eee",
    width: "100%",
  },
});

const orderLabel = {
  STORE_ORDER: {
    color: "#000000",
    bgColor: "#4F4F4F20",
    text: "Store Order",
    icon: (
      <img
        src="/icons/storeOrder.svg"
        alt=""
        width="16px"
        style={{ marginRight: "5px", position: "relative", top: "3px" }}
      />
    ),
  },
};

export default function StoreOrderCard({
  order,
  loading,
  setLoading,
  selectedOrderStatusFilter,
}) {
  const classes = useStyles();
  let navigate = useNavigate();
  const [orderAndFarmerDetails, setOrderAndFarmerDetails] = useState([]);

  const handleScanQRClicked = () => {
    navigate(
      `/scan-qr?cartId=${order?.cartId}&cartStatus=${order?.status}&timestamp=${order?.createdOn}&farmerName=${orderAndFarmerDetails?.farmerName}&agroStarPointsUsed=${orderAndFarmerDetails?.agroStarPointsUsed}`,
      { replace: true }
    );
  };

  const orderMappingElement =
    selectedOrderStatusFilter === "pending" ? orderAndFarmerDetails : order;

  const handleAccordionChange = (event, isExpanded) => {
    if (isExpanded && selectedOrderStatusFilter === "pending") {
      setLoading("started");
      const { axiosRequest, abortController } =
        StoreFrontService.getStoreOrderDetails(order?.cartId);
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            const responseData = response?.data?.responseData;
            setOrderAndFarmerDetails(responseData);
          }
          setLoading("done");
        })
        .catch((error) => {
          console.error("Error fetching order details:", error);
          setLoading("done");
        });
    }
  };

  return (
    <Accordion
      key={order?.orderId}
      className={classes.accordian}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordianSummary}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              {selectedOrderStatusFilter === "completed"
                ? `Order ID: ${order?.orderId}`
                : `Order:`}
            </Typography>
            <Typography variant="caption">
              {moment(order?.createdOn || order?.orderDate).format(
                "DD MMM YYYY"
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <span
              className={classes.orderStatus}
              style={{
                color: orderLabel["STORE_ORDER"]?.color,
                background: orderLabel["STORE_ORDER"]?.bgColor,
              }}
            >
              {orderLabel["STORE_ORDER"]?.icon}
              {orderLabel["STORE_ORDER"]?.text}
            </span>
          </Grid>
        </Grid>

        <div className={classes.orderDetails}>
          <Typography variant="subtitle2">
            Order Amount : <b>₹{AmountUtils.format(order?.codAmount)}</b>
          </Typography>
          <div className={classes.lineDivider}></div>
        </div>
      </AccordionSummary>

      {/* {loading === "done" && ( */}
      <AccordionDetails sx={{ padding: 0, paddingBottom: "3px" }}>
        <Grid
          container
          sx={{
            padding: "8px 16px",
            fontSize: "12px",
            background: "#00733E1F",
          }}
        >
          <Grid container sx={{ margin: "2px 0px" }}>
            <Grid item xs={8}>
              Total Amount
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right", fontSize: "14px" }}>
              {AmountUtils.formatCurrency(orderMappingElement?.grandTotal ?? 0)}
            </Grid>
          </Grid>
          <Grid container sx={{ margin: "2px 0px" }}>
            <Grid item xs={8}>
              Payable In AgroStar Points
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ textAlign: "right", color: "#9D2123", fontSize: "14px" }}
            >
              -{" "}
              {AmountUtils.formatCurrency(
                orderMappingElement?.agroStarPointsUsed ?? 0
              )}{" "}
            </Grid>
          </Grid>
          <Grid container sx={{ margin: "2px 0px", fontWeight: "700" }}>
            <Grid item xs={8}>
              Net Amount
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                textAlign: "right",
                color: "#00733E",
                fontSize: "14px",
              }}
            >
              {AmountUtils.formatCurrency(orderMappingElement?.codAmount ?? 0)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "8px 16px" }}>
          <Grid item sx={{ fontWeight: "700", fontSize: "12px" }}>
            Order Items
          </Grid>
        </Grid>
        {orderMappingElement?.orderItems?.map((product, index) => (
          <Grid container key={index} className={classes.productWrapper}>
            <Grid item xs={2}>
              <div className={classes.imageWrapper}>
                <img
                  src={product?.productImage || "/icons/dummy.jpeg"}
                  alt="product"
                  width="100%"
                  style={{ borderRadius: "4px" }}
                />
              </div>
            </Grid>
            <Grid item xs={10} container sx={{ pl: 1 }}>
              <Grid item xs={8}>
                <Clamp lines={2} maxLines={2}>
                  <span>{product?.productName}</span>
                </Clamp>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                Qty: <b>{product?.quantity}</b>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  textAlign: "right",
                  color: "#00733E",
                }}
              >
                {AmountUtils.formatCurrency(product?.totalPrice)}
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid container sx={{ padding: "8px 16px" }}>
          {(orderMappingElement?.farmerName || order?.farmerName) && (
            <>
              <Grid item xs={6} sx={{ marginTop: "6px" }}>
                <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                  Farmer Name
                </Typography>
                <Clamp lines={1} maxLines={1}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={700}
                    sx={{ fontSize: "12px" }}
                  >
                    {orderMappingElement?.farmerName || order?.farmerName}
                  </Typography>
                </Clamp>
              </Grid>
              {orderMappingElement?.availableAgroStarPoints >= 0 && (
                <Grid item xs={6} sx={{ marginTop: "6px" }}>
                  <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                    Available AgroStar Points
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                    }}
                  >
                    <img src={"/icons/points.svg"} width="16px" />
                    <Typography
                      variant="subtitle2"
                      fontWeight={700}
                      sx={{
                        fontSize: "12px",
                        alignItems: "center",
                        marginLeft: "4px",
                      }}
                    >
                      {orderMappingElement?.availableAgroStarPoints}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>

        {selectedOrderStatusFilter === "pending" && (
          <Grid
            container
            spacing={0}
            alignItems={`center`}
            justifyContent={`center`}
            textAlign={`right`}
            sx={{ borderTop: "1px solid #eeee" }}
          >
            <Button
              fullWidth
              color="primary"
              size={"medium"}
              endIcon={<ArrowForwardTwoTone />}
              onClick={() => {
                AnalyticsService.pushEvent("Button Clicked", {
                  type: "Generate QR",
                });
                handleScanQRClicked();
              }}
            >
              Generate QR Code
            </Button>
          </Grid>
        )}
      </AccordionDetails>
      {/* )} */}
    </Accordion>
  );
}
