import { Box, Grid, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";
import FarmersService from "../../Services/FarmersService";

const useStyles = makeStyles((theme) => ({
  myFarmersWrapper: {
    padding: theme.spacing(0.77, 0),
    textAlign: "center",
    borderRadius: "12px",
    background:
      "url(/icons/starBackground.svg), linear-gradient(15.14deg, #535353 0%, #909090 158.4%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    cursor: "pointer",
  },
  myFarmers: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#FFFFFF",
    lineHeight: theme.spacing(2.75),
  },
  primaryText: {
    padding: theme.spacing(1, 0),
  },
  subText: {
    fontSize: theme.spacing(1.75),
    fontWeight: "400",
    color: "#FFFFFF",
    lineHeight: theme.spacing(2),
  },
  myFarmersTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MyFarmers = (props) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const handleMyFarmersClick = () => {
    navigate(`/my-farmers`);
    AnalyticsService.pushEvent(`My Farmers Clicked`, {
      page: `/profile`,
    });
  };

  return (
    <>
      <Grid
        container
        direction={`row`}
        justifyContent="center"
        alignItems="center"
        spacing={0}
        className={classes.myFarmersWrapper}
        onClick={handleMyFarmersClick}
      >
        <Grid item xs={12}>
          <Box className={classes.myFarmersTitle}>
            <Typography
              variant="span"
              className={`${classes.myFarmers} ${classes.primaryText}`}
            >{`My Farmers`}</Typography>
            <img
              src="/icons/rightArrow.svg"
              alt=""
              style={{ paddingLeft: "5px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MyFarmers;
