import { Box, Skeleton, Typography } from "@mui/material";
import PartnerService from "../../Services/PartnerService";

const PendingApprovalOrdersNudge = ({ ordersCount, loading }) => {
  return !PartnerService.checkIfFieldAgent() ? (
    loading !== "done" ? (
      <Skeleton variant={`rectangular`} height={52} />
    ) : (
      ordersCount > 0 && (
        <Box
          bgcolor={"#F8D149"}
          sx={{
            minHeight: "50px",
            padding: "8px 16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography color={"primary"} fontSize={"12px"} fontWeight={"bold"}>
            {ordersCount === 1
              ? `1 order is pending for approval`
              : `${ordersCount} orders are pending for approval`}
          </Typography>
        </Box>
      )
    )
  ) : (
    <></>
  );
};

export default PendingApprovalOrdersNudge;
