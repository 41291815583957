import { Grid, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  myFarmersWrapper: {
    padding: theme.spacing(1.25, 4.5),
    textAlign: "center",
    borderRadius: "12px",
    background:
      "url(/icons/starBackground.svg), linear-gradient(15.14deg, #535353 0%, #909090 158.4%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  myFarmers: {
    fontSize: theme.spacing(2),
    fontWeight: "700",
    color: "#FFFFFF",
    lineHeight: theme.spacing(2.75),
  },
  primaryText: {
    padding: theme.spacing(1, 0),
  },
  subText: {
    fontSize: theme.spacing(1.75),
    fontWeight: "400",
    color: "#FFFFFF",
    lineHeight: theme.spacing(2),
  },
}));

const MyFarmersSummary = (props) => {
  const classes = useStyles();

  return (
    <>
      {props?.loadingFarmersSummary === "started" && (
        <Skeleton variant={`rectangular`} height={58} />
      )}

      {props?.loadingFarmersSummary === "done" &&
        (props?.farmersSummary?.totalFarmers >= 0 &&
        props?.farmersSummary?.totalScans >= 0 ? (
          <Grid
            container
            direction={`row`}
            justifyContent="center"
            alignItems="center"
            spacing={0}
            className={classes.myFarmersWrapper}
            onClick={() => {}}
          >
            <Grid item xs={6}>
              <Typography className={classes.myFarmers}>
                {props.farmersSummary.totalFarmers}
              </Typography>
              <Typography
                className={classes.subText}
              >{`Total Farmers`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.myFarmers}>
                {props.farmersSummary.totalScans}
              </Typography>
              <Typography
                className={classes.subText}
              >{`Total Scans`}</Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        ))}
    </>
  );
};

export default MyFarmersSummary;
