import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BlockUi from "react-block-ui";

import { ArrowRightAlt, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  Radio,
  SwipeableDrawer,
  Typography,
} from "@mui/material";

import useFocus from "../../Hooks/useFocus";
import AmountUtils from "../../Utils/AmountUtils";
import { BillDetailPriceItem } from "../Cart/BillDetails";
import PaymentService from "../../Services/PaymentService";
import AnalyticsService from "../../Services/AnalyticsService";

export default function SettleNowDialog(props) {
  const navigate = useNavigate();
  const [inputRef, setInputFocus] = useFocus();
  const defaultPaymentAmountValue = props.paymentAmount;
  const [loading, setLoading] = useState("not-started");
  const [otherPaymentAmountValue, setOtherPaymentAmountValue] = useState(0);
  const [settlementAmountType, setSettlementAmountType] =
    useState("defaultAmount");

  useEffect(() => {
    if (props.open && props.paymentAmount === 0) {
      setSettlementAmountType("otherAmount");
    }
  }, [props.paymentAmount, props.open]);

  useEffect(() => {
    if (settlementAmountType === "otherAmount") {
      AnalyticsService.pushEvent("Amount Selected", {
        type: "Other Amount Selected",
        amount: props.paymentAmount,
        settlementType: props?.settlementType,
      });
    }
  }, [props?.open, settlementAmountType]);

  const handleChange = (valueType) => {
    if (valueType === "otherAmount") {
      setInputFocus();
      if (otherPaymentAmountValue === 0) {
        setOtherPaymentAmountValue("");
      }
    } else {
      AnalyticsService.pushEvent("Amount Selected", {
        type: "Default Amount Selected",
        amount: props.paymentAmount,
        settlementType: props?.settlementType,
      });
    }
    setSettlementAmountType(valueType);
  };

  const handleChangeOtherPaymentAmount = (event) => {
    setOtherPaymentAmountValue(event.target.value);
    if (
      settlementAmountType === "otherAmount" &&
      event?.target?.value > defaultPaymentAmountValue
    ) {
      event.target.setCustomValidity(
        "Amount cannot be greater than full amount"
      );
    } else {
      event.target.setCustomValidity("");
    }
  };

  const handleDrawerClose = () => {
    AnalyticsService.pushEvent("Dialog Closed", {
      type: "Settle Now",
      amount: props.paymentAmount,
      settlementType: props?.settlementType,
    });
    setOtherPaymentAmountValue(0);
    setSettlementAmountType("defaultAmount");
    props.handleClose();
  };

  const handleProceedPaymentPayNow = (event) => {
    event.preventDefault();

    let amountToPay = 0;
    if (props?.settlementType === "advancebookingscheme") {
      if (settlementAmountType === "defaultAmount") {
        amountToPay = parseInt(defaultPaymentAmountValue);
      } else if (settlementAmountType === "otherAmount") {
        amountToPay = parseInt(otherPaymentAmountValue);
      }
    } else {
      amountToPay = props?.settlementDetails?.totalNetPayable;
    }

    const eventProperties = {
      type: "Proceed To Settle",
      amount: amountToPay,
      settlementType: props?.settlementType,
    };
    if (props?.settlementType !== "advancebookingscheme") {
      eventProperties.totalAmountTosettle =
        props?.settlementDetails?.totalAmountTosettle;
      eventProperties.totalCashDiscount =
        props?.settlementDetails?.totalCashDiscount;
      eventProperties.totalNetPayable =
        props?.settlementDetails?.totalNetPayable;
      eventProperties.transactionReferenceNumber =
        props?.settlementDetails?.transactionReferenceNumber;
    }
    AnalyticsService.pushEvent("Button Clicked", eventProperties);

    setLoading("started");
    const { axiosRequest } = PaymentService.settleInvoices(
      props?.settlementDetails?.transactionReferenceNumber
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          navigate(
            `/hisaab/payment-settled-success?paidAmount=${props?.settlementDetails?.totalNetPayable}&settlementType=${props?.settlementType}`
          );
        }
        setLoading("done");
      })
      .catch((error) => {
        setLoading("done");
        console.error("Error Settling Invoice: ", error?.message);
      });
  };

  return (
    <>
      <SwipeableDrawer
        anchor={`bottom`}
        onOpen={() => {}}
        open={props.open}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          },
        }}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent={`flex-start`}
          sx={{ marginTop: "20px" }}
        >
          <Grid item xs={5} />
          <Grid item xs={1}>
            <Box
              sx={{
                border: "3px solid #DDDDDD",
                width: "43px",
                height: "0px",
                borderRadius: "5px",
              }}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={1}>
            <IconButton sx={{ marginTop: "-16px" }} onClick={handleDrawerClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <form onSubmit={handleProceedPaymentPayNow}>
          <BlockUi
            tag="div"
            keepInView
            message={`Please wait...`}
            blocking={loading === "started"}
            style={{ minHeight: "320px" }}
          >
            {props?.settlementType === "advancebookingscheme" && (
              <FormGroup>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ padding: "0 20px" }}
                >
                  <Grid item xs={5}>
                    <Typography variant={`body1`} sx={{ fontSize: "15px" }}>
                      Settle Full Amount
                    </Typography>

                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#EEEEEE",
                        borderRadius: "8px",
                        marginTop: "6px",
                      }}
                      onClick={() => handleChange("defaultAmount")}
                      value={"defaultAmount"}
                    >
                      <FormControlLabel
                        sx={{ marginLeft: "1px" }}
                        label={AmountUtils.formatCurrency(
                          defaultPaymentAmountValue
                        )}
                        control={
                          <>
                            <Radio
                              checked={settlementAmountType === "defaultAmount"}
                            />
                          </>
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={`body1`} sx={{ fontSize: "15px" }}>
                      Settle Other Amount
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#EEEEEE",
                        borderRadius: "8px",
                        marginTop: "6px",
                      }}
                      value={"otherAmount"}
                      onClick={() => {
                        handleChange("otherAmount");
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        sx={{ marginLeft: "10px" }}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={settlementAmountType === "otherAmount"}
                            />
                          }
                          label={
                            <Input
                              autoFocus={settlementAmountType === "otherAmount"}
                              required={settlementAmountType === "otherAmount"}
                              onChange={handleChangeOtherPaymentAmount}
                              inputRef={inputRef}
                              id="input-with-icon-adornment"
                              value={otherPaymentAmountValue}
                              onFocus={() => {
                                setSettlementAmountType("otherAmount");
                              }}
                              inputProps={{
                                type: "number",
                                min:
                                  settlementAmountType === "otherAmount"
                                    ? 1
                                    : 0,
                              }}
                              sx={{
                                "& .MuiInputBase-input": {
                                  minWidth: "70px !important",
                                },
                              }}
                              startAdornment={<Typography>₹ </Typography>}
                            />
                          }
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </FormGroup>
            )}

            {props?.settlementType !== "advancebookingscheme" && (
              <Grid
                container
                justifyContent="center"
                sx={{
                  marginTop: "20px",
                  padding: "30px 0 40px",
                  background: "#EAE2C7",
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: "0",
                    left: "2.5%",
                    width: "94%",
                    background:
                      "radial-gradient(ellipse at center, #FFF 0%, #FFF 40%, transparent 8%)",
                    backgroundSize: "calc(94% / 12) 100%",
                    backgroundRepeat: "repeat-x",
                    transform: "translate(0, -50%)",
                    aspectRatio: 12,
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: "0",
                    left: "2.5%",
                    width: "94%",
                    background:
                      "radial-gradient(ellipse at center, #FFF 0%, #FFF 40%, transparent 8%)",
                    backgroundSize: "calc(94% / 12) 100%",
                    backgroundRepeat: "repeat-x",
                    transform: "translate(0, 50%)",
                    aspectRatio: 12,
                  },
                }}
              >
                <Grid item xs={12} container sx={{ padding: "6px 16px 10px" }}>
                  <Grid item xs={4} sx={{ fontSize: "14px", fontWeight: 700 }}>
                    {props?.settlementType === "product"
                      ? "Products"
                      : "Order Id"}
                  </Grid>
                  <Grid item xs={3} sx={{ fontSize: "14px", fontWeight: 700 }}>
                    Payable Amount
                  </Grid>
                  <Grid item xs={2} sx={{ fontSize: "14px", fontWeight: 700 }}>
                    CD
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      textAlign: "right",
                    }}
                  >
                    Settlement Amount
                  </Grid>
                </Grid>
                {props?.settlementDetails &&
                  props?.settlementDetails?.amountBreakup?.map(
                    (item, index) => (
                      <Grid
                        item
                        xs={12}
                        container
                        key={index}
                        sx={{
                          backgroundColor: "#f5f1e3",
                          marginBottom: "2px",
                          padding: "6px 16px 10px",
                        }}
                      >
                        <Grid item xs={4} sx={{ fontSize: "13px" }}>
                          {props?.settlementType === "product"
                            ? item?.productSalesGroup
                            : item?.orderId}
                        </Grid>
                        <Grid item xs={3} sx={{ fontSize: "13px" }}>
                          {AmountUtils.formatCurrency(item?.netPayable)}
                        </Grid>

                        <Grid item xs={2} sx={{ fontSize: "13px" }}>
                          {AmountUtils.formatCurrency(item?.cashDiscount)}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ fontSize: "13px", textAlign: "right" }}
                        >
                          {AmountUtils.formatCurrency(item?.amountTosettle)}
                        </Grid>
                      </Grid>
                    )
                  )}

                <Grid item xs={12} marginTop={2}>
                  <BillDetailPriceItem
                    title="Settlement Amount"
                    value={AmountUtils.formatCurrency(
                      props?.settlementDetails?.totalAmountTosettle || 0
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BillDetailPriceItem
                    title="Cash Discount"
                    value={
                      <span style={{ color: "#00733E" }}>
                        {AmountUtils.formatCurrency(
                          props?.settlementDetails?.totalCashDiscount || 0
                        )}
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <BillDetailPriceItem
                    title={<b>Payable Amount</b>}
                    value={
                      <b>
                        {AmountUtils.formatCurrency(
                          props?.settlementDetails?.totalNetPayable || 0
                        )}
                      </b>
                    }
                  />
                </Grid>
              </Grid>
            )}
          </BlockUi>

          <Button
            fullWidth
            size="large"
            type={"submit"}
            variant={`contained`}
            sx={{
              height: "45px",
              marginTop: "25px",
              borderRadius: "0",
              fontSize: "14px",
            }}
            disabled={
              (props.paymentAmount === 0 &&
                settlementAmountType === "defaultAmount") ||
              loading === "started"
            }
            endIcon={<ArrowRightAlt />}
          >
            Proceed To Settle
          </Button>
        </form>
      </SwipeableDrawer>
    </>
  );
}
