import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "38px",
    lineHeight: "52px",
    color: "#000000",
    opacity: "0.1",
  },
}));
export default function FooterImageLogo() {
  const classes = useStyles();
  return (
    <Container maxWidth={`xl`}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        sx={{ mt: 1, mb: 8 }}
      >
        <Grid item xs={6}>
          <Typography className={classes.title}>Let's Grow Together</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
          <Box sx={{ mt: 1.3 }}>
            <img className="mt4" src="/icons/Footer.svg" />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
