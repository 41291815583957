import React from "react";
import HorizontalProductCardContainer from "../Common/HorizontalProductCardContainer";
import useRecentlyViewedProducts from "../../Hooks/useRecentlyViewedProducts";

function RecentlyViewedProducts(props) {
  const { products, loading, error } = useRecentlyViewedProducts();

  return (
    <HorizontalProductCardContainer
      products={products}
      loading={loading}
      error={error}
      title={`Recently Viewed Products`}
      viewAllUrl={`/recently-viewed-products`}
      numberOfProductsToShow={props.numberOfProductsToShow}
      stateCode={props.stateCode}
      backgroundColor={"rgba(0, 115, 62, 0.1)"}
      saveScrollYPosition={true}
    />
  );
}

export default RecentlyViewedProducts;
