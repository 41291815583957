import * as React from "react";
import { Box } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";
import useMessages from "../../Hooks/useMessages";
import AndroidService from "../../Services/AndroidService";

const StoryShareButtonNew = ({ story, action }) => {
  const { getMessage } = useMessages();
  const [device, setDevice] = useState("web");
  const [pauseStory, shouldPauseStory] = useState(false);
  const params = useParams();
  const whatsappMessage =
    getMessage("story.share") +
    (story?.type === "video" ? "\r\n" + story?.url : "");
  const image = story?.type === "image" ? story?.url : "";

  useEffect(() => {
    if (params.storyId === story.storyParentCode) {
      action(pauseStory ? "pause" : "play");
    }
  }, [action, pauseStory]);

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (appVersionCode && parseInt(appVersionCode) > 6) {
          setDevice("android");
        } else {
          setDevice("web");
        }
      } else {
        setDevice("web");
      }
    }
  }, []);

  const handleAndroidJSBridgeWhatsappShare = () => {
    shouldPauseStory(true);
    handlePromoteOnWhatsappClickEvent();
    try {
      /*global Android*/
      if (Android) {
        Android.shareOnWhatsApp(whatsappMessage, image);
      }
      shouldPauseStory(false);
    } catch (e) {
      console.log(e);
      shouldPauseStory(false);
    }
  };

  const handlePromoteOnWhatsappClickEvent = () => {
    const staticBannerClickedEventData = {
      section: story.section,
      name: story.name,
      imageUrl: story.imageUrl,
      position: story.position,
      subPosition: story.subPosition,
    };
    AnalyticsService.pushEvent(
      `Share Button Clicked`,
      staticBannerClickedEventData
    );
  };

  if (device === `web` || story?.type === "quiz") {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        bottom: 15,
        right: 7,
      }}
      onClick={handleAndroidJSBridgeWhatsappShare}
    >
      <img
        alt="Share"
        src="/icons/videoShareIcon.svg"
        style={{ height: "55px" }}
      />
    </Box>
  );
};

export default StoryShareButtonNew;
