import { QRCode } from "react-qrcode-logo";

const GenerateQRCode = ({ qrData }) => {
  const data = qrData;
  return (
    <>
      <QRCode
        value={data}
        enableCORS={true}
        size={175}
        logoPaddingStyle={"square"}
      />
    </>
  );
};

export default GenerateQRCode;
