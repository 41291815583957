import { useEffect, useState } from "react";
import PartnersSearch from "./PartnersSearch";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";
import PartnersList from "./PartnersList";
import SingleSelectFilter from "../Common/SingleSelectFilter";
import { Grid, Stack, Typography } from "@mui/material";

const ONBOARDING_LEADS_FILTERS = [
  {
    filterTitle: "Lead Status",
    filterOptions: [],
    filterCategory: "Zoho Status Filter",
    filterAPIKey: "zohoStage",
  },
  {
    filterTitle: "First Order Status",
    filterOptions: ["Pending", "Done"],
    filterCategory: "First Order Status Filter",
    filterAPIKey: "firstOrderStatus",
  },
  {
    filterTitle: "Cheque Status",
    filterOptions: ["Pending", "Recieved"],
    filterCategory: "Cheque Status Filter",
    filterAPIKey: "chequeStatus",
  },
  {
    filterTitle: "Territory",
    filterOptions: [],
    filterCategory: "Territory Filter",
    filterAPIKey: "territory",
  },
  {
    filterTitle: "District",
    filterOptions: [],
    filterCategory: "District Filter",
    filterAPIKey: "district",
  },
];

const OnboardingLeads = ({ authorized }) => {
  const [onboardingLeads, setOnboardingLeads] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [loading, setLoading] = useState("not-started");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalOnboardingLeads, setTotalOnboardingLeads] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(null);

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, {
      page: "My Partners",
      tab: "Onboarding Leads",
    });
  }, []);

  useEffect(() => {
    const districtCB = getLeadsDropDown({ district: true });
    const territoryCB = getLeadsDropDown({ territory: true });
    const zohoCB = getLeadsDropDown({ zohostatus: true });

    return () => {
      zohoCB();
      districtCB();
      territoryCB();
    };
  }, []);

  useEffect(() => {
    setLoading("started");
    const { axiosRequest, abortController } = PartnerService.getOnboardingLeads(
      localStorage.getItem("fieldAgentMobileNumber"),
      0,
      searchKeyword,
      selectedStatus,
      getfilterAPIKey(selectedFilterCategory)
    );
    axiosRequest
      .then((response) => {
        let leadsResponse = response?.data?.responseData;
        setOnboardingLeads(leadsResponse?.partnerList);
        setTotalOnboardingLeads(leadsResponse?.totalPartners);
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, [selectedStatus, searchKeyword, selectedFilterCategory]);

  const fetchMoreOnboardingLeads = () => {
    const { axiosRequest } = PartnerService.getOnboardingLeads(
      localStorage.getItem("fieldAgentMobileNumber"),
      currentPage + 1,
      searchKeyword,
      selectedStatus,
      getfilterAPIKey(selectedFilterCategory)
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.partnerList) {
          let newPartners = response?.data?.responseData?.partnerList;
          setOnboardingLeads((previousPartners) => [
            ...previousPartners,
            ...newPartners,
          ]);
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting partners`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
        }
      });
    AnalyticsService.pushEvent("Partners List Next Page Loaded", {
      pageNumber: currentPage + 1,
      tab: "Onboarding Leads",
    });
    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });
  };

  const getLeadsDropDown = ({ district, territory, zohostatus }) => {
    setLoading("started");
    const { axiosRequest, abortController } = PartnerService.getLeadsDropDown({
      district,
      territory,
      zohostatus,
    });
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.districts?.length > 0) {
          const districtFilter = ONBOARDING_LEADS_FILTERS.find(
            (filter) => filter.filterCategory === "District Filter"
          );
          districtFilter.filterOptions = response?.data?.responseData.districts;
        }
        if (response?.data?.responseData?.territories?.length > 0) {
          const territoriesFilter = ONBOARDING_LEADS_FILTERS.find(
            (filter) => filter.filterCategory === "Territory Filter"
          );
          territoriesFilter.filterOptions =
            response?.data?.responseData.territories;
        }
        if (response?.data?.responseData?.zohoStatus?.length > 0) {
          const zohoFilter = ONBOARDING_LEADS_FILTERS.find(
            (filter) => filter.filterCategory === "Zoho Status Filter"
          );
          zohoFilter.filterOptions = response?.data?.responseData.zohoStatus;
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
        }
      });
    return () => abortController.abort();
  };

  const clearSearch = () => {
    setSearchKeyword("");
    AnalyticsService.pushEvent("Partners Search Clear Clicked");
  };

  const handleSearch = (keyword) => {
    if (keyword) {
      setSearchKeyword(keyword);
    } else {
      clearSearch();
    }
  };

  const handleClearAllFilters = () => {
    AnalyticsService.pushEvent(`Button Clicked`, {
      type: "Clear All Filters",
      page: "My Partners",
      tab: "Onboarding Leads",
    });
    setSelectedStatus("All");
    setClearAllFilters(true);
    setSearchKeyword("");
    setCurrentPage(0);
    setSelectedFilterCategory(null);
  };

  const getfilterAPIKey = (selectedFilterCategory) => {
    const selectedFilter = ONBOARDING_LEADS_FILTERS.find(
      (filter) => filter.filterCategory === selectedFilterCategory
    );

    return selectedFilter ? selectedFilter.filterAPIKey : null;
  };

  return (
    <>
      <PartnersSearch
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        searchKeyword={searchKeyword}
        placeholderText={"Search Leads"}
        page={"My Partners"}
        tab={"Onboarding Leads"}
      />

      <Stack
        direction="row"
        spacing={1}
        sx={{
          margin: "4px 0px",
          overflowX: "auto",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {ONBOARDING_LEADS_FILTERS.map((filter, index) => (
          <SingleSelectFilter
            key={index}
            filter={filter}
            page={"My Partners"}
            setCurrentPage={setCurrentPage}
            selectedFilter={selectedStatus}
            setSelectedFilter={setSelectedStatus}
            clearAllFilters={clearAllFilters}
            selectedFilterCategory={selectedFilterCategory}
            setSelectedFilterCategory={setSelectedFilterCategory}
          />
        ))}
      </Stack>

      <Grid
        container
        direction={"row"}
        sx={{ marginTop: "12px", padding: "0px 5px" }}
      >
        <Grid item xs={9}>
          <Typography
            variant={`body2`}
            sx={{
              color: "#33333380",
              fontWeight: "700",
              fontSize: "12px",
            }}
          >
            {totalOnboardingLeads} Total Leads
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: "end", marginRight: "-10px" }}>
          <Typography
            sx={{
              color: "#33333380",
              fontWeight: "700",
              fontSize: "12px",
            }}
            onClick={handleClearAllFilters}
          >
            Clear All
          </Typography>
        </Grid>
      </Grid>

      {authorized && (
        <>
          <PartnersList
            fetchMorePartners={fetchMoreOnboardingLeads}
            totalPartners={totalOnboardingLeads}
            partners={onboardingLeads}
            loading={loading}
            showZohoStatus={true}
            noResultsText={"No Leads Found"}
          />
        </>
      )}
    </>
  );
};

export default OnboardingLeads;
