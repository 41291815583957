import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Link } from "react-router-dom";

import { Box, Button, Grid, Typography } from "@mui/material";
import { ArrowForwardTwoTone, WarningRounded } from "@mui/icons-material";

import CatalogError from "./CatalogError";
import EmptyError from "../EmptyError/EmptyError";
import AnalyticsService from "../../Services/AnalyticsService";
import StoreFrontService from "../../Services/StoreFrontService";
import CatalogItemPublishedDialog from "./CatalogItemPublishedDialog";
import CatalogItemUnpublishedDialog from "./CatalogItemUnpublishedDialog";
import CatalogRecommendedProductCard from "./CatalogRecommendedProductCard";
import CatalogItemUnpublishConfirmationDialog from "./CatalogItemUnpublishConfirmationDialog";
import AddProductsToCatalogCard from "./AddProductsToCatalogCard";
import UpdateProductInventoryDrawer from "./UpdateProductInventoryDrawer";
import CatalogItemInventoryUpdatedDialog from "./CatalogItemInventoryUpdatedDialog";

export default function StoreInventory({ showMyInventoryOnly }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState(null);
  const [catalogDetails, setCatalogDetails] = useState(null);
  const [skuCodeToUpdate, setSkuCodeToUpdate] = useState(null);
  const [catalogRecommendations, setCatalogRecommendations] = useState(null);
  const [addToCatalogProducts, setAddToCatalogProducts] = useState(null);
  const [loadingUnpublish, setLoadingUnpublish] = useState("not-started");
  const [loadingCatalog, setLoadingCatalog] = useState("not-started");
  const [loadingAddToCatalogProducts, setLoadingAddToCatalogProducts] =
    useState("not-started");
  const [loadingCatalogRecommendations, setLoadingCatalogRecommendations] =
    useState("not-started");
  const [showCatalogErrorDialog, setShowCatalogErrorDialog] = useState(false);
  const [showCatalogItemPublishedDialog, setShowCatalogItemPublishedDialog] =
    useState(false);
  const [
    showCatalogItemInventoryUpdatedDialog,
    setShowCatalogItemInventoryUpdatedDialog,
  ] = useState(false);
  const [
    showCatalogItemUnpublishConfirmationDialog,
    setShowCatalogItemUnpublishConfirmationDialog,
  ] = useState(false);
  const [showCatalogItemUnpublishedDialog, setShowCatalogItemUnblishedDialog] =
    useState(false);
  const [isInventoryUpdated, setIsInventoryUpdated] = useState(false);

  useEffect(() => {
    const catalogCB = getCatalog();
    const addToCatalogCB = getAddToCatalogProducts();
    const catalogRecommendationCB = getCatalogRecommendations();
    return () => {
      catalogCB();
      addToCatalogCB();
      catalogRecommendationCB();
    };
  }, []);

  const getCatalog = () => {
    setLoadingCatalog("started");
    const { axiosRequest, abortController } =
      StoreFrontService.getStoreFrontCatalog();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.totalCount) {
          let totalQuantity = 0;
          response.data.responseData.productGists.forEach((product) => {
            totalQuantity += product?.quantity;
            if (product?.isEnabled) {
              setIsInventoryUpdated(true);
            }
          });
          setCatalogDetails({
            totalQuantity,
            totalCount: response.data.responseData.totalCount,
          });
        }
        setLoadingCatalog("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoadingCatalog("done");
          console.error(`Error Getting Catalog: `, error);
        }
      });
    return () => abortController.abort();
  };

  const getAddToCatalogProducts = () => {
    setLoadingAddToCatalogProducts("started");
    const { axiosRequest, abortController } =
      StoreFrontService.getCatalogNPLProducts();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setAddToCatalogProducts(response.data.responseData);
        }
        setLoadingAddToCatalogProducts("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoadingAddToCatalogProducts("done");
          console.error(`Error Getting Add To Catalog Products: `, error);
        }
      });
    return () => abortController.abort();
  };

  const getCatalogRecommendations = () => {
    setLoadingCatalogRecommendations("started");
    const { axiosRequest, abortController } =
      StoreFrontService.getCatalogProductRecommendations();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setCatalogRecommendations(response.data.responseData);
        }
        setLoadingCatalogRecommendations("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoadingCatalogRecommendations("done");
          console.error(`Error Getting Catalog Recommendation: `, error);
        }
      });
    return () => abortController.abort();
  };

  const handleToggleCatalogItem = (skuCode, isEnabled) => {
    if (isEnabled) {
      const { axiosRequest } = StoreFrontService.toggleStoreFrontCatalogItem(
        skuCode,
        isEnabled,
        productToUpdate?.sellingPrice,
        productToUpdate?.quantity
      );
      axiosRequest
        .then((response) => {
          if (response?.data?.status) {
            getCatalog();
            getAddToCatalogProducts();
            setProductToUpdate((prevProductToUpdate) => ({
              ...prevProductToUpdate,
              isEnabled,
            }));
            setShowCatalogItemPublishedDialog(true);
          } else {
            setShowCatalogErrorDialog(true);
          }
        })
        .catch((error) => {
          setShowCatalogErrorDialog(true);
          console.error(`Error Enabling Product: `, error);
        });
      AnalyticsService.pushEvent(
        "Store Front Catalog Item Publish Button Clicked",
        { skuCode: skuCode }
      );
    } else {
      setSkuCodeToUpdate(skuCode);
      setShowCatalogItemUnpublishConfirmationDialog(true);
      AnalyticsService.pushEvent(
        "Store Front Catalog Item Unpublish Button Clicked",
        { skuCode: productToUpdate?.skuCode }
      );
    }
  };

  const handleUpdateProductInventory = (price, quantity) => {
    const { axiosRequest } = StoreFrontService.toggleStoreFrontCatalogItem(
      productToUpdate?.skuCode,
      true,
      price,
      quantity
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.status) {
          getCatalog();
          getAddToCatalogProducts();
          setShowCatalogItemInventoryUpdatedDialog(true);
        } else {
          setShowCatalogErrorDialog(true);
        }
        setOpenDrawer(false);
      })
      .catch((error) => {
        setOpenDrawer(false);
        setShowCatalogErrorDialog(true);
        console.error(`Error Updating Inventory: `, error);
      });
    AnalyticsService.pushEvent(
      "Store Front Catalog Item Publish Button Clicked",
      { skuCode: productToUpdate?.skuCode }
    );
  };

  const handleUnpublishCatalogItem = () => {
    setLoadingUnpublish("started");
    const { axiosRequest } = StoreFrontService.toggleStoreFrontCatalogItem(
      productToUpdate?.skuCode,
      false,
      productToUpdate?.sellingPrice,
      productToUpdate?.quantity
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.status) {
          getCatalog();
          getAddToCatalogProducts();
          setShowCatalogItemUnblishedDialog(true);
        } else {
          setShowCatalogErrorDialog(true);
        }
        setOpenDrawer(false);
        setLoadingUnpublish("done");
        setShowCatalogItemUnpublishConfirmationDialog(false);
      })
      .catch((error) => {
        setOpenDrawer(false);
        setLoadingUnpublish("done");
        setShowCatalogErrorDialog(true);
        setShowCatalogItemUnpublishConfirmationDialog(false);
        console.error(`Error Updating Inventory: `, error);
      });
  };

  return (
    <div style={{ padding: "16px 16px 40px" }}>
      <BlockUi
        tag="div"
        keepInView
        message={`Please wait...`}
        blocking={loadingCatalog === "started"}
        style={{ minHeight: "10vh", marginBottom: "24px" }}
      >
        {catalogDetails?.totalCount ? (
          <Link
            to="/store-front-catalog"
            style={{ textDecoration: "none" }}
            onClick={() =>
              AnalyticsService.pushEvent("Banner Clicked", {
                type: "Update Inventory",
              })
            }
          >
            <Grid
              container
              sx={{
                marginBottom: "24px",
                padding: "8px 16px",
                borderRadius: "16px",
                border: "1px solid #fff",
                background:
                  "linear-gradient(12deg, #B84307 0%, #F6A313 130.92%)",
                boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
              }}
            >
              <Grid
                item
                xs={11}
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#fff",
                }}
              >
                Update Your Inventory
              </Grid>
              <Grid item xs={1} sx={{ textAlign: "right" }}>
                <ArrowForwardTwoTone sx={{ color: "#fff" }} />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#FFF1C6",
                }}
              >
                {catalogDetails?.totalCount}
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid
                item
                xs={3}
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#FFF1C6",
                }}
              >
                {catalogDetails?.totalQuantity}
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={3} sx={{ fontSize: "12px", color: "#FFF1C6" }}>
                Total SKUs
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4} sx={{ fontSize: "12px", color: "#FFF1C6" }}>
                Total Quantity
              </Grid>
              {!isInventoryUpdated && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    fontSize: "12px",
                    padding: "2px 5px",
                    marginTop: "10px",
                    color: "#9D2123",
                    background: "#F8D149",
                    borderRadius: "8px",
                  }}
                >
                  <WarningRounded /> Update today's inventory to get more orders
                </Grid>
              )}
            </Grid>
          </Link>
        ) : (
          loadingCatalog === "done" && (
            <EmptyError
              extraStyle={{
                minHeight: `40vh`,
                background: "#FFFFFF",
              }}
              hideBack={true}
              image="EmptyPage.svg"
              subText="You haven't purchased any products in the last 90 days"
            />
          )
        )}
      </BlockUi>

      <BlockUi
        tag="div"
        keepInView
        message={`Please wait...`}
        blocking={loadingAddToCatalogProducts === "started"}
        style={{ minHeight: `20vh`, marginBottom: "24px" }}
      >
        <Typography variant="subtitle2" fontWeight={700}>
          Add Products in Inventory (
          {addToCatalogProducts?.productGists?.length || 0})
        </Typography>
        {addToCatalogProducts?.productGists?.length ? (
          <Box
            sx={{
              marginTop: "10px",
              overflow: "auto",
              width: "100%",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              flexWrap="wrap"
              sx={{ rowGap: "10px", columnGap: "10px", height: "180px" }}
            >
              {addToCatalogProducts?.productGists
                ?.slice(0, 6)
                ?.map((product) => (
                  <Grid
                    item
                    xs={5}
                    key={product.skuCode}
                    sx={{ width: "100%", maxWidth: "300px !important" }}
                  >
                    <AddProductsToCatalogCard
                      product={product}
                      setOpenDrawer={setOpenDrawer}
                      setProductToUpdate={setProductToUpdate}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        ) : (
          loadingAddToCatalogProducts === "done" && (
            <EmptyError
              extraStyle={{
                minHeight: `20vh`,
                background: "#FFFFFF",
              }}
              hideBack={true}
              subText="No product to add."
            />
          )
        )}

        {addToCatalogProducts?.productGists?.length > 6 ? (
          <Button
            fullWidth
            variant="outlined"
            href="/add-products-to-catalog"
            endIcon={<ArrowForwardTwoTone />}
            sx={{
              marginTop: "16px",
              fontWeight: 700,
              borderRadius: "16px",
              textTransform: "capitalize",
            }}
            onClick={() =>
              AnalyticsService.pushEvent(`Button Clicked`, {
                type: "View All Products",
                page: "/store-manager/store-inventory",
              })
            }
          >
            View All Products
          </Button>
        ) : null}
      </BlockUi>

      <BlockUi
        tag="div"
        keepInView
        message={`Please wait...`}
        blocking={loadingCatalogRecommendations === "started"}
        style={{ minHeight: `40vh`, marginBottom: "24px" }}
      >
        {catalogRecommendations?.productGists?.length ? (
          <>
            <Typography variant="subtitle2" fontWeight={700}>
              Recommended Products ({catalogRecommendations?.totalCount})
            </Typography>
            {catalogRecommendations?.productGists?.map((product) => (
              <CatalogRecommendedProductCard
                product={product}
                key={product.skuCode}
              />
            ))}
          </>
        ) : null}
      </BlockUi>

      <>
        <UpdateProductInventoryDrawer
          product={productToUpdate}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleToggleCatalogItem={handleToggleCatalogItem}
          handleUpdateProductInventory={handleUpdateProductInventory}
        />

        <CatalogItemPublishedDialog
          open={showCatalogItemPublishedDialog}
          handleClickDone={() => {
            setShowCatalogItemPublishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Published Dialog Done Clicked",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemPublishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Published Dialog Closed",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
        />
        <CatalogItemInventoryUpdatedDialog
          open={showCatalogItemInventoryUpdatedDialog}
          handleClickDone={() => {
            setShowCatalogItemInventoryUpdatedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Inventory Updated Dialog Done Clicked",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemInventoryUpdatedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Inventory Updated Dialog Closed",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
        />
        <CatalogItemUnpublishConfirmationDialog
          skuCode={skuCodeToUpdate}
          open={showCatalogItemUnpublishConfirmationDialog}
          loadingUnpublish={loadingUnpublish}
          handleClickReject={() => {
            setShowCatalogItemUnpublishConfirmationDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmation Rejected",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
          handleClickConfirm={(skuCode) => {
            handleUnpublishCatalogItem(skuCode);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmed",
              {
                skuCode: skuCode,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemUnpublishConfirmationDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmation Dialog Closed",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
        />
        <CatalogItemUnpublishedDialog
          open={showCatalogItemUnpublishedDialog}
          handleClickDone={() => {
            setShowCatalogItemUnblishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublished Dialog Done Clicked",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemUnblishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublished Dialog Closed",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
        />
        <CatalogError
          open={showCatalogErrorDialog}
          handleClickDone={() => {
            setShowCatalogErrorDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Error Dialog Done Clicked"
            );
          }}
          onClose={() => {
            setShowCatalogErrorDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Error Dialog Closed"
            );
          }}
        />
      </>
    </div>
  );
}
