import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Button, DialogContent, Grid, Typography } from "@mui/material";

export default function ConfirmationModal(props) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);
  return (
    <div>
      <Dialog open={open} onClose={props.handleClose}>
        <DialogContent>
          <Grid container alignItems={`center`} justifyContent={`center`}>
            <Grid item xs={11}>
              <Typography
                sx={{
                  textAlign: "center",
                  lineHeight: "1.533rem",
                  fontWeight: "500",
                  color: "#222222",
                  fontSize: "1.125rem",
                }}
              >
                {props.modalText}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              width: "100%",
              display: `flex`,
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <Grid item xs={5.7}>
              <Button
                variant={"outlined"}
                color="primary"
                fullWidth
                sx={{ height: "2.813rem" }}
                onClick={props.handleClickCancelButton}
              >
                {props.cancelButtonText}
              </Button>
            </Grid>
            <Grid item xs={5.7}>
              <Button
                variant={"contained"}
                fullWidth
                sx={{ height: "2.813rem" }}
                onClick={props.handleClickConfirmButton}
              >
                {props.confirmButtonText}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
