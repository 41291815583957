// COLOURS:
const LIGHT_GREEN = "#E6F2EC";
const GREEN = "#07733E";
const RED = "#9D2123";
const LIGHT_RED = "#F9EBEB";

// Icon Links:
const IC_ORDER_CANCEL = `/icons/statusCancel.svg`;
const IC_CHECK_O = `/icons/enabledCheck.svg`;
const DROPPED_AT_FC = `/icons/droppedAtFC.svg`;
const IC_ORDER_RETURN = `/icons/returnIcon.svg`;

// Dictionary of status Details
const ReturnOrderStatusIcons = {
  CREATED: {
    statusColor: GREEN,
    statusContainerColor: LIGHT_GREEN,
    statusIcon: IC_CHECK_O,
    statusMessage: "Confirmed",
  },
  RETURNED: {
    statusColor: RED,
    statusContainerColor: LIGHT_RED,
    statusIcon: IC_ORDER_RETURN,
    statusMessage: "Returned",
  },
  PENDING: {
    statusColor: RED,
    statusContainerColor: LIGHT_RED,
    statusIcon: IC_ORDER_RETURN,
    statusMessage: "Pending",
  },
  CANCELLED: {
    statusColor: RED,
    statusContainerColor: LIGHT_RED,
    statusIcon: IC_ORDER_CANCEL,
    statusMessage: "Cancelled",
  },
  PICKUPSCHEDULED: {
    statusColor: GREEN,
    statusContainerColor: LIGHT_GREEN,
    statusIcon: IC_CHECK_O,
    statusMessage: "Pick-Up Scheduled",
  },
  PICKUPRESCHEDULED: {
    statusColor: GREEN,
    statusContainerColor: LIGHT_GREEN,
    statusIcon: IC_CHECK_O,
    statusMessage: "Pick-Up Re-Scheduled",
  },
  RECEIVED: {
    statusColor: GREEN,
    statusContainerColor: LIGHT_GREEN,
    statusIcon: DROPPED_AT_FC,
    statusMessage: "Dropped At FC",
  },
};

export default ReturnOrderStatusIcons;
