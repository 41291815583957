import React from "react";
import { Alert, Snackbar } from "@mui/material";

function Toast(props) {
  return (
    <Snackbar
      open={props.show}
      autoHideDuration={props.autoHideDuration ? props.autoHideDuration : 3000}
      onClose={() => props.setShow(false)}
      anchorOrigin={
        props.anchorOrigin
          ? props.anchorOrigin
          : { vertical: "bottom", horizontal: "center" }
      }
      sx={{ marginBottom: "46px" }}
    >
      <Alert
        sx={{
          background: "#666666",
          color: "#ffffff",
          borderRadius: "6px",
          textAlign: "center",
        }}
        icon={false}
        onClose={() => props.setShow(false)}
      >
        {props.alertText}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
