import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  closeButtonDiv: {
    cursor: "pointer",
    float: "right",
  },
  closeButtonImage: {
    cursor: "pointer",
    marginRight: 14,
    marginTop: 24,
  },
  globalHeader: {
    width: "100vw",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9999,
  },
}));

const StoryEmptyScreen = ({ text, handleCloseButtonPressed, isLoading }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        background: "black",
        width: "100vw",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.globalHeader}>
        <Box
          className={classes.closeButtonDiv}
          onClick={handleCloseButtonPressed}
        >
          <img
            src="/icons/whiteCross.svg"
            alt="close"
            color="white"
            height="18px"
            className={classes.closeButtonImage}
          />
        </Box>
      </Box>
      {isLoading && <img src={`/icons/spinner.svg`} alt="" />}
      {!isLoading && text && <Typography color="white">{text}</Typography>}
    </Box>
  );
};

export default StoryEmptyScreen;
