import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _, { parseInt } from "lodash";
import format from "date-fns/format";

import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  Container,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Box,
  Dialog,
  Button,
  DialogContent,
} from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import OrderDetailCard from "../Common/OrderDetailCard";
import TopBar from "../Common/TopBar";
import SectionTitle from "../Common/SectionTitle";
import AndroidService from "../../Services/AndroidService";
import OrderService from "../../Services/OrderService";
import AnalyticsService from "../../Services/AnalyticsService";
import useQuery from "../../Hooks/useQuery";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import AmountUtils from "../../Utils/AmountUtils";
import AcceptOrCancelOrder from "./AcceptOrCancelOrder";
import PayNowDialog from "../Hisaab/PayNowDialog";
import config from "../../config";
import OrderCancelReasonSelect from "../Common/OrderCancelReasonSelect";
import ErrorIcon from "@mui/icons-material/Error";
import OrderBillDetails from "./OrderBillDetails";
import useMessages from "../../Hooks/useMessages";
import FloatingActionButton from "../Common/FloatingActionButton";
import ConfirmDelivery from "./ConfirmDelivery";
import CommonConstantValues from "../../Services/CommonConstantValues";
import EmptyError from "../EmptyError/EmptyError";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: window.innerHeight,
    fontSize: "14px",
    background: theme.palette.background.main,
  },
  itemsInBagTitleContainer: {
    marginTop: theme.spacing(3),
  },
  header: {
    background: "#ffffff",
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  subTotalLabel: {
    fontSize: "14px",
  },
  subTotalValue: {
    fontSize: "14px",
    textAlign: "right",
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "none",
    fontWeight: 400,
  },
  cardHeader: {
    padding: theme.spacing(1),
    borderBottom: "1px solid #eeeeee",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
  },
  cardFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    border: "none",
    lineHeight: "18.75px",
    fontSize: "16px",
  },
  cardHeaderSubText: {
    color: "#00733E",
    fontSize: "14px",
  },
  cardHeaderSubTextRight: {
    color: "#00733E",
    fontSize: "14px",
    float: "right",
  },
  cardFooterSubText: {
    color: "#757575",
    fontSize: "small",
  },
  orderItem: {
    background: "#ffffff",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5),
    flexWrap: "nowrap",
  },
  item: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 1),

    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  itemColumn: {
    margin: theme.spacing(0, 1),
    display: "flex",
    flexDirection: "column",
  },
  quantity: {
    float: "right",
  },
  itemSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  productImage: {
    marginLeft: theme.spacing(1),
    height: "80px",
  },
  itemSubText: {
    color: "#757575",
  },
  creditUsedValue: {
    fontSize: "18px",
  },
  sectionHeader: {
    marginTop: theme.spacing(3),
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  pricePerQuantity: {
    color: "#666666",
    fontSize: "12px",
    "&>span": {
      color: "#00733E",
    },
  },
  offerAppliedLabel: {
    marginTop: theme.spacing(0.5),
  },
  offerAppliedLabelImage: {
    height: theme.spacing(2.5),
  },
  productNameContainer: {
    marginLeft: theme.spacing(1),
    fontSize: "14px",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  alertMessageStyle: {
    background: "#666666",
    color: "#ffffff",
    textAlign: "center",
    lineHeight: `1.1`,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  invoiceNotPresent: {
    color: "#757575",
    fontSize: "small",
    marginTop: theme.spacing(1),
    // margin: theme.spacing(1, 1),
  },
}));

const OrderDetails = () => {
  const classes = useStyles();

  const { orderId } = useParams();
  const { getMessage } = useMessages();
  const query = useQuery();
  const wmsOrderIdFromQueryParam = query.get("wmsOrderId");
  const orderTypeQueryParam = query.get("orderType");
  const navigate = useNavigate();
  const location = useLocation();

  const [order, setOrder] = useState(null);
  const [wmsOrderId, setWmsOrderId] = useState(
    location?.state?.wmsOrderId || wmsOrderIdFromQueryParam
  );
  const [orderType, setOrderType] = useState(
    location?.state?.orderType || orderTypeQueryParam
  );

  const [loading, setLoading] = useState("not_started");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [isInvoicePresent, SetIsInvoicePresent] = useState(false);

  const [showPayNowDialog, setShowPayNowDialog] = useState(false);
  const [payNowPaymentAmount, setPayNowPaymentAmount] = useState(0);
  const [paymentLoading, setPaymentLoading] = useState("not_started");
  const [openSelectCancelReason, setOpenSelectCancelReason] = useState(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState(null);
  const [loadingCancelReason, setLoadingCancelReason] = useState("not-started");
  const [loadingCancelOrder, setLoadingCancelOrder] = useState("not-started");
  const [loadingAcceptOrder, setLoadingAcceptOrder] = useState("not-started");
  const [errorCancellingOrder, setErrorCancellingOrder] = useState(null);
  const [errorAcceptingOrder, setErrorAcceptingOrder] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isPageNotAccessible, setIsPageNotAccessible] = useState(false);
  const [
    showConfirmationDialogForDelivery,
    setShowConfirmationDialogForDelivery,
  ] = useState(false);
  const isFieldAgent = localStorage.getItem("role") === "fieldAgent";
  const [errorMessageWhileDelivering, setErrorMessageWhileDelivering] =
    useState(null);
  const [errorDeliveringOrder, setErrorDeliveringOrder] = useState(false);

  const checkInvoicePresent = (wmsOrderId) => {
    if (wmsOrderId) {
      const { axiosRequest, abortController } =
        OrderService.checkIfInvoiceIsPresent(wmsOrderId);
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.message === "Invoice present")
            SetIsInvoicePresent(true);
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            SetIsInvoicePresent(false);
            setLoading("done");
            console.log(error);
          }
        });
      return () => abortController.abort();
    }
    return () => {};
  };

  useEffect(() => {
    if (orderId && orderId !== "") {
      setLoading("started");
      let walletUserId = localStorage.getItem("userId");
      if (orderType === "fieldAgentLead") {
        // agent's lead details page view
        walletUserId = null;
      } else {
        if (localStorage.getItem("role") === "fieldAgent") {
          // saathi's order details page view for field agent
          walletUserId = null;
        }
      }
      const { axiosRequest, abortController } = OrderService.getOrder(
        orderId,
        walletUserId,
        orderType
      );
      axiosRequest
        .then((response) => {
          setLoading("done");
          if (response?.data?.responseData) {
            const order = response.data.responseData;

            const orderDetailsClickedEventData = {
              orderState: order?.statusMessage || "",
              estimatedDeliveryDate:
                format(order?.estimatedDeliveryDate, "dd MMM yyyy") || "",
              productNames:
                order?.products.map((p) => p.productName).toString() || "",
              orderId: order?.orderId || "",
              numberOfProducts: order?.products.length || 0,
              totalOrderValue: order?.paymentDetails?.orderValue || 0,
              isPageNotAccessible: false,
            };

            const farmerId = JSON.parse(localStorage.getItem("farmerId"));
            if (!isFieldAgent && farmerId !== order.farmerId) {
              setIsPageNotAccessible(true);
              orderDetailsClickedEventData.isPageNotAccessible = true;
            } else {
              setOrder(order);
              setWmsOrderId(order.wmsOrderId);
              if (order?.wmsOrderId) {
                checkInvoicePresent(order?.wmsOrderId);
              }
            }

            AnalyticsService.pushEvent(
              `Order Details Clicked`,
              orderDetailsClickedEventData
            );
          } else {
            console.log(`no orders found`);
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoading("done");
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
          }
        });
      return () => abortController.abort();
    } else {
      if (wmsOrderId) {
        const invoiceCB = checkInvoicePresent(wmsOrderId);
        return () => invoiceCB();
      }
    }
  }, [orderId]);

  const downloadFile = (fileData) => {
    const newBlob = new Blob([fileData], {
      type: "application/pdf",
    });

    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.style = "display: none";
    link.href = data;
    let orderIdForFileName = _.split(orderId, "&")?.[0];
    link.download = `AgroStar-Saathi-Invoice-${orderIdForFileName}.pdf`;
    link.click();
  };

  const handleOnClickInvoiceDownload = () => {
    if (orderId && orderId !== "") {
      setLoading("started");

      const { axiosRequest } = OrderService.getOrderInvoice(wmsOrderId);
      axiosRequest
        .then((response) => {
          try {
            if (AndroidService.checkIfAndroid()) {
              if (AndroidService.getAndroidVersion()) {
                let { appVersionCode } = AndroidService.getAndroidVersion();
                if (appVersionCode && parseInt(appVersionCode) >= 8) {
                  /*global Android*/
                  Android.setFileName(`Agrostar-Saathi-Invoice-${orderId}`);
                } else {
                }
              }

              if (response.message) {
                setShowAlertMessage(true);
                setAlertMessage(response.message);
              }

              downloadFile(response.data);

              setLoading("done");
            } else {
              console.log("No android");
              if (response.message) {
                setShowAlertMessage(true);
                setAlertMessage(response.message);
              }
              downloadFile(response.data);

              setLoading("done");
            }
          } catch (error) {
            console.log("error in setting file name in android", error);
          }
        })
        .catch((error) => {
          setLoading("done");
          setShowAlertMessage(true);
          if (error?.response?.data?.message) {
            setAlertMessage(error.response.data.message);
          } else {
            setAlertMessage("Something went wrong, please retry");
          }
        });

      const invoiceDownloadClickedEventData = {
        pageName: "Order Details",
        orderId,
        wmsOrderId,
      };

      AnalyticsService.pushEvent(
        `Invoice Download Clicked`,
        invoiceDownloadClickedEventData
      );
    }
  };

  const handleCancelOrderRequest = () => {
    if (localStorage.getItem("role") === "fieldAgent") {
      // field agent must provide cancellation reason before cancelling the order
      if (selectedCancelReason) {
        setLoadingCancelReason("started");
        const { axiosRequest } = OrderService.cancelOrder(
          order?.orderId,
          selectedCancelReason?.id
        );
        axiosRequest
          .then((response) => {
            setOpenSelectCancelReason(false);
            setLoadingCancelReason("done");
            window.location.reload();
          })
          .catch((error) => {
            setOpenSelectCancelReason(false);
            setErrorCancellingOrder(true);
            console.error(`Error Cancelling Order: `, error);
            setLoadingCancelReason("done");
          });
      }
    } else {
      setLoadingCancelOrder("started");
      // no cancellation reason passed by Saathi
      const { axiosRequest } = OrderService.cancelOrder(order?.orderId);
      axiosRequest
        .then((response) => {
          setShowConfirmationDialog(false);
          setLoadingCancelOrder("done");
          window.location.reload();
        })
        .catch((error) => {
          setErrorCancellingOrder(true);
          setShowConfirmationDialog(false);
          setLoadingCancelOrder("done");
          console.error(`Error Cancelling Order: `, error);
        });
    }
  };

  const handleAcceptCashOrderRequest = () => {
    // for online payment orders from either "CASH_ONLY_CART" or "CREDIT_CART"
    handleClickPayNow();
  };

  const handleAcceptCreditOrderRequest = () => {
    // for credit orders from "CREDIT_CART"
    setLoadingAcceptOrder("started");
    const { axiosRequest } = OrderService.approveOrder({
      orderId: order?.orderId,
    });
    axiosRequest
      .then((response) => {
        setShowConfirmationDialog(false);
        setLoadingAcceptOrder("done");
        window.location.reload();
      })
      .catch((error) => {
        setErrorAcceptingOrder(true);
        setShowConfirmationDialog(false);
        setLoadingAcceptOrder("done");
        console.error(`Error Accepting Order: `, error);
      });
  };

  const handleAcceptCreditOrderRequestForLendingPartner = () => {
    // for credit orders from External Credit Line partners
    setLoadingAcceptOrder("started");
    const { axiosRequest } = OrderService.approveOrder({
      orderId: order?.orderId,
      redirectUrl: `${config.appUrl}/orders`,
    });
    axiosRequest
      .then((response) => {
        const responseData = response?.data?.responseData;
        if (
          responseData?.lendingProviderSource === "EXTERNAL" &&
          responseData?.lendingProviderData?.lendingProvider === "RUPIFI" &&
          responseData?.lendingProviderData?.paymentUrl
        ) {
          window.open(responseData?.lendingProviderData?.paymentUrl, "_self");
        } else {
          window.location.reload();
        }
        setShowConfirmationDialog(false);
        setLoadingAcceptOrder("done");
      })
      .catch((error) => {
        setErrorAcceptingOrder(true);
        setShowConfirmationDialog(false);
        setLoadingAcceptOrder("done");
        console.error(`Error Accepting Order: `, error);
      });
  };

  const handleClickPayNow = () => {
    if (order?.unSettledAmount) {
      setPayNowPaymentAmount(order?.unSettledAmount);
    }
    payNowHandler();
    AnalyticsService.pushEvent("Pay Now Clicked", {
      dueAmount: order?.unSettledAmount,
      creditLine: localStorage.getItem("creditLine")?.toLowerCase(),
      orderId: order?.orderId || "",
      cashDiscountAmount: order?.cdBenefitAmount,
      netPayableAmount: order?.netPayableAmount,
      checkoutOrderType: "FIELD-APP-SELF-CHECKOUT",
    });
  };

  const payNowHandler = () => {
    if (!config.disablePayNow) {
      setShowPayNowDialog(true);
    }
  };

  const handleClosePayNow = () => {
    setShowPayNowDialog(false);
  };

  const handleRazorpayFailure = (error, errorCode = null) => {
    const urlParams = errorCode ? `?errorCode=${errorCode}` : "";
    navigate(`/hisaab/razorpay-failure/${error}${urlParams}`);
  };

  const onConfirmationDialogPositiveClick = () => {
    setShowConfirmationDialogForDelivery(false);
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Positive Confirm Delivery Modal",
      page: "Order Detail",
      orderId: order?.orderId,
    });
    setLoading("started");
    const { axiosRequest } = OrderService.markOrderDelivered(order?.wmsOrderId);
    axiosRequest
      .then((response) => {
        if (
          response?.data?.responseData?.message ===
          "Package marked successfully delivered"
        ) {
          navigate(
            `/order/freight-charge-reimburse-success?orderId=${
              order?.orderId
            }&freightCharges=${order?.freightCharges || 0}`
          );
        }
        setLoading("done");
      })
      .catch((error) => {
        console.log("Error Marking Order Delivered: ", error);
        setErrorDeliveringOrder(true);
        setErrorMessageWhileDelivering(getDeliveryErrorMessage(error));
        setLoading("done");
      });
  };

  function getDeliveryErrorMessage(error) {
    return error?.response?.data?.message === `Package is in delivered status`
      ? `Package is already delivered.`
      : `Error marking this order delivered`;
  }

  const onConfirmationDialogNegativeClicked = () => {
    setShowConfirmationDialogForDelivery(false);
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Negative Confirm Delivery Dialog",
      page: "Order Detail",
      orderId: order?.orderId,
    });
  };

  const handleCloseConfirmation = () => {
    setShowConfirmationDialogForDelivery(false);
  };

  const handleClickedOnConfirmDelivery = () => {
    setShowConfirmationDialogForDelivery(true);
  };

  return (
    <div className={classes.main}>
      <TopBar
        backNavigation={true}
        title={orderType == "fieldAgentLead" ? `Lead Details` : `Order Details`}
        showInvoiceDownload={isInvoicePresent}
        handleOnClickInvoiceDownload={handleOnClickInvoiceDownload}
      />
      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "95vh" }}
      >
        <Snackbar
          open={showAlertMessage}
          autoHideDuration={5000}
          onClose={() => setShowAlertMessage(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            icon={false}
            className={classes.alertMessageStyle}
            onClose={() => setShowAlertMessage(false)}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {loading === "done" && order && (
          <>
            {orderType !== "fieldAgentLead" && !isInvoicePresent && (
              <Container maxWidth={`xl`}>
                <Typography className={classes.invoiceNotPresent}>
                  <b>
                    Invoice will be available after the order is dispatched.
                  </b>
                </Typography>
              </Container>
            )}
            <Container maxWidth={`xl`}>
              <OrderDetailCard
                order={order}
                orderType={orderType}
                showCancelOrder={true}
                showPlacedBy={true}
                setOpenSelectCancelReason={setOpenSelectCancelReason}
              />
            </Container>

            <OrderCancelReasonSelect
              open={openSelectCancelReason}
              setOpen={setOpenSelectCancelReason}
              selectedCancelReason={selectedCancelReason}
              setSelectedCancelReason={setSelectedCancelReason}
              handleCancelOrder={handleCancelOrderRequest}
              loadingCancelReason={loadingCancelReason}
              setLoadingCancelReason={setLoadingCancelReason}
            />

            <ErrorBoundary message={""}>
              {order?.products && order.products.length > 0 && (
                <>
                  <Container
                    maxWidth={`xl`}
                    className={classes.itemsInBagTitleContainer}
                  >
                    <SectionTitle
                      title={`Items in Cart (${order.products.length})`}
                    />
                  </Container>
                  {order.products.map((item, index) => (
                    <Grid
                      container
                      maxWidth={`xl`}
                      className={classes.orderItem}
                      key={index}
                    >
                      <Grid
                        container
                        sx={{ width: "80px", height: "80px", flex: "none" }}
                      >
                        <img
                          src={
                            item?.productImageUrl ? item.productImageUrl : ""
                          }
                          alt={
                            item.productName
                              ? item.productName.length > 6
                                ? item.productName.slice(0, 6).concat(`...`)
                                : item.productName
                              : `product`
                          }
                          className={classes.productImage}
                        />
                      </Grid>
                      <Grid item className={classes.productNameContainer}>
                        <Box>
                          <Typography>{item.productName || ""}</Typography>
                          {item.pricePerUnit >= 0 && (
                            <span className={classes.pricePerQuantity}>
                              Price per quantity :
                              <span color="success">
                                {AmountUtils.formatCurrency(
                                  item.pricePerUnit || 0
                                )}
                              </span>
                            </span>
                          )}
                          {item.offerApplicable && (
                            <div className={classes.offerAppliedLabel}>
                              <img
                                src={`/icons/OfferApplied.svg`}
                                alt="offer applied"
                                className={classes.offerAppliedLabelImage}
                              />
                            </div>
                          )}
                        </Box>
                        <Grid
                          sx={{ position: "relative", alignItems: "flex-end" }}
                        >
                          <Typography>
                            Quantity : <b>{item.quantity || "-"}</b>
                            <span className={classes.quantity}>
                              <b>
                                {AmountUtils.formatCurrency(
                                  (item.sellingPrice || 0) *
                                    (item.quantity || 0)
                                )}
                              </b>
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </ErrorBoundary>
            <ErrorBoundary message={""}>
              {order.status.toLowerCase() !== "returned" &&
                order.status.toLowerCase() !== "returnconfirmed" &&
                order.status.toLowerCase() !== "returnexpected" &&
                order.status.toLowerCase() !== "returnacknowledged" &&
                order.status.toLowerCase() !== "cancelled" &&
                // order.status.toLowerCase() !== "processing" &&
                order.crmStatus.toLowerCase() !== "mob_app_unverified" && (
                  <>
                    {/* <Container
                      maxWidth={`xl`}
                      className={classes.sectionHeader}
                    >
                      <SectionTitle title={`Bill Details`} />
                    </Container>

                    <Card className={classes.card} variant="outlined">
                      <div className={classes.cardHeader}>
                        <Typography className={classes.subTotalLabel}>
                          SubTotal
                          <br />
                          <span className={classes.cardHeaderSubText}>
                            Delivery Charges
                          </span>
                        </Typography>
                        <Typography className={classes.subTotalValue}>
                          {order?.paymentDetails?.orderValue
                            ? AmountUtils.formatCurrency(
                                order.paymentDetails.orderValue
                              )
                            : ``}
                          <br />
                          <span className={classes.cardHeaderSubTextRight}>
                            FREE
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.cardFooter}>
                        <Typography>
                          <b>Total Order Value</b>
                          <br />
                        </Typography>
                        <Typography className={classes.creditUsedValue}>
                          <b>
                            {order?.paymentDetails?.orderValue
                              ? AmountUtils.formatCurrency(
                                  order.paymentDetails.orderValue
                                )
                              : ``}
                          </b>
                        </Typography>
                      </div>
                    </Card>
                    <Container maxWidth={`xl`} sx={{ paddingBottom: "100px" }}>
                      <span className={classes.cardFooterSubText}>
                        Prices inclusive of all taxes including GST
                      </span>
                    </Container> */}

                    <Box sx={{ paddingBottom: 12 }}>
                      <OrderBillDetails order={order} />
                    </Box>
                  </>
                )}
            </ErrorBoundary>

            {orderType !== "fieldAgentLead" &&
              localStorage.getItem("role") !== "fieldAgent" &&
              (order?.crmStatus === "WAITING_FOR_APPROVAL" ||
                (order?.crmStatus === "ONLINE_PAYMENT_PENDING" &&
                  order?.InternalNote === "FIELD-APP-SELF-CHECKOUT") ||
                order?.crmStatus ===
                  "WAITING_FOR_LENDING_PARTNER_APPROVAL") && (
                <ErrorBoundary message={""}>
                  <AcceptOrCancelOrder
                    order={order}
                    showConfirmationDialog={showConfirmationDialog}
                    setShowConfirmationDialog={setShowConfirmationDialog}
                    loadingCancelOrder={loadingCancelOrder}
                    loadingAcceptOrder={loadingAcceptOrder}
                    handleAcceptCashOrderRequest={handleAcceptCashOrderRequest}
                    handleAcceptCreditOrderRequest={
                      handleAcceptCreditOrderRequest
                    }
                    handleAcceptCreditOrderRequestForLendingPartner={
                      handleAcceptCreditOrderRequestForLendingPartner
                    }
                    handleCancelOrderRequest={handleCancelOrderRequest}
                  />
                </ErrorBoundary>
              )}

            {order?.status === CommonConstantValues.DELIVERED_AT_GODOWN && (
              <ErrorBoundary message={""}>
                <ConfirmDelivery
                  order={order}
                  setLoading={setLoading}
                  handleConfirmDeliveryClicked={handleClickedOnConfirmDelivery}
                />
              </ErrorBoundary>
            )}
          </>
        )}

        {isPageNotAccessible && (
          <EmptyError
            image="EmptyPage.svg"
            text="You don't have access to the order!"
          />
        )}
      </BlockUi>

      <ErrorBoundary message={""}>
        {localStorage.getItem("partnerStatus") !== "UNVERIFIED" &&
          localStorage.getItem("farmerId") && (
            <FloatingActionButton
              title={getMessage(`complaintHistory.fabHeader`)}
              fabIconPath={"/icons/complaintFABIcon.svg"}
              currentPagePath={"Order Details"}
              clickedEventTitle={`Fab Clicked`}
              nextPagePath={`/complaints/create?issueType=Order Related Issue`}
              isFromProfilePage={false}
            />
          )}
      </ErrorBoundary>

      <PayNowDialog
        open={showPayNowDialog}
        setOpen={setShowPayNowDialog}
        paymentAmount={payNowPaymentAmount}
        lendingPartner={PartnerService.getLendingPartner()}
        handleClose={handleClosePayNow}
        handleRazorpayFailure={handleRazorpayFailure}
        paymentLoading={paymentLoading}
        setPaymentLoading={setPaymentLoading}
        paymentType={"order"}
        orderId={order?.orderId}
        initiationFailureRetryRedirectionLink={`/orders/${order?.orderId}&wmsOrderId=${order?.wmsOrderId}`}
        checkoutOrderType={"FIELD-APP-SELF-CHECKOUT"}
      />

      <Dialog
        open={
          errorAcceptingOrder || errorCancellingOrder || errorDeliveringOrder
        }
        onClose={() => {
          if (errorAcceptingOrder) {
            setErrorAcceptingOrder(false);
          }
          if (errorCancellingOrder) {
            setErrorCancellingOrder(false);
          }
          setErrorDeliveringOrder(!errorDeliveringOrder);
        }}
        PaperProps={{
          style: {
            borderRadius: "32px",
            padding: "24px",
            minWidth: "70vw",
          },
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <ErrorIcon fontSize={"large"} sx={{ color: "#9D2123" }} />

          <Typography variant={`body1`} sx={{ marginTop: 1 }}>
            {errorAcceptingOrder
              ? `Something went wrong while accepting the order. Please try again.`
              : errorCancellingOrder
                ? `Something went wrong while cancelling the order. Please try again.`
                : errorDeliveringOrder
                  ? errorMessageWhileDelivering
                  : ``}
          </Typography>

          <Box sx={{ marginTop: 2.5 }}>
            <Button
              variant={"outlined"}
              size={"large"}
              color={"secondary"}
              sx={{
                borderRadius: "16px",
                background: "rgba(255, 255, 255, 1)",
                color: "rgba(0, 115, 62, 1)",
                textTransform: "capitalize",
              }}
              onClick={() => {
                if (errorAcceptingOrder) {
                  setErrorAcceptingOrder(false);
                }
                if (errorCancellingOrder) {
                  setErrorCancellingOrder(false);
                }
                setErrorDeliveringOrder(!errorDeliveringOrder);
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={showConfirmationDialogForDelivery}
        onClose={handleCloseConfirmation}
      >
        <DialogContent>
          <Typography variant={`body1`} textAlign="center">
            Are you sure you want to confirm the delivery of this order?
          </Typography>
          <Grid
            container
            justifyContent={`space-evenly`}
            sx={{ marginTop: "16px" }}
          >
            <Button
              variant={`contained`}
              color={`primary`}
              onClick={() => onConfirmationDialogPositiveClick()}
            >
              Yes
            </Button>

            <Button
              variant={`outlined`}
              color={`primary`}
              onClick={() => onConfirmationDialogNegativeClicked()}
            >
              No
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrderDetails;
