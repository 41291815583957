import { Box, Card, CardContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import SectionTitle from "../../Components/Common/SectionTitle";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(3) },
  productInfoCard: {
    borderRadius: "0px",
    border: "none",
  },
  keyPointsLabel: {
    // fontWeight: "700",
    fontSize: "14px",
  },
  valueCell: {
    borderLeft: "1px solid #F3F3F3;",
    fontSize: "12px",
    lineHeight: "16px",
  },
}));

function PayAdvancedSchemeDetails(props) {
  const classes = useStyles();

  return (
    <>
      {props.scheme && (
        <div className={classes.root}>
          <Card variant={`outlined`} className={classes.productInfoCard}>
            <CardContent style={{ paddingBottom: "0px" }}>
              <SectionTitle title={`Scheme Details`} />

              <Typography
                variant={`body2`}
                fontWeight={500}
                fontSize={12}
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  lineHeight: "normal",
                  filter: PartnerService.applySmudgedPriceFilter(),
                }}
              >
                {_.map(props.scheme?.schemeDetails, (schemeDetail, index) => {
                  return (
                    <>
                      {schemeDetail?.type === "text" ? (
                        <>
                          <Box key={index}>
                            {schemeDetail?.title}
                            {schemeDetail?.description?.length > 0 ? (
                              <ul style={{ margin: "4px 0px" }}>
                                {schemeDetail?.description.map(
                                  (schemeDetailDescription, index) => (
                                    <li key={index}>
                                      {schemeDetailDescription}
                                    </li>
                                  )
                                )}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <br />
                        </>
                      ) : (
                        <></>
                      )}

                      {schemeDetail?.type === "image" ? (
                        <Box key={index} sx={{ marginBottom: "0px" }}>
                          {schemeDetail?.title}
                          {schemeDetail?.description.map(
                            (schemeDetailImage) => (
                              <Box sx={{ textAlign: "center" }}>
                                <img
                                  src={schemeDetailImage}
                                  alt=""
                                  style={{
                                    maxWidth: "100%",
                                    margin: "8px 0px 0px 0px",
                                  }}
                                />
                              </Box>
                            )
                          )}
                          <br />
                        </Box>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </Typography>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
}

export default PayAdvancedSchemeDetails;
