import React, { useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import { DialogContent, Grid, Typography } from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";
import { HighlightOffRounded } from "@mui/icons-material";
import AmountUtils from "../../Utils/AmountUtils";

export default function RealCashBifurcationDialog(props) {
  useEffect(() => {
    if (props.open) {
      AnalyticsService.pushEvent(`Dialog Opened`, {
        type: "Real Cash and Advance Payment Breakup",
      });
    }
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
    AnalyticsService.pushEvent(`Dialog Closed`, {
      type: "Real Cash and Advance Payment Breakup",
    });
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={props.open}
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: "32px" } }}
      >
        <DialogContent>
          <Grid container alignItems={`center`} justifyContent={`center`}>
            <Grid item xs={8} sx={{ mb: 1 }}>
              <Typography variant="h6" fontSize={18} fontWeight={700}>
                Amount Breakup
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ mb: 1, textAlign: "right" }}>
              <HighlightOffRounded onClick={handleClose} />
            </Grid>
            <Grid item xs={12} container gap="10px">
              {props?.realCash?.absSchemeBalances?.map((scheme, index) => (
                <Grid
                  item
                  xs={5.5}
                  key={index}
                  sx={{
                    padding: "5px 8px",
                    borderRadius: "8px",
                    minHeight: "84px",
                    border: "1px solid #CACACA",
                  }}
                >
                  <Typography fontSize={12}>{scheme.schemeName}</Typography>
                  <Typography fontSize={12}>
                    Left:{" "}
                    <b style={{ color: "#00733E" }}>
                      {AmountUtils.formatCurrency(scheme.realUsable || 0)}
                    </b>
                  </Typography>
                  <Typography fontSize={12}>
                    Used:{" "}
                    <b style={{ color: "#9D2123" }}>
                      {AmountUtils.formatCurrency(scheme.used || 0)}
                    </b>
                  </Typography>
                </Grid>
              ))}
              <Grid
                item
                xs={5.5}
                sx={{
                  padding: "5px 8px",
                  borderRadius: "8px",
                  border: "1px solid #CACACA",
                }}
              >
                <Typography fontSize={12}>Normal Real Cash</Typography>
                <Typography fontSize={12}>
                  Left:{" "}
                  <b style={{ color: "#00733E" }}>
                    {AmountUtils.formatCurrency(
                      props?.realCash?.realCashBreakup?.amountRemaining || 0
                    )}
                  </b>
                </Typography>
                <Typography fontSize={12}>
                  Used:{" "}
                  <b style={{ color: "#9D2123" }}>
                    {AmountUtils.formatCurrency(
                      props?.realCash?.realCashBreakup?.amountUsable || 0
                    )}
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
