import { Box, Button, Container, Grid, Typography } from "@mui/material";
import PartnerService from "../../Services/PartnerService";
import PayAdvanceSchemeProducts from "../../Services/PayAdvanceSchemeProducts";
import ProductPrice from "./ProductPrice";
import ProductMargin from "./ProductMargin";
import ErrorBoundary from "../Common/ErrorBoundary";
import ProductVariant from "./ProductVariant";
import AnalyticsService from "../../Services/AnalyticsService";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import ProductService from "../../Services/ProductService";
import ChangeQuantity from "./ChangeQuantity";
import CreditPeriod from "./CreditPeriod";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  productTitle: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    wordBreak: "break-word",
  },
  productTitleSizeReduced: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    transition: ProductService.productDetailsStickyTransition,
    wordBreak: "break-word",
  },
  productDetailsWrapper: {
    paddingTop: theme.spacing(1),
  },
  DeliveryLine: {
    color: "#000000",
    fontSize: "12px",
  },
  boldFonts: {
    fontWeight: "700",
  },
  deliveryWrapper: {
    marginTop: "0.5rem",
  },
}));

const ProductDetailsPrimary = ({
  product,
  productDetailsRef,
  productDetailsStickyAtTop,
  isPayAdvanceSchemeActive,
  appliedOffer,
  appliedCashDiscountBenefit,
  offersLoading,
  turnOverSchemeBenefits,
  cashDiscountBenefitContainerRef,
  offersContainerRef,
  turnoverScheme,
  turnoverSchemeContainerRef,
  farmerNetPrice,
  loading,
  payAdvanceSchemeDetailsRef,
  quantity,
  handleAdd,
  handleRemove,
  handleChange,
  setQuantity,
  minimumOrderQuantity,
  hideProductVariants,
  hideProductMargin,
  setOpenSchemeDetailsDrawer,
  section,
}) => {
  const classes = useStyles();

  return (
    <Container
      ref={productDetailsRef}
      maxWidth={`xl`}
      sx={{
        position: "sticky",
        top: "52px",
        zIndex: 4,
        borderBottom: "1px dashed #000000",
        borderBottomColor: "rgba(0,0,0,0.3)",
        background: "#E7E7E7",
      }}
    >
      <Box
        sx={{
          margin: "0px -16px 0px -16px",
          padding: `${
            productDetailsStickyAtTop
              ? `0px 16px 0px 16px`
              : `10px 16px 0px 16px`
          }`,
          transition: ProductService.productDetailsStickyTransition,
        }}
      >
        {isPayAdvanceSchemeActive ? (
          <>
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={12}>
                <Typography
                  className={`${
                    productDetailsStickyAtTop
                      ? classes.productTitleSizeReduced
                      : classes.productTitle
                  }`}
                >
                  {product?.productName}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                background: "rgba(248, 209, 73, 1)",
                borderRadius: "5px",
                padding: "5px 10px",
                marginTop: "10px",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant={`body1`}
                  color={"secondary"}
                  fontWeight={"bold"}
                  sx={{
                    filter: PartnerService.applySmudgedPriceFilter(product),
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  Advance Booking Open @ ₹
                  {
                    PayAdvanceSchemeProducts?.[product?.skuCode]
                      ?.bookingPricePerUnit
                  }
                  {PayAdvanceSchemeProducts?.[product?.skuCode]?.schemeUnit
                    ? `/- Per ${
                        PayAdvanceSchemeProducts?.[product?.skuCode]?.schemeUnit
                      }`
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={7.2}>
                <Typography
                  className={`${
                    productDetailsStickyAtTop
                      ? classes.productTitleSizeReduced
                      : classes.productTitle
                  }`}
                >
                  {product?.productName}
                </Typography>
              </Grid>
              <Grid item xs={4.8}>
                <ProductPrice
                  product={product}
                  appliedOffer={appliedOffer}
                  appliedCashDiscountBenefit={appliedCashDiscountBenefit}
                  offersLoading={offersLoading}
                  turnOverSchemeBenefits={turnOverSchemeBenefits}
                  cashDiscountBenefitContainerRef={
                    cashDiscountBenefitContainerRef
                  }
                  offersContainerRef={offersContainerRef}
                  turnoverScheme={turnoverScheme}
                  turnoverSchemeContainerRef={turnoverSchemeContainerRef}
                  productDetailsStickyAtTop={productDetailsStickyAtTop}
                  setOpenSchemeDetailsDrawer={setOpenSchemeDetailsDrawer}
                  section={section}
                />
              </Grid>
            </Grid>
            {!hideProductMargin && (
              <ProductMargin
                product={product}
                farmerNetPrice={farmerNetPrice}
                appliedOffer={appliedOffer}
                appliedCashDiscountBenefit={appliedCashDiscountBenefit}
                offersLoading={offersLoading}
                productDetailsStickyAtTop={productDetailsStickyAtTop}
              />
            )}
          </>
        )}

        {/* product Details */}
        <Box
          sx={{
            background: "#FFFFFF",
            margin: `${
              productDetailsStickyAtTop
                ? `6px -16px 0px -16px`
                : `12px -16px 0px -16px`
            }`,
            padding: `${
              productDetailsStickyAtTop
                ? `0px 12px 2px 12px`
                : `4px 16px 10px 16px`
            }`,
            transition: ProductService.productDetailsStickyTransition,
          }}
        >
          {product?.variants &&
            product?.variants?.length > 0 &&
            !hideProductVariants && (
              <ErrorBoundary message={``}>
                <ProductVariant
                  product={product}
                  loading={loading}
                  page={`${product?.productName}'s Product Page`}
                  productDetailsStickyAtTop={productDetailsStickyAtTop}
                />
              </ErrorBoundary>
            )}
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent={"flex-start"}
              className={classes.productDetailsWrapper}
            >
              {isPayAdvanceSchemeActive ? (
                <Grid item xs={7}>
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent={"flex-start"}
                    className={classes.deliveryWrapper}
                  >
                    <Grid item xs={10}>
                      <Button
                        size={"small"}
                        onClick={() => {
                          if (
                            payAdvanceSchemeDetailsRef?.current?.hasChildNodes()
                          ) {
                            payAdvanceSchemeDetailsRef?.current?.scrollIntoView(
                              {
                                behavior: "smooth",
                                block: "start",
                                inline: "nearest",
                              }
                            );
                          }
                          AnalyticsService.pushEvent(
                            `View Scheme Details Clicked`,
                            {
                              skuCode: product?.skuCode,
                              productName: product?.productName,
                            }
                          );
                        }}
                        variant={`text`}
                        endIcon={<ArrowForwardSharpIcon />}
                        sx={{
                          fontSize: "12px",
                          textTransform: "none",
                        }}
                      >
                        View Scheme Details
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={7}>
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent={"flex-start"}
                    className={classes.deliveryWrapper}
                  >
                    <Grid item xs={2}>
                      <img src="/icons/shipping.svg" alt="shippingIcon" />
                    </Grid>
                    <Grid item xs={10}>
                      <div className={classes.DeliveryLine}>
                        &nbsp; Est. Delivery :{" "}
                        <span className={classes.boldFonts}>{`3-4 days`}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <CreditPeriod product={product} />
                </Grid>
              )}

              <Grid item xs={5}>
                <ChangeQuantity
                  product={product}
                  quantity={quantity}
                  handleAdd={handleAdd}
                  handleRemove={handleRemove}
                  handleChange={(e) => {
                    handleChange(e, "inputBox", null);
                  }}
                  setQuantity={setQuantity}
                  minimumOrderQuantity={minimumOrderQuantity}
                  offersLoading={offersLoading}
                />
                {product?.minimumOrderQuantity > 1 && (
                  <Grid container>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                      <Typography
                        variant={`body2`}
                        sx={{
                          fontSize: `${
                            productDetailsStickyAtTop ? `10px` : `12px`
                          }`,
                          fontWeight: "400",
                          margin: "8px -32px 0px -40px",
                          textAlign: "center",
                          color: `${
                            offersLoading === "started" ? `#808080` : `#9D2123`
                          }`,
                          transition:
                            ProductService.productDetailsStickyTransition,
                        }}
                      >
                        Available in pack of {product?.minimumOrderQuantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default ProductDetailsPrimary;
