import * as Sentry from "@sentry/react";

export default class SentryService {
  static captureCustomErrorMessage(message, error) {
    let reason = "";
    if (error?.response) {
      reason = `HTTP ERROR`;
      if (error?.response?.status) {
        reason = `${reason}, HTTP Status : ${error?.response?.status}`;
      }
      if (error?.response?.data?.message) {
        reason = `${reason}, Response-Data Message : ${error?.response?.data?.message}`;
      }
    } else {
      reason = `JS EXCEPTION`;
      if (error.toString()) {
        reason = `${reason}, Exception : ${error
          .toString()
          ?.substring(0, 100)}`;
      }
    }
    Sentry.captureMessage(`${message} ${reason}`);
    return reason;
  }

  static captureErrorWithData(message, metadata) {
    Sentry.captureMessage(`${message} ${JSON.stringify(metadata)}`);
  }

  static captureExceptionWithData(error, data) {
    Sentry.captureException(error, { extra: { requestBody: data } });
  }

  static getSentryHeaders() {
    const activeSpan = Sentry.getActiveSpan();
    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

    // Create `sentry-trace` header
    const sentryTraceHeader = rootSpan
      ? Sentry.spanToTraceHeader(rootSpan)
      : undefined;

    // Create `baggage` header
    const sentryBaggageHeader = rootSpan
      ? Sentry.spanToBaggageHeader(rootSpan)
      : undefined;
    return { sentryTraceHeader, sentryBaggageHeader };
  }
}
