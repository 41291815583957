import { Box, Grid, Slider, Typography } from "@mui/material";
import Clamp from "react-multiline-clamp";
import SchemesService from "../../Services/SchemesService";
import _ from "lodash";

const TurnoverSchemeProgressBar = (props) => {
  return (
    <>
      <Grid
        container
        alignItems={`center`}
        sx={{
          position: props?.productCardType === "horizontal" ? "" : "absolute",
          bottom: props.section === "Recently Bought Products" ? "35px" : 0,
        }}
        justifyContent={`center`}
      >
        <Grid
          item
          xs={12}
          sx={{
            marginTop:
              props?.productCardType === "horizontal" ? "-1px" : "-12px",
            padding: "0px 8px 4px 8px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "-9px",
                gap: "8px",
              }}
            >
              <Clamp lines={1} maxLines={1}>
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "10px",
                    fontWeight: 700,
                    color: "#4F4F4F",
                    wordBreak: "break-all",
                    maxWidth: "50%",
                  }}
                >
                  {props?.lastAchievedSlabDetails
                    ? SchemesService.generateEntitlementDescription(
                        props?.lastAchievedSlabDetails?.entitlements
                          ?.entitlementList
                      )
                    : ``}
                </Typography>
              </Clamp>
              <Clamp lines={1} maxLines={1}>
                <Typography
                  component={"span"}
                  color={"secondary"}
                  sx={{
                    fontSize: "10px",
                    fontWeight: 700,
                    wordBreak: "break-all",
                    maxWidth: "50%",
                  }}
                >
                  {props?.currentTargetSlabDetails
                    ? SchemesService.generateEntitlementDescription(
                        props?.currentTargetSlabDetails?.entitlements
                          ?.entitlementList
                      )
                    : ``}
                </Typography>
              </Clamp>
            </Box>
            <Slider
              aria-label="Scheme Progress"
              disabled={true}
              value={props?.qualifiedSlabRelativePercentageAchieved}
              sx={{
                height: "6px",
                padding: "0px",
                "@media (pointer: coarse)": {
                  padding: "0px !important",
                },
                "& .MuiSlider-thumb": {
                  height: "0px",
                  width: "0px",
                },
                "& .MuiSlider-rail": {
                  borderRadius: "16px",
                  background: "rgba(79, 79, 79, 0.2)",
                  opacity: 1,
                },
                "& .MuiSlider-track": {
                  background:
                    "linear-gradient(270deg, #00733E 0%, #FFDC42 100%)",
                  border: "1px solid #FFF",
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25);",
                  borderRadius: "16px",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TurnoverSchemeProgressBar;
