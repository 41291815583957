import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import LeadService from "../../Services/LeadService";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const useStyles = makeStyles((theme) => ({
  leadName: {
    fontWeight: "700",
    fontSize: "14px",
    color: "white",
  },
  leadInformation: {
    fontWeight: "400",
    fontSize: "12px",
    color: "white",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardContainer: {
    paddingTop: "5px",
    paddingBottom: "6px !important",
  },
  name: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#333333",
  },
  title: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#333333",
  },
  productCardWrapperLink: {
    textDecoration: `none`,
    color: "rgba(0, 0, 0, 0.87)",
  },
  productCardWrapper: {
    wordBreak: "break-word",
    borderRadius: "7px",
    border: "1px solid #EEEEEE",
    textAlign: "center",
  },
  productCardContent: {
    padding: theme.spacing(1),
    paddingBottom: "10px !important", // theme.spacing(1),
  },
  productImageContainer: {
    marginTop: theme.spacing(0.5),
    width: "100%",
  },
  productImage: {
    height: "100%",
    maxHeight: "150px",
    width: "95%",
    maxWidth: "100px",
  },
  productNameWrapper: {
    margin: theme.spacing(0, 0, 1.5, 0),
  },
  productName: {
    maxHeight: "3.9em",
    lineHeight: "1.3em",
    letterSpacing: "0px",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3 /* number of lines to show */,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
    fontSize: "14px",
  },
  secondaryButton: {
    // background: "#00733E",
    borderRadius: "10px",
    width: "49%",
    boxShadow: "none",
    height: "40px",
    border: "0px",
    marginLeft: "4px",
  },
  primaryButton: {
    borderRadius: "10px",
    width: "49%",
    boxShadow: "none",
    height: "40px",
    border: "0px",
  },
  paper: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    minHeight: "0px",
  },
}));

function LeadsCallAttempt(props) {
  const classes = useStyles();
  const [connectStatus, setConnectStatus] = useState(null);
  const [state, setState] = useState({ bottom: false });

  useEffect(() => {
    console.log(props.anchor);
    const leadsCallAttemptViewedData = {
      page: `/nearby-farmers`,
      screen: "Leads Call Attempt",
      leadId: props?.leadInformation?.leadId,
      farmerId: props?.leadInformation?.farmerId,
    };
    AnalyticsService.pushEvent(
      `Leads Call Attempt Screen Viewed`,
      leadsCallAttemptViewedData
    );
    setState({ ...state, [props.anchor]: true });
  }, [props.state]);

  const handleRemarkStatusEvent = (remark) => {
    updateLeadsStatus({ remarks: remark, leadStatus: connectStatus });
  };

  const handleConnectStatusEvent = (event, status, remark) => {
    setConnectStatus(status);
    updateLeadsStatus({ remarks: remark, leadStatus: status });
  };

  const updateLeadsStatus = (data) => {
    const leadsCallStatusData = {
      page: `/nearby-farmers`,
      remarks: data?.remarks,
      leadStatus: data?.leadStatus,
      leadId: props?.leadInformation?.leadId,
      farmerId: props?.leadInformation?.farmerId,
    };
    AnalyticsService.pushEvent(`Leads Call Status`, leadsCallStatusData);

    const { axiosRequest } = LeadService.setLeadStatus(
      props?.leadInformation?.leadId,
      props?.leadInformation?.farmerId,
      data
    );
    axiosRequest
      .then((response) => {
        console.log(response);
        props.handleLeadsBottomSheetClose();
      })
      .catch(() => {
        props.handleLeadsBottomSheetClose();
      });
  };

  const handleBottomSheetSwipe = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={state["bottom"]}
      onClose={handleBottomSheetSwipe("bottom", false)}
      onOpen={handleBottomSheetSwipe("bottom", true)}
      classes={{ paper: classes.paper }}
      disableSwipeToOpen={true}
    >
      <div>
        <Box
          sx={{
            width:
              props.anchor === "top" || props.anchor === "bottom"
                ? "auto"
                : 250,
          }}
          role="presentation"
        >
          <Box
            sx={{
              padding: "15px",
              paddingTop: "5px",
              paddingBottom: "0px",
              marginTop: "10px",
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    height: "6px",
                    width: "43px",
                    background: "#DDDDDD",
                    borderRadius: "19%",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "15px",
              paddingTop: "5px",
              paddingBottom: "0px",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.name}>
                  What happend to last lead call
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <div>
            <img
              src={"/icons/pattern.png"}
              style={{ width: "96%", height: "62px" }}
            />
          </div>
          <Box
            sx={{
              padding: "15px",
              paddingTop: "0px",
              paddingBottom: "15px",
              marginTop: "-67px",
            }}
          >
            <Card
              style={{
                boxShadow: "none",
                borderRadius: "7px",
                marginTop: "10px",
                background:
                  "linear-gradient(12.06deg, #B84307 0%, #F6A313 130.92%)",
              }}
            >
              <CardContent className={classes.cardContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography className={classes.leadName}>
                      {props.leadInformation.farmerAddress.farmerName}
                    </Typography>
                    <div>
                      <Typography className={classes.leadInformation}>
                        <img
                          src="./icons/whiteLocation.svg"
                          style={{ height: "14px" }}
                        />
                        &nbsp;{" "}
                        <span
                          style={{ position: "absolute", marginTop: "-2px" }}
                        >
                          {props.leadInformation?.farmerAddress?.line2}
                          {/* {props.leadInformation?.farmerAddress?.city} */}
                          {/* -{" "}
                          {props.leadInformation?.farmerAddress?.farmerPincode} */}
                        </span>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      style={{ marginTop: "5px" }}
                    ></Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              padding: "15px",
              paddingTop: "20px",
              paddingBottom: "165px",
              background: "#333333",
              opacity: "0.1",
            }}
          ></Box>
          <Box
            sx={{
              padding: "15px",
              paddingTop: "5px",
              position: "absolute",
              zIndex: "2",
              top: "150px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.primaryButton}
                  onClick={(event) =>
                    handleConnectStatusEvent(
                      event,
                      "connected",
                      "notinterested"
                    )
                  }
                >
                  No Sale
                </Button>
                <ErrorBoundary>
                  <Button
                    variant="contained"
                    color="secondary"
                    // onClick={handleClick}
                    className={classes.secondaryButton}
                    onClick={(event) =>
                      handleConnectStatusEvent(event, "connected", "interested")
                    }
                  >
                    Sale Made
                  </Button>
                </ErrorBoundary>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={(event) =>
                    handleConnectStatusEvent(event, "notconnected", "")
                  }
                  sx={{ borderRadius: "10px", height: "40px" }}
                >
                  Not Connected
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </SwipeableDrawer>
  );
}

export default LeadsCallAttempt;
