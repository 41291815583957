import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Clamp from "react-multiline-clamp";
import BlockUi from "react-block-ui";
import moment from "moment";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Container,
  Grid,
  Button,
  Typography,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  East,
  ExpandMore,
  Info,
  WarningAmberRounded,
} from "@mui/icons-material";

import config from "../../config";
import TopBar from "../Common/TopBar";
import EmptyError from "../EmptyError/EmptyError";
import AndroidService from "../../Services/AndroidService";
import { iptStatus } from "./InterPartyTransferRequestsPage";
import AnalyticsService from "../../Services/AnalyticsService";
import IPTDetailPagePartnerCard from "./IPTDetailPagePartnerCard";
import InterPartyTransfersService from "../../Services/InterPartyTransfersService";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";

const useStyles = makeStyles({
  iptHeaderBox: { fontSize: "14px", background: "#fff", borderRadius: "10px" },
  iptRequestStatus: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  iptTag: {
    fontSize: "14px",
    borderRadius: "5px",
    background: "#dcdcdc",
    padding: "5px 4px",
    marginRight: "8px",
  },
  tagAndIdsWrapper: { display: "flex", alignItems: "center" },
  iptApprovalStatus: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    borderRadius: "5px",
    padding: "5px 7px",
    textTransform: "capitalize",
  },
  headingStyles: { fontWeight: 700, marginTop: "16px", lineHeight: 1 },
  partnerCard: {
    marginTop: "8px",
    borderRadius: "10px",
    padding: "12px 16px",
    fontSize: "14px",
    borderTop: "1px solid #eee",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  partnerAddress: {
    display: "flex",
    alignItems: "flex-start",
    gap: "5px",
    marginTop: "5px",
  },
  locationIconImage: { position: "relative", top: 2, opacity: 0.7 },
  tableDataStyle: { fontSize: "12px", padding: "4px" },
  continueRequestButton: {
    position: "fixed",
    bottom: 0,
    maxWidth: "600px",
    height: "50px",
    borderRadius: 0,
  },
  partnerCTAAboutOrder: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    maxWidth: "600px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "50px",
  },
  partnerApprovalStatusInfo: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  remarks: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "#717171",
    borderTop: "1px solid #eee",
  },
  modalBox: {
    position: "absolute",
    left: "50%",
    top: "50%",
    width: "95%",
    padding: "16px 24px",
    border: "none",
    textAlign: "center",
    background: "#fff",
    borderRadius: "8px",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  buttonWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "10px",
  },
  modalButton: {
    height: "40px",
    fontWeight: 700,
    textTransform: "none",
    borderRadius: "16px",
  },
});

export default function InterPartyTransfersDetailsPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [farmerId, setFarmerId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const [isFieldAgent, setIsFieldAgent] = useState(null);
  const [showApprovalCTAs, setShowApprovalCTAs] = useState(null);
  const [iptOrderDetails, setIPTOrderDetails] = useState(null);
  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);
  const [partnerApprovalStatus, setPartnerApprovalStatus] = useState(null);

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, {
      page: "IPT Details",
      requestId: orderId,
    });

    setLoading("started");
    const { axiosRequest, abortController } =
      InterPartyTransfersService.getIPTOrderDetails(orderId);
    axiosRequest
      .then((orderResponse) => {
        const orderData = orderResponse?.data?.responseData;
        const role = localStorage.getItem("role");
        const farmerId = JSON.parse(localStorage.getItem("farmerId") || null);
        setFarmerId(farmerId);
        setIsFieldAgent(role === "fieldAgent");

        if (role === "fieldAgent") {
          setShowApprovalCTAs(false);
        } else {
          const fromPartner = orderData?.fromPartner;
          const toPartner = orderData?.toPartner;
          if (
            (fromPartner?.partnerId === farmerId &&
              fromPartner?.iptApprovalStatus === "PENDING") ||
            (toPartner?.partnerId === farmerId &&
              toPartner?.iptApprovalStatus === "PENDING")
          ) {
            setShowApprovalCTAs(true);
          } else if (
            (fromPartner?.partnerId === farmerId &&
              fromPartner?.iptApprovalStatus === "APPROVED") ||
            (toPartner?.partnerId === farmerId &&
              toPartner?.iptApprovalStatus === "APPROVED")
          ) {
            setPartnerApprovalStatus("APPROVED");
          } else {
            setPartnerApprovalStatus("REJECTED");
          }
        }

        const { axiosRequest } = InterPartyTransfersService.getIPTCart(
          orderData?.cartID
        );
        axiosRequest
          .then((cartResponse) => {
            setIPTOrderDetails({
              ...orderData,
              cart: cartResponse?.data?.responseData,
            });
            setLoading("done");
          })
          .catch((error) => {
            setLoading("done");
            setIPTOrderDetails(orderData);
            console.error("Failed to Get IPT Cart: ", error);
          });
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.error("Failed to Get IPT Order Details: ", error);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleIPTApprovalStatusUpdate = (action) => {
    setLoading("started");
    const requesterId =
      JSON.parse(localStorage.getItem(`fieldAgentUserId`)) ||
      JSON.parse(localStorage.getItem("farmerId"));

    const { axiosRequest } = InterPartyTransfersService.updateIPTStatus(
      action,
      iptOrderDetails?.id,
      requesterId
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          const orderData = response?.data?.responseData;
          setIPTOrderDetails({ ...orderData, cart: iptOrderDetails?.cart });

          if (
            !isFieldAgent &&
            ((orderData?.fromPartner?.partnerId === farmerId &&
              orderData?.fromPartner?.iptApprovalStatus === "PENDING") ||
              (orderData?.toPartner?.partnerId === farmerId &&
                orderData?.toPartner?.iptApprovalStatus === "PENDING"))
          ) {
            setShowApprovalCTAs(true);
          } else if (
            (orderData?.fromPartner?.partnerId === farmerId &&
              orderData?.fromPartner?.iptApprovalStatus === "APPROVED") ||
            (orderData?.toPartner?.partnerId === farmerId &&
              orderData?.toPartner?.iptApprovalStatus === "APPROVED")
          ) {
            setShowApprovalCTAs(false);
            setPartnerApprovalStatus("APPROVED");
            AnalyticsService.pushEvent(`IPT Request Approved`, {
              page: "IPT Details",
              requestId: orderId,
            });
          } else {
            setShowApprovalCTAs(false);
            setPartnerApprovalStatus("REJECTED");
            AnalyticsService.pushEvent(`IPT Request Rejected`, {
              page: "IPT Details",
              requestId: orderId,
            });
          }

          if (action === "APPROVE") {
            setOpenModal(true);
            setModalData({
              message: "You have successfully approved the transfer request.",
            });
          }
          if (action === "DISCARD") {
            navigate("/inter-party-transfers", { replace: true });
          }
        }
        setLoading("done");
      })
      .catch((error) => {
        setModalData({
          icon: <WarningAmberRounded sx={{ width: "55px", height: "55px" }} />,
          message:
            error?.response?.data?.message ||
            "Something went wrong, Please try again later!",
        });
        setOpenModal(true);
        setLoading("done");
        console.error("Error Updating IPT Status: ", error);
      });
  };

  const handleContinueRequest = () => {
    if (AndroidService.checkIfAndroid()) {
      if (
        parseInt(AndroidService.getAndroidVersion()?.appVersionCode) >=
        AndroidService.SCANNER_COMPATIBLE_VERSION
      ) {
        AndroidService.openScannerCameraActivity(
          `${config.appUrl}/inter-party-transfers/barcode-scan-details?iptRequestId=${iptOrderDetails?.id}&cartId=${iptOrderDetails?.cartID}&scanCode=`,
          `${config.appUrl}/inter-party-transfers/scanned-products?iptRequestId=${iptOrderDetails?.id}&cartId=${iptOrderDetails?.cartID}`
        );
      } else {
        setOpenAppUpdateDialog(true);
        AnalyticsService.pushEvent("App Update Required Dialog Shown", {
          page: "IPT Details",
        });
      }
    } else {
      navigate(
        `/inter-party-transfers/barcode-scan-details?iptRequestId=${iptOrderDetails?.id}&cartId=${iptOrderDetails?.cartID}&scanCode=`
      );
    }
  };

  const handleRejectRequest = () => {
    setOpenModal(true);
    setModalData({
      icon: <WarningAmberRounded sx={{ width: "55px", height: "55px" }} />,
      message: "Are you sure you want to reject the request ?",
      functions: {
        yes: () => {
          handleIPTApprovalStatusUpdate("REJECT");
          setOpenModal(false);
        },
      },
    });
  };

  return (
    <>
      <TopBar
        title={`IPT Request Details`}
        backNavigation={true}
        otherTextOrIconToShow={
          isFieldAgent &&
          (iptOrderDetails?.status === "DRAFT" ||
            iptOrderDetails?.status === "PENDING")
            ? {
                icon: <img src="/icons/delete.svg" alt="delete" />,
                function: () => {
                  AnalyticsService.pushEvent(`Delete Icon Clicked`, {
                    page: "IPT Details",
                    requestId: orderId,
                  });
                  setOpenModal(true);
                  setModalData({
                    icon: (
                      <WarningAmberRounded
                        sx={{ width: "55px", height: "55px" }}
                      />
                    ),
                    message: "Are you sure you want to delete the request ?",
                    functions: {
                      yes: () => {
                        AnalyticsService.pushEvent(`IPT Request Deleted`, {
                          page: "IPT Details",
                          requestId: orderId,
                        });
                        handleIPTApprovalStatusUpdate("DISCARD");
                        setOpenModal(false);
                      },
                    },
                  });
                },
              }
            : null
        }
      />

      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Loading...`}
        style={{ minHeight: "85vh" }}
        keepInView
      >
        {iptOrderDetails ? (
          <>
            <Container maxWidth={`xl`} sx={{ p: 2 }}>
              <Grid container className={classes.iptHeaderBox}>
                <Grid item xs={12} className={classes.iptRequestStatus}>
                  <div className={classes.tagAndIdsWrapper}>
                    <span className={classes.iptTag}>IPT</span>
                    <div style={{ fontSize: "12px" }}>
                      <div>Request ID: {iptOrderDetails?.id}</div>
                      {isFieldAgent && (
                        <div>
                          Order ID: {iptOrderDetails?.toPartner?.orderId || "-"}
                        </div>
                      )}
                    </div>
                  </div>
                  <span
                    className={classes.iptApprovalStatus}
                    style={{
                      color: iptStatus[iptOrderDetails?.status]?.color,
                      background: iptStatus[iptOrderDetails?.status]?.bgColor,
                    }}
                  >
                    {iptStatus[iptOrderDetails?.status]?.icon}
                    {iptStatus[iptOrderDetails?.status]?.text}
                  </span>
                </Grid>
                <Grid item xs={12} container sx={{ padding: "10px" }}>
                  <Grid item xs={6}>
                    Estimated Delivery
                  </Grid>
                  {!(iptOrderDetails?.fromPartner?.partnerId === farmerId) && (
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      Total Order Amount
                    </Grid>
                  )}
                  <Grid item xs={6} sx={{ fontWeight: 700 }}>
                    3-4 days
                  </Grid>
                  {!(iptOrderDetails?.fromPartner?.partnerId === farmerId) && (
                    <Grid
                      item
                      xs={6}
                      sx={{ fontWeight: 700, textAlign: "right" }}
                    >
                      ₹{iptOrderDetails?.cart?.grandTotal || 0}
                    </Grid>
                  )}
                </Grid>
                {iptOrderDetails?.remarks && isFieldAgent && (
                  <Grid item xs={12} className={classes.remarks}>
                    <Info sx={{ mr: "5px" }} />
                    <Clamp lines={2} maxLines={2}>
                      {iptOrderDetails?.remarks}
                    </Clamp>
                  </Grid>
                )}
              </Grid>

              {isFieldAgent ? (
                <>
                  <IPTDetailPagePartnerCard
                    heading="Transfer From"
                    partner={iptOrderDetails?.fromPartner}
                    showPartnerApprovalStatus={true}
                  />
                  <IPTDetailPagePartnerCard
                    heading="Transfer To"
                    partner={iptOrderDetails?.toPartner}
                    showPartnerApprovalStatus={true}
                  />
                </>
              ) : iptOrderDetails?.fromPartner?.partnerId === farmerId ? (
                <IPTDetailPagePartnerCard
                  heading="Transfer To"
                  partner={iptOrderDetails?.toPartner}
                />
              ) : (
                <IPTDetailPagePartnerCard
                  heading="Transfer From"
                  partner={iptOrderDetails?.fromPartner}
                />
              )}
            </Container>

            <Container maxWidth={`xl`} sx={{ padding: "0 0 50px" }}>
              <Typography
                variant="subtitle1"
                fontWeight={700}
                sx={{ ml: 2, mr: 2, lineHeight: 1.2 }}
              >
                Items in this Order
              </Typography>

              {iptOrderDetails?.cart?.products?.length ? (
                iptOrderDetails?.cart?.products?.map((product, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      margin: "8px 0 !important",
                      boxShadow: "none",
                      borderRadius: "0 !important",
                      "&::before": { display: "none" },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "12px 0 !important",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        padding: "12px 16px",
                        alignItems: "flex-start",
                        "& .MuiAccordionSummary-content": { margin: 0 },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          margin: "0 !important",
                        },
                      }}
                    >
                      <Grid container>
                        <Grid item xs={2}>
                          <img
                            src={product?.productImage || "/icons/dummy.jpeg"}
                            alt="product"
                            width="100%"
                          />
                        </Grid>
                        <Grid item xs={10} sx={{ pl: 1 }}>
                          <Clamp lines={2} maxLines={2}>
                            <Typography variant="subtitle2" fontWeight={400}>
                              {product?.productName}
                            </Typography>
                          </Clamp>

                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "400", color: "#999" }}
                          >
                            Quantity - {product?.quantity}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table style={{ width: "100%", textAlign: "left" }}>
                        <thead>
                          <tr>
                            <th className={classes.tableDataStyle}>LOT NO</th>
                            <th className={classes.tableDataStyle}>
                              EXPIRY DATE
                            </th>
                            <th className={classes.tableDataStyle}>QTY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {product?.cartItemLotcodes?.map((item, index) => (
                            <tr key={index}>
                              <td className={classes.tableDataStyle}>
                                {item?.lotNumber}
                              </td>
                              <td className={classes.tableDataStyle}>
                                {moment(item?.codes?.[0]?.expiryDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td className={classes.tableDataStyle}>
                                {item?.codes?.length}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography sx={{ margin: "16px", textAlign: "center" }}>
                  No products in cart
                </Typography>
              )}
            </Container>

            {iptOrderDetails?.status === "DRAFT" && (
              <Button
                fullWidth
                variant="contained"
                endIcon={<East />}
                onClick={handleContinueRequest}
                className={classes.continueRequestButton}
              >
                Continue Request
              </Button>
            )}

            {showApprovalCTAs && (
              <Box className={classes.partnerCTAAboutOrder}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: 0 }}
                  onClick={handleRejectRequest}
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ borderRadius: 0 }}
                  onClick={() => {
                    handleIPTApprovalStatusUpdate("APPROVE");
                  }}
                >
                  APPROVE
                </Button>
              </Box>
            )}

            {partnerApprovalStatus && (
              <div
                className={classes.partnerApprovalStatusInfo}
                style={{
                  color:
                    partnerApprovalStatus == "APPROVED" ? "#00733E" : "#9D2123",
                  background:
                    partnerApprovalStatus == "APPROVED" ? "#dbe6e1" : "#eadfdf",
                }}
              >
                <img
                  src={
                    partnerApprovalStatus == "APPROVED"
                      ? "/icons/like.svg"
                      : "/icons/dislike.svg"
                  }
                  style={{ marginRight: "5px" }}
                />
                <span>
                  You have
                  {partnerApprovalStatus == "APPROVED"
                    ? " approved "
                    : " rejected "}
                  this request
                </span>
              </div>
            )}

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <Box className={classes.modalBox}>
                {modalData?.icon ? (
                  modalData?.icon
                ) : (
                  <img src="/icons/star.svg" alt="star" />
                )}
                <Typography sx={{ mt: 1, mb: 2 }}>
                  {modalData?.message}
                </Typography>
                {modalData?.functions ? (
                  <div className={classes.buttonWrapper}>
                    <Button
                      variant="contained"
                      className={classes.modalButton}
                      onClick={() => setOpenModal(false)}
                    >
                      No
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.modalButton}
                      onClick={() => modalData?.functions?.yes()}
                    >
                      Yes
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.modalButton}
                    onClick={() => setOpenModal(false)}
                  >
                    Okay
                  </Button>
                )}
              </Box>
            </Modal>
          </>
        ) : (
          loading === "done" && (
            <Container maxWidth={`xl`} sx={{ padding: "16px" }}>
              <EmptyError text="Order Details Not Found." hideBack={true} />
            </Container>
          )
        )}
      </BlockUi>

      <AppVersionUnsupported
        open={openAppUpdateDialog}
        handleClose={() => {
          AnalyticsService.pushEvent("App Update Required Dialog Closed", {
            page: "Select Store",
          });
          setOpenAppUpdateDialog(false);
        }}
        page={window.location.pathname}
      />
    </>
  );
}
