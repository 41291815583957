import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import BrandsList from "../Brand/BrandsList";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import BrandService from "../../Services/BrandService";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F3F3F3",
    minHeight: window.innerHeight,
  },
  mainContent: {
    marginTop: theme.spacing(1),
  },
}));

function BrandsPage() {
  const classes = useStyles();
  const { categoryName } = useParams();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState("not_started");

  useEffect(() => {
    AnalyticsService.pushEvent(`All Brands Page Viewed`);
  }, []);

  useEffect(() => {
    setLoading("started");
    if (categoryName && categoryName !== "") {
      const { axiosRequest, abortController } =
        BrandService.getBrandsForCategory(categoryName);
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.brands?.items?.length > 0) {
            let brandsFromResponse = response.data.responseData.brands.items;
            setBrands(brandsFromResponse);
            setLoading("done");
          } else {
            console.log(`no brands found`);
            setLoading("done");
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting brands`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    } else {
      const { axiosRequest, abortController } = BrandService.getGlobalBrands();
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.brands?.length > 0) {
            let brandsFromResponse = response.data.responseData.brands;
            setBrands(brandsFromResponse);
            setLoading("done");
          } else {
            console.log(`no brands found`);
            setLoading("done");
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting brands`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    }
  }, [categoryName]);

  let title = `All Brands`;
  if (categoryName && categoryName !== "") {
    title = title + ` in ${categoryName}`;
  }
  return (
    <div className={classes.root}>
      <ErrorBoundary message={``}>
        <TopBar
          title={title}
          backNavigation={true}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <div className={classes.mainContent}>
        <ErrorBoundary message={``}>
          <div style={{ marginTop: "0px", padding: "15px" }}>
            <BrandsList
              loading={loading}
              brands={brands}
              categoryName={categoryName}
              page={`${title} Page`}
            />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default BrandsPage;
