import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Clamp from "react-multiline-clamp";

import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

import TopBar from "../Common/TopBar";
import CatalogService from "../../Services/CatalogService";
import UiSearch from "../Common/UiSearch";
import BlockUi from "react-block-ui";
import EmptyError from "../EmptyError/EmptyError";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles({
  cropCard: {
    minWidth: "31%",
    maxWidth: "31%",
    textAlign: "center",
    padding: "10px 5px",
    marginBottom: "10px",
    color: "#333",
    background: "#fff",
    borderRadius: "8px",
  },
  cropImage: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    border: "2px solid #fff",
  },
});

export default function CropListPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [crops, setCrops] = useState([]);
  const [cropListToShow, setCropListToShow] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState("not-started");

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, { page: "Crop Selection" });
    setLoading("started");
    const fieldAgentSource = localStorage.getItem(`fieldAgentSource`);
    const { axiosRequest, abortController } =
      CatalogService.getCropList(fieldAgentSource);
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          const allCrops = [];
          response?.data?.responseData?.children?.forEach((category) => {
            allCrops.push(...category?.children);
          });
          setCrops(allCrops);
          setCropListToShow(allCrops);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.error("Error getting Crop List", error?.message);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleCropClick = (cropName) => {
    navigate(`/krishi-gyan?crop=${cropName}`);
    AnalyticsService.pushEvent(`Card Clicked`, { type: "Crop", cropName });
  };

  const setSearchValueDebounce = (searchQuery) => {
    setSearchTerm(searchQuery);
    setCropListToShow(
      crops?.filter((crop) =>
        crop?.category?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )
    );
  };

  return (
    <div>
      <TopBar title={`Crop Selection`} backNavigation={true} />

      <UiSearch
        searchValue={searchTerm}
        setSearchValue={setSearchValueDebounce}
        shouldBlockSendSearchQuerySentEvent={true}
        placeholderText="Search Crop"
      />

      <Typography fontWeight={700} sx={{ padding: 2 }}>
        Select a Crop
      </Typography>

      <BlockUi
        keepInView
        tag="div"
        message="Loading..."
        blocking={loading === "started"}
        style={{ minHeight: "80vh", marginBottom: "20px" }}
      >
        {cropListToShow.length ? (
          <Grid container gap="3%" sx={{ padding: "0 16px 16px" }}>
            {cropListToShow.map((crop) => (
              <Grid
                item
                key={crop?.id}
                className={classes.cropCard}
                onClick={() => handleCropClick(crop?.category)}
              >
                <img
                  src={crop?.image}
                  alt={crop?.display}
                  className={classes.cropImage}
                />
                <Clamp lines={2} maxLines={2}>
                  <Typography fontSize={14}>{crop?.display}</Typography>
                </Clamp>
              </Grid>
            ))}
          </Grid>
        ) : (
          loading === "done" && (
            <EmptyError
              hideBack={true}
              subText="No crops found."
              extraStyle={{ minHeight: `75vh`, background: "#FFFFFF" }}
            />
          )
        )}
      </BlockUi>
    </div>
  );
}
