import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TopBar from "../Common/TopBar";
import { Box, Chip, Skeleton, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import MyFarmerCard from "./MyFarmerCard";
import AnalyticsService from "../../Services/AnalyticsService";
import FarmersService from "../../Services/FarmersService";
import _ from "lodash";
import BlockUi from "react-block-ui";
import InfiniteScroll from "react-infinite-scroll-component";
import BannerService from "../../Services/BannerService";
import Banner from "../Offers/Banner";
import MyFarmersSummary from "./MyFarmersSummary";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: window.innerHeight,
    fontSize: "14px",
    background: "#f3f3f3",
  },
  heading: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "700",
    color: "#333333",
    marginTop: theme.spacing(2.5),
  },
  container: {
    paddingBottom: theme.spacing(3.75),
  },
  blockUI: {
    height: "100vh",
  },
  noFarmersFound: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  infiniteScrollLoader: {
    textAlign: "center",
    fontSize: "16px",
  },
  bannerWrapper: {
    marginBottom: theme.spacing(1.5),
  },
  filters: {
    background: "#FFFFFF",
  },
  myFarmersSummaryWrapper: {
    padding: theme.spacing(0, 2, 1.75, 2),
  },
  filterWrapper: {
    padding: theme.spacing(1.75, 0, 1.25, 2),
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  selectedFilter: {
    border: "none",
    backgroundColor: "#9D2123",
    color: "#FFFFFF",
  },
  unselectedFilter: {
    border: "1px solid #9D2123",
    backgroundColor: "#FFFFFF",
    color: "#9D2123",
  },
}));

const FILTERS = [
  { label: "This Month", identifier: "this_month" },
  { label: "Last Month", identifier: "last_month" },
  { label: "Last 3 months", identifier: "last_3_months" },
  { label: "This Year", identifier: "this_year" },
  { label: "All Time", identifier: "" },
];

const MyFarmersPage = () => {
  const classes = useStyles();
  const [myFarmers, setMyFarmers] = useState([]);
  const [totalFarmers, setTotalFarmers] = useState(0);
  const [loading, setLoading] = useState("not_started");
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [globalBanners, setGlobalBanners] = useState([]);
  const [loadingBanners, setLoadingBanners] = useState("not_started");
  const [farmersSummaryFilter, setFarmersSummaryFilter] = useState(FILTERS[0]);
  const [farmersSummary, setFarmersSummary] = useState(null);
  const [loadingFarmersSummary, setLoadingFarmersSummary] =
    useState("not_started");
  const [errorFarmerSummary, setErrorFarmerSummary] = useState(null);

  const fetchMoreFarmers = () => {
    const { axiosRequest } = FarmersService.getMyFarmers(currentPage + 1);
    axiosRequest
      .then((response) => {
        if (
          response?.data?.responseData?.farmers?.length > 0 &&
          response?.data?.responseData?.totalFarmers
        ) {
          let fetchedFarmers = response.data.responseData.farmers;
          setMyFarmers((prevFarmers) => [...prevFarmers, ...fetchedFarmers]);
        } else {
          console.log(`no farmers found`);
        }
      })
      .catch((error) => {
        console.log(`error getting farmers`);
        if (error.data) {
          console.log(error.data);
        } else {
          console.log(error);
        }
      });

    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });
  };

  useEffect(() => {
    setLoading("started");
    const { axiosRequest, abortController } =
      FarmersService.getMyFarmers(currentPage);
    axiosRequest
      .then((response) => {
        if (
          response?.data?.responseData?.farmers?.length > 0 &&
          response?.data?.responseData?.totalFarmers
        ) {
          setMyFarmers(response.data.responseData.farmers);
          setTotalFarmers(response.data.responseData.totalFarmers);
          setLoading("done");
        } else {
          console.log(`no farmers found`);
          setLoading("done");
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting farmers`);
          if (error.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setLoading("done");
        }
      });
    AnalyticsService.pushEvent(`My Farmers Page Viewed`, {
      page: `/my-farmers`,
    });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    setLoadingBanners("started");
    const { axiosRequest, abortController } = BannerService.getBannersData(
      `SCAN`,
      localStorage.getItem("district")
    );
    axiosRequest
      .then((response) => {
        let carouselScrollableBanners = [];
        _.map(
          response?.data?.responseData?.scrollableBanners,
          (bannerData, index) => {
            let bannerObj = getScrollableBanners(bannerData);
            carouselScrollableBanners.push(bannerObj);
          }
        );

        carouselScrollableBanners = _.filter(
          carouselScrollableBanners,
          (scrollableBanner) => {
            return scrollableBanner.description === "CAROUSEL";
          }
        );
        carouselScrollableBanners = carouselScrollableBanners.sort(
          (a, b) => parseInt(a.priority) - parseInt(b.priority)
        );
        carouselScrollableBanners = carouselScrollableBanners.slice(0, 1);

        setGlobalBanners(carouselScrollableBanners);
        setLoadingBanners("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting banners`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoadingBanners("done");
        }
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    setLoadingFarmersSummary("started");
    const { axiosRequest, abortController } =
      FarmersService.getMyFarmersSummary(farmersSummaryFilter.identifier);
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setFarmersSummary(response.data.responseData);
          setLoadingFarmersSummary("done");
        } else {
          console.log(`no my farmers summary found`);
          setLoadingFarmersSummary("done");
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting my farmers summary`);
          if (error.data) {
            setErrorFarmerSummary(error.data);
            console.log(error.data);
          } else {
            setErrorFarmerSummary(error);
            console.log(error);
          }
          setLoadingFarmersSummary("done");
        }
      });
    return () => abortController.abort();
  }, [farmersSummaryFilter]);

  const getScrollableBanners = (bannerData) => {
    let scrollableBannerMedia = bannerData?.media;
    let scrollableBannerData = null;
    if (bannerData?.data) {
      scrollableBannerData = bannerData?.data;
      _.forEach(scrollableBannerMedia, (media, index) => {
        if (scrollableBannerData[index]) {
          media.data = scrollableBannerData[index];
        }
      });
    }
    scrollableBannerMedia = _.filter(scrollableBannerMedia, (media) => {
      return media.type === `IMAGE`;
    });
    scrollableBannerMedia["description"] = bannerData?.description;
    scrollableBannerMedia["bannerType"] = bannerData?.type?.toUpperCase();
    scrollableBannerMedia["priority"] = bannerData?.priority;
    scrollableBannerMedia["name"] = bannerData?.name;
    return scrollableBannerMedia;
  };

  const handleFilterClick = (selectedFilter) => {
    AnalyticsService.pushEvent(`My Farmers Filter Clicked`, {
      page: `/my-farmers`,
      duration: selectedFilter.label,
    });
    setFarmersSummaryFilter(selectedFilter);
  };

  return (
    <div className={classes.main}>
      <TopBar backNavigation={true} title={`My Farmers`} />
      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        className={classes.blockUI}
      >
        {loadingBanners === "started" && (
          <Skeleton
            variant={`rectangular`}
            height={175}
            style={{ margin: "16px" }}
          />
        )}
        {loadingBanners === "done" && globalBanners.length > 0 && (
          <Box className={classes.bannerWrapper}>
            <Banner
              globalBanners={globalBanners}
              loadingBanners={loadingBanners}
              page={`/my-farmers`}
            />
          </Box>
        )}

        {!errorFarmerSummary && (
          <Box className={classes.filters}>
            <Box className={classes.filterWrapper}>
              <Stack direction="row" spacing={1}>
                {_.map(FILTERS, (filter, index) => {
                  return (
                    <Chip
                      key={index}
                      clickable={false}
                      label={filter.label}
                      onClick={() => {
                        handleFilterClick(filter);
                      }}
                      className={
                        farmersSummaryFilter.identifier === filter.identifier
                          ? `${classes.selectedFilter}`
                          : `${classes.unselectedFilter}`
                      }
                    />
                  );
                })}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Stack>
            </Box>
            <Box className={classes.myFarmersSummaryWrapper}>
              <MyFarmersSummary
                farmersSummary={farmersSummary}
                loadingFarmersSummary={loadingFarmersSummary}
              />
            </Box>
          </Box>
        )}

        <Container maxWidth={`xl`} className={classes.container}>
          {loading === "done" && !error && myFarmers.length > 0 && (
            <>
              <Typography
                className={classes.heading}
              >{`Farmer Details (All Time)`}</Typography>
              <InfiniteScroll
                dataLength={myFarmers.length}
                next={fetchMoreFarmers}
                hasMore={myFarmers.length < totalFarmers}
                loader={
                  <h4 className={classes.infiniteScrollLoader}>Loading...</h4>
                }
              >
                {_.map(myFarmers, (myFarmer, index) => (
                  <MyFarmerCard myFarmer={myFarmer} key={index} />
                ))}
              </InfiniteScroll>
            </>
          )}

          {loading === "done" && !error && myFarmers.length === 0 && (
            <>
              <Typography
                className={classes.heading}
              >{`Farmer Details (All Time)`}</Typography>
              <Box className={classes.noFarmersFound}>
                <img src={`/icons/EmptyPage.svg`} alt="" height={80} />
                <Typography>{`No farmers found`}</Typography>
              </Box>
            </>
          )}
        </Container>
      </BlockUi>
    </div>
  );
};

export default MyFarmersPage;
