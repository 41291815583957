import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import {
  Alert,
  Box,
  Button,
  CardContent,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { FilePond, registerPlugin } from "react-filepond";
import { useNavigate } from "react-router-dom";
import useMessages from "../../Hooks/useMessages";
import useQuery from "../../Hooks/useQuery";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import CommonConstantValues from "../../Services/CommonConstantValues";
import Languages from "../../Services/LanguageCode";
import TicketService from "../../Services/TicketService";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import EmptyError from "../EmptyError/EmptyError";
import SelectedComplaintProductCard from "./SelectedComplaintProductCard";
import "./style.css";
import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import PartnerService from "../../Services/PartnerService";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

function CreateComplaint() {
  const { getMessage } = useMessages();
  const query = useQuery();
  const issueType = query.get("issueType");
  const [selectedIssue, setSelectedIssue] = useState("");
  const [issuesList, setIssueList] = useState([]);
  const [reasonsList, setReasonsList] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileSizeErrorMessage, setFileSizeErrorMessage] = useState("");
  const [loading, setLoading] = useState(CommonConstantValues.NOT_STARTED);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showAddAttachmentButton, setShowAddAttachmentButton] = useState(true);
  const [complaintCreationProcessStatus, setComplaintCreationProcessStatus] =
    useState(CommonConstantValues.NOT_STARTED);
  const [descriptionValue, setDescriptionValue] = useState("");
  const [showCreateComplaintError, setShowCreateComplaintError] =
    useState(false);
  let navigate = useNavigate();
  const productLevelIssueNameEN = "Product Related Issues";
  const [selectedProducts, setSelectedProducts] = useState(
    JSON.parse(localStorage.getItem(`complaintProducts`)) || []
  );
  const pageName = "Create Complaints";

  const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "en";

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (appVersionCode && parseInt(appVersionCode) < 20) {
          setShowAddAttachmentButton(false);
        }
      }
    }

    setLoading(CommonConstantValues.STARTED);
    const { axiosRequest, abortController } =
      TicketService.getComplaintReasonsAndIssues(languageCode);
    axiosRequest
      .then(({ data }) => {
        const pageViewedEventData = {
          page: "Create Complaints",
        };
        AnalyticsService.pushEvent(`Page Viewed`, pageViewedEventData);

        setLoading(CommonConstantValues.DONE);
        if (data?.responseData?.profileLevelReasons?.length) {
          const issues = data?.responseData?.profileLevelReasons;
          setIssueList(issues);
          let issueToSelect = null;
          if (issueType) {
            const issue = _.find(
              issues,
              (issue) => issue.issueNameEN === issueType
            );
            issueToSelect = issue;
          }
          if (!issueToSelect && selectedProducts.length) {
            const issue = _.find(
              issues,
              (issue) => issue.issueNameEN === productLevelIssueNameEN
            );
            issueToSelect = issue;
          }
          if (issueToSelect) {
            setSelectedIssue(issueToSelect);
          }
        } else {
          setShowCreateComplaintError(true);
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setShowCreateComplaintError(true);
          setLoading(CommonConstantValues.DONE);
        }
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    let selectedIssueReason = _.find(
      issuesList,
      (item) => item?.issueName === selectedIssue?.issueName
    );
    setReasonsList(selectedIssueReason?.reasons || []);
    setSelectedReasons([]);
  }, [selectedIssue?.issueName]);

  const handleSelectReason = (event) => {
    const selectedReasonName = JSON.parse(event.target.value);

    const selectReasonEventData = {
      page: pageName,
      SamasyaOptionType: "Reason",
      SamasyaOptionName: selectedReasonName?.reasonNameEN,
    };
    AnalyticsService.pushEvent(
      `Samasya Options Clicked`,
      selectReasonEventData
    );

    if (event.target.checked) {
      setSelectedReasons((previousSelectedReasons) => [
        ...previousSelectedReasons,
        selectedReasonName,
      ]);
    } else {
      setSelectedReasons((previousSelectedReasons) =>
        _.filter(
          previousSelectedReasons,
          (item) => item.reasonId !== selectedReasonName.reasonId
        )
      );
    }
  };

  const handleChangeDescriptionValue = (descriptionValue) => {
    setDescriptionValue(descriptionValue);
  };

  const handleSelectProductsClick = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      page: pageName,
      type: "Select Products",
    });
    navigate(`/complaints/select-products`);
  };

  const handleRemoveProduct = (product) => {
    AnalyticsService.pushEvent("Button Clicked", {
      page: pageName,
      type: "Remove Product",
      skuCode: product.skuCode,
    });
    const newSelectedProducts = _.filter(
      selectedProducts,
      (selectedProduct) => product.skuCode !== selectedProduct.skuCode
    );
    setSelectedProducts(newSelectedProducts);
    localStorage.setItem(
      `complaintProducts`,
      JSON.stringify(newSelectedProducts)
    );
  };

  function removeHashTag(text) {
    if (text.includes("#")) {
      return text.replace(/#/g, "");
    } else {
      return text;
    }
  }

  const handleSubmitButton = () => {
    setComplaintCreationProcessStatus(CommonConstantValues.STARTED);
    const formData = new FormData();
    const fieldAgentPhoneNumber = localStorage.getItem(
      "fieldAgentMobileNumber"
    );

    formData.append("farmerId", Number(localStorage.getItem(`farmerId`)));
    formData.append("phoneNum", localStorage.getItem("mobile"));
    formData.append("IsB2BCustomer", true);
    formData.append("issueId", selectedIssue?.issueId);
    if (fieldAgentPhoneNumber) {
      formData.append("agentPhoneNo", fieldAgentPhoneNumber);
    }
    _.forEach(files, (fileItem) => {
      formData.append("file", fileItem.file, fileItem.file.name);
    });

    _.forEach(selectedReasons, ({ reasonId }) => {
      formData.append("reasons", reasonId);
    });

    const selectedProductsDto = [];
    _.forEach(selectedProducts, (selectedProduct) => {
      const selectedProductDto = {};
      selectedProductDto.skuCode = selectedProduct.skuCode;
      selectedProductDto.productName = selectedProduct.productName;
      selectedProductDto.quantity = selectedProduct.quantity;
      selectedProductDto.reasons = selectedProduct.reasons
        .map((reason) => {
          return reason.reasonNameEN;
        })
        .toString();
      selectedProductsDto.push(selectedProductDto);
    });
    if (
      selectedIssue?.issueNameEN === productLevelIssueNameEN &&
      selectedProducts.length
    ) {
      formData.append("orderItemMeta", JSON.stringify(selectedProductsDto));
    }

    if (
      selectedIssue?.issueNameEN === productLevelIssueNameEN &&
      selectedProducts.length
    ) {
      const skuTableData = _.map(
        selectedProducts,
        ({ skuCode, productName, quantity, reasons }) => [
          skuCode,
          productName,
          quantity,
          _.map(reasons, (reason) => reason.reasonNameEN),
        ]
      );

      skuTableData.unshift(["SKU", "Product", "Qty", ["Reason"]]);
      const productComplaintsTableData = skuTableData.reduce((acc, current) => {
        if (current[3].length > 1) {
          let flag = false;
          for (let reason of current[3]) {
            if (!flag) {
              acc.push([
                { data: current[0], rowspan: current[3].length },
                { data: current[1], rowspan: current[3].length },
                { data: current[2], rowspan: current[3].length },
                { data: reason },
              ]);
              flag = true;
            } else {
              acc.push([{ data: reason }]);
            }
          }
        } else {
          acc.push([
            { data: current[0] },
            { data: current[1] },
            { data: current[2] },
            { data: current[3] },
          ]);
        }
        return acc;
      }, []);
      const productComplaintsTable =
        (descriptionValue || "") + makeTableHTML(productComplaintsTableData);
      formData.append("remarks", productComplaintsTable);
    } else {
      formData.append("remarks", descriptionValue);
    }
    formData.append("remarksText", descriptionValue);

    const { axiosRequest } = TicketService.createComplaint({
      ticketDetails: formData,
    });
    axiosRequest
      .then(({ data }) => {
        const submitButtonClickedEventData = {
          page: pageName,
          issueName: selectedIssue?.issueName,
          attachment: files?.length ? "Yes" : "No",
        };
        if (selectedReasons) {
          submitButtonClickedEventData.reasonName = selectedReasons
            .map((reason) => reason?.reasonNameEN)
            .join(",");
        }
        if (selectedProducts) {
          submitButtonClickedEventData.products = selectedProducts
            .map((product) => product.skuCode)
            .join(",");
        }
        AnalyticsService.pushEvent(
          `Samasya Submit Button Clicked`,
          submitButtonClickedEventData
        );

        setComplaintCreationProcessStatus(CommonConstantValues.DONE);
        const ticketId = removeHashTag(data?.responseData?.ticketId) || "";
        navigate(`/complaints/successful/${ticketId}`, { replace: true });
        localStorage.setItem("createComplaintInitiated", true);
        localStorage.removeItem("complaintProducts");
      })
      .catch((error) => {
        setShowCreateComplaintError(true);
        setComplaintCreationProcessStatus(CommonConstantValues.DONE);
        if (error?.response?.status === 413) {
          setFileSizeErrorMessage("File size is too large");
        }
      });
  };

  const makeTableHTML = (tableDataArray) => {
    let result = '<br/><br/><table border=1 style="border-collapse:collapse;">';
    for (let i = 0; i < tableDataArray.length; i++) {
      result += i === 0 ? '<tr bgColor="grey" style="color:whilte;">' : "<tr>";
      for (let j = 0; j < tableDataArray[i].length; j++) {
        result +=
          `<td rowspan=${tableDataArray[i][j].rowspan || 1}>&nbsp;` +
          tableDataArray[i][j].data +
          `&nbsp;</td>`;
      }
      result += "</tr>";
    }
    result += "</table><br/>";
    return result;
  };

  const handleUpdateFile = (fileItems) => {
    const isValidFile = _.some(
      fileItems,
      (fileItem) =>
        fileItem.fileSize >
        CommonConstantValues.MAX_FILE_SIZE_FOR_COMPLAINT_ATTACHMENT
    );

    if (isValidFile) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
    setFiles(fileItems);
  };

  const handleRemoveFile = () => {
    const removedAttachmentEventData = {
      page: pageName,
    };
    AnalyticsService.pushEvent(
      `Remove Attachment Clicked`,
      removedAttachmentEventData
    );
  };

  const handleClickAddAttachmentButton = () => {
    const clickedAddMoreProductEventData = {
      page: pageName,
    };
    AnalyticsService.pushEvent(
      `Add Attachment Clicked`,
      clickedAddMoreProductEventData
    );
  };

  const handleSelectIssueName = (issueId, issueName, issueNameEN, reasons) => {
    setSelectedIssue({ issueId, issueName, issueNameEN, reasons });
    const selectIssueEventData = {
      page: pageName,
      SamasyaOptionType: "Issue",
      SamasyaOptionName: issueName,
      SamasyaOptionNameEN: issueNameEN,
    };
    AnalyticsService.pushEvent(`Samasya Options Clicked`, selectIssueEventData);
  };

  const handleSamasyaTutorialVideoClicked = () => {
    AnalyticsService.pushEvent(`Banner Clicked`, {
      page: pageName,
      type: "Samasya Tutorial",
      youtubeId: "F5_TbtxyqGI",
    });
    navigate("/featured-videos?youtubeId=F5_TbtxyqGI");
  };
  return (
    <>
      <ErrorBoundary>
        <TopBar
          title={getMessage("createComplaint.createComplaintHeader")}
          backNavigation={true}
          showHisaab={true}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <BlockUi
          tag="div"
          blocking={
            complaintCreationProcessStatus === CommonConstantValues.STARTED &&
            complaintCreationProcessStatus !== CommonConstantValues.DONE
          }
          message={`Registering Complaint...`}
          keepInView
        >
          {loading === CommonConstantValues.STARTED && (
            <CardContent>
              <Skeleton
                variant="rectangular"
                height={125}
                sx={{ marginTop: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                height={125}
                sx={{ marginTop: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                height={125}
                sx={{ marginTop: "10px" }}
              />
            </CardContent>
          )}
          {loading === CommonConstantValues.DONE && issuesList.length ? (
            <>
              <Box sx={{ background: "#fff", padding: "11px 0px" }}>
                <Container>
                  <Box>
                    <Box style={{ margin: "3px 10px" }}>
                      <Typography sx={{ fontWeight: "700" }}>
                        {getMessage(`createComplaint.selectIssueHeader`)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {_.map(
                        issuesList,
                        ({ issueId, issueName, issueNameEN, reasons }) => {
                          const checked =
                            selectedIssue?.issueName === issueName;
                          if (
                            !PartnerService.checkIfFieldAgent() &&
                            issueNameEN === "Product Related Issues"
                          ) {
                            return null;
                          }
                          return (
                            <Chip
                              key={issueName}
                              variant={checked ? "soft" : "plain"}
                              label={issueName}
                              sx={{
                                backgroundColor: checked
                                  ? "#9D2123 !important"
                                  : "white",
                                color: checked ? "#fff" : "#9D2123",
                                border: "1px solid #9D2123",
                                minWidth: "80px",
                                margin: "4px",
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                              clickable={true}
                              onClick={() =>
                                handleSelectIssueName(
                                  issueId,
                                  issueName,
                                  issueNameEN,
                                  reasons
                                )
                              }
                            />
                          );
                        }
                      )}
                    </Box>
                  </Box>
                </Container>
              </Box>

              <Container maxWidth={"xl"} sx={{ padding: "0px" }}>
                <Box
                  sx={{
                    padding: "10px 10px 0px",
                  }}
                >
                  <img
                    src={`/icons/samasyaVideo_${languageCode}.svg`}
                    onClick={() => handleSamasyaTutorialVideoClicked()}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </Box>
              </Container>

              {selectedIssue === "" && (
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "auto",
                    flexDirection: "column",
                    padding: "60px 0px",
                    minHeight: "calc(50vh - 1rem)",
                  }}
                >
                  <img src={`/icons/EmptyPage.svg`} alt="" />
                  <Typography>
                    {getMessage(`createComplaint.selectSamasyaText`)}
                  </Typography>
                </Box>
              )}
              {selectedIssue?.issueName && (
                <Container sx={{ paddingBottom: "100px" }}>
                  {selectedIssue?.issueNameEN !== productLevelIssueNameEN && (
                    <Box>
                      <FormGroup>
                        <Box
                          sx={{
                            margin: "11px 0px 6px 5px",
                          }}
                        >
                          <Typography sx={{ fontWeight: "700" }}>
                            {getMessage(`createComplaint.selectReasonHeader`)}
                          </Typography>
                        </Box>
                        {_.map(reasonsList, ({ reasonName, reasonId }) => (
                          <FormControlLabel
                            key={reasonName}
                            control={
                              <Checkbox
                                checked={_.some(
                                  selectedReasons,
                                  (reasonDetail) =>
                                    reasonDetail.reasonName === reasonName
                                )}
                                onChange={handleSelectReason}
                                value={JSON.stringify({ reasonName, reasonId })}
                              />
                            }
                            label={reasonName}
                            labelPlacement="start"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              backgroundColor: "white",
                              margin: "4px 0px",
                              padding: "0px 10px",
                              borderRadius: "16px",
                              boxShadow:
                                "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                                marginLeft: "7px",
                              },
                            }}
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  )}

                  {selectedIssue?.issueNameEN === productLevelIssueNameEN && (
                    <Box>
                      {selectedProducts?.length ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#333",
                            }}
                          >
                            {getMessage(
                              `createComplaint.selectedProductsTitle`
                            )}
                          </Typography>
                          {_.map(selectedProducts, (product) => {
                            return (
                              <SelectedComplaintProductCard
                                key={product.skuCode}
                                product={product}
                                handleRemoveProduct={handleRemoveProduct}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src="/icons/selectProducts.svg"
                            alt="Select Products"
                            width="72px"
                            style={{
                              marginTop: "32px",
                            }}
                          />
                          <Typography
                            textAlign={`center`}
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              marginTop: "10px",
                            }}
                          >
                            {getMessage(`createComplaint.selectProductsTitle`)}
                          </Typography>
                          <Typography
                            textAlign={`center`}
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "18px",
                              margin: "8px 28px 16px 28px",
                            }}
                          >
                            {getMessage(
                              `createComplaint.selectProductsDescription`
                            )}
                          </Typography>
                        </Box>
                      )}
                      <Button
                        fullWidth
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: "16px",
                          padding: "13px 32px",
                          background: "white",
                          margin: "12px 0px",
                        }}
                        onClick={handleSelectProductsClick}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            marginRight: "4px",
                          }}
                        >
                          {getMessage(`createComplaint.selectProducts`)}
                        </Typography>
                        <ArrowForwardTwoToneIcon fontSize="small" />
                      </Button>
                    </Box>
                  )}

                  {(selectedIssue.issueNameEN !== productLevelIssueNameEN ||
                    selectedProducts?.length > 0) && (
                    <Box sx={{ margin: "12px 0px" }}>
                      <Box sx={{ margin: "3px 5px" }}>
                        <Typography sx={{ fontWeight: "700" }}>
                          {getMessage(`createComplaint.descriptionHeader`)}
                        </Typography>
                      </Box>
                      <textarea
                        rows="4"
                        name="description"
                        placeholder={getMessage(
                          `createComplaint.descriptionPlaceHolder`
                        )}
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "1px solid #EEEEEE",
                          fontFamily: "Roboto",
                          padding: "5px",
                        }}
                        onChange={(event) =>
                          handleChangeDescriptionValue(event.target.value)
                        }
                      />
                    </Box>
                  )}

                  {showAddAttachmentButton &&
                  (selectedIssue.issueNameEN !== productLevelIssueNameEN ||
                    selectedProducts?.length > 0) ? (
                    <>
                      <Typography
                        sx={{
                          textAlign: "center",
                          marginTop: "21px",
                          fontSize: "12px",
                          marginBottom: "-13px",
                        }}
                      >
                        {getMessage(`createComplaint.addAttachmentWarning`)}
                      </Typography>

                      {files.length >=
                      CommonConstantValues.COMPLAINTS_ATTACHMENTS_MAX_FILES_LIMIT ? (
                        <Button
                          fullWidth
                          variant={"outlined"}
                          disabled={true}
                          sx={{
                            background: "white",
                            marginTop: "30px",
                            borderRadius: "10px",
                            height: "40px",
                            textTransform: "none",
                          }}
                        >
                          Only{" "}
                          {
                            CommonConstantValues.COMPLAINTS_ATTACHMENTS_MAX_FILES_LIMIT
                          }{" "}
                          attachments are supported
                        </Button>
                      ) : (
                        <></>
                      )}

                      <Box
                        sx={{
                          background: "#FFFFFF",
                          color: "#9D2123",
                          borderRadius: "10px",
                          marginTop: "17px",
                        }}
                      >
                        <FilePond
                          files={files}
                          allowFileSizeValidation={true}
                          maxFileSize={
                            CommonConstantValues.MAX_FILE_SIZE_FOR_COMPLAINT_ATTACHMENT
                          }
                          labelMaxFileSizeExceeded={"File is too large"}
                          allowReorder={false}
                          allowMultiple={true}
                          onupdatefiles={handleUpdateFile}
                          onremovefile={handleRemoveFile}
                          onaddfile={handleClickAddAttachmentButton}
                          labelMaxFileSize="Max file size is 10 MB"
                          labelIdle={`<b>${getMessage(
                            "createComplaint.addAttachmentsButtonHeader"
                          )}</b>`}
                          maxFiles={
                            CommonConstantValues.COMPLAINTS_ATTACHMENTS_MAX_FILES_LIMIT
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Container>
              )}

              <Box
                sx={{
                  position: "fixed",
                  bottom: "0px",
                  width: "100%",
                  marginTop: 2,
                  maxWidth: "600px",
                }}
              >
                <Button
                  fullWidth
                  variant={"contained"}
                  disabled={
                    (!selectedReasons?.length && !selectedProducts?.length) ||
                    !selectedIssue?.issueName ||
                    disableSubmitButton
                  }
                  sx={{
                    height: "50px",
                    borderRadius: "0px",
                    background:
                      (!selectedReasons?.length && !selectedProducts?.length) ||
                      !selectedIssue?.issueName ||
                      disableSubmitButton
                        ? "#CACACA !important"
                        : "#9D2123",
                  }}
                  onClick={handleSubmitButton}
                >
                  {getMessage(`createComplaint.submitButton`)}
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
          {loading === CommonConstantValues.DONE && issuesList.length === 0 ? (
            <EmptyError image="" text={CommonConstantValues.ERROR_MESSAGE} />
          ) : (
            <></>
          )}
        </BlockUi>
      </ErrorBoundary>
      <Snackbar
        open={showCreateComplaintError}
        autoHideDuration={5000}
        onClose={() => setShowCreateComplaintError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          marginBottom: "55px",
        }}
      >
        <Alert
          icon={false}
          sx={{
            background: "#f26b6b",
            color: "#ffffff",
            width: "328px",
          }}
          onClose={() => setShowCreateComplaintError(false)}
        >
          {fileSizeErrorMessage
            ? fileSizeErrorMessage
            : CommonConstantValues.ERROR_MESSAGE}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateComplaint;
