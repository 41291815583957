import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { Container, Button, IconButton, Snackbar, Alert } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";
import IETFLanguages from "../../Services/IETFLanguageCode";
import config from "../../config";
import useQuery from "../../Hooks/useQuery";

import useFocus from "../../Hooks/useFocus";
import _ from "lodash";
import ProductService from "../../Services/ProductService";
import CommonConstantValues from "../../Services/CommonConstantValues";
import ImageSearchInformationDialog from "../Common/ImageSearchInformationDialog";
import DocumentService from "../../Services/DocumentService";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";

const useStyles = makeStyles((theme) => ({
  MuiOutlinedInput: {
    borderRadius: "10px",
  },
}));

function Search(props) {
  let navigate = useNavigate();
  let classes = useStyles();
  const debounceValue = props?.debounceValue || 1000;

  const navigateToSearchRoute =
    props?.navigateToSearchRoute !== undefined
      ? props?.navigateToSearchRoute
      : true;
  const replaceCurrentUrl =
    props?.replaceCurrentUrl !== undefined ? props?.replaceCurrentUrl : true;

  const [showModal, setShowModal] = useState(false);
  const [
    isVoiceSearchCompatibleAndroidAppVersion,
    setIsVoiceSearchCompatibleAndroidAppVersion,
  ] = useState(false);
  const [
    isImageSearchCompatibleAndroidAppVersion,
    setIsImageSearchCompatibleAndroidAppVersion,
  ] = useState(false);

  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);
  const query = useQuery();
  const productSearchImageUrl = query.get("imageUrl");
  const searchType = query.get("searchType");
  const [inputRef, setInputFocus] = useFocus();
  const [showError, setShowError] = useState(false);
  const targetImageWidth = 720;
  const targetImageHeight = 720;
  const targetImageQuality = 100;
  const shouldCompress = true;

  const handleImageSearch = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    setShowModal(true);
    AnalyticsService.pushEvent(`Image Search Clicked`);
  };
  const handleVoiceSearch = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    if (AndroidService.checkIfAndroid) {
      const ietfLanguageCode = IETFLanguages[
        localStorage.getItem("state")?.toUpperCase()
      ]
        ? IETFLanguages[localStorage.getItem("state")?.toUpperCase()]
        : "hi-IN";
      const voiceSearchPrompt = ProductService.getVoiceSearchPromptText(
        localStorage.getItem("source")?.toUpperCase()
          ? localStorage.getItem("source")?.toUpperCase()
          : "ENGLISH"
      );
      if (props?.setShowSuggestions) {
        props?.setShowSuggestions(true);
      }

      AndroidService.startSpeechToText(
        ietfLanguageCode,
        `${config.appUrl}/search`,
        voiceSearchPrompt
      );
    }
    AnalyticsService.pushEvent(`Voice Search Clicked`);
  };

  const handleClearSearch = (event) => {
    AnalyticsService.pushEvent("Cancel Icon Clicked", {
      page: props?.page || "",
      keyword: props?.keywordDebounced || "",
    });
    if (productSearchImageUrl !== null) {
      navigate("/search");
    } else {
      event.nativeEvent.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      props?.setKeyword(``);
      props?.setKeywordDebounced(``);
    }
  };

  useEffect(() => {
    if (props?.refresh) {
      setInputFocus();
    }
  }, [props?.refresh]);

  useEffect(() => {});

  const handleClick = () => {
    if (props?.type === `create-offer`) {
      navigate(
        `/search?keyword=${props?.keyword}&skuCode=${props?.selectedSkuCode}&title=${props?.offerDetail?.title}&description=${props?.offerDetail?.description}&theme=${props?.offerDetail?.theme}&type=create-offer`,
        { replace: true }
      );
    } else {
      // search bar should not clear on click when it's an image search
      if (!productSearchImageUrl) {
        if (_.trim(props?.keywordDebounced?.length) > 0) {
          if (props?.setShowSuggestions) {
            props?.setShowSuggestions(true);
          }
        }
        if (navigateToSearchRoute) {
          navigate(`/search`);
        }
      }
    }
  };

  const handleChange = (event) => {
    if (props?.setKeywordDebounced) {
      props?.setKeywordDebounced(event.target.value);
      if (props?.type === `create-offer`) {
        navigate(
          `/search?keyword=${event.target.value}&skuCode=${props?.selectedSkuCode}&title=${props?.offerDetail?.title}&description=${props?.offerDetail?.description}&theme=${props?.offerDetail?.theme}&type=create-offer`,
          { replace: true }
        );
      } else {
        if (replaceCurrentUrl) {
          navigate(`/search?keyword=${event.target.value}`, { replace: true });
        }
      }
    }
  };

  const handleChangeDebounced = useCallback(
    debounce(handleChange, debounceValue),
    []
  );

  const handleChangeInput = (event) => {
    handleChangeDebounced(event);
    if (props?.setKeyword) {
      props?.setKeyword(event.target.value);
    }

    if (props?.setShowSuggestions) {
      props?.setShowSuggestions(true);
    }
  };

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (
          appVersionCode &&
          parseInt(appVersionCode) >=
            AndroidService.VOICE_SEARCH_COMPATIBLE_ANDROID_VERSION
        ) {
          setIsVoiceSearchCompatibleAndroidAppVersion(true);
        } else {
          setIsVoiceSearchCompatibleAndroidAppVersion(false);
        }
        if (
          appVersionCode &&
          parseInt(appVersionCode) >=
            CommonConstantValues.STORE_IMAGE_UPLOAD_ANDROID_VERSION
        ) {
          setIsImageSearchCompatibleAndroidAppVersion(true);
        } else {
          setIsImageSearchCompatibleAndroidAppVersion(false);
        }
      } else {
        setIsVoiceSearchCompatibleAndroidAppVersion(false);
        setIsImageSearchCompatibleAndroidAppVersion(false);
      }
    }
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.setAttribute("readonly", true); // Force keyboard to hide on input field.

      if (props?.getSearchResultsBySuggestion) {
        const querySearchType = "Direct Search";
        props?.getSearchResultsBySuggestion(
          props?.keywordDebounced,
          querySearchType
        );
      }

      setTimeout(function () {
        event.target.blur(); //actually close the keyboard
        event.target.removeAttribute("readonly"); // Remove readonly attribute after keyboard is hidden.
      }, 100);
    }
  };

  const onConfirmTakeProductPhoto = () => {
    setShowModal(false);

    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();

        if (
          appVersionCode &&
          parseInt(appVersionCode) >=
            CommonConstantValues.STORE_IMAGE_UPLOAD_ANDROID_VERSION
        ) {
          const { axiosRequest } = DocumentService.getProductImageUploadUrls();
          axiosRequest
            .then(({ data }) => {
              AndroidService.captureImageAndLocation(
                `${config.appUrl}/search`,
                false,
                data?.responseData?.putPresignedUrl,
                data?.responseData?.getUrl,
                shouldCompress,
                targetImageWidth,
                targetImageHeight,
                targetImageQuality
              );
            })
            .catch((error) => {
              console.log(error);
              setShowError(true);
            });
        } else {
          setOpenAppUpdateDialog(true);
        }
      }
    }
  };
  return (
    <>
      <Container
        sx={{
          backgroundColor: !props.showingOnTopBar ? "#FFFFFF" : "",
          paddingTop: props.noPaddingRequired ? "0px" : "10px",
          paddingLeft: props.noPaddingRequired ? "0px" : "16px",
          paddingRight: props.noPaddingRequired ? "0px" : "16px",
          position: props.shouldMakeItSticky ? "sticky" : "",
          top: props.shouldMakeItSticky ? "53px" : "",
          zIndex: props.shouldMakeItSticky ? "10" : "",
        }}
        maxWidth={`xl`}
      >
        <TextField
          inputRef={inputRef}
          onClick={() => {
            const searchClicked = {
              page: props.currentPage,
            };
            handleClick();
            AnalyticsService.pushEvent(`Search Clicked`, searchClicked);
          }}
          autoFocus={
            window.location.pathname && window.location.pathname === `/search`
          }
          classes={{
            MuiOutlinedInput: classes.MuiOutlinedInput,
          }}
          sx={{
            marginBottom: props.noPaddingRequired ? 0 : 1,
            backgroundColor: (theme) => theme.palette.background.main,
            border: "none",
            borderRadius: "10px",
          }}
          fullWidth={true}
          onKeyDown={handleKeyDown}
          placeholder={
            productSearchImageUrl === null
              ? props.placeholder
                ? props.placeholder
                : "Search Products"
              : ""
          }
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start" onClick={(event) => {}}>
                  <img src={`/icons/search.svg`} alt="Search" />
                  {productSearchImageUrl !== null ? (
                    <div
                      style={{
                        marginLeft: "9px",
                      }}
                    >
                      <img
                        src={productSearchImageUrl}
                        alt="Voice Search"
                        style={{
                          maxHeight: "30px",
                          maxWidth: "30px",
                          marginTop: "4px",
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              </>
            ),
            endAdornment: (
              <>
                <InputAdornment
                  position="end"
                  style={{
                    cursor: "default",
                    borderRadius: "10px",
                  }}
                >
                  {isVoiceSearchCompatibleAndroidAppVersion === true &&
                  isImageSearchCompatibleAndroidAppVersion === true ? (
                    (props.keyword && props.keyword.length > 0) ||
                    productSearchImageUrl !== null ? (
                      <>
                        <IconButton
                          onClick={(event) => handleVoiceSearch(event)}
                          sx={{
                            padding: "6px",
                            paddingRight: "4px",
                          }}
                        >
                          <img src={`/icons/mic.svg`} alt="Voice Search" />
                        </IconButton>
                        <IconButton
                          onClick={(event) => handleImageSearch(event)}
                          sx={{
                            padding: "6px",
                            paddingRight: "4px",
                          }}
                        >
                          <img src={`/icons/cameraIcon.svg`} alt="" />
                        </IconButton>
                        <Close
                          color="primary"
                          onClick={(event) => {
                            handleClearSearch(event);
                          }}
                          sx={{ marginLeft: "3px" }}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          variant="text"
                          startIcon={
                            <img src={`/icons/mic.svg`} alt="Voice Search" />
                          }
                          sx={{
                            textTransform: "none",
                            minWidth: "15px",
                            padding: "6px",
                            paddingRight: "4px",
                            "& .MuiButton-startIcon": {
                              marginRight: "4px",
                            },
                          }}
                          onClick={(event) => handleVoiceSearch(event)}
                        ></Button>
                        <Button
                          variant="text"
                          startIcon={
                            <img
                              src={`/icons/cameraIcon.svg`}
                              alt="Camera Search"
                            />
                          }
                          sx={{
                            textTransform: "none",
                            minWidth: "15px",
                            padding: "6px",
                            paddingRight: "4px",
                            "& .MuiButton-startIcon": {
                              marginRight: "4px",
                            },
                          }}
                          onClick={(event) => handleImageSearch(event)}
                        ></Button>
                      </>
                    )
                  ) : (
                    ((props.keyword && props.keyword.length > 0) ||
                      productSearchImageUrl !== null) && (
                      <Close
                        color="primary"
                        onClick={(event) => handleClearSearch(event)}
                      />
                    )
                  )}
                </InputAdornment>
              </>
            ),
            style: {
              padding: "2px 10px",
            },
          }}
          variant="outlined"
          size={`small`}
          value={props.keyword ? props.keyword : ""}
          onChange={handleChangeInput}
        />
      </Container>

      <ImageSearchInformationDialog
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        onConfirmTakeProductPhoto={onConfirmTakeProductPhoto}
      />

      <Snackbar
        open={showError}
        autoHideDuration={5000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          marginBottom: "55px",
        }}
      >
        <Alert
          icon={false}
          sx={{
            background: "#f26b6b",
            color: "#ffffff",
            width: "328px",
          }}
          onClose={() => setShowError(false)}
        >
          {CommonConstantValues.ERROR_MESSAGE}
        </Alert>
      </Snackbar>

      <AppVersionUnsupported
        open={openAppUpdateDialog}
        handleClose={() => {
          AnalyticsService.pushEvent("App Update Required Dialog Closed", {
            page: window.location.pathname,
            section: "Store Image Upload",
          });
          setOpenAppUpdateDialog(false);
        }}
        page={window.location.pathname}
        section={"Store Image Upload"}
      />
    </>
  );
}

export default Search;
