import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { East, GppMaybeOutlined, ThumbUpOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import AmountUtils from "../../Utils/AmountUtils";
import PayNowDialog from "../Hisaab/PayNowDialog";
import BackDropLoader from "../Common/BackDropLoader";
import AnalyticsService from "../../Services/AnalyticsService";
import OrderService from "../../Services/OrderService";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles({
  productGroupCard: {
    marginBottom: "16px",
    boxShadow: "none",
    padding: "8px 16px 0px",
    borderRadius: "16px",
  },
  productImage: { width: "100%" },
  productGroupName: {
    fontSize: "14px",
    fontWeight: "700",
    wordBreak: "break-all",
  },
  title: { fontSize: "12px", fontWeight: "400" },
  amount: { fontSize: "14px", fontWeight: "700" },
  netPayableAmount: {
    position: "absolute",
    left: "-16px",
    bottom: "4px",
    fontSize: "12px",
    padding: "4px 22px 4px 5px",
    color: "#9D2123",
    background: "#f8d248",
    "& span": {
      width: "18px",
      height: "37px",
      background: "white",
      display: "inline-block",
      position: "absolute",
      right: "-3px",
      top: "-12px",
      transform: "rotate(-45deg)",
    },
  },
  payNowButton: {
    fontSize: "14px",
    fontWeight: "700",
    borderRadius: "10px",
    textTransform: "uppercase",
    height: "35px",
  },
  payNowDisabledMessage: {
    margin: "0px -16px",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 0px",
  },
});

export default function ProductGroupCard({
  productGroup,
  vanPaymentDetails,
  selectClicked,
  productListToSettle,
  setProductListToSettle,
  selectedAmountToSettle,
  setSelectedAmountToSettle,
  lendingPartner,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPayNowDialog, setShowPayNowDialog] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState("not_started");
  const [payNowPaymentAmount, setPayNowPaymentAmount] = useState(0);
  const [productListToSettleObj, setProductListToSettleObj] = useState({});

  useEffect(() => {
    let totalNetPayable = 0;
    setProductListToSettleObj(
      productListToSettle?.reduce((acc, curr) => {
        acc[encodeURI(curr.productSalesGroup)] = curr;
        totalNetPayable += curr.netPayableAmount;
        return acc;
      }, {})
    );

    setSelectedAmountToSettle(
      (totalNetPayable > vanPaymentDetails?.amount
        ? vanPaymentDetails?.amount
        : totalNetPayable) || 0
    );
  }, [productListToSettle]);

  const handleProductGroupClick = (productGroup) => {
    if (selectClicked && disableForVanSettlementSelection()) {
      return;
    }
    const isSelected = productListToSettle?.find(
      (product) => product.productSalesGroup === productGroup.productSalesGroup
    );

    const eventProperties = {
      page: window?.location?.pathname,
      type: "Pay By Product Group",
      dueAmount: productGroup?.unsettledAmount,
      productSalesGroup: productGroup?.productSalesGroup || "",
      cashDiscountAmount: productGroup?.cdBenefitAmount,
      netPayableAmount: productGroup?.netPayableAmount,
    };

    if (selectClicked) {
      eventProperties.isCardSelectedForSettlement = isSelected ? true : false;
    }

    AnalyticsService.pushEvent("Card Clicked", eventProperties);

    if (selectClicked) {
      if (isSelected) {
        setProductListToSettle(
          productListToSettle?.filter(
            (product) =>
              product.productSalesGroup !== productGroup.productSalesGroup
          )
        );
      } else {
        if (selectedAmountToSettle >= vanPaymentDetails?.amount) {
          return null;
        }
        setProductListToSettle((prevDetails) => [...prevDetails, productGroup]);
      }

      return null;
    }
    let navigationUrl = `/hisaab/pay-by-product-group/${encodeURI(productGroup?.productSalesGroup)}`;
    if (PartnerService.isPartnerExternalCreditType()) {
      navigationUrl += `?lendingPartner=${encodeURI(lendingPartner)}`;
    }
    navigate(navigationUrl);
  };

  const handlePayNow = () => {
    setShowPayNowDialog(true);
    setPayNowPaymentAmount(productGroup?.unsettledAmount);

    AnalyticsService.pushEvent("Pay Now Clicked", {
      page: window?.location?.pathname,
      dueAmount: productGroup?.unsettledAmount,
      creditLine: localStorage.getItem("creditLine")?.toLowerCase(),
      productSalesGroup: productGroup?.productSalesGroup || "",
      cashDiscountAmount: productGroup?.cdBenefitAmount,
      netPayableAmount: productGroup?.netPayableAmount,
    });
  };

  const handlePaymentStatus = (paymentId) => {
    navigate(`/hisaab/payment-status/${paymentId}`);
  };

  const handleRazorpayFailure = (error, errorCode = null) => {
    const urlParams = errorCode ? `?errorCode=${errorCode}` : "";
    navigate(`/hisaab/razorpay-failure/${error}${urlParams}`);
  };

  const isProductGroupLendingPartnerDifferentFromSelectedLendingPartner =
    () => {
      // [SAT-1220] If a Product Group card is already selected for VAN settlement,
      // then don’t allow Product Group card with a different lending partner to be selectable
      // as only one Lending Partner can be settled at a time
      if (
        PartnerService.isPartnerExternalCreditType() &&
        productListToSettle?.length > 0 &&
        lendingPartner !== productListToSettle?.[0]?.lendingPartner
      ) {
        return true;
      }
      return false;
    };

  const isProductGroupLendingPartnerDifferentFromVanPaymentLendingPartner =
    () => {
      // Don't allow VAN settlement if lending partner of this PG is different
      // from the lending partner returned from backend
      if (
        PartnerService.isPartnerExternalCreditType() &&
        vanPaymentDetails?.lendingPartner !== lendingPartner
      ) {
        return true;
      }
      return false;
    };

  const disableForVanSettlementSelection = () => {
    if (
      (selectedAmountToSettle >= vanPaymentDetails?.amount &&
        !productListToSettleObj?.[encodeURI(productGroup.productSalesGroup)]) ||
      isProductGroupLendingPartnerDifferentFromSelectedLendingPartner() ||
      isProductGroupLendingPartnerDifferentFromVanPaymentLendingPartner()
    ) {
      return true;
    }
    return false;
  };

  return (
    <Card className={classes.productGroupCard}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "5px" }}
        onClick={() => handleProductGroupClick(productGroup)}
      >
        <Grid item xs={2}>
          <img
            src={productGroup?.productImageUrl || "/icons/dummy.jpeg"}
            alt={productGroup?.productSalesGroup}
            className={classes.productImage}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.productGroupName}>
            {productGroup?.productSalesGroup}
          </Typography>
          {productGroup?.dueDate && (
            <Typography className={classes.title}>
              Due Date - {moment(productGroup?.dueDate).format("DD MMM YYYY")}
            </Typography>
          )}
        </Grid>
        <Grid item xs={1} textAlign="right">
          {selectClicked ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    productListToSettleObj?.[
                      encodeURI(productGroup.productSalesGroup)
                    ]
                      ? true
                      : false
                  }
                  indeterminate={disableForVanSettlementSelection()}
                  disabled={disableForVanSettlementSelection()}
                />
              }
            />
          ) : (
            <East color="primary" />
          )}
        </Grid>
      </Grid>

      <Divider sx={{ margin: "0px -16px", height: "2px" }} />

      <Grid
        container
        alignItems="center"
        spacing={0}
        sx={{ padding: "8px 0" }}
        onClick={() => handleProductGroupClick(productGroup)}
      >
        <Grid item xs={6}>
          <Typography className={classes.title}>Total Outstanding</Typography>
          <Typography color={"primary"} className={classes.amount}>
            {AmountUtils.formatCurrency(productGroup?.unsettledAmount || 0)}
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign={"right"}>
          <Typography className={classes.title}>Cash Discount</Typography>
          <Typography color={"secondary"} className={classes.amount}>
            {AmountUtils.formatCurrency(productGroup?.cdBenefitAmount || 0)}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ margin: "0px -16px", height: "2px" }} />

      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ position: "relative", margin: "8px 0" }}
      >
        <Grid item xs={5}>
          {productGroup?.netPayableAmount ? (
            <Box className={classes.netPayableAmount}>
              Net Payable :{" "}
              <b>
                {AmountUtils.formatCurrency(productGroup?.netPayableAmount)}
              </b>
              <span></span>
            </Box>
          ) : null}
        </Grid>

        <Grid item xs={5}>
          <Button
            fullWidth
            size={`small`}
            variant={"contained"}
            color={"primary"}
            onClick={handlePayNow}
            className={classes.payNowButton}
            sx={{ visibility: selectClicked ? "hidden" : "visible" }}
            disabled={
              !productGroup?.isPayNowEnabled ||
              OrderService.isLastSuccessfulPaymentInProgressForOrder(
                productGroup
              )
            }
          >
            Pay Now
          </Button>
        </Grid>
      </Grid>

      {!productGroup?.isPayNowEnabled ? (
        <Typography
          className={classes.payNowDisabledMessage}
          sx={{
            color: `${productGroup?.unsettledAmount > 0 ? `#9D2123` : `#00733E`}`,
            background:
              productGroup?.unsettledAmount > 0 ? `#F5E9E9` : `#E6F2EC`,
          }}
        >
          {productGroup?.unsettledAmount > 0 ? (
            <GppMaybeOutlined sx={{ fontSize: "20px", marginRight: "4px" }} />
          ) : (
            <ThumbUpOutlined sx={{ fontSize: "18px", marginRight: "4px" }} />
          )}
          {productGroup?.payNowDisabledMessage}
        </Typography>
      ) : OrderService.isLastSuccessfulPaymentInProgressForOrder(
          productGroup
        ) ? (
        <Typography
          sx={{
            margin: "0px -16px",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#FDF4E2",
            padding: "4px 0px",
            color: "#CC8800",
          }}
        >
          <GppMaybeOutlined sx={{ fontSize: "20px", marginRight: "4px" }} />
          Previous payment is in progress
        </Typography>
      ) : null}

      <PayNowDialog
        open={showPayNowDialog}
        setOpen={setShowPayNowDialog}
        paymentAmount={payNowPaymentAmount}
        lendingPartner={lendingPartner}
        handleClose={() => setShowPayNowDialog(false)}
        handlePaymentStatus={handlePaymentStatus}
        handleRazorpayFailure={handleRazorpayFailure}
        setPaymentLoading={setPaymentLoading}
        paymentType={"productsalesgroup"}
        productSalesGroup={productGroup?.productSalesGroup}
        initiationFailureRetryRedirectionLink={`/hisaab/pay-by-product-group`}
      />
      <BackDropLoader open={paymentLoading === "started"} />
    </Card>
  );
}
