import _ from "lodash";
import { useEffect, useState } from "react";
import BrandService from "../Services/BrandService";

const useSelectedBrands = (
  brandNamesFromQuery,
  brands,
  setSelectedBrands
  // handleClickChooseBrands
) => {
  const [loadingAllBrands, setLoadingAllBrands] = useState("not_started");

  useEffect(() => {
    if (brandNamesFromQuery.length > 0 && brands) {
      setLoadingAllBrands("started");
      const { axiosRequest, abortController } = BrandService.getAllBrands();
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.brands?.length > 0) {
            let brandsFromResponse = response.data.responseData.brands;
            brandsFromResponse = _.filter(
              brandsFromResponse,
              (brandFromResponse) => {
                return brandNamesFromQuery?.includes(
                  brandFromResponse?.brandName?.toLowerCase()
                );
              }
            );
            brandsFromResponse = _.map(
              brandsFromResponse,
              (brandFromResponse) => {
                return {
                  brandId: brandFromResponse.brandId,
                  brandImage: brandFromResponse.brandImage,
                  brandName: brandFromResponse.brandName,
                };
              }
            );
            if (brandsFromResponse?.length > 0) {
              console.log("brandsFromResponse", brandsFromResponse);
              setSelectedBrands(brandsFromResponse);
              //   handleClickChooseBrands(brandsFromResponse);
            }
          } else {
            console.log(`no brands found`);
          }
          setLoadingAllBrands("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting brands`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoadingAllBrands("done");
          }
        });
      return () => abortController.abort();
    } else {
      setLoadingAllBrands("done");
    }
  }, [brands]);

  return { loadingAllBrands };
};

export default useSelectedBrands;
