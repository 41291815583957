import { Box, Skeleton } from "@mui/material";

const SelectedBrandFiltersLoader = () => {
  return (
    <Box sx={{ margin: "16px" }}>
      <Skeleton variant="rectangular" height={30} />
    </Box>
  );
};

export default SelectedBrandFiltersLoader;
