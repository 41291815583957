import config from "../config";
import {
  ActiveTurnoverSchemesMock,
  CompletedTurnoverSchemesMock,
  ProductCohortMock,
  TurnoverSchemesMock,
} from "./mocks/SchemesMock";
import _ from "lodash";
import ProductService from "../Services/ProductService";
import { TodSettledSaleBreakupMock } from "./TodSettledSaleBreakupMock";
import HTTPService from "./HTTPService";

const useMocks = false;

export default class SchemesService {
  static SLAB_CONSTRAINT_UNIT_MAP = {
    ORDER_VALUE: "₹",
    TOTAL_SKU_COUNT: "Qty",
    TOTAL_WEIGHT_VALUE: "Kg(s)",
    TOTAL_LITRE_VALUE: "Ltr(s)",
  };

  static ENTITLEMENT_MAP = {
    CREDIT_MEMO: "CN",
    CREDIT_MEMO_PERCENT: "% CN",
    CREDIT_MEMO_PER_QUANTITY_PERCENT: "% CN/Qty",
    CREDIT_MEMO_PER_QUANTITY: "CN/Qty",
    FREE_VOUCHER: "",
  };
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getTurnoverBasedSchemes(featuredSchemesOnly, schemeStatus) {
    let url = `${
      config.backendUrl
    }offerservice/v1/turnoverofferstatus/?farmerId=${localStorage.getItem(
      "farmerId"
    )}${featuredSchemesOnly ? `&isFeatured=true` : ``}${
      schemeStatus ? `&status=${schemeStatus}` : ``
    }`;

    if (useMocks === false) {
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (schemeStatus === "active") {
            resolve({ data: ActiveTurnoverSchemesMock });
          } else if (schemeStatus === "completed") {
            resolve({ data: CompletedTurnoverSchemesMock });
          } else {
            resolve({ data: TurnoverSchemesMock });
          }
        }, 1500);
      });
    }
  }

  static getProductCohorts(promotionUUID) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }offerservice/v1/cohortrevenue/?type=json&revenue=false&promotionuuid=${promotionUUID}&farmerId=${localStorage.getItem(
        "farmerId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: ProductCohortMock });
        }, 1000);
      });
    }
  }

  static generateEntitlementDescription = (entitlements) => {
    let entitlementsList = [];
    let entitlementsString = ``;
    _.forEach(entitlements, (entitlement) => {
      if (entitlement?.type === `FREE_VOUCHER`) {
        _.forEach(entitlement?.params?.freeVouchers, (voucherDetails) => {
          entitlementsList.push(
            `${voucherDetails?.voucherCode} ${
              this.ENTITLEMENT_MAP[entitlement?.type]
            }`
          );
        });
      } else {
        if (
          entitlement?.type === `CREDIT_MEMO` ||
          entitlement?.type === `CREDIT_MEMO_PER_QUANTITY`
        ) {
          entitlementsList.push(
            `${
              entitlement?.params?.value >= 10000000
                ? `₹${ProductService.roundFloatsToNdecimalPlaces(
                    entitlement?.params?.value / 10000000,
                    2
                  )} Cr`
                : entitlement?.params?.value >= 100000
                  ? `₹${ProductService.roundFloatsToNdecimalPlaces(
                      entitlement?.params?.value / 100000,
                      2
                    )} lacs`
                  : `₹${new Intl.NumberFormat("en-IN").format(
                      entitlement?.params?.value
                    )}`
            }  ${this.ENTITLEMENT_MAP[entitlement?.type]}`
          );
        } else {
          entitlementsList.push(
            `${entitlement?.params?.value} ${
              this.ENTITLEMENT_MAP[entitlement?.type]
            }`
          );
        }
      }
    });
    entitlementsString = _.join(entitlementsList, ", ");
    return entitlementsString;
  };

  static getDataForTurnoverSchemeProgressBar = (
    showTodProgressBar,
    product
  ) => {
    var lastAchievedSlabDetails = null;
    var currentTargetSlabDetails = null;
    var qualifiedSlabRelativePercentageAchieved = null;

    if (showTodProgressBar) {
      var lastAchievedSlabIndex = _.findIndex(
        product?.turnoverData?.turnoverDetails?.[0]?.turnoverOfferRule,
        (slabDetails) => {
          return (
            product?.turnoverData?.turnoverDetails?.[0]?.netValue <
            slabDetails?.constraints?.constraintList?.[0]?.params?.targetValue
          );
        }
      );
      if (lastAchievedSlabIndex === -1) {
        // if user has achieved the last slab target
        lastAchievedSlabIndex =
          product?.turnoverData?.turnoverDetails?.[0]?.turnoverOfferRule
            ?.length - 1;
      }
      lastAchievedSlabDetails =
        product?.turnoverData?.turnoverDetails?.[0]?.turnoverOfferRule?.[
          lastAchievedSlabIndex - 1
        ];

      qualifiedSlabRelativePercentageAchieved =
        product?.turnoverData?.turnoverDetails?.[0]?.constraintsStatus?.[
          lastAchievedSlabIndex
        ]?.slabConstraintsStatus?.[0]?.relativePercentageAchieved;

      currentTargetSlabDetails = _.find(
        product?.turnoverData?.turnoverDetails?.[0]?.turnoverOfferRule,
        (slabDetails) => {
          return (
            slabDetails?.constraints?.constraintList?.[0]?.params?.targetValue >
            product?.turnoverData?.turnoverDetails?.[0]?.netValue
          );
        }
      );
      if (!currentTargetSlabDetails) {
        // if current slab is the last slab, treat the current slab itself as next slab
        currentTargetSlabDetails =
          product?.turnoverData?.turnoverDetails?.[0]?.turnoverOfferRule?.slice(
            -1
          )?.[0];
      }
    }

    return {
      lastAchievedSlabDetails,
      currentTargetSlabDetails,
      qualifiedSlabRelativePercentageAchieved,
    };
  };

  static getSettledSaleBreakup(promotionUUID) {
    let url = `${config.backendUrl}offerservice/v1/turnoverofferstatus/settledentitlementbreakups/?farmerId=${localStorage.getItem(
      "farmerId"
    )}&promotionUUID=${promotionUUID}`;

    if (useMocks === false) {
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: TodSettledSaleBreakupMock });
        }, 2000);
      });
    }
  }

  static getHardcodedSchemeMessage(schemeId) {
    const schemes = {
      "84622632-af9b-4ebf-892d-d55c19e26640": true,
      "290ad0a4-42d1-4f6f-b853-7615f6247715": true,
      "6080ac8a-4018-46f7-8a50-fec9519954b4": true,
      "40e2696c-73ea-4261-86e1-065063f93319": true,
      "c2659f00-0815-4e7a-9e7b-4d8ea340e46a": true,
    };
    if (schemes[schemeId]) {
      return "Get extra Rs. 1000 CN for each litre purchased over 6 litres";
    }
    return null;
  }
}
