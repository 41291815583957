import config from "../config";
import FeaturedVideosMock from "./mocks/ReferralMock";
import HTTPService from "./HTTPService";

// import AboutThisProductVideo from "../content/AboutThisProduct";
const useMocks = false;
export default class VideoService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getFeaturedVideos() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/videos/saathi/dashboard/?section=SHOP&farmerId=${localStorage.getItem(
        "farmerId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: FeaturedVideosMock });
      });
    }
  }
}
