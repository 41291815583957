import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { styled } from "@mui/material/styles";
import GiftReceived from "./GiftReceived";
import AnalyticsService from "../../Services/AnalyticsService";
import Clamp from "react-multiline-clamp";
import { useState } from "react";
import FarmersService from "../../Services/FarmersService";
import _ from "lodash";
import GiftsReceivedEmpty from "./GiftsReceivedEmpty";
import GiftsReceivedError from "./GiftsReceivedError";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    marginTop: "10px",
  },
  mainText: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "19px",
    color: "#333333",
  },
  name: {
    minHeight: "19px",
  },
  addressWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "6px",
  },
  address: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "14px",
    color: "#666666",
    marginLeft: "8px",
    wordBreak: "break-all",
  },
  nameAddressWrapper: {
    marginTop: "5px",
  },
  nameAddress: {
    paddingRight: theme.spacing(1),
  },
  callIcon: {
    background: "rgba(0, 115, 62, 0.1)",
    borderRadius: "50%",
  },
  expandIcon: {
    fontSize: "40px",
    color: "#b3b3b3",
    marginLeft: "12px",
  },
  iconsWrapper: {
    textAlign: "center",
  },
  metricsDataWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "2px",
  },
  totalScansWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: theme.spacing(1.5, 0),
  },
  metrics: {
    textAlign: "center",
  },
  metricsText: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#333333",
  },
  totalScansText: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#333333",
    marginLeft: "5px",
  },
  pointsSummaryWrapper: {
    margin: theme.spacing(0, 0, 1.5, 0),
  },
  pointsSummary: {
    background: `rgb(255,255,255,0.3)`,
    padding: theme.spacing(1.5, 0),
    borderRadius: "10px",
  },
  giftsReceivedWrapper: {
    background: "#e5e5e5",
    padding: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.5),
    borderRadius: "0px 0px 10px 10px",
    maxHeight: "357px",
    overflowY: "auto",
  },
  giftsReceivedHeading: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "19px",
    color: "#333333",
    marginBottom: "7px",
  },
  circularLoader: {
    minHeight: "225px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CustomAccordion = styled((props) => (
  <Accordion elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: theme.spacing(1.25),
}));

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(0),
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0px",
  },
}));

const MyFarmerCard = (props) => {
  const classes = useStyles();
  const [redeemedGifts, setRedeemedGifts] = useState([]);
  const [chevronClicked, setChevronClicked] = useState(false);
  const [loading, setLoading] = useState("not_started");
  const [error, setError] = useState(null);
  const [cardExpanded, setCardExpanded] = useState(false);
  const [availablePoints, setAvailablePoints] = useState(null);

  const handleCallFarmer = (event) => {
    event.stopPropagation();
    AnalyticsService.pushEvent(`My Farmers Call Button Clicked`, {
      page: `/my-farmers`,
      phoneNumber: props?.myFarmer?.farmerPhone,
      customerFarmerId: props?.myFarmer?.farmerId,
    });
  };

  const handleFarmerCardClick = (event) => {
    event.stopPropagation();
  };

  const handleExpandCardClick = () => {
    if (!chevronClicked) {
      setLoading(`started`);
      const { axiosRequest } = FarmersService.getScanRewardRedeemHistory(
        props?.myFarmer?.farmerId
      );
      axiosRequest
        .then((response) => {
          setAvailablePoints(
            response?.data?.responseData?.rewardDetails?.totalAvailablePoints
          );
          if (response?.data?.responseData?.redeemedHistory?.length > 0) {
            let giftsFromResponse = response.data.responseData.redeemedHistory;
            giftsFromResponse = _.filter(giftsFromResponse, (gift) => {
              return gift.skuCode && gift.skuCode !== "";
            });
            setRedeemedGifts(giftsFromResponse);
          } else {
            console.log(`no redeemed gifts found`);
          }
          setLoading(`done`);
        })
        .catch((error) => {
          console.log(`failed to get redeemed gifts history`);
          setLoading(`done`);
          if (error.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
        });
    }
    setChevronClicked((prevState) => !prevState);
  };

  const generateFormattedAddress = () => {
    let formattedAddress = "";
    if (props?.myFarmer?.farmerVillage && props?.myFarmer?.farmerTaluka) {
      formattedAddress +=
        props.myFarmer.farmerVillage + ", " + props.myFarmer.farmerTaluka;
    }
    if (props?.myFarmer?.farmerVillage && !props?.myFarmer?.farmerTaluka) {
      formattedAddress += props.myFarmer.farmerVillage;
    }
    if (!props?.myFarmer?.farmerVillage && props?.myFarmer?.farmerTaluka) {
      formattedAddress += props.myFarmer.farmerTaluka;
    }
    if (props?.myFarmer?.farmerPincode) {
      formattedAddress += " - " + props.myFarmer.farmerPincode;
    }
    return formattedAddress;
  };

  return (
    <div className={classes.cardWrapper}>
      <CustomAccordion
        onChange={() => {
          AnalyticsService.pushEvent(`My Farmers View Gifts Chevron Clicked`, {
            page: `/my-farmers`,
            customerFarmerId: props?.myFarmer?.farmerId,
            "Gifts View": cardExpanded ? "Collapsed" : "Expanded",
          });
          setCardExpanded((prevState) => !prevState);
        }}
      >
        <CustomAccordionSummary>
          <Grid container direction={`column`}>
            <Grid
              container
              alignItems={`center`}
              justifyContent={`center`}
              className={classes.nameAddressWrapper}
            >
              <Grid
                item
                xs={9}
                onClick={handleFarmerCardClick}
                className={classes.nameAddress}
              >
                <Grid
                  container
                  alignItems={`center`}
                  justifyContent={`flex-start`}
                >
                  <Grid item xs={12}>
                    <Typography
                      className={`${classes.mainText} ${classes.name}`}
                    >
                      {props?.myFarmer?.farmerName
                        ? props.myFarmer.farmerName.trim() === `Some User`
                          ? `My Farmer`
                          : props.myFarmer.farmerName
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.addressWrapper}>
                    <img
                      src="/icons/location.svg"
                      alt=""
                      style={{ height: "14px" }}
                    />
                    <Typography variant="span" className={classes.address}>
                      <Clamp withTooltip lines={1}>
                        {generateFormattedAddress()}
                      </Clamp>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {props?.myFarmer?.farmerPhone ? (
                <Grid
                  item
                  xs={1}
                  onClick={handleCallFarmer}
                  className={classes.iconsWrapper}
                >
                  <a href={`tel:${props.myFarmer.farmerPhone}`}>
                    <img
                      src="/icons/callIconBackground.svg"
                      alt=""
                      style={{ height: "29px" }}
                    />
                  </a>
                </Grid>
              ) : (
                <Grid item xs={1} />
              )}

              <Grid
                item
                xs={2}
                className={classes.iconsWrapper}
                onClick={handleExpandCardClick}
              >
                {cardExpanded ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={`center`}
              justifyContent={`center`}
              onClick={handleFarmerCardClick}
            >
              <Grid item xs={12}>
                <Typography className={classes.totalScansWrapper}>
                  <img
                    src="/icons/scan.svg"
                    alt=""
                    style={{ height: "21px", marginRight: "4px" }}
                  />
                  <span className={classes.mainText}>
                    {props?.myFarmer?.totalScans >= 0
                      ? props.myFarmer.totalScans
                      : ""}
                  </span>
                  <span
                    className={classes.totalScansText}
                  >{`Total Scans`}</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CustomAccordionSummary>
        <AccordionDetails className={classes.giftsReceivedWrapper}>
          {loading === "started" && (
            <Box className={classes.circularLoader}>
              <CircularProgress variant={"indeterminate"} />
            </Box>
          )}

          <Box className={classes.pointsSummaryWrapper}>
            {loading === "done" && availablePoints != null && (
              <Grid
                container
                alignItems={`center`}
                justifyContent={`center`}
                className={classes.pointsSummary}
              >
                <Grid item xs={12} className={classes.metrics}>
                  <Typography className={classes.metricsDataWrapper}>
                    <img
                      src="/icons/points.svg"
                      alt=""
                      style={{ marginRight: "4px" }}
                    />
                    <span className={classes.mainText}>{availablePoints}</span>
                  </Typography>
                  <Typography
                    className={classes.metricsText}
                  >{`Available`}</Typography>
                </Grid>
              </Grid>
            )}
          </Box>

          {loading === "done" && (
            <Typography
              className={classes.giftsReceivedHeading}
            >{`Gifts Received`}</Typography>
          )}

          {loading === "done" &&
            (redeemedGifts.length > 0 ? (
              _.map(redeemedGifts.slice(0, 100), (gift, index) => (
                <GiftReceived gift={gift} key={index} />
              ))
            ) : error ? (
              <GiftsReceivedError />
            ) : (
              <GiftsReceivedEmpty />
            ))}
        </AccordionDetails>
      </CustomAccordion>
    </div>
  );
};

export default MyFarmerCard;
