import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  giftName: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
    color: "#212121",
  },
  giftImageWrapper: {
    minHeight: "57px",
  },
  redeemDate: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#666666",
    marginTop: "2px",
  },
  points: {
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "16px",
    color: "#00733E",
    textAlign: "right",
    paddingRight: "4px",
  },
  divider: {
    marginTop: "9px",
    marginBottom: "11px",
    color: "#000000",
  },
}));

const GiftReceived = (props) => {
  const classes = useStyles();

  return (
    <>
      {props?.gift?.skuCode && (
        <>
          <Grid container>
            <Grid item xs={2} className={classes.giftImageWrapper}>
              <img
                src={`https://static.agrostar.in/agro-elearning-firebase/v0/b/agro-elearning/o/tr:w-100/scan%2Frewards%2F${props?.gift?.skuCode}.png?alt=media`}
                alt=""
                style={{ height: "45px" }}
              />
            </Grid>

            <Grid item xs={7}>
              <Typography className={classes.giftName}>
                {props?.gift?.productName ? props.gift.productName : ""}
              </Typography>
              <Typography className={classes.redeemDate}>
                {props?.gift?.createdOn
                  ? format(props.gift.createdOn, `dd MMM yyyy`)
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.points}>
                {props?.gift?.pointsRedeemed >= 0
                  ? props.gift.pointsRedeemed + " Points"
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </>
      )}
    </>
  );
};

export default GiftReceived;
