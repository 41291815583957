import config from "../config";
import { FarmerExperiencePostsMock } from "./mocks/FarmerExperiencePostsMock";
import Languages from "./LanguageCode";
import StateUtils from "../Utils/StateUtils";
import HTTPService from "./HTTPService";

const useMocks = false;

export default class FarmerExperienceService {
  static getFarmerExperiencePosts(productGroup, skuCode, limit = 5) {
    const source = StateUtils.getAppSource();

    if (useMocks) {
      return new Promise((resolve, reject) => {
        return setTimeout(() => {
          resolve(FarmerExperiencePostsMock);
        }, 3000);
      });
    } else {
      let url = `${
        config.backendUrl
      }communicationservice/v2/testimonialposts?language=${
        Languages[localStorage.getItem("state")?.toUpperCase()] || "hi"
      }&limit=${limit}`;

      if (productGroup && productGroup.length > 0) {
        url = `${url}&productGroup=${productGroup}`;
      } else {
        url = `${url}&skuCode=${skuCode}`;
      }
      return HTTPService.makeXHRRequest(url, "GET", null, {
        source: source,
        "X-Authorization-Token": localStorage.getItem("X-Authorization-Token"),
      });
    }
  }
}
