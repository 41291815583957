import { ArrowForwardTwoTone } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";
import useReferrerParams from "../../Hooks/useReferrerParams";

const LogInOrSignUp = () => {
  const navigate = useNavigate();
  const { storeReferralParams } = useReferrerParams();
  storeReferralParams();
  const handleSignUpAsSaathiPartner = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Sign Up",
    });
    navigate(`/login?mode=signup`);
  };

  const handleLoginExistingPartner = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Login",
    });
    navigate(`/login?mode=login`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#9D2123",
        height: "100vh",
        textAlign: "center",
        overflowY: "hidden",
      }}
    >
      <Box sx={{ minHeight: "90px" }}>
        <img
          src={`/icons/agrostarSaathiLogo.svg`}
          alt={`AgroStar Saathi`}
          style={{
            position: "relative",
            zIndex: 1,
            top: "2vh",
          }}
        />
        <Typography
          component="h1"
          variant="subtitle1"
          sx={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#ffffff",
            margin: "4px 4px 0px 4px",
          }}
        >
          Get Agriculture products at best price for retailers
        </Typography>
      </Box>
      <img
        src="/images/loginPageBackground.png"
        alt="Buy India's best agriculture products at affordable price"
        style={{ width: "100%", position: "relative", top: "2vh" }}
      />
      <Box
        sx={{
          textAlign: "center",
          padding: "12px 16px 20px 16px",
          background: "#FFF",
          borderTopLeftRadius: "32px",
          borderTopRightRadius: "32px",
          position: "fixed",
          bottom: 0,
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <Box sx={{ minHeight: "60px" }}>
          <img src="/icons/welcome.svg" alt="welcome" />
        </Box>
        <Typography
          component="h2"
          variant="subtitle1"
          sx={{
            fontSize: "14px",
            fontWeight: "700",
            color: "#4F4F4F",
            lineHeight: "18.29px",
            mb: 2,
          }}
        >
          Join the community of 85 lac+ Farmers and 10,000+ retail partners and
          grow your business!
        </Typography>

        <Button
          size={"large"}
          fullWidth
          variant={"contained"}
          endIcon={<ArrowForwardTwoTone />}
          sx={{
            borderRadius: "16px",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: 700,
            mb: 1.25,
            boxShadow: "none",
          }}
          onClick={handleLoginExistingPartner}
        >
          Login
        </Button>
        <Button
          size={"large"}
          fullWidth
          variant={"outlined"}
          endIcon={<ArrowForwardTwoTone />}
          sx={{
            border: "1px solid #9D2123",
            borderRadius: "16px",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: 700,
          }}
          onClick={handleSignUpAsSaathiPartner}
        >
          <img src="/icons/bill.svg" alt="" style={{ marginRight: "4px" }} />
          Become a Saathi Retail Partner
        </Button>
      </Box>
    </Box>
  );
};

export default LogInOrSignUp;
