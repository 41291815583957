export default class AmountUtils {
  static format(amount) {
    return amount ? new Intl.NumberFormat("en-IN").format(amount) : 0;
  }

  static formatNumberWithSuffix(num) {
    if (Math.abs(num) >= 10000000) {
      return Math.sign(num) * (Math.abs(num) / 10000000).toFixed(2) + " Cr";
    } else if (Math.abs(num) >= 100000) {
      if (Math.abs(num) === 100000) {
        return Math.sign(num) * (Math.abs(num) / 100000).toFixed(2) + " lac";
      } else {
        return Math.sign(num) * (Math.abs(num) / 100000).toFixed(2) + " lacs";
      }
    } else {
      return this.format(Math.sign(num) * Math.abs(num));
    }
  }

  static formatCurrency = (amount) => {
    return new Intl.NumberFormat(`en-IN`, {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };
}
