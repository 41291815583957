import React from "react";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import { CheckCircle } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import OfferEntitlements from "../Common/OfferEntitlements";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  couponCard: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1),
    paddingBottom: 0,
    border: "1px solid white",
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    flexWrap: "nowrap",
    background: "white",
    position: "relative",
  },
  couponCardActive: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1),
    paddingBottom: 0,
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    border: "2px solid #00733E",
    background: "#E5F1EC",
    position: "relative",
  },
  dashedCouponCardActive: {
    margin: 0,
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1),
    paddingBottom: 0,
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    background: "#E5F1EC",
    position: "relative",
  },
  bestApplicableCouponCard: {
    margin: 0,
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1),
    paddingBottom: 0,
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    border: "2px solid #FFFFFF",
    background: "#FFFFFF66",
    position: "relative",
  },
  offerName: {
    fontWeight: 700,
    display: "flex",
    fontSize: "14px",
    color: "#00733E",
    alignItems: "center",
    lineHeight: "19px",
  },
  offerIcon: {
    marginRight: theme.spacing(1),
  },
  bestPriceText: {
    color: "#00733E",
    fontSize: "14px",
    marginTop: theme.spacing(1),
  },
  offerAvailable: {
    color: "#666666",
  },
  offerAvailableSpaced: {
    color: "#666666",
    padding: theme.spacing(0.5, 0),
    marginRight: theme.spacing(3),
  },
  absolutePosition: {
    position: "absolute",
    top: "0",
    right: "-10px",
  },
  centerBox: {
    width: "80px",
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBox: {
    flex: "1",
    display: "flex",
    minHeight: "50px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "12px 0px",
    marginLeft: "10px",
  },
  applyButton: {
    borderRadius: "20px",
    background: "#9D2123",
    color: "white",
    width: "auto",
    height: "auto",
    fontSize: "14px",
    fontWeight: "700",
    padding: "2px 0px",
    textTransform: "none",
    alignSelf: "center",
  },
  descriptionFlexBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "4px",
    width: "100%",
  },
  closeButton: {
    color: "#9D2123",
    fontSize: "25px",
    cursor: "pointer",
    marginRight: "6px",
  },
}));

const CouponCard = ({
  coupon,
  isChecked,
  handleOnSelected,
  showRadio,
  showApply,
  handleApplyOnCouponClicked,
  onRemove,
}) => {
  const classes = useStyles();

  const couponCardClassName = isChecked
    ? showRadio && handleOnSelected
      ? classes.couponCardActive
      : classes.dashedCouponCardActive
    : showApply
      ? classes.bestApplicableCouponCard
      : classes.couponCard;

  return (
    <Card className={couponCardClassName} variant="outlined">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        sx={{ position: "relative" }}
      >
        {showRadio && (
          <Box className={classes.absolutePosition}>
            <Radio
              icon={<CheckCircle color={"disabled"} />}
              checkedIcon={<CheckCircle />}
              color={"secondary"}
              checked={isChecked}
              onChange={handleOnSelected}
              value={coupon.id}
            />
          </Box>
        )}
        {!showApply && (
          <Box className={classes.centerBox}>
            <img
              src={`/icons/coupon-entitlement.svg`}
              alt=""
              className={classes.offerIcon}
            />
          </Box>
        )}
        <Box className={classes.flexBox}>
          <Box className={classes.descriptionFlexBox}>
            <Typography
              variant={"caption"}
              className={
                showRadio
                  ? classes.offerAvailableSpaced
                  : classes.offerAvailable
              }
            >
              {coupon?.offerDescription}
            </Typography>
            {showApply ? (
              <Button
                className={classes.applyButton}
                onClick={() => {
                  handleApplyOnCouponClicked(coupon);
                }}
              >
                Apply
              </Button>
            ) : !showRadio ? (
              <HighlightOffIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const couponRemoved = {
                    couponCode: coupon?.offerId || coupon?.couponName,
                  };
                  AnalyticsService.pushEvent("Coupon Removed", couponRemoved);
                  onRemove(null);
                }}
                className={classes.closeButton}
                fontSize="medium"
              />
            ) : (
              <></>
            )}
          </Box>
          <Accordion
            variant="outlined"
            TransitionProps={{ unmountOnExit: true }}
            disableGutters={true}
            elevation={0}
            sx={{
              border: "none",
              width: "100%",
              background: "transparent",
              padding: 0,
              margin: 0,
              "&.MuiAccordion-root:before": {
                display: "none",
              },
            }}
            onChange={(e, expanded) => {
              const accordionEvent = {
                couponCode: coupon.offerId,
                isExpanded: expanded,
              };
              AnalyticsService.pushEvent("Accordion Clicked", accordionEvent);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                margin: 0,
                ".MuiAccordionSummary-content": {
                  margin: 0,
                },
                "&.MuiAccordionSummary-root": {
                  minHeight: "8px",
                  padding: "0",
                  marginRight: "8px",
                },
              }}
            >
              <Typography className={classes.offerName}>
                <img
                  src={`/icons/offersGreenIcon.svg`}
                  alt=""
                  className={classes.offerIcon}
                />
                {coupon.bestPricetext}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={`some2`} sx={{ padding: "8px 0 0 0" }}>
              <Grid>
                {}

                {_.map(
                  _.transform(
                    coupon.benefits,
                    function (result, value, type) {
                      if (!value) return;
                      if (_.isArray(value)) {
                        _.forEach(value, (v) => {
                          result.push({ type, value: v, product: v });
                        });
                      } else {
                        result.push({ type, value, product: value });
                      }
                    },
                    []
                  ),
                  (e, index) => {
                    return <OfferEntitlements key={index} entitlement={e} />;
                  }
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grid>
    </Card>
  );
};

export default CouponCard;
