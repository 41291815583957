import * as React from "react";

import Dialog from "@mui/material/Dialog";
import { Button, DialogContent, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  dialogContentStyle: {
    borderRadius: "24px",
    marginTop: "4px",
    marginBottom: "4px",
  },
  imgIconStyle: {
    height: "41px",
  },
  titleTextStyle: {
    align: "center",
    fontSize: "18px",
    fontWeight: "700",
    color: "#00733E",
    margin: "16px 36px 0px 36px",
  },
  subTitleTextStyle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#1C1C1C",
    marginTop: "8px",
    alignItems: "center",
  },
  buttonStyle: {
    backgroundColor: "#00733E",
    padding: "12px 32px",
    borderRadius: "16px",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "700",
    marginTop: "16px",
    textTransform: "none",
  },
}));

function CallSentDialog(props) {
  var classes = useStyles();
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{ sx: { borderRadius: "24px" } }}
      >
        <DialogContent className={classes.dialogContentStyle}>
          <Grid alignContent="center" textAlign="center">
            <img
              src="/icons/headsUpIcon.svg"
              alt=""
              align="center"
              className={classes.imgIconStyle}
            />
            <Typography align="center" className={classes.titleTextStyle}>
              Successfully Sent Call Request
            </Typography>
            <Typography align="center" className={classes.subTitleTextStyle}>
              {props.name} will call in some time{" "}
            </Typography>
            <Button
              align="center"
              className={classes.buttonStyle}
              onClick={() => {
                props.handleClose();
              }}
            >
              Done
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CallSentDialog;
