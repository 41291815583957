import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { WithSeeMore, WithHeader } from "react-insta-stories";
import ReactPlayer from "react-player";

const Renderer = ({ story, action, isPaused, config, messageHandler }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { width, height, loader } = config;
  const params = useParams();

  const handleOnWaiting = () => {
    setIsLoaded(false);
  };

  const handleOnPlaying = () => {
    setIsLoaded(true);
  };

  const handleDurationReceived = (seconds) => {
    if (story.setStoryDuration) {
      story.setStoryDuration(seconds);
    }
    messageHandler("UPDATE_VIDEO_DURATION", { duration: seconds });
    setIsLoaded(true);
    setIsPlaying(true);
  };

  useEffect(() => {
    if (!isPlaying && !isLoaded) {
      action("pause");
    } else {
      action("play");
    }
  }, [action, isPlaying, isLoaded]);

  useEffect(() => {
    action("pause");
    setIsInitialized(true);
  }, []);

  return (
    <WithHeader {...{ story, globalHeader: config.header }}>
      <WithSeeMore {...{ story, action }}>
        <Box sx={styles.videoContainer}>
          {isInitialized && params.storyId === story.storyParentCode && (
            <ReactPlayer
              url={story.url}
              playing={isPlaying}
              muted={false}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
              title="Embedded youtube"
              playsinline={true}
              width={window.innerWidth}
              className={""}
              height={window.innerHeight + 200}
              controls={false}
              volume={1}
              config={{
                youtube: {
                  playerVars: {
                    enablejsapi: 1,
                    autoplay: 0,
                    rel: 0,
                    modestbranding: 1,
                    playsinline: 1,
                    fs: 1,
                    disablekb: 1,
                    iv_load_policy: 0,
                  },
                  embedOptions: {},
                },
              }}
              onDuration={handleDurationReceived}
              onBufferEnd={handleOnPlaying}
              onBuffer={handleOnWaiting}
            />
          )}
          {(!isLoaded ||
            !isInitialized ||
            params.storyId !== story.storyParentCode) && (
            <Box
              sx={{
                width: width,
                height: height,
                position: "absolute",
                left: 0,
                top: 0,
                background: "rgba(0, 0, 0, 0.9)",
                zIndex: 9,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ccc",
              }}
            >
              {loader || <img src={`/icons/spinner.svg`} alt="" />}
            </Box>
          )}
        </Box>
      </WithSeeMore>
    </WithHeader>
  );
};

const styles = {
  videoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const Tester = (story) => {
  return {
    condition: story.type === "video",
    priority: 2,
  };
};

const VideoRenderer = {
  renderer: Renderer,
  tester: Tester,
};
export default VideoRenderer;
