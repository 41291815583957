import React, { useEffect, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import Clamp from "react-multiline-clamp";
import { WhatsappShareButton } from "react-share";
import InfiniteScroll from "react-infinite-scroll-component";

import _ from "lodash";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Directions, Call, EditOutlined } from "@mui/icons-material";
import { Button, Card, Grid, IconButton, Typography } from "@mui/material";

import TopBar from "../Common/TopBar";
import useMessages from "../../Hooks/useMessages";
import HisaabService from "../../Services/HisaabService";
import StarRatingLabel from "../Product/StarRatingLabel";
import AndroidService from "../../Services/AndroidService";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";
import EmptyError from "../EmptyError/EmptyError";

const useStyles = makeStyles((theme) => ({
  storeFrontWrapper: {
    position: "relative",
    minHeight: "100vh",
  },
  drawerWrapper: {
    padding: "16px",
    minHeight: "75vh",
    background: theme.palette.background.default,
    position: "relative",
  },
  storeDetailWrapper: {
    width: "100%",
    padding: "12px",
    marginBottom: "20px",
    position: "sticky",
    top: 10,
    zIndex: 1,
    background: theme.palette.background.default,
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "space-between",
    transitionProperty: "transform",
    transitionDuration: "2s",
    transitionTimingFunction: "ease-in-out",
    border: "1px solid rgba(202, 202, 202, 0.3)",
    boxShadow: "0px 12px 24px rgba(28, 28, 28, 0.1)",
  },
  farmerVisitWrapper: {
    display: "flex",
    padding: "10px",
    marginBottom: "30px",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "2px solid #9D2123",
    borderBottom: "2px solid #9D2123",
    background:
      "linear-gradient(270deg, rgba(157, 33, 35, 0) 0%, rgba(157, 33, 35, 0.17) 50.86%, rgba(157, 33, 35, 0) 97.1%);",
  },
  farmerVisitText: { fontSize: "14px", fontWeight: 700 },
  fixedWhatsAppIcon: {
    width: "62px",
    height: "62px",
    position: "fixed",
    right: "15px",
    bottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    borderRadius: "50%",
    backdropFilter: "blur(1.66667px)",
    background: "rgba(76, 175, 80, 0.3) !important",
    boxShadow: "0px 8.33333px 8.33333px rgba(76, 175, 80, 0.2)",
  },
  storeLocation: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    margin: "5px 0 15px",
  },
  storeCTAs: {
    gap: "16px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  productAvailableAtStore: {
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  productCardWrapper: {
    position: "relative",
    padding: "8px",
    marginTop: "20px",
    textAlign: "center",
    background: "#FFF",
    borderRadius: "10px",
    border: "1px solid #CCCCCC",
    boxShadow: "none",
  },
  ratingLabelWrapper: {
    position: "absolute",
    top: "5px",
  },
  productName: { fontSize: "14px", fontWeight: 400, textAlign: "left" },
  bottomBannerWrapper: {
    gap: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottomBannerText: { fontSize: "28px", fontWeight: 700, color: "GrayText" },
}));

function StoreFront() {
  const classes = useStyles();
  const { getMessage } = useMessages();
  const storeDetailsWrapperRef = useRef();
  const [isSticky, setIsSticky] = useState(false);
  const [shareText, setShareText] = useState(null);
  const storeUrl = PartnerService.getStoreFrontUrl();
  const [loading, setLoading] = useState("not_started");
  const [storeDetails, setStoreDetails] = useState({});
  const [productsInStore, setProductsInStore] = useState({});
  const [inventoryFetchedCount, setInventoryFetchedCount] = useState(0);

  useEffect(() => {
    setLoading("started");
    AnalyticsService.pushEvent("Page Viewed", { page: "Store Front" });

    const retailerId = localStorage.getItem("farmerId");
    const { axiosRequest, abortController } =
      PartnerService.getStoreDetails(retailerId);
    axiosRequest
      .then((storeDetailsResponse) => {
        const storeDetails = storeDetailsResponse?.data?.responseData?.[0];
        setStoreDetails(storeDetails);
        setLoading("done");

        fetchStoreInventory();
        const { axiosRequest } =
          HisaabService.getInstitutionDetails(retailerId);
        axiosRequest
          .then((response) => {
            const institutionDetails = response?.data?.responseData;

            const whatsappMessage = `${getMessage(
              "profile.header"
            )}\r\n\r\n${getMessage("profile.storeName")}${
              institutionDetails?.nameInRegionalLanguage
            }\r\n${getMessage("profile.storeAddress")}${
              institutionDetails?.addressInRegionalLanguage?.village
            }, ${institutionDetails?.addressInRegionalLanguage?.taluka}, ${
              institutionDetails?.addressInRegionalLanguage?.district
            }, ${institutionDetails?.addressInRegionalLanguage?.state}, ${
              institutionDetails?.addressInRegionalLanguage?.pincode
            }.\r\n${getMessage("profile.storePhoneNumber")}${
              institutionDetails?.contacts?.mobileNumber
            }\r\n`;

            setShareText(whatsappMessage);
          })
          .catch((e) => {
            console.error("Failed to get Institution Details: ", e?.message);
          });
      })
      .catch((e) => {
        if (e?.message !== "canceled") {
          setLoading("error");
          console.error("Failed to get Partner Details: ", e?.message);
        }
      });

    window.addEventListener("scroll", () => {
      if (storeDetailsWrapperRef?.current) {
        const client = storeDetailsWrapperRef.current?.getBoundingClientRect();
        if (client.top <= 15) client.height > 70 && setIsSticky(true);
        if (client.top > 100) client.height < 70 && setIsSticky(false);
      }
    });
    return () => abortController.abort();
  }, []);

  const fetchStoreInventory = () => {
    const retailerId = localStorage.getItem("farmerId");
    const { axiosRequest } = PartnerService.getProductsInStore(
      retailerId,
      inventoryFetchedCount
    );
    axiosRequest
      .then((response) => {
        const productGists = response?.data?.responseData?.productGists;
        if (productGists?.length) {
          setInventoryFetchedCount(
            (previousInventoryFetchedCount) =>
              previousInventoryFetchedCount + productGists?.length
          );
          setProductsInStore((previousProductsInStore) =>
            previousProductsInStore?.productGists
              ? {
                  ...previousProductsInStore,
                  productGists: [
                    ...previousProductsInStore?.productGists,
                    ...productGists,
                  ],
                }
              : response?.data?.responseData
          );
        }
      })
      .catch((e) => {
        console.error("Failed to get Store Products: ", e?.message);
      });
  };

  const handleWhatsAppShareClickEvent = () => {
    AnalyticsService.pushEvent("Button Clicked", { type: "WhatsApp Share" });
  };

  const handleAndroidShareClickEvent = () => {
    handleWhatsAppShareClickEvent();
    AndroidService.shareOnWhatsApp(`${shareText}
👉 ${storeUrl}`);
  };

  return (
    <>
      <TopBar
        title={`Digital Dukaan`}
        backNavigation={true}
        showCart={false}
        showHisaab={true}
        toolTip={`Digital Dukaan`}
      />
      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Loading...`}
        style={{ minHeight: "80vh" }}
        keepInView
      >
        {loading === "done" && (
          <div className={classes.storeFrontWrapper}>
            {storeDetails?.storeImage ? (
              <img
                width="100%"
                alt="Store Img"
                src={`https://res.cloudinary.com/agrostar/image/fetch/c_fill,g_auto,q_auto,f_auto,h_900,w_1600/${storeDetails?.storeImage}`}
              />
            ) : (
              <img
                src={`/images/ogStoreImage.png`}
                alt="Store Img"
                width="100%"
              />
            )}

            <Box className={classes.drawerWrapper}>
              <div
                id="storeDetailWrapper"
                ref={storeDetailsWrapperRef}
                className={classes.storeDetailWrapper}
                style={{ display: isSticky ? "flex" : "block" }}
              >
                <Clamp lines={1} maxLines={1}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {storeDetails?.storeName}
                  </Typography>
                </Clamp>

                {!isSticky && (
                  <div className={classes.storeLocation}>
                    <img src="/icons/location.svg" alt="location" />
                    <Typography
                      variant="subtitle2"
                      fontSize={12}
                      fontWeight={400}
                      sx={{ lineHeight: "1.2", color: "#666", ml: 1 }}
                    >
                      {storeDetails?.storeAddress}
                    </Typography>
                  </div>
                )}

                <div className={classes.storeCTAs}>
                  <Button
                    href={`https://www.google.com/maps/dir/?api=1&destination=${storeDetails?.latitude},${storeDetails?.longitude}`}
                    target="_blank"
                    sx={{
                      minWidth: "38px",
                      width: isSticky ? "38px" : "100%",
                      fontSize: "12px",
                      fontWeight: "700",
                      color: "#00733E",
                      background: "#FFF",
                      border: "1px solid #00733E",
                      "&:hover": { color: "#00733E", background: "#FFF" },
                      borderRadius: "10px",
                    }}
                    onClick={() =>
                      AnalyticsService.pushEvent("Button Clicked", {
                        type: "Directions",
                      })
                    }
                  >
                    <Directions />
                    {isSticky ? (
                      ""
                    ) : (
                      <span style={{ marginLeft: "8px" }}>Directions</span>
                    )}
                  </Button>
                  <Button
                    href={`tel:${storeDetails?.phoneNumber}`}
                    sx={{
                      minWidth: "38px",
                      width: isSticky ? "38px" : "100%",
                      fontSize: "12px",
                      fontWeight: "700",
                      color: "#FFF",
                      background: "#00733E",
                      border: "1px solid #00733E",
                      "&:hover": { color: "#FFF", background: "#00733E" },
                      borderRadius: "10px",
                    }}
                    onClick={() =>
                      AnalyticsService.pushEvent("Button Clicked", {
                        type: "Call now",
                      })
                    }
                  >
                    <Call />
                    {isSticky ? (
                      ""
                    ) : (
                      <span style={{ marginLeft: "8px" }}>Call now</span>
                    )}
                  </Button>
                </div>
              </div>

              {storeDetails?.farmerVisits > 0 && (
                <div className={classes.farmerVisitWrapper}>
                  <p className={classes.farmerVisitText}>
                    <span style={{ color: "#9D2123" }}>
                      {storeDetails?.farmerVisits}{" "}
                    </span>
                    Farmers bought from this store
                  </p>
                </div>
              )}

              {productsInStore?.productGists?.length && (
                <InfiniteScroll
                  next={fetchStoreInventory}
                  dataLength={inventoryFetchedCount}
                  hasMore={
                    inventoryFetchedCount < productsInStore?.totalProductsCount
                  }
                  loader={<h5 style={{ textAlign: "center" }}>Loading...</h5>}
                >
                  <Typography className={classes.productAvailableAtStore}>
                    <span>
                      Products Available at Your store (
                      {productsInStore?.totalProductsCount})
                    </span>
                    <IconButton
                      href="/store-front-catalog"
                      sx={{
                        width: "32px",
                        height: "32px",
                        background: "#dde8e4",
                      }}
                      onClick={() =>
                        AnalyticsService.pushEvent("Button Clicked", {
                          type: "Edit Products",
                        })
                      }
                    >
                      <EditOutlined
                        sx={{ width: "20px", height: "20px", color: "#00733e" }}
                      />
                    </IconButton>
                  </Typography>

                  <Grid
                    container
                    justifyContent="space-between"
                    sx={{ mb: "20px" }}
                  >
                    {_.map(productsInStore?.productGists, (product, index) =>
                      product?.productName &&
                      product?.productImages?.[0]?.name ? (
                        <Grid item xs={5.5} key={index}>
                          <Card className={classes.productCardWrapper}>
                            <div className={classes.ratingLabelWrapper}>
                              <StarRatingLabel
                                rating={product.rating.average}
                              />
                            </div>

                            <img
                              src={product?.productImages?.[0]?.name}
                              alt={product.productName}
                              height="125px"
                            />

                            <Clamp lines={2} maxLines={2}>
                              <span className={classes.productName}>
                                {product.productName}
                              </span>
                            </Clamp>
                          </Card>
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                </InfiniteScroll>
              )}

              <div className={classes.bottomBannerWrapper}>
                <img src={`/icons/storeDiscountMan.svg`} />
                <p className={classes.bottomBannerText}>
                  Give Discount At Store On Their Bill
                </p>
              </div>
            </Box>

            {AndroidService.checkIfAndroid() ? (
              <div
                className={classes.fixedWhatsAppIcon}
                onClick={handleAndroidShareClickEvent}
              >
                <img src={`/icons/whatsapp.svg`} width="32px" height="32px" />
              </div>
            ) : (
              <WhatsappShareButton
                className={classes.fixedWhatsAppIcon}
                url={`
    👉 ${storeUrl}`}
                title={shareText}
                onClick={handleWhatsAppShareClickEvent}
              >
                <img src={`/icons/whatsapp.svg`} width="32px" height="32px" />
              </WhatsappShareButton>
            )}
          </div>
        )}

        {loading === "error" && (
          <EmptyError
            image="EmptyPage.svg"
            text="Ooooops!"
            subText="Something went wrong, Please try later."
          />
        )}
      </BlockUi>
    </>
  );
}

export default StoreFront;
