import { useNavigate } from "react-router-dom";
import { Box, Button, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CartService from "../../Services/CartService";
import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";
import React, { useState } from "react";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";
import ViewOrUpdateCartButton from "./ViewOrUpdateCartButton";

import useQuery from "../../Hooks/useQuery";
import PayAdvanceForSkuDialog from "./PayAdvanceForSkuDialog";
import useRazorpay from "react-razorpay";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  cartButton: {
    borderRadius: "0px",
  },

  iconSize: {
    height: "22px",
    marginTop: "-3px",
  },
  bottomButtons: {
    width: "100%",
  },
}));

function BookNowButton(props) {
  const Razorpay = useRazorpay();
  const classes = useStyles();
  let navigate = useNavigate();
  const query = useQuery();
  const [showPayAdvanceDialog, setShowPayAdvanceDialog] = useState(false);
  const handleClosePayAdvance = () => {
    setShowPayAdvanceDialog(false);
  };
  const handlePaymentStatus = (paymentId) => {
    navigate(`/hisaab/payment-status/${paymentId}`);
  };
  const handleRazorpayFailure = (error, errorCode = null) => {
    const urlParams = errorCode ? `?errorCode=${errorCode}` : "";
    navigate(`/hisaab/razorpay-failure/${error}${urlParams}`);
  };
  const [paymentLoading, setPaymentLoading] = useState("not_started");
  const amountFromParams = query.get("amount");
  const directPayment = query.get("payment");
  return (
    <>
      <Box
        sx={{
          bottom: 0,
          position: "fixed",
          // marginLeft: "-16px",
          display: "flex",
          width: "100%",
          boxShadow: "0 -4px 10px 0 rgba(129, 129, 129, 0.3)",
          height: "50px",
          zIndex: 3,
          maxWidth: "600px",
        }}
      >
        {props?.loading === "started" && (
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={50}
            sx={{ backgroundColor: "#CF8687", width: "100%" }}
          />
        )}
        {props.loading === "done" && (
          <>
            <Button
              size={"large"}
              sx={{
                width: "100%",
                borderRadius: "0px",
                filter: PartnerService.applySmudgedPriceFilter(props?.product),
              }}
              variant={"contained"}
              color={"primary"}
              fullWidth={true}
              onClick={() => {
                setShowPayAdvanceDialog(true);
                AnalyticsService.pushEvent(`Book Now Clicked`, {
                  quantity: props?.quantity,
                  skuCode: props?.product?.skuCode,
                  productName: props?.product?.productName,
                  paymentAmount: props?.price * props?.quantity,
                });
              }}
              disabled={props?.quantity < 1}
              type={"submit"}
            >
              Book Now @ ₹{props?.price}
              {props?.schemeUnit ? `/- Per ${props?.schemeUnit}` : ``}
            </Button>
          </>
        )}
      </Box>
      <PayAdvanceForSkuDialog
        open={showPayAdvanceDialog}
        paymentAmount={props?.price * props?.quantity}
        handleClose={handleClosePayAdvance}
        handlePaymentStatus={handlePaymentStatus}
        handleRazorpayFailure={handleRazorpayFailure}
        paymentLoading={paymentLoading}
        setPaymentLoading={setPaymentLoading}
        amountFromParams={amountFromParams}
        reasonFromParams={"others"}
        productName={props?.product?.productName}
        quantity={props?.quantity}
        price={props?.price}
        skuCode={props?.product?.skuCode}
        schemeUnit={props?.schemeUnit}
        schemeName={props?.schemeName}
        schemeUnitMultiple={props?.schemeUnitMultiple}
      />
    </>
  );
}

export default BookNowButton;
