// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "product.header": {
    en: "Dear Farmer🙏🙏\n\r\n\rCheck out this high quality product available at my AgroStar Saathi shop 🥳🥳 You can reach out to me to buy this now 📞👇",
    gu: "પ્રિય કિસાન🙏🙏\n\r\n\rમારી એગ્રોસ્ટાર સાથી દુકાન પર ઉપલબ્ધ આ ઉચ્ચ ગુણવત્તા વાળા પ્રોડક્ટ ને જુઓ 🥳🥳 તમે તેને હમણાં ખરીદવા માટે મારી સાથે સંપર્ક કરી શકો છો ! 📞👇",
    hi: "प्रिय किसान🙏🙏\n\r\n\rमेरी एग्रोस्टार साथी दुकान पर उपलब्ध इस उच्च गुणवत्ता वाले उत्पाद को देखें। 🥳🥳 आप इसे अभी खरीदने के लिए मुझसे संपर्क कर सकते हैं! 📞👇",
    mr: "प्रिय शेतकरी🙏🙏\n\r\n\rमाझ्या अॅग्रोस्टार साथी स्टोअरवर उपलब्ध असलेले उच्च दर्जाचे उत्पादने पहा. 🥳🥳 तुम्ही आता हे विकत घेण्यासाठी माझ्याशी संपर्क करू शकता! 📞👇",
  },
  "product.details": {
    en: "Product Details👇👇",
    gu: "પ્રોડક્ટ ની માહિતી 👇👇",
    hi: "प्रोडक्ट की जानकारी 👇👇",
    mr: "प्रोडक्टची माहिती 👇👇",
  },
  "product.testimonialVideo": {
    en: "What other farmers are saying 📹👇",
    gu: "અન્ય કિસાન મંતવ્યો શું છે 📹👇",
    hi: "क्या कह रहे हैं दूसरे किसान भाई 📹👇",
    mr: "इतर शेतकरी बांधव काय म्हणतात 📹👇",
  },
  "product.address": {
    en: "AgroStar Saathi Store Address 🏪👇",
    gu: "એગ્રોસ્ટાર સાથી દુકાન નું સરનામું 🏪👇",
    hi: "एग्रोस्टार साथी दुकान का पता 🏪👇",
    mr: "अॅग्रोस्टार साथी स्टोअरचा पत्ता 🏪👇",
  },
  "product.owner": {
    en: "Owner name",
    gu: "માલિક નું નામ",
    hi: "मालिक का नाम",
    mr: "मालकाचे नाव",
  },
  "product.contact": {
    en: "Contact",
    gu: "સંપર્ક કરો",
    hi: "सम्पर्क  करें",
    mr: "संपर्क करा",
  },

  "profile.header": {
    en: "Dear Farmer🙏🙏\n\r\n\rNow get Agrostar's high quality products at my AgroStar Saathi shop 🥳🥳 Come visit my store today 👇",
    gu: "પ્રિય કિસાન🙏🙏\n\r\n\rહવે એગ્રોસ્ટારની ઉત્તમ પ્રોડક્ટ મેળવો મારી એગ્રોસ્ટાર સાથી સેન્ટર પર 🥳🥳 તો આજે જ મુલાકાત લો 👇",
    hi: "प्रिय किसान🙏🙏\n\r\n\rअब पाए एग्रोस्टार के उत्तम कृषि उत्पाद मेरे एग्रोस्टार साथी सेंटर पर 🥳🥳 तो फिर चलो आज ही पधारे 👇",
    mr: "प्रिय शेतकरी🙏🙏\n\r\n\rआता अॅग्रोस्टार चे उत्कृष्ट कृषी उत्पादन मिळवा माझ्या अॅग्रोस्टार साथी केंद्रात 🥳🥳 चला तर मग आजच भेट द्या 👇",
  },
  "profile.storeName": {
    en: "Saathi Store Name 🏪: ",
    gu: "મારા સાથી સ્ટોરનું નામ 🏪: ",
    hi: "मेरे साथी स्टोर का नाम 🏪: ",
    mr: "माझ्या साथी स्टोअरचे नाव 🏪: ",
  },
  "profile.storeAddress": {
    en: "Address 📍: ",
    gu: "સ્ટોરનું સરનામું 📍: ",
    hi: "स्टोर का पता 📍: ",
    mr: "स्टोरचा पता 📍: ",
  },
  "profile.storePhoneNumber": {
    en: "Phone Number 📞: ",
    gu: "ફોન નંબર 📞: ",
    hi: "फ़ोन नंबर 📞: ",
    mr: "फ़ोन नंबर 📞: ",
  },
  "video.title": {
    en: "I like This Video 👌👌👀 \r\n इथे पहा- ",
    mr: "मला हा व्हिडिओ आवडला 👌👌👀 \r\n इथे पहा- ",
    gu: "મને આ વિડિયો ગમે છે 👌👌👀 \r\n અહીં જુઓ- ",
    hi: "मुझे ये वीडियो पसंद आया है 👌👌👀 \r\n यहाँ देखे - ",
  },
  "grahak.whatsappMessage1": {
    en: "नमस्ते",
    gu: "નમસ્તે",
    hi: "नमस्ते",
    mr: "नमस्कार",
  },
  "grahak.whatsappMessage2": {
    en: "🙏🙏 \r\n\r\nएग्रोस्टार ने मुझे आपके साथ जोड़ा है🤝🤝। आप मेरे स्टोर🏪 से",
    gu: "🙏🙏 \r\n\r\nએગ્રોસ્ટારે મને તમારી સાથે જોડ્યો છે🤝🤝. તમે મારા સ્ટોરમાંથી🏪",
    hi: "🙏🙏 \r\n\r\nएग्रोस्टार ने मुझे आपके साथ जोड़ा है🤝🤝। आप मेरे स्टोर🏪 से",
    mr: "🙏🙏 \r\n\r\nअॅग्रोस्टारने मला तुमच्याशी जोडले आहे🤝🤝. तुम्ही माझ्या स्टोअरमधून🏪",
  },
  "grahak.whatsappMessage3": {
    en: "या कोई अन्य एग्रोस्टार उत्पाद खरीद सकते हैं। उत्पाद खरीदने के लिए कृपया मुझसे संपर्क📞 करें। \r\n\r\nधन्यवाद🙏🙏 \r\n",
    gu: "અથવા અન્ય કોઈપણ એગ્રોસ્ટાર ઉત્પાદન ખરીદી શકો છો. ઉત્પાદન ખરીદવા માટે કૃપા કરીને મારો સંપર્ક📞 કરો. \r\n\r\nધન્યવાદ🙏🙏 \r\n",
    hi: "या कोई अन्य एग्रोस्टार उत्पाद खरीद सकते हैं। उत्पाद खरीदने के लिए कृपया मुझसे संपर्क📞 करें। \r\n\r\nधन्यवाद🙏🙏 \r\n",
    mr: "किंवा इतर कोणतेही अॅग्रोस्टार उत्पादन खरेदी करू शकता. उत्पादन खरेदी करण्यासाठी कृपया माझ्याशी संपर्क📞 साधा. \r\n\r\nधन्यवाद🙏🙏 \r\n",
  },
  "grahak.whatsappMessage4": {
    en: "\r\nपता🏪 - ",
    gu: "\r\nસરનામું🏪 - ",
    hi: "\r\nपता🏪 - ",
    mr: "\r\nपता🏪 - ",
  },
  "grahak.whatsappMessage5": {
    en: "\r\nसंपर्क नंबर📞 - ",
    gu: "\r\nસંપર્ક નંબર📞 - ",
    hi: "\r\nसंपर्क नंबर📞 - ",
    mr: "\r\nसंपर्क नंबर📞 - ",
  },
  "complaintHistory.header": {
    hi: "समस्या टिकिट",
    en: "Samasya Ticket",
    gu: "સમસ્યા ટિકિટ",
    mr: "समस्या तिकीट",
  },
  "complaintHistory.fabHeader": {
    hi: "समस्या",
    en: "Samasya",
    gu: "સમસ્યા",
    mr: "समस्या",
  },
  "complaintHistory.noComplaintsMessage": {
    en: "You don't have any complaints yet!",
    hi: "आपको अभी तक कोई समस्या नहीं आई है।",
    mr: "तुम्हाला सध्या कोणतीही समस्या नाही आहे",
    gu: "તમે હજી સુધી કોઈ સમસ્યાનો સામનો કર્યો નથી",
  },
  "createComplaint.selectIssueHeader": {
    en: "Choose your issue",
    mr: "तुमची समस्या निवडा",
    gu: "તમારી સમસ્યા પસંદ કરો",
    hi: "अपनी समस्या चुनें",
  },
  "createComplaint.selectReasonHeader": {
    en: "Select the reason(s)",
    mr: "समस्येचे कारण निवडा",
    gu: "સમસ્યાનું કારણ પસંદ કરો",
    hi: "समस्या का कारण चुनें",
  },
  "createComplaint.descriptionHeader": {
    en: "Tell us more details",
    mr: "आम्हाला अधिक माहिती सांगा",
    gu: "વર્ણનઅમને વધુ માહિતી જણાવો",
    hi: "हमें अधिक जानकारी बताएं",
  },
  "createComplaint.addAttachmentWarning": {
    en: "You can upload Upto 10MB file ",
    mr: "तुम्ही 10MB पर्यंत फाइल अपलोड करू शकता",
    gu: "તમે 10MB સુધીની ફાઇલ અપલોડ કરી શકો છો",
    hi: "आप 10 एमबी तक की फ़ाइल अपलोड कर सकते हैं",
  },
  "createComplaint.addAttachmentsButtonHeader": {
    en: "Add image/document",
    gu: "ઇમેજ/ડોક્યુમેન્ટ અપલોડ કરો",
    mr: "इमेज/डॉक्यूमेंट अपलोड करा",
    hi: "इमेज/डॉक्यूमेंट अपलोड करें",
  },
  "createComplaint.descriptionPlaceHolder": {
    en: "Please type here",
    mr: "कृपया येथे टाइप करा",
    gu: "કૃપા કરીને અહીં લખો",
    hi: "कृपया यहां टाइप करें",
  },
  "createComplaint.submitButton": {
    hi: "दर्ज करें",
    en: "Submit",
    gu: "દાખલ કરો",
    mr: "दाखल करा",
  },
  "createComplaint.redirectToTicketsButtonTitle": {
    hi: "अपने टिकट देखें",
    en: "Go To Your Tickets",
    gu: "તમારી ટિકિટ જુઓ",
    mr: "तुमचे तिकीट पहा",
  },
  "createComplaint.confirmationMessage": {
    hi: "आपकी समस्या (ID -) सफलतापूर्वक दर्ज कर ली गई है",
    en: "Your Samasya (ID -) has been successfully registered",
    gu: "તમારી સમસ્યા (ID -) સફળતાપૂર્વક નોંધવામાં આવી છે",
    mr: "तुमची समस्या (ID -) यशस्वीरित्या नोंदवण्यात आली आहे",
  },
  "createComplaint.createComplaintHeader": {
    en: "Raise a complaint",
    mr: "समस्या दाखल करा",
    gu: "સમસ્યા ફાઇલ કરો",
    hi: "समस्या दर्ज़ करे",
  },
  "story.share": {
    en: "Check out this amazing story from Agrostar!👀👌",
    gu: "એગ્રોસ્ટારની આ અદ્ભુત પોસ્ટ અવશ્ય જુઓ!👀👌",
    hi: "एग्रोस्टार की इस दिलचस्प पोस्ट को जरूर देखें!👀👌",
    mr: "अॅग्रोस्टार ची ही अप्रतिम पोस्ट जरूर पहा!👀👌",
  },
  "createComplaint.selectProductsTitle": {
    en: "Please Select Products",
    gu: "કૃપા કરીને પ્રોડક્ટ પસંદ કરો",
    hi: "कृपया प्रोडक्टस चुनें",
    mr: "कृपया प्रोडक्ट निवडा",
  },
  "createComplaint.selectProductsDescription": {
    en: "Please add products which you are facing issues with, and select  reason accordingly",
    gu: "કૃપા કરીને એવી પ્રોડક્ટ ઉમેરો જેનાથી તમને સમસ્યા આવી રહી છે  અને તે મુજબ કારણ પસંદ કરો",
    hi: "कृपया ऐसे प्रोडक्ट जोड़े जिनसे आपको समस्या आ रही है, और उसके अनुसार कारण चुनें",
    mr: "कृपया तुम्हाला ज्या प्रोडक्टमध्ये समस्या येत आहेत ते जोडा व त्यानुसार कारण निवडा",
  },
  "createComplaint.selectProducts": {
    en: "Select Products",
    gu: "પ્રોડક્ટ પસંદ કરો",
    hi: "प्रोडक्टस चुनें",
    mr: "प्रोडक्ट निवडा",
  },
  "createComplaint.selectedProductsTitle": {
    en: "Product and cause of complaint",
    gu: "પ્રોડક્ટ અને ફરિયાદનું કારણ",
    hi: "प्रोडक्ट और शिकायत का कारण",
    mr: "प्रोडक्ट व तक्रारीचे कारण",
  },

  "referral.whatsappMessage": {
    en: "Hello 🙏,\nI have grown my business by taking up an AgroStar Dealership. Now, it's your turn\n\n▶ Open the Agrostar Saathi website using my link.\n▶ Log in with your mobile number 📱.\n▶ Use my referral code *%1$s* in the form.\n▶ Submit documents 📄, place your first order, and enjoy the benefits 🎁.\n\nHurry Up!! Click on my Agrostar Saathi link and enjoy benefits🎁up to ₹5000! 👇👇\nhttps://saathi.agrostar.in/?referralCode=",

    hi: "नमस्ते 🙏,\nमैंने बढ़ाया अपना बिजनेस एग्रोस्टार की डीलरशिप लेकर अब बारी है आपकी।\n\n▶ मेरे लिंक का उपयोग करके एग्रोस्टार साथी वेबसाइट खोलें।\n▶ अपने मोबाइल नंबर से लॉगिन करें 📱।\n▶ फॉर्म में मेरा रेफरल कोड  *%1$s* इस्तेमाल करें।\n▶ डाक्यूमेंट्स 📄 सबमिट करके पहला ऑर्डर करें और बेनिफ़िट्स 🎁 का लाभ पाएं।\n\nअब और कितनी देर? जल्दी से एग्रोस्टार साथी लिंक पर क्लिक करें और 5 हजार का शगुन गिफ्ट🎁 पाएं! 👇👇\nhttps://saathi.agrostar.in/?referralCode=",

    gu: "નમસ્કાર 🙏,\nમેં એગ્રોસ્ટાર ની ડીલરશીપ લઈને મારો બિઝનેસ વધાર્યો, હવે તમારો વારો છે.\n\n▶ મારી લિંકનો ઉપયોગ કરીને એગ્રોસ્ટાર સાથી વેબસાઇટ ખોલો.\n▶ તમારા મોબાઈલ નંબર વડે લોગિન કરો.\n▶ ફોર્મમાં મારા રેફરલ કોડ  *%1$s* નો ઉપયોગ કરો.\n▶ ડોક્યુમેન્ટ 📄 સબમિટ કરીને તમારો પ્રથમ ઓર્ડર આપો અને 🎁 લાભ મેળવો.\n\nહવે શું વાર છે? ઝડપ થી એગ્રોસ્ટાર સાથી લિંક પર ક્લિક કરો અને મેળવો 5 હજાર ની શગુન🎁 ભેટ 👇👇\nhttps://saathi.agrostar.in/?referralCode=",

    mr: "नमस्कार 🙏,\nॲग्रोस्टार डीलरशिपसह मी माझा बिझनेस वाढवला, आता आहे तुमची बारी.\n\n▶  माझी लिंक वापरून ॲग्रोस्टार साथी वेबसाइट ओपन करा.\n▶  तुमच्या मोबाईल नंबरने लॉग इन करा 📱.\n▶  फॉर्ममध्ये माझा रेफरल कोड *%1$s* वापरा.\n▶  कागदपत्रे 📄 सबमिट करून तुमची पहिली ऑर्डर द्या आणि फायदे 🎁 मिळवा.\n\nआता अजून किती वेळ घेणार ?  त्वरीत ॲग्रोस्टार साथी लिंकवर क्लिक करा आणि 5,000 रुपयांची आकर्षक भेट 🎁 मिळवा! 👇👇\nhttps://saathi.agrostar.in/?referralCode=",

    te: "నమస్కారం 🙏,\nనేను ఆగ్రోస్టార్ డీలర్‌షిప్ తీసుకొని నా వ్యాపారాన్ని విస్తరించాను, ఇప్పుడు మీ వంతు వచ్చింది.\n\n▶ నా లింక్‌ని ఉపయోగించి ఆగ్రోస్టార్ సాతి  వెబ్‌సైట్‌ని తెరవండి.\n▶ మీ మొబైల్ నంబర్‌తో లాగిన్ అవ్వండి 📱.\n▶ ఫారమ్‌లో నా రెఫరల్  కోడ్ *%1$s* ఉపయోగించండి.\n▶పత్రాలను సమర్పించడం ద్వారా మొదటి ఆర్డర్ చేయండి 📄 మరియు ప్రయోజనాలను పొందండి 🎁.\n\nఇంకెంత కాలం? ఆగ్రోస్టార్ సాతి లింక్‌పై త్వరగా క్లిక్ చేయండి మరియు రూ.5 వేలు విలువైన వెల్కమ్  బహుమతిని పొందండి.\n👇👇\nhttps://saathi.agrostar.in/?referralCode=",

    kn: "ನಮಸ್ಕಾರ 🙏,\nನಾನು ಆಗ್ರೋಸ್ಟಾರ್ ಡೀಲರ್‌ಶಿಪ್  ಮೂಲಕ ನನ್ನ ವ್ಯಾಪಾರವನ್ನು ವಿಸ್ತರಿಸಿದೇನೆ , ಈಗ ಇದು ನಿಮ್ಮ ಬಾರಿ .\n\n▶ ನನ್ನ ಲಿಂಕ್ ಬಳಸಿ ಮತ್ತು ಅಗ್ರೋಸ್ಟಾರ್ ಸಾಥೀ  ವೆಬ್‌ಸೈಟ್ ಗೆ  ಹೋಗಿ.\n▶ ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ 📱.\n▶ ನನ್ನ ರೆಫರಲ್ ಕೋಡ್ *%1$s* ಬಳಸಿ.\n▶ ದಾಖಲೆಗಳನ್ನು ಸಲ್ಲಿಸುವ ಮೂಲಕ  ಆರ್ಡರ್ ಮಾಡಿ 📄 ಮತ್ತು ಪ್ರಯೋಜನಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳಿ 🎁.\n\nಅಗ್ರೋಸ್ಟಾರ್  ಸಾಥಿ ಲಿಂಕ್ ಅನ್ನು ತ್ವರಿತವಾಗಿ ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು 5,000 ರೂಪಾಯಿ ಮೌಲ್ಯದ ಶಗುನ್ ಗಿಫ್ಟ್ ಯನ್ನು  ಪಡೆಯಿರಿ!👇👇\nhttps://saathi.agrostar.in/?referralCode=",
  },
  "createComplaint.selectSamasyaText": {
    en: "Please select your Samasya to continue",
    mr: "कृपया आपली समस्या निवडा",
    gu: "કૃપયા તમારી સમસ્યા પસંદ કરવાનું જારી રાખો",
    hi: "कृपया अपनी समस्या चुनें",
  },
};
