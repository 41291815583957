import React, { useState } from "react";
import BlockUi from "react-block-ui";

import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { ArrowRightAlt, Close } from "@mui/icons-material";

import useQuery from "../../Hooks/useQuery";
import AmountUtils from "../../Utils/AmountUtils";
import AnalyticsService from "../../Services/AnalyticsService";

export default function PaymentSettledSuccessPage() {
  const query = useQuery();
  const paidAmount = query.get("paidAmount");
  const settlementType = query.get("settlementType");
  const [loading, setLoading] = useState("not-started");

  return (
    <BlockUi
      tag="div"
      blocking={loading === "started"}
      message={`Loading...`}
      keepInView
      renderChildren={false}
      style={{ height: "95vh" }}
    >
      <Box sx={{ background: "#E6F2EC", height: "92vh" }}>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <IconButton
              href="/hisaab/transactions"
              size={"large"}
              sx={{ color: "#757575", marginLeft: "6px" }}
              onClick={() => {
                AnalyticsService.pushEvent("Payment Status Dialog Closed", {
                  amount: parseInt(paidAmount),
                  settlementType: settlementType,
                });
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Box>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginTop: "15vh" }}
          >
            <Grid item>
              <img src={`/icons/success.svg`} alt="" />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginTop: "3vh" }}
          >
            <Grid item>
              <Typography
                variant={"h6"}
                align={"center"}
                color={"secondary"}
                sx={{ fontWeight: "700" }}
              >
                Payment Settled
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginTop: "1vh" }}
          >
            <Grid item>
              <Typography variant={"body2"} align={"center"}>
                Amount Settled : <b>{AmountUtils.formatCurrency(paidAmount)}</b>
              </Typography>
              <Typography variant={"body2"} align={"center"}>
                Settlement Type :{" "}
                <b style={{ textTransform: "capitalize" }}>{settlementType}</b>
              </Typography>
            </Grid>
          </Grid>
          <Button
            href="/hisaab/transactions"
            variant={"contained"}
            fullWidth
            color={"secondary"}
            endIcon={<ArrowRightAlt />}
            onClick={() => {
              AnalyticsService.pushEvent(
                "Payment Success Dialog Go To Transactions Clicked",
                {
                  amount: parseInt(paidAmount),
                  settlementType: settlementType,
                }
              );
            }}
            sx={{
              marginTop: "8px",
              position: "fixed",
              bottom: 0,
              borderRadius: "0",
              zIndex: 0,
              height: "8vh",
              maxWidth: "600px",
            }}
          >
            Go to Transactions
          </Button>
        </Box>
      </Box>
    </BlockUi>
  );
}
