import React, { useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Button, DialogContent, Grid, Typography } from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";

function CatalogError(props) {
  useEffect(() => {
    if (props.open) {
      AnalyticsService.pushEvent("Store Front Catalog Error Dialog Viewed");
    }
  }, [props.open]);
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      PaperProps={{
        style: { borderRadius: "32px" },
      }}
    >
      <DialogContent>
        <Grid container alignItems={`center`} justifyContent={`center`}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <ErrorIcon fontSize={"large"} sx={{ color: "#9D2123" }} />
            </Box>

            <Typography
              variant={`body1`}
              sx={{
                textAlign: "center",
                color: "#9D2123",
                marginTop: "10px",
              }}
              // fontSize={18}
              // fontWeight={700}
            >
              Something went wrong,
              <br /> Please try again.
            </Typography>

            <Box sx={{ textAlign: "center", marginTop: "16px" }}>
              <Button
                variant={`outlined`}
                size={"large"}
                color={"secondary"}
                sx={{
                  borderRadius: "16px",
                  background: "rgba(255, 255, 255, 1)",
                  color: "rgba(0, 115, 62, 1)",
                }}
                onClick={() => {
                  props.handleClickDone();
                }}
              >
                Okay
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CatalogError;
