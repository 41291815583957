import React, { useEffect } from "react";
import _ from "lodash";

import { Dialog, DialogContent, Grid, Typography } from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";
import { HighlightOffRounded } from "@mui/icons-material";
import AmountUtils from "../../Utils/AmountUtils";

export default function InventoryDetailsDialog(props) {
  useEffect(() => {
    if (props.open) {
      AnalyticsService.pushEvent("Dialog Shown", { type: "Inventory Details" });
    }
  }, [props.open]);

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={() => props.setOpen(false)}
      PaperProps={{ style: { borderRadius: "32px" } }}
    >
      <DialogContent>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6} sx={{ mb: 1 }}>
            <Typography variant="h6" fontSize={18} fontWeight={700}>
              Stock
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mb: 1, textAlign: "right" }}>
            <HighlightOffRounded onClick={() => props.setOpen(false)} />
          </Grid>

          <Grid item xs={6} sx={{ borderBottom: "1px solid #cacaca" }}>
            <Typography variant="subtitle2" fontWeight={700}>
              Warehouse
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "right", borderBottom: "1px solid #cacaca" }}
          >
            <Typography variant="subtitle2" fontWeight={700}>
              Inventory
            </Typography>
          </Grid>

          {_.sortBy(
            Object.entries(props?.product?.inventoryDetails || {}),
            (item) => -item[1]
          )?.map(([key, value], index) => (
            <React.Fragment key={index}>
              <Grid
                item
                xs={6}
                sx={{
                  color: index === 0 && "#00733E",
                  borderBottom: "1px solid #cacaca",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontWeight: index === 0 && "bold" }}
                >
                  {key}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  textAlign: "right",
                  color: index === 0 && "#00733E",
                  borderBottom: "1px solid #cacaca",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontWeight: index === 0 && "bold" }}
                >
                  {AmountUtils.format(value)}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={6}>
            <Typography
              variant="caption"
              fontWeight={700}
              sx={{ color: "#9D2123" }}
            >
              Total
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography
              variant="caption"
              fontWeight={700}
              sx={{ color: "#9D2123" }}
            >
              {AmountUtils.format(props?.product?.inventory)}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
