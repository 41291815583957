import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  errorFeedbackWrapper: {
    textAlign: "center",
  },
  errorPrimaryText: {
    fontSize: "12px",
    marginTop: "-4px",
  },
  errorSecondaryText: {
    fontSize: "12px",
    marginTop: "-4px",
  },
}));

const GiftsReceivedError = () => {
  const classes = useStyles();
  return (
    <Box className={classes.errorFeedbackWrapper}>
      <img src={`/icons/WarningSecondary.svg`} alt="" height={50} />
      <Typography
        className={classes.errorPrimaryText}
      >{`Something went wrong`}</Typography>
      <Typography
        className={classes.errorSecondaryText}
      >{`Please try again later`}</Typography>
    </Box>
  );
};

export default GiftsReceivedError;
