import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CurrentSchemeAchievements from "./CurrentSchemeAchievements";
import SchemeBenefits from "./SchemeBenefits";
import HorizontalScrollableSchemeProgress from "./HorizontalScrollableSchemeProgress";
import AnalyticsService from "../../Services/AnalyticsService";
import TopBar from "../Common/TopBar";
import ErrorBoundary from "../Common/ErrorBoundary";

const matrixOptions = {
  ORDER_VALUE: {
    value: "ORDER_VALUE",
    label: "Total sales value",
    suffix: "₹",
  },
  TOTAL_SKU_COUNT: {
    value: "TOTAL_SKU_COUNT",
    label: "Total quantity bought",
    suffix: "Qty",
  },
  TOTAL_WEIGHT_VALUE: {
    value: "TOTAL_WEIGHT_VALUE",
    label: "Total volume (Kg)",
    suffix: "Kg(s)",
  },
  TOTAL_LITRE_VALUE: {
    value: "TOTAL_LITRE_VALUE",
    label: "Total volume (Litre)",
    suffix: "Ltr(s)",
  },
};

const ENTITLEMENT_DESCRIPTION_MAP = {
  CREDIT_MEMO: {
    prefix: "Credit Note",
    suffix: "₹",
  },
  CREDIT_MEMO_PERCENT: {
    prefix: "Credit Note Percent",
    suffix: "%",
  },
  CREDIT_MEMO_PER_QUANTITY: {
    prefix: "Credit Note Per Quantity",
    suffix: "₹",
  },
  CREDIT_MEMO_PER_QUANTITY_PERCENT: {
    prefix: "Credit Note Per Quantity Percent",
    suffix: "%",
  },
  FREE_VOUCHER: {
    prefix: "",
    suffix: "",
  },
};

const SchemeDetails = (props) => {
  const [schemeBenefits, setSchemeBenefits] = useState([]);
  let navigate = useNavigate();

  const generateSlabDescription = (currentSlab) => {
    let slabDescription = ``;

    if (currentSlab?.constraints?.constraintList?.[0]?.type === `ORDER_VALUE`) {
      slabDescription =
        slabDescription +
        `${
          currentSlab?.constraints?.constraintList?.[0]?.params?.brandCategory
        } : Order value is more than or equal to ₹${new Intl.NumberFormat(
          "en-IN"
        ).format(
          currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
        )}`;
    } else if (
      currentSlab?.constraints?.constraintList?.[0]?.type === `PRODUCT_COHORT`
    ) {
      if (
        currentSlab?.constraints?.constraintList?.[0]?.matrix === `ORDER_VALUE`
      ) {
        slabDescription =
          slabDescription +
          `Product Cohort ${
            currentSlab?.constraints?.constraintList?.[0]?.params
              ?.productCohortName
          } with ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .label
          } more than ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .suffix
          }${new Intl.NumberFormat("en-IN").format(
            currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
          )}`;
      } else {
        slabDescription =
          slabDescription +
          `Product Cohort ${
            currentSlab?.constraints?.constraintList?.[0]?.params
              ?.productCohortName
          } with ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .label
          } more than ${
            currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
          } ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .suffix
          }`;
      }
    } else if (
      currentSlab?.constraints?.constraintList?.[0]?.type === `PRODUCT_GROUP`
    ) {
      if (
        currentSlab?.constraints?.constraintList?.[0]?.matrix === `ORDER_VALUE`
      ) {
        slabDescription =
          slabDescription +
          `Product Group ${
            currentSlab?.constraints?.constraintList?.[0]?.params?.productGroup
          } with ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .label
          } more than ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .suffix
          }${new Intl.NumberFormat("en-IN").format(
            currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
          )}`;
      } else {
        slabDescription =
          slabDescription +
          `Product Group ${
            currentSlab?.constraints?.constraintList?.[0]?.params?.productGroup
          } with ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .label
          } more than ${
            currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
          } ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .suffix
          }`;
      }
    } else if (
      currentSlab?.constraints?.constraintList?.[0]?.type === `PRODUCT`
    ) {
      if (
        currentSlab?.constraints?.constraintList?.[0]?.matrix === `ORDER_VALUE`
      ) {
        slabDescription =
          slabDescription +
          `${
            currentSlab?.constraints?.constraintList?.[0]?.params
              ?.itemQuanList?.[0]?.name
          } with ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .label
          } more than ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .suffix
          }${new Intl.NumberFormat("en-IN").format(
            currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
          )}`;
      } else {
        slabDescription =
          slabDescription +
          `${
            currentSlab?.constraints?.constraintList?.[0]?.params
              ?.itemQuanList?.[0]?.name
          } with ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .label
          } more than ${
            currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
          } ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .suffix
          }`;
      }
    } else if (
      currentSlab?.constraints?.constraintList?.[0]?.type === `CATEGORY`
    ) {
      if (
        currentSlab?.constraints?.constraintList?.[0]?.matrix === `ORDER_VALUE`
      ) {
        slabDescription =
          slabDescription +
          `${
            currentSlab?.constraints?.constraintList?.[0]?.params?.brandCategory
          } : Categories ${
            currentSlab?.constraints?.constraintList?.[0]?.params
              ?.itemQuanList?.[0]?.categoryName
          } with ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .label
          } more than ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .suffix
          }${new Intl.NumberFormat("en-IN").format(
            currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
          )}`;
      } else {
        slabDescription =
          slabDescription +
          `${
            currentSlab?.constraints?.constraintList?.[0]?.params?.brandCategory
          } : Categories ${
            currentSlab?.constraints?.constraintList?.[0]?.params
              ?.itemQuanList?.[0]?.categoryName
          } with ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .label
          } more than ${
            currentSlab?.constraints?.constraintList?.[0]?.params?.targetValue
          } ${
            matrixOptions[currentSlab?.constraints?.constraintList?.[0]?.matrix]
              .suffix
          }`;
      }
    }

    return slabDescription;
  };

  const generateSlabEntitlementDescription = (entitlements) => {
    let entitlementsList = [];
    let entitlementsString = ``;
    _.forEach(entitlements, (entitlement) => {
      if (entitlement?.type === `FREE_VOUCHER`) {
        _.forEach(entitlement?.params?.freeVouchers, (voucherDetails) => {
          entitlementsList.push(`${voucherDetails?.voucherCode}`);
        });
      } else {
        if (
          entitlement?.type === `CREDIT_MEMO` ||
          entitlement?.type === `CREDIT_MEMO_PER_QUANTITY`
        ) {
          entitlementsList.push(
            `${ENTITLEMENT_DESCRIPTION_MAP[entitlement?.type]?.prefix} - ${
              ENTITLEMENT_DESCRIPTION_MAP[entitlement?.type]?.suffix
            }${new Intl.NumberFormat("en-IN").format(
              entitlement?.params?.value
            )}`
          );
        } else {
          entitlementsList.push(
            `${ENTITLEMENT_DESCRIPTION_MAP[entitlement?.type]?.prefix} - ${
              entitlement?.params?.value
            } ${ENTITLEMENT_DESCRIPTION_MAP[entitlement?.type]?.suffix}`
          );
        }
      }
    });

    entitlementsString = _.join(entitlementsList, ", ");
    return entitlementsString;
  };

  useEffect(() => {
    let schemeBenefitsList = [];
    _.forEach(props?.turnoverScheme?.turnoverOfferRule, (currentSlab) => {
      const currentSlabEntitlementDescription =
        generateSlabEntitlementDescription(
          currentSlab?.entitlements?.entitlementList
        );

      const currentSlabDescription = generateSlabDescription(currentSlab);

      schemeBenefitsList.push({
        slabs: currentSlabDescription,
        benefits: currentSlabEntitlementDescription.split(","),
      });
    });
    setSchemeBenefits(schemeBenefitsList);
  }, [props?.turnoverScheme]);

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Scheme Details`}
          backNavigation={true}
          showCart={true}
          showHisaab={true}
          toolTip={``}
        />
      </ErrorBoundary>
      <Box>
        <Box
          sx={{
            textAlign: "center",
            background: "#DFFAFF",
            position: "relative",
          }}
        >
          <Typography
            color={`secondary`}
            sx={{
              fontSize: "16px",
              lineHeight: "22px",
              textTransform: "uppercase",
              padding: "12px 0px 0px 0px",
            }}
          >{`You are on`}</Typography>
          <Typography
            color={`secondary`}
            sx={{ fontSize: "28px", fontWeight: 700, lineHeight: "30px" }}
          >
            {props.sliderTooltipValue()}
          </Typography>
        </Box>

        <HorizontalScrollableSchemeProgress
          sliderMarks={props?.sliderMarks}
          turnoverScheme={props?.turnoverScheme}
          finalSlabTarget={props?.finalSlabTarget}
          slabConstraintUnit={props?.slabConstraintUnit}
          nextTargetEntitlement={props?.nextTargetEntitlement}
          nextSlabDetails={props?.nextSlabDetails}
          generateEntitlementDescription={props?.generateEntitlementDescription}
          schemeId={props?.schemeId}
          lastSlabAchieved={props?.lastSlabAchieved}
          deltaPromptQuantity={props?.deltaPromptQuantity}
          isFeaturedSchemes={props?.isFeaturedSchemes}
        />

        <Container
          maxWidth={`xl`}
          sx={{ background: "#F3F3F3", height: "100%", paddingTop: "10px" }}
        >
          <Typography
            sx={{
              color: "#1C1C1C",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {props?.turnoverScheme?.name}
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              fontSize: "12px",
              fontWeight: 400,
              marginTop: "9px",
            }}
          >
            {props?.turnoverScheme?.descriptions}
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              fontSize: "16px",
              fontWeight: 700,
              margin: "12px 0px 10px 0px",
            }}
          >
            Scheme Details
          </Typography>
          {_.map(props?.schemeInformation, (schemeInfo, index) => (
            <Grid key={index} container>
              <Grid item xs={4.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#333333",
                    marginBottom: "15px",
                  }}
                >
                  {schemeInfo.label}
                </Typography>
              </Grid>

              {schemeInfo.label === `Applicable Product` ? (
                <>
                  {props?.schemeConstraintType === `PRODUCT_COHORT` ? (
                    <>
                      {props?.loadingApplicableProductsSkus === "started" && (
                        <CircularProgress size={16} />
                      )}
                      {props?.loadingApplicableProductsSkus === "done" && (
                        <>
                          {props?.shopNowUrl ? (
                            <Grid item xs={7.5}>
                              <Typography
                                color={`primary`}
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  textDecoration: "underline #9D2123",
                                  marginBottom: "15px",
                                }}
                                onClick={() => {
                                  AnalyticsService.pushEvent(
                                    `Applicable Products Link Clicked`,
                                    {
                                      schemeName: props?.turnoverScheme?.name,
                                      schemeId: props?.schemeId,
                                      page: "Scheme Details Page",
                                      schemeAchievement:
                                        props?.turnoverScheme?.netValue >= 0
                                          ? props?.slabConstraintUnit === `₹`
                                            ? `${
                                                props.slabConstraintUnit
                                              } ${new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                props?.turnoverScheme?.netValue
                                              )}`
                                            : `${props?.turnoverScheme?.netValue} ${props?.slabConstraintUnit}`
                                          : ``,
                                    }
                                  );
                                  navigate(props?.shopNowUrl);
                                }}
                              >
                                {schemeInfo.description}
                              </Typography>
                            </Grid>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                marginBottom: "15px",
                                color: "#333333",
                              }}
                            >
                              {`N/A`}
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  ) : props?.schemeConstraintType === `PRODUCT_GROUP` ||
                    (props?.schemeConstraintType === `CATEGORY` &&
                      !props?.shopNowUrl) ? (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        marginBottom: "15px",
                        color: "#333333",
                      }}
                    >
                      {`N/A`}
                    </Typography>
                  ) : (
                    <Grid item xs={7.5}>
                      <Typography
                        color={`primary`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                          textDecoration: "underline #9D2123",
                          marginBottom: "15px",
                        }}
                        onClick={() => {
                          AnalyticsService.pushEvent(
                            `Applicable Products Link Clicked`,
                            {
                              schemeName: props?.turnoverScheme?.name,
                              schemeId: props?.schemeId,
                            }
                          );
                          navigate(props?.shopNowUrl);
                        }}
                      >
                        {schemeInfo.description}
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={7.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#333333",
                      marginBottom: "15px",
                    }}
                  >
                    {schemeInfo.description}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ))}

          <Typography
            sx={{
              color: "#000000",
              fontSize: "16px",
              fontWeight: 700,
              margin: "6px 0px 20px 0px",
            }}
          >
            Scheme Benefits
          </Typography>

          <SchemeBenefits schemeBenefits={schemeBenefits} />

          <Box sx={{ pb: "88px" }}>
            <CurrentSchemeAchievements
              turnoverScheme={props?.turnoverScheme}
              slabConstraintUnit={props?.slabConstraintUnit}
              showTitle={true}
              showNetSchemeCN={true}
              showNetLandingCN={true}
              isFeaturedSchemes={props?.isFeaturedSchemes}
            />
          </Box>
        </Container>
      </Box>

      {props?.shopNowUrl ? (
        <Button
          size={`large`}
          variant={`contained`}
          color={`secondary`}
          fullWidth={true}
          type={`submit`}
          sx={{
            position: "sticky",
            bottom: 0,
            borderRadius: "0px",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: 700,
            padding: "16px",
            lineHeight: "19px",
          }}
          onClick={() => {
            AnalyticsService.pushEvent(`Shop Now Clicked`, {
              schemeName: props?.turnoverScheme?.name,
              schemeId: props?.schemeId,
              page: "Scheme Details Page",
              schemeAchievement:
                props?.turnoverScheme?.netValue >= 0
                  ? props?.slabConstraintUnit === `₹`
                    ? `${props.slabConstraintUnit} ${new Intl.NumberFormat(
                        "en-IN"
                      ).format(props?.turnoverScheme?.netValue)}`
                    : `${props?.turnoverScheme?.netValue} ${props?.slabConstraintUnit}`
                  : ``,
            });
            navigate(props?.shopNowUrl);
          }}
        >
          Shop Now
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

export default SchemeDetails;
