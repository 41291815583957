import config from "../config";
import OrdersMock from "./mocks/OrdersMock";
import HTTPService from "./HTTPService";

const useMocks = false;

export default class TicketService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
      client:
        localStorage.getItem(`role`) === "fieldAgent"
          ? "FIELD_AGENT"
          : "SAATHI",
    };
  }

  static getAllReturnReasons() {
    if (useMocks === false) {
      const url = `${config.backendUrl}ticketservice/ticketreasons/?level=return_order`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static getComplaintHistory(stateLanguage) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }ticketservice/tickethistory/?farmerId=${localStorage.getItem(
        "farmerId"
      )}&filterOutTicketTypes=RETURN_ORDER&language=${stateLanguage}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static getComplaintReasonsAndIssues(stateLanguage) {
    if (useMocks === false) {
      const url = `${config.backendUrl}ticketservice/ticketreasons/?isB2BCustomer=true&level=profile&language=${stateLanguage}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }

  static createComplaint({ ticketDetails }) {
    if (useMocks === false) {
      const url = `${config.backendUrl}ticketservice/v2/ticket/`;
      const data = ticketDetails;
      return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: OrdersMock });
      });
    }
  }
}
