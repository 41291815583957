import * as React from "react";
// import { JsonFormatter } from "react-json-formatter";
// import JsonFormatter from "react-json-formatter";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DebugDialog(props) {
  // const JsonStyle = {
  //   propertyStyle: { color: "red" },
  //   stringStyle: { color: "green" },
  //   numberStyle: { color: "darkorange" },
  // };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleCloseDebug}
        TransitionComponent={Transition}
      >
        <DialogContent>
          {/*<JsonFormatter*/}
          {/*  json={props.debugMessage}*/}
          {/*  tabWith="3"*/}
          {/*  JsonStyle={JsonStyle}*/}
          {/*/>*/}
          {props.debugMessage}
        </DialogContent>
        <Button onClick={props.handleCloseDebug}>Close</Button>
      </Dialog>
    </div>
  );
}
