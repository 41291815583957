import React, { useCallback, useEffect, useState } from "react";
import StoreFrontService from "../../Services/StoreFrontService";
import TopBar from "../Common/TopBar";
import BlockUi from "react-block-ui";
import { Container, Grid, Typography } from "@mui/material";
import EmptyError from "../EmptyError/EmptyError";
import AddProductsToCatalogCard from "./AddProductsToCatalogCard";
import UpdateProductInventoryDrawer from "./UpdateProductInventoryDrawer";
import CatalogItemPublishedDialog from "./CatalogItemPublishedDialog";
import AnalyticsService from "../../Services/AnalyticsService";
import CatalogItemUnpublishConfirmationDialog from "./CatalogItemUnpublishConfirmationDialog";
import CatalogItemUnpublishedDialog from "./CatalogItemUnpublishedDialog";
import CatalogError from "./CatalogError";
import CatalogItemInventoryUpdatedDialog from "./CatalogItemInventoryUpdatedDialog";
import _ from "lodash";
import UiSearch from "../Common/UiSearch";
import ErrorBoundary from "../Common/ErrorBoundary";

export default function AddProductsToCatalogPage() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState(false);
  const [addToCatalogProducts, setAddToCatalogProducts] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueForQuery, setSearchValueForQuery] = useState("");
  const [loadingAddToCatalogProducts, setLoadingAddToCatalogProducts] =
    useState("not-started");
  const [skuCodeToUpdate, setSkuCodeToUpdate] = useState(null);
  const [loadingUnpublish, setLoadingUnpublish] = useState("not-started");
  useState("not-started");
  const [showCatalogErrorDialog, setShowCatalogErrorDialog] = useState(false);
  const [showCatalogItemPublishedDialog, setShowCatalogItemPublishedDialog] =
    useState(false);
  const [
    showCatalogItemInventoryUpdatedDialog,
    setShowCatalogItemInventoryUpdatedDialog,
  ] = useState(false);
  const [
    showCatalogItemUnpublishConfirmationDialog,
    setShowCatalogItemUnpublishConfirmationDialog,
  ] = useState(false);
  const [showCatalogItemUnpublishedDialog, setShowCatalogItemUnblishedDialog] =
    useState(false);

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`);
    const addToCatalogCB = getAddToCatalogProducts();
    return () => addToCatalogCB();
  }, []);

  const getAddToCatalogProducts = () => {
    setLoadingAddToCatalogProducts("started");
    const { axiosRequest, abortController } =
      StoreFrontService.getCatalogNPLProducts();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          const productsList =
            response.data.responseData.productGists.length > 1000
              ? response.data.responseData.productGists.slice(0, 1000)
              : response.data.responseData.productGists;
          setAddToCatalogProducts(productsList);
        }
        setLoadingAddToCatalogProducts("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoadingAddToCatalogProducts("done");
          console.error(`Error Getting Add To Catalog Products: `, error);
        }
      });
    return () => abortController.abort();
  };

  const handleToggleCatalogItem = (skuCode, isEnabled) => {
    if (isEnabled) {
      const { axiosRequest } = StoreFrontService.toggleStoreFrontCatalogItem(
        skuCode,
        isEnabled,
        productToUpdate?.sellingPrice,
        productToUpdate?.quantity
      );
      axiosRequest
        .then((response) => {
          if (response?.data?.status) {
            getAddToCatalogProducts();
            setProductToUpdate((prevProductToUpdate) => ({
              ...prevProductToUpdate,
              isEnabled,
            }));
            setShowCatalogItemPublishedDialog(true);
          } else {
            setShowCatalogErrorDialog(true);
          }
        })
        .catch((error) => {
          setShowCatalogErrorDialog(true);
          console.error(`Error Enabling Product: `, error);
        });
      AnalyticsService.pushEvent(
        "Store Front Catalog Item Publish Button Clicked",
        { skuCode: skuCode }
      );
    } else {
      setSkuCodeToUpdate(skuCode);
      setShowCatalogItemUnpublishConfirmationDialog(true);
      AnalyticsService.pushEvent(
        "Store Front Catalog Item Unpublish Button Clicked",
        { skuCode: productToUpdate?.skuCode }
      );
    }
  };

  const handleUpdateProductInventory = (price, quantity) => {
    const { axiosRequest } = StoreFrontService.toggleStoreFrontCatalogItem(
      productToUpdate?.skuCode,
      true,
      price,
      quantity
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.status) {
          getAddToCatalogProducts();
          setShowCatalogItemInventoryUpdatedDialog(true);
        } else {
          setShowCatalogErrorDialog(true);
        }
        setOpenDrawer(false);
      })
      .catch((error) => {
        setOpenDrawer(false);
        setShowCatalogErrorDialog(true);
        console.error(`Error Updating Inventory: `, error);
      });
    AnalyticsService.pushEvent(
      "Store Front Catalog Item Publish Button Clicked",
      { skuCode: productToUpdate?.skuCode }
    );
  };

  const handleUnpublishCatalogItem = () => {
    setLoadingUnpublish("started");
    const { axiosRequest } = StoreFrontService.toggleStoreFrontCatalogItem(
      productToUpdate?.skuCode,
      false,
      productToUpdate?.sellingPrice,
      productToUpdate?.quantity
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.status) {
          getAddToCatalogProducts();
          setShowCatalogItemUnblishedDialog(true);
        } else {
          setShowCatalogErrorDialog(true);
        }
        setOpenDrawer(false);
        setLoadingUnpublish("done");
        setShowCatalogItemUnpublishConfirmationDialog(false);
      })
      .catch((error) => {
        setOpenDrawer(false);
        setLoadingUnpublish("done");
        setShowCatalogErrorDialog(true);
        setShowCatalogItemUnpublishConfirmationDialog(false);
        console.error(`Error Updating Inventory: `, error);
      });
  };

  const setSearchValueDebounce = (queriedValue) => {
    setSearchValue(queriedValue);
    setSearchValueForQueryDebounce(queriedValue);
  };

  const setSearchValueForQueryDebounce = useCallback(
    _.debounce((queriedValue) => {
      setSearchValueForQuery(queriedValue);
    }, 0)
  );

  return (
    <div>
      <TopBar title={`Add Products To Catalog`} backNavigation={true} />
      <ErrorBoundary message={``}>
        <UiSearch
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          shouldBlockSendSearchQuerySentEvent={true}
          shouldKeepDefaultBackgroundColor={true}
        />
      </ErrorBoundary>
      <Container maxWidth={`xl`}>
        <BlockUi
          tag="div"
          blocking={loadingAddToCatalogProducts === "started"}
          message={`Please wait`}
          keepInView
          style={{ minHeight: `20vh`, marginTop: "20px" }}
        >
          <Typography variant="subtitle2" fontWeight={700} sx={{ mb: "10px" }}>
            {searchValue.length
              ? `Add Products in Inventory`
              : `Add Products in Inventory (${
                  addToCatalogProducts?.length || 0
                })`}
          </Typography>
          {addToCatalogProducts?.length ? (
            <>
              <Grid container gap="10px">
                {addToCatalogProducts?.map((product) => {
                  if (
                    !product?.productName
                      ?.toLowerCase()
                      ?.includes(searchValue.toLowerCase())
                  ) {
                    return null;
                  }
                  return (
                    <Grid item xs={12} key={product.skuCode}>
                      <AddProductsToCatalogCard
                        product={product}
                        setProductToUpdate={setProductToUpdate}
                        setOpenDrawer={setOpenDrawer}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            loadingAddToCatalogProducts === "done" && (
              <EmptyError
                extraStyle={{
                  minHeight: `40vh`,
                }}
                hideBack={true}
                image="EmptyPage.svg"
                subText="No products to add."
              />
            )
          )}
        </BlockUi>
      </Container>

      <>
        <UpdateProductInventoryDrawer
          product={productToUpdate}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleToggleCatalogItem={handleToggleCatalogItem}
          handleUpdateProductInventory={handleUpdateProductInventory}
        />

        <CatalogItemPublishedDialog
          open={showCatalogItemPublishedDialog}
          handleClickDone={() => {
            setShowCatalogItemPublishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Published Dialog Done Clicked",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemPublishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Published Dialog Closed",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
        />
        <CatalogItemInventoryUpdatedDialog
          open={showCatalogItemInventoryUpdatedDialog}
          handleClickDone={() => {
            setShowCatalogItemInventoryUpdatedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Inventory Updated Dialog Done Clicked",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemInventoryUpdatedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Inventory Updated Dialog Closed",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
        />
        <CatalogItemUnpublishConfirmationDialog
          skuCode={skuCodeToUpdate}
          open={showCatalogItemUnpublishConfirmationDialog}
          loadingUnpublish={loadingUnpublish}
          handleClickReject={() => {
            setShowCatalogItemUnpublishConfirmationDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmation Rejected",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
          handleClickConfirm={(skuCode) => {
            handleUnpublishCatalogItem(skuCode);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmed",
              {
                skuCode: skuCode,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemUnpublishConfirmationDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmation Dialog Closed",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
        />
        <CatalogItemUnpublishedDialog
          open={showCatalogItemUnpublishedDialog}
          handleClickDone={() => {
            setShowCatalogItemUnblishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublished Dialog Done Clicked",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemUnblishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublished Dialog Closed",
              {
                skuCode: skuCodeToUpdate,
              }
            );
          }}
        />
        <CatalogError
          open={showCatalogErrorDialog}
          handleClickDone={() => {
            setShowCatalogErrorDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Error Dialog Done Clicked"
            );
          }}
          onClose={() => {
            setShowCatalogErrorDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Error Dialog Closed"
            );
          }}
        />
      </>
    </div>
  );
}
