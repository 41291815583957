import * as React from "react";
import { Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

import AnalyticsService from "../../Services/AnalyticsService";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    height: "97px",
  },
}));

export default function HorizontalScrollableBanner(props) {
  const classes = useStyles();

  const handleClickHorizontalBanner = (index) => {
    MiscUtils.saveScrollYPosition();
    const horizontalBannerClickedEventData = {
      bannerType: props?.banners[index]?.data?.type || "",
      position: index || "",
      skuList:
        props?.banners[index]?.data?.data?.sku?.toString() ||
        props?.banners[index]?.data?.data?.skus?.join(",") ||
        "",
      imageUrl: props?.banners[index]?.url || "",
      page: props?.page,
    };

    AnalyticsService.pushEvent(
      `Banner Clicked`,
      horizontalBannerClickedEventData
    );
  };

  return (
    <div
      style={{
        // marginLeft: "16px",
        marginTop: "10px",
      }}
    >
      {props.banners && props.banners.length > 0 && (
        <Box
          sx={{
            display: "-webkit-box",
            gap: 1,
            width: "100%",
            overflow: "auto",
            paddingTop: props.showBackgroundImage ? "95px" : "",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          {props.banners.map((scrollableBanner, index) => (
            <Box
              key={index}
              onClick={() => handleClickHorizontalBanner(index)}
              variant="outlined"
              sx={{
                gap: 2,
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                maxWidth: "132px",
              }}
            >
              {scrollableBanner?.data?.type === `PRODUCT_DETAILS` && (
                <>
                  {scrollableBanner?.data?.data?.sku ? (
                    <Link
                      to={{
                        pathname: `/products/${scrollableBanner.data.data.sku}`,
                        state: { from: window.location.pathname },
                      }}
                    >
                      <div
                        style={{
                          minWidth: 60,
                          borderRadius: "sm",
                          marginBottom: "-5px",
                        }}
                      >
                        <img
                          src={scrollableBanner.url}
                          className={classes.img}
                          srcSet={scrollableBanner.url}
                          alt={`banner`}
                          style={props?.blur ? { filter: props.blur } : {}}
                        />
                      </div>
                    </Link>
                  ) : (
                    <img
                      src={scrollableBanner.url}
                      className={classes.img}
                      srcSet={scrollableBanner.url}
                      alt={`banner`}
                      style={props?.blur ? { filter: props.blur } : {}}
                    />
                  )}
                </>
              )}
              {scrollableBanner?.data?.type === `PRODUCT_LIST` && (
                <>
                  {scrollableBanner?.data?.data?.skus ? (
                    <Link
                      to={`/products-offer/${scrollableBanner.data.data.skus}`}
                    >
                      <img
                        className={classes.img}
                        src={scrollableBanner.url}
                        alt={`banner`}
                        style={props?.blur ? { filter: props.blur } : {}}
                      />
                    </Link>
                  ) : (
                    <img
                      className={classes.img}
                      src={scrollableBanner.url}
                      alt={`banner`}
                      style={props?.blur ? { filter: props.blur } : {}}
                    />
                  )}
                </>
              )}

              {scrollableBanner?.data?.type === "EXTERNAL_WEB_PAGE" && (
                <>
                  {scrollableBanner?.data?.data?.url && (
                    <a href={scrollableBanner.data.data.url}>
                      <img
                        className={classes.img}
                        src={scrollableBanner.url}
                        alt={`banner`}
                        style={props?.blur ? { filter: props.blur } : {}}
                      />
                    </a>
                  )}
                </>
              )}

              {scrollableBanner?.data?.type === `NO_CLICK` && (
                <>
                  <img
                    className={classes.img}
                    src={scrollableBanner.url}
                    alt={`banner`}
                    style={props?.blur ? { filter: props.blur } : {}}
                  />
                </>
              )}
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
}
