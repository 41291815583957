import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/analytics";
import "firebase/auth";
import config from "./config";

const firebaseConfig = config.firebaseConfig;

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;
