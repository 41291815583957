import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  SwipeableDrawer,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles({
  updateDetailsSection: {
    position: "relative",
    padding: "0 16px",
    background: "#fff",
  },
  buttonWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "10px",
    padding: "16px",
    marginBottom: "16px",
    "& Button": {
      height: "40px",
      fontWeight: 700,
      borderRadius: "16px",
      textTransform: "none",
    },
  },
  iconButton: {
    height: "24px",
    width: "24px",
    margin: "5px",
    border: "1px solid #9D2123",
  },
  addIcon: {
    height: "20px",
    color: "#9D2123",
  },
  removeIcon: {
    height: "20px",
    color: "#9D2123",
  },
  priceInput: {
    width: "100px",
    margin: "0 5px",
    "& fieldset": { border: "1px solid #CACACA" },
  },
});

export default function UpdateProductInventoryDrawer({
  product,
  openDrawer,
  setOpenDrawer,
  handleToggleCatalogItem,
  handleUpdateProductInventory,
}) {
  const classes = useStyles();
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setPrice(product?.sellingPrice || 0);
    setQuantity(product?.quantity || 0);
  }, [openDrawer]);

  const handleAdd = () => {
    setQuantity((prevQuantity) => Number(prevQuantity) + 1);
  };

  const handleRemove = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => Number(prevQuantity) - 1);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    AnalyticsService.pushEvent(`Button Clicked`, {
      type: "Update Button From Update Product Inventory Drawer",
      isEnabled: product?.isEnabled || false,
      isPLProduct: product?.isPLProduct || false,
      productName: product?.productName,
      quantity: quantity,
      sellingPrice: product?.isPLProduct ? product?.sellingPrice : price,
      skuCode: product?.skuCode,
    });
    handleUpdateProductInventory(parseInt(price), parseInt(quantity));
  };

  return (
    <ErrorBoundary message={``}>
      <SwipeableDrawer
        anchor={`bottom`}
        onOpen={() => {}}
        open={openDrawer}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        onClose={() => setOpenDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ padding: 2, fontSize: "18px", fontWeight: 700 }}
        >
          Update Details
        </Typography>

        <Grid container sx={{ mb: 1, p: "8px 16px", background: "#D9D9D92A" }}>
          <Grid item xs={2}>
            <img
              src={
                product?.productImages?.[0]?.name
                  ? product.productImages?.[0]?.name
                  : `/icons/dummy.jpeg`
              }
              style={{ border: "1px solid #0000002A", borderRadius: "16px" }}
              alt={product?.productName}
              height={50}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption" fontWeight={700}>
              {product?.productName}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "right" }}>
            <FormControlLabel
              control={
                <Switch
                  color={"secondary"}
                  size={"small"}
                  checked={product?.isEnabled === true}
                  onChange={() => {
                    handleToggleCatalogItem(
                      product.skuCode,
                      !product.isEnabled
                    );
                  }}
                />
              }
            />
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit}>
          <div className={classes.updateDetailsSection}>
            <Grid
              container
              rowGap="10px"
              sx={{ marginTop: "10px", "& .MuiGrid-item": { height: "35px" } }}
            >
              <Grid item xs={6}>
                <Typography variant="caption" fontWeight={700}>
                  Quantity Available -
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  onClick={handleRemove}
                  className={classes.iconButton}
                >
                  <Remove className={classes.removeIcon} />
                </IconButton>
                <TextField
                  name={`quantity`}
                  type={`number`}
                  size={`small`}
                  value={quantity}
                  onChange={(event) => setQuantity(event.target.value)}
                  sx={{ height: 25 }}
                  inputProps={{
                    min: 0,
                    max: 1000000,
                    type: "number",
                    minLength: "1",
                    maxLength: "4",
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    style: {
                      width: 40,
                      height: 17,
                      fontSize: 15,
                      padding: "8px",
                      fontWeight: "500",
                      textAlign: "center",
                      borderRadius: "4px",
                      color: "#9D2123",
                      border: "1px solid #9D2123",
                    },
                  }}
                />
                <IconButton onClick={handleAdd} className={classes.iconButton}>
                  <Add className={classes.addIcon} />
                </IconButton>
              </Grid>

              {!product?.isPLProduct && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="caption" fontWeight={700}>
                      Your Price -
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name={`price`}
                      type={`number`}
                      size={`small`}
                      value={price}
                      className={classes.priceInput}
                      onChange={(event) => setPrice(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span style={{ fontWeight: 700, color: "#000" }}>
                              ₹
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: 0,
                        max: 1000000,
                        type: "number",
                        minLength: "1",
                        maxLength: "4",
                        pattern: "[0-9]*",
                        inputMode: "numeric",
                        style: {
                          height: 17,
                          fontSize: 15,
                          fontWeight: "500",
                          textAlign: "center",
                          borderRadius: "4px",
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>

          <div className={classes.buttonWrapper}>
            <Button
              variant="outlined"
              onClick={() => {
                AnalyticsService.pushEvent(`Button Clicked`, {
                  type: "Cancel Button From Update Product Inventory Drawer",
                  isEnabled: product?.isEnabled,
                  isPLProduct: product?.isPLProduct,
                  productName: product?.productName,
                  quantity: product?.quantity,
                  sellingPrice: product?.sellingPrice,
                  skuCode: product?.skuCode,
                });
                setOpenDrawer(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!product?.isEnabled}
            >
              Update
            </Button>
          </div>
        </form>
      </SwipeableDrawer>
    </ErrorBoundary>
  );
}
