import React from "react";
import Clamp from "react-multiline-clamp";

import { makeStyles } from "@mui/styles";
import { Button, Grid, Typography } from "@mui/material";
import { Call, RadioButtonUnchecked } from "@mui/icons-material";

import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles({
  partnerCard: {
    position: "relative",
    padding: "12px 16px",
    marginTop: "8px",
    fontSize: "14px",
    borderTop: "1px solid #eee",
    borderRadius: "16px",
    background: "#fff",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  partnerAddress: {
    display: "flex",
    alignItems: "flex-start",
    gap: "5px",
    img: { position: "relative", top: 2, opacity: 0.7 },
  },
  callButton: {
    position: "absolute",
    right: "16px",
    bottom: "12px",
    height: "30px",
    fontSize: "10px",
    borderRadius: "10px",
  },
});

export default function InitiateTransferPartnerCard({
  partner,
  isSelected,
  handleSelectPartner,
  handleRemovePartner,
}) {
  const classes = useStyles();
  return (
    <Grid
      container
      rowGap="5px"
      key={partner.mobile}
      justifyContent="space-between"
      className={classes.partnerCard}
      onClick={() => {
        !isSelected && handleSelectPartner(partner);
      }}
    >
      <Grid item xs={10}>
        <b>{partner.name}</b> ({partner.farmerId})
      </Grid>
      <Grid item xs={2} sx={{ textAlign: "end" }}>
        {isSelected ? (
          <Typography
            variant="string"
            sx={{ color: "#9D2123" }}
            onClick={() => handleRemovePartner(partner)}
          >
            Remove
          </Typography>
        ) : (
          <RadioButtonUnchecked sx={{ height: "20px", color: "#CACACA" }} />
        )}
      </Grid>
      <Grid
        item
        xs={9}
        className={classes.partnerAddress}
        sx={{ img: { position: "relative", top: 2, opacity: 0.7 } }}
      >
        <img src="/icons/location.svg" alt="location" width="12px" />
        <Clamp lines={2} maxLines={2}>
          <Typography
            fontSize={12}
            sx={{ color: "#666", textTransform: "capitalize" }}
          >
            {partner.addressString}
          </Typography>
        </Clamp>
      </Grid>
      <Grid item xs={9} sx={{ fontSize: "12px", color: "#666", pl: 2 }}>
        Ph. No - {partner.mobile}
      </Grid>

      <Button
        variant="outlined"
        color="secondary"
        startIcon={<Call sx={{ width: "18px", height: "18px" }} />}
        className={classes.callButton}
        href={`tel:${partner.mobile}`}
        onClick={() =>
          AnalyticsService.pushEvent(`Call Button Clicked`, {
            page: "Select Store",
            farmerId: partner?.farmerId,
          })
        }
      >
        Call
      </Button>
    </Grid>
  );
}
