import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { Typography, CircularProgress } from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({}));

const ProductListPrice = (props) => {
  const classes = useStyles();

  const getApplicablePrice = () => {
    let applicablePrice = 0;
    if (props?.product?.offers?.offerId) {
      if (
        props?.product?.cashDiscountBenefits?.[
          props?.product?.offers?.offerId
        ]?.[0]?.effectiveOfferPrice
      ) {
        applicablePrice =
          props?.product?.cashDiscountBenefits?.[
            props?.product?.offers?.offerId
          ]?.[0]?.effectiveOfferPrice;
      } else {
        //has offer id, but does not have CD for that offer ID
        if (
          props?.product?.cashDiscountBenefits?.["no-offer"]?.[0]
            ?.effectiveOfferPrice
        ) {
          applicablePrice =
            props?.product?.cashDiscountBenefits?.["no-offer"]?.[0]
              ?.effectiveOfferPrice;
        } else if (props?.product?.turnoverData?.turnOverSchemeBenefits) {
          if (props?.product?.offers?.offerId) {
            applicablePrice =
              props?.product?.turnoverData?.turnOverSchemeBenefits?.[
                props?.product?.offers?.offerId
              ]?.perUnitNetEffectivePrice;
          } else {
            applicablePrice =
              props?.product?.turnoverData?.turnOverSchemeBenefits?.["no-offer"]
                ?.perUnitNetEffectivePrice;
          }
        } else if (props?.product?.offers?.netEffectivePrice) {
          //has offer ID, but does not have CD benefits for "no-offer" either, and has NEP
          applicablePrice = props?.product?.offers?.netEffectivePrice;
        } else if (props?.product?.sellingPrice) {
          applicablePrice = props?.product?.sellingPrice;
        } else {
        }
      }
    } else if (
      props?.product?.cashDiscountBenefits?.["no-offer"]?.[0]
        ?.effectiveOfferPrice
    ) {
      applicablePrice =
        props?.product?.cashDiscountBenefits?.["no-offer"]?.[0]
          ?.effectiveOfferPrice;
    } else if (props?.product?.turnoverData?.turnOverSchemeBenefits) {
      if (props?.product?.offers?.offerId) {
        applicablePrice =
          props?.product?.turnoverData?.turnOverSchemeBenefits[
            props?.product?.offers?.offerId
          ]?.perUnitNetEffectivePrice;
      } else {
        applicablePrice =
          props?.product?.turnoverData?.turnOverSchemeBenefits["no-offer"]
            ?.perUnitNetEffectivePrice;
      }
    } else if (props?.product?.sellingPrice) {
      applicablePrice = props.product.sellingPrice;
    } else {
    }
    return new Intl.NumberFormat("en-IN").format(Math.round(applicablePrice));
  };
  return (
    <ErrorBoundary message={``}>
      <>
        <Typography
          variant={`body1`}
          sx={{
            fontSize: "14px",
            color: "#333333",
            display: "inline-flex",
            lineHeight: "22px",
            fontWeight: "700",
            marginLeft: "4px",
            filter: PartnerService.applySmudgedPriceFilter(props?.product),
          }}
        >
          ₹{getApplicablePrice()}
        </Typography>

        <Typography
          sx={{
            color: "#666666",
            textDecoration: "line-through",
            marginLeft: "4px",
            display: "inline-flex",
            fontSize: "12px",
            lineHeight: "16px",
            filter: PartnerService.applySmudgedPriceFilter(props?.product),
          }}
        >
          ₹
          {props?.product?.sellingPrice
            ? new Intl.NumberFormat("en-IN").format(
                props?.product?.sellingPrice
              )
            : 0}
        </Typography>
      </>
    </ErrorBoundary>
  );
};

export default ProductListPrice;
