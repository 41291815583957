import React from "react";
import { debounce } from "lodash";
import { makeStyles } from "@mui/styles";

import { Box, Container, Grid, Skeleton } from "@mui/material";

import useStories from "../../Hooks/useStories";
import AndroidService from "../../Services/AndroidService";
import SectionTitle from "../Common/SectionTitle";
import StoriesPreviewContainer from "../Mahaul/StoriesPreviewContainer";
import CommonConstantValues from "../../Services/CommonConstantValues";

const useStyles = makeStyles((theme) => ({
  loaderHeader: {
    marginLeft: "16px",
    marginTop: "8px",
    marginBottom: "10px",
  },
  loaderContent: {
    marginBottom: "20px",
  },
  loaderWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
  storiesPreviewContainer: {
    overflow: "scroll",
    marginBottom: "20px",
    marginTop: "10px",
    maxWidth: "99vw",

    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    WebkitOverflowScrolling: "touch",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

function StoriesPreview() {
  const classes = useStyles();
  const { stories, loading, error } = useStories();
  const storiesPreviewContainerRef = React.useRef(null);

  React.useEffect(() => {
    // Check if the storiesPreviewContainerRef is available
    if (storiesPreviewContainerRef.current) {
      // Function to unset the pull-to-refresh after a short time
      const unsetForShortTime = (() => {
        let isUnset = false;

        // Debounced function to set the pull-to-refresh
        const unsetDebounced = debounce(() => {
          AndroidService.setPullToRefresh();
          isUnset = false;
        }, 1000);

        // Cleanup function to unset the pull-to-refresh
        return () => {
          // If already unset, return
          if (isUnset) {
            return;
          }

          // Unset the pull-to-refresh
          AndroidService.unsetPullToRefresh();
          isUnset = true;

          // Call the debounced function to set the pull-to-refresh after a delay
          unsetDebounced();
        };
      })();

      // Add scroll event listener to the element
      const element = storiesPreviewContainerRef.current;
      element.addEventListener("scroll", unsetForShortTime);

      // Cleanup function to remove the scroll event listener
      return () => {
        element.removeEventListener("scroll");
      };
    }
  }, [loading, storiesPreviewContainerRef]);

  return (
    <Box
      sx={{
        overflowY: "hidden",
        marginBottom: "10px",
        background: (theme) => theme.palette.background.default,
        overflowX: "hidden",
        boxShadow: "0px 12px 24px 0px #1C1C1C1A",
        minHeight: "220px",
      }}
    >
      {loading === CommonConstantValues.DONE &&
        (!error || !error?.data) &&
        stories &&
        stories.length > 0 && (
          <>
            <Container>
              <SectionTitle title={`Surkhiyan`} />
            </Container>
            <Box
              className={classes.storiesPreviewContainer}
              ref={storiesPreviewContainerRef}
            >
              <StoriesPreviewContainer classes={classes} stories={stories} />
            </Box>
          </>
        )}

      {loading === CommonConstantValues.STARTED && (
        <>
          <Skeleton
            variant={`text`}
            width={150}
            className={classes.loaderHeader}
          />
          <Box className={classes.loaderWrapper}>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.loaderContent}
              wrap={"nowrap"}
              spacing={2}
            >
              <Grid item>
                <Skeleton variant={`rectangular`} height={115} width={86} />
                <Skeleton variant={`text`} width={86} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={115} width={86} />
                <Skeleton variant={`text`} width={86} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={115} width={86} />
                <Skeleton variant={`text`} width={86} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={115} width={86} />
                <Skeleton variant={`text`} width={86} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={115} width={86} />
                <Skeleton variant={`text`} width={86} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default StoriesPreview;
