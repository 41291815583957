import { useEffect, useState } from "react";
import _ from "lodash";
import {
  clearLocalStorageForStoriesExpired,
  convertToStories,
  getWeatherStory,
  markStoryViewedByIndex,
  reorderStories,
} from "../Components/Mahaul/StoryUtils";
import CommonConstantValues from "../Services/CommonConstantValues";
import WeatherService from "../Services/WeatherService";
import StoryService from "../Services/StoryService";

function useStories() {
  const [loading, setLoading] = useState(CommonConstantValues.NOT_STARTED);
  const [stories, setStories] = useState([]);
  const [storiesDurations, setStoriesDuration] = useState({});
  const [error, setError] = useState(null);

  const markStoryViewed = (storyIndex) => {
    markStoryViewedByIndex(stories, storyIndex);
  };

  const setStoryDuration = (storyCode, duration) => {
    setStoriesDuration({
      ...storiesDurations,
      [`${storyCode}`]: duration,
    });
  };

  const markStoryAsLikedOrUnliked = (updatedStory, totalLikedCount, index) => {
    setStories((prevState) => {
      return _.map(prevState, (story) => {
        return story[0].storyParentCode === updatedStory.storyParentCode
          ? _.map(story, (childStory, childIndex) => {
              return childIndex === index
                ? {
                    ...childStory,
                    isLiked: !updatedStory.isLiked,
                    totalLikeCount: totalLikedCount,
                  }
                : childStory;
            })
          : story;
      });
    });
  };

  const updateStoryWhenQuizIsAnswered = (
    storyToBeUpdated,
    quizPayload,
    index
  ) => {
    setStories((prevState) => {
      return _.map(prevState, (story) => {
        return story[0].storyParentCode === storyToBeUpdated.storyParentCode
          ? _.map(story, (childStory, childIndex) => {
              return childIndex === index
                ? {
                    ...childStory,
                    isAnswered: quizPayload.isAnswered,
                    isAnsweredCorrect: quizPayload.isAnsweredCorrect,
                    userSelectedIndex: quizPayload.userSelectedIndex,
                    correctAnswerIndex: quizPayload.correctAnswerIndex,
                  }
                : childStory;
            })
          : story;
      });
    });
  };

  useEffect(() => {
    const district = localStorage.getItem("district");
    if (district) {
      setLoading(CommonConstantValues.STARTED);
      const promises = [];
      const { axiosRequest, abortController } =
        StoryService.getStories(district);
      const {
        axiosRequest: axiosRequestWeather,
        abortController: abortControllerWeather,
      } = WeatherService.get();
      promises.push(axiosRequest);
      promises.push(axiosRequestWeather);
      Promise.allSettled(promises)
        .then((results) => {
          let stories = [];
          const [response, weather] = results;
          if (
            response?.status === "fulfilled" &&
            response?.value?.data?.responseData
          ) {
            const storiesPayload = response?.value?.data?.responseData;
            if (storiesPayload.length) {
              stories = _.concat(
                stories,
                _.map(storiesPayload, (story) =>
                  convertToStories(
                    story,
                    setStoryDuration,
                    markStoryAsLikedOrUnliked,
                    updateStoryWhenQuizIsAnswered
                  )
                )
              );
            }
          }

          if (
            weather?.status === "fulfilled" &&
            weather?.value?.data?.responseData
          ) {
            stories = _.concat(
              stories,
              getWeatherStory(
                weather?.value?.data?.responseData,
                setStoryDuration
              )
            );
          }
          setStories(reorderStories(stories));
          setLoading(CommonConstantValues.DONE);
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setError(error);
            setLoading(CommonConstantValues.DONE);
          }
        });

      return () => {
        abortController?.abort();
        abortControllerWeather?.abort();
      };
    }
  }, [
    localStorage.getItem("district"),
    localStorage.getItem("farmerId"),
    localStorage.getItem("InstitutionDetails"),
  ]);

  useEffect(() => {
    return () => {
      clearLocalStorageForStoriesExpired();
    };
  }, []);

  return { stories, loading, error, storiesDurations, markStoryViewed };
}

export default useStories;
