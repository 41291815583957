import React from "react";
import _ from "lodash";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function DropdownSelector({
  selectedAllFilterValue,
  handleChangeSelectedValue,
  menuList,
  inputLabelText,
}) {
  return (
    <FormControl
      sx={{
        height: "30px",
        background: "#FFFFFF",
        borderRadius: "16px",
        fontSize: "12px",
        margin: "0px 4px",
        boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1)",
      }}
    >
      {!selectedAllFilterValue && (
        <InputLabel sx={{ marginTop: "-9px" }}>{inputLabelText}</InputLabel>
      )}

      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={selectedAllFilterValue}
        onChange={handleChangeSelectedValue}
        sx={{
          height: "100%",
          fontSize: "12px",
          "&.Mui-focused fieldset": {
            border: "none !important",
          },

          border: "none !important",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
      >
        {_.map(menuList, ({ label, value }) => {
          return (
            <MenuItem
              key={value}
              value={value}
              sx={{ whiteSpace: "normal", fontSize: "12px" }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default DropdownSelector;
