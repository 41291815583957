import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import useQuery from "../../Hooks/useQuery";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px",
    textAlign: "center",
    backgroundColor: "#ffffff",
    minHeight: window.innerHeight,
  },
  successIconWrapper: {
    paddingTop: theme.spacing(25),
  },
  successMessage: { textAlign: "center", fontWeight: 700, lineHeight: "1.5" },
}));

export default function IPTOrderSuccessPage() {
  const classes = useStyles();
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    AnalyticsService.pushEvent(`IPT request created`, {
      page: "IPT Success",
      requestId: query.get("orderId"),
    });
  }, []);

  const handleButtonClick = () => {
    navigate(`/inter-party-transfers/${query.get("orderId")}`, {
      replace: true,
    });
  };

  return (
    <div className={classes.root}>
      <ErrorBoundary message={``}>
        <div className={classes.successIconWrapper}>
          <img src={`/icons/success.svg`} alt="success" width="130px" />
        </div>
        <Typography variant="subtitle1" className={classes.successMessage}>
          <span>
            Request Sent <br />
            Your Transfer (IPT){" "}
            {query.get("orderId") && `Order ID (${query.get("orderId")})`} has
            been successfully placed
          </span>
        </Typography>

        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={handleButtonClick}
          sx={{ marginTop: 10, borderRadius: "10px" }}
        >
          Go to Request details
        </Button>
      </ErrorBoundary>
    </div>
  );
}
