import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";
import { Container, Grid, Skeleton } from "@mui/material";

import SectionTitle from "../Common/SectionTitle";
import ProductCard from "../Product/ProductCard";
import ViewAllButton from "../Common/ViewAllButton";
import useRecentlyBoughtProducts from "../../Hooks/useRecentlyBoughtProducts";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    marginTop: theme.spacing(2),
  },
  loaderContent: {},
  mainContent: {
    backgroundColor: theme.palette.background.main,
  },
  loaderWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
  viewAllBrandsButtonWrapperLink: {
    textDecoration: "none",
  },
  sectionTitle: {
    marginTop: "15px",
  },
}));

function RecentlyBoughtProducts(props) {
  const classes = useStyles();
  const { products, loading, error } = useRecentlyBoughtProducts();

  return (
    <div>
      {loading === "done" &&
        (!error || !error?.data) &&
        products &&
        products.length > 0 && (
          <Container maxWidth={`xl`} className={classes.sectionTitle}>
            <SectionTitle title={`Market Your Products To Your Farmers`} />
            <div style={{ marginTop: "15px" }}>
              <Grid
                className={classes.mainContent}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                {_.map(
                  products.slice(
                    0,
                    props.numberOfProductsToShow
                      ? props.numberOfProductsToShow
                      : products.length
                  ),
                  (product, index) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <ProductCard
                          product={product}
                          key={product.skuCode}
                          index={index}
                          section={"Recently Bought Products"}
                          stateCode={props.stateCode}
                          saveScrollYPosition={true}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </div>
            {(!error || !error?.data) &&
              products &&
              products.length > props.numberOfProductsToShow && (
                <Link
                  to={`/market-your-products`}
                  className={classes.viewAllBrandsButtonWrapperLink}
                >
                  <ViewAllButton
                    section={"Recently Bought Products"}
                    saveScrollYPosition={true}
                  />
                </Link>
              )}
          </Container>
        )}

      {loading === "started" && (!error || !error?.data) && (
        <>
          <Skeleton
            variant={`text`}
            width={300}
            style={{ marginLeft: "16px", marginTop: "8px" }}
          />
          <div className={classes.loaderWrapper}>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.loaderContent}
              wrap={"nowrap"}
              spacing={2}
            >
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default RecentlyBoughtProducts;
