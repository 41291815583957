import { ButtonBase, Stack, Typography } from "@mui/material";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";

import CustomDateRange from "./CustomDateRange";
import AnalyticsService from "../../Services/AnalyticsService";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  selectedFilter: {
    backgroundColor: "#FFFFFF",
    color: "#9D2123",
    border: "1px solid #9D2123",
  },
  unselectedFilter: {
    backgroundColor: "#FFFFFF !important",
    color: "#4F4F4F",
  },
}));

const OrderFilters = (props) => {
  const classes = useStyles();
  let location = useLocation();

  const handleFilterClick = (clickedFilter) => {
    AnalyticsService.pushEvent(`Filter Clicked`, {
      page: location?.pathname || "",
      filterLabel: clickedFilter?.label || "",
    });
    props?.setSelectedOrderStatusFilter(clickedFilter);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          margin: "4px 0px",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {_.map(props?.orderFilters, (filter, index) => {
          return (
            <ButtonBase
              key={index}
              sx={{
                boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1);",
                borderRadius: "16px",
                padding: "5px 10px",
                display: "flex",
                alignItems: "center",
                fontWeight: 700,
              }}
              onClick={() => {
                handleFilterClick(filter);
              }}
              className={
                props?.selectedOrderStatusFilter?.identifier ===
                filter?.identifier
                  ? `${classes.selectedFilter}`
                  : `${classes.unselectedFilter}`
              }
            >
              <Typography
                noWrap
                sx={{
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                {filter.label}
              </Typography>
            </ButtonBase>
          );
        })}
        {props?.showDateFilter && (
          <CustomDateRange
            filterLabel="Order Date"
            maxDate={new Date()}
            selectedDateRangeFilter={props?.orderCreationDateRangeFilter}
            setSelectedDateRangeFilter={props?.setOrderCreationDateRangeFilter}
          />
        )}
        &nbsp;&nbsp;
      </Stack>
    </>
  );
};

export default OrderFilters;
