import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Box, Typography } from "@mui/material";
import OrderCard from "./OrderCard";
import EmptyError from "../EmptyError/EmptyError";
import StoreFrontService from "../../Services/StoreFrontService";
import InfiniteScroll from "react-infinite-scroll-component";
import StoreOrderCard from "./StoreOrderCard";
import _ from "lodash";
import OrderFilters from "../Hisaab/OrderFilters";

export default function StoreOrders() {
  const STORE_ORDER_STATUS_FILTERS = [
    {
      label: "Pending",
      identifier: "pending",
      selected: true,
    },
    {
      label: "Completed",
      identifier: "completed",
      selected: false,
    },
  ];
  const [ordersData, setOrdersData] = useState(null);
  const [loading, setLoading] = useState("not-started");
  const [fetchedOrdersCount, setFetchedOrdersCount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] = useState(
    _.find(STORE_ORDER_STATUS_FILTERS, (filter) => filter.selected)
  );

  useEffect(() => {
    const storeOrdersCB = fetchStoreOrders(true);
    return () => storeOrdersCB();
  }, [selectedOrderStatusFilter]);

  const fetchStoreOrders = (fetchFromStarting = false) => {
    if (fetchFromStarting) {
      setLoading("started");
      setOrdersData(null);
      setFetchedOrdersCount(0);
      setTotalOrdersCount(0);
    }
    const { axiosRequest, abortController } = StoreFrontService.getStoreOrders(
      selectedOrderStatusFilter.identifier,
      fetchFromStarting ? 0 : fetchedOrdersCount
    );

    axiosRequest
      .then((response) => {
        setFetchedOrdersCount(
          (fetchFromStarting ? 0 : fetchedOrdersCount) +
            response?.data?.responseData?.orders?.length
        );
        setOrdersData([
          ...(fetchFromStarting ? [] : ordersData || []),
          ...response?.data?.responseData?.orders,
        ]);
        setTotalOrdersCount(response?.data?.responseData?.totalOrders);
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.log(`Error Getting Store Orders: `, error);
        }
      });
    return () => abortController.abort();
  };

  return (
    <Box
      style={{
        padding: "16px 0 40px",
        background: "#F3F3F3",
      }}
    >
      <BlockUi
        tag="div"
        keepInView
        message={`Please wait...`}
        blocking={loading === "started"}
        style={{ minHeight: `90vh`, marginBottom: "24px" }}
      >
        <Box sx={{ padding: "0 16px" }}>
          <OrderFilters
            orderFilters={STORE_ORDER_STATUS_FILTERS}
            selectedOrderStatusFilter={selectedOrderStatusFilter}
            setSelectedOrderStatusFilter={setSelectedOrderStatusFilter}
            showDateFilter={false}
          />
        </Box>
        {ordersData?.length > 0 ? (
          <>
            <Typography
              variant="subtitle2"
              fontWeight={700}
              sx={{ padding: "0 16px", marginTop: "8px" }}
            >
              Orders ({totalOrdersCount})
            </Typography>

            <InfiniteScroll
              next={fetchStoreOrders}
              dataLength={fetchedOrdersCount}
              hasMore={fetchedOrdersCount < totalOrdersCount}
              loader={<h5 style={{ textAlign: "center" }}>Loading...</h5>}
              style={{ padding: "8px 16px 20px" }}
            >
              {ordersData?.map((order) => (
                <StoreOrderCard
                  key={order?.skuCode}
                  order={order}
                  loading={loading}
                  setLoading={setLoading}
                  selectedOrderStatusFilter={
                    selectedOrderStatusFilter?.identifier
                  }
                />
              ))}
            </InfiniteScroll>
          </>
        ) : (
          loading === "done" && (
            <EmptyError
              extraStyle={{
                minHeight: `40vh`,
              }}
              hideBack={true}
              image="EmptyPage.svg"
              subText="There are no orders yet!"
            />
          )
        )}
      </BlockUi>
    </Box>
  );
}
