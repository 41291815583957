import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import makeStyles from "@mui/styles/makeStyles";
import { Global } from "@emotion/react";
import { Box } from "@mui/material";
import {
  Button,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";

import useQuery from "../../Hooks/useQuery";
import AnalyticsService from "../../Services/AnalyticsService";
import InsuranceService from "../../Services/InsuranceService";
import AndroidService from "../../Services/AndroidService";

const useStyles = makeStyles((theme) => ({
  insuranceDetailsSection: {
    position: "relative",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: "#fff",
  },
  scrollingLine: {
    width: "50px",
    height: "5px",
    margin: "auto",
    marginTop: theme.spacing(2),
    background: "#000",
    borderRadius: "5px",
    opacity: 0.2,
  },
  cancelBtn: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(1),
    opacity: 0.5,
  },
  sumInsured: {
    padding: "12px",
    background: "rgb(0,0,0,0.1)",
    borderRadius: "10px",
  },
  smallBoldType: { fontSize: "14px", fontWeight: 700, marginTop: "10px" },
  smallNormalType: { fontSize: "12px", fontWeight: 400 },
  kisanImg: { position: "absolute", bottom: 0 },
  callImg: { marginRight: theme.spacing(0.6) },
}));

function InsuranceDetails({
  partnerEligible,
  setPartnerEligible,
  showInsuranceDetails,
  setShowInsuranceDetails,
  setShowUnauthorizedError,
}) {
  const classes = useStyles();
  const query = useQuery();
  const [insuranceIndicator, setInsuranceIndicator] = useState({});

  useEffect(() => {
    const farmerId = localStorage.getItem("farmerId");
    const { axiosRequest, abortController } =
      InsuranceService.getInsuranceDetails(farmerId);
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.insuranceIndicator?.status) {
          const insuranceInfo =
            response?.data?.responseData?.insuranceIndicator;

          const claimAmount = insuranceInfo?.sumInsured
            ?.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
              style: "currency",
              currency: "INR",
            })
            ?.split(".")[0];
          const validityDate = format(
            new Date(
              insuranceInfo?.validity
                ?.split("/")
                ?.reverse()
                ?.map(Number)
                ?.join(",")
            ),
            "dd MMM yyyy"
          );

          setInsuranceIndicator({
            ...insuranceInfo,
            sumInsured: claimAmount,
            validity: validityDate,
          });

          if (
            insuranceInfo.status === "Active" &&
            insuranceInfo.sumInsured &&
            insuranceInfo.validity
          ) {
            setPartnerEligible(true);
          }
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(error);
        }
      })
      .finally(() => {
        if (query.get("showDetails") == "insurance") {
          AnalyticsService.pushEvent(`Insurance Details Drawer Viewed`, {
            page: `/profile`,
          });
          setShowInsuranceDetails(true);
        }
      });
    return () => abortController.abort();
  }, [query]);

  const handleCloseInsuranceSection = () => {
    AnalyticsService.pushEvent(`Insurance Dialog Box Closed`, {
      page: `/profile`,
      partnerEligible,
    });
    setShowInsuranceDetails(false);
  };

  const handleDownload = async () => {
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      setShowUnauthorizedError(true);
    } else {
      AnalyticsService.pushEvent(`Insurance Download Document Clicked`, {
        page: `/profile`,
      });

      try {
        const source = localStorage.getItem("source");
        const pdfResponse = await fetch(`/${source}.pdf`);
        const blobResponse = await pdfResponse.blob();

        const fileDownloadElement = document.createElement("a");
        fileDownloadElement.href = URL.createObjectURL(blobResponse);
        if (AndroidService.checkIfAndroid()) {
          AndroidService.setFileNameInApp(`Claim Process Details`);
        }
        fileDownloadElement.download = `Claim Process Details.pdf`;
        fileDownloadElement.click();
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            overflow: showInsuranceDetails ? "visible" : "",
          },
        }}
      />
      <SwipeableDrawer
        anchor={`bottom`}
        onOpen={() => {}}
        open={showInsuranceDetails}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        onClose={handleCloseInsuranceSection}
        sx={{ borderRadius: "20px !important" }}
      >
        <div className={classes.insuranceDetailsSection}>
          <Box
            sx={{
              position: "absolute",
              top: -60,
              right: 0,
              left: 0,
              background: "#fff",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            }}
          >
            <div className={classes.scrollingLine}></div>
            <IconButton
              className={classes.cancelBtn}
              onClick={handleCloseInsuranceSection}
            >
              <HighlightOff
                sx={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </IconButton>
            <Typography
              variant="h6"
              fontSize={18}
              fontWeight={700}
              sx={{ p: 2 }}
            >
              Stock Insurance Details
            </Typography>
          </Box>

          {partnerEligible ? (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  position: "relative",
                  padding: "12px 20px",
                  color: "#fff",
                  background: "url('/icons/insuranceDetailsBackground.svg')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: "10px",
                }}
              >
                <Grid item xs={6}>
                  <Grid item xs={12} className={classes.sumInsured}>
                    <Typography variant="h6" fontWeight={700}>
                      {insuranceIndicator?.sumInsured}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.smallNormalType}
                    >
                      Sum Insured
                    </Typography>
                  </Grid>

                  <Typography variant="h6" className={classes.smallBoldType}>
                    11080011218000001233
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.smallNormalType}
                  >
                    Policy Number
                  </Typography>

                  <Typography variant="h6" className={classes.smallBoldType}>
                    {insuranceIndicator?.validity}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.smallNormalType}
                  >
                    Valid Till
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <img
                    src="/icons/saathiStockRakshak.svg"
                    alt="kisan calling"
                    width="150px"
                    height="150px"
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  mt: 3,
                  padding: "12px",
                  background: "rgba(0, 115, 62, 0.1)",
                  border: "1px solid rgba(0, 115, 62, 0.2)",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "13px",
                    fontWeight: "700",
                    color: "#00733E",
                  }}
                >
                  How to claim stock insurance?
                </Typography>
                <Button
                  variant="contained"
                  fullWidth={true}
                  aria-label="download"
                  endIcon={<img src="/icons/downloadIcon.svg" alt="download" />}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    mt: 1,
                    padding: "10px 16px",
                    color: "#00733E",
                    textTransform: "none",
                    boxShadow: "none",
                    background: "#fff",
                    borderRadius: "10px",
                    "&:hover": { background: "#fff" },
                  }}
                  onClick={handleDownload}
                >
                  Download the claim process details
                </Button>
              </Box>
            </>
          ) : (
            <Box sx={{ textAlign: "center", padding: "20px 30px" }}>
              <img src="/icons/EmptyPage.svg" alt="not found" />
              <Typography variant="subtitle2" className={classes.smallBoldType}>
                Ohhhh!! Seems like you don’t have stock insurance at this
                moment.
              </Typography>
            </Box>
          )}

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: "relative",
              padding: "12px",
              mt: 3,
              background: "#F5E9E9",
              border: "1px solid #FCD7D7",
              borderRadius: "10px",
            }}
          >
            <Grid item xs={1}>
              <img
                src="/icons/kisanCalling.svg"
                alt="kisan calling"
                width="40px"
                height="65px"
                className={classes.kisanImg}
              />
            </Grid>
            <Grid item xs={6.5}>
              <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
                Need help on stock insurance? Give us a call
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                href={
                  localStorage.getItem("partnerStatus") === "UNVERIFIED"
                    ? ``
                    : `tel:9960107107`
                }
                variant="contained"
                fullWidth={true}
                aria-label="download"
                sx={{
                  fontSize: "14px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
                    setShowUnauthorizedError(true);
                  } else {
                    AnalyticsService.pushEvent(
                      `Call Button Clicked From Insurance Drawer`,
                      {
                        page: `/profile`,
                        partnerEligible,
                      }
                    );
                  }
                }}
              >
                <img
                  src="/icons/needHelp.svg"
                  alt="call"
                  width="14px"
                  height="14px"
                  className={classes.callImg}
                />
                Call
              </Button>
            </Grid>
          </Grid>
        </div>
      </SwipeableDrawer>
    </>
  );
}

export default InsuranceDetails;
