import { Grid, IconButton, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Add, Remove } from "@mui/icons-material";
import { useCallback } from "react";
import { debounce } from "lodash";
import CommonConstantValues from "../../Services/CommonConstantValues";

const useStyles = makeStyles((theme) => ({
  inputField: {
    textAlign: "center",
  },
  addIcon: {
    height: theme.spacing(2.5),
    color: "#9D2123",
  },
  addIconDisabled: {
    height: theme.spacing(2.5),
    color: "#808080",
  },
  addIconButton: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    border: "1px solid #9D2123",
    marginLeft: "2.5px",
  },
  addIconButtonDisabled: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    border: "1px solid #808080",
    color: "#808080",
    marginLeft: "2.5px",
  },
  removeIcon: {
    height: theme.spacing(2.5),
    color: "#9D2123",
  },
  removeIconDisabled: {
    height: theme.spacing(2.5),
    color: "#808080",
  },
  removeIconButton: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    border: "1px solid #9D2123",
  },
  removeIconButtonDisabled: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    border: "1px solid #808080",
  },
}));

function ChangeQuantity({
  setQuantity,
  minimumOrderQuantity,
  handleChange,
  handleRemove,
  handleAdd,
  quantity,
  product,
  offersLoading,
}) {
  const handleChangeInput = (event) => {
    handleChangeDebounced(event);
    setQuantity(event.target.value);
    // if (setKeyword) {
    //   setKeyword(event.target.value);
    // }
  };

  const handleChangeDebounced = useCallback(debounce(handleChange, 1000), []);
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <Grid item xs={3}>
        <IconButton
          size={"small"}
          onClick={handleRemove}
          className={
            offersLoading === "started"
              ? classes.removeIconButtonDisabled
              : classes.removeIconButton
          }
          disabled={offersLoading === "started" ? true : false}
        >
          <Remove
            className={
              offersLoading === "started"
                ? classes.removeIconDisabled
                : classes.removeIcon
            }
          />
        </IconButton>
      </Grid>
      <Grid item xs={6} sx={{ marginLeft: "-6px", marginRight: "2px" }}>
        <TextField
          name={`quantity`}
          required={true}
          type={`number`}
          size={`small`}
          variant={"outlined"}
          value={quantity}
          onChange={handleChangeInput}
          min={minimumOrderQuantity || 1}
          max={CommonConstantValues.MAX_PURCHASE_QUANTITY}
          inputProps={{
            step: { minimumOrderQuantity },
            type: "number",
            minLength: "1",
            maxLength: "4",
            min: { minimumOrderQuantity },
            max: `${CommonConstantValues.MAX_PURCHASE_QUANTITY}`,
            pattern: "[0-9]*",
            inputMode: "numeric",
            style: {
              height: 17,
              // lineHeight: 17,
              border: `${
                offersLoading === "started"
                  ? `1px solid #808080`
                  : `1px solid #9D2123`
              }`,
              borderRadius: "4px",
              fontWeight: "500",
              fontSize: 15,
              textAlign: "center",
              color: `${offersLoading === "started" ? `#808080` : `#9D2123`}`,
            },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <IconButton
          size={"small"}
          onClick={handleAdd}
          className={
            offersLoading === "started"
              ? classes.addIconButtonDisabled
              : classes.addIconButton
          }
          disabled={offersLoading === "started" ? true : false}
        >
          <Add
            className={
              offersLoading === "started"
                ? classes.addIconDisabled
                : classes.addIcon
            }
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default ChangeQuantity;
