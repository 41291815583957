import { Add, CheckCircle, HighlightOff, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { useEffect, useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles({
  iconButton: {
    height: "24px",
    width: "24px",
    margin: "5px",
    border: "solid 1px #9D2123",
  },
  removeIcon: {
    height: "20px",
    color: "#9D2123",
  },
});

function ComplaintProductCard({
  product,
  handleSelectProduct,
  handleRemoveProduct,
  selectedProducts,
  issueReasons,
}) {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(1);
  const [reasons, setReasons] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const pageName = "Select Complaint Products";

  const handleReasonSelect = (event) => {
    const reasonName = event.target.value;
    const reason = _.find(reasons, (item) => {
      return item.reasonName === reasonName;
    });
    selectedReasons.push(reason);
    const newReasons = _.filter(reasons, (r) => {
      return r.reasonName !== reason.reasonName;
    });
    setReasons(newReasons);
    setSelectedReasons(selectedReasons);
    AnalyticsService.pushEvent("Reason selected", {
      page: pageName,
      skuCode: product.sku_code,
      reason: reason.reasonNameEN,
    });
  };

  const handleRemoveReason = (reason) => {
    reasons.push(reason);
    setReasons(reasons);
    const newSelectedReasons = _.filter(selectedReasons, (r) => {
      return r.reasonName !== reason.reasonName;
    });
    setSelectedReasons(newSelectedReasons);
    AnalyticsService.pushEvent("Reason unselected", {
      page: pageName,
      skuCode: product.sku_code,
      reason: reason.reasonNameEN,
    });
  };

  const handleAddProduct = (product) => {
    handleSelectProduct({
      skuCode: product.sku_code,
      productName: product.productName,
      productImage: product.productImage,
      quantity: quantity,
      reasons: selectedReasons,
    });
    setIsAdded(true);
    setIsExpanded(false);
  };

  const toggleCheck = (event, product) => {
    if (isAdded) {
      event.stopPropagation();
      setQuantity(1);
      clearSelectedReasons();
      handleRemoveProduct(product);
      setIsAdded(false);
    }
  };

  const clearSelectedReasons = () => {
    reasons.push(...selectedReasons);
    setReasons(reasons);
    setSelectedReasons([]);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCardClick = () => {
    const cardClickedEvent = {
      page: pageName,
      action: isExpanded ? "Collapsed" : "Expanded",
    };
    AnalyticsService.pushEvent(`Card Clicked`, cardClickedEvent);
    toggleExpand();
  };

  const handleQtyDecrease = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => {
        const productQuantityDecreasedEventData = {
          skuCode: product?.sku_code || "",
          page: pageName,
          quantity: prevQuantity - 1,
        };

        AnalyticsService.pushEvent(
          `Product Quantity Decreased`,
          productQuantityDecreasedEventData
        );
        return Number(prevQuantity) - 1;
      });
    }
  };

  const handleQtyIncrease = () => {
    if (quantity < product?.quantity) {
      setQuantity((prevQuantity) => {
        const productQuantityIncreasedEventData = {
          skuCode: product?.sku_code || "",
          page: pageName,
          quantity: prevQuantity + 1,
        };

        AnalyticsService.pushEvent(
          `Product Quantity Increased`,
          productQuantityIncreasedEventData
        );
        return Number(prevQuantity) + 1;
      });
    }
  };

  useEffect(() => {
    const selectedProduct = _.find(selectedProducts, (selectedProduct) => {
      return selectedProduct.skuCode === product.sku_code;
    });
    if (selectedProduct) {
      setQuantity(selectedProduct.quantity);
      setIsAdded(true);
      setSelectedReasons(selectedProduct.reasons);
      const reasonList = _.differenceBy(
        issueReasons,
        selectedProduct.reasons,
        (item) => item.reasonId
      );
      setReasons(reasonList);
    } else {
      setReasons(issueReasons);
    }
  }, []);

  return (
    <Card
      elevation={0}
      sx={{
        margin: "16px",
        borderRadius: "8px",
      }}
    >
      <Grid
        container
        sx={{
          padding: "10px",
        }}
        onClick={handleCardClick}
      >
        <Grid item xs={2}>
          <img
            src={product?.productImage}
            alt=""
            width={"32px"}
            height="100%"
          />
        </Grid>
        <Grid item xs={9}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "18px",
              color: "#212121",
            }}
          >
            {product?.productName}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#4f4f4f",
            }}
          >
            Quantity - {product.quantity}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <CheckCircle
            onClick={(event) => toggleCheck(event, product)}
            color={isAdded ? "secondary" : "disabled"}
          />
        </Grid>
      </Grid>
      {isExpanded && (
        <form onSubmit={() => handleAddProduct(product)}>
          <Box>
            <hr
              style={{
                border: "1px solid #EEE",
              }}
            />
            <Grid
              container
              sx={{
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Grid item xs={3}></Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    color: "#212121",
                  }}
                >
                  Select Quantity
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <IconButton
                  className={classes.iconButton}
                  onClick={handleQtyDecrease}
                >
                  <Remove className={classes.removeIcon} />
                </IconButton>
                <TextField
                  name={`quantity`}
                  type={`number`}
                  size={`small`}
                  value={quantity}
                  onChange={(event) => setQuantity(Number(event.target.value))}
                  sx={{ height: 25 }}
                  inputProps={{
                    min: 1,
                    max: product.quantity,
                    type: "number",
                    minLength: "1",
                    maxLength: "4",
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    style: {
                      width: 40,
                      height: 17,
                      fontSize: 15,
                      padding: "8px",
                      fontWeight: "500",
                      textAlign: "center",
                      borderRadius: "4px",
                      color: "#9D2123",
                      border: "1px solid #9D2123",
                    },
                  }}
                />
                <IconButton
                  className={classes.iconButton}
                  onClick={handleQtyIncrease}
                >
                  <Add className={classes.removeIcon} />
                </IconButton>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "16px 0px",
                }}
              >
                <FormControl fullWidth size="small">
                  <InputLabel id="select-reason">
                    Select one or more reasons
                  </InputLabel>
                  <Select
                    sx={{
                      height: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9D2123",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#9D2123",
                      },
                    }}
                    onChange={handleReasonSelect}
                    label="Select one or more reasons"
                    id="select-reason"
                    labelId="select-reason"
                    disabled={reasons.length === 0}
                    defaultValue=""
                    value=""
                  >
                    {_.map(reasons, ({ reasonName, reasonId }) => {
                      return (
                        <MenuItem
                          key={reasonId}
                          value={reasonName}
                          sx={{
                            whiteSpace: "normal",
                          }}
                        >
                          {reasonName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box width={`100%`}>
                {selectedReasons?.length > 0 &&
                  _.map(selectedReasons, (reason) => {
                    return (
                      <Card
                        width="100%"
                        key={reason.reasonId}
                        sx={{
                          margin: "4px 0px",
                          padding: "5px 10px",
                          boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
                          borderRadius: "16px",
                        }}
                        elevation={0}
                      >
                        <Grid
                          container
                          width={`100%`}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={11}>
                            <Typography justifyContent={`center`}>
                              {reason.reasonName}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} display="flex">
                            <HighlightOff
                              color="disabled"
                              onClick={() => {
                                handleRemoveReason(reason);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })}
              </Box>
            </Grid>
            <Grid container>
              <Grid item xs={11}>
                <Button
                  fullWidth
                  type="submit"
                  variant={`contained`}
                  sx={{
                    borderRadius: "10px",
                    margin: "16px",
                    textTransform: "capitalize",
                  }}
                  disabled={selectedReasons?.length === 0}
                >
                  Add Product
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </Card>
  );
}

export default ComplaintProductCard;
