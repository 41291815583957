import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(3) },
  productInfoCard: {
    borderRadius: "0px",
    border: "none",
  },
  keyPointsLabel: {
    fontWeight: "700",
    fontSize: "14px",
  },
  valueCell: {
    borderLeft: "1px solid #F3F3F3;",
    fontSize: "12px",
    lineHeight: "16px",
  },
}));

function ProductInfo(props) {
  const classes = useStyles();

  let productInfo = props.productInfo;

  return (
    <>
      {productInfo && productInfo.length > 0 && (
        <div className={classes.root}>
          <Card variant={`outlined`} className={classes.productInfoCard}>
            <CardContent style={{ paddingBottom: "0px" }}>
              <Typography className={classes.keyPointsLabel}>
                {productInfo?.[0]?.label}
              </Typography>
            </CardContent>

            <TableContainer component={"div"}>
              <Table aria-label="simple table">
                {_.map(productInfo, (info, infoIndex) => {
                  return (
                    <TableBody key={infoIndex}>
                      {_.map(info?.attributes, (attribute, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              <b
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                {attribute.key}
                              </b>
                            </TableCell>
                            <TableCell className={classes.valueCell}>
                              {attribute.value}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
          </Card>
        </div>
      )}
    </>
  );
}

export default ProductInfo;
