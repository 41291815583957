const config = {
  environment: window.config.environment,
  appUrl: window.config.appUrl,
  backendUrl: window.config.backendUrl,
  mockOtp: window.config.mockOtp,
  hideAndroidDialogs: window.config.hideAndroidDialogs,
  clevertapAccountId: window.config.clevertapAccountId,
  paymentRedirectionUrl: window.config.paymentRedirectionUrl,
  farmerAppBaseUrl: window.config.farmerAppBaseUrl,
  disablePayNow: window.config.disablePayNow,
  farmerWebAppBaseUrl: window.config.farmerWebAppBaseUrl,
  firebaseConfig: window.config.firebaseConfig,
  reactAppVersion: "15.1.4",
};

export default config;
