import { Box, SwipeableDrawer, Typography } from "@mui/material";
import AndroidService from "../../Services/AndroidService";
import SwipeableDrawerHeader from "../Common/SwipeableDrawerHeader";
import CurrentSchemeAchievements from "./CurrentSchemeAchievements";
import SchemesService from "../../Services/SchemesService";
import ProductDetailsPrimary from "../Product/ProductDetailsPrimary";
import ProductCheckoutActions from "../Product/ProductCheckoutActions";
import TodSettledSaleBreakup from "./TodSettledSaleBreakup";
import { useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";

const SchemeDetailsDrawer = ({
  open,
  setOpen,
  product,
  productDetailsRef,
  productDetailsStickyAtTop,
  isPayAdvanceSchemeActive,
  appliedOffer,
  appliedCashDiscountBenefit,
  offersLoading,
  turnOverSchemeBenefits,
  cashDiscountBenefitContainerRef,
  offersContainerRef,
  turnoverScheme,
  turnoverSchemeContainerRef,
  farmerNetPrice,
  loading,
  payAdvanceSchemeDetailsRef,
  quantity,
  handleAdd,
  handleRemove,
  handleChange,
  setQuantity,
  minimumOrderQuantity,
  skuCode,
  setLoading,
  sku,
  setCartLoading,
  setShowAddedToBagToast,
  setShowBagUpdatedToast,
  setShowCouponRemovedToast,
  setShowUnauthorizedError,
  setUnauthorizedMessage,
}) => {
  const slabConstraintUnit =
    SchemesService.SLAB_CONSTRAINT_UNIT_MAP[
      turnoverScheme?.constraintsStatus?.[0]?.slabConstraintsStatus?.[0]
        ?.constraintKey
    ];

  const [openSettledSaleBreakup, setOpenSettledSaleBreakup] = useState(false);

  return (
    <>
      <SwipeableDrawer
        anchor={`bottom`}
        open={open}
        PaperProps={{
          style: {
            borderRadius: "24px 24px 0px 0px",
            maxHeight: "85vh",
          },
        }}
        onOpen={() => AndroidService.unsetPullToRefresh()}
        onClose={() => {
          setOpen(false);
          AndroidService.setPullToRefresh();
          AnalyticsService.pushEvent(`Drawer Closed`, {
            type: "Scheme Details",
          });
        }}
        disableDiscovery={true}
        disableSwipeToOpen={true}
      >
        <Box sx={{ padding: 2, background: "#EFEFEF80" }}>
          <SwipeableDrawerHeader
            handleOnClose={() => {
              setOpen(false);
              AndroidService.setPullToRefresh();
              AnalyticsService.pushEvent(`Dialog Closed`, {
                type: "Scheme Details",
              });
            }}
          />
          <Typography sx={{ fontWeight: "bold", color: "#4F4F4F", mb: "20px" }}>
            {turnoverScheme?.name}
          </Typography>
          <CurrentSchemeAchievements
            turnoverScheme={turnoverScheme}
            slabConstraintUnit={slabConstraintUnit}
            showTitle={false}
            showSettledSaleBreakup={
              turnoverScheme?.linkWithCollection ? true : false
            }
            showNetSchemeCN={false}
            showNetLandingCN={false}
            setOpenSettledSaleBreakup={setOpenSettledSaleBreakup}
          />
        </Box>

        <ProductDetailsPrimary
          product={product}
          productDetailsRef={productDetailsRef}
          productDetailsStickyAtTop={productDetailsStickyAtTop}
          isPayAdvanceSchemeActive={isPayAdvanceSchemeActive}
          appliedOffer={appliedOffer}
          appliedCashDiscountBenefit={appliedCashDiscountBenefit}
          offersLoading={offersLoading}
          turnOverSchemeBenefits={turnOverSchemeBenefits}
          cashDiscountBenefitContainerRef={cashDiscountBenefitContainerRef}
          offersContainerRef={offersContainerRef}
          turnoverScheme={turnoverScheme}
          turnoverSchemeContainerRef={turnoverSchemeContainerRef}
          farmerNetPrice={farmerNetPrice}
          loading={loading}
          payAdvanceSchemeDetailsRef={payAdvanceSchemeDetailsRef}
          quantity={quantity}
          handleAdd={handleAdd}
          handleRemove={handleRemove}
          handleChange={handleChange}
          setQuantity={setQuantity}
          minimumOrderQuantity={minimumOrderQuantity}
          hideProductVariants={true}
          hideProductMargin={true}
          setOpenSchemeDetailsDrawer={setOpen}
          section={"Scheme Details Drawer"}
        />

        <Box sx={{ paddingBottom: 10 }}>
          <ProductCheckoutActions
            product={product}
            quantity={quantity}
            loading={loading}
            isPayAdvanceSchemeActive={isPayAdvanceSchemeActive}
            skuCode={skuCode}
            setLoading={setLoading}
            sku={sku}
            appliedOffer={appliedOffer}
            setCartLoading={setCartLoading}
            setShowAddedToBagToast={setShowAddedToBagToast}
            setShowBagUpdatedToast={setShowBagUpdatedToast}
            setShowCouponRemovedToast={setShowCouponRemovedToast}
            setShowUnauthorizedError={setShowUnauthorizedError}
            setUnauthorizedMessage={setUnauthorizedMessage}
            hideWhatsAppPromote={true}
            section={"Scheme Details Drawer"}
          />
        </Box>
      </SwipeableDrawer>
      <TodSettledSaleBreakup
        turnoverScheme={turnoverScheme}
        open={openSettledSaleBreakup}
        setOpen={setOpenSettledSaleBreakup}
      />
    </>
  );
};

export default SchemeDetailsDrawer;
