import PayAdvanceSchemeProducts from "../../Services/PayAdvanceSchemeProducts";
import ErrorBoundary from "../Common/ErrorBoundary";
import AddToCart from "./AddToCart";
import BookNowButton from "./BookNowButton";
import NotifyMe from "./NotifyMe";

const ProductCheckoutActions = ({
  product,
  quantity,
  loading,
  isPayAdvanceSchemeActive,
  skuCode,
  setLoading,
  sku,
  appliedOffer,
  setCartLoading,
  setShowAddedToBagToast,
  setShowBagUpdatedToast,
  setShowCouponRemovedToast,
  setShowUnauthorizedError,
  setUnauthorizedMessage,
  hideWhatsAppPromote,
  section,
}) => {
  return (
    <>
      {isPayAdvanceSchemeActive ? (
        <>
          {localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <BookNowButton
              loading={loading}
              price={PayAdvanceSchemeProducts?.[skuCode]?.bookingPricePerUnit}
              product={product}
              quantity={quantity}
              schemeUnit={PayAdvanceSchemeProducts?.[skuCode]?.schemeUnit}
              schemeName={PayAdvanceSchemeProducts?.[skuCode]?.schemeName}
              schemeUnitMultiple={
                PayAdvanceSchemeProducts?.[skuCode]?.schemeUnitMultiple
              }
            />
          )}
        </>
      ) : (
        <ErrorBoundary message={``}>
          {product?.showNotifyMe &&
          localStorage.getItem("role") !== "fieldAgent" &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" ? (
            <NotifyMe
              product={product}
              isUserOptInForNotify={product?.isUserOptInForNotify}
              setLoading={setLoading}
            />
          ) : (
            <AddToCart
              product={product}
              skuCode={sku}
              quantity={quantity}
              offerAppliedOnProduct={appliedOffer}
              setCartLoading={setCartLoading}
              setShowAddedToBagToast={setShowAddedToBagToast}
              setShowBagUpdatedToast={setShowBagUpdatedToast}
              setShowCouponRemovedToast={setShowCouponRemovedToast}
              loading={loading}
              setLoading={setLoading}
              setShowUnauthorizedError={setShowUnauthorizedError}
              setUnauthorizedMessage={setUnauthorizedMessage}
              hideWhatsAppPromote={hideWhatsAppPromote}
              section={section}
            />
          )}
        </ErrorBoundary>
      )}
    </>
  );
};

export default ProductCheckoutActions;
