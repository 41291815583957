import { Box, Container, Grid, Typography } from "@mui/material";
import _ from "lodash";
import TripOriginOutlinedIcon from "@mui/icons-material/TripOriginOutlined";
import CurrentProgressDescription from "./CurrentProgressDescription";
import { useMemo, useRef, useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";

const HorizontalScrollableSchemeProgress = (props) => {
  let containerWidth = window.innerWidth;
  containerWidth = `${
    window.innerWidth * Math.ceil(props?.sliderMarks?.length / 2)
  }px`;

  const tooltipRef1 = useRef(null);
  const tooltipRef2 = useRef(null);
  const tooltipRef3 = useRef(null);
  const tooltipRef4 = useRef(null);

  const entitlementTooltipTopMargin = (tooltipRef) => {
    return tooltipRef?.current?.clientHeight <= 28
      ? `-32px`
      : tooltipRef?.current?.clientHeight <= 42
        ? `-48px`
        : tooltipRef?.current?.clientHeight <= 58
          ? `-64px`
          : tooltipRef?.current?.clientHeight <= 74
            ? `-82px`
            : `-96px`;
  };

  const [progressBackgroundScrolled, setProgressBackgroundScrolled] =
    useState(false);

  const handleSchemeProgressScrollEnd = useMemo(
    () =>
      _.debounce(() => {
        setProgressBackgroundScrolled(false);
      }, 50),
    []
  );
  const handleSchemeProgressScroll = () => {
    if (progressBackgroundScrolled === false) {
      AnalyticsService.pushEvent(`Scheme Progress Scrolled`, {
        schemeName: props?.turnoverScheme?.name,
        schemeId: props?.schemeId,
      });
      setProgressBackgroundScrolled(true);
    }
    handleSchemeProgressScrollEnd();
  };

  return (
    <div>
      <Container
        maxWidth={`xl`}
        sx={{ background: "#CFF8FF", padding: "0px", marginTop: "-26px" }}
      >
        <Box>
          <Box
            sx={{ overflowX: "auto", overflowY: "hidden", width: "100%" }}
            onScroll={handleSchemeProgressScroll}
          >
            <Grid
              container
              direction="row"
              sx={{
                flexWrap: "wrap",
                width: containerWidth,
                height: "280px",
              }}
              spacing={0}
            >
              {_.times(Math.ceil(props?.sliderMarks?.length / 4), (index) => {
                return (
                  <>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        flex: "1 1",
                        width: window.innerWidth,
                        height: "280px",
                      }}
                      key={2 * index}
                    >
                      <img
                        src={`/icons/schemeDetailsBackground1.png`}
                        alt=""
                        key={index}
                        style={{
                          width: window.innerWidth,
                          maxHeight: "280px",
                          minHeight: "280px",
                        }}
                      />
                      <Box
                        sx={{
                          width: window.innerWidth,
                          position: "relative",
                          marginTop: "-115px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {props?.turnoverScheme?.turnoverOfferRule?.[
                              4 * index - 1
                            ]?.entitlements?.entitlementList &&
                              props?.sliderMarks?.[4 * index]?.slabTarget >
                                props?.turnoverScheme?.netValue && (
                                <Box
                                  ref={tooltipRef1}
                                  sx={{
                                    textAlign: "center",
                                    position: "absolute",
                                    marginTop:
                                      entitlementTooltipTopMargin(tooltipRef1),
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      marginRight: "3px",
                                      fontSize: "11px",
                                      fontWeight: 700,
                                      color: "#FFFFFF",
                                      background: "#1C1C1C",
                                      borderRadius: "10px",
                                      padding: "5px 4px",
                                      letterSpacing: "0.01px",
                                      lineHeight: "16px",
                                      position: "relative",
                                      wordBreak: "break-all",
                                      "&::after": {
                                        content: `""`,
                                        position: "absolute",
                                        top: "100%",
                                        left: "50%",
                                        marginLeft: "-5px",
                                        borderWidth: "5px",
                                        borderStyle: "solid",
                                        borderColor:
                                          "#1C1C1C transparent transparent transparent",
                                      },
                                    }}
                                  >
                                    {props?.generateEntitlementDescription(
                                      props?.turnoverScheme
                                        ?.turnoverOfferRule?.[4 * index - 1]
                                        ?.entitlements?.entitlementList
                                    )}
                                  </Typography>
                                </Box>
                              )}

                            <TripOriginOutlinedIcon
                              sx={{
                                alignSelf: "center",
                                fontSize: "21px",
                                color: `${
                                  props?.sliderMarks?.[4 * index]?.slabTarget <=
                                  props?.turnoverScheme?.netValue
                                    ? `#00733E`
                                    : `#1C1C1C`
                                }`,
                                background: `${
                                  props?.sliderMarks?.[4 * index]?.slabTarget <=
                                  props?.turnoverScheme?.netValue
                                    ? `#F8D149`
                                    : `#FFFFFF`
                                }`,
                                borderRadius: "50%",
                              }}
                            />

                            <Typography
                              sx={{
                                letterSpacing: "-0.8px",
                                wordSpacing: "-0.5px",
                                fontSize: "14px",
                                fontWeight: 700,
                                color: `${
                                  props?.sliderMarks?.[4 * index]?.slabTarget <=
                                  props?.turnoverScheme?.netValue
                                    ? `#00733E`
                                    : `#4F4F4F`
                                }`,
                              }}
                            >
                              {props?.sliderMarks?.[4 * index]?.label}
                            </Typography>
                          </Box>

                          {<>&nbsp;</>}

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {props?.sliderMarks?.[4 * index + 1] ? (
                              <>
                                {props?.turnoverScheme?.turnoverOfferRule?.[
                                  4 * index
                                ]?.entitlements?.entitlementList &&
                                  props?.sliderMarks?.[4 * index + 1]
                                    ?.slabTarget >
                                    props?.turnoverScheme?.netValue && (
                                    <Box
                                      ref={tooltipRef2}
                                      sx={{
                                        textAlign: "center",
                                        position: "absolute",
                                        marginTop:
                                          entitlementTooltipTopMargin(
                                            tooltipRef2
                                          ),
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          marginRight: "3px",
                                          fontSize: "11px",
                                          fontWeight: 700,
                                          color: "#FFFFFF",
                                          background: "#1C1C1C",
                                          borderRadius: "10px",
                                          padding: "5px 4px",
                                          letterSpacing: "0.01px",
                                          lineHeight: "16px",
                                          position: "relative",
                                          wordBreak: "break-all",
                                          "&::after": {
                                            content: `""`,
                                            position: "absolute",
                                            top: "100%",
                                            left: "50%",
                                            marginLeft: "-5px",
                                            borderWidth: "5px",
                                            borderStyle: "solid",
                                            borderColor:
                                              "#1C1C1C transparent transparent transparent",
                                          },
                                        }}
                                      >
                                        {props?.generateEntitlementDescription(
                                          props?.turnoverScheme
                                            ?.turnoverOfferRule?.[4 * index]
                                            ?.entitlements?.entitlementList
                                        )}
                                      </Typography>
                                    </Box>
                                  )}

                                <TripOriginOutlinedIcon
                                  sx={{
                                    alignSelf: "center",
                                    fontSize: "21px",
                                    color: `${
                                      props?.sliderMarks?.[4 * index + 1]
                                        ?.slabTarget <=
                                      props?.turnoverScheme?.netValue
                                        ? `#00733E`
                                        : `#1C1C1C`
                                    }`,
                                    background: `${
                                      props?.sliderMarks?.[4 * index + 1]
                                        ?.slabTarget <=
                                      props?.turnoverScheme?.netValue
                                        ? `#F8D149`
                                        : `#FFFFFF`
                                    }`,
                                    borderRadius: "50%",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    letterSpacing: "-0.8px",
                                    wordSpacing: "-0.5px",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    color: `${
                                      props?.sliderMarks?.[4 * index + 1]
                                        ?.slabTarget <=
                                      props?.turnoverScheme?.netValue
                                        ? `#00733E`
                                        : `#4F4F4F`
                                    }`,
                                  }}
                                >
                                  {props?.sliderMarks?.[4 * index + 1]?.label}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <TripOriginOutlinedIcon
                                  sx={{
                                    alignSelf: "center",
                                    fontSize: "21px",
                                    color: `transparent`,
                                    background: `transparent`,
                                    borderRadius: "50%",
                                  }}
                                />
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    {props?.sliderMarks?.length - (index * 4 + 2) > 0 && (
                      <Grid
                        item
                        xs={6}
                        sx={{
                          flex: "1 1",
                          width: window.innerWidth,
                          height: "280px",
                        }}
                        key={2 * index + 1}
                      >
                        <img
                          src={`/icons/schemeDetailsBackground2.png`}
                          alt=""
                          key={index}
                          style={{
                            width: window.innerWidth,
                            maxHeight: "280px",
                            minHeight: "280px",
                          }}
                        />
                        <Box
                          sx={{
                            width: window.innerWidth,
                            position: "relative",
                            marginTop: "-115px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {props?.turnoverScheme?.turnoverOfferRule?.[
                                4 * index + 1
                              ]?.entitlements?.entitlementList &&
                                props?.sliderMarks?.[4 * index + 2]
                                  ?.slabTarget >
                                  props?.turnoverScheme?.netValue && (
                                  <Box
                                    ref={tooltipRef3}
                                    sx={{
                                      textAlign: "center",
                                      position: "absolute",
                                      marginTop:
                                        entitlementTooltipTopMargin(
                                          tooltipRef3
                                        ),
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        marginRight: "3px",
                                        fontSize: "11px",
                                        fontWeight: 700,
                                        color: "#FFFFFF",
                                        background: "#1C1C1C",
                                        borderRadius: "10px",
                                        padding: "5px 4px",
                                        letterSpacing: "0.01px",
                                        lineHeight: "16px",
                                        position: "relative",
                                        wordBreak: "break-all",
                                        "&::after": {
                                          content: `""`,
                                          position: "absolute",
                                          top: "100%",
                                          left: "50%",
                                          marginLeft: "-5px",
                                          borderWidth: "5px",
                                          borderStyle: "solid",
                                          borderColor:
                                            "#1C1C1C transparent transparent transparent",
                                        },
                                      }}
                                    >
                                      {props?.generateEntitlementDescription(
                                        props?.turnoverScheme
                                          ?.turnoverOfferRule?.[4 * index + 1]
                                          ?.entitlements?.entitlementList
                                      )}
                                    </Typography>
                                  </Box>
                                )}

                              <TripOriginOutlinedIcon
                                sx={{
                                  alignSelf: "center",
                                  fontSize: "21px",
                                  color: `${
                                    props?.sliderMarks?.[4 * index + 2]
                                      ?.slabTarget <=
                                    props?.turnoverScheme?.netValue
                                      ? `#00733E`
                                      : `#1C1C1C`
                                  }`,
                                  background: `${
                                    props?.sliderMarks?.[4 * index + 2]
                                      ?.slabTarget <=
                                    props?.turnoverScheme?.netValue
                                      ? `#F8D149`
                                      : `#FFFFFF`
                                  }`,
                                  borderRadius: "50%",
                                }}
                              />
                              <Typography
                                sx={{
                                  letterSpacing: "-0.8px",
                                  wordSpacing: "-0.5px",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  color: `${
                                    props?.sliderMarks?.[4 * index + 2]
                                      ?.slabTarget <=
                                    props?.turnoverScheme?.netValue
                                      ? `#00733E`
                                      : `#4F4F4F`
                                  }`,
                                }}
                              >
                                {props?.sliderMarks?.[4 * index + 2]?.label}
                              </Typography>
                            </Box>

                            {<>&nbsp;</>}

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {props?.sliderMarks?.[4 * index + 3] ? (
                                <>
                                  {props?.turnoverScheme?.turnoverOfferRule?.[
                                    4 * index + 2
                                  ]?.entitlements?.entitlementList &&
                                    props?.sliderMarks?.[4 * index + 3]
                                      ?.slabTarget >
                                      props?.turnoverScheme?.netValue && (
                                      <Box
                                        ref={tooltipRef4}
                                        sx={{
                                          textAlign: "center",
                                          position: "absolute",
                                          marginTop:
                                            entitlementTooltipTopMargin(
                                              tooltipRef4
                                            ),
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            marginRight: "3px",
                                            fontSize: "11px",
                                            fontWeight: 700,
                                            color: "#FFFFFF",
                                            background: "#1C1C1C",
                                            borderRadius: "10px",
                                            padding: "5px 4px",
                                            letterSpacing: "0.01px",
                                            lineHeight: "16px",
                                            position: "relative",
                                            wordBreak: "break-all",
                                            "&::after": {
                                              content: `""`,
                                              position: "absolute",
                                              top: "100%",
                                              left: "50%",
                                              marginLeft: "-5px",
                                              borderWidth: "5px",
                                              borderStyle: "solid",
                                              borderColor:
                                                "#1C1C1C transparent transparent transparent",
                                            },
                                          }}
                                        >
                                          {props?.generateEntitlementDescription(
                                            props?.turnoverScheme
                                              ?.turnoverOfferRule?.[
                                              4 * index + 2
                                            ]?.entitlements?.entitlementList
                                          )}
                                        </Typography>
                                      </Box>
                                    )}

                                  <TripOriginOutlinedIcon
                                    sx={{
                                      alignSelf: "center",
                                      fontSize: "21px",
                                      color: `${
                                        props?.sliderMarks?.[4 * index + 3]
                                          ?.slabTarget <=
                                        props?.turnoverScheme?.netValue
                                          ? `#00733E`
                                          : `#1C1C1C`
                                      }`,
                                      background: `${
                                        props?.sliderMarks?.[4 * index + 3]
                                          ?.slabTarget <=
                                        props?.turnoverScheme?.netValue
                                          ? `#F8D149`
                                          : `#FFFFFF`
                                      }`,
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      letterSpacing: "-0.8px",
                                      wordSpacing: "-0.5px",
                                      fontSize: "14px",
                                      fontWeight: 700,
                                      color: `${
                                        props?.sliderMarks?.[4 * index + 3]
                                          ?.slabTarget <=
                                        props?.turnoverScheme?.netValue
                                          ? `#00733E`
                                          : `#4F4F4F`
                                      }`,
                                    }}
                                  >
                                    {props?.sliderMarks?.[4 * index + 3]?.label}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <TripOriginOutlinedIcon
                                    sx={{
                                      alignSelf: "center",
                                      fontSize: "21px",
                                      color: `transparent`,
                                      background: `transparent`,
                                      borderRadius: "50%",
                                    }}
                                  />
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </>
                );
              })}
            </Grid>
          </Box>
        </Box>

        {/* Temporarily commented out as prompt message requires backend change to consider settlement logic (instead of purchase logic) */}
        {/* <Box
          sx={{
            background: "#FFFFFF",
            marginTop: "-42px",
            paddingBottom: "20px",
          }}
        >
          <CurrentProgressDescription
            reverseDescriptionFormat={true}
            turnoverScheme={props?.turnoverScheme}
            finalSlabTarget={props?.finalSlabTarget}
            slabConstraintUnit={props?.slabConstraintUnit}
            nextTargetEntitlement={props?.nextTargetEntitlement}
            nextSlabDetails={props?.nextSlabDetails}
            generateEntitlementDescription={
              props?.generateEntitlementDescription
            }
            page={props?.page}
            schemeId={props?.schemeId}
            lastSlabAchieved={props?.lastSlabAchieved}
            nudgeClickable={false}
            deltaPromptQuantity={props?.deltaPromptQuantity}
            isFeaturedSchemes={props?.isFeaturedSchemes}
          />
        </Box> */}
      </Container>
    </div>
  );
};

export default HorizontalScrollableSchemeProgress;
