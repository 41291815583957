import config from "../config";
import _ from "lodash";
import CartMock from "./mocks/CartMock";
import AndroidService from "./AndroidService";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class CartService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static fetchAndStoreCart() {
    const { axiosRequest, abortController } = this.getMultiCartProductsLive();
    const promise = axiosRequest.then((cart) => {
      localStorage.setItem(`cartv2`, JSON.stringify(cart));
      return cart.products;
    });
    return { axiosRequest: promise, abortController };
  }

  static getMultiCartProductsLive = () => {
    const { axiosRequest, abortController } = this.getMultiCartLive();
    const promise = new Promise((resolve, reject) => {
      axiosRequest
        .then((carts) => {
          const appliedCouponCreditCart = carts?.CREDIT_ORDER?.appliedCoupon;
          const appliedCouponCashOnlyCart =
            carts?.CASH_ONLY_ORDER?.appliedCoupon;
          const products = _.reduce(
            carts,
            (acc, current) => {
              return current && current.products
                ? [...acc, ...current.products]
                : acc;
            },
            []
          );
          return resolve({
            products,
            appliedCouponCashOnlyCart,
            appliedCouponCreditCart,
          });
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
          }
        });
    });
    return { axiosRequest: promise, abortController };
  };

  static getCart(withCoupons) {
    let cartFromLocalStorage = localStorage.getItem(`cartv2`);
    if (cartFromLocalStorage) {
      if (withCoupons) {
        return JSON.parse(cartFromLocalStorage);
      }
      return JSON.parse(cartFromLocalStorage)?.products;
    }
    return [];
  }

  static getProductFromCart(skuCode) {
    let currentCart = this.getCart();
    if (currentCart) {
      let product = _.find(currentCart, (product) => {
        return skuCode === product.skuCode;
      });
      if (product) {
        return product;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static addToCart(product) {
    if (
      product.quantity &&
      product.quantity > 0 &&
      product.skuCode &&
      product.skuCode !== ""
    ) {
      let {
        products: newProducts,
        appliedCouponCashOnlyCart,
        appliedCouponCreditCart,
      } = this.getCart(true);
      let updatedFlag = false;
      _.forEach(newProducts, (newProduct) => {
        if (newProduct.skuCode === product.skuCode) {
          newProduct.quantity = product.quantity;
          newProduct.appliedOffer = product.appliedOffer;
          newProduct.appliedOfferId = product.appliedOfferId;
          updatedFlag = true;
        }
      });
      if (updatedFlag === false) {
        newProducts.push(product);
      }
      const url = `${config.backendUrl}onlineecommerceservice/v1/saathicart/`;
      const data = {
        farmerId: parseInt(localStorage.getItem("farmerId")),
        products: newProducts,
        appliedCouponCashOnlyCart,
        appliedCouponCreditCart,
      };
      const { axiosRequest } = HTTPService.makeXHRRequest(
        url,
        "PUT",
        data,
        this.getHeaders()
      );
      return axiosRequest.then((response) => {
        const carts = response?.data?.responseData;
        if (carts) {
          const appliedCouponCreditCart = carts?.CREDIT_ORDER?.appliedCoupon;
          const couponRemovedReasonCreditCart =
            carts?.CREDIT_ORDER?.couponRemovedReason;
          const appliedCouponCashOnlyCart =
            carts?.CASH_ONLY_ORDER?.appliedCoupon;
          const couponRemovedReasonCashOnlyCart =
            carts?.CASH_ONLY_ORDER?.couponRemovedReason;

          localStorage.setItem(
            `cartv2`,
            JSON.stringify({
              products: newProducts,
              appliedCouponCreditCart,
              appliedCouponCashOnlyCart,
            })
          );
          return (
            couponRemovedReasonCreditCart || couponRemovedReasonCashOnlyCart
          );
        }
        return null;
      });
    } else {
      console.log(`invalid quantity or sku`);
    }
  }

  static getMultiCartLive = () => {
    const farmerId = localStorage.getItem("farmerId");
    const url = `${config.backendUrl}onlineecommerceservice/v1/saathicart/?farmerId=${farmerId}`;
    const { axiosRequest, abortController } = HTTPService.makeXHRRequest(
      url,
      "GET",
      null,
      this.getHeaders()
    );
    const promise = farmerId
      ? new Promise((resolve, reject) => {
          axiosRequest
            .then((response) => {
              if (response?.data?.responseData)
                return resolve(_.omitBy(response.data.responseData, _.isNil));
              else return resolve({});
            })
            .catch((error) => {
              if (error?.message !== "canceled") {
                return resolve({});
              }
            });
        })
      : Promise.resolve({});

    return { axiosRequest: promise, abortController };
  };

  static getReturnProductsCart = () => {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }cartservice/v2/farmercart/?farmerId=${localStorage.getItem(
        `farmerId`
      )}&isReturnOrder=true`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: CartMock });
      });
    }
  };

  static updateReturnProductCart(products) {
    const data = {
      farmerId: Number(localStorage.getItem(`farmerId`)),
      source: localStorage.getItem(`source`),
      products,
      isReturnOrder: true,
    };
    const url = `${config.backendUrl}cartservice/v2/farmercart/`;
    return HTTPService.makeXHRRequest(url, "PUT", data, this.getHeaders());
  }

  /*As per [SAT-495](https://agrostar.atlassian.net/browse/SAT-495) we are to hide cart icon from web 
      if the client is android and app version is 16 or more*/
  static shouldShowCartIconBasedOnClient = () => {
    if (!AndroidService.checkIfAndroid()) return true;
    if (!AndroidService.getAndroidVersion()) return true;
    let { appVersionCode } = AndroidService.getAndroidVersion();
    if (
      (appVersionCode &&
        parseInt(appVersionCode) >=
          AndroidService.CART_HIDDEN_ANDROID_VERSION) ||
      localStorage.getItem("partnerStatus") === "INACTIVE"
    ) {
      return false;
    } else {
      return true;
    }
  };
}
