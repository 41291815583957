import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";

import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";

import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    overflow: "hidden",
    display: "block",
    borderRadius: "10px",
    // height: "auto !important",
  },
}));

function ScrollableBanners(props) {
  const classes = useStyles();
  const [navButton, setNavButton] = useState({
    style: {
      display: "none",
    },
  });

  useEffect(() => {
    if (props?.banners?.length > 1) {
      setNavButton({
        style: {
          display: "block",
        },
      });
    }
  }, []);
  const handleClickScrollableBanners = (index) => {
    const scrollableBannerClickedEventData = {
      bannerType: props?.banners[index]?.data?.type || "",
      position: index + 1 || "",
      skuList:
        props?.banners[index]?.data?.data?.sku?.toString() ||
        props?.banners[index]?.data?.data?.skus?.join(",") ||
        "",
      imageUrl: props?.banners[index]?.url || "",
      page: props?.page,
    };

    AnalyticsService.pushEvent(
      `Banner Clicked`,
      scrollableBannerClickedEventData
    );
  };

  return (
    <div>
      <Grid container direction={`row`} justifyContent={`center`} spacing={0}>
        <Grid item xs={12}>
          {props.banners && props.banners.length > 0 && (
            <>
              <Carousel
                swipe={true}
                // navButtonsAlwaysVisible={
                //   props?.banners?.length > 1 ? true : false
                // }
                navButtonsAlwaysVisible={false}
                animation={`slide`}
                fullHeightHover={true}
                interval={9000}
                duration={600}
                indicators={props?.banners?.length > 1 ? true : false}
                navButtonsProps={navButton}
              >
                {props.banners.map((scrollableBanner, index) => (
                  <div
                    key={index}
                    onClick={() => handleClickScrollableBanners(index)}
                  >
                    {scrollableBanner?.data?.type === `PRODUCT_DETAILS` && (
                      <>
                        {scrollableBanner?.data?.data?.sku ? (
                          <Link
                            to={{
                              pathname: `/products/${scrollableBanner.data.data.sku}`,
                              state: { from: window.location.pathname },
                            }}
                          >
                            <img
                              className={classes.img}
                              src={scrollableBanner.url}
                              alt={`banner`}
                              style={props?.blur ? { filter: props.blur } : {}}
                            />
                          </Link>
                        ) : (
                          <img
                            className={classes.img}
                            src={scrollableBanner.url}
                            alt={`banner`}
                            style={props?.blur ? { filter: props.blur } : {}}
                          />
                        )}
                      </>
                    )}

                    {scrollableBanner?.data?.type === `PRODUCT_LIST` && (
                      <>
                        {scrollableBanner?.data?.data?.skus ? (
                          <Link
                            to={`/products-offer/${scrollableBanner.data.data.skus}`}
                          >
                            <img
                              className={classes.img}
                              src={scrollableBanner.url}
                              alt={`banner`}
                              style={props?.blur ? { filter: props.blur } : {}}
                            />
                          </Link>
                        ) : (
                          <img
                            className={classes.img}
                            src={scrollableBanner.url}
                            alt={`banner`}
                            style={props?.blur ? { filter: props.blur } : {}}
                          />
                        )}
                      </>
                    )}

                    {scrollableBanner?.data?.type === "EXTERNAL_WEB_PAGE" && (
                      <>
                        {scrollableBanner?.data?.data?.url && (
                          <a href={scrollableBanner.data.data.url}>
                            <img
                              className={classes.img}
                              src={scrollableBanner.url}
                              alt={`banner`}
                              style={props?.blur ? { filter: props.blur } : {}}
                            />
                          </a>
                        )}
                      </>
                    )}

                    {scrollableBanner?.data?.type === `NO_CLICK` && (
                      <>
                        <img
                          className={classes.img}
                          src={scrollableBanner.url}
                          alt={`banner`}
                          style={props?.blur ? { filter: props.blur } : {}}
                        />
                      </>
                    )}
                  </div>
                ))}
              </Carousel>
            </>
          )}

          {props.loadingBanners === true && (
            <Skeleton variant="rectangular" height={290} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ScrollableBanners;
