import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Clamp from "react-multiline-clamp";
import BlockUi from "react-block-ui";
import moment from "moment";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { East, ExpandMore } from "@mui/icons-material";

import config from "../../config";
import TopBar from "../Common/TopBar";
import useQuery from "../../Hooks/useQuery";
import EmptyError from "../EmptyError/EmptyError";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import InterPartyTransfersService from "../../Services/InterPartyTransfersService";
import { format } from "date-fns";

const useStyles = makeStyles({
  removeButton: {
    position: "absolute",
    right: "16px",
    bottom: "8px",
    color: "#9D2123",
  },
  tableDataStyle: { fontSize: "12px", padding: "4px" },
  pageFixedBottom: {
    position: "fixed",
    bottom: 0,
    padding: "8px 16px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 700,
    background: "#9D2123",
  },
  submitButton: {
    color: "#fff",
    borderRadius: "10px",
    height: "40px",
  },
  continueRequestButton: {
    position: "fixed",
    bottom: 0,
    maxWidth: "600px",
    height: "50px",
    borderRadius: 0,
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "90%",
    padding: "24px",
    border: "none",
    textAlign: "center",
    background: "#fff",
    borderRadius: "32px",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  modalButton: {
    width: "100px",
    height: "45px",
    fontWeight: 700,
    marginTop: "12px",
    textTransform: "none",
    borderRadius: "16px",
  },
});

export default function ScannedProductsPage() {
  const classes = useStyles();
  const query = useQuery();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState("not_started");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [scannedProducts, setScannedProducts] = useState(null);

  useEffect(() => {
    setLoading("started");
    const { axiosRequest, abortController } =
      InterPartyTransfersService.getIPTCart(query.get(`cartId`));
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          let totalBarcodes = 0;
          const pageViewedProperties = {
            page: "Scanned Products",
            skuCodes: "",
            price: "",
            totalAmount: response?.data?.responseData?.grandTotal || "",
            totalProducts: response?.data?.responseData?.totalProducts || "",
          };
          response?.data?.responseData?.products?.map((product) => {
            pageViewedProperties["skuCodes"] += product?.skuCode + ", ";
            pageViewedProperties["price"] += product?.totalPrice + ", ";
            totalBarcodes += product?.quantity;
          });
          setTotalQuantity(totalBarcodes);
          setScannedProducts(response?.data?.responseData?.products);
          AnalyticsService.pushEvent(`Page Viewed`, pageViewedProperties);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.error("Failed to Get IPT Order success: ", error);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleSubmitRequest = () => {
    AnalyticsService.pushEvent(`Submit Button Clicked`, {
      page: "Scanned Products",
    });

    setLoading("started");
    const requesterId = JSON.parse(localStorage.getItem(`fieldAgentUserId`));
    const { axiosRequest } = InterPartyTransfersService.updateIPTStatus(
      "SUBMIT_IPT_REQUEST",
      Number(query.get(`iptRequestId`)),
      requesterId
    );
    axiosRequest
      .then((response) => {
        setLoading("done");
        if (response?.data?.responseData) {
          navigate(
            `/inter-party-transfers/success?orderId=${query.get(
              `iptRequestId`
            )}`,
            { replace: true }
          );
        }
      })
      .catch((error) => {
        setLoading("done");
        setOpenModal(true);
        console.error("Error Updating IPT Status: ", error);
      });
  };

  const handleOpenScannerCameraClick = (event) => {
    event?.stopPropagation();

    AndroidService.checkIfAndroid()
      ? AndroidService.openScannerCameraActivity(
          `${
            config.appUrl
          }/inter-party-transfers/barcode-scan-details?iptRequestId=${query.get(
            `iptRequestId`
          )}&cartId=${query.get(`cartId`)}&scanCode=`,
          `${
            config.appUrl
          }/inter-party-transfers/scanned-products?iptRequestId=${query.get(
            `iptRequestId`
          )}&cartId=${query.get(`cartId`)}`
        )
      : navigate(
          `/inter-party-transfers/barcode-scan-details?iptRequestId=${query.get(
            `iptRequestId`
          )}&cartId=${query.get(`cartId`)}&scanCode=`,
          { replace: true }
        );
  };

  const handleOpenManuallyAddRemoveProductsPage = (event) => {
    event?.stopPropagation();
    navigate(
      `/inter-party-transfers/manually-add-products?iptRequestId=${query.get(
        `iptRequestId`
      )}&cartId=${query.get(`cartId`)}`
    );
  };

  return (
    <>
      <TopBar title={`Scanned Products`} backNavigation={true} />

      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Loading...`}
        style={{ minHeight: "85vh" }}
        keepInView
      >
        {scannedProducts?.length ? (
          <>
            <Container maxWidth={`xl`} sx={{ p: 2, pb: 10 }}>
              {scannedProducts?.map((product, index) => (
                <Accordion
                  key={index}
                  sx={{
                    marginBottom: "16px",
                    boxShadow: "none",
                    borderRadius: "10px !important",
                    background: "#e3e3e3",
                    "&::before": { display: "none" },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: "12px 0 !important",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      padding: "12px 16px",
                      alignItems: "flex-start",
                      "& .MuiAccordionSummary-content": { margin: 0 },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "0 !important",
                      },
                    }}
                  >
                    <Grid container sx={{ mr: "4px" }}>
                      <Grid item xs={2}>
                        <img
                          src={product?.productImage}
                          alt="product"
                          width="100%"
                          style={{ borderRadius: "5px" }}
                        />
                      </Grid>
                      <Grid item xs={10} sx={{ pl: 1 }}>
                        <Clamp lines={2} maxLines={2}>
                          <Typography variant="subtitle2" fontWeight={400}>
                            {product?.productName}
                          </Typography>
                        </Clamp>

                        <Grid
                          container
                          sx={{ fontSize: "12px", color: "#4F4F4F" }}
                        >
                          <Grid item xs={7}>
                            Transfer Quantity -
                          </Grid>
                          <Grid item xs={5}>
                            {product?.quantity}
                          </Grid>
                          <Grid item xs={7}>
                            {product?.creditPeriodDate &&
                            product?.creditPeriodDate !== ""
                              ? "Due Date -"
                              : "Credit Period -"}
                          </Grid>
                          <Grid item xs={5}>
                            {product?.creditPeriodDate &&
                            product?.creditPeriodDate !== ""
                              ? format(
                                  new Date(product?.creditPeriodDate),
                                  "dd MMM yyyy"
                                )
                              : product?.creditPeriod}
                          </Grid>
                          <Grid item xs={7}>
                            Price per quantity -
                          </Grid>
                          <Grid item xs={5}>
                            ₹{product?.sellingPrice}
                          </Grid>
                          <Grid item xs={7}>
                            Total Amount -
                          </Grid>
                          <Grid item xs={5}>
                            ₹{product?.totalPrice}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Typography
                      variant="caption"
                      className={classes.removeButton}
                      onClick={(event) => {
                        handleOpenScannerCameraClick(event);
                        AnalyticsService.pushEvent(`Remove Product Clicked`, {
                          page: "Scanned Products",
                        });
                      }}
                    >
                      Remove
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: "1px solid #0000001a" }}>
                    <table style={{ width: "100%", textAlign: "left" }}>
                      <thead>
                        <tr>
                          <th className={classes.tableDataStyle}>LOT NO</th>
                          <th className={classes.tableDataStyle}>
                            EXPIRY DATE
                          </th>
                          <th className={classes.tableDataStyle}>QTY</th>
                        </tr>
                      </thead>
                      <tbody>
                        {product?.cartItemLotcodes?.map((item, index) => (
                          <tr key={index}>
                            <td className={classes.tableDataStyle}>
                              {item?.lotNumber}
                            </td>
                            <td className={classes.tableDataStyle}>
                              {moment(item?.codes?.[0]?.expiryDate).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td className={classes.tableDataStyle}>
                              {item?.codes?.length}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </Accordion>
              ))}

              <Button
                fullWidth
                variant="outlined"
                endIcon={<East />}
                sx={{
                  mt: 2,
                  background: "#fff",
                  "&:hover": { background: "#fff" },
                }}
                onClick={(event) => {
                  handleOpenScannerCameraClick(event);
                  AnalyticsService.pushEvent(`Add Product Clicked`, {
                    page: "Scanned Products",
                  });
                }}
              >
                Scan More Products
              </Button>
              <Button
                fullWidth
                variant="outlined"
                endIcon={<East />}
                sx={{
                  mt: 2,
                  background: "#fff",
                  "&:hover": { background: "#fff" },
                }}
                onClick={(event) => {
                  handleOpenManuallyAddRemoveProductsPage(event);
                  AnalyticsService.pushEvent(`Button Clicked`, {
                    type: "Add Manually",
                    page: "Scanned Products",
                  });
                }}
              >
                Add Manually
              </Button>
            </Container>

            <Grid
              container
              alignItems="center"
              className={classes.pageFixedBottom}
            >
              <Grid item xs={7} container>
                <Grid item xs={6}>
                  {scannedProducts?.length}
                </Grid>
                <Grid item xs={6}>
                  {totalQuantity}
                </Grid>
                <Grid item xs={6}>
                  Products
                </Grid>
                <Grid item xs={6}>
                  Quantity
                </Grid>
              </Grid>
              <Grid item xs={5} textAlign="right">
                <Button
                  fullWidth
                  className={classes.submitButton}
                  onClick={handleSubmitRequest}
                >
                  Submit Request
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          loading === "done" && (
            <>
              <Container maxWidth={`xl`} sx={{ padding: "16px" }}>
                <EmptyError text="No Products Scanned Yet." hideBack={true} />
              </Container>
              <Button
                fullWidth
                variant="contained"
                endIcon={<East />}
                onClick={(event) => {
                  handleOpenScannerCameraClick(event);
                  AnalyticsService.pushEvent(`Add Product Clicked`, {
                    page: "Scanned Products",
                  });
                }}
                className={classes.continueRequestButton}
              >
                Add Products
              </Button>
            </>
          )
        )}

        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <div className={classes.modalBox}>
            <img src="/icons/starBlack.svg" alt="star" />
            <Typography variant="h6" sx={{ color: "#9D2123" }}>
              Opsssss!!!
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Something went wrong, Please try again later !
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.modalButton}
              onClick={() => setOpenModal(false)}
            >
              Okay
            </Button>
          </div>
        </Modal>
      </BlockUi>
    </>
  );
}
