import React from "react";
import moment from "moment";
import Clamp from "react-multiline-clamp";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DoneAll, ExpandMore, Loop } from "@mui/icons-material";
import AmountUtils from "../../Utils/AmountUtils";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles({
  accordian: {
    margin: "0 0 8px !important",
    borderRadius: "10px !important",
    boxShadow: "0px 12px 24px rgba(28, 28, 28, 0.10)",
    "&::before": { display: "none" },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0 !important",
    },
  },
  accordianSummary: {
    padding: "12px 16px",
    alignItems: "flex-start",
    borderBottom: "1px solid #eee",
    "& .MuiAccordionSummary-content": {
      margin: 0,
      flexDirection: "column",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 !important",
    },
  },
  orderStatus: {
    position: "relative",
    fontSize: "12px",
    borderRadius: "5px",
    padding: "5px 7px",
    textTransform: "capitalize",
  },
  orderDetails: {
    paddingTop: "10px",
    "& .MuiTypography-subtitle2": { fontWeight: 400 },
    "& b": { fontSize: "16px", color: "#00733E" },
  },
  callLMDWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    "& a": { background: "#e5f1ec", "&:hover": { background: "#e5f1ec" } },
  },
  deliveryFee: { position: "relative", top: "-5px", color: "#00733E" },
  lineDivider: {
    position: "absolute",
    left: 0,
    bottom: "40px",
    borderTop: "1px solid #eee",
    width: "100%",
  },
  productWrapper: {
    padding: "8px 16px",
    marginBottom: "5px",
    fontSize: "12px",
    fontWeight: 400,
    background: "#cacaca1a",
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #CACACA",
  },
  productTotalAmount: {
    textAlign: "right",
    color: "#00733E",
    fontSize: "14px",
    fontWeight: 700,
  },
});

const orderStatus = {
  CREATED: {
    color: "#a56f00",
    bgColor: "#efe5ce",
    text: "Created",
    icon: (
      <img
        src="/icons/created.svg"
        alt=""
        width="16px"
        style={{ marginRight: "5px", position: "relative", top: "3px" }}
      />
    ),
  },
  PENDING: {
    color: "#a56f00",
    bgColor: "#efe5ce",
    text: "Pending",
    icon: (
      <Loop
        sx={{
          width: "18px",
          position: "relative",
          top: "7px",
          marginRight: "5px",
          color: "#a56f00",
        }}
      />
    ),
  },
  CANCELLED: {
    color: "#9e2123",
    bgColor: "#ebd3d4",
    text: "Cancelled",
    icon: (
      <img
        src="/icons/statusCancel.svg"
        alt=""
        width="16px"
        style={{ marginRight: "5px", position: "relative", top: "3px" }}
      />
    ),
  },
  DISPATCHED: {
    color: "#a56f00",
    bgColor: "#efe5ce",
    text: "Confirmed",
    icon: (
      <img
        src="/icons/dispatch.svg"
        alt=""
        width="16px"
        style={{ marginRight: "5px", position: "relative", top: "3px" }}
      />
    ),
  },
  PICKED_BY_LMD: {
    color: "#00733e",
    bgColor: "#cce3d8",
    text: "Dispatched",
    icon: (
      <img
        src="/icons/pickedByLMD.svg"
        alt=""
        width="16px"
        style={{ marginRight: "5px", position: "relative", top: "3px" }}
      />
    ),
  },
  SHIPPED: {
    color: "#00733e",
    bgColor: "#cce3d8",
    text: "Shipped",
    icon: (
      <DoneAll
        sx={{
          width: "18px",
          position: "relative",
          top: "7px",
          marginRight: "5px",
          color: "#00733e",
        }}
      />
    ),
  },
  DELIVERED: {
    color: "#00733e",
    bgColor: "#cce3d8",
    text: "Delivered",
    icon: (
      <img
        src="/icons/delivered.svg"
        alt=""
        width="16px"
        style={{ marginRight: "5px", position: "relative", top: "3px" }}
      />
    ),
  },
};

export default function OrderCard({ newOrder, showCTA }) {
  const classes = useStyles();

  return (
    <Accordion key={newOrder?.id} className={classes.accordian}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordianSummary}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              Order ID: {newOrder?.id}
            </Typography>
            <Typography variant="caption">
              {moment(newOrder?.createdOn).format("DD MMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <span
              className={classes.orderStatus}
              style={{
                color: orderStatus[newOrder?.unicommerceStatus]?.color,
                background: orderStatus[newOrder?.unicommerceStatus]?.bgColor,
              }}
            >
              {orderStatus[newOrder?.unicommerceStatus]?.icon}
              {orderStatus[newOrder?.unicommerceStatus]?.text}
            </span>
          </Grid>
        </Grid>

        <div className={classes.orderDetails}>
          <Typography variant="subtitle2">
            Order Amount : <b>₹{AmountUtils.format(newOrder?.orderAmount)}</b>
          </Typography>
          <div className={classes.lineDivider}></div>
        </div>
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 0, paddingBottom: "3px" }}>
        <Grid
          container
          sx={{
            padding: "8px 16px",
            fontSize: "12px",
            background: "#00733E1F",
          }}
        >
          <Grid container sx={{ margin: "2px 0px" }}>
            <Grid item xs={8}>
              Total Amount
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right", fontSize: "14px" }}>
              {AmountUtils.formatCurrency(newOrder?.grandTotal)}
            </Grid>
          </Grid>
          <Grid container sx={{ margin: "2px 0px" }}>
            <Grid item xs={8}>
              - Payable Against Outstanding
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right", fontSize: "14px" }}>
              {AmountUtils.formatCurrency(newOrder?.payableOutstanding)}
            </Grid>
          </Grid>
          <Grid container sx={{ margin: "2px 0px" }}>
            <Grid item xs={8}>
              - Payable in AP
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right", fontSize: "14px" }}>
              {AmountUtils.formatCurrency(newOrder?.payableAP)}
            </Grid>
          </Grid>
          <Grid container sx={{ margin: "2px 0px" }}>
            <Grid item xs={8}>
              Delivery & Platform Fee
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ textAlign: "right", color: "#9D2123", fontSize: "14px" }}
            >
              - {AmountUtils.formatCurrency(newOrder?.totalCharges)}
            </Grid>
          </Grid>
          <Grid container sx={{ margin: "2px 0px", fontWeight: "700" }}>
            <Grid item xs={8}>
              Net Amount
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                textAlign: "right",
                color: "#00733E",
                fontSize: "14px",
              }}
            >
              {AmountUtils.formatCurrency(newOrder?.netTotal)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "8px 16px" }}>
          <Grid item sx={{ fontWeight: "700", fontSize: "12px" }}>
            Order Items
          </Grid>
        </Grid>
        {newOrder?.products?.map((product, index) => (
          <Grid container key={index} className={classes.productWrapper}>
            <Grid item xs={2}>
              <div className={classes.imageWrapper}>
                <img
                  src={product?.productImage?.url || "/icons/dummy.jpeg"}
                  alt="product"
                  width="100%"
                  style={{ borderRadius: "4px" }}
                />
              </div>
            </Grid>
            <Grid item xs={10} container sx={{ pl: 1 }}>
              <Grid item xs={8}>
                <Clamp lines={2} maxLines={2}>
                  <span>{product?.productName}</span>
                </Clamp>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                Qty: <b>{product?.quantity}</b>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  textAlign: "right",
                  color: "#00733E",
                }}
              >
                {AmountUtils.formatCurrency(product?.totalPrice)}
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid container sx={{ padding: "8px 16px" }}>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
              LMD Partner
            </Typography>
            <Clamp lines={1} maxLines={1}>
              <Typography
                variant="subtitle2"
                fontWeight={700}
                sx={{ fontSize: "12px" }}
              >
                {newOrder?.lmdPartner?.name}
              </Typography>
            </Clamp>
          </Grid>
          <Grid item xs={4} className={classes.callLMDWrapper}>
            {showCTA && newOrder?.lmdPartner?.mobileNumber ? (
              <IconButton
                href={`tel:${newOrder?.lmdPartner?.mobileNumber}`}
                onClick={() =>
                  AnalyticsService.pushEvent("Button Clicked", {
                    type: "Call Button",
                  })
                }
              >
                <img src="/icons/callIcon.svg" alt="call" />
              </IconButton>
            ) : (
              ""
            )}
          </Grid>
          {newOrder?.shippingAddress?.farmerName && (
            <Grid item xs={6} sx={{ marginTop: "6px" }}>
              <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                Farmer Name
              </Typography>
              <Clamp lines={1} maxLines={1}>
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ fontSize: "12px" }}
                >
                  {newOrder?.shippingAddress?.farmerName}
                </Typography>
              </Clamp>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
