import React, { useEffect, useRef, useState } from "react";
import ErrorBoundary from "../Common/ErrorBoundary";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import EastIcon from "@mui/icons-material/East";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";
import FooterImageLogo from "../Common/FooterImageLogo";
import Backdrop from "@mui/material/Backdrop";
import NeedHelp from "../Common/NeedHelp";
import TopBar from "../Common/TopBar";
import MiscUtils from "../../Utils/MiscUtils";
import LeegalityService from "../../Services/LeegalityService";

export default function AgreementDetails(props) {
  const [shopOwnerName, setShopOwnerName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [emailId, setEmailId] = useState("");
  const [shopName, setShopName] = useState("");
  const [businessType, setBusinessType] = React.useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [loading, setLoading] = useState("not-started");
  const [partnershipDetails, setPartnershipDetails] = useState([
    { name: "", address: "", isRequired: true },
    { name: "", address: "", isRequired: true },
  ]);
  const [proprietorAddress, setProprietorAddress] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyRepresentativeName, setCompanyRepresentativeName] =
    useState("");
  const [isSecurityDepositPaid, setIsSecurityDepositPaid] = useState(false);
  const [securityDepositAmount, setSecurityDepositAmount] = useState("");
  const errorMessageRef = useRef();

  const handleChangeShopOwnerName = (event) => {
    setShopOwnerName(event.target.value);
  };
  const handleChangeEmailId = (event) => {
    setEmailId(event.target.value);
  };
  const handleChangeShopName = (event) => {
    setShopName(event.target.value);
  };
  const handleChangeBusinessType = (event) => {
    setBusinessType(event.target.value);
    if (event.target.value === "Partnership" && shopOwnerName) {
      setPartnershipDetails((prevDetails) => {
        const updatedDetails = [
          {
            ...prevDetails[0],
            name: shopOwnerName,
          },
          ...prevDetails.slice(1),
        ];
        return updatedDetails;
      });
    }
  };
  const handleChangeShopAddress = (event) => {
    setShopAddress(event.target.value);
  };
  const handleChangePartnershipDetails = (event, index, updateType) => {
    const updatedPartnershipDetails = [...partnershipDetails];
    if (updateType === "name") {
      updatedPartnershipDetails[index].name = event?.target?.value;
    }
    if (updateType === "address") {
      updatedPartnershipDetails[index].address = event?.target?.value;
    }
    setPartnershipDetails(updatedPartnershipDetails);
  };
  const handleChangeProprietorAddress = (event) => {
    setProprietorAddress(event?.target?.value);
  };
  const handleChangeCompanyAddress = (event) => {
    setCompanyAddress(event?.target?.value);
  };
  const handleChangeCompanyRepresentativeName = (event) => {
    setCompanyRepresentativeName(event?.target?.value);
  };
  const handleChangeSecurityDepositAmount = (event) => {
    const value = event?.target?.value;
    const input = event.target;
    if (value === "") {
      setSecurityDepositAmount("");
      input.setCustomValidity("");
      input.reportValidity();
      return;
    }
    const parsedValue = parseInt(value);
    if (parsedValue <= 0) {
      input.setCustomValidity("Amount cannot be less than or equal to zero");
    } else if (MiscUtils.checkIfIntegerInput(parsedValue)) {
      setSecurityDepositAmount(parsedValue);
      input.setCustomValidity("");
    } else {
      input.setCustomValidity("Please enter a valid number");
    }
    input.reportValidity();
  };

  useEffect(() => {
    AnalyticsService.pushEvent("Page Viewed", {
      page: "Fill Agreement Details",
    });
  }, []);

  useEffect(() => {
    const farmerId = localStorage.getItem("farmerId");
    setLoading("started");
    const { axiosRequest, abortController } =
      LeegalityService.getInstitutionMetaInfo(farmerId);
    axiosRequest
      .then((institutionMetaDetailsResponse) => {
        const institutionMetaDetails =
          institutionMetaDetailsResponse?.data?.responseData;
        if (institutionMetaDetails?.storeOwnerName) {
          setShopOwnerName(institutionMetaDetails?.storeOwnerName);
        }
        if (institutionMetaDetails?.emailId) {
          setEmailId(institutionMetaDetails?.emailId);
        }
        if (institutionMetaDetails?.storeName) {
          setShopName(institutionMetaDetails?.storeName);
        }
        if (institutionMetaDetails?.businessType) {
          setBusinessType(institutionMetaDetails?.businessType);
        }
        if (institutionMetaDetails?.storeOwnerAddress) {
          setShopAddress(institutionMetaDetails?.storeOwnerAddress);
        }
        if (institutionMetaDetails?.businessTypeMetaInformation) {
          if (institutionMetaDetails?.businessType === "Partnership") {
            setPartnershipDetails(
              institutionMetaDetails?.businessTypeMetaInformation
            );
          } else if (
            institutionMetaDetails?.businessType === "Proprietorship"
          ) {
            setProprietorAddress(
              institutionMetaDetails?.businessTypeMetaInformation?.[0]?.address
            );
          } else if (
            institutionMetaDetails?.businessType === "Private Limited"
          ) {
            setCompanyRepresentativeName(
              institutionMetaDetails?.businessTypeMetaInformation?.[0]?.name
            );
            setCompanyAddress(
              institutionMetaDetails?.businessTypeMetaInformation?.[0]?.address
            );
          } else {
          }
        } else {
          if (institutionMetaDetails?.businessType === "Partnership") {
            setPartnershipDetails((prevDetails) => {
              const updatedDetails = [
                {
                  ...prevDetails[0],
                  name: institutionMetaDetails?.storeOwnerName,
                },
                ...prevDetails.slice(1),
              ];
              return updatedDetails;
            });
          }
        }
        if (institutionMetaDetails?.securityDepositAmount) {
          setSecurityDepositAmount(
            institutionMetaDetails?.securityDepositAmount
          );
        }
        if (institutionMetaDetails?.securityDepositPaid === true) {
          setIsSecurityDepositPaid(institutionMetaDetails?.securityDepositPaid);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, []);

  const createRequestPayload = () => {
    let requestPayload = {
      storeOwnerName: shopOwnerName,
      storeOwnerAddress: shopAddress,
      storeName: shopName,
      emailId: emailId?.trim(),
      businessType: businessType,
      securityDepositAmount: securityDepositAmount,
      securityDepositPaid: isSecurityDepositPaid,
    };

    if (businessType === "Partnership") {
      requestPayload = {
        ...requestPayload,
        businessTypeMetaInformation: partnershipDetails,
      };
    } else if (businessType === "Proprietorship") {
      requestPayload = {
        ...requestPayload,
        businessTypeMetaInformation: [
          {
            address: proprietorAddress,
          },
        ],
      };
    } else if (businessType === "Private Limited") {
      requestPayload = {
        ...requestPayload,
        businessTypeMetaInformation: [
          {
            name: companyRepresentativeName,
            address: companyAddress,
          },
        ],
      };
    } else {
    }
    return requestPayload;
  };

  const handleSubmit = (event) => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Submit Agreement Details",
      page: "Fill Agreement Details",
    });
    event.preventDefault();
    setErrorCode("");
    setErrorMessage("");

    if (
      !MiscUtils.checkIfValidEmail(emailId) ||
      _.endsWith(_.toLower(emailId), "agrostar.in")
    ) {
      setErrorCode("INVALID_EMAIL_ID");
      setErrorMessage("Please enter a valid Email ID & try again.");
      errorMessageRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return;
    }

    setLoading("started");
    let requestPayload = createRequestPayload();
    const { axiosRequest } =
      LeegalityService.editDetailsForAgreementSignRequest(
        localStorage.getItem("farmerId"),
        requestPayload
      );
    axiosRequest
      .then(() => {
        const { axiosRequest } = LeegalityService.createAgreementSignRequest(
          localStorage.getItem("farmerId")
        );
        axiosRequest
          .then((response) => {
            if (response?.data?.responseData?.invitees?.[0]?.signUrl) {
              window.location.href =
                response?.data?.responseData?.invitees?.[0]?.signUrl;
            } else {
              setErrorMessage(
                "Sorry, something went wrong. Please try again in sometime."
              );
              setErrorCode("AGREEMENT_REQUEST_INVALID_URL");
              errorMessageRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
            setLoading("done");
          })
          .catch((error) => {
            if (error?.message !== "canceled") {
              setLoading("done");
              setErrorMessage(
                "Sorry, something went wrong. Please try again in sometime."
              );
              setErrorCode("AGREEMENT_REQUEST_CREATION_FAILED");
              errorMessageRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          });
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          const errorData = error?.response?.data?.responseData?.message;
          if (errorData && !_.isEmpty(errorData)) {
            setErrorMessage(errorData);
          } else {
            setErrorMessage(
              "Sorry, something went wrong. Please try again in sometime."
            );
          }
          setLoading("done");
          setErrorCode("EDIT_AGREEMENT_DETAILS_FAILED");
          errorMessageRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
  };

  return (
    <ErrorBoundary>
      <ErrorBoundary message={``}>
        <TopBar
          title={"Digital Agreement"}
          backNavigation={true}
          showCart={false}
          showHisaab={false}
        />
      </ErrorBoundary>
      <Box sx={{ backgroundColor: `background.main` }}>
        <form onSubmit={handleSubmit}>
          <Container maxWidth={`xl`} sx={{ paddingTop: "16px" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
              Please fill in the details for agreement
            </Typography>
            <Box sx={{ marginTop: 2, marginBottom: 5 }}>
              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Shop Owner Name *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  placeholder="Enter Here"
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    pattern: `[A-Za-z0-9 ]{2,100}`,
                    title: `e.g. Prakash Patil`,
                    autoComplete: `name`,
                  }}
                  type={`text`}
                  value={shopOwnerName}
                  onChange={handleChangeShopOwnerName}
                  required={true}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Email ID *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    title: `e.g. prakash@gmail.com`,

                    autoComplete: `email`,
                  }}
                  type={`email`}
                  value={emailId}
                  onChange={handleChangeEmailId}
                  required={true}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Shop Name (As per GST) *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    pattern: `[A-Za-z0-9 ]{2,100}`,
                    title: `e.g. Mauli Krishi Kendra`,
                    autoComplete: `organization`,
                  }}
                  type={`text`}
                  value={shopName}
                  onChange={handleChangeShopName}
                  required={true}
                  fullWidth
                />
              </Box>

              {!isSecurityDepositPaid && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", fontSize: 14 }}
                  >
                    Security Deposit Amount *
                  </Typography>

                  <TextField
                    variant={`outlined`}
                    size={`small`}
                    placeholder="Enter Here"
                    sx={{
                      marginTop: 1.25,
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                      },
                    }}
                    inputProps={{
                      sx: {
                        backgroundColor: "#ffffff",
                        borderRadius: 1.25,
                      },
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      title: "Enter a valid number",
                      autoComplete: "off",
                    }}
                    type={`text`}
                    value={securityDepositAmount}
                    onChange={handleChangeSecurityDepositAmount}
                    required={true}
                    fullWidth
                  />
                </Box>
              )}

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Business Type *`}
                </Typography>
                <Select
                  variant={`outlined`}
                  size={`small`}
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    type: "select",
                    title: "e.g. Proprietorship",
                  }}
                  required={true}
                  value={businessType}
                  onChange={handleChangeBusinessType}
                  fullWidth
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <>Select Business Type</>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem disabled value="">
                    Select Business Type
                  </MenuItem>
                  {_.map(
                    PartnerService.businessTypes,
                    (businessType, index) => {
                      return (
                        <MenuItem value={businessType} key={index}>
                          {businessType}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant={`body1`}
                  sx={{ fontWeight: "600", fontSize: 14 }}
                >
                  {`Shop Address *`}
                </Typography>

                <TextField
                  variant={`outlined`}
                  size={`small`}
                  placeholder="Enter Here"
                  sx={{
                    marginTop: 1.25,
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: "#ffffff",
                      borderRadius: 1.25,
                    },
                    minLength: 2,
                    maxLength: 100,
                    title: `e.g. A-1, First Floor, Pune, Maharashtra - 411036`,
                    autoComplete: `address`,
                  }}
                  type={`text`}
                  value={shopAddress}
                  onChange={handleChangeShopAddress}
                  required={true}
                  fullWidth
                />
              </Box>

              {businessType === "Partnership" && (
                <>
                  {_.map(partnershipDetails, (partnership, index) => (
                    <Box key={index}>
                      <Box sx={{ marginTop: 2 }}>
                        <Typography
                          variant={`body1`}
                          sx={{ fontWeight: "600", fontSize: 14 }}
                        >
                          {`Partner ${index + 1} Name ${partnership?.isRequired ? `*` : ``}`}
                        </Typography>

                        <TextField
                          variant={`outlined`}
                          size={`small`}
                          sx={{
                            marginTop: 1.25,
                            "& fieldset": {
                              borderColor: "#FFFFFF",
                            },
                          }}
                          placeholder="Enter Here"
                          inputProps={{
                            sx: {
                              backgroundColor: "#ffffff",
                              borderRadius: 1.25,
                            },
                            minLength: 2,
                            maxLength: 100,
                            pattern: `[A-Za-z0-9 ]{2,100}`,
                            title: `e.g. Prakash Patil`,
                            autoComplete: `name`,
                          }}
                          type={`text`}
                          value={partnership?.name}
                          onChange={(event) =>
                            handleChangePartnershipDetails(event, index, "name")
                          }
                          required={partnership?.isRequired ? true : false}
                          fullWidth
                        />
                      </Box>
                      <Box sx={{ marginTop: 2 }}>
                        <Typography
                          variant={`body1`}
                          sx={{ fontWeight: "600", fontSize: 14 }}
                        >
                          {`Partner ${index + 1} Address (As per Aadhar) ${partnership?.isRequired ? `*` : ``}`}
                        </Typography>

                        <TextField
                          variant={`outlined`}
                          size={`small`}
                          placeholder="Enter Here"
                          sx={{
                            marginTop: 1.25,
                            "& fieldset": {
                              borderColor: "#FFFFFF",
                            },
                          }}
                          inputProps={{
                            sx: {
                              backgroundColor: "#ffffff",
                              borderRadius: 1.25,
                            },
                            minLength: 2,
                            maxLength: 100,
                            title: `e.g. A-1, First Floor`,
                            autoComplete: `address`,
                          }}
                          type={`text`}
                          value={partnership?.address}
                          onChange={(event) =>
                            handleChangePartnershipDetails(
                              event,
                              index,
                              "address"
                            )
                          }
                          required={partnership?.isRequired ? true : false}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  ))}
                </>
              )}
              {businessType === "Proprietorship" && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    variant={`body1`}
                    sx={{ fontWeight: "600", fontSize: 14 }}
                  >
                    {`Proprietor Address (As per Aadhar) *`}
                  </Typography>

                  <TextField
                    variant={`outlined`}
                    size={`small`}
                    placeholder="Enter Here"
                    sx={{
                      marginTop: 1.25,
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                      },
                    }}
                    inputProps={{
                      sx: {
                        backgroundColor: "#ffffff",
                        borderRadius: 1.25,
                      },
                      minLength: 2,
                      maxLength: 100,
                      title: `e.g. A-1, First Floor`,
                      autoComplete: `address`,
                    }}
                    type={`text`}
                    value={proprietorAddress}
                    onChange={handleChangeProprietorAddress}
                    required={true}
                    fullWidth
                  />
                </Box>
              )}
              {businessType === "Private Limited" && (
                <>
                  <Box sx={{ marginTop: 2 }}>
                    <Typography
                      variant={`body1`}
                      sx={{ fontWeight: "600", fontSize: 14 }}
                    >
                      {`Registered Address of Company *`}
                    </Typography>

                    <TextField
                      variant={`outlined`}
                      size={`small`}
                      placeholder="Enter Here"
                      sx={{
                        marginTop: 1.25,
                        "& fieldset": {
                          borderColor: "#FFFFFF",
                        },
                      }}
                      inputProps={{
                        sx: {
                          backgroundColor: "#ffffff",
                          borderRadius: 1.25,
                        },
                        minLength: 2,
                        maxLength: 100,
                        title: `e.g. A-1, First Floor`,
                        autoComplete: `address`,
                      }}
                      type={`text`}
                      value={companyAddress}
                      onChange={handleChangeCompanyAddress}
                      required={true}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ marginTop: 2 }}>
                    <Typography
                      variant={`body1`}
                      sx={{ fontWeight: "600", fontSize: 14 }}
                    >
                      {`Name of Director/Authorized Representative *`}
                    </Typography>

                    <TextField
                      variant={`outlined`}
                      size={`small`}
                      sx={{
                        marginTop: 1.25,
                        "& fieldset": {
                          borderColor: "#FFFFFF",
                        },
                      }}
                      placeholder="Enter Here"
                      inputProps={{
                        sx: {
                          backgroundColor: "#ffffff",
                          borderRadius: 1.25,
                        },
                        minLength: 2,
                        maxLength: 100,
                        pattern: `[A-Za-z0-9 ]{2,100}`,
                        title: `e.g. Prakash Patil`,
                        autoComplete: `name`,
                      }}
                      type={`text`}
                      value={companyRepresentativeName}
                      onChange={handleChangeCompanyRepresentativeName}
                      required={true}
                      fullWidth
                    />
                  </Box>
                </>
              )}

              <Typography sx={{ pt: 2, color: "#666666", fontSize: "12px" }}>
                All fields marked with an asterisk (*) are required
              </Typography>
            </Box>

            <Box ref={errorMessageRef}>
              {errorMessage && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    variant={`body2`}
                    fontWeight={600}
                    color={`primary`}
                  >
                    Error: {errorMessage}
                    <br />
                  </Typography>
                </Box>
              )}
            </Box>

            {errorCode && (
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  variant={`caption`}
                  sx={{ color: "#222222", opacity: 0.3 }}
                >
                  {errorCode}
                  <br />
                </Typography>
              </Box>
            )}
          </Container>

          <FooterImageLogo />

          <Box
            sx={(theme) => ({
              bottom: 0,
              position: "fixed",
              width: "100%",
              marginTop: 2,
              [theme.breakpoints.up("md")]: {
                maxWidth: "600px",
              },
            })}
          >
            <Button
              fullWidth={true}
              variant={`contained`}
              size={"large"}
              type={`submit`}
              sx={{ borderRadius: "0px" }}
              endIcon={<EastIcon fontSize="medium" />}
            >
              Continue
            </Button>
          </Box>
        </form>
      </Box>

      <Backdrop sx={{}} open={loading === "started"} onClick={() => {}}>
        <CircularProgress sx={{ color: "#FFFFFF" }} />
      </Backdrop>

      <NeedHelp title={`Need Help`} page={`Fill Partner Details`} />
    </ErrorBoundary>
  );
}
