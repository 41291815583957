import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useEffect, useRef, useState } from "react";
import "./CustomDateRangeStyles.css";
import { useLocation } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";
import { Box } from "@mui/material";

const CustomDateRange = (props) => {
  let location = useLocation();
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    document.addEventListener("click", handleHideOnClickOutside, true);
  }, []);

  const handleHideOnClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleDateRangeChange = (dateRangeObject) => {
    setDateRange([dateRangeObject.selection]);

    if (clickCount === 0) {
      // First click (date range start selected)
      setClickCount(1);
    } else {
      // Second click (date range end selected)
      setClickCount(0);
      setOpen(false);
      props?.setSelectedDateRangeFilter([
        dateRangeObject?.selection?.startDate,
        dateRangeObject?.selection?.endDate,
      ]);
    }
  };

  return (
    <>
      <Box className="calendarWrapper">
        <input
          style={{
            textAlign: "center",
            background: `${!props?.selectedDateRangeFilter ? `` : `#FFFFFF`}`,
            color: `${!props?.selectedDateRangeFilter ? `` : `#9D2123`}`,
            border: `${
              !props?.selectedDateRangeFilter ? `` : `1px solid #9D2123`
            }`,
            padding: `${
              !props?.selectedDateRangeFilter ? "8px 10px" : "7px 10px"
            }`,
          }}
          size={
            !props?.selectedDateRangeFilter ? props?.filterLabel?.length : 20
          }
          value={
            props?.selectedDateRangeFilter
              ? `${
                  props?.selectedDateRangeFilter?.[0]
                    ? format(props?.selectedDateRangeFilter?.[0], "dd-MMM-yy")
                    : ``
                } to ${
                  props?.selectedDateRangeFilter?.[1]
                    ? format(props?.selectedDateRangeFilter?.[1], "dd-MMM-yy")
                    : ``
                }`
              : `${props?.filterLabel ? props?.filterLabel : ``}`
          }
          readOnly
          className="dateRangeInputBox"
          onClick={() => {
            AnalyticsService.pushEvent(`Filter Clicked`, {
              page: props?.page ? props?.page : location?.pathname,
              filterLabel: props?.filterLabel || "",
            });
            if (!props?.selectedDateRangeFilter) {
              setDateRange([
                {
                  startDate: new Date(),
                  endDate: new Date(),
                  key: "selection",
                },
              ]);
            } else {
              setDateRange([
                {
                  startDate: props?.selectedDateRangeFilter?.[0],
                  endDate: props?.selectedDateRangeFilter?.[1],
                  key: "selection",
                },
              ]);
            }
            setOpen((open) => !open);
          }}
        />
        <Box ref={inputRef}>
          {open && (
            <DateRange
              onChange={(dateRangeObject) =>
                handleDateRangeChange(dateRangeObject)
              }
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              months={1}
              direction="horizontal"
              className="calendarElement"
              rangeColors={["#9D2123"]}
              maxDate={props?.maxDate}
              minDate={props?.minDate}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default CustomDateRange;
