import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Typography, CardContent, Box } from "@mui/material";
import AnalyticsService from "../../Services/AnalyticsService";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import useMessages from "../../Hooks/useMessages";
import Languages from "../../Services/LanguageCode";

export default function ComplaintSuccessfulDialog() {
  let navigate = useNavigate();
  const { ticketId } = useParams();
  const { getMessage } = useMessages();
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const language = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "en";

  let regionalTicketMessages = {
    hi: `आपकी समस्या (ID - ${ticketId}) सफलतापूर्वक दर्ज कर ली गई है`,
    en: `Your Samasya (ID - ${ticketId}) has been successfully registered`,
    gu: `તમારી સમસ્યા (ID - ${ticketId}) સફળતાપૂર્વક નોંધવામાં આવી છે`,
    mr: `तुमची समस्या (ID - ${ticketId}) यशस्वीरित्या नोंदवण्यात आली आहे`,
  };

  useEffect(() => {
    const pageViewedEventData = {
      page: `Complaints successful`,
    };
    AnalyticsService.pushEvent(`Page Viewed`, pageViewedEventData);
    setConfirmationMessage(regionalTicketMessages[language]);
    if (!localStorage.getItem("createComplaintInitiated")) {
      navigate(-1);
    }
    localStorage.removeItem("createComplaintInitiated");
  }, []);

  const handleClickTicketsHistory = () => {
    const redirectToComplaintHistoryPageEventData = {
      page: `Complaints successful`,
      ticketId: ticketId,
      buttonTitle: "Go To Your Tickets",
      module: "Samasya",
    };

    AnalyticsService.pushEvent(
      `Success Page Button Clicked`,
      redirectToComplaintHistoryPageEventData
    );
    if (localStorage.getItem("createComplaintFromProfilePage")) {
      navigate("/complaints/history");
    } else {
      navigate(-1);
    }
  };

  return (
    <CardContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "96vh",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              height: "230px",
            }}
          >
            <img
              src={`/icons/success.svg`}
              alt=""
              style={{
                position: "fixed",
              }}
            />
          </Box>
          <Box
            sx={{
              height: "75px",
              width: "75%",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "25px",
              }}
            >
              {confirmationMessage}
            </Typography>
          </Box>
        </Box>
        <Button
          fullWidth
          variant={`contained`}
          sx={{
            borderRadius: "10px",
            height: "45px",
            letterSpacing: "0.04em",
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "14px",
          }}
          onClick={handleClickTicketsHistory}
        >
          {getMessage("createComplaint.redirectToTicketsButtonTitle")}{" "}
          <ArrowForwardTwoToneIcon />
        </Button>
      </Box>
    </CardContent>
  );
}
