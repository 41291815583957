import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProductWithOrderCount from "./ProductWithOrderCount";
import _ from "lodash";
import format from "date-fns/format";
import AmountUtils from "../../Utils/AmountUtils";

const useStyles = makeStyles({
  dayAndSaleAmountContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "18px 14px 6px",
  },
  dateText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#4F4F4F",
  },
  missedSalesAmount: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#9D2123",
  },
});

export default function MissedSalesWithDate({ missedSalesWithDate }) {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.dayAndSaleAmountContainer}>
        <Typography className={classes.dateText}>
          {format(missedSalesWithDate.date, "dd MMM yyyy")}
        </Typography>
        <Typography className={classes.missedSalesAmount}>
          Missed Sale: ₹{AmountUtils.format(missedSalesWithDate.saleAmount)}
        </Typography>
      </Box>
      {missedSalesWithDate.products?.length > 0 &&
        _.map(missedSalesWithDate.products, (product, index) => {
          return (
            <ProductWithOrderCount key={product.skuCode} product={product} />
          );
        })}
    </Box>
  );
}
