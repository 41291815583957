import React, { useState, useEffect } from "react";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";

import { Grid, Typography, Card, CardContent, Container } from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import SectionTitle from "../Common/SectionTitle";
import Slider from "@mui/material/Slider";
import Skeleton from "@mui/material/Skeleton";
import ProductService from "../../Services/ProductService";
import PartnerService from "../../Services/PartnerService";

const CashDiscountBenefitContainer = (props) => {
  let [discountBenefits, setDiscountBenefits] = useState(null);
  let [sliderMarks, setSliderMarks] = useState(null);
  let [activeSliderMarkIndex, setActiveSliderMarkIndex] = useState(0);
  const [discountBenefitForPaymentPeriod, setDiscountBenefitForPaymentPeriod] =
    useState(null);

  const getPositiveDiscountBenefitsWithFirstNegativeBenefit = (
    discountBenefits
  ) => {
    let positiveDiscountBenefits = [];
    let negativeDiscountBenefits = [];
    let firstNegativeBenefit = null;
    _.forEach(discountBenefits, (benefit) => {
      if (benefit?.benefitAmount >= 0) {
        positiveDiscountBenefits.push(benefit);
      } else {
        negativeDiscountBenefits.push(benefit);
      }
    });
    if (negativeDiscountBenefits.length > 0) {
      firstNegativeBenefit = negativeDiscountBenefits[0];
    }

    return _.concat(positiveDiscountBenefits, firstNegativeBenefit);
  };
  const generateSliderMarks = (discountBenefits) => {
    let generatedSliderMarks = [];
    _.forEach(discountBenefits, (benefit, index) => {
      let sliderMarkItem = {};
      if (benefit?.paymentPeriod) {
        //@TODO revisit this logic

        //
        if (benefit?.paymentPeriodEndDay) {
          let paymentPeriodDays = 0;
          if (benefit?.paymentPeriodEndDay?.split("Days")) {
            paymentPeriodDays = benefit?.paymentPeriodEndDay?.split("Days")[0];
          } else {
            paymentPeriodDays = benefit?.paymentPeriodEndDay;
          }
          sliderMarkItem.label = paymentPeriodDays;
        }

        // sliderMarkItem.label = benefit?.paymentPeriodEndDay;
        sliderMarkItem.value = index * 15;
        sliderMarkItem.paymentPeriod = benefit?.paymentPeriod;
        sliderMarkItem.paymentPeriodEndDay = benefit?.paymentPeriodEndDay;
        sliderMarkItem.effectiveOfferPrice = benefit?.effectiveOfferPrice;
        sliderMarkItem.benefitType = benefit?.benefitType;
        sliderMarkItem.benefitAmount = benefit?.benefitAmount;
        sliderMarkItem.effectiveTotalNetPrice = benefit?.effectiveTotalNetPrice;
        sliderMarkItem.benefitText = benefit?.benefitText;
        sliderMarkItem.totalCalculatedBenefitAmount =
          benefit?.totalCalculatedBenefitAmount;
        sliderMarkItem.perUnitCalculatedBenefitAmount =
          ProductService.roundFloatsToNdecimalPlaces(
            benefit?.perUnitCalculatedBenefitAmount,
            2
          );
        generatedSliderMarks.push(sliderMarkItem);
      }
    });
    return generatedSliderMarks;
  };

  useEffect(() => {
    let discountBenefitsFromAppliedOffer = null;
    let sliderMarksFromAppliedBenefits = null;
    if (props.appliedOffer?.offerId && props.cashDiscountBenefits) {
      if (props.cashDiscountBenefits[props.appliedOffer.offerId]) {
        discountBenefitsFromAppliedOffer =
          props.cashDiscountBenefits[props.appliedOffer.offerId];
      } else {
        if (props.cashDiscountBenefits[`no-offer`]) {
          discountBenefitsFromAppliedOffer =
            props.cashDiscountBenefits[`no-offer`];
        } else {
        }
      }
    } else {
      if (props.cashDiscountBenefits) {
        if (props.cashDiscountBenefits[`no-offer`]) {
          discountBenefitsFromAppliedOffer =
            props.cashDiscountBenefits[`no-offer`];
        } else {
        }
      } else {
      }
    }
    if (discountBenefitsFromAppliedOffer) {
      let positiveDiscountBenefitsWithFirstNegativeBenefit =
        getPositiveDiscountBenefitsWithFirstNegativeBenefit(
          discountBenefitsFromAppliedOffer
        );
      sliderMarksFromAppliedBenefits = generateSliderMarks(
        positiveDiscountBenefitsWithFirstNegativeBenefit
      );
      setDiscountBenefits(discountBenefitsFromAppliedOffer);
      setSliderMarks(sliderMarksFromAppliedBenefits);
      setDiscountBenefitForPaymentPeriod(
        sliderMarksFromAppliedBenefits[activeSliderMarkIndex]
      );
      props.setAppliedCashDiscountBenefit(
        sliderMarksFromAppliedBenefits?.[activeSliderMarkIndex]
      );
    }
  }, [props.appliedOffer?.offerId, props.cashDiscountBenefits]);

  const handleSliderChange = (event, newValue, activeThumb) => {
    let newDiscountBenefitIndex = _.findIndex(
      sliderMarks,
      (mark, index) => {
        return mark.value >= newValue;
      },
      0
    );
    if (newDiscountBenefitIndex !== -1) {
      setDiscountBenefitForPaymentPeriod(sliderMarks[newDiscountBenefitIndex]);
      props.setAppliedCashDiscountBenefit(sliderMarks[newDiscountBenefitIndex]);
      setActiveSliderMarkIndex(newDiscountBenefitIndex);
    }
  };

  useEffect(() => {
    if (sliderMarks?.length > 0) {
      let eventData = {
        paymentPeriod: sliderMarks[activeSliderMarkIndex].paymentPeriod,
      };
      AnalyticsService.pushEvent(
        "Cash Discount Benefit Slider Slab Changed",
        eventData
      );
    }
  }, [activeSliderMarkIndex]);

  const checkWhetherToShow = () => {
    let showFlag = true;
    if (props.appliedOffer?.offerId) {
      if (props?.cashDiscountBenefits?.[props.appliedOffer.offerId]) {
        if (
          props.cashDiscountBenefits[props.appliedOffer.offerId].length === 1 &&
          props.cashDiscountBenefits[props.appliedOffer.offerId][0]
            ?.benefitAmount === 0
        ) {
          showFlag = false;
        }
      }
    } else {
      if (props?.cashDiscountBenefits?.["no-offer"]) {
        if (
          props.cashDiscountBenefits["no-offer"].length === 1 &&
          props.cashDiscountBenefits["no-offer"][0]?.benefitAmount === 0
        ) {
          showFlag = false;
        }
      }
    }
    if (props?.isPayAdvanceSchemeActive) {
      showFlag = false;
    }
    return showFlag;
  };

  function whetherToShowNegativeThemeColor() {
    return (
      _.includes(props.appliedCashDiscountBenefit?.benefitText, `-`) ||
      props.appliedCashDiscountBenefit?.benefitAmount === 0
    );
  }
  function getColor() {
    return whetherToShowNegativeThemeColor() ? "#9D2123" : "#00733E";
  }

  function getColorInverted() {
    return whetherToShowNegativeThemeColor() ? "#00733E" : "#9D2123";
  }

  function getMuiColor() {
    return whetherToShowNegativeThemeColor() ? "primary" : "secondary";
  }

  return (
    <ErrorBoundary message={``}>
      <>
        {checkWhetherToShow() && sliderMarks && (
          <Container maxWidth={`xl`} sx={{ marginTop: "30px" }}>
            <SectionTitle title={`Cash Discount Benefit`} />
            {props.offersLoading !== "started" ? (
              <Card
                sx={{
                  marginTop: "10px",
                  boxShadow: "0px 10px 10px #E0E0E0",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Grid
                    container
                    justifyContent={`center`}
                    alignItems={`center`}
                  >
                    <Grid item xs={11}>
                      {sliderMarks?.length > 0 && (
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={activeSliderMarkIndex * 15}
                          step={1}
                          // value={activeSliderMarkIndex * 15}
                          valueLabelDisplay="off"
                          marks={sliderMarks}
                          max={
                            sliderMarks.length > 1
                              ? (sliderMarks.length - 1) * 15
                              : 15
                          }
                          onChange={handleSliderChange}
                          sx={{
                            marginTop: "0px",
                            "& .MuiSlider-markLabel": {
                              fontSize: "12px",
                              fontWeight: "700",
                            },
                            "& .MuiSlider-markLabelActive": {
                              fontSize: "12px",
                              fontWeight: "700",
                              color: getColor(),
                            },
                            "& .MuiSlider-thumb": {
                              // height: "20px",
                            },
                            "& .MuiSlider-mark": {
                              height: "15px",
                              // width: "15px",
                              // borderRadius: "15px",
                            },
                            "& .MuiSlider-markActive": {
                              height: "15px",
                              // width: "15px",
                              // borderRadius: "15px",
                              color: getColorInverted(),
                              backgroundColor: getColor(),
                            },
                          }}
                          components={
                            {
                              // Thumb: SliderThumb,
                            }
                          }
                          track={`normal`}
                          size={`medium`}
                          color={getMuiColor()}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {discountBenefitForPaymentPeriod && (
                    <Grid
                      container
                      maxWidth={`xl`}
                      alignItems={`center`}
                      justifyContent={`center`}
                    >
                      <Grid item xs={4}>
                        <Typography
                          variant={`body1`}
                          sx={{ fontWeight: "700" }}
                          align={"center"}
                        >
                          {discountBenefitForPaymentPeriod.paymentPeriod}
                        </Typography>
                        <Typography
                          variant={`body2`}
                          sx={{
                            fontSize: "12px",
                            opacity: "0.5",
                            fontWeight: "400",
                          }}
                          align={"center"}
                        >
                          Payment Period
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant={`body1`}
                          sx={{
                            fontWeight: "700",
                            filter: PartnerService.applySmudgedPriceFilter(
                              props?.product
                            ),
                          }}
                          align={"center"}
                        >
                          {_.includes(
                            discountBenefitForPaymentPeriod.benefitText,
                            `-`
                          ) ? (
                            <Typography
                              sx={{
                                color: "#9D2123",
                                fontWeight: "700",
                              }}
                            >
                              {discountBenefitForPaymentPeriod.benefitType ===
                                "ABSOLUTE" &&
                                `₹${
                                  _.toString(
                                    discountBenefitForPaymentPeriod.totalCalculatedBenefitAmount
                                  ).split(`-`)[1]
                                }`}

                              {discountBenefitForPaymentPeriod.benefitType ===
                                "PERCENTAGE" &&
                                `${
                                  _.toString(
                                    discountBenefitForPaymentPeriod.benefitAmount
                                  ).split(`-`)[1]
                                }%`}
                            </Typography>
                          ) : (
                            <>
                              {discountBenefitForPaymentPeriod.benefitType ===
                                "ABSOLUTE" &&
                                `₹${new Intl.NumberFormat("en-IN").format(
                                  discountBenefitForPaymentPeriod.totalCalculatedBenefitAmount
                                )}`}
                              {discountBenefitForPaymentPeriod.benefitType ===
                                "PERCENTAGE" &&
                                `${discountBenefitForPaymentPeriod.benefitAmount}%`}
                            </>
                          )}
                        </Typography>
                        <>
                          {_.includes(
                            discountBenefitForPaymentPeriod.benefitText,
                            `-`
                          ) ? (
                            <Typography
                              variant={`body2`}
                              sx={{
                                color: "#9D2123",
                                fontSize: "12px",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                              }}
                              align={`center`}
                            >
                              Interest
                            </Typography>
                          ) : (
                            <Typography
                              variant={`body2`}
                              sx={{
                                fontSize: "12px",
                                opacity: "0.5",
                                fontWeight: "400",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                              }}
                              align={`center`}
                            >
                              Discount
                            </Typography>
                          )}
                        </>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>
                          {_.includes(
                            _.toString(
                              discountBenefitForPaymentPeriod.perUnitCalculatedBenefitAmount
                            ),
                            `-`
                          ) ? (
                            <Typography
                              variant={`body1`}
                              sx={{
                                color: "#9D2123",
                                fontWeight: "700",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                              }}
                              align={`center`}
                            >
                              ₹
                              {new Intl.NumberFormat("en-IN").format(
                                _.toString(
                                  discountBenefitForPaymentPeriod.perUnitCalculatedBenefitAmount
                                ).split(`-`)[1]
                              )}
                            </Typography>
                          ) : (
                            <Typography
                              variant={`body1`}
                              sx={{
                                fontWeight: "700",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                              }}
                              align={`center`}
                            >
                              ₹
                              {discountBenefitForPaymentPeriod.perUnitCalculatedBenefitAmount
                                ? new Intl.NumberFormat("en-IN").format(
                                    discountBenefitForPaymentPeriod.perUnitCalculatedBenefitAmount
                                  )
                                : 0}
                            </Typography>
                          )}
                        </Typography>
                        <>
                          {_.includes(
                            discountBenefitForPaymentPeriod.benefitText,
                            `-`
                          ) ? (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#9D2123",
                              }}
                              align={"center"}
                            >
                              Monthly Interest/Unit
                            </Typography>
                          ) : (
                            <Typography
                              variant={`body2`}
                              sx={{
                                fontSize: "12px",
                                opacity: "0.5",
                                fontWeight: "400",
                              }}
                              align={"center"}
                            >
                              Cash Discount/Unit
                            </Typography>
                          )}
                        </>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            ) : (
              <>
                <Skeleton variant="rectangular" height={150} />
              </>
            )}
          </Container>
        )}
      </>
    </ErrorBoundary>
  );
};

export default CashDiscountBenefitContainer;
