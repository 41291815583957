import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import ErrorIcon from "@mui/icons-material/Error";

function CatalogItemUnpublishConfirmationDialog(props) {
  useEffect(() => {
    if (props.open) {
      AnalyticsService.pushEvent(
        "Store Front Catalog Item Unpublish Confirmation Dialog Viewed",
        { skuCode: props.skuCode }
      );
    }
  }, [props.open]);
  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
        } else {
          props.onClose();
        }
      }}
      fullWidth={true}
      PaperProps={{
        style: { borderRadius: "32px" },
      }}
    >
      <DialogContent>
        <Grid container alignItems={`center`} justifyContent={`center`}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center", minHeight: "67px" }}>
              <img src={`/icons/starWarning.svg`} alt={`star`} />
            </Box>

            <Typography
              variant={`h2`}
              sx={{
                textAlign: "center",
                color: "#9D2123",
                marginTop: "10px",
              }}
              fontSize={18}
              fontWeight={700}
            >
              Are You Sure?
            </Typography>

            <Typography
              variant={`body2`}
              sx={{
                textAlign: "center",
                marginTop: "10px",
                lineHeight: "18px",
              }}
              fontSize={12}
              fontWeight={400}
            >
              Are you sure that you want to hide this product? It will be{" "}
              <b>hidden</b> from your AgroStar Digital Dukaan.
            </Typography>

            <Box
              sx={{ textAlign: "center", marginTop: "16px", minHeight: "30px" }}
            >
              {props.loadingUnpublish === "started" && (
                <CircularProgress size={24} color={"secondary"} />
              )}
            </Box>

            <Grid
              container
              alignItems={`center`}
              justifyContent={`center`}
              sx={{ marginTop: "2px" }}
              spacing={2}
            >
              <Grid item>
                <Button
                  variant={`outlined`}
                  size={"large"}
                  color={"secondary"}
                  sx={{
                    borderRadius: "16px",
                    background: "rgba(255, 255, 255, 1)",
                    color: "rgba(0, 115, 62, 1)",
                  }}
                  disabled={props.loadingUnpublish === "started"}
                  onClick={() => {
                    props.handleClickReject();
                  }}
                >
                  No
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={`outlined`}
                  size={"large"}
                  color={"secondary"}
                  sx={{
                    borderRadius: "16px",
                    color: "rgba(255, 255, 255, 1)",
                    background: "rgba(0, 115, 62, 1)",
                  }}
                  disabled={props.loadingUnpublish === "started"}
                  onClick={() => {
                    props.handleClickConfirm(props.skuCode);
                  }}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CatalogItemUnpublishConfirmationDialog;
