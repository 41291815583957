import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { Typography, CircularProgress, Grid, Box } from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import ProductService from "../../Services/ProductService";
import PartnerService from "../../Services/PartnerService";

const ProductMargin = ({
  product,
  farmerNetPrice,
  appliedOffer,
  appliedCashDiscountBenefit,
  offersLoading,
  productDetailsStickyAtTop,
}) => {
  const getProductMargin = () => {
    let applicablePrice = ProductService.getApplicablePrice(
      product,
      appliedOffer,
      appliedCashDiscountBenefit
    );
    let productMargin = farmerNetPrice - applicablePrice;
    let marginText = ``;
    if (Number.isInteger(productMargin)) {
      if (productMargin < 0) {
        marginText = `-₹${new Intl.NumberFormat("en-IN").format(
          Math.abs(productMargin)
        )}`;
      } else {
        marginText = `₹${new Intl.NumberFormat("en-IN").format(productMargin)}`;
      }
    }
    return marginText;
  };

  return (
    <ErrorBoundary message={``}>
      {farmerNetPrice ? (
        <Grid item xs={12}>
          <Typography
            sx={{
              marginTop: `${productDetailsStickyAtTop ? `4px` : `8px`}`,
              padding: "4px",
              borderRadius: "5px",
              fontSize: `${productDetailsStickyAtTop ? `12px` : `14px`}`,
              transition: ProductService.productDetailsStickyTransition,
              color: "white",
              background: "#00733E",
              textAlign: "center",
              border: "none",
            }}
          >
            Farmer Net Price{" "}
            {
              <b>
                <span
                  style={{
                    filter: PartnerService.applySmudgedPriceFilter(product),
                  }}
                >
                  ₹
                  {farmerNetPrice
                    ? new Intl.NumberFormat("en-IN").format(farmerNetPrice)
                    : 0}{" "}
                </span>
                | Your Margin{" "}
              </b>
            }
            {offersLoading !== "started" ? (
              <b>
                <span
                  style={{
                    filter: PartnerService.applySmudgedPriceFilter(product),
                  }}
                >
                  {" "}
                  <>{getProductMargin()}</>
                </span>
              </b>
            ) : (
              <>
                <CircularProgress
                  sx={{
                    marginLeft: "31px",
                    align: "right",
                    color: "white",
                    transition: ProductService.productDetailsStickyTransition,
                  }}
                  size={productDetailsStickyAtTop ? 10 : 15}
                />
              </>
            )}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};

export default ProductMargin;
