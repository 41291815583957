import { useEffect } from "react";
import { Box } from "@mui/material";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import AnalyticsService from "../../Services/AnalyticsService";

function SafalMeetPage() {
  useEffect(() => {
    AnalyticsService.pushEvent(`Offline Saathi Safal Meeting Page Viewed`, {
      page: `/offline-saathi-safal-meeting`,
    });
  }, []);

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Offline Saathi Safal Meeting`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
          toolTip={`Offline Saathi Safal Meeting`}
        />
        <Box
          sx={{
            textAlign: "center",
            margin: "32px 0px 64px 0px",
          }}
        >
          <img
            src={`/icons/safalMeetRegistrationSuccess.jpg`}
            style={{ width: "90%" }}
            alt={`Thank you for registration`}
          />
        </Box>
      </ErrorBoundary>
    </>
  );
}

export default SafalMeetPage;
