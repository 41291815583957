import clevertap from "clevertap-web-sdk";
import config from "../config";
// import firebase from "./../../firebase";
import { getAnalytics, logEvent } from "firebase/analytics";

export default class AnalyticsService {
  static pushProfile(farmerId, mobile, source, status) {
    let identity = null;
    if (localStorage.getItem("role") === "fieldAgent") {
      identity = localStorage.getItem("fieldAgentMobileNumber");
    } else {
      identity = farmerId;
    }
    let profilePushData = {
      Identity: identity,
      "MSG-push": true,
      "MSG-whatsapp": true,
      UserAgent: window?.navigator?.userAgent,
      // Phone: `+91${mobile}`,
      // Source: source,
      // "Mobile Number": mobile,
      // status: status === "INACTIVE" ? "INACTIVE" : "ACTIVE",
    };
    if (localStorage.getItem("role") === "fieldAgent") {
      profilePushData.role = "fieldAgent";
    } else {
      if (mobile) {
        profilePushData.Phone = `+91${mobile}`;
        profilePushData["Mobile Number"] = mobile;
      }

      if (source) {
        profilePushData.Source = source;
      }
      if (status) {
        profilePushData.status = status === "INACTIVE" ? "INACTIVE" : "ACTIVE";
      }
    }

    clevertap.onUserLogin.push({
      Site: profilePushData,
    });
    const analytics = getAnalytics();
    logEvent(analytics, "ProfileLogin", profilePushData);

    console.log(`profile pushed`);
    console.log({ profilePushData });
    /*global setFarmerId*/
    setFarmerId(identity);
  }

  static pushEvent = (eventName, eventData) => {
    try {
      AnalyticsService.pushEventWithTryCatch(`${eventName}`, eventData);
    } catch (err) {
      console.log(`failed to send analytics event for ${eventData?.name}`);
      console.log(err);
    }
  };

  static pushEventWithTryCatch(eventName, eventData) {
    eventData = AnalyticsService.populateWithDefaultEventProperties(eventData);
    console.log("event", eventName, eventData);
    clevertap.event.push(eventName, eventData);

    const analytics = getAnalytics();
    logEvent(analytics, eventName, eventData);
    return eventData;
  }

  static populateWithDefaultEventProperties(eventData) {
    let userSource = "WEB";
    let appVersionName = null;
    let appVersionCode = null;
    try {
      /*global Android*/
      if (Android) {
        console.log("Is android");
        userSource = "APP";
        try {
          appVersionName = Android.getAppVersion();
          appVersionCode = Android.getAppVersionCode();
        } catch (error) {
          console.log(`no android version name or code`);
          appVersionName = "1.0";
          appVersionCode = 2;
        }
      }
    } catch (error) {
      console.log(`No android`);
    }

    if (eventData) {
      eventData.userSource = userSource;
    } else {
      eventData = {};
      eventData.userSource = userSource;
    }

    if (appVersionName && appVersionCode) {
      eventData.appVersionName = appVersionName;
      eventData.appVersionCode = appVersionCode;
    }

    try {
      if (window.navigator.language) {
        eventData.deviceLanguage = window.navigator.language;
      }
    } catch (error) {
      console.log(`can't get device language`);
    }

    let source = localStorage.getItem(`source`);
    if (localStorage.getItem(`source`)) {
      eventData.source = source;
    }

    let farmerId = localStorage.getItem(`farmerId`);
    if (localStorage.getItem(`farmerId`)) {
      eventData.farmerId = farmerId;
    }

    let role = localStorage.getItem("role");
    if (role) {
      eventData.role = role;
    }

    let fieldAgentMobileNumber = localStorage.getItem("fieldAgentMobileNumber");
    if (fieldAgentMobileNumber) {
      eventData.fieldAgentMobileNumber = fieldAgentMobileNumber;
    }

    let partnerStatus = localStorage.getItem("partnerStatus");
    if (partnerStatus) {
      eventData.status = partnerStatus;
    }

    let lendingProvider = localStorage.getItem("lendingProvider");
    if (lendingProvider) {
      eventData.lendingProvider = lendingProvider;
    }

    eventData.webappVersionName = config.reactAppVersion;

    eventData.path =
      `${window.location.pathname}${window.location.search}`.slice(0, 1024);

    if (!eventData.page) {
      let pagename = window.location.pathname
        .split("/")
        .filter((p) => p !== "");
      eventData.page =
        pagename.length > 1 ? pagename[0] + ":" + pagename[1] : "";
    }

    eventData.timestamp = new Intl.DateTimeFormat("en-GB", {
      dateStyle: "full",
      timeStyle: "long",
    }).format(Date.now());
    return eventData;
  }
}
