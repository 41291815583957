import ErrorIcon from "@mui/icons-material/Error";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useEffect } from "react";
import AnalyticsService from "../../Services/AnalyticsService";

const UnverifiedPartnersDialog = ({ showError, setShowError, error }) => {
  useEffect(() => {
    if (showError === true && error) {
      AnalyticsService.pushEvent("Dialog Shown", {
        type: "Failure",
        message: error,
        page: window.location.pathname,
      });
    }
  }, [showError, error]);

  return (
    <Dialog
      open={showError}
      onClose={() => setShowError(false)}
      PaperProps={{
        style: {
          borderRadius: "32px",
          padding: "24px",
          minWidth: "70vw",
        },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <ErrorIcon fontSize={"large"} sx={{ color: "#9D2123" }} />
        <Typography variant={`body1`} sx={{ color: "#9D2123", marginTop: 1 }}>
          {error}
        </Typography>
        <Box sx={{ marginTop: 2.5 }}>
          <Button
            variant={"outlined"}
            size={"large"}
            color={"secondary"}
            sx={{
              borderRadius: "16px",
              background: "rgba(255, 255, 255, 1)",
              color: "rgba(0, 115, 62, 1)",
              textTransform: "capitalize",
            }}
            onClick={() => setShowError(false)}
          >
            Okay
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UnverifiedPartnersDialog;
