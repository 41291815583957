import React, { useEffect } from "react";

import { Close, Error } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import AnalyticsService from "../../Services/AnalyticsService";

const ProceedPaymentFailureDialog = (props) => {
  useEffect(() => {
    if (props.open === true) {
      AnalyticsService.pushEvent("Payment Initiation Failure Dialog Shown", {
        errorCode: props.errorCode,
      });
    }
  }, [props.open]);

  return (
    <Dialog open={props.open}>
      <Grid container alignItems={"flex-start"} justifyContent={"flex-start"}>
        <Grid item xs={10} />
        <Grid item xs={2}>
          <IconButton
            size={"medium"}
            onClick={() => {
              AnalyticsService.pushEvent(
                "Payment Initiation Failure Dialog Closed",
                {
                  errorCode: props.errorCode,
                }
              );
              props.handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          // sx={{ marginTop: "20vh" }}
        >
          <Grid item>
            <Error color={"error"} sx={{ fontSize: "40px" }} />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ marginTop: "1vh" }}
        >
          <Grid item>
            <Typography variant={"body1"} align={"center"} color={"error"}>
              Sorry! The payment request has expired.
              <br /> Please retry.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ marginTop: "1vh", marginBottom: "2vh" }}
        >
          <Grid item>
            <Typography
              variant={"caption"}
              align={"center"}
              color={"error"}
              sx={{ color: "#222222", opacity: 0.5 }}
            >
              {props.errorCode}
            </Typography>
          </Grid>
        </Grid>

        <Button
          variant={"contained"}
          fullWidth={true}
          color={"primary"}
          href={props?.initiationFailureRetryRedirectionLink}
          onClick={() => {
            AnalyticsService.pushEvent(
              "Payment Initiation Failure Dialog Retry Payment Clicked",
              {
                errorCode: props.errorCode,
              }
            );
          }}
        >
          Retry Payment
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ProceedPaymentFailureDialog;
