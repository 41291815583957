import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AnalyticsService from "../../Services/AnalyticsService";

export default function ProductsUnlockedDialog(props) {
  const dialogMessage =
    "Thank you for sharing your details. You can now explore our range of products.";

  useEffect(() => {
    if (props.open) {
      AnalyticsService.pushEvent("Dialog Shown", {
        type: "Success",
        message: dialogMessage,
        page: "Fill Partner Details",
      });
    }
  }, [props.open]);
  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
        } else {
          props.onClose();
        }
      }}
      fullWidth={true}
      PaperProps={{
        style: { borderRadius: "32px" },
      }}
    >
      <DialogContent>
        <Grid container alignItems={`center`} justifyContent={`center`}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <img src={`/icons/like.svg`} height={"40px"} alt={`like`} />
            </Box>

            <Typography
              variant={`h2`}
              sx={{
                textAlign: "center",

                marginTop: "10px",
              }}
              fontSize={18}
              fontWeight={700}
            >
              Products Unlocked
            </Typography>

            <Typography
              variant={`body2`}
              sx={{
                textAlign: "center",
                marginTop: "10px",
                lineHeight: "18px",
              }}
              fontSize={12}
              fontWeight={400}
            >
              {dialogMessage}
            </Typography>

            <Grid
              container
              alignItems={`center`}
              spacing={2}
              sx={{ marginTop: 0.5 }}
            >
              <Grid item xs={6}>
                <Button
                  variant={`outlined`}
                  size={"small"}
                  color={"primary"}
                  sx={{
                    borderRadius: "16px",
                    textTransform: "none",
                    paddingTop: 1,
                    paddingBottom: 1,
                  }}
                  onClick={() => {
                    props.handleClickViewProducts();
                  }}
                  fullWidth={true}
                >
                  View Products
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant={`contained`}
                  size={"small"}
                  color={"primary"}
                  sx={{
                    borderRadius: "16px",
                    textTransform: "none",
                    paddingTop: 1,
                    paddingBottom: 1,
                  }}
                  onClick={() => {
                    props.handleClickUnlockPrices();
                  }}
                  fullWidth={true}
                >
                  Unlock Prices
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
