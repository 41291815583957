import React from "react";

import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForward } from "@mui/icons-material";

import AnalyticsService from "../../Services/AnalyticsService";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  viewAllButton: {
    marginTop: theme.spacing(2),
    height: "40px",
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1.25),
    border: "none",
    "&:focus": {
      backgroundColor: `#FFFFFF`,
    },
    "&:hover": {
      backgroundColor: `#FFFFFF`,
    },
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "19px",
  },
}));

function ViewAllButton(props) {
  const classes = useStyles();

  const handleClickViewAllButton = () => {
    if (props?.saveScrollYPosition) {
      MiscUtils.saveScrollYPosition();
    }
    const viewAllClickedEventData = {
      section: props?.section || "",
      page:
        props?.section === "Brand List"
          ? "dashboard:Brand list view all"
          : props?.section === "Featured Products List"
            ? "dashboard: Featured products list view all"
            : props?.section === "Popular Products List"
              ? "dashboard: Popular products list view all"
              : props?.section === "Orders"
                ? "dashboard: orders view all"
                : props?.section === "Recently Bought Products"
                  ? "dashboard: Recently Bought Product view all"
                  : props?.section === "Buy it Again"
                    ? "dashboard: Buy it Again Products view all"
                    : props?.section === "High Demand Products"
                      ? "dashboard: High Demand Products view all"
                      : props?.section === "Featured Videos"
                        ? "dashboard: Featured Videos view all"
                        : props?.section === "Grahak Products Recommendation"
                          ? "dashboard: Grahak Products view all"
                          : "",
    };

    AnalyticsService.pushEvent(`View All Clicked`, viewAllClickedEventData);
  };

  return (
    <>
      <Button
        variant={"outlined"}
        fullWidth
        size={"medium"}
        color={"primary"}
        className={classes.viewAllButton}
        sx={{
          marginTop: `${
            props.section === "Featured Videos" ? "0px !important" : ""
          }`,
        }}
        onClick={() => handleClickViewAllButton()}
      >
        View All <ArrowForward />
      </Button>
    </>
  );
}

export default ViewAllButton;
