import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Skeleton } from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import ProductsList from "../Product/ProductsList";
import ProductService from "../../Services/ProductService";
import Search from "../Dashboard/Search";
import useQuery from "../../Hooks/useQuery";
import _ from "lodash";
import ProductsFilter from "./ProductsFilter";
import ProductsLoader from "./ProductsLoader";
import ProductsFilterLoader from "./ProductsFilterLoader";
import SelectedBrandFiltersLoader from "./SelectedBrandFiltersLoader";
import SelectedBrandFilters from "./SelectedBrandFilters";
import useSelectedBrands from "../../Hooks/useSelectedBrands";
import AnalyticsService from "../../Services/AnalyticsService";

function ProductsOffer() {
  const { skus } = useParams();
  const query = useQuery();
  let brandNamesFromQuery = query.get("brandNames")?.split(",");
  brandNamesFromQuery = _.map(brandNamesFromQuery, (brandNameFromQuery) =>
    brandNameFromQuery?.toLowerCase()
  );
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [brands, setBrands] = useState(null);

  // const [currentlyChosenBrands, setCurrentlyChosenBrands] = useState([]);

  // const [productsCount, setProductsCount] = useState(null);
  // const [loadingProductsCount, setLoadingProductsCount] =
  //   useState("not_started");
  // const [errorProductsCount, setErrorProductsCount] = useState(null);

  // const handleClickChooseBrands = (currentlyChosenBrands) => {
  //   setCurrentlyChosenBrands(currentlyChosenBrands);
  // };

  const { loadingAllBrands } = useSelectedBrands(
    brandNamesFromQuery,
    brands,
    setSelectedBrands
    // handleClickChooseBrands
  );

  useEffect(() => {
    AnalyticsService.pushEvent("Product List Page Viewed", {
      page: "Products Offers",
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (skus) {
      const { axiosRequest, abortController } =
        ProductService.getProductsListBySKUs(skus, selectedBrands);
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.productGists?.length > 0) {
            let productsFromResponse = response.data.responseData.productGists;
            setProducts(productsFromResponse);
          } else {
            console.log(`no response received in responseData`);
            setProducts(null);
          }

          if (
            selectedBrands?.length === 0 &&
            response?.data?.responseData?.brands?.length > 0
          ) {
            let brandsFromResponse = response?.data?.responseData?.brands;
            brandsFromResponse = _.filter(brandsFromResponse, (brand) => {
              return brand.brandName !== "Unassigned Brand";
            });
            setBrands(brandsFromResponse);
          } else {
            console.log(`no brands received in responseData`);
          }

          setLoading(false);
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting products`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoading(false);
          }
        });
      return () => abortController.abort();
    } else {
      console.log(`no SKUs found in route`);
      setLoading(false);
    }
  }, [skus, selectedBrands]);

  // useEffect(() => {
  //   setLoadingProductsCount(true);
  //   if (skus) {
  //     ProductService.getProductsListBySKUsCount(skus, currentlyChosenBrands)
  //       .then((response) => {
  //         if (response?.data?.responseData?.count) {
  //           setProductsCount(response.data.responseData.count);
  //         } else {
  //           console.log(`no response received in responseData`);
  //         }
  //         setLoadingProductsCount(false);
  //       })
  //       .catch((error) => {
  //         console.log(`error getting products count`);
  //         if (error.data) {
  //           console.log(error.data);
  //           setErrorProductsCount(error.data);
  //         } else {
  //           console.log(error);
  //           setErrorProductsCount(error);
  //         }
  //         setLoadingProductsCount(false);
  //       });
  //   } else {
  //     console.log(`no SKUs found in route`);
  //     setLoadingProductsCount(false);
  //   }
  // }, [skus, currentlyChosenBrands]);

  return (
    <div>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Offer: Products`}
          backNavigation={true}
          backLink={"/dashboard"}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <ErrorBoundary message={``}>
        <Search page={"Products Offer Page"} shouldMakeItSticky={true} />
      </ErrorBoundary>
      {!loading && loadingAllBrands === "done" ? (
        <div>
          <ErrorBoundary message={``}>
            <SelectedBrandFilters
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
            />
          </ErrorBoundary>
          <ErrorBoundary message={``}>
            <ProductsList
              loading={loading}
              products={products}
              page={`Products Offer Page`}
            />
          </ErrorBoundary>{" "}
          <ErrorBoundary message={``}>
            <ProductsFilter
              // productsCount={productsCount}
              // loadingProductsCount={loadingProductsCount}
              // errorProductsCount={errorProductsCount}
              // handleClickChooseBrands={handleClickChooseBrands}
              brands={brands}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
            />
          </ErrorBoundary>
        </div>
      ) : (
        <>
          {selectedBrands?.length > 0 && <SelectedBrandFiltersLoader />}
          <ProductsLoader />
          <ProductsFilterLoader />
        </>
      )}
    </div>
  );
}

export default ProductsOffer;
