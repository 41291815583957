import React from "react";
import { Box, Card, Grid, Radio, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import makeStyles from "@mui/styles/makeStyles";
import AmountUtils from "../../Utils/AmountUtils";

const useStyles = makeStyles((theme) => ({
  paymentMethodCard: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1.5),
    border: "1px solid white",
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    display: "block",
  },
  paymentMethodCardActive: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1.5),
    border: "2px solid #00733E",
    background: "#E5F1EC",
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    display: "block",
  },
  logoWrapper: {
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "16px",
    minHeight: "90px",
    height: "fit-content",
    width: "90px",
    borderRadius: theme.spacing(1.2),
    flex: "none",
  },
}));

function PaymentMethod({
  url,
  text,
  amount,
  value,
  isChecked,
  isDisabled,
  handleOnSelected,
  subText,
  subTextPrefix,
  errorText,
}) {
  const classes = useStyles();
  const Icon = isDisabled ? RadioButtonCheckedIcon : CheckCircle;

  return (
    <Card
      className={
        isChecked ? classes.paymentMethodCardActive : classes.paymentMethodCard
      }
      variant="outlined"
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ position: "relative" }}
      >
        <Box sx={{ position: "absolute", top: "0", right: "-10px" }}>
          <Radio
            icon={<Icon color={"disabled"} style={{ fontSize: "24px" }} />}
            checkedIcon={<Icon style={{ fontSize: "24px" }} />}
            color={"secondary"}
            checked={isChecked}
            onChange={handleOnSelected}
            value={value}
            disabled={isDisabled}
          />
        </Box>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            minHeight: "90px",
            height: "fit-content",
          }}
        >
          <Box className={classes.logoWrapper}>
            <img
              src={url}
              alt={text}
              style={{
                margin: "5px",
                width: "100%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "flex-start",
              flexDirection: "column",
              textAlign: "left",
              flex: "auto",
            }}
          >
            <Typography
              variant={`h5`}
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                marginBottom: "2px",
                textTransform: "capitalize",
              }}
            >
              {text}
            </Typography>
            <Typography
              variant={`h6`}
              color="secondary"
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                textTransform: "none",
              }}
            >
              Payable amount ₹{AmountUtils.format(amount)}
            </Typography>
            {subText && !isDisabled && (
              <Typography
                variant="h6"
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  textTransform: "none",
                }}
              >
                {subTextPrefix}
                <b>{subText}</b>
              </Typography>
            )}
            {errorText && isDisabled && (
              <Typography
                variant="h6"
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  textTransform: "none",
                  color: "#9E2123",
                }}
              >
                <b>{errorText}</b>
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default PaymentMethod;
