import { Box, Card, Grid, Typography } from "@mui/material";
import useMessages from "../..//Hooks/useMessages";
import Languages from "../../Services/LanguageCode";
import AnalyticsService from "../../Services/AnalyticsService";
import { WhatsappShareButton } from "react-share";
import { Link, useNavigate } from "react-router-dom";
import PartnerService from "../../Services/PartnerService";
import config from "../../config";
import { useEffect, useState } from "react";
import StoreFrontService from "../../Services/StoreFrontService";
import CommonConstantValues from "../../Services/CommonConstantValues";

const tabsAfterDeliveryViaStoreEnabled = [
  {
    path: "/store-manager/store-inventory",
    name: "Farmer Orders",
    iconPath: "icons/grahakOrdersIcon.svg",
    eventName: "Farmer Orders Clicked",
  },
  {
    path: "/store-manager/store-inventory",
    name: "Manage Stock",
    iconPath: "icons/dukaanCatalog.svg",
    eventName: "Store Front Catalog Clicked",
  },
  {
    path: "/store-front",
    name: "Digital Dukaan",
    iconPath: "icons/digitalDukaan.svg",
    eventName: "Store Front Clicked",
  },
];

const tabsBeforeDeliveryViaStoreEnabled = [
  {
    path: "/store-front",
    name: "Digital Dukaan",
    iconPath: "icons/digitalDukaan.svg",
    eventName: "Store Front Clicked",
  },
  {
    path: "/store-front-catalog",
    name: "Manage Stock",
    iconPath: "icons/dukaanCatalog.svg",
    eventName: "Store Front Catalog Clicked",
  },
  {
    name: "Share Dukaan",
    iconPath: "icons/shareDukaan.svg",
    eventName: "Profile Shared",
  },
];

const StoreFrontManager = ({ profileData, setShowUnauthorizedError }) => {
  const { getMessage } = useMessages();
  const navigate = useNavigate();
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  const [storeFrontManagerTabs, setStoreFrontManagerTabs] = useState(null);
  const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "en";
  let shopData = profileData;
  let whatsappMessage =
    getMessage(`profile.header`) +
    "\r\n\r\n" +
    getMessage(`profile.storeName`) +
    shopData?.nameInRegionalLanguage +
    "\r\n" +
    getMessage(`profile.storeAddress`) +
    shopData?.addressInRegionalLanguage?.village +
    ", " +
    shopData?.addressInRegionalLanguage?.taluka +
    ", " +
    shopData?.addressInRegionalLanguage?.district +
    ", " +
    shopData?.addressInRegionalLanguage?.state +
    ", " +
    shopData?.addressInRegionalLanguage?.country +
    ", " +
    shopData?.addressInRegionalLanguage?.pincode +
    ".\r\n" +
    getMessage(`profile.storePhoneNumber`) +
    shopData?.contacts?.mobileNumber +
    "\r\n";

  useEffect(() => {
    const institutionDetailsFromLocalStorage =
      localStorage.getItem(`InstitutionDetails`);
    if (
      institutionDetailsFromLocalStorage &&
      institutionDetailsFromLocalStorage !== ""
    ) {
      const institutionDetails = JSON.parse(institutionDetailsFromLocalStorage);
      if (institutionDetails?.isDeliveryViaStoreEnabled) {
        setStoreFrontManagerTabs(tabsAfterDeliveryViaStoreEnabled);
      } else {
        if (
          CommonConstantValues.OFFLINE_DISCOUNT_AT_STORE_ENABLED_SOURCES.includes(
            localStorage.getItem("source")
          )
        ) {
          setStoreFrontManagerTabs(tabsAfterDeliveryViaStoreEnabled);
          return;
        }
        setStoreFrontManagerTabs(tabsBeforeDeliveryViaStoreEnabled);
      }
    }

    const { axiosRequest, abortController } =
      StoreFrontService.getFarmerOrders("pending");
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setNewOrdersCount(response?.data?.responseData?.orders?.length);
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`Error Getting Store Orders: `, error);
        }
      });
    return () => abortController.abort();
  }, []);

  return (
    <>
      {storeFrontManagerTabs ? (
        <Box
          sx={{
            backgroundColor: "rgba(28, 28, 28, 0.05)",
            padding: 2,
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems={`center`}
            justifyContent={`space-evenly`}
          >
            {storeFrontManagerTabs?.map((tab, index) => (
              <Grid item xs={4} key={index}>
                <Card
                  elevation={0}
                  sx={{
                    position: "relative",
                    textAlign: "center",
                    padding: 1,
                    borderRadius: "9px",
                    height: "80px",
                  }}
                  onClick={() => {
                    AnalyticsService.pushEvent(tab.eventName, {
                      page: `/profile`,
                    });
                    if (
                      localStorage.getItem("partnerStatus") === "UNVERIFIED"
                    ) {
                      setShowUnauthorizedError(true);
                    } else {
                      tab.path && navigate(tab.path);
                    }
                  }}
                >
                  {tab.name === "Share Dukaan" ? (
                    <WhatsappShareButton
                      url={
                        localStorage.getItem("partnerStatus") === "UNVERIFIED"
                          ? ``
                          : `👉 ${PartnerService.getStoreFrontUrl()} `
                      }
                      title={whatsappMessage}
                    >
                      <Box sx={{ marginTop: 1 }}>
                        <img
                          src={tab.iconPath}
                          alt={tab.name}
                          style={{ height: "25px" }}
                        />
                        <Typography
                          variant={`caption`}
                          sx={{
                            display: "block",
                            fontSize: "0.74rem",
                            marginTop: 0.5,
                          }}
                          align={"center"}
                        >
                          {tab.name}
                        </Typography>
                      </Box>
                    </WhatsappShareButton>
                  ) : (
                    <Box sx={{ marginTop: 1 }}>
                      {tab.name === "Farmer Orders" && (
                        <div
                          style={{
                            position: "absolute",
                            right: "8px",
                            top: "8px",
                            minWidth: "20px",
                            padding: "0 4px",
                            fontSize: "14px",
                            color: "white",
                            borderRadius: "50%",
                            background: "#9D2123",
                          }}
                        >
                          {newOrdersCount}
                        </div>
                      )}
                      <img
                        src={tab.iconPath}
                        alt={tab.name}
                        style={{ height: "25px" }}
                      />
                      <Typography
                        variant={`caption`}
                        sx={{
                          display: "block",
                          fontSize: "0.74rem",
                          marginTop: 0.5,
                        }}
                        align={"center"}
                      >
                        {tab.name}
                      </Typography>
                    </Box>
                  )}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default StoreFrontManager;
