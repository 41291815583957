import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  SwipeableDrawer,
  Typography,
  Card,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import AnalyticsService from "../../Services/AnalyticsService";
import { makeStyles } from "@mui/styles";
import BlockUi from "react-block-ui";
import PromoCard from "./PromoCard";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const useStyles = makeStyles((theme) => ({
  header: {
    position: "sticky",
    top: "0",
    zIndex: 100,
    background: "#FFFFFF",
    paddingTop: "16px",
  },
  closeButton: {
    color: "#999999",
    fontSize: "28px",
    cursor: "pointer",
  },
  content: {
    background: "#F3F3F3",
    paddingBottom: "60px",
    height: "70vh",
    overflowY: "scroll",
  },
  formControl: {
    padding: "0px 12px",
  },
  radioLabel: {
    margin: "0px",
    justifyContent: "space-between",
    color: "#4F4F4F",
    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      fontWeight: 700,
      paddingLeft: "8px",
    },
  },
  footer: {
    position: "sticky",
    bottom: "0",
    width: "100%",
    zIndex: 100,
    background: "#FFFFFF",
  },
  applyButton: {
    fontWeight: 700,
    padding: "10px 15px",
    borderRadius: "0px",
    boxShadow: "none",
  },
}));

const Promo = ({
  promosData,
  open,
  cart,
  otherCart,
  onClose,
  currentTab,
  applyPromo,
  promoApplyLoader,
  setPromoSelectorOpen,
}) => {
  const classes = useStyles();
  const [currentlyAppliedPromos, setCurrentlyAppliedPromos] = useState(
    cart?.appliedPromo
  );
  const currentlyAppliedPromosArray = currentlyAppliedPromos
    ? currentlyAppliedPromos.split(",")
    : [];
  const availablePromos = _.map(
    promosData?.results,
    "promoCodeDetails.voucherCode"
  ).join(",");

  useEffect(() => {
    setCurrentlyAppliedPromos(cart?.appliedPromo);
    if (open) {
      AnalyticsService.pushEvent("Drawer Viewed", {
        drawerType: "promo",
        availablePromos: availablePromos,
        appliedPromos: currentlyAppliedPromos,
        cartId: cart.cartId,
        cartType: currentTab,
      });
    }
  }, [open, cart]);

  const handleOnClose = () => {
    AnalyticsService.pushEvent("Cancel Icon Clicked", {
      drawerType: "promo",
      availablePromos: availablePromos,
      appliedPromos: currentlyAppliedPromos,
      cartId: cart.cartId,
      cartType: currentTab,
    });
    onClose();
  };

  const isPromoCodeApplied = (promoCode) => {
    return cart?.appliedPromo?.includes(promoCode);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{ borderRadius: 2.4, mt: 1, padding: 1 }}
        onClick={() => setPromoSelectorOpen(true)}
      >
        <Grid container>
          <Grid item xs={11}>
            {currentlyAppliedPromosArray?.length > 0 ? (
              <Typography
                component={"span"}
                color={"secondary"}
                fontWeight={700}
                fontSize={"14px"}
              >
                {currentlyAppliedPromosArray?.length} promo code
                {currentlyAppliedPromosArray?.length > 1 ? "s" : ""} applied
              </Typography>
            ) : (
              <>
                <Typography
                  component={"span"}
                  fontWeight={700}
                  fontSize={"14px"}
                >
                  You have unlocked
                </Typography>
                <Typography
                  component={"span"}
                  color={"secondary"}
                  fontWeight={700}
                  pl={0.5}
                  fontSize={"14px"}
                >
                  {promosData?.totalCount} promo code
                  {promosData?.totalCount > 1 ? "s" : ""}
                </Typography>
              </>
            )}
            <Typography fontSize={"12px"} color={"#333333"}>
              Check Now
            </Typography>
          </Grid>
          <Grid item xs={1} textAlign={"end"}>
            <ChevronRightRoundedIcon
              fontSize={"large"}
              sx={{ color: "#666666" }}
            />
          </Grid>
        </Grid>
      </Card>

      <SwipeableDrawer
        anchor={`bottom`}
        open={open}
        PaperProps={{
          style: {
            borderRadius: "24px 24px 0px 0px",
            overflowY: "hidden",
          },
        }}
        onClose={handleOnClose}
        onOpen={() => {}}
        disableDiscovery={true}
        disableSwipeToOpen={true}
      >
        <Box sx={{ height: "85vh" }} role="presentation">
          <Box className={classes.header}>
            <Grid
              container
              alignItems={`center`}
              justifyContent={`space-between`}
              sx={{ padding: "0px 20px 10px" }}
            >
              <img src="/icons/coupon.svg" alt="" />
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "18px",
                  fontWeight: "700",
                  marginRight: "120px",
                }}
              >
                {`Apply Promos`}
              </Typography>
              <CloseIcon
                onClick={handleOnClose}
                className={classes.closeButton}
              />
            </Grid>
          </Box>

          <Grid
            container
            alignItems={"center"}
            sx={{ background: "#F3F3F3", padding: "8px 12px" }}
          >
            <Grid item xs={1} sx={{ display: "flex" }}>
              <img
                src="/icons/infoIcon.svg"
                style={{ height: "24px" }}
                alt="i"
              />
            </Grid>
            <Grid item xs={11} sx={{ pl: "4px" }}>
              <Typography sx={{ fontSize: "12px" }}>
                You can apply multiple promos
              </Typography>
            </Grid>
          </Grid>

          <BlockUi
            tag="div"
            blocking={promoApplyLoader === "started"}
            message={`Please wait...`}
            keepInView
          >
            <Box className={classes.content}>
              <FormControl fullWidth className={classes.formControl}>
                {_.map(promosData?.results, (promo, index) => (
                  <PromoCard
                    key={`${promo?.promoCodeDetails?.voucherCode}-${index}`}
                    promoCodeDetails={promo?.promoCodeDetails}
                    isApplied={isPromoCodeApplied(
                      promo?.promoCodeDetails?.voucherCode
                    )}
                    handleApplyOnPromoClicked={() => {
                      let updatedAppliedPromoCodes = currentlyAppliedPromos
                        ? `${currentlyAppliedPromos},${promo?.promoCodeDetails?.voucherCode}`
                        : promo?.promoCodeDetails?.voucherCode;
                      setCurrentlyAppliedPromos(updatedAppliedPromoCodes);
                      applyPromo(updatedAppliedPromoCodes);
                      AnalyticsService.pushEvent("Button Clicked", {
                        type: "Apply Promo",
                        drawerType: "promo",
                        availablePromos: availablePromos,
                        appliedPromos: updatedAppliedPromoCodes,
                        cartId: cart.cartId,
                        cartType: currentTab,
                      });
                    }}
                    handleRemoveOnPromoClicked={() => {
                      let updatedAppliedPromoCodes = currentlyAppliedPromos
                        .split(",")
                        .filter(
                          (promoCode) =>
                            promoCode !== promo?.promoCodeDetails?.voucherCode
                        )
                        .join(",");
                      setCurrentlyAppliedPromos(updatedAppliedPromoCodes);
                      applyPromo(updatedAppliedPromoCodes);
                      AnalyticsService.pushEvent("Button Clicked", {
                        type: "Remove Promo",
                        drawerType: "promo",
                        availablePromos: availablePromos,
                        appliedPromos: updatedAppliedPromoCodes,
                        cartId: cart.cartId,
                        cartType: currentTab,
                      });
                    }}
                  />
                ))}
              </FormControl>
            </Box>
          </BlockUi>
          <Box className={classes.footer}>
            <Grid
              container
              spacing={0}
              alignItems={`center`}
              justifyContent={`center`}
              textAlign={`right`}
            >
              <Button
                variant="contained"
                fullWidth
                color="primary"
                size={"medium"}
                onClick={() => {
                  AnalyticsService.pushEvent("Button Clicked", {
                    type: "Done",
                    drawerType: "promo",
                    availablePromos: availablePromos,
                    appliedPromos: currentlyAppliedPromos,
                    cartId: cart.cartId,
                    cartType: currentTab,
                  });
                  onClose();
                }}
                className={classes.applyButton}
              >
                Done
              </Button>
            </Grid>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default Promo;
