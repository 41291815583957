import { Box, Grid, Typography } from "@mui/material";
import AmountUtils from "../../Utils/AmountUtils";
import SchemesService from "../../Services/SchemesService";
import _ from "lodash";

const TodSettledSaleBreakupDetails = ({
  settledSaleDetails,
  tableHeaderBackground,
  tableBodyBackground,
}) => {
  const slabConstraintUnit =
    SchemesService.SLAB_CONSTRAINT_UNIT_MAP[
      settledSaleDetails?.constraintsType
    ];

  return (
    <>
      {settledSaleDetails?.settlementsCnDistributions?.length > 0 && (
        <>
          <Typography fontWeight={"bold"} marginBottom={2} marginTop={1}>
            Settlement Details
          </Typography>
          <Box sx={{ background: tableHeaderBackground, borderRadius: "16px" }}>
            <Grid
              container
              columns={12}
              justifyContent={"center"}
              sx={{
                background: tableBodyBackground,
                borderRadius: "16px 16px 0px 0px",
              }}
            >
              <Grid
                item
                xs={3}
                fontWeight={"bold"}
                sx={{
                  fontSize: "12px",
                  padding: "8px 6px",
                }}
              >
                Settled By
              </Grid>
              <Grid
                item
                xs={3}
                fontWeight={"bold"}
                sx={{
                  fontSize: "12px",
                  textAlign: "end",
                  padding: "8px 6px",
                }}
              >
                Settled Sales
              </Grid>
              <Grid
                item
                xs={3}
                fontWeight={"bold"}
                sx={{
                  fontSize: "12px",
                  textAlign: "end",
                  padding: "8px 6px",
                }}
              >
                Settled Amount
              </Grid>
              <Grid
                item
                xs={3}
                fontWeight={"bold"}
                sx={{
                  fontSize: "12px",
                  textAlign: "end",
                  padding: "8px 6px",
                }}
              >
                {"Credit Note"}
                {settledSaleDetails?.settlementsCnDistributions?.[0]
                  ?.qualifiedSlabCn !== null &&
                settledSaleDetails?.settlementsCnDistributions?.[0]
                  ?.qualifiedSlabCn !== undefined &&
                settledSaleDetails?.settlementsCnDistributions?.[0]?.cnType ? (
                  <Typography sx={{ fontSize: "12px", textAlign: "end" }}>
                    {settledSaleDetails?.settlementsCnDistributions?.[0]
                      ?.cnType === "CREDIT_MEMO_PER_QUANTITY"
                      ? `(₹${settledSaleDetails?.settlementsCnDistributions?.[0]?.qualifiedSlabCn} CN/Qty)`
                      : settledSaleDetails?.settlementsCnDistributions?.[0]
                            ?.cnType === "CREDIT_MEMO_PERCENT"
                        ? `(${settledSaleDetails?.settlementsCnDistributions?.[0]?.qualifiedSlabCn}% CN)`
                        : ""}
                  </Typography>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            {_.map(
              settledSaleDetails?.settlementsCnDistributions,
              (entitlement, index) => (
                <Grid
                  key={index}
                  container
                  columns={12}
                  justifyContent={"center"}
                  sx={{
                    borderBottom:
                      index !==
                      settledSaleDetails?.settlementsCnDistributions?.length - 1
                        ? `1px solid ${tableBodyBackground}`
                        : "",
                  }}
                >
                  <Grid
                    item
                    xs={3}
                    sx={{
                      fontSize: "12px",
                      padding: "8px 6px",
                    }}
                  >
                    {entitlement?.resonId ? entitlement?.resonId : `-`}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      fontSize: "12px",
                      textAlign: "end",
                      padding: "8px 6px",
                    }}
                  >
                    {slabConstraintUnit === `₹`
                      ? `₹${AmountUtils.format(entitlement?.settledSaleValue)}`
                      : `${entitlement?.settledSaleValue} ${slabConstraintUnit}`}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      fontSize: "12px",
                      textAlign: "end",
                      padding: "8px 6px",
                    }}
                  >
                    {"₹" + AmountUtils.format(entitlement?.settledAmount)}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      fontSize: "12px",
                      textAlign: "end",
                      padding: "8px 6px",
                    }}
                  >
                    {entitlement?.netCnAmount
                      ? "₹" + AmountUtils.format(entitlement?.netCnAmount)
                      : `-`}
                  </Grid>
                </Grid>
              )
            )}

            <Grid
              container
              columns={12}
              justifyContent={"center"}
              sx={{
                background: tableBodyBackground,
                borderRadius: "0px 0px 16px 16px",
              }}
            >
              <Grid
                item
                xs={3}
                fontWeight={"bold"}
                sx={{
                  fontSize: "12px",
                  padding: "8px 6px",
                }}
              >
                Total
              </Grid>
              <Grid
                item
                xs={3}
                fontWeight={"bold"}
                sx={{
                  fontSize: "12px",
                  textAlign: "end",
                  padding: "8px 6px",
                }}
              >
                {slabConstraintUnit === `₹`
                  ? `₹${AmountUtils.format(settledSaleDetails?.settledSaleValue)}`
                  : `${settledSaleDetails?.settledSaleValue} ${slabConstraintUnit}`}
              </Grid>
              <Grid
                item
                xs={3}
                fontWeight={"bold"}
                sx={{
                  fontSize: "12px",
                  textAlign: "end",
                  padding: "8px 6px",
                }}
              >
                {settledSaleDetails?.totalSettledAmount
                  ? "₹" +
                    AmountUtils.format(settledSaleDetails?.totalSettledAmount)
                  : `-`}
              </Grid>
              <Grid
                item
                xs={3}
                fontWeight={"bold"}
                sx={{
                  fontSize: "12px",
                  textAlign: "end",
                  padding: "8px 6px",
                }}
              >
                {settledSaleDetails?.totalCN
                  ? `₹${AmountUtils.format(settledSaleDetails?.totalCN)}`
                  : `-`}
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            alignItems={"center"}
            padding={1}
            mt={2}
            sx={{ background: "#FFDC42", borderRadius: "16px" }}
          >
            <Grid item xs={1} sx={{ display: "flex" }}>
              <img
                src="/icons/infoIcon.svg"
                style={{ height: "24px" }}
                alt="i"
              />
            </Grid>
            <Grid item xs={11} sx={{ pl: "4px" }}>
              <Typography sx={{ fontSize: "10px" }}>
                Any remaining credit note will be issued once all the scheme
                orders are settled
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default TodSettledSaleBreakupDetails;
