import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { WhatsappShareButton } from "react-share";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { ArrowForwardTwoTone } from "@mui/icons-material";

import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import useFeaturedVideos from "../../Hooks/useFeaturedVideos";
import useQuery from "../../Hooks/useQuery";
import useMessages from "../../Hooks/useMessages";
import ProductCard from "../Product/ProductCard";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  page: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
    color: "#000",
    backgroundColor: "#e6e6e6",
    willChange: "transform",
    position: "absolute",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    overflow: "scroll",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    scrollSnapType: "y mandatory",
    maxHeight: "1200px",
    background: "black",
  },
  swipeCard: {
    height: "100vh",
    minHeight: "100vh",
    background: "black",
    scrollSnapStop: "always",
    position: "relative",
    scrollSnapAlign: "start",
  },
  hint: {
    position: "absolute",
    bottom: "20%",
    fontSize: "16px",
    scrollSnapAlign: "start",
    scrollSnapStop: "always",
  },
  videoTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    color: "#FFFFFF",
    marginTop: "2px",
    position: "relative",
  },
  productTitle: {
    color: "#F6F6F6",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "-0.02em",
  },
  featureRedirectButton: {
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    width: "87vw",
    height: "40px",
    background: "#00733E",
    color: "white",
    marginTop: "20px",
  },
}));

function MyObserver({ selector, callback }) {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => callback(entries), {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    const element = document.querySelector(selector);

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
}

export default function VideoCard() {
  const classes = useStyles();
  const navigate = useNavigate();
  let query = useQuery();
  const { featuredVideos, loading } = useFeaturedVideos();
  const { getMessage } = useMessages();
  let [selectedVideoId, setSelectedVideoId] = useState("slide-0");
  let [playingStatus, setPlayingStatus] = useState(false);
  let [playById, setPlayById] = useState(false);
  let [muteState, setMuteState] = useState(false);
  let [currentVideo, setCurrentVideo] = useState({});

  const handleWhatsappPromote = (title, link, ranking) => {
    const featuredVideoWhatsappShareData = {
      farmerId: localStorage.getItem("farmerId"),
      page: "/featured-videos",
      videoUrl: link,
      videoTitle: title,
      ranking: ranking,
      source: localStorage.getItem("source"),
    };

    AnalyticsService.pushEvent(
      `Featured Video Share Icon Clicked`,
      featuredVideoWhatsappShareData
    );
  };

  const handleFeatureRedirectClck = (redirectionLink, link, title, ranking) => {
    const featuredVideoRedirectionData = {
      farmerId: localStorage.getItem("farmerId"),
      page: "/featured-videos",
      videoUrl: link,
      videoTitle: title,
      ranking: ranking,
      source: localStorage.getItem("source"),
    };

    AnalyticsService.pushEvent(
      `Featured Video Redirection Link Clicked`,
      featuredVideoRedirectionData
    );

    navigate({
      pathname: redirectionLink?.toString()?.split(".in")[1],
    });
  };

  useEffect(() => {
    const sections = document.querySelectorAll(".swipeCard");

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    if (loading === "done") {
      let index;

      if (!featuredVideos) {
        setPlayById(true);
        index = -1;
      }
      let selectedVideo = query.get("youtubeId");
      if (selectedVideo && featuredVideos) {
        index = featuredVideos.findIndex(
          (video) =>
            video?.link
              .split("/")
              [video?.link.split("/").length - 1].replace("watch?v=", "") ===
            selectedVideo
        );
        if (index === -1) {
          setPlayById(true);
        }
      }
      const observer = new IntersectionObserver(function (entries) {
        let data = [];

        // setPlayingStatus(false);
        entries.forEach((entry) => {
          if (entry?.isIntersecting) {
            setSelectedVideoId(entry?.target?.id);
            let youtubeId =
              featuredVideos[entry?.target?.id.replace("slide-", "")]?.link;
            youtubeId = youtubeId
              .split("/")
              [youtubeId.split("/").length - 1].replace("watch?v=", "");
            navigate(
              {
                search: `youtubeId=${youtubeId}`,
              },
              { replace: true }
            );
            setPlayingStatus(true);
          }
        });
      }, options);

      sections.forEach((section) => {
        let intersectedVideos = observer.observe(section);
      });
      let featuredVideoPageViewedData;
      if (featuredVideos && index !== -1 && selectedVideo) {
        const element = document.getElementById(`slide-${index}`);
        element.scrollIntoView();
        featuredVideoPageViewedData = {
          farmerId: localStorage.getItem("farmerId"),
          page: "/featured-videos",
          videoUrl: featuredVideos[0]?.link,
          videoTitle: featuredVideos[0]?.videoTitle,
          ranking: featuredVideos[0]?.ranking,
          source: localStorage.getItem("source"),
        };
      } else {
        featuredVideoPageViewedData = {
          farmerId: localStorage.getItem("farmerId"),
          page: "/featured-videos",
          videoUrl: `https://www.youtube.com/watch?v=${query.get(
            "youtubeId"
          )}?fs=0`,
          videoTitle: `Agrostar Saathi Featured Video`,
          source: localStorage.getItem("source"),
        };
      }
      AnalyticsService.pushEvent(
        `Featured Video Page Viewed`,
        featuredVideoPageViewedData
      );
    }
  }, [loading === "done"]);

  const onMuted = (videoUrl, videoTitle, ranking) => {
    const featuredVideoMutedData = {
      farmerId: localStorage.getItem("farmerId"),
      page: "/featured-videos",
      videoUrl: videoUrl,
      videoTitle: videoTitle,
      ranking: ranking,
      source: localStorage.getItem("source"),
    };

    AnalyticsService.pushEvent(`Featured Video Muted`, featuredVideoMutedData);

    return true;
  };

  return (
    <div id="container" className={classes.container}>
      {loading === "done" && !playById && featuredVideos?.length > 0 && (
        <>
          <div>
            {_.map(featuredVideos, (video, index) => {
              return (
                <>
                  <div
                    className="swipeCard"
                    selectedVideo
                    id={`slide-${index}`}
                    key={index}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        zIndex: "2",
                      }}
                    >
                      <img
                        src="/icons/whiteCross.svg"
                        color="white"
                        height="22px"
                        onClick={() => {
                          const featuredVideoClosedButtonData = {
                            farmerId: localStorage.getItem("farmerId"),
                            page: "/featured-videos",
                            videoUrl: video?.link,
                            videoTitle: video?.videoTitle,
                            ranking: video?.ranking,
                            source: localStorage.getItem("source"),
                          };

                          AnalyticsService.pushEvent(
                            `Featured Video Closed Button Clicked`,
                            featuredVideoClosedButtonData
                          );
                          navigate(-1);
                        }}
                      />
                    </Box>
                    <Box sx={{ pt: 9 }}>
                      {selectedVideoId === `slide-${index}` ? (
                        <>
                          {playingStatus ? (
                            <>
                              <ReactVideoPlayer
                                playing={playingStatus ? true : false}
                                video={{ src: `${video.link}?fs=0` }}
                                fullscreen={false}
                                muted={
                                  // false
                                  muteState
                                    ? onMuted(
                                        video?.link,
                                        video?.videoTitle,
                                        video?.ranking
                                      )
                                    : false
                                }
                                playbackStartedEventName="Featured Video Playback Started"
                                playbackEndedEventName="Featured Video Playback Ended"
                                playbackPausedEventName="Featured Video Playback Paused"
                                eventProperties={{
                                  farmerId: localStorage.getItem("farmerId"),
                                  page: "/featured-videos",
                                  videoUrl: video?.link,
                                  videoTitle: video?.videoTitle,
                                  ranking: video?.ranking,
                                  source: localStorage.getItem("source"),
                                }}
                                handlePlaybackReady={() => {
                                  let currentVideoObj = {
                                    link: video?.link,
                                    videoTitle: video?.videoTitle,
                                    ranking: video?.ranking,
                                  };
                                  setCurrentVideo(currentVideoObj);
                                }}
                                height="218px"
                              />

                              <Box sx={{ pl: 3, pt: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      justifyContent: "flex-start",
                                      display: "flex",
                                      width: "70%",
                                    }}
                                  >
                                    {video.videoTitle && (
                                      <Typography
                                        className={classes.videoTitle}
                                        style={{
                                          marginLeft: `${
                                            video?.productGists.length > 0
                                              ? "-8px"
                                              : ""
                                          }`,
                                          marginBottom: "5px",
                                        }}
                                      >
                                        {video.videoTitle}
                                      </Typography>
                                    )}
                                  </Box>

                                  <Box
                                    className={classes.whatsappPromote}
                                    sx={{
                                      justifyContent: "flex-end",
                                      display: "flex",
                                    }}
                                  >
                                    <WhatsappShareButton
                                      url={`.`}
                                      title={`${getMessage("video.title")} *${
                                        video?.videoTitle
                                      }*\r\n${video?.link}`}
                                    >
                                      <Box
                                        onClick={() => {
                                          handleWhatsappPromote(
                                            video?.videoTitle,
                                            video?.link,
                                            video?.ranking
                                          );
                                        }}
                                        style={{
                                          textTransform: "capitalize",
                                          color: "#333",
                                          marginRight: "20px",
                                          marginBottom: "-14px",
                                        }}
                                      >
                                        <img
                                          src="/icons/videoShareIcon.svg"
                                          style={{ height: "48px" }}
                                        />
                                      </Box>
                                      {/* <Button
                                        size={`small`}
                                        variant={"contained"}
                                        color={"primary"}
                                        fullWidth={false}
                                        type={`submit`}
                                        onClick={() => {
                                          handleWhatsappPromote(
                                            video?.videoTitle,
                                            video?.link,
                                            video?.ranking
                                          );
                                        }}
                                        style={{
                                          textTransform: "capitalize",
                                          background: "white",
                                          color: "#333",
                                          lineHeight: "2",
                                        }}
                                        startIcon={
                                          <img
                                            src="/icons/whatsappVideo.svg"
                                            height={`22px`}
                                          />
                                        }
                                      >
                                        Share
                                      </Button> */}
                                    </WhatsappShareButton>
                                  </Box>
                                </Box>

                                <Grid
                                  container
                                  direction="column"
                                  sx={{
                                    flexWrap: "wrap",
                                  }}
                                  spacing={1}
                                >
                                  {video?.productGists.length > 0 && (
                                    <>
                                      <Typography
                                        sx={{ mt: 2 }}
                                        className={classes.productTitle}
                                      >
                                        Products in this video
                                      </Typography>
                                      <Box
                                        className="productContainer"
                                        sx={{
                                          display: "flex",
                                          gap: 1,
                                          gridTemplateColumns: `repeat(${Math.round(
                                            video?.productGists.length / 2
                                          )}, 1fr)`,
                                          width: "93vw",

                                          overflowX: "auto",
                                          mt: 1,
                                          mb: 20,
                                        }}
                                      >
                                        {_.map(
                                          video?.productGists.slice(0, 2),
                                          (product, productIndex) => {
                                            return (
                                              <>
                                                <Box
                                                  key={product.skuCode}
                                                  sx={{
                                                    width: "44vw",
                                                  }}
                                                >
                                                  <ProductCard
                                                    product={product}
                                                    path={`/featured-videos`}
                                                  />
                                                </Box>
                                              </>
                                            );
                                          }
                                        )}
                                      </Box>
                                    </>
                                  )}
                                </Grid>

                                {video?.productGists.length === 0 &&
                                  video?.redirectionLink && (
                                    // <Button
                                    //   size={`large`}
                                    //   variant={"contained"}
                                    //   color={"secondary"}
                                    //   endIcon={<ArrowForwardTwoTone />}
                                    //   onClick={() => {
                                    //     handleFeatureRedirectClck(
                                    //       video?.redirectionLink,
                                    //       video?.link,
                                    //       video?.videoTitle,
                                    //       video?.ranking
                                    //     );
                                    //   }}
                                    //   sx={{ mt: 2 }}
                                    //   className={classes.featureRedirectButton}
                                    // >
                                    <div
                                      style={{
                                        color: "white",
                                        textDecoration: "underline",
                                        width: "76%",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                      onClick={() => {
                                        handleFeatureRedirectClck(
                                          video?.redirectionLink,
                                          video?.link,
                                          video?.videoTitle,
                                          video?.ranking
                                        );
                                      }}
                                    >
                                      {video?.redirectionLinkText
                                        ? video?.redirectionLinkText
                                        : "Click Kare"}
                                    </div>
                                    // </Button>
                                  )}
                                {/* {arrowState && featuredVideos.length > 0 && ( */}
                                <Box
                                  sx={{
                                    position: "absolute",
                                    bottom: "10px",
                                    zIndex: "2",
                                    left: "47%",
                                  }}
                                  className="arrowAnimation"
                                >
                                  <img src="/icons/downArrow.svg" />
                                </Box>
                                {/* )} */}
                              </Box>
                            </>
                          ) : (
                            <>
                              <Skeleton
                                variant={`text`}
                                width={`92vw`}
                                style={{
                                  marginLeft: "16px",
                                  marginTop: "8px",
                                  backgroundColor: "grey",
                                  height: "60px",
                                }}
                              />
                              <Box sx={{ padding: "15px" }}>
                                <Grid
                                  container
                                  direction={`column`}
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                  wrap={"nowrap"}
                                >
                                  <Grid item>
                                    <Skeleton
                                      variant={`rectangular`}
                                      height={250}
                                      width={`92vw`}
                                      style={{ backgroundColor: "grey" }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                              <Skeleton
                                variant={`text`}
                                width={`92vw`}
                                style={{
                                  marginLeft: "16px",
                                  marginTop: "4px",
                                  backgroundColor: "grey",
                                  height: "60px",
                                }}
                              />
                              <Skeleton
                                variant={`text`}
                                width={`92vw`}
                                style={{
                                  marginLeft: "16px",
                                  marginTop: "4px",
                                  backgroundColor: "grey",
                                  height: "60px",
                                }}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Skeleton
                            variant={`text`}
                            width={`92vw`}
                            style={{
                              marginLeft: "16px",
                              marginTop: "8px",
                              backgroundColor: "grey",
                              height: "60px",
                            }}
                          />
                          <Box sx={{ padding: "15px" }}>
                            <Grid
                              container
                              direction={`column`}
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              wrap={"nowrap"}
                            >
                              <Grid item>
                                <Skeleton
                                  variant={`rectangular`}
                                  height={250}
                                  width={`92vw`}
                                  style={{ backgroundColor: "grey" }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Skeleton
                            variant={`text`}
                            width={`92vw`}
                            style={{
                              marginLeft: "16px",
                              marginTop: "4px",
                              backgroundColor: "grey",
                              height: "60px",
                            }}
                          />
                          <Skeleton
                            variant={`text`}
                            width={`92vw`}
                            style={{
                              marginLeft: "16px",
                              marginTop: "4px",
                              backgroundColor: "grey",
                              height: "60px",
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </div>
                  );
                </>
              );
            })}

            <div
              className={classes.swipeCard}
              id={`slide-${featuredVideos?.length + 1}`}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100vh",
                  my: 2,
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: "74px",
                      width: "74px",
                      background: "#444444",
                      borderRadius: "50%",
                      left: "0px",
                      right: "0px",
                      display: "flex",
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Box sx={{ alignSelf: "center" }}>
                      <img src="/icons/celebration.svg" />
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      mt: 1,
                    }}
                  >
                    You have seen all the videos!
                  </Typography>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: "14px",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Come back for more videos
                  </Typography>
                  <Button
                    size={`large`}
                    variant={"contained"}
                    color={"primary"}
                    sx={{ mt: 1 }}
                    endIcon={<ArrowForwardTwoTone />}
                    onClick={() => {
                      // navigate("/");
                      if (window.history.length === 1) {
                        navigate("/");
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    Go Back
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
          {/* );
            }}
          </InView> */}
        </>
      )}

      {(loading === "loading" || loading === "started") &&
        featuredVideos?.length === 0 && (
          <>
            <Skeleton
              variant={`text`}
              width={`92vw`}
              style={{
                marginLeft: "16px",
                marginTop: "8px",
                backgroundColor: "grey",
                height: "60px",
              }}
            />
            <Box sx={{ padding: "15px" }}>
              <Grid
                container
                direction={`column`}
                justifyContent="flex-start"
                alignItems="flex-start"
                wrap={"nowrap"}
              >
                <Grid item>
                  <Skeleton
                    variant={`rectangular`}
                    height={250}
                    width={`92vw`}
                    style={{ backgroundColor: "grey" }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Skeleton
              variant={`text`}
              width={`92vw`}
              style={{
                marginLeft: "16px",
                marginTop: "4px",
                backgroundColor: "grey",
                height: "60px",
              }}
            />
            <Skeleton
              variant={`text`}
              width={`92vw`}
              style={{
                marginLeft: "16px",
                marginTop: "4px",
                backgroundColor: "grey",
                height: "60px",
              }}
            />
          </>
        )}

      {loading === "done" && playById && (
        <div className="swipeCard">
          <Box
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
              zIndex: "2",
            }}
          >
            <img
              src="/icons/whiteCross.svg"
              color="white"
              height="22px"
              onClick={() => {
                const featuredVideoClosedButtonData = {
                  farmerId: localStorage.getItem("farmerId"),
                  page: "/featured-videos",
                  videoUrl: `https://www.youtube.com/watch?v=${query.get(
                    "youtubeId"
                  )}?fs=0`,
                  source: localStorage.getItem("source"),
                };

                AnalyticsService.pushEvent(
                  `Featured Video Closed Button Clicked`,
                  featuredVideoClosedButtonData
                );
                navigate(-1);
              }}
            />
          </Box>
          <Box sx={{ pt: 9 }}>
            <ReactVideoPlayer
              video={{
                src: `https://www.youtube.com/watch?v=${query.get(
                  "youtubeId"
                )}?fs=0`,
              }}
              fullscreen={false}
              playing={playingStatus ? true : false}
              muted={playingStatus ? true : false}
              handlePlaybackReady={() => {
                setPlayingStatus(true);
              }}
              height="218px"
              playbackStartedEventName="Featured Video Playback Started"
              playbackEndedEventName="Featured Video Playback Ended"
              playbackPausedEventName="Featured Video Playback Paused"
              eventProperties={{
                farmerId: localStorage.getItem("farmerId"),
                page: "/featured-videos",
                videoUrl: `https://www.youtube.com/watch?v=${query.get(
                  "youtubeId"
                )}?fs=0`,
                source: localStorage.getItem("source"),
              }}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  display: "flex",
                  width: "70%",
                }}
              >
                <Typography
                  className={classes.videoTitle}
                  style={{ marginLeft: "20px" }}
                >
                  Agrostar Saathi Featured Video
                </Typography>
              </Box>

              <Box
                className={classes.whatsappPromote}
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <WhatsappShareButton
                  url={`.`}
                  title={`${getMessage(
                    "video.title"
                  )} *Agrostar Saathi Featured Video*\r\nhttps://www.youtube.com/watch?v=${query.get(
                    "youtubeId"
                  )}`}
                >
                  <Box
                    onClick={() => {
                      handleWhatsappPromote(
                        "Agrostar Saathi Featured Video",
                        `https://www.youtube.com/watch?v=${query.get(
                          "youtubeId"
                        )}?fs=0`,
                        ""
                      );
                    }}
                    style={{
                      textTransform: "capitalize",
                      color: "#333",
                      lineHeight: "2",
                      marginTop: "6px",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      src="/icons/videoShareIcon.svg"
                      style={{ height: "48px" }}
                    />
                  </Box>
                </WhatsappShareButton>
              </Box>
            </Box>
          </Box>
        </div>
      )}

      {loading === "done" && !playById && featuredVideos?.length === 0 && (
        <div className={classes.swipeCard}>
          <Box
            sx={{
              display: "flex",
              height: "100vh",
              my: 2,
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "white",
                  mt: 1,
                }}
              >
                Sorry No Videos Found!
              </Typography>

              <Box>
                <Button
                  size={`large`}
                  variant={"contained"}
                  color={"primary"}
                  sx={{ mt: 1 }}
                  endIcon={<ArrowForwardTwoTone />}
                  onClick={(e) => {
                    const featuredVideoGoBackData = {
                      farmerId: localStorage.getItem("farmerId"),
                      page: "/featured-videos",
                      source: localStorage.getItem("source"),
                    };

                    AnalyticsService.pushEvent(
                      `Featured Video Playback Paused`,
                      featuredVideoGoBackData
                    );
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
}
