const IframeContent = (props) => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <iframe
        src={props.iframeData.src}
        className={props.iframeData.iframeClass}
        onLoad={() => {
          window.parent.scrollTo(0, 0);
        }}
      >
        Loading…
      </iframe>
    </>
  );
};
export default IframeContent;
