import * as React from "react";
import { Box, Container } from "@mui/material";
import { Card } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";

import AnalyticsService from "../../Services/AnalyticsService";
import HorizontalScrollableBanner from "../Common/HorizontalScrollableBanners";
import SectionTitle from "../Common/SectionTitle";
import _ from "lodash";
import EventBasedSaleTheme from "../../Theme/EventBasedSaleTheme";

const useStyles = makeStyles((theme) => ({}));

export default function HorizontalScrollableBanners(props) {
  const classes = useStyles();

  return (
    <>
      {props?.banners?.length > 0 && (
        <>
          {props.showBackgroundImage ? (
            <Box
              sx={{
                marginTop: 3,
                backgroundImage: `url(${EventBasedSaleTheme?.eventScrollBackgroundImage})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Container maxWidth={`xl`}>
                <SectionTitle title={props.sectionTitle} />
              </Container>
              <HorizontalScrollableBanner
                banners={props.banners}
                loadingBanners={props.loadingBanners}
                page={props?.page}
                showBackgroundImage={props.showBackgroundImage}
              />
            </Box>
          ) : (
            <Box sx={{ marginTop: 1 }}>
              <Container maxWidth={`xl`}>
                <SectionTitle title={props.sectionTitle} />
              </Container>
              <HorizontalScrollableBanner
                banners={props.banners}
                loadingBanners={props.loadingBanners}
                page={props?.page}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
