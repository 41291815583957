import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SectionTitle from "../Common/SectionTitle";
import AmountUtils from "../../Utils/AmountUtils";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  temp: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "2.5%",
      width: "94%",
      background:
        "radial-gradient(ellipse at center, #F3F3F3 0%, #F3F3F3 40%, transparent 8%)",
      backgroundSize: "calc(94% / 12) 100%",
      backgroundRepeat: "repeat-x",
      transform: "translate(0, -50%)",
      aspectRatio: 12,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      left: "2.5%",
      width: "94%",
      background:
        "radial-gradient(ellipse at center, #F3F3F3 0%, #F3F3F3 40%, transparent 8%)",
      backgroundSize: "calc(94% / 12) 100%",
      backgroundRepeat: "repeat-x",
      transform: "translate(0, 50%)",
      aspectRatio: 12,
    },
  },
  billSection: {
    marginTop: theme.spacing(3),
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "none",
    fontWeight: 400,
  },
  totalValueText: {
    padding: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
  },
  totalValue: {
    fontSize: "18px",
    fontWeight: 700,
  },
  billSectionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    padding: theme.spacing(0.5),
  },
  discountSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    padding: theme.spacing(0.5),
    color: "#00733E",
  },
}));

export function BillDetailPriceItem({ title, subtitle, value }) {
  return (
    <>
      <Grid
        container
        alignItems={`center`}
        sx={{ margin: "0px 16px", width: "auto" }}
      >
        <Grid item xs={8}>
          <Typography
            variant={`body1`}
            sx={{ fontSize: "16px" }}
            textAlign={`start`}
          >
            {title}
          </Typography>

          {subtitle && (
            <Typography
              variant={`body2`}
              sx={{
                marginTop: "-4px",
                fontSize: "12px",
              }}
              textAlign={`start`}
            >
              {subtitle}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant={`body1`}
            sx={{ fontSize: "16px" }}
            textAlign={`end`}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          borderTop: "1px dashed #00000080",
          margin: "8px 16px",
        }}
      />
    </>
  );
}

export function BillDetailFinalPriceItem({ title, subtitle, value }) {
  return (
    <Grid
      container
      alignItems={`center`}
      sx={{
        backgroundColor: "#DED7BD",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px",
        marginTop: -1,
      }}
    >
      <Grid item xs={8}>
        <Typography
          variant={`body1`}
          sx={{ fontSize: "16px", fontWeight: 700 }}
          textAlign={`start`}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant={`body1`}
          sx={{ fontSize: "16px", fontWeight: 700 }}
          textAlign={`end`}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

function BillDetails({ cart, isPurchaseRequest }) {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={"xl"} className={classes.billSection}>
        <SectionTitle title={`Bill Details`} />
      </Container>

      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#EAE2C7",
          padding: "8px",
          border: "none",
          fontWeight: 400,
          marginBottom: "16px",
          marginTop: "12px",
          borderRadius: "0px",
        }}
        className={classes.temp}
      >
        <CardContent sx={{ color: "#333333", padding: 0, marginTop: "16px" }}>
          <BillDetailPriceItem
            title={`Sub Total`}
            value={`${AmountUtils.formatCurrency(cart.billDetails?.subTotal)}`}
          />

          {cart.billDetails?.usableAgroStarPoints > 0 && (
            <BillDetailPriceItem
              title={`AgroStar Points`}
              value={`- ${AmountUtils.formatCurrency(
                cart.billDetails?.usableAgroStarPoints
              )}`}
            />
          )}

          {cart?.totalDiscount > 0 && (
            <BillDetailPriceItem
              title={`Discount Amount`}
              value={`- ${AmountUtils.formatCurrency(cart?.totalDiscount)}`}
            />
          )}

          {/* {cart.billDetails?.couponDiscount > 0 && (
            <BillDetailPriceItem
              title={`Coupon Discount`}
              value={`- ${AmountUtils.formatCurrency(
                cart.billDetails?.couponDiscount
              )}`}
            />
          )} */}

          {/* {cart.billDetails?.promoDiscount > 0 && (
            <BillDetailPriceItem
              title={`Promo Discount`}
              value={`- ${AmountUtils.formatCurrency(
                cart.billDetails?.promoDiscount
              )}`}
            />
          )} */}

          {/* {cart.billDetails?.totalOfferDiscount > 0 && (
            <BillDetailPriceItem
              title={`Total Offer Discount`}
              value={`- ${AmountUtils.formatCurrency(
                cart.billDetails?.totalOfferDiscount
              )}`}
            />
          )} */}
          {/* {cart.billDetails?.netLandingDiscount > 0 && (
            <BillDetailPriceItem
              title={`Total Net Landing Discount`}
              value={`- ${AmountUtils.formatCurrency(
                cart.billDetails?.netLandingDiscount
              )}`}
            />
          )} */}

          <BillDetailFinalPriceItem
            title={`Total Order Amount`}
            value={`${AmountUtils.formatCurrency(
              cart.billDetails?.totalBillAmount
            )}`}
          />

          <Box
            sx={{
              borderTop: "1px dashed #00000080",
              marginBottom: "8px",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          />

          {cart?.b2bTcsAmount > 0 && (
            <BillDetailPriceItem
              title={`TCS`}
              value={`${AmountUtils.formatCurrency(cart.b2bTcsAmount)}`}
            />
          )}

          {cart.billDetails?.usableRealCashAmount > 0 && (
            <BillDetailPriceItem
              title={`Real Cash Applied`}
              value={`- ${AmountUtils.formatCurrency(
                cart.billDetails?.usableRealCashAmount
              )}`}
            />
          )}

          <BillDetailPriceItem
            title={`Total Payable Amount`}
            value={`${AmountUtils.formatCurrency(
              cart.billDetails.saathiCreditAmount
            )}`}
          />

          {(cart.totalSavings ||
            cart.totalCashback ||
            cart.cashDiscountBenefit?.amount) > 0 && (
            <Box
              sx={{
                marginTop: 2.5,
                paddingTop: 1,
                paddingBottom: "8px",
                paddingLeft: "12px",
                paddingRight: "12px",
                backgroundColor: "rgba(0, 115, 62, 0.2)",
                borderRadius: "10px",
                color: "#00733E",
              }}
            >
              {cart.totalSavings > 0 && (
                <Grid container sx={{ marginTop: 0.5, marginBottom: 0.5 }}>
                  <Grid item xs={9}>
                    <Typography
                      variant={`h5`}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        display: "inline-block",
                      }}
                      textAlign={`start`}
                    >
                      Total Saving
                    </Typography>
                    <Typography
                      variant={`caption`}
                      sx={{
                        display: "inline-block",
                      }}
                      textAlign={`start`}
                    >
                      &nbsp;(Discount)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant={`h5`}
                      sx={{ fontSize: "16px", fontWeight: 700 }}
                      textAlign={`end`}
                    >
                      {AmountUtils.formatCurrency(cart.totalSavings)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {cart.totalCashback > 0 && (
                <Grid container sx={{ marginTop: 0.8, marginBottom: 0.5 }}>
                  <Grid item xs={9}>
                    <Typography
                      variant={`h5`}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        display: "inline-block",
                      }}
                      textAlign={`start`}
                    >
                      Total Cashback
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant={`h5`}
                      sx={{ fontSize: "16px", fontWeight: 700 }}
                      textAlign={`end`}
                    >
                      {AmountUtils.formatCurrency(cart.totalCashback)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {cart.cashDiscountBenefit?.amount > 0 && (
                <Grid container sx={{ marginTop: 1, marginBottom: 0.5 }}>
                  <Grid item xs={9}>
                    <Typography
                      variant={`h5`}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                      textAlign={`start`}
                    >
                      Total CD Benefit
                    </Typography>
                    <Typography
                      variant={`subtitle2`}
                      textAlign={`start`}
                      sx={{
                        verticalAlign: "start",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      &nbsp;(On Advanced Pay)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant={`h5`}
                      sx={{ fontSize: "16px", fontWeight: 700 }}
                      textAlign={`end`}
                    >
                      {AmountUtils.formatCurrency(
                        cart.cashDiscountBenefit?.amount
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
      <Container maxWidth={`xl`}>
        {!isPurchaseRequest &&
          !_.isNil(cart.cashDiscountBenefit?.percentage) &&
          cart.cashDiscountBenefit?.percentage > 0 && (
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "#F8D149",
                padding: "8px",
                border: "none",
                fontWeight: 400,
                marginBottom: "16px",
                marginTop: "12px",
                borderRadius: "10px",
                color: "#00733E",
                fontSize: "14px",
              }}
            >
              <Grid container alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={12}>
                  <Typography variant={`body2`} textAlign={`center`}>
                    <b>{cart.cashDiscountBenefit.message}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}

        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent={"flex-start"}
          sx={{ marginTop: -1 }}
        >
          <Grid item xs={1} sx={{ marginTop: 0.4 }}>
            <img src="/icons/shipping.svg" alt="shippingIcon" />
          </Grid>
          <Grid item xs={10} sx={{ marginLeft: 1.5 }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: 400,
                display: "inline-block",
              }}
            >
              Estimated Delivery:
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                color: "#000000",
                fontSize: "14px",
                display: "inline-block",
              }}
            >
              &nbsp;
              {cart.estimatedDeliveryDays
                ? cart.estimatedDeliveryDays
                : "Currently Unavailable"}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default BillDetails;
