import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlockUi from "react-block-ui";
import _ from "lodash";
import { Grid, Typography, CardContent, Box } from "@mui/material";
import OrderService from "../../Services/OrderService";
import TopBar from "../Common/TopBar";
import ErrorBoundary from "../Common/ErrorBoundary";
import ReturnOrderStatusIcons from "./ReturnOrderStatusIcons";
import ReturnOrderDetailCard from "../Common/ReturnOrderDetailCard";
import NeedHelp from "../Common/NeedHelp";
import CommonConstantValues from "../../Services/CommonConstantValues";

function ReturnOrderDetails() {
  const { returnOrderID } = useParams();
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(CommonConstantValues.NOT_STARTED);

  useEffect(() => {
    if (localStorage.getItem("createReturnInitiated")) {
      localStorage.removeItem("createReturnInitiated");
    }
    localStorage.setItem("visitedReturnOrderDetailsPage", true);
    setLoading(CommonConstantValues.STARTED);
    const { axiosRequest, abortController } =
      OrderService.getReturnOrderDetails(returnOrderID);
    axiosRequest
      .then(({ data }) => {
        setLoading(CommonConstantValues.DONE);
        let updatedOrderDetails = data?.responseData;
        updatedOrderDetails.statusDetails =
          ReturnOrderStatusIcons[data?.responseData?.status];
        setOrderDetails(updatedOrderDetails);
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading(CommonConstantValues.DONE);
        }
      });
    return () => abortController.abort();
  }, []);

  return (
    <Box>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Return Order Details`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <BlockUi
          tag="div"
          blocking={
            loading === CommonConstantValues.STARTED &&
            loading !== CommonConstantValues.DONE
          }
          message={`Loading...`}
          style={{ height: "100vh" }}
        >
          <CardContent>
            <ReturnOrderDetailCard
              order={orderDetails}
              returnOrderID={returnOrderID}
              returnOrderDetailsCart={true}
            />
          </CardContent>

          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
              marginLeft: "23px",
            }}
          >
            Items in this Order
          </Typography>

          {_.map(orderDetails?.orderItems, (itemDetails) => {
            return (
              <>
                <Grid
                  container
                  sx={{
                    margin: "10px 0px",
                    display: "flex",
                    padding: "8px",
                    height: "90px",
                    border: "1px solid #EEEEEE",
                    background: "#FFFFFF",
                  }}
                >
                  <Grid
                    item
                    xs={2}
                    sx={{
                      height: "100%",
                    }}
                  >
                    <img
                      src={itemDetails?.productImage}
                      alt=""
                      width={"100%"}
                      height="100%"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      paddingLeft: "10px",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        wordBreak: "break-all",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {itemDetails?.productName}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#4F4F4F",
                      }}
                    >
                      Quantity - {itemDetails?.quantity}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            );
          })}
        </BlockUi>
      </ErrorBoundary>

      <ErrorBoundary>
        <NeedHelp title={`Need Help`} />
      </ErrorBoundary>
    </Box>
  );
}

export default ReturnOrderDetails;
