import React from "react";
import _ from "lodash";
import {
  Card,
  InputLabel,
  Button,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import ChangeQuantity from "../Product/ChangeQuantity";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CommonConstantValues from "../../Services/CommonConstantValues";
import PartnerService from "../../Services/PartnerService";
import AmountUtils from "../../Utils/AmountUtils";

function SelectProductCart({
  productListToShowOnUI,
  isClickedOnCard,
  activeSkuCode,
  handleClickedOnCard,
  productCartList,
  handleRemoveProductFromCart,
  quantity,
  handleAdd,
  handleRemove,
  handleChange,
  setQuantity,
  minimumOrderQuantity,
  selectedReason,
  onChangeReturnReason,
  returnReasons,
  updateCart,
}) {
  return (
    <div>
      {_.map(productListToShowOnUI, (productDetail) => {
        return (
          <>
            <Grid
              container
              sx={{
                margin: "10px 0px",
                display: "flex",
                padding: "8px",
                background:
                  !productDetail?.IsCartPresent ||
                  (isClickedOnCard && activeSkuCode === productDetail?.sku_code)
                    ? "#FFFFFF"
                    : "#CACACA",
                borderRadius: "10px",
                border: "1px solid #EEEEEE",
              }}
              onClick={() =>
                handleClickedOnCard(
                  productDetail?.sku_code || productDetail?.skuCode,
                  productDetail
                )
              }
            >
              <Grid
                item
                xs={3}
                sx={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "5px",
                  background: "#fff",
                  textAlign: "center",
                  height: "83px",
                }}
              >
                <img
                  src={productDetail?.productImage}
                  alt=""
                  width={"auto"}
                  height="100%"
                />
              </Grid>
              <Grid
                item
                xs={9}
                container
                sx={{ height: "100%", paddingLeft: "10px" }}
              >
                <Grid item xs={9}>
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {productDetail?.productName}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "right" }}>
                  <CheckCircle
                    color={
                      productDetail?.IsCartPresent ? "secondary" : "disabled"
                    }
                    sx={{ fontSize: "27px" }}
                  />
                </Grid>
                {productDetail?.IsCartPresent && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#4F4F4F",
                        fontWeight: "700",
                      }}
                    >
                      Return Quantity -{" "}
                      {
                        productCartList.find(
                          (item) => item?.skuCode === productDetail?.sku_code
                        )?.quantity
                      }
                    </Typography>

                    {PartnerService.checkIfFieldAgent() && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#4F4F4F",
                          fontWeight: "700",
                        }}
                      >
                        Approx Return Amount -{" "}
                        {AmountUtils.formatCurrency(
                          productCartList.find(
                            (item) => item?.skuCode === productDetail?.sku_code
                          )?.returnableAmount || 0
                        )}
                      </Typography>
                    )}

                    <Grid item xs={9}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#4F4F4F",
                          fontWeight: "700",
                        }}
                      >
                        Reason -{" "}
                        {
                          productCartList.find(
                            (item) => item?.skuCode === productDetail?.sku_code
                          )?.remarks
                        }
                      </Typography>
                    </Grid>

                    <Grid item xs={3} sx={{ position: "relative" }}>
                      {productDetail?.IsCartPresent &&
                      isClickedOnCard &&
                      activeSkuCode === productDetail?.sku_code ? (
                        <></>
                      ) : (
                        productDetail?.IsCartPresent && (
                          <Typography
                            sx={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#9D2123",
                              position: "absolute",
                              right: 0,
                              bottom: 0,
                            }}
                            onClick={(event) =>
                              handleRemoveProductFromCart(event, productDetail)
                            }
                          >
                            Remove
                          </Typography>
                        )
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {isClickedOnCard && activeSkuCode === productDetail?.sku_code && (
              <Card
                sx={{
                  marginTop: "-17px",
                  padding: "9px 17px",
                }}
              >
                <Grid>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Grid item xs={5}>
                      <Typography sx={{ fontWeight: "14px" }}>
                        Return Quantity
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{
                        padding: "3px",
                        textAlign: "center",
                      }}
                    >
                      <ChangeQuantity
                        product={productDetail}
                        quantity={quantity}
                        handleAdd={() =>
                          handleAdd(productDetail?.minimumOrderQuantity)
                        }
                        handleRemove={() =>
                          handleRemove(productDetail?.minimumOrderQuantity)
                        }
                        handleChange={(event) =>
                          handleChange(
                            event,
                            productDetail?.minimumOrderQuantity
                          )
                        }
                        setQuantity={setQuantity}
                        minimumOrderQuantity={minimumOrderQuantity}
                      />
                      {quantity > CommonConstantValues?.MAX_RETURN_QUANTITY ? (
                        <Typography
                          sx={{
                            color: "#9D2123",
                            display: "flex",
                            fontSize: "12px",
                            marginBottom: "14px",
                            justifyContent: "center",
                          }}
                        >
                          <ErrorOutlineIcon fontSize="small" />
                          Quantity should be less than{" "}
                          {CommonConstantValues?.MAX_RETURN_QUANTITY}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "#9D2123",
                            display: "flex",
                            fontSize: "12px",
                            marginBottom: "14px",
                            justifyContent: "center",
                          }}
                        >
                          Return allowed in pack of{" "}
                          {productDetail?.minimumOrderQuantity === 0
                            ? 1
                            : productDetail?.minimumOrderQuantity}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl
                      sx={{
                        width: "100%",
                        height: "35px",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                      }}
                    >
                      {!selectedReason && (
                        <InputLabel sx={{ marginTop: "-9px" }}>
                          Select a Reason
                        </InputLabel>
                      )}

                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={selectedReason}
                        onChange={onChangeReturnReason}
                        sx={{
                          height: "100%",
                        }}
                      >
                        {_.map(returnReasons, ({ reasonName }) => {
                          return (
                            <MenuItem
                              key={reasonName}
                              value={reasonName}
                              sx={{ whiteSpace: "normal" }}
                            >
                              {reasonName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  <Button
                    variant={"contained"}
                    fullWidth
                    sx={{
                      marginTop: "18px",
                      borderRadius: "10px",
                      height: "35px",
                      background: "#9D2123",
                      color: "#FFFFFF",
                      letterSpacing: "0.04em",
                      textTransform: "uppercase",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                    onClick={() => updateCart(productDetail)}
                    disabled={
                      quantity > CommonConstantValues?.MAX_RETURN_QUANTITY ||
                      !quantity ||
                      !selectedReason ||
                      quantity.toString().includes(".")
                    }
                  >
                    Add
                  </Button>
                </Grid>
              </Card>
            )}
          </>
        );
      })}
    </div>
  );
}

export default SelectProductCart;
