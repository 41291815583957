import makeStyles from "@mui/styles/makeStyles";

import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.spacing(2),
    lineHeight: `1.3`,
    fontWeight: `600`,
    marginTop: theme.spacing(2),
  },
  sectionTitleIcon: {
    height: theme.spacing(3.25),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
}));

function SectionTitle(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        {props.title && (
          <Typography
            variant={`h6`}
            className={classes.title}
            color={props.color ? props.color : "default"}
          >
            {props?.icon && (
              <img
                className={classes.sectionTitleIcon}
                src={props.icon}
                alt=""
              />
            )}
            {props.title}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default SectionTitle;
