import React, { useState, useEffect } from "react";

import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";

const useStyles = makeStyles(() => ({
  whatsAppShare: {
    width: "100%",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "700",
    padding: "6px 16px",
    textTransform: "none",
    color: "#fff",
    background: "#00733E",
    borderRadius: "10px",
  },
  greetingDownload: {
    height: "45px",
    fontSize: "14px",
    fontWeight: "700",
    textTransform: "none",
    borderRadius: "10px",
  },
}));

export default function OfferShareAndDownload({
  page,
  index,
  shareText,
  skuCode,
  imageUrl,
  banners,
  offerTitle,
  offerDescription,
}) {
  const classes = useStyles();
  const [isAndroid, setIsAndroid] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [eventProperties, setEventProperties] = useState({});
  const WhatsAppImageIcon = (
    <img src="/icons/whatsapp.svg" alt="whatsapp" width="16px" height="16px" />
  );

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      setIsAndroid(true);
      const { appVersionCode } = AndroidService.getAndroidVersion();
      setIsDownloadEnabled(appVersionCode >= 11 ? true : false);
    }

    const properties = {
      page: page,
      type: page === "/festive-greetings" ? "Greetings" : "Offers",
      imageUrl: imageUrl?.slice(0, 1024),
    };
    if (index) {
      properties["themeName"] = `Theme ${index + 1}`;
      properties["themeUrl"] = banners[index]?.originalUrl;
    }
    if (page === "/create-offer" || page === "/my-offers") {
      properties["skuCode"] = skuCode;
      properties["offerTitle"] = offerTitle;
      properties["offerDescription"] = offerDescription;
    }
    setEventProperties(properties);
  }, []);

  const handleAndroidShare = () => {
    AnalyticsService.pushEvent(`Share Button Clicked`, eventProperties);
    AndroidService.shareOnWhatsApp(shareText, imageUrl);
  };

  const handleAndroidDownload = () => {
    AnalyticsService.pushEvent(`Download Button Clicked`, eventProperties);
    AndroidService.downloadImage("Offer", imageUrl);
  };

  const handleWebDownload = () => {
    AnalyticsService.pushEvent(`Download Button Clicked`, eventProperties);

    fetch(imageUrl)
      .then((greetingResponse) => {
        greetingResponse
          .blob()
          .then((blobResponse) => {
            const fileDownloadElement = document.createElement("a");
            fileDownloadElement.href = URL.createObjectURL(blobResponse);
            fileDownloadElement.download = `Offer.jpg`;
            fileDownloadElement.click();
          })
          .catch((e) => {
            console.log("Error While Download: ", e.message);
          });
      })
      .catch((e) => {
        console.log("Error While Download: ", e.message);
      });
  };

  return (
    <Grid container>
      {isAndroid ? (
        <>
          <Grid item xs={isDownloadEnabled ? 5 : 12}>
            <div className={classes.whatsAppShare} onClick={handleAndroidShare}>
              {WhatsAppImageIcon}
              <p style={{ marginLeft: "5px" }}>Share</p>
            </div>
          </Grid>
          {isDownloadEnabled && (
            <>
              <Grid item xs={2}></Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  className={classes.greetingDownload}
                  onClick={handleAndroidDownload}
                >
                  Download
                </Button>
              </Grid>
            </>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            className={classes.greetingDownload}
            onClick={handleWebDownload}
          >
            Download
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
