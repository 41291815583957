import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";

import { Container } from "@mui/material";
import { DoneAll, Loop } from "@mui/icons-material";

import TopBar from "../Common/TopBar";
import EmptyError from "../EmptyError/EmptyError";
import IPTRequestDetailsCard from "./IPTRequestDetailsCard";
import AnalyticsService from "../../Services/AnalyticsService";
import InterPartyTransfersService from "../../Services/InterPartyTransfersService";

export const iptStatus = {
  PENDING: {
    color: "#a56f00",
    bgColor: "#efe5ce",
    text: "Pending",
    icon: <Loop sx={{ marginRight: "5px", color: "#a56f00" }} />,
  },
  REJECTED: {
    color: "#9e2123",
    bgColor: "#ebd3d4",
    text: "Rejected",
    icon: (
      <img
        src="/icons/statusCancel.svg"
        width="20px"
        style={{ marginRight: "5px" }}
      />
    ),
  },
  APPROVED: {
    color: "#00733e",
    bgColor: "#cce3d8",
    text: "Approved",
    icon: <DoneAll sx={{ marginRight: "5px", color: "#00733e" }} />,
  },
  DRAFT: {
    color: "#a56f00",
    bgColor: "#efe5ce",
    text: "Incomplete",
    icon: (
      <img src="/icons/draft.svg" width="20px" style={{ marginRight: "5px" }} />
    ),
  },
  CANCELLED: {
    color: "#9e2123",
    bgColor: "#ebd3d4",
    text: "Cancelled",
    icon: (
      <img
        src="/icons/statusCancel.svg"
        width="20px"
        style={{ marginRight: "5px" }}
      />
    ),
  },
  CREATED: {
    color: "#00733e",
    bgColor: "#cce3d8",
    text: "Created",
    icon: <DoneAll sx={{ marginRight: "5px", color: "#00733e" }} />,
  },
  COMPLETED: {
    color: "#00733e",
    bgColor: "#cce3d8",
    text: "Completed",
    icon: <DoneAll sx={{ marginRight: "5px", color: "#00733e" }} />,
  },
};

export default function InterPartyTransferRequestsPage() {
  const [farmerId, setFarmerId] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const [interPartyTransfersList, setInterPartyTransfersList] = useState(null);

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`);

    setLoading("started");
    setFarmerId(JSON.parse(localStorage.getItem("farmerId")));
    const { axiosRequest, abortController } =
      InterPartyTransfersService.getInterPartyTransfersList();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setInterPartyTransfersList(response?.data?.responseData);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.error("Failed to Get IPT List: ", error);
        }
      });
    return () => abortController.abort();
  }, []);

  return (
    <>
      <TopBar title={`Inter Party Transfer Requests`} backNavigation={true} />

      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Loading...`}
        style={{ minHeight: "85vh" }}
        keepInView
      >
        <Container maxWidth={`xl`} sx={{ pt: "10px", pb: "125px" }}>
          {interPartyTransfersList?.length
            ? interPartyTransfersList?.map((ipt) => {
                if (ipt?.status === "DRAFT") return null;

                return ipt?.fromPartner?.partnerId === farmerId ? (
                  <IPTRequestDetailsCard
                    ipt={ipt}
                    key={ipt.id}
                    partnerTitle="Transferred To"
                    partnerName={ipt?.toPartner?.name}
                    partner={ipt?.fromPartner}
                  />
                ) : (
                  <IPTRequestDetailsCard
                    ipt={ipt}
                    key={ipt.id}
                    partnerTitle="Transferred From"
                    partnerName={ipt?.fromPartner?.name}
                    partner={ipt?.toPartner}
                  />
                );
              })
            : loading === "done" && (
                <EmptyError
                  text="No Transfer Requests Found."
                  hideBack={true}
                />
              )}
        </Container>
      </BlockUi>
    </>
  );
}
