import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import withRouter from "../../Hooks/withRouter";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const unlisten = window.scrollTo(0, 0);
    return unlisten;
  }, [location]);

  return null;
}

export default withRouter(ScrollToTop);
