import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import { Box } from "@mui/material";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  videoCard: {
    width: "47vw",
    "@media screen and (min-width: 600px)": {
      width: "23vw",
    },
    // height: "115px",
    // background: "white",
    borderRadius: "10px",
  },
  videoContainer: {
    whiteSpace: "nowrap",
    overflowX: "auto",
    display: "flex",
    maxHeight: "186px",
    marginTop: "10px",
  },
  videoItem: {
    display: "flex",
    flex: "none",
    boxShadow: "0px 10px 10px #E0E0E0;",
    overflow: "hidden",
    borderRadius: "16px",
    width: "100%",
  },
}));

function Testimonials(props) {
  const classes = useStyles();
  const testimonialsLength = props.testimonials.length;

  return (
    <Box
      className={classes.videoContainer}
      style={
        testimonialsLength == 1
          ? {
              marginLeft: "15px",
              marginRight: "15px",
              overflow: "hidden",
              borderRadius: "16px",
            }
          : {}
      }
    >
      {_.map(props.testimonials, (testimonial, index) => (
        <Box
          key={index}
          className={classes.videoItem}
          style={
            testimonialsLength > 1
              ? {
                  width: "80%",
                  marginRight: "15px",
                  marginLeft: index == 0 ? "15px" : "0px",
                }
              : {}
          }
        >
          <ReactVideoPlayer
            video={testimonial}
            autoplay={true}
            key={index}
            id={index}
            style={{ height: "186px" }}
            control={1}
            light={true}
            playbackStartedEventName="Testimonial Video Playback Started"
            playbackEndedEventName="Testimonial Video Playback Ended"
            eventProperties={{
              page: "Product Page",
              skuCode: props?.skuCode,
              section: props?.section,
            }}
          />
        </Box>
      ))}
    </Box>
  );
}

export default Testimonials;
