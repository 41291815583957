import config from "../config";
import StateService from "./StateService";
import Leads from "./mocks/LeadsMock";
import HTTPService from "./HTTPService";

const useMocks = false;

export const GRAHAK_CHAT_SOURCES_ENABLED = [
  "B2BGJ",
  "B2BMH",
  "B2BMP",
  "B2BRJ",
  "B2BUP",
];

export default class LeadService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static registerLead(stateName, mobile, remarks) {
    let state = StateService.getStateByName(stateName);
    let stateCode = "";
    if (state) {
      stateCode = state.stateCode;
    }

    let leadData = {
      state: stateName,
      source: `SaathiAgronomy${stateCode}`,
      customerPhoneNumber: mobile,
    };

    const url = `${config.backendUrl}dialerservice/registerAppCall/`;
    return HTTPService.makeXHRRequest(url, "POST", leadData, {
      ...this.getHeaders(),
      source: "CSR",
    });
  }

  static getLeads(userId, leadAttribute) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }institutionalsalesservice/saathi/${localStorage.getItem(
        "farmerId"
      )}/leads/?${
        leadAttribute === "sale" ? "connected=true&interested=true" : ""
      }${
        leadAttribute === "nosale"
          ? "rejected=true&expired=true&notinterested=true"
          : ""
      }${
        leadAttribute === "active"
          ? "active=true&attempted=true&notconnected=true"
          : ""
      }`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: Leads });
      });
    }
  }

  static getLeadCount() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }institutionalsalesservice/saathi/${localStorage.getItem(
        "farmerId"
      )}/leads-summary/`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        resolve({ data: Leads });
      });
    }
  }

  static setLeadStatus(leadId, farmerId, data) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/saathi/${farmerId}/leads/${leadId}/`;
      return HTTPService.makeXHRRequest(url, "PATCH", data, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: Leads });
      });
    }
  }

  static getLeadDetails(leadId, farmerId) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/saathi/${farmerId}/leads/${leadId}/`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: Leads });
      });
    }
  }
}
