import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Clamp from "react-multiline-clamp";
import BlockUi from "react-block-ui";
import moment from "moment";

import { makeStyles } from "@mui/styles";
import { East, GppMaybeOutlined, ThumbUpOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import TopBar from "../Common/TopBar";
import AmountUtils from "../../Utils/AmountUtils";
import OrderService from "../../Services/OrderService";
import PayNowDialog from "../Hisaab/PayNowDialog";
import BackDropLoader from "../Common/BackDropLoader";
import EmptyError from "../EmptyError/EmptyError";
import AnalyticsService from "../../Services/AnalyticsService";
import useQuery from "../../Hooks/useQuery";

const useStyles = makeStyles({
  orderItem: {
    background: "#ffffff",
    marginTop: "8px",
    padding: "12px",
    flexWrap: "nowrap",
  },
  accordian: {
    margin: "8px 0 !important",
    boxShadow: "none",
    borderRadius: "0 !important",
    background: "#fff",
    "&::before": { display: "none" },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 0 !important",
    },
    "& .MuiAccordionSummary-root": {
      // "pointer-events": "none",
    },
    "& .MuiAccordionDetails-root": { padding: "8px 16px" },
  },
  accordianSummary: {
    padding: "12px 16px",
    alignItems: "flex-start",
    "& .MuiAccordionSummary-content": { margin: 0 },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 !important",
    },
  },
  productImage: {
    marginLeft: "8px",
    height: "80px",
  },
  productNameContainer: {
    fontSize: "14px",
    "& p": { fontSize: "14px" },
  },
  tableDataStyle: { fontSize: "12px", padding: "4px" },
  listHeadingStyle: { fontSize: "12px", fontWeight: 700, padding: "4px" },
  outstandingAmount: {
    fontSize: "12px",
    padding: "4px",
    color: "#9D2123",
    textAlign: "right",
  },
  cdAmount: {
    fontSize: "12px",
    padding: "4px",
    color: "#00733E",
    textAlign: "right",
  },
  payNowButton: {
    position: "fixed",
    bottom: 0,
    borderRadius: 0,
    fontWeight: "bold",
    height: "45px",
    maxWidth: "600px",
  },
  payNowDisabledMessage: {
    position: "fixed",
    bottom: 45,
    width: "100%",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 0px",
  },
});

export default function PayByProductGroupDetailsPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { groupName } = useParams();
  const query = useQuery();
  const lendingPartner = query.get("lendingPartner");
  const [loading, setLoading] = useState("not-started");
  const [productGroup, setProductGroup] = useState(null);
  const [showPayNowDialog, setShowPayNowDialog] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState("not_started");
  const [payNowPaymentAmount, setPayNowPaymentAmount] = useState(0);

  useEffect(() => {
    AnalyticsService.pushEvent("Page Viewed", {
      page: "Pay By Product Group Details",
    });

    setLoading("started");
    const { axiosRequest, abortController } =
      OrderService.getProductGroupDetails({
        productSalesGroup: groupName,
        lendingPartner: lendingPartner,
      });
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setProductGroup(response.data.responseData);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.error("Error getting Product Group: ", error?.message);
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, [groupName]);

  const handleProductClick = ({ skuCode }) => {
    navigate(`/products/${skuCode}?from=${window.location.pathname}`);
  };

  const handlePayNow = () => {
    setShowPayNowDialog(true);
    setPayNowPaymentAmount(productGroup?.unsettledAmount);

    AnalyticsService.pushEvent("Pay Now Clicked", {
      page: window?.location?.pathname,
      dueAmount: productGroup?.unsettledAmount,
      creditLine: localStorage.getItem("creditLine")?.toLowerCase(),
      productSalesGroup: productGroup?.productSalesGroup || "",
      cashDiscountAmount: productGroup?.cdBenefitAmount,
      netPayableAmount: productGroup?.netPayableAmount,
    });
  };

  const handlePaymentStatus = (paymentId) => {
    navigate(`/hisaab/payment-status/${paymentId}`);
  };

  const handleRazorpayFailure = (error, errorCode = null) => {
    const urlParams = errorCode ? `?errorCode=${errorCode}` : "";
    navigate(`/hisaab/razorpay-failure/${error}${urlParams}`);
  };

  return (
    <div>
      <TopBar title={groupName} backNavigation={true} />

      <BlockUi
        tag="div"
        keepInView
        renderChildren={false}
        message={`Loading...`}
        blocking={loading === "started" && loading !== "done"}
        style={{ minHeight: "95vh" }}
      >
        {productGroup && productGroup?.productSalesGroupDetails?.length ? (
          <>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", p: "8px 16px" }}
            >
              Items
            </Typography>

            {productGroup?.productSalesGroupDetails?.map((product, index) => (
              <Box key={index} className={classes.accordian}>
                <AccordionSummary className={classes.accordianSummary}>
                  <Grid container justifyContent="space-between">
                    <Grid
                      item
                      xs={3}
                      onClick={() => handleProductClick(product)}
                    >
                      <img
                        src={product?.productImageUrl || "/icons/dummy.jpeg"}
                        alt={product.productName}
                        className={classes.productImage}
                      />
                    </Grid>
                    <Grid item xs={9} className={classes.productNameContainer}>
                      <Clamp lines={2} maxLines={2}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          {product.productName}
                        </Typography>
                      </Clamp>

                      <Typography>
                        Outstanding:{" "}
                        <b style={{ color: "#9D2123" }}>
                          {AmountUtils.formatCurrency(
                            product.unsettledAmount || 0
                          )}
                        </b>
                      </Typography>
                      <Typography>
                        Cash Discount:{" "}
                        <b style={{ color: "#00733E" }}>
                          {AmountUtils.formatCurrency(
                            product.cdBenefitAmount || 0
                          )}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={3} className={classes.listHeadingStyle}>
                      Due Date
                    </Grid>
                    <Grid item xs={2.5} className={classes.listHeadingStyle}>
                      Order No.
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      textAlign="right"
                      className={classes.listHeadingStyle}
                    >
                      Outstanding
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      textAlign="right"
                      className={classes.listHeadingStyle}
                    >
                      CD
                    </Grid>

                    {product.orderWiseSplit?.map((order, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Divider
                            sx={{ width: "calc(100% + 32px)", ml: "-16px" }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.tableDataStyle}>
                          {moment(order?.dueDate).format("DD-MM-YYYY")}
                        </Grid>
                        <Grid item xs={2.5} className={classes.tableDataStyle}>
                          {order?.orderId}
                        </Grid>
                        <Grid item xs={3} className={classes.outstandingAmount}>
                          {AmountUtils.formatCurrency(order?.unsettledAmount)}
                        </Grid>
                        <Grid item xs={2} className={classes.cdAmount}>
                          {AmountUtils.formatCurrency(order?.cdBenefitAmount)}
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Box>
            ))}

            {!productGroup?.isPayNowEnabled ? (
              <Typography
                className={classes.payNowDisabledMessage}
                sx={{
                  color: `${productGroup?.unsettledAmount > 0 ? `#9D2123` : `#00733E`}`,
                  background:
                    productGroup?.unsettledAmount > 0 ? `#F5E9E9` : `#E6F2EC`,
                }}
              >
                {productGroup?.unsettledAmount > 0 ? (
                  <GppMaybeOutlined
                    sx={{ fontSize: "20px", marginRight: "4px" }}
                  />
                ) : (
                  <ThumbUpOutlined
                    sx={{ fontSize: "18px", marginRight: "4px" }}
                  />
                )}
                {productGroup?.payNowDisabledMessage}
              </Typography>
            ) : OrderService.isLastSuccessfulPaymentInProgressForOrder(
                productGroup
              ) ? (
              <Typography
                className={classes.payNowDisabledMessage}
                sx={{ color: "#CC8800", background: "#FDF4E2" }}
              >
                <GppMaybeOutlined
                  sx={{ fontSize: "20px", marginRight: "4px" }}
                />
                Previous payment is in progress
              </Typography>
            ) : null}

            <Button
              fullWidth
              variant="contained"
              endIcon={<East />}
              onClick={handlePayNow}
              className={classes.payNowButton}
              disabled={
                !productGroup?.isPayNowEnabled ||
                OrderService.isLastSuccessfulPaymentInProgressForOrder(
                  productGroup
                )
              }
            >
              Pay Now
            </Button>
          </>
        ) : (
          loading === "done" && (
            <EmptyError
              image={`Empty Cart.svg`}
              text={`No Products found!`}
              extraStyle={{ minHeight: "50vh" }}
              hideBack={true}
            />
          )
        )}
      </BlockUi>

      <PayNowDialog
        open={showPayNowDialog}
        setOpen={setShowPayNowDialog}
        paymentAmount={payNowPaymentAmount}
        lendingPartner={lendingPartner}
        handleClose={() => setShowPayNowDialog(false)}
        handlePaymentStatus={handlePaymentStatus}
        handleRazorpayFailure={handleRazorpayFailure}
        setPaymentLoading={setPaymentLoading}
        paymentType={"productsalesgroup"}
        productSalesGroup={productGroup?.productSalesGroup}
        initiationFailureRetryRedirectionLink={`/hisaab/pay-by-product-group/${encodeURI(productGroup?.productSalesGroup)}`}
      />
      <BackDropLoader open={paymentLoading === "started"} />
    </div>
  );
}
