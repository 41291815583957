import { useEffect, useState } from "react";
import ProductService from "../Services/ProductService";
import _ from "lodash";

function usePopularSearchProducts() {
  const [loading, setLoading] = useState("not_started");
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("district")) {
      setLoading("started");
      const { axiosRequest, abortController } =
        ProductService.getPopularSearchProducts();
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.productGists?.length > 0) {
            let productsFromResponse =
              response?.data?.responseData?.productGists;
            productsFromResponse = _.filter(productsFromResponse, (product) => {
              return (
                product.brandName !== "Unassigned Brand" &&
                ProductService.checkIfProductEnabled(product)
              );
            });
            setProducts(productsFromResponse);
          } else {
            console.log(`no response received in responseData`);
          }
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting products`);
            if (error.data) {
              setError(error.data);
              console.log(error.data);
            } else {
              setError(error);
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    }
  }, [localStorage.getItem("district")]);

  return { products, loading, error };
}

export default usePopularSearchProducts;
