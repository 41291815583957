import config from "../config";
import {
  DocumentUploadUrlsMock,
  UploadedDocumentsForUser,
} from "./mocks/DocumentMock";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class DocumentService {
  static getHeaders() {
    return {
      source: "B2B",
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getInstitutionId() {
    const url = `${
      config.backendUrl
    }institutionalsalesservice/institution/?farmerId=${localStorage.getItem(
      "farmerId"
    )}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static getStoreImageUrls() {
    const url = `${
      config.backendUrl
    }institutionalsalesservice/saathi/${localStorage.getItem(
      "farmerId"
    )}/store-image/url/?urlTypes=get,put`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      ...this.getHeaders(),
      source: localStorage.getItem("source"),
    });
  }

  static updateStoreLatLongAndImage(
    latitude,
    longitude,
    institutionId,
    storeImageUrl
  ) {
    const url = `${config.backendUrl}institutionalsalesservice/institution/`;
    const data = {
      updateType: "Location_And_Image",
      location: { lat: latitude, long: longitude },
      institutionId: institutionId,
      storeImageUrl: storeImageUrl,
    };
    return HTTPService.makeXHRRequest(url, "PATCH", data, this.getHeaders());
  }

  static getDocumentLinks(documentIds) {
    const url = `${config.backendUrl}institutionalsalesservice/document/?documentId=${documentIds}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static getProductImageUploadUrls() {
    const url = `${config.backendUrl}catalogservice/${localStorage.getItem(
      "farmerId"
    )}/product-image-upload-urls`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      ...this.getHeaders(),
      source: localStorage.getItem("source"),
    });
  }

  static getDocumentUploadUrls(fileExtension, fileType) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }institutionalsalesservice/presignedurl/?fileExtension=${fileExtension}&fileType=${fileType}&parentFolderName=${localStorage.getItem(
        "institutionId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        ...this.getHeaders(),
        source: localStorage.getItem("source"),
      });
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: DocumentUploadUrlsMock });
        }, 2000);
      });
    }
  }

  static uploadDocumentToS3(putPresignedUrl, fileData, fileType) {
    return HTTPService.makeXHRRequest(putPresignedUrl, "PUT", fileData, {
      "Content-Type": fileType,
    });
  }

  static getUploadedDocumentsForUser() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }institutionalsalesservice/selfonboarding/?institutionId=${localStorage.getItem(
        "institutionId"
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: UploadedDocumentsForUser });
        }, 2000);
      });
    }
  }

  static uploadDocumentsForOnboarding({
    requestPayload,
    isPartialUploadComplete,
    isCompleted,
  }) {
    const url = `${config.backendUrl}institutionalsalesservice/selfonboarding/?pageNumber=2`;
    const data = {
      pageNumber: 2,
      institutionId: localStorage.getItem("institutionId"),
      isPartialUploadComplete: isPartialUploadComplete,
      isComplete: isCompleted,
      documents: requestPayload,
    };
    return HTTPService.makeXHRRequest(url, "PUT", data, this.getHeaders());
  }
}
