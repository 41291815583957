import useQuery from "./useQuery";

export default function useReferrerParams() {
  const query = useQuery();

  const storeReferralParams = () => {
    const referralCode = query.get("referralCode") || query.get("referralcode");
    const referrer = query.get("referrer");
    const referrerMobileNumber =
      query.get("referrerMobileNumber") || query.get("referrermobile");
    const referrerEmailId =
      query.get("referrerEmail") || query.get("referreremail");
    if (referralCode) {
      localStorage.setItem("referralCode", referralCode);
    }
    if (referrer) {
      let referrerToStore = referrer;
      if (referrer?.toLowerCase() === "retailer") {
        referrerToStore = "Other Retailer";
      }
      if (referrer?.toLowerCase() === "officer") {
        referrerToStore = "Sales Officer";
      }
      if (referrer?.toLowerCase() === "farmer") {
        referrerToStore = "Farmer";
      }
      localStorage.setItem("referrer", referrerToStore);
    }
    if (referrerMobileNumber) {
      localStorage.setItem("referrerMobileNumber", referrerMobileNumber);
    }
    if (referrerEmailId) {
      localStorage.setItem("referrerEmailId", referrerEmailId);
    }
  };

  const referralCodeFromLocalStorage = localStorage.getItem("referralCode");
  const referrerFromLocalStorage = localStorage.getItem("referrer");
  const referrerMobileNumberFromLocalStorage = localStorage.getItem(
    "referrerMobileNumber"
  );
  const referrerEmailIdFromLocalStorage =
    localStorage.getItem("referrerEmailId");

  return {
    storeReferralParams,
    referralCodeFromLocalStorage,
    referrerFromLocalStorage,
    referrerMobileNumberFromLocalStorage,
    referrerEmailIdFromLocalStorage,
  };
}
