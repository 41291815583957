import React, { useEffect, useState } from "react";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import ProductsList from "./ProductsList";
import Search from "../Dashboard/Search";
import ProductsLoader from "./ProductsLoader";
import StateUtils from "../../Utils/StateUtils";
import AnalyticsService from "../../Services/AnalyticsService";
import _ from "lodash";
import useTurnoverSchemeProductsRecommendation from "../../Hooks/useTurnoverSchemeProductsRecommendation";

function TurnoverProductsRecommendationPage() {
  const { products, loading, error } =
    useTurnoverSchemeProductsRecommendation();
  const [stateCode, setStateCode] = useState("MH");

  useEffect(() => {
    let currentStateCode = StateUtils.getStateCode();
    setStateCode(currentStateCode);
    AnalyticsService.pushEvent("Page Viewed", {
      page: "Turnover Scheme Recommendation Page",
    });
  }, []);

  return (
    <div>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Buy More to Avail Slab Benefits`}
          backNavigation={true}
          backLink={"/dashboard"}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <ErrorBoundary message={``}>
        <Search page={"Turnover Scheme Recommendation Page"} />
      </ErrorBoundary>
      {loading !== "started" &&
      (!error || !error?.data) &&
      products &&
      products.length > 0 ? (
        <>
          <ErrorBoundary message={``}>
            <ProductsList
              loading={loading}
              products={products}
              title={`Products`}
              page={`Dashboard`}
              stateCode={stateCode}
            />
          </ErrorBoundary>
        </>
      ) : (
        <>
          {loading === "started" && (!error || !!error?.data) && (
            <>
              <ProductsLoader />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default TurnoverProductsRecommendationPage;
