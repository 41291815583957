import React from "react";
import classNames from "classnames";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Clamp from "react-multiline-clamp";
import AnalyticsService from "../../Services/AnalyticsService";
import ImageService from "../../Services/ImageService";

const useStyles = makeStyles((theme) => ({
  videoTitle: {
    color: "#999999",
    fontSize: "12px",
    fontStyle: "normal",
    marginTop: "2px",
    fontWeight: "500",
    textAlign: "center",
    maxWidth: "87px",
    wordBreak: "break-word",
  },
  storyBorderBox: {
    width: "86px",
    height: "115px",
    borderRadius: "15px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  storyWrapped: {
    backgroundImage:
      "conic-gradient(from 270deg, #ff4800 10%, #dfd902 35%, #20dc68, #0092f4, #da54d8 72% 75%, #ff4800 95%)",
  },
  viewedStoryWrapped: {
    background: "#CCC",
  },
  storyDiv: {
    width: "80px",
    height: "109px",
    borderRadius: "12px",
    overflow: "hidden",
    border: "1px solid white",
    background: "white",
    color: "black",
    fontSize: "12px",
    padding: 0,
  },
  storyVideo: {
    borderRadius: "10px",
    width: "100%",
    objectFit: "cover",
    height: "100%",
  },
  storyImage: {
    objectFit: "cover",
  },
  weatherPreviewWrapper: {
    width: "100%",
    height: "100%",
    background: "white",
    padding: "4px 1px",
    wordBreak: "break-all",
  },
}));

export default function StoriesPreviewContainer({ stories }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const getSmartCroppedVideoUrl = (shortUrl, forPoster = false) => {
    if (forPoster) {
      return `https://i.ytimg.com/vi/${shortUrl}/mqdefault.jpg`;
    }
    return `https://static.agrostar.in/videos/previews/${shortUrl}.MP4.5s.mp4?tr=so-5,du-5`;
  };

  const handleStoryButtonClick = (story) => {
    navigate({
      pathname: `/stories/${story[0].storyParentCode}`,
    });
    const staticBannerClickedEventData = {
      bannerType: story[0].storyType,
      skuList: story[0].skuList,
      bannerCode: story[0].storyParentCode,
      imageUrl: story[0].imageUrl,
      position: story[0].position,
      section: story[0].section,
      bannerStatus: story[0].storyStatus,
      numberOfBanners: story[0].numberOfStories,
      name: story[0].name,
    };
    AnalyticsService.pushEvent(`Banner Clicked`, staticBannerClickedEventData);
  };

  return (
    <Box sx={{ display: "flex", gap: "14px", width: "max-content" }}>
      {_.map(stories, (story, index) => {
        return (
          <Box key={`react-stories-${index}`}>
            <Box
              className={classNames(
                story[0].viewed
                  ? classes.viewedStoryWrapped
                  : classes.storyWrapped,
                classes.storyBorderBox
              )}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleStoryButtonClick(story);
              }}
            >
              <IconButton className={classes.storyDiv}>
                {story[0].type === "image" && (
                  <img
                    width="100%"
                    height="100%"
                    src={ImageService.transformImageHeight(story[0].url, 220)}
                    alt={story[0].header.heading}
                    className={classes.storyImage}
                  ></img>
                )}

                {story[0].type === "video" && (
                  <video
                    autoPlay="autoplay"
                    loop={true}
                    muted
                    defaultmuted="true"
                    playsInline={true}
                    poster={getSmartCroppedVideoUrl(story[0].shortUrl, true)}
                    className={classes.storyVideo}
                  >
                    <source
                      src={getSmartCroppedVideoUrl(story[0].shortUrl, false)}
                      type="video/mp4"
                    />
                  </video>
                )}
                {story[0].type === "weather" && (
                  <Box className={classes.weatherPreviewWrapper}>
                    <img
                      src={story[0].content.current_weather.weather.imageUrl}
                      height={60}
                      alt=""
                    />
                    <Typography
                      variant="body2"
                      style={{ fontSize: "12px", fontWeight: 700 }}
                    >
                      {Math.floor(
                        story[0].content.current_weather.weather.temperature.max
                      )}
                      º
                      {
                        story[0].content.current_weather.weather.temperature
                          .unit
                      }
                      &nbsp;/&nbsp;
                      <span style={{ color: "#999" }}>
                        {Math.floor(
                          story[0].content.current_weather.weather.temperature
                            .min
                        )}
                        º
                        {
                          story[0].content.current_weather.weather.temperature
                            .unit
                        }
                      </span>
                    </Typography>
                    <Clamp withTooltip lines={1}>
                      {story[0].content.current_weather?.weather?.text || ""}
                    </Clamp>
                  </Box>
                )}
                {story[0].type === "quiz" && (
                  <img src="icons/quizPreview.png" />
                )}
              </IconButton>
            </Box>
            <Typography
              className={classes.videoTitle}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleStoryButtonClick(story);
              }}
            >
              <Clamp withTooltip lines={2}>
                {story[0].header.heading}
              </Clamp>
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
