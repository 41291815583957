import * as React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { FavoriteOutlined } from "@mui/icons-material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import StoryService from "../../Services/StoryService";
import { useParams } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles({
  modalBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.6)",
  },
  buttonWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "10px",
  },
  likeContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    right: 20,
    alignItems: "center",
  },
  confirmationDialogMessage: {
    marginBottom: "12px",
    color: "black",
    textAlign: "center",
  },
  confirmationDialogContainer: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    padding: "20px",
    borderRadius: "4px",
    width: "70%",
  },
  confimationDialogButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  // "@keyframes scaleAndFill": {
  //   "0%": {
  //     transform: "scale(1)",
  //   },
  //   "50%": {
  //     transform: "scale(1.2)",
  //   },
  //   "100%": {
  //     transform: "scale(1)",
  //   },
  // },
  // "@keyframes scaleAndFade": {
  //   "40%": {
  //     transform: "scale(1)",
  //   },
  //   "60%": {
  //     transform: "scale(1.1)",
  //     opacity: 1,
  //   },
  //   "70%": {
  //     opacity: 1,
  //   },
  //   "100%": {
  //     transform: "scale(1.5)",
  //     opacity: 0,
  //   },
  // },
  // iconHeartLiked: {
  //   animation: "$scaleAndFill 0.3s ease-in forwards",
  // },
  // iconHeartLikedFading: {
  //   animation: "$scaleAndFade 0.3s ease-in-out forwards",
  // },
  // "@keyframes floating-heart": {
  //   "0%": {
  //     opacity: 0,
  //     bottom: "0%",
  //     left: "0%",
  //     transform: "scale(1.5)",
  //   },
  //   "10%": {
  //     opacity: 1,
  //     bottom: "20%",
  //     left: "70%",
  //     transform: "scale(1.2)",
  //   },
  //   "20%": {
  //     bottom: "40%",
  //     left: "10%",
  //     transform: "scale(1)",
  //   },
  //   "30%": {
  //     bottom: "60%",
  //     left: "50%",
  //     transform: "scale(0.8)",
  //   },
  //   "40%": {
  //     opacity: 1,
  //     bottom: "80%",
  //     left: "5%",
  //     transform: "scale(0.4)",
  //   },
  //   "48%": {
  //     opacity: 0,
  //     bottom: "100%",
  //     left: "60%",
  //     transform: "scale(0.2)",
  //   },
  //   "100%": {
  //     opacity: 0,
  //     bottom: "100%",
  //     left: "90%",
  //     transform: "scale(0.5)",
  //   },
  // },
  // hearts: {
  //   width: "5%",
  //   minWidth: "50px",
  //   height: "25%",
  //   minHeight: "60px",
  //   position: "absolute",
  //   zIndex: "10",
  //   bottom: "25px",
  //   right: "-10px",
  // },
  // heart: {
  //   position: "absolute",
  //   height: "20%",
  //   minHeight: "15px",
  //   width: "20%",
  //   minWidth: "15px",
  //   backgroundColor: "red",
  //   WebkitMaskImage:
  //     'url(\'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>\')',
  //   maskImage:
  //     'url(\'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>\')',
  //   WebkitMaskRepeat: "no-repeat",
  //   maskRepeat: "no-repeat",
  //   WebkitMaskPosition: "bottom",
  //   maskPosition: "bottom",
  //   opacity: "0",
  //   animation: "$floating-heart 4s forwards cubic-bezier(0.5, 0.5, 0.5, 0.5)",
  // },
});

const StoryLikeButton = ({ story, action }) => {
  //Variables
  const classes = useStyles();
  const [pauseStory, shouldPauseStory] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (params.storyId === story.storyParentCode) {
      //This
      action(pauseStory ? "pause" : "play");
    }
  }, [action, pauseStory]);

  //Callbacks
  const handleLikeIconClicked = () => {
    shouldPauseStory(true);
    sendLikeIconClickedEventToAnalytics();
    const { axiosRequest } = StoryService.markStoryLiked(story);
    axiosRequest
      .then((response) => {
        story.markStoryAsLikedOrUnliked(
          story,
          response.data.responseData.totalLikeCount
        );
        shouldPauseStory(false);
      })
      .catch((error) => {
        //Let user know about the error?
        shouldPauseStory(false);
      });
  };

  const sendLikeIconClickedEventToAnalytics = () => {
    const likeIconClickedEventData = {
      section: story.section,
      name: story.name,
      imageUrl: story.imageUrl,
      position: story.position,
      subPosition: story.subPosition,
      isLiked: !story.isLiked,
      page: "Surkhiya",
      type: "Like Button",
    };
    AnalyticsService.pushEvent(`Button Clicked`, likeIconClickedEventData);
  };

  //UI
  return (
    <Box
      sx={{
        bottom: 100,
      }}
      className={classes.likeContainer}
      onClick={handleLikeIconClicked}
    >
      {story.isLiked ? (
        <FavoriteOutlined
          sx={{
            height: "30px",
            width: "30px",
            color: "red",
          }}
        />
      ) : (
        <FavoriteBorderOutlinedIcon
          sx={{
            height: "30px",
            width: "30px",
            color: "red",
          }}
        />
      )}

      {/* {story.isLiked ? (
        <Box sx={{ position: "relative" }}>
          <FavoriteOutlined
            className={classes.iconHeartLiked}
            sx={{
              height: "30px",
              width: "30px",
              color: "red",
            }}
          />
          {/* <FavoriteOutlined
            className={classes.iconHeartLikedFading}
            sx={{
              height: "30px",
              width: "30px",
              color: "red",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <Box className={classes.hearts}>
            <Box className={classes.heart} style={{ animationDelay: "0s" }}></Box>
            <Box className={classes.heart} style={{ animationDelay: "0.3s" }}></Box>
            <Box className={classes.heart} style={{ animationDelay: "0.6s" }}></Box>
            <Box className={classes.heart} style={{ animationDelay: "0.9s" }}></Box>
          </Box>}
        </Box>
      ) : (
        <Box sx={{ position: "relative" }}>
          <FavoriteBorderOutlinedIcon
            className={classes.iconHeartLiked}
            sx={{
              height: "30px",
              width: "30px",
              color: "red",
            }}
          />
        </Box>
      )} */}

      <Typography
        sx={{
          color: "white",
          fontSize: "18px",
          fontWeight: "800",
          fontStyle: "normal",
          textShadow:
            "1.5px 0 6px #9D2123,0 1.5px 6px #9D2123,-1.5px 0 6px #9D2123,0 -1.5px 6px #9D2123",
        }}
      >
        {story.totalLikeCount}
      </Typography>
    </Box>
  );
};

export default StoryLikeButton;
