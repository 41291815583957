import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { ArrowRightAlt, Close } from "@mui/icons-material";
import useFocus from "../../Hooks/useFocus";
import PaymentService from "../../Services/PaymentService";
import useRazorpay from "react-razorpay";
import _, { parseInt } from "lodash";
import ProceedPaymentFailureDialog from "../Hisaab/ProceedPaymentFailureDialog";
import AnalyticsService from "../../Services/AnalyticsService";
import config from "../../config";
import AndroidService from "../../Services/AndroidService";

const PayAdvanceForSkuDialog = (props) => {
  const Razorpay = useRazorpay();

  const [inputRef, setInputFocus] = useFocus();

  const [openDebug, setOpenDebug] = useState(false);
  const [debugMessage, setDebugMessage] = useState("");

  const [showProceedPaymentFailureDialog, setShowProceedPaymentFailureDialog] =
    useState(false);
  const [proceedPaymentErrorCode, setProceedPaymentErrorCode] = useState(null);
  const [paymentReason, setPaymentReason] = useState(
    `Payment received through Pay Advance for the ${props.productName} for ${
      props.quantity
    } ${props?.quantity > 1 ? props?.schemeUnitMultiple : props?.schemeUnit}`
  );

  useEffect(() => {
    setPaymentReason(
      `Payment received through Pay Advance for the ${props.productName} for ${
        props.quantity
      } ${props?.quantity > 1 ? props?.schemeUnitMultiple : props?.schemeUnit}`
    );
  }, [props.quantity, props?.schemeUnit, props?.schemeUnitMultiple]);

  function handlePaymentViaWebSDK(razorpayConfig) {
    props.setPaymentLoading("done");

    let razorpayInstance = new Razorpay(razorpayConfig);
    razorpayInstance.open();
    razorpayInstance.on("payment.failed", function (response) {
      AnalyticsService.pushEvent("Payment Initiation Failure", {
        code: response?.error?.code,
        description: response?.error?.description,
        source: response?.error?.source,
        step: response?.error?.step,
        reason: response?.error?.reason,
        orderId: response?.error?.metadata?.order_id,
        paymentId: response?.error?.metadata?.payment_id,
        type: "advance",
        amount: razorpayConfig?.amount,
        sdk: "web",
      });
      props.handleRazorpayFailure("RAZORPAY_FAILURE", response?.error?.code);
    });

    AnalyticsService.pushEvent("Payment Initiation Successful", {
      type: "advance",
      amount: razorpayConfig?.amount,
      sdk: "web",
    });
  }

  const handleProceedPaymentPayAdvance = (event) => {
    event.preventDefault();

    props.setPaymentLoading("started");
    let amountToPay = props.paymentAmount;

    AnalyticsService.pushEvent("Proceed Payment Clicked", {
      type: "advance",
      amount: amountToPay,
      skuCode: props.skuCode,
      productName: props.productName,
      quantity: props.quantity,
    });

    let paymentErrorCode = null;
    let initiationErrorCode = null;
    const { axiosRequest } = PaymentService.initiatePayment({
      paymentType: "advance",
      amount: amountToPay,
      reason: paymentReason,
      schemeName: props?.schemeName,
    });
    axiosRequest
      .then((response) => {
        if (
          response?.data?.responseData &&
          !_.isEmpty(response?.data?.responseData)
        ) {
          let responseData = response.data.responseData;
          console.log({ responseData });
          let razorPayConfig = {
            key: responseData.apiKey,
            amount: amountToPay,
            currency: "INR",
            name: "AgroStar",
            description: "Payment Amount",
            order_id: responseData.razorpayOrderId,
            callback_url: `${config.paymentRedirectionUrl}paymentapi/v1/paymentredirect`,
            redirect: true,
            remember_customer: false,
            hidden: {
              contact: true,
              email: true,
            },
            modal: {
              confirm_close: true,
              ondismiss: function (response) {
                console.log("in close");
                console.log(response);
                props.setPaymentLoading("done");
                AnalyticsService.pushEvent("Razorpay Payment SDK Closed");
              },
            },
            prefill: {
              // name: localStorage.getItem("partnerName"),
              email: "rzpcustomer.payment@agrostar.in",
              contact: localStorage.getItem("mobile"),
              // method: "upi",
            },
            theme: {
              color: "#9D2123",
            },
            // notes: {
            //   address: "Razorpay Corporate Office",
            // },
            razorpaySuccessCallbackUrl: `${config.appUrl}/hisaab/payment-status/`,
            razorpayFailureCallbackUrl: `${config.appUrl}/hisaab/razorpay-failure/RAZORPAY_FAILURE`,
          };
          console.log({ razorPayConfig });
          if (responseData.apiKey && responseData.razorpayOrderId) {
            if (AndroidService.checkIfAndroid()) {
              if (AndroidService.getAndroidVersion()) {
                let { appVersionCode } = AndroidService.getAndroidVersion();

                if (appVersionCode && parseInt(appVersionCode) >= 8) {
                  handlePaymentViaAndroidNativeSDK(razorPayConfig);
                } else {
                  handlePaymentViaWebSDK(razorPayConfig);
                }
              } else {
                handlePaymentViaWebSDK(razorPayConfig);
              }
            } else {
              handlePaymentViaWebSDK(razorPayConfig);
            }
          } else {
            props.setPaymentLoading("done");

            if (!responseData.razorpayOrderId) {
              initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_ORDER_ID";
              setProceedPaymentErrorCode(initiationErrorCode);
              setShowProceedPaymentFailureDialog(true);
            }
            if (!responseData.apiKey) {
              initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_API_KEY";
              setProceedPaymentErrorCode(initiationErrorCode);
              setShowProceedPaymentFailureDialog(true);
            }
          }
        } else {
          initiationErrorCode = "PAYMENT_INIT_NO_RESPONSE_DATA";
          setProceedPaymentErrorCode(initiationErrorCode);
          setShowProceedPaymentFailureDialog(true);
          props.setPaymentLoading("done");
        }
        if (initiationErrorCode) {
          AnalyticsService.pushEvent("Payment Initiation Failed", {
            type: "advance",
            amount: amountToPay,
            errorCode: initiationErrorCode,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        initiationErrorCode = "PAYMENT_INIT_FAILED";
        setProceedPaymentErrorCode(initiationErrorCode);
        AnalyticsService.pushEvent("Payment Initiation Failed", {
          type: "advance",
          amount: amountToPay,
          errorCode: initiationErrorCode,
        });
        setShowProceedPaymentFailureDialog(true);
        props.setPaymentLoading("done");
      })
      .finally(() => {});
  };

  const handlePaymentViaAndroidNativeSDK = (razorPayConfig) => {
    props.setPaymentLoading("done");
    delete razorPayConfig.modal.ondismiss;
    razorPayConfig.redirect = false;
    delete razorPayConfig.callback_url;

    let razorPayMetadataStringified = JSON.stringify(razorPayConfig);

    try {
      /*global Android*/
      if (Android) {
        Android.startRazorpayPaymentFlow(razorPayMetadataStringified);
        AnalyticsService.pushEvent("Payment Initiation Successful", {
          type: "advance",
          amount: razorPayConfig?.amount,
          sdk: "android",
        });
      } else {
        handlePaymentViaWebSDK(razorPayConfig);
      }
    } catch (e) {
      handlePaymentViaWebSDK(razorPayConfig);
    }
  };
  return (
    <>
      <SwipeableDrawer
        anchor={`bottom`}
        onOpen={() => {}}
        open={props.open}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        onClose={() => {
          AnalyticsService.pushEvent("Pay Advance Dialog Closed", {
            amount: props.paymentAmount,
            type: "advance",
          });
          props.handleClose();
        }}
        // swipeAreaWidth={drawerBleeding}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent={`flex-start`}
          sx={{ marginTop: "20px" }}
        >
          <Grid item xs={5} />
          <Grid item xs={1}>
            <Box
              sx={{
                border: "3px solid #DDDDDD",
                width: "43px",
                height: "0px",
              }}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={1}>
            <IconButton
              sx={{ marginTop: "-16px" }}
              onClick={() => {
                AnalyticsService.pushEvent("Pay Advance Dialog Closed", {
                  amount: props.paymentAmount,
                  type: "advance",
                });
                props.handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Container sx={{ marginBottom: "50px", marginTop: "16px" }}>
          <Typography variant={`span`} sx={{ color: "#333" }} fontSize={16}>
            You are booking&nbsp;
          </Typography>
          <Typography
            variant={`span`}
            sx={{ color: "#00733E" }}
            fontSize={16}
            fontWeight={700}
          >
            {props.quantity + " "}
            {props.quantity > 1
              ? props?.schemeUnitMultiple
                ? props?.schemeUnitMultiple + " of "
                : ""
              : props?.schemeUnit
                ? props?.schemeUnit + " of "
                : ""}
          </Typography>
          <Typography variant={`span`} fontSize={16}>
            {props.productName} for a total amount of
          </Typography>
          <Typography
            variant={`span`}
            sx={{ color: "#00733E" }}
            fontSize={16}
            fontWeight={700}
          >
            &nbsp;₹
            {props.quantity * props.price
              ? new Intl.NumberFormat("en-IN").format(
                  props.quantity * props.price
                )
              : ``}
          </Typography>
        </Container>
        <form onSubmit={handleProceedPaymentPayAdvance}>
          <Box sx={{ marginBottom: "30px" }}>
            <Box
              sx={{
                bottom: 0,
                position: "fixed",
                // marginLeft: "-16px",
                display: "flex",
                width: "100%",
                boxShadow: "0 -4px 10px 0 rgba(129, 129, 129, 0.3)",
                height: "50px",
                zIndex: 2,
                maxWidth: "600px",
              }}
            >
              <>
                <Button
                  size={"large"}
                  sx={{ width: "100%", borderRadius: "0px" }}
                  variant={"contained"}
                  color={"primary"}
                  fullWidth={true}
                  onClick={() => {
                    // props.setShowPayAdvanceDialog(true);
                  }}
                  type={"submit"}
                  endIcon={<ArrowRightAlt />}
                >
                  Proceed to Pay
                </Button>
              </>
            </Box>
          </Box>
        </form>
      </SwipeableDrawer>

      <ProceedPaymentFailureDialog
        open={showProceedPaymentFailureDialog}
        retryPayment={handleProceedPaymentPayAdvance}
        errorCode={proceedPaymentErrorCode}
        handleClose={() => {
          setShowProceedPaymentFailureDialog(false);
        }}
      />
    </>
  );
};

export default PayAdvanceForSkuDialog;
