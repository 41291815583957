import React from "react";
import { useNavigate } from "react-router-dom";

import { ArrowRightAlt } from "@mui/icons-material";
import { Button, Container, Typography } from "@mui/material";

import useQuery from "../../Hooks/useQuery";
import AnalyticsService from "../../Services/AnalyticsService";
import AmountUtils from "../../Utils/AmountUtils";
import PartnerService from "../../Services/PartnerService";

export default function FreightChargeSuccessPage() {
  const query = useQuery();
  const orderId = query.get("orderId");
  const freightCharges = query.get("freightCharges") || 0;
  const navigate = useNavigate();

  return (
    <div>
      <Container
        sx={{ padding: "40% 44px 0", height: "80vh", textAlign: "center" }}
      >
        <img src={`/icons/success.svg`} alt="success" />
        <Typography sx={{ fontSize: "18px" }}>
          {PartnerService.checkIfFieldAgent() ? `Partner has` : `You have`}{" "}
          successfully received{" "}
          <b>{AmountUtils.formatCurrency(freightCharges)} credit note</b> for
          freight charges against order id {orderId}
        </Typography>
      </Container>

      <Button
        fullWidth
        variant={"contained"}
        endIcon={<ArrowRightAlt />}
        onClick={() => {
          AnalyticsService.pushEvent("Button Clicked", {
            type: "Go To Hisaab",
          });
          navigate("/hisaab/transactions", { replace: true });
        }}
        sx={{
          position: "fixed",
          bottom: 0,
          height: "45px",
          borderRadius: "0",
        }}
      >
        Go to Hisaab
      </Button>
    </div>
  );
}
