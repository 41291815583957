import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ArrowBack } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AndroidService from "../../Services/AndroidService";
import CartService from "../../Services/CartService";
import AnalyticsService from "../../Services/AnalyticsService";
import PartnerService from "../../Services/PartnerService";
import UnverifiedPartnersDialog from "../Common/UnverifiedPartnersDialog";
import Search from "../Dashboard/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "#9D2123",
  },
  appBar: {
    backgroundColor: "#9D2123",
    border: `none`,
    boxShadow: `none`,
  },
  titleBrand: {
    flexGrow: 1,
    fontWeight: "500",
    fontSize: "18px",
  },
  title: {
    flexGrow: 1,
    fontWeight: "500",
    fontSize: "18px",
    color: "white",
  },
  callButton: {
    padding: theme.spacing(0, 1),
    textTransform: "none",
  },
  badge: {
    backgroundColor: "#FFBB33",
  },
  searchContainer: {
    borderRadius: "4px",
  },
  headerText: {
    textOverflow: "ellipsis",
    maxWidth: "250px",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

export default function TopBar(props) {
  const classes = useStyles();
  let navigate = useNavigate();

  const [cartProducts, setCartProducts] = useState(null);
  const [isAndroid, setIsAndroid] = useState(false);
  const [showUnauthorizedError, setShowUnauthorizedError] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const showToolbarIcons =
    PartnerService.hasOnboarded() || PartnerService.hasOnboardedAsFieldAgent();

  useEffect(() => {
    try {
      /*global Android */
      if (Android) {
        setIsAndroid(true);
      }
    } catch (e) {}
    if (!props?.showCart) {
      return;
    }
    const { axiosRequest, abortController } = CartService.fetchAndStoreCart();
    axiosRequest
      .then((products) => {
        let numberOfProducts = 0;
        if (products) {
          numberOfProducts = products.length;
          setCartProducts(products);
        }
        setCartItemCount(numberOfProducts);
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
        }
      });
    return () => abortController.abort();
  }, [localStorage.getItem(`cartv2`)]);

  const handleOnClickShowCart = () => {
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      AnalyticsService.pushEvent(`My Cart Clicked`);
      setShowUnauthorizedError(true);
    } else {
      const showCartClickEventData = {
        numberOfProduct: cartProducts?.length || 0,
        productName: cartProducts?.map((p) => p.productName).toString() || "",
        skuCodes: cartProducts?.map((p) => p.skuCode).toString() || "",
        sellingPrices:
          cartProducts?.map((p) => p.sellingPrice).toString() || "",
        totalOrderValue:
          cartProducts?.map((p) => p.sellingPrice * p.quantity).length > 0
            ? cartProducts
                .map((p) => p.sellingPrice * p.quantity)
                .reduce((a, b) => a + b) || 0
            : 0,
      };

      AnalyticsService.pushEvent(`My Cart Clicked`, showCartClickEventData);

      navigate(`/cart`);
    }
  };

  const onNotificationIconClicked = () => {
    if (AndroidService.checkIfAndroid()) {
      //Confirm if we want to handle backward compatibility.
      AndroidService.handleNotificationRedirectionWithPermission();
    }
  };

  const backNavigationClickHandler = () => {
    if (props.handleBackClickEvent) {
      props.handleBackClickEvent();
    } else if (props.backLink) {
      navigate({
        pathname: props.backLink,
        state: { from: window.location.pathname },
      });
    } else if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate({
        pathname: "/",
      });
    }
  };

  const BackNavigation = () => {
    return (
      <Box>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={backNavigationClickHandler}
          size="large"
        >
          <ArrowBack sx={{ color: "white" }} />
        </IconButton>
      </Box>
    );
  };

  const Header = () => {
    return (
      <Typography
        variant="h6"
        className={props.backNavigation ? classes.title : classes.titleBrand}
        color={"primary"}
      >
        {props.backNavigation ? (
          props.toolTip && props.toolTip !== null ? (
            <Tooltip title={props.toolTip}>
              <div className={classes.headerText}>{props.title}</div>
            </Tooltip>
          ) : (
            props.title
          )
        ) : (
          <>
            {localStorage.getItem("partnerStatus") === "INACTIVE" ? (
              <img
                src={`/icons/saathi-logo.png`}
                height={30}
                alt={`saathi-logo`}
              />
            ) : (
              <a href={`/dashboard`}>
                <img
                  src={`/icons/saathiLogoWhite.svg`}
                  height={30}
                  alt={`saathi-logo`}
                />
              </a>
            )}
          </>
        )}
      </Typography>
    );
  };

  const FieldAgentLogout = () => {
    return (
      <Button
        variant="text"
        color="primary"
        size={"large"}
        className={classes.callButton}
        onClick={() => {
          if (props.handleLogout) {
            props.handleLogout();
          }
        }}
      >
        {/*<Typography variant={`body2`} fontWeight={700}>*/}
        <b style={{ color: "white" }}>Logout</b>
        {/*</Typography>*/}
      </Button>
    );
  };

  const NotificationIcon = () => {
    return (
      <img
        style={{ margin: "6px", alignSelf: "center" }}
        src={`/icons/Notification.svg`}
        onClick={onNotificationIconClicked}
      />
    );
  };

  const CartIcon = () => {
    return (
      <IconButton
        edge={`end`}
        size={"medium"}
        color={"primary"}
        sx={{ margin: "0px 4px" }}
        onClick={() => handleOnClickShowCart()}
      >
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={cartItemCount}
          showZero
          sx={{
            ".MuiBadge-badge": {
              background: "#FFBB33",
              color: "black",
            },
          }}
        >
          <img src={`/icons/BagHeader.svg`} alt={`cart`} height={24} />
        </Badge>
      </IconButton>
    );
  };

  const DownloadInvoiceAction = () => {
    return (
      <IconButton
        size={"small"}
        onClick={() => props.handleOnClickInvoiceDownload()}
      >
        <FileDownloadIcon sx={{ color: "#FFFFFF" }} />
        <Typography variant="h6" color={"#FFFFFF"}>
          Invoice
        </Typography>{" "}
      </IconButton>
    );
  };

  const GenericTextOrIcon = () => {
    return (
      <Typography
        variant="subtitle2"
        color={"primary"}
        fontWeight={500}
        onClick={
          props?.otherTextOrIconToShow?.function
            ? props?.otherTextOrIconToShow?.function
            : () => {}
        }
      >
        {props?.otherTextOrIconToShow?.text ||
          props?.otherTextOrIconToShow?.icon}
      </Typography>
    );
  };

  return props.backNavigation === true ||
    props.showFieldAgentLogout === true ||
    props.showCallAgriDoctor === true ||
    props.showHowToUseVideo === true ||
    props.backLink === true ||
    props.showInvoiceDownload === true ||
    props.showNotificationIcon === true ||
    props.showCart === true ||
    props.showSearchBar === true ? (
    <div className={classes.root}>
      <AppBar
        position={props.position ? props.position : "static"}
        color={"transparent"}
        className={classes.appBar}
      >
        <Toolbar>
          {props.backNavigation && <BackNavigation />}
          {!props.showSearchBar && <Header />}
          {props.showSearchBar && (
            <Search
              page={props.currentPage}
              noPaddingRequired={true}
              placeholder={"Search"}
              showingOnTopBar={true}
              currentPage={props.currentPage}
            ></Search>
          )}
          {props.showFieldAgentLogout && <FieldAgentLogout />}
          {showToolbarIcons && props.showNotificationIcon && (
            <NotificationIcon />
          )}
          {showToolbarIcons && props.showCart && <CartIcon />}
          {props.showInvoiceDownload && <DownloadInvoiceAction />}
          {props?.otherTextOrIconToShow && <GenericTextOrIcon />}
        </Toolbar>
      </AppBar>
      <UnverifiedPartnersDialog
        showError={showUnauthorizedError}
        setShowError={setShowUnauthorizedError}
        error={
          "You will get access to this feature once you become our Saathi partner"
        }
      />
    </div>
  ) : (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#FFFFFF",
        paddingTop: 1.2,
        height: 1,
      }}
    ></Box>
  );
}
