import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";
import ErrorBoundary from "../Common/ErrorBoundary";
import AndroidService from "../../Services/AndroidService";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.1)",
    margin: theme.spacing(-1, 1),
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "16px 0px !important",
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: "12px",
    wordBreak: "break-word",
    textTransform: "capitalize",
  },
  liveButton: {
    border: "1px solid rgba(157, 33, 35, 1)",
    borderRadius: "16px",
    padding: "8px",
    opacity: "0px",
  },
  liveCard: {
    borderRadius: theme.spacing(2),
    margin: theme.spacing(0, 0.5),
  },
  cardContentContainer: {
    margin: theme.spacing(-1, -1, -0.8, -1),
    paddingBottom: "0px !important",
  },
  contentVideoThumbnailContainer: {
    borderRadius: theme.spacing(2),
    position: "relative",
  },
  contentVideoThumbnail: {
    objectFit: "contain",
  },
  playButtonBase: {
    top: theme.spacing(-11.5),
    left: "39%",
    marginBottom: theme.spacing(-8.5),
  },
  playButton: {
    width: "30px",
    height: "30px",
    opacity: "0.9",
    alignSelf: "center",
  },
  videoTitle: {
    fontWeight: 600,
    fontSize: "14px",
    wordBreak: "break-word",
    marginTop: theme.spacing(0.5),
  },
  liveChip: {
    position: "absolute",
    top: theme.spacing(0.7),
    left: theme.spacing(0.7),
    height: "16px",
    background: "#EF5350",
    padding: theme.spacing(1),
    borderRadius: "4px",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
  },
  dot1: {
    height: "10px",
    width: "10px",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginRight: "3px",
  },
  dot2: {
    height: "5px",
    width: "5px",
    backgroundColor: "#EF5350",
    borderRadius: "50%",
    display: "inline-block",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  tag: {
    backgroundColor: "lightyellow !important",
    color: "#666666",
    fontSize: "11px",
    height: "18px",
    fontWeight: "400",
    boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.10)",
  },
}));

const LiveSessionContainer = ({ liveSession, page }) => {
  const classes = useStyles();

  const handleVideoClick = () => {
    AnalyticsService.pushEvent(`Live Session Link Clicked`, {
      page: "/learning-videos",
      farmerId: localStorage.getItem("farmerId"),
      source: localStorage.getItem("source"),
      "Video Code": liveSession?.videoCode,
      "Type Of Video": liveSession?.typeOfVid,
    });

    window.open(liveSession?.redirectionLink, "_blank");
  };

  const shareText = `Join us for live sessions! Click the link to join our Session ${liveSession?.redirectionLink}`;
  const handleAndroidShareClickEvent = (
    event,
    sessionThumbnail,
    sessionLink
  ) => {
    handleWhatsAppShareClickEvent(event, sessionLink);
    AndroidService.shareOnWhatsApp(shareText, sessionThumbnail);
  };

  const handleWhatsAppShareClickEvent = (event, sessionLink) => {
    event.stopPropagation();
    AnalyticsService.pushEvent("Button Clicked", {
      type: "WhatsApp Share",
      videoUrl: sessionLink,
      shareType: "Live Session",
    });
  };

  return (
    <>
      {liveSession && liveSession?.link && liveSession?.redirectionLink ? (
        <div
          style={{
            margin:
              page === "dashboard" ? "24px 8px 0px 8px" : "24px 8px 16px 8px",
          }}
        >
          <ErrorBoundary message={``}>
            {page === "dashboard" && (
              <Card
                className={classes.card}
                variant={"outlined"}
                onClick={() => handleVideoClick()}
              >
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.contentTitle}>
                    <b style={{ color: "#9D2123" }}>Live Session</b>
                    <br />
                    Join session & learn more
                  </Typography>
                  <Button
                    variant={"outlined"}
                    className={classes.liveButton}
                    onClick={() => handleVideoClick()}
                  >
                    <Typography className={classes.contentTitle}>
                      Join Now
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            )}
          </ErrorBoundary>

          <ErrorBoundary message={``}>
            {page === "learning-videos" && (
              <Card
                className={classes.liveCard}
                variant={"outlined"}
                onClick={() => handleVideoClick()}
              >
                <CardContent className={classes.cardContentContainer}>
                  <Grid
                    container
                    direction="row"
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    spacing={1}
                  >
                    <Grid item xs={6}>
                      <div className={classes.contentVideoThumbnailContainer}>
                        <CardMedia
                          component="img"
                          width="140"
                          image={liveSession?.link}
                          alt={liveSession?.videoCode}
                          classes={{
                            img: classes.contentVideoThumbnail,
                          }}
                        />
                        <div className={classes.liveChip}>
                          <div>
                            <span className={classes.dot1}>
                              <span className={classes.dot2}></span>
                            </span>
                          </div>
                          <Typography>Live</Typography>
                        </div>
                      </div>
                      {liveSession?.link ? (
                        <ButtonBase
                          centerRipple={true}
                          focusRipple={true}
                          aria-label="play"
                          className={classes.playButtonBase}
                          size="medium"
                        >
                          <img
                            src="/icons/playThumbnail.svg"
                            alt="play"
                            className={classes.playButton}
                          />
                        </ButtonBase>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        direction="column"
                        justifyContent={"space-between"}
                        alignItems={"normal"}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                          >
                            <Grid item>
                              <Chip
                                label={liveSession?.typeOfVid}
                                className={classes.tag}
                                clickable={false}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.videoTitle}>
                            {liveSession?.videoTitle}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </ErrorBoundary>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LiveSessionContainer;
