import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../Common/TopBar";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";
import { Container, Tab, Tabs } from "@mui/material";
import PartnersSearch from "../Partners/PartnersSearch";
import ErrorBoundary from "../Common/ErrorBoundary";
import FieldAgentLeads from "./FieldAgentLeads";
import FieldAgentOrders from "./FieldAgentOrders";
import AgentService from "../../Services/AgentService";

export default function FieldAgentCommercePage() {
  const [currentTab, setCurrentTab] = useState(
    sessionStorage.getItem("currentCommerceTab")
      ? sessionStorage.getItem("currentCommerceTab")
      : "orders"
  );
  const [leadsList, setLeadsList] = useState(null);
  const [ordersList, setOrdersList] = useState(null);
  const [totalLeadsCount, setTotalLeadsCount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [currenLeadsCount, setCurrenLeadsCount] = useState(0);
  const [currentOrdersCount, setCurrentOrdersCount] = useState(0);
  const [loading, setLoading] = useState("not_started");
  const [searchLeadsKeyword, setSearchLeadsKeyword] = useState("");
  const [searchOrdersKeyword, setSearchOrdersKeyword] = useState("");
  const [orderDateRangeFilter, setOrderDateRangeFilter] = useState(null);
  const navigate = useNavigate();
  const LEADS_TO_SHOW_IN_A_SINGLE_SCROLL = 10;
  const ORDERS_TO_SHOW_IN_A_SINGLE_SCROLL = 10;

  useEffect(() => {
    setLoading("started");
    if (currentTab === "leads") {
      const leadsCB = getAgentLeads(true);
      return () => leadsCB();
    } else if (currentTab === "orders") {
      // when user applies date filter OR changes the tab (Orders/Leads), we need to fetch order list from the start again (offset = 0)
      const agentOrdersCB = getAgentOrders(true);
      return () => agentOrdersCB();
    }
  }, [currentTab, orderDateRangeFilter]);

  const handleLogout = () => {
    PartnerService.logoutFieldAgent();
    AnalyticsService.pushEvent("Logout As Field Agent Clicked");
    navigate("/");
    window.location.reload();
  };

  const getAgentLeads = (fetchFromStarting) => {
    const { axiosRequest, abortController } = AgentService.getFieldAgentLeads(
      fetchFromStarting ? 0 : currenLeadsCount,
      LEADS_TO_SHOW_IN_A_SINGLE_SCROLL,
      searchLeadsKeyword
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.purchaseRequests) {
          setLeadsList([
            ...(fetchFromStarting ? [] : leadsList || []),
            ...response?.data?.responseData?.purchaseRequests,
          ]);
          setTotalLeadsCount(
            response?.data?.responseData?.totalCountOfPurchaseRequests
          );
          setCurrenLeadsCount(
            (fetchFromStarting ? 0 : currenLeadsCount) +
              response?.data?.responseData?.purchaseRequests.length
          );
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.error("Failed to Get FieldAgentLeads List: ", error);
        }
      });
    return () => abortController.abort();
  };

  const getAgentOrders = (fetchFromStarting) => {
    const { axiosRequest, abortController } = AgentService.getFieldAgentOrders(
      fetchFromStarting ? 0 : currentOrdersCount,
      ORDERS_TO_SHOW_IN_A_SINGLE_SCROLL,
      searchOrdersKeyword,
      orderDateRangeFilter
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.fieldOrders) {
          setOrdersList([
            ...(fetchFromStarting ? [] : ordersList || []),
            ...response?.data?.responseData?.fieldOrders,
          ]);
          setTotalOrdersCount(response?.data?.responseData?.totalFieldOrders);
          setCurrentOrdersCount(
            (fetchFromStarting ? 0 : currentOrdersCount) +
              response?.data?.responseData?.fieldOrders?.length
          );
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.error("Failed to Get Field Agent Orders: ", error);
        }
      });
    return () => abortController.abort();
  };

  const handleSearch = (keyword) => {
    if (keyword) {
      if (currentTab === "leads") {
        setSearchLeadsKeyword(keyword);
        setLeadsList([]);
        setTotalLeadsCount(0);
        setCurrenLeadsCount(0);
        setLoading("started");
        const { axiosRequest } = AgentService.getFieldAgentLeads(
          0,
          LEADS_TO_SHOW_IN_A_SINGLE_SCROLL,
          keyword
        );
        axiosRequest
          .then((response) => {
            if (response?.data?.responseData?.purchaseRequests) {
              setLeadsList(response?.data?.responseData?.purchaseRequests);
              setTotalLeadsCount(
                response?.data?.responseData?.totalCountOfPurchaseRequests
              );
              setCurrenLeadsCount(
                response?.data?.responseData?.purchaseRequests.length
              );
            }
            setLoading("done");
          })
          .catch((error) => {
            setLoading("done");
            console.error("Failed to Get FieldAgentLeads List: ", error);
          });
      } else if (currentTab === "orders") {
        setSearchOrdersKeyword(keyword);
        setOrdersList([]);
        setTotalOrdersCount(0);
        setCurrentOrdersCount(0);
        setLoading("started");
        const { axiosRequest } = AgentService.getFieldAgentOrders(
          0,
          ORDERS_TO_SHOW_IN_A_SINGLE_SCROLL,
          keyword,
          orderDateRangeFilter
        );
        axiosRequest
          .then((response) => {
            if (response?.data?.responseData?.fieldOrders) {
              setOrdersList(response?.data?.responseData?.fieldOrders);
              setTotalOrdersCount(
                response?.data?.responseData?.totalFieldOrders
              );
              setCurrentOrdersCount(
                response?.data?.responseData?.fieldOrders?.length
              );
            }
            setLoading("done");
          })
          .catch((error) => {
            setLoading("done");
            console.error("Failed to Get Field Agent Orders: ", error);
          });
      }
    } else {
      clearSearch();
    }
  };

  const clearSearch = () => {
    if (currentTab === "leads") {
      setSearchLeadsKeyword("");
      setLeadsList([]);
      setTotalLeadsCount(0);
      setCurrenLeadsCount(0);
      setLoading("started");
      const { axiosRequest } = AgentService.getFieldAgentLeads(
        0,
        LEADS_TO_SHOW_IN_A_SINGLE_SCROLL
      );
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.purchaseRequests) {
            setLeadsList(response?.data?.responseData?.purchaseRequests);
            setTotalLeadsCount(
              response?.data?.responseData?.totalCountOfPurchaseRequests
            );
            setCurrenLeadsCount(
              response?.data?.responseData?.purchaseRequests.length
            );
          }
          setLoading("done");
        })
        .catch((error) => {
          setLoading("done");
          console.error("Failed to Get FieldAgentLeads List: ", error);
        });
    } else if (currentTab === "orders") {
      setSearchOrdersKeyword("");
      setOrdersList([]);
      setTotalOrdersCount(0);
      setCurrentOrdersCount(0);
      setLoading("started");
      const { axiosRequest } = AgentService.getFieldAgentOrders(
        0,
        ORDERS_TO_SHOW_IN_A_SINGLE_SCROLL,
        null,
        orderDateRangeFilter
      );
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.fieldOrders) {
            setOrdersList(response?.data?.responseData?.fieldOrders);
            setTotalOrdersCount(response?.data?.responseData?.totalFieldOrders);
            setCurrentOrdersCount(
              response?.data?.responseData?.fieldOrders?.length
            );
          }
          setLoading("done");
        })
        .catch((error) => {
          setLoading("done");
          console.error("Failed to Get Field Agent Orders: ", error);
        });
    }
  };

  const handleTabClickedEvent = (tabName, pageName) => {
    AnalyticsService.pushEvent(`Tab Clicked`, {
      tabName: tabName,
      page: pageName,
    });
    setCurrentTab(tabName);
    sessionStorage.setItem("currentCommerceTab", tabName);
  };

  const handleClearOrderDateRangeFilter = () => {
    setOrderDateRangeFilter(null);
    AnalyticsService.pushEvent(`Button Clicked`, {
      type: "Clear All Filters",
      page: "My Orders",
    });
  };

  return (
    <>
      <TopBar title="My Orders" backNavigation={true} />

      <ErrorBoundary message={``}>
        <Tabs
          value={currentTab}
          centered
          sx={{
            background: "#ffffff",
            margin: "16px",
            marginBottom: "16px",
            padding: "0px 24px",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
            borderRadius: "20px",
          }}
        >
          <Tab
            label="Orders"
            value={"orders"}
            sx={{
              width: "50%",
              borderBottom: `${
                currentTab === `orders` ? `1px solid #9D2123` : `none`
              }`,
              color: `${currentTab === `orders` ? `#9D2123` : `#333333`}`,
              opacity: `${currentTab === `orders` ? `1` : `0.6`}`,
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "20px 16px",
            }}
            onClick={() => {
              handleTabClickedEvent("orders", "My Orders");
            }}
          />
          <Tab
            label="Leads"
            value={"leads"}
            sx={{
              width: "50%",
              borderBottom: `${
                currentTab === `leads` ? `1px solid #9D2123` : `none`
              }`,
              color: `${currentTab === `leads` ? `#9D2123` : `#333333`}`,
              opacity: `${currentTab === `leads` ? `1` : `0.6`}`,
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "20px 16px",
            }}
            onClick={() => {
              handleTabClickedEvent("leads", "My Orders");
            }}
          />
        </Tabs>
      </ErrorBoundary>

      {currentTab === "leads" && (
        <FieldAgentLeads
          loading={loading}
          leadsList={leadsList}
          currentCount={currenLeadsCount}
          totalCount={totalLeadsCount}
          getAgentLeads={getAgentLeads}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          searchKeyword={searchLeadsKeyword}
        />
      )}

      {currentTab === "orders" && (
        <FieldAgentOrders
          loading={loading}
          ordersList={ordersList}
          currentCount={currentOrdersCount}
          totalCount={totalOrdersCount}
          getAgentOrders={getAgentOrders}
          orderDateRangeFilter={orderDateRangeFilter}
          setOrderDateRangeFilter={setOrderDateRangeFilter}
          handleClearOrderDateRangeFilter={handleClearOrderDateRangeFilter}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          searchKeyword={searchOrdersKeyword}
        />
      )}
    </>
  );
}
