import { Box, Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductService from "../../Services/ProductService";

const ProductVariant = (props) => {
  const handleClickVariant = (variant, index) => {
    AnalyticsService.pushEvent(`Product Variant Clicked`, {
      page: props?.page ? props.page : "",
      position: index + 1 || "",
      mrp: props?.product?.mrp || 0,
      sellingPrice: props?.product?.sellingPrice || 0,
      isOfferAvailable:
        props?.product?.offers &&
        Object.entries(props?.product?.offers).length > 0
          ? true
          : false,
      productName: props?.product?.productName || "",
      skuCode: props?.product?.skuCode || "",
    });
    // if (variant?.sku_code) {
    //   navigate(
    //     `/products/${variant.sku_code}?from=/products/${props.product.skuCode}`,
    //   );
    // }
  };

  return (
    <>
      {props.loading === "started" && (
        <Skeleton variant="rectangular" height={50} sx={{ marginTop: "8px" }} />
      )}
      {props.loading === "done" && (
        <Box sx={{ marginRight: "-15px" }}>
          <Grid
            container
            alignItems={`center`}
            sx={{
              paddingBottom: "8px",
              paddingTop: "10px",
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              display: `${
                props.productDetailsStickyAtTop ? `none !important` : `flex`
              }`,
              transition: ProductService.productDetailsStickyTransition,
            }}
          >
            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{ fontSize: "16px", fontWeight: "600" }}
              >
                {`Other Variants:`}
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ paddingLeft: "4px" }}>
              <Stack direction="row" spacing={1}>
                {_.map(props?.product?.variants, (variant, index) => {
                  return (
                    <a
                      href={`/products/${variant?.sku_code}?from=${window.location.pathname}`}
                      style={{
                        textDecoration: `none`,
                        // color: "rgba(0, 0, 0, 0.87)",
                      }}
                      onClick={() => handleClickVariant(variant, index)}
                    >
                      <Chip
                        key={index}
                        label={variant?.label}
                        clickable={true}
                        variant="outlined"
                        sx={{
                          border: "1px solid #9D2123",
                          color: "#9D2123",
                          fontSize: "14px",
                          fontWeight: "700",
                          padding: "0px 4px",
                        }}
                        // onClick={() => {
                        //   handleClickVariant(variant, index);
                        // }}
                      />
                    </a>
                  );
                })}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProductVariant;
