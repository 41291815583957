import React, { useEffect } from "react";

import TopBar from "../Common/TopBar";
import config from "../../config";
import useQuery from "../../Hooks/useQuery";
import Languages from "../../Services/LanguageCode";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";

export default function KrishiGyanPage() {
  const query = useQuery();
  const crop = query.get("crop") || "";
  const redirectTo = query.get("redirectTo") || "";
  const language = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "en";

  const params = {
    language,
    state: (
      localStorage.getItem(`state`) || localStorage.getItem(`fieldAgentState`)
    )
      ?.toLowerCase()
      ?.split(" ")
      ?.join("-"),
    role: localStorage.getItem(`role`) === "fieldAgent" ? "FAG" : "",
    source:
      localStorage.getItem(`source`) ||
      localStorage.getItem(`fieldAgentSource`),
    mobile:
      localStorage.getItem(`mobile`) ||
      localStorage.getItem(`fieldAgentMobileNumber`),
    userId:
      localStorage.getItem(`userId`) ||
      localStorage.getItem(`fieldAgentUserId`),
    farmerId:
      localStorage.getItem(`farmerId`) ||
      localStorage.getItem(`fieldAgentUserId`),
    farmerAuthToken: localStorage.getItem(`X-Authorization-Token`),
    fieldAgentMobileNumber: localStorage.getItem(`fieldAgentMobileNumber`),
    profile: localStorage.getItem(`mobile`) ? true : false,
    height: window.innerHeight - 56 + 6,
    showProductPrices: false,
  };

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, { page: "Krishi Gyan" });
    AndroidService.unsetPullToRefresh();
    return () => {
      AndroidService.setPullToRefresh();
    };
  }, []);

  return (
    <div>
      <TopBar title={`Krishi Gyan`} backNavigation={true} />

      <embed
        type="application/xml"
        src={`${config.farmerWebAppBaseUrl}/crop-info?language=${params?.language}&state=${params?.state}&userId=${params?.userId}&farmerId=${params?.farmerId}&farmerAuthToken=${params?.farmerAuthToken}&mobile=${params?.mobile}&showHeader=false&showTasks=false&showPosts=false&appLinks=saathi&source=${params?.source}&showProductPrices=${params?.showProductPrices}&role=${params?.role}&profile=${params?.profile}&fieldAgentMobileNumber=${params?.fieldAgentMobileNumber}&redirectTo=${redirectTo}&crop=${crop}`}
        width="100%"
        height={params?.height}
      />
    </div>
  );
}
