import { useEffect, useState } from "react";

import { Card } from "@mui/material";
import ReactPlayer from "react-player";

import AnalyticsService from "../../Services/AnalyticsService";

export default function ReactAudioPlayer({
  src,
  height,
  setAutoPlayParentCarousel,
  playbackStartedEventName,
  playbackPausedEventName,
  playbackEndedEventName,
  playbackErrorEventName,
  eventProperties = {},
}) {
  const [error, setError] = useState(false);
  const [playingMediaId, setPlayingMediaId] = useState(null);

  const handlePlaybackStart = (audioId) => {
    localStorage.setItem("playingMediaId", audioId);
    window.dispatchEvent(new Event("storage"));
  };

  const handleAudioPlayerError = () => {
    setError(true);
    AnalyticsService.pushEvent(
      playbackErrorEventName || `Audio Player Error`,
      eventProperties
    );
  };

  useEffect(() => {
    window.addEventListener("storage", function () {
      setPlayingMediaId(localStorage.getItem("playingMediaId"));
    });

    return () =>
      window.addEventListener("storage", function () {
        setPlayingMediaId(localStorage.getItem("playingMediaId"));
      });
  }, []);

  return (
    <Card elevation={0}>
      {!error && (
        <ReactPlayer
          url={src}
          playing={playingMediaId === src?.split("/")?.slice(-1)?.[0]}
          height={height ? height : "100%"}
          width={"100%"}
          controls={true}
          light={false}
          config={{
            file: {
              attributes: {
                controlslist: "nodownload noplaybackrate",
              },
            },
          }}
          cons
          onClickPreview={() => {
            let audioId = src?.split("/")?.slice(-1)?.[0];
            localStorage.setItem("playingMediaId", audioId);
            window.setTimeout(() => {
              setPlayingMediaId(audioId);
            }, 300);
          }}
          onPlay={() => {
            setAutoPlayParentCarousel(false);
            AnalyticsService.pushEvent(
              playbackStartedEventName || "Audio Started",
              eventProperties
            );
            handlePlaybackStart(src?.split("/")?.slice(-1)?.[0]);
          }}
          onPause={() => {
            setAutoPlayParentCarousel(true);
            AnalyticsService.pushEvent(
              playbackPausedEventName || `Audio Paused`,
              eventProperties
            );
          }}
          onEnded={() => {
            setAutoPlayParentCarousel(true);
            AnalyticsService.pushEvent(
              playbackEndedEventName || `Audio Ended`,
              eventProperties
            );
          }}
          onError={() => {
            setAutoPlayParentCarousel(true);
            handleAudioPlayerError();
          }}
        />
      )}
    </Card>
  );
}
