import config from "../config";
import AgentMock from "./mocks/AgentMock";
import { endOfDay, getTime } from "date-fns";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class AgentService {
  static getHeaders() {
    return {
      source: localStorage.getItem(`fieldAgentSource`),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static verifyAgent(mobileNumber) {
    if (useMocks === false) {
      const url = `${config.backendUrl}institutionalsalesservice/agentverification/`;
      const data = { phoneNumber: mobileNumber };
      return HTTPService.makeXHRRequest(url, "POST", data, {
        ...this.getHeaders(),
        source: "B2B",
      });
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: AgentMock });
        }, 500);
      });
    }
  }

  static getFieldAgentLeads(offset, limit, searchTerm) {
    let agentUserId = localStorage.getItem(`fieldAgentUserId`);
    let agentMobileNum = localStorage.getItem(`fieldAgentMobileNumber`);

    let url = `${config.backendUrl}onlineecommerceservice/${agentUserId}/purchase-requests/?limit=${limit}&offset=${offset}`;
    if (searchTerm) {
      url += `&searchTerm=${searchTerm}&agentMobileNum=${agentMobileNum}`;
    }

    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static getFieldAgentOrders(offset, limit, searchTerm, orderDateRangeFilter) {
    let agentUserId = localStorage.getItem(`fieldAgentUserId`);
    let agentMobileNum = localStorage.getItem(`fieldAgentMobileNumber`);
    let startTime = getTime(new Date(orderDateRangeFilter?.[0]));
    let endTime = getTime(endOfDay(new Date(orderDateRangeFilter?.[1])));

    let url = `${config.backendUrl}onlineecommerceservice/field-agent/${agentUserId}/orders/?limit=${limit}&offset=${offset}`;

    if (searchTerm) {
      url += `&searchTerm=${searchTerm}&agentMobileNum=${agentMobileNum}`;
    }
    if (startTime && endTime) {
      url += `&startTime=${startTime}&endTime=${endTime}`;
    }

    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }
}
