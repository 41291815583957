import React, { useEffect, useRef, useState } from "react";

import ErrorBoundary from "../Common/ErrorBoundary";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowRight,
  ArrowRightAlt,
  CheckCircle,
  CheckCircleOutline,
  Close,
  CurrencyRupee,
  CurrencyRupeeOutlined,
  Error,
} from "@mui/icons-material";
import useFocus from "../../Hooks/useFocus";
import PaymentService from "../../Services/PaymentService";
import AnalyticsService from "../../Services/AnalyticsService";
import { useParams } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import SentryService from "../../Services/SentryService";
import _ from "lodash";

const RazorPayFailureDialog = (props) => {
  const { error } = useParams();
  const query = useQuery();
  const errorCode = query.get("errorCode");

  useEffect(() => {
    if (props.open === true) {
      AnalyticsService.pushEvent("Razorpay Failure Dialog Shown", { error });
      SentryService.captureErrorWithData("Razorpay Failure Dialog Shown", {
        error,
        errorCode,
      });
    }
  }, [props.open]);
  return (
    <Dialog fullWidth={true} fullScreen={true} open={true}>
      <Box sx={{ background: "#FAEEEE", height: "92vh" }}>
        <Grid container alignItems={"flex-start"} justifyContent={"flex-start"}>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <IconButton
              size={"large"}
              href={"/hisaab"}
              sx={{ color: "#757575" }}
              onClick={() => {
                AnalyticsService.pushEvent("Razorpay Failure Dialog Closed", {
                  error,
                  errorCode,
                });
                // props.handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ marginTop: "20vh" }}
        >
          <Grid item>
            <Error color={"error"} sx={{ fontSize: "60px" }} />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ marginTop: "3vh" }}
        >
          <Grid item>
            <Typography variant={"h6"} align={"center"} color={"error"}>
              Sorry, Payment Failed.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ marginTop: "1vh" }}
        >
          <Grid item xs={9}>
            <Typography variant={"body1"} align={"center"}>
              Your transaction has failed, if you have been charged we will
              contact you. Please click to retry.
            </Typography>
          </Grid>
        </Grid>
        {error && (
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginTop: "3vh" }}
          >
            <Grid item>
              <Typography
                variant={"caption"}
                align={"center"}
                color={"error"}
                sx={{ color: "#222222" }}
              >
                {error}
                {!_.isNil(errorCode) && <Box>{`ERROR_CODE_${errorCode}`}</Box>}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>

      <Button
        variant={"contained"}
        fullWidth={true}
        color={"primary"}
        onClick={() => {
          AnalyticsService.pushEvent(
            "Razorpay Failure Dialog Retry Payment Clicked",
            { error, errorCode }
          );
        }}
        href={"/hisaab?payment=true&type=due"}
        sx={{
          marginTop: "8px",
          position: "fixed",
          bottom: 0,
          borderRadius: "0",
          zIndex: 0,
          height: "8vh",
        }}
      >
        Retry Payment
      </Button>
    </Dialog>
  );
};

export default RazorPayFailureDialog;
