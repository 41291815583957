import React from "react";
import "react-block-ui/style.css";

import { Alert, Snackbar } from "@mui/material";

function CouponToast(props) {
  return (
    <Snackbar
      // style={{ top: "70vh" }}
      open={props.show}
      autoHideDuration={3000}
      onClose={() => props.setShow(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ marginBottom: props.doubleMargin ? "100px" : "46px" }}
    >
      <Alert
        sx={{
          background: "#666666",
          color: "#ffffff",
          borderRadius: "6px",
          textAlign: "center",
        }}
        icon={false}
        onClose={() => props.setShow(false)}
      >
        {props.showRemovedText
          ? "Sorry, the coupon you tried to use is no longer available."
          : "Coupon application failed"}
        {props.error && (
          <>
            ,<br />
          </>
        )}
        <b>{props.error}</b>
      </Alert>
    </Snackbar>
  );
}

export default CouponToast;
