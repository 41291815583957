import { useNavigate } from "react-router-dom";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CartService from "../../Services/CartService";
import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";
import { useState } from "react";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";
import ViewOrUpdateCartButton from "./ViewOrUpdateCartButton";
import ProductPrice from "./ProductPrice";
import AmountUtils from "../../Utils/AmountUtils";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({
  cartButton: {
    borderRadius: "0px",
  },
  buttonContainer: {
    bottom: 0,
    position: "fixed",
    // marginLeft: "-16px",
    display: "flex",
    width: "100%",
    boxShadow: "0 -4px 10px 0 rgba(129, 129, 129, 0.3)",
    height: "50px",
    zIndex: 4,
    [theme.breakpoints.up("md")]: {
      maxWidth: "600px",
    },
  },
  messageWrapper: {
    position: "absolute",
    top: "-24px",
    height: "24px",
    fontSize: "14px",
    background: "#FFDC42",
    width: "100%",
    textAlign: "center",
  },
  messageWrapperWarning: {
    position: "absolute",
    top: "-24px",
    height: "24px",
    fontSize: "14px",
    background: "#E2B4B4",
    width: "100%",
    textAlign: "center",
  },
  promote: {
    background: "white",
    color: "#9D2123",
    "&:hover": {
      background: "#F5F5F5",
    },
    borderRadius: "0px",
  },
  iconSize: {
    height: "22px",
    marginTop: "-3px",
  },
  bottomButtons: {
    width: "100%",
  },
}));

function AddToCart(props) {
  const classes = useStyles();
  let navigate = useNavigate();
  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);

  const addToCart = (productQuantityUpdated) => {
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      AnalyticsService.pushEvent(`Add To Cart Clicked`);
      props?.setShowUnauthorizedError(true);
      props?.setUnauthorizedMessage(
        "You will get access to this feature once you become our Saathi partner"
      );
    } else if (PartnerService.isProductPriceBlocked(props?.product)) {
      AnalyticsService.pushEvent(`Add To Cart Clicked`);
      props?.setShowUnauthorizedError(true);
      props?.setUnauthorizedMessage(
        "Ordering will be enabled once pricing is available"
      );
    } else {
      let productToAdd = {
        skuCode: props.skuCode,
        quantity: props.quantity,
        image: props.product?.productImages?.[0]?.name,
        productName: props.product?.productName,
        inventory: props.product?.inventory,
        mrp: props.product.mrp,
        sellingPrice: props.product.sellingPrice,
        offers: props.product.offers,
        offersDetail: props.product.offersDetail,
        appliedOffer: props.offerAppliedOnProduct?.offerId,
        appliedOfferId: props.offerAppliedOnProduc?.id,
        appliedOfferData: props.offerAppliedOnProduct,
      };

      const addToCartClickedEventData = {
        quantity: props?.quantity || 0,
        sellingPrice: props?.product?.sellingPrice || 0,

        appliedOfferId: props?.offerAppliedOnProduct?.offerId || "",
        productName: props?.product?.productName || "",
        skuCode: props?.product?.skuCode || "",
        isOfferAvailable:
          props?.product?.offersDetail?.productOffers?.length > 0
            ? true
            : false,
        appliedOfferName: props.offerAppliedOnProduct?.offerName,
        availableOfferNames:
          props?.product?.offersDetail?.productOffers
            ?.map((p) => p.offerName)
            .toString() || "",
        section: props?.section || "",
      };

      props?.setLoading("started");
      const promise = CartService.addToCart(productToAdd);
      promise &&
        promise
          .then((couponRemovedReason) => {
            const products = CartService.getCart();
            if (productQuantityUpdated) {
              if (products.length) {
                props.setShowBagUpdatedToast(true);
              }
            } else {
              if (products.length) {
                props.setShowAddedToBagToast(true);
              }
            }
            if (couponRemovedReason) {
              props.setShowCouponRemovedToast(true, couponRemovedReason);
            }
            props?.setLoading("done");
          })
          .catch((error) => {
            console.log("Add to cart failed");
            if (productQuantityUpdated) {
              AnalyticsService.pushEvent(
                `Update Cart Failed`,
                addToCartClickedEventData
              );
            } else {
              AnalyticsService.pushEvent(
                `Add To Cart Failed`,
                addToCartClickedEventData
              );
            }
            props?.setLoading("done");
          });

      if (productQuantityUpdated) {
        AnalyticsService.pushEvent(
          `Update Cart Clicked`,
          addToCartClickedEventData
        );
      } else {
        AnalyticsService.pushEvent(
          `Add To Cart Clicked`,
          addToCartClickedEventData
        );
      }
    }
  };

  const handlePromoteWhatsappNavigation = () => {
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      AnalyticsService.pushEvent(
        `Promote On Whatsapp Clicked From Product Details Page`
      );
      props?.setShowUnauthorizedError(true);
      props?.setUnauthorizedMessage(
        "You will get access to this feature once you become our Saathi partner"
      );
    } else {
      const promoteWhatsappNavigationData = {
        page: `/products/${props.skuCode}`,
        skuCode: props.skuCode,
        productName: props.product?.productName,
      };
      AnalyticsService.pushEvent(
        `Promote On Whatsapp Clicked From Product Details Page`,
        promoteWhatsappNavigationData
      );

      if (AndroidService.checkIfAndroid()) {
        if (AndroidService.getAndroidVersion()) {
          let { appVersionCode } = AndroidService.getAndroidVersion();
          if (appVersionCode && parseInt(appVersionCode) < 4) {
            setOpenAppUpdateDialog(true);
          } else {
            navigate({
              pathname: `/products/${props.skuCode}/promote/`,
            });
          }
        } else {
          setOpenAppUpdateDialog(true);
        }
      } else {
        navigate({
          pathname: `/products/${props.skuCode}/promote/`,
        });
      }
    }
  };

  return (
    <div className={classes.buttonContainer}>
      {props.loading === "started" && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={50}
          sx={{ bgcolor: "#CF8687", width: "100%" }}
        />
      )}

      {props?.loading === "done" &&
        PartnerService.checkIfFieldAgent() &&
        props?.product?.maxPlacementAmount !== null &&
        props?.product?.maxPlacementAmount >=
          (props?.product?.pendingAmount || 0) && (
          <Typography variant={"subtitle1"} className={classes.messageWrapper}>
            This product's max billing limit is{" "}
            <strong>
              {AmountUtils.formatCurrency(
                Math.min(
                  props?.product?.maxPlacementAmount -
                    (props?.product?.pendingAmount || 0),
                  props?.product?.maxOrderAmount
                )
              )}{" "}
            </strong>
            on credit
          </Typography>
        )}

      {props.loading === "done" &&
        props?.product?.maxPlacementAmount !== null &&
        props?.product?.maxPlacementAmount <
          (props?.product?.pendingAmount || 0) && (
          <Typography
            variant={"subtitle1"}
            className={classes.messageWrapperWarning}
          >
            The product has reached its max billing limit for credit
          </Typography>
        )}

      {props?.loading === "done" &&
        (CartService.getProductFromCart(props?.skuCode) ? (
          <ViewOrUpdateCartButton
            skuCode={props?.skuCode}
            quantity={props?.quantity}
            addToCart={addToCart}
            handlePromoteWhatsappNavigation={handlePromoteWhatsappNavigation}
            setShowUnauthorizedError={props?.setShowUnauthorizedError}
            setUnauthorizedMessage={props?.setUnauthorizedMessage}
            product={props?.product}
            hideWhatsAppPromote={props?.hideWhatsAppPromote}
            section={props?.section}
          />
        ) : (
          <>
            {!props?.hideWhatsAppPromote && (
              <Button
                sx={{ width: "50%" }}
                size={"large"}
                variant={"contained"}
                color={"primary"}
                fullWidth={true}
                className={classes.promote}
                disabled={props?.quantity < 1}
                onClick={handlePromoteWhatsappNavigation}
                startIcon={
                  <img
                    src={`/icons/whatsapp.svg`}
                    alt={`promote`}
                    className={classes.iconSize}
                  />
                }
              >
                Promote
              </Button>
            )}
            <Button
              size={"large"}
              sx={{ width: props?.hideWhatsAppPromote ? "100%" : "50%" }}
              variant={"contained"}
              color={"primary"}
              fullWidth={true}
              onClick={() => {
                addToCart(false);
              }}
              className={classes.cartButton}
              disabled={props?.quantity < 1}
              type={"submit"}
              startIcon={
                <img
                  src={`/icons/bag.svg`}
                  alt=""
                  className={classes.iconSize}
                />
              }
            >
              Add to Cart
            </Button>
          </>
        ))}
      <AppVersionUnsupported
        open={openAppUpdateDialog}
        handleClose={() => {
          setOpenAppUpdateDialog(false);
        }}
      />
    </div>
  );
}

export default AddToCart;
