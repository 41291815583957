import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import SectionTitle from "./SectionTitle";
import ViewAllButton from "./ViewAllButton";
import ProductCard from "../Product/ProductCard";

const useStyles = makeStyles(() => ({
  viewAllBrandsButtonWrapperLink: {
    textDecoration: "none",
  },
  mainWrapper: {
    marginTop: "10px",
    paddingTop: "10px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  mainSkeletonWrapper: {
    marginTop: "10px",
    paddingTop: "10px",
    overflow: "auto",
    background: "#F8D149",
    paddingBottom: "10px",
  },
  mainGridContainer: {
    flexWrap: "nowrap",
    width: "100%",
  },
  mainGridItem: {
    minWidth: "171px",
    marginBottom: "10px",
  },
  headingGridItem: {
    minWidth: "150px",
    marginBottom: "10px",
  },
  headingWrapper: {
    width: "100%",
    height: "100%",
    border: "1px solid #CB9F9F",
    borderRadius: "10px",
    paddingTop: "28px",
    paddingLeft: "16px",
    backgroundImage: "url(/icons/grahakRecommendationDashboard.svg)",
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80% 75%",
    mixBlendMode: "darken",
  },
  headingBox: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
  },
  headingTextOne: {
    color: "#9D2123",
    textTransform: "uppercase",
  },
  headingTextTwo: {
    color: "#00733E",
    textTransform: "uppercase",
  },
  viewAllWrapper: {
    marginBottom: "17px",
    marginTop: "-10px",
  },
}));

function HorizontalStandardProductCardContainer({
  products,
  loading,
  error,
  numberOfProductsToShow,
  title,
  viewAllUrl,
  stateCode,
  backgroundColor,
  section,
  saveScrollYPosition,
}) {
  const classes = useStyles();

  return (
    <>
      {loading === "done" &&
        (!error || !error?.data) &&
        products &&
        products.length > 0 && (
          <div
            style={{
              background: backgroundColor,
              padding: "1px 0",
              marginTop: "20px",
            }}
          >
            <Container maxWidth={`xl`}>
              {title && title !== "" ? <SectionTitle title={title} /> : <></>}
              <div className={classes.mainWrapper}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.mainGridContainer}
                >
                  {section === "Grahak Products Recommendation" ? (
                    <Grid item xs={6} className={classes.headingGridItem}>
                      <div className={classes.headingWrapper}>
                        <Box className={classes.headingBox}>
                          <Typography
                            variant="span"
                            className={classes.headingTextOne}
                          >
                            based on
                          </Typography>{" "}
                          <Typography
                            variant="span"
                            className={classes.headingTextTwo}
                          >
                            grahak leads
                          </Typography>
                        </Box>
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {_.map(
                    products.slice(
                      0,
                      numberOfProductsToShow
                        ? numberOfProductsToShow
                        : products.length
                    ),
                    (product, index) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          key={index}
                          className={classes.mainGridItem}
                        >
                          <ProductCard
                            product={product}
                            key={product.skuCode}
                            index={index}
                            section={section}
                            stateCode={stateCode}
                            saveScrollYPosition={saveScrollYPosition}
                          />
                        </Grid>
                      );
                    }
                  )}
                  <Grid item xs={2}></Grid>
                </Grid>
              </div>
              {(!error || !error?.data) &&
                products &&
                products.length > numberOfProductsToShow && (
                  <Box className={classes.viewAllWrapper}>
                    <Link
                      to={viewAllUrl}
                      className={classes.viewAllBrandsButtonWrapperLink}
                    >
                      <ViewAllButton
                        section={section}
                        saveScrollYPosition={saveScrollYPosition}
                      />
                    </Link>
                  </Box>
                )}
            </Container>
          </div>
        )}
      {loading === "started" && !error && (
        <>
          <div className={classes.mainSkeletonWrapper}>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              wrap={"nowrap"}
              spacing={2}
            >
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}

export default HorizontalStandardProductCardContainer;
