import { Box, Grid, IconButton } from "@mui/material";
import config from "../../config";
import { EditOutlined } from "@mui/icons-material";
import AndroidService from "../../Services/AndroidService";
import ImageUploadInformationModal from "../Common/ImageUploadInformationModal";
import { useEffect, useState } from "react";
import useQuery from "../../Hooks/useQuery";
import DocumentService from "../../Services/DocumentService";
import CommonConstantValues from "../../Services/CommonConstantValues";
import HisaabService from "../../Services/HisaabService";
import AnalyticsService from "../../Services/AnalyticsService";

const storeImageHeightPixels = "180px";
const StoreImage = ({
  profileData,
  setLoading,
  setShowError,
  setProfileData,
  setOpenAppUpdateDialog,
  setShowUnauthorizedError,
}) => {
  const [showModal, setShowModal] = useState(false);
  const query = useQuery();
  const latitude = query.get("latitude");
  const longitude = query.get("longitude");
  const storeImageUrl = query.get("imageUrl");
  const userRole = localStorage.getItem("role");
  const targetImageWidth = 720;
  const targetImageHeight = 720;
  const targetImageQuality = 100;
  const shouldCompress = false;

  const handleUploadStoreImage = () => {
    AnalyticsService.pushEvent(`Store Image Upload Edit Button Clicked`, {
      page: "profile",
    });
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      setShowUnauthorizedError(true);
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (
      AndroidService.checkIfAndroid() &&
      storeImageUrl &&
      latitude &&
      longitude
    ) {
      AndroidService.showBlockingProgress("Uploading store image");
      setLoading(CommonConstantValues.STARTED);
      const institutionId = localStorage.getItem("institutionId");
      const { axiosRequest, abortController } =
        DocumentService.updateStoreLatLongAndImage(
          latitude,
          longitude,
          institutionId,
          storeImageUrl
        );
      axiosRequest
        .then(() => {
          AndroidService.hideBlockingProgress();
          AnalyticsService.pushEvent("Store Image And Location Updated", {
            page: "profile",
            storeImageUrl: storeImageUrl,
            latitude: latitude,
            longitude: longitude,
            institutionId: institutionId,
          });
          const { axiosRequest } = HisaabService.getInstitutionDetails();
          axiosRequest.then((response) => {
            setProfileData(response?.data?.responseData);
            setLoading(CommonConstantValues.DONE);
          });
          AndroidService.goBack();
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            AndroidService.hideBlockingProgress();
            AndroidService.goBack();
            setShowError(true);
            setLoading(CommonConstantValues.DONE);
          }
        });
      return () => abortController.abort();
    }
  }, [storeImageUrl]);

  const onConfirmUploadStoreImage = () => {
    setShowModal(false);
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();

        if (
          appVersionCode &&
          parseInt(appVersionCode) >=
            CommonConstantValues.STORE_IMAGE_UPLOAD_ANDROID_VERSION
        ) {
          const { axiosRequest } = DocumentService.getStoreImageUrls();
          axiosRequest
            .then(({ data }) => {
              AndroidService.captureImageAndLocation(
                `${config.appUrl}/profile`,
                true,
                data?.responseData?.putPresignedUrl,
                data?.responseData?.getUrl,
                shouldCompress,
                targetImageWidth,
                targetImageHeight,
                targetImageQuality
              );
            })
            .catch(() => {
              setShowError(true);
            });
        } else {
          setOpenAppUpdateDialog(true);
        }
      }
    }
  };
  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ position: "relative", display: "inline-block" }}
      >
        <Grid item xs={12}>
          {profileData?.storeImageUrl && !storeImageUrl ? (
            <Box
              sx={{
                textAlign: "center",
                height: storeImageHeightPixels,
                maxHeight: storeImageHeightPixels,
              }}
            >
              <img
                src={profileData?.storeImageUrl}
                style={{
                  height: storeImageHeightPixels,
                  maxWidth: "100%",
                }}
                alt=""
              />
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                height: storeImageHeightPixels,
                maxHeight: storeImageHeightPixels,
              }}
            >
              <img
                src={
                  storeImageUrl
                    ? `${storeImageUrl}`
                    : "/icons/storeFrontDummy.png"
                }
                alt="store"
                onError={(error) => console.log("Image failed to load", error)}
                style={{
                  height: storeImageHeightPixels,
                  maxWidth: "100%",
                }}
              />
            </Box>
          )}
        </Grid>
        {userRole !== "fieldAgent" && (
          <IconButton
            sx={{
              width: "32px",
              height: "32px",
              background: "rgb(43,135,93,0.8)",
              position: "absolute",
              bottom: "10px",
              right: "10px",
              "&:hover": {
                background: "rgb(43,135,93,0.8)",
              },
            }}
            onClick={handleUploadStoreImage}
          >
            <EditOutlined
              sx={{ width: "20px", height: "20px", color: "#fff" }}
            />
          </IconButton>
        )}
      </Grid>

      <ImageUploadInformationModal
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        onConfirmUploadStoreImage={onConfirmUploadStoreImage}
      />
    </>
  );
};

export default StoreImage;
