import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import GenerateQRCode from "./GenerateQRCode";
import useQuery from "../../Hooks/useQuery";
import _ from "lodash";

const ScanQR = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const cartId = query.get("cartId");
  const cartStatus = query.get("cartStatus");
  const timestamp = query.get("timestamp");
  const farmerName = query.get("farmerName");
  const agroStarPointsUsed = query.get("agroStarPointsUsed");

  const qrData = _.join([cartId, cartStatus, timestamp], ",");

  const handleButtonClicked = (type) => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: type,
      page: "Scan QR Page",
    });
    navigate("/store-manager/store-orders", { replace: true });
  };
  return (
    <>
      <Box>
        <Dialog fullWidth={true} fullScreen={true} open={true}>
          <Box sx={{ height: "92vh" }}>
            <Grid
              container
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Grid item xs={10} />
              <Grid item xs={2}>
                <IconButton
                  size={"large"}
                  sx={{ color: "#757575" }}
                  onClick={() => handleButtonClicked("Close")}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ marginTop: "7vh" }}
            >
              <Grid item>
                <img src="/icons/points.svg" height="70px" alt="" />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ marginTop: "1vh", padding: "12px 24px" }}
            >
              <Grid item>
                <Typography
                  variant={"h6"}
                  align={"center"}
                  sx={{ fontWeight: "400", fontSize: "14px" }}
                >
                  Ask {farmerName} to scan the QR to get {agroStarPointsUsed}{" "}
                  points in your wallet.
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ textAlign: "center" }}>
              <GenerateQRCode qrData={qrData} />
            </Box>
          </Box>
          <Box
            sx={{
              height: "8vh",
              display: "flex",
            }}
          >
            <Button
              variant={"contained"}
              fullWidth={true}
              color={"primary"}
              sx={{ borderRadius: "0px", textTransform: "capitalize" }}
              onClick={() => handleButtonClicked("Done")}
            >
              Done
            </Button>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default ScanQR;
