import { Grid, Typography } from "@mui/material";
import SignAgreementButton from "../Dashboard/SignAgreementButton";
import { Done } from "@mui/icons-material";

const SignAgreementNudge = (props) => {
  const showSignAgreementNudge = () => {
    let showNudgeFlag = false;
    if (props?.partnerStatus === "UNVERIFIED") {
      if (
        localStorage.getItem("onboardingReferrerSalesOfficerEmailID") &&
        localStorage.getItem("onboardingReferrerSalesOfficerEmailID") !== ""
      ) {
        if (localStorage.getItem("role") === "fieldAgent") {
          showNudgeFlag = true;
        }
        showNudgeFlag = true;
      } else {
        if (
          props?.partnerSubStatus === "DOCUMENTS_VERIFIED" ||
          props?.partnerSubStatus === "SECURITY_DEPOSIT_PAID"
        ) {
          showNudgeFlag = true;
        }
      }
    }
    return showNudgeFlag;
  };

  return (
    <>
      {showSignAgreementNudge() ? (
        <Grid
          container
          alignItems={"center"}
          sx={{
            color:
              props?.agreementSigningStatus !== "SUCCESS" ? "black" : "green",
            marginBottom: "8px",
            padding: "6px",
            border: "2px solid #1C1C1C10",
            borderRadius: "16px",
            boxShadow: "2px 4px 15px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Grid
            item
            xs={7.5}
            sx={{ display: "flex", alignItems: "center", paddingLeft: "8px" }}
          >
            {props?.agreementSigningStatus === "SUCCESS" && (
              <Done sx={{ color: "green" }} />
            )}
            <Typography fontWeight={700} fontSize={"12px"} paddingLeft={"4px"}>
              {props?.agreementSigningStatus !== "SUCCESS"
                ? "Sign Agreement"
                : "Agreement Signed"}
            </Typography>
          </Grid>
          <Grid item xs={4.5} textAlign={"right"}>
            <SignAgreementButton
              setLoadingAgreementSigningWindow={
                props?.setLoadingAgreementSigningWindow
              }
              agreementSigningStatus={props?.agreementSigningStatus}
              setOpenUploadDrawer={props?.setOpenUploadDrawer}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default SignAgreementNudge;
