import states from "../Services/States";

export default class StateUtils {
  static getStateCode() {
    return states.filter((data) => {
      return (
        data.stateName?.toUpperCase() ===
        localStorage.getItem("state")?.toUpperCase()
      );
    })?.[0]?.["stateCode"];
  }

  static getAppSource() {
    const source = localStorage.getItem("source")?.split("B2B")?.[1];
    if (!source) {
      return "";
    }

    return `APP${source}`;
  }
}
