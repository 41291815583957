import { Box, Button, Grid, SwipeableDrawer, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AnalyticsService from "../../Services/AnalyticsService";
import SignAgreementNudge from "../AgreementSigning/SignAgreementNudge";
import { useNavigate } from "react-router-dom";
import { Done } from "@mui/icons-material";
import PartnerService from "../../Services/PartnerService";

const OnboardingProcessCTA = (props) => {
  let navigate = useNavigate();
  const securityDepositAmount = Number(
    localStorage.getItem(`securityDepositAmount`)
  );
  return (
    <>
      <SwipeableDrawer
        anchor={`bottom`}
        open={props?.open}
        onClose={props?.onClose}
        onOpen={() => {}}
        PaperProps={{
          style: {
            borderRadius: "24px 24px 0px 0px",
            overflowY: "hidden",
          },
        }}
        disableDiscovery={true}
        disableSwipeToOpen={true}
      >
        <Box
          sx={{
            background: "#FFFFFF",
            maxHeight: "80vh",
            overflow: "scroll",
          }}
        >
          <Grid
            container
            justifyContent={"flex-end"}
            sx={{
              p: 2,
              pb: 2,
              position: "sticky",
              top: 0,
              bgcolor: "#FFF",
            }}
          >
            <CloseIcon
              onClick={() => {
                props?.onClose();
                AnalyticsService.pushEvent("Cancel Icon Clicked", {
                  drawerType: "Onboarding Process Nudge Drawer",
                  page: window.location.pathname,
                });
              }}
              sx={{
                color: "#999999",
                fontSize: "28px",
                cursor: "pointer",
                float: "right",
              }}
            />
          </Grid>
        </Box>
        <Box sx={{ margin: "0px 10px 10px" }}>
          {props?.partnerSubStatus === "DOCUMENTS_UPLOAD_PENDING" && (
            <Grid
              container
              alignItems={"center"}
              sx={{
                padding: "6px",
                border: "2px solid #1C1C1C10",
                borderRadius: "16px",
                boxShadow: "2px 4px 15px rgba(0, 0, 0, 0.05)",
                marginBottom: "10px",
              }}
            >
              <Grid item xs={8}>
                <Typography
                  fontWeight={700}
                  fontSize={"12px"}
                  paddingLeft={"12px"}
                >
                  Upload Documents
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign={"right"}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    borderRadius: "16px",
                    textTransform: "capitalize",
                    lineHeight: "14px",
                    padding: "8px 26px",
                  }}
                  onClick={() => {
                    AnalyticsService.pushEvent("Button Clicked", {
                      type: "Unlock Prices",
                      page: window.location.pathname,
                    });
                    navigate("/register/documents-upload");
                  }}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          )}

          {PartnerService.isPartnerStatusUnverified() &&
            (props?.partnerSubStatus === "DOCUMENTS_SUBMITTED" ||
              props?.partnerSubStatus === "DOCUMENTS_VERIFIED" ||
              props?.partnerSubStatus === "SECURITY_DEPOSIT_PAID") && (
              <Grid
                container
                alignItems={"center"}
                sx={{
                  padding: "6px",
                  border: "2px solid #1C1C1C10",
                  borderRadius: "16px",
                  boxShadow: "2px 4px 15px rgba(0, 0, 0, 0.05)",
                  marginBottom: "10px",
                }}
              >
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  <Typography
                    fontWeight={700}
                    fontSize={"12px"}
                    paddingLeft={"7px"}
                  >
                    {PartnerService.areMandatoryDocumentsSubmitted()
                      ? "Upload Remaining Documents"
                      : "Upload Documents"}
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign={"right"}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                      borderRadius: "16px",
                      textTransform: "capitalize",
                      lineHeight: "14px",
                      padding: "8px 26px",
                    }}
                    onClick={() => {
                      AnalyticsService.pushEvent("Button Clicked", {
                        type: "Unlock Prices",
                        page: window.location.pathname,
                      });
                      navigate("/register/documents-upload");
                    }}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            )}

          {props?.partnerSubStatus === "DOCUMENTS_VERIFIED" &&
            securityDepositAmount && (
              <Grid
                container
                alignItems={"center"}
                sx={{
                  padding: "6px",
                  border: "2px solid #1C1C1C10",
                  borderRadius: "16px",
                  boxShadow: "2px 4px 15px rgba(0, 0, 0, 0.05)",
                  marginBottom: "10px",
                }}
              >
                <Grid item xs={8}>
                  <Typography
                    fontWeight={700}
                    fontSize={"12px"}
                    paddingLeft={"12px"}
                  >
                    Pay Deposit
                  </Typography>
                  {PartnerService.checkIfFieldAgent() && (
                    <Typography sx={{ fontSize: "10px", paddingLeft: "12px" }}>
                      This feature is only for Partners
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} textAlign={"right"}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                      borderRadius: "16px",
                      textTransform: "capitalize",
                      lineHeight: "14px",
                      padding: "8px 20px",
                    }}
                    onClick={() => {
                      AnalyticsService.pushEvent("Button Clicked", {
                        type: "Pay Now",
                        page: window.location.pathname,
                      });
                      props?.handlePayNowClick();
                    }}
                    disabled={PartnerService.checkIfFieldAgent()}
                  >
                    Pay Now
                  </Button>
                </Grid>
              </Grid>
            )}

          {props?.partnerSubStatus === "SECURITY_DEPOSIT_PAID" && (
            <Grid
              container
              alignItems={"center"}
              sx={{
                padding: "6px",
                border: "2px solid #1C1C1C10",
                borderRadius: "16px",
                boxShadow: "2px 4px 15px rgba(0, 0, 0, 0.05)",
                marginBottom: "10px",
              }}
            >
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                }}
              >
                <Done color="secondary" />
                <Typography
                  fontWeight={700}
                  fontSize={"12px"}
                  paddingLeft={"7px"}
                  color="secondary"
                >
                  Deposit Received
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign={"right"}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    borderRadius: "16px",
                    textTransform: "capitalize",
                    lineHeight: "14px",
                    padding: "8px 20px",
                  }}
                  disabled
                  onClick={() => {
                    AnalyticsService.pushEvent("Button Clicked", {
                      type: "Pay Now",
                      page: window.location.pathname,
                    });
                    props?.handlePayNowClick();
                  }}
                >
                  Pay Now
                </Button>
              </Grid>
            </Grid>
          )}

          <SignAgreementNudge
            setLoadingAgreementSigningWindow={
              props?.setLoadingAgreementSigningWindow
            }
            setOpenUploadDrawer={props?.setOpenUploadDrawer}
            agreementSigningStatus={props?.agreementSigningStatus}
            partnerSubStatus={props?.partnerSubStatus}
            partnerStatus={props?.partnerStatus}
          />
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default OnboardingProcessCTA;
