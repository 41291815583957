import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  emptyFeedbackWrapper: {
    textAlign: "center",
  },
  emptyPrimaryText: {
    fontSize: "12px",
    marginTop: "-4px",
  },
}));

const GiftsReceivedEmpty = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.emptyFeedbackWrapper}>
      <img src={`/icons/EmptyPage.svg`} alt="" height={80} />
      <Typography
        className={classes.emptyPrimaryText}
      >{`No gifts received by the farmer`}</Typography>
    </Box>
  );
};

export default GiftsReceivedEmpty;
