const products = {
  status: true,
  message: "",
  responseData: {
    productGists: [
      {
        buyBar: true,
        leadCaptureEnabled: false,
        productImages: [
          {
            type: "thumb",
            name: "https://static.agrostar.in/static/AGS-S-1802_l.jpg",
          },
        ],
        priceOnApp: true,
        productName: "MH Kaveri Jaadoo BG II Cotton Seeds",
        needExpertHelp: true,
        displayMessage: "",
        skuCode: "AGS-S-1802",
        freeShipping: true,
        mrp: 767,
        version: "1.55.98",
        inventory: 13763,
        sellingPrice: 500,
        offers: {
          offerType: {
            url: "https://stagestatic.agrostar.in/offerfreeproducts.png",
            value: "offer_free_products",
            bestPriceText: "Offer Price ₹500/qty + Extra Benefits of ₹729",
          },
          offerPLPType: {},
          id: "63f600f72266ce6ddb56e3c7",
          offerId: "38d31e98-1d10-4af7-917a-10d42f5bbe03",
          quantity: 2,
          netEffectivePrice: 301,
        },
        productNameEN: "MH Kaveri Jaadoo BG II Cotton Seeds",
        rating: { totalCount: 1, average: 3.5, showRatingBars: false },
        brandName: "Caprica",
        brandImage: "https://static.agrostar.in/static/Caprica.jpg",
        videos: {
          HTU: [],
          testimonials: ["https://www.youtube.com/watch?v=7K8LI0hy0I0"],
          descriptionVideos: [],
        },
        isCashDiscountApplicable: true,
        cashDiscountBenefits: null,
        enabledForSaathi: true,
      },
      {
        buyBar: false,
        leadCaptureEnabled: false,
        productImages: [
          {
            type: "thumb",
            name: "https://static.agrostar.in/static/AGS-HW-203_l.jpg",
          },
        ],
        priceOnApp: true,
        productName: "Dhara - Battery Pump (12*8)",
        needExpertHelp: true,
        displayMessage: "",
        skuCode: "AGS-HW-203",
        freeShipping: true,
        mrp: 3500,
        version: "2.64.5",
        inventory: 1,
        sellingPrice: 310,
        offers: null,
        productNameEN: "Dhara - Battery Pump (12*8)",
        rating: {
          totalCount: 18,
          average: 4,
          showRatingBars: true,
        },
        brandName: "Dhara",
        brandImage: "https://static.agrostar.in/static/Dhara.jpg",
        videos: {
          HTU: [],
          testimonials: [],
          descriptionVideos: [],
        },
        isCashDiscountApplicable: true,
        cashDiscountBenefits: {
          "no-offer": [
            {
              id: 0,
              paymentPeriod: "",
              benefitAmount: 0,
              effectiveOfferPrice: 282.1,
              effectiveTotalNetPrice: 0,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 0,
              perUnitCalculatedBenefitAmount: 0,
              benefitText: "",
              paymentPeriodEndDay: "",
            },
            {
              id: 0,
              paymentPeriod: "",
              benefitAmount: 8,
              effectiveOfferPrice: 259.53200000000004,
              effectiveTotalNetPrice: 0,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 0,
              perUnitCalculatedBenefitAmount: 0,
              benefitText: "",
              paymentPeriodEndDay: "",
            },
            {
              id: 0,
              paymentPeriod: "",
              benefitAmount: 5,
              effectiveOfferPrice: 267.995,
              effectiveTotalNetPrice: 0,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 0,
              perUnitCalculatedBenefitAmount: 0,
              benefitText: "",
              paymentPeriodEndDay: "",
            },
            {
              id: 0,
              paymentPeriod: "",
              benefitAmount: 0,
              effectiveOfferPrice: 282.1,
              effectiveTotalNetPrice: 0,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 0,
              perUnitCalculatedBenefitAmount: 0,
              benefitText: "",
              paymentPeriodEndDay: "",
            },
            {
              id: 0,
              paymentPeriod: "",
              benefitAmount: -1,
              effectiveOfferPrice: 284.92100000000005,
              effectiveTotalNetPrice: 0,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 0,
              perUnitCalculatedBenefitAmount: 0,
              benefitText: "",
              paymentPeriodEndDay: "",
            },
            {
              id: 0,
              paymentPeriod: "",
              benefitAmount: -2,
              effectiveOfferPrice: 287.742,
              effectiveTotalNetPrice: 0,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 0,
              perUnitCalculatedBenefitAmount: 0,
              benefitText: "",
              paymentPeriodEndDay: "",
            },
            {
              id: 0,
              paymentPeriod: "",
              benefitAmount: -3,
              effectiveOfferPrice: 290.56300000000005,
              effectiveTotalNetPrice: 0,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 0,
              perUnitCalculatedBenefitAmount: 0,
              benefitText: "",
              paymentPeriodEndDay: "",
            },
          ],
        },
        redeemablePointsAtStore: 0,
        priceAtStore: 310,
        turnoverData: {
          turnoverDetails: [
            {
              qualifiedSlabIndex: "3",
              constraintsStatus: [
                {
                  slabIndex: 1,
                  slabConstraintsStatus: [
                    {
                      constraintKey: "TOTAL_WEIGHT_VALUE",
                      target: 40,
                      percentageAchieved: 372.5,
                      relativePercentageAchieved: 372.5,
                      showTODProgressBarOnPLP: true,
                    },
                  ],
                },
                {
                  slabIndex: 2,
                  slabConstraintsStatus: [
                    {
                      constraintKey: "TOTAL_WEIGHT_VALUE",
                      target: 55,
                      percentageAchieved: 270.91,
                      relativePercentageAchieved: 726.67,
                      showTODProgressBarOnPLP: true,
                    },
                  ],
                },
                {
                  slabIndex: 3,
                  slabConstraintsStatus: [
                    {
                      constraintKey: "TOTAL_WEIGHT_VALUE",
                      target: 100,
                      percentageAchieved: 149,
                      relativePercentageAchieved: 208.89,
                      showTODProgressBarOnPLP: true,
                    },
                  ],
                },
              ],
              descriptions: "test tod on nld ",
              name: "SA_TOD01",
              turnoverOfferRule: [
                {
                  constraints: {
                    constraintList: [
                      {
                        matrix: "TOTAL_WEIGHT_VALUE",
                        variation: "OR",
                        params: {
                          productCohortName: "TestNOVEM02",
                          productCohortUUID:
                            "84f7fc5f-6051-479e-a048-3e31e61863a4",
                          targetValue: 40,
                        },
                        type: "PRODUCT_COHORT",
                      },
                    ],
                    operator: "AND",
                  },
                  entitlements: {
                    entitlementList: [
                      {
                        type: "CREDIT_MEMO_PERCENT",
                        variation: "is",
                        params: {
                          value: 3,
                        },
                      },
                    ],
                    operator: "AND",
                  },
                  slabIndex: 0,
                },
                {
                  constraints: {
                    constraintList: [
                      {
                        matrix: "TOTAL_WEIGHT_VALUE",
                        variation: "OR",
                        params: {
                          productCohortName: "TestNOVEM02",
                          productCohortUUID:
                            "84f7fc5f-6051-479e-a048-3e31e61863a4",
                          targetValue: 55,
                        },
                        type: "PRODUCT_COHORT",
                      },
                    ],
                    operator: "AND",
                  },
                  entitlements: {
                    entitlementList: [
                      {
                        type: "CREDIT_MEMO_PERCENT",
                        variation: "is",
                        params: {
                          value: 7,
                        },
                      },
                    ],
                    operator: "AND",
                  },
                  slabIndex: 0,
                },
                {
                  constraints: {
                    constraintList: [
                      {
                        matrix: "TOTAL_WEIGHT_VALUE",
                        variation: "OR",
                        params: {
                          productCohortName: "TestNOVEM02",
                          productCohortUUID:
                            "84f7fc5f-6051-479e-a048-3e31e61863a4",
                          targetValue: 100,
                        },
                        type: "PRODUCT_COHORT",
                      },
                    ],
                    operator: "AND",
                  },
                  entitlements: {
                    entitlementList: [
                      {
                        type: "CREDIT_MEMO_PERCENT",
                        variation: "is",
                        params: {
                          value: 9,
                        },
                      },
                    ],
                    operator: "AND",
                  },
                  slabIndex: 0,
                },
              ],
              netValue: 149,
              SettledValue: 0,
              grossAmount: 22333,
              settledAmount: 0,
              grossValue: 149,
              validFrom: 1696098600000,
              validTo: 1701282600000,
              settlemetDate: 1701369000000,
              sources: null,
              offerCreatedBy: "atul.mulik",
              offerCreatedOn: 1698777000000,
              promotionUUID: "5594bdf7-3eaa-435c-bed7-2c993fabd53e",
              ShowSources: ["B2BMH"],
              returnedValue: 0,
              returnedAmount: 0,
              netAmount: 22333,
              orders: [
                {
                  isSettled: false,
                  orderId: 1726254,
                },
                {
                  isSettled: false,
                  orderId: 1726258,
                },
                {
                  isSettled: false,
                  orderId: 1726255,
                },
              ],
              settledQuantity: 0,
              percentAchieved: 149,
              qualifiedSlabEntlemt: {
                entlemtType: "CREDIT_MEMO_PERCENT",
                entlemtValue: 9,
                constraintMatrix: "TOTAL_WEIGHT_VALUE",
              },
              highestSlabEntlemt: {
                entlemtType: "CREDIT_MEMO_PERCENT",
                entlemtValue: 9,
                quantity: 5,
                constraintMatrix: "TOTAL_WEIGHT_VALUE",
              },
            },
            {
              qualifiedSlabIndex: "4",
              constraintsStatus: [
                {
                  slabIndex: 1,
                  slabConstraintsStatus: [
                    {
                      constraintKey: "TOTAL_WEIGHT_VALUE",
                      target: 40,
                      percentageAchieved: 372.5,
                      relativePercentageAchieved: 372.5,
                      showTODProgressBarOnPLP: true,
                    },
                  ],
                },
                {
                  slabIndex: 2,
                  slabConstraintsStatus: [
                    {
                      constraintKey: "TOTAL_WEIGHT_VALUE",
                      target: 55,
                      percentageAchieved: 270.91,
                      relativePercentageAchieved: 726.67,
                      showTODProgressBarOnPLP: true,
                    },
                  ],
                },
                {
                  slabIndex: 3,
                  slabConstraintsStatus: [
                    {
                      constraintKey: "TOTAL_WEIGHT_VALUE",
                      target: 100,
                      percentageAchieved: 149,
                      relativePercentageAchieved: 208.89,
                      showTODProgressBarOnPLP: true,
                    },
                  ],
                },
                {
                  slabIndex: 4,
                  slabConstraintsStatus: [
                    {
                      constraintKey: "TOTAL_WEIGHT_VALUE",
                      target: 130,
                      percentageAchieved: 114.62,
                      relativePercentageAchieved: 163.33,
                      showTODProgressBarOnPLP: true,
                    },
                  ],
                },
                {
                  slabIndex: 5,
                  slabConstraintsStatus: [
                    {
                      constraintKey: "TOTAL_WEIGHT_VALUE",
                      target: 150,
                      percentageAchieved: 99.33,
                      relativePercentageAchieved: 95,
                      showTODProgressBarOnPLP: true,
                    },
                  ],
                },
              ],
              descriptions: "test test test test",
              name: "TEST 100",
              turnoverOfferRule: [
                {
                  constraints: {
                    constraintList: [
                      {
                        matrix: "TOTAL_WEIGHT_VALUE",
                        variation: "OR",
                        params: {
                          productCohortName: "TestNOVEM02",
                          productCohortUUID:
                            "84f7fc5f-6051-479e-a048-3e31e61863a4",
                          targetValue: 40,
                        },
                        type: "PRODUCT_COHORT",
                      },
                    ],
                    operator: "AND",
                  },
                  entitlements: {
                    entitlementList: [
                      {
                        type: "CREDIT_MEMO",
                        variation: "is",
                        params: {
                          value: 55,
                        },
                      },
                    ],
                    operator: "AND",
                  },
                  slabIndex: 0,
                },
                {
                  constraints: {
                    constraintList: [
                      {
                        matrix: "TOTAL_WEIGHT_VALUE",
                        variation: "OR",
                        params: {
                          productCohortName: "TestNOVEM02",
                          productCohortUUID:
                            "84f7fc5f-6051-479e-a048-3e31e61863a4",
                          targetValue: 55,
                        },
                        type: "PRODUCT_COHORT",
                      },
                    ],
                    operator: "AND",
                  },
                  entitlements: {
                    entitlementList: [
                      {
                        type: "CREDIT_MEMO",
                        variation: "is",
                        params: {
                          value: 77,
                        },
                      },
                    ],
                    operator: "AND",
                  },
                  slabIndex: 0,
                },
                {
                  constraints: {
                    constraintList: [
                      {
                        matrix: "TOTAL_WEIGHT_VALUE",
                        variation: "OR",
                        params: {
                          productCohortName: "TestNOVEM02",
                          productCohortUUID:
                            "84f7fc5f-6051-479e-a048-3e31e61863a4",
                          targetValue: 100,
                        },
                        type: "PRODUCT_COHORT",
                      },
                    ],
                    operator: "AND",
                  },
                  entitlements: {
                    entitlementList: [
                      {
                        type: "CREDIT_MEMO",
                        variation: "is",
                        params: {
                          value: 93,
                        },
                      },
                    ],
                    operator: "AND",
                  },
                  slabIndex: 0,
                },
                {
                  constraints: {
                    constraintList: [
                      {
                        matrix: "TOTAL_WEIGHT_VALUE",
                        variation: "OR",
                        params: {
                          productCohortName: "TestNOVEM02",
                          productCohortUUID:
                            "84f7fc5f-6051-479e-a048-3e31e61863a4",
                          targetValue: 130,
                        },
                        type: "PRODUCT_COHORT",
                      },
                    ],
                    operator: "AND",
                  },
                  entitlements: {
                    entitlementList: [
                      {
                        type: "CREDIT_MEMO",
                        variation: "is",
                        params: {
                          value: 115,
                        },
                      },
                    ],
                    operator: "AND",
                  },
                  slabIndex: 0,
                },
                {
                  constraints: {
                    constraintList: [
                      {
                        matrix: "TOTAL_WEIGHT_VALUE",
                        variation: "OR",
                        params: {
                          productCohortName: "TestNOVEM02",
                          productCohortUUID:
                            "84f7fc5f-6051-479e-a048-3e31e61863a4",
                          targetValue: 150,
                        },
                        type: "PRODUCT_COHORT",
                      },
                    ],
                    operator: "AND",
                  },
                  entitlements: {
                    entitlementList: [
                      {
                        type: "CREDIT_MEMO",
                        variation: "is",
                        params: {
                          value: 123,
                        },
                      },
                    ],
                    operator: "AND",
                  },
                  slabIndex: 0,
                },
              ],
              netValue: 149,
              SettledValue: 0,
              grossAmount: 22333,
              settledAmount: 0,
              grossValue: 149,
              validFrom: 1696098600000,
              validTo: 1701282600000,
              settlemetDate: 1701369000000,
              sources: null,
              offerCreatedBy: "atul.admin",
              offerCreatedOn: 1698777000000,
              promotionUUID: "cdb37ea5-e69c-44cd-bf38-4ea9d32b48c7",
              ShowSources: ["B2BMH"],
              returnedValue: 0,
              returnedAmount: 0,
              netAmount: 22333,
              orders: [
                {
                  isSettled: false,
                  orderId: 1726254,
                },
                {
                  isSettled: false,
                  orderId: 1726258,
                },
                {
                  isSettled: false,
                  orderId: 1726255,
                },
              ],
              settledQuantity: 0,
              percentAchieved: 99.33,
              deltaPrompt: [
                {
                  slabIndex: 5,
                  constraintMatrix: "TOTAL_WEIGHT_VALUE",
                  entitlementType: "CREDIT_MEMO",
                  deltaMatrixValue: 35,
                  deltaAmount: 1550,
                  deltaQuantity: 5,
                  entitlementValue: 123,
                },
              ],
              qualifiedSlabEntlemt: {
                entlemtType: "CREDIT_MEMO",
                entlemtValue: 115,
                constraintMatrix: "TOTAL_WEIGHT_VALUE",
              },
              highestSlabEntlemt: {
                entlemtType: "CREDIT_MEMO",
                entlemtValue: 123,
                quantity: 5,
                constraintMatrix: "TOTAL_WEIGHT_VALUE",
              },
            },
          ],
          turnOverSchemeBenefits: {
            "no-offer": {
              perUnitSchemeBenefit: 27.9,
              totalSchemeBenefit: 139.5,
              perUnitNetEffectivePrice: 282.1,
              totalNetPrice: 1410.5,
              projectedNetSale: 0,
              projectedNetValue: 0,
              turnOverBenefitRate: 9,
              turnOverBenefitRateType: "PERCENTAGE",
            },
          },
        },
        enabledForSaathi: true,
        enabledForFieldOfficer: true,
        licenseCheckRequired: true,
        productClass: "D",
        pdpWebUrl:
          "ulink://android.agrostar.in/internalWebpage?url=https://test-app.agrostar.in/product/AGS-HW-203?farmerId=1931932\u0026language=en\u0026userId=0\u0026source=B2BMH",
        isPLProduct: false,
        creditPeriod: 60,
        minimumOrderQuantity: 5,
        addToCart: {
          addToCartFlag: false,
          reason: ["Credit Period and Cash Discount Mismatch"],
        },
      },
      {
        buyBar: true,
        leadCaptureEnabled: false,
        productImages: [
          {
            type: "thumb",
            name: "https://static.agrostar.in/static/AGS-S-2002_l.jpg",
          },
        ],
        priceOnApp: true,
        productName: "Seminis Sitara Gold Chilli (1500 Seeds)",
        needExpertHelp: true,
        displayMessage: "",
        skuCode: "AGS-S-2002",
        freeShipping: true,
        mrp: 637,
        version: "1.8.95",
        inventory: 1,
        sellingPrice: 485,
        offers: null,
        productNameEN: "Seminis Sitara Gold Chilli (1500 Seeds)",
        rating: { totalCount: 0, average: 0, showRatingBars: false },
        brandName: "Seminis",
        brandImage: "https://static.agrostar.in/static/Seminis.jpg",
        videos: { HTU: [], testimonials: [], descriptionVideos: [] },
        isCashDiscountApplicable: true,
        cashDiscountBenefits: {
          "no-offer": [
            {
              id: 0,
              paymentPeriod: "Advance",
              benefitAmount: 100,
              effectiveOfferPrice: 385,
              effectiveTotalNetPrice: 385,
              benefitType: "ABSOLUTE",
              totalCalculatedBenefitAmount: 100,
              perUnitCalculatedBenefitAmount: 100,
              benefitText: "100.00 Rs",
              paymentPeriodEndDay: "Advance",
            },
            {
              id: 1,
              paymentPeriod: "0-2 Days",
              benefitAmount: 80,
              effectiveOfferPrice: 405,
              effectiveTotalNetPrice: 405,
              benefitType: "ABSOLUTE",
              totalCalculatedBenefitAmount: 80,
              perUnitCalculatedBenefitAmount: 80,
              benefitText: "80.00 Rs",
              paymentPeriodEndDay: "2 Days",
            },
            {
              id: 2,
              paymentPeriod: "3-10 Days",
              benefitAmount: 50,
              effectiveOfferPrice: 435,
              effectiveTotalNetPrice: 435,
              benefitType: "ABSOLUTE",
              totalCalculatedBenefitAmount: 50,
              perUnitCalculatedBenefitAmount: 50,
              benefitText: "50.00 Rs",
              paymentPeriodEndDay: "10 Days",
            },
            {
              id: 3,
              paymentPeriod: "11-20 Days",
              benefitAmount: 30,
              effectiveOfferPrice: 455,
              effectiveTotalNetPrice: 455,
              benefitType: "ABSOLUTE",
              totalCalculatedBenefitAmount: 30,
              perUnitCalculatedBenefitAmount: 30,
              benefitText: "30.00 Rs",
              paymentPeriodEndDay: "20 Days",
            },
            {
              id: 4,
              paymentPeriod: "\u003e90 days",
              benefitAmount: -2,
              effectiveOfferPrice: 487,
              effectiveTotalNetPrice: 487,
              benefitType: "ABSOLUTE",
              totalCalculatedBenefitAmount: -2,
              perUnitCalculatedBenefitAmount: -2,
              benefitText: "-2.00 Rs",
              paymentPeriodEndDay: "\u003e90 days",
            },
          ],
        },
        enabledForSaathi: true,
      },
      {
        buyBar: false,
        leadCaptureEnabled: false,
        productImages: [
          {
            type: "thumb",
            name: "https://static.agrostar.in/static/AGS-CN-214_l.jpg",
          },
        ],
        priceOnApp: true,
        productName: "RJ - IPL Premium Phoster (PSB) 4 Kg",
        needExpertHelp: true,
        displayMessage: "",
        skuCode: "AGS-CN-214",
        freeShipping: true,
        mrp: 450,
        version: "1.11.22",
        inventory: 0,
        sellingPrice: 440,
        offers: null,
        productNameEN: "RJ - IPL Premium Phoster (PSB) 4 Kg",
        rating: { totalCount: 0, average: 0, showRatingBars: false },
        brandName: "IPL Biologicals",
        brandImage: "https://static.agrostar.in/static/IPL Biologicals.jpg",
        videos: { HTU: [], testimonials: [], descriptionVideos: [] },
        isCashDiscountApplicable: false,
        cashDiscountBenefits: null,
        enabledForSaathi: true,
      },
      {
        buyBar: true,
        leadCaptureEnabled: false,
        productImages: [
          {
            type: "thumb",
            name: "https://static.agrostar.in/static/AGS-CN-031_l.jpg",
          },
        ],
        priceOnApp: true,
        productName: "BT special Advance powder (500 Gms)",
        needExpertHelp: true,
        displayMessage: "",
        skuCode: "AGS-CN-031",
        freeShipping: false,
        mrp: 550,
        version: "3.42.88",
        inventory: 187,
        sellingPrice: 300,
        offers: null,
        productNameEN: "BT special Advance powder (500 Gms)",
        rating: { totalCount: 0, average: 0, showRatingBars: false },
        brandName: "Hifield",
        brandImage: "https://static.agrostar.in/static/Hifield.jpg",
        videos: { HTU: [], testimonials: [], descriptionVideos: [] },
        isCashDiscountApplicable: true,
        cashDiscountBenefits: {
          "no-offer": [
            {
              id: 0,
              paymentPeriod: "Advance",
              benefitAmount: 15,
              effectiveOfferPrice: 255,
              effectiveTotalNetPrice: 255,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 45,
              perUnitCalculatedBenefitAmount: 45,
              benefitText: "15.00 %",
              paymentPeriodEndDay: "Advance",
            },
            {
              id: 1,
              paymentPeriod: "0-2 Days",
              benefitAmount: 12,
              effectiveOfferPrice: 264,
              effectiveTotalNetPrice: 264,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 36,
              perUnitCalculatedBenefitAmount: 36,
              benefitText: "12.00 %",
              paymentPeriodEndDay: "2 Days",
            },
            {
              id: 2,
              paymentPeriod: "3-10 Days",
              benefitAmount: 10,
              effectiveOfferPrice: 270,
              effectiveTotalNetPrice: 270,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 30,
              perUnitCalculatedBenefitAmount: 30,
              benefitText: "10.00 %",
              paymentPeriodEndDay: "10 Days",
            },
            {
              id: 3,
              paymentPeriod: "11-20 Days",
              benefitAmount: 8,
              effectiveOfferPrice: 276,
              effectiveTotalNetPrice: 276,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 24,
              perUnitCalculatedBenefitAmount: 24,
              benefitText: "8.00 %",
              paymentPeriodEndDay: "20 Days",
            },
            {
              id: 4,
              paymentPeriod: "21-25 Days",
              benefitAmount: 8,
              effectiveOfferPrice: 276,
              effectiveTotalNetPrice: 276,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 24,
              perUnitCalculatedBenefitAmount: 24,
              benefitText: "8.00 %",
              paymentPeriodEndDay: "25 Days",
            },
            {
              id: 5,
              paymentPeriod: "26-30 Days",
              benefitAmount: 8,
              effectiveOfferPrice: 276,
              effectiveTotalNetPrice: 276,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 24,
              perUnitCalculatedBenefitAmount: 24,
              benefitText: "8.00 %",
              paymentPeriodEndDay: "30 Days",
            },
            {
              id: 6,
              paymentPeriod: "31-35 Days",
              benefitAmount: 6,
              effectiveOfferPrice: 282,
              effectiveTotalNetPrice: 282,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 18,
              perUnitCalculatedBenefitAmount: 18,
              benefitText: "6.00 %",
              paymentPeriodEndDay: "35 Days",
            },
            {
              id: 7,
              paymentPeriod: "36-45 Days",
              benefitAmount: 0,
              effectiveOfferPrice: 300,
              effectiveTotalNetPrice: 300,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: 0,
              benefitText: "0.00 %",
              paymentPeriodEndDay: "45 Days",
            },
            {
              id: 8,
              paymentPeriod: "46-89 Days",
              benefitAmount: -2,
              effectiveOfferPrice: 306,
              effectiveTotalNetPrice: 306,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: -6,
              perUnitCalculatedBenefitAmount: -6,
              benefitText: "-2.00 %",
              paymentPeriodEndDay: "89 Days",
            },
            {
              id: 9,
              paymentPeriod: "\u003e90 days",
              benefitAmount: -4,
              effectiveOfferPrice: 312,
              effectiveTotalNetPrice: 312,
              benefitType: "PERCENTAGE",
              totalCalculatedBenefitAmount: -12,
              perUnitCalculatedBenefitAmount: -12,
              benefitText: "-4.00 %",
              paymentPeriodEndDay: "\u003e90 days",
            },
          ],
        },
        enabledForSaathi: true,
      },
      {
        buyBar: false,
        leadCaptureEnabled: false,
        productImages: [
          {
            type: "thumb",
            name: "https://static.agrostar.in/static/AGS-HW-1210_l.jpg",
          },
        ],
        priceOnApp: true,
        productName: "Sunlord Power Sprayer 4 Stroke Petrol (25 Ltr)",
        needExpertHelp: true,
        displayMessage: "",
        skuCode: "AGS-HW-1210",
        freeShipping: true,
        mrp: 15000,
        version: "1.0.66",
        inventory: 0,
        sellingPrice: 7700,
        offers: null,
        productNameEN: "Sunlord Power Sprayer 4 Stroke Petrol (25 Ltr)",
        rating: { totalCount: 0, average: 0, showRatingBars: false },
        brandName: "Sunlord",
        brandImage: "https://static.agrostar.in/static/Sunlord.jpg",
        videos: { HTU: [], testimonials: [], descriptionVideos: [] },
        isCashDiscountApplicable: false,
        cashDiscountBenefits: null,
        enabledForSaathi: true,
      },
    ],
  },
};
export default products;
