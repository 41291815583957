import { InputAdornment, TextField } from "@mui/material";
import AnalyticsService from "../../Services/AnalyticsService";
import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useFocus from "../../Hooks/useFocus";

const SearchOrders = (props) => {
  const [inputRef, setInputFocus] = useFocus();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.setAttribute("readonly", true);

      setTimeout(function () {
        event.target.blur();
        event.target.removeAttribute("readonly");
      }, 100);
    }
  };

  const handleChange = (event) => {
    if (props.setKeywordDebounced) {
      props.setKeywordDebounced(event.target.value);
    }
  };

  const handleChangeDebounced = useCallback(debounce(handleChange, 1000), []);

  const handleChangeInput = (event) => {
    handleChangeDebounced(event);
    if (props.setKeyword) {
      props.setKeyword(event.target.value);
    }
  };

  const handleClearSearch = () => {
    props?.setKeyword("");
    props?.setKeywordDebounced("");
  };

  useEffect(() => {
    if (props?.keyword && props?.keywordDebounced) {
      setInputFocus();
    }
  }, []);

  return (
    <TextField
      inputRef={inputRef}
      onClick={() => {
        AnalyticsService.pushEvent(`Search Clicked`, {
          page: props?.page || "",
        });
      }}
      sx={{
        margin: "6px 0px",
        background: "#F5E9E9",
        border: "none",
        borderRadius: "20px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderRadius: "20px !important",
          border: "1px solid rgba(157,33,35,0.5)",
        },
        "& .MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            border: "1px solid rgba(157,33,35,0.5)",
            "&:hover": {
              border: "1px solid rgba(157,33,35,0.5)",
            },
          },
        "& .MuiInputBase-input": {
          color: "#333333",
          fontSize: "14px",
        },
      }}
      fullWidth={true}
      onKeyDown={handleKeyDown}
      placeholder={
        props?.placeholderText ? props?.placeholderText : "Search by Order ID"
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" onClick={(event) => {}}>
            {props?.keywordDebounced && props?.keywordDebounced !== "" ? (
              <CloseRoundedIcon color="primary" onClick={handleClearSearch} />
            ) : (
              <img src={`/icons/search.svg`} alt="Search" />
            )}
          </InputAdornment>
        ),
      }}
      variant="outlined"
      size={`small`}
      value={props.keyword ? props.keyword : ""}
      onChange={handleChangeInput}
    />
  );
};

export default SearchOrders;
