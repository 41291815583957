import makeStyles from "@mui/styles/makeStyles";

import IframeContent from "../Common/IframeContent";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  containerIframe: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "110vh !important",
    paddingTop: "56.25%" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
    marginTop: "11px",
  },

  /* Then style the iframe to fit in the container div with full height and width */
  responsiveIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "88vh",
    border: "0px",
  },
}));

const TermsAndCondition = () => {
  const classes = useStyles();

  const termsAndConditionViewedData = {
    page: `/terms-and-conditions`,
  };
  AnalyticsService.pushEvent(
    `Terms and Conditions Viewed`,
    termsAndConditionViewedData
  );

  const iframeData = {
    src: `https://www.corporate.agrostar.in/saathitnc-en`,
    iframeClass: classes.responsiveIframe,
  };

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`AgroStar Terms & Conditions`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
          toolTip={`AgroStar Terms & Conditions`}
        />
        <div className={classes.containerIframe}>
          <IframeContent iframeData={iframeData} />
        </div>
      </ErrorBoundary>
    </>
  );
};

export default TermsAndCondition;
