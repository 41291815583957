// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MH: [
    "AGS-S-4351",
    "AGS-S-3309",
    "AGS-S-3138",
    "AGS-S-4411",
    "AGS-S-4602",
    "AGS-S-3191",
    "AGS-S-4488",
    "AGS-S-4490",
    "AGS-S-4550",
    "AGS-S-4523",
    "AGS-S-4487",
    "AGS-S-4489",
    "AGS-S-3189",
    "AGS-S-4524",
    "AGS-S-4278",
    "AGS-S-4133",
    "AGS-S-4520",
    "AGS-S-3232",
    "AGS-S-4543",
    "AGS-S-4275",
    "AGS-S-4133",
    "AGS-S-4518",
    "AGS-S-4227",
    "AGS-S-3040",
    "AGS-S-4521",
    "AGS-S-4642",
    "AGS-S-4581",
    "AGS-S-2999",
    "AGS-S-3273",
    "AGS-S-3041",
    "AGS-S-4546",
    "AGS-S-4555",
    "AGS-S-4556",
    "AGS-S-4561",
    "AGS-S-4560",
    "AGS-S-4571",
    "AGS-S-4559",
    "AGS-S-4558",
    "AGS-S-4671",
  ],
  RJ: [
    "AGS-S-4351",
    "AGS-S-3309",
    "AGS-S-3138",
    "AGS-S-4411",
    "AGS-S-4602",
    "AGS-S-3190",
  ],
  UP: [
    "AGS-S-4421",
    "AGS-S-3282",
    "AGS-S-2704",
    "AGS-S-4366",
    "AGS-S-3143",
    "AGS-S-2027",
    "AGS-S-3234",
    "AGS-S-4351",
    "AGS-S-3309",
    "AGS-S-3138",
    "AGS-S-4411",
    "AGS-S-4602",
  ],
  MP: ["AGS-S-4351", "AGS-S-3309", "AGS-S-3138", "AGS-S-4411", "AGS-S-4602"],
  GJ: ["AGS-S-4351", "AGS-S-3309", "AGS-S-3138", "AGS-S-4411", "AGS-S-4602"],
  BH: ["AGS-S-4351", "AGS-S-3309", "AGS-S-3138", "AGS-S-4411", "AGS-S-4602"],
};
