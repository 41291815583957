import React, { useEffect, useState } from "react";

import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import ProductsList from "../Product/ProductsList";
import Search from "../Dashboard/Search";

import ProductsLoader from "./ProductsLoader";
import useNearbyPartnerPurchase from "../../Hooks/useNearbyPartnerPurchase";
import StateUtils from "../../Utils/StateUtils";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductsFilter from "./ProductsFilter";
import ProductsFilterLoader from "./ProductsFilterLoader";
import useQuery from "../../Hooks/useQuery";
import _ from "lodash";
import SelectedBrandFilters from "./SelectedBrandFilters";
import SelectedBrandFiltersLoader from "./SelectedBrandFiltersLoader";
import useSelectedBrands from "../../Hooks/useSelectedBrands";

// import useNearbyPartnerPurchaseCount from "../../Hooks/useNearbyPartnerPurchaseCount";

function NearbyPartnerPurchasePage() {
  const query = useQuery();
  let brandNamesFromQuery = query.get("brandNames")?.split(",");
  brandNamesFromQuery = _.map(brandNamesFromQuery, (brandNameFromQuery) =>
    brandNameFromQuery?.toLowerCase()
  );
  const [selectedBrands, setSelectedBrands] = useState([]);
  const { products, brands, loading, error } =
    useNearbyPartnerPurchase(selectedBrands);
  const [stateCode, setStateCode] = useState("MH");

  // const [currentlyChosenBrands, setCurrentlyChosenBrands] = useState([]);
  // const { productsCount, loadingProductsCount, errorProductsCount } =
  //   useNearbyPartnerPurchaseCount(currentlyChosenBrands);

  // const handleClickChooseBrands = (currentlyChosenBrands) => {
  //   setCurrentlyChosenBrands(currentlyChosenBrands);
  // };

  const { loadingAllBrands } = useSelectedBrands(
    brandNamesFromQuery,
    brands,
    setSelectedBrands
    // handleClickChooseBrands
  );

  useEffect(() => {
    let currentStateCode = StateUtils.getStateCode();
    setStateCode(currentStateCode);
    AnalyticsService.pushEvent(`Product List Page Viewed`, {
      page: "High Demand Products",
    });
  }, []);

  return (
    <div>
      <ErrorBoundary message={``}>
        <TopBar
          title={`High Demand Products`}
          backNavigation={true}
          backLink={"/dashboard"}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <ErrorBoundary message={``}>
        <Search page={`High Demand Products`} shouldMakeItSticky={true} />
      </ErrorBoundary>
      {loadingAllBrands === "done" &&
      loading !== "started" &&
      (!error || !error?.data) ? (
        <>
          <ErrorBoundary message={``}>
            <SelectedBrandFilters
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
            />
          </ErrorBoundary>
          <ErrorBoundary message={``}>
            <ProductsList
              loading={loading}
              products={products}
              title={`Products`}
              page={`Dashboard`}
              stateCode={stateCode}
            />
          </ErrorBoundary>
          <ErrorBoundary message={``}>
            <ProductsFilter
              // productsCount={productsCount}
              // loadingProductsCount={loadingProductsCount}
              // errorProductsCount={errorProductsCount}
              // handleClickChooseBrands={handleClickChooseBrands}
              brands={brands}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
            />
          </ErrorBoundary>
        </>
      ) : (
        <>
          {(loading === "started" || loadingAllBrands === "started") &&
            (!error || !!error?.data) && (
              <>
                {selectedBrands?.length > 0 && <SelectedBrandFiltersLoader />}
                <ProductsLoader />
                <ProductsFilterLoader />
              </>
            )}
        </>
      )}
    </div>
  );
}

export default NearbyPartnerPurchasePage;
