import config from "../config";
import CategoryDetailsMock from "./mocks/CategoryDetailsMock";
import CategoriesMock from "./mocks/CategoriesMock";
import _ from "lodash";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class CategoryService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getCategoryDetails(categoryName, count) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/categorydetail?source=${localStorage.getItem(
        `source`
      )}&language=en&categoryName=${categoryName}&farmerId=${localStorage.getItem(
        "farmerId"
      )}&count=${count}&enabledForSaathi=${localStorage.getItem("role") !== "fieldAgent"}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: CategoryDetailsMock });
        }, 10000);
      });
    }
  }

  static getAllCategories() {
    if (useMocks === false) {
      const url = `${config.backendUrl}feedservice/v1/shopcategories?source=${localStorage.getItem("source")}&language=en`;
      return HTTPService.makeXHRRequest(url, "GET", null, null);
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: CategoriesMock });
        }, 10000);
      });
    }
  }

  static getCategoryByName(categoriesFromResponse, categoryName) {
    return _.filter(categoriesFromResponse, (category) => {
      return category.value === categoryName;
    })?.[0];
  }
}
