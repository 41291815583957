import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import ErrorBoundary from "../Common/ErrorBoundary";

const useStyles = makeStyles(() => ({
  mainText: {
    textAlign: "center",
    fontSize: "1.125rem",
    color: "#000000",
    lineHeight: "1.563rem",
    marginTop: "0.875rem",
  },
  subText: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#666666",
    lineHeight: "1.188rem",
  },
}));

function EmptyError({ image, text, subText, extraStyle, hideBack }) {
  const classes = useStyles();
  let navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/dashboard");
  };

  return (
    <ErrorBoundary message={``}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={
          extraStyle
            ? extraStyle
            : {
                minHeight: "calc(100vh - 4rem)",
                background: (theme) => theme.palette.background.default,
              }
        }
      >
        <Grid item justifyContent="center" xs={12}>
          {image && <img src={`/icons/${image}`} alt="empty" />}
        </Grid>
        <Grid item xs={8} justifyContent="center">
          <Typography className={classes.mainText}>{text}</Typography>
        </Grid>
        <Grid item xs={8} justifyContent="center">
          <Typography className={classes.subText}>{subText}</Typography>
        </Grid>
        <Grid item xs={6} justifyContent="center">
          {!hideBack && (
            <Button
              variant={`text`}
              onClick={handleBackToHome}
              color={`primary`}
            >
              <img
                src={`/icons/activeHomeTab.svg`}
                style={{ height: "28px", marginTop: "-2px" }}
                alt="empty"
              />{" "}
              Back to Home
            </Button>
          )}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
}

export default EmptyError;
