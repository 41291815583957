import config from "../config";
import HTTPService from "./HTTPService";

export default class ImageService {
  static transformImageHeight(url, height) {
    const lastSlashIndex = url.lastIndexOf("/");
    if (lastSlashIndex === -1) {
      return url;
    }
    return (
      url.slice(0, lastSlashIndex) +
      `/tr:h-${height}` +
      url.slice(lastSlashIndex)
    );
  }
}
