import config from "../config";
import ScannedProductsMock from "./mocks/ScannedProductsMock";
import HTTPService from "./HTTPService";

const useMocks = false;

export default class InterPartyTransfersService {
  static getHeaders() {
    return {
      source:
        localStorage.getItem("source") ||
        localStorage.getItem("fieldAgentSource"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getInterPartyTransfersList(searchTerm) {
    const requester = localStorage.getItem(`farmerId`)
      ? "PARTNER"
      : "FIELD_AGENT";
    const requesterId =
      localStorage.getItem(`farmerId`) ||
      localStorage.getItem(`fieldAgentUserId`);

    let url = `${config.backendUrl}ordermanagementservice/v4/ipt/?requester=${requester}&requesterId=${requesterId}`;
    if (searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static createIPTRequest(fromPartner, toPartner) {
    const fieldOfficerUserId = JSON.parse(
      localStorage.getItem(`fieldAgentUserId`)
    );
    const url = `${config.backendUrl}ordermanagementservice/v4/ipt/`;
    const data = { fromPartner, toPartner, fieldOfficerUserId };
    return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
  }

  static getIPTOrderDetails(requestId) {
    const url = `${config.backendUrl}ordermanagementservice/v4/ipt/?requestId=${requestId}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static updateIPTStatus(action, iptRequestId, requesterId) {
    const url = `${config.backendUrl}ordermanagementservice/v4/ipt/`;
    const data = { action, iptRequestId, requesterId };
    return HTTPService.makeXHRRequest(url, "PATCH", data, this.getHeaders());
  }

  static getIPTCart(cartId) {
    const url = `${config.backendUrl}cartservice/iptcart/${cartId}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static updateIPTCart(cartId, action, scanCode) {
    const url = `${config.backendUrl}cartservice/iptcart/${cartId}`;
    const data = { action, scanCode };
    return HTTPService.makeXHRRequest(url, "PATCH", data, this.getHeaders());
  }

  static partnerSearchByMobileOrName(keyword) {
    let url = `${config.backendUrl}institutionalsalesservice/search/`;
    if (Number(keyword)) {
      url += `?mobileNumber=${keyword}`;
    } else {
      url += `?NameTerm=${keyword}`;
    }
    return HTTPService.makeXHRRequest(url, "GET", null, {
      ...this.getHeaders(),
      State: localStorage.getItem("fieldAgentState"),
    });
  }

  static fetchProductsFromSearchQuery(searchValue, cartId) {
    if (useMocks === false) {
      let url = `${config.backendUrl}cartservice/iptcart-products-with-barcode/?barcode=${searchValue}&cart_id=${cartId}`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        ...this.getHeaders(),
        State: localStorage.getItem("fieldAgentState"),
      });
    } else {
      return new Promise((resolve) => {
        resolve({ data: ScannedProductsMock });
      });
    }
  }
}
