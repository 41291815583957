import { Box, Stack, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import TerritorySelect from "./TerritorySelect";
import PartnersList from "./PartnersList";
import { useEffect, useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import _ from "lodash";
import PartnerService from "../../Services/PartnerService";
import PartnersSearch from "./PartnersSearch";

const useStyles = makeStyles((theme) => ({
  selectedFilter: {
    backgroundColor: "#9D2123 !important",
    color: "#FFFFFF",
    fontWeight: 700,
  },
  unselectedFilter: {
    backgroundColor: "#FFFFFF !important",
    color: "#4F4F4F",
    fontWeight: 700,
  },
}));

let PARTNER_STATUS_FILTERS = [
  { label: "Open for Sale", identifier: "OPEN", selected: false },
  { label: "OCP Blocked", identifier: "BLOCKED", selected: false },
  { label: "Churned", identifier: "INACTIVE", selected: false },
];
const MyPartners = ({ authorized }) => {
  const classes = useStyles();

  const [partners, setPartners] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [loading, setLoading] = useState("not-started");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPartners, setTotalPartners] = useState(0);
  const [selectedTerritory, setSelectedTerritory] = useState("");

  const isFilterSelected = (filterIdentifier) => {
    const selectedFilter = _.find(
      PARTNER_STATUS_FILTERS,
      (filter) =>
        filter.identifier === filterIdentifier && filter.selected === true
    );
    return selectedFilter !== undefined ? selectedFilter?.selected : false;
  };

  const setFilterValueToDefault = (filterIdentifier) => {
    const selectedFilter = _.find(
      PARTNER_STATUS_FILTERS,
      (filter) => filter.identifier === filterIdentifier
    );
    selectedFilter.selected = false;
  };

  const handleFilterClick = (selectedStatusFilter) => {
    let selectedFarmerStatusFilter = _.find(PARTNER_STATUS_FILTERS, {
      identifier: selectedStatusFilter.identifier,
    });
    selectedFarmerStatusFilter.selected = !selectedFarmerStatusFilter.selected;
    AnalyticsService.pushEvent(`Filter Clicked`, {
      page: window.location.pathname || "",
      filterLabel: selectedStatusFilter?.label || "",
      filterStatus: selectedStatusFilter?.selected ? "Applied" : "Removed",
    });
    setCurrentPage(0);
    setLoading("started");
    const { axiosRequest } = PartnerService.getPartnersListForFieldAgent(
      localStorage.getItem("fieldAgentMobileNumber"),
      0,
      selectedTerritory,
      searchKeyword,
      isFilterSelected("OPEN"),
      isFilterSelected("BLOCKED"),
      isFilterSelected("INACTIVE")
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.partnerList) {
          setPartners(response?.data?.responseData?.partnerList);
          setTotalPartners(response?.data?.responseData?.totalPartners);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
        }
      });
  };

  useEffect(() => {
    AnalyticsService.pushProfile(
      null,
      localStorage.getItem("fieldAgentMobileNumber")
    );
    if (selectedTerritory) {
      setCurrentPage(0);
      const { axiosRequest, abortController } =
        PartnerService.getPartnersListForFieldAgent(
          localStorage.getItem("fieldAgentMobileNumber"),
          0,
          selectedTerritory,
          searchKeyword,
          isFilterSelected("OPEN"),
          isFilterSelected("BLOCKED"),
          isFilterSelected("INACTIVE")
        );
      axiosRequest
        .then((responsePartnersList) => {
          if (responsePartnersList?.data?.responseData?.partnerList) {
            setPartners(responsePartnersList.data.responseData.partnerList);
            setTotalPartners(
              responsePartnersList.data.responseData.totalPartners
            );
          }
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoading("done");
          }
        });
      return () => abortController.abort();
    }
  }, [selectedTerritory]);

  useEffect(() => {
    if (clearAllFilters) {
      setSearchKeyword("");
      setCurrentPage(0);
      const { axiosRequest, abortController } =
        PartnerService.getPartnersListForFieldAgent(
          localStorage.getItem("fieldAgentMobileNumber"),
          0,
          "All",
          "",
          isFilterSelected("OPEN"),
          isFilterSelected("BLOCKED"),
          isFilterSelected("INACTIVE")
        );
      axiosRequest
        .then((responsePartnersList) => {
          if (responsePartnersList?.data?.responseData?.partnerList) {
            setPartners(responsePartnersList.data.responseData.partnerList);
            setTotalPartners(
              responsePartnersList.data.responseData.totalPartners
            );
          }
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoading("done");
          }
        });
      setClearAllFilters(false);
      return () => abortController.abort();
    }
  }, [clearAllFilters]);

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, {
      page: "My Partners",
      tab: "My Partners",
    });

    setLoading("started");
    const { axiosRequest, abortController } =
      PartnerService.getTerritoriesForFieldAgent(
        localStorage.getItem("fieldAgentMobileNumber")
      );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.territories.length > 0) {
          let territoriesFromResponse = [
            "All",
            ...response?.data?.responseData?.territories,
          ];
          setTerritories(territoriesFromResponse);
          setSelectedTerritory("All");
        } else {
          setTerritories(["All"]);
          setSelectedTerritory("All");
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setSelectedTerritory("All");
        }
      });
    return () => abortController.abort();
  }, []);

  const fetchMorePartners = () => {
    const { axiosRequest } = PartnerService.getPartnersListForFieldAgent(
      localStorage.getItem("fieldAgentMobileNumber"),
      currentPage + 1,
      selectedTerritory,
      searchKeyword,
      isFilterSelected("OPEN"),
      isFilterSelected("BLOCKED"),
      isFilterSelected("INACTIVE")
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.partnerList?.length > 0) {
          let newPartners = response?.data?.responseData?.partnerList;

          setPartners((previousPartners) => [
            ...previousPartners,
            ...newPartners,
          ]);
        } else {
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting partners`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
        }
      });
    AnalyticsService.pushEvent("Partners List Next Page Loaded", {
      pageNumber: currentPage + 1,
      tab: "My Partners",
    });
    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });
  };

  const handleSelectTerritory = (event) => {
    if (event?.target?.value) {
      setSelectedTerritory(event.target.value);
      AnalyticsService.pushEvent("Territory Changed", {
        territory: event.target.value,
      });
    }
  };

  const clearSearch = () => {
    setSearchKeyword("");
    const { axiosRequest } = PartnerService.getPartnersListForFieldAgent(
      localStorage.getItem("fieldAgentMobileNumber"),
      0,
      selectedTerritory,
      "",
      isFilterSelected("OPEN"),
      isFilterSelected("BLOCKED"),
      isFilterSelected("INACTIVE")
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.partnerList?.length > currentPage) {
          setPartners(response?.data?.responseData?.partnerList);
          setTotalPartners(response?.data?.responseData?.totalPartners);
        } else {
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
        }
      });
    AnalyticsService.pushEvent("Partners Search Clear Clicked");
  };

  const handleClearAllFilters = () => {
    AnalyticsService.pushEvent(`Button Clicked`, {
      type: "Clear All Filters",
      page: "My Partners",
    });
    setSelectedTerritory("All");
    setClearAllFilters(true);
    setSearchKeyword("");
    setFilterValueToDefault("OPEN");
    setFilterValueToDefault("BLOCKED");
    setFilterValueToDefault("INACTIVE");
  };

  const handleSearch = (keyword) => {
    if (keyword) {
      setSearchKeyword(keyword);
      setLoading("started");
      setPartners([]);
      AnalyticsService.pushEvent("Partners Search Keyword Entered", {
        keyword: keyword,
      });
      const { axiosRequest } = PartnerService.getPartnersListForFieldAgent(
        localStorage.getItem("fieldAgentMobileNumber"),
        0,
        selectedTerritory,
        keyword,
        isFilterSelected("OPEN"),
        isFilterSelected("BLOCKED"),
        isFilterSelected("INACTIVE")
      );
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData?.partnerList.length > 0) {
            setPartners(response?.data?.responseData?.partnerList);
            setTotalPartners(response?.data?.responseData?.totalPartners);
          } else {
          }

          AnalyticsService.pushEvent("Partners Search Results Shown", {
            keyword: keyword,
            totalPartners: response.data.responseData.totalPartners,
          });
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoading("done");
          }
        });
    } else {
      clearSearch();
    }
  };

  return (
    <>
      <PartnersSearch
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        searchKeyword={searchKeyword}
        page={"My Partners"}
        tab={"My Partners"}
      />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          margin: "4px 0px",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {_.map(PARTNER_STATUS_FILTERS, (filter, index) => {
          return (
            <Box
              key={index}
              sx={{
                boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1);",
                borderRadius: "16px",
                paddingLeft: "12px",
                paddingRight: "12px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                handleFilterClick(filter);
              }}
              className={
                filter?.selected === true
                  ? `${classes.selectedFilter}`
                  : `${classes.unselectedFilter}`
              }
            >
              <Typography
                noWrap
                sx={{
                  fontSize: "13px",
                  fontWeight: 700,
                }}
              >
                {filter?.label}
              </Typography>
              {filter.selected === true ? (
                <CloseIcon sx={{ fontSize: "18px", marginLeft: "4px" }} />
              ) : (
                <></>
              )}
            </Box>
          );
        })}
        <TerritorySelect
          territories={territories}
          handleSelectTerritory={handleSelectTerritory}
          selectedTerritory={selectedTerritory}
          setSelectedTerritory={setSelectedTerritory}
          emptyElementSize={"0"}
          clearAllFilters={clearAllFilters}
        />
      </Stack>
      <Grid
        container
        direction={"row"}
        sx={{ marginTop: "12px", padding: "0px 5px" }}
      >
        <Grid item xs={9}>
          <Typography
            variant={`body2`}
            sx={{
              color: "#33333380",
              fontWeight: "700",
              fontSize: "12px",
            }}
          >
            {totalPartners} Total Partners
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: "end", marginRight: "-10px" }}>
          <Typography
            sx={{
              color: "#33333380",
              fontWeight: "700",
              fontSize: "12px",
            }}
            onClick={handleClearAllFilters}
          >
            Clear All
          </Typography>
        </Grid>
      </Grid>

      {authorized && (
        <>
          <PartnersList
            fetchMorePartners={fetchMorePartners}
            totalPartners={totalPartners}
            partners={partners}
            loading={loading}
            noResultsText={"No Partners Found"}
          />
        </>
      )}
    </>
  );
};

export default MyPartners;
