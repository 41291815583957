import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { HighlightOffRounded } from "@mui/icons-material";

import SettleNowDialog from "./SettleNowDialog";
import AmountUtils from "../../Utils/AmountUtils";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles({
  nudge: {
    minHeight: "50px",
    marginTop: "1px",
    padding: "8px 16px",
    alignItems: "center",
    backgroundColor: "#F8D149",
  },
  settleNowButton: {
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "16px",
    textTransform: "capitalize",
  },
  disableSettlementMessage: {
    textAlign: "center",
    fontSize: "12px",
    padding: "8px 0 0",
    color: "#9D2123",
  },
  settleItem: {
    fontSize: "14px",
    fontWeight: 700,
    marginTop: "12px",
    padding: "5px 8px",
    borderRadius: "10px",
    boxShadow: "0px 0px 6px 0px #1C1C1C1A",
  },
});

export default function SettlePaymentNudge({ data: vanPaymentDetails }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [showPayNowDialog, setShowPayNowDialog] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);

  const handleNudgeClick = () => {
    const eventProperties = {
      type: "Settle Payment Nudge",
      page: window.location.pathname,
      amount: vanPaymentDetails?.amount || 0,
      enableCustomSettlement: vanPaymentDetails?.enableCustomSettlement,
    };

    if (!vanPaymentDetails?.enableCustomSettlement) {
      eventProperties.disabledMessage = vanPaymentDetails?.disabledMessage;
      return AnalyticsService.pushEvent("Button Clicked", eventProperties);
    }

    AnalyticsService.pushEvent("Button Clicked", eventProperties);

    // Required in next iteration: commenting dialog and navigating to pay by invoice tab
    // setShowDialog(true);
    navigate(`/hisaab/pay-by-product-group`);
  };

  const handleSettleForABS = () => {
    setShowDialog(false);
    setShowPayNowDialog(true);
    setPaymentAmount(vanPaymentDetails?.amount);

    AnalyticsService.pushEvent("Button Clicked", {
      type: "Settle ABS",
      page: window?.location?.pathname,
    });
  };

  return (
    <React.Fragment>
      {vanPaymentDetails?.amount > 0 ? (
        <Grid container className={classes.nudge} onClick={handleNudgeClick}>
          <Grid item xs={8.8}>
            <Typography color={"secondary"} fontSize={"12px"}>
              We have received{" "}
              <b>
                {AmountUtils.formatCurrency(vanPaymentDetails?.amount || 0)}
              </b>{" "}
              via VAN payment
            </Typography>
          </Grid>
          <Grid item xs={3.2} sx={{ textAlign: "right" }}>
            <Button
              size="small"
              variant="contained"
              className={classes.settleNowButton}
              disabled={!vanPaymentDetails?.enableCustomSettlement}
            >
              Settle Now
            </Button>
          </Grid>
          {!vanPaymentDetails?.enableCustomSettlement && (
            <Grid item xs={12} className={classes.disableSettlementMessage}>
              {vanPaymentDetails?.disabledMessage}
            </Grid>
          )}
        </Grid>
      ) : null}

      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        PaperProps={{ style: { borderRadius: "32px" } }}
      >
        <DialogContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={10}>
              <Typography variant="h6" fontSize={18} fontWeight={700}>
                Settle Payment for
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "right" }}>
              <HighlightOffRounded onClick={() => setShowDialog(false)} />
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.settleItem}
              onClick={handleSettleForABS}
            >
              ABS Scheme
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.settleItem}
              onClick={() => {
                AnalyticsService.pushEvent("Button Clicked", {
                  type: "Settle Invoices",
                  page: window?.location?.pathname,
                });
                navigate(`/hisaab/pay-by-product-group`);
              }}
            >
              Invoices
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <SettleNowDialog
        open={showPayNowDialog}
        handleClose={() => setShowPayNowDialog(false)}
        settlementType={"advancebookingscheme"}
        paymentAmount={paymentAmount}
      />
    </React.Fragment>
  );
}
