import React, { useEffect, useState } from "react";
import moment from "moment";
import BlockUi from "react-block-ui";

import { makeStyles } from "@mui/styles";
import { Cancel, Close, WarningAmberRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";

import TopBar from "../Common/TopBar";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import { PromotionService } from "../../Services/PromotionService";
import EmptyError from "../EmptyError/EmptyError";
import OfferShareAndDownload from "./OfferShareAndDownload";
import DocumentService from "../../Services/DocumentService";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  pageBackground: {
    minHeight: "100vh",
    padding: "16px 24px 24px",
    background: "#E5E5E5",
  },
  card: {
    position: "relative",
    background: "none",
    boxShadow: "none",
  },
  cancelBtn: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(1),
    zIndex: 2,
  },
  cardImage: {
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: "450px",
    zIndex: 1,
    borderRadius: "10px",
  },
  cardActions: {
    position: "relative",
    top: "-15px",
    padding: "24px",
    paddingTop: "35px",
    background: "rgb(0,0,0,0.4)",
    borderRadius: "0 0 30px 30px",
  },
}));

export default function MyOffers() {
  const classes = useStyles();
  const source = localStorage.getItem("source");
  const [myOffers, setMyOffers] = useState(null);
  const [shareText, setShareText] = useState("");
  const [loading, setLoading] = useState("not_started");
  const [deleteOffer, setDeleteOffer] = useState({});

  useEffect(() => {
    AnalyticsService.pushEvent(`My Offer Page Viewed`, {
      page: "/my-offers",
    });

    setLoading("started");

    const { axiosRequest, abortController } =
      DocumentService.getInstitutionId();
    axiosRequest
      .then((storeResponse) => {
        if (storeResponse?.data?.responseData) {
          const storeData = storeResponse?.data?.responseData;
          let storeName = "",
            shareTextSource = "ENGLISH";
          if (storeData?.nameInRegionalLanguage) {
            shareTextSource = source;
            storeName = storeData?.nameInRegionalLanguage;
          } else {
            storeName = storeData?.name;
          }
          const text = PromotionService.getOfferShareText(
            shareTextSource,
            storeName,
            storeData?.contacts?.mobileNumber
          );
          setShareText(text);
        }
        const { axiosRequest } = PromotionService.getSaathiOffers();
        axiosRequest
          .then((offerResponse) => {
            if (offerResponse?.data?.responseData?.offers?.length) {
              let newOffersData = offerResponse?.data?.responseData?.offers;
              newOffersData = _.map(newOffersData, (offer) => ({
                ...offer,
                createdOn: moment(offer?.createdOn).format("DD MMM YYYY"),
              }));

              setMyOffers(newOffersData);
            }
            setLoading("done");
          })
          .catch((e) => {
            console.log("get offer failed: ", e?.message);
            setLoading("done");
          });
      })
      .catch((e) => {
        if (e?.message !== "canceled") {
          setLoading("done");
          console.log("Institution API Failure: ", e?.message);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleDeleteOfferClickEvent = (myOffer) => {
    setDeleteOffer({ ...myOffer, showPrompt: true });
    AnalyticsService.pushEvent(`Delete Offer Clicked`, {
      page: "/my-offers",
      offerId: myOffer?.offerId,
      skuCode: myOffer?.productDetails[0]?.skuCode,
      offerTitle: myOffer?.title,
      offerDescription: myOffer?.descriptions[0],
    });
  };

  const handleCloseDeletePrompt = () => {
    setDeleteOffer({});
    AnalyticsService.pushEvent(`Delete Offer Prompt Closed`, {
      page: "/my-offers",
      offerId: deleteOffer?.offerId,
      skuCode: deleteOffer?.productDetails[0]?.skuCode,
      offerTitle: deleteOffer?.title,
      offerDescription: deleteOffer?.descriptions[0],
    });
  };

  const handleCancelDeletePrompt = () => {
    setDeleteOffer({});
    AnalyticsService.pushEvent(`Delete Offer Prompt Cancel`, {
      page: "/my-offers",
      offerId: deleteOffer?.offerId,
      skuCode: deleteOffer?.productDetails[0]?.skuCode,
      offerTitle: deleteOffer?.title,
      offerDescription: deleteOffer?.descriptions[0],
    });
  };

  const handleDeleteOffer = () => {
    AnalyticsService.pushEvent(`Delete Offer Prompt Confirmed`, {
      page: "/my-offers",
      offerId: deleteOffer?.offerId,
      skuCode: deleteOffer?.productDetails[0]?.skuCode,
      offerTitle: deleteOffer?.title,
      offerDescription: deleteOffer?.descriptions[0],
    });

    setLoading("started");
    const { axiosRequest } = PromotionService.deleteSaathiOffer(
      deleteOffer?.offerId
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.offerId) {
          const newMyOffers = myOffers?.filter((offer) => {
            return offer?.offerId !== deleteOffer?.offerId;
          });
          setMyOffers(newMyOffers);
        }
        setDeleteOffer({});
        setLoading("done");
      })
      .catch((e) => {
        setLoading("done");
        console.log("Delete Offer API Failure: ", e?.message);
      });
  };

  const handleMyOfferClickedEvent = () => {
    AnalyticsService.pushEvent(`My Offer Clicked`, { page: "/my-offers" });
  };

  return (
    <>
      <ErrorBoundary>
        <TopBar
          title={`My Offer`}
          backNavigation={true}
          // backLink="/create-offer"
          showCart={false}
          showHisaab={false}
        />
      </ErrorBoundary>

      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{
          height: "90vh",
          overflow: deleteOffer?.showPrompt ? "hidden" : "",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "calc(100% - 52px)",
            position: "absolute",
            display: deleteOffer?.showPrompt ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3,
            background: "rgb(79, 79, 79, .8)",
          }}
        >
          <Box
            sx={{
              p: 2,
              width: "80%",
              textAlign: "center",
              background: "#fff",
              borderRadius: "10px",
            }}
          >
            <div style={{ textAlign: "right" }}>
              <Close sx={{ color: "#666" }} onClick={handleCloseDeletePrompt} />
            </div>
            <WarningAmberRounded
              sx={{ width: "40px", height: "40px", color: "#4F4F4F" }}
            />
            <Typography fontSize={18} sx={{ mb: 6 }}>
              Are you sure to delete the offer ?
            </Typography>
            <Button
              variant="contained"
              sx={{
                width: "47%",
                margin: "0 3px 0",
                borderRadius: "10px",
                background: "#cacaca",
                "&:hover": { background: "#cacaca" },
              }}
              onClick={handleCancelDeletePrompt}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ width: "47%", margin: "0 3px 0", borderRadius: "10px" }}
              onClick={handleDeleteOffer}
            >
              Yes
            </Button>
          </Box>
        </div>

        <div className={classes.pageBackground}>
          {loading === "done" && myOffers && myOffers.length > 0 ? (
            myOffers?.map((myOffer) => {
              if (myOffer?.exportedImageUrl) {
                return (
                  <React.Fragment key={myOffer?.offerId}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      {myOffer?.createdOn}
                    </Typography>
                    <Card
                      className={classes.card}
                      onClick={handleMyOfferClickedEvent}
                    >
                      <IconButton
                        className={classes.cancelBtn}
                        onClick={() => handleDeleteOfferClickEvent(myOffer)}
                      >
                        <Cancel
                          sx={{
                            width: "30px",
                            height: "30px",
                            color: "#fff",
                          }}
                        />
                      </IconButton>
                      <CardMedia
                        component="img"
                        image={myOffer?.exportedImageUrl}
                        alt="greeting"
                        className={classes.cardImage}
                      />

                      <CardActions
                        disableSpacing
                        className={classes.cardActions}
                      >
                        <OfferShareAndDownload
                          imageUrl={myOffer?.exportedImageUrl}
                          page="/my-offers"
                          shareText={
                            myOffer?.messageTemplateText
                              ? myOffer?.messageTemplateText
                              : shareText
                          }
                          skuCode={myOffer?.productDetails[0]?.skuCode}
                          offerTitle={myOffer?.title}
                          offerDescription={myOffer?.descriptions[0]}
                        />
                      </CardActions>
                    </Card>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })
          ) : loading === "done" ? (
            <Box sx={{ p: "60px 30px 0" }}>
              <EmptyError
                image="EmptyPage.svg"
                text="Ooooops!"
                subText="Looks like there are no templates available at this moment.
              Please come back later."
                extraStyle={{
                  minHeight: "500px",
                  p: "0 20px",
                  borderRadius: "20px",
                  background: "#fff",
                }}
              />
            </Box>
          ) : (
            <></>
          )}
        </div>
      </BlockUi>
    </>
  );
}
