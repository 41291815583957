import React, { useEffect, useState } from "react";
import ErrorBoundary from "../Common/ErrorBoundary";
import BlockUi from "react-block-ui";
import moment from "moment";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import {
  Button,
  Grid,
  Typography,
  CardContent,
  Alert,
  Snackbar,
  Box,
  Skeleton,
} from "@mui/material";
import SelectProductCart from "./SelectProductCart";
import TopBar from "../Common/TopBar";
import UiSearch from "../Common/UiSearch";
import OrderService from "../../Services/OrderService";
import TicketService from "../../Services/TicketService";
import CartService from "../../Services/CartService";
import saathiReturnsConstValues from "../../Services/SaathiReturnsConst";
import CommonConstantValues from "../../Services/CommonConstantValues";
import AnalyticsService from "../../Services/AnalyticsService";
import EmptyError from "../EmptyError/EmptyError";
import QuantityChangeToast from "../Product/QuantityChangeToast";

function SelectReturnProducts() {
  let navigate = useNavigate();
  const currentDate = moment();
  const [returnableProducts, setReturnableProducts] = useState([]);
  const [isClickedOnCard, setIsClickedOnCard] = useState(false);
  const [activeSkuCode, setActiveSkuCode] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedReason, setSelectedReason] = useState("");
  const [returnReasons, setReturnReasons] = useState([]);
  const [productCartList, setProductCartList] = useState([]);
  const [loading, setLoading] = useState(CommonConstantValues.NOT_STARTED);
  const [updatingCart, setUpdatingCart] = useState(
    CommonConstantValues.NOT_STARTED
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchedProduct, setSearchedProduct] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [showStorageAlertMessage, setShowStorageAlertMessage] = useState(false);
  const [noSearchResult, setNoSearchResult] = useState(false);
  const [showQuantityChangeToast, setShowQuantityChangeToast] = useState(false);
  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("createReturnInitiated")) {
      navigate("/orders/return", { replace: true });
      navigate(-1);
    }
    setLoading(CommonConstantValues.STARTED);
    const listCB = getProductAndCartList();
    // get return reason list
    const { axiosRequest, abortController } =
      TicketService.getAllReturnReasons();
    axiosRequest
      .then(({ data }) => {
        setReturnReasons(
          data?.responseData?.returnOrderReasons[0]?.reasons || []
        );
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
        }
      });
    return () => {
      listCB();
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    let totalCount = 0;
    _.forEach(productCartList, (item) => {
      totalCount += Number(item.quantity);
    });
    setTotalQuantity(totalCount);
  }, [productCartList]);

  useEffect(() => {
    const delay = 300;
    const timer = setTimeout(() => {
      performSearch();
    }, delay);

    return () => clearTimeout(timer);
  }, [searchValue]);

  const performSearch = () => {
    if (searchValue !== "") {
      let searchedProducts = _.filter(returnableProducts, (item) =>
        item?.productName.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (searchedProducts?.length) {
        setSearchedProduct(searchedProducts);
      } else {
        if (searchValue.length > 5) {
          setNoSearchResult(true);
        } else {
          setNoSearchResult(true);
        }
        setSearchedProduct([]);
      }
    } else {
      setNoSearchResult(false);
      setSearchedProduct([]);
    }
  };

  const getProductAndCartList = () => {
    let returnableTillDays =
      saathiReturnsConstValues.saathiReturnsRecentOrdersDurationInDays;
    if (localStorage.getItem("role") === "fieldAgent") {
      returnableTillDays =
        saathiReturnsConstValues.fieldReturnsRecentOrdersDurationInDays;
    }
    const { axiosRequest, abortController } =
      OrderService.getAllReturnableOrders(returnableTillDays);
    axiosRequest
      .then(({ data }) => {
        setLoading(CommonConstantValues.DONE);
        if (data?.responseData?.recentOrderStats.length) {
          let productList = data?.responseData?.recentOrderStats;

          productList.sort((a, b) => {
            return b.IsCartPresent - a.IsCartPresent;
          });

          // hide NPL & seeds category products from return selection (only for partners)
          if (localStorage.getItem("role") !== "fieldAgent") {
            productList = _.filter(productList, (product) => {
              return (
                product?.isPLProduct &&
                _.lowerCase(product.levelOneCategoryEN) !== "seeds"
              );
            });
          }

          setReturnableProducts(productList);
          const { axiosRequest } = CartService.getReturnProductsCart();
          axiosRequest
            .then(({ data }) => {
              const cartProducts = data.responseData.products;

              let cartProductsArray = [];

              _.map(cartProducts, (item) => {
                let obj = {};
                obj.productName = item.productName;
                obj.skuCode = item.skuCode;
                obj.quantity = item.quantity;
                obj.remarks = item?.remarks || "";
                obj.returnableAmount = item?.returnableAmount || "";

                cartProductsArray.push(obj);
              });

              _.forEach(productList, (product) => {
                const skuCode = product.sku_code;
                product.IsCartPresent = _.some(
                  cartProductsArray,
                  (cartProduct) => cartProduct.skuCode === skuCode
                );
              });

              productList.sort((a, b) => {
                return b.IsCartPresent - a.IsCartPresent;
              });

              setReturnableProducts(productList);
              setProductCartList(cartProductsArray);
              setLoading(CommonConstantValues.DONE);
            })
            .catch((error) => {
              if (error?.response?.status == 404) {
                setLoading(CommonConstantValues.DONE);
              }
            });
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading(CommonConstantValues.DONE);
          setShowStorageAlertMessage(true);
          console.log("Get all returnable products error", error);
        }
      });
    return () => abortController.abort();
  };

  const handleClickedOnCard = (value, productDetail) => {
    const clickedCartEventData = {
      page: `orders/return/select-product`,
    };
    AnalyticsService.pushEvent(`Return Product Clicked`, clickedCartEventData);

    if (activeSkuCode === value) {
      setIsClickedOnCard(!isClickedOnCard);
    } else {
      setIsClickedOnCard(true);
    }
    setActiveSkuCode(value);
    let selectedProductReason =
      productCartList.length &&
      productCartList.filter((item) => item.skuCode === value);
    setSelectedReason(
      selectedProductReason.length ? selectedProductReason[0]?.remarks : ""
    );
    const updatedMinimumOrderQuantityValue =
      productDetail?.minimumOrderQuantity === 0
        ? 1
        : productDetail?.minimumOrderQuantity;
    setMinimumOrderQuantity(updatedMinimumOrderQuantityValue);
    setQuantity(productDetail?.minimumOrderQuantity);
  };

  const handleAdd = (minimumOrderQuantity) => {
    const increasedQuantityEvent = {
      page: `orders/return/select-product`,
    };
    AnalyticsService.pushEvent(
      `Product Quantity Increased`,
      increasedQuantityEvent
    );

    const updatedMinimumOrderQuantityValue =
      minimumOrderQuantity === 0 ? 1 : minimumOrderQuantity;

    const updatedQuantity = Number(quantity) + updatedMinimumOrderQuantityValue;
    const remainder = updatedQuantity % updatedMinimumOrderQuantityValue;

    if (
      Number(quantity) < saathiReturnsConstValues?.maxReturnQuantity &&
      Number(quantity) >= updatedMinimumOrderQuantityValue &&
      Number(quantity) % updatedMinimumOrderQuantityValue === 0
    ) {
      setQuantity(updatedQuantity);
    } else if (Number(quantity) < updatedMinimumOrderQuantityValue) {
      setQuantity(updatedMinimumOrderQuantityValue);
    } else {
      const adjustedQuantity =
        updatedQuantity + (updatedMinimumOrderQuantityValue - remainder);
      if (adjustedQuantity <= saathiReturnsConstValues?.maxReturnQuantity) {
        setQuantity(adjustedQuantity);
      }
    }
  };

  const handleRemove = (minimumOrderQuantity) => {
    const decreasedQuantity = {
      page: `orders/return/select-product`,
    };
    AnalyticsService.pushEvent(`Return Quantity Decreased`, decreasedQuantity);

    const updatedMinimumOrderQuantityValue =
      minimumOrderQuantity === 0 ? 1 : minimumOrderQuantity;

    const updateQuantity = Number(quantity) - updatedMinimumOrderQuantityValue;
    const remainder = updateQuantity % updatedMinimumOrderQuantityValue;

    if (
      Number(quantity) > updatedMinimumOrderQuantityValue &&
      Number(quantity) >= updatedMinimumOrderQuantityValue &&
      Number(quantity) % updatedMinimumOrderQuantityValue === 0
    ) {
      setQuantity(updateQuantity);
    } else if (Number(quantity) <= updatedMinimumOrderQuantityValue) {
      setQuantity(updatedMinimumOrderQuantityValue);
    } else {
      const adjustedQuantity = updateQuantity - remainder;
      if (adjustedQuantity >= updatedMinimumOrderQuantityValue) {
        setQuantity(adjustedQuantity);
      }
    }
  };

  const handleChange = (event, minimumOrderQuantity) => {
    const updatedMinimumOrderQuantityValue =
      minimumOrderQuantity === 0 ? 1 : minimumOrderQuantity;

    const inputQuantity = Number(event?.target?.value);
    const remainder = inputQuantity % updatedMinimumOrderQuantityValue;
    let updatedQuantity;

    if (remainder === 0) {
      updatedQuantity = inputQuantity;
    } else {
      updatedQuantity =
        inputQuantity + (updatedMinimumOrderQuantityValue - remainder);
    }
    if (updatedQuantity !== inputQuantity) {
      setShowQuantityChangeToast(true);
    }
    setQuantity(updatedQuantity);
  };

  const handleChangeReturnReason = (event) => {
    const returnReasonChangedEventData = {
      page: `orders/return/select-product`,
      returnReason: event.target.value,
    };
    AnalyticsService.pushEvent(
      `Return Reason Selected`,
      returnReasonChangedEventData
    );

    setSelectedReason(event.target.value);
  };

  const updateCart = (productDetail) => {
    let product = { ...productDetail };
    product.skuCode = productDetail.sku_code;
    delete product?.sku_code;
    delete product?.isInterState;
    delete product?.IsCartPresent;
    product.quantity = quantity;
    product.remarks = selectedReason;

    const updatedProductCartList = _.filter(
      productCartList,
      (item) =>
        item.skuCode !== product.skuCode ||
        item.productName !== product.productName
    );

    const payload = [...updatedProductCartList, product];
    setUpdatingCart(CommonConstantValues.STARTED);
    const { axiosRequest } = CartService.updateReturnProductCart(payload);
    axiosRequest
      .then((response) => {
        const products = response?.data?.responseData?.products;
        const productsObj = {};
        _.forEach(products, (item) => (productsObj[item?.skuCode] = item));
        const returnAddProductEventData = {
          page: `orders/return/select-product`,
          skuCode: productDetail?.sku_code,
          productName: productDetail?.productName,
          returnReason: selectedReason,
          quantity: productDetail?.quantity,
        };
        AnalyticsService.pushEvent(
          `Return Add Product Button Clicked`,
          returnAddProductEventData
        );
        let updatedCartList = returnableProducts;
        _.forEach(updatedCartList, (item) => {
          if (item?.sku_code === productDetail?.sku_code) {
            item.IsCartPresent = true;
          } else if (!item?.IsCartPresent) {
            item.IsCartPresent = false;
          }
        });
        _.forEach(payload, (item) => {
          if (productsObj[item?.skuCode]) {
            item.returnableAmount =
              productsObj[item?.skuCode]?.returnableAmount;
          }
        });

        updatedCartList.sort((a, b) => {
          return b.IsCartPresent - a.IsCartPresent;
        });

        setProductCartList(payload);
        setReturnableProducts(updatedCartList);
        setUpdatingCart(CommonConstantValues.DONE);
        setIsClickedOnCard(!isClickedOnCard);
        setActiveSkuCode("");
        setSelectedReason("");
        setQuantity(1);
      })
      .catch(() => {
        setUpdatingCart(CommonConstantValues.DONE);
        setShowStorageAlertMessage(true);
      });
  };

  const handleRemoveProductFromCart = (event, productDetail) => {
    event.stopPropagation();

    let recentUpdatedList = _.filter(
      productCartList,
      (product) => product.skuCode !== productDetail?.sku_code
    );
    setProductCartList(recentUpdatedList);
    setUpdatingCart(CommonConstantValues.STARTED);
    const { axiosRequest } =
      CartService.updateReturnProductCart(recentUpdatedList);
    axiosRequest
      .then(() => {
        setUpdatingCart(CommonConstantValues.DONE);
        const returnProductRemoveEvent = {
          page: `orders/return/select-product`,
          skuCode: productDetail?.sku_code,
          productName: productDetail?.productName,
          quantity: productDetail?.quantity,
        };
        AnalyticsService.pushEvent(
          `Return Remove Product Button Clicked`,
          returnProductRemoveEvent
        );
      })
      .catch(() => {
        setUpdatingCart(CommonConstantValues.DONE);
        setShowStorageAlertMessage(true);
      });

    _.forEach(returnableProducts, (item) => {
      if (item.sku_code === productDetail?.sku_code) {
        item.IsCartPresent = false;
      }
    });

    returnableProducts.sort((a, b) => {
      return b.IsCartPresent - a.IsCartPresent;
    });

    setReturnableProducts(returnableProducts);
  };

  const handleClickMarkDone = () => {
    if (productCartList.length !== 0) {
      let productNamesList = "";
      let skuCodeList = "";

      _.map(productCartList, (items) => {
        productNamesList += `${items?.productName}, `;
        skuCodeList += `${items?.skuCode}, `;
      });

      const clickedProceedButtonEvent = {
        page: `orders/return/select-product`,
        productName: productNamesList,
        products: skuCodeList,
      };
      AnalyticsService.pushEvent(
        `Return Proceed Button Clicked`,
        clickedProceedButtonEvent
      );

      localStorage.setItem("markAsDoneButtonClicked", true);
      navigate("/orders/return/review-product");
    }
  };

  const oneYearAgo = currentDate.subtract(1, "year");

  const productListToShowOnUI = searchedProduct?.length
    ? searchedProduct
    : returnableProducts;

  return (
    <BlockUi
      tag="div"
      blocking={updatingCart === CommonConstantValues.STARTED}
      message={`Please wait`}
      keepInView
      sx={{ background: "#e5e5e5" }}
    >
      <ErrorBoundary message={``}>
        <TopBar
          title={`Select Return Products`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
        />
      </ErrorBoundary>
      <QuantityChangeToast
        show={showQuantityChangeToast}
        minimumOrderQuantity={minimumOrderQuantity}
        setShow={setShowQuantityChangeToast}
      />
      <ErrorBoundary>
        {loading === CommonConstantValues.STARTED &&
        productListToShowOnUI?.length === 0 ? (
          <CardContent>
            <Skeleton
              variant="rectangular"
              height={105}
              sx={{ marginTop: "10px" }}
            />
            <Skeleton
              variant="rectangular"
              height={105}
              sx={{ marginTop: "10px" }}
            />
            <Skeleton
              variant="rectangular"
              height={105}
              sx={{ marginTop: "10px" }}
            />
          </CardContent>
        ) : loading === CommonConstantValues.DONE &&
          productListToShowOnUI?.length === 0 ? (
          <EmptyError image={`Empty Cart.svg`} text={`No Orders Found!`} />
        ) : (
          <></>
        )}

        {loading === CommonConstantValues.DONE &&
        productListToShowOnUI?.length ? (
          <>
            <Snackbar
              open={showStorageAlertMessage}
              autoHideDuration={5000}
              onClose={() => setShowStorageAlertMessage(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              sx={{
                marginBottom: "55px",
              }}
            >
              <Alert
                icon={false}
                sx={{
                  background: "#f26b6b",
                  color: "#ffffff",
                  width: "328px",
                }}
                onClose={() => setShowStorageAlertMessage(false)}
              >
                Something went wrong !
              </Alert>
            </Snackbar>

            <ErrorBoundary message={``}>
              <UiSearch
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />
            </ErrorBoundary>

            <CardContent
              sx={{ color: "#333333", padding: "0px 16px 16px 16px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                <InfoIcon
                  fontSize="medium"
                  sx={{ color: "rgba(79, 79, 79, 1)" }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(79, 79, 79, 1)",
                    paddingLeft: "4px",
                  }}
                >
                  Returns allowed only at the box level
                </Typography>
              </Box>

              {loading === CommonConstantValues.DONE &&
              noSearchResult &&
              !searchedProduct?.length ? (
                <EmptyError
                  image="EmptyPage.svg"
                  text="Sorry! No Results Found"
                  hideBack={true}
                />
              ) : (
                <>
                  <SelectProductCart
                    productListToShowOnUI={productListToShowOnUI}
                    isClickedOnCard={isClickedOnCard}
                    activeSkuCode={activeSkuCode}
                    handleClickedOnCard={handleClickedOnCard}
                    productCartList={productCartList}
                    handleRemoveProductFromCart={handleRemoveProductFromCart}
                    quantity={quantity}
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                    handleChange={handleChange}
                    setQuantity={setQuantity}
                    minimumOrderQuantity={
                      saathiReturnsConstValues?.minimumOrderQuantity
                    }
                    selectedReason={selectedReason}
                    onChangeReturnReason={handleChangeReturnReason}
                    returnReasons={returnReasons}
                    updateCart={updateCart}
                  />
                </>
              )}
            </CardContent>

            <Box
              sx={{
                height: "36px",
              }}
            ></Box>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                position: "fixed",
                bottom: "0",
                width: "100%",
                height: "51px",
                background:
                  productCartList.length === 0 ? "#CACACA" : "#9D2123",
                zIndex: "1",
                maxWidth: "600px",
              }}
              onClick={handleClickMarkDone}
            >
              <Grid
                item
                xs={2.3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFFFFF",
                  flexDirection: "column",
                  paddingLeft: "10px",
                  marginTop: "-5px",
                  textAlign: "center",
                }}
                variant={"contained"}
                color={"primary"}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19.07px",
                    fontWeight: "bold",
                  }}
                >
                  {productCartList.length}{" "}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontSize: "12px", lineHeight: "19.07px" }}
                >
                  Product
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFFFFF",
                  flexDirection: "column",
                  paddingLeft: "10px",
                  marginTop: "-5px",
                  textAlign: "center",
                }}
                variant={"contained"}
                color={"primary"}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19.07px",
                    fontWeight: "bold",
                  }}
                >
                  {totalQuantity}{" "}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontSize: "12px", lineHeight: "19.07px" }}
                >
                  Quantity
                </Typography>
              </Grid>
              <Grid
                item
                xs={6.5}
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  padding: "10px",
                }}
              >
                <Button
                  sx={{
                    height: "100%",
                    color: "#FFFFFF",
                    fontWeight: "700",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  disabled={productCartList.length === 0}
                >
                  <Typography
                    sx={{
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Proceed
                  </Typography>
                  <ArrowForwardTwoToneIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Snackbar
              open={showStorageAlertMessage}
              autoHideDuration={5000}
              onClose={() => setShowStorageAlertMessage(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              sx={{
                marginBottom: "55px",
              }}
            >
              <Alert
                icon={false}
                sx={{
                  background: "#f26b6b",
                  color: "#ffffff",
                  width: "328px",
                }}
                onClose={() => setShowStorageAlertMessage(false)}
              >
                Something went wrong !
              </Alert>
            </Snackbar>
          </>
        )}
      </ErrorBoundary>
    </BlockUi>
  );
}

export default SelectReturnProducts;
