import { Card, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
import PhoneAndroidSharpIcon from "@mui/icons-material/PhoneAndroidSharp";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "none",
    fontWeight: 400,
    width: "95%",
    marginLeft: "8px",
  },
  shopDetails: {
    marginTop: "5px",
  },
  align: {
    marginBottom: "8px",
    marginLeft: "2px",
  },
  alignAddress: {
    marginLeft: "23px",
    marginBottom: "16px",
  },
}));

const ShopDetails = (props) => {
  const classes = useStyles();
  let shopData = props.shopData;
  return (
    <>
      <Card className={classes.card} variant="outlined">
        <Typography variant="body2" className={classes.align}>
          <b>{shopData?.name}</b>
        </Typography>
        <Typography variant="body2" className={classes.shopDetails}>
          <PhoneAndroidSharpIcon
            style={{ fontSize: "18px", position: "absolute" }}
          />{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{shopData?.contacts?.mobileNumber}
        </Typography>
        <Typography variant="body2" className={classes.shopDetails}>
          <div style={{ display: "flex" }}>
            <div>
              <LocationOnSharpIcon
                style={{
                  fontSize: "22px",
                  position: "absolute",
                  marginLeft: "-2px",
                }}
              />
            </div>
            <div className={classes.alignAddress}>
              {shopData?.address?.village}, {shopData?.address?.taluka},{" "}
              {shopData?.address?.district}, {shopData?.address?.state},{" "}
              {shopData?.address?.country}, {shopData?.address?.pincode}
            </div>
          </div>
        </Typography>
      </Card>
    </>
  );
};

export default ShopDetails;
