import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    background: "white",
    width: "100%",
    height: "auto",
    display: "flex",
    marginBottom: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  productImage: {
    height: theme.spacing(8.5),
    width: theme.spacing(6),
    margin: "10px 16px ",
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "auto",
  },
  productName: {
    margin: "2px 0px",
    fontSize: "14px",
    color: "#333333",
  },
  lotId: {
    margin: "2px 0px",
    fontSize: "12px",
    fontWeight: "400",
  },
  codeContainer: {
    display: "flex",
    margin: "2px 0px 2px 0px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  maskedCode: {
    fontSize: "12px",
    fontWeight: "500",
  },
  actionButton: {
    margin: "0px 6px 10px",
    borderRadius: "10px",
    width: "auto",
    padding: "6px 20px",
    boxShadow: "none",
    color: "white",
  },
  expiryDate: {
    margin: "2px 0px 4px 0px",
    fontSize: "10px",
    fontWeight: "500",
  },
}));

export default function ProductContainer(props) {
  const classes = useStyles();
  const product = props.product;
  return (
    <Box className={classes.productContainer}>
      <img
        className={classes.productImage}
        src={product.productImage}
        alt={product.productName}
      />
      <Box className={classes.productInfoContainer}>
        <Typography className={classes.productName}>
          {product.productName}
        </Typography>
        <Typography className={classes.lotId}>
          LOT ID : {product.lotNumber}
        </Typography>
        <Box className={classes.codeContainer}>
          <Typography className={classes.maskedCode}>
            {`${
              product.containerType === "BARCODE" ? "Barcode" : "Boxcode"
            } : ${product.maskedCode}`}
          </Typography>
          {product.containerType !== "BARCODE" && (
            <Typography className={classes.maskedCode}>
              Qty : {product.quantity}
            </Typography>
          )}
        </Box>
        <Typography className={classes.expiryDate}>
          Expiry :{" "}
          {product.expiryDate
            ? moment(product.expiryDate).format("DD-MM-YYYY")
            : ""}
        </Typography>
      </Box>
      <Button
        className={classes.actionButton}
        sx={{ background: product.isAddedToCart ? "#9D2123" : "#00733E" }}
        onClick={() => {
          props.addOrRemoveButtonClicked(props.product);
        }}
      >
        {product.isAddedToCart ? "Remove" : "Add"}
      </Button>
    </Box>
  );
}
