import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import ProductService from "../../Services/ProductService";
import AnalyticsService from "../../Services/AnalyticsService";
import _ from "lodash";
import ProductVariantCard from "./ProductVariantCard";

const NotifyMe = (props) => {
  const [showNotifyMeDialog, setShowNotifyMeDialog] = useState(false);
  const [notifyMeDialogShown, setNotifyMeDialogShown] = useState(false);
  const mappedFC = props?.product?.mappedFC;

  const handleNotifyMeClicked = () => {
    if (
      !showNotifyMeDialog &&
      !notifyMeDialogShown &&
      !props?.isUserOptInForNotify
    ) {
      props?.setLoading("started");
      const { axiosRequest } = ProductService.getProductsNotifyMe(
        props?.product?.skuCode,
        [mappedFC]
      );
      axiosRequest
        .then((response) => {
          if (response.data.responseData) {
            setShowNotifyMeDialog(true);
            AnalyticsService.pushEvent("Button Clicked", {
              type: "Notify Me",
              skuCode: props?.product?.skuCode,
              productName: props?.product?.productName,
            });
            props?.setLoading("done");
          }
        })
        .catch((error) => {
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setShowNotifyMeDialog(false);
          props?.setLoading("done");
        });
    } else {
      setShowNotifyMeDialog(false);
    }
  };

  const variantsInStockAtAssignedWarehouse = _.filter(
    props?.product?.variants,
    (variant) => {
      return (
        variant.inventoryCount > 0 &&
        _.has(variant.warehouseInventoryDetails, mappedFC)
      );
    }
  );

  return (
    <>
      <Button
        fullWidth
        variant={`contained`}
        size={`large`}
        disableRipple
        disableTouchRipple
        startIcon={<NotificationsNoneIcon />}
        sx={{
          textTransform: "uppercase",
          borderRadius: "0px",
          position: "fixed",
          bottom: 0,
          zIndex: 4,
          minHeight: "50px",
          maxHeight: "50px",
          maxWidth: "600px",
          backgroundColor:
            !showNotifyMeDialog &&
            !notifyMeDialogShown &&
            !props?.isUserOptInForNotify
              ? "primary"
              : "#4f4f4f",
          "&:hover": {
            backgroundColor:
              !showNotifyMeDialog &&
              !notifyMeDialogShown &&
              !props?.isUserOptInForNotify
                ? "primary"
                : "#4f4f4f",
          },
        }}
        onClick={() => handleNotifyMeClicked()}
      >
        {!showNotifyMeDialog &&
        !notifyMeDialogShown &&
        !props?.isUserOptInForNotify
          ? "Notify Me"
          : "Already Subscribed"}
      </Button>
      <Dialog
        open={showNotifyMeDialog}
        onClose={() => {
          setShowNotifyMeDialog(false);
          setNotifyMeDialogShown(true);
        }}
        PaperProps={{
          sx: {
            borderRadius: "32px 32px 0px 0px",
            backgroundColor: "#FFF",
            width: "-webkit-fill-available",
            position: "fixed",
            bottom: 0,
            margin: 0,
          },
        }}
      >
        <DialogContent
          sx={{
            backgroundSize: "100% auto",
            padding: "0px !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "19px",
            }}
          >
            <img
              src={`/icons/NotificationIcon.svg`}
              alt={`Notification`}
              width={41}
              height={47}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                flexDirection: "column",
                marginLeft: "10px",
              }}
            >
              <Typography
                variant={`h3`}
                textAlign={`center`}
                sx={{ fontSize: "18px", fontWeight: "700" }}
              >
                Thank You!
              </Typography>
              <Typography
                variant={`body2`}
                textAlign={`left`}
                sx={{ fontSize: "12px", lineHeight: "18px" }}
              >
                We will notify you once this product is back in stock
              </Typography>
            </Box>
          </Box>
          {props?.product?.variants &&
          props?.product?.variants?.length !== 0 ? (
            <Box
              sx={{
                background: "#CACACA4D",
                padding: "15px",
                maxHeight: "40vh",
                overflowY: "auto",
              }}
            >
              <Typography
                variant={`h3`}
                textAlign={`left`}
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#333333",
                  marginBottom: "8px",
                }}
              >
                Also available in other packaging
              </Typography>
              {_.map(variantsInStockAtAssignedWarehouse, (variant, index) => (
                <ProductVariantCard variant={variant} key={index} />
              ))}
            </Box>
          ) : (
            <></>
          )}
          <DialogActions
            sx={{
              background:
                props?.product?.variants &&
                props?.product?.variants?.length !== 0
                  ? "#CACACA4D"
                  : "#FFF",
              padding: "0px",
            }}
          >
            <Button
              fullWidth
              variant={`contained`}
              size={`large`}
              color={"secondary"}
              onClick={() => {
                setShowNotifyMeDialog(false);
                setNotifyMeDialogShown(true);
              }}
              sx={{
                border: "none",
                bottom: 0,
                borderRadius: "0px",
              }}
            >
              Done
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NotifyMe;
