import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "@mui/material/Modal";
import { Button, Grid, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorCodeWrapper: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: "20rem",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
  },
  warningWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  btnWrapper: {
    marginTop: "1.813rem",
  },
  btn: {
    width: "18.563rem",
    height: "2.813rem",
    borderRadius: "0.625rem",
    fontWeight: "700",
    fontSize: "0.875rem",
    lineHeight: "1.192rem",
  },
  closeIcon: {
    alignSelf: "flex-end",
    marginRight: "-1rem",
    cursor: "pointer",
  },
  modalText: {
    textAlign: "center",
    lineHeight: "1.533rem",
    fontWeight: "500",
    color: "#222222",
    fontSize: "1.125rem",
  },
  errorCode: {
    color: "#222222",
    opacity: 0.3,
    textAlign: "center",
  },
  errorCodeGrid: {
    textAlign: "center",
  },
}));

export default function ModalComp(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={props.handleClose}
      >
        <div className={classes.paper}>
          <div className={classes.warningWrapper}>
            <ClearIcon
              className={classes.closeIcon}
              onClick={props.handleClose}
            />
            <img src={`/icons/Warning.svg`} alt="warningIcon" />
            <Grid item xs={10}>
              <Typography className={classes.modalText}>
                {props.modalText}
              </Typography>
            </Grid>

            <div className={classes.btnWrapper}>
              <Grid
                container
                justifyContent={`center`}
                className={classes.errorCodeWrapper}
              >
                <Grid item xs={12} className={classes.errorCodeGrid}>
                  <Typography variant={`caption`} className={classes.errorCode}>
                    {props.errorCode}
                  </Typography>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={props.modelBtnClick}
              >
                {props.modalBtnText}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
