import { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";

const StoreDetails = ({ profileData }) => {
  return (
    <Box sx={{ marginTop: -1 }}>
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{ fontSize: "18px", fontWeight: 700 }}
            align={"center"}
          >
            {profileData?.name}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 1 }}>
        {!profileData ? (
          <Box>&nbsp;&nbsp;NA</Box>
        ) : (
          <Typography
            variant="body1"
            align={`center`}
            sx={{ fontSize: "14px" }}
          >
            {profileData?.contacts?.mobileNumber},{" "}
            {profileData?.address?.village}, {profileData?.address?.taluka}
            <br /> {profileData?.address?.district},{" "}
            {profileData?.address?.state}, {profileData?.address?.pincode}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default StoreDetails;
