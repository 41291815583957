import { Link } from "react-router-dom";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Grid, Skeleton } from "@mui/material";
import SectionTitle from "../Common/SectionTitle";
import ProductCard from "../Product/ProductCard";
import ViewAllButton from "../Common/ViewAllButton";
import useTurnoverSchemeProductsRecommendation from "../../Hooks/useTurnoverSchemeProductsRecommendation";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    marginTop: theme.spacing(2),
  },
  loaderContent: {},
  mainContent: {
    backgroundColor: "#F3F3F3",
  },
  loaderWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
  viewAllBrandsButtonWrapperLink: {
    textDecoration: "none",
  },
}));

function TurnoverSchemeProductsRecommendation(props) {
  const classes = useStyles();
  const { products, loading, error } =
    useTurnoverSchemeProductsRecommendation();
  return (
    <div style={{ backgroundColor: "rgba(0, 115, 62, 0.1)" }}>
      {loading === "done" &&
        (!error || !error?.data) &&
        products &&
        products.length > 0 && (
          <Container
            maxWidth={`xl`}
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              paddingTop: "1px",
              paddingBottom: "14px",
            }}
          >
            <SectionTitle title={"Buy More to Avail Slab Benefits"} />
            <div style={{ marginTop: "10px" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                {_.map(
                  products.slice(
                    0,
                    props.numberOfProductsToShow
                      ? props.numberOfProductsToShow
                      : products.length
                  ),
                  (product, index) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <ProductCard
                          product={product}
                          key={product.skuCode}
                          index={index}
                          section={"Turnover Scheme Products Recommendation"}
                          stateCode={props.stateCode}
                          saveScrollYPosition={true}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </div>
            {products && products?.length > props?.numberOfProductsToShow && (
              <Link
                to={`/turnover-scheme-products-recommendation`}
                className={classes.viewAllBrandsButtonWrapperLink}
              >
                <ViewAllButton
                  section={"Turnover Scheme Products Recommendation"}
                  saveScrollYPosition={true}
                />
              </Link>
            )}
          </Container>
        )}

      {loading === "started" && (
        <>
          <Skeleton
            variant={`text`}
            width={300}
            style={{ marginLeft: "16px", marginTop: "8px" }}
          />
          <div className={classes.loaderWrapper}>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.loaderContent}
              wrap={"nowrap"}
              spacing={2}
            >
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default TurnoverSchemeProductsRecommendation;
