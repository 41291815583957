import React, { useEffect, useState } from "react";
import _ from "lodash";
import BlockUi from "react-block-ui";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { WarningAmberRounded } from "@mui/icons-material";

import TopBar from "../Common/TopBar";
import CatalogProductCard from "./CatalogProductCard";
import AnalyticsService from "../../Services/AnalyticsService";
import SectionTitle from "../../Components/Common/SectionTitle";
import EmptyError from "../../Components/EmptyError/EmptyError";
import StoreFrontService from "../../Services/StoreFrontService";
import CatalogError from "./CatalogError";
import CatalogItemPublishedDialog from "./CatalogItemPublishedDialog";
import CatalogItemUnpublishConfirmationDialog from "./CatalogItemUnpublishConfirmationDialog";
import CatalogItemUnpublishedDialog from "./CatalogItemUnpublishedDialog";
import CatalogRecommendedProductCard from "./CatalogRecommendedProductCard";
import CatalogItemInventoryUpdatedDialog from "./CatalogItemInventoryUpdatedDialog";
import CommonConstantValues from "../../Services/CommonConstantValues";
import StoreDialog from "./StoreDialog";
import { differenceInDays, startOfDay } from "date-fns";

export default function StoreInventoryPage() {
  const navigate = useNavigate();
  const [isDeliveryViaStoreEnabled, setIsDeliveryViaStoreEnabled] =
    useState(false);
  const [catalog, setCatalog] = useState([]);
  const [inventoryChangePayload, setInventoryChangePayload] = useState([]);
  const [isInventorySameAsYesterday, setIsInventorySameAsYesterday] =
    useState(false);
  const [catalogRecommendations, setCatalogRecommendations] = useState(null);
  const [showCatalogItemPublishedDialog, setShowCatalogItemPublishedDialog] =
    useState(false);
  const [showCatalogErrorDialog, setShowCatalogErrorDialog] = useState(false);
  const [
    showCatalogItemUnpublishConfirmationDialog,
    setShowCatalogItemUnpublishConfirmationDialog,
  ] = useState(false);
  const [showCatalogItemUnpublishedDialog, setShowCatalogItemUnblishedDialog] =
    useState(false);
  const [skuCodeToUnpublish, setSkuCodeToUnpublish] = useState(null);
  const [skuCodeToPublish, setSkuCodeToPublish] = useState(null);
  const [productToUpdate, setProductToUpdate] = useState(null);
  const [loadingCatalog, setLoadingCatalog] = useState("not-started");
  const [loadingPublish, setLoadingPublish] = useState("not-started");
  const [loadingUnpublish, setLoadinUnpublish] = useState("not-started");
  const [loadingCatalogRecommendations, setLoadingCatalogRecommendations] =
    useState("not-started");
  const [isInventoryChanged, setIsInventoryChanged] = useState(false);
  const [showStoreDialog, setShowStoreDialog] = useState(false);

  useEffect(() => {
    AnalyticsService.pushEvent(`Store Front Catalog Page Viewed`);
    const catalogCB = fetchCatalog();

    const institutionDetails = JSON.parse(
      localStorage.getItem(`InstitutionDetails`)
    );
    if (institutionDetails?.isDeliveryViaStoreEnabled) {
      setIsDeliveryViaStoreEnabled(true);
    } else {
      fetchCatalogRecommendations();
    }

    return () => {
      catalogCB();
    };
  }, []);

  useEffect(() => {
    const today = new Date();
    const lastUpdatedOn = JSON.parse(
      localStorage.getItem(`inventoryLastUpdatedOn`)
    );
    if (
      lastUpdatedOn &&
      today.getDate() === new Date(lastUpdatedOn).getDate()
    ) {
      setIsInventorySameAsYesterday(true);
    }
  }, []);

  const fetchCatalog = () => {
    setCatalog([]);
    setLoadingCatalog("started");
    const { axiosRequest, abortController } =
      StoreFrontService.getStoreFrontCatalog();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.productGists?.length > 0) {
          setCatalog(response.data.responseData.productGists);
        } else {
        }
        setLoadingCatalog("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoadingCatalog("done");
        }
      });
    return () => abortController.abort();
  };

  const fetchCatalogRecommendations = () => {
    setCatalogRecommendations(null);
    setLoadingCatalogRecommendations("started");
    const { axiosRequest, abortController } =
      StoreFrontService.getCatalogProductRecommendations();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.productGists?.length > 0) {
          setCatalogRecommendations(response.data.responseData.productGists);
        } else {
        }
        setLoadingCatalogRecommendations("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoadingCatalogRecommendations("done");
        }
      });
    return () => abortController.abort();
  };

  const handleToggleCatalogItem = (product) => {
    const skuCode = product?.skuCode;
    const isEnabled = !product?.isEnabled;
    setProductToUpdate(product);
    if (isEnabled) {
      setSkuCodeToPublish(skuCode);
      setLoadingPublish("started");
      const { axiosRequest } = StoreFrontService.toggleStoreFrontCatalogItem(
        skuCode,
        isEnabled,
        product?.sellingPrice,
        product?.quantity
      );
      axiosRequest
        .then((response) => {
          if (response?.data?.status) {
            setShowCatalogItemPublishedDialog(true);

            fetchCatalog();
          } else {
            setShowCatalogErrorDialog(true);
          }
          setLoadingPublish("done");
        })
        .catch(() => {
          setLoadingPublish("done");
          setShowCatalogErrorDialog(true);
        });
      AnalyticsService.pushEvent(
        "Store Front Catalog Item Publish Button Clicked",
        {
          skuCode: skuCode,
        }
      );
    } else {
      setSkuCodeToUnpublish(skuCode);
      setShowCatalogItemUnpublishConfirmationDialog(true);
      AnalyticsService.pushEvent(
        "Store Front Catalog Item Unpublish Button Clicked",
        {
          skuCode: skuCode,
        }
      );
    }
  };

  const handleUnpublishCatalogItem = (skuCode) => {
    setLoadinUnpublish("started");
    const { axiosRequest } = StoreFrontService.toggleStoreFrontCatalogItem(
      skuCode,
      false,
      productToUpdate?.sellingPrice,
      productToUpdate?.quantity
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.status) {
          setShowCatalogItemUnblishedDialog(true);
          setShowCatalogItemUnpublishConfirmationDialog(false);
          fetchCatalog();
        } else {
          setShowCatalogItemUnpublishConfirmationDialog(false);
          setShowCatalogErrorDialog(true);
        }
        setLoadinUnpublish("done");
      })
      .catch(() => {
        setShowCatalogItemUnpublishConfirmationDialog(false);
        setLoadinUnpublish("done");
        setShowCatalogErrorDialog(true);
      });
  };

  const handleInventorySameAsYesterday = () => {
    const toggleProperties = {
      type: "My Inventory Same As Yesterday Inventory",
      isUpdated: false,
    };
    if (!isInventorySameAsYesterday) {
      // If partner has not updated inventory
      if (!JSON.parse(localStorage.getItem(`inventoryLastUpdatedOn`))) {
        AnalyticsService.pushEvent(`Toggle Clicked`, toggleProperties);
        return setShowStoreDialog(true);
      }

      // If partner has updated inventory, but coming on/after 2 days
      const today = startOfDay(new Date());
      const lastUpdatedOn = startOfDay(
        new Date(JSON.parse(localStorage.getItem(`inventoryLastUpdatedOn`)))
      );
      const daysDiff = differenceInDays(lastUpdatedOn, today);
      console.log("daysDiff: ", daysDiff);
      if (daysDiff > CommonConstantValues.INVENTORY_SAME_AS_YESTERDAY_TIME) {
        localStorage.removeItem(`catalogInventory`);
        localStorage.removeItem(`inventoryLastUpdatedOn`);
        AnalyticsService.pushEvent(`Toggle Clicked`, toggleProperties);
        return setShowStoreDialog(true);
      }

      const clientCatalogToIsEnabledDict =
        JSON.parse(localStorage.getItem(`catalogInventory`))?.reduce(
          (acc, curr) => {
            acc[curr.skuCode] = curr?.isEnabled;
            return acc;
          },
          {}
        ) || {};

      const newCatalog = catalog.map((product) => ({
        ...product,
        isEnabled:
          product?.isEnabled ||
          clientCatalogToIsEnabledDict?.[product?.skuCode],
      }));
      setCatalog(newCatalog);
      setInventoryChangePayload(newCatalog);
      setIsInventorySameAsYesterday(true);
      toggleProperties.isUpdated = true;
      AnalyticsService.pushEvent(`Toggle Clicked`, toggleProperties);
    } else {
      setIsInventorySameAsYesterday(false);
      setInventoryChangePayload(catalog);
      AnalyticsService.pushEvent(`Toggle Clicked`, toggleProperties);
    }
    setIsInventoryChanged(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let skus = "";
    let quantities = "";
    let enabledStatus = "";
    let sellingPrices = "";
    catalog?.forEach((product) => {
      skus += `${product?.skuCode},`;
      quantities += `${product?.quantity},`;
      enabledStatus += `${product?.isEnabled},`;
      sellingPrices += `${product?.sellingPrice},`;
    });

    if (!isInventoryChanged) {
      AnalyticsService.pushEvent("Button Clicked", {
        type: "Submit",
        skus,
        enabledStatus,
        quantities,
        sellingPrices,
        isInventoryUpdated: false,
      });
      return null;
    }

    AnalyticsService.pushEvent("Button Clicked", {
      type: "Submit",
      skus,
      enabledStatus,
      quantities,
      sellingPrices,
      isInventoryUpdated: true,
    });

    localStorage.setItem(`catalogInventory`, JSON.stringify(catalog));
    localStorage.setItem(`inventoryLastUpdatedOn`, JSON.stringify(new Date()));

    const products = inventoryChangePayload?.map((product) => ({
      skuCode: product?.skuCode,
      isEnabled: product?.isEnabled,
      quantity: parseInt(product?.quantity),
      sellingPrice: parseInt(product?.sellingPrice),
    }));
    const { axiosRequest } =
      StoreFrontService.toggleStoreFrontCatalogItems(products);
    axiosRequest
      .then((response) => {
        if (response?.data?.status) {
          fetchCatalog();
          setIsInventoryChanged(false);
          setInventoryChangePayload([]);
          setShowCatalogItemPublishedDialog(true);
        } else {
          setShowCatalogErrorDialog(true);
        }
        setLoadingPublish("done");
      })
      .catch(() => {
        setLoadingPublish("done");
        setShowCatalogErrorDialog(true);
      });
  };

  return (
    <div>
      <TopBar title={`My Inventory`} backNavigation={true} />
      {isDeliveryViaStoreEnabled ? (
        <BlockUi
          tag="div"
          keepInView
          message={`Please wait...`}
          blocking={loadingCatalog === "started"}
          style={{ minHeight: "20vh", marginBottom: "110px" }}
        >
          {catalog?.length ? (
            <form onSubmit={handleSubmit}>
              <Container maxWidth={`xl`} sx={{ padding: "16px" }}>
                <Typography variant="subtitle2" fontWeight={700}>
                  Update Inventory ({catalog?.length || 0})
                </Typography>

                {catalog?.map((product) => (
                  <CatalogProductCard
                    product={product}
                    key={product.skuCode}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    isDeliveryViaStoreEnabled={true}
                    setIsInventoryChanged={setIsInventoryChanged}
                    handleToggleCatalogItem={handleToggleCatalogItem}
                    inventoryChangePayload={inventoryChangePayload}
                    setInventoryChangePayload={setInventoryChangePayload}
                  />
                ))}
              </Container>

              <Box
                sx={{
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                  maxWidth: "600px",
                  background: "#fff",
                  borderTop: "1px solid #f3f3f3",
                }}
              >
                <Grid
                  container
                  sx={{ padding: "8px 25px", borderRadius: "16px" }}
                >
                  <Grid
                    item
                    xs={11}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#4F4F4F",
                    }}
                  >
                    My inventory is same as yesterday's
                  </Grid>
                  <Grid item xs={1}>
                    <FormControlLabel
                      control={
                        <Switch
                          color={"secondary"}
                          size={"small"}
                          checked={isInventorySameAsYesterday}
                          onChange={handleInventorySameAsYesterday}
                        />
                      }
                    />
                  </Grid>
                </Grid>

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    height: "50px",
                    textAlign: "center",
                    borderRadius: 0,
                    background: !isInventoryChanged ? "#8C8C8C" : "",
                    "&:hover": {
                      backgroundColor: !isInventoryChanged ? "#8C8C8C" : "",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          ) : (
            loadingCatalog === "done" && (
              <EmptyError
                extraStyle={{
                  minHeight: `40vh`,
                  background: "#FFFFFF",
                }}
                hideBack={true}
                image="EmptyPage.svg"
                subText="You haven't purchased any products in the last 90 days"
              />
            )
          )}
        </BlockUi>
      ) : (
        <Container maxWidth={`xl`}>
          <BlockUi
            tag="div"
            blocking={
              loadingCatalog === "started" || loadingPublish === "started"
            }
            message={`Please wait`}
            keepInView
            style={{ minHeight: `20vh`, marginTop: "20px" }}
          >
            {catalog?.length > 0 ? (
              <>
                <SectionTitle title={`Recently Bought Products`} />
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    {_.map(catalog, (product, index) => {
                      return (
                        <CatalogProductCard
                          product={product}
                          loadingPublish={loadingPublish}
                          handleToggleCatalogItem={handleToggleCatalogItem}
                          key={product.skuCode}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            ) : (
              loadingCatalog === "done" && (
                <EmptyError
                  extraStyle={{
                    minHeight: `40vh`,
                    background: "#FFFFFF",
                  }}
                  hideBack={true}
                  image="EmptyPage.svg"
                  subText="You haven't purchased any products in the last 90 days"
                />
              )
            )}
          </BlockUi>
          <BlockUi
            tag="div"
            blocking={loadingCatalogRecommendations === "started"}
            message={`Please wait`}
            style={{ minHeight: `40vh`, marginTop: "30px" }}
          >
            {catalogRecommendations?.length > 0 ? (
              <>
                <SectionTitle title={`You Don't Have These Products`} />
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    {_.map(catalogRecommendations, (product, index) => {
                      return (
                        <CatalogRecommendedProductCard
                          product={product}
                          key={product.skuCode}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </BlockUi>
        </Container>
      )}

      <>
        {isDeliveryViaStoreEnabled ? (
          <CatalogItemInventoryUpdatedDialog
            open={showCatalogItemPublishedDialog}
            handleClickDone={() => {
              setShowCatalogItemPublishedDialog(false);
              AnalyticsService.pushEvent(
                "Store Front Catalog Item Published Dialog Done Clicked"
              );
              navigate(-1);
            }}
            onClose={() => {
              setShowCatalogItemPublishedDialog(false);
              AnalyticsService.pushEvent(
                "Store Front Catalog Item Published Dialog Closed"
              );
            }}
          />
        ) : (
          <CatalogItemPublishedDialog
            open={showCatalogItemPublishedDialog}
            handleClickDone={() => {
              setShowCatalogItemPublishedDialog(false);
              AnalyticsService.pushEvent(
                "Store Front Catalog Item Published Dialog Done Clicked",
                {
                  skuCode: skuCodeToPublish,
                }
              );
            }}
            onClose={() => {
              setShowCatalogItemPublishedDialog(false);
              AnalyticsService.pushEvent(
                "Store Front Catalog Item Published Dialog Closed",
                {
                  skuCode: skuCodeToPublish,
                }
              );
            }}
          />
        )}
        <CatalogItemUnpublishConfirmationDialog
          skuCode={skuCodeToUnpublish}
          open={showCatalogItemUnpublishConfirmationDialog}
          loadingUnpublish={loadingUnpublish}
          handleClickReject={() => {
            setShowCatalogItemUnpublishConfirmationDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmation Rejected",
              {
                skuCode: skuCodeToUnpublish,
              }
            );
          }}
          handleClickConfirm={(skuCode) => {
            handleUnpublishCatalogItem(skuCode);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmed",
              {
                skuCode: skuCode,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemUnpublishConfirmationDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublish Confirmation Dialog Closed",
              {
                skuCode: skuCodeToUnpublish,
              }
            );
          }}
        />
        <CatalogItemUnpublishedDialog
          open={showCatalogItemUnpublishedDialog}
          handleClickDone={() => {
            setShowCatalogItemUnblishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublished Dialog Done Clicked",
              {
                skuCode: skuCodeToUnpublish,
              }
            );
          }}
          onClose={() => {
            setShowCatalogItemUnblishedDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Item Unpublished Dialog Closed",
              {
                skuCode: skuCodeToUnpublish,
              }
            );
          }}
        />
        <CatalogError
          open={showCatalogErrorDialog}
          handleClickDone={() => {
            setShowCatalogErrorDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Error Dialog Done Clicked"
            );
          }}
          onClose={() => {
            setShowCatalogErrorDialog(false);
            AnalyticsService.pushEvent(
              "Store Front Catalog Error Dialog Closed"
            );
          }}
        />
        <StoreDialog
          open={showStoreDialog}
          icon={<WarningAmberRounded sx={{ width: "55px", height: "55px" }} />}
          message={`Please update your inventory to use this.`}
          handleClickDone={() => {
            setShowStoreDialog(false);
            AnalyticsService.pushEvent(`Dialog Closed`, {
              type: "My Inventory Same As Yesterday Inventory",
            });
            window.scrollTo(0, 0);
          }}
          onClose={() => {
            setShowStoreDialog(false);
            AnalyticsService.pushEvent(`Dialog Closed`, {
              type: "My Inventory Same As Yesterday Inventory",
            });
            window.scrollTo(0, 0);
          }}
        />
      </>
    </div>
  );
}
