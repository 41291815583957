import config from "../config";
import HTTPService from "./HTTPService";

export default class InsuranceService {
  static getInsuranceDetails(farmerId) {
    const url = `${config.backendUrl}institutionalsalesservice/uploader/InsuranceIndicator/?farmerId=${farmerId}`;
    return HTTPService.makeXHRRequest(url, "GET", null, {
      source: "B2B",
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    });
  }
}
