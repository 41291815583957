import Fab from "@mui/material/Fab";
import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AnalyticsService from "../../Services/AnalyticsService";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  FloatingActionButton: {
    background: "rgba(157, 33, 35, 0.7)",
    position: "fixed",
    right: 16,
    [theme.breakpoints.up("sm")]: {
      right: "30vw",
    },
    color: "white",
    fontWeight: "700",
    fontSize: "15px",
    textTransform: "capitalize",
    borderRadius: "47.8px",
    height: "40px",
    boxShadow: "0px, 8.39px rgba(157, 33, 35, 0.2)",
  },
  fabImage: {
    height: "22px",
  },
}));

const FloatingActionButton = (props) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (event) => {
      setScrollTop(event.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const handleClickFab = () => {
    if (props.isFromProfilePage) {
      localStorage.setItem("createComplaintFromProfilePage", true);
    }
    const floatingActionButtonEvent = {
      page: props.currentPagePath,
    };
    AnalyticsService.pushEvent(
      props.clickedEventTitle,
      floatingActionButtonEvent
    );
    navigate(props.nextPagePath);
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Fab
          onClick={handleClickFab}
          variant="extended"
          className={classes.FloatingActionButton}
          color="primary"
          style={{ bottom: 75, fontSize: "12px" }}
        >
          <img src={props.fabIconPath} className={classes.fabImage} />
          {!scrollTop ? <>&nbsp;&nbsp;{props.title}</> : ""}
        </Fab>
      </Box>
    </>
  );
};

export default FloatingActionButton;
