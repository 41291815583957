import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import ReferralService from "../../Services/ReferralService";
import AnalyticsService from "../../Services/AnalyticsService";
import SuccessfulReferral from "./SuccessfulReferral";
import UnverifiedPartnersDialog from "../Common/UnverifiedPartnersDialog";
import Toast from "../Common/Toast";
import { Skeleton } from "@mui/material";
import useMessages from "../../Hooks/useMessages";
import AndroidService from "../../Services/AndroidService";
import StringUtils from "../../Utils/StringUtils";
import { WhatsappShareButton } from "react-share";

const useStyles = makeStyles((theme) => ({
  head: {
    fontSize: "18px",
    padding: "15px",
    fontWeight: "bold",
  },
  note: {
    fontStyle: "italic",
    fontSize: "10px",
    padding: "15px",
    paddingTop: "0px",
    //opacity: 0.5,
    color: "#666666",
  },
  inviteButton: {
    width: "95%",
    textTransform: "capitalize",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "5vh",
  },
}));

const Referral = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  let [successfulReferredUser, setSuccessfulReferredUser] = useState([]);
  const { getMessage } = useMessages();
  const [referralCode, setReferralCode] = useState("");
  const [showReferralCodeCopiedToast, setShowReferralCodeCopiedToast] =
    useState(false);
  let [successfulReferredUsers, setSuccessfulReferredUsers] = useState([]);
  const [showUnauthorizedError, setShowUnauthorizedError] = useState(false);
  const [referralCodeLoading, setReferralCodeLoading] = useState("not_started");
  const [successfulReferralsLoading, setSuccessfulReferralsLoading] =
    useState("not_started");
  const [whatsappWebShareText, setWhatsappWebShareText] = useState("");
  const [deviceType, setDeviceType] = useState("web");

  function handleWhatsappShare() {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Share Referral Code",
    });
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      setShowUnauthorizedError(true);
      return;
    }

    const whatsappText = `${StringUtils.fillTemplate(
      getMessage("referral.whatsappMessage"),
      [referralCode]
    )}${referralCode}`;

    const whatsappImage = ReferralService.getWhatsappReferralImage();

    AndroidService.shareOnWhatsApp(whatsappText, whatsappImage);
  }

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      setDeviceType("android");
    } else {
      setDeviceType("web");
    }
  }, []);

  useEffect(() => {
    setReferralCodeLoading("started");
    setSuccessfulReferralsLoading("started");
    const referralPageViewedData = {
      page: `/referral`,
    };

    AnalyticsService.pushEvent(`Referral Page Viewed`, referralPageViewedData);

    const {
      axiosRequest: axiosRequestReferral,
      abortController: abortControllerReferral,
    } = ReferralService.getReferralCodeByFarmerId();
    axiosRequestReferral
      .then((referralCodeResponse) => {
        const referralCode =
          referralCodeResponse?.data?.responseData?.referralCode;
        setReferralCode(referralCode);
        setWhatsappWebShareText(
          `${StringUtils.fillTemplate(getMessage("referral.whatsappMessage"), [
            referralCode,
          ])}${referralCode}`
        );

        setReferralCodeLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.error("could not get referral code", error);
          setReferralCode("");
          setReferralCodeLoading("done");
        }
      });

    const { axiosRequest, abortController } =
      ReferralService.getSuccessfulReferralsByFarmerId();
    axiosRequest
      .then((successfulReferralResponse) => {
        setSuccessfulReferredUsers(
          successfulReferralResponse?.data?.responseData
            ?.referredFarmersTransactions
        );
        setSuccessfulReferralsLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.error("could not get successful referrals list", error);
          setSuccessfulReferredUsers([]);
          setSuccessfulReferralsLoading("done");
        }
      });

    return () => {
      abortController.abort();
      abortControllerReferral.abort();
    };
  }, []);

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Refer & Earn`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
          toolTip={`Refer & Earn`}
        />
        <Typography variant="subtitle2" className={classes.head}>
          How It Works
        </Typography>
        <Box
          sx={{
            padding: "0px 8px",
            minHeight: "325px",
            backgroundColor: "#fff",
          }}
        >
          <img
            src={ReferralService.getReferralHeroImage()}
            style={{ width: "100%" }}
            alt={`How it works`}
          />
        </Box>
        <Box sx={{ padding: "0px 16px", marginTop: "16px" }}>
          {deviceType === "web" ? (
            <WhatsappShareButton
              style={{ width: "100%" }}
              url={`.`}
              title={whatsappWebShareText}
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ marginBottom: "16px" }}
                onClick={() => {
                  AnalyticsService.pushEvent("Button Clicked", {
                    type: "Share Referral Code",
                  });
                }}
              >
                <img
                  src="/icons/whatsappLeadsChat.svg"
                  alt=""
                  height={24}
                  width={24}
                />
                <Typography
                  variant="subtitle1"
                  component="span"
                  sx={{ marginLeft: "8px", fontSize: "14px" }}
                >
                  Refer a new partner
                </Typography>
              </Button>
            </WhatsappShareButton>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ marginBottom: "16px" }}
              onClick={handleWhatsappShare}
            >
              <img
                src="/icons/whatsappLeadsChat.svg"
                alt=""
                height={24}
                width={24}
              />
              <Typography
                variant="subtitle1"
                component="span"
                sx={{ marginLeft: "8px", fontSize: "14px" }}
              >
                Refer a new partner
              </Typography>
            </Button>
          )}

          {referralCodeLoading === "started" ? (
            <Skeleton
              variant="rounded"
              height={50}
              sx={{ marginBottom: "12px" }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "2px solid #fff",
                padding: "8px 16px",
                borderRadius: "12px",
                marginBottom: "16px",
                backgroundColor: "#EDECEC",
              }}
            >
              <Box>
                <Typography variant="subtitle1" component="p">
                  Referral Code
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ fontWeight: "bold" }}
                >
                  {referralCode}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  color="primary"
                  sx={{
                    padding: "6px",
                    backgroundColor: "#9D2123",
                    "&:focus": {
                      backgroundColor: `#9D2123`,
                    },
                    "&:hover": {
                      backgroundColor: `#9D2123`,
                    },
                  }}
                  onClick={() => {
                    AnalyticsService.pushEvent("Button Clicked", {
                      type: "Referral Code Copied",
                    });
                    navigator?.clipboard?.writeText(referralCode);
                    setShowReferralCodeCopiedToast(true);
                  }}
                >
                  <img src="/icons/copy.svg" alt="" height={20} width={20} />
                </IconButton>
              </Box>
              <Toast
                show={showReferralCodeCopiedToast}
                setShow={setShowReferralCodeCopiedToast}
                alertText="Referral Code Copied"
              />
            </Box>
          )}
        </Box>

        <SuccessfulReferral
          successfulReferredUsers={successfulReferredUsers}
          loading={successfulReferralsLoading}
        />

        <UnverifiedPartnersDialog
          showError={showUnauthorizedError}
          setShowError={setShowUnauthorizedError}
          error={
            "You will get access to this feature once you become our Saathi partner"
          }
        />
      </ErrorBoundary>
    </>
  );
};

export default Referral;
