import React from "react";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import {
  Card,
  Container,
  Typography,
  Grid,
  CardContent,
  CardMedia,
  IconButton,
  Button,
  Skeleton,
} from "@mui/material";
import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { HighlightOff } from "@mui/icons-material";
import AndroidService from "../../Services/AndroidService";
import EmptyError from "../EmptyError/EmptyError";
import useQuery from "../../Hooks/useQuery";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    minHeight: "200px",
  },
  scrollingLine: {
    width: "50px",
    height: "5px",
    margin: "auto",
    marginTop: theme.spacing(2),
    background: "#000",
    borderRadius: "5px",
    opacity: 0.2,
  },
  cancelButton: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(1),
    opacity: 0.5,
  },
  documentList: {
    background: "#FFFFFF",
    border: "1px solid rgba(0, 115, 62, 0.1)",
    borderRadius: "10px",
    height: "40px",
  },
  fileNameText: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
}));

export default function Document({
  showDocumentPanel,
  setShowDocumentPanel,
  documentList,
  documentLoad,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState("not_started");
  const [docList, setDocList] = useState([]);
  let query = useQuery();
  useEffect(() => {
    if (query.get("showDetails") === "documents-download") {
      const documentDownloadViewedData = {
        page: `/profile`,
      };
      AnalyticsService.pushEvent(
        `Documents Drawer Viewed`,
        documentDownloadViewedData
      );
    }
    setLoading(documentLoad);
    setDocList(documentList);
    setShowDocumentPanel(showDocumentPanel);
  }, []);

  const handleDocumentDownloadPanel = () => {
    setShowDocumentPanel(false);
    const documentDownloadClosed = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(
      `Documents Drawer Closed`,
      documentDownloadClosed
    );
  };

  const handleDownload = async (downloadUrl, fileName, fileType, fileId) => {
    let documentDownloadViewedData = {
      file: downloadUrl,
      fileName: fileName,
      fileId: fileId,
      page: `/profile`,
    };
    AnalyticsService.pushEvent(
      `Documents Download Clicked`,
      documentDownloadViewedData
    );

    try {
      const pdfResponse = await fetch(`${downloadUrl}`);
      const blobResponse = await pdfResponse.blob();

      const fileDownloadElement = document.createElement("a");
      fileDownloadElement.href = URL.createObjectURL(blobResponse);
      if (AndroidService.checkIfAndroid()) {
        AndroidService.setFileNameInApp(`${fileName}`);
      }
      fileDownloadElement.download = `${fileName}.${fileType.split("/")[1]}`;
      fileDownloadElement.click();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={showDocumentPanel}
      onClose={() => handleDocumentDownloadPanel()}
      onOpen={() => {}}
      classes={{ paper: classes.paper }}
      disableSwipeToOpen={true}
      disableDiscovery={true}
    >
      <div>
        <Box role="presentation">
          <Box
            sx={{
              position: "absolute",
              // top: -60,
              right: 0,
              left: 0,
              background: "#fff",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            }}
          >
            <div className={classes.scrollingLine}></div>
            <IconButton
              className={classes.cancelButton}
              onClick={handleDocumentDownloadPanel}
            >
              <HighlightOff
                sx={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </IconButton>
            <Typography
              variant="h6"
              fontSize={18}
              fontWeight={700}
              sx={{ p: 2 }}
            >
              Download Documents
            </Typography>
          </Box>

          {/* <Box className={classes.documentList}></Box> */}
        </Box>
        <Box sx={{ mt: 9, mb: 2 }}>
          {docList.length > 0 &&
            loading === "done" &&
            docList.map((doc, docIndex) => {
              return (
                <Box
                  key={docIndex}
                  sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                >
                  <Button
                    variant="contained"
                    fullWidth={true}
                    aria-label="download"
                    endIcon={
                      <img
                        src="/icons/downloadIcon.svg"
                        alt="download"
                        style={{ height: "14px" }}
                      />
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "400",
                      padding: "10px 16px",
                      color: "#333333",
                      textTransform: "none",
                      boxShadow: "none",
                      background: "#fff",
                      mt: 2,
                      borderRadius: "10px",
                      "&:hover": { background: "#fff" },
                      border: "1px solid rgba(0, 115, 62, 0.1)",
                      height: "40px",
                      // margin
                    }}
                    onClick={() =>
                      handleDownload(
                        doc?.fileLink,
                        doc?.name,
                        doc?.fileType,
                        doc?.id
                      )
                    }
                  >
                    <span className={classes.fileNameText}>{doc?.name}</span>
                  </Button>
                </Box>
              );
            })}
        </Box>

        {loading === "started" && (
          <Box>
            <Box sx={{ mb: 2, pl: 2, pr: 2 }}>
              <Skeleton variant="rectangular" height={40} />
            </Box>
            <Box sx={{ mb: 2, pl: 2, pr: 2 }}>
              <Skeleton variant="rectangular" height={40} />
            </Box>
            <Box sx={{ mb: 2, pl: 2, pr: 2 }}>
              <Skeleton variant="rectangular" height={40} />
            </Box>
          </Box>
        )}
      </div>
    </SwipeableDrawer>
  );
}
