import React from "react";
import { Chip } from "@mui/material";

function ChipSelector({
  isFilterSelected,
  handleChangeSelectedValue,
  keyName,
}) {
  return (
    <Chip
      key={keyName}
      variant={isFilterSelected ? "soft" : "plain"}
      label={keyName}
      sx={{
        backgroundColor: "white !important",
        color: isFilterSelected ? "#9D2123" : "#4F4F4F",
        border: isFilterSelected && "1px solid #9D2123",
        margin: "1px 4px",
        fontSize: "12px",
        height: "30px",
        fontWeight: "700",
        boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.10)",
      }}
      clickable={true}
      onClick={() => handleChangeSelectedValue()}
    />
  );
}

export default ChipSelector;
