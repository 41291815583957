import { Box, Grid, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import AnalyticsService from "../../Services/AnalyticsService";
import FarmersService from "../../Services/FarmersService";
import PartnerClubSegments from "../../Services/PartnerClubSegments";

const useStyles = makeStyles((theme) => ({}));

const PartnerClub = (props) => {
  const classes = useStyles();
  const [myFarmersSummary, setMyFarmersSummary] = useState();
  const [loading, setLoading] = useState("not_started");

  const handlePartnerClubClick = () => {
    AnalyticsService.pushEvent(`Partner Club Clicked`, {
      page: `/profile`,
      segment: props.currentPartnerClubData?.club,
    });
  };

  return (
    <>
      {props.loading === "started" && (
        <Skeleton
          variant={`rectangular`}
          height={45}
          style={{ marginTop: "16px" }}
        />
      )}

      {props.loading === "done" &&
        props.currentPartnerClubData?.club &&
        PartnerClubSegments[props.currentPartnerClubData?.club] && (
          <Grid
            container
            direction={`row`}
            justifyContent="start"
            alignItems="center"
            spacing={0}
            sx={{
              paddingTop: 0.5,
              paddingBottom: 0.5,
              paddingLeft: 1,
              paddingRight: 1,
              borderRadius: "12px",
              background:
                "url(/icons/starBackground.svg), linear-gradient(15.14deg, #B84307 0%, #F6A313 158.4%);",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              cursor: "pointer",
            }}
            onClick={handlePartnerClubClick}
          >
            <>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  fontSize={16}
                  fontWeight={700}
                  sx={{
                    color: "#FFFFFF",
                  }}
                >
                  {PartnerClubSegments[props.currentPartnerClubData?.club]}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <img
                  src={`/icons/partnerStar.gif`}
                  style={{ height: "36px" }}
                  alt={`Club Star`}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant={`body1`}
                  fontSize={28}
                  fontWeight={700}
                  sx={{ display: `inline`, color: "#FEDB41" }}
                >
                  {parseInt(
                    Math.round(props.currentPartnerClubData?.stars / 4)
                  )}
                </Typography>
                <Typography
                  variant={`body1`}
                  fontSize={18}
                  fontWeight={700}
                  sx={{ display: `inline`, color: "#FFFFFF" }}
                >
                  /5
                </Typography>
              </Grid>
            </>
          </Grid>
        )}
    </>
  );
};

export default PartnerClub;
