import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Box, Typography } from "@mui/material";
import OrderCard from "./OrderCard";
import EmptyError from "../EmptyError/EmptyError";
import StoreFrontService from "../../Services/StoreFrontService";
import InfiniteScroll from "react-infinite-scroll-component";
import OrderFilters from "../Hisaab/OrderFilters";
import _ from "lodash";

export default function NewOrders() {
  const [newOrdersData, setNewOrdersData] = useState(null);
  const [loading, setLoading] = useState("not-started");
  const [fetchedOrdersCount, setFetchedOrdersCount] = useState(0);

  const ORDER_STATUS_FILTERS = [
    {
      label: "All orders",
      identifier: "all",
      selected: true,
    },
    {
      label: "Confirmed",
      identifier: "pending",
      selected: false,
    },
    {
      label: "Dispatched",
      identifier: "shipped",
      selected: false,
    },
  ];

  const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] = useState(
    _.find(ORDER_STATUS_FILTERS, (filter) => filter.selected)
  );

  useEffect(() => {
    const storeOrdersCB = fetchStoreOrders(true);
    return () => storeOrdersCB();
  }, [selectedOrderStatusFilter]);

  const fetchStoreOrders = (fetchFromStarting = false) => {
    if (fetchFromStarting) {
      setLoading("started");
      setNewOrdersData(null);
      setFetchedOrdersCount(0);
    }
    const { axiosRequest, abortController } = StoreFrontService.getFarmerOrders(
      selectedOrderStatusFilter.identifier,
      fetchFromStarting ? 0 : fetchedOrdersCount
    );

    axiosRequest
      .then((response) => {
        setFetchedOrdersCount(
          (previousFetchedOrdersCount) =>
            previousFetchedOrdersCount +
            response?.data?.responseData?.orders?.length
        );
        setNewOrdersData((previousOrders) =>
          previousOrders?.orders
            ? {
                ...previousOrders,
                orders: [
                  ...previousOrders?.orders,
                  ...response?.data?.responseData?.orders,
                ],
              }
            : response?.data?.responseData
        );
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.log(`Error Getting Store Orders: `, error);
        }
      });
    return () => abortController.abort();
  };

  return (
    <div style={{ padding: "16px 0 40px", background: "#F3F3F3" }}>
      <BlockUi
        tag="div"
        keepInView
        message={`Please wait...`}
        blocking={loading === "started"}
        style={{ minHeight: `90vh`, marginBottom: "24px" }}
      >
        <Box sx={{ padding: "0 16px" }}>
          <OrderFilters
            orderFilters={ORDER_STATUS_FILTERS}
            selectedOrderStatusFilter={selectedOrderStatusFilter}
            setSelectedOrderStatusFilter={setSelectedOrderStatusFilter}
            showDateFilter={false}
          />
        </Box>

        {newOrdersData?.orders?.length ? (
          <>
            <Typography
              variant="subtitle2"
              fontWeight={700}
              sx={{ padding: "0 16px", marginTop: "8px" }}
            >
              Orders ({newOrdersData?.totalOrdersCount})
            </Typography>

            <InfiniteScroll
              next={fetchStoreOrders}
              dataLength={fetchedOrdersCount}
              hasMore={fetchedOrdersCount < newOrdersData?.totalOrdersCount}
              loader={<h5 style={{ textAlign: "center" }}>Loading...</h5>}
              style={{ padding: "8px 16px 20px" }}
            >
              {newOrdersData?.orders?.map((newOrder) => (
                <OrderCard
                  key={newOrder?.id}
                  showCTA={true}
                  newOrder={newOrder}
                />
              ))}
            </InfiniteScroll>
          </>
        ) : (
          loading === "done" && (
            <EmptyError
              extraStyle={{
                minHeight: `40vh`,
              }}
              hideBack={true}
              image="EmptyPage.svg"
              subText={
                selectedOrderStatusFilter.identifier === "shipped"
                  ? "You haven't got any dispatched orders"
                  : "You haven't got new orders."
              }
            />
          )
        )}
      </BlockUi>
    </div>
  );
}
