import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";

import { East } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import useQuery from "../../Hooks/useQuery";
import ProductGroupCard from "./ProductGroupCard";
import SearchOrders from "../Hisaab/SearchOrders";
import OrderService from "../../Services/OrderService";
import EmptyError from "../EmptyError/EmptyError";
import AnalyticsService from "../../Services/AnalyticsService";
import ErrorBoundary from "../Common/ErrorBoundary";
import AmountUtils from "../../Utils/AmountUtils";
import PaymentService from "../../Services/PaymentService";
import SettleNowDialog from "../Hisaab/SettleNowDialog";
import _ from "lodash";
import PartnerService from "../../Services/PartnerService";

let ORDER_FILTERS = [
  {
    label: "Dispatched Orders",
    identifier: "DISPATCHED",
    lendingPartner: "RUPIFI",
    selected: true,
  },
  {
    label: "Non-Dispatched Orders",
    identifier: "NON_DISPATCHED",
    lendingPartner: "AGROSTAR",
    selected: false,
  },
];
const selectedFilter = {
  backgroundColor: "#FFFFFF",
  color: "#9D2123",
  border: "1px solid #9D2123",
};
const unselectedFilter = {
  backgroundColor: "#FFFFFF !important",
  color: "#4F4F4F",
};

export default function PayByProductGroup() {
  const query = useQuery();
  const [keyword, setKeyword] = useState("");
  const [keywordDebounced, setKeywordDebounced] = useState("");
  const [productGroupList, setProductGroupList] = useState(null);
  const [filteredProductList, setFilteredProductList] = useState(null);
  const [totalGroupCount, setTotalGroupCount] = useState(0);
  const [loading, setLoading] = useState("not-started");
  const [loadingVANDetails, setLoadingVANDetails] = useState("not-started");
  const [productListToSettle, setProductListToSettle] = useState([]);
  const [vanPaymentDetails, setVanPaymentDetails] = useState(null);
  const [selectedAmountToSettle, setSelectedAmountToSettle] = useState(0);
  const [selectClicked, setSelectClicked] = useState(false);
  const [showSettleNowDialog, setShowSettleNowDialog] = useState(false);
  const [settlementDetails, setSettlementDetails] = useState(null);
  const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] = useState(
    sessionStorage.getItem("payByProductGroupSelectedFilter")
      ? JSON.parse(sessionStorage.getItem("payByProductGroupSelectedFilter"))
      : _.find(ORDER_FILTERS, (filter) => {
          return filter?.selected;
        })
  );

  useEffect(() => {
    AnalyticsService.pushEvent("Tab Viewed", {
      tabName: "Pay By Product Group",
    });

    setLoading("started");
    const { axiosRequest, abortController } = OrderService.getProductGroups({
      lendingPartner: selectedOrderStatusFilter?.lendingPartner,
    });
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          const modifiedProductGroupListFromResponse = _.map(
            response?.data?.responseData?.productGroupList,
            (item) => ({
              ...item,
              lendingPartner: selectedOrderStatusFilter?.lendingPartner,
            })
          );
          setTotalGroupCount(
            response.data.responseData?.totalProductGroupCount
          );
          setProductGroupList(modifiedProductGroupListFromResponse);
          setFilteredProductList(modifiedProductGroupListFromResponse);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.error("Error getting Product Groups: ", error?.message);
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, [selectedOrderStatusFilter]);

  useEffect(() => {
    const productSalesGroup = query.get("productSalesGroup");
    if (productSalesGroup) {
      setKeyword(productSalesGroup);
      setKeywordDebounced(productSalesGroup);
    }
  }, []);

  useEffect(() => {
    // Href Update Logic
    if (keywordDebounced) {
      query.set("productSalesGroup", keywordDebounced);
      const replaceURL = `/hisaab/pay-by-product-group?${query.toString()}`;
      window.history.replaceState({}, null, replaceURL);
    }

    // Product Group List Filter Logic
    const newFilteredProductList = productGroupList?.filter((product) =>
      product?.productSalesGroup
        ?.toLowerCase()
        ?.includes(keywordDebounced?.toLowerCase())
    );
    setTotalGroupCount(newFilteredProductList?.length);
    setFilteredProductList(newFilteredProductList);
  }, [keywordDebounced, productGroupList]);

  useEffect(() => {
    setLoadingVANDetails("started");
    const { axiosRequest, abortController } =
      PaymentService.getVANPaymentDetails();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setVanPaymentDetails(response.data.responseData);
        }
        setLoadingVANDetails("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoadingVANDetails("done");
          console.error("Error getting VAN Payment Details: ", error?.message);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleSelectClick = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: !selectClicked ? "Select" : "Cancel",
      isSelected: !selectClicked,
    });
    setSelectClicked(!selectClicked);
    setProductListToSettle([]);
  };

  const handleProceed = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Proceed",
      selectedAmountToSettle,
      isDisabled: selectedAmountToSettle === 0,
    });

    if (loading !== "started" && selectedAmountToSettle > 0) {
      setLoading("started");
      const listToSettle = productListToSettle?.map(
        (product) => product?.productSalesGroup
      );
      const { axiosRequest } = PaymentService.getCustomSettlementDetails({
        listToSettle,
        settlementType: "product",
        creditTransactionId: vanPaymentDetails?.transactionId,
      });
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            setSettlementDetails(response.data.responseData);
            setShowSettleNowDialog(true);
          }
          setLoading("done");
        })
        .catch((error) => {
          setLoading("done");
          console.error("Error Credit Custom Settlement: ", error?.message);
        });
    }
  };

  return (
    <React.Fragment>
      <Container>
        <SearchOrders
          keyword={keyword}
          setKeyword={setKeyword}
          keywordDebounced={keywordDebounced}
          setKeywordDebounced={setKeywordDebounced}
          page={window?.location?.pathname}
          placeholderText="Search by Product"
        />

        <ErrorBoundary message={``}>
          {loadingVANDetails === "started" ? (
            <Skeleton variant={`rectangular`} height={52} />
          ) : loadingVANDetails === "done" && vanPaymentDetails?.amount > 0 ? (
            <Grid
              container
              alignItems="center"
              sx={{
                margin: "5px 0px 8px",
                borderRadius: "10px",
                padding: "8px 16px",
                backgroundColor: "#F8D149",
              }}
            >
              <Grid item xs={8.5}>
                <Typography
                  color={"secondary"}
                  fontSize={"12px"}
                  paddingRight={"6px"}
                >
                  We have received{" "}
                  <b>
                    {AmountUtils.formatCurrency(vanPaymentDetails?.amount || 0)}
                  </b>{" "}
                  via VAN payment. Please select products to settle.
                </Typography>
              </Grid>
              <Grid item xs={3.5} sx={{ textAlign: "right" }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    borderRadius: "16px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleSelectClick}
                  disabled={!vanPaymentDetails?.enableCustomSettlement}
                >
                  {selectClicked ? "Cancel" : "Select"}
                </Button>
              </Grid>
              {!vanPaymentDetails?.enableCustomSettlement && (
                <Grid
                  item
                  xs={12}
                  textAlign="center"
                  sx={{
                    fontSize: "12px",
                    padding: "8px 0 0",
                    color: "#9D2123",
                  }}
                >
                  {vanPaymentDetails?.disabledMessage}
                </Grid>
              )}
            </Grid>
          ) : null}
        </ErrorBoundary>

        {PartnerService.isPartnerExternalCreditType() ? (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              margin: "4px 0px",
              marginBottom: "16px",
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {_.map(ORDER_FILTERS, (filter, index) => {
              return (
                <ButtonBase
                  key={index}
                  sx={{
                    boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1);",
                    borderRadius: "16px",
                    padding: "5px 10px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 700,
                    background: "#FFF",
                  }}
                  onClick={() => {
                    AnalyticsService.pushEvent(`Filter Clicked`, {
                      filterLabel: filter?.label || "",
                    });
                    sessionStorage.setItem(
                      "payByProductGroupSelectedFilter",
                      JSON.stringify(filter)
                    );
                    setSelectedOrderStatusFilter(filter);
                  }}
                  style={
                    selectedOrderStatusFilter?.identifier === filter?.identifier
                      ? selectedFilter
                      : unselectedFilter
                  }
                >
                  <Typography
                    noWrap
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    {filter.label}
                  </Typography>
                </ButtonBase>
              );
            })}
          </Stack>
        ) : (
          <></>
        )}
      </Container>

      <BlockUi
        tag="div"
        keepInView
        renderChildren={false}
        message={`Loading...`}
        blocking={loading === "started" && loading !== "done"}
        style={{ minHeight: "60vh", padding: "0 16px 114px" }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "700", mb: 1 }}>
          Products ({totalGroupCount})
        </Typography>

        {filteredProductList?.length
          ? filteredProductList?.map((productGroup) => (
              <ProductGroupCard
                key={productGroup?.productSalesGroup}
                productGroup={productGroup}
                vanPaymentDetails={vanPaymentDetails}
                selectClicked={selectClicked}
                productListToSettle={productListToSettle}
                setProductListToSettle={setProductListToSettle}
                selectedAmountToSettle={selectedAmountToSettle}
                setSelectedAmountToSettle={setSelectedAmountToSettle}
                lendingPartner={
                  PartnerService.isPartnerExternalCreditType()
                    ? selectedOrderStatusFilter?.lendingPartner
                    : "AGROSTAR"
                }
              />
            ))
          : loading === "done" && (
              <EmptyError
                image={`Empty Cart.svg`}
                text={`No Products found!`}
                extraStyle={{ minHeight: "50vh" }}
                hideBack={true}
              />
            )}
      </BlockUi>
      {selectClicked && (
        <Grid
          container
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: 0,
            height: "60px",
            maxWidth: "600px",
            padding: "8px 16px",
            color: "#fff",
            fontSize: "14px",
            background:
              loading !== "started" && selectedAmountToSettle > 0
                ? "#9D2123"
                : "#999",
            borderTop: "1px solid #ffffff1a",
            zIndex: 1061,
          }}
          onClick={handleProceed}
        >
          <Grid item xs={8} container>
            <Grid item xs={12}>
              <b>{AmountUtils.formatCurrency(selectedAmountToSettle)}</b>
            </Grid>
            <Grid item xs={12}>
              Selected Amount
            </Grid>
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Button variant="text" sx={{ color: "#fff" }} endIcon={<East />}>
              Proceed
            </Button>
          </Grid>
        </Grid>
      )}

      {showSettleNowDialog && (
        <SettleNowDialog
          open={showSettleNowDialog}
          paymentAmount={selectedAmountToSettle}
          handleClose={() => setShowSettleNowDialog(false)}
          settlementType={"product"}
          settlementDetails={settlementDetails}
        />
      )}
    </React.Fragment>
  );
}
