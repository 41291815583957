import config from "../config";
import HTTPService from "./HTTPService";

const accountKey = `agrostar`;
const storeImage = `l_fetch:${btoa(
  "https://stagestatic.agrostar.in/static/storeimg.png"
)},x_15,y_40,w_130,g_south_west`;
const storeDetailBackground = `l_text:Roboto_1:t,x_0,y_0,w_600,h_185,co_rgb:FFFFFF,b_rgb:FFFFFF,g_south`;

export class PromotionService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static createSaathiOffer(data) {
    const saathiId = localStorage.getItem(`farmerId`);
    const url = `${config.backendUrl}institutionalsalesservice/saathi/${saathiId}/promotions/`;
    return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
  }

  static getSaathiOffers(count = 25) {
    const saathiId = localStorage.getItem(`farmerId`);
    const url = `${config.backendUrl}institutionalsalesservice/saathi/${saathiId}/promotions/?count=${count}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static deleteSaathiOffer(offerId) {
    const saathiId = localStorage.getItem(`farmerId`);
    const url = `${config.backendUrl}institutionalsalesservice/saathi/${saathiId}/promotions/?offerId=${offerId}`;
    return HTTPService.makeXHRRequest(url, "DELETE", null, this.getHeaders());
  }

  static getOverlayUrlForFestiveGreeting(
    url,
    storeName,
    storeAddress,
    storeMobileNumber
  ) {
    if (!url) return url;

    const addressLines = this.getLines(storeAddress, 40, 3);
    let top = 770,
      addressString = "";
    addressLines?.forEach((line) => {
      const encodeAddressText = this.encodingSpecialCharacterForCloudinary(
        encodeURIComponent(line)
      );
      addressString += `/c_fit,l_text:Roboto_20:${encodeAddressText},g_north_west,x_160,y_${top},w_420,h_30`;
      top += 30;
    });
    storeName = this.encodingSpecialCharacterForCloudinary(
      encodeURIComponent(storeName)
    );
    storeName = `c_fit,l_text:Roboto_24_bold:${storeName},g_north_west,x_160,y_735,w_420,h_30`;
    storeMobileNumber = `l_text:Roboto_20:${storeMobileNumber},g_north_west,x_160,y_${top}`;

    return `https://res.cloudinary.com/${accountKey}/image/fetch/${storeDetailBackground}/${storeImage}/${storeName}${addressString}/${storeMobileNumber}/${url}`;
  }

  static getOverlayUrlForCreateOffer(
    url,
    storeName,
    storeAddress,
    storeMobileNumber,
    image,
    productName,
    title,
    description,
    rating,
    textOverlayFontColour
  ) {
    if (!url) return url;

    if (!image || !productName || !title) {
      return this.getOverlayUrlForFestiveGreeting(
        url,
        storeName,
        storeAddress,
        storeMobileNumber
      );
    }

    const addressLines = this.getLines(storeAddress, 40, 3);
    let top = 770,
      addressString = "";
    addressLines?.forEach((line) => {
      const encodeAddressText = this.encodingSpecialCharacterForCloudinary(
        encodeURIComponent(line)
      );
      addressString += `/c_fit,l_text:Roboto_20:${encodeAddressText},g_north_west,x_160,y_${top},w_420,h_30`;
      top += 30;
    });

    const productCardBackground = `l_text:Roboto_10:product,g_north,y_100,w_250,h_260,r_15,co_white,b_white`;
    const titleAndDescriptionDivider = `l_text:Roboto_2:l,x_100,y_460,w_100,h_3,b_rgb:FFFFFF,r_5,g_north_west/l_text:Roboto_2:l,x_-50,y_450,w_20,h_20,b_rgb:FFFFFF,r_50,g_north/l_text:Roboto_2:l,y_450,w_20,h_20,b_rgb:FFFFFF,r_50,g_north/l_text:Roboto_2:l,x_50,y_450,w_20,h_20,b_rgb:FFFFFF,r_50,g_north/l_text:Roboto_2:l,x_100,y_460,w_100,h_3,b_rgb:FFFFFF,r_5,g_north_east`;
    textOverlayFontColour = textOverlayFontColour?.split("#")[1];
    image = `l_fetch:${btoa(image)},y_150,w_100,ar_100:140,g_north`;
    productName = this.encodingSpecialCharacterForCloudinary(
      encodeURIComponent(productName)
    );
    productName = `c_fit,l_text:Roboto_18:${productName},x_190,y_310,w_220,h_50,g_north_west`;
    rating =
      rating > 0
        ? `l_text:Roboto_10:t,x_175,y_100,w_40,h_30,r_15,co_rgb:00733E,b_rgb:00733E,g_north_west/l_text:Roboto_10:t,x_175,y_115,w_40,h_15,r_3,co_rgb:00733E,b_rgb:00733E,g_north_west/l_text:Roboto_10:tttttt,x_188,y_100,h_30,co_rgb:00733E,b_rgb:00733E,r_3,g_north_west/l_text:Roboto_20:${
            rating.toString().includes(".") ? rating : rating + ".0"
          },x_185,y_104,r_15,co_rgb:FFFFFF,b_rgb:00733E,g_north_west/l_fetch:aHR0cHM6Ly9zdGFnZXN0YXRpYy5hZ3Jvc3Rhci5pbi9zdGF0aWMvc3Rhci5wbmc,x_217,y_108,w_15,ar_1:1,g_north_west`
        : "";
    title = encodeURIComponent(title);
    title = `c_fit,l_text:Roboto_26_bold:${title},y_400,w_580,h_40,g_north,co_rgb:${
      textOverlayFontColour ? textOverlayFontColour : "000000"
    }`;
    description = encodeURIComponent(description);
    description = description
      ? `c_fit,l_text:Roboto_24:${description},y_500,w_580,h_200,g_north,co_rgb:${
          textOverlayFontColour ? textOverlayFontColour : "000000"
        }`
      : "";
    storeName = this.encodingSpecialCharacterForCloudinary(
      encodeURIComponent(storeName)
    );
    storeName = `c_fit,l_text:Roboto_24_bold:${storeName},g_north_west,x_160,y_735,w_420,h_30`;
    storeMobileNumber = `l_text:Roboto_20:${storeMobileNumber},g_north_west,x_160,y_${top}`;

    return `https://res.cloudinary.com/${accountKey}/image/fetch/${productCardBackground}/${rating}/${image}/${productName}/${title}/${titleAndDescriptionDivider}/${description}/${storeDetailBackground}/${storeImage}/${storeName}${addressString}/${storeMobileNumber}/${url}`;
  }

  static getLines(sentence, letterInLine, maxLine) {
    const words = sentence?.split(" "),
      lines = [];
    let letterCount = 0,
      line = "";
    for (let x = 0; x < words?.length; x++) {
      if (lines.length === maxLine) return lines;

      letterCount += words[x].length;
      if (letterCount > letterInLine) {
        lines.push(line);
        line = words[x] + " ";
        letterCount = words[x].length + 1;
        continue;
      }
      line += words[x] + " ";
      letterCount++;
    }
    if (lines.length !== maxLine) lines.push(line);
    return lines;
  }

  static getPhoneString(source) {
    const message = {
      B2BMP: `फोन नंबर:`,
      B2BUP: `फोन नंबर:`,
      B2BRJ: `फोन नंबर:`,
      B2BMH: `फोन नंबर:`,
      B2BGJ: `ફોન નંબર:`,
      ENGLISH: `Phone No:`,
    };

    return message[source];
  }

  static getFestiveShareText(source, storeName, storeMobileNumber) {
    const message = {
      B2BMP: `आज का दिन आपके जीवन में ढेर सारी खुशियां लेकर आए
${storeName}, ${this.getPhoneString(source)} ${storeMobileNumber}`,
      B2BUP: `आज का दिन आपके जीवन में ढेर सारी खुशियां लेकर आए
${storeName}, ${this.getPhoneString(source)} ${storeMobileNumber}`,
      B2BRJ: `आज का दिन आपके जीवन में ढेर सारी खुशियां लेकर आए
${storeName}, ${this.getPhoneString(source)} ${storeMobileNumber}`,
      B2BMH: `आजचा दिवस तुमच्या आयुष्यात खूप आनंद घेऊन येवो
${storeName}, ${this.getPhoneString(source)} ${storeMobileNumber}`,
      B2BGJ: `આજકા દિવસ તમારા જીવનમાં ઘણી ખુશીઓ લઈને આવે
${storeName}, ${this.getPhoneString(source)} ${storeMobileNumber}`,
      ENGLISH: `May this day bring lots of happiness in your life
${storeName}, ${this.getPhoneString(source)} ${storeMobileNumber}`,
    };

    return message[source];
  }

  static getOfferShareText(source, storeName, storeMobileNumber) {
    const message = {
      B2BMP: `प्रिय किसान, 🙏🙏
${storeName} लाया है आपके लिए एक नया ऑफर💥💥। इस्स ऑफर की अधिक जानकारी के लिए कॉल करें 📞+91 ${storeMobileNumber} या स्टोर विजिट करें।`,
      B2BUP: `प्रिय किसान, 🙏🙏
${storeName} लाया है आपके लिए एक नया ऑफर💥💥। इस्स ऑफर की अधिक जानकारी के लिए कॉल करें 📞+91 ${storeMobileNumber} या स्टोर विजिट करें।`,
      B2BRJ: `प्रिय किसान, 🙏🙏
${storeName} लाया है आपके लिए एक नया ऑफर💥💥। इस्स ऑफर की अधिक जानकारी के लिए कॉल करें 📞+91 ${storeMobileNumber} या स्टोर विजिट करें।`,
      B2BMH: `प्रिय शेतकरी, 🙏🙏
${storeName} ने तुमच्यासाठी आणली आहे एक नवीन ऑफर 💥💥. या ऑफरबद्दल अधिक माहिती जाणून घेण्यासाठी 📞+91 ${storeMobileNumber} वर कॉल करा किंवा स्टोअरला भेट द्या.`,
      B2BGJ: `પ્રિય ખેડૂત, 🙏🙏
${storeName} તમારા માટે લાવવાલી એક નવી ઑફર છે 💥💥. આ ઑફરની વધુ માહિતી માટે કૉલ કરો 📞+91 ${storeMobileNumber} અથવા સ્ટોર વિજિટ કરો.`,
      ENGLISH: `Dear Farmer, 🙏🙏
${storeName} has launched a new offer for you 💥💥. To know more about this offer call 📞+91 ${storeMobileNumber} or visit the store.
`,
    };

    return message[source];
  }

  static getOfferPlaceholders(source) {
    const title = {
      B2BMP: `पावर जेल 500 ग्राम पर बंपर ऑफर`,
      B2BUP: `पावर जेल 500 ग्राम पर बंपर ऑफर`,
      B2BRJ: `पावर जेल 500 ग्राम पर बंपर ऑफर`,
      B2BMH: `पॉवर जेल 500 ग्रॅम वर बंपर ऑफर`,
      B2BGJ: `પાવર જેલ 500 ગ્રામ પર બમ્પર ઓફર`,
    };
    const description = {
      B2BMP: `3 पावर जैल की खरीद पर 100 रुपये की छूट पाएं`,
      B2BUP: `3 पावर जैल की खरीद पर 100 रुपये की छूट पाएं`,
      B2BRJ: `3 पावर जैल की खरीद पर 100 रुपये की छूट पाएं`,
      B2BMH: `3 पॉवर जेलच्या खरेदीवर 100 रुपये सूट मिळवा`,
      B2BGJ: `3 પાવર જેલ ખરીદવા પર 100 રૂપિયાની છૂટ મળે છે`,
    };

    return { title: title[source], description: description[source] };
  }

  static encodingSpecialCharacterForCloudinary(text) {
    return text
      .replaceAll("%2C", "%252C")
      .replaceAll("%2F", "%252F")
      .replaceAll("%26", "%2526")
      .replaceAll("%23", "%2523")
      .replaceAll("%2B", "%252B")
      .replaceAll("%3A", "%352A")
      .replaceAll("%40", "%4520");
  }
}
