import React, { useEffect, useState } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import useProfile from "../../Hooks/useProfile";
import PartnerLoginInProgressDialog from "./PartnerLoginInProgressDialog";
import AnalyticsService from "../../Services/AnalyticsService";
import Clamp from "react-multiline-clamp";
import _ from "lodash";
import AmountUtils from "../../Utils/AmountUtils";

const PartnerCard = (props) => {
  const { saveProfile, loading, errorCode, errorMessage } = useProfile();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (loading === "started" || errorCode !== null) {
      setOpenDialog(true);
    }
  }, [loading, errorCode]);

  const handleClickLoginAsPartner = () => {
    saveProfile(
      props.partner?.referenceCustomerId,
      "/dashboard",
      "Login As Field Agent Successful",
      "Login As Field Agent Failed"
    );
    AnalyticsService.pushEvent("Login As Field Agent Clicked", {
      farmerId: props.partner?.referenceCustomerId,
    });
  };

  return (
    <>
      <Card
        sx={{
          marginTop: 1.5,
          border: "none",
          borderRadius: 2,
          boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1)",
          background:
            props?.partner?.status === "INACTIVE" ? "lightgrey" : "white",
        }}
        variant={`outlined`}
        onClick={handleClickLoginAsPartner}
      >
        <Box sx={{ padding: "6px 12px" }}>
          <Grid container justifyContent="start" alignItems="center">
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <Grid item xs={7}>
                <Typography
                  variant={`body2`}
                  fontSize={13}
                  fontWeight={700}
                  lineHeight={"18px"}
                  sx={{ wordBreak: "break-all" }}
                >
                  <Clamp withTooltip lines={1}>
                    {props.partner?.name}
                  </Clamp>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize="1.5rem"
                  fontWeight={700}
                  marginRight="5px"
                  marginLeft="2px"
                  display={"inline"}
                  lineHeight={0.5}
                  color={
                    props?.showZohoStatus &&
                    props?.partner?.zohoStatus &&
                    props?.partner?.zohoStatus !== ""
                      ? "#B17B07"
                      : props.partner?.status === "INACTIVE"
                        ? "#333333"
                        : props.partner?.isSalesBlocked
                          ? "#9d2123"
                          : "green"
                  }
                >
                  &#x2022;
                </Typography>
                <Typography
                  fontSize="0.7rem"
                  textAlign={"start"}
                  display={"inline"}
                >
                  {props?.showZohoStatus &&
                  props?.partner?.zohoStatus &&
                  props?.partner?.zohoStatus !== "" ? (
                    <font color="#B17B07">{props?.partner?.zohoStatus}</font>
                  ) : props.partner?.status === "INACTIVE" ? (
                    <font color="#333333">Churned</font>
                  ) : props.partner?.isSalesBlocked ? (
                    <font color="#9d2123">OCP Blocked</font>
                  ) : (
                    <font color="green">Open</font>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant={`body2`}
              fontSize={12}
              fontWeight={400}
              lineHeight={"18px"}
            >
              <b>Tal. - </b>
              {props.partner?.taluka
                ? _.capitalize(props.partner.taluka)
                : " "}{" "}
              <br /> <b>Dist. - </b>
              {props.partner?.district
                ? _.capitalize(props.partner.district)
                : " "}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <Grid item xs={6.5}>
                <Typography variant={`body2`} fontSize={12} fontWeight={400}>
                  Ph. No. - {props.partner?.mobileNumber}
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Grid
                  container
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                >
                  {props?.partner?.creditDetails?.totalOCP > 0 && (
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#9D2123",
                      }}
                    >
                      OCP :{" "}
                      {AmountUtils.formatCurrency(
                        props.partner?.creditDetails?.totalOCP
                      )}
                    </Typography>
                  )}
                  <Button
                    variant={`text`}
                    sx={{
                      fontWeight: 700,
                      padding: "0px 0px",
                      justifyContent: "flex-end",
                      minWidth: "0px",
                    }}
                    endIcon={<ArrowForward />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <PartnerLoginInProgressDialog
        loading={loading}
        errorCode={errorCode}
        errorMessage={errorMessage}
        partner={props.partner}
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        // handleCloseDebug={() => setOpenDialog(false)}
      />
    </>
  );
};

export default PartnerCard;
