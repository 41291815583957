import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";

import {
  Grid,
  Typography,
  Box,
  Card,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
} from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import SectionTitle from "../Common/SectionTitle";
import ProductService from "../../Services/ProductService";
import PayAdvanceSchemeProducts from "../../Services/PayAdvanceSchemeProducts";
import PartnerService from "../../Services/PartnerService";

const useStyles = makeStyles((theme) => ({}));

const NON_FEATURED_SCHEMES_ENTITLEMENT_MAP = {
  PERCENTAGE: "% CN",
  PER_UNIT: "CN/Qty",
  ABSOLUTE: "CN",
};

const PricingDetailsContainer = (props) => {
  const classes = useStyles();

  const isPayAdvanceSchemeActive = ProductService.isPayAdvanceSchemeActive(
    props.product?.skuCode
  );

  const getTotalQuantity = () => {
    let freeProductQuantity = 0;
    if (
      props?.appliedOffer?.freeProductQuantity &&
      props?.appliedOffer?.freeProductQuantity > 0
    ) {
      freeProductQuantity = props?.appliedOffer?.freeProductQuantity;
    }
    return parseInt(props?.quantity + freeProductQuantity);
  };

  const getQuantityText = (quantity) => {
    let quantityText = ``;
    if (quantity > 1) {
      quantityText = `${quantity} Units`;
    } else {
      quantityText = `${quantity} Unit`;
    }
    return quantityText;
  };

  const getTotalQuantityText = () => {
    return getQuantityText(getTotalQuantity());
  };

  const getFreeProductQuantityText = () => {
    return getQuantityText(props?.appliedOffer?.freeProductQuantity);
  };

  const getQuantityAndFreeSumText = () => {
    if (isPayAdvanceSchemeActive) {
      if (props?.appliedOffer?.freeProductQuantity) {
        return `${props?.quantity} + ${
          props?.appliedOffer?.freeProductQuantity
        } ${
          PayAdvanceSchemeProducts?.[props?.product?.skuCode]
            ?.schemeUnitMultiple
        }`;
      } else {
        return `${props?.quantity} ${
          props?.quantity > 1
            ? PayAdvanceSchemeProducts?.[props?.product?.skuCode]
                ?.schemeUnitMultiple
            : PayAdvanceSchemeProducts?.[props?.product?.skuCode]?.schemeUnit
        }`;
      }
    } else {
      if (props?.appliedOffer?.freeProductQuantity) {
        return `${props?.quantity} + ${props?.appliedOffer?.freeProductQuantity} Units`;
      } else {
        return `${props?.quantity} Units`;
      }
    }
  };

  const checkWhetherToShowPricingDetails = () => {
    let whetherToShow = false;
    if (props.appliedCashDiscountBenefit && checkWhetherToShowCashDiscount()) {
      whetherToShow = true;
    }
    if (props?.turnoverScheme && props?.turnOverSchemeBenefits) {
      whetherToShow = true;
    }
    if (props.appliedOffer) {
      whetherToShow = true;
    }
    if (isPayAdvanceSchemeActive) {
      whetherToShow = true;
    }
    return whetherToShow;
  };

  const checkWhetherToShowCashDiscount = () => {
    let showFlag = true;
    if (props.appliedOffer?.offerId) {
      if (props?.cashDiscountBenefits?.[props.appliedOffer.offerId]) {
        if (
          props.cashDiscountBenefits[props.appliedOffer.offerId].length === 1 &&
          props.cashDiscountBenefits[props.appliedOffer.offerId][0]
            ?.benefitAmount === 0
        ) {
          showFlag = false;
        }
      }
    } else {
      if (props?.cashDiscountBenefits?.["no-offer"]) {
        if (
          props.cashDiscountBenefits["no-offer"].length === 1 &&
          props.cashDiscountBenefits["no-offer"][0]?.benefitAmount === 0
        ) {
          showFlag = false;
        }
      }
    }

    return showFlag;
  };

  const generateNonFeaturedSchemeEntitlementDescription = (
    entitlementValue,
    entitlementType
  ) => {
    if (entitlementType === "ABSOLUTE" || entitlementType === "PER_UNIT") {
      return `${`₹${new Intl.NumberFormat("en-IN").format(
        entitlementValue
      )}`}  ${NON_FEATURED_SCHEMES_ENTITLEMENT_MAP[entitlementType]}`;
    } else if (entitlementType === "PERCENTAGE") {
      return `${entitlementValue} ${NON_FEATURED_SCHEMES_ENTITLEMENT_MAP[entitlementType]}`;
    } else {
      return ``;
    }
  };

  return (
    <ErrorBoundary message={``}>
      <>
        {checkWhetherToShowPricingDetails() && (
          <Container maxWidth={`xl`} sx={{ marginTop: "30px" }}>
            <SectionTitle title={`Pricing Details`} />
            {props.offersLoading !== "started" ? (
              <Card
                sx={{
                  marginTop: "10px",
                  boxShadow: "0px 10px 10px #E0E0E0",
                  borderRadius: "10px",
                  filter: PartnerService.applySmudgedPriceFilter(
                    props?.product
                  ),
                }}
              >
                <>
                  <Box sx={{ background: "rgba(0, 115, 62, 0.05)" }}>
                    <Grid container>
                      <Grid
                        item
                        xs={5}
                        sx={{ background: "#FFFFFF", padding: "12px" }}
                      >
                        &nbsp;
                      </Grid>
                      <Grid
                        item
                        xs={3.5}
                        sx={{
                          borderRight: "1px solid #FFFFFF",
                          padding: "6px 8px",
                        }}
                      >
                        {isPayAdvanceSchemeActive ? (
                          <>
                            <Typography
                              align={`center`}
                              sx={{
                                fontSize: "12px",
                                fontWeight: "700",
                                color: "#444444",
                                opacity: "0.5",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                textTransform: "capitalize",
                              }}
                            >
                              Per{" "}
                              {PayAdvanceSchemeProducts?.[
                                props?.product?.skuCode
                              ]?.schemeUnit
                                ? PayAdvanceSchemeProducts?.[
                                    props?.product?.skuCode
                                  ]?.schemeUnit
                                : "Unit"}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            align={`center`}
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "#444444",
                              opacity: "0.5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            Per Unit
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={3.5} sx={{ padding: "6px 8px" }}>
                        <Typography
                          align={`right`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "700",
                            color: "#444444",
                            opacity: "0.5",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          Total
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {isPayAdvanceSchemeActive ? (
                    <>
                      <Box sx={{ background: "rgba(0, 115, 62, 0.05)" }}>
                        <Grid container>
                          <Grid
                            item
                            xs={5}
                            sx={{
                              background: "#FFFFFF",
                              padding: "6px 4px 6px 8px",
                            }}
                          >
                            <Typography
                              variant={`body2`}
                              align={`left`}
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#333333",
                              }}
                            >
                              Advance Booking Amount{" "}
                              {PayAdvanceSchemeProducts?.[
                                props?.product?.skuCode
                              ]?.schemeUnit !== "" ? (
                                <Typography
                                  variant={`body2`}
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#666666",
                                    filter:
                                      PartnerService.applySmudgedPriceFilter(
                                        props?.product
                                      ),
                                  }}
                                >
                                  ({getQuantityAndFreeSumText()})
                                </Typography>
                              ) : (
                                <></>
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.5}
                            align={`center`}
                            sx={{
                              borderRight: "1px solid #FFFFFF",
                              padding: "6px 8px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#333333",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                              }}
                            >
                              ₹
                              {PayAdvanceSchemeProducts?.[
                                props?.product?.skuCode
                              ]?.bookingPricePerUnit
                                ? new Intl.NumberFormat("en-IN").format(
                                    PayAdvanceSchemeProducts?.[
                                      props?.product?.skuCode
                                    ]?.bookingPricePerUnit
                                  )
                                : ``}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.5}
                            align={`right`}
                            sx={{ padding: "6px 8px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#333333",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                height: "100%",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                              }}
                            >
                              ₹
                              {new Intl.NumberFormat("en-IN").format(
                                PayAdvanceSchemeProducts?.[
                                  props?.product?.skuCode
                                ]?.bookingPricePerUnit * props?.quantity
                              )}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent={`center`}
                          alignItems={`center`}
                          sx={{ paddingBottom: "10px" }}
                        >
                          <Grid
                            item
                            xs={9}
                            align={`left`}
                            sx={{ padding: "6px 4px 6px 8px" }}
                          >
                            <Typography
                              align={`left`}
                              variant={`body2`}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#00733E",
                                // marginLeft: "15px",
                              }}
                            >
                              Advance Amount Payable {/*<Typography*/}
                              {/*  variant={`body2`}*/}
                              {/*  sx={{*/}
                              {/*    fontSize: "16px",*/}
                              {/*    fontWeight: "400",*/}
                              {/*  }}*/}
                              {/*>*/}
                              {/*  {getTotalQuantityText()*/}
                              {/*    ? `(${getTotalQuantityText()})`*/}
                              {/*    : ``}*/}
                              {/*</Typography>*/}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sx={{ padding: "6px 4px 6px 4px" }}>
                            <Typography
                              align={`right`}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#00733E",
                                marginRight: "4px",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                                // marginLeft: "15px",
                              }}
                            >
                              ₹
                              {new Intl.NumberFormat("en-IN").format(
                                PayAdvanceSchemeProducts?.[
                                  props?.product?.skuCode
                                ]?.bookingPricePerUnit * props.quantity
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box sx={{ background: "rgba(0, 115, 62, 0.05)" }}>
                        <Grid container>
                          <Grid
                            item
                            xs={5}
                            sx={{
                              background: "#FFFFFF",
                              padding: "6px 4px 6px 8px",
                            }}
                          >
                            <Typography
                              variant={`body2`}
                              align={`left`}
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#333333",
                              }}
                            >
                              Selling Price{" "}
                              <Typography
                                variant={`body2`}
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#666666",
                                }}
                              >
                                ({getQuantityAndFreeSumText()})
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.5}
                            align={`center`}
                            sx={{
                              borderRight: "1px solid #FFFFFF",
                              padding: "6px 8px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#333333",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                              }}
                            >
                              ₹
                              {props?.product?.sellingPrice
                                ? new Intl.NumberFormat("en-IN").format(
                                    props?.product?.sellingPrice
                                  )
                                : ``}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.5}
                            align={`right`}
                            sx={{ padding: "6px 8px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#333333",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                height: "100%",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                              }}
                            >
                              ₹
                              {props?.appliedOffer
                                ? new Intl.NumberFormat("en-IN").format(
                                    props?.appliedOffer?.totalPrice
                                  )
                                : new Intl.NumberFormat("en-IN").format(
                                    props?.product?.totalPrice
                                  )}
                            </Typography>
                          </Grid>
                        </Grid>

                        {props?.appliedOffer && (
                          <Grid
                            container
                            sx={{
                              borderTop: "dashed",
                              borderColor: "rgba(0,0,0,0.3)",
                            }}
                          >
                            <Grid
                              item
                              xs={5}
                              align={`left`}
                              sx={{
                                background: "#FFFFFF",
                                padding: "6px 4px 6px 8px",
                              }}
                            >
                              <Typography
                                variant={`body2`}
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#333333",
                                }}
                              >
                                Offer Benefit
                                <Typography
                                  variant={`body2`}
                                  sx={{
                                    color: "#666666",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {props?.appliedOffer?.freeProductQuantity
                                    ? ` (${getFreeProductQuantityText()})`
                                    : ``}
                                </Typography>
                              </Typography>
                              <Typography
                                variant={`body2`}
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#00733E",
                                  wordBreak: "break-all",
                                }}
                              >
                                {props?.appliedOffer?.offerName}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3.5}
                              align={`center`}
                              sx={{
                                borderRight: "1px solid #FFFFFF",
                                padding: "6px 8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#9D2123",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  filter:
                                    PartnerService.applySmudgedPriceFilter(
                                      props?.product
                                    ),
                                }}
                              >
                                - ₹
                                {props.appliedOffer?.perUnitOfferBenefit
                                  ? new Intl.NumberFormat("en-IN").format(
                                      props.appliedOffer?.perUnitOfferBenefit
                                    )
                                  : 0}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3.5}
                              align={`right`}
                              sx={{ padding: "6px 8px" }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#9D2123",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  height: "100%",
                                  filter:
                                    PartnerService.applySmudgedPriceFilter(
                                      props?.product
                                    ),
                                }}
                              >
                                - ₹
                                {props.appliedOffer?.offerBenefit
                                  ? new Intl.NumberFormat("en-IN").format(
                                      props.appliedOffer?.offerBenefit
                                    )
                                  : 0}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}

                        {props?.turnoverScheme?.highestSlabEntlemt
                          ?.entlemtType !== "VOUCHER" &&
                          props?.turnOverSchemeBenefits &&
                          props?.turnoverScheme && (
                            <Grid
                              container
                              sx={{
                                borderTop: "dashed",
                                borderColor: "rgba(0,0,0,0.3)",
                              }}
                            >
                              <Grid
                                item
                                xs={5}
                                align={`left`}
                                sx={{
                                  background: "#FFFFFF",
                                  padding: "6px 4px 6px 8px",
                                }}
                              >
                                <Typography
                                  variant={`body2`}
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#333333",
                                  }}
                                >
                                  Scheme Benefit
                                </Typography>
                                <Typography
                                  variant={`body2`}
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "#00733E",
                                  }}
                                >
                                  {generateNonFeaturedSchemeEntitlementDescription(
                                    props?.turnOverSchemeBenefits
                                      ?.turnOverBenefitRate,
                                    props?.turnOverSchemeBenefits
                                      ?.turnOverBenefitRateType
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3.5}
                                align={`center`}
                                sx={{
                                  borderRight: "1px solid #FFFFFF",
                                  padding: "6px 8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#9D2123",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    filter:
                                      PartnerService.applySmudgedPriceFilter(
                                        props?.product
                                      ),
                                  }}
                                >
                                  - ₹
                                  {props?.turnOverSchemeBenefits
                                    ?.perUnitSchemeBenefit
                                    ? new Intl.NumberFormat("en-IN").format(
                                        ProductService.roundFloatsToNdecimalPlaces(
                                          props?.turnOverSchemeBenefits
                                            ?.perUnitSchemeBenefit,
                                          2
                                        )
                                      )
                                    : 0}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3.5}
                                align={`right`}
                                sx={{ padding: "6px 8px" }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#9D2123",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    height: "100%",
                                    filter:
                                      PartnerService.applySmudgedPriceFilter(
                                        props?.product
                                      ),
                                  }}
                                >
                                  - ₹
                                  {props?.turnOverSchemeBenefits
                                    ?.totalSchemeBenefit
                                    ? new Intl.NumberFormat("en-IN").format(
                                        Math.round(
                                          props?.turnOverSchemeBenefits
                                            ?.totalSchemeBenefit
                                        )
                                      )
                                    : 0}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}

                        {checkWhetherToShowCashDiscount() &&
                          props?.appliedCashDiscountBenefit && (
                            <Grid
                              container
                              sx={{
                                borderTop: "dashed",
                                borderColor: "rgba(0,0,0,0.3)",
                              }}
                            >
                              <Grid
                                item
                                xs={5}
                                align={`left`}
                                sx={{
                                  background: "#FFFFFF",
                                  padding: "6px 4px 6px 8px",
                                  fontSize: "12px",
                                }}
                              >
                                <>
                                  {_.includes(
                                    props?.appliedCashDiscountBenefit
                                      ?.benefitText,
                                    `-`
                                  ) ? (
                                    <Typography
                                      variant={`body2`}
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#9D2123",
                                      }}
                                    >
                                      Interest
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant={`body2`}
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#333333",
                                      }}
                                    >
                                      Cash Discount
                                    </Typography>
                                  )}
                                </>
                                <>
                                  {
                                    props?.appliedCashDiscountBenefit
                                      ?.paymentPeriod
                                  }
                                  {_.includes(
                                    props?.appliedCashDiscountBenefit
                                      ?.benefitText,
                                    `-`
                                  ) ? (
                                    <Typography
                                      variant={`caption`}
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#9D2123",
                                      }}
                                    >
                                      &nbsp;(+
                                      {
                                        props?.appliedCashDiscountBenefit?.benefitText.split(
                                          `-`
                                        )?.[1]
                                      }
                                      )
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant={`caption`}
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#00733E",
                                      }}
                                    >
                                      (
                                      {
                                        props?.appliedCashDiscountBenefit
                                          ?.benefitText
                                      }
                                      )
                                    </Typography>
                                  )}
                                </>
                              </Grid>
                              <Grid
                                item
                                xs={3.5}
                                align={`center`}
                                sx={{
                                  borderRight: "1px solid #FFFFFF",
                                  padding: "6px 8px",
                                }}
                              >
                                {_.includes(
                                  _.toString(
                                    props?.appliedCashDiscountBenefit
                                      ?.perUnitCalculatedBenefitAmount
                                  ),
                                  `-`
                                ) ? (
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      color: "#9D2123",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      filter:
                                        PartnerService.applySmudgedPriceFilter(
                                          props?.product
                                        ),
                                    }}
                                  >
                                    {" "}
                                    + ₹
                                    {
                                      _.toString(
                                        new Intl.NumberFormat("en-IN").format(
                                          ProductService.roundFloatsToNdecimalPlaces(
                                            props?.appliedCashDiscountBenefit
                                              ?.perUnitCalculatedBenefitAmount,
                                            2
                                          )
                                        )
                                      ).split(`-`)?.[1]
                                    }
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#9D2123",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      filter:
                                        PartnerService.applySmudgedPriceFilter(
                                          props?.product
                                        ),
                                    }}
                                  >
                                    {" "}
                                    - ₹
                                    {new Intl.NumberFormat("en-IN").format(
                                      ProductService.roundFloatsToNdecimalPlaces(
                                        props?.appliedCashDiscountBenefit
                                          ?.perUnitCalculatedBenefitAmount,
                                        2
                                      )
                                    )}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={3.5}
                                align={`right`}
                                sx={{ padding: "6px 8px" }}
                              >
                                {_.includes(
                                  _.toString(
                                    props?.appliedCashDiscountBenefit
                                      ?.totalCalculatedBenefitAmount
                                  ),
                                  `-`
                                ) ? (
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      color: "#9D2123",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                      height: "100%",
                                      filter:
                                        PartnerService.applySmudgedPriceFilter(
                                          props?.product
                                        ),
                                    }}
                                  >
                                    {" "}
                                    + ₹
                                    {
                                      _.toString(
                                        new Intl.NumberFormat("en-IN").format(
                                          Math.round(
                                            props?.appliedCashDiscountBenefit
                                              ?.totalCalculatedBenefitAmount
                                          )
                                        )
                                      ).split(`-`)?.[1]
                                    }
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#9D2123",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                      height: "100%",
                                      filter:
                                        PartnerService.applySmudgedPriceFilter(
                                          props?.product
                                        ),
                                    }}
                                  >
                                    {" "}
                                    - ₹
                                    {new Intl.NumberFormat("en-IN").format(
                                      Math.round(
                                        props?.appliedCashDiscountBenefit
                                          ?.totalCalculatedBenefitAmount
                                      )
                                    )}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          )}
                      </Box>

                      <Box sx={{ background: "rgba(0, 115, 62, 0.1)" }}>
                        <Grid
                          container
                          justifyContent={`center`}
                          alignItems={`center`}
                          sx={{ paddingBottom: "10px" }}
                        >
                          <Grid
                            item
                            xs={5}
                            align={`left`}
                            sx={{ padding: "6px 4px 6px 8px" }}
                          >
                            <Typography
                              align={`left`}
                              variant={`body2`}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#00733E",
                                // marginLeft: "15px",
                              }}
                            >
                              Net Amount{" "}
                              <Typography
                                variant={`body2`}
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                {getTotalQuantityText()
                                  ? `(${getTotalQuantityText()})`
                                  : ``}
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.5}
                            sx={{ padding: "6px 4px 6px 4px" }}
                          >
                            <Typography
                              align={`center`}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#00733E",
                                marginRight: "4px",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                                // marginLeft: "15px",
                              }}
                            >
                              ₹
                              {props?.appliedCashDiscountBenefit ? (
                                <>
                                  {new Intl.NumberFormat("en-IN").format(
                                    ProductService.roundFloatsToNdecimalPlaces(
                                      props?.appliedCashDiscountBenefit
                                        ?.effectiveOfferPrice,
                                      2
                                    )
                                  )}
                                </>
                              ) : props?.turnOverSchemeBenefits ? (
                                <>
                                  {props?.turnOverSchemeBenefits
                                    ?.perUnitNetEffectivePrice
                                    ? new Intl.NumberFormat("en-IN").format(
                                        ProductService.roundFloatsToNdecimalPlaces(
                                          props?.turnOverSchemeBenefits
                                            ?.perUnitNetEffectivePrice,
                                          2
                                        )
                                      )
                                    : ``}
                                </>
                              ) : (
                                <>
                                  {props?.appliedOffer ? (
                                    <>
                                      {new Intl.NumberFormat("en-IN").format(
                                        ProductService.roundFloatsToNdecimalPlaces(
                                          props?.appliedOffer
                                            ?.netEffectivePrice,
                                          2
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.5}
                            align={`right`}
                            sx={{ padding: "6px 4px 6px 4px" }}
                          >
                            <Typography
                              align={`right`}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#00733E",
                                marginRight: "4px",
                                filter: PartnerService.applySmudgedPriceFilter(
                                  props?.product
                                ),
                                // marginRight: "15px",
                              }}
                            >
                              ₹
                              {props?.appliedCashDiscountBenefit ? (
                                <>
                                  {new Intl.NumberFormat("en-IN").format(
                                    Math.round(
                                      props?.appliedCashDiscountBenefit
                                        ?.effectiveTotalNetPrice
                                    )
                                  )}
                                </>
                              ) : props?.turnOverSchemeBenefits ? (
                                <>
                                  {props?.turnOverSchemeBenefits?.totalNetPrice
                                    ? new Intl.NumberFormat("en-IN").format(
                                        Math.round(
                                          props?.turnOverSchemeBenefits
                                            ?.totalNetPrice
                                        )
                                      )
                                    : ``}
                                </>
                              ) : (
                                <>
                                  {props?.appliedOffer ? (
                                    <>
                                      {new Intl.NumberFormat("en-IN").format(
                                        Math.round(
                                          props?.appliedOffer
                                            ?.totalNetEffectivePrice
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                </>
              </Card>
            ) : (
              <>
                <Skeleton variant={`rectangular`} height={200}></Skeleton>
              </>
            )}
          </Container>
        )}
      </>
    </ErrorBoundary>
  );
};

export default PricingDetailsContainer;
