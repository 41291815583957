import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import ReactInstaStories from "react-insta-stories";
import VideoRenderer from "./StoryVideoRenderer";
import WeatherRenderer from "./WeatherRenderer";

import {
  StoryFooterElement,
  StoryFooterComponentExpanded,
} from "./StoryFooter";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import QuizRenderer from "./QuizRenderer";

const useStyles = makeStyles((theme) => ({
  buttonsDiv: {
    float: "right",
    position: "relative",
    top: 24,
    right: 14,
    display: "flex",
    gap: 10,
  },
  buttonImage: {
    cursor: "pointer",
  },
  globalHeader: {
    width: "100vw",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9999,
  },
}));

const Stories = ({
  stories,
  currentIndex,
  nextStory,
  onCloseClick,
  onStoriesStart,
  onStoriesEnd,
  onAllStoriesEnd,
  onFinish,
  onStoryNavigated,
  storiesDurations,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const currentStoryIndexFromQuery = query.get("storyId");

  const storyWrapperReference = useRef(null);
  const [startTimes, setStartTimes] = useState(
    _.times(stories.length, () => null)
  );
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [currentStoryToPlay, setCurrentStoryToPlay] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const params = useParams();

  stories = _.map(stories, (story) => {
    const seeMoreCollapsed = StoryFooterElement(navigate, story, setIsPaused);
    const seeMore = StoryFooterComponentExpanded();
    return { ...story, seeMoreCollapsed, seeMore };
  });

  useEffect(() => {
    if (storyWrapperReference?.current) {
      const observer = new IntersectionObserver(
        function (entries) {
          _.forEach(entries, (entry, _) => {
            if (entry?.isIntersecting) {
              setIsPaused(false);
            } else {
              setIsPaused(true);
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }
      );
      observer.observe(storyWrapperReference.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [storyWrapperReference]);

  const handleOnAllStoriesEnd = () => {
    onAllStoriesEnd();
    if (!nextStory) {
      console.log(onFinish);
      onFinish();
    } else {
      document
        .getElementById("mahaul" + nextStory[0].storyParentCode)
        .scrollIntoView();
    }
  };

  const handleOnStoryEnd = (index, story) => {
    // let duration = storiesDurations[`${story.storyId}`] || 5;
    // if (story.type === "quiz") duration = 20;
    // duration = duration * 1000 - 500;
    // console.log(index, startTimes[index] + "," + duration);
    // if (startTimes[index] && Date.now() - startTimes[index] > duration) {
    //   onStoriesEnd(story, index);
    // }
    onStoriesEnd(story, index);
  };

  const handleOnStoryStart = (index, story) => {
    if (
      params.storyId === story.storyParentCode &&
      currentStoryIndexFromQuery &&
      currentStoryToPlay == null
    ) {
      setCurrentStoryToPlay(parseInt(story.subPosition));
    }
    setCurrentStoryIndex(index);
    let currentTimes = startTimes;
    currentTimes[index] = Date.now();
    setStartTimes(currentTimes);
    if (params.storyId === story.storyParentCode) {
      onStoriesStart(story);
    }
  };

  const handleOnNext = () => {
    onStoryNavigated(stories[currentStoryIndex], true);
  };

  const handleOnPrevious = () => {
    onStoryNavigated(stories[currentStoryIndex], false);
  };

  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 99999,
      }}
      ref={storyWrapperReference}
    >
      <Box className={classes.globalHeader}>
        <Box className={classes.buttonsDiv}>
          <Box onClick={() => onCloseClick(stories[currentStoryIndex])}>
            <img
              src="/icons/whiteCross.svg"
              alt="close"
              color="white"
              height="18px"
              className={classes.buttonImage}
            />
          </Box>
        </Box>
      </Box>
      <ReactInstaStories
        renderers={[VideoRenderer, WeatherRenderer, QuizRenderer]}
        stories={stories}
        defaultInterval={5000}
        width={window.innerWidth}
        height={window.innerHeight}
        storyStyles={{ width: window.innerWidth }}
        currentIndex={currentStoryToPlay || 0}
        progressContainerStyles={{
          width: "100%",
          padding: 12,
          paddingTop: 7,
        }}
        isPaused={isPaused}
        onPrevious={handleOnPrevious}
        onNext={handleOnNext}
        onStoryStart={handleOnStoryStart}
        onStoryEnd={handleOnStoryEnd}
        onAllStoriesEnd={handleOnAllStoriesEnd}
        storyContainerStyles={{
          background: `linear-gradient(0deg, #0000006b 0%, #d9d9d900 115.99%)`,
        }}
      />
    </Box>
  );
};

export default Stories;
