import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Card, Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  promoCard: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1),
    paddingBottom: 0,
    border: "1px solid white",
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    flexWrap: "nowrap",
    background: "white",
    position: "relative",
  },
  promoCardApplied: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1),
    paddingBottom: 0,
    border: "1px solid white",
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    flexWrap: "nowrap",
    background: "#E5F1EC",
    position: "relative",
  },
  offerName: {
    fontWeight: 400,
    display: "flex",
    fontSize: "14px",
    color: "#00733E",
    alignItems: "center",
    lineHeight: "19px",
  },
  offerIcon: {
    marginRight: theme.spacing(1),
  },
  offerAvailableSpaced: {
    color: "#666666",
    padding: theme.spacing(0.5, 0),
    marginRight: theme.spacing(3),
  },
  flexBox: {
    flex: "1",
    display: "flex",
    minHeight: "50px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "12px 0px",
    marginLeft: "10px",
  },
  applyButton: {
    borderRadius: "20px",
    background: "#9D2123",
    fontSize: "14px",
    fontWeight: "700",
    padding: "2px 0px",
    textTransform: "none",
    alignSelf: "center",
  },
  removeButton: {
    borderRadius: "20px",
    backgroundColor: "white",
    fontSize: "14px",
    fontWeight: "700",
    padding: "2px 8px",
    textTransform: "none",
    alignSelf: "center",
    border: "1px solid #9D2123",
  },
  descriptionFlexBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const PromoCard = ({
  promoCodeDetails,
  isApplied,
  handleApplyOnPromoClicked,
  handleRemoveOnPromoClicked,
}) => {
  const classes = useStyles();
  const promoCardClass = isApplied
    ? classes.promoCardApplied
    : classes.promoCard;

  return (
    <Card className={promoCardClass} variant="outlined">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        sx={{ position: "relative" }}
      >
        <Box className={classes.flexBox}>
          <Box className={classes.descriptionFlexBox}>
            <Typography
              variant={"caption"}
              className={classes.offerAvailableSpaced}
            >
              {promoCodeDetails?.description}
            </Typography>

            {isApplied ? (
              <Button
                variant="outlined"
                className={classes.removeButton}
                onClick={() => {
                  handleRemoveOnPromoClicked(promoCodeDetails?.voucherCode);
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.applyButton}
                onClick={() => {
                  handleApplyOnPromoClicked(promoCodeDetails?.voucherCode);
                }}
              >
                Apply
              </Button>
            )}
          </Box>
          {isApplied && (
            <Typography className={classes.offerName}>
              <img
                src={`/icons/offersGreenIcon.svg`}
                alt=""
                className={classes.offerIcon}
              />
              Promo Applied
            </Typography>
          )}
        </Box>
      </Grid>
    </Card>
  );
};

export default PromoCard;
