import { Button, Grid, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import OrderService from "../../Services/OrderService";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import UiSearch from "../Common/UiSearch";
import ComplaintProductList from "./ComplaintProductList";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import EmptyError from "../EmptyError/EmptyError";
import ProductSelectedToast from "./ProductSelectedToast";
import Languages from "../../Services/LanguageCode";
import TicketService from "../../Services/TicketService";
import AnalyticsService from "../../Services/AnalyticsService";
import ProductRemovedToast from "./ProductRemovedToast";
import useMessages from "../../Hooks/useMessages";

function SelectComplaintProducts() {
  const navigate = useNavigate();
  const { getMessage } = useMessages();
  const [loading, setLoading] = useState("not-started");
  const [searchValue, setSearchValue] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [showProductSelectedToast, setShowProductSelectedToast] =
    useState(false);
  const [showProductRemovedToast, setShowProductRemovedToast] = useState(false);
  const [productIssueReasons, setProductIssuseReasons] = useState([]);
  const productLevelIssueNameEN = "Product Related Issues";
  const pageName = "Select Complaint Products";

  const handleSelectProduct = (selectedProduct) => {
    AnalyticsService.pushEvent("Button Clicked", {
      page: pageName,
      type: "Add Product",
      skuCode: selectedProduct.skuCode,
    });
    const updatedSelectedProducts = _.uniqBy(
      [selectedProduct, ...selectedProducts],
      "skuCode"
    );
    setSelectedProducts(updatedSelectedProducts);
    const quantity = _.sumBy(updatedSelectedProducts, "quantity");

    setSelectedQuantity(quantity);
    setShowProductSelectedToast(true);
  };

  const handleProceedClick = () => {
    if (selectedProducts.length === 0) {
      return;
    }
    AnalyticsService.pushEvent("Button Clicked", {
      page: pageName,
      type: "Proceed",
    });
    localStorage.setItem("complaintProducts", JSON.stringify(selectedProducts));
    navigate(-1);
  };

  const handleRemoveProduct = (product) => {
    AnalyticsService.pushEvent("Button Clicked", {
      page: pageName,
      type: "Remove Product",
      skuCode: product.sku_code,
    });
    const newSelectedProducts = _.filter(
      selectedProducts,
      (selectedProduct) => product.sku_code !== selectedProduct.skuCode
    );
    setSelectedProducts(newSelectedProducts);
    const quantity = _.sumBy(newSelectedProducts, "quantity");

    setSelectedQuantity(quantity);
    setShowProductRemovedToast(true);
  };

  useEffect(() => {
    setLoading("started");
    const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
      ? Languages[localStorage.getItem("state")?.toUpperCase()]
      : "en";
    const promises = [];
    const {
      axiosRequest: axiosRequestOrders,
      abortController: abortControllerOrders,
    } = OrderService.getRecentOrders(180, "PRODUCT");
    const { axiosRequest, abortController } =
      TicketService.getComplaintReasonsAndIssues(languageCode);
    promises.push(axiosRequestOrders);
    promises.push(axiosRequest);
    Promise.all(promises)
      .then(([recentOrdersResponse, complaintReasonsAndIssuesResponse]) => {
        if (recentOrdersResponse?.data?.responseData?.products.length) {
          setProducts(recentOrdersResponse.data.responseData.products);
        }
        if (
          complaintReasonsAndIssuesResponse?.data?.responseData
            ?.profileLevelReasons?.length
        ) {
          const issues =
            complaintReasonsAndIssuesResponse?.data.responseData
              ?.profileLevelReasons;
          const productLevelIssue = _.find(
            issues,
            (issue) => issue.issueNameEN === productLevelIssueNameEN
          );
          if (productLevelIssue?.reasons?.length) {
            setProductIssuseReasons(productLevelIssue.reasons);
          }
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(error);
          setLoading("done");
        }
      });
    return () => {
      abortController.abort();
      abortControllerOrders.abort();
    };
  }, []);

  useEffect(() => {
    const newSelectedProducts =
      JSON.parse(localStorage.getItem(`complaintProducts`)) || [];
    setSelectedProducts(newSelectedProducts);
    setSelectedQuantity(_.sumBy(newSelectedProducts, "quantity"));
  }, []);

  return (
    <BlockUi
      blocking={loading === "started"}
      message={`Please wait`}
      sx={{
        background: "e5e5e5",
      }}
      style={{ minHeight: "100vh" }}
    >
      <ErrorBoundary message={``}>
        <TopBar
          title={`Select Products`}
          backNavigation={true}
          showCart={false}
          showHisaab={true}
        />
      </ErrorBoundary>

      <ErrorBoundary message={``}>
        <UiSearch setSearchValue={setSearchValue} searchValue={searchValue} />
      </ErrorBoundary>

      {products?.length > 0 && productIssueReasons.length > 0 && (
        <ComplaintProductList
          products={products}
          handleSelectProduct={handleSelectProduct}
          handleRemoveProduct={handleRemoveProduct}
          selectedProducts={selectedProducts}
          searchValue={searchValue}
          issueReasons={productIssueReasons}
        />
      )}

      {loading === "done" && products.length === 0 && (
        <EmptyError
          image="EmptyPage.svg"
          text="Sorry! No Products found"
          hideBack={true}
        />
      )}

      {loading === "done" && products.length === 0 && (
        <EmptyError
          image="EmptyPage.svg"
          text="Sorry! No Products found"
          hideBack={true}
        />
      )}

      <Box
        sx={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          zIndex: "1",
          maxWidth: "600px",
        }}
      >
        {selectedProducts.length === 0 && (
          <Typography
            color={"primary"}
            fontWeight={"bold"}
            sx={{
              fontSize: "14px",
              textAlign: "center",
              padding: "4px 2px",
              background: "#EBD2D3",
            }}
          >
            {getMessage("createComplaint.selectReasonHeader")}
          </Typography>
        )}
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "51px",
            background: selectedProducts.length === 0 ? "#d9b5b5" : "#9D2123",
          }}
          onClick={handleProceedClick}
        >
          <Grid
            item
            xs={2.3}
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "#FFFFFF",
              flexDirection: "column",
              paddingLeft: "10px",
              marginTop: "-5px",
              textAlign: "center",
            }}
            variant={"contained"}
            color={"primary"}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "19.07px",
                fontWeight: "bold",
              }}
            >
              {selectedProducts.length ? selectedProducts.length : 0}
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "12px", lineHeight: "19.07px" }}
            >
              Product
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "#FFFFFF",
              flexDirection: "column",
              paddingLeft: "10px",
              marginTop: "-5px",
              textAlign: "center",
            }}
            variant={"contained"}
            color={"primary"}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "19.07px",
                fontWeight: "bold",
              }}
            >
              {selectedQuantity}
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{ fontSize: "12px", lineHeight: "19.07px" }}
            >
              Quantity
            </Typography>
          </Grid>
          <Grid
            item
            xs={6.5}
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              padding: "10px",
            }}
          >
            <Button
              sx={{
                height: "100%",
                color: "#FFFFFF",
                fontWeight: "700",
                fontSize: "14px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  alignItems: "center",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Proceed
              </Typography>
              <ArrowForwardTwoToneIcon />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ProductSelectedToast
        show={showProductSelectedToast}
        setShow={setShowProductSelectedToast}
      />
      <ProductRemovedToast
        show={showProductRemovedToast}
        setShow={setShowProductRemovedToast}
      />
    </BlockUi>
  );
}

export default SelectComplaintProducts;
