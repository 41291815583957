import { useEffect, useRef, useState } from "react";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import DocumentService from "../../Services/DocumentService";
import _ from "lodash";
import ErrorBoundary from "../Common/ErrorBoundary";
import ErrorIcon from "@mui/icons-material/Error";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";
import UploadDocument from "./UploadDocument";
import Backdrop from "@mui/material/Backdrop";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const UploadDocumentGroup = ({
  documentTitle,
  documentSubtitle,
  documentAllowedExtensions,
  imageFileTypeExtensions,
  documentFileTypeExtensions,
  documentIdentifier,
  uploadedFiles,
  setUploadedFiles,
  documentTemplate,
  documentGroup,
  setDocumentGroup,
  groupTitle,
  uploadType,
}) => {
  var isFileUploadEnabled = true;
  if (AndroidService.checkIfAndroid()) {
    if (AndroidService.getAndroidVersion()) {
      let { appVersionCode } = AndroidService.getAndroidVersion();
      if (appVersionCode && parseInt(appVersionCode) < 20) {
        isFileUploadEnabled = false;
      }
    }
  }

  var docIdentifierForNextUpload = `${documentTemplate?.identifier}_${
    documentGroup?.length + 1
  }`;

  const attachedFiles = _.filter(uploadedFiles, (uploadedFile) =>
    _.startsWith(uploadedFile?.type, documentTemplate?.identifier)
  );

  const [uploadingFile, setUploadingFile] = useState("not-started");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [openUploadDrawer, setOpenUploadDrawer] = useState(false);
  const imagePond = useRef(null);
  const pdfPond = useRef(null);

  useEffect(() => {
    if (showError === true && errorMessage) {
      AnalyticsService.pushEvent("Dialog Shown", {
        type: showError ? "Failure" : "",
        message: errorMessage,
        page: "Upload Documents",
        errorCode: errorCode || "",
      });
    }
  }, [showError, errorMessage, errorCode]);

  const openImageFilePondDialog = () => {
    if (imagePond.current) {
      imagePond.current.browse();
    }
  };
  const openPdfFilePondDialog = () => {
    if (pdfPond.current) {
      pdfPond.current.browse();
    }
  };

  const handleRemoveFile = () => {};

  const handleAddFile = (error, file) => {
    if (error) {
      setShowError(true);
      setErrorMessage(error?.sub);
      return;
    }

    if (!_.includes(documentAllowedExtensions, file?.fileExtension)) {
      setShowError(true);
      setErrorMessage(
        `${documentTitle} must be a file with ${
          documentAllowedExtensions?.length <= 1
            ? `extension`
            : `one of these extensions`
        }: ${_.join(documentAllowedExtensions, ", ")}`
      );
      return;
    }

    AnalyticsService.pushEvent(`Add Attachment Clicked`, {
      page: "Upload Documents",
    });

    setUploadingFile("started");
    const { axiosRequest } = DocumentService.getDocumentUploadUrls(
      file?.fileExtension,
      docIdentifierForNextUpload
    );
    axiosRequest
      .then(({ data }) => {
        const putPresignedUrl = data?.responseData?.presignedPutUrl;
        const getPresignedUrl = data?.responseData?.presignedGetUrl;
        const objectUrl = data?.responseData?.objectUrl;
        const fileData = file?.file;
        const { axiosRequest } = DocumentService.uploadDocumentToS3(
          putPresignedUrl,
          fileData,
          file?.fileType
        );
        axiosRequest
          .then((response) => {
            const fileObject = {
              type: docIdentifierForNextUpload,
              fileLink: objectUrl,
              filePresignedLink: getPresignedUrl,
              fileType: file?.fileExtension,
              isUploaded: true,
            };
            const putRequestPayload = { ...fileObject };
            delete putRequestPayload.isUploaded;
            delete putRequestPayload.filePresignedLink;

            const { axiosRequest } =
              DocumentService.uploadDocumentsForOnboarding({
                requestPayload: [putRequestPayload],
                isPartialUploadComplete: false,
                isCompleted: false,
              });
            axiosRequest
              .then((response) => {
                setUploadedFiles((prevFiles) => [...prevFiles, fileObject]);
                setDocumentGroup((previousDocumentGroup) => {
                  let newDocumentObjToAdd = _.cloneDeep(documentTemplate);
                  newDocumentObjToAdd.identifier = docIdentifierForNextUpload;
                  newDocumentObjToAdd.title = `${documentTemplate?.title} ${
                    previousDocumentGroup?.length + 1
                  }`;
                  return [...previousDocumentGroup, newDocumentObjToAdd];
                });
                setUploadingFile("done");
              })
              .catch(() => {
                setShowError(true);
                setErrorMessage("Something went wrong. Please try again.");
                setErrorCode("UPLOAD_DOC_API_FAILURE");
                setUploadingFile("done");
              });
          })
          .catch(() => {
            setShowError(true);
            setErrorMessage("Something went wrong. Please try again.");
            setErrorCode("UPLOAD_DOC_S3_FAILURE");
            setUploadingFile("done");
          });
      })
      .catch(() => {
        setShowError(true);
        setErrorMessage("Something went wrong. Please try again.");
        setErrorCode("GENERATE_UPLOAD_URLS_FAILURE");
        setUploadingFile("done");
      });
  };

  const handleAddImageFileGroup = () => {
    openImageFilePondDialog();
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Upload Document Group",
      page: "Upload Documents",
      documentName: documentIdentifier || "",
      fileType: "Image",
    });
  };

  const handleAddPdfFileGroup = () => {
    openPdfFilePondDialog();
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Upload Document Group",
      page: "Upload Documents",
      documentName: documentIdentifier || "",
      fileType: "PDF",
    });
  };

  return (
    <>
      <ErrorBoundary>
        <Grid
          container
          alignItems={"center"}
          sx={{
            borderRadius: "12px",
            background: "#FFF",
            boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10);",
            padding: "5px",
            marginBottom: "4px",
          }}
        >
          <Grid item xs={2} sx={{ display: "flex" }}>
            {attachedFiles?.[0]?.isUploaded ? (
              <>
                <Box className="image-stack">
                  <img
                    className={
                      attachedFiles?.length > 1 ? "image-stack-preview" : ""
                    }
                    src={
                      imageFileTypeExtensions?.includes(
                        _.find(
                          attachedFiles,
                          (attachFile) =>
                            attachFile.type ===
                            `${documentTemplate?.identifier}_1`
                        )?.fileType
                      )
                        ? _.find(
                            attachedFiles,
                            (attachFile) =>
                              attachFile.type ===
                              `${documentTemplate?.identifier}_1`
                          )?.filePresignedLink
                        : documentFileTypeExtensions?.includes(
                              _.find(
                                attachedFiles,
                                (attachFile) =>
                                  attachFile.type ===
                                  `${documentTemplate?.identifier}_1`
                              )?.fileType
                            )
                          ? "/icons/pdfIcon.svg"
                          : "/icons/emptyDocumentPreview.svg"
                    }
                    alt=""
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "12px",
                      background: "#FFF",
                    }}
                  />
                  {attachedFiles?.length > 1 && (
                    <img
                      className="image-stack-preview"
                      src={
                        imageFileTypeExtensions?.includes(
                          _.find(
                            attachedFiles,
                            (attachFile) =>
                              attachFile.type ===
                              `${documentTemplate?.identifier}_2`
                          )?.fileType
                        )
                          ? _.find(
                              attachedFiles,
                              (attachFile) =>
                                attachFile.type ===
                                `${documentTemplate?.identifier}_2`
                            )?.filePresignedLink
                          : "/icons/pdfIcon.svg"
                      }
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "12px",
                        background: "#FFF",
                      }}
                    />
                  )}
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  background: "#D9D9D9",
                  borderRadius: "12px",
                }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
              {documentTitle + (documentTemplate?.isMandatory ? " *" : "")}
            </Typography>
            <Typography sx={{ fontSize: "14px", marginTop: "-2px" }}>
              {documentSubtitle}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "end", pr: 2 }}>
            <Typography color={"primary"} sx={{ fontSize: "14px" }}>
              {attachedFiles?.length > 0
                ? attachedFiles?.length +
                  (attachedFiles?.length === 1 ? " File" : " Files")
                : ""}
            </Typography>
          </Grid>

          {isFileUploadEnabled && (
            <>
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: `${
                    uploadingFile !== "started" &&
                    attachedFiles?.[0]?.isUploaded
                      ? "flex-end"
                      : uploadingFile === "started"
                        ? "center"
                        : "flex-end"
                  }`,
                }}
              >
                <Box
                  sx={{ display: "flex", gap: "8px" }}
                  className="onboardingDocumentsUpload"
                >
                  <FilePond
                    ref={imagePond}
                    allowFileSizeValidation={true}
                    maxFileSize={"25MB"}
                    acceptedFileTypes={["image/*"]}
                    labelMaxFileSizeExceeded={"File is too large"}
                    allowReorder={false}
                    allowMultiple={false}
                    onaddfile={handleAddFile}
                    onremovefile={handleRemoveFile}
                    labelMaxFileSize="Max file size allowed is 25 MB"
                    labelIdle={`<img src="/icons/upload.svg" />`}
                    maxFiles={1}
                  />
                  <FilePond
                    ref={pdfPond}
                    allowFileSizeValidation={true}
                    maxFileSize={"100MB"}
                    acceptedFileTypes={["application/pdf"]}
                    labelMaxFileSizeExceeded={"File is too large"}
                    allowReorder={false}
                    allowMultiple={false}
                    onaddfile={handleAddFile}
                    onremovefile={handleRemoveFile}
                    labelMaxFileSize="Max file size allowed is 100 MB"
                    labelIdle={`<img src="/icons/upload.svg" />`}
                    maxFiles={1}
                  />
                  {uploadingFile === "started" ? (
                    <img src="/icons/loading.svg" alt="" className="spin" />
                  ) : (
                    <img
                      src="/icons/upload.svg"
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenUploadDrawer(true);
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <Dialog
          open={showError}
          onClose={() => {
            setShowError(false);
            window.location.reload();
          }}
          PaperProps={{
            style: {
              borderRadius: "32px",
              padding: "24px",
              minWidth: "70vw",
            },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon fontSize={"large"} sx={{ color: "#9D2123" }} />
            <Typography
              variant={`body1`}
              sx={{ color: "#9D2123", marginTop: 1 }}
            >
              {errorMessage}
            </Typography>
            {errorCode && (
              <Typography
                variant={`caption`}
                sx={{ color: "#222222", opacity: 0.3, wordBreak: "break-all" }}
              >
                {errorCode}
              </Typography>
            )}

            <Box sx={{ marginTop: 2.5 }}>
              <Button
                variant={"outlined"}
                size={"large"}
                color={"secondary"}
                sx={{
                  borderRadius: "16px",
                  background: "rgba(255, 255, 255, 1)",
                  color: "rgba(0, 115, 62, 1)",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setShowError(false);
                  window.location.reload();
                }}
              >
                Okay
              </Button>
            </Box>
          </Box>
        </Dialog>

        <SwipeableDrawer
          anchor={`bottom`}
          open={openUploadDrawer}
          onClose={() => setOpenUploadDrawer(false)}
          onOpen={() => {}}
          PaperProps={{
            style: {
              borderRadius: "24px 24px 0px 0px",
              overflowY: "hidden",
            },
          }}
          disableDiscovery={true}
          disableSwipeToOpen={true}
        >
          <Box
            sx={{
              background: "#F3F3F380",
              maxHeight: "80vh",
              overflow: "scroll",
            }}
          >
            <Grid
              container
              alignItems={`center`}
              justifyContent={`space-between`}
              sx={{
                p: 2,
                pb: 2,
                position: "sticky",
                top: 0,
                bgcolor: "#F3F3F380",
              }}
            >
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {groupTitle}
              </Typography>
              <CloseIcon
                onClick={() => {
                  setOpenUploadDrawer(false);
                  AnalyticsService.pushEvent("Cancel Icon Clicked", {
                    drawerType: "Upload Document Group",
                    page: "Upload Documents",
                  });
                }}
                sx={{ color: "#999999", fontSize: "28px", cursor: "pointer" }}
              />
            </Grid>

            <Box sx={{ p: 2, pt: 0 }}>
              {_.map(documentGroup, (document, index) => (
                <UploadDocument
                  key={index}
                  documentTitle={document?.title}
                  documentSubtitle={document?.subtitle}
                  documentAllowedExtensions={document?.allowedFileExtensions}
                  documentMimeType={
                    imageFileTypeExtensions?.includes(
                      _.find(attachedFiles, {
                        type: document?.identifier,
                      })?.fileType
                    )
                      ? ["image/*"]
                      : documentFileTypeExtensions?.includes(
                            _.find(attachedFiles, {
                              type: document?.identifier,
                            })?.fileType
                          )
                        ? ["application/pdf"]
                        : ["image/*", "application/pdf"]
                  }
                  imageFileTypeExtensions={imageFileTypeExtensions}
                  documentFileTypeExtensions={documentFileTypeExtensions}
                  documentIdentifier={document?.identifier}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  allowEditable={document?.allowEditable}
                />
              ))}
              <Backdrop open={uploadingFile === "started"}>
                <CircularProgress sx={{ color: "#FFFFFF" }} />
              </Backdrop>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                {_.includes(uploadType, "Image") && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "17px",
                        border: "1px solid #9d2123",
                        borderRadius: "16px",
                        background: "rgba(157, 33, 35, 0.15)",
                        cursor: "pointer",
                      }}
                      onClick={handleAddImageFileGroup}
                    >
                      <CameraAltRoundedIcon color="primary" />
                    </Box>
                    <Typography
                      textAlign={"center"}
                      color={"primary"}
                      sx={{ fontSize: "12px", fontWeight: 700, mt: 0.5 }}
                    >
                      Image
                    </Typography>
                  </Box>
                )}
                {_.includes(uploadType, "PDF") && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "17px",
                        border: "1px solid #9d2123",
                        borderRadius: "16px",
                        background: "rgba(157, 33, 35, 0.15)",
                        cursor: "pointer",
                      }}
                      onClick={handleAddPdfFileGroup}
                    >
                      <DescriptionRoundedIcon color="primary" />
                    </Box>
                    <Typography
                      textAlign={"center"}
                      color={"primary"}
                      sx={{ fontSize: "12px", fontWeight: 700, mt: 0.5 }}
                    >
                      PDF
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              borderRadius: 0,
              padding: "14px 22px",
              background: "#9D2123",
              "&:hover": {
                backgroundColor: "#9D2123",
              },
            }}
            onClick={() => {
              setOpenUploadDrawer(false);
              AnalyticsService.pushEvent("Button Clicked", {
                type: "Upload Document Group",
                page: "Upload Documents",
                groupSize: documentGroup?.length,
              });
            }}
          >
            Continue
          </Button>
        </SwipeableDrawer>
      </ErrorBoundary>
    </>
  );
};

export default UploadDocumentGroup;
