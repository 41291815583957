import React, { useEffect } from "react";
import useQuery from "../../Hooks/useQuery";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import AnalyticsService from "../../Services/AnalyticsService";
const useStyles = makeStyles((theme) => ({
  successIconWrapper: {
    position: "relative",
    marginTop: theme.spacing(8),
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  successIcon: {
    width: theme.spacing(19),
    height: theme.spacing(19),
    position: "absolute",
    top: 0,
    left: 0,
  },
  successIconCongrats: {
    animation: "$hideAfterSingleRun 1.83s forwards",
    width: theme.spacing(16),
    height: theme.spacing(16),
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    transform: "scale(2.5)",
  },
  "@keyframes hideAfterSingleRun": {
    "0%": {
      opacity: 0,
    },
    "1%": {
      opacity: 1,
    },
    "99%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
}));

const AgreementSigningSuccess = () => {
  useEffect(() => {
    AnalyticsService.pushEvent("Dialog Shown", {
      type: "Agreement Signing Success",
      documentId: query.get("documentId"),
    });
  }, []);

  const classes = useStyles();
  const query = useQuery();
  const documentId = query.get("documentId");
  let navigate = useNavigate();

  return (
    <Dialog fullWidth={true} fullScreen={true} open={true}>
      <Box sx={{ background: "#E6F2EC", height: "92vh" }}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ marginTop: "20vh" }}
        >
          <Grid item className={classes.successIconWrapper}>
            <img
              src={`/icons/congratulations.gif`}
              className={classes.successIconCongrats}
              alt=""
            />
            <img
              src={`/icons/success.svg`}
              className={classes.successIcon}
              alt=""
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ marginTop: "3vh" }}
        >
          <Grid item>
            <Typography variant={"h6"} align={"center"}>
              Agreement Signed Successfully
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ marginTop: "1vh" }}
        >
          <Grid item>
            <Typography variant={"body2"} align={"center"}>
              Document ID - <b>{documentId}</b>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant={"contained"}
        fullWidth={true}
        color={"secondary"}
        onClick={() => {
          AnalyticsService.pushEvent("Button Clicked", {
            type: "Agreement Signing Success Redirection",
            redirectTo: "dashboard",
          });
          navigate(`/dashboard`);
        }}
        sx={{
          marginTop: "8px",
          position: "fixed",
          bottom: 0,
          borderRadius: "0",
          zIndex: 0,
          height: "8vh",
        }}
      >
        Okay
      </Button>
    </Dialog>
  );
};
export default AgreementSigningSuccess;
