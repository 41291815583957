import { Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorBoundary from "../Common/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    background: "#ffffff",
    margin: "8px 16px",
    marginBottom: "16px",
    padding: "0px 15px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "20px",
  },
  tab: {
    width: "50%",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: "20px 8px",
    borderBottom: "none",
    color: "#333333",
    opacity: "0.6",
  },
  active: {
    borderBottom: "1px solid #9D2123",
    color: "#9D2123",
    opacity: "1",
    width: "50%",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: "20px 8px",
  },
}));

function MultiCartTabs({ currentTab, carts, setCurrentTab, setRealCashError }) {
  const classes = useStyles();
  const creditItems = carts?.CREDIT_ORDER?.products?.length || 0;
  const cashItems = carts?.CASH_ONLY_ORDER?.products?.length || 0;
  return (
    <ErrorBoundary message={``}>
      <Tabs value={currentTab} className={classes.tabWrapper} centered>
        <Tab
          label={`Credit (${creditItems})`}
          value={"credit"}
          className={currentTab === `credit` ? classes.active : classes.tab}
          onClick={() => {
            setCurrentTab("credit");
            setRealCashError(null);
          }}
        />
        <Tab
          label={`Cash (${cashItems})`}
          value={"cash"}
          className={currentTab === `cash` ? classes.active : classes.tab}
          onClick={() => {
            setCurrentTab("cash");
            setRealCashError(null);
          }}
        />
      </Tabs>
    </ErrorBoundary>
  );
}

export default MultiCartTabs;
