import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import useQuery from "../../Hooks/useQuery";

import {
  ButtonBase,
  Card,
  Typography,
  Box,
  Container,
  Grid,
  Snackbar,
  Alert,
  Dialog,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ShareProfile from "./ShareProfile";
import TopBar from "../Common/TopBar";
import HisaabService from "../../Services/HisaabService";
import AnalyticsService from "../../Services/AnalyticsService";
import InsuranceDetails from "../InsuranceDetails/InsuranceDetails";
import Document from "./Document";
import DocumentService from "../../Services/DocumentService";
import FooterImageLogo from "../Common/FooterImageLogo";
import MyFarmers from "./MyFarmers";
import BannerService from "../../Services/BannerService";
import Button from "@mui/material/Button";
import PartnerService from "../../Services/PartnerService";
import PartnerClub from "./PartnerClub";
import _ from "lodash";
import StoreDetails from "../../Components/Profile/StoreDetails";
import StoreImage from "../../Components/Profile/StoreImage";
import StoreFrontManager from "../../Components/Profile/StoreFrontManager";
import PartnerClubSegments from "../../Services/PartnerClubSegments";
import useMessages from "../../Hooks/useMessages";
import CommonConstantValues from "../../Services/CommonConstantValues";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";
import ErrorBoundary from "../Common/ErrorBoundary";
import NeedHelp from "../Common/NeedHelp";
import OnboardingStatusNudge from "../OnboardPartner/OnboardingStatusNudge";
import ErrorIcon from "@mui/icons-material/Error";
import UnverifiedPartnersDialog from "../Common/UnverifiedPartnersDialog";
import BackDropLoader from "../Common/BackDropLoader";

const useStyles = makeStyles((theme) => ({
  addressCard: {
    padding: theme.spacing(1),
    border: "none",
    fontWeight: "400 !important",
    width: "100%",
    // marginTop: "8px",
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "none",
    fontWeight: "400 !important",
    width: "92%",
    marginLeft: "14px",
    borderRadius: "10px",
  },
  shopDetails: {
    marginTop: "5px",
    fontSize: "15px",
    fontWeight: "normal",
  },
  align: {
    marginBottom: "8px",
    marginLeft: "2px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  alignAddress: {
    marginLeft: "10px",
    marginBottom: "16px",
    fontWeight: "400",
  },
  menuContainer: {
    display: "flex",
  },
  cart: {
    height: "23px",
  },
  linkStyle: {
    textDecoration: "none",
  },
  bottomContent: {
    marginTop: theme.spacing(1),
    position: "fixed",
    bottom: 80,
    borderRadius: "0",
    zIndex: 0,
  },
  termsCondition: {
    fontSize: "12px",
    textDecoration: "underline",
    lineHeight: 1,
    textAlign: "center !important",
    width: "100%",
    color: "#9D2123",
  },
  mobileAlign: {
    fontWeight: "400",
  },
  imgAlign: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  menuAlign: {
    margin: theme.spacing(0.1, 0, 0.1, 2.5),
    fontSize: theme.spacing(2),
    fontWeight: "normal",
  },
  imgOrders: {
    height: "17.5px",
  },
  menuAlignOrder: {
    margin: theme.spacing(0.1, 0, 0.1, 1.3),
    fontSize: theme.spacing(2),
    fontWeight: "normal",
  },
  myFarmersWrapper: {
    marginTop: theme.spacing(2),
  },
}));

const Profile = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  let query = useQuery();
  const { getMessage } = useMessages();

  let [profileData, setProfileData] = useState(null);
  let [loading, setLoading] = useState("not-started");
  let [loadingPartnerClub, setLoadingPartnerClub] = useState("not-started");
  let [currentPartnerClubData, setCurrentPartnerClubData] = useState(null);
  let [partnerName, setPartnerName] = useState("");
  let [mobile, setMobile] = useState("");
  const [partnerEligible, setPartnerEligible] = useState(false);
  const [showInsuranceDetails, setShowInsuranceDetails] = useState(false);
  const [showDocumentPanel, setShowDocumentPanel] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [documentLoad, setDocumentLoad] = useState("not_started");
  const [showPromotionTab, setShowPromotionTab] = useState(false);
  const [showError, setShowError] = useState(false);
  const [partnerClubEnabledStateCodes, setPartnerClubEnabledStateCodes] =
    useState([]);
  const [showComplaintTab, setShowComplaintTab] = useState(false);
  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);
  const [showUnauthorizedError, setShowUnauthorizedError] = useState(false);
  const [loadingAgreementSigningWindow, setLoadingAgreementSigningWindow] =
    useState("not-started");

  useEffect(() => {
    setLoading("started");
    localStorage.removeItem("createComplaintFromProfilePage");
    const profilePageViewedData = {
      page: `/profile`,
    };
    setLoadingPartnerClub("started");
    AnalyticsService.pushEvent(`Profile Page Viewed`, profilePageViewedData);
    const { axiosRequest, abortController } =
      HisaabService.getInstitutionDetails();
    axiosRequest
      .then((response) => {
        if (!localStorage.getItem("institutionId")) {
          localStorage.setItem(
            "institutionId",
            response?.data?.responseData?.institutionId
          );
        }
        setProfileData(response?.data?.responseData);
        setPartnerName(response?.data?.responseData?.name);
        setMobile(response?.data?.responseData?.contacts?.mobileNumber);
        if (response?.data?.responseData?.partnerClubData) {
          setCurrentPartnerClubData(
            response?.data?.responseData?.partnerClubData
          );
        }
        setLoadingPartnerClub("done");
        if (response?.data?.responseData?.documents) {
          let documentIds = response?.data?.responseData?.documents
            .map(function (val) {
              return val;
            })
            .join("&documentId=");
          setDocumentLoad("started");

          const { axiosRequest } =
            DocumentService.getDocumentLinks(documentIds);
          axiosRequest
            .then((response) => {
              let documentLinkLists = [];
              if (response?.data?.responseData) {
                response?.data?.responseData?.map((documentObject) => {
                  if (
                    documentObject?.attachments[
                      documentObject?.attachments.length - 1
                    ]["fileType"]
                      ?.split("/")[1]
                      ?.toLowerCase() === "pdf"
                  ) {
                    documentObject.attachments[
                      documentObject?.attachments?.length - 1
                    ]["name"] = documentObject?.name;
                    documentLinkLists.push(
                      documentObject?.attachments[
                        documentObject?.attachments?.length - 1
                      ]
                    );
                  }
                });
                if (query.get("showDetails") === "documents-download") {
                  setShowDocumentPanel(true);
                }
                setDocumentList(documentLinkLists);
              }

              setDocumentLoad("done");
              setLoading("done");
            })
            .catch((error) => {
              setDocumentLoad("done");
              setLoading("done");
            });
        } else {
          setDocumentLoad("done");
          setLoading("done");
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(error);
          setLoading("done");
          setLoadingPartnerClub("done");
        }
      });

    return () => abortController.abort();
  }, []);

  useEffect(() => {
    const district = localStorage.getItem("district");
    const { axiosRequest, abortController } = BannerService.getBannersData(
      `GREETINGS_PAGE`,
      district
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          const { banners } = response?.data?.responseData;
          if (banners?.length) {
            setShowPromotionTab(true);
          } else {
            const { axiosRequest } = BannerService.getBannersData(
              `PROMOTIONS`,
              district
            );
            axiosRequest
              .then((response) => {
                if (response?.data?.responseData) {
                  const { banners } = response?.data?.responseData;
                  if (banners?.length) {
                    setShowPromotionTab(true);
                  }
                }
              })
              .catch((e) => {
                console.error(e?.message);
              });
          }
        }
      })
      .catch((e) => {
        if (e?.message !== "canceled") {
          console.error(e?.message);
        }
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    let partnerClubEnabledStates = "RJ,MH,MP,UP,GJ".split(",");

    setPartnerClubEnabledStateCodes(partnerClubEnabledStates);
  }, []);

  const handleMyOrdersMenuClickEvent = () => {
    const ordersMenuClickedData = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(`My Orders Menu Clicked`, ordersMenuClickedData);
    navigate({
      pathname: `/orders`,
    });
  };

  const handleMyReturnOrdersMenuClickEvent = () => {
    const returnsMenuClickedEventData = {
      menuItem: "Return",
      page: `/profile`,
    };
    AnalyticsService.pushEvent(`Menu Clicked`, returnsMenuClickedEventData);
    navigate({
      pathname: `/orders/return`,
    });
  };

  const handleIPTMenuClickEvent = () => {
    AnalyticsService.pushEvent(`Menu Clicked`, {
      menuItem: "Inter Party Transfer",
      page: `/profile`,
    });

    navigate({
      pathname: `/inter-party-transfers/requests`,
    });
  };

  const handlePromotionMenuClickEvent = () => {
    AnalyticsService.pushEvent(`Promotion Menu Clicked`, {
      page: `/profile`,
    });
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      setShowUnauthorizedError(true);
    } else {
      navigate({
        pathname: `/promotion`,
      });
    }
  };

  const handleMyCartMenuClickEvent = () => {
    const cartMenuClickedData = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(`My Cart Menu Clicked`, cartMenuClickedData);
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      setShowUnauthorizedError(true);
    } else {
      navigate({
        pathname: `/cart`,
      });
    }
  };

  const handleMyWalletMenuClickEvent = () => {
    const walletMenuClickedData = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(`My Wallet Menu Clicked`, walletMenuClickedData);
    navigate({
      pathname: `/wallet`,
    });
  };

  const handleBusinessInsightsMenuClickEvent = () => {
    const businessInsightsMenuClickedData = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(
      `Business Insights Menu Clicked`,
      businessInsightsMenuClickedData
    );
    navigate({
      pathname: `/store-manager/business-insights`,
    });
  };

  const handleDownloadDocumentMenuClickEvent = () => {
    const downloadDocumentMenuClickedData = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(
      `Doucuments Menu Clicked`,
      downloadDocumentMenuClickedData
    );
    const documentDownloadViewedData = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(
      `Documents Drawer Viewed`,
      documentDownloadViewedData
    );
    setShowDocumentPanel(true);
  };

  const handleReferralMenuClickEvent = () => {
    const referralMenuClickedData = {
      page: `/profile`,
    };
    AnalyticsService.pushEvent(
      `Referral Menu Clicked`,
      referralMenuClickedData
    );
    navigate({
      pathname: `/referral`,
      state: { partnerName: partnerName, mobile: mobile },
    });
  };

  const handleInsuranceDetailsMenuClickEvent = () => {
    AnalyticsService.pushEvent(`Insurance Details Menu Clicked`, {
      page: `/profile`,
    });
    AnalyticsService.pushEvent(`Insurance Details Drawer Viewed`, {
      page: `/profile`,
    });
    setShowInsuranceDetails(true);
  };

  const handleComplaintsMenuClickEvent = () => {
    const complaintMenuClickEvent = {
      menuItem: "Complaints",
      page: `/profile`,
    };
    AnalyticsService.pushEvent(`Menu Clicked`, complaintMenuClickEvent);
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      setShowUnauthorizedError(true);
    } else {
      navigate({
        pathname: `/complaints/history`,
      });
    }
  };

  const handleBizConnectMenuClickEvent = () => {
    const bizConnectMenuClickEvent = {
      menuItem: "BizConnect",
      page: `/profile`,
    };
    AnalyticsService.pushEvent(`Menu Clicked`, bizConnectMenuClickEvent);
    if (localStorage.getItem("partnerStatus") === "UNVERIFIED") {
      setShowUnauthorizedError(true);
    } else {
      navigate({
        pathname: `/bizconnect`,
      });
    }
  };

  const handleLogout = () => {
    PartnerService.logoutFieldAgentAsPartner();
    navigate("/partners");
    AnalyticsService.pushEvent("Logout Clicked");
    window.location.reload();
  };
  return (
    <>
      <TopBar
        title={`My Profile`}
        backNavigation={true}
        showCart={false}
        showHisaab={true}
        toolTip={``}
      />
      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Loading...`}
        style={{ minHeight: "80vh" }}
        keepInView
        renderChildren={false}
      >
        {loading === "done" && (
          <>
            <ErrorBoundary message={``}>
              <OnboardingStatusNudge
                setLoadingAgreementSigningWindow={
                  setLoadingAgreementSigningWindow
                }
              />
            </ErrorBoundary>

            <StoreImage
              profileData={profileData}
              setLoading={setLoading}
              setShowError={setShowError}
              setProfileData={setProfileData}
              setOpenAppUpdateDialog={setOpenAppUpdateDialog}
              setShowUnauthorizedError={setShowUnauthorizedError}
            />
            <Card className={classes.addressCard} variant="contained">
              <StoreDetails profileData={profileData} />

              {/*<ShareProfile profileData={profileData} />*/}
              <Box sx={{ marginTop: 2, marginBottom: 1 }}>
                {loadingPartnerClub === "done" && (
                  <>
                    {currentPartnerClubData?.club &&
                    PartnerClubSegments[currentPartnerClubData?.club] ? (
                      <Grid container spacing={1} justifyContent={`center`}>
                        <Grid item xs={6}>
                          <PartnerClub
                            loading={loadingPartnerClub}
                            currentPartnerClubData={currentPartnerClubData}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MyFarmers />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={1} justifyContent={`center`}>
                        <Grid item xs={12}>
                          <MyFarmers />
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Box>
            </Card>

            <StoreFrontManager
              profileData={profileData}
              setShowUnauthorizedError={setShowUnauthorizedError}
            />

            <ProfileMenuItem
              handleClick={handleMyOrdersMenuClickEvent}
              image={`icons/inActiveOrderTab.svg`}
              body={`Orders and Invoice`}
              imageClass={classes.imgOrders}
              bodyClass={classes.menuAlignOrder}
            />

            <ProfileMenuItem
              handleClick={handleMyReturnOrdersMenuClickEvent}
              image={`icons/return.svg`}
              body={`Returns`}
              imageClass={classes.imgOrders}
              bodyClass={classes.menuAlignOrder}
            />

            <ProfileMenuItem
              handleClick={handleIPTMenuClickEvent}
              image={`icons/inActiveTransferTab.svg`}
              body={`Inter Party Transfer`}
            />

            {showPromotionTab && (
              <ProfileMenuItem
                handleClick={handlePromotionMenuClickEvent}
                image={`icons/promotion.svg`}
                body={`Prachaar`}
              />
            )}

            <ProfileMenuItem
              handleClick={handleMyCartMenuClickEvent}
              image={`icons/ShoppingCartProfile.svg`}
              body={`Cart`}
            />

            <ProfileMenuItem
              handleClick={handleMyWalletMenuClickEvent}
              image={`icons/inActiveWalletTab.svg`}
              body={`My Wallet`}
            />
            {profileData?.isDeliveryViaStoreEnabled && (
              <ProfileMenuItem
                handleClick={handleBusinessInsightsMenuClickEvent}
                image={`icons/businessInsights.svg`}
                body={`Business Insights`}
              />
            )}

            <ProfileMenuItem
              handleClick={handleReferralMenuClickEvent}
              image={`icons/inActiveReferTab.svg`}
              body={`Refer and Earn`}
            />

            <ProfileMenuItem
              handleClick={handleComplaintsMenuClickEvent}
              image={`icons/complaintIcon.svg`}
              body={`Samasya Tickets`}
            />

            {documentList.length > 0 && (
              <ProfileMenuItem
                handleClick={handleDownloadDocumentMenuClickEvent}
                image={`icons/document.svg`}
                body={`Principal Certificates`}
              />
            )}

            {/* Disabling as the program has ended. */}
            {/* {partnerEligible && (
              <ProfileMenuItem
                handleClick={handleInsuranceDetailsMenuClickEvent}
                image={`icons/insuranceShield.svg`}
                body={`Saathi Stock Rakshak`}
              />
            )} */}
            <ProfileMenuItem
              handleClick={handleBizConnectMenuClickEvent}
              image={"icons/callOutlined.svg"}
              body={"BizConnect"}
            />

            {localStorage.getItem("role") === "fieldAgent" && (
              <Container maxWidth={`xl`}>
                <Button
                  variant={`outlined`}
                  fullWidth
                  sx={{
                    background: "#FFFFFF",
                    marginTop: 1.5,
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Container>
            )}

            <InsuranceDetails
              setShowInsuranceDetails={setShowInsuranceDetails}
              showInsuranceDetails={showInsuranceDetails}
              partnerEligible={partnerEligible}
              setPartnerEligible={setPartnerEligible}
              setShowUnauthorizedError={setShowUnauthorizedError}
            />
            <Document
              showDocumentPanel={showDocumentPanel}
              setShowDocumentPanel={setShowDocumentPanel}
              documentList={documentList}
              documentLoad={documentLoad}
            />
            <FooterImageLogo />

            {localStorage.getItem("partnerStatus") === "UNVERIFIED" ? (
              <ErrorBoundary>
                <NeedHelp title={"Need Help"} />
              </ErrorBoundary>
            ) : (
              <></>
            )}
          </>
        )}
      </BlockUi>

      <Snackbar
        open={showError}
        autoHideDuration={5000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          marginBottom: "55px",
        }}
      >
        <Alert
          icon={false}
          sx={{
            background: "#f26b6b",
            color: "#ffffff",
            width: "328px",
          }}
          onClose={() => setShowError(false)}
        >
          {CommonConstantValues.ERROR_MESSAGE}
        </Alert>
      </Snackbar>

      <AppVersionUnsupported
        open={openAppUpdateDialog}
        handleClose={() => {
          AnalyticsService.pushEvent("App Update Required Dialog Closed", {
            page: window.location.pathname,
            section: "Store Image Upload",
          });
          setOpenAppUpdateDialog(false);
        }}
        page={window.location.pathname}
        section={"Store Image Upload"}
      />

      <UnverifiedPartnersDialog
        showError={showUnauthorizedError}
        setShowError={setShowUnauthorizedError}
        error={
          "You will get access to this feature once you become our Saathi partner"
        }
      />
      <BackDropLoader open={loadingAgreementSigningWindow === "started"} />
    </>
  );
};

function ProfileMenuItem({ handleClick, image, body, imageClass, bodyClass }) {
  const classes = useStyles();
  return (
    <div className={classes.linkStyle} onClick={handleClick}>
      <Card className={classes.card} variant="outlined">
        <ButtonBase className={classes.menuContainer}>
          <img
            src={image}
            alt={body}
            className={imageClass ? imageClass : classes.imgAlign}
          />
          <Typography
            variant="body2"
            className={bodyClass ? bodyClass : classes.menuAlign}
            style={{ width: "247px", textAlign: "left" }}
          >
            {body}
          </Typography>
        </ButtonBase>
      </Card>
    </div>
  );
}

export default Profile;
