const IETFLanguages = {
  MAHARASHTRA: "mr-IN",
  RAJASTHAN: "hi-IN",
  "UTTAR PRADESH": "hi-IN",
  "MADHYA PRADESH": "hi-IN",
  GUJARAT: "gu-IN",
  BIHAR: "hi-IN",
  HARYANA: "hi-IN",
};

export default IETFLanguages;
