import React from "react";
import Clamp from "react-multiline-clamp";

import { Button, Card, Grid, Typography } from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import AnalyticsService from "../../Services/AnalyticsService";

export default function AddProductsToCatalogCard(props) {
  return (
    <>
      <Card
        sx={{
          minHeight: "85px",
          maxHeight: "85px",
          padding: "8px",
          borderRadius: "16px",
          border: "1px solid rgba(202, 202, 202, 1)",
          boxShadow: "0px 0px 6px 0px rgba(28, 28, 28, 0.10)",
        }}
      >
        <Grid container alignItems="flex-start">
          <Grid item xs={2}>
            <img
              src={
                props.product?.productImages?.[0]?.name
                  ? props.product.productImages?.[0]?.name
                  : `/icons/dummy.jpeg`
              }
              style={{ borderRadius: "16px" }}
              alt={props.product?.productName}
              height={50}
            />
          </Grid>
          <Grid item xs={7} container rowGap="3px" sx={{ p: "0 4px" }}>
            <Clamp lines={4} maxLines={4}>
              <Typography
                variant="caption"
                sx={{
                  color: "#1C1C1C",
                  fontWeight: 700,
                  wordBreak: "break-all",
                }}
              >
                {props.product?.productName}
              </Typography>
            </Clamp>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            <ErrorBoundary message={``}>
              <div
                variant="outlined"
                style={{
                  fontSize: "13px",
                  fontWeight: 700,
                  borderRadius: "16px",
                  textTransform: "capitalize",
                  border: "1px solid #9D2123",
                  padding: "5px 15px",
                  minWidth: "64px",
                  textAlign: "center",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#9D2123",
                }}
                onClick={() => {
                  AnalyticsService.pushEvent(`Button Clicked`, {
                    type: "Add Product To Catalog Inventory From Card",
                    isEnabled: props.product?.isEnabled || false,
                    isPLProduct: false,
                    productName: props.product?.productName,
                    quantity: props.product?.quantity || 0,
                    sellingPrice: props.product?.sellingPrice,
                    skuCode: props.product?.skuCode,
                  });
                  props.setProductToUpdate(props.product);
                  props.setOpenDrawer(true);
                }}
              >
                Add
              </div>
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
