import React from "react";
import { Box, Card, Typography } from "@mui/material";
import ErrorBoundary from "./ErrorBoundary";
import moment from "moment";

import CommonConstantValues from "../../Services/CommonConstantValues";
import PartnerService from "../../Services/PartnerService";
import AmountUtils from "../../Utils/AmountUtils";

const ReturnOrderDetailCard = ({
  order,
  returnOrderID,
  returnOrderDetailsCart,
}) => {
  function formatDate(dateString) {
    if (dateString) {
      const parsedDate = moment(dateString);
      return parsedDate.format("D MMMM YYYY");
    }
  }

  return (
    <ErrorBoundary message={""}>
      <Card
        style={{
          marginTop: "16px",
          paddingBottom: "8px",
          borderRadius: "16px",
          border: "none",
        }}
        variant="outlined"
      >
        <Box
          sx={{
            margin: "4px 0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "13px 13px",
          }}
        >
          <div>
            {order?.orderType === CommonConstantValues.IPT_RETURN_ORDER && (
              <span
                style={{
                  fontSize: "14px",
                  borderRadius: "5px",
                  background: "#dcdcdc",
                  padding: "5px 4px",
                  marginRight: "8px",
                }}
              >
                IPT
              </span>
            )}
            <span style={{ fontSize: "14px" }}>
              Return ID: {order?.orderId ? order.orderId : returnOrderID}
            </span>
          </div>
          <Typography
            variant={"span"}
            sx={{
              color: order?.statusDetails?.statusColor,
              background: order?.statusDetails?.statusContainerColor,
              opacity: "1",
              display: "flex",
              alignItems: "center",
              padding: "5px",
              borderRadius: "5px",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
          >
            <img
              src={order?.statusDetails?.statusIcon}
              alt="order status"
              style={{ marginRight: "8px", height: "20px", width: "20px" }}
            />
            {order?.statusDetails?.statusMessage || ""}
          </Typography>
        </Box>
        {returnOrderDetailsCart && order?.pickupDate ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              borderTop: "1px solid #eeeeee",
              padding: "13px 13px",
            }}
          >
            {order?.pickupDate ? (
              <>
                Estimated Pickup Date{" "}
                <Typography sx={{ fontWeight: "bold" }}>
                  {formatDate(order?.pickupDate)}{" "}
                </Typography>
              </>
            ) : (
              <></>
            )}
            {PartnerService.checkIfFieldAgent() && order?.returnableAmount ? (
              <Typography>
                Approx Return Amount -{" "}
                {AmountUtils.formatCurrency(order?.returnableAmount || 0)}
              </Typography>
            ) : null}
          </Box>
        ) : (
          !returnOrderDetailsCart && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #eeeeee",
                padding: "13px 13px",
              }}
            >
              {order?.createdOn ? (
                <Typography>
                  Date - {formatDate(order?.createdOn || "")}
                </Typography>
              ) : (
                <></>
              )}
              {PartnerService.checkIfFieldAgent() && order?.returnableAmount ? (
                <Typography>
                  Approx Return Amount -{" "}
                  {AmountUtils.formatCurrency(order?.returnableAmount || 0)}
                </Typography>
              ) : null}
            </Box>
          )
        )}
      </Card>
    </ErrorBoundary>
  );
};

export default ReturnOrderDetailCard;
