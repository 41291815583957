import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Box } from "@mui/material";
import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import BannerService from "../../Services/BannerService";

export default function GridBannerTile({ url, type, banner, index }) {
  const [href, setHref] = useState("");

  useEffect(() => {
    const bannerHrefMaker =
      BannerService.getBannerHrefLink[banner?.data?.[index]?.type];
    if (bannerHrefMaker) {
      bannerHrefMaker(banner?.data?.[index]).then((response) =>
        setHref(response)
      );
    }
  }, []);

  return banner?.data?.[index]?.data ? (
    <Link
      key={url}
      to={href ? href : undefined}
      style={{ position: "relative", img: { objectFit: "fill" } }}
    >
      {type === "YOUTUBE" ? (
        <ReactVideoPlayer
          video={{ src: `https://www.youtube.com/watch?v=${url}` }}
          autoplay={false}
          id={index}
          style={{ width: "100%" }}
          light={true}
          playbackStartedEventName="Grid Banner Video Playback Started"
          playbackEndedEventName="Grid Banner Video Playback Ended"
          eventProperties={{
            page: window.location.pathname,
            youtube_id: url,
            section: "Grid Banner",
            position: index + 1,
          }}
        />
      ) : (
        <img src={url} alt={`banner_${index}`} width="100%" />
      )}
    </Link>
  ) : (
    <Box key={url} sx={{ position: "relative", img: { objectFit: "fill" } }}>
      {type === "YOUTUBE" ? (
        <ReactVideoPlayer
          video={{ src: `https://www.youtube.com/watch?v=${url}` }}
          autoplay={false}
          id={index}
          style={{ width: "100%" }}
          light={true}
          playbackStartedEventName="Grid Banner Video Playback Started"
          playbackEndedEventName="Grid Banner Video Playback Ended"
          eventProperties={{
            page: window.location.pathname,
            youtube_id: url,
            section: "Grid Banner",
            position: index + 1,
          }}
        />
      ) : (
        <img src={url} alt={`banner_${index}`} width="100%" />
      )}
    </Box>
  );
}
