import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SwipeableDrawerHeader = ({ handleOnClose }) => {
  return (
    <>
      <Box
        sx={{
          width: 50,
          height: 6,
          position: "absolute",
          top: 20,
          left: "calc(50% - 25px)",
          borderRadius: 3,
          backgroundColor: "#ddd",
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CloseIcon
          onClick={handleOnClose}
          sx={{
            color: "#CACACA",
            fontSize: "28px",
            cursor: "pointer",
            marginTop: "-7px",
          }}
        />
      </Box>
    </>
  );
};

export default SwipeableDrawerHeader;
