import AnalyticsService from "../../Services/AnalyticsService";

const handleMobileScreenViewedEvent = () => {
  const mobileScreenViewedEventData = {
    body: {
      page: "onboard",
    },
    name: "Mobile Input Screen Viewed",
  };

  AnalyticsService.pushEvent(
    mobileScreenViewedEventData.name,
    mobileScreenViewedEventData.body
  );
};

const handleMobileNumberEnteredEvent = (farmerType, mobileNumber) => {
  const mobileNumberEnteredEventData = {
    body: {
      page: "onboard",
      mobileNumber: mobileNumber,
      onboardedFromBackend:
        farmerType === "B2B Partner" || farmerType === "B2B Onboarding"
          ? true
          : false,
    },
    name: "Mobile Entered",
  };

  AnalyticsService.pushEvent(
    mobileNumberEnteredEventData.name,
    mobileNumberEnteredEventData.body
  );
};

const handleOtpEnteredEvent = (mobileNumber) => {
  const otpEnteredEventData = {
    body: {
      mobileNumber: mobileNumber,
      page: "onboard",
    },
    name: "OTP Entered",
  };

  AnalyticsService.pushEvent(
    otpEnteredEventData.name,
    otpEnteredEventData.body
  );
};

const handleResendOtpEvent = () => {
  const resendOtpEventData = {
    body: {
      page: "onboard",
    },
    name: "Resend OTP",
  };

  AnalyticsService.pushEvent(resendOtpEventData.name, resendOtpEventData.body);
};

const handleWhatsappPermissionEvent = (whatsappChecked) => {
  const whatsappPermissionClickedEventData = {
    body: {
      checked: whatsappChecked ? true : false,
      page: "onboard",
    },
    name: "WA Permission Clicked",
  };
  AnalyticsService.pushEvent(
    whatsappPermissionClickedEventData.name,
    whatsappPermissionClickedEventData.body
  );
};

const handleOnBoardingSuccessfulEvent = () => {
  const onBoardingSuccessfulEventData = {
    body: {
      // checked: whatsappChecked ? true : false,
      page: "onboard",
    },
    name: "Onboarding Successful",
  };

  AnalyticsService.pushEvent(
    onBoardingSuccessfulEventData.name,
    onBoardingSuccessfulEventData.body
  );
};

const handleEventChangeMobileNumber = () => {
  const changeMobileNumberEventData = {
    body: {
      page: "onboard",
    },
    name: "Change Mobile Number Clicked",
  };

  AnalyticsService.pushEvent(
    changeMobileNumberEventData.name,
    changeMobileNumberEventData.body
  );
};

const handleRegisterNowClickEvent = (mobileNumber) => {
  const registerNowClickEventData = {
    body: {
      mobileNumber: mobileNumber,
      page: "onboard",
    },
    name: "Register Now Clicked",
  };

  AnalyticsService.pushEvent(
    registerNowClickEventData.name,
    registerNowClickEventData.body
  );
};

export {
  handleMobileScreenViewedEvent,
  handleMobileNumberEnteredEvent,
  handleOtpEnteredEvent,
  handleResendOtpEvent,
  handleWhatsappPermissionEvent,
  handleOnBoardingSuccessfulEvent,
  handleEventChangeMobileNumber,
  handleRegisterNowClickEvent,
};
