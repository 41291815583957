import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Typography, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";

export default function SuccessReturn() {
  let navigate = useNavigate();
  const { returnOrderId } = useParams();
  const [returnOrderID, setReturnOrderID] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("createdReturnOrder")) {
      navigate(-1);
    }

    localStorage.removeItem("createReturnInitiated");
    localStorage.removeItem("createdReturnOrder");
    setReturnOrderID(returnOrderId);
    localStorage.removeItem("markAsDoneButtonClicked");
  }, []);

  const goToOrderDetailsPage = () => {
    const goToReturnEventData = {
      page: `/orders/return/${returnOrderID}/success-return`,
      returnID: returnOrderID,
    };
    AnalyticsService.pushEvent(
      `Go To Return Order Details Button Clicked`,
      goToReturnEventData
    );

    navigate(`/orders/return/${returnOrderID}/order-detail`, { replace: true });
  };

  return (
    <CardContent>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "96vh",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              height: "230px",
            }}
          >
            <img
              src={`/icons/success.svg`}
              alt=""
              style={{
                position: "fixed",
              }}
            />
          </div>
          <div
            style={{
              height: "75px",
              width: "75%",
              marginLeft: "12%",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "25px",
              }}
            >
              Your return order ({returnOrderId}) has been successfully placed.
              You will get a call from our team for confirmation.
            </Typography>
          </div>
        </div>
        <Button
          fullWidth
          variant={`contained`}
          sx={{
            borderRadius: "10px",
            height: "45px",
            letterSpacing: "0.04em",
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "14px",
          }}
          onClick={goToOrderDetailsPage}
        >
          Go TO Return Order Details
        </Button>
      </div>
    </CardContent>
  );
}
