import React from "react";

import { Box, Skeleton } from "@mui/material";
import GridBannerTile from "./GridBannerTile";
import MiscUtils from "../../Utils/MiscUtils";

export default function GridBanner({ banner, loadingBanners }) {
  if (!banner) return null;

  if (loadingBanners === true) {
    return <Skeleton variant="rectangular" height={290} />;
  }

  return (
    <Box
      sx={{
        display: "grid",
        width: "100%",
        margin: "10px 0",
        lineHeight: 0,
        gridTemplateColumns: `repeat(${banner.columns}, 1fr)`,
      }}
      onClick={() => MiscUtils.saveScrollYPosition()}
    >
      {banner.media.map(({ type, url }, index) => {
        if (!url) return null;

        return (
          <GridBannerTile
            key={index}
            index={index}
            type={type}
            url={url}
            banner={banner}
          />
        );
      })}
    </Box>
  );
}
