import Languages from "./LanguageCode";
import config from "../config";
import HTTPService from "./HTTPService";

export default class WeatherService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static get() {
    const InstitutionDetails = localStorage.getItem("InstitutionDetails");
    const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
      ? Languages[localStorage.getItem("state")?.toUpperCase()]
      : "en";
    const location = InstitutionDetails
      ? JSON.parse(InstitutionDetails).location
      : {};
    if (!location || !location.lat || !location.long) {
      return { axiosRequest: Promise.resolve(null) };
    }
    let url = `${config.backendUrl}weatherservice/v1/weather/currentupcomingalertdetails?language=${languageCode}&latitude=${location.lat}&longitude=${location.long}&farmerId=${localStorage.getItem("farmerId")}&isSaathi=true`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }
}
