import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Global } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Card, Grid, SwipeableDrawer, Typography } from "@mui/material";

import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";

const tabs = [
  {
    name: "Recovery",
    path: "/reports/recovery",
    iconPath: "/icons/rupeeGrey.svg",
  },
  {
    name: "My Orders",
    path: "/field-agent-commerce",
    iconPath: "/icons/inactiveOrdersTab.svg",
  },
];

const useStyles = makeStyles({
  wrapper: {
    margin: "20px 0 60px",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  puller: {
    width: 50,
    height: 6,
    position: "absolute",
    top: 10,
    left: "calc(50% - 25px)",
    borderRadius: 3,
    backgroundColor: "#ddd",
  },
  tabName: {
    display: "block",
    marginTop: 0.5,
    fontWeight: 500,
    color: "#0009",
  },
});

export default function FieldAgentExpandMoreDrawer({ open, setOpen }) {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    AndroidService.setPullToRefresh();
  }, []);

  return (
    <>
      {open && (
        <Global styles={{ ".MuiDrawer-root": { zIndex: "1055 !important" } }} />
      )}

      <SwipeableDrawer
        anchor={`bottom`}
        open={open}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        onOpen={() => AndroidService.unsetPullToRefresh()}
        onClose={() => {
          setOpen(false);
          AndroidService.setPullToRefresh();
        }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          },
        }}
      >
        <div className={classes.puller}></div>
        <Grid container className={classes.wrapper}>
          {tabs?.map((tab, index) => (
            <Grid item xs={3} key={index}>
              <Card
                elevation={0}
                sx={{ position: "relative", padding: 1, textAlign: "center" }}
                onClick={() => {
                  setOpen(false);
                  AnalyticsService.pushEvent(`Bottom Tab Clicked`, {
                    tabName: tab.name,
                  });
                  tab.path && navigate(tab.path);
                }}
              >
                <img
                  src={tab.iconPath}
                  alt={tab.name}
                  style={{ height: "25px", marginTop: "12px" }}
                />
                <Typography variant={`caption`} className={classes.tabName}>
                  {tab.name}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </SwipeableDrawer>
    </>
  );
}
