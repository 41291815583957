import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import moment from "moment";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";

import BackDropLoader from "../Common/BackDropLoader";
import ErrorBoundary from "../Common/ErrorBoundary";
import FloatingActionButton from "../Common/FloatingActionButton";
import FooterImageLogo from "../Common/FooterImageLogo";
import LiveSessionContainer from "../Common/LiveSessionContainer";
import NeedHelp from "../Common/NeedHelp";
import TopBar from "../Common/TopBar";
import PayNowDialog from "../Hisaab/PayNowDialog";

import config from "../../config";
import useFeaturedVideos from "../../Hooks/useFeaturedVideos";
import useMessages from "../../Hooks/useMessages";
import AndroidService from "../../Services/AndroidService";
import BrandService from "../../Services/BrandService";
import BannerService from "../../Services/BannerService";
import HisaabService from "../../Services/HisaabService";
import PartnerService from "../../Services/PartnerService";
import EventBasedSaleTheme from "../../Theme/EventBasedSaleTheme";
import StateUtils from "../../Utils/StateUtils";

import BottomBanner from "./BottomBanner";
import Bulletins from "./Bulletins";
import BuyItAgain from "./BuyItAgain";
import CategoriesList from "./CategoriesList";
import FeaturedProducts from "./FeaturedProducts";
import FeaturedVideos from "./FeaturedVideos";
import GrahakProductsRecommendation from "./GrahakProductsRecommendation";
import HorizontalScrollableBanners from "./HorizontalScrollableBanners";
import NearbyPartnerPurchase from "./NearbyPartnerPurchase";
import RecentlyBoughtProducts from "./RecentlyBoughtProducts";
import RecentlyViewedProducts from "./RecentlyViewedProducts";
import ScrollableBanners from "./ScrollableBanners";
import Search from "./Search";
import StoriesPreview from "./StoriesPreview";
import StaticScrollableBanners from "./StaticScrollableBanners";
import TurnoverSchemeProductsRecommendation from "./TurnoverSchemeProductsRecommendation";
import GridBanner from "./GridBanner";
import NudgeCarousel from "./NudgeCarousel";
import firebase from "./../../firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: theme.palette.background.main,
    marginBottom: "80px",
  },
}));

const staticBannersForUnverifiedPartners = {
  B2BMH: [],
  B2BUP: [],
  B2BRJ: [],
  B2BGJ: [],
  B2BMP: [],
};

function Dashboard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { getMessage } = useMessages();
  const [gridBanners, setGridBanners] = useState(null);
  const { featuredVideos, loading, error, liveSession } = useFeaturedVideos();

  const [brands, setBrands] = useState(null);
  const [banners, setBanners] = useState(null);
  const [staticBanners, setStaticBanners] = useState(null);
  const [scrollableBanners, setScrollableBanners] = useState(null);
  const [classBanners, setClassBanners] = useState(null);
  const [eventBasedSaleBanners, setEventBasedSaleBanners] = useState(null);

  const [loadingBanners, setLoadingBanners] = useState(false);
  const [showPayNowDialog, setShowPayNowDialog] = useState(false);
  const [showPaymentInProgressDialog, setShowPaymentInProgressDialog] =
    useState(false);
  const [showOnboardingStatusNudge, setShowOnboardingStatusNudge] =
    useState(false);
  const [loadingOnboardingStatusNudge, setLoadingOnboardingStatusNudge] =
    useState("not-started");
  const [loadingAgreementSigningWindow, setLoadingAgreementSigningWindow] =
    useState("not-started");
  const [institutionalDetails, setInstitutionalDetails] = useState(null);

  const handleShowPaymentInProgressDialogClose = () => {
    setShowPaymentInProgressDialog(false);
  };

  const [dashboardLoading, setDashboardLoading] = useState("not_started");
  const [paymentLoading, setPaymentLoading] = useState("not_started");
  const [stateCode, setStateCode] = useState("MH");

  const [payNowPaymentAmount, setPayNowPaymentAmount] = useState(0);

  const currentDate = new Date();

  const isSaleLive = () => {
    return (
      currentDate >= moment(EventBasedSaleTheme?.eventStartDate) &&
      currentDate <= moment(EventBasedSaleTheme?.eventEndDate)
    );
  };

  const handlePaymentStatus = (paymentId) => {
    navigate(`/hisaab/payment-status/${paymentId}`);
  };

  const handleRazorpayFailure = (error, errorCode = null) => {
    const urlParams = errorCode ? `?errorCode=${errorCode}` : "";
    navigate(`/hisaab/razorpay-failure/${error}${urlParams}`);
  };

  const handleClosePayNow = () => {
    setShowPayNowDialog(false);
  };

  const getPayNowDisabledReasonMessage = () => {
    if (localStorage.getItem("role") === "fieldAgent") {
      return `This feature is only for Partners`;
    } else if (config.disablePayNow) {
      return `Pay Now is temporarily disabled for system maintenance. It will be available soon.`;
    } else if (PartnerService.isPartnerOnboardingInProgress()) {
      return `Pay Now will be available once you become our Saathi Partner`;
    } else {
      return ``;
    }
  };

  const handleClickPayNow = (paymentAmount) => {
    if (
      localStorage.getItem("role") === "fieldAgent" ||
      config.disablePayNow ||
      PartnerService.isPartnerOnboardingInProgress()
    ) {
      setShowPaymentInProgressDialog(true);
      return;
    }
    setShowPayNowDialog(true);
    setPayNowPaymentAmount(paymentAmount);
    AnalyticsService.pushEvent("Pay Now Clicked", {
      dueAmount: paymentAmount,
      page: window?.location?.pathname || "",
      creditLine: localStorage.getItem("creditLine")?.toLowerCase(),
    });
  };

  useEffect(() => {
    AnalyticsService.pushEvent(`Dashboard Viewed`);
    setDashboardLoading("started");
    let currentStateCode = StateUtils.getStateCode();
    setStateCode(currentStateCode);

    const { axiosRequest, abortController } = BrandService.getGlobalBrands();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.brands?.length > 0) {
          let brandsFromResponse = response.data.responseData.brands;
          setBrands(brandsFromResponse);
          setDashboardLoading("done");
        } else {
          console.log(`no brands found`);
          setDashboardLoading("done");
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting brands`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setDashboardLoading("done");
        }
      });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("district") ||
      !localStorage.getItem("clubSegment") ||
      !localStorage.getItem("mobile")
    ) {
      const { axiosRequest, abortController } =
        PartnerService.getPartnerDetailedData(localStorage.getItem("farmerId"));
      axiosRequest
        .then((partnerResponse) => {
          const district =
            partnerResponse?.data?.responseData?.profileAddress?.district;
          const segment = partnerResponse?.data?.responseData?.segment;
          const mobile1 = partnerResponse?.data?.responseData?.mobile1;

          if (district) {
            getBannersData(district);
            localStorage.setItem("district", district);
          }

          if (segment) {
            localStorage.setItem("clubSegment", segment);
          }

          if (mobile1) {
            localStorage.setItem("mobile", mobile1);
            setSaathiAppInstalledIndicator();
            AnalyticsService.pushProfile(
              localStorage.getItem("farmerId"),
              mobile1,
              localStorage.getItem("source")
            );
            Sentry.setUser({ id: localStorage.getItem("farmerId") });
          }
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log("could not get partner details");
            if (error?.response?.status === 401) {
              console.log("unauthorized");
              PartnerService.logout();
              navigate(0);
            }
          }
        });
      return () => abortController.abort();
    }

    if (localStorage.getItem("district")) {
      const bannersCB = getBannersData(localStorage.getItem("district"));
      return () => bannersCB();
    }
    if (localStorage.getItem("mobile")) {
      setSaathiAppInstalledIndicator();
      AnalyticsService.pushProfile(
        localStorage.getItem("farmerId"),
        localStorage.getItem("mobile"),
        localStorage.getItem("source")
      );

      Sentry.setUser({ id: localStorage.getItem("farmerId") });
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("farmerId")) {
      setLoadingOnboardingStatusNudge("started");
      const { axiosRequest, abortController } =
        HisaabService.getInstitutionDetails(localStorage.getItem(`farmerId`));
      axiosRequest
        .then((institutionalDetailsResponse) => {
          let institutionalDetailsResponseData =
            institutionalDetailsResponse?.data?.responseData;
          setInstitutionalDetails(institutionalDetailsResponseData);
          if (institutionalDetailsResponseData.agroExFranchiseId) {
            localStorage.setItem(
              "agroExFranchiseId",
              institutionalDetailsResponseData?.agroExFranchiseId
            );
          }
          if (institutionalDetailsResponseData?.institutionId) {
            localStorage.setItem(
              "institutionId",
              institutionalDetailsResponseData?.institutionId
            );
          }

          if (institutionalDetailsResponseData) {
            localStorage.setItem(
              "InstitutionDetails",
              JSON.stringify(institutionalDetailsResponseData)
            );
          }

          if (institutionalDetailsResponseData?.documentSubmissionStatus) {
            localStorage.setItem(
              "documentSubmissionStatus",
              institutionalDetailsResponseData?.documentSubmissionStatus
            );
          }
          if (institutionalDetailsResponseData?.areDocumentsVerified) {
            localStorage.setItem(
              "areDocumentsVerified",
              institutionalDetailsResponseData?.areDocumentsVerified
            );
          }

          if (institutionalDetailsResponseData?.status) {
            localStorage.setItem(
              "partnerStatus",
              institutionalDetailsResponseData?.status
            );
            if (institutionalDetailsResponseData?.subStatus) {
              localStorage.setItem(
                "partnerSubStatus",
                institutionalDetailsResponseData?.subStatus
              );
            }
            if (
              institutionalDetailsResponseData?.referrerDetails?.referrerEmailId
            ) {
              localStorage.setItem(
                "onboardingReferrerSalesOfficerEmailID",
                institutionalDetailsResponseData?.referrerDetails
                  ?.referrerEmailId
              );
            }
            if (institutionalDetailsResponseData?.status === "UNVERIFIED") {
              if (institutionalDetailsResponseData?.showPrices) {
                localStorage.setItem(
                  "partnerPricingEnabled",
                  institutionalDetailsResponseData?.showPrices
                );
              } else {
                localStorage.setItem(
                  "partnerPricingEnabled",
                  institutionalDetailsResponseData?.showPrices
                );
              }
            }
            if (institutionalDetailsResponseData?.securityDeposit?.amount) {
              localStorage.setItem(
                "securityDepositAmount",
                institutionalDetailsResponseData.securityDeposit.amount
              );
            }
          }

          window.dispatchEvent(new Event("storage"));

          if (institutionalDetailsResponseData?.creditLine) {
            localStorage.setItem(
              "creditLine",
              institutionalDetailsResponseData?.creditLine
            );
          } else {
            localStorage.setItem("creditLine", "AGROSTAR");
          }

          if (institutionalDetailsResponseData?.lendingProvider) {
            localStorage.setItem(
              "lendingProvider",
              institutionalDetailsResponseData?.lendingProvider
            );
          }
          if (institutionalDetailsResponseData.name) {
            localStorage.setItem(
              "partnerName",
              institutionalDetailsResponseData.name
            );
          }
          if (institutionalDetailsResponseData.name) {
            localStorage.setItem(
              "creditPeriod",
              institutionalDetailsResponseData.creditPeriod
            );
          }
          if (
            institutionalDetailsResponseData?.status &&
            institutionalDetailsResponseData?.status === "INACTIVE"
          ) {
            if (
              institutionalDetailsResponseData?.inactivatedOnDate ||
              institutionalDetailsResponseData?.inactivatedOnDate === 0
            ) {
              localStorage.setItem(
                "inactivatedOnDate",
                institutionalDetailsResponseData?.inactivatedOnDate
              );
            }
            if (
              institutionalDetailsResponseData?.inactivatedOnDate === 0 ||
              PartnerService.isPartnerInactiveSinceMoreThanNdays(
                institutionalDetailsResponseData?.inactivatedOnDate
              )
            ) {
              let inactiveUserEventsData = {
                name: "Inactive User Logged Out",
                body: {
                  status: "INACTIVE",
                  source: localStorage.getItem("source"),
                  mobile: localStorage.getItem("mobile"),
                },
              };

              AnalyticsService.pushEvent(
                inactiveUserEventsData.name,
                inactiveUserEventsData.body
              );
              AnalyticsService.pushProfile(
                localStorage.getItem(`farmerId`),
                localStorage.getItem("mobile"),
                localStorage.getItem("source"),
                "INACTIVE"
              );
              PartnerService.logout();
              navigate(0);
            } else {
              navigate("/hisaab", { replace: true });
            }
          }
          setLoadingOnboardingStatusNudge("done");
          setShowOnboardingStatusNudge(true);
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            setLoadingOnboardingStatusNudge("done");
            setShowOnboardingStatusNudge(true);
          }
        });
      return () => abortController.abort();
    } else {
      PartnerService.logout();
      navigate(0);
    }
  }, []);

  useEffect(() => {
    const scrollYPosition = sessionStorage.getItem("scrollYPosition");
    if (scrollYPosition) {
      setTimeout(() => {
        window.scrollTo({
          top: parseInt(scrollYPosition),
          behavior: "smooth",
        });
        sessionStorage.removeItem("scrollYPosition");
      }, 1000);
    }
  }, []);

  function getScrollableBannerMedia(scrollableBannerRecord) {
    let scrollableBannerMedia = scrollableBannerRecord.media;
    let scrollableBannerData = null;
    if (scrollableBannerRecord?.data) {
      scrollableBannerData = scrollableBannerRecord.data;
      _.forEach(scrollableBannerMedia, (media, index) => {
        if (scrollableBannerData[index]) {
          media.data = scrollableBannerData[index];
        }
      });
    }
    return _.filter(scrollableBannerMedia, (media) => {
      return media.type === `IMAGE`;
    });
  }

  const getBannersData = (district) => {
    setLoadingBanners(true);
    const { axiosRequest, abortController } = BannerService.getBannersData(
      `SHOP`,
      district ? district : localStorage.getItem("district")
    );
    axiosRequest
      .then((response) => {
        let scrollableBannerRecord =
          response.data.responseData.scrollableBanners[0];
        if (scrollableBannerRecord?.media) {
          let scrollableBannerMedia = getScrollableBannerMedia(
            scrollableBannerRecord
          );
          setScrollableBanners(scrollableBannerMedia);
        }

        let classBannerRecord = response.data.responseData.scrollableBanners[1];
        if (classBannerRecord?.media) {
          let classBannerMedia = getScrollableBannerMedia(classBannerRecord);
          setClassBanners(classBannerMedia);
        }

        let eventBasedSaleBannerRecord =
          response?.data?.responseData?.scrollableBanners[2];
        if (eventBasedSaleBannerRecord?.media) {
          let eventBasedSaleBannerMedia = getScrollableBannerMedia(
            eventBasedSaleBannerRecord
          );
          setEventBasedSaleBanners(eventBasedSaleBannerMedia);
        }

        if (response?.data?.responseData?.banners?.length > 0) {
          let bannersData = response?.data?.responseData?.banners;
          let bannersFromData = [];
          _.map(bannersData, (banner) => {
            if (banner.columns === 1 && banner.rows === 1) {
              bannersFromData.push(banner);
            }
          });
          setBanners(bannersFromData);
        }
        if (response?.data?.responseData?.staticBanners?.length > 0) {
          let staticBannersData = response?.data?.responseData?.staticBanners;
          let staticBannersFromData = [];
          _.map(staticBannersData, (banner) => {
            staticBannersFromData.push(banner);
          });
          setStaticBanners(staticBannersFromData);
        }

        const gridBannersObject = _.filter(
          response.data.responseData?.banners,
          (banner) => banner.columns > 1 || banner.rows > 1
        );
        setGridBanners(gridBannersObject);

        setLoadingBanners(false);
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting banners`);
          if (error.data) {
            console.log(error.data);
          } else {
            console.log(error);
          }
          setLoadingBanners(false);
        }
      });
    return () => abortController.abort();
  };

  const setSaathiAppInstalledIndicator = () => {
    if (AndroidService.checkIfAndroid()) {
      let appInstalledData = {
        farmerId: localStorage.farmerId,
        saathiAppEnabled: true,
        saathiAppRegistrationOtpVerified: true,
        saathiAppVersion: "",
      };
      if (AndroidService.getAndroidVersion()) {
        let { appVersionName } = AndroidService.getAndroidVersion();
        if (appVersionName) {
          appInstalledData.saathiAppVersion = appVersionName;
        } else {
          appInstalledData.saathiAppVersion = "";
        }
      } else {
      }
      const { axiosRequest } =
        PartnerService.setPartnerAppInstalled(appInstalledData);
      axiosRequest.then(() => {}).catch(() => {});
    }
  };

  return (
    <div>
      <div className={classes.mainContent}>
        <ErrorBoundary message={``}>
          <TopBar
            title={`SAATHI`}
            showCart={true}
            showHisaab={true}
            showCallAgriDoctor={false}
            showNotificationIcon={true}
          />
        </ErrorBoundary>
        <ErrorBoundary message={``}>
          <Search page={`Dashboard`} />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <NudgeCarousel
            institutionalDetails={institutionalDetails}
            setLoadingAgreementSigningWindow={setLoadingAgreementSigningWindow}
          />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <StoriesPreview />
        </ErrorBoundary>
        {localStorage.getItem("partnerStatus") === "UNVERIFIED" ? (
          <ErrorBoundary message={``}>
            <StaticScrollableBanners
              banners={
                staticBannersForUnverifiedPartners?.[
                  localStorage.getItem("source")
                ]
              }
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary message={``}>
            <ScrollableBanners
              banners={scrollableBanners}
              loadingBanners={loadingBanners}
            />
          </ErrorBoundary>
        )}
        {staticBanners?.[0] &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <Box sx={{ marginTop: "16px" }}>
              <ErrorBoundary message={``}>
                <BottomBanner
                  key={0}
                  index={0}
                  banner={staticBanners[0]}
                  loadingBanners={loadingBanners}
                />
              </ErrorBoundary>
            </Box>
          )}
        <ErrorBoundary message={``}>
          <Bulletins handleClickPayNow={handleClickPayNow} />
        </ErrorBoundary>
        {/*<ErrorBoundary>*/}
        {/*  <NewYearGreetings />*/}
        {/*</ErrorBoundary>*/}
        <ErrorBoundary message={``}>
          {loading === "done" &&
            (!error || !error?.data) &&
            featuredVideos &&
            featuredVideos.length > 0 &&
            liveSession &&
            liveSession?.link &&
            liveSession?.redirectionLink && (
              <LiveSessionContainer
                liveSession={liveSession}
                page={"dashboard"}
              />
            )}
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <TurnoverSchemeProductsRecommendation
            numberOfProductsToShow={4}
            stateCode={stateCode}
          />
        </ErrorBoundary>
        {isSaleLive() &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <ErrorBoundary message={``}>
              <HorizontalScrollableBanners
                banners={eventBasedSaleBanners}
                loadingBanners={loadingBanners}
                page={`Dashboard`}
                sectionTitle={``}
                showBackgroundImage={true}
              />
            </ErrorBoundary>
          )}
        <ErrorBoundary message={``}>
          <NearbyPartnerPurchase
            numberOfProductsToShow={4}
            stateCode={stateCode}
          />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <GridBanner
            banner={gridBanners?.[0]}
            loadingBanners={loadingBanners}
          />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <FeaturedVideos
            numberOfVideosToShow={2}
            featuredVideos={featuredVideos}
            loading={loading}
            error={error}
            saveScrollYPosition={true}
          />
        </ErrorBoundary>

        {staticBanners?.[1] &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <Box sx={{ marginTop: "16px" }}>
              <ErrorBoundary message={``}>
                <BottomBanner
                  key={0}
                  index={0}
                  banner={staticBanners[1]}
                  loadingBanners={loadingBanners}
                />
              </ErrorBoundary>
            </Box>
          )}
        <ErrorBoundary message={``}>
          <RecentlyViewedProducts
            numberOfProductsToShow={6}
            stateCode={stateCode}
          />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <GridBanner
            banner={gridBanners?.[1]}
            loadingBanners={loadingBanners}
          />
        </ErrorBoundary>

        {staticBanners?.[2] &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <Box sx={{ marginTop: "16px" }}>
              <ErrorBoundary message={``}>
                <BottomBanner
                  key={0}
                  index={0}
                  banner={staticBanners[2]}
                  loadingBanners={loadingBanners}
                />
              </ErrorBoundary>
            </Box>
          )}

        <ErrorBoundary message={``}>
          <FeaturedProducts numberOfProductsToShow={4} stateCode={stateCode} />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <GridBanner
            banner={gridBanners?.[2]}
            loadingBanners={loadingBanners}
          />
        </ErrorBoundary>

        {staticBanners?.[3] &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <Box sx={{ marginTop: "16px" }}>
              <ErrorBoundary message={``}>
                <BottomBanner
                  key={0}
                  index={0}
                  banner={staticBanners[3]}
                  loadingBanners={loadingBanners}
                />
              </ErrorBoundary>
            </Box>
          )}

        <ErrorBoundary message={``}>
          <CategoriesList />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <GridBanner
            banner={gridBanners?.[3]}
            loadingBanners={loadingBanners}
          />
        </ErrorBoundary>

        {staticBanners?.[4] &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <Box sx={{ marginTop: "16px" }}>
              <ErrorBoundary message={``}>
                <BottomBanner
                  key={0}
                  index={0}
                  banner={staticBanners[1]}
                  loadingBanners={loadingBanners}
                />
              </ErrorBoundary>
            </Box>
          )}

        <ErrorBoundary message={``}>
          <GrahakProductsRecommendation
            numberOfProductsToShow={6}
            stateCode={stateCode}
          />
        </ErrorBoundary>
        {banners?.[0] &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <Box sx={{ marginTop: "16px" }}>
              <ErrorBoundary message={``}>
                <BottomBanner
                  key={0}
                  index={0}
                  banner={banners[0]}
                  loadingBanners={loadingBanners}
                />
              </ErrorBoundary>
            </Box>
          )}
        <ErrorBoundary message={``}>
          <BuyItAgain numberOfProductsToShow={6} stateCode={stateCode} />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <GridBanner
            banner={gridBanners?.[4]}
            loadingBanners={loadingBanners}
          />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <HorizontalScrollableBanners
            banners={classBanners}
            loadingBanners={loadingBanners}
            page={`Dashboard`}
            sectionTitle={`AgroStar Premium Product Class`}
          />
        </ErrorBoundary>

        <ErrorBoundary message={``}>
          <GridBanner
            banner={gridBanners?.[5]}
            loadingBanners={loadingBanners}
          />
        </ErrorBoundary>

        {banners?.[1] &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <Box sx={{ marginTop: "16px" }}>
              <ErrorBoundary message={``}>
                <BottomBanner
                  key={1}
                  index={1}
                  banner={banners[1]}
                  loadingBanners={loadingBanners}
                />
              </ErrorBoundary>
            </Box>
          )}
        {banners?.[2] &&
          localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
            <Box sx={{ marginTop: "16px" }}>
              <ErrorBoundary message={``}>
                <BottomBanner
                  key={2}
                  index={2}
                  banner={banners[2]}
                  loadingBanners={loadingBanners}
                />
              </ErrorBoundary>
            </Box>
          )}
        {/* <ErrorBoundary message={``}>
          {brands && brands.length && brands.length > 0 && (
            <div style={{ marginTop: "5px", padding: "15px" }}>
              <BrandsList
                loading={dashboardLoading}
                brands={brands}
                numberOfBrandsToShow={9}
                page={`Dashboard`}
              />
            </div>
          )}
        </ErrorBoundary> */}
        <ErrorBoundary message={``}>
          <RecentlyBoughtProducts
            numberOfProductsToShow={4}
            stateCode={stateCode}
          />
        </ErrorBoundary>
        {/* <ErrorBoundary message={``}>
          <PopularProducts numberOfProductsToShow={4} stateCode={stateCode} />
        </ErrorBoundary> */}
        {localStorage.getItem("partnerStatus") !== "UNVERIFIED" && (
          <ErrorBoundary message={``}>
            <Box sx={{ marginTop: "16px" }}>
              {banners &&
                banners.length >= 3 &&
                banners.slice(3, banners.length).map((banner, index) => {
                  return (
                    <BottomBanner
                      key={index + 2}
                      index={index + 2}
                      banner={banner}
                      loadingBanners={loadingBanners}
                    />
                  );
                })}
            </Box>
          </ErrorBoundary>
        )}
        <ErrorBoundary>
          <FooterImageLogo />
        </ErrorBoundary>
        {localStorage.getItem("partnerStatus") === "UNVERIFIED" ? (
          <ErrorBoundary>
            <NeedHelp title={`Need Help`} />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <FloatingActionButton
              title={getMessage(`complaintHistory.fabHeader`)}
              fabIconPath={`icons/complaintFABIcon.svg`}
              currentPagePath={"Dashboard"}
              clickedEventTitle={`Fab Clicked`}
              nextPagePath={`/complaints/create`}
              isFromProfilePage={true}
              buttonOrder={1}
            />
          </ErrorBoundary>
        )}

        <PayNowDialog
          open={showPayNowDialog}
          setOpen={setShowPayNowDialog}
          paymentAmount={payNowPaymentAmount}
          lendingPartner={PartnerService.getLendingPartner()}
          handleClose={handleClosePayNow}
          handlePaymentStatus={handlePaymentStatus}
          handleRazorpayFailure={handleRazorpayFailure}
          paymentLoading={paymentLoading}
          setPaymentLoading={setPaymentLoading}
          paymentType={"due"}
          initiationFailureRetryRedirectionLink={`/`}
        />

        <Dialog
          open={showPaymentInProgressDialog}
          onClose={handleShowPaymentInProgressDialogClose}
        >
          <DialogContent>
            <Typography textAlign={"center"}>
              {getPayNowDisabledReasonMessage()}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={handleShowPaymentInProgressDialogClose}
              sx={{
                marginTop: "8px",
                textTransform: "capitalize",
              }}
            >
              Okay
            </Button>
          </DialogContent>
        </Dialog>

        <BackDropLoader
          open={
            paymentLoading === "started" ||
            loadingAgreementSigningWindow === "started"
          }
        />
      </div>
    </div>
  );
}

export default Dashboard;
