import config from "../config";
import AddressService from "./AddressService";
import SentryService from "./SentryService";
import HTTPService from "./HTTPService";

export default class CartCheckoutService {
  static isFieldAgent = localStorage.getItem("role") === "fieldAgent";

  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getCart = (cart, otherCart) => {
    const { billingAddressId, shippingAddressId } =
      AddressService.getShippingAndBillingAddress();
    const usedAgrostarPointsAmount =
      otherCart?.billDetails?.usableAgroStarPoints || null;

    const requestData = {
      farmerId: localStorage.getItem("farmerId"),
      requestSource: localStorage.getItem(`source`),
    };
    requestData[cart.cartType] = {
      cartId: cart.cartId,
      advanceDate: 0,
      billingAddressID: billingAddressId,
      shippingAddressID: shippingAddressId,
      orderSource: requestData.requestSource,
      autoAddOn: false,
      usedRealCash: false,
      usedAgrostarPoints: true,
    };
    if (otherCart) {
      requestData[otherCart.cartType] = {
        cartId: otherCart.cartId,
        advanceDate: 0,
        billingAddressID: billingAddressId,
        shippingAddressID: shippingAddressId,
        orderSource: requestData.requestSource,
        autoAddOn: false,
        usedRealCash: false,
        usedAgrostarPoints: true,
      };
    }
    const url = `${config.backendUrl}onlineecommerceservice/v2/saathicartcheckout/`;
    const { axiosRequest, abortController } = HTTPService.makeXHRRequest(
      url,
      "POST",
      requestData,
      this.getHeaders()
    );
    const promise = new Promise((resolve, reject) => {
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            if (response.data.responseData.CASH_ONLY_ORDER) {
              response.data.responseData.cash =
                response.data.responseData.CASH_ONLY_ORDER;
              delete response.data.responseData.CASH_ONLY_ORDER;
            }
            if (response.data.responseData.CREDIT_ORDER) {
              response.data.responseData.credit =
                response.data.responseData.CREDIT_ORDER;
              delete response.data.responseData.CREDIT_ORDER;
            }
            return resolve(response.data.responseData);
          } else return resolve({});
        })
        .catch((error) => {
          SentryService.captureExceptionWithData(error, requestData);
          return reject(error);
        });
    });
    return { axiosRequest: promise, abortController };
  };

  static putCart = (cart, otherCart) => {
    const { billingAddressId, shippingAddressId } =
      AddressService.getShippingAndBillingAddress();
    const usedAgrostarPointsAmount =
      otherCart?.billDetails?.usableAgroStarPoints || null;
    const requestData = {};
    requestData[cart.cartType] = {
      farmerId: localStorage.getItem("farmerId"),
      requestSource: localStorage.getItem(`source`),
      cartType: cart.cartType,
      billingAddressID: billingAddressId,
      shippingAddressID: shippingAddressId,
      cartId: cart.cartId,
      appliedCoupon: cart.appliedCoupon,
      appliedPromo: cart.appliedPromo,
      products: cart.products,
      usedRealCash: cart?.usedRealCash && this.isFieldAgent ? true : false,
      usedAgrostarPoints: true,
      // usedAgrostarPointsAmount
      usedRealCashAmountOnThisCart: cart?.usedRealCashAmountOnThisCart,
    };
    if (otherCart) {
      requestData[otherCart.cartType] = {
        farmerId: localStorage.getItem("farmerId"),
        requestSource: localStorage.getItem(`source`),
        cartType: otherCart.cartType,
        billingAddressID: billingAddressId,
        shippingAddressID: shippingAddressId,
        cartId: otherCart.cartId,
        appliedCoupon: otherCart.appliedCoupon,
        appliedPromo: otherCart.appliedPromo,
        products: otherCart.products,
        usedRealCash: false,
        usedAgrostarPoints: true,
        usedRealCashAmountOnThisCart: 0,
      };
    }
    const url = `${config.backendUrl}onlineecommerceservice/v2/saathicartcheckout/`;
    const { axiosRequest } = HTTPService.makeXHRRequest(
      url,
      "PUT",
      requestData,
      this.getHeaders()
    );
    return new Promise((resolve, reject) => {
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            if (response.data.responseData.CASH_ONLY_ORDER) {
              response.data.responseData.cash =
                response.data.responseData.CASH_ONLY_ORDER;
              delete response.data.responseData.CASH_ONLY_ORDER;
            }
            if (response.data.responseData.CREDIT_ORDER) {
              response.data.responseData.credit =
                response.data.responseData.CREDIT_ORDER;
              delete response.data.responseData.CREDIT_ORDER;
            }
            return resolve(response.data.responseData);
          } else return resolve({});
        })
        .catch((error) => {
          if (cart.products.length > 0) {
            return reject(error);
          }
          return resolve({});
        });
    });
  };

  static reviewCart = (cart, otherCart) => {
    const { billingAddressId, shippingAddressId } =
      AddressService.getShippingAndBillingAddress();
    const usedAgrostarPointsAmount =
      otherCart?.billDetails?.usableAgroStarPoints || null;

    const requestData = {
      farmerId: localStorage.getItem("farmerId"),
      requestSource: localStorage.getItem(`source`),
      cartType: cart.cartType,
      billingAddressID: billingAddressId,
      shippingAddressID: shippingAddressId,
      cartId: cart.cartId,
      appliedCoupon: cart.appliedCoupon,
      appliedPromo: cart.appliedPromo,
      products: cart.products,
      orderType: "",
      shippingProvider: "CUSTOM",
      advanceDate: 0,
      CODAmount: 0,
      totalStoreDiscount: 0,
      usedAgrostarPoints: true,
      usedRealCash: cart?.usedRealCash && this.isFieldAgent ? true : false,
      // usedAgrostarPointsAmount
    };
    const url = `${config.backendUrl}onlineecommerceservice/v1/saathireviewcart/`;
    const { axiosRequest, abortController } = HTTPService.makeXHRRequest(
      url,
      "POST",
      requestData,
      this.getHeaders()
    );
    const promise = new Promise((resolve, reject) => {
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData)
            return resolve(response.data.responseData);
          else return resolve({});
        })
        .catch((error) => {
          return reject(error);
        });
    });
    return { axiosRequest: promise, abortController };
  };

  static placeOrder = (reviewCart, checkoutCart, paymentType, otherCart) => {
    const { billingAddressId, shippingAddressId } =
      AddressService.getShippingAndBillingAddress();
    const usedAgrostarPointsAmount =
      otherCart?.billDetails?.usableAgroStarPoints || null;

    const requestData = {
      farmerId: parseInt(localStorage.getItem("farmerId")),
      userId: parseInt(localStorage.getItem("userId")),
      requestSource: localStorage.getItem(`source`),
      cartId: reviewCart.cartId,
      paymentDetails: {
        agrostarWallet: {
          usedRealCash:
            checkoutCart?.agrostarWallet?.realCash?.amountUsable &&
            this.isFieldAgent
              ? true
              : false,
          usedAgrostarPoints: true,
          // usedAgrostarPointsAmount: usedAgrostarPointsAmount,
          usedRealCashAmountInThisOrder:
            checkoutCart?.agrostarWallet?.realCash?.amountUsable,
        },
        paymentType: paymentType,
        paymentAmountDetails: {
          totalPaymentToBeCollected:
            checkoutCart.billDetails.saathiCreditAmount,
          totalCashDiscountToBePassed: checkoutCart.cashDiscountBenefit.amount,
          actualPaymentToBeCollected:
            paymentType === "CASH"
              ? checkoutCart.billDetails.saathiAdvancePayAmount
              : checkoutCart.billDetails.saathiCreditAmount,
        },
      },
      advanceOrderDate: 0,
      billingAddressId: billingAddressId,
      shippingAddressId: shippingAddressId,
      shippingProviderId: "",
      shippingChannel: "",
      orderType: "",
      deliveryData: {
        tatObjectId: reviewCart.orderDeliveryTAT.tatObjectId,
        shippingProvider: reviewCart.orderDeliveryTAT.shippingProvider,
        deliveryDate: reviewCart.orderDeliveryTAT.maxDeliveryDate,
        facilityCode: reviewCart.orderDeliveryTAT.facilityCode,
        readyToShipTime: reviewCart.orderDeliveryTAT.readyToShipTime,
      },
      totalStoreDiscount: 0,
      unfulfillableSKUs: reviewCart.unfulfillableSKUs || [],
    };
    const requestHeaders = this.getHeaders();
    if (localStorage.getItem("role") === "fieldAgent") {
      requestHeaders["Request-Client"] = "FIELD-SAATHI-APP";
    }
    const url = `${config.backendUrl}onlineecommerceservice/v1/saathiorder/${requestData.farmerId}/orders/`;
    const { axiosRequest } = HTTPService.makeXHRRequest(
      url,
      "POST",
      requestData,
      requestHeaders
    );
    return new Promise((resolve, reject) => {
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            const order = response.data.responseData;
            order.paymentAmountDetails =
              requestData.paymentDetails.paymentAmountDetails;
            return resolve(order);
          } else return resolve({});
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  static getPromos() {
    const url = `${config.backendUrl}offerservice/v1/promotion/?farmerId=${localStorage.getItem("farmerId")}&startDateTime=${Date.now()}&endDateTime=${Date.now()}&type=VOUCHER&subType=AGROREFERRAL,AGROPLUS,SCANANDEARN,TURNOVER,NEWCUSTOMERBENEFIT&freeFloating=false`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }
}
