import {
  Box,
  Button,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import AndroidService from "../../Services/AndroidService";
import SwipeableDrawerHeader from "../Common/SwipeableDrawerHeader";
import TodSettledSaleBreakupDetails from "./TodSettledSaleBreakupDetails";
import { useEffect, useState } from "react";
import SchemesService from "../../Services/SchemesService";
import ErrorIcon from "@mui/icons-material/Error";
import AnalyticsService from "../../Services/AnalyticsService";

const TodSettledSaleBreakup = ({ turnoverScheme, open, setOpen }) => {
  const [settledSaleDetails, setSettledSaleDetails] = useState(null);
  const [loading, setLoading] = useState("not-started");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (turnoverScheme?.promotionUUID && open) {
      setLoading("started");
      const { axiosRequest, abortController } =
        SchemesService.getSettledSaleBreakup(turnoverScheme?.promotionUUID);
      axiosRequest
        .then((response) => {
          let settledSaleBreakupResponse = response?.data?.responseData;
          setSettledSaleDetails(settledSaleBreakupResponse);
          setLoading("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            if (error.data) {
              setError(error.data);
              console.log(error.data);
            } else {
              setError(error);
              console.log(error);
            }
            setLoading("done");
          }
        });
      return () => abortController.abort();
    }
  }, [turnoverScheme?.promotionUUID, open]);

  return (
    <SwipeableDrawer
      anchor={`bottom`}
      open={open}
      PaperProps={{
        style: {
          borderRadius: "24px 24px 0px 0px",
          maxHeight: "85vh",
          height: "85vh",
        },
      }}
      onOpen={() => AndroidService.unsetPullToRefresh()}
      onClose={() => {
        setOpen(false);
        AndroidService.setPullToRefresh();
        AnalyticsService.pushEvent("Drawer Closed", {
          type: "Settled Sale Breakup",
        });
      }}
      disableDiscovery={true}
      disableSwipeToOpen={true}
    >
      <Box sx={{ padding: 2, paddingBottom: 8 }}>
        <SwipeableDrawerHeader
          handleOnClose={() => {
            setOpen(false);
            AndroidService.setPullToRefresh();
            AnalyticsService.pushEvent("Drawer Closed", {
              type: "Settled Sale Breakup",
            });
          }}
        />
        {loading === "started" ? (
          <>
            <Skeleton
              variant={`rectangular`}
              height={200}
              sx={{ mt: 3, borderRadius: "16px" }}
            />
            <Skeleton
              variant={`rectangular`}
              height={200}
              sx={{ mt: 3, borderRadius: "16px" }}
            />
          </>
        ) : loading === "done" ? (
          error ? (
            <Box sx={{ textAlign: "center", mt: 8 }}>
              <ErrorIcon sx={{ color: "#9D2123", fontSize: "56px" }} />
              <Typography sx={{ fontSize: "14px", mt: 1.5 }}>
                Something went wrong. Please try again.
              </Typography>
            </Box>
          ) : !settledSaleDetails?.settlementsCnDistributions ||
            settledSaleDetails?.settlementsCnDistributions?.length === 0 ? (
            <Box sx={{ textAlign: "center", mt: 8 }}>
              <img src={`/icons/EmptyPage.svg`} alt="" />
              <Typography sx={{ fontSize: "14px", mt: 1.5 }}>
                Settled sale details will be available after the first payment
              </Typography>
            </Box>
          ) : (
            <>
              <TodSettledSaleBreakupDetails
                settledSaleDetails={settledSaleDetails}
                tableHeaderBackground={"rgba(0, 115, 62, 0.05)"}
                tableBodyBackground={"rgba(0, 115, 62, 0.1)"}
              />
            </>
          )
        ) : (
          <></>
        )}
      </Box>
      <Button
        size={"large"}
        sx={{ borderRadius: 0, position: "fixed", bottom: 0 }}
        variant={"contained"}
        color={"primary"}
        fullWidth={true}
        onClick={() => {
          setOpen(false);
          AndroidService.setPullToRefresh();
          AnalyticsService.pushEvent("Drawer Closed", {
            type: "Settled Sale Breakup",
          });
        }}
      >
        Okay
      </Button>
    </SwipeableDrawer>
  );
};
export default TodSettledSaleBreakup;
