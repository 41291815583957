import { Card, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  productCard: {
    border: "none",
    marginTop: theme.spacing(1.25),
    width: "95%",
    marginLeft: "8px",
  },
  productCardContent: {
    padding: theme.spacing(0.9, 1, 0.5, 1),
  },
  productImage: {
    height: "100%",
    width: "100%",
  },
  productName: {
    lineHeight: `1.3`,
    marginLeft: theme.spacing(-1),
  },
  productDetails: {
    color: "#666666",
  },
}));

const ProductDetails = (props) => {
  const classes = useStyles();
  console.log(props);
  let data = props.productData;

  return (
    <>
      <Card className={classes.productCard} variant={"outlined"}>
        <div className={classes.productCardContent}>
          <Grid
            container
            direction={`row`}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            spacing={0}
          >
            <Grid item xs={3}>
              <img src={data.image} alt="" className={classes.productImage} />
            </Grid>
            <Grid item xs={8}>
              <Grid
                container
                direction={`column`}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                spacing={0}
              >
                <Grid
                  container
                  direction={`row`}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  spacing={0}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2">{data.name}</Typography>

                    <div style={{ display: "flex" }}>
                      <div>
                        <Typography variant="body2" sx={{ fontSize: "12px" }}>
                          <b>Composition</b>&nbsp;- <br />
                          {data.composition}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default ProductDetails;
