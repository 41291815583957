import config from "../config";
import BrandsMock from "./mocks/BrandsMock";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class BrandService {
  static getHeaders() {
    return {
      source: localStorage.getItem(`source`),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static getGlobalBrands() {
    if (useMocks === false) {
      const url = `${config.backendUrl}catalogservice/v2/feauredbrands?language=en`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: BrandsMock });
      });
    }
  }

  static getAllBrands() {
    if (useMocks === false) {
      const url = `${config.backendUrl}catalogservice/v2/brand?language=en`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: BrandsMock });
      });
    }
  }

  static getBrandsForCategory(categoryName) {
    if (useMocks === false) {
      const url = `${config.backendUrl}shopaggregator/v1/categorydetail?source=B2BMH&language=mr&categoryName=${categoryName}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: BrandsMock });
        }, 10000);
      });
    }
  }

  static getProductsForBrandByName(brandName) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }shopaggregator/v1/products?brandName=${brandName}&language=en&farmerId=${localStorage.getItem(
        `farmerId`
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: BrandsMock });
      });
    }
  }
}
