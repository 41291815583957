import _ from "lodash";
import config from "../config";
import AddressMock from "./mocks/AddressMock";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class AddressService {
  static OldOrderAddressId = null;
  static OrderAddressId = null;
  static AddressMap = null;

  static setAddresses() {
    const { axiosRequest, abortController } = this.getAddresses();
    const promise = axiosRequest.then((addressResponse) => {
      if (addressResponse?.data?.responseData) {
        const addressesList = [];
        const addressesFromBackend = addressResponse?.data?.responseData;
        addressesFromBackend.billingAddresses.forEach((address) => {
          addressesList.push(address);
        });
        addressesFromBackend.shippingAddresses.forEach((address) => {
          addressesList.push(address);
        });

        const addressMap = addressesList.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {});
        let id = this.OldOrderAddressId;
        if (_.isNil(id) || _.isNil(addressMap[id])) {
          this.OldOrderAddressId = addressesList[0].id;
        }
        let currentid = this.OrderAddressId;
        if (
          _.isNil(currentid) ||
          _.isNil(addressMap[currentid]) ||
          currentid === "null"
        ) {
          this.OrderAddressId = this.OldOrderAddressId;
        }
        this.AddressMap = JSON.stringify(addressMap);
        return addressesList.length;
      }
      return 0;
    });

    return { axiosRequest: promise, abortController };
  }

  static setAddressForCart(addressId) {
    this.OrderAddressId = addressId;
  }

  static getAddressForCart() {
    const addresses = JSON.parse(this.AddressMap);
    const val = this.OrderAddressId;
    return addresses[val];
  }

  static setOldAddressForCart() {
    const old = this.getAddressForCart();
    this.OldOrderAddressId = old.id;
  }

  static getShippingAndBillingAddress() {
    const address = this.getAddressForCart();
    const shippingAddressId = address.SAddressId;
    const billingAddressId = address.BAddressId || address.id;
    return { shippingAddressId, billingAddressId };
  }

  static getOldAddressForCart() {
    const addresses = JSON.parse(this.AddressMap);
    const val = this.OldOrderAddressId;
    return addresses[val];
  }

  static getAddresses() {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }onlineecommerceservice/v1/addresses/?language=en&farmerId=${localStorage.getItem(
        `farmerId`
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, {
        source: localStorage.getItem(`source`),
        "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
      });
    } else {
      return new Promise((resolve) => {
        resolve({ data: AddressMock });
      });
    }
  }
}
