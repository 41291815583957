import React, { useEffect, useState } from "react";

import ErrorBoundary from "../Common/ErrorBoundary";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import PaymentService from "../../Services/PaymentService";
import useRazorpay from "react-razorpay";
import _, { parseInt } from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";
import { useNavigate, useParams } from "react-router-dom";
import BlockUi from "react-block-ui";
import config from "../../config";
import useQuery from "../../Hooks/useQuery";
import SentryService from "../../Services/SentryService";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    borderRadius: "0",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "0.56px",
    textTransform: "uppercase",
  },
}));

const OrderPaymentRequest = (props) => {
  const classes = useStyles();
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const query = useQuery();
  const { error } = useParams();
  const errorCode = query.get("errorCode");
  const redirect = query.get("redirect")
    ? `?redirect=${query.get("redirect")}`
    : "";
  const redirectWithAmpersandPrefix = query.get("redirect")
    ? `&redirect=${query.get("redirect")}`
    : "";

  const order = localStorage.getItem("order")
    ? JSON.parse(localStorage.getItem("order"))
    : null;

  if (order === null || (order && order.paid)) {
    if (query.get("redirect") === "cart") {
      navigate("/cart", { replace: true });
    } else {
      window.history.replaceState(null, null, "/dashboard");
      window.location.reload();
    }
  }

  useEffect(() => {
    if (!error) {
      handleProceedPaymentPayorder();
    } else {
      SentryService.captureErrorWithData("Razorpay Failure Dialog Shown", {
        error,
        errorCode,
      });
      setPaymentLoading("done");
    }
  }, []);

  const [showProceedPaymentFailureDialog, setShowProceedPaymentFailureDialog] =
    useState(error ? true : false);
  const [proceedPaymentError, setProceedPaymentError] = useState(error);
  const [proceedPaymentErrorCode, setProceedPaymentErrorCode] =
    useState(errorCode);
  const [paymentLoading, setPaymentLoading] = useState("started");

  function handlePaymentViaWebSDK(razorpayConfig) {
    setPaymentLoading("done");

    let razorpayInstance = new Razorpay(razorpayConfig);
    razorpayInstance.open();
    razorpayInstance.on("payment.failed", function (response) {
      AnalyticsService.pushEvent("Payment Initiation Failure", {
        code: response?.error?.code,
        description: response?.error?.description,
        source: response?.error?.source,
        step: response?.error?.step,
        reason: response?.error?.reason,
        orderId: response?.error?.metadata?.order_id,
        paymentId: response?.error?.metadata?.payment_id,
        type: "order",
        amount: razorpayConfig?.amount,
        sdk: "web",
      });
      setProceedPaymentError("RAZORPAY_FAILURE");
      setProceedPaymentErrorCode(response?.error?.code);
      setShowProceedPaymentFailureDialog(true);
    });

    AnalyticsService.pushEvent("Payment Initiation Successful", {
      type: "order",
      amount: razorpayConfig?.amount,
      sdk: "web",
    });
  }

  const handleProceedPaymentPayorder = () => {
    const order = localStorage.getItem("order")
      ? JSON.parse(localStorage.getItem("order"))
      : null;
    if (!order) return;
    if (order && order.paid) return;
    const orderIds = _.map(order.placedOrders, (order) => `${order.orderId}`);
    const amountToPay = order.paymentAmountDetails.actualPaymentToBeCollected;
    let initiationErrorCode = null;
    const { axiosRequest } = PaymentService.initiatePayment({
      paymentType: "order",
      amount: amountToPay,
      orderIds,
    });
    axiosRequest
      .then((response) => {
        if (
          response?.data?.responseData &&
          !_.isEmpty(response?.data?.responseData)
        ) {
          let responseData = response.data.responseData;
          console.log({ responseData });
          let razorPayConfig = {
            key: responseData.apiKey,
            amount: amountToPay,
            currency: "INR",
            name: "AgroStar",
            description: "Payment Amount",
            order_id: responseData.razorpayOrderId,
            callback_url: `${config.paymentRedirectionUrl}paymentapi/v1/paymentredirect?type=order${redirectWithAmpersandPrefix}`,
            // callback_url: `${config.appUrl}/order/payment-status/`,
            redirect: true,
            remember_customer: false,
            hidden: {
              contact: true,
              email: true,
            },
            modal: {
              confirm_close: true,
              ondismiss: function (response) {
                initiationErrorCode = "PAYMENT_MODAL_CLOSED";
                setProceedPaymentError(initiationErrorCode);
                setProceedPaymentErrorCode(null);
                setShowProceedPaymentFailureDialog(true);
                AnalyticsService.pushEvent("Razorpay Payment SDK Closed");
              },
            },
            prefill: {
              // name: localStorage.getItem("partnerName"),
              email: "rzpcustomer.payment@agrostar.in",
              contact: localStorage.getItem("mobile"),
              // method: "upi",
            },

            theme: {
              color: "#9D2123",
            },
            // notes: {
            //   address: "Razorpay Corporate Office",
            // },
            razorpaySuccessCallbackUrl: `${config.appUrl}/order/payment-status/${redirect}`,
            razorpayFailureCallbackUrl: `${config.appUrl}/order/razorpay-failure/RAZORPAY_FAILURE${redirect}`,
          };
          console.log({ razorPayConfig });
          if (responseData.apiKey && responseData.razorpayOrderId) {
            if (AndroidService.checkIfAndroid()) {
              if (AndroidService.getAndroidVersion()) {
                // setOpenDebug(true);

                let { appVersionCode } = AndroidService.getAndroidVersion();
                // setOpenDebug(true);
                // setDebugMessage(
                //   `in get version; android version ${appVersionCode}`
                // );
                if (appVersionCode && parseInt(appVersionCode) >= 8) {
                  handlePaymentViaAndroidNativeSDK(razorPayConfig);
                } else {
                  // setOpenDebug(true);
                  // setDebugMessage(`No appVersionCode;`);
                  handlePaymentViaWebSDK(razorPayConfig);
                }
              } else {
                // setOpenDebug(true);
                // setDebugMessage(`No getAppVersion();`);
                handlePaymentViaWebSDK(razorPayConfig);
              }
            } else {
              // setOpenDebug(true);
              // setDebugMessage(`No Android;`);
              handlePaymentViaWebSDK(razorPayConfig);
            }
          } else {
            setPaymentLoading("done");

            if (!responseData.razorpayOrderId) {
              initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_ORDER_ID";
              setProceedPaymentError(initiationErrorCode);
              setProceedPaymentErrorCode(null);
              setShowProceedPaymentFailureDialog(true);
            }
            if (!responseData.apiKey) {
              initiationErrorCode = "PAYMENT_INIT_NO_RAZORPAY_API_KEY";
              setProceedPaymentError(initiationErrorCode);
              setProceedPaymentErrorCode(null);
              setShowProceedPaymentFailureDialog(true);
            }
          }
        } else {
          initiationErrorCode = "PAYMENT_INIT_NO_RESPONSE_DATA";
          setProceedPaymentError(initiationErrorCode);
          setProceedPaymentErrorCode(null);
          setShowProceedPaymentFailureDialog(true);
          setPaymentLoading("done");
        }
        if (initiationErrorCode) {
          AnalyticsService.pushEvent("Payment Initiation Failed", {
            type: "order",
            amount: amountToPay,
            errorCode: initiationErrorCode,
          });
        }
      })
      .catch((error) => {
        initiationErrorCode = "PAYMENT_INIT_FAILED";
        SentryService.captureCustomErrorMessage("PAYMENT_INIT_FAILED", error);
        setProceedPaymentError(initiationErrorCode);
        setProceedPaymentErrorCode(null);
        AnalyticsService.pushEvent("Payment Initiation Failed", {
          type: "order",
          amount: amountToPay,
          errorCode: initiationErrorCode,
        });
        setShowProceedPaymentFailureDialog(true);
        setPaymentLoading("done");
      })
      .finally(() => {});
  };

  const handlePaymentViaAndroidNativeSDK = (razorPayConfig) => {
    setPaymentLoading("done");
    // delete razorPayConfig.modal.ondismiss;
    razorPayConfig.loadRazorpayCallbackUrlOnPaymentCancel = true;
    razorPayConfig.redirect = false;
    delete razorPayConfig.callback_url;

    let razorPayMetadataStringified = JSON.stringify(razorPayConfig);

    try {
      /*global Android*/
      if (Android) {
        Android.startRazorpayPaymentFlow(razorPayMetadataStringified);
        AnalyticsService.pushEvent("Payment Initiation Successful", {
          type: "order",
          amount: razorPayConfig?.amount,
          sdk: "android",
        });
      } else {
        // setOpenDebug(true);
        // setDebugMessage(`No Android;in native handler`);
        handlePaymentViaWebSDK(razorPayConfig);
      }
    } catch (e) {
      // setOpenDebug(true);
      // setDebugMessage(`in try native catch; error- ${JSON.stringify(e)}`);
      handlePaymentViaWebSDK(razorPayConfig);
    }
  };

  return (
    <Dialog fullWidth={true} fullScreen={true} open={true}>
      <ErrorBoundary message={``}>
        <BlockUi
          tag="div"
          blocking={paymentLoading === "started" && paymentLoading !== "done"}
          message={`Loading...`}
          keepInView
          renderChildren={false}
        >
          {showProceedPaymentFailureDialog && (
            <Box
              sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                background: "#FAEEEE",
              }}
            >
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                direction={"column"}
                style={{ height: "100%" }}
              >
                <Grid
                  item
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    src="/icons/payment-status-failed.svg"
                    height="150px"
                    alt=""
                  />
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ marginTop: "3vh" }}
                >
                  <Grid item>
                    <Typography
                      variant={"h6"}
                      align={"center"}
                      sx={{ fontWeight: "700" }}
                      color={"primary"}
                    >
                      Sorry, Payment Failed.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ marginTop: "1vh" }}
                >
                  <Grid item xs={9}>
                    <Typography
                      variant={"body1"}
                      align={"center"}
                      sx={{ color: "#757575" }}
                    >
                      Your transaction has failed, if you have been charged we
                      will contact you. Please click on retry.
                    </Typography>
                  </Grid>
                </Grid>
                {proceedPaymentError && (
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ marginTop: "3vh" }}
                  >
                    <Grid item>
                      <Typography
                        variant={"caption"}
                        align={"center"}
                        color={"primary"}
                        sx={{ color: "#222222" }}
                      >
                        {proceedPaymentError}
                        {!_.isNil(proceedPaymentErrorCode) && (
                          <Box>{`ERROR_CODE_${proceedPaymentErrorCode}`}</Box>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Box
                sx={{
                  height: "8vh",
                  display: "flex",
                }}
              >
                <Button
                  variant={"outlined"}
                  fullWidth={true}
                  color={"secondary"}
                  className={classes.buttonStyle}
                  sx={{
                    background: "#FFF",
                    "&:focus": {
                      backgroundColor: `#F5F5F5`,
                    },
                    "&:hover": {
                      backgroundColor: `#F5F5F5`,
                    },
                  }}
                  onClick={() => {
                    if (query.get("redirect") === "cart") {
                      navigate("/cart", { replace: true });
                    } else {
                      window.history.replaceState(null, null, "/dashboard");
                      window.location.reload();
                    }
                  }}
                >
                  {query.get("redirect") === "cart" ? "Go To Cart" : "Go Back"}
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  fullWidth={true}
                  className={classes.buttonStyle}
                  onClick={() => {
                    if (!error) {
                      setShowProceedPaymentFailureDialog(false);
                      handleProceedPaymentPayorder();
                      AnalyticsService.pushEvent(
                        "Payment Status Dialog Retry Payment Clicked",
                        {
                          errorCode: proceedPaymentError,
                        }
                      );
                    } else {
                      navigate(`/order/payment-request/${redirect}`, {
                        replace: true,
                      });
                    }
                  }}
                >
                  Retry Payment
                </Button>
              </Box>

              <IconButton
                size={"large"}
                sx={{
                  color: "#757575",
                  marginRight: "6px",
                  position: "fixed",
                  top: 0,
                  right: 0,
                }}
                onClick={() => {
                  if (query.get("redirect") === "cart") {
                    navigate("/cart", { replace: true });
                  } else {
                    window.history.replaceState(null, null, "/dashboard");
                    window.location.reload();
                  }
                }}
              >
                <Close />
              </IconButton>
            </Box>
          )}
        </BlockUi>
      </ErrorBoundary>
    </Dialog>
  );
};

export default OrderPaymentRequest;
