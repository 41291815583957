const Languages = {
  MAHARASHTRA: "mr",
  RAJASTHAN: "hi",
  "UTTAR PRADESH": "hi",
  "MADHYA PRADESH": "hi",
  GUJARAT: "gu",
  BIHAR: "hi",
  HARYANA: "hi",
  TELANGANA: "te",
  KARNATAKA: "kn",
};

export default Languages;
