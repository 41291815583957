import React, { useState, useEffect } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import _ from "lodash";
import SectionTitle from "../Common/SectionTitle";
import {
  getTime,
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
  getMonth,
  getYear,
  set,
} from "date-fns";
import { Container, Grid, Typography, Box } from "@mui/material";
import ErrorBoundary from "../Common/ErrorBoundary";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";
import PurchaseDetails from "./PurchaseDetails";
import TopProductCard from "./TopProductCard";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyError from "../EmptyError/EmptyError";
import PartnerStatsFilter from "./PartnerStatsFilter";
import { Info } from "@mui/icons-material";

const Vyapaar = () => {
  const TIME_DURATION_DROPDOWN_FILTERS = [
    { label: "Select Month(s)", value: "defaultDummyFilter" },
    {
      label: "This Month",
      value: "thisMonth",
    },
    {
      label: "Last Month",
      value: "lastMonth",
    },
    {
      label: "Last 3 Months",
      value: "lastThreeMonths",
    },
    {
      label: "This Financial Year",
      value: "thisFinancialYear",
    },
    {
      label: "Last Financial Year",
      value: "lastFinancialYear",
    },
    {
      label: "Custom Date",
      value: "dateRange",
    },
  ];

  const PRODUCT_CATEGORY_FILTERS = [
    {
      label: "All Products",
      identifier: "",
      selected: true,
    },
    {
      label: "AgroStar Products",
      identifier: "PL",
      selected: false,
    },
  ];

  const getThisFinancialYearDates = () => {
    if (getMonth(new Date()) > 2) {
      const fromDate = getTime(set(new Date(), { month: 3, date: 1 }));
      let financialYearEnd = getYear(new Date()) + 1;
      const toDate = getTime(
        set(new Date(), { year: financialYearEnd, month: 2, date: 31 })
      );
      return [fromDate, toDate];
    } else {
      let financialYearStart = getYear(new Date()) - 1;
      const fromDate = getTime(
        set(new Date(), { year: financialYearStart, month: 3, date: 1 })
      );
      const toDate = getTime(set(new Date(), { month: 2, date: 31 }));
      return [fromDate, toDate];
    }
  };

  const [fromDate, setFromDate] = useState(getThisFinancialYearDates()?.[0]);
  const [toDate, setToDate] = useState(getThisFinancialYearDates()?.[1]);
  const [topProducts, setTopProducts] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const [error, setError] = useState(null);
  const [
    partnerStatsProductCategoryFilter,
    setPartnerStatsProductCategoryFilter,
  ] = useState(
    _.find(PRODUCT_CATEGORY_FILTERS, (filter) => {
      return filter?.selected;
    })
  );
  const [partnerStatsDropdownFilter, setPartnerStatsDropdownFilter] =
    useState("thisFinancialYear");
  const [partnerStatsDateRangeFilter, setPartnerStatsDateRangeFilter] =
    useState(null);
  const [categoryWisePurchaseDetails, setCategoryWisePurchaseDetails] =
    useState(null);
  const [classWisePurchaseDetails, setClassWisePurchaseDetails] =
    useState(null);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [netPurchase, setNetPurchase] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTopProducts, setTotalTopProducts] = useState(0);
  const [currentTableTab, setCurrentTableTab] = useState(`categoryWiseSale`);
  const [previousDropdownFilterValue, setPreviousDropdownFilterValue] =
    useState("thisFinancialYear");
  const [
    totalTopProductsAtProductGroupLevel,
    setTotalTopProductsAtProductGroupLevel,
  ] = useState(0);
  const PRODUCTS_TO_SHOW_IN_A_SINGLE_SCROLL = 10;

  useEffect(() => {
    AnalyticsService.pushEvent("Page Viewed", { page: "vyapaar" });
  }, []);

  useEffect(() => {
    let selectedDateRangeValues = getTime(
      new Date(partnerStatsDateRangeFilter)
    );
    if (selectedDateRangeValues !== 0) {
      setFromDate(getTime(new Date(partnerStatsDateRangeFilter?.[0])));
      setToDate(getTime(new Date(partnerStatsDateRangeFilter?.[1])));
      setPartnerStatsDropdownFilter("dateRange");
    }
  }, [partnerStatsDateRangeFilter]);

  useEffect(() => {
    setLoading("started");
    setCurrentPage(1);
    const { axiosRequest, abortController } =
      PartnerService.getPartnerPurchaseHistory(
        fromDate,
        toDate,
        currentPage,
        PRODUCTS_TO_SHOW_IN_A_SINGLE_SCROLL,
        partnerStatsProductCategoryFilter?.identifier,
        true,
        true
      );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          let partnerPurchaseData = response.data.responseData;
          setCategoryWisePurchaseDetails(
            partnerPurchaseData.summary.categoryWise
          );
          setClassWisePurchaseDetails(partnerPurchaseData.summary.classWise);
          setTotalPurchase(partnerPurchaseData?.summary?.grossRevenue);
          setTotalReturn(partnerPurchaseData?.summary?.returnRevenue);
          setNetPurchase(partnerPurchaseData?.summary?.netRevenue);
          let skuLevelProducts = _.map(
            partnerPurchaseData?.topnetrevenueproducts,
            (data) => {
              return data.skuData;
            }
          );
          skuLevelProducts = _.flatten(skuLevelProducts);
          setTopProducts(skuLevelProducts);
          setTotalTopProductsAtProductGroupLevel(
            partnerPurchaseData?.topnetrevenueproducts?.length
          );
          setTotalTopProducts(partnerPurchaseData?.pagination?.totalCount);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          if (error?.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, [fromDate, toDate, partnerStatsProductCategoryFilter]);

  const fetchMoreProducts = () => {
    const { axiosRequest } = PartnerService.getPartnerPurchaseHistory(
      fromDate,
      toDate,
      currentPage + 1,
      PRODUCTS_TO_SHOW_IN_A_SINGLE_SCROLL,
      partnerStatsProductCategoryFilter?.identifier,
      false,
      false
    );
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData?.topnetrevenueproducts) {
          let partnerPurchaseData = response.data.responseData;
          let fetchedProducts = _.map(
            partnerPurchaseData?.topnetrevenueproducts,
            (data) => {
              return data.skuData;
            }
          );
          fetchedProducts = _.flatten(fetchedProducts);
          setTopProducts((previousProducts) => [
            ...previousProducts,
            ...fetchedProducts,
          ]);
          setTotalTopProductsAtProductGroupLevel(
            (prevCount) =>
              prevCount + partnerPurchaseData?.topnetrevenueproducts?.length
          );
        } else {
          console.log(`no top products found`);
        }
      })
      .catch((error) => {
        console.log(`error getting products`);
        if (error.data) {
          console.log(error.data);
        } else {
          console.log(error);
        }
      });

    setCurrentPage((previousPageNumber) => {
      return previousPageNumber + 1;
    });
  };

  const handleChangePartnerStatsDropdownFilter = (event) => {
    if (event.target.value) {
      AnalyticsService.pushEvent(`Button Clicked`, {
        type: "filter",
        page: "vyapaar",
        filterName: "Select month(s)",
        filterOption: event.target.value,
      });
    }
    setPartnerStatsDropdownFilter(event.target.value);
    setCurrentPage(1);
    if (event.target.value === "thisMonth") {
      const fromDate = getTime(startOfMonth(new Date()));
      const toDate = getTime(new Date());

      setFromDate(fromDate);
      setToDate(toDate);
      setPartnerStatsDateRangeFilter(null);
      setPreviousDropdownFilterValue("thisMonth");
    }
    if (event.target.value === "lastMonth") {
      const fromDate = getTime(startOfMonth(subMonths(new Date(), 1)));
      const toDate = getTime(endOfMonth(subMonths(new Date(), 1)));

      setFromDate(fromDate);
      setToDate(toDate);
      setPartnerStatsDateRangeFilter(null);

      setPreviousDropdownFilterValue("lastMonth");
    }
    if (event.target.value === "lastThreeMonths") {
      const fromDate = getTime(startOfMonth(subMonths(new Date(), 3)));
      const toDate = getTime(endOfMonth(subMonths(new Date(), 1)));

      setFromDate(fromDate);
      setToDate(toDate);
      setPartnerStatsDateRangeFilter(null);
      setPreviousDropdownFilterValue("lastThreeMonths");
    }
    if (event.target.value === "thisFinancialYear") {
      setFromDate(getThisFinancialYearDates()?.[0]);
      setToDate(getThisFinancialYearDates()?.[1]);
      setPartnerStatsDateRangeFilter(null);
      setPreviousDropdownFilterValue("thisFinancialYear");
    }
    if (event.target.value === "lastFinancialYear") {
      if (getMonth(new Date()) >= 0 && getMonth(new Date()) <= 2) {
        let financialYearStart = getYear(new Date()) - 2;
        const fromDate = getTime(
          set(new Date(), { year: financialYearStart, month: 3, date: 1 })
        );
        let financialYearEnd = getYear(new Date()) - 1;
        const toDate = getTime(
          set(new Date(), { year: financialYearEnd, month: 2, date: 31 })
        );
        setFromDate(fromDate);
        setToDate(toDate);
      } else {
        let financialYearStart = getYear(new Date()) - 1;
        const fromDate = getTime(
          set(new Date(), { year: financialYearStart, month: 3, date: 1 })
        );
        const toDate = getTime(set(new Date(), { month: 2, date: 31 }));
        setFromDate(fromDate);
        setToDate(toDate);
      }
    }
  };
  const handleClearAllFilters = () => {
    AnalyticsService.pushEvent(`Button Clicked`, {
      type: "Clear All Filters",
      page: "vyapaar",
    });
    setPartnerStatsDropdownFilter("thisFinancialYear");
    setFromDate(getThisFinancialYearDates()?.[0]);
    setToDate(getThisFinancialYearDates()?.[1]);
    setPartnerStatsProductCategoryFilter(PRODUCT_CATEGORY_FILTERS?.[0]);
    setPartnerStatsDateRangeFilter(null);
    setPreviousDropdownFilterValue("thisFinancialYear");
    setCurrentPage(1);
  };
  return (
    <Container maxWidth={`xl`} sx={{ paddingBottom: "120px" }}>
      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "75vh" }}
      >
        <ErrorBoundary>
          <PartnerStatsFilter
            statsFilters={PRODUCT_CATEGORY_FILTERS}
            partnerStatsProductCategoryFilter={
              partnerStatsProductCategoryFilter
            }
            setPartnerStatsProductCategoryFilter={
              setPartnerStatsProductCategoryFilter
            }
            partnerStatsDateRangeFilter={partnerStatsDateRangeFilter}
            setPartnerStatsDateRangeFilter={setPartnerStatsDateRangeFilter}
            partnerStatsDropdownFilter={partnerStatsDropdownFilter}
            setPartnerStatsDropdownFilter={setPartnerStatsDropdownFilter}
            handleChangePartnerStatsDropdownFilter={
              handleChangePartnerStatsDropdownFilter
            }
            menuList={TIME_DURATION_DROPDOWN_FILTERS}
            previousDropdownFilterValue={previousDropdownFilterValue}
            setCurrentPage={setCurrentPage}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          {fromDate && toDate ? (
            <Grid container direction={"row"} sx={{ marginTop: "12px" }}>
              <Grid item xs={9}>
                <Typography variant={`body2`}>
                  From {fromDate ? format(fromDate, "dd/MM/yyyy") : ""} to{" "}
                  {toDate ? format(toDate, "dd/MM/yyyy") : ""}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "end" }}>
                <Typography
                  sx={{
                    color: "#9D2123",
                    fontWeight: "700",
                    fontSize: "14px",
                  }}
                  onClick={handleClearAllFilters}
                >
                  Clear All
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </ErrorBoundary>

        {!error && (
          <ErrorBoundary>
            <PurchaseDetails
              setCurrentTableTab={setCurrentTableTab}
              currentTableTab={currentTableTab}
              categoryWisePurchaseDetails={categoryWisePurchaseDetails}
              classWisePurchaseDetails={classWisePurchaseDetails}
              totalPurchase={totalPurchase}
              totalReturn={totalReturn}
              netPurchase={netPurchase}
            />
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Info color="disabled" fontSize="small" />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgba(51, 51, 51, 0.80)",
                  marginLeft: "3px",
                }}
              >
                Purchase and Return are based on invoiced amount
              </Typography>
            </Box>
          </ErrorBoundary>
        )}

        <ErrorBoundary>
          {topProducts?.length > 0 ? (
            <>
              <SectionTitle title={"Top Products Purchased"} />
              <Box sx={{ paddingBottom: "120px" }}>
                <InfiniteScroll
                  dataLength={totalTopProductsAtProductGroupLevel}
                  next={fetchMoreProducts}
                  hasMore={
                    totalTopProductsAtProductGroupLevel < totalTopProducts
                  }
                  loader={
                    <h4 style={{ textAlign: "center", fontSize: "16px" }}>
                      Loading...
                    </h4>
                  }
                >
                  {_.map(topProducts, (product, index) => (
                    <TopProductCard product={product} key={index} />
                  ))}
                </InfiniteScroll>
              </Box>
            </>
          ) : (
            <></>
          )}
        </ErrorBoundary>

        {loading === "done" && error ? (
          <EmptyError
            image="EmptyPage.svg"
            text=""
            subText="Something went wrong. Please try again later."
            hideBack={true}
            extraStyle={{ minHeight: "50vh" }}
          />
        ) : (
          <></>
        )}
      </BlockUi>
    </Container>
  );
};

export default Vyapaar;
