import React, { useState } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { Tab, Tabs } from "@mui/material";

import Hisaab from "./HisaabPage";
import Transactions from "./TransactionsPage";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import AnalyticsService from "../../Services/AnalyticsService";
import NeedHelp from "../Common/NeedHelp";
import Orders from "./Orders";
import OnboardingStatusNudge from "../OnboardPartner/OnboardingStatusNudge";
import PayByProductGroup from "../ProductGroup/PayByProductGroup";
import BackDropLoader from "../Common/BackDropLoader";

const useStyles = makeStyles(() => ({
  tabs: {
    background: "#ffffff",
    position: "sticky",
    top: 0,
    zIndex: 4,
  },
  tab: {
    width: "33.33%",
    color: "#333333",
    textTransform: "capitalize",
    fontSize: "12px",
    padding: "12px 14px",
  },
  payByProductTabs: {
    background: "#fff",
    margin: "10px 16px",
    padding: "0px 16px",
    borderRadius: "20px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
  },
}));

const hisaabTabs = [
  {
    name: "Hisaab",
    path: "/hisaab",
    eventProperties: { tabName: "hisaab", pageName: "transaction" },
  },
  {
    name: "Pay by Invoice",
    path: "/hisaab/pay-by-product-group",
    ordersPath: "/hisaab/orders",
    eventProperties: { tabName: "orders", pageName: "hisaab" },
  },
  {
    name: "Transactions",
    path: "/hisaab/transactions",
    eventProperties: { tabName: "transaction", pageName: "hisaab" },
  },
];

const payByProductTabs = [
  { name: "Products", path: "/hisaab/pay-by-product-group" },
  { name: "Invoices", path: "/hisaab/orders" },
];

function HisaabIndex() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [loadingAgreementSigningWindow, setLoadingAgreementSigningWindow] =
    useState("not-started");

  function useRouteMatch(patterns) {
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  }

  const routeMatch = useRouteMatch([
    "/hisaab",
    "/hisaab/transactions",
    "/hisaab/orders",
    "/hisaab/pay-by-product-group",
  ]);
  const currentTab = routeMatch?.pathname;

  return (
    <>
      <ErrorBoundary message={``}>
        <TopBar
          title={`SAATHI`}
          showCart={
            localStorage.getItem("partnerStatus") === "INACTIVE" ? false : true
          }
          showHisaab={false}
        />
      </ErrorBoundary>

      <ErrorBoundary message={``}>
        <Tabs value={currentTab} className={classes.tabs}>
          {hisaabTabs.map((hisaabTab, index) => (
            <Tab
              key={index}
              label={hisaabTab.name}
              value={
                currentTab === hisaabTab.ordersPath
                  ? hisaabTab.ordersPath
                  : hisaabTab.path
              }
              to={hisaabTab.path}
              component={Link}
              className={classes.tab}
              onClick={() => {
                AnalyticsService.pushEvent("Tab Clicked", {
                  tabName: hisaabTab.eventProperties.tabName,
                  page: hisaabTab.eventProperties.pageName,
                });
              }}
            />
          ))}
        </Tabs>
      </ErrorBoundary>

      <ErrorBoundary message={``}>
        <OnboardingStatusNudge
          setLoadingAgreementSigningWindow={setLoadingAgreementSigningWindow}
        />
      </ErrorBoundary>

      {(pathname === hisaabTabs[1].path ||
        pathname === hisaabTabs[1].ordersPath) && (
        <Tabs
          value={currentTab}
          variant="fullWidth"
          className={classes.payByProductTabs}
        >
          {payByProductTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.name}
              value={tab.path}
              to={tab.path}
              component={Link}
              sx={{
                fontSize: "12px",
                textTransform: "none",
                opacity: currentTab === tab.path ? 1 : 0.6,
                color: currentTab === tab.path ? "#9D2123" : "#333333",
              }}
              onClick={() => {
                AnalyticsService.pushEvent(`Tab Clicked`, {
                  tabName: tab.name,
                });
              }}
            />
          ))}
        </Tabs>
      )}

      {pathname === hisaabTabs[0].path && <Hisaab />}
      {pathname === hisaabTabs[1].ordersPath && <Orders />}
      {pathname === hisaabTabs[1].path && <PayByProductGroup />}
      {pathname === hisaabTabs[2].path && <Transactions />}

      <NeedHelp title={`Need Help`} />
      <BackDropLoader open={loadingAgreementSigningWindow === "started"} />
    </>
  );
}

export default HisaabIndex;
