import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import EastIcon from "@mui/icons-material/East";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import OrderService from "../../Services/OrderService";
import AnalyticsService from "../../Services/AnalyticsService";
import CommonConstantValues from "../../Services/CommonConstantValues";

export default function AcceptOrCancelOrder({
  order,
  showConfirmationDialog,
  setShowConfirmationDialog,
  loadingCancelOrder,
  loadingAcceptOrder,
  handleAcceptCashOrderRequest,
  handleAcceptCreditOrderRequest,
  handleAcceptCreditOrderRequestForLendingPartner,
  handleCancelOrderRequest,
}) {
  const [confirmationType, setConfirmationType] = useState(null);

  useEffect(() => {
    if (showConfirmationDialog) {
      AnalyticsService.pushEvent("Dialog Shown", {
        type:
          confirmationType === "ACCEPT_CREDIT_ORDER_WAITING_FOR_APPROVAL"
            ? "Approve Order"
            : confirmationType ===
                "ACCEPT_CREDIT_ORDER_WAITING_FOR_LENDING_PARTNER_APPROVAL"
              ? "Approve Order Confirmation By Lending Partner"
              : confirmationType === "CANCEL"
                ? "Cancel Order"
                : confirmationType === "LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS"
                  ? "Previous Payment In Progress"
                  : "",
      });
    }
  }, [showConfirmationDialog, confirmationType]);

  const disableCancelOrderButton = () => {
    if (order?.orderType === CommonConstantValues.IPT_FORWARD_ORDER) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Grid container sx={{ position: "fixed", bottom: 0, marginTop: 2 }}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              borderRadius: 0,
              fontWeight: "bold",
              padding: "13px 16px",
              background: "#FFFFFF",
              color: "#9D2123",
              boxShadow: "0 -4px 10px 0 rgba(129, 129, 129, 0.3)",
              "&:hover": {
                background: "#F5F5F5",
              },
              "&:disabled": {
                backgroundColor: "grey",
                color: "white",
              },
            }}
            onClick={() => {
              if (disableCancelOrderButton()) {
                return;
              }
              AnalyticsService.pushEvent("Button Clicked", {
                type: "Cancel Order",
              });
              setShowConfirmationDialog(true);
              if (
                OrderService.isLastSuccessfulPaymentInProgressForOrder(order)
              ) {
                setConfirmationType("LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS");
              } else {
                setConfirmationType("CANCEL");
              }
            }}
            disabled={disableCancelOrderButton()}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          {order?.crmStatus === "WAITING_FOR_APPROVAL" ||
          order?.crmStatus === "WAITING_FOR_LENDING_PARTNER_APPROVAL" ? (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              sx={{
                borderRadius: 0,
                boxShadow: "none",
                fontWeight: "bold",
                padding: "13px 16px",
              }}
              onClick={() => {
                AnalyticsService.pushEvent("Button Clicked", {
                  type: "Approve Order",
                });
                if (order?.crmStatus === "WAITING_FOR_APPROVAL") {
                  setConfirmationType(
                    "ACCEPT_CREDIT_ORDER_WAITING_FOR_APPROVAL"
                  );
                  setShowConfirmationDialog(true);
                } else if (
                  order?.crmStatus === "WAITING_FOR_LENDING_PARTNER_APPROVAL"
                ) {
                  setConfirmationType(
                    "ACCEPT_CREDIT_ORDER_WAITING_FOR_LENDING_PARTNER_APPROVAL"
                  );
                  handleAcceptCreditOrderRequestForLendingPartner();
                }
              }}
            >
              Approve
            </Button>
          ) : order?.crmStatus === "ONLINE_PAYMENT_PENDING" &&
            order?.InternalNote === "FIELD-APP-SELF-CHECKOUT" ? (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              sx={{
                borderRadius: 0,
                boxShadow: "none",
                fontWeight: "bold",
                padding: "13px 16px",
              }}
              onClick={() => {
                if (
                  OrderService.isLastSuccessfulPaymentInProgressForOrder(order)
                ) {
                  setShowConfirmationDialog(true);
                  setConfirmationType("LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS");
                } else {
                  handleAcceptCashOrderRequest();
                }
              }}
              endIcon={<EastIcon />}
            >
              Pay Now
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={showConfirmationDialog}
        onClose={() => {
          setShowConfirmationDialog(false);
          if (confirmationType === "LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS") {
            window.location.reload();
          }
        }}
        PaperProps={{
          style: {
            borderRadius: "32px",
            padding: "24px",
            minWidth: "70vw",
          },
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          {confirmationType === "ACCEPT_CREDIT_ORDER_WAITING_FOR_APPROVAL" ||
          confirmationType ===
            "ACCEPT_CREDIT_ORDER_WAITING_FOR_LENDING_PARTNER_APPROVAL" ? (
            <ThumbUpAltRoundedIcon fontSize={"large"} color="secondary" />
          ) : confirmationType === "CANCEL" ? (
            <ErrorIcon fontSize={"large"} sx={{ color: "#9D2123" }} />
          ) : confirmationType === "LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS" ? (
            <GppMaybeOutlinedIcon
              fontSize={"large"}
              sx={{ color: "#CC8800" }}
            />
          ) : (
            <></>
          )}

          <Typography variant={`body1`} sx={{ marginTop: 1 }}>
            {confirmationType === "ACCEPT_CREDIT_ORDER_WAITING_FOR_APPROVAL" ||
            confirmationType ===
              "ACCEPT_CREDIT_ORDER_WAITING_FOR_LENDING_PARTNER_APPROVAL"
              ? "Approve Order?"
              : confirmationType === "CANCEL"
                ? "Are you sure you want to cancel?"
                : confirmationType === "LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS"
                  ? "Previous payment is in progress. Please wait for some time."
                  : ""}
          </Typography>

          {loadingCancelOrder === "started" ||
          loadingAcceptOrder === "started" ? (
            <Box sx={{ marginTop: 2.5 }}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Grid
              container
              spacing={4}
              justifyContent={"center"}
              sx={{ marginTop: 0 }}
            >
              {(confirmationType === "CANCEL" ||
                confirmationType ===
                  "ACCEPT_CREDIT_ORDER_WAITING_FOR_APPROVAL" ||
                confirmationType ===
                  "ACCEPT_CREDIT_ORDER_WAITING_FOR_LENDING_PARTNER_APPROVAL") && (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant={"outlined"}
                    size={"large"}
                    color={
                      confirmationType === "CANCEL" ? "primary" : "secondary"
                    }
                    sx={{
                      borderRadius: "12px",
                      background: "rgba(255, 255, 255, 1)",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      setShowConfirmationDialog(false);
                      AnalyticsService.pushEvent("Dialog Button Clicked", {
                        dialogType:
                          confirmationType ===
                          "ACCEPT_CREDIT_ORDER_WAITING_FOR_APPROVAL"
                            ? "Approve Order Confirmation"
                            : confirmationType ===
                                "ACCEPT_CREDIT_ORDER_WAITING_FOR_LENDING_PARTNER_APPROVAL"
                              ? "Approve Order Confirmation By Lending Partner"
                              : confirmationType === "CANCEL"
                                ? "Cancel Order Confirmation"
                                : confirmationType ===
                                    "LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS"
                                  ? "Previous Payment In Progress"
                                  : "",
                        buttonType: "No",
                      });
                    }}
                  >
                    No
                  </Button>
                </Grid>
              )}
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant={"contained"}
                  size={"large"}
                  color={
                    confirmationType === "CANCEL" ? "primary" : "secondary"
                  }
                  sx={{ borderRadius: "12px", textTransform: "capitalize" }}
                  onClick={() => {
                    AnalyticsService.pushEvent("Dialog Button Clicked", {
                      dialogType:
                        confirmationType ===
                        "ACCEPT_CREDIT_ORDER_WAITING_FOR_APPROVAL"
                          ? "Approve Order Confirmation"
                          : confirmationType ===
                              "ACCEPT_CREDIT_ORDER_WAITING_FOR_LENDING_PARTNER_APPROVAL"
                            ? "Approve Order Confirmation By Lending Partner"
                            : confirmationType === "CANCEL"
                              ? "Cancel Order Confirmation"
                              : confirmationType ===
                                  "LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS"
                                ? "Previous Payment In Progress"
                                : "",
                      buttonType: "Yes",
                    });

                    if (confirmationType === "CANCEL") {
                      handleCancelOrderRequest();
                    } else if (
                      confirmationType ===
                      "ACCEPT_CREDIT_ORDER_WAITING_FOR_APPROVAL"
                    ) {
                      handleAcceptCreditOrderRequest();
                    } else if (
                      confirmationType ===
                      "ACCEPT_CREDIT_ORDER_WAITING_FOR_LENDING_PARTNER_APPROVAL"
                    ) {
                      handleAcceptCreditOrderRequestForLendingPartner();
                    } else if (
                      confirmationType === "LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS"
                    ) {
                      setShowConfirmationDialog(false);
                      window.location.reload();
                    }
                  }}
                >
                  {confirmationType === "LAST_SUCCESSFUL_PAYMENT_IN_PROGRESS"
                    ? "Okay"
                    : "Yes"}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Dialog>
    </>
  );
}
