import React from "react";
import { Box, Button, Card, Grid, Radio, Typography } from "@mui/material";
import AddressService from "../../Services/AddressService";
import { CheckCircle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  addressCard: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1.5),
    border: "1px solid white",
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
  },
  addressCardActive: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(1, 1.5),
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    border: "2px solid #00733E",
    background: "#E5F1EC",
  },
  lastUsedAddressSpan: {
    borderRadius: "5px",
    background: "#EAEAEA",
    width: "fit-content",
    padding: "0px 6px",
    marginBottom: "3px",
    display: "block",
  },
  lastUsedAddressSpanDark: {
    borderRadius: "5px",
    background: "#D5DFDB",
    width: "fit-content",
    padding: "0px 6px",
    marginBottom: "3px",
    display: "block",
  },
  changeAddressButton: {
    borderRadius: "10px",
    fontSize: "14px",
    marginTop: "4px",
  },
  addressWrapper: {
    fontSize: "12px",
    marginBottom: "4px",
    textTransform: "capitalize",
  },
  radioWrapper: {
    position: "absolute",
    top: "0",
    right: "-10px",
  },
}));

function AddressCard({
  currentTab,
  data,
  hideChangeAddress,
  showRadio,
  isChecked,
  handleOnSelected,
  onChangeAddress,
}) {
  const classes = useStyles();
  const address = data || AddressService.getAddressForCart();
  const addressPrevious = AddressService.getOldAddressForCart();
  const hideLastActiveAddress = address.id !== addressPrevious.id;

  return (
    <Card
      className={
        isChecked && showRadio ? classes.addressCardActive : classes.addressCard
      }
      variant="outlined"
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ position: "relative" }}
      >
        {showRadio && (
          <Box className={classes.radioWrapper}>
            <Radio
              icon={<CheckCircle color={"disabled"} />}
              checkedIcon={<CheckCircle />}
              color={"secondary"}
              checked={isChecked}
              onChange={handleOnSelected}
              value={address.id}
            />
          </Box>
        )}

        <Grid item xs={12}>
          <>
            <Typography
              variant={`body2`}
              className={classes.addressWrapper}
              sx={{ marginRight: showRadio ? "24px" : "0" }}
            >
              {!hideLastActiveAddress && (
                <span
                  className={
                    isChecked
                      ? classes.lastUsedAddressSpanDark
                      : classes.lastUsedAddressSpan
                  }
                >
                  Last Used Address
                </span>
              )}
              {`${address?.street},`}
              <br />
              {`${address?.village?.value} - ${address?.postOffice},`}
              <br />
              {`${address?.taluka?.value} - ${address?.district?.value} - ${
                address?.state?.value
              } - ${address?.pinCode || address?.pincode}`}
            </Typography>
          </>
          {!hideChangeAddress && (
            <Button
              size={"large"}
              fullWidth
              variant={`outlined`}
              className={classes.changeAddressButton}
              onClick={onChangeAddress}
            >
              Change Address
            </Button>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default AddressCard;
