import React from "react";
import { Container, InputAdornment, TextField } from "@mui/material";
import useFocus from "../../Hooks/useFocus";
import { Close } from "@mui/icons-material";
import AnalyticsService from "../../Services/AnalyticsService";

function UiSearch(props) {
  const [inputRef, setInputFocus] = useFocus();

  const handleKeyDown = (event) => {
    props.setSearchValue(event.target.value);
    if (event.key === "Enter") {
      props.setSearchValue(event.target.value);
    }
  };

  const handleValueChange = (event) => {
    if (!props.shouldBlockSendSearchQuerySentEvent) {
      const searchQueryEventData = {
        page: window.location.pathname,
        searchType: "textsearch",
        searchQuery: event.target.value,
      };
      AnalyticsService.pushEvent(`Search Query Sent`, searchQueryEventData);
    }
    props.setSearchValue(event.target.value);
  };

  const handleClickedSearch = () => {
    const searchClickedData = {
      page: window.location.pathname,
    };
    AnalyticsService.pushEvent(`Search Clicked`, searchClickedData);
  };

  const clearSearch = (event) => {
    const searchCanceledEventData = {
      page: window.location.pathname,
    };
    AnalyticsService.pushEvent(
      `Search Cancelled Icon Clicked`,
      searchCanceledEventData
    );

    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    props.setSearchValue("");
  };
  return (
    <Container sx={{ backgroundColor: "#FFFFFF" }} maxWidth={`xl`}>
      <TextField
        inputRef={inputRef}
        variant="outlined"
        sx={{
          marginBottom: 1,
          marginTop: 1,
          background: "#F3F3F3",
          border: "none",
          borderRadius: "10px",
        }}
        fullWidth={true}
        value={props.searchValue}
        onKeyDown={handleKeyDown}
        onChange={handleValueChange}
        onClick={handleClickedSearch}
        placeholder={
          props.placeholderText ? props.placeholderText : "Search Products"
        }
        size={`small`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" onClick={(event) => {}}>
              <img src={`/icons/search.svg`} alt="Search" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ cursor: "default", borderRadius: "10px" }}
            >
              {props.searchValue && props.searchValue.length > 0 && (
                <Close
                  color="primary"
                  onClick={(event) => {
                    clearSearch(event);
                  }}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
}

export default UiSearch;
