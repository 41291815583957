import config from "../config";
import { PaymentMock, PaymentStatusMock } from "./mocks/PaymentMock";
import { parseInt } from "lodash";
import HTTPService from "./HTTPService";

const useMocks = false;
export default class PaymentService {
  static getHeaders() {
    return {
      source: localStorage.getItem("source"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static initiatePayment({
    paymentType,
    amount,
    reason,
    orderId,
    orderIds,
    transactionReferenceNumber,
    checkoutOrderType,
    schemeName,
    productSalesGroup,
    redirectUrl,
    lendingPartnerDueAmount,
    lendingPartner,
  }) {
    let paymentData = {
      source: localStorage.getItem("source"),
      meta: {
        farmerId: localStorage.getItem("farmerId"),
        userId: parseInt(localStorage.getItem("userId")),
        paymentType:
          paymentType === "productsalesgroup" ? "product" : paymentType,
      },
      amount: amount,
    };
    //reason is treated as a remark in backend
    if (reason) {
      paymentData.meta.remarks = reason;
    }
    if (schemeName) {
      paymentData.meta.referenceType = schemeName || "";
    }

    if (paymentType === "order") {
      paymentData.meta.orderId = orderId;
      if (orderIds) paymentData.meta.orderId = orderIds.join(",");
      if (checkoutOrderType === "FIELD-APP-SELF-CHECKOUT") {
        paymentData.meta.checkoutOrderType = "FIELD-APP-SELF-CHECKOUT";
      }
    }
    if (paymentType === "productsalesgroup") {
      paymentData.meta.productSalesGroup = productSalesGroup;
    }
    if (transactionReferenceNumber) {
      paymentData.transactionReferenceNumber = transactionReferenceNumber;
    }
    if (redirectUrl) {
      // [SAT-1206] this is the URL which is used for redirection after payment on external credit line
      paymentData.redirectUrl = redirectUrl;
    }
    if (lendingPartnerDueAmount > 0) {
      paymentData.lendingPartnerDueAmount = lendingPartnerDueAmount;
    }
    paymentData.lendingPartner = lendingPartner ? lendingPartner : "AGROSTAR";

    if (useMocks === false) {
      const url = `${config.backendUrl}paymentapi/v1/paymentinit/`;
      return HTTPService.makeXHRRequest(
        url,
        "POST",
        paymentData,
        this.getHeaders()
      );
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: PaymentMock });
        }, 2000);
      });
    }
  }

  static getPaymentStatus(razorpayPaymentId) {
    if (useMocks === false) {
      const url = `${
        config.backendUrl
      }paymentapi/v1/paymentstatus/?razorpayPaymentId=${razorpayPaymentId}&source=${localStorage.getItem(
        `source`
      )}`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve({ data: PaymentStatusMock });
        }, 2000);
      });
    }
  }

  static downloadCreditNoteInvoice(transactionId) {
    if (useMocks === false) {
      const url = `${config.backendUrl}paymentapi/getcreditnotepdf/?transactionId=${transactionId}&requestType=download`;
      return HTTPService.makeXHRRequest(
        url,
        "GET",
        null,
        this.getHeaders(),
        "",
        "arraybuffer"
      );
    }
  }

  static getVANPaymentDetails() {
    const userId = localStorage.getItem("userId");
    const url = `${config.backendUrl}paymentapi/v2/creditcustomsettlement/?walletUserId=${userId}`;
    return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
  }

  static getCustomSettlementDetails({
    settlementType,
    listToSettle,
    creditTransactionId,
  }) {
    const walletUserId = localStorage.getItem("userId");
    const url = `${config.backendUrl}paymentapi/v2/creditcustomsettlement/`;
    const data = { walletUserId, settlementType, creditTransactionId };
    if (settlementType === "product") {
      data.productSalesGroupList = listToSettle;
    } else {
      data.orderIdList = listToSettle;
    }
    return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
  }

  static settleInvoices(transactionReferenceNumber) {
    const url = `${config.backendUrl}paymentapi/v2/settlecredittransaction/`;
    const data = { transactionReferenceNumber };
    return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
  }

  // Required for next iteration
  // static transferToABSScheme({ amount, transactionId, schemeName }) {
  //   const useMocks = true;
  //   if (useMocks) {
  //     return new Promise((resolve) => {
  //       window.setTimeout(() => {
  //         resolve({
  //           data: {
  //             message: "OK",
  //             status: true,
  //             responseData: {},
  //           },
  //         });
  //       }, 1000);
  //     });
  //   } else {
  //     const url = `${config.backendUrl}paymentapi/v2/transfercreditwallettransaction`;
  //     const data = {
  //       amount, // 1000
  //       transactionId, // "1234"
  //       schemeName, // "power gel"
  //     };

  //     return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
  //   }
  // }

  static isPaymentOverdue(dueDate) {
    const currentDate = Date.now();
    return dueDate < currentDate;
  }

  static isPaymentDueInNDays(dueDate, days) {
    const currentDate = Date.now();
    const diffInMs = dueDate - currentDate;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays <= days;
  }
}
