import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Button, Container, Grid, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SectionTitle from "../Common/SectionTitle";
import CategoryService from "../../Services/CategoryService";
import AnalyticsService from "../../Services/AnalyticsService";
import SentryService from "../../Services/SentryService";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: "-20px",
  },
  categoryListWrapper: {
    marginTop: theme.spacing(1),
    background: "white",
    padding: "14px",
    borderRadius: "20px",
  },
  categoryButton: {
    height: "60px",
    width: "50px",
    border: "none",
    background: "#9D2123",
    opacity: "0.1",
    borderRadius: "120px",
  },
  categoryName: {
    marginTop: theme.spacing(1),
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#9D2123",
  },
}));

function CategoriesList() {
  const classes = useStyles();
  const [categories, setCategories] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { axiosRequest, abortController } =
      CategoryService.getAllCategories();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          let categoriesFromResponse = _.find(
            response?.data?.responseData,
            (categoryObject) =>
              categoryObject.type === "SHOP_BY_CATEGORY" &&
              categoryObject?.data?.categories
          )?.data?.categories;
          if (categoriesFromResponse && categoriesFromResponse?.length > 0) {
            setCategories(categoriesFromResponse);
          } else {
            SentryService.captureErrorWithData(
              "Categories empty in response",
              response?.data?.responseData
            );
          }
        }
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting categories`);
          if (error.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
        }
      });
    return () => abortController.abort();
  }, []);

  const handleClickCategory = (categoryName, index) => {
    MiscUtils.saveScrollYPosition();
    const categoryClickedEventData = {
      categoryName: categoryName || "",
      position: index + 1 || "",
    };

    AnalyticsService.pushEvent(`Category Clicked`, categoryClickedEventData);
  };

  return (
    <>
      {!error && categories && (
        <Container maxWidth={`xl`} className={classes.root}>
          <SectionTitle title={`Shop by Category`} />
          <Grid
            container
            direction={`row`}
            justifyContent="center"
            alignItems="center"
            spacing={0}
            className={classes.categoryListWrapper}
            style={{
              paddingTop: categories?.length >= 5 ? "16px" : "14px",
              paddingBottom: categories?.length >= 5 ? "12px" : "14px",
            }}
          >
            {_.map(categories, (category, index) => {
              return (
                <Grid
                  item
                  xs={categories?.length < 5 ? 3 : 4}
                  key={index}
                  sx={{
                    textAlign: "center",
                    minHeight: categories?.length >= 5 ? "100px" : "",
                  }}
                >
                  <Link
                    to={`/categories/${category?.value}`}
                    onClick={() => handleClickCategory(category?.value, index)}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Button
                        fullWidth
                        variant={"outlined"}
                        className={classes.categoryButton}
                      ></Button>
                      <img
                        src={category?.url}
                        alt=""
                        height={35}
                        style={{ position: "absolute", alignSelf: "center" }}
                      />
                    </Box>
                  </Link>
                  <Typography
                    variant={"body1"}
                    className={classes.categoryName}
                  >
                    {category?.label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      )}
    </>
  );
}

export default CategoriesList;
