import _ from "lodash";

const storyHash = (story) =>
  `${story.storyId}|${story.startTime}|${story.endTime}`;

export const getCTADetails = (data) => {
  let ctaText = null;
  let ctaLink = null;
  let webLink = null;

  if (data?.externalLink) {
    ctaText = "Explore Now";
    webLink = data?.externalLink;
  }
  if (data?.productList?.length > 1) {
    ctaText = "Explore Now";
    ctaLink = `/products-offer/${data.productList.join(",")}`;
  }
  if (data?.productList?.length === 1) {
    ctaText = "Explore Now";
    ctaLink = `/products/${data.productList[0]}`;
  }
  return { ctaText, ctaLink, webLink };
};

export const getQuizData = (data) => {
  let question = null;
  let options = null;
  let quizId = null;
  let isAnswered = false;
  let isAnsweredCorrect = false;
  let correctAnswerIndex = -1;
  let userSelectedIndex = -1;

  if (data?.storyType === `QUIZ` && data?.quiz) {
    question = data?.quiz?.question;
    options = data?.quiz?.options;
    quizId = data?.quiz?.quizId;
    isAnswered = data?.quiz?.isAnswered;
    isAnsweredCorrect = data?.quiz?.isAnsweredCorrect;
    correctAnswerIndex = data?.quiz?.correctAnswerIndex;
    userSelectedIndex = data?.quiz?.userSelectedIndex;
  }
  return {
    question,
    options,
    quizId,
    isAnswered,
    isAnsweredCorrect,
    correctAnswerIndex,
    userSelectedIndex,
  };
};

export const convertToStories = (
  storyData,
  setStoryDuration,
  markStoryAsLikedOrUnliked,
  updateStoryWhenQuizIsAnswered
) => {
  const urls = new Set();
  const subStories = storyData.subStories;
  return _.reduce(
    subStories,
    (stories, subStory, index) => {
      const { mediaUrl, mediaType } = subStory;
      const { ctaLink, ctaText, webLink } = getCTADetails(subStory);
      const {
        question,
        options,
        quizId,
        isAnswered,
        isAnsweredCorrect,
        correctAnswerIndex,
        userSelectedIndex,
      } = getQuizData(subStory);
      const story_url =
        mediaType === "YOUTUBE"
          ? `https://www.youtube.com/watch?v=${mediaUrl}`
          : mediaUrl;
      if (subStory.storyType === "MEDIA" && urls.has(story_url)) {
        return stories;
      }
      urls.add(story_url);
      const story = {
        storyId: subStory.id,
        startTime: storyData.startTime,
        endTime: storyData.endTime,
        url: story_url,
        shortUrl: mediaUrl,
        type:
          subStory.storyType === "MEDIA"
            ? mediaType === "YOUTUBE"
              ? "video"
              : "image"
            : "quiz",
        header: {
          heading: storyData.name,
          subheading: "",
          profileImage: "",
        },
        ctaLink,
        ctaText,
        webLink,
        storyType: "",
        skuList: subStory.productList?.join(",") || "",
        section: subStory?.section || "STORIES",
        imageUrl: story_url || "",
        position: index,
        name: storyData?.name || "",
        storyParentCode: storyData?.code || "",
        numberOfStories: storyData?.subStories?.length,
        storyStatus: "New",
        question,
        options,
        quizId,
        isAnswered,
        isAnsweredCorrect,
        correctAnswerIndex,
        userSelectedIndex,
        isLiked: subStory.isLiked,
        totalLikeCount: subStory.totalLikeCount,
      };
      story.storyHash = storyHash(story);
      story.setStoryDuration = (duration) =>
        setStoryDuration(story.storyId, duration);
      story.markStoryAsLikedOrUnliked = (story, totalLikedCount) => {
        markStoryAsLikedOrUnliked(story, totalLikedCount, index);
      };
      story.updateStoryWhenQuizIsAnswered = (storyToBeUpdated, quizPayload) => {
        updateStoryWhenQuizIsAnswered(storyToBeUpdated, quizPayload, index);
      };
      if (story.type === "quiz") {
        story.duration = 20000;
      }
      return [...stories, story];
    },
    []
  );
};

export const getWeatherStory = (weather, setStoryDuration) => {
  const now = new Date();
  const timeAtZeroMinute = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
    0
  );
  const timeAtLastMinute = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    59
  );

  const story = {
    storyId: "weather",
    startTime: timeAtZeroMinute.getTime(),
    endTime: timeAtLastMinute.getTime(),
    url: "weather",
    shortUrl: "weather",
    type: "weather",
    content: weather,
    header: {
      heading: "Weather Update",
      subheading: "",
      profileImage: "",
    },
    ctaLink: null,
    ctaText: null,
    webLink: null,
    storyType: "weather",
    skuList: "",
    section: "weather",
    imageUrl: "weather",
    position: 0,
    name: "weather",
    storyParentCode: "weather",
    numberOfStories: 1,
    storyStatus: "New",
    duration: 5000,
  };
  story.storyHash = storyHash(story);
  story.setStoryDuration = (duration) => setStoryDuration("weather", duration);
  return [[story]];
};

export const reorderStories = (stories) => {
  if (localStorage.getItem("viewedStories")) {
    const viewedStories = JSON.parse(localStorage.getItem("viewedStories"));
    _.forEach(stories, (storyGroup) => {
      const viewed = viewedStories.includes(storyGroup[0].storyHash);
      _.forEach(storyGroup, (story) => {
        story.viewed = viewed;
        story.storyStatus = "Viewed";
      });
    });
  }
  stories.sort((a, b) => {
    if (a[0].type === "weather") return -1;
    if (b[0].type === "weather") return 1;
    return a[0].viewed === b[0].viewed ? 0 : a[0].viewed ? 1 : -1;
  });

  _.forEach(stories, (storyGroup, index) => {
    _.forEach(storyGroup, (story, subIndex) => {
      story.position = index;
      story.subPosition = subIndex;
    });
  });
  return stories;
};

export const markStoryViewedByIndex = (stories, storyIndex) => {
  const story = stories[storyIndex][0];
  const viewed = localStorage.getItem("viewedStories")
    ? JSON.parse(localStorage.getItem("viewedStories"))
    : [];
  viewed.push(story.storyHash);
  localStorage.setItem("viewedStories", JSON.stringify(viewed));
};

export const clearLocalStorageForStoriesExpired = () => {
  const viewed = localStorage.getItem("viewedStories");
  if (viewed) {
    let toCheckExpired = JSON.parse(viewed);
    toCheckExpired = _.filter(
      toCheckExpired,
      (storyHash) => parseInt(storyHash.split("|")[2]) > Date.now()
    );
    localStorage.setItem("viewedStories", JSON.stringify(toCheckExpired));
  }
};
