import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PaymentService from "../../Services/PaymentService";
import { parseInt } from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";
import { useNavigate, useParams } from "react-router-dom";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import AndroidService from "../../Services/AndroidService";
import useQuery from "../../Hooks/useQuery";

const PaymentStatus = (props) => {
  const { paymentId } = useParams();
  const navigate = useNavigate();
  const query = useQuery();
  const [paymentErrorCode, setPaymentErrorCode] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState("not-started");
  const [amount, setAmount] = useState(null);
  const [type, setType] = useState(null);
  const [transactionId, setTransactionId] = useState(null);

  const redirect = query.get("redirect")
    ? `?redirect=${query.get("redirect")}`
    : "";

  useEffect(() => {
    let errorCode = null;
    setLoading("started");
    const { axiosRequest, abortController } =
      PaymentService.getPaymentStatus(paymentId);
    axiosRequest
      .then((responsePaymentStatus) => {
        if (responsePaymentStatus?.data?.responseData) {
          let paymentStatusData = responsePaymentStatus.data.responseData;
          if (paymentStatusData?.status) {
            if (paymentStatusData?.status === "paid") {
              setPaymentStatus("success");
              AndroidService.pushEvent("Payment Status Check Successful", {
                amount: parseInt(paymentStatusData.paidAmount) / 100,
                type: paymentStatusData?.meta?.paymentType,
                transactionId: paymentStatusData?.transactionId,
              });
              AnalyticsService.pushEvent("Payment Status Dialog Shown", {
                status: paymentStatusData?.status,
                amount: parseInt(paymentStatusData.paidAmount) / 100,
                type: paymentStatusData?.meta?.paymentType,
                transactionId: paymentStatusData?.transactionId,
              });
              navigate(`/order/success${redirect}`, { replace: true });
            } else if (paymentStatusData?.status === "attempted") {
              setPaymentStatus("failure");
              errorCode = "PAYMENT_API_STATUS_ATTEMPTED";
            } else {
              setPaymentStatus("waiting");
              errorCode = "PAYMENT_API_STATUS_NOT_PAID";
            }
          } else {
            setPaymentStatus("waiting");
            errorCode = "PAYMENT_API_STATUS_NOT_PAID";
          }

          if (paymentStatusData?.paidAmount) {
            setAmount(parseInt(paymentStatusData.paidAmount) / 100);
          }
          if (paymentStatusData?.transactionId) {
            setTransactionId(paymentStatusData.transactionId);
          }
          if (paymentStatusData?.meta?.paymentType) {
            setType(paymentStatusData?.meta?.paymentType);
          }
        } else {
          setPaymentStatus("failure");
          errorCode = "PAYMENT_API_NO_RESPONSE_DATA";
        }
        if (errorCode) {
          setPaymentErrorCode(errorCode);
          AnalyticsService.pushEvent("Payment Status Check Failed", {
            errorCode: errorCode,
          });
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setPaymentStatus("failure");
          setLoading("done");
          errorCode = "PAYMENT_API_FAILED";
          setPaymentErrorCode(errorCode);
          AnalyticsService.pushEvent("Payment Status Check Failed", {
            errorCode: errorCode,
          });
          AnalyticsService.pushEvent("Payment Status Dialog Shown", {
            status: paymentStatus,
            errorCode: errorCode,
          });
        }
      })
      .finally(() => {});
    return () => abortController.abort();
  }, [paymentId]);

  return (
    <Dialog fullWidth={true} fullScreen={true} open={true}>
      <BlockUi
        tag="div"
        blocking={loading === "started" && loading !== "done"}
        message={`Loading...`}
        keepInView
        renderChildren={false}
        style={{ height: "95vh" }}
      >
        {paymentStatus === "waiting" && (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              background: "#FAEFDE",
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
              style={{ height: "100%" }}
            >
              <Grid
                item
                container
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src="/icons/payment-status-waiting.svg"
                  height="150px"
                  alt=""
                />
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "3vh" }}
              >
                <Grid item>
                  <Typography
                    variant={"h6"}
                    align={"center"}
                    sx={{ fontWeight: "700" }}
                    color={"primary"}
                  >
                    Payment is Processing
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "1vh" }}
              >
                <Grid item xs={9}>
                  <Typography
                    variant={"body1"}
                    align={"center"}
                    sx={{ color: "#757575" }}
                  >
                    Please wait for some time &<br /> click to check again.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                height: "8vh",
                display: "flex",
              }}
            >
              <Button
                variant={"contained"}
                color={"secondary"}
                fullWidth={true}
                sx={{ borderRadius: "0" }}
                onClick={() => {
                  AnalyticsService.pushEvent(
                    "Payment Status Dialog Refresh Status Clicked",
                    {
                      errorCode: paymentErrorCode,
                      amount: amount,
                      type: type,
                      transactionId: transactionId,
                    }
                  );
                }}
                href={`/order/payment-status/${paymentId}`}
              >
                Check Again
              </Button>
            </Box>
          </Box>
        )}

        {paymentStatus === "failure" && (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              background: "#FAEEEE",
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
              style={{ height: "100%" }}
            >
              <Grid
                item
                container
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src="/icons/payment-status-failed.svg"
                  height="150px"
                  alt=""
                />
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "3vh" }}
              >
                <Grid item>
                  <Typography
                    variant={"h6"}
                    align={"center"}
                    sx={{ fontWeight: "700" }}
                    color={"primary"}
                  >
                    Sorry, Payment Failed.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ marginTop: "1vh" }}
              >
                <Grid item xs={9}>
                  <Typography
                    variant={"body1"}
                    align={"center"}
                    sx={{ color: "#757575" }}
                  >
                    Your transaction has failed, if you have been charged we
                    will contact you. Please click on retry.
                  </Typography>
                </Grid>
              </Grid>
              {paymentErrorCode && (
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ marginTop: "3vh" }}
                >
                  <Grid item>
                    <Typography
                      variant={"caption"}
                      align={"center"}
                      color={"primary"}
                      sx={{ color: "#222222" }}
                    >
                      {paymentErrorCode}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Box
              sx={{
                height: "8vh",
                display: "flex",
              }}
            >
              <Button
                variant={"contained"}
                color={"secondary"}
                fullWidth={true}
                sx={{ borderRadius: "0" }}
                onClick={() => {
                  AnalyticsService.pushEvent("Retry Button Clicked", {
                    errorCode: paymentErrorCode,
                    amount: amount,
                    type: type,
                    transactionId: transactionId,
                  });
                  navigate(`/order/payment-request/${redirect}`, {
                    replace: true,
                  });
                }}
              >
                Retry Payment
              </Button>
            </Box>
          </Box>
        )}
        <IconButton
          size={"large"}
          sx={{
            color: "#757575",
            marginRight: "6px",
            position: "fixed",
            top: 0,
            right: 0,
          }}
          onClick={() => {
            AnalyticsService.pushEvent("Go To Cart Button Clicked", {
              errorCode: paymentErrorCode,
              amount: amount,
              type: type,
              transactionId: transactionId,
            });
            if (query.get("redirect") === "cart") {
              navigate("/cart", { replace: true });
            } else {
              window.history.replaceState(null, null, "/dashboard");
              window.location.reload();
            }
          }}
        >
          <Close />
        </IconButton>
      </BlockUi>
    </Dialog>
  );
};

export default PaymentStatus;
