import Fab from "@mui/material/Fab";
import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AnalyticsService from "../../Services/AnalyticsService";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  helpButton: {
    background: "rgba(0, 115, 62, 0.70)",
    position: "fixed",
    right: 16,
    [theme.breakpoints.up("sm")]: {
      right: "30vw",
    },
    color: "white",
    // bottom: 20,
    fontWeight: "700",
    fontSize: "15px",
    textTransform: "capitalize",
    borderRadius: "47.8px",
    height: "40px",
    boxShadow: "0px, 8.39px rgba(157, 33, 35, 0.2)",
  },
  buttonImage: {
    height: "22px",
  },
}));

const NeedHelp = (props) => {
  const classes = useStyles();
  const [scrollTop, setScrollTop] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const getPhoneNumber = () => {
    let stateName = localStorage.getItem(`state`);
    let stateWisePhoneNumbers = {
      Maharashtra: "07947085751",
      "Uttar Pradesh": "07947085751",
      Rajasthan: "07947085751",
      Gujarat: "07947085751",
      "Madhya Pradesh": "07947085751",
      Bihar: "07947085751",
      Haryana: "07947085751",
      Karnataka: "07947085751",
      "Andhra Pradesh": "07947085751",
      Chhattisgarh: "07947085751",
      Telangana: "07947085751",
    };
    if (stateWisePhoneNumbers[stateName]) {
      setPhoneNumber(stateWisePhoneNumbers[stateName]);
    }
  };

  useEffect(() => {
    getPhoneNumber();
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const handleClickPhoneDialer = () => {
    const needHelpEventData = {
      page: window.location.pathname?.substring(1),
      source: localStorage.getItem("source"),
      farmerId: localStorage.getItem("farmerId"),
      skuCode: props.skuCode || "",
    };
    AnalyticsService.pushEvent(`Need Help Clicked`, needHelpEventData);
    if (
      localStorage.getItem("partnerStatus") === "UNVERIFIED" ||
      props?.page === "Fill Partner Details"
    ) {
      window.location.href = phoneNumber
        ? `tel:${phoneNumber}`
        : "tel:07947085751";
    } else {
      window.location.href = "tel:9960107107";
    }
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Fab
          onClick={handleClickPhoneDialer}
          variant="extended"
          className={classes.helpButton}
          color="primary"
          style={{ bottom: 75, fontSize: "12px" }}
          sx={{
            "&:hover": {
              backgroundColor: "#00733E",
            },
          }}
        >
          <img
            src={`/icons/needHelp.svg`}
            className={classes.buttonImage}
            alt=""
          />
          {!scrollTop ? <>&nbsp;&nbsp;{props.title}</> : ""}
        </Fab>
      </Box>
    </>
  );
};

export default NeedHelp;
