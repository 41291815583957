import { Chip, Stack } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import _ from "lodash";
import AnalyticsService from "../../Services/AnalyticsService";
import { useNavigate, useLocation } from "react-router-dom";

const SelectedBrandFilters = (props) => {
  let navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClickRemoveFilter = (clickedBrand) => {
    if (props?.selectedBrands?.length <= 1) {
      props.setSelectedBrands([]);
      navigate(pathname);
    } else {
      let updatedSelectedBrandsList = _.filter(
        props.selectedBrands,
        (selectedBrand) => {
          return selectedBrand.brandId !== clickedBrand.brandId;
        }
      );
      props.setSelectedBrands(updatedSelectedBrandsList);
      navigate(
        `${pathname}?brandNames=${_.map(updatedSelectedBrandsList, (brand) =>
          brand?.brandName?.toLowerCase()
        ).join(",")}`
      );
    }

    AnalyticsService.pushEvent(`Remove Brand Filter Clicked`, {
      brandName: clickedBrand.brandName || ``,
      brandId: clickedBrand.brandId || ``,
    });
  };

  return (
    <>
      {props.selectedBrands?.length > 0 && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            padding: "16px 16px 0px 16px",
            marginBottom: "-8px",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {_.map(props.selectedBrands, (selectedBrand, index) => {
            return (
              <Chip
                key={index}
                label={selectedBrand.brandName}
                variant="outlined"
                onDelete={() => handleClickRemoveFilter(selectedBrand)}
                deleteIcon={
                  <CancelOutlinedIcon
                    sx={{
                      color: "#CACACA !important",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                }
                sx={{
                  background: "#FFFFFF",
                  boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1)",
                  border: "none",
                  fontSize: "12px",
                  fontWeight: "700",
                  color: "#4F4F4F",
                  height: "26px",
                }}
              />
            );
          })}
        </Stack>
      )}
    </>
  );
};

export default SelectedBrandFilters;
