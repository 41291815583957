import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AnalyticsService from "../../Services/AnalyticsService";

const OrderConfirmationNudge = ({ data: orders }) => {
  let navigate = useNavigate();
  const isFieldAgent = localStorage.getItem("role") === "fieldAgent";

  const handleNudgeClick = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Approve Order Nudge",
      page: window.location.pathname,
    });
    navigate(`/orders`);
  };

  return (
    !isFieldAgent &&
    orders?.length > 0 && (
      <Grid
        container
        alignItems={"center"}
        bgcolor={"#F8D149"}
        sx={{ minHeight: "50px", padding: "8px 16px" }}
        onClick={handleNudgeClick}
      >
        <Grid item xs={8.5}>
          <Typography
            color={"secondary"}
            fontSize={"12px"}
            paddingRight={"6px"}
          >
            <>
              You have {orders?.length > 1 ? "orders" : "an order"} pending for
              approval. Please <b>approve</b> the order
              {orders?.length > 1 ? "s" : ""}.
            </>
          </Typography>
        </Grid>
        <Grid item xs={3.5} display={"flex"} justifyContent={"flex-end"}>
          <Button
            size="small"
            variant="contained"
            sx={{
              fontSize: "12px",
              fontWeight: "700",
              borderRadius: "16px",
              textTransform: "none",
              lineHeight: "14px",
              padding: "8px 10px",
            }}
          >
            View Order{orders?.length > 1 ? "s" : ""}
          </Button>
        </Grid>
      </Grid>
    )
  );
};

export default OrderConfirmationNudge;
