import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Button, DialogContent, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import AnalyticsService from "../../Services/AnalyticsService";
import Languages from "../../Services/LanguageCode";

export default function ImageUploadInformationModal(props) {
  const [open, setOpen] = useState(false);

  const handleClickConfirmButton = () => {
    AnalyticsService.pushEvent("Capture Store Image Button Clicked", {
      page: "profile",
    });
    props.onConfirmUploadStoreImage();
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const languageCode = Languages[localStorage.getItem("state")?.toUpperCase()]
    ? Languages[localStorage.getItem("state")?.toUpperCase()]
    : "hi";

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.handleClose}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            height: "500px",
            width: "400px",
            backgroundColor: "#9D2123",
          },
        }}
      >
        <DialogContent
          sx={{
            backgroundImage: `url("/images/storeImageCaptureInstructions_${languageCode}.jpg")`,
            backgroundSize: "100% auto",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: "18px",
          }}
        >
          <Grid
            container
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{ height: "100%" }}
          >
            <Typography sx={{ textAlign: "right" }}>
              <Close
                onClick={props.handleClose}
                sx={{ color: "#FFFFFF", fontSize: "34px" }}
              />
            </Typography>

            <Button
              fullWidth
              onClick={handleClickConfirmButton}
              variant="outlined"
              sx={{
                backgroundColor: "#FFF",
                borderRadius: "16px",
                color: "#9D2123",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "700",
                "&:hover": {
                  backgroundColor: "#FFF",
                },
              }}
            >
              Take Photo
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
