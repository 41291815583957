import Languages from "./LanguageCode";
import config from "../config";
import StoryMock from "./mocks/StoryMock";
import quizSelectionMock from "./mocks/QuizSelectionMock";
import HTTPService from "./HTTPService";

const useMocks = false;

export default class StoryService {
  static getHeaders() {
    return {
      source:
        localStorage.getItem("source") ||
        localStorage.getItem("fieldAgentSource"),
      "X-Authorization-Token": localStorage.getItem(`X-Authorization-Token`),
    };
  }

  static markStoryLiked(story) {
    let userId = localStorage.getItem("userId");
    let actionToBePerform = story.isLiked ? "unlike" : "like";
    let url = `${config.backendUrl}contentservice/v1/storylike`;
    let data = {
      id: story.storyId,
      userId: parseInt(userId),
      action: actionToBePerform,
    };
    return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
  }

  static updateQuizSelection(index, quiz) {
    if (useMocks === false) {
      const languageCode = Languages[
        localStorage.getItem("state")?.toUpperCase()
      ]
        ? Languages[localStorage.getItem("state")?.toUpperCase()]
        : "en";
      let url = `${
        config.backendUrl
      }communicationservice/v2/answerquiz?farmerId=${localStorage.getItem(
        "farmerId"
      )}&isSaathi=true`;
      let data = {
        language: languageCode,
        quizId: quiz.quizId,
        selectedOption: index,
      };
      return HTTPService.makeXHRRequest(url, "POST", data, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: quizSelectionMock });
      });
    }
  }

  static getStories(district) {
    if (useMocks === false) {
      const languageCode = Languages[
        localStorage.getItem("state")?.toUpperCase()
      ]
        ? Languages[localStorage.getItem("state")?.toUpperCase()]
        : "en";
      //${localStorage.getItem("farmerId")}

      //I have added hard coded farmerId and District for testing purpose.
      let url = `${
        config.backendUrl
      }contentservice/v1/stories/${localStorage.getItem(
        "farmerId"
      )}/?district=${district}&isSaathi=true`;
      return HTTPService.makeXHRRequest(url, "GET", null, this.getHeaders());
    } else {
      return new Promise((resolve) => {
        resolve({ data: StoryMock });
      });
    }
  }
}
