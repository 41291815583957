import React, { useEffect } from "react";

import TopBar from "../Common/TopBar";
import AndroidService from "../../Services/AndroidService";
import AnalyticsService from "../../Services/AnalyticsService";

export default function Recovery() {
  const mobile = localStorage.getItem(`fieldAgentMobileNumber`);

  useEffect(() => {
    AnalyticsService.pushEvent("Page Viewed", {
      page: window.location.pathname,
    });

    AndroidService.unsetPullToRefresh();
    return () => AndroidService.setPullToRefresh();
  }, []);

  return (
    <div>
      <TopBar title="Recovery" backNavigation={true} />

      <embed
        type="application/xml"
        src={`https://lookerstudio.google.com/embed/u/0/reporting/59d44951-58dc-4be6-800c-7b342939bc86/page/giJ0D?params=%7B%22mob_no%22:%22${mobile}%22%7D`}
        width="100%"
        height={window.innerHeight - 56 + 6}
      />
    </div>
  );
}
