import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";

import AnalyticsService from "../../Services/AnalyticsService";
import ReactVideoPlayer from "../Common/ReactVideoPlayer";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  img: {
    // height: 270,
    maxWidth: 440,
    width: "100%",
    overflow: "hidden",
    display: "block",
    textAlign: "center",
    align: "center",
    margin: "auto",
    // margin: theme.spacing(2, 0),
  },
}));

function VideoPlayer(props) {
  return (
    <ReactVideoPlayer
      height={"56.25vw"}
      video={{ src: props.src, skuCode: props.skuCode }}
      autoplay={false}
      playing={false}
      muted={false}
      playbackStartedEventName="Banner Video Playback Started"
      playbackEndedEventName="Banner Video Playback Ended"
      playbackPausedEventName="Banner Video Playback Paused"
      eventProperties={{
        page: "Dashboard",
        skuCode: props.skuCode || "",
        // videoTitle: productInformationVideo?.title || "",
        videoUrl: props.src || "",
      }}
    />
  );
}

function BottomBanner(props) {
  const classes = useStyles();
  const [district, setDistrict] = useState("");

  const handleClickStaticBanners = () => {
    MiscUtils.saveScrollYPosition();
    const staticBannerClickedEventData = {
      bannerType: props?.banner?.data?.[0]?.type || "",
      skuList:
        props?.banner?.data?.[0]?.data?.sku?.toString() ||
        props?.banner?.data?.[0]?.data?.skus?.join(",") ||
        "",
      bannerCode: props?.banner?.code || "",
      imageUrl: props?.banner?.media?.[0]?.url || "",
    };

    AnalyticsService.pushEvent(`Banner Clicked`, staticBannerClickedEventData);
  };

  return (
    <>
      <Grid
        container
        direction={`row`}
        justifyContent={`center`}
        spacing={0}
        className={classes.root}
      >
        <Grid item xs={12}>
          {props.loadingBanners === true && (
            <Skeleton variant="rectangular" height={290} />
          )}
          {props.banner?.media?.[0]?.type === "IMAGE" &&
            props.banner?.media?.[0]?.url && (
              <div onClick={() => handleClickStaticBanners()}>
                {props.banner?.data?.[0]?.type === `PRODUCT_DETAILS` && (
                  <>
                    {props.banner?.data?.[0]?.data?.sku ? (
                      <Link
                        to={{
                          pathname: `/products/${props.banner.data[0].data.sku}`,
                          state: { from: window.location.pathname },
                        }}
                      >
                        <img
                          className={classes.img}
                          src={props.banner?.media?.[0]?.url}
                          alt={`banner`}
                        />
                      </Link>
                    ) : (
                      <img
                        className={classes.img}
                        src={props.banner?.media?.[0]?.url}
                        alt={`banner`}
                      />
                    )}
                  </>
                )}

                {props.banner?.data?.[0]?.type === `PRODUCT_LIST` && (
                  <>
                    {props.banner?.data?.[0]?.data?.skus?.length > 0 ? (
                      <Link
                        to={`/products-offer/${props.banner.data[0].data.skus.join(
                          ","
                        )}`}
                      >
                        <img
                          className={classes.img}
                          src={props.banner?.media?.[0]?.url}
                          alt={`banner`}
                        />
                      </Link>
                    ) : (
                      <img
                        className={classes.img}
                        src={props.banner?.media?.[0]?.url}
                        alt={`banner`}
                      />
                    )}
                  </>
                )}

                {props.banner?.data?.[0]?.type === `EXTERNAL_WEB_PAGE` && (
                  <a href={props.banner?.data?.[0]?.data?.url}>
                    <img
                      className={classes.img}
                      src={props.banner?.media?.[0]?.url}
                      alt={`banner`}
                    />
                  </a>
                )}
              </div>
            )}
          {props.banner?.media?.[0]?.type === "YOUTUBE" &&
            props.banner?.media?.[0]?.url && (
              <>
                <div onClick={() => handleClickStaticBanners()}>
                  {props.banner?.data?.[0]?.type === `NO_DATA` && (
                    <VideoPlayer
                      // className={classes.img}
                      src={`https://youtu.be/${props.banner?.media?.[0]?.url}`}
                      alt={`banner`}
                    />
                  )}
                </div>
              </>
            )}
        </Grid>
      </Grid>
    </>
  );
}

export default BottomBanner;
