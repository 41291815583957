import React from "react";
import Carousel from "react-material-ui-carousel";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MiscUtils from "../../Utils/MiscUtils";

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: 440,
    width: "100%",
    overflow: "hidden",
    display: "block",
    textAlign: "center",
    align: "center",
    margin: "auto",
  },
}));

function StaticScrollableBanners(props) {
  const classes = useStyles();

  return (
    <div onClick={() => MiscUtils.saveScrollYPosition()}>
      <Grid container direction={`row`} justifyContent={`center`} spacing={0}>
        <Grid item xs={12}>
          {props?.banners && props?.banners?.length > 0 && (
            <>
              <Carousel
                swipe={true}
                navButtonsAlwaysVisible={false}
                animation={`slide`}
                fullHeightHover={true}
                interval={9000}
                duration={600}
                activeIndicatorIconButtonProps={{
                  style: {
                    color: "#9D2123",
                  },
                }}
                indicatorIconButtonProps={{
                  style: {
                    background: "#F3F3F3",
                  },
                }}
              >
                {props?.banners?.map((bannerUrl, index) => (
                  <div key={index}>
                    <img
                      className={classes.img}
                      src={bannerUrl}
                      alt={`banner`}
                    />
                  </div>
                ))}
              </Carousel>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default StaticScrollableBanners;
