import _ from "lodash";
import { FormControl, MenuItem, Select } from "@mui/material";
import VyapaarDateRange from "./VyapaarDateRange";

function CustomDropdownSelector({
  selectedDropdownFilterValue,
  setSelectedDropdownFilterValue,
  handleChangeSelectedDropdownFilterValue,
  menuList,
  selectedDateRangeFilter,
  setSelectedDateRangeFilter,
  minDate,
  previousDropdownFilterValue,
}) {
  return (
    <FormControl
      sx={{
        height: "30px",
        background: "#FFFFFF",
        borderRadius: "16px",
        fontSize: "12px",
        margin: "0px 4px",
        boxShadow: "0px 0px 6px rgba(28, 28, 28, 0.1)",
      }}
    >
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={selectedDropdownFilterValue}
        onChange={handleChangeSelectedDropdownFilterValue}
        sx={{
          height: "100%",
          fontSize: "12px",
          fontWeight: "700",
          "&.Mui-focused fieldset": {
            border: "none !important",
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          border:
            selectedDropdownFilterValue !== "defaultDummyFilter"
              ? "1px solid #9D2123"
              : "",
          borderRadius: "16px",
          color:
            selectedDropdownFilterValue !== "defaultDummyFilter"
              ? "#9D2123"
              : "#4F4F4F",
          ".MuiSelect-select": {
            padding: "0px 14px",
          },
          ".MuiSelect-icon": {
            color:
              selectedDropdownFilterValue !== "defaultDummyFilter"
                ? "#9D2123"
                : "#4F4F4F",
          },
        }}
      >
        {_.map(menuList, ({ label, value }) => {
          return (
            <MenuItem
              key={value}
              value={value}
              sx={{
                whiteSpace: "normal",
                fontSize: "12px",
                display: value === "defaultDummyFilter" ? "none" : "",
              }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Select>
      {selectedDropdownFilterValue === "dateRange" &&
        selectedDateRangeFilter === null && (
          <VyapaarDateRange
            filterLabel="Date Range"
            maxDate={new Date()}
            selectedDateRangeFilter={selectedDateRangeFilter}
            setSelectedDateRangeFilter={setSelectedDateRangeFilter}
            minDate={minDate}
            setSelectedDropdownFilterValue={setSelectedDropdownFilterValue}
            previousDropdownFilterValue={previousDropdownFilterValue}
          />
        )}
    </FormControl>
  );
}

export default CustomDropdownSelector;
