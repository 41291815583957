import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { LinearProgress } from "@mui/material";

import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";
import AnalyticsService from "../../Services/AnalyticsService";

const useStyles = makeStyles(() => ({
  mainContent: {
    backgroundColor: "#F3F3F3",
  },
}));

function Safal() {
  const classes = useStyles();
  const { meetId } = useParams();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);

  useEffect(() => {
    if (meetId) {
      AnalyticsService.pushEvent(`Saathi Safal Meet Joined`);
      // if (AndroidService.checkIfAndroid()) {
      //   window.location.href = `gmeet://${meetId}`;
      // } else {
      window.location.href = `https://meet.google.com/${meetId}`;
      // }
    }
  }, [meetId]);

  return (
    <div>
      <ErrorBoundary message={``}>
        <TopBar
          title={`Safal Meet`}
          backNavigation={true}
          showCart={true}
          showHisaab={true}
        />
      </ErrorBoundary>
      <LinearProgress />
    </div>
  );
}

export default Safal;
