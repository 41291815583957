import { useNavigate } from "react-router-dom";
import { East } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import AmountUtils from "../../Utils/AmountUtils";
import { Box, Button, Card, Typography } from "@mui/material";

const useStyles = makeStyles({
  agentOrderCard: {
    marginBottom: "10px",
    borderRadius: "10px",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  orderBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    borderBottom: "1px solid #eee",
  },
  boldSizeText: {
    fontSize: "14px",
    fontWeight: 700,
  },
  normalSizeText: {
    fontSize: "14px",
    fontWeight: 400,
  },
  alignElementVertically: {
    display: "flex",
    flexDirection: "column",
  },
  leadStatus: {
    borderRadius: "5px",
    padding: "5px 7px 5px 5px",
    fontSize: "12px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
  },
  leadDetailsButton: {
    padding: "8px",
    fontSize: "12px",
    fontWeight: 700,
  },
});

export default function FieldAgentOrderCard({ order }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card
      className={classes.agentOrderCard}
      onClick={() =>
        navigate({
          pathname: `/orders/${order?.orderId}`,
        })
      }
    >
      <Box className={classes.orderBox}>
        <Typography className={classes.boldSizeText}>
          {order?.saathiName ? order?.saathiName : ""}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
          }}
        >
          {order?.createdOn ? order?.createdOn : ""}
        </Typography>
      </Box>
      <Box className={classes.orderBox}>
        <Typography
          className={classes.normalSizeText}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Mob: {order?.saathiMobileNum ? order?.saathiMobileNum : ""}
        </Typography>
        <Typography>
          <span
            style={{
              color: order?.statusColor ? order.statusColor : "",
              background: order?.statusContainerColor
                ? order.statusContainerColor
                : "",
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              padding: "8px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
          >
            <img
              src={order?.statusIcon ? order.statusIcon : ""}
              alt="order status"
              style={{ marginRight: "8px", height: "20px", width: "20px" }}
            />
            {order?.statusMessage ? order.statusMessage : ""}
          </span>
        </Typography>
      </Box>
      <Box className={classes.orderBox}>
        <Box className={classes.alignElementVertically}>
          <Typography className={classes.normalSizeText} component="span">
            Order ID
          </Typography>
          <Typography className={classes.boldSizeText} component="span">
            {order?.orderId ? order?.orderId : ""}
          </Typography>
        </Box>
        <Box className={classes.alignElementVertically}>
          <Typography className={classes.normalSizeText} component="span">
            Total Amount
          </Typography>
          <Typography
            className={classes.boldSizeText}
            sx={{ textAlign: "right" }}
            component="span"
          >
            {AmountUtils.formatCurrency(order?.grandTotal)}
          </Typography>
        </Box>
      </Box>
      <Button
        fullWidth
        endIcon={<East />}
        className={classes.leadDetailsButton}
      >
        Order Details
      </Button>
    </Card>
  );
}
