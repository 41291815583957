import { useState } from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AnalyticsService from "../../Services/AnalyticsService";
import AndroidService from "../../Services/AndroidService";
import CommonConstantValues from "../../Services/CommonConstantValues";
import AppVersionUnsupported from "../Common/AppVersionUnsupported";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    borderRadius: "0",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "0.56px",
    textTransform: "uppercase",
  },
  modalBox: {
    position: "absolute",
    zIndex: "1000",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: "#00000090",
  },
  errorDialogContainer: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    padding: "20px",
    borderRadius: "4px",
    width: "70%",
  },
  errorDialogMessage: {
    marginBottom: "12px",
    color: "black",
    textAlign: "center",
  },
  errorDialogButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

function SignAgreementButton(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openAppUpdateDialog, setOpenAppUpdateDialog] = useState(false);

  const isSignAgreementCompatibleVersion = () => {
    // We have added this check to check if the app version is less than 29, in that case we won't be showing the sign agreement CTA
    let isSaathiAppUpdated = false;
    if (AndroidService.checkIfAndroid()) {
      if (AndroidService.getAndroidVersion()) {
        let { appVersionCode } = AndroidService.getAndroidVersion();
        if (
          appVersionCode &&
          parseInt(appVersionCode) >=
            CommonConstantValues.SIGN_AGREEMENT_ENABLED_ANDROID_VERSION
        ) {
          isSaathiAppUpdated = true;
        }
      }
    }
    return isSaathiAppUpdated;
  };

  const handleProceedToAgreementDetails = () => {
    AnalyticsService.pushEvent("Button Clicked", {
      type: "Sign Agreement",
    });
    if (
      !isSignAgreementCompatibleVersion() &&
      AndroidService.checkIfAndroid()
    ) {
      setOpenAppUpdateDialog(true);
      return;
    }
    if (AndroidService.checkIfAndroid()) {
      AndroidService.askCameraPermission();
    }
    if (props?.page === "agreementSigningFailure") {
      navigate("/agreement-details", { replace: true });
    } else {
      navigate("/agreement-details");
    }
  };

  return (
    <>
      {props?.page !== "agreementSigningFailure" ? (
        <Box>
          <Button
            size="small"
            variant="contained"
            disabled={
              props?.agreementSigningStatus === "SUCCESS" ? true : false
            }
            sx={{
              fontSize: "12px",
              borderRadius: "16px",
              textTransform: "capitalize",
              fontWeight: "700",
              lineHeight: "14px",
              padding: "8px 34px",
            }}
            onClick={handleProceedToAgreementDetails}
          >
            Sign
          </Button>
        </Box>
      ) : (
        <>
          <Button
            variant={"contained"}
            color={"secondary"}
            fullWidth={true}
            className={classes.buttonStyle}
            onClick={handleProceedToAgreementDetails}
          >
            Retry
          </Button>
        </>
      )}

      <AppVersionUnsupported
        open={openAppUpdateDialog}
        handleClose={() => {
          AnalyticsService.pushEvent("App Update Required Dialog Closed", {
            page: window.location.pathname,
            section: "Sign Agreement CTA",
          });
          setOpenAppUpdateDialog(false);
        }}
        page={window.location.pathname}
        section={"Sign Agreement CTA"}
      />
    </>
  );
}

export default SignAgreementButton;
