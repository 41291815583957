import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import ErrorBoundary from "../Common/ErrorBoundary";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../config";
import PartnerService from "../../Services/PartnerService";
import AnalyticsService from "../../Services/AnalyticsService";

const OrderCard = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [orderListToSettleObj, setOrderListToSettleObj] = useState({});

  useEffect(() => {
    let totalNetPayable = 0;
    setOrderListToSettleObj(
      props?.orderListToSettle?.reduce((acc, curr) => {
        acc[curr.orderId] = curr;
        totalNetPayable += curr.netPayableAmount;
        return acc;
      }, {})
    );
    props?.setSelectedAmountToSettle(
      totalNetPayable > props?.vanPaymentDetails?.amount
        ? props?.vanPaymentDetails?.amount
        : totalNetPayable
    );
  }, [props?.orderListToSettle]);

  // useEffect(() => {
  //   if (props?.order?.orderId === props?.orderIdFromParams) {
  //     props?.handleOpenDeepLink(props?.order);
  //   }
  // }, []);

  const downloadFile = (fileData) => {
    const newBlob = new Blob([fileData], {
      type: "application/pdf",
    });

    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.style = "display: none";
    link.href = data;
    link.download = `AgroStar-Saathi-Invoice-${props?.order?.orderId}.pdf`;
    link.click();
  };

  const handleClickOrderCard = () => {
    if (props?.selectClicked && disableForVanSettlementSelection()) {
      return;
    }
    const isSelected = props?.orderListToSettle?.find(
      (order) => order.orderId === props?.order.orderId
    );

    const eventProperties = {
      page: window?.location?.pathname,
      type: "Pay By Invoice",
      dueAmount: props?.order?.unsettledAmount,
      orderId: props?.order?.orderId || "",
      cashDiscountAmount: props?.order?.cdBenefitAmount,
      netPayableAmount: props?.order?.netPayableAmount,
    };

    if (props?.selectClicked) {
      eventProperties.isCardSelectedForSettlement = isSelected ? true : false;
    }

    AnalyticsService.pushEvent("Card Clicked", eventProperties);

    if (props?.selectClicked) {
      if (isSelected) {
        props?.setOrderListToSettle(
          props?.orderListToSettle?.filter(
            (order) => order.orderId !== props?.order.orderId
          )
        );
      } else {
        if (
          props?.order?.unsettledAmount === 0 ||
          props?.selectedAmountToSettle >= props?.vanPaymentDetails?.amount
        ) {
          return null;
        }
        props?.setOrderListToSettle((prevDetails) => [
          ...prevDetails,
          props?.order,
        ]);
      }

      return null;
    }

    if (props?.order?.orderId) {
      navigate({
        pathname: `/orders/${props?.order.orderId}&wmsOrderId=${props?.order.wmsId}`,
        state: { wmsOrderId: props?.order?.wmsId },
      });
    }
  };

  const isOrderLendingPartnerDifferentFromSaathiLendingPartner = () => {
    // Against each order, check it's lending partner. If it matches the lending partner in the partner's profile, then only allow it to be selected for VAN settlement
    if (PartnerService.getLendingPartner() !== props?.order?.lendingPartner) {
      return true;
    }
    return false;
  };

  const disableForVanSettlementSelection = () => {
    if (
      props?.order?.unsettledAmount === 0 ||
      (props?.selectedAmountToSettle >= props?.vanPaymentDetails?.amount &&
        !orderListToSettleObj?.[encodeURI(props?.order?.orderId)]) ||
      isOrderLendingPartnerDifferentFromSaathiLendingPartner()
    ) {
      return true;
    }
    return false;
  };

  return (
    <ErrorBoundary message={""}>
      <Snackbar
        open={showAlertMessage}
        autoHideDuration={3000}
        onClose={() => setShowAlertMessage(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          icon={false}
          sx={{
            background: "#666666",
            color: "#ffffff",
            textAlign: "center",
            lineHeight: `1.1`,
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            padding: "8px 16px",
            marginBottom: "64px",
          }}
          onClose={() => setShowAlertMessage(false)}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {props?.order?.isInOutStandingCreditPeriod && (
        <Box
          sx={{
            position: "relative",
            fontSize: "12px",
            "& span": {
              position: "relative",
              bottom: "-1px",
              marginLeft: "12px",
              padding: "2px 20px 2px 8px",
              color: "#fff",
              background: "url('/images/paymentOverdueBackground.svg')",
              backgroundRepeat: "no-repeat",
            },
          }}
        >
          <span>Payment Overdue</span>
        </Box>
      )}
      <Card
        sx={{
          marginBottom: "16px",
          boxShadow: "none",
          padding: "8px 16px 0px 16px",
          border: `${
            props?.order?.isInOutStandingCreditPeriod ? `1px solid #9D2123` : ``
          }`,
          borderRadius: "16px",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ margin: "2px 0px 6px 0px" }}
          onClick={handleClickOrderCard}
        >
          <Grid item xs={5}>
            <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
              {`Order ID: ${
                props?.order?.orderId ? props?.order?.orderId : ``
              }`}
            </Typography>
            {props?.order?.dueDate && (
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                {`Due On - ${format(props?.order?.dueDate, "dd MMM yy")}`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} textAlign={"right"} sx={{ paddingLeft: "8px" }}>
            <span
              style={{
                color: props?.order?.statusColor,
                background: props?.order?.statusContainerColor
                  ? props?.order.statusContainerColor
                  : "",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "6px 8px",
                borderRadius: "8px",
                textTransform: "capitalize",
                fontSize: "11px",
                textAlign: "center",
              }}
            >
              <img
                src={props?.order?.statusIcon ? props?.order.statusIcon : ""}
                alt="order status"
                style={{
                  marginRight: "6px",
                  height: "20px",
                  width: "20px",
                }}
              />
              {props?.order?.statusMessage ? props?.order?.statusMessage : ""}
            </span>
          </Grid>
          {props?.selectClicked && (
            <Grid item xs={1} textAlign={"right"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      orderListToSettleObj?.[props?.order?.orderId]
                        ? true
                        : false
                    }
                    indeterminate={disableForVanSettlementSelection()}
                    disabled={disableForVanSettlementSelection()}
                    sx={{ left: "8px" }}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
        <Divider sx={{ margin: "0px -16px", height: "2px" }} />
        <Grid
          container
          alignItems="center"
          spacing={0}
          sx={{ padding: "16px 0px 22px 0px" }}
          onClick={handleClickOrderCard}
        >
          <Grid item xs={4.5}>
            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
              Order Amount
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
              ₹
              {props?.order?.orderAmount >= 0
                ? new Intl.NumberFormat("en-IN").format(
                    props?.order?.orderAmount
                  )
                : ``}
            </Typography>
          </Grid>
          <Grid item xs={4.5}>
            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
              Unsettled Amount
            </Typography>
            <Typography
              color={"primary"}
              sx={{ fontSize: "14px", fontWeight: "700" }}
            >
              ₹
              {props?.order?.unsettledAmount >= 0
                ? new Intl.NumberFormat("en-IN").format(
                    props?.order?.unsettledAmount
                  )
                : ``}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
              CD Benefit
            </Typography>
            <Typography
              color={"secondary"}
              sx={{ fontSize: "14px", fontWeight: "700" }}
            >
              ₹
              {props?.order?.cdBenefitAmount >= 0
                ? new Intl.NumberFormat("en-IN").format(
                    props?.order?.cdBenefitAmount
                  )
                : ``}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ margin: "0px -16px", height: "2px" }} />
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          spacing={0}
          marginTop={"8px"}
          marginBottom={"8px"}
          sx={{ position: "relative" }}
        >
          <Grid item xs={5}>
            {props?.order?.netPayableAmount ? (
              <Box
                sx={{
                  position: "absolute",
                  left: "-16px",
                  bottom: "4px",
                  fontSize: "12px",
                  padding: "4px 22px 4px 5px",
                  color: "#9D2123",
                  background: "#f8d248",
                  "& span": {
                    width: "18px",
                    height: "37px",
                    background: "white",
                    display: "inline-block",
                    position: "absolute",
                    right: "-3px",
                    top: "-12px",
                    transform: "rotate(-45deg)",
                  },
                }}
              >
                Net Payable :{" "}
                <b>
                  ₹
                  {new Intl.NumberFormat("en-IN").format(
                    props?.order?.netPayableAmount
                  )}
                </b>
                <span></span>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={5}>
            <Button
              size={`small`}
              variant={"contained"}
              color={"primary"}
              fullWidth={true}
              disabled={
                props?.disablePayNowForOrder(props?.order) ? true : false
              }
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                borderRadius: "10px",
                textTransform: "uppercase",
                padding: "5px 10px",
                visibility: props?.selectClicked ? "hidden" : "visible",
              }}
              onClick={() => {
                props?.handleClickPayNow(props?.order);
                props?.setPayNowOrderId(props?.order?.orderId);
              }}
            >
              Pay Now
            </Button>
          </Grid>
        </Grid>
        {props?.disablePayNowForOrder(props?.order) ? (
          <>
            {PartnerService.isPartnerOnboardingInProgress() &&
            localStorage.getItem("role") !== "fieldAgent" ? (
              <Typography
                sx={{
                  margin: "0px -16px",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FDF4E2",
                  padding: "4px 16px",
                  color: "#CC8800",
                }}
              >
                <GppMaybeOutlinedIcon
                  sx={{ fontSize: "20px", marginRight: "4px" }}
                />
                {`Pay Now will be available once you become our Saathi Partner`}
              </Typography>
            ) : config.disablePayNow ? (
              <Typography
                sx={{
                  margin: "0px -16px",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FDF4E2",
                  padding: "4px 16px",
                  color: "#CC8800",
                }}
              >
                <GppMaybeOutlinedIcon
                  sx={{ fontSize: "20px", marginRight: "4px" }}
                />
                {`Pay Now is temporarily disabled for system maintenance. It will be available soon.`}
              </Typography>
            ) : props?.isLastSuccessfulPaymentInProgressForOrder(
                props?.order
              ) ? (
              <Typography
                sx={{
                  margin: "0px -16px",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FDF4E2",
                  padding: "4px 0px",
                  color: "#CC8800",
                }}
              >
                <GppMaybeOutlinedIcon
                  sx={{ fontSize: "20px", marginRight: "4px" }}
                />
                {`Previous payment is in progress`}
              </Typography>
            ) : props?.order?.payNowDisabledMessage ? (
              <Typography
                sx={{
                  margin: "0px -16px",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: `${
                    props?.order?.unsettledAmount > 0 ? `#F5E9E9` : `#E6F2EC`
                  }  `,
                  padding: "4px 0px",
                  color: `${
                    props?.order?.unsettledAmount > 0 ? `#9D2123` : `#00733E`
                  }`,
                }}
              >
                {props?.order?.unsettledAmount > 0 ? (
                  <GppMaybeOutlinedIcon
                    sx={{ fontSize: "20px", marginRight: "4px" }}
                  />
                ) : (
                  <ThumbUpOutlinedIcon
                    sx={{ fontSize: "18px", marginRight: "4px" }}
                  />
                )}

                {props?.order?.payNowDisabledMessage}
              </Typography>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Card>
    </ErrorBoundary>
  );
};

export default OrderCard;
