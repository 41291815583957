import { useEffect, useState } from "react";
import SchemeDetails from "./SchemeDetails";
import _ from "lodash";
import format from "date-fns/format";
import SchemesService from "../../Services/SchemesService";
import { useParams } from "react-router-dom";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import AnalyticsService from "../../Services/AnalyticsService";
import EmptyError from "../EmptyError/EmptyError";
import ErrorBoundary from "../Common/ErrorBoundary";
import TopBar from "../Common/TopBar";

const isFeaturedSchemes = true;

const SchemeDetailsPage = (props) => {
  const [sliderMarks, setSliderMarks] = useState(null);
  const [nextSlabDetails, setNextSlabDetails] = useState(null);
  const [nextTargetEntitlement, setNextTargetEntitlement] = useState(null);
  const [loadingApplicableProductsSkus, setLoadingApplicableProductsSkus] =
    useState(`not_started`);

  const [turnoverScheme, setTurnoverScheme] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const [error, setError] = useState(null);
  const { schemeId } = useParams();
  const [slabConstraintUnit, setSlabConstraintUnit] = useState(null);
  const [schemeInformation, setSchemeInformation] = useState(null);
  const [schemeConstraintType, setSchemeConstraintType] = useState(null);
  const [shopNowUrl, setShopNowUrl] = useState(null);
  const [lastSlabAchieved, setLastSlabAchieved] = useState(null);
  const [deltaPromptQuantity, setDeltaPromptQuantity] = useState(null);

  useEffect(() => {
    setLoading("started");
    const { axiosRequest, abortController } =
      SchemesService.getTurnoverBasedSchemes(isFeaturedSchemes, "all");
    axiosRequest
      .then((response) => {
        let turnoverSchemesFromResponse =
          response?.data?.responseData?.turnoverData?.turnoverOfferStatus;

        _.forEach(turnoverSchemesFromResponse, (turnoverScheme) => {
          let schemeIdToMatchAgainst =
            turnoverScheme?.name +
            "-" +
            turnoverScheme?.promotionUUID?.slice(0, 3);

          schemeIdToMatchAgainst = _.replace(schemeIdToMatchAgainst, "%", "");
          schemeIdToMatchAgainst = _.replace(schemeIdToMatchAgainst, ".", "");
          schemeIdToMatchAgainst = _.trim(schemeIdToMatchAgainst);

          if (schemeIdToMatchAgainst === schemeId) {
            let slabConstraintUnitFromScheme =
              SchemesService.SLAB_CONSTRAINT_UNIT_MAP[
                turnoverScheme?.constraintsStatus?.[0]
                  ?.slabConstraintsStatus?.[0]?.constraintKey
              ];
            setSlabConstraintUnit(slabConstraintUnitFromScheme);

            let nextSlabData = _.find(
              turnoverScheme?.turnoverOfferRule,
              (slabDetails) => {
                return (
                  slabDetails.constraints?.constraintList?.[0]?.params
                    ?.targetValue > turnoverScheme?.netValue
                );
              }
            );

            if (!nextSlabData) {
              // if current slab is the last slab, treat the current slab itself as next slab
              nextSlabData = turnoverScheme?.turnoverOfferRule?.slice(-1)?.[0];
            }

            setNextSlabDetails(nextSlabData);
            setNextTargetEntitlement(
              nextSlabData?.entitlements?.entitlementList
            );

            let lastSlabAchievedIndex = _.findIndex(
              turnoverScheme.turnoverOfferRule,
              (slabDetails) => {
                return (
                  turnoverScheme.netValue <
                  slabDetails.constraints?.constraintList?.[0]?.params
                    ?.targetValue
                );
              }
            );
            let lastSlabAchievedData = null;
            if (lastSlabAchievedIndex === -1) {
              lastSlabAchievedData =
                turnoverScheme?.turnoverOfferRule?.slice(-1)?.[0];
            } else if (lastSlabAchievedIndex > 0) {
              lastSlabAchievedData =
                turnoverScheme?.turnoverOfferRule?.[lastSlabAchievedIndex - 1];
            }
            setLastSlabAchieved(lastSlabAchievedData);

            let sliderMarksFromSlabsData = generateSliderMarks(
              turnoverScheme?.turnoverOfferRule,
              turnoverScheme?.netValue,
              false,
              slabConstraintUnitFromScheme
            );
            setSliderMarks(sliderMarksFromSlabsData);

            let schemeConstraintTypeDerived =
              turnoverScheme?.turnoverOfferRule?.[0]?.constraints
                ?.constraintList?.[0]?.type;
            setSchemeConstraintType(schemeConstraintTypeDerived);

            let schemeInformationDerived = [
              {
                label: "Scheme Period",
                description: `${format(
                  turnoverScheme?.validFrom,
                  "do MMMM yyyy"
                )} to ${format(turnoverScheme?.validTo, "do MMMM yyyy")}`,
              },
              {
                label: "Settlement On",
                description: `${format(
                  turnoverScheme?.settlemetDate,
                  "do MMMM yyyy"
                )}`,
              },
              {
                label: "Applicable Product",
                description: `${
                  schemeConstraintTypeDerived === `PRODUCT_COHORT` ||
                  schemeConstraintTypeDerived === `CATEGORY` ||
                  schemeConstraintTypeDerived === `ORDER_VALUE`
                    ? `Product List`
                    : schemeConstraintTypeDerived === `PRODUCT`
                      ? `Product`
                      : ``
                }`,
              },
            ];
            setSchemeInformation(schemeInformationDerived);
            setTurnoverScheme(turnoverScheme);

            let deltaPromptQuantityFromResponse = isFeaturedSchemes
              ? turnoverScheme?.deltaPrompt?.[0]?.deltaMatrixValue
              : turnoverScheme?.deltaPrompt?.[0]?.deltaQuantity;
            setDeltaPromptQuantity(deltaPromptQuantityFromResponse);

            AnalyticsService.pushEvent(`Scheme Details Page Viewed`, {
              schemeName: turnoverScheme?.name,
              schemeId: schemeId,
            });
          }
        });
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          console.log(`error getting turnover based offers`);
          if (error.data) {
            setError(error.data);
            console.log(error.data);
          } else {
            setError(error);
            console.log(error);
          }
          setLoading("done");
        }
      });
    return () => abortController.abort();
  }, []);

  const finalSlabDetails = _.last(turnoverScheme?.turnoverOfferRule);
  const finalSlabTarget =
    finalSlabDetails?.constraints?.constraintList?.[0]?.params?.targetValue;

  const generateEntitlementDescription = (entitlements) => {
    let entitlementsList = [];
    let entitlementsString = ``;
    _.forEach(entitlements, (entitlement) => {
      if (entitlement?.type === `FREE_VOUCHER`) {
        _.forEach(entitlement?.params?.freeVouchers, (voucherDetails) => {
          entitlementsList.push(
            `${voucherDetails?.voucherCode} ${
              SchemesService.ENTITLEMENT_MAP[entitlement?.type]
            }`
          );
        });
      } else {
        if (
          entitlement?.type === `CREDIT_MEMO` ||
          entitlement?.type === `CREDIT_MEMO_PER_QUANTITY`
        ) {
          entitlementsList.push(
            `₹${new Intl.NumberFormat("en-IN").format(
              entitlement?.params?.value
            )} ${SchemesService.ENTITLEMENT_MAP[entitlement?.type]}`
          );
        } else {
          entitlementsList.push(
            `${entitlement?.params?.value} ${
              SchemesService.ENTITLEMENT_MAP[entitlement?.type]
            }`
          );
        }
      }
    });
    entitlementsString = _.join(entitlementsList, ", ");
    return entitlementsString;
  };

  const generateSliderMarks = (
    slabsData,
    valueToPlotOnProgressBar,
    isOverlayProgressBar,
    slabConstraintUnit
  ) => {
    let generatedSliderMarks = [
      {
        label: isOverlayProgressBar
          ? ``
          : slabConstraintUnit === `₹`
            ? `${slabConstraintUnit} 0`
            : `0 ${slabConstraintUnit}`,
        value: 0,
        slabIndex: 0,
        slabTarget: 0,
        nextSlabTarget:
          slabsData?.[0]?.constraints?.constraintList?.[0]?.params?.targetValue,
      },
    ];

    _.forEach(slabsData, (slab, index) => {
      let sliderMarkItem = {};
      sliderMarkItem.label = isOverlayProgressBar
        ? ``
        : slabConstraintUnit === `₹`
          ? `${slabConstraintUnit} ${new Intl.NumberFormat("en-IN").format(
              slab?.constraints?.constraintList?.[0]?.params?.targetValue
            )}`
          : `${slab?.constraints?.constraintList?.[0]?.params?.targetValue} ${slabConstraintUnit}`;
      sliderMarkItem.value = (index + 1) * (100 / slabsData?.length);
      sliderMarkItem.slabIndex = index + 1;
      sliderMarkItem.slabTarget =
        slab?.constraints?.constraintList?.[0]?.params?.targetValue;
      sliderMarkItem.nextSlabTarget = slabsData?.[index + 1]
        ? slabsData?.[index + 1]?.constraints?.constraintList?.[0]?.params
            ?.targetValue
        : finalSlabTarget;
      generatedSliderMarks.push(sliderMarkItem);
    });

    return generatedSliderMarks;
  };

  useEffect(() => {
    if (
      turnoverScheme?.promotionUUID &&
      schemeConstraintType === `PRODUCT_COHORT`
    ) {
      setLoadingApplicableProductsSkus("started");
      const { axiosRequest, abortController } =
        SchemesService.getProductCohorts(turnoverScheme?.promotionUUID);
      axiosRequest
        .then((response) => {
          let productCohortsFromResponse =
            response?.data?.responseData?.productCohorts?.productDetails;
          let applicableSkus = [];

          _.forEach(productCohortsFromResponse, (productCohort, index) => {
            applicableSkus.push(productCohort?.skuCode);
          });
          if (applicableSkus?.length > 0) {
            setShopNowUrl(`/scheme-products/${applicableSkus?.join(",")}`);
          }
          setLoadingApplicableProductsSkus("done");
        })
        .catch((error) => {
          if (error?.message !== "canceled") {
            console.log(`error getting product cohorts`);
            if (error.data) {
              console.log(error.data);
            } else {
              console.log(error);
            }
            setLoadingApplicableProductsSkus("done");
          }
        });
      return () => abortController.abort();
    } else if (schemeConstraintType === `PRODUCT`) {
      let applicableSkus = [];
      _.forEach(
        turnoverScheme?.turnoverOfferRule?.[0]?.constraints?.constraintList?.[0]
          ?.params?.itemQuanList,
        (itemQuan) => {
          applicableSkus.push(itemQuan?.skuCode);
        }
      );
      // if only 1 sku, redirect user to PDP. If multiple skus, redirect to PLP
      if (applicableSkus?.length === 1) {
        setShopNowUrl(`/products/${applicableSkus?.[0]}`);
      } else if (applicableSkus?.length > 1) {
        setShopNowUrl(`/scheme-products/${applicableSkus?.join(",")}`);
      }
    } else if (schemeConstraintType === `CATEGORY`) {
      let categoryName =
        turnoverScheme?.turnoverOfferRule?.[0]?.constraints?.constraintList?.[0]
          ?.params?.itemQuanList?.[0]?.categoryName;
      let allowedCategories = [
        "Seeds",
        "Crop Protection",
        "Crop Nutrition",
        "Hardware",
      ];
      if (allowedCategories.includes(categoryName)) {
        setShopNowUrl(`/categories/${categoryName}`);
      }
    } else if (schemeConstraintType === `ORDER_VALUE`) {
      setShopNowUrl(`/nearby-partner-purchase-products`);
    }
  }, [turnoverScheme, schemeConstraintType]);

  const sliderTooltipValue = () => {
    if (slabConstraintUnit === `₹`) {
      return `${slabConstraintUnit} ${new Intl.NumberFormat("en-IN").format(
        turnoverScheme?.netValue
      )}`;
    } else {
      return `${turnoverScheme?.netValue} ${slabConstraintUnit}`;
    }
  };

  return (
    <>
      <BlockUi
        tag="div"
        blocking={loading !== "done"}
        message={`Loading...`}
        renderChildren={false}
        keepInView
        style={{ height: "100vh", background: "#F3F3F3" }}
      >
        {turnoverScheme ? (
          <>
            <SchemeDetails
              turnoverScheme={turnoverScheme}
              schemeInformation={schemeInformation}
              slabConstraintUnit={slabConstraintUnit}
              schemeConstraintType={schemeConstraintType}
              shopNowUrl={shopNowUrl}
              loadingApplicableProductsSkus={loadingApplicableProductsSkus}
              sliderMarks={sliderMarks}
              sliderTooltipValue={sliderTooltipValue}
              finalSlabTarget={finalSlabTarget}
              nextTargetEntitlement={nextTargetEntitlement}
              nextSlabDetails={nextSlabDetails}
              generateEntitlementDescription={generateEntitlementDescription}
              schemeId={schemeId}
              lastSlabAchieved={lastSlabAchieved}
              deltaPromptQuantity={deltaPromptQuantity}
              isFeaturedSchemes={isFeaturedSchemes}
            />
          </>
        ) : (
          <>
            <ErrorBoundary message={``}>
              <TopBar
                title={`Scheme Details`}
                backNavigation={true}
                showCart={true}
                showHisaab={true}
                toolTip={``}
              />
            </ErrorBoundary>
            <EmptyError
              image="EmptyPage.svg"
              text={`${
                error
                  ? `Something went wrong. Please try again later.`
                  : `Sorry, this is not a valid scheme`
              }`}
              extraStyle={{
                background: "#F3F3F3",
                minHeight: "calc(100vh - 4rem)",
              }}
            />
          </>
        )}
      </BlockUi>
    </>
  );
};

export default SchemeDetailsPage;
