import { useState } from "react";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import FarmerExperienceCard from "./FarmerExperienceCard";
import AnalyticsService from "../../Services/AnalyticsService";

export default function FarmerExperience({ farmerExperiencePosts, skuCode }) {
  const [autoPlayCarousel, setAutoPlayCarousel] = useState(true);

  return (
    <Box
      sx={{
        marginTop: "10px",
        marginBottom: "0px",
        padding: 2,
        paddingBottom: 0,
      }}
    >
      <Typography
        component="h3"
        sx={{ fontWeight: "bold", marginBottom: "8px" }}
      >
        Farmer's Experience
      </Typography>
      {farmerExperiencePosts.length > 1 ? (
        <Carousel
          navButtonsAlwaysInvisible
          fullHeightHover={false}
          interval={4000}
          sx={{ zIndex: 0 }}
          autoPlay={autoPlayCarousel}
          stopAutoPlayOnHover={false}
          onChange={() => {
            const isAudioPlaying =
              localStorage.getItem("playingMediaId")?.split(".")?.[1] === "wav";
            if (isAudioPlaying) {
              localStorage.removeItem("playingMediaId");
              window.dispatchEvent(new Event("storage"));
            }
          }}
        >
          {_.map(farmerExperiencePosts, (post, index) => {
            return (
              <FarmerExperienceCard
                key={index}
                post={post}
                skuCode={skuCode}
                testimonialIndex={index}
                postId={post?._id}
                setAutoPlayCarousel={setAutoPlayCarousel}
              />
            );
          })}
        </Carousel>
      ) : (
        <FarmerExperienceCard
          post={farmerExperiencePosts?.[0]}
          skuCode={skuCode}
          testimonialIndex={0}
          postId={farmerExperiencePosts?.[0]?._id}
          setAutoPlayCarousel={setAutoPlayCarousel}
        />
      )}
    </Box>
  );
}
