import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BlockUi from "react-block-ui";
import moment from "moment";

import { makeStyles } from "@mui/styles";
import { Box, Button, Card, Container, Grid } from "@mui/material";
import { Cancel, CheckCircle, East } from "@mui/icons-material";

import TopBar from "../Common/TopBar";
import EmptyError from "../EmptyError/EmptyError";
import PartnersSearch from "../Partners/PartnersSearch";
import PartnerService from "../../Services/PartnerService";
import { iptStatus } from "./InterPartyTransferRequestsPage";
import AnalyticsService from "../../Services/AnalyticsService";
import InterPartyTransfersService from "../../Services/InterPartyTransfersService";

const useStyles = makeStyles({
  iptCard: {
    marginTop: "10px",
    borderRadius: "10px",
    boxShadow: "0px 12px 24px 0px rgba(28, 28, 28, 0.10)",
  },
  iptCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
  },
  iptCardBody: {
    padding: "10px 14px",
    fontSize: "14px",
    borderTop: "1px solid #eee",
  },
  iptTag: {
    fontSize: "14px",
    borderRadius: "5px",
    background: "#dcdcdc",
    padding: "5px 4px",
    marginRight: "8px",
  },
  iptTransferStatus: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    borderRadius: "5px",
    background: "#efe5cc",
    padding: "5px 7px",
  },
  iptPartner: { fontWeight: 700 },
  iptDetailsButton: {
    height: "40px",
    fontSize: "12px",
    fontWeight: 700,
    borderTop: "1px solid #eee",
  },
  initiateTransferButton: {
    position: "fixed",
    right: "16px",
    bottom: "80px",
    height: "40px",
    fontWeight: "500",
    textTransform: "none",
    borderRadius: "50px",
    background: "rgba(157, 33, 35, 0.70)",
    "&:hover": { background: "rgba(157, 33, 35, 0.70)" },
  },
});

export const iptPartnerStatus = {
  PENDING: (
    <CheckCircle
      sx={{
        position: "relative",
        top: "3px",
        height: "16px",
        marginLeft: "5px",
        color: "#dcdcdc",
      }}
    />
  ),
  REJECTED: (
    <Cancel
      sx={{
        position: "relative",
        top: "3px",
        height: "16px",
        marginLeft: "5px",
        color: "#b97778",
      }}
    />
  ),
  APPROVED: (
    <CheckCircle
      sx={{
        position: "relative",
        top: "3px",
        height: "16px",
        marginLeft: "5px",
        color: "#87dab2",
      }}
    />
  ),
};

const checkIPTApprovalStatus = (fromPartner, toPartner) => {
  if (
    fromPartner?.iptApprovalStatus === "APPROVED" &&
    toPartner?.iptApprovalStatus === "APPROVED"
  ) {
    return "Approved";
  } else if (
    fromPartner?.iptApprovalStatus === "REJECTED" ||
    toPartner?.iptApprovalStatus === "REJECTED"
  ) {
    return "Rejected";
  } else {
    return "Pending";
  }
};

export default function InterPartyTransfersPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [iptList, setIPTList] = useState(null);
  const [loading, setLoading] = useState("not_started");
  const [searchKeyword, setSearchKeyword] = useState(null);

  useEffect(() => {
    AnalyticsService.pushEvent(`Page Viewed`, { page: "Transfer" });

    setLoading("started");
    const { axiosRequest, abortController } =
      InterPartyTransfersService.getInterPartyTransfersList();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setIPTList(response?.data?.responseData);
        }
        setLoading("done");
      })
      .catch((error) => {
        if (error?.message !== "canceled") {
          setLoading("done");
          console.error("Failed to Get IPT List: ", error);
        }
      });
    return () => abortController.abort();
  }, []);

  const handleLogout = () => {
    PartnerService.logoutFieldAgent();
    AnalyticsService.pushEvent("Logout As Field Agent Clicked");
    navigate("/");
    window.location.reload();
  };

  const handleSearch = (keyword) => {
    if (keyword) {
      setSearchKeyword(keyword);
      setLoading("started");
      setIPTList([]);
      const { axiosRequest } =
        InterPartyTransfersService.getInterPartyTransfersList(keyword);
      axiosRequest
        .then((response) => {
          if (response?.data?.responseData) {
            setIPTList(response?.data?.responseData);
          }
          setLoading("done");
        })
        .catch((error) => {
          setLoading("done");
          console.error("Failed to Get IPT List: ", error);
        });
    } else {
      clearSearch();
    }
  };

  const clearSearch = () => {
    setSearchKeyword("");
    const { axiosRequest } =
      InterPartyTransfersService.getInterPartyTransfersList();
    axiosRequest
      .then((response) => {
        if (response?.data?.responseData) {
          setIPTList(response?.data?.responseData);
        }
        setLoading("done");
      })
      .catch((error) => {
        setLoading("done");
        console.error("Failed to Get IPT List: ", error);
      });
  };

  return (
    <>
      <TopBar
        title={`SAATHI`}
        showCart={false}
        showHisaab={false}
        showCallAgriDoctor={false}
        showFieldAgentLogout={true}
        handleLogout={handleLogout}
      />

      <Container maxWidth={`xl`} sx={{ background: "#fff", pt: 1 }}>
        <PartnersSearch
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          searchKeyword={searchKeyword}
        />
      </Container>

      <BlockUi
        tag="div"
        blocking={loading === "started"}
        message={`Loading...`}
        style={{ minHeight: "85vh" }}
        keepInView
      >
        <Container maxWidth={`xl`} sx={{ pt: "10px", pb: "125px" }}>
          {iptList?.length
            ? iptList?.map((ipt) => (
                <Card
                  key={ipt.id}
                  className={classes.iptCard}
                  onClick={() => navigate(`/inter-party-transfers/${ipt.id}`)}
                >
                  <Box className={classes.iptCardHeader}>
                    <div>
                      <span className={classes.iptTag}>IPT</span>
                      <span style={{ fontSize: "14px" }}>
                        Request ID: {ipt.id}
                      </span>
                    </div>
                    <span
                      className={classes.iptTransferStatus}
                      style={{
                        color: iptStatus[ipt?.status]?.color,
                        background: iptStatus[ipt?.status]?.bgColor,
                      }}
                    >
                      {iptStatus[ipt.status]?.icon}
                      {iptStatus[ipt.status]?.text}
                    </span>
                  </Box>
                  <Grid container className={classes.iptCardBody}>
                    <Grid item xs={3.5}>
                      From -
                    </Grid>
                    <Grid item xs={8.5} className={classes.iptPartner}>
                      {ipt.fromPartner?.name}
                      {iptPartnerStatus[ipt.fromPartner?.iptApprovalStatus]}
                    </Grid>
                    <Grid item xs={3.5}>
                      To -
                    </Grid>
                    <Grid item xs={8.5} className={classes.iptPartner}>
                      {ipt.toPartner?.name}
                      {iptPartnerStatus[ipt.toPartner?.iptApprovalStatus]}
                    </Grid>
                    <Grid item xs={3.5}>
                      Date -
                    </Grid>
                    <Grid item xs={8.5}>
                      {moment(ipt.createdOn).format("D MMM YYYY")}
                    </Grid>
                    <Grid item xs={3.5}>
                      Approval -
                    </Grid>
                    <Grid
                      item
                      xs={8.5}
                      sx={{
                        fontWeight: 700,
                        textTransform: "capitalize",
                        color:
                          checkIPTApprovalStatus(
                            ipt.fromPartner,
                            ipt.toPartner
                          ) === "Approved"
                            ? "#00733E"
                            : "#9D2123",
                      }}
                    >
                      {checkIPTApprovalStatus(ipt.fromPartner, ipt.toPartner)}
                    </Grid>
                  </Grid>
                  <Button
                    fullWidth
                    endIcon={<East />}
                    className={classes.iptDetailsButton}
                  >
                    IPT Details
                  </Button>
                </Card>
              ))
            : loading === "done" && (
                <EmptyError text="No Transfers Found." hideBack={true} />
              )}
        </Container>
      </BlockUi>

      <Button
        variant="contained"
        href="/inter-party-transfers/initiate"
        startIcon={<img src="/icons/transferWhiteIcon.svg" />}
        className={classes.initiateTransferButton}
        onClick={() =>
          AnalyticsService.pushEvent(`FAB Button Clicked`, {
            buttonName: "Initiate Transfer",
          })
        }
      >
        Initiate Transfer
      </Button>
    </>
  );
}
