import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Container, Grid, Skeleton } from "@mui/material";

import SectionTitle from "../Common/SectionTitle";
import ProductCard from "../Product/ProductCard";
import ViewAllButton from "../Common/ViewAllButton";
import useFeaturedProducts from "../../Hooks/useFeaturedProducts";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    marginTop: theme.spacing(1),
  },
  loaderContent: {},
  mainContent: {
    backgroundColor: theme.palette.background.main,
  },
  loaderWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
  },
  viewAllBrandsButtonWrapperLink: {
    textDecoration: "none",
  },
}));

function FeaturedProducts(props) {
  const classes = useStyles();
  const { products, loading, error } = useFeaturedProducts();

  return (
    <div>
      {loading === "done" && !error && products && products.length > 0 && (
        <Container maxWidth={`xl`} style={{ marginTop: "5px" }}>
          <SectionTitle title={`New Launches`} />
          <div style={{ marginTop: "10px" }}>
            <Grid
              className={classes.mainContent}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              {_.map(
                products.slice(
                  0,
                  props.numberOfProductsToShow
                    ? props.numberOfProductsToShow
                    : products.length
                ),
                (product, index) => {
                  return (
                    <Grid item xs={6} key={index}>
                      <ProductCard
                        product={product}
                        key={product.skuCode}
                        index={index}
                        section={"Featured Products"}
                        stateCode={props.stateCode}
                        newLaunch={true}
                        saveScrollYPosition={true}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          </div>
          {products && products.length > props.numberOfProductsToShow && (
            <Box sx={{ marginBottom: "20px" }}>
              <Link
                to={`/featured-products`}
                className={classes.viewAllBrandsButtonWrapperLink}
              >
                <ViewAllButton
                  section={"Featured Products List"}
                  saveScrollYPosition={true}
                />
              </Link>
            </Box>
          )}
        </Container>
      )}

      {loading === "started" && (!error || !error?.data) && (
        <>
          <Skeleton
            variant={`text`}
            width={300}
            style={{ marginLeft: "16px", marginTop: "8px" }}
          />
          <div className={classes.loaderWrapper}>
            <Grid
              container
              direction={`row`}
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.loaderContent}
              wrap={"nowrap"}
              spacing={2}
            >
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
              <Grid item>
                <Skeleton variant={`rectangular`} height={250} width={152} />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default FeaturedProducts;
