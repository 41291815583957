export default class MiscUtils {
  static saveScrollYPosition = () => {
    sessionStorage.setItem("scrollYPosition", window.scrollY);
  };

  static checkIfValidEmail = (email) => {
    const emailRegex =
      /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    return true;
  };

  static checkIfIntegerInput = (value) => {
    const integerRegex = /^[0-9]*$/;
    if (!integerRegex.test(value)) {
      return false;
    }
    return true;
  };
}
